.react-app ._container_12fqi_1 {
  position: absolute;
  right: var(--space-less);
  bottom: var(--space-less);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.react-app ._multiUploadsWrapper_12fqi_11 {
  background: var(--ff-bg-haze);
  margin-bottom: var(--space-small);
  padding: var(--space-tiny);
  display: flex;
}
.react-app ._multiUploadsWrapper_12fqi_11 ._thumbnailContainer_12fqi_17 {
  height: 24px;
  width: 24px;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
.react-app ._multiUploadsWrapper_12fqi_11 ._thumbnailContainer_12fqi_17 ._thumbnailImg_12fqi_27 {
  width: 100%;
}
.react-app ._multiUploadsWrapper_12fqi_11 ._thumbnailContainer_12fqi_17 ._thumbnailImg_12fqi_27 path {
  fill: var(--ff-color-text-base);
}
.react-app ._multiUploadsWrapper_12fqi_11 ._thumbnailContainer_12fqi_17._selected_12fqi_33 path {
  fill: var(--ff-color-text-base-lightest);
}
.react-app ._multiUploadsWrapper_12fqi_11 :not(:first-child) {
  margin-left: var(--space-small);
}

.react-app ._controlsWrapper_12fqi_40 {
  display: flex;
}

.react-app ._downloadLink_12fqi_44 {
  text-decoration: none;
  display: inline-flex;
}
.react-app ._container_1gtny_1 {
  display: flex;
  align-items: center;
}

.react-app ._logo_1gtny_6 {
  height: 42px;
  min-height: 42px;
  width: 42px;
  min-width: 42px;
}

.react-app ._text_1gtny_13 {
  text-transform: uppercase;
}
export const getRoleContextName = (context) => {
  if (!context) return ''

  return `${context.parent_name} ${context.name}`
}

export const getRoleContextSubtitle = (context) => {
  if (!context) return ''

  return context.campus_name
}

export const isClassOrCourseRole = (role) =>
  ['Institutions::ClassOf', 'Institutions::Course'].includes(role.context_type)

import Bar from './Bar'
import BarLarge from './BarLarge'
import Line from './Line'

const GraphPlaceholder = {}

GraphPlaceholder.Bar = Bar
GraphPlaceholder.BarLarge = BarLarge
GraphPlaceholder.Line = Line

export default GraphPlaceholder

import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import {
  Container as Grid,
  Link,
  Row,
  Table,
  Text
} from '@fullfabric/alma-mater'

import useFetchedImportData from 'apps/Imports/components/import-report-step/hooks/use-fetched-import-data'
import { IMPORT_TYPES } from 'apps/Imports/utils/constants'
import { ReactComponent as PersonIcon } from './person-icon.svg'
import ReportErrorsFooter from './report-errors-footer'

import styles from './styles.module.scss'

const ReportErrorsSection = () => {
  const { t } = useTranslation()
  const { document } = useFetchedImportData()

  const isProfileImport = document._ruby_type === IMPORT_TYPES.PROFILE
  const errorList = document?.error_summary
  const documentId = document?.id

  if (!errorList || errorList.length === 0) return null

  return (
    <Grid className={styles.container}>
      <Row between='xs'>
        <Text type='h5' marginLeft='small'>
          <Trans>List of Failures & Errors</Trans>
        </Text>
        <Link
          className={styles.downloadLink}
          href={`/import/${documentId}/failures`}
        >
          <Trans>Download .csv with failures & errors</Trans>
        </Link>
      </Row>

      <Table.Container>
        <Table className={styles.errorTableList}>
          <Table.Header>
            <Table.HeaderItem className={styles.tableHeaderItem}>
              <Trans>Record on file</Trans>
            </Table.HeaderItem>
            <Table.HeaderItem className={styles.tableHeaderItem}>
              <Trans>Error/failure</Trans>
            </Table.HeaderItem>
          </Table.Header>

          <Table.Body>
            {errorList.map((error, i) => {
              const colorClass =
                error.type === 'failure'
                  ? styles.failureColor
                  : styles.warningColor

              return (
                <Table.Row key={`error_${i}`}>
                  <Table.Data className={styles.tableData}>
                    {isProfileImport ? (
                      <PersonIcon className={styles.profileIcon} />
                    ) : null}
                    {isProfileImport ? error.profile_name : error.product_name}
                    &nbsp;
                    {error.similar_error_count
                      ? t('and {{count}} others', {
                          count: error.similar_error_count
                        })
                      : null}
                  </Table.Data>
                  <Table.Data className={styles.tableData}>
                    <>
                      {error.errors.map((err, i) => (
                        <Text key={`error-${i}`} className={colorClass}>
                          - {err}
                        </Text>
                      ))}
                    </>
                  </Table.Data>
                </Table.Row>
              )
            })}
          </Table.Body>
        </Table>
      </Table.Container>

      <ReportErrorsFooter />
    </Grid>
  )
}

export default ReportErrorsSection

import { connect } from 'react-redux'

import actionTypes from 'apps/Courses/actions/types'
import CourseEditDisplayStatus from 'apps/Courses/components/CourseEditDisplayStatus'

const mapStateToProps = ({ editCourse: { data } }) => ({ data })

const mapDispatchToProps = (dispatch) => ({
  onEditClick: (course) =>
    dispatch({
      type: actionTypes.editStatusSectionData,
      payload: {
        state: course.state,
        state_auto_mode: course.state_auto_mode
      }
    })
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CourseEditDisplayStatus)

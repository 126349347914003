export const getUserDecisionKey = (userId) =>
  `evaluations:do_not_show_modal_again:${userId}`

export const shouldShowModalToUser = (userId) => {
  const userDoesntWantToSeeAgain = localStorage.getItem(
    getUserDecisionKey(userId)
  )

  if (
    userDoesntWantToSeeAgain !== 'false' &&
    userDoesntWantToSeeAgain !== 'true'
  ) {
    return true
  }

  return userDoesntWantToSeeAgain === 'false'
}

export const setUserDecision = (userId, userWantsToSkip) => {
  localStorage.setItem(getUserDecisionKey(userId), userWantsToSkip)
}

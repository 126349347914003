.react-app ._feedbackMessage_4mzya_1 {
  position: fixed;
  bottom: -150px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
  will-change: bottom;
  transition: bottom 0.7s ease-in-out;
}
.react-app ._feedbackMessage_4mzya_1._opened_4mzya_10 {
  bottom: 0;
}
import React from 'react'

import { ArrowLeftIcon, PageHead, Text } from '@fullfabric/alma-mater'
import { IfPermit } from '@fullfabric/authorization-officer'

import AddCoursesAction from 'apps/Applics/components/StaffSubjectSelection/AddCoursesAction'
import ChangePathAction from 'apps/Applics/components/StaffSubjectSelection/ChangePathAction'
import { useApplicData } from 'apps/Applics/contexts/StaffSubjectSelection/ApplicData'
import { useProfileData } from 'apps/Applics/contexts/StaffSubjectSelection/ProfileData'
import { useStudyPlanData } from 'apps/Applics/contexts/StaffSubjectSelection/StudyPlanData'

const SubjectSelectionPageHead = () => {
  const applic = useApplicData()
  const profile = useProfileData()
  const studyPlan = useStudyPlanData()

  return (
    <PageHead
      title={
        <>
          <Text type='f2' tag='span' fontColor='text-base-darker'>
            Subjects
          </Text>
          <Text type='h2' tag='span' fontColor='text-base-darkest'>
            {` ${profile?.first_name} ${profile?.last_name} - ${applic?.name}`}
          </Text>
        </>
      }
      action={
        <IfPermit to='applics.applications_change'>
          <AddCoursesAction applic={applic} studyPlan={studyPlan} />
          <ChangePathAction applic={applic} studyPlan={studyPlan} />
        </IfPermit>
      }
      breadcrumbs={[
        {
          label: 'Back to application',
          icon: ArrowLeftIcon,
          href: `/applications/${applic?.id}`,
          target: '_top'
        }
      ]}
    />
  )
}

export default SubjectSelectionPageHead

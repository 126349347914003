import React from 'react'
import { Trans } from 'react-i18next'

import { AnnotatedLayout } from '@fullfabric/alma-mater'

const CourseSectionStatus = ({ children }) => {
  return (
    <AnnotatedLayout.Section
      title={<Trans>Status</Trans>}
      description={
        <Trans>
          The status determines the availability of the course in application
          templates.
        </Trans>
      }
    >
      {children}
    </AnnotatedLayout.Section>
  )
}

export default CourseSectionStatus

import React from 'react'
import { Trans } from 'react-i18next'

import { Button } from '@fullfabric/alma-mater'

import styles from './styles.module.scss'

const LoadingActionsPlaceholder = () => (
  <Button primary loading className={styles.placeholder}>
    <Trans>Loading</Trans>
  </Button>
)

export default LoadingActionsPlaceholder

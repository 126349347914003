.react-app ._placeholderContainer_b4zlb_1 {
  position: relative;
  width: 100%;
}
.react-app ._placeholderContainer_b4zlb_1 > svg {
  width: 100%;
}

.react-app ._placeholderText_b4zlb_9 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: var(--space-small) var(--space-default);
  border-radius: 17px;
  border: var(--border-base-lighter);
  background: var(--ff-bg-base-lightest);
}
function getStaffByTeams(teams, staff) {
  return (teams || []).reduce((hashed, team) => {
    const teamId = team?.id

    if (!teamId) {
      return hashed
    }

    const staffsInTheTeam = (staff || []).filter(({ teams_ids: teamIds }) =>
      teamIds.includes(teamId)
    )

    return { ...hashed, [teamId]: staffsInTheTeam }
  }, {})
}

export default getStaffByTeams

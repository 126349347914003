import React from 'react'

import PropTypes from 'prop-types'

import { Text, useScreenClass } from '@fullfabric/alma-mater'

import useIsMobileNavigation from 'apps/ContentPages/hooks/use-is-mobile-navigation'
import Linkable from '../../linkable'

import classNames from 'classnames'
import styles from './styles.module.scss'

export default function SecondaryLink({ label, icon, ...linkableProps }) {
  const screenClass = useScreenClass()
  const isMobileNavigation = useIsMobileNavigation()
  const isTableNav = screenClass === 'md'

  return (
    <Linkable {...linkableProps}>
      <div
        className={classNames(
          styles.container,
          isMobileNavigation && styles.mobileNav,
          isTableNav && styles.tabletNav
        )}
      >
        <Text type='f4' fontColor='text-base-darkest' className={styles.label}>
          {label}
        </Text>

        {icon && (
          <div
            className={classNames(
              styles.iconWrapper,
              isMobileNavigation && styles.mobileNav
            )}
          >
            {icon}
          </div>
        )}
      </div>
    </Linkable>
  )
}

SecondaryLink.propTypes = {
  label: PropTypes.string,
  icon: PropTypes.node,
  ...Linkable.propTypes
}

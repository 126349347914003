import { utils } from '@fullfabric/public-api'

const getJourneysSchema = async () => {
  const response = await fetch('/api/institutions/schemas/journeys', {
    credentials: 'same-origin'
  })
  return await utils.checkResponse(response)
}

export default getJourneysSchema

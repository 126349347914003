import React from 'react'

import FormPageContent from 'apps/ContentPages/components/form-page-content'
import FormInfoTextContent from 'apps/ContentPages/components/form-page-text-content'
import FormTitle from 'apps/ContentPages/components/form-page-title'
import LandingPageHeader from 'apps/ContentPages/components/landing-page-header'
import LandingPageLogo from 'apps/ContentPages/components/landing-page-logo'
import PageLayout from 'apps/ContentPages/components/page-layout'

export default function FormWithContent() {
  return (
    <PageLayout
      type='action'
      title={
        <>
          <LandingPageHeader>
            <LandingPageLogo />
          </LandingPageHeader>

          <FormTitle />
        </>
      }
      widgets={<FormPageContent inCard />}
    >
      <FormInfoTextContent />
    </PageLayout>
  )
}

import 'css-chunk:src/apps/ContentPages/components/page-layout/background-layer/styles.module.scss';export default {
  "react-app": "react-app",
  "positioner": "_positioner_14gg7_1",
  "background": "_background_14gg7_7",
  "imageContainer": "_imageContainer_14gg7_19",
  "mobileLayout": "_mobileLayout_14gg7_27",
  "tabletLayout": "_tabletLayout_14gg7_33",
  "contentContainer": "_contentContainer_14gg7_40",
  "desktopLayout": "_desktopLayout_14gg7_41",
  "narrower": "_narrower_14gg7_61"
};
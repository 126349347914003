import * as React from 'react'
import { Trans } from 'react-i18next'

import {
  Button,
  Modal,
  ModalButtons,
  ModalTitle,
  Text
} from '@fullfabric/alma-mater'

const ExportConfirmationModal = ({ isOpen, handleClose }) => (
  <Modal
    role='dialog'
    aria-modal='true'
    aria-labelledby='export-confirmation-modal-title'
    size='small'
    isOpen={isOpen}
    header={
      <ModalTitle
        title={
          <span id='export-confirmation-modal-title'>
            <Trans>Export in progress</Trans>
          </span>
        }
        onClose={handleClose}
      />
    }
    footer={
      <ModalButtons>
        <Button primary onClick={handleClose}>
          <Trans>I understand</Trans>
        </Button>
      </ModalButtons>
    }
  >
    <Text>
      <Trans>
        You will receive an email once the export is ready. This can take some
        time for large files.
      </Trans>
    </Text>
  </Modal>
)

export default ExportConfirmationModal

import actionTypes from 'apps/Courses/actions/types'

const initialState = {
  data: [],
  loaded: false,
  loading: false
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.getSubjects.start:
      return { ...state, loading: true }
    case actionTypes.getSubjects.success:
      return { ...state, loading: false, loaded: true, data: action.payload }
    case actionTypes.newSubjectCreated:
      return { ...state, data: [...state.data, action.payload] }
    default:
      return state
  }
}

export default reducer

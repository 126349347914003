import { useMutation, useQueryClient } from 'react-query'

import { utils } from '@fullfabric/public-api'

export const useEmailTemplateUpdate = (id) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (newData) => {
      const response = await fetch(`/api/email_templates/${id}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(newData),
        credentials: 'same-origin'
      })
      return utils.checkResponse(response)
    },
    onSuccess: (savedData) => {
      queryClient.setQueryData(['emailTemplate', id], savedData)
    },
    enabled: Boolean(id)
  })
}

import React from 'react'
import { Trans } from 'react-i18next'

import { Hidden, Text } from '@fullfabric/alma-mater'

import styles from './styles.module.scss'

const Title = () => (
  <Hidden xs sm md>
    <Text
      className={styles.title}
      type='h6'
      fontColor='text-base-darker'
      marginBottom='small'
    >
      <Trans>Other Content</Trans>
    </Text>
  </Hidden>
)

export default Title

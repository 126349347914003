import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useSettings } from '@fullfabric/authorization-officer'

import createApplicTemplate from 'apps/Applics/api/createApplicTemplate'
import TemplateWizardNavigation from 'apps/Applics/components/TemplateWizardNavigation'
import WizardPage from 'apps/Applics/components/TemplateWizardPage'
import { useTemplateContext } from 'apps/Applics/contexts/TemplateContextContext'
import {
  useSetWizardData,
  useWizardData
} from 'apps/Applics/contexts/WizardDataContext'
import { useWizardNavigationState } from 'apps/Applics/contexts/WizardNavigationContext'
import ToggleableTab from './ToggleableTab'

export const submitTemplate = async (
  templateData,
  templateContext,
  setIsSubmitting
) => {
  try {
    setIsSubmitting(true)
    const { parentContextId, context: contextType, contextId } = templateContext
    const submittableContext = contextType
      ? {
          context_type: contextType,
          context_id: contextId
        }
      : {}

    const createdTemplate = await createApplicTemplate({
      ...templateData,
      ...submittableContext
    })

    const templateDetailsUrl =
      contextType === 'Institutions::ClassOf'
        ? `/institutions/settings#programmes/${parentContextId}/classes/${contextId}/applic-templates/${createdTemplate.id}/details`
        : `/institutions/settings#applic-templates/${createdTemplate.id}/details`

    window.location.assign(templateDetailsUrl)
  } catch (e) {
    setIsSubmitting(false)
  }
}

const TemplateTabsWizardPage = () => {
  const { t } = useTranslation()

  const {
    hash: { edition }
  } = useSettings()

  const templateContext = useTemplateContext()
  const templateData = useWizardData()
  const setWizardData = useSetWizardData()
  const { currentStep } = useWizardNavigationState()

  const [isSubmitting, setIsSubmitting] = useState()

  return (
    <WizardPage
      title={t('Capture the right information.')}
      subtitle={t('Your application form tabs')}
      description={t(
        'Personalise and collect special types of information with dedicated tabs. You can change these tabs at any time.'
      )}
    >
      <ToggleableTab
        name={t('Customisable tabs')}
        description={t(
          'Add tabs with sections and fields of your choice. “Personal” and “Application” tabs will be created by default which you can rename or delete.'
        )}
        alwaysActive
      />
      <ToggleableTab
        name={t('Experience')}
        description={t(
          'A special tab which allows applicants to create entries for their professional and academic experience.'
        )}
        isActive={templateData.enable_experience}
        onChange={() => {
          setWizardData((data) => ({
            ...data,
            enable_experience: !data.enable_experience
          }))
        }}
      />
      <ToggleableTab
        name={t('References')}
        description={t(
          'A special tab from which applicants can request their referees to submit a reference for them through a form.'
        )}
        isActive={templateData.references_enabled}
        onChange={() => {
          setWizardData((data) => ({
            ...data,
            references_enabled: !data.references_enabled
          }))
        }}
      />
      <ToggleableTab
        name={t('Payments')}
        description={t(
          'Collect payments for an application fee from trusted secure payment platforms such as Paypal and Stripe.'
        )}
        isActive={templateData.payment_enabled}
        isPremiumExclusive={edition === 'starter'}
        onChange={
          edition !== 'starter'
            ? () => {
                setWizardData((data) => ({
                  ...data,
                  payment_enabled: !data.payment_enabled
                }))
              }
            : undefined
        }
      />
      <TemplateWizardNavigation
        isSubmitting={isSubmitting}
        onNext={() =>
          submitTemplate(templateData, templateContext, setIsSubmitting)
        }
        previousStep={currentStep - 1}
      />
    </WizardPage>
  )
}

export default TemplateTabsWizardPage

import { utils } from '@fullfabric/public-api'

const getExternalPayerRequest = async (externalPayerRequestId) => {
  const response = await fetch(
    `/book_keeper/api/external_payer_requests/${externalPayerRequestId}`,
    {
      credentials: 'same-origin'
    }
  )

  return await utils.checkResponse(response)
}

export default getExternalPayerRequest

import qs from 'qs'

import { utils } from '@fullfabric/public-api'

const getCoursesForStudyPlan = async (_key, studyPlanId) => {
  const requestParams = qs.stringify({
    query: { courses_for_study_plan: studyPlanId }
  })
  const response = await fetch(`/api/courses?${requestParams}`, {
    credentials: 'same-origin'
  })

  return await utils.checkResponse(response)
}

export default getCoursesForStudyPlan

import React from 'react'

import { Text } from '@fullfabric/alma-mater'

import { useTemplate } from '../template-info-context'

const TemplateName = () => {
  const {
    name,
    context_name: contextName,
    context_type: contextType
  } = useTemplate()

  const nameOfClass =
    contextType === 'Institutions::ClassOf' ? contextName : null

  return (
    <>
      <Text type='h4' fontColor='text-base-darkest'>
        {nameOfClass || name}
      </Text>

      {nameOfClass && (
        <Text data-testid='template-name-for-class' type='f5' marginTop='tiny'>
          {name}
        </Text>
      )}
    </>
  )
}

export default TemplateName

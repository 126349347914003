import { utils } from '@fullfabric/public-api'

const submitEvaluation = async (evaluationId) => {
  const response = await fetch(
    `/evaluations/api/evaluations/${evaluationId}/submit`,
    {
      credentials: 'same-origin',
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' }
    }
  )

  return await utils.checkResponse(response)
}

export default submitEvaluation

.react-app ._itemButton_ihuue_1 {
  border: none;
  background: none;
  cursor: pointer;
}
.react-app ._itemButton_ihuue_1._selected_ihuue_6 {
  background: var(--ff-bg-primary-darker);
  border: var(--border-primary-darker);
  border-radius: 14px;
  color: var(--ff-color-text-base-lightest);
}
import React from 'react'
import { Trans } from 'react-i18next'

import { Button } from '@fullfabric/alma-mater'

import CardContent from 'apps/StudyPlans/components/CardContent'
import CardInfo from 'apps/StudyPlans/components/CardInfo'
import { useStudyPlan } from 'apps/StudyPlans/contexts/StudyPlanData'
import { useSubjectEditionData } from 'apps/StudyPlans/contexts/SubjectEditionData'
import { useSetSubjectSectionsBeingEdited } from 'apps/StudyPlans/contexts/SubjectSectionBeingEdited'
import { subjectTypeOptionsForPath } from 'apps/StudyPlans/utils/subjectTypesValues'

const SubjectPathsSectionDisplay = () => {
  const studyPlan = useStudyPlan()
  const data = useSubjectEditionData()
  const setSubjectBeingEdited = useSetSubjectSectionsBeingEdited()
  const onClickEdit = React.useCallback(() => {
    setSubjectBeingEdited((sections) => ({ ...sections, paths: true }))
  }, [setSubjectBeingEdited])

  const paths = studyPlan.paths

  return (
    <CardContent>
      {paths.map((path) => {
        const typeForPath = (data.course_types || []).filter(
          (courseTypeForPath) => courseTypeForPath.path_id === path.id
        )[0]

        const courseTypeOption =
          typeForPath &&
          subjectTypeOptionsForPath(path).filter(
            (opt) => opt.value === typeForPath.group_id
          )[0]

        const courseTypeLabel = courseTypeOption && courseTypeOption.label

        return typeForPath ? (
          <CardInfo
            key={path.id}
            title={<Trans>Course type</Trans>}
            main={courseTypeLabel}
            secondary={path.name}
            containerClassName='marginBottom-default'
          />
        ) : null
      })}

      <div className='marginTop-default'>
        <Button size='small' onClick={() => onClickEdit(data)}>
          <Trans>Edit</Trans>
        </Button>
      </div>
    </CardContent>
  )
}
export default SubjectPathsSectionDisplay

import React from 'react'

import { Dropdown } from '@fullfabric/alma-mater'

const SelectColumnsMenu = ({
  onSearchChange,
  sectionHash,
  isSingleUntitledSection,
  resetSearch
}) => {
  React.useEffect(() => {
    resetSearch()
  }, [resetSearch])

  return (
    <React.Fragment>
      <Dropdown.Menu.Search onSearchChange={onSearchChange} />
      {Object.keys(sectionHash).map((sectionName) => {
        const options = sectionHash[sectionName].map((column) => (
          <Dropdown.Menu.CheckableOption
            key={column.id}
            role='listitem'
            id={column.id}
            checked={column.isVisible}
            onChange={() => column.toggleHidden()}
          >
            {column.Header}
          </Dropdown.Menu.CheckableOption>
        ))

        return isSingleUntitledSection ? (
          options
        ) : (
          <React.Fragment key={sectionName}>
            <Dropdown.Menu.OptionGroup title={sectionName}>
              {options}
            </Dropdown.Menu.OptionGroup>
          </React.Fragment>
        )
      })}
    </React.Fragment>
  )
}

export default SelectColumnsMenu

import moment from 'moment'

import { Field } from '@fullfabric/alma-mater'

export function getInvalidDateError(value, t) {
  return !value || Field.isValidDate({ value })
    ? null
    : t('Invalid date or date format - please use dd/mm/yyyy')
}

export function areDatesCompatible(startDate, endDate) {
  const startDateMoment = moment(startDate)
  const endDateMoment = moment(endDate)

  if (
    !startDate ||
    !endDate ||
    !startDateMoment.isValid() ||
    !endDateMoment.isValid()
  ) {
    // needs both dates to be validated
    // if the one date is not valid, skip this validation, let the user know the other date is invalid
    return true
  }

  return startDateMoment.isBefore(endDateMoment)
}

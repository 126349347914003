.react-app ._appContainer_11f2o_1 {
  display: flex;
  flex-flow: row nowrap;
  height: 100%;
}

.react-app ._contentRegionContainer_11f2o_7 {
  height: 100%;
  flex-grow: 1;
}

.react-app ._container_11f2o_12 {
  height: 100%;
}

.react-app ._contentContainer_11f2o_16 {
  margin-top: 104px;
  margin-top: calc(var(--space-huge) + var(--space-big));
}

.react-app ._scrollContainer_11f2o_21 {
  height: 100%;
  overflow: scroll;
  -ms-overflow-style: none;
}
.react-app ._scrollContainer_11f2o_21::-webkit-scrollbar {
  display: none;
}
.react-app ._scrollContainer_11f2o_21::-moz-scrollbar {
  display: none;
}
import React from 'react'
import { Trans } from 'react-i18next'

import { SuccessIcon, Text } from '@fullfabric/alma-mater'

import styles from './styles.module.scss'

const SuccessMessage = () => (
  <>
    <div className={styles.titleContainer}>
      <SuccessIcon color='text-success' className={styles.icon} />
      <Text
        tag='h2'
        type='h3'
        fontColor='text-success'
        marginLeft='small'
        className={styles.title}
      >
        <Trans>Thank you for filling in the form.</Trans>
      </Text>
    </div>
    <Text type='f4' fontColor='text-base-darkest' marginTop='small'>
      <Trans>You will receive an email shortly.</Trans>
    </Text>
  </>
)

export default SuccessMessage

import React from 'react'
import { Trans } from 'react-i18next'

import { Button } from '@fullfabric/alma-mater'

import { useTypeOfWidget } from '../../type-of-widget-context'
import { useStartApplicationProcess } from '../start-application-state-context'

const StartApplication = ({ hasSubmittedApplications, template, contexts }) => {
  const startApplicationProcess = useStartApplicationProcess(contexts)
  const { isRegistration } = useTypeOfWidget()
  const canStartMultipleApplications =
    template.multiple && template.context_type === 'Institutions::Institution'

  let label = null

  if (!hasSubmittedApplications) {
    label = getStartLabel(isRegistration)
  }

  if (hasSubmittedApplications && canStartMultipleApplications) {
    label = getStartAnotherLabel(isRegistration)
  }

  if (!label) {
    // cases leading to an empty label mean that it's not possible to start an
    // application
    return null
  }

  const handleClick = startApplicationProcess // either opens modal or redirects to application page

  return (
    <Button
      primary
      onClick={() => handleClick(template.id)}
      marginTop='default'
    >
      {label}
    </Button>
  )
}

const getStartLabel = (isRegistration) =>
  isRegistration ? (
    <Trans>Start registration</Trans>
  ) : (
    <Trans>Start application</Trans>
  )

const getStartAnotherLabel = (isRegistration) =>
  isRegistration ? (
    <Trans>Start another registration</Trans>
  ) : (
    <Trans>Start another application</Trans>
  )

export default StartApplication

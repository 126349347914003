import React from 'react'
import { Trans } from 'react-i18next'

import { Label, Text } from '@fullfabric/alma-mater'

import onPressEnter from 'apps/ContentPages/utils/on-press-enter'
import ArrowRightIcon from './arrow-right-icon'
import { goToApplic } from './utils/applic-row-utilities'

import classNames from 'classnames'
import styles from './styles.module.scss'

const ApplicationRowSmall = ({ applic, isFirst, isLast }) => (
  <div
    className={classNames([styles.rowSmall, isLast && styles.last])}
    onClick={() => goToApplic(applic)}
    onKeyPress={onPressEnter(() => goToApplic(applic))}
    role='button'
    tabIndex='0'
  >
    <div className={classNames([styles.contentCol, isFirst && styles.first])}>
      <Text tag='p' type='h5' fontColor='text-base-darkest'>
        {applic.full_name}
      </Text>
      {applic.state === 'started' && (
        <Label color='purple-500' marginTop='small'>
          <Trans>In Progress</Trans>
        </Label>
      )}
      {applic.state === 'submitted' && (
        <Label outline color='green-700' marginTop='small'>
          <Trans>Submitted</Trans>
        </Label>
      )}
      <div className={styles.classOfIdsRow}>
        {applic.primary_choice_contexts.map((context) => (
          <Text tag='div' type='f5' fontColor='text-base-darkest'>
            {context.context_long_name}
          </Text>
        ))}
      </div>
      <div className={styles.subTitle}>
        <Text tag='div' type='f5' fontColor='text-base-darker'>
          {applic.template_name}
        </Text>
      </div>
    </div>
    <div className={styles.arrowCol}>
      <ArrowRightIcon />
    </div>
  </div>
)

export default ApplicationRowSmall

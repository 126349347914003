import React from 'react'
import { useParams } from 'react-router'

import ContractSettingsPageComponent from 'apps/Billing/components/ContractSettingsPage'

const ContractSettingsPage = () => {
  const { contractId } = useParams()

  return <ContractSettingsPageComponent contractId={contractId} />
}

export default ContractSettingsPage

/* eslint-disable camelcase */

import React from 'react'

import { useAppData } from 'shared/contexts/AppData'

export const StudyPlanDataContext = React.createContext()

export const StudyPlanDataProvider = ({ children }) => {
  const appData = useAppData()
  const [studyPlan, setStudyPlan] = React.useState(() => appData?.study_plan)

  return (
    <StudyPlanDataContext.Provider value={{ studyPlan, setStudyPlan }}>
      {children}
    </StudyPlanDataContext.Provider>
  )
}

export const useStudyPlan = () => {
  const { studyPlan } = React.useContext(StudyPlanDataContext)

  return studyPlan || {}
}

export const useSetStudyPlan = () => {
  const { setStudyPlan } = React.useContext(StudyPlanDataContext)

  return setStudyPlan || {}
}

export const useClassOf = () => {
  const { class_of } = useAppData()

  return class_of || {}
}

export const useAllSubjects = () => {
  const { courses } = useAppData()

  return courses || {}
}

export const useCourseTypes = () => {
  const { course_types } = useAppData()

  return course_types || {}
}

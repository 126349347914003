import React from 'react'

import moment from 'moment'

import { EditIcon, EyeIcon, Label } from '@fullfabric/alma-mater'

const InboxEmailCardState = ({
  lastSeenBy,
  lastSeenAt,
  beingRepliedBy,
  beingRepliedAt
}) => {
  const emailBeingReplied =
    beingRepliedAt &&
    moment().subtract(5, 'minutes').isBefore(moment(beingRepliedAt))
  const emailBeingViewed =
    !emailBeingReplied &&
    lastSeenAt &&
    moment().subtract(5, 'minutes').isBefore(moment(lastSeenAt))

  return (
    <>
      {emailBeingViewed && (
        <Label icon marginLeft='small'>
          <EyeIcon />
          {lastSeenBy.full_name}
        </Label>
      )}
      {emailBeingReplied && (
        <Label icon marginLeft='small'>
          <EditIcon />
          {beingRepliedBy.full_name}
        </Label>
      )}
    </>
  )
}

export default InboxEmailCardState

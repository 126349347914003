.react-app ._section_19gs8_1 {
  margin-bottom: var(--space-default);
}

.react-app ._section_19gs8_1._withFilterRow_19gs8_5 {
  margin-bottom: var(--space-small);
}

.react-app ._inBetweenSpacing_19gs8_9 {
  margin-bottom: var(--space-small);
}
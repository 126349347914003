import React from 'react'
import { useTranslation } from 'react-i18next'

import { Text } from '@fullfabric/alma-mater'

import styles from './styles.module.scss'

function Placeholder() {
  const { t } = useTranslation()

  return (
    <div className={styles.placeholderContainer}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='458'
        height='74'
        fill='none'
        viewBox='0 0 458 74'
        preserveAspectRatio='none'
      >
        <rect width='24' height='24' fill='#EDF0F3' rx='2'></rect>
        <rect width='76' height='24' x='28' fill='#EDF0F3' rx='2'></rect>
        <rect width='12' height='24' x='108' fill='#EDF0F3' rx='2'></rect>
        <rect width='24' height='24' x='434' fill='#EDF0F3' rx='2'></rect>
        <rect width='9' height='9' y='40' fill='#EDF0F3' rx='2'></rect>
        <rect width='35' height='9' x='13' y='40' fill='#EDF0F3' rx='2'></rect>
        <rect width='57' height='9' x='52' y='40' fill='#EDF0F3' rx='2'></rect>
        <rect width='32' height='9' x='113' y='40' fill='#EDF0F3' rx='2'></rect>
        <rect width='9' height='9' x='374' y='40' fill='#EDF0F3' rx='2'></rect>
        <rect width='35' height='9' x='387' y='40' fill='#EDF0F3' rx='2'></rect>
        <rect width='32' height='9' x='426' y='40' fill='#EDF0F3' rx='2'></rect>
        <rect width='35' height='9' y='65' fill='#EDF0F3' rx='2'></rect>
        <rect width='37' height='9' x='39' y='65' fill='#EDF0F3' rx='2'></rect>
        <rect width='32' height='9' x='80' y='65' fill='#EDF0F3' rx='2'></rect>
        <rect width='9' height='9' x='116' y='65' fill='#EDF0F3' rx='2'></rect>
        <rect width='9' height='9' x='374' y='65' fill='#EDF0F3' rx='2'></rect>
        <rect width='35' height='9' x='387' y='65' fill='#EDF0F3' rx='2'></rect>
        <rect width='32' height='9' x='426' y='65' fill='#EDF0F3' rx='2'></rect>
      </svg>
      <Text tag='span' type='h6' className={styles.placeholderText}>
        {t('No data available')}
      </Text>
    </div>
  )
}

export default Placeholder

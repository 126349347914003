import React from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '@fullfabric/alma-mater'
import { triggerSubmitFormEvent as triggerSubmitEventInEvaluationForm } from '@fullfabric/schemable-forms'

import { useEvaluation } from 'apps/Evaluations/context/EvaluationData'

const SubmitEvaluationButton = ({ formRef }) => {
  const { isLoadingEvaluationData, canChangeEvaluation } = useEvaluation()
  const { t } = useTranslation()

  const onClickSubmitEvaluation = () => {
    triggerSubmitEventInEvaluationForm(formRef)
  }

  if (!canChangeEvaluation) return null

  return (
    <Button
      primary
      size='small'
      marginRight='more'
      loading={isLoadingEvaluationData}
      onClick={onClickSubmitEvaluation}
    >
      {t('Submit evaluation')}
    </Button>
  )
}

export default SubmitEvaluationButton

{
  "A word by itself is easy to guess": "Una parola sola è facile da indovinare",
  "Add \"{{val}}\"": "Aggiungi \"{{val}}\"",
  "Add another word or two. Uncommon words are better.": "Aggiungi un'altra parola o due. Meglio se le parole sono non comuni.",
  "All-uppercase is almost as easy to guess as all-lowercase": "Il tutto maiuscolo è quasi facile da indovinare come il tutto minuscolo",
  "Almost there": "Ci sei quasi",
  "Already have an account?": "Hai già un account?",
  "Avoid dates and years that are associated with you": "Evita date e anni che sono associati a te",
  "Avoid recent years": "Evita gli anni recenti",
  "Avoid repeated words and characters": "Evita parole e caratteri ripetuti",
  "Avoid sequences": "Evita le sequenze",
  "Avoid years that are associated with you": "Evita gli anni che sono associati a te",
  "Back to login": "Torna all'accesso",
  "Cancel": "Annulla",
  "Capitalization doesn't help very much": "Le maiuscole non aiutano molto",
  "Common names and surnames are easy to guess": "Nomi e cognomi comuni sono facili da indovinare",
  "Continue": "Continua",
  "Create an account": "Creare un account",
  "Create new account": "Crea un nuovo account",
  "Data Protection Regulation": "Regolamento sulla protezione dei dati",
  "Dates are often easy to guess": "Le date sono spesso facili da indovinare",
  "Didn't receive the sms?": "Non hai ricevuto l'SMS?",
  "E-mail address": "Indirizzo e-mail",
  "First name": "Nome",
  "For security reasons account activation links are perishable. You can get a new activation link by filling the sign up form.": "Per motivi di sicurezza i link di attivazione dell'account sono scaduti. Puoi ottenere un nuovo link di attivazione compilando il modulo di registrazione.",
  "Forgot your password?": "Hai dimenticato la password?",
  "Great, your account has been created!": "Perfetto, il tuo account è stato creato!",
  "I accept the privacy policy": "Accetto l'Informativa sulla privacy",
  "Institutional login": "Accesso istituzionale",
  "Last name": "Cognome",
  "Link existing account": "Collega l'account esistente",
  "LinkedIn": "LinkedIn",
  "Log in": "Accedi",
  "Log in securely": "Accedi in modo sicuro",
  "Names and surnames by themselves are easy to guess": "I nomi e i cognomi sono facili da indovinare.",
  "New Password": "Nuova password",
  "No Results": "Nessun risultato",
  "No need for symbols, digits, or uppercase letters": "Non c'è bisogno di simboli, cifre o lettere maiuscole",
  "Not registered?": "Non sei registrato?",
  "Nothing found for": "Nulla di trovato per",
  "Or use these alternatives": "Oppure usa queste alternative",
  "Password": "Password",
  "Password Reset": "Reimpostazione della password",
  "Please accept the privacy policy to continue": "Accetta l'Informativa sulla privacy per continuare",
  "Please check your email for instructions on resetting your password": "Controlla la tua e-mail per le istruzioni sulla reimpostazione della password",
  "Please check your mailbox for instructions on verifying your registration.": "Controlla la tua casella di posta per le istruzioni sulla verifica della registrazione.",
  "Please choose a password": "Scegli una password",
  "Please confirm your account details below": "Conferma i dati del tuo account qui sotto",
  "Please contact your university's IT support department in order to get a new activation link to access Full Fabric.": "Contatta il servizio di assistenza informatica della tua università per ottenere un nuovo link di attivazione e accedere a Full Fabric.",
  "Please type a new password.": "Digita una nuova password.",
  "Predictable substitutions like \"@\" instead of \"a\" don't help very much": "Sostituzioni prevedibili come \"@\" invece di \"a\" non aiutano molto",
  "Predictable substitutions like '@' instead of 'a' don't help very much": "Sostituzioni prevedibili come \"@\" invece di \"a\" non aiutano molto",
  "Privacy policy": "Informativa sulla privacy",
  "Recent years are easy to guess": "Gli anni recenti sono facili da indovinare",
  "Repeats like \"aaa\" are easy to guess": "Ripetizioni come \"aaa\" sono facili da indovinare",
  "Repeats like \"abcabcabc\" are only slightly harder to guess than \"abc\"": "Ripetizioni come \"abcabcabc\" sono solo leggermente più difficili da indovinare rispetto a \"abc\"",
  "Reset password": "Reimposta la password",
  "Reset password and sign in": "Reimposta la password e accedi",
  "Reset your password below.": "Reimposta la password qui sotto.",
  "Return to log in page": "Torna alla pagina di accesso",
  "Reversed words aren't much harder to guess": "Le parole al contrario non sono molto più difficili da indovinare",
  "Send again": "Invia di nuovo",
  "Sequences like abc or 6543 are easy to guess": "Sequenze come abc o 6543 sono facili da indovinare",
  "Set password and sign in": "Imposta la password e accedi",
  "Short keyboard patterns are easy to guess": "Le brevi sequenza di tasti sono facili da indovinare",
  "Sign in": "Accedi",
  "Sign up": "Registrati",
  "Skip to main content": "Passa al contenuto principale",
  "Straight rows of keys are easy to guess": "Le combinazioni di tasti consecutivi sono facili da indovinare",
  "Suggestions": "Suggerimenti",
  "Thank you.": "Grazie.",
  "There was a server problem. Please try again.": "Si è verificato un problema con il server. Per favore, riprova.",
  "This is a top-10 common password": "Questa è una fra le prime 10 password più usate",
  "This is a top-100 common password": "Questa è una fra le prime 100 password più usate",
  "This is a very common password": "Questa è una password molto usata",
  "This is similar to a commonly used password": "Questa è simile a una password molto usata",
  "Two Factor Authentication Token": "Token di autenticazione a due fattori",
  "Two step verification": "Verifica in due passaggi",
  "Use a few words, avoid common phrases": "Usa poche parole, evita le frasi comuni",
  "Use a longer keyboard pattern with more turns": "Usa una sequenza di tasti più lunga con più svolte",
  "Use one of these alternatives to signup": "Usa una di queste alternative per registrarti",
  "Use one of these methods to log in": "Usa uno di questi metodi per accedere",
  "We sent a sms to the number <1>{{phoneNumber}}</1> with the verification code": "Abbiamo inviato un sms al numero <1>{{phoneNumber}}</1> con il codice di verifica",
  "Welcome": "Benvenuto",
  "Your activation link has expired.": "Il tuo link di attivazione è scaduto.",
  "Your password has expired.": "La tua password è scaduta.",
  "Your password is good": "La tua password è buona",
  "Your password is reasonably adequate": "La tua password è ragionevolmente adeguata",
  "Your password is too weak": "La tua password è troppo debole",
  "or e-mail": "o e-mail",
  "or link an existing account": "o collega un account esistente",
  "{{minAlpha}} letters": "{{minAlpha}} lettera/e",
  "{{minDigits}} digits": "{{minDigits}} cifra/e",
  "{{minLength}} characters": "{{minLength}} caratteri",
  "{{minSymbols}} symbols": "{{minSymbols}} simbolo/i",
  "Password must be at least {{minLength}} characters long": "La password deve contenere almeno {{minLength}} carattere",
  "Password must have at least {{count}} characters": "La password deve contenere almeno {{count}} caratteri",
  "Password must have at least {{count}} characters_plural": "La password deve contenere almeno {{count}} caratteri",
  "Password must have at least {{count}} digit": "La password deve contenere almeno {{count}} cifra",
  "Password must have at least {{count}} digit_plural": "La password deve contenere almeno {{count}} cifre",
  "Password must have at least {{count}} symbol": "La password deve contenere almeno {{count}} simbolo",
  "Password must have at least {{count}} symbol_plural": "La password deve contenere almeno {{count}} simboli",
  "Password was already used": "La password è stata già utilizzata",
  "Thank you. Please log in below.": "Grazie. Effettua l'accesso qui sotto.",
  "This field is required": "Questo campo è obbligatorio",
  "Privacy Policy": "Informativa sulla privacy",
  "Link to an existing account": "Collega a un account esistente",
  "There are fields with errors.": "Ci sono campi con errori.",
  "Please correct the fields with errors.": "Correggi i campi con errori.",
  "We sent a sms to the number {{ phoneNumber }} with the verification code": "Abbiamo inviato un sms al numero {{phoneNumber}} con il codice di verifica"
}

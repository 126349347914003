import React from 'react'
import { useQuery } from 'react-query'

import { OneColumnLayout } from '@fullfabric/alma-mater'
import { useCurrentUser } from '@fullfabric/authorization-officer'

import getClassOf from 'apps/Reports/api/getClassOf'
import getProgrammeClasses from 'apps/Reports/api/getProgrammeClasses'
import ProductOverviewReport from 'apps/Reports/components/ProductOverviewReport'
import validateFilter from 'apps/Reports/utils/validateFilter'
import FixedClassFilter from './FixedClassFilter'
import Head from './Head'
import mergeImplicitFilter from './helpers/mergeImplicitFilter'

import styles from './styles.module.scss'

const FILTER_STORAGE_KEY = 'class_overview:filter:'
const INITIAL_FILTER = {
  states: ['prospect::started_application']
}

function FixedClassOverviewPage({ baseClassId }) {
  const user = useCurrentUser()
  const { data: classData } = useQuery(['getClassOf', baseClassId], () =>
    getClassOf(baseClassId)
  )
  const { data: allClassesData } = useQuery(
    ['getProgrammeClasses', baseClassId],
    () => getProgrammeClasses(classData?.programme_id),
    { enabled: !!classData }
  )
  const selectableClassesData = (allClassesData || []).filter(
    (aClass) => aClass.id !== baseClassId
  )

  // in addition to the visible filter, we have to filter the results with
  // the base class taken into account. Thus, there's the filter visible to
  // the user, and the filter we actually use for the requests
  const [visibleFilter, setVisibleFilter] = React.useState(() => {
    const storedFilter = validateFilter(
      FILTER_STORAGE_KEY + baseClassId + user.id,
      ['classes', 'states', 'withdrawn'],
      INITIAL_FILTER
    )

    return storedFilter || INITIAL_FILTER
  })
  const [filterInEffect, setFilterInEffect] = React.useState(
    mergeImplicitFilter(baseClassId, visibleFilter)
  )
  const onFilterChange = React.useCallback(
    (selectedFilter) => {
      setVisibleFilter(selectedFilter)
      localStorage.setItem(
        FILTER_STORAGE_KEY + baseClassId + user.id,
        JSON.stringify(selectedFilter || INITIAL_FILTER)
      )

      setFilterInEffect(mergeImplicitFilter(baseClassId, selectedFilter))
    },
    [user.id, baseClassId]
  )

  return (
    <OneColumnLayout
      pageHead={<Head classData={classData} />}
      className={styles.pageSpacing}
    >
      <FixedClassFilter
        baseClassId={baseClassId}
        filter={visibleFilter}
        onFilterChange={onFilterChange}
        selectableClasses={selectableClassesData}
      />
      <ProductOverviewReport
        filter={filterInEffect}
        baseClass={classData || {}}
        classOptions={allClassesData || []}
      />
    </OneColumnLayout>
  )
}

export default FixedClassOverviewPage

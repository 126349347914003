import moment from 'moment-timezone'

const getDateInUserTimezone = (date, timezone, locale) => {
  return date
    ? moment(date)
        .tz(timezone || moment.tz.guess())
        .locale(locale)
    : null
}

export default getDateInUserTimezone

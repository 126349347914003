import { utils } from '@fullfabric/public-api'

const addExternalPayerDiscount = async ({ code, externalPayerRequestId }) => {
  const response = await fetch(
    `/book_keeper/api/external_payer_requests/${externalPayerRequestId}/create_payable_discount`,
    {
      credentials: 'same-origin',
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      body: JSON.stringify({ code })
    }
  )

  return await utils.checkResponse(response)
}

export default addExternalPayerDiscount

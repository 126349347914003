import React from 'react'

function ShortcutIcons() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='77'
      height='23'
      fill='none'
      viewBox='0 0 77 23'
    >
      <g filter='url(#filter0_d_344_2820)'>
        <rect width='52' height='20' x='1' y='1' fill='#fff' rx='4'></rect>
        <path
          fill='#9399A2'
          d='M10.523 15.047c-.697 0-1.054-.404-1.054-1.055v-1.98H7.676c-.41 0-.739-.252-.739-.639 0-.24.106-.398.305-.598l4.055-4.043c.17-.164.369-.275.592-.275.222 0 .421.111.591.275l4.061 4.043c.182.188.299.358.299.598 0 .387-.328.639-.738.639h-1.793v1.98c0 .65-.358 1.055-1.055 1.055h-2.73zm.088-.815h2.555a.255.255 0 00.264-.263v-2.59c0-.111.047-.158.164-.158h2.086c.017 0 .035-.012.035-.03 0-.011-.012-.023-.024-.04l-3.744-3.71a.075.075 0 00-.058-.023.084.084 0 00-.059.023l-3.744 3.71c-.012.017-.018.029-.018.04 0 .018.012.03.036.03h2.08c.117 0 .164.047.164.158v2.59c0 .158.111.263.263.263zm14.391.967c-1.852 0-3.047-.972-3.17-2.35l-.006-.064h1.055l.006.065c.076.843.955 1.377 2.174 1.377 1.148 0 1.986-.592 1.986-1.454v-.005c0-.704-.486-1.184-1.647-1.442l-.937-.205c-1.694-.375-2.426-1.154-2.426-2.379v-.006c.006-1.4 1.23-2.39 2.977-2.39 1.687 0 2.865.996 2.953 2.256l.006.082h-1.055l-.012-.077c-.117-.744-.82-1.294-1.922-1.289-1.054.006-1.869.504-1.869 1.389v.006c0 .674.463 1.13 1.612 1.383l.937.21c1.764.393 2.461 1.102 2.461 2.321v.006c0 1.582-1.236 2.566-3.123 2.566zm4.81-.199V6.176h1.02v3.457h.094c.316-.668.908-1.06 1.84-1.06 1.418 0 2.209.837 2.209 2.337V15h-1.02v-3.844c0-1.136-.469-1.681-1.477-1.681-1.007 0-1.646.68-1.646 1.787V15h-1.02zm7.547-7.535a.705.705 0 01-.703-.703c0-.387.317-.703.703-.703.387 0 .703.316.703.703a.705.705 0 01-.703.703zM36.844 15V8.684h1.02V15h-1.02zm3.363 0V9.527h-1.049v-.843h1.049V7.98c0-1.26.633-1.863 1.834-1.863.246 0 .469.018.68.059v.814a2.774 2.774 0 00-.475-.03c-.727 0-1.02.358-1.02 1.05v.674h1.436v.843h-1.435V15h-1.02zm6.17.047c-1.3 0-1.846-.48-1.846-1.688V9.527h-.996v-.843h.996V7.049h1.055v1.635h1.383v.843h-1.383v3.575c0 .744.258 1.043.914 1.043.182 0 .281-.006.469-.024v.867c-.2.035-.393.059-.592.059z'
        ></path>
        <rect
          width='53'
          height='21'
          x='0.5'
          y='0.5'
          stroke='#D8DDE2'
          rx='4.5'
        ></rect>
      </g>
      <g filter='url(#filter1_d_344_2820)'>
        <rect width='19' height='20' x='57' y='1' fill='#fff' rx='4'></rect>
        <path
          fill='#9399A2'
          d='M62.404 15l3.117-8.455h1.043L69.682 15h-1.108l-.85-2.42h-3.363l-.85 2.42h-1.107zm3.592-7.09l-1.324 3.774h2.742L66.09 7.91h-.094z'
        ></path>
        <rect
          width='20'
          height='21'
          x='56.5'
          y='0.5'
          stroke='#D8DDE2'
          rx='4.5'
        ></rect>
      </g>
      <defs>
        <filter
          id='filter0_d_344_2820'
          width='54'
          height='23'
          x='0'
          y='0'
          colorInterpolationFilters='sRGB'
          filterUnits='userSpaceOnUse'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix'></feFlood>
          <feColorMatrix
            in='SourceAlpha'
            result='hardAlpha'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          ></feColorMatrix>
          <feOffset dy='1'></feOffset>
          <feColorMatrix values='0 0 0 0 0.126 0 0 0 0 0.1302 0 0 0 0 0.154 0 0 0 0.1 0'></feColorMatrix>
          <feBlend
            in2='BackgroundImageFix'
            result='effect1_dropShadow_344_2820'
          ></feBlend>
          <feBlend
            in='SourceGraphic'
            in2='effect1_dropShadow_344_2820'
            result='shape'
          ></feBlend>
        </filter>
        <filter
          id='filter1_d_344_2820'
          width='21'
          height='23'
          x='56'
          y='0'
          colorInterpolationFilters='sRGB'
          filterUnits='userSpaceOnUse'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix'></feFlood>
          <feColorMatrix
            in='SourceAlpha'
            result='hardAlpha'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          ></feColorMatrix>
          <feOffset dy='1'></feOffset>
          <feColorMatrix values='0 0 0 0 0.126 0 0 0 0 0.1302 0 0 0 0 0.154 0 0 0 0.1 0'></feColorMatrix>
          <feBlend
            in2='BackgroundImageFix'
            result='effect1_dropShadow_344_2820'
          ></feBlend>
          <feBlend
            in='SourceGraphic'
            in2='effect1_dropShadow_344_2820'
            result='shape'
          ></feBlend>
        </filter>
      </defs>
    </svg>
  )
}

export default ShortcutIcons

import React from 'react'
import { Trans } from 'react-i18next'

import SubjectsSection from 'apps/StudyPlans/components/SubjectGroupsList/SubjectsSection'
import { useCoreSubjectsForSelectedPath } from 'apps/StudyPlans/contexts/SelectedPath'

const CoreSubjectsSection = () => {
  const subjects = useCoreSubjectsForSelectedPath()

  return (
    <SubjectsSection title={<Trans>Core subjects</Trans>} subjects={subjects} />
  )
}

export default CoreSubjectsSection

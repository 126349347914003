.react-app ._clickableCard_eez3z_1 {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: var(--space-less);
  box-shadow: none;
  border: var(--border-base-lighter);
  border-radius: 10px;
  margin-bottom: var(--space-default);
}
.react-app ._clickableCard_eez3z_1:hover {
  background-color: #f9f9fa;
}
.react-app ._clickableCard_eez3z_1 ._cardText_eez3z_14 {
  flex: 1;
}
.react-app ._clickableCard_eez3z_1 ._icon_eez3z_17 {
  height: 22px;
  width: 22px;
}
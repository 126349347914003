import React from 'react'

import ReportsListPage from 'apps/Reports/components/ReportsListPage'
import useChangeDocumentTitle from 'apps/Reports/hooks/useChangeDocumentTitle'

const ReportsList = () => {
  useChangeDocumentTitle('Reports')

  return <ReportsListPage />
}

export default ReportsList

.react-app ._pageHead_1ncfr_1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: var(--space-default);
  padding-left: var(--space-default);
  padding-top: var(--space-default);
  border-bottom: var(--border-base-lighter);
  min-height: 65px;
}

.react-app ._pageLeftHead_1ncfr_12 {
  display: flex;
  align-items: center;
}

.react-app ._titleContainer_1ncfr_17 {
  border-left: var(--border-base-lighter);
}

.react-app ._closeButton_1ncfr_21 {
  display: flex;
  margin-right: var(--space-small);
}
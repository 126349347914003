import React from 'react'
import { useTranslation } from 'react-i18next'

import { Badge, Table } from '@fullfabric/alma-mater'

export const State = ({ entry }) => {
  const [t] = useTranslation()

  let state = entry.state && <Badge>{t(entry.state)}</Badge>

  if (entry.state === 'planned') {
    state = <Badge type='purple'>{t(entry.state)}</Badge>
  }

  return <Table.Data>{state}</Table.Data>
}

import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Alert } from '@fullfabric/alma-mater'

import ApplicationsSelector from 'apps/MergeProfiles/components/ApplicationsSelector'
import PageLayout from 'apps/MergeProfiles/components/PageLayout'
import {
  MERGE_STEPS,
  useMergeData
} from 'apps/MergeProfiles/contexts/MergeData'
import useCurrentPage from 'apps/MergeProfiles/hooks/useCurrentPage'
import NextActionButton from './NextActionButton'
import PreviousActionButton from './PreviousActionButton'

const ERRORS_MAP = {
  someNotSelected: <Trans>A selection must be made for all applications</Trans>
}

const ApplicationsPage = () => {
  useCurrentPage(MERGE_STEPS.applications)
  const { t } = useTranslation()
  const { mergeError } = useMergeData()

  const errorText = ERRORS_MAP[mergeError.applicsFrom]

  const actionButtons = (
    <>
      <PreviousActionButton />
      <NextActionButton />
    </>
  )

  return (
    <>
      <PageLayout.Header
        title={t('Applications')}
        description={t(
          'Select the information that is more relevant for the final profile.'
        )}
        actions={actionButtons}
      />

      <PageLayout.Content>
        {errorText && (
          <Alert
            error
            title={t('Errors found.')}
            description={errorText}
            marginBottom='big'
          />
        )}

        <ApplicationsSelector />

        {errorText && (
          <Alert
            error
            title={t('Errors found.')}
            description={errorText}
            marginTop='big'
          />
        )}
      </PageLayout.Content>

      <PageLayout.Footer actions={actionButtons} />
    </>
  )
}

export default ApplicationsPage

import { useExpanded, useRowSelect, useSortBy, useTable } from 'react-table'

import { TABLE_SORT_DEFAULT_CONFIG } from '../../../../constants'
import useCellsWithLinks from '../../../../hooks/useCellsWithLinks'
import useColumnTypes from '../../../../hooks/useColumnTypes'
import useRowSelectCheckbox from '../../../../hooks/useRowSelectCheckbox'
import useFixedTableLayout from './useFixedTableLayout'

const useReactTable = ({
  memoizedData,
  memoizedColumns,
  selectableRows,
  expandableRows,
  fixedLayout,
  onChangeSort,
  initialHiddenColumns,
  tableProps
}) => {
  const isSortableTable = !!onChangeSort
  const sortByConfig = isSortableTable ? TABLE_SORT_DEFAULT_CONFIG : {}
  const tablePropsWithHiddenCols = initialHiddenColumns
    ? {
        ...tableProps,
        initialState: {
          ...tableProps.initialState,
          hiddenColumns: initialHiddenColumns
        }
      }
    : tableProps

  return useTable(
    {
      columns: memoizedColumns,
      data: memoizedData,
      ...sortByConfig,
      ...tablePropsWithHiddenCols
    },
    useColumnTypes,
    useCellsWithLinks,
    ...(selectableRows ? [useRowSelect, useRowSelectCheckbox] : []),
    ...(isSortableTable ? [useSortBy] : []),
    ...(expandableRows ? [useExpanded] : []),
    ...(fixedLayout ? [useFixedTableLayout] : [])
  )
}

export default useReactTable

class SchemaHelper {
  static getFieldNames(schema, prefix) {
    return schema?.sections
      ?.map((section) => section.fields)
      .reduce((acc, cur) => [...acc, ...cur], [])
      .map((field) => [prefix, field.name].filter((x) => x).join('.'))
  }
}

export default SchemaHelper

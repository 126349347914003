import React from 'react'
import { Trans } from 'react-i18next'

import ImportActions from 'apps/Imports/components/profile-import/import-actions'
import MappingSummarySection from 'apps/Imports/components/summary-mapping-section'
import SimpleSummarySection from 'apps/Imports/components/summary-simple-section'
import { useSelectedData } from 'apps/Imports/contexts/selected-data'
import { useImportContext } from 'apps/Imports/hooks/useImportContext'
import { PROFILE_IMPORT_STEPS } from 'apps/Imports/utils/profile-import/constants'
import importUrlFromStepId from 'apps/Imports/utils/profile-import/get-url-from-step-id'
import AddToContextSummarySection from './components/add-to-context-summary-section'
import ConsentSummarySection from './components/consent-summary-section'

const ImportSummaryStep = () => {
  const context = useImportContext()
  const { data } = useSelectedData()

  return (
    <>
      <SimpleSummarySection
        linkTo={importUrlFromStepId(context, PROFILE_IMPORT_STEPS.FILE_UPLOAD)}
        stepId={PROFILE_IMPORT_STEPS.FILE_UPLOAD}
        fieldsData={[
          {
            label: <Trans>File name</Trans>,
            value: data.file?.name
          }
        ]}
      />

      <SimpleSummarySection
        linkTo={importUrlFromStepId(
          context,
          PROFILE_IMPORT_STEPS.IMPORT_SETTINGS
        )}
        stepId={PROFILE_IMPORT_STEPS.IMPORT_SETTINGS}
        fieldsData={[
          {
            label: <Trans>Do you want to update existing profiles?</Trans>,
            value: data.updateExistingProfiles ? (
              <Trans>Yes, add new profiles and update existing profiles</Trans>
            ) : (
              <Trans>
                No, only add new profiles, do not update existing profiles
              </Trans>
            )
          }
        ]}
      />

      <ConsentSummarySection />
      <AddToContextSummarySection />

      <SimpleSummarySection
        linkTo={importUrlFromStepId(
          context,
          PROFILE_IMPORT_STEPS.PROFILE_DETAILS
        )}
        fieldsData={[
          {
            label: <Trans>Tags</Trans>,
            value: data.tags.join(', ')
          },
          {
            label: <Trans>Source category</Trans>,
            value:
              data.sourceCategoryType === 'same' ? (
                data.sourceCategory
              ) : (
                <Trans>via Mapping</Trans>
              )
          }
        ]}
        stepId={PROFILE_IMPORT_STEPS.PROFILE_DETAILS}
      />

      <MappingSummarySection
        url={importUrlFromStepId(context, PROFILE_IMPORT_STEPS.MAPPING)}
        stepId={PROFILE_IMPORT_STEPS.MAPPING}
      />

      <ImportActions />
    </>
  )
}

export default ImportSummaryStep

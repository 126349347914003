import React from 'react'

import { LoadingPlaceholder, Table } from '@fullfabric/alma-mater'

import classNames from 'classnames'
import styles from './styles.module.scss'

export const parseWidth = (rawWidthProp) => {
  return rawWidthProp ? `${rawWidthProp}px` : rawWidthProp
}

function PlaceholderTableCell({ columnData, columnIndex, virtualized, level }) {
  const columnHeaderName = columnData.Header
  const columnWidth = parseWidth(columnData.totalWidth)
  const maxColumnWidth = parseWidth(columnData.maxWidth)

  const colProps = virtualized
    ? {
        role: 'cell',
        tag: 'div',
        style: {
          flex: `1 1 ${columnWidth}`,
          minWidth: columnWidth,
          maxWidth: maxColumnWidth,
          boxSizing: 'border-box'
        }
      }
    : {}

  return (
    <Table.Data
      {...colProps}
      title={
        typeof columnHeaderName === 'string'
          ? `loading cell for column ${columnHeaderName}`
          : `loading cell for column number ${columnIndex + 1}`
      }
    >
      <LoadingPlaceholder
        className={classNames(
          styles[`level-${level}`],
          columnData.type === 'number' && styles.numericAlignment
        )}
        innerClassName={classNames(
          styles.defaultTextPlaceholder,
          columnData.id === 'selection' && styles.checkboxPlaceholder
        )}
      />
    </Table.Data>
  )
}

export default PlaceholderTableCell

import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import {
  Button,
  Modal,
  ModalButtons,
  ModalTitle,
  Text
} from '@fullfabric/alma-mater'

import AssigneeField from './AssigneeField'
import useReassignEvaluation from './useReassignEvaluation'

const ReassignModal = ({ isOpen, handleClose }) => {
  const { t } = useTranslation()
  const formMethods = useForm()
  const { handleSubmit } = formMethods

  const { onSubmit, isSubmitting, hasServerError } = useReassignEvaluation({
    onSubmitted: () => {
      handleClose()
    }
  })

  return (
    <Modal
      role='dialog'
      aria-labelledby='reassign-modal-title'
      isOpen={isOpen}
      size='medium'
      header={
        <ModalTitle
          title={
            <span id='reassign-modal-title'>{t('Assign evaluation')}</span>
          }
          onClose={handleClose}
        />
      }
      footer={
        <ModalButtons>
          <Button onClick={handleClose} disabled={isSubmitting}>
            {t('Cancel')}
          </Button>
          <Button
            primary
            type='submit'
            form='reassign-modal-form'
            onClick={handleSubmit(onSubmit)}
            loading={isSubmitting}
          >
            {t('Change assignee')}
          </Button>
        </ModalButtons>
      }
    >
      <FormProvider {...formMethods}>
        <form id='reassign-modal-form'>
          <AssigneeField />
        </form>
      </FormProvider>
      {hasServerError ? (
        <Text fontColor='text-alert' type='f5' marginTop='default'>
          {t(
            'There was an error, please try again in a few seconds. If the issue persists, please contact us.'
          )}
        </Text>
      ) : null}
    </Modal>
  )
}

export default ReassignModal

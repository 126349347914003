import React from 'react'

import { Row, Text } from '@fullfabric/alma-mater'

const Header = ({ title, description, actions }) => (
  <Row justify='between' nogutter>
    <div>
      <Text type='f3' fontColor='text-base-darkest'>
        {title}
      </Text>

      {description && (
        <Text small fontColor='text-base'>
          {description}
        </Text>
      )}
    </div>

    <div>{actions}</div>
  </Row>
)

export default Header

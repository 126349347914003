import React from 'react'
import { Trans } from 'react-i18next'

import PageContainer from 'apps/Imports/components/page-container'
import ImportSettingsStep from 'apps/Imports/components/profile-import/import-settings-step'
import { useCurrentStep } from 'apps/Imports/contexts/steps'
import { PROFILE_IMPORT_STEPS } from 'apps/Imports/utils/profile-import/constants'

const ImportSettingsPage = () => {
  useCurrentStep(PROFILE_IMPORT_STEPS.IMPORT_SETTINGS)

  return (
    <PageContainer
      title={<Trans>Import settings</Trans>}
      sidebarTitle={<Trans>Import Profiles</Trans>}
    >
      <ImportSettingsStep />
    </PageContainer>
  )
}

export default ImportSettingsPage

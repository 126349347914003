/* eslint-disable camelcase */
export const getApplicAssociatedContextIds = (applic) =>
  [
    applic.context_type !== 'Institutions::Institution' && applic.context_id,
    ...(applic.associated_context_ids || [])
  ].filter((x) => x)

export const getAssociatedContextIdsForProfileType = (
  applics = [],
  profileType,
  applicsFrom
) =>
  applics
    .filter((applic) => applicsFrom[applic.template_id] === profileType)
    .map((applic) => getApplicAssociatedContextIds(applic))
    .reduce((acc, context_ids) => [...acc, ...context_ids], [])

const getObjectFromArrayWithValue = (array, value) =>
  array.reduce((acc, item) => {
    return { ...acc, [item]: value }
  }, {})

export const getJourneysFromApplics = (
  sourceProfile,
  sourceProfileApplics,
  targetProfileApplics,
  applicsFrom
) => {
  const sourceProfileRoleContextIds = sourceProfile.roles.map(
    (r) => r.context_id
  )
  const duplicatedPreselectedContextIds = getAssociatedContextIdsForProfileType(
    sourceProfileApplics,
    'duplicate',
    applicsFrom
  ).filter((contextId) => sourceProfileRoleContextIds.includes(contextId))

  const mainPreselectedContextIds = getAssociatedContextIdsForProfileType(
    targetProfileApplics,
    'main',
    applicsFrom
  ).filter((contextId) => sourceProfileRoleContextIds.includes(contextId))

  const journeysFromDuplicatedApplics = getObjectFromArrayWithValue(
    duplicatedPreselectedContextIds,
    'duplicate'
  )
  const journeysFromMainApplics = getObjectFromArrayWithValue(
    mainPreselectedContextIds,
    'main'
  )

  return {
    ...journeysFromMainApplics,
    ...journeysFromDuplicatedApplics
  }
}

const toLocaleStringSupportsLocales = () => {
  const number = 0
  try {
    number.toLocaleString('i')
  } catch (e) {
    return e.name === 'RangeError'
  }
  return false
}

const formatPrice = (price) => {
  if (toLocaleStringSupportsLocales()) {
    return price.toLocaleString('en-US')
  } else {
    return price.toLocaleString()
  }
}

export const formatCurrency = (value, currency, locale) => {
  const floatValue = value || 0.0

  if (Intl && Intl.NumberFormat && currency) {
    const formatter = new Intl.NumberFormat(locale, {
      currency,
      style: 'currency',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })

    return formatter.format(floatValue)
  }

  return floatValue.toFixed(2)
}

export default formatPrice

import React from 'react'
import { Trans } from 'react-i18next'

import { ErrorIcon, SuccessIcon, Text } from '@fullfabric/alma-mater'

import { useMergeData } from 'apps/MergeProfiles/contexts/MergeData'

import styles from './styles.module.scss'

const JourneyCardNotice = ({ context, profileType }) => {
  const {
    mergeData: { journeysFrom }
  } = useMergeData()

  const hasSelection = !!journeysFrom[context.id]
  const isAdded = journeysFrom[context.id] === profileType

  if (!hasSelection) return null

  const noticeColor = isAdded ? 'text-success' : 'text-alert'
  const NoticeIcon = isAdded ? SuccessIcon : ErrorIcon
  const noticeText =
    profileType === 'main' ? (
      isAdded ? (
        <Trans>Keep</Trans>
      ) : (
        <Trans>Delete</Trans>
      )
    ) : isAdded ? (
      <Trans>Add</Trans>
    ) : (
      <Trans>Discard</Trans>
    )

  return (
    <Text fontColor={noticeColor} className={styles.cardNotice}>
      <NoticeIcon color={noticeColor} /> {noticeText}
    </Text>
  )
}

export default JourneyCardNotice

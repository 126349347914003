import React from 'react'

import isOfferInFinalState from 'apps/ContentPages/services/offers/is-offer-in-final-state'
import AcceptanceButtons from './acceptance-buttons'
import ContinueButton from './continue-button'
import { isOfferInProgress, isOfferSubmitted } from './utils'
import ViewOfferButton from './view-offer-button'

import styles from './styles.module.scss'

const OffersActions = ({ data }) => {
  const isOfferAnswered = isOfferInFinalState(data)

  if (isOfferAnswered) {
    return null
  }

  let Action = AcceptanceButtons

  if (isOfferInProgress(data)) {
    Action = ContinueButton
  }

  if (isOfferSubmitted(data)) {
    Action = ViewOfferButton
  }

  return (
    <div className={styles.container}>
      <Action offer={data} />
    </div>
  )
}

export default OffersActions

import React from 'react'
import { useTranslation } from 'react-i18next'

import { Text } from '@fullfabric/alma-mater'

import { useWizardNavigationState } from 'apps/Applics/contexts/WizardNavigationContext'
import Logo from './Logo'

import styles from './styles.module.scss'

const Header = () => {
  const { currentStep, totalSteps } = useWizardNavigationState()
  const { t } = useTranslation()
  return (
    <div className={styles.container}>
      <Logo className={styles.logo} />
      <Text
        type='h6'
        fontColor='text-base-darker'
        marginLeft='big'
        className={styles.text}
      >
        {t(
          `Create a new application form [step ${
            currentStep + 1
          } of ${totalSteps}]`
        )}
      </Text>
    </div>
  )
}

export default Header

import 'css-chunk:src/apps/ContentPages/components/page-layout/header/styles.module.scss';export default {
  "react-app": "react-app",
  "wrapper": "_wrapper_wyfz7_1",
  "desktop": "_desktop_wyfz7_5",
  "placeholder": "_placeholder_wyfz7_9",
  "container": "_container_wyfz7_15",
  "plain": "_plain_wyfz7_30",
  "hidden": "_hidden_wyfz7_34",
  "logoContainer": "_logoContainer_wyfz7_38",
  "logoLink": "_logoLink_wyfz7_51"
};
import React, { useState } from 'react'

import { SchemableForm } from '@fullfabric/schemable-forms'

import reduxStoreHelper from 'apps/Courses/utils/reduxStoreHelper'

import styles from './styles.module.scss'

const CourseCreateFormSchema = ({
  data: reduxStoreData,
  schema,
  countriesAsOptions,
  onChange
}) => {
  const [localData, setLocalData] = useState(reduxStoreData)

  return (
    <SchemableForm
      data={localData}
      schema={schema}
      countriesAsOptions={countriesAsOptions}
      onChange={(field, value) => {
        setLocalData({ ...localData, [field]: value })
        onChange(field, reduxStoreHelper.formValueToStoreFormat(value))
      }}
      hideSubmitButton={true}
      separatorClassName={styles.separator}
    />
  )
}

export default CourseCreateFormSchema

import { DEFAULT_MARKETING_OPTIONS, VALID_CHANNELS } from './constants'

const getRelevantMarketingOptions = (selectedMarketingOptions) => {
  return Object.keys(selectedMarketingOptions || {}).reduce(
    (acc, optName) => {
      if (
        !VALID_CHANNELS.includes(optName) ||
        !selectedMarketingOptions[optName]
      )
        return acc
      return { ...acc, [optName]: selectedMarketingOptions[optName] }
    },
    { ...DEFAULT_MARKETING_OPTIONS }
  )
}

export default getRelevantMarketingOptions

import React from 'react'
import { Trans } from 'react-i18next'

import { SuccessIcon, Text } from '@fullfabric/alma-mater'

import { ReactComponent as WarningIcon } from './warning.svg'

import classNames from 'classnames'
import styles from './styles.module.scss'

const SubscriptionInfo = ({ profile }) => {
  return (
    <div className={classNames(styles.infoRow, styles.withIcon)}>
      {profile.unsubscribed ? (
        <>
          <WarningIcon className={styles.icon} />{' '}
          <Text fontColor='yellow-800'>
            <Trans>Email not subscribed</Trans>
          </Text>
        </>
      ) : (
        <>
          <SuccessIcon color='text-success' className={styles.icon} />{' '}
          <Text fontColor='text-success'>
            <Trans>Email subscribed</Trans>
          </Text>
        </>
      )}
    </div>
  )
}

export default SubscriptionInfo

import React from 'react'

import SavedChangesPopup from 'apps/StudyPlans/components/SavedChangesPopup'
import SubjectBasicSection from 'apps/StudyPlans/components/SubjectBasicSection'
import SubjectFinalGradeSection from 'apps/StudyPlans/components/SubjectFinalGradeSection'
import SubjectPathsSection from 'apps/StudyPlans/components/SubjectPathsSection'
import { ChangesSavedContext } from 'apps/StudyPlans/contexts/ChangesSaved'
import { useStudyPlan } from 'apps/StudyPlans/contexts/StudyPlanData'
import { useSubjectSectionsBeingEdited } from 'apps/StudyPlans/contexts/SubjectSectionBeingEdited'

const EditSubjectForm = () => {
  const studyPlan = useStudyPlan()
  const sectionsBeingEdited = useSubjectSectionsBeingEdited()
  const [changesSaved, setChangesSaved] = React.useState(false)
  const onChangesSaved = React.useCallback(() => {
    setChangesSaved(true)
    setTimeout(() => setChangesSaved(false), 3000)
  }, [])

  return (
    <ChangesSavedContext.Provider value={onChangesSaved}>
      <SubjectBasicSection beingEdited={sectionsBeingEdited.basic} />
      {studyPlan.multiple_paths && (
        <SubjectPathsSection beingEdited={sectionsBeingEdited.paths} />
      )}
      <SubjectFinalGradeSection beingEdited={sectionsBeingEdited.finalGrade} />

      <SavedChangesPopup show={changesSaved} />
    </ChangesSavedContext.Provider>
  )
}

export default EditSubjectForm

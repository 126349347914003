import React, { useEffect } from 'react'

import grapesjs from 'grapesjs'
import presetMjml from 'grapesjs-mjml'
import { compile } from 'html-to-text'

import GjsEditor from '@grapesjs/react'

import useSettingByName from 'apps/ContentPages/hooks/use-setting-by-name'

import './grapejs.scss'

import { debounce } from 'lodash'

import { Loading } from '@fullfabric/alma-mater'

import { baseTemplateGrapejs } from 'apps/EmailTemplates/constants/baseTemplateGrapejs'
import { useEmailTemplateImages } from 'apps/EmailTemplates/hooks/useEmailTemplateImages'
import { useMergeTags } from 'apps/EmailTemplates/hooks/useMergeTags'
import { getGrapeJsOptionsConfig } from './config'
import { mergeTagPlugin } from './plugins/mergeTagPlugin'

const compiledConvert = compile({})

export default function GrapejsEditor({ emailTemplate, onSave, onChange }) {
  const mergeTags = useMergeTags()
  const editorRef = React.useRef(null)
  const colors = useSettingByName(
    'modules.core.submodules.email_templates.themes.colors'
  )
  const { data: dataImages, isLoading } = useEmailTemplateImages(
    emailTemplate.id
  )

  const imageUploadResponse = (responseData) => {
    editorRef.current.AssetManager.add({
      src: responseData.url,
      type: 'image'
    })
  }

  const onEditorSave = debounce(() => {
    const html = editorRef.current.runCommand('mjml-code-to-html')?.html
    const mjml = editorRef.current.runCommand('mjml-code')

    onSave({ html, mjml, text: compiledConvert(html) })
  }, 500)

  const onEditor = (editor) => {
    editorRef.current = editor
    editor.addComponents(emailTemplate.mjml || baseTemplateGrapejs)
    editor.Panels.removeButton('options', 'export-template')
    editor.Panels.removeButton('options', 'mjml-import')
    editor.on('asset:upload:response', imageUploadResponse)
  }

  const onGrapeJsUpdate = () => {
    onChange()
    onEditorSave()
  }

  useEffect(() => {
    return () => {
      editorRef.current.off('asset:upload:response', imageUploadResponse)
    }
  }, [])

  if (isLoading) {
    return <Loading />
  }

  return (
    <GjsEditor
      aria-label='Grapejs email editor'
      role='main'
      grapesjs={grapesjs}
      options={getGrapeJsOptionsConfig(
        colors,
        emailTemplate,
        mergeTags,
        dataImages
      )}
      onUpdate={onGrapeJsUpdate}
      plugins={[presetMjml, mergeTagPlugin]}
      onEditor={onEditor}
    />
  )
}

.react-app ._marginTop_jvn58_1 {
  margin-top: var(--space-default);
}

.react-app ._table_jvn58_5 td {
  vertical-align: middle !important;
}
.react-app ._table_jvn58_5 td:not(:first-child):not(:last-child) {
  width: 10%;
}
.react-app ._table_jvn58_5 td:first-child {
  width: 75%;
}
.react-app ._table_jvn58_5 td:last-child {
  width: 5%;
  text-align: end;
}
.react-app ._table_jvn58_5 td:last-child._empty_jvn58_18 {
  text-align: unset;
}
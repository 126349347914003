import React from 'react'
import { Trans } from 'react-i18next'

import { Button } from '@fullfabric/alma-mater'

import useIsMobileLayout from 'apps/ContentPages/components/payments-page/use-is-payments-mobile-layout'
import useSettingByName from 'apps/ContentPages/hooks/use-setting-by-name'

import classNames from 'classnames'
import styles from './pay-fee-button/styles.module.scss'

const PayFeeButton = ({ feeState, onFeeClick, paymentPortal }) => {
  const isMobileLayout = useIsMobileLayout()

  const stripeIntegrationEnabled = useSettingByName(
    'integrations.payments.stripe.enable'
  )

  const gateway = paymentPortal?.type

  const isGatewayStripe = /stripe/i.test(gateway)
  const isGatewayFlywire = /flywire/i.test(gateway)

  if (isGatewayStripe && !stripeIntegrationEnabled) {
    return null
  }

  if (feeState === 'paid') {
    return null
  }

  return (
    // <IfHasSetting settingName="integrations.payments.flywire.enable">
    <div className={classNames(styles.footer, isMobileLayout && styles.mobile)}>
      {isGatewayStripe ? (
        <Button primary className={styles.button} onClick={onFeeClick}>
          <Trans>{'Pay with Stripe'}</Trans>
        </Button>
      ) : null}
      {isGatewayFlywire ? (
        <Button primary className={styles.button} onClick={onFeeClick}>
          <Trans>{'Pay with Flywire'}</Trans>
        </Button>
      ) : null}
    </div>
    // </IfHasSetting>
  )
}

export default PayFeeButton

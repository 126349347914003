{
  "Analytical": "Cookie analitici",
  "Strictly necessary": "Cookie strettamente necessari",
  "Functional": "Cookie funzionali",
  "Advertising": "Cookie pubblicitari",
  "Submit": "Invia",
  "Thank you": "Grazie",
  "You have confirmed your marketing preferences.": "Hai confermato le tue preferenze di marketing.",
  "You did not accept the Privacy Policy.": "Non hai accettato l'informativa sulla privacy.",
  "You must contact us to delete your data.": "Devi contattarci per cancellare i tuoi dati.",
  "Change privacy policy agreement": "Cambia l'accordo sull'informativa sulla privacy",
  "Privacy Policy": "Politica sulla riservatezza",
  "Next: Update marketing preferences": "Avanti: Aggiorna le preferenze di marketing",
  "I Accept the Privacy Policy": "Accetto l'informativa sulla privacy",
  "I DO NOT accept the Privacy Policy": "NON accetto la Privacy Policy",
  "If you do not accept the privacy policy, you must contact the data processor to delete your data.": "Se non accetti l'informativa sulla privacy dovrai contattare il titolare del trattamento per cancellare i tuoi dati.",
  "You have updated your marketing preferences.": "Hai aggiornato le tue preferenze di marketing.",
  "Log in": "Accesso",
  "Finish your registration": "Completa la tua registrazione",
  "Marketing Policy": "Politica di marketing",
  "I would like to receive information by Email": "Desidero ricevere informazioni via e-mail",
  "I would like to receive information by Phone": "Desidero ricevere informazioni per telefono",
  "I would like to receive information by SMS": "Desidero ricevere informazioni via SMS",
  "I would like to receive information by Mail": "Desidero ricevere informazioni per posta",
  "I do not want to be contacted with this type of information": "Non voglio essere contattato con questo tipo di informazioni",
  "Cookie Policy": "Gestione dei Cookie",
  "Back to Portal": "Torna al portale",
  "Manage your preferences": "Gestisci le tue preferenze",
  "Save cookie preferences": "Salva le preferenze sui cookie",
  "You accepted our privacy policy on our website - {{institutionHost}} - on {{date}}": "Hai accettato la nostra politica sulla privacy sul nostro sito Web - {{institutionHost}} - su {{date}}",
  "You are receiving this email because you accepted our marketing policy on our website - {{institutionHost}} - on  {{date}}": "Hai ricevuto questa email perché hai accettato la nostra politica di marketing sul nostro sito web - {{institutionHost}} - su {{date}}",
  "You accepted our privacy policy when creating an account on {{date}}": "Hai accettato la nostra politica sulla privacy durante la creazione di un account su {{date}}",
  "You accepted our privacy policy on {{date}} from our email": "Hai accettato la nostra politica sulla privacy su {{date}} dalla nostra e-mail",
  "You are receiving this email because you accepted our marketing policy on {{date}} from our email": "Hai ricevuto questa email perché hai accettato la nostra politica di marketing su {{date}} dalla nostra email",
  "You are receiving this email because a person created a profile for you and obtained your consent from {{consentSource}} on {{date}}": "Hai ricevuto questa email perché una persona ha creato un profilo per te e ha ottenuto il tuo consenso da \" {{consentSource}}\" il {{date}}",
  "You accepted our privacy policy when submitting the {{entity}} form on {{date}}": "Hai accettato la nostra politica sulla privacy inviando il modulo \" {{entity}}\" su {{date}}",
  "You accepted our marketing policy when submitting the {{entity}} form on {{date}}": "Hai accettato la nostra politica di marketing inviando il modulo \" {{entity}}\" su {{date}}",
  "You accepted our privacy policy when registering for the {{entity}} event on {{date}}": "Hai accettato la nostra politica sulla privacy al momento della registrazione per l'evento \" {{entity}}\" di {{date}}",
  "You accepted our marketing policy when registering for the {{entity}} event on {{date}}": "Hai accettato la nostra politica di marketing al momento della registrazione per l'evento \" {{entity}}\" di {{date}}",
  "Unsubscribe": "Annulla l'iscrizione",
  "Yes, unsubscribe": "Sì, annulla l'iscrizione",
  "No, stay subscribed": "No, rimani iscritto",
  "Manage marketing preferences": "Gestisci le preferenze di marketing",
  "Manage preferences for <0>{{policyName}}</0>": "Gestisci le preferenze per <0>{{policyName}}</0>",
  "Unsubscribe successful": "Annullamento dell'iscrizione riuscito",
  "You are still subscribed": "Sei ancora iscritto",
  "Back to homepage": "Torna alla homepage",
  "Are you sure you want to unsubscribe from the <0>{{topicName}}</0> topic?": "Sei sicuro di voler annullare l'iscrizione all'argomento <0>{{topicName}}</0>?",
  "You have been unsubscribed from the <0>{{topicName}}</0> topic.": "Sei stato annullato dall'argomento <0>{{topicName}}</0>.",
  "You have not been unsubscribed from the <0>{{topicName}}</0> topic.": "Non sei stato cancellato dall'argomento <0>{{topicName}}</0>."
}

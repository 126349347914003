import 'css-chunk:src/apps/MergeProfiles/components/JourneyCard/styles.module.scss';export default {
  "react-app": "react-app",
  "journeyCard": "_journeyCard_oovom_1",
  "journeyAdded": "_journeyAdded_oovom_6",
  "journeyDiscarded": "_journeyDiscarded_oovom_9",
  "summaryType": "_summaryType_oovom_9",
  "cardInfoRegion": "_cardInfoRegion_oovom_13",
  "cardNotice": "_cardNotice_oovom_16",
  "associatedContextNoticeContainer": "_associatedContextNoticeContainer_oovom_23",
  "discarded": "_discarded_oovom_27",
  "added": "_added_oovom_31",
  "cardButtons": "_cardButtons_oovom_36",
  "cardButton": "_cardButton_oovom_36",
  "buttonIcon": "_buttonIcon_oovom_41"
};
import React from 'react'

import { LoadingPlaceholder } from '@fullfabric/alma-mater'

const Placeholder = () => (
  <>
    <LoadingPlaceholder type='f5' />
    <LoadingPlaceholder type='f4' marginBottom='less' />
    <LoadingPlaceholder type='f5' />
    <LoadingPlaceholder type='f4' marginBottom='less' />
  </>
)

export default Placeholder

.react-app ._reportCard_1i5go_1 {
  min-width: 300px;
  max-width: 400px;
  margin-right: var(--space-more);
  margin-bottom: var(--space-more);
  display: inline-block;
  cursor: pointer;
  overflow: hidden;
}
.react-app ._reportCard_1i5go_1:hover {
  box-shadow: var(--shadow-3);
}
.react-app ._reportCard_1i5go_1:hover ._reportLink_1i5go_13 {
  border-bottom: 1px solid var(--ff-color-text-primary) !important;
}

.react-app ._imageContainer_1i5go_17 {
  height: 136px;
  width: 100%;
  overflow: hidden;
  border-bottom: var(--border-base-lighter);
  display: flex;
  justify-content: center !important;
  background-size: 100% 100%;
}

.react-app ._image_1i5go_17 {
  height: 100%;
}

.react-app ._infoContainer_1i5go_31 {
  padding: var(--space-default);
}
.react-app ._infoContainer_1i5go_31 ._reportLink_1i5go_13 {
  margin-left: 0;
  margin-right: 0;
}
.react-app ._infoContainer_1i5go_31 ._description_1i5go_38 {
  height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
}
import React from 'react'
import { Trans } from 'react-i18next'

import {
  ArrowRightIcon,
  AutocompleteInput,
  Table,
  Text
} from '@fullfabric/alma-mater'

import { useSelectedData } from 'apps/Imports/contexts/selected-data'
import useMappingFieldOptions from '../hooks/use-mapping-field-options'
import useMappingSamples from '../hooks/use-mapping-samples'
import useOnMappingChange from '../hooks/use-on-mapping-change'
import useRequiredMappingFields from '../hooks/use-required-mapping-fields'

import classNames from 'classnames'
import styles from './styles.module.scss'

export default function AllFieldsSection() {
  const { data } = useSelectedData()
  const { requiredFieldsToImport } = useRequiredMappingFields()
  const { mappingFieldOptions } = useMappingFieldOptions()

  const onChange = useOnMappingChange()
  const samples = useMappingSamples()

  return (
    <div className={styles.allFieldsSection}>
      <Text type='h4' fontColor='text-base-darkest'>
        <Trans>All columns</Trans>
      </Text>

      <Text>
        <Trans>All columns found on the .CSV file are listed here.</Trans>
      </Text>

      <Table className={styles.allFieldsList}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderItem disabled className={styles.allFieldsHeaderItem}>
              <Trans>Column on .csv file</Trans>
            </Table.HeaderItem>

            <Table.HeaderItem disabled className={styles.allFieldsHeaderItem}>
              <Trans>Full fabric field</Trans>
            </Table.HeaderItem>

            <Table.HeaderItem
              disabled
              className={classNames(
                styles.allFieldsHeaderItem,
                styles.samplesColumnHeader
              )}
            >
              <Trans>Sample from .csv file</Trans>
            </Table.HeaderItem>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {data.mappings.map((mapping, index) => (
            <Table.Row key={`${mapping.header}-${index}`}>
              <Table.Data
                className={classNames(
                  styles.allFieldsCsvFieldCol,
                  styles.allFieldsColData
                )}
              >
                <Text fontColor='text-base-darkest' type='f4'>
                  {mapping.header}
                </Text>

                <ArrowRightIcon
                  width={20}
                  height={20}
                  color='grey-300'
                  className={styles.arrow}
                />
              </Table.Data>

              <Table.Data className={styles.allFieldsColData}>
                <AutocompleteInput
                  id='all_email'
                  isClearable
                  value={mappingFieldOptions.reduce((found, group) => {
                    if (found) return found

                    return mapping?.field
                      ? group.options.find(
                          (opt) => opt.value === mapping.field
                        ) || null
                      : null
                  }, null)} // for dropdowns with grouped options, should set the value as the whole option object ({value, label})
                  styles={{
                    menu: (base) => ({
                      ...base,
                      zIndex: 10 + index, // this is needed for keeping the dropdown menus over the dropdowns below them when opened
                      overflow: 'hidden'
                    })
                  }}
                  options={mappingFieldOptions.map((group) => {
                    return {
                      label: group.label,
                      value: group.label,
                      options: group.options.map((opt) => {
                        return {
                          ...opt,
                          disabled:
                            data.mappings.filter((m) => m.field === opt.value)
                              .length > 0
                        }
                      })
                    }
                  })}
                  blurInputOnSelect
                  disabled={requiredFieldsToImport.includes(mapping.field)}
                  formatOptionLabel={(opt) =>
                    requiredFieldsToImport.includes(opt.value)
                      ? `${opt.label} *`
                      : opt.label
                  }
                  onChange={(value) => onChange(value, mapping.header)}
                />
              </Table.Data>

              <Table.Data className={styles.allFieldsColData}>
                <Text className={styles.samplesColumnText}>
                  {samples[mapping.header]}
                </Text>
              </Table.Data>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </div>
  )
}

import { utils } from '@fullfabric/public-api'

const createLinkedinAccount = async (data) => {
  const response = await fetch('/linked_in/accounts', {
    method: 'POST',
    credentials: 'same-origin',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ ...data })
  })

  return await utils.checkResponse(response)
}

export default createLinkedinAccount

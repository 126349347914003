import React from 'react'

import { Text, useScreenClass } from '@fullfabric/alma-mater'

import Placeholder from './placeholder'

import styles from './styles.module.scss'

const PageTitle = ({ isLoading, thin, subtitle, children }) => {
  const screenClass = useScreenClass()
  const isMobile = ['xs', 'sm'].includes(screenClass)

  const titleProps = thin
    ? {
        type: isMobile ? 'h2' : 'f1',
        marginBottom: isMobile ? 'small' : 'tiny',
        className: styles.title
      }
    : {
        type: isMobile ? 'h1' : 'h0',
        className: styles.title
      }

  const subtitleProps = thin
    ? { type: isMobile ? 'f5' : 'f4', className: styles.thinSubtitle }
    : { type: 'f2', className: styles.subtitle }

  return (
    <div className={styles.container}>
      {isLoading ? (
        <Placeholder />
      ) : (
        <>
          <Text
            tag='h1'
            fontColor='text-base-darkest'
            id='page-title'
            data-testid='page-title'
            {...titleProps}
          >
            {children}
          </Text>
          {subtitle && (
            <Text tag='h2' fontColor='text-base-darker' {...subtitleProps}>
              {subtitle}
            </Text>
          )}
        </>
      )}
    </div>
  )
}

export default PageTitle

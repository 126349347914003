import React from 'react'
import { Trans } from 'react-i18next'

import { Button, Col, Row } from '@fullfabric/alma-mater'

import CourseCreateFormStatus from '../CourseCreateFormStatus'

import styles from './styles.module.scss'

const CourseEditFormStatus = ({
  data,
  onChange,
  inProgress,
  onSaveClick,
  onCancelClick
}) => {
  return (
    <>
      <Row>
        <Col xs={12}>
          <CourseCreateFormStatus data={data} onChange={onChange} />
        </Col>
      </Row>

      <div className='marginTop-default'>
        <Button primary size='small' loading={inProgress} onClick={onSaveClick}>
          <Trans>Save changes</Trans>
        </Button>

        <Button
          size='small'
          onClick={onCancelClick}
          className={styles.cancelButton}
        >
          <Trans>Cancel</Trans>
        </Button>
      </div>
    </>
  )
}

export default CourseEditFormStatus

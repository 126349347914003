import React from 'react'
import { Trans } from 'react-i18next'

import PropTypes from 'prop-types'

import { useAppData } from 'shared/contexts/AppData'

import AuthorizationWrapper from './authorization-wrapper'
import BaseApplicationsWidget from './base'

export default function MyResgistrationsWidget({ widget }) {
  const { applics } = useAppData()
  const templateIds = (applics || []).map((applic) => applic.template_id)

  return (
    <AuthorizationWrapper
      forRegistrations
      id={widget.id}
      title={<Trans>My Registrations</Trans>}
    >
      <BaseApplicationsWidget
        widgetId={widget.id}
        forRegistrations
        templateIds={templateIds}
        emptyMessage={
          <Trans>You haven't started or submitted a registration yet</Trans>
        }
      />
    </AuthorizationWrapper>
  )
}

MyResgistrationsWidget.propTypes = {
  widget: PropTypes.shape({
    id: PropTypes.string.isRequired
  }).isRequired
}

import { useEffect, useRef } from 'react'

import Pusher from 'pusher-js'

import { useSettings } from '@fullfabric/authorization-officer'

import { useAppData } from 'shared/contexts/AppData'

let pusherInstance

export default function usePusher({
  channelName,
  binds,
  onMessage,
  opts = {}
}) {
  const settings = useSettings()
  const { _handle: handle } = useAppData()
  const channelRef = useRef({})

  const config = settings.hash.integrations.pusher

  pusherInstance ||= new Pusher(config.key, {
    cluster: config.cluster,
    authEndpoint: '/api/pusher/auth',
    encrypted: config.encrypted
  })

  // Use Pusher to subscribe to the exports channel as soon as this hook is
  // used. Then, unsubscribe once the component is unmounted.
  useEffect(() => {
    channelRef.current = pusherInstance.subscribe(
      fullChannelName(channelName, handle, opts)
    )

    binds.forEach((bind) =>
      channelRef.current.bind(bind, (data) => onMessage({ bind, data }))
    )

    return () => {
      channelRef.current.unsubscribe()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return channelRef.current
}

function fullChannelName(channelName, handle, opts) {
  if (opts.tenant) channelName = `${handle}-${channelName}`
  if (opts.private) channelName = `private-${channelName}`
  return channelName
}

import { utils } from '@fullfabric/public-api'

const updateDegreeClasses = async (studyPlanId, data) => {
  const response = await fetch(
    `/affairs/api/study_plan/${studyPlanId}/degree_classes`,
    {
      credentials: 'same-origin',
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ degree_classes: data })
    }
  )

  return await utils.checkResponse(response)
}

export default updateDegreeClasses

import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '@fullfabric/alma-mater'

import CreationModal from './CreationModal'

function CreateEmailTemplateButton() {
  const { t } = useTranslation()
  const [isCreationModalOpen, setCreationModalOpen] = useState(false)

  return (
    <>
      <Button
        primary
        size='small'
        onClick={() => {
          setCreationModalOpen(true)
        }}
      >
        {t('Create a new email template')}
      </Button>

      {isCreationModalOpen ? (
        <CreationModal
          isOpen={isCreationModalOpen}
          handleClose={() => {
            setCreationModalOpen(false)
          }}
        />
      ) : null}
    </>
  )
}

export default CreateEmailTemplateButton

.react-app ._title_5dhgt_1 {
  text-transform: uppercase;
}

.react-app ._spaceBetweenTables_5dhgt_5 {
  margin-bottom: var(--space-big);
}

.react-app ._spaceBetweenTables_5dhgt_5._mobile_5dhgt_9 {
  margin-bottom: var(--space-more);
}
.react-app ._container_1h62c_1 {
  height: 100%;
}

.react-app ._imageCol_1h62c_5,
.react-app ._contentCol_1h62c_6 {
  height: 100%;
}

.react-app ._contentCol_1h62c_6 {
  overflow-y: auto;
}

.react-app ._appLocaleSelector_1h62c_14 {
  position: absolute;
  top: 0;
  right: 0;
}

.react-app ._skipLink_1h62c_20 {
  position: fixed;
  top: -200px;
}
.react-app ._skipLink_1h62c_20:focus, .react-app ._skipLink_1h62c_20:active {
  top: 0;
  z-index: 1;
}
.react-app ._skipLink_1h62c_20._mobile_1h62c_28 {
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
}
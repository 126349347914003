import React from 'react'
import { Trans } from 'react-i18next'

import { Text } from '@fullfabric/alma-mater'

import MappingTable from 'apps/Imports/components/mapping-table'
import { useImportFieldOptions } from 'apps/Imports/contexts/import-document'
import { useSelectedData } from 'apps/Imports/contexts/selected-data'
import getCsvMappings from 'apps/Imports/utils/get-csv-mappings'
import SummarySection from '../summary-section'

const MappingSummarySection = ({ url, stepId }) => {
  const { data } = useSelectedData()
  const mappingFieldOptions = useImportFieldOptions()

  const selectedMappings = getCsvMappings(mappingFieldOptions, data.mappings)

  return (
    <SummarySection linkTo={url} stepId={stepId}>
      <Text fontColor='text-base-darkest' marginBottom='default'>
        <Trans>Mapping</Trans>
      </Text>
      <MappingTable mappings={selectedMappings} />
    </SummarySection>
  )
}

export default MappingSummarySection

import React from 'react'
import { Route, Switch } from 'react-router-dom'

import StaffManagement from './StaffManagement'

export default function Staff() {
  return (
    <Switch>
      <Route path='/staff/management' component={StaffManagement} />
    </Switch>
  )
}

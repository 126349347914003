import React from 'react'

import classNames from 'classnames'
import styles from './styles.module.scss'

const EventDetailRow = ({ icon, noMargin, children }) => (
  <div className={classNames(styles.container, noMargin && styles.noMargin)}>
    <div className={styles.iconColumn}>{icon}</div>
    <div>{children}</div>
  </div>
)

export default EventDetailRow

import React, { useState } from 'react'
import { Trans } from 'react-i18next'

import { ActionList, BoxShadow, MoreIcon, Table } from '@fullfabric/alma-mater'
import { IfPermit } from '@fullfabric/authorization-officer'

import DeleteCourseModal from 'apps/Applics/components/StaffSubjectSelection/DeleteCourseModal'
import SubjectSelectionHeader from 'apps/Applics/components/StaffSubjectSelection/SubjectSelectionHeader'
import { useApplicData } from 'apps/Applics/contexts/StaffSubjectSelection/ApplicData'
import {
  useCoursesData,
  useStudyPlanData
} from 'apps/Applics/contexts/StaffSubjectSelection/StudyPlanData'
import courseTypes from 'apps/Applics/utils/constants/courseTypes'

import styles from './styles.module.scss'

const getPathCoursesByGroup = (studyPlan, selectedPath, courseGroup) => {
  return studyPlan?.courses.filter((course) =>
    course.course_types?.find(
      (courseType) =>
        courseGroup.id === courseType.group_id &&
        courseType.path_id === selectedPath.id
    )
  )
}

const SelectedCourseGroup = ({
  courseGroup,
  selectedPath,
  hasMultipleElectiveGroups = false
}) => {
  const studyPlan = useStudyPlanData()
  const applic = useApplicData()
  const availableCourses = useCoursesData()
  const [deleteModalInfo, setDeleteModalInfo] = useState({ open: false })

  const studyPlanSubjectsForType = getPathCoursesByGroup(
    studyPlan,
    selectedPath,
    courseGroup
  )
  const selectedStudyPlanCourses =
    applic?.primary_choice_class_study_plan_courses || []

  const studyPlanSubjectIdsForType = studyPlanSubjectsForType.map(
    (spSubject) => spSubject.id
  )

  const selectedCoursesForType =
    selectedStudyPlanCourses
      .filter((selected) =>
        studyPlanSubjectIdsForType.includes(selected.study_plan_subject_id)
      )
      .map((selected) => {
        const course = availableCourses?.find(
          (course) => course.id === selected.course_id
        )
        const studyPlanSubject = studyPlan.courses.find(
          (spSubj) => spSubj.id === selected.study_plan_subject_id
        )

        return {
          ...selected,
          course,
          studyPlanSubject
        }
      })
      .filter((x) => x) || []

  const handleDeleteCourse = (applicId, courseId, studyPlanSubjectId) => {
    openModal(applicId, courseId, studyPlanSubjectId)
  }

  const closeModal = () => {
    setDeleteModalInfo({ open: false })
  }

  const openModal = (applicId, courseId, studyPlanSubjectId) => {
    setDeleteModalInfo({ open: true, applicId, courseId, studyPlanSubjectId })
  }

  if (
    selectedCoursesForType.length === 0 &&
    courseGroup.type.toLowerCase() === courseTypes.ELECTIVE
  ) {
    return null
  }

  return (
    <>
      <DeleteCourseModal {...deleteModalInfo} handleClose={closeModal} />
      <BoxShadow className={styles.marginTop} bgColor='white' rounded type='2'>
        <SubjectSelectionHeader
          type={courseGroup.type}
          groupName={
            courseGroup.type.toLowerCase() ===
              courseTypes.ELECTIVE.toLowerCase() && hasMultipleElectiveGroups
              ? courseGroup.name
              : null
          }
          coursesLength={selectedCoursesForType?.length}
          maxSelected={courseGroup.max_mandatory_courses}
          minSelected={courseGroup.min_mandatory_courses}
        />
        <Table className={styles.table}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderItem>
                <Trans>Subject</Trans>
              </Table.HeaderItem>
              <Table.HeaderItem>
                <Trans>Course</Trans>
              </Table.HeaderItem>
              <Table.HeaderItem>
                <Trans>Credits</Trans>
              </Table.HeaderItem>
              <IfPermit to='applics.applications_change'>
                <Table.HeaderItem>
                  <Trans></Trans>
                </Table.HeaderItem>
              </IfPermit>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {selectedCoursesForType.length === 0 && (
              <Table.Row>
                <Table.Data colSpan='100' align='left' className={styles.empty}>
                  No courses selected
                </Table.Data>
              </Table.Row>
            )}
            {selectedCoursesForType.length > 0 &&
              selectedCoursesForType.map((selection) => (
                <Table.Row key={selection.id}>
                  <Table.Data main>
                    {selection.course?.subject?.name ||
                      selection.studyPlanSubject?.course?.name}
                  </Table.Data>
                  <Table.Data>
                    {selection.course?.name || <Trans>- Not selected -</Trans>}
                  </Table.Data>
                  <Table.Data>{selection.course?.credits}</Table.Data>
                  <IfPermit to='applics.applications_change'>
                    <Table.Data>
                      <ActionList
                        iconButton={<MoreIcon />}
                        iconButtonProps={{ small: true }}
                        menuPosition='down'
                        alignEnd={false}
                      >
                        <ActionList.Option
                          negative
                          onClick={() => {
                            handleDeleteCourse(
                              applic.id,
                              selection.course?.id,
                              selection.studyPlanSubject?.id
                            )
                          }}
                        >
                          <Trans>Delete course</Trans>
                        </ActionList.Option>
                      </ActionList>
                    </Table.Data>
                  </IfPermit>
                </Table.Row>
              ))}
          </Table.Body>
        </Table>
      </BoxShadow>
    </>
  )
}

export default SelectedCourseGroup

import { utils } from '@fullfabric/public-api'

const addPaymentPlanDiscount = async ({ profileId, paymentPlanId, code }) => {
  const data = {
    code,
    source: 'template'
  }

  const response = await fetch(
    `/book_keeper/api/profiles/${profileId}/plan/${paymentPlanId}/discounts`,
    {
      credentials: 'same-origin',
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      body: JSON.stringify(data)
    }
  )

  return await utils.checkResponse(response)
}

export default addPaymentPlanDiscount

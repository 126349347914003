const useFixedTableLayout = (hooks) => {
  hooks.getTableProps.push((props) => [
    props,
    { style: { tableLayout: 'fixed' } }
  ])

  hooks.getHeaderProps.push((props, { column, ...other }) => {
    return column.flexibleWidth
      ? [props, { flexibleWidth: true }]
      : [props, { style: { width: `${column.totalWidth}px` } }]
  })

  hooks.getCellProps.push((props) => [props, { style: { overflow: 'hidden' } }])
}

useFixedTableLayout.pluginName = 'useFixedTableLayout'

export default useFixedTableLayout

import React from 'react'

function ArrowIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='16'
      height='24'
      fill='none'
      viewBox='0 0 16 16'
    >
      <path fill='#6A6D77' d='M5 3l1-1 6 6-6 6-1-1 5-5-5-5z'></path>
    </svg>
  )
}

export default ArrowIcon

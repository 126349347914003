.react-app ._successBanner_17wyo_1 {
  margin-bottom: var(--space-big);
  box-sizing: border-box;
}
.react-app ._successBanner_17wyo_1 p {
  margin-left: var(--space-small);
}

.react-app ._createAnotherButton_17wyo_9 {
  margin-left: var(--space-small);
  font-size: 16px;
  line-height: 24px;
}
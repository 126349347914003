import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Dropdown, IconButton, MoreIcon } from '@fullfabric/alma-mater'

import DeleteSubjectConfirmationModal from './DeleteSubjectConfirmationModal'

const MoreOptionsCell = ({ row, data }) => {
  const { t } = useTranslation()
  const [
    isDeleteConfirmationModalVisible,
    setIsDeleteConfirmationModalVisible
  ] = useState()

  return (
    <>
      <Dropdown>
        <Dropdown.Toggle>
          <IconButton plain>
            <MoreIcon />
          </IconButton>
        </Dropdown.Toggle>

        <Dropdown.Menu aria-label={t('Row options menu')}>
          <Dropdown.Menu.Option
            negative
            aria-labelledby='deleteSubjectMenuOptionText'
            onClick={() => setIsDeleteConfirmationModalVisible(true)}
          >
            <span id='deleteSubjectMenuOptionText'>{t('Delete')}</span>
          </Dropdown.Menu.Option>
        </Dropdown.Menu>
      </Dropdown>

      <DeleteSubjectConfirmationModal
        subjectId={data[row.index]?.id}
        subjectName={data[row.index]?.name}
        isOpen={isDeleteConfirmationModalVisible}
        handleClose={() => setIsDeleteConfirmationModalVisible(false)}
      />
    </>
  )
}

export default MoreOptionsCell

import { utils } from '@fullfabric/public-api'

export default async function createAccount(
  data,
  privacyPolicy,
  hasAcceptedPrivacyPolicy
) {
  const digest = await utils.getPageDigest()

  const policies = privacyPolicy
    ? {
        [privacyPolicy.id]: {
          accepted: hasAcceptedPrivacyPolicy ? 'true' : 'false',
          options: hasAcceptedPrivacyPolicy ? { agreement: 'accepted' } : {}
        }
      }
    : {}

  const response = await fetch('/accounts', {
    method: 'POST',
    credentials: 'same-origin',
    headers: { 'Content-Type': 'application/json', 'X-FF-DIGEST': digest },
    body: JSON.stringify({
      ...data,
      password_confirmation: data.password,
      policies,
      auto_login: true
    })
  })

  return await utils.checkResponse(response)
}

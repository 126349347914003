import i18n from 'i18next'

import daResource from 'apps/Authentication/i18n/da/locales.json'
import deResource from 'apps/Authentication/i18n/de/locales.json'
import enResource from 'apps/Authentication/i18n/en/locales.json'
import esResource from 'apps/Authentication/i18n/es/locales.json'
import frResource from 'apps/Authentication/i18n/fr/locales.json'
import itResource from 'apps/Authentication/i18n/it/locales.json'
import nlResource from 'apps/Authentication/i18n/nl/locales.json'
import svResource from 'apps/Authentication/i18n/sv/locales.json'
import daResourceAuthViaApplicApp from 'apps/Authentication/i18nAuthViaApplicApp/da/locales.json'
import deResourceAuthViaApplicApp from 'apps/Authentication/i18nAuthViaApplicApp/de/locales.json'
import enResourceAuthViaApplicApp from 'apps/Authentication/i18nAuthViaApplicApp/en/locales.json'
import esResourceAuthViaApplicApp from 'apps/Authentication/i18nAuthViaApplicApp/es/locales.json'
import frResourceAuthViaApplicApp from 'apps/Authentication/i18nAuthViaApplicApp/fr/locales.json'
import itResourceAuthViaApplicApp from 'apps/Authentication/i18nAuthViaApplicApp/it/locales.json'
import nlResourceAuthViaApplicApp from 'apps/Authentication/i18nAuthViaApplicApp/nl/locales.json'
import svResourceAuthViaApplicApp from 'apps/Authentication/i18nAuthViaApplicApp/sv/locales.json'

export const loadAuthenticationI18n = (
  namespace = 'translation',
  i18nInstance = i18n
) => {
  i18nInstance.addResourceBundle(
    'da',
    namespace,
    daResourceAuthViaApplicApp,
    true,
    false
  )
  i18nInstance.addResourceBundle(
    'de',
    namespace,
    deResourceAuthViaApplicApp,
    true,
    false
  )
  i18nInstance.addResourceBundle(
    'en',
    namespace,
    enResourceAuthViaApplicApp,
    true,
    false
  )
  i18nInstance.addResourceBundle(
    'es',
    namespace,
    esResourceAuthViaApplicApp,
    true,
    false
  )
  i18nInstance.addResourceBundle(
    'fr',
    namespace,
    frResourceAuthViaApplicApp,
    true,
    false
  )
  i18nInstance.addResourceBundle(
    'it',
    namespace,
    itResourceAuthViaApplicApp,
    true,
    false
  )
  i18nInstance.addResourceBundle(
    'nl',
    namespace,
    nlResourceAuthViaApplicApp,
    true,
    false
  )
  i18nInstance.addResourceBundle(
    'sv',
    namespace,
    svResourceAuthViaApplicApp,
    true,
    false
  )

  i18nInstance.addResourceBundle('en', namespace, enResource, true, false)
  i18nInstance.addResourceBundle('de', namespace, deResource, true, false)
  i18nInstance.addResourceBundle('es', namespace, esResource, true, false)
  i18nInstance.addResourceBundle('fr', namespace, frResource, true, false)
  i18nInstance.addResourceBundle('nl', namespace, nlResource, true, false)
  i18nInstance.addResourceBundle('sv', namespace, svResource, true, false)
  i18nInstance.addResourceBundle('da', namespace, daResource, true, false)
  i18nInstance.addResourceBundle('it', namespace, itResource, true, false)
}

import 'css-chunk:src/apps/Imports/components/mapping-step/required-fields-section/styles.module.scss';export default {
  "react-app": "react-app",
  "requiredFieldsListContainer": "_requiredFieldsListContainer_u2oev_1",
  "requiredFieldsHeader": "_requiredFieldsHeader_u2oev_5",
  "requiredFieldDropdown": "_requiredFieldDropdown_u2oev_11",
  "arrow": "_arrow_u2oev_16",
  "requiredFieldColData": "_requiredFieldColData_u2oev_21",
  "requiredFieldsList": "_requiredFieldsList_u2oev_1",
  "samplesColumnHeader": "_samplesColumnHeader_u2oev_28",
  "samplesColumnText": "_samplesColumnText_u2oev_31",
  "textCol": "_textCol_u2oev_36"
};
import React from 'react'
import { useMutation } from 'react-query'

import api from 'apps/ContentPages/api'

const useStripeClientKey = (fee) => {
  const [stripeClientKey, setStripeClientKey] = React.useState(null)

  const payableType = fee._ruby_type

  const { mutate: createStripePaymentIntentMutation } = useMutation(
    ({ feeId }) => {
      return api.createStripePaymentIntent(payableType, feeId)
    },
    {
      onSuccess: (stripeIntentResult) => {
        setStripeClientKey(stripeIntentResult.client_secret)
      }
    }
  )

  React.useEffect(() => {
    createStripePaymentIntentMutation({ feeId: fee.id })
  }, [createStripePaymentIntentMutation, fee.id])

  return stripeClientKey
}

export default useStripeClientKey

/* eslint-disable promise/always-return */

import React, { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import axios from 'axios'
import qs from 'qs'

import { Field, Link, Text } from '@fullfabric/alma-mater'

import lookupUser from 'apps/Authentication/api/lookup-user'
import locationHelpers from 'apps/Authentication/utils/locationHelpers'
import Form from '..'
import { EmailField } from './email'
import PrivacyPolicyField from './policy'

import classNames from 'classnames'
import styles from './styles.module.scss'

const SignIn = ({
  data,
  setData,
  setStep,
  privacyPolicy,
  needsPrivacyPolicy,
  hasPassword
}) => {
  const { t } = useTranslation()
  const location = useLocation()
  const [resendingEmail, setResendingEmail] = useState(false)
  const [errors, setErrors] = useState({})

  useEffect(() => setErrors({}), [data])

  const redirectTo = `${location.pathname}${location.search}`

  const onSubmit = async ({ onError }) => {
    let queryData = `auth_key=${encodeURIComponent(
      data.email
    )}&password=${encodeURIComponent(data.password)}`

    if (privacyPolicy && needsPrivacyPolicy) {
      const { policies } = data

      if (!policies || !policies[privacyPolicy.id]) {
        setErrors({ ...errors, privacy: true })
        onError()
        return
      }

      queryData += `&${qs.stringify({ policies })}`
    }

    // taken from authentication app
    const requestParams = {
      url: '/auth/ff/callback',
      method: 'post',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      data: queryData
    }

    try {
      const response = await axios(requestParams)
      locationHelpers.goTo(response.data.url)
    } catch (error) {
      onError()
      setErrors({ auth: error.response.data.message })
    }
  }

  const resendAuthLink = async (e) => {
    e.preventDefault()

    setResendingEmail(true)
    await lookupUser(data.email, redirectTo)
    setTimeout(() => setResendingEmail(false), 3000)
  }

  return (
    <Form
      onSubmit={onSubmit}
      step='signIn'
      setStep={setStep}
      showModalActions={hasPassword}
    >
      <div className={classNames([styles.emailField])}>
        <EmailField data={data} setData={setData} errors={errors} />
      </div>

      <Text type='h2' fontColor='text-base-darkest' marginBottom='more'>
        <Trans>Welcome back</Trans>
      </Text>

      {hasPassword ? (
        <>
          <Text type='f4' fontColor='text-base-darkest'>
            <Trans>Please enter your password for security reasons.</Trans>
          </Text>

          <Text
            type='f5'
            fontColor='text-base-darker'
            marginTop='default'
            marginBottom='default'
          >
            <Trans>Email address </Trans>
            <Text type='h5' tag='span'>
              {' '}
              {data.email}
            </Text>
          </Text>

          <Field
            id='password'
            type='password'
            label={<Trans>Password</Trans>}
            onChange={(id, value) => setData({ ...data, [id]: value })}
            error={errors.auth}
            inputOptions={{ autoFocus: true }}
          ></Field>

          <div
            className={classNames([
              styles.link,
              privacyPolicy && needsPrivacyPolicy && styles.noSpace
            ])}
          >
            <Link href='/forgot'>
              <Trans>Forgot your password?</Trans>
            </Link>
          </div>

          {privacyPolicy && needsPrivacyPolicy && (
            <PrivacyPolicyField
              data={data}
              setData={setData}
              errors={errors.privacy}
              privacyPolicy={privacyPolicy}
            />
          )}
        </>
      ) : (
        <>
          <Text type='f4' fontColor='text-base-darkest'>
            {t(
              'An email has been sent to {{email}} with an authentication link that will take you to your application.',
              { email: data.email }
            )}
          </Text>

          <Text
            type='f5'
            fontColor='text-base-darker'
            marginTop='default'
            marginBottom='default'
          >
            <Trans>
              Once you have accessed the authentication link you can close this
              page.
            </Trans>
          </Text>

          <Text type='f5' fontColor='text-base-darkest' marginTop='huge'>
            <Trans>Didn’t receive the email?</Trans>
          </Text>

          <div className={styles.resendAuthLinkContainer}>
            {!resendingEmail ? (
              <Link href='/' onClick={resendAuthLink}>
                <Trans>Resend email with authentication link</Trans>
              </Link>
            ) : (
              <Text type='h5' fontColor='text-success'>
                <Trans>Email sent</Trans>
              </Text>
            )}
          </div>
        </>
      )}
    </Form>
  )
}

export default SignIn

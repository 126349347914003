import React from 'react'
import { Trans } from 'react-i18next'

import { AnnotatedLayout } from '@fullfabric/alma-mater'

import { useSettingsSectionsBeingEdited } from 'apps/StudyPlans/contexts/SettingsSectionsBeingEdited'
import CreditsDisplay from './Display'
import CreditsForm from './Form'

const CreditsSection = () => {
  const sectionsBeingEdited = useSettingsSectionsBeingEdited()

  return (
    <AnnotatedLayout.Section
      title={<Trans>Credits</Trans>}
      description={
        <Trans>
          Define the required number of credits for completion of the programme.
        </Trans>
      }
    >
      {sectionsBeingEdited.credits ? <CreditsForm /> : <CreditsDisplay />}
    </AnnotatedLayout.Section>
  )
}

export default CreditsSection

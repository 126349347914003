.react-app ._modalBody_7ohrq_1 {
  overflow: visible;
}
.react-app ._modalBody_7ohrq_1 p {
  margin: 0;
}

.react-app ._modal_7ohrq_1 p {
  margin: 0;
}

.react-app ._marginError_7ohrq_12 {
  margin-top: var(--space-tiny) !important;
}
import React from 'react'

import { Text } from '@fullfabric/alma-mater'

import styles from './styles.module.scss'

const CardInfoBullet = ({ main, secondary }) => (
  <Text
    type='f4'
    fontColor='text-base-darkest'
    className={styles.text}
    marginBottom='small'
  >
    {main}
    {secondary ? (
      <span className={styles.textBaseColor}> - {secondary}</span>
    ) : null}
  </Text>
)

export default CardInfoBullet

import React from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

import { ArrowLeftIcon, ClipBoardIcon, PageHead } from '@fullfabric/alma-mater'

import getProfile from 'apps/Evaluations/api/getProfile'
import { useApplication } from 'apps/Evaluations/context/ApplicationData'

const EvaluationsListPageHeader = ({ profileId, applicationId, action }) => {
  const { data: profileData, isLoading: isLoadingProfile } = useQuery(
    ['getProfile', profileId],
    () => getProfile(profileId)
  )

  const { data: applicData } = useApplication()

  const { t } = useTranslation()
  return (
    <PageHead
      noBorder
      breadcrumbs={[
        {
          label: t('Back to application'),
          icon: ArrowLeftIcon,
          href: `/profiles/${profileId}#applications/${applicationId}`,
          target: '_top'
        }
      ]}
      prefix={isLoadingProfile ? t('Loading') : profileData?.full_name}
      title={t('Evaluations')}
      secondaryInfo={
        <PageHead.SecondaryInfo icon={<ClipBoardIcon />}>
          {applicData?.name}
        </PageHead.SecondaryInfo>
      }
      action={action}
    />
  )
}

export default EvaluationsListPageHeader

import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Row, Text } from '@fullfabric/alma-mater'

import CompleteCheck from 'apps/Applics/components/StaffSubjectSelection/CompleteCheck'
import courseTypes from 'apps/Applics/utils/constants/courseTypes'

import styles from './styles.module.scss'

const minNumberString = (minSelected, t) => {
  if (!minSelected) {
    return minSelected
  }

  const count = minSelected

  return <span key={count}>{t('minSelection', { count })}</span>
}

const maxNumberString = (maxSelected, t) => {
  if (!maxSelected) {
    return maxSelected
  }

  const count = maxSelected

  return <span key={count}>{t('maxSelection', { count })}</span>
}

const SubjectSelectionHeader = ({
  type,
  groupName,
  coursesLength,
  maxSelected,
  minSelected
}) => {
  const { t } = useTranslation()
  const minString = minNumberString(minSelected, t)
  const maxString = maxNumberString(maxSelected, t)

  const electiveConstraints = [minString, maxString].filter((str) => !!str)

  if (electiveConstraints.length === 2) {
    electiveConstraints.splice(1, 0, <span key='and'> and </span>)
  }

  const electiveString =
    electiveConstraints.length > 0
      ? electiveConstraints
      : 'all of these courses are optional'

  return (
    <div className={styles.container}>
      <Row nogutter justify='between'>
        <div>
          <Text
            type='f3'
            fontColor='text-base-darkest'
            className={styles.upperCase}
          >
            {groupName
              ? t('{{type}} Courses - {{groupName}}', { type, groupName })
              : t('{{type}} Courses', { type })}
          </Text>
          {type === courseTypes.CORE && (
            <Text
              type='f6'
              fontColor='text-base-darker'
              className={styles.upperCase}
            >
              <Trans>All of these courses are required.</Trans>
            </Text>
          )}
          {type === courseTypes.ELECTIVE && (
            <Text
              type='f6'
              fontColor='text-base-darker'
              className={styles.upperCase}
            >
              {electiveString}.
            </Text>
          )}
        </div>
        <div>
          <CompleteCheck
            coursesLength={coursesLength}
            maxSelected={maxSelected}
            minSelected={minSelected}
          />
        </div>
      </Row>
    </div>
  )
}

export default SubjectSelectionHeader

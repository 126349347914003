import React from 'react'
import { CellMeasurer } from 'react-virtualized'

import { Table } from '@fullfabric/alma-mater'

import cx from 'classnames'
import styles from './styles.module.scss'

const useRenderCell = (prepareRow, rows, cellMeasureCache, data) => {
  return React.useCallback(
    ({ columnIndex, rowIndex, dataKey, parent }) => {
      const row = rows[rowIndex]
      prepareRow(row)

      const cell = row.cells[columnIndex]
      const {
        key: cellKey,
        linkTo: getLinkTo,
        ...cellProps
      } = cell.getCellProps()

      const linkTo =
        getLinkTo &&
        getLinkTo({
          value: cell.value,
          row,
          data
        })

      return (
        <CellMeasurer
          key={dataKey}
          cache={cellMeasureCache}
          columnIndex={columnIndex}
          parent={parent}
          rowIndex={rowIndex}
        >
          {({ registerChild }) => (
            <div ref={registerChild}>
              <Table.Data
                tag='div'
                key={cellKey}
                className={cx(
                  styles.cell,
                  cellProps.numeric && styles.numericCell
                )}
                title={cell.value}
                linkTo={linkTo}
                {...cellProps}
              >
                {cell.render('Cell')}
              </Table.Data>
            </div>
          )}
        </CellMeasurer>
      )
    },
    [prepareRow, rows, cellMeasureCache, data]
  )
}

export default useRenderCell

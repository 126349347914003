import { utils } from '@fullfabric/public-api'

const getApplicsBy = async (profileId) => {
  const response = await fetch(
    `/api/applics/applics_by?profile_id=${profileId}`,
    { credentials: 'same-origin' }
  )

  return await utils.checkResponse(response)
}

export default getApplicsBy

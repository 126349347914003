import React from 'react'
import { Trans } from 'react-i18next'

import { FeedbackMessage } from '@fullfabric/alma-mater'

import {
  useCloseScheduledToComplete,
  useScheduledToComplete,
  useUnsetScheduledToComplete
} from 'apps/Inbox/contexts/scheduledToComplete'

import styles from './styles.module.scss'

const InboxCompletedNotification = () => {
  const scheduledToComplete = useScheduledToComplete()
  const unsetScheduledToComplete = useUnsetScheduledToComplete()
  const closeScheduleToComplete = useCloseScheduledToComplete()

  // TODO: check best way to do this
  React.useEffect(() => {
    if (scheduledToComplete.length > 0) {
      setTimeout(() => closeScheduleToComplete(), 5000)
    }
  }, [scheduledToComplete, closeScheduleToComplete])

  return (
    <>
      {scheduledToComplete
        .filter((data) => data.pending === true && data.hide !== true)
        .map((data) => (
          <FeedbackMessage
            key={data.task.id}
            className={styles.feedbackMessage}
            isOpen={scheduledToComplete.length > 0}
            message={<Trans>Task completed</Trans>}
            type='success'
            onClose={() => closeScheduleToComplete(data)}
            onUndo={() => unsetScheduledToComplete(data)}
          />
        ))}
    </>
  )
}

export default InboxCompletedNotification

import React from 'react'
import { Trans } from 'react-i18next'

import { Text } from '@fullfabric/alma-mater'

import useIsPaymentsMobileLayout from 'apps/ContentPages/components/payments-page/use-is-payments-mobile-layout'
import PaymentsPageLayout from '../layout'
import UpcomingFeesWidget from './upcoming-fees-widget'

import classNames from 'classnames'
import styles from './styles.module.scss'

const getMostRecentUnpaidFee = (paymentPlan) => {
  const sortedFees = [...(paymentPlan.fees || [])].sort(
    (feeA, feeB) => feeA.due_date - feeB.due_date
  )

  const fee = sortedFees.filter(
    (fee) =>
      !fee.is_paid && !fee.has_pending_payment && !fee.external_payer_request
  )[0]
  return fee ? { fee, paymentPlan } : null
}

const AlertWidgetArea = ({ paymentPlans, isLoading }) => {
  const isMobileLayout = useIsPaymentsMobileLayout()

  const urgentFees = paymentPlans
    ? paymentPlans
        .map(getMostRecentUnpaidFee)
        .filter((fee) => !!fee)
        .sort((feeA, feeB) => feeA.fee.due_date - feeB.fee.due_date)
    : []

  const hasUrgentFees = urgentFees?.length > 0

  if (isLoading) {
    return (
      <PaymentsPageLayout.WidgetArea label='payment alerts area'>
        {isMobileLayout && (
          <Text
            type='h6'
            fontColor='text-base-darker'
            marginTop='more'
            marginLeft='default'
            className={styles.mobileTitle}
          >
            <Trans>Alerts</Trans>
          </Text>
        )}
        <PaymentsPageLayout.Placeholder
          className={classNames(
            isMobileLayout && styles.mobileLoadingPlaceholder
          )}
        />
      </PaymentsPageLayout.WidgetArea>
    )
  }

  if (!hasUrgentFees) {
    return isMobileLayout ? (
      <div className={styles.marginBetweenRegions} />
    ) : (
      <div className={styles.marginToEdgeOfPage} />
    )
  }

  return (
    <PaymentsPageLayout.WidgetArea label='payment alerts area'>
      {isMobileLayout && (
        <Text
          type='h6'
          fontColor='text-base-darker'
          className={styles.mobileTitle}
          marginTop='more'
          marginLeft='default'
        >
          <Trans>Alerts</Trans>
        </Text>
      )}
      <UpcomingFeesWidget
        urgentFees={urgentFees}
        discounts={paymentPlans.reduce(
          (all, plan) => [...all, ...(plan.discounts || [])],
          []
        )}
      />
    </PaymentsPageLayout.WidgetArea>
  )
}

export default AlertWidgetArea

import React from 'react'
import { Trans } from 'react-i18next'

import { AnnotatedLayout } from '@fullfabric/alma-mater'

const SubjectBasicsSection = ({ children }) => (
  <AnnotatedLayout.Section
    title={<Trans>Basic</Trans>}
    description={
      <Trans>
        A subject represents the study of a particular field. A subject consists
        of courses, where each course is for a specific time period.
      </Trans>
    }
  >
    {children}
  </AnnotatedLayout.Section>
)

export default SubjectBasicsSection

import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { ArrowLeftIcon, Breadcrumbs, Row, Text } from '@fullfabric/alma-mater'

import { useMergeData } from 'apps/MergeProfiles/contexts/MergeData'
import Progress from './Progress'

import styles from './styles.module.scss'

const Sidebar = () => {
  const { t } = useTranslation()
  const {
    mergeData: { targetProfileId }
  } = useMergeData()

  return (
    <aside className={styles.container}>
      <Row nogutter>
        <Breadcrumbs
          breadcrumbs={[
            {
              label: t('Cancel'),
              icon: ArrowLeftIcon,
              href: `/profiles/${targetProfileId}#settings/mergeProfiles`
            }
          ]}
        />
      </Row>
      <Row nogutter>
        <Text
          type='f1'
          fontColor='text-base-darkest'
          marginTop='tiny'
          marginBottom='huge'
        >
          <Trans>Merge profiles</Trans>
        </Text>
      </Row>

      <Progress />
    </aside>
  )
}

export default Sidebar

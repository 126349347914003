import React from 'react'
import { Trans } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'

import {
  AlertFullIcon,
  Button,
  Modal,
  ModalButtons,
  ModalTitle,
  Text
} from '@fullfabric/alma-mater'

import patchCourse from 'apps/Applics/api/patchCourse'

import styles from './styles.module.scss'

const ChangePathWarning = ({ isOpen, applic, pathId, handleClose }) => {
  const queryClient = useQueryClient()

  const { mutate: changePathMutation, isLoading: loading } = useMutation(
    patchCourse,
    {
      onSuccess: (data) => {
        queryClient.setQueryData(['applic', applic.id], data)
        handleClose()
      }
    }
  )

  const handleChangePath = () => {
    const body = {
      primary_choice_class_path_id: pathId,
      primary_choice_class_study_plan_courses: []
    }

    changePathMutation({ applicId: applic.id, body })
  }

  return (
    <>
      <Modal
        isOpen={isOpen}
        size='medium'
        header={
          <ModalTitle
            title={<Trans>Change Path</Trans>}
            iconProps={{
              icon: AlertFullIcon,
              color: 'text-alert'
            }}
          />
        }
        footer={
          <ModalButtons>
            <Button onClick={handleClose}>
              <Trans>Cancel</Trans>
            </Button>
            <Button
              primary
              onClick={handleChangePath}
              loading={loading}
              role='change'
            >
              <Trans>Change Path</Trans>
            </Button>
          </ModalButtons>
        }
        className={styles.modal}
      >
        <Text type='h4' fontColor='text-base-darkest'>
          <Trans>Are you ABSOLUTELY sure?</Trans>
        </Text>
        <Text type='f5' fontColor='text-base-darkest'>
          <Trans>This action CANNOT be undone.</Trans>
        </Text>
        <Text
          type='h5'
          fontColor='text-base-darkest'
          className={styles.warningSeparation}
        >
          <Trans>This action will:</Trans>
        </Text>
        <Text type='f5' fontColor='text-base-darker'>
          <Trans>Change the path of the programme choice</Trans>
        </Text>
        <Text type='f5' fontColor='text-base-darker'>
          <Trans>Delete all courses selected on the application</Trans>
        </Text>
        <Text
          type='h5'
          fontColor='text-base-darkest'
          className={styles.warningSeparation}
        >
          <Trans>This action will NOT:</Trans>
        </Text>
        <Text type='f5' fontColor='text-base-darker'>
          <Trans>Add new courses for the new path to the application</Trans>
        </Text>
      </Modal>
    </>
  )
}

export default ChangePathWarning

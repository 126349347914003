import React from 'react'
import { Trans } from 'react-i18next'

import AppLayout from 'apps/Authentication/components/AppLayout'
import AuthContentLayout from 'apps/Authentication/components/AuthContentLayout'
import ChooseNewPasswordForm from 'apps/Authentication/components/ChooseNewPasswordForm'

const ChooseNewPasswordPage = ({ type, token }) => {
  return (
    <AppLayout>
      <AuthContentLayout>
        <AuthContentLayout.Body>
          <ChooseNewPasswordForm type={type} token={token} />
        </AuthContentLayout.Body>
        {type !== 'expired' && (
          <AuthContentLayout.Footer
            question={<Trans>Already have an account?</Trans>}
            linkTo={'/login'}
            linkText={<Trans>Sign in</Trans>}
          />
        )}
      </AuthContentLayout>
    </AppLayout>
  )
}

export default ChooseNewPasswordPage

import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

import {
  ArrowRightIcon,
  IconButton,
  InfoIcon,
  Link,
  Loading,
  Table,
  Text
} from '@fullfabric/alma-mater'

import getStudyPlans from 'apps/Reports/api/getStudyPlans'

import styles from './styles.module.scss'

const isValidStudyPlan = (sp) => !!sp.class_of

const LinkedClassesSubTable = ({ data, row, tableProps }) => {
  const { t } = useTranslation()
  const course = data[row.index]
  const courseId = course?.id
  const nColumns = tableProps.visibleColumns?.length || 0

  const {
    data: rawStudyPlans,
    isLoading,
    isError
  } = useQuery(
    ['getStudyPlansWhichACourseIsIn', courseId],
    () => getStudyPlans({ targetCourseId: courseId }),
    {
      enabled: !!courseId
    }
  )

  const [studyPlans, setStudyPlans] = React.useState(rawStudyPlans)
  React.useLayoutEffect(() => {
    setStudyPlans(rawStudyPlans?.filter(isValidStudyPlan))
  }, [rawStudyPlans])

  if (isLoading) {
    return (
      <Table.Row>
        <Table.Data
          className={styles.alertCell}
          colSpan={nColumns}
          aria-label={t('Loading classes with this course included')}
        >
          <Loading />
        </Table.Data>
      </Table.Row>
    )
  }

  if (isError) {
    return (
      <Table.Row>
        <Table.Data className={styles.alertCell} colSpan={nColumns}>
          <span className={styles.centeredCellContent}>
            <InfoIcon aria-hidden='true' />
            <Text tag='span' marginLeft='small'>
              <Trans>
                There was an error, please try again in a few seconds. If the
                issue persists, please contact us.
              </Trans>
            </Text>
          </span>
        </Table.Data>
      </Table.Row>
    )
  }

  if (!studyPlans || studyPlans.length === 0) {
    return (
      <Table.Row>
        <Table.Data className={styles.alertCell} colSpan={nColumns}>
          <span className={styles.centeredCellContent}>
            <InfoIcon aria-hidden='true' />
            <Text tag='span' marginLeft='small'>
              <Trans>This course can only be taken individually</Trans>
            </Text>
          </span>
        </Table.Data>
      </Table.Row>
    )
  }

  return (
    <>
      <Table.Row>
        <Table.Data className={styles.tableTitleCell} colSpan={nColumns}>
          <Text tag='span' type='h6' fontColor='text-base-darker'>
            <Trans>Classes with this course included:</Trans>
          </Text>
        </Table.Data>
      </Table.Row>
      {studyPlans.map((studyPlan) => (
        <Table.Row key={studyPlan.id} className={styles.tableDataRow}>
          <Table.Data colSpan={nColumns - 2}>
            {studyPlan.class_of?.programme_name}
          </Table.Data>
          <Table.Data>
            <Table.CellLink
              href={`/classes/${studyPlan.class_of_id}`}
              target='_parent'
            >
              {studyPlan.class_of?.name}
            </Table.CellLink>
          </Table.Data>
          <Table.Data>
            <Link
              withoutStyles
              to={`/reports/product_performance/${studyPlan.class_of_id}`}
              role='link'
              aria-label={t('show class products')}
            >
              <IconButton plain tag='div' aria-hidden='true'>
                <ArrowRightIcon />
              </IconButton>
            </Link>
          </Table.Data>
        </Table.Row>
      ))}
    </>
  )
}

export default LinkedClassesSubTable

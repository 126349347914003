import { useMutation } from 'react-query'

import createImport from 'apps/Imports/api/create-import'
import { useSetImportDocumentData } from 'apps/Imports/contexts/import-document'
import { useSelectedData } from 'apps/Imports/contexts/selected-data'
import { useCommunicationPolicies } from 'apps/Imports/hooks/useCommunicationPolicies'
import { useDataProcessingPolicy } from 'apps/Imports/hooks/useDataProcessingPolicy'
import { useImportContext } from 'apps/Imports/hooks/useImportContext'
import { useMarketingPolicy } from 'apps/Imports/hooks/useMarketingPolicy'
import getProfileImportFormData from 'apps/Imports/utils/profile-import/get-profile-import-form-data'
import useGoToStep from './use-go-to-step'

const useCreateImportMutation = (nextStep = null) => {
  const context = useImportContext()
  const marketingPolicy = useMarketingPolicy()
  const dataProcessingPolicy = useDataProcessingPolicy()
  const communicationPolicies = useCommunicationPolicies()
  const { data, onDataChanged } = useSelectedData()

  const { setImportDocument, setImportMappableFields, setImportErrors } =
    useSetImportDocumentData()

  const { goToNextStepPage, goToStepPage } = useGoToStep()

  return useMutation(
    async () => {
      const formData = await getProfileImportFormData({
        data,
        context,
        marketingPolicy,
        dataProcessingPolicy,
        communicationPolicies
      })

      return await createImport(formData)
    },
    {
      onSuccess: (importData) => {
        onDataChanged('mappings', importData.columns)
        setImportErrors(null)
        setImportDocument(importData.document)
        setImportMappableFields(importData.mapping_options)

        if (nextStep) {
          goToStepPage(nextStep)
        } else {
          goToNextStepPage()
        }
      },
      onError: (err) => {
        setImportErrors(err)
        if (nextStep) {
          goToStepPage(nextStep)
        } else {
          goToNextStepPage()
        }
      }
    }
  )
}

export default useCreateImportMutation

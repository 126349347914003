export const EVENT_PAGE_ROUTE = '/e/:id'
export const FORM_PAGE_ROUTE = '/f/:id'
export const MY_APPLICATIONS_PAGE_ROUTE = '/my-applications'
export const MY_REGISTRATIONS_PAGE_ROUTE = '/my-registrations'
export const PAYMENTS_PAGE_ROUTE = '/payments'
export const APPLIC_PAYMENTS_PAGE_ROUTE = '/payments/application/:applicId'
export const EXTERNAL_PAYER_PAYMENT_PAGE_ROUTE =
  '/payments/external/:externalPayerRequestId'
export const PRODUCT_CATALOG_PAGE_ROUTE = '/product-catalog'
export const PRODUCT_CATALOG_DETAILS_PAGE_ROUTE = '/product-catalog/:productId'
export const STUDENT_HUB_PREVIEW_PAGE_ROUTE = '/content/pages/:pageId/preview'
export const STUDENT_HUB_PAGE_ROUTE = '/*'

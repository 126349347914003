import React from 'react'

import Logo from '../logo'

import styles from './styles.module.scss'

const LandingPageLogo = () => {
  return (
    <div className={styles.container}>
      <a href={'/'} aria-label='Go to home page'>
        <Logo />
      </a>
    </div>
  )
}

export default LandingPageLogo

.react-app ._table_14iax_1 {
  width: 100%;
  border: none;
}
.react-app ._table_14iax_1,
.react-app ._table_14iax_1 thead,
.react-app ._table_14iax_1 tr,
.react-app ._table_14iax_1 th,
.react-app ._table_14iax_1 td {
  border-collapse: collapse;
}

.react-app ._headerCell_14iax_13 {
  padding: var(--space-more) 0;
  vertical-align: middle;
}

.react-app ._leftColumn_14iax_18 {
  text-align: left;
  padding-left: var(--space-more);
}

.react-app ._rightColumn_14iax_23 {
  padding-right: var(--space-more);
  text-align: right;
}

.react-app ._dataCell_14iax_28 {
  vertical-align: top;
  padding-bottom: var(--space-more);
}

.react-app ._totalRow_14iax_33 {
  background-color: var(--ff-bg-base-lighter);
}

.react-app ._totalCell_14iax_37 {
  padding-top: var(--space-default);
  padding-bottom: var(--space-default);
}

.react-app ._mobile_14iax_42 ._leftColumn_14iax_18 {
  padding-left: var(--space-default);
}
.react-app ._mobile_14iax_42 ._rightColumn_14iax_23 {
  padding-right: var(--space-default);
}
import React from 'react'

import { Text } from '@fullfabric/alma-mater'

import { useSetSelectedPathId } from 'apps/StudyPlans/contexts/SelectedPath'

import classNames from 'classnames'
import styles from './styles.module.scss'

const PathItemButton = ({ id, name, selected }) => {
  const setSelectedPathId = useSetSelectedPathId()
  const onClick = React.useCallback(
    () => setSelectedPathId(id),
    [id, setSelectedPathId]
  )

  return (
    <Text
      tag='button'
      bold
      onClick={onClick}
      marginLeft='default'
      paddingTopBottom='tiny'
      paddingLeftRight='less'
      className={classNames(styles.itemButton, selected && styles.selected)}
    >
      {name}
    </Text>
  )
}

export default PathItemButton

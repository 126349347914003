import React from 'react'
import { useTranslation } from 'react-i18next'

import { Alert } from '@fullfabric/alma-mater'

import { useMergeData } from 'apps/MergeProfiles/contexts/MergeData'
import { useProfileSchema } from 'apps/MergeProfiles/contexts/ProfileSchema'
import FieldSection from './FieldSection'
import HeaderRow from './Rows/Header'
import LoadingRow from './Rows/Loading'
import SmartEmailSection from './SmartEmailSection'

import styles from './styles.module.scss'

const ProfileInfoSelector = () => {
  const { mergeError } = useMergeData()
  const { t } = useTranslation()
  const { data: profileSchema, isLoading } = useProfileSchema()

  const errorAlert = mergeError.profileInfoFromFieldsWithoutSelection?.length >
    0 && (
    <Alert
      error
      className={styles.errorAlert}
      title={t('Errors found')}
      description={t('A selection must be made for all fields')}
    />
  )

  return (
    <div className={styles.mainContainer}>
      {errorAlert}

      <SmartEmailSection />

      <HeaderRow />

      {isLoading && <LoadingRow />}

      {profileSchema?.sections
        .sort((a, b) => a.order - b.order)
        .map((section) => (
          <FieldSection key={section.id} section={section} />
        ))}

      {errorAlert}
    </div>
  )
}

export default ProfileInfoSelector

import {
  usePageContent,
  usePageData,
  usePageMainTextContent,
  usePageWidgets
} from './consumer'
import { CurrentPageDataProvider } from './provider'

export {
  CurrentPageDataProvider,
  usePageData,
  usePageContent,
  usePageMainTextContent,
  usePageWidgets
}

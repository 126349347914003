/* eslint-disable camelcase */

import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Text } from '@fullfabric/alma-mater'

import SubjectsSection from 'apps/StudyPlans/components/SubjectGroupsList/SubjectsSection'
import { useStudyPlan } from 'apps/StudyPlans/contexts/StudyPlanData'
import GroupSettingsActions from './actions'

const ElectiveSubjectsSection = ({ electiveGroup, multipleElectiveGroups }) => {
  const { t } = useTranslation()
  const studyPlan = useStudyPlan()

  const subjects = studyPlan?.courses.filter(
    (course) =>
      course.course_types.filter(
        ({ group_id }) => group_id === electiveGroup.id
      ).length > 0
  )

  return (
    <SubjectsSection
      subjects={subjects}
      title={
        multipleElectiveGroups ? (
          <Trans>
            Elective subjects -{' '}
            <Text type='f3' tag='span' fontColor='text-base-darker'>
              {electiveGroup.name}
            </Text>
          </Trans>
        ) : (
          <Trans>Elective subjects</Trans>
        )
      }
      description={
        <>
          <Trans>Students can choose which elective subjects to study.</Trans>{' '}
          <Text small bold tag='span'>
            {t(
              'Required number of subjects: Minimum = {{min}}, Maximum = {{max}}',
              {
                min: electiveGroup.min_mandatory_courses || t('None'),
                max: electiveGroup.max_mandatory_courses || t('None'),
                nsSeparator: false
              }
            )}
          </Text>
        </>
      }
      actions={
        <GroupSettingsActions
          electiveGroup={electiveGroup}
          multipleElectiveGroups={multipleElectiveGroups}
        />
      }
    />
  )
}

export default ElectiveSubjectsSection

import React from 'react'
import { useInfiniteQuery } from 'react-query'

import fetchTableDataFn from '../fetchTableDataFn'

const DEFAULT_LOAD_MORE_PARAMS = {
  resultTotalKey: 'total',
  pageSize: 20,
  resultDataKey: 'data',
  sizeKey: 'limit',
  offsetKey: 'offset'
}

const useTableQuery = ({
  fetchURL,
  fetchFn,
  fetchParams,
  sortState,
  searchState,
  filterState,
  fetchOptions,
  fetchedDataParser,
  fetchResponseParser,
  loadMoreProps,
  reactQueryProps
}) => {
  const loadMoreConfig = React.useMemo(
    () =>
      loadMoreProps ? { ...DEFAULT_LOAD_MORE_PARAMS, ...loadMoreProps } : null,
    [loadMoreProps]
  )

  const {
    data,
    error,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage
  } = useInfiniteQuery(
    [
      'tableDataFetch',
      fetchURL,
      fetchFn,
      { ...fetchParams, ...sortState, ...searchState, ...filterState },
      fetchOptions,
      fetchedDataParser,
      fetchResponseParser,
      loadMoreConfig
    ],
    fetchTableDataFn,
    {
      retry: 2,
      getNextPageParam: (lastPage) =>
        loadMoreConfig &&
        (lastPage.page + 1) * loadMoreConfig.pageSize <
          lastPage[loadMoreConfig.resultTotalKey]
          ? lastPage.page + 1
          : false,
      ...(reactQueryProps || {})
    }
  )

  const onLoadMoreClick = React.useCallback(() => {
    fetchNextPage()
  }, [fetchNextPage])

  const [loadedPageNumbers, setLoadedPageNumbers] = React.useState([])
  React.useEffect(() => {
    const loadedPages = data?.pageParams?.map((x) => x || 0) || []
    const lastPageNumber = Math.max(...loadedPages)
    if (lastPageNumber >= 0 && !loadedPageNumbers.includes(lastPageNumber))
      setLoadedPageNumbers((current) => [...current, lastPageNumber])
  }, [data, setLoadedPageNumbers, loadedPageNumbers])

  const fetchedData = loadMoreConfig
    ? data?.pages?.reduce(
        (acc, pageData) => [...acc, ...pageData[loadMoreConfig.resultDataKey]],
        []
      )
    : data?.pages?.reduce((acc, pageData) => [...acc, ...pageData], [])

  const loadedPages = data?.pageParams?.map((x) => x || 0) || []
  const lastPageNumber = Math.max(...loadedPages)
  const lastPageData = data?.pages?.filter((p) => p.page === lastPageNumber)[0]

  return {
    fetchedData,
    error,
    onLoadMoreClick: loadMoreConfig && hasNextPage && onLoadMoreClick,
    isInitialDataLoading:
      isFetching &&
      !isFetchingNextPage &&
      !loadedPageNumbers.includes(lastPageNumber),
    loadMoreButtonProps: loadMoreConfig && {
      loadingNextPage: isFetchingNextPage,
      page: lastPageNumber,
      pageSize: loadMoreConfig.pageSize,
      total: lastPageData ? lastPageData[loadMoreConfig.resultTotalKey] : 0,
      hasNextPage
    }
  }
}

export default useTableQuery

const getChoices = (applicData, templateData, t) => {
  if (!applicData || !templateData) return { choiceOptions: [] }

  const {
    primary_choice_document_and_field: templatePrimaryChoice,
    secondary_choice_document_and_field: templateSecondaryChoice
  } = templateData

  const templateHasOnlyOneChoice =
    (templatePrimaryChoice && !templateSecondaryChoice) ||
    (!templatePrimaryChoice && templateSecondaryChoice)

  if (templateHasOnlyOneChoice) {
    return { choiceOptions: [] }
  }

  const templateHasBothChoices =
    templatePrimaryChoice && templateSecondaryChoice

  if (templateHasBothChoices) {
    const GENERIC_OPTION = {
      label: t('Generic evaluation, not for a specific product'),
      value: 'none'
    }

    const DISABLED_PRIMARY = {
      label: t('Primary choice not available'),
      disabled: true,
      value: '__NOT_SELECTABLE__'
    }

    const DISABLED_SECONDARY = {
      label: t('Secondary choice not available'),
      disabled: true,
      value: '__NOT_SELECTABLE__'
    }

    const applicHasPrimaryChoice = applicData.primary_choice_contexts?.length
    const applicHasSecondaryChoice =
      applicData.secondary_choice_contexts?.length
    const applicHasNoChoice =
      !applicHasPrimaryChoice && !applicHasSecondaryChoice

    if (applicHasNoChoice) {
      return {
        choiceOptions: [DISABLED_PRIMARY, DISABLED_SECONDARY, GENERIC_OPTION],
        initialValue: 'none'
      }
    }

    const primaryCampusDesc =
      applicData.primary_choice_contexts &&
      applicData.primary_choice_contexts[0]?.campus_name
        ? ` - ${applicData.primary_choice_contexts[0].campus_name}`
        : ''

    const secondaryCampusDesc =
      applicData.secondary_choice_contexts &&
      applicData.secondary_choice_contexts[0]?.campus_name
        ? ` - ${applicData.secondary_choice_contexts[0].campus_name}`
        : ''

    if (applicHasPrimaryChoice && applicHasSecondaryChoice) {
      return {
        choiceOptions: [
          {
            label: t('Primary choice'),
            instructions: `${applicData.primary_choice_contexts[0].context_long_name}${primaryCampusDesc}`,
            value: 'primary'
          },
          {
            label: t('Secondary choice'),
            instructions: `${applicData.secondary_choice_contexts[0].context_long_name}${secondaryCampusDesc}`,
            value: 'secondary'
          },
          GENERIC_OPTION
        ],
        initialValue: 'primary'
      }
    }

    // applic has just one choice filled in
    if (applicHasPrimaryChoice) {
      // applic has just primary choice filled in
      return {
        choiceOptions: [
          {
            label: t('Primary choice'),
            instructions: `${applicData.primary_choice_contexts[0].context_long_name}${primaryCampusDesc}`,
            value: 'primary'
          },
          DISABLED_SECONDARY,
          GENERIC_OPTION
        ],
        initialValue: 'primary'
      }
    }

    if (applicHasSecondaryChoice) {
      // applic has just secondary choice filled in
      return {
        choiceOptions: [
          DISABLED_PRIMARY,
          {
            label: t('Secondary choice'),
            instructions: `${applicData.secondary_choice_contexts[0].context_long_name}${secondaryCampusDesc}`,
            value: 'secondary'
          },
          GENERIC_OPTION
        ],
        initialValue: 'secondary'
      }
    }
  }

  // template has no product choice
  return { choiceOptions: [] }
}

export default getChoices

import React from 'react'
import { Trans } from 'react-i18next'
import { useQuery } from 'react-query'

import { Text } from '@fullfabric/alma-mater'

import getApplicsBy from 'apps/MergeProfiles/api/getApplicsBy'
import capitalizeString from 'apps/MergeProfiles/utils/capitalizeString'
import DetailSection from './DetailSection'
import InfoRow, { InfoRowLoading } from './InfoRow'

const ApplicsSection = ({ profile }) => {
  const { data: applics, isLoading } = useQuery(['applicsBy', profile.id], () =>
    getApplicsBy(profile.id)
  )

  return (
    <DetailSection noBorder aria-label='applications'>
      <Text type='h6' marginBottom='less'>
        <Trans>Applications</Trans>
      </Text>

      {isLoading && <InfoRowLoading />}

      {!isLoading && applics?.length === 0 && (
        <Text>
          <Trans>No applications</Trans>
        </Text>
      )}

      {!isLoading &&
        applics?.length > 0 &&
        applics.map((applic) => {
          const choicesAsString = applic.primary_choice_contexts
            .map((choice) =>
              [
                choice.context_parent_name,
                choice.context_name,
                choice.campus_name
              ]
                .filter((x) => x)
                .join(' - ')
            )
            .join(', ')

          return (
            <InfoRow
              key={applic.id}
              main={`${applic.context_name} - ${applic.template_name}`}
              secondary={[capitalizeString(applic.state), choicesAsString]
                .filter((x) => x)
                .join(' - ')}
            />
          )
        })}
    </DetailSection>
  )
}

export default ApplicsSection

.react-app ._viewport_1pt47_1 {
  height: 100vh;
  overflow-y: auto;
}

.react-app ._container_1pt47_6 {
  padding-top: var(--space-big);
  padding-bottom: var(--space-huge);
  width: 100%;
}

.react-app ._containerMobile_1pt47_12 {
  padding-top: var(--space-more);
  padding-bottom: var(--space-more);
  width: 100%;
}
.react-app ._containerMobile_1pt47_12 ._spaceBetweenTables_1pt47_17 {
  margin-bottom: var(--space-more);
}

.react-app ._spaceBetweenTables_1pt47_17 {
  margin-bottom: var(--space-big);
}

.react-app ._grid_1pt47_25 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.react-app ._feeListContainer_1pt47_31 {
  flex: 1;
  margin-left: var(--space-big);
}

.react-app ._warningWidgetContainer_1pt47_36 {
  width: 25%;
  min-width: 300px;
  max-width: 500px;
  margin: 0 var(--space-big);
}

.react-app ._placeholder_1pt47_43 {
  height: 300px;
}

.react-app ._mobileGrid_1pt47_47 {
  display: flex;
  flex-direction: column-reverse;
}
.react-app ._mobileGrid_1pt47_47 ._feeListContainer_1pt47_31 {
  flex: 0;
  width: 100%;
  margin: 0;
  padding-left: var(--space-small);
  padding-right: var(--space-small);
}
.react-app ._mobileGrid_1pt47_47 ._warningWidgetContainer_1pt47_36 {
  width: 100%;
  max-width: none;
  min-width: none;
  margin: 0;
}
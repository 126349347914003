const sortStrings = (a, b, accessor = (s) => s) => {
  const nameA = accessor(a).toUpperCase()
  const nameB = accessor(b).toUpperCase()
  if (nameA < nameB) return -1
  if (nameA > nameB) return 1

  return 0
}

export default sortStrings

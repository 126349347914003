import React from 'react'

import classNames from 'classnames'
import styles from './styles.module.scss'

const StepLine = ({ completed }) => (
  <div className={classNames(styles.line, completed && styles.lineCompleted)} />
)

export default StepLine

import { capitalize, sortBy, words } from 'lodash'

import { utils } from '@fullfabric/public-api'

const DEFAULT_IGNORED_STATES = ['staff', 'admin', 'contact', 'lecturer', 'user']

export default async function getLifecyclesAsOptions({
  ignoredStates = DEFAULT_IGNORED_STATES,
  includedStates,
  sortBy = 'order'
} = {}) {
  const response = await fetch('/api/lifecycles', {
    credentials: 'same-origin'
  })

  const { states } = await utils.checkResponse(response)

  return lifecyclesToOptions(states, { ignoredStates, includedStates, sortBy })
}

export const lifecyclesToOptions = (
  lifecycleStates,
  {
    ignoredStates = DEFAULT_IGNORED_STATES,
    includedStates,
    sortBy: sortByKey = 'order'
  } = {}
) =>
  lifecycleStates
    .filter(
      (state) =>
        (!includedStates || includedStates.includes(state.name)) &&
        (!ignoredStates || !ignoredStates.includes(state.name))
    )
    .reduce((acc, state) => {
      const sortedSubstates = sortBy(state.substates, sortByKey)

      return [
        ...acc,
        ...sortedSubstates.map((substate) => ({
          value: `${state.name}::${substate.name}`,
          label: getLifecycleLabel(state.name, substate.name)
        }))
      ]
    }, [])

const getLifecycleLabel = (state, substate) => {
  const stateLabel = capitalize(state)
  const substateLabel = capitalize(words(substate).join(' '))

  return `${stateLabel} - ${substateLabel}`
}

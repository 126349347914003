.react-app ._applicCard_feb2z_1 {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.react-app ._applicCard_feb2z_1._applicAdded_feb2z_6 {
  border-left: 4px solid var(--ff-color-green-300);
}
.react-app ._applicCard_feb2z_1._applicDiscarded_feb2z_9 p:not(._summaryType_feb2z_9) {
  text-decoration-line: line-through;
  color: var(--ff-color-text-base-darker) !important;
}
.react-app ._applicCard_feb2z_1._applicDiscarded_feb2z_9 p:not(._summaryType_feb2z_9) svg * {
  fill: var(--ff-color-text-base-darker) !important;
}
.react-app ._applicCard_feb2z_1 ._cardInfoRegion_feb2z_16 {
  flex: 1 0 auto;
}
.react-app ._applicCard_feb2z_1 ._cardNotice_feb2z_19 {
  display: flex;
  align-items: center;
}
.react-app ._applicCard_feb2z_1 ._cardNotice_feb2z_19 svg {
  margin-right: var(--space-tiny);
}

.react-app ._cardButtons_feb2z_27 {
  border-top: var(--border-base-lighter);
  margin-top: var(--space-default);
  padding: var(--space-default) var(--space-more);
}
.react-app ._cardButtons_feb2z_27 ._jouneysNotice_feb2z_32 {
  margin-bottom: var(--space-default);
  display: flex;
  align-items: baseline;
}
.react-app ._cardButtons_feb2z_27 ._jouneysNotice_feb2z_32 svg {
  margin-right: var(--space-small);
  flex: 0 0 auto;
}
.react-app ._cardButtons_feb2z_27 ._cardButton_feb2z_27 ._buttonIcon_feb2z_41 {
  margin-right: var(--space-small);
  vertical-align: -1px;
}
.react-app ._cardButtons_feb2z_27 ._added_feb2z_45,
.react-app ._cardButtons_feb2z_27 ._added_feb2z_45:hover {
  background: var(--ff-bg-success-lightest);
  border: 1px solid var(--ff-bg-success-lightest);
  box-shadow: none;
  color: var(--ff-color-text-success);
}
.react-app ._cardButtons_feb2z_27 ._discarded_feb2z_52,
.react-app ._cardButtons_feb2z_27 ._discarded_feb2z_52:hover {
  background: var(--ff-bg-alert-lightest);
  border: 1px solid var(--ff-bg-alert-lightest);
  box-shadow: none;
  color: var(--ff-color-text-alert);
}
.react-app ._cardButtons_feb2z_27 button:last-child {
  margin-left: var(--space-small);
}
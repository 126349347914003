.react-app ._cardLinkWrapper_1lyd5_1 {
  text-decoration: none;
  display: inherit;
}

.react-app ._clickableCard_1lyd5_6:hover {
  background: var(--ff-bg-base-lighter);
}

.react-app ._cardInfoRow_1lyd5_10 {
  margin-top: var(--space-default);
}

.react-app ._cardLink_1lyd5_1 {
  margin: 0;
  margin-top: var(--space-default);
  display: inline-block;
}
.react-app ._cardLink_1lyd5_1._detailsLink_1lyd5_19 {
  margin-right: var(--space-more);
}
.react-app ._cardLink_1lyd5_1 ._cardLinkContentWrapper_1lyd5_22 {
  display: flex;
  align-items: center;
}
.react-app ._cardLink_1lyd5_1:hover {
  border-bottom: 1px solid transparent;
  color: var(--ff-color-text-primary-darkest) !important;
}
.react-app ._cardLink_1lyd5_1:hover svg,
.react-app ._cardLink_1lyd5_1:hover svg path {
  fill: var(--ff-color-text-primary-darkest) !important;
}

.react-app ._cardLinkContainer_1lyd5_35 {
  margin-top: auto;
}
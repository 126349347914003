{
  "Email": "E-mailadres",
  "Email address is invalid": "E-mailadres is ongeldig",
  "I accept the privacy policy": "Ik accepteer het privacybeleid",
  "Privacy Policy": "Privacybeleid",
  "Please accept the privacy policy to continue": "Gelieve het privacybeleid te aanvaarden om verder te gaan",
  "Welcome back": "Welkom terug",
  "Please enter your password for security reasons.": "Voer om veiligheidsredenen uw wachtwoord in.",
  "Email address ": "E-mailadres ",
  "Password": "Wachtwoord",
  "Forgot your password?": "Bent u uw wachtwoord vergeten?",
  "An email has been sent to  with an authentication link that will take you to your application.": "An email has been sent to  with an authentication link that will take you to your application.",
  "Once you have accessed the authentication link you can close this page.": "Als u de authenticatie-link eenmaal heeft bezocht kunt u deze pagina afsluiten.",
  "Didn’t receive the email?": "Heeft u de e-mail niet ontvangen?",
  "Resend email with authentication link": "Verstuur authenticatiemail opnieuw",
  "Email sent": "Email verzonden",
  "Welcome! Let's start your new application.": "Welkom! Laten we beginnen met uw nieuwe aanmelding.",
  "Welcome! Let's start your new registration.": "Welkom! Laten we beginnen met uw nieuwe registratie.",
  "Please enter the following details so that you can revisit your application if you exit it.": "Voer alstublieft de volgende gegevens in zodat we uw aanmelding opnieuw kunnen bezoeken als u deze heeft verlaten.",
  "Please enter the following details so that you can revisit your registration if you exit it.": "Voer alstublieft de volgende gegevens in zodat we uw registratie opnieuw kunnen bezoeken als u deze heeft verlaten.",
  "First name": "Voornaam",
  "Last name": "Achternaam",
  "Back": "Terug",
  "Continue to application": "Blijven toepassen",
  "Continue to registration": "Ga verder met registreren",
  "Data Protection regulation": "Verordening Gegevensbescherming",
  "Someone already registered with that email address": "Iemand heeft zich al geregistreerd met dat e-mail adres",
  "Email address can't be blank": "Email adres kan niet leeg zijn",
  "Continue": "Ga verder",
  "Participant details": "Details deelnemer",
  "Please enter the details of the participant": "Gelieve de details van de deelnemer in te voeren.",
  "Registration already exists": "Registratie bestaat al",
  "Application already exists": "Toepassing bestaat al",
  "There are fields with errors": "Er zijn velden met fouten.",
  "Please correct the fields with errors": "Gelieve de velden met fouten te corrigeren.",
  "First name can't be blank": "Voornaam mag niet blanco zijn",
  "Last Name": "Achternaam",
  "Last name can't be blank": "Achternaam mag niet leeg zijn",
  "Your employment details": "Uw arbeidsgegevens",
  "To register on behalf of someone else we need more information on your current employment details.": "Om namens iemand anders te kunnen registreren hebben we meer informatie nodig over uw huidige arbeidsgegevens.",
  "There was an error in your submission, please try again in a few seconds. If the issue persists, please contact us.": "Er is een fout opgetreden bij uw inzending, probeer het over een paar seconden opnieuw. Als het probleem zich blijft voordoen, neem dan contact met ons op.",
  "The link has expired.": "De koppeling is verlopen.",
  "Please re-enter your details to receive a new authentication email.": "Voer uw gegevens opnieuw in om een nieuwe authenticatie-e-mail te ontvangen.",
  "Please enter your email address so that we can retrieve your information if you have visited before.": "Voer alstublieft uw e-mailadres in zodat wij u informatie kunnen opvragen als u ons eerder heeft bezocht.",
  "Please select an option": "Maak een keuze a.u.b",
  "I am starting a registration for myself": "Ik start een registratie",
  "I am starting an application for myself": "Ik start een aanvraag voor mezelf.",
  "I am starting a registration on behalf of someone else": "Ik start een registratie namens iemand anders.",
  "I am starting an application on behalf of someone else": "Ik start een aanvraag namens iemand anders.",
  "I am continuing an existing registration": "Ik ga door met een bestaande registratie.",
  "I am continuing an existing application": "Ik ga door met een bestaande aanvraag.",
  "An email has been sent to {{email}} with an authentication link that will take you to your application.": "We hebben een e-mail verzonden naar {{email}} met een authenticatie-link die u naar uw aanmelding brengt."
}

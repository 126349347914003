import React, { useCallback, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { Trans } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'

import moment from 'moment'

import { Button } from '@fullfabric/alma-mater'

import editContract from 'apps/Billing/api/editContract'
import ContractForm from 'apps/Billing/components/ContractForm'
import {
  CONTRACTS_TABLE_QUERY_KEY_PART,
  GET_CONTRACT_QUERY_KEY,
  getApplicsUnderContractQueryKeyFilter
} from 'apps/Billing/constants/queryParts'

const ContractBasicEditForm = ({ contract, onSubmittedChanges }) => {
  const formMethods = useForm({
    defaultValues: {
      start_date: contract?.start_date ? moment(contract.start_date) : '',
      end_date: contract?.end_date ? moment(contract.end_date) : '',
      billing_periodicity: contract?.billing_periodicity || null
    }
  })

  const [hasCaughtError, setHasCaughtError] = useState(false)
  const queryClient = useQueryClient()
  const editContractMutation = useMutation(
    ({ id, formValues }) => {
      setHasCaughtError(false)
      return editContract(id, formValues)
    },
    {
      onSuccess: (data) => {
        queryClient.setQueryData([GET_CONTRACT_QUERY_KEY, contract.id], data)
        queryClient.invalidateQueries(CONTRACTS_TABLE_QUERY_KEY_PART)
        queryClient.invalidateQueries(
          getApplicsUnderContractQueryKeyFilter(contract.id)
        )

        onSubmittedChanges()
      },
      onError: (error) => {
        if (Object.keys(error?.data['billing/contract'] || {}).length > 0) {
          setHasCaughtError(true)

          Object.keys(error.data['billing/contract']).forEach((key) => {
            formMethods.setError(key, {
              message: error.data['billing/contract'][key]
            })
          })
        }
      }
    }
  )

  const onSubmit = useCallback(
    async (formValues) => {
      editContractMutation.mutate({ id: contract.id, formValues })
    },
    [contract.id, editContractMutation]
  )

  return (
    <FormProvider {...formMethods}>
      <ContractForm
        withError={editContractMutation.isError && !hasCaughtError}
      />

      <Button
        size='small'
        type='button'
        primary
        onClick={formMethods.handleSubmit(onSubmit)}
        loading={editContractMutation.isLoading}
      >
        <Trans>Save changes</Trans>
      </Button>
    </FormProvider>
  )
}

export default ContractBasicEditForm

.react-app ._activeToggle_dom5v_1 {
  background-color: var(--ff-bg-base-lighter);
}

.react-app ._folderContainer_dom5v_5 {
  padding-top: var(--space-small);
  padding-bottom: var(--space-small);
  padding-left: var(--space-more);
  background-color: var(--ff-bg-base-lighter);
}
.react-app ._folderContainer_dom5v_5._tabletNav_dom5v_11 {
  padding-left: var(--space-big);
}
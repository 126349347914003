/* eslint-disable */

import React, { useState } from 'react'
import { Trans } from 'react-i18next'

import { Modal, PlainButton, Text } from '@fullfabric/alma-mater'
import { SchemableForm } from '@fullfabric/schemable-forms'

import { useAppData } from 'shared/contexts/AppData'

import createContract from 'apps/Authentication/api/createContract'
import updateContract from 'apps/Authentication/api/updateContract'

import styles from '../styles.module.scss'

const EmploymentDetailsModal = ({ newContactId, back, next }) => {
  const {
    logo_url,
    authenticated_profile_id,
    contract_schema: contractSchema,
    countries_as_options: countriesAsOptions
  } = useAppData()

  const [error, setError] = useState(null)

  return (
    <Modal size='medium' isOpen={true} className={styles.modal}>
      <img alt='Logo' src={logo_url} />

      <Text type='h2' fontColor='text-base-darkest' marginBottom='more'>
        <Trans>Your employment details</Trans>
      </Text>

      <Text type='f4' fontColor='text-base-darkest' marginBottom='small'>
        <Trans>
          To register on behalf of someone else we need more information on your
          current employment details.
        </Trans>
      </Text>

      <div className={styles.form}>
        <SchemableForm
          editable
          locale={window.locale || 'en-GB'}
          handleSubmit={async (data) => {
            try {
              if (newContactId) {
                const result = await updateContract(
                  newContactId.id,
                  authenticated_profile_id,
                  data,
                  true
                )
                next(result)
              } else {
                const result = await createContract(
                  authenticated_profile_id,
                  data,
                  true
                )
                next(result)
              }
            } catch (err) {
              const submissionError = err?.data?.message || (
                <Trans>
                  There was an error in your submission, please try again in a
                  few seconds. If the issue persists, please contact us.
                </Trans>
              )
              setError(submissionError)
            }
          }}
          error={error}
          schema={contractSchema}
          countriesAsOptions={countriesAsOptions}
        />

        <PlainButton type='button' onClick={back} className={styles.backButton}>
          <Trans>Back</Trans>
        </PlainButton>
      </div>
    </Modal>
  )
}

export default EmploymentDetailsModal

import { IMPORT_TYPES } from './constants'

const getOrGuessImportType = (location, importDocument) => {
  if (importDocument?._ruby_type) return importDocument?._ruby_type

  if (location.pathname.startsWith('/institutions/settings/programmes/import'))
    return IMPORT_TYPES.PROGRAMME_AND_CLASS

  if (location.pathname.startsWith('/institutions/settings/courses/import'))
    return IMPORT_TYPES.SUBJECT_AND_COURSE

  return IMPORT_TYPES.PROFILE
}

export default getOrGuessImportType

export const DEFAULT_MARKETING_OPTIONS = {
  email: 'false',
  phone: 'false',
  sms: 'false',
  mail: 'false'
}

export const VALID_CHANNELS = Object.keys(DEFAULT_MARKETING_OPTIONS)

export const DEFAULT_CHANNELS_ACTIVE = {
  email: true,
  phone: true,
  sms: true,
  mail: true
}

export const DEFAULT_MARKETING_OPTIONS_USER_SELECTION = {
  ...DEFAULT_MARKETING_OPTIONS,
  none: 'true'
}

import React from 'react'

const FONT_SIZE = 12
const DEFAULT_MARGIN = 2
const MARGIN = 7
const TICK_SIZE = 8
const HALF_TICK_SIZE = 4
const DEFAULT_LINE_PROPS = {
  className: 'recharts-cartesian-axis-tick-line',
  width: '979',
  height: '30',
  x: '0',
  y: '264',
  fill: 'none',
  strokeWidth: '1'
}

const XAxisTick = ({
  intervaled,
  customValue,
  payload,
  verticalAnchor,
  visibleTicksCount,
  index,
  x,
  y,
  ...otherProps
}) => {
  const tickLineStartingY = y - TICK_SIZE - DEFAULT_MARGIN || 0

  if (intervaled && index % 2 === 1) {
    return (
      <line
        {...DEFAULT_LINE_PROPS}
        stroke='var(--ff-color-grey-200)'
        x1={x}
        y1={tickLineStartingY + HALF_TICK_SIZE}
        x2={x}
        y2={tickLineStartingY}
      ></line>
    )
  }

  return (
    <>
      <line
        {...DEFAULT_LINE_PROPS}
        stroke='var(--ff-color-grey-200)'
        x1={x}
        y1={tickLineStartingY + TICK_SIZE}
        x2={x}
        y2={tickLineStartingY}
      ></line>
      <text
        {...otherProps}
        index={index}
        x={x}
        y={y}
        dy={TICK_SIZE + MARGIN}
        fill={'var(--ff-color-text-base-darker)'}
        fontSize={FONT_SIZE}
      >
        {typeof customValue !== 'undefined' ? customValue : payload.value}
      </text>
    </>
  )
}

export default XAxisTick

import React from 'react'

import { BoxShadow } from '@fullfabric/alma-mater'

import ViewerContainer from '../ViewerContainer'

const VideoViewer = ({ viewOption, uploadIndex }) => {
  const [loading, setLoading] = React.useState(true)
  const [error, setError] = React.useState(false)

  return (
    <ViewerContainer
      viewOption={viewOption}
      uploadIndex={uploadIndex}
      loading={loading}
      error={error}
    >
      <BoxShadow
        type='2'
        rounded
        style={{ display: 'flex', overflow: 'hidden' }}
      >
        <video
          controls
          preload='auto'
          // src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/VolkswagenGTIReview.mp4"
          src={`/${viewOption?.uploads[uploadIndex]?.path}`}
          onCanPlayThrough={() => setLoading(false)}
          onError={(e) => {
            setLoading(false)
            setError(true)
          }}
          alt={viewOption.label}
        />
      </BoxShadow>
    </ViewerContainer>
  )
}

VideoViewer.hideScaling = true

export default VideoViewer

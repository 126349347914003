.react-app ._row_1slc1_1 {
  background-color: var(--ff-color-white);
}
.react-app ._row_1slc1_1:hover {
  background-color: var(--ff-bg-base-lighter);
}

.react-app ._prerequisitesCell_1slc1_8 {
  overflow: hidden;
  text-overflow: ellipsis;
}
const { useSettings } = require('@fullfabric/authorization-officer')

const useHasCourseJourneysSetting = () => {
  const settings = useSettings()

  return settings?.hash?.modules?.core?.submodules?.institutions?.features
    ?.course_journeys?.enable
}

export default useHasCourseJourneysSetting

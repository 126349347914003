.react-app ._pageHead_1r3co_1 {
  width: 100%;
}

.react-app ._layoutContainer_1r3co_5 {
  overflow: auto;
  padding-bottom: var(--space-more);
  height: auto;
  min-height: 100vh;
  background-color: var(--ff-bg-base-lightest);
}
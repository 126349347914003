import React from 'react'

import useColumns from './useColumns'
import useReactTable from './useReactTable'
import useUpdateHiddenColumns from './useUpdateHiddenColumns'
import useUpdateSelectedRows from './useUpdateSelectedRows'

const useDataTable = ({
  onChangeSort,
  initialHiddenColumns,
  tableProps,
  data,
  dataSchemas,
  columns,
  selectableRows,
  expandableRows,
  fixedLayout,
  onHiddenColumnsChanged,
  onSelectionChanged
}) => {
  const memoizedData = React.useMemo(() => data || [], [data])
  const memoizedColumns = useColumns({ columns, dataSchemas })
  const reactTableProps = useReactTable({
    memoizedData,
    memoizedColumns,
    selectableRows,
    fixedLayout,
    expandableRows,
    onChangeSort,
    initialHiddenColumns,
    tableProps
  })

  useUpdateHiddenColumns(
    reactTableProps.allColumns,
    reactTableProps.visibleColumns,
    onHiddenColumnsChanged
  )

  useUpdateSelectedRows(
    selectableRows,
    onSelectionChanged,
    reactTableProps.selectedFlatRows
  )

  return {
    reactTableProps,
    memoizedData
  }
}

export default useDataTable

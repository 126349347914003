import React from 'react'

import { AnnotatedLayout } from '@fullfabric/alma-mater'

import StudyPlanSettingsForm from 'apps/StudyPlans/components/StudyPlanSettingsForm'
import { SettingsSectionsBeingEditedProvider } from 'apps/StudyPlans/contexts/SettingsSectionsBeingEdited'
import DiscardChangesModal from './DiscardChangesModal'
import PageHeader from './PageHeader'

const SettingsPage = () => {
  const [isConfirmationModalOpen, setConfirmationModalOpen] =
    React.useState(false)

  return (
    <SettingsSectionsBeingEditedProvider>
      <DiscardChangesModal
        isOpen={isConfirmationModalOpen}
        onClose={() => setConfirmationModalOpen(false)}
      />
      <AnnotatedLayout
        pageHead={
          <PageHeader
            openConfimationModal={() => setConfirmationModalOpen(true)}
          />
        }
      >
        <StudyPlanSettingsForm />
      </AnnotatedLayout>
    </SettingsSectionsBeingEditedProvider>
  )
}

export default SettingsPage

.react-app ._newItemsCard_jpfdu_1 {
  width: 100%;
  background-color: var(--ff-color-primary-000);
}
.react-app ._newItemsCard_jpfdu_1 a {
  font-weight: 600;
  color: var(--ff-color-black-500);
}
.react-app ._newItemsCard_jpfdu_1 svg {
  position: relative;
  top: 2px;
}
.react-app ._newItemsCard_jpfdu_1 svg path {
  fill: black;
}
import React from 'react'

import PropTypes from 'prop-types'

import WidgetContainer from '../widget-container'
import widgetsByType from './types'

export default function Widget({ widget }) {
  const { component: WidgetComponent, hasOwnContainer } =
    widgetsByType[widget._ruby_type] || {}

  if (!WidgetComponent) {
    console.error("Couldn't find component for widget", widget)
    return null
  }

  return hasOwnContainer ? (
    <WidgetComponent widget={widget} />
  ) : (
    <WidgetContainer>
      <WidgetComponent widget={widget} />
    </WidgetContainer>
  )
}

Widget.propTypes = {
  widget: PropTypes.shape({
    _ruby_type: PropTypes.string.isRequired
  }).isRequired
}

import React from 'react'
import { Route, Switch } from 'react-router'

import CookiePolicyPage from 'apps/Policies/pages/CookePolicyPage'
import EditMarketingPolicyPage from 'apps/Policies/pages/EditMarketingPolicyPage'
import EditMarketingPolicyTopicsPage from 'apps/Policies/pages/EditMarketingPolicyTopicsPage'
import EditPrivacyPolicyPage from 'apps/Policies/pages/EditPrivacyPolicyPage'
import FinishedSetupTopicsPage from 'apps/Policies/pages/FinishedSetupTopicsPage'
import MarketingPolicyAcceptedPage from 'apps/Policies/pages/MarketingPolicyAcceptedPage'
import MarketingPolicyConfirmedPage from 'apps/Policies/pages/MarketingPolicyConfirmedPage'
import PrivacyPolicyRefusedPage from 'apps/Policies/pages/PrivacyPolicyRefusedPage'
import AppLayout from '../AppLayout'

export const COOKIE_POLICY_ROUTES = [
  '/policies/cookies',
  '/policies/user/:profile_id/cookies/edit'
]

const PoliciesAppRoutes = () => {
  return (
    <AppLayout>
      <Switch>
        <Route exact path={COOKIE_POLICY_ROUTES} component={CookiePolicyPage} />
        <Route
          path='/policies/user/:profile_id/privacy/:policy_id?/edit'
          component={EditPrivacyPolicyPage}
        />
        <Route
          path='/policies/user/:profile_id/privacy/:policy_id?/refused'
          component={PrivacyPolicyRefusedPage}
        />
        <Route
          path='/policies/user/:profile_id/marketing/:policy_id?/edit'
          component={EditMarketingPolicyPage}
        />
        <Route
          path='/policies/user/:profile_id/marketing/:policy_id?/unsubscribe'
          render={(props) => (
            <EditMarketingPolicyPage {...props} noAgreementOnStart />
          )}
        />
        <Route
          path='/policies/user/:profile_id/marketing/:policy_id?/accepted'
          component={MarketingPolicyAcceptedPage}
        />
        <Route
          path='/policies/user/:profile_id/marketing/:policy_id?/confirm/:acceptance_id'
          component={MarketingPolicyConfirmedPage}
        />
        <Route
          path='/policies/user/:profileId/marketing/:policy_id?/topic/:topic_id/unsubscribe'
          component={EditMarketingPolicyTopicsPage}
        />
        <Route
          path='/policies/user/:profileId/marketing/:policy_id?/topic/:topic_id/keep'
          render={(props) => <FinishedSetupTopicsPage {...props} />}
        />
        <Route
          path='/policies/user/:profileId/marketing/:policy_id?/topic/:topic_id/unsubscribed'
          render={(props) => (
            <FinishedSetupTopicsPage {...props} unsubscribed />
          )}
        />
      </Switch>
    </AppLayout>
  )
}

export default PoliciesAppRoutes

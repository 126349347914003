import React, { useEffect } from 'react'
import { useController, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Field } from '@fullfabric/alma-mater'

import {
  useApplication,
  useApplicationTemplate
} from 'apps/Evaluations/context/ApplicationData'
import getChoices from './getChoices'

const ProductChoiceField = ({ name }) => {
  const { t } = useTranslation()
  const { control, formState, setValue } = useFormContext()
  const { errors } = formState

  const { data: applicData } = useApplication()
  const { data: templateData } = useApplicationTemplate()

  const { choiceOptions, initialValue } = getChoices(
    applicData,
    templateData,
    t
  )

  const {
    field: { ref: productChoiceInputRef, ...productChoiceInputProps }
  } = useController({
    name,
    control,
    rules: {
      required: {
        value: choiceOptions?.length > 0,
        message: t("Can't be blank")
      }
    }
  })
  useEffect(() => {
    if (initialValue) {
      setValue('choice', initialValue)
    }
  }, [initialValue, setValue])

  if (choiceOptions?.length === 0) {
    return null
  }

  return (
    <Field
      {...productChoiceInputProps}
      id='new-evaluation-product-choice-field'
      required
      label={t('Product')}
      inputRef={productChoiceInputRef}
      type='radio'
      error={errors[name]?.message}
      inputOptions={choiceOptions}
    />
  )
}

export default ProductChoiceField

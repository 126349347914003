/* eslint-disable no-prototype-builtins */

import { utils } from '@fullfabric/public-api'

/**
 * Fetches a list of Inbox Cards from the API.
 *
 * @param {Integer} [query.offset] How many cards to skip (pagination).
 * @param {Integer} [query.per_page] How many cards to return (pagination).
 * @param {String} [query.card_type] Filter by card type.
 * @param {String} [query.card_status] Filter by card status.
 * @returns A `{ data, headers }` object with the response data and headers.
 */
export default async function getCards(query = {}) {
  const response = await fetch(queriedUrl(query), {
    credentials: 'same-origin'
  })

  const result = await utils.checkResponse(response)
  const headers = response.headers.get('x-fullfabric-json')

  return { data: result, headers: JSON.parse(headers) }
}

function queriedUrl(query) {
  const url = new URL(window.location.href)
  url.pathname = '/inbox/api/cards'
  url.search = new URLSearchParams(query).toString()
  return url.toString()
}

import React from 'react'
import { useParams } from 'react-router'

import FixedClassConversionPage from 'apps/Reports/components/FixedClassConversionPage'
import useChangeDocumentTitle from 'apps/Reports/hooks/useChangeDocumentTitle'

function FixedClassConversion() {
  useChangeDocumentTitle('Product conversion report')
  const { classId } = useParams()

  return <FixedClassConversionPage classId={classId} />
}

export default FixedClassConversion

import React from 'react'

import { CheckboxInput } from '@fullfabric/alma-mater'

const useRowSelectCheckbox = (hooks) => {
  hooks.visibleColumns.push((columns) => [
    // Let's make a column for selection
    {
      id: 'selection',
      alwaysVisible: true,
      // The header can use the table's getToggleAllRowsSelectedProps method
      // to render a checkbox
      Header: ({ getToggleAllRowsSelectedProps }) => {
        const { checked, indeterminate, ...toggleAllRowsSelectedProps } =
          getToggleAllRowsSelectedProps()

        return (
          <div aria-label='checkbox header'>
            <CheckboxInput
              id='selectAllRows'
              ref={() => null}
              checked={indeterminate ? 'indeterminate' : checked}
              {...toggleAllRowsSelectedProps}
            />
          </div>
        )
      },
      // The cell can use the individual row's getToggleRowSelectedProps method
      // to the render a checkbox
      Cell: ({ row }) => {
        const { checked, indeterminate, ...toggleRowSelectedProps } =
          row.getToggleRowSelectedProps()

        return (
          <div aria-label='checkbox cell'>
            <CheckboxInput
              id={`selectRow_${row.id}`}
              ref={() => null}
              role='checkbox'
              aria-label={`checkbox_row_${row.id}`}
              checked={indeterminate ? 'indeterminate' : checked}
              {...toggleRowSelectedProps}
            />
          </div>
        )
      },
      maxWidth: 50
    },
    ...columns
  ])
}

export default useRowSelectCheckbox

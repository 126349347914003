import React from 'react'

import { useBackgroundImage } from 'apps/ContentPages/contexts/background-image'
import useIsMobileLayout from 'apps/ContentPages/hooks/use-is-mobile-layout'

import classNames from 'classnames'
import styles from './styles.module.scss'

export default function BackgroundImage() {
  const isMobileLayout = useIsMobileLayout()
  const imageUrl = useBackgroundImage()
  const backgroundImageProps = {
    backgroundImage: imageUrl ? `url(${imageUrl})` : 'none'
  }

  return (
    <div
      className={classNames([styles.image, isMobileLayout && styles.mobile])}
      role='img'
      aria-label='Background image'
      data-testid='background-image'
      style={backgroundImageProps}
    ></div>
  )
}

import React, { useState } from 'react'
import { Trans } from 'react-i18next'

import {
  Button,
  Col,
  Field,
  Modal,
  ModalTitle,
  Row
} from '@fullfabric/alma-mater'

const EditSubjectPropagationModal = ({
  isOpen,
  loading,
  targetData,
  onSubmit,
  onCancel
}) => {
  const [shouldPropagateChange, setShouldPropagateChanges] = useState('false')

  const footerActions = (
    <Row nogutter end='xs' className='marginBottom-more'>
      <Button onClick={onCancel}>
        <Trans>Close</Trans>
      </Button>
      <Button
        primary
        loading={loading}
        className='marginLeft-small'
        onClick={() => onSubmit(shouldPropagateChange !== 'false', targetData)}
      >
        <Trans>Apply changes</Trans>
      </Button>
    </Row>
  )

  return (
    <Modal
      header={
        <ModalTitle
          title={<Trans>Update study plan subject</Trans>}
          onClose={onCancel}
        />
      }
      footer={footerActions}
      isOpen={isOpen}
      onClose={onCancel}
    >
      <Row>
        <Col xs={12}>
          <Field
            id='shouldPropagateChange'
            type='radio'
            label={
              <Trans>
                Would you like to apply these changes to all student
                transcripts?
              </Trans>
            }
            value={shouldPropagateChange}
            onChange={(_, value) => setShouldPropagateChanges(value)}
            inputOptions={[
              {
                label: (
                  <Trans>No, do not update existing student transcripts</Trans>
                ),
                value: 'false' // values have to be strings or else radio buttons don't work correctly
              },
              {
                label: <Trans>Yes, update all student transcripts</Trans>,
                value: 'true'
              }
            ]}
          />
        </Col>
      </Row>
    </Modal>
  )
}

export default EditSubjectPropagationModal

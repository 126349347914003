import qs from 'qs'

import { utils } from '@fullfabric/public-api'

import getWithdrawnRulesFromValue from 'apps/Reports/utils/getWithdrawnRulesFromValue'

const getClassAttributeDistribution = async ({
  classId,
  dimension,
  top,
  withdrawn,
  states
}) => {
  const rules = getWithdrawnRulesFromValue(withdrawn)

  const params = qs.stringify(
    {
      top,
      ...rules,
      states
    },
    { arrayFormat: 'brackets' }
  )

  const response = await fetch(
    `/intel/classes/${classId}/dimensions/${dimension}?${params}`,
    {
      credentials: 'same-origin'
    }
  )

  return await utils.checkResponse(response)
}

export default getClassAttributeDistribution

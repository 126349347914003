import React from 'react'
import { Trans } from 'react-i18next'

import { Button, Col, Row, Text } from '@fullfabric/alma-mater'

import { useAppData } from 'shared/contexts/AppData'
import getLocalizedText from 'shared/utils/getLocalizedText'

import { useSelectedLocale } from 'apps/Policies/contexts/SelectedLocale'

const onBackToHomePage = () => {
  window.location.href = '/'
}

const FinishedSetupTopicsPage = ({ unsubscribed }) => {
  const { topic } = useAppData()
  const locale = useSelectedLocale()
  const topicName = getLocalizedText(topic.name, locale, 'text')

  return (
    <Row>
      {unsubscribed && (
        <>
          <Col xs={12}>
            <Text type='h1' fontColor='text-base-darkest' data-testid='title'>
              <Trans>Unsubscribe successful</Trans>
            </Text>
          </Col>
          <Col xs={12}>
            <Text
              type='f4'
              fontColor='text-base-darkest'
              marginTop='small'
              marginBottom='default'
              data-testid='subtitle'
            >
              <Trans
                defaults='You have been unsubscribed from the <0>{{topicName}}</0> topic.'
                values={{ topicName }}
                components={[
                  <Text type='h4' tag='span'>
                    {topicName}
                  </Text>
                ]}
              />
            </Text>
          </Col>
        </>
      )}
      {!unsubscribed && (
        <>
          <Col xs={12}>
            <Text type='h1' fontColor='text-base-darkest' data-testid='title'>
              <Trans>You are still subscribed</Trans>
            </Text>
          </Col>
          <Col xs={12}>
            <Text
              type='f4'
              fontColor='text-base-darkest'
              marginTop='small'
              marginBottom='default'
              data-testid='subtitle'
            >
              <Trans
                defaults='You have not been unsubscribed from the <0>{{topicName}}</0> topic.'
                components={[
                  <Text type='h4' tag='span'>
                    {topicName}
                  </Text>
                ]}
                values={{ topicName }}
              />
            </Text>
          </Col>
        </>
      )}
      <Col xs={12}>
        <Button primary onClick={onBackToHomePage} marginRight='small'>
          <Trans>Back to homepage</Trans>
        </Button>
      </Col>
    </Row>
  )
}

export default FinishedSetupTopicsPage

import React, { useContext } from 'react'

export const CardFiltersContext = React.createContext()

export function CardFiltersProvider({ children }) {
  const [cardStatus, setCardStatus] = React.useState(null)
  const [cardType, setCardType] = React.useState(null)

  return (
    <CardFiltersContext.Provider
      value={{
        cardStatus,
        cardType,
        setCardType,
        setCardStatus
      }}
    >
      {children}
    </CardFiltersContext.Provider>
  )
}

export function useCardFilters() {
  return useContext(CardFiltersContext)
}

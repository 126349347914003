import React from 'react'
import { Trans } from 'react-i18next'
import { useHistory } from 'react-router'

import { Button } from '@fullfabric/alma-mater'

import {
  useImportType,
  useSetCurrentStep,
  useStepState
} from 'apps/Imports/contexts/steps'
import getUrlFromStepId from 'apps/Imports/utils/product-import/get-url-from-step-id'

import styles from '../styles.module.scss'

const PreviousStepButton = () => {
  const history = useHistory()
  const { previousStep } = useStepState()
  const setCurrentStep = useSetCurrentStep()
  const importType = useImportType()

  const onPreviousStepClick = React.useCallback(() => {
    const pageUrl = getUrlFromStepId(importType, previousStep)
    history.push(pageUrl)

    setCurrentStep(previousStep)
  }, [history, previousStep, setCurrentStep, importType])

  if (!previousStep) return null

  return (
    <Button className={styles.actionButtons} onClick={onPreviousStepClick}>
      <Trans>Previous</Trans>
    </Button>
  )
}

export default PreviousStepButton

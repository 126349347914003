import { capitalize } from 'lodash'

import { ApiError } from 'apps/ContentPages/api'

export default function processSubmissionError(error, t) {
  const submissionError =
    error instanceof ApiError &&
    error.status >= 400 &&
    error.status < 500 &&
    error.data

  const fallbackErrorMessage = {
    message: t(
      'There was an error in your submission, please try again in a few seconds. If the issue persists, please contact us.'
    ),
    mayNeedRefetch: true
  }

  if (!submissionError) return fallbackErrorMessage

  return { ...submissionError, message: capitalize(submissionError.message) }
}

/* eslint-disable camelcase */

import React, { useState } from 'react'
import { Trans } from 'react-i18next'
import { useMutation } from 'react-query'

import { Button, Col, Field, Row } from '@fullfabric/alma-mater'

import updateStudyPlan from 'apps/StudyPlans/api/updateStudyPlan'
import { useOnChangesSaved } from 'apps/StudyPlans/contexts/ChangesSaved'
import { useSetSettingsSectionsBeingEdited } from 'apps/StudyPlans/contexts/SettingsSectionsBeingEdited'
import {
  useSetStudyPlan,
  useStudyPlan
} from 'apps/StudyPlans/contexts/StudyPlanData'

const FinalGradeSectionForm = () => {
  const studyPlan = useStudyPlan()
  const setStudyPlan = useSetStudyPlan()
  const setSectionsBeingEdited = useSetSettingsSectionsBeingEdited()
  const onChangesSaved = useOnChangesSaved()

  const [finalGrade, setFinalGrade] = useState(
    studyPlan.max_final_grade ? 'percentage' : 'average'
  )
  const [maxFinalGrade, setMaxFinalGrade] = useState(studyPlan.max_final_grade)
  const [maxFinalGradeDecimals, setMaxFinalGradeDecimals] = useState(
    studyPlan.max_final_grade_decimals
  )

  const { mutate: updateGradesMutation, isLoading } = useMutation(
    ({ studyPlanId, max_final_grade, max_final_grade_decimals }) => {
      return updateStudyPlan(studyPlanId, {
        max_final_grade,
        max_final_grade_decimals
      })
    },
    {
      onSuccess: (updatedStudyPlan) => {
        setSectionsBeingEdited((sections) => ({
          ...sections,
          finalGrades: false
        }))
        setStudyPlan({ ...studyPlan, ...updatedStudyPlan })
        onChangesSaved()
      }
    }
  )

  const onSaveChanges = React.useCallback(() => {
    updateGradesMutation({
      studyPlanId: studyPlan.id,
      max_final_grade: maxFinalGrade,
      max_final_grade_decimals: maxFinalGradeDecimals
    })
  }, [updateGradesMutation, studyPlan, maxFinalGrade, maxFinalGradeDecimals])

  const onCancel = React.useCallback(() => {
    setFinalGrade(studyPlan.max_final_grade ? 'percentage' : 'average')
    setMaxFinalGrade(studyPlan.max_final_grade)
    setMaxFinalGradeDecimals(studyPlan.max_final_grade_decimals)
    setSectionsBeingEdited((sections) => ({
      ...sections,
      finalGrades: false
    }))
  }, [
    setMaxFinalGradeDecimals,
    setMaxFinalGrade,
    setFinalGrade,
    studyPlan,
    setSectionsBeingEdited
  ])

  return (
    <React.Fragment>
      <Field
        id='finalGrade'
        type='radio'
        value={finalGrade}
        inputOptions={[
          { label: <Trans>Grade average</Trans>, value: 'average' },
          { label: <Trans>Grade percentage</Trans>, value: 'percentage' }
        ]}
        label={<Trans>Final grade display</Trans>}
        onChange={(_, value) => {
          setFinalGrade(value)

          if (value === 'average') {
            setMaxFinalGrade(null)
            setMaxFinalGradeDecimals(null)
          }
        }}
      />
      {finalGrade === 'percentage' && (
        <React.Fragment>
          <Row>
            <Col xs={6}>
              <Field
                id='max_final_grade'
                type='text'
                value={maxFinalGrade ? `${maxFinalGrade}` : ''}
                label={<Trans>Maximum transcript grade</Trans>}
                onChange={(id, value) => setMaxFinalGrade(value)}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <Field
                id='max_final_grade_decimals'
                type='text'
                value={maxFinalGradeDecimals ? `${maxFinalGradeDecimals}` : ''}
                label={<Trans>Maximum transcript grade decimal places</Trans>}
                onChange={(id, value) => setMaxFinalGradeDecimals(value)}
              />
            </Col>
          </Row>
        </React.Fragment>
      )}
      <div className='marginTop-small'>
        <Button
          primary
          size='small'
          loading={isLoading}
          disabled={isLoading}
          onClick={onSaveChanges}
        >
          <Trans>Save changes</Trans>
        </Button>
        <Button
          size='small'
          disabled={isLoading}
          onClick={onCancel}
          className='marginLeft-small'
        >
          <Trans>Cancel</Trans>
        </Button>
      </div>
    </React.Fragment>
  )
}

export default FinalGradeSectionForm

import React from 'react'

import classNames from 'classnames'
import styles from './styles.module.scss'

const TablePageLayout = React.forwardRef(({ className, children }, ref) => {
  return (
    <div ref={ref} className={classNames(styles.pageLayout, className)}>
      {children}
    </div>
  )
})

const TableSection = React.forwardRef(({ className, children }, ref) => {
  return (
    <div ref={ref} className={classNames(styles.tableSection, className)}>
      {children}
    </div>
  )
})

TablePageLayout.TableSection = TableSection

export default TablePageLayout

import { utils } from '@fullfabric/public-api'

const getCourse = async (id) => {
  const response = await fetch(`/api/courses/${id}`, {
    credentials: 'same-origin'
  })
  return await utils.checkResponse(response)
}

export default getCourse

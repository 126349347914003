import ProductImportFormDataError from 'apps/Imports/errors/product-import-form-data-error'
import UploadedFileError from 'apps/Imports/errors/uploaded-file-error'
import { IMPORT_TYPES } from 'apps/Imports/utils/constants'
import getFileBlob from 'apps/Imports/utils/get-file-blob'

const getProductImportFormData = async (
  data,
  context,
  importType = IMPORT_TYPES.PROGRAMME_AND_CLASS
) => {
  const formData = new FormData()

  // IMPORT TYPE
  formData.append('_type', importType)

  // UPLOADED FILE
  try {
    const uploadedFile = await getFileBlob(data.file.url)
    formData.append('file_contents', uploadedFile, data.file.name)
  } catch (err) {
    console.error(err)
    throw new UploadedFileError(err)
  }

  try {
    // CONTEXT
    formData.append('context_type', 'Institutions::Institution')
    formData.append('context_id', context.id)

    // SEPARATOR
    formData.append('column_separator', data.separator)

    data.mappings.forEach((mapping) => {
      formData.append('mapping[][header]', mapping.header)
      formData.append('mapping[][field]', mapping.field || '')
    })
  } catch (err) {
    console.error(err)
    throw new ProductImportFormDataError(err)
  }

  return formData
}

export default getProductImportFormData

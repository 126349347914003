import React, { useCallback, useState } from 'react'

import * as tus from 'tus-js-client'

import { Button } from '@fullfabric/alma-mater'

import { useAppData } from 'shared/contexts/AppData'

import { Card } from '../Card'
import AttachmentFile from './AttachmentFile'
import EmptyState from './EmptyState'

import styles from './styles.module.scss'

const ATTACHMENT_CHUNK_MB = 20 * 1024 * 1024

export default function Attachments({ attachments, templateId }) {
  const { _handle } = useAppData()
  const [internalAttachments, setAttachments] = useState(attachments)
  const [erroredFiles, setErroredFiles] = useState([])
  const [uploadingFiles, setUploadingFiles] = useState([])

  const onFileChange = useCallback(
    (e) => {
      const file = e.nativeEvent.target.files[0]
      const upload = new tus.Upload(file, {
        endpoint: `/api/email_templates/${templateId}/attachments/resumable`,
        chunkSize: ATTACHMENT_CHUNK_MB,
        metadata: {
          filename: file.name,
          filetype: file.type,
          channelName: `${_handle}-tus-uploads-attachments`,
          emailTemplateId: templateId
        },
        onShouldRetry: (error) => {
          if (error.originalResponse.getStatus() === 406) {
            return false
          }
        },
        onAfterResponse: (_, res) => {
          if (res.getStatus() === 201 && res.getBody()) {
            setUploadingFiles((uploads) =>
              uploads.filter((internalUpload) => internalUpload.file !== file)
            )
            setAttachments((attachments) => [
              ...attachments,
              JSON.parse(res.getBody())
            ])
          }
        },
        onError: (error) => {
          setErroredFiles((files) => [
            ...files,
            { file, error: error.originalResponse.getBody() }
          ])
        }
      })
      setUploadingFiles((uploadingFiles) => [...uploadingFiles, upload])
      upload.start()
      e.nativeEvent.target.value = null
    },
    [_handle, templateId]
  )

  const onDelete = useCallback(
    (id) => {
      if (window.confirm('Are you sure?')) {
        fetch(`/api/email_templates/${templateId}/attachments/${id}`, {
          method: 'DELETE'
        })
          .then(() =>
            setAttachments((attachments) =>
              attachments.filter((attachment) => attachment.id !== id)
            )
          )
          .catch(console.error)
      }
    },
    [templateId]
  )

  const rows = internalAttachments.map((attachment, index) => (
    <AttachmentFile
      key={index}
      name={attachment.name}
      id={attachment.id}
      path={attachment.path}
      templateId={templateId}
      onDelete={onDelete}
    />
  ))

  if (!uploadingFiles.length && rows.length === 0) {
    rows.push(<EmptyState key='empty-state' />)
  }

  uploadingFiles.forEach((upload) => {
    const errorInfo = erroredFiles.find(({ file }) => file === upload.file)
    rows.push(
      <AttachmentFile
        key={rows.length}
        name={upload.file.name}
        uploading={!errorInfo}
        error={errorInfo?.error}
        onErrorDismiss={() => {
          setErroredFiles((errorFiles) =>
            errorFiles.filter(({ file }) => file !== upload)
          )
          setUploadingFiles((uploads) =>
            uploads.filter((internalUpload) => internalUpload !== upload)
          )
        }}
      />
    )
  })

  return (
    <>
      <Card title='Attach file'>
        <div className={styles.uploadContainer}>
          <label className='mb-0' style={{ color: '#8F9699' }}>
            File
          </label>
          <Button size='small' className={styles.uploadButton}>
            <input
              className={styles.uploadFile}
              name='file'
              type='file'
              onChange={onFileChange}
            />
            Attach file
          </Button>
        </div>
      </Card>
      <Card title='Files attached'>
        <div className={styles.filesList}>{rows}</div>
      </Card>
    </>
  )
}

import React from 'react'

import { Link } from '@fullfabric/alma-mater'

import { useAppData } from 'shared/contexts/AppData'

import styles from './styles.module.scss'

const LogoLink = () => {
  const {
    logo_url: institutionLogo,
    institution_name: incomingInstitutionName
  } = useAppData()

  const institutionName = incomingInstitutionName || 'institution'

  return (
    <Link href='/' className={styles['logo-rectangle']}>
      <img src={institutionLogo} alt={`${institutionName} logo`} />
    </Link>
  )
}

export default LogoLink

import React, { useEffect, useLayoutEffect, useRef } from 'react'

import { useTransition } from '@react-spring/core'
import { animated } from '@react-spring/web'
import { Visible } from '@fullfabric/alma-mater'
import { ScrollableAreaProvider } from '@fullfabric/schemable-forms'

import useIsMobileLayout from 'apps/ContentPages/components/payments-page/use-is-payments-mobile-layout'
import {
  useClosePaymentsDrawer,
  useIsPaymentsDrawerOpened,
  usePaymentsDrawerData
} from '../../contexts/payments-drawer'
import FeeInfo from './fee-info'
import FeePayment from './fee-payment'

import classNames from 'classnames'
import styles from './styles.module.scss'

const PaymentsDrawer = () => {
  const isMobileLayout = useIsMobileLayout()
  const drawerRef = useRef()
  const shadowRef = useRef()

  const { fee, type: typeOfDrawer } = usePaymentsDrawerData()

  const isOpen = useIsPaymentsDrawerOpened()
  const handleClose = useClosePaymentsDrawer()
  const shadowTransitions = useTransition(isOpen, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 }
  })
  const paneTransitions = useTransition(isOpen, {
    from: { transform: 'translate(100%)' },
    enter: { transform: 'translate(0%)' },
    leave: { transform: 'translate(100%)' }
  })

  useEffect(() => {
    const handleClickOutsideMenu = (evt) => {
      if (
        isOpen &&
        drawerRef &&
        drawerRef.current &&
        !drawerRef.current.contains(evt.target)
      ) {
        handleClose(evt)
      }
    }

    document.addEventListener('mouseup', handleClickOutsideMenu) // click closes the pane when switching between types
    return () => {
      document.removeEventListener('mouseup', handleClickOutsideMenu)
    }
  }, [handleClose, isOpen])

  useLayoutEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
  }, [isOpen])

  return (
    <>
      <Visible md lg xl>
        {shadowTransitions(
          (transitionsStyles, isOpen) =>
            isOpen && (
              <animated.div
                ref={shadowRef}
                style={transitionsStyles}
                className={styles.shadow}
                role='presentation'
                aria-label='shadow'
              />
            )
        )}
      </Visible>
      {paneTransitions(
        (transitionsStyles, isOpen) =>
          isOpen && (
            <animated.div
              ref={drawerRef}
              role='region'
              aria-label={`${fee?.description} fee ${typeOfDrawer} area`}
              style={transitionsStyles}
              className={classNames(
                styles.pane,
                !isMobileLayout && styles.desktop
              )}
            >
              <ScrollableAreaProvider
                areaRef={drawerRef}
                mobileLayout={isMobileLayout}
              >
                {typeOfDrawer === 'details' && <FeeInfo />}
                {typeOfDrawer === 'payment' && <FeePayment />}
              </ScrollableAreaProvider>
            </animated.div>
          )
      )}
    </>
  )
}

export default PaymentsDrawer

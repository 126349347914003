import React from 'react'

import EditCoursePageContainer from 'apps/Courses/containers/EditCoursePage'

const EditCoursePage = (props) => {
  return (
    <EditCoursePageContainer {...props} urlCourseId={props.match.params.id} />
  )
}

export default EditCoursePage

import React from 'react'

import classNames from 'classnames'
import styles from './styles.module.scss'

const LoadingPlaceholderBlock = ({ className, ...otherProps }) => (
  <div className={classNames([styles.block, className])} {...otherProps}></div>
)

export default LoadingPlaceholderBlock

export default function buildInlinePostInputs(params) {
  let inputs = []

  Object.keys(params).forEach((key) => {
    if (Array.isArray(params[key])) {
      params[key].forEach((val) => {
        inputs = inputs.concat([[`${key}[]`, val]])
      })
    } else {
      inputs = inputs.concat([[key, params[key]]])
    }
  })

  return inputs
}

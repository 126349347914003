const getFieldValue = (field, profile) => {
  const rawValue = profile ? profile[field.name] : ''

  if (field.type === 'Schemable::Fields::Types::File') {
    const fileUploads = rawValue?.uploads || []
    return String(field.multiple_files) === 'true'
      ? fileUploads.map((val) => val.filename)
      : fileUploads[0]?.filename
  }

  if (field.type === 'Schemable::Fields::Types::Address') {
    return [
      rawValue?.line_1,
      rawValue?.line_2,
      rawValue?.line_3,
      rawValue?.city,
      rawValue?.state,
      rawValue?.post_code,
      rawValue?.country
    ]
  }

  return rawValue
}

export default getFieldValue

import {
  MULTI_VALUE_1D_TYPES,
  SINGLE_VALUE_TYPES
} from 'apps/MergeProfiles/utils/constants'

export const isSingleValueField = (field) =>
  SINGLE_VALUE_TYPES.includes(field.type) ||
  (field.type === 'Schemable::Fields::Types::DropDown' &&
    String(field.drop_down_options?.multiple) !== 'true') ||
  (field.type === 'Schemable::Fields::Types::File' &&
    String(field.multiple_files) !== 'true')

export const isMultipleValueUnidimensionField = (field) =>
  MULTI_VALUE_1D_TYPES.includes(field.type) ||
  (field.type === 'Schemable::Fields::Types::DropDown' &&
    String(field.drop_down_options?.multiple) === 'true') ||
  (field.type === 'Schemable::Fields::Types::File' &&
    String(field.multiple_files) === 'true')

import React from 'react'

import getFeeDiscounts from 'apps/ContentPages/services/payments/get-fee-discounts'

const PaymentsDrawerStateContext = React.createContext()
const SetPaymentsDrawerStateContext = React.createContext()
const SetPaymentsDrawerHistoryContext = React.createContext()
const PaymentsDrawerHistoryContext = React.createContext()

const PaymentsDrawerStateProvider = ({ children }) => {
  const [paymentsDrawerHistory, setPaymentsDrawerHistory] = React.useState([])
  const [paymentsDrawerState, setPaymentsDrawerState] = React.useState({})

  return (
    <SetPaymentsDrawerHistoryContext.Provider value={setPaymentsDrawerHistory}>
      <PaymentsDrawerHistoryContext.Provider value={paymentsDrawerHistory}>
        <SetPaymentsDrawerStateContext.Provider value={setPaymentsDrawerState}>
          <PaymentsDrawerStateContext.Provider value={paymentsDrawerState}>
            {children}
          </PaymentsDrawerStateContext.Provider>
        </SetPaymentsDrawerStateContext.Provider>
      </PaymentsDrawerHistoryContext.Provider>
    </SetPaymentsDrawerHistoryContext.Provider>
  )
}

const usePaymentsDrawerHistorySize = () => {
  const paymentsDrawerHistory = React.useContext(PaymentsDrawerHistoryContext)

  return paymentsDrawerHistory?.length
}

const usePaymentsDrawerData = () => {
  const paymentsDrawerHistory = React.useContext(PaymentsDrawerHistoryContext)

  return paymentsDrawerHistory[0] || {}
}

const useIsPaymentsDrawerOpened = () => {
  const paymentsDrawerHistory = React.useContext(PaymentsDrawerHistoryContext)

  return paymentsDrawerHistory?.length > 0
}

const useOpenPaymentsDrawer = (paymentGateway) => {
  const setPaymentsDrawerHistory = React.useContext(
    SetPaymentsDrawerHistoryContext
  )

  const openPaymentsDrawer = React.useCallback(
    ({ fee, discounts, paymentPlan }) => {
      setPaymentsDrawerHistory((paymentsDrawerHistory) => [
        {
          type: 'payment',
          paymentPlan,
          fee,
          discounts,
          paymentGateway
        },
        ...paymentsDrawerHistory
      ])
    },
    [setPaymentsDrawerHistory, paymentGateway]
  )

  return openPaymentsDrawer
}

const useOpenDetailsPaymentsDrawer = () => {
  const setPaymentsDrawerHistory = React.useContext(
    SetPaymentsDrawerHistoryContext
  )

  const openDetailsPaymentsDrawer = React.useCallback(
    ({ fee, discounts, paymentPlan }) => {
      const associatedDiscounts = getFeeDiscounts(fee, discounts)

      setPaymentsDrawerHistory((paymentsDrawerHistory) => [
        {
          type: 'details',
          fee,
          paymentPlan,
          discounts: associatedDiscounts
        },
        ...paymentsDrawerHistory
      ])
    },
    [setPaymentsDrawerHistory]
  )

  return openDetailsPaymentsDrawer
}

const useClosePaymentsDrawer = () => {
  const setPaymentsDrawerHistory = React.useContext(
    SetPaymentsDrawerHistoryContext
  )

  const closePaymentsDrawer = React.useCallback(() => {
    setPaymentsDrawerHistory([])
  }, [setPaymentsDrawerHistory])

  return closePaymentsDrawer
}

const useGoBackOnPaymentsDrawer = () => {
  const setPaymentsDrawerHistory = React.useContext(
    SetPaymentsDrawerHistoryContext
  )

  const goBackOnPaymentsDrawer = React.useCallback(() => {
    setPaymentsDrawerHistory((history) => history.slice(1))
  }, [setPaymentsDrawerHistory])

  return goBackOnPaymentsDrawer
}

export {
  PaymentsDrawerStateProvider,
  useClosePaymentsDrawer,
  usePaymentsDrawerHistorySize,
  usePaymentsDrawerData,
  useIsPaymentsDrawerOpened,
  useOpenPaymentsDrawer,
  useOpenDetailsPaymentsDrawer,
  useGoBackOnPaymentsDrawer
}

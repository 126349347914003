import React from 'react'

import { useCurrentUser } from '@fullfabric/authorization-officer'

export const formatCurrency = (value, currency, userLocale = 'en-GB') => {
  const floatValue = value / 100.0

  if (Intl && Intl.NumberFormat && currency) {
    const formatter = new Intl.NumberFormat(userLocale, {
      style: 'currency',
      currency,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })

    return formatter.format(floatValue)
  }

  return floatValue.toFixed(2)
}

export const useCurrencyFormatter = () => {
  const user = useCurrentUser()

  return React.useCallback(
    (value, currency) => formatCurrency(value, currency, user.locale),
    [user]
  )
}

import React from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { useParams } from 'react-router'

import moment from 'moment'

import { ArrowLeftIcon, Breadcrumbs } from '@fullfabric/alma-mater'

import { useAppData } from 'shared/contexts/AppData'
import { useLocale } from 'shared/contexts/Locale'

import api from 'apps/ContentPages/api'
import PageLayout from 'apps/ContentPages/components/page-layout'
import PageTitle from 'apps/ContentPages/components/page-title'
import ProductCatalogDetailWidget from 'apps/ContentPages/components/product-catalog-detail-widget'
import ProductCatalogDetails from 'apps/ContentPages/components/product-catalog-details'
import SidebarLayout from 'apps/ContentPages/components/sidebar-layout'
import { BackgroundImageProvider } from 'apps/ContentPages/contexts/background-image'

const ProductCatalogDetailsPage = () => {
  const { productId } = useParams()
  const { locale } = useLocale()
  const { t } = useTranslation()
  const { institution_name: insitutionName } = useAppData()

  const { data: products, status } = useQuery('get-products', () =>
    api.getProductsCatalog()
  )

  const product = React.useMemo(
    () =>
      status === 'success'
        ? products?.find((p) => p.context_id === productId)
        : null,
    [status, products, productId]
  )

  React.useEffect(() => {
    moment.locale(locale)
    document.title = [
      product
        ? `${product?.context_parent_name} ${product?.context_name}`
        : null,
      insitutionName
    ]
      .filter((x) => x)
      .join(' :: ')
  }, [locale, product, insitutionName])

  return (
    <BackgroundImageProvider>
      <SidebarLayout>
        <PageLayout
          type='action'
          withNavigationMenu
          withPrioritizedWidgets
          title={
            <>
              <Breadcrumbs
                breadcrumbs={[
                  {
                    icon: ArrowLeftIcon,
                    label: t('Product catalog'),
                    to: '/product-catalog'
                  }
                ]}
              />
              <PageTitle
                isLoading={status === 'loading'}
                subtitle={product?.context_name}
              >
                {product?.context_parent_name}
              </PageTitle>
            </>
          }
          widgets={
            <ProductCatalogDetailWidget
              isLoading={status === 'loading'}
              product={product}
            />
          }
        >
          <ProductCatalogDetails
            product={product}
            isLoading={status === 'loading'}
          />
        </PageLayout>
      </SidebarLayout>
    </BackgroundImageProvider>
  )
}

export default ProductCatalogDetailsPage

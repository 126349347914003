import React from 'react'

import { Row, Visible } from '@fullfabric/alma-mater'

const LandingPageHeader = ({ children }) => (
  <Visible lg xl>
    <Row align='start' justify='between' nogutter>
      {children}
    </Row>
  </Visible>
)

export default LandingPageHeader

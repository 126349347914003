import React from 'react'
import { QrReader } from 'react-qr-reader'

import AskGuestCountModal from 'apps/Events/components/AskGuestCountModal'
import { useOnScanSuccess } from './hooks/useOnScanSuccess'
import ScannerTarget from './ScannerTarget.js'

import styles from './styles.module.scss'

const QRCodeScanner = () => {
  const [isScanning, setIsScanning] = React.useState(false)
  const [openGuestCountModal, setOpenGuestCountModal] = React.useState(false)

  const onScanSuccess = useOnScanSuccess({
    isScanning,
    setIsScanning,
    setOpenGuestCountModal
  })

  return (
    <div>
      <QrReader
        className={styles.qrReader}
        onResult={onScanSuccess}
        containerStyle={{ height: '100vh' }}
        videoContainerStyle={{ height: '100vh' }}
        videoStyle={{ height: '100vh' }}
      />

      <AskGuestCountModal
        isOpen={openGuestCountModal}
        onClose={() => setOpenGuestCountModal(false)}
      />

      <ScannerTarget
        className={`${styles.scanTarget} ${
          isScanning ? styles.scanInProgress : ''
        }`}
      />
    </div>
  )
}

export default QRCodeScanner

import React from 'react'
import { Trans } from 'react-i18next'

import { Text } from '@fullfabric/alma-mater'

import AppLayout from 'apps/Authentication/components/AppLayout'
import AuthContentLayout from 'apps/Authentication/components/AuthContentLayout'
import FormTitle from 'apps/Authentication/components/FormTitle'
import { LOGIN_ROUTE } from 'apps/Authentication/constants/routes'

import styles from './styles.module.scss'

const InvalidStaffActivationLinkPage = () => (
  <AppLayout>
    <AuthContentLayout>
      <AuthContentLayout.Body>
        <FormTitle title={<Trans>Your activation link has expired.</Trans>} />
        <Text type='f4' marginTop='default'>
          <Trans>
            Please contact your university's IT support department in order to
            get a new activation link to access Full Fabric.
          </Trans>
        </Text>
      </AuthContentLayout.Body>
      <AuthContentLayout.Footer
        className={styles.footer}
        question={<Trans>Already have an account?</Trans>}
        linkTo={LOGIN_ROUTE}
        linkText={<Trans>Sign in</Trans>}
      />
    </AuthContentLayout>
  </AppLayout>
)

export default InvalidStaffActivationLinkPage

/* eslint-disable camelcase */

import React from 'react'
import { Trans } from 'react-i18next'

import PropTypes from 'prop-types'

import {
  ActionList,
  Button,
  Col,
  Label,
  Link,
  MoreIcon,
  Row,
  Text
} from '@fullfabric/alma-mater'

import InboxApplicCardDescriptor from 'apps/Inbox/components/InboxApplicCardDescriptor'
import { useHandleArchive } from 'apps/Inbox/hooks/handleArchiveHook'
import { applicRoute } from 'apps/Inbox/utils/cardUtils'

import styles from './styles.module.scss'

export default function InboxApplicCard({ card }) {
  const handleArchived = useHandleArchive()

  const {
    id: cardId,
    entity: { id: applicId, name },
    status,
    status_updated_by: statusUpdatedBy,
    actor,
    sub_type: subType,
    updated_at
  } = card

  return (
    <div key={cardId} className={styles.applicCard}>
      <Row middle='xs'>
        <Col xs={9} className={styles.titleContainer}>
          <Label color='green-700'>
            <Trans>Application</Trans>
          </Label>

          <Text
            className={styles.title}
            tag='span'
            type='h4'
            marginLeft='small'
            fontColor='text-base-darkest'
          >
            {name || <Trans>(no name)</Trans>}
          </Text>

          <InboxApplicCardDescriptor
            status={status}
            statusUpdatedBy={statusUpdatedBy}
            actor={actor}
            updatedAt={updated_at}
            subType={subType}
          />
        </Col>

        <Col xs={3}>
          <Row middle='xs' end='xs'>
            <Col xs={12} className={styles.buttons}>
              <Link
                disabled
                href={applicRoute(actor.id, applicId)}
                target='_top'
              >
                <Button marginRight='tiny' size='small'>
                  <Trans>View application</Trans>
                </Button>
              </Link>

              <ActionList
                iconButton={<MoreIcon />}
                iconButtonProps={{ small: true }}
                menuPosition='down'
                wrapperClassName={styles.actionListWrapper}
              >
                <ActionList.Option onClick={() => handleArchived({ card })}>
                  {status === 'active' ? (
                    <Trans>Archive</Trans>
                  ) : (
                    <Trans>Restore</Trans>
                  )}
                </ActionList.Option>
              </ActionList>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  )
}

InboxApplicCard.propTypes = {
  card: PropTypes.shape({
    id: PropTypes.string.isRequired,
    entity: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      sub_type: PropTypes.string
    }).isRequired,
    status: PropTypes.string.isRequired,
    status_updated_by: PropTypes.object,
    actor: PropTypes.shape({
      id: PropTypes.string.isRequired
    }).isRequired,
    updated_at: PropTypes.string.isRequired
  }).isRequired
}

import React from 'react'

import PageContentRefContext from 'apps/MergeProfiles/contexts/PageContentRef'
import MergeDataProvider from '../MergeDataProvider'
import PageLayout from '../PageLayout'
import ProfileSchemaProvider from '../ProfileSchemaProvider'
import Sidebar from '../Sidebar'

const AppLayout = ({ children }) => {
  const pageContentRef = React.useRef()

  return (
    <ProfileSchemaProvider>
      <MergeDataProvider>
        <PageLayout>
          <PageLayout.SidebarRegion>
            <Sidebar />
          </PageLayout.SidebarRegion>

          <PageContentRefContext.Provider value={pageContentRef}>
            <PageLayout.ContentRegion ref={pageContentRef}>
              {children}
            </PageLayout.ContentRegion>
          </PageContentRefContext.Provider>
        </PageLayout>
      </MergeDataProvider>
    </ProfileSchemaProvider>
  )
}

export default AppLayout

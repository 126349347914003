/**
 * Makes the locally stored filter compliant with the current filter. Sometimes
 * new versions can change the amount of filters or change some filter id, this
 * ensures that the stored filter does not make the applied filter invalid.
 * @param {String} storageKey Where to fetch from local storage
 * @param {Array<String>} validFilterProperties Array of the filter params ids
 * that are present in the current filter version.
 * @param {Object} initialValues Initial values of the filter, to fill out
 * properties which aren't initialized in the local storage. I.e: for new filters
 * @returns The locally stored filter value, after validation according to current filter ids
 */
const validateFilter = (
  storageKey,
  validFilterProperties,
  initialValues = {}
) => {
  const value = localStorage.getItem(storageKey)
  const storedFilter = value && JSON.parse(value)

  if (!storedFilter || !validFilterProperties?.length) {
    return storedFilter
  }

  const validFilter = validFilterProperties.reduce((acc, filterProp) => {
    if (typeof storedFilter[filterProp] === 'undefined') {
      const initializedProperty =
        initialValues && typeof initialValues[filterProp] !== 'undefined'
          ? { [filterProp]: initialValues[filterProp] }
          : {}

      return {
        ...acc,
        ...initializedProperty
      }
    }

    return { ...acc, [filterProp]: storedFilter[filterProp] }
  }, {})

  localStorage.setItem(storageKey, JSON.stringify(validFilter))

  return validFilter
}

export default validateFilter

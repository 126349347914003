import React from 'react'
import { Trans } from 'react-i18next'
import { useQuery } from 'react-query'

import { Col, Loading, Row, Text } from '@fullfabric/alma-mater'

import getContextForRole from 'apps/MergeProfiles/api/getContextForRole'
import { getRoleContextName } from 'apps/MergeProfiles/utils/journeyHelpers'
import JourneyCard from '../JourneyCard'
import JourneyNotOnMainProfileCard from './NoJourneyCard'

import styles from './styles.module.scss'

const JourneySelectionRow = ({ role, targetProfileRole }) => {
  const { data: context, isLoading } = useQuery(['contextForRole', role], () =>
    getContextForRole(role)
  )
  const { data: contextForTargetProfileRole } = useQuery(
    ['contextForRole', targetProfileRole],
    () => getContextForRole(role)
  )

  if (isLoading) return <Loading />

  return (
    <Row
      role='row'
      className={styles.journeySelectionRow}
      gutterWidth={24}
      aria-label={`select journey for ${getRoleContextName(context)}`}
    >
      {targetProfileRole && (
        <Text className={styles.orSeparator} tag='div' type='h5'>
          <Trans>OR</Trans>
        </Text>
      )}

      <Col xs={6}>
        <JourneyCard role={role} context={context} profileType='duplicate' />
      </Col>

      <Col xs={6}>
        {targetProfileRole ? (
          <JourneyCard
            role={targetProfileRole}
            context={contextForTargetProfileRole}
            profileType='main'
          />
        ) : (
          <JourneyNotOnMainProfileCard context={context} />
        )}
      </Col>
    </Row>
  )
}

export default JourneySelectionRow

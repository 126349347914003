import React from 'react'
import { render } from 'react-dom'

import Attachments from './Attachments'
import { mergeTagPlugin } from './plugins/mergeTagPlugin'

const swv = 'sw-visibility'
const osm = 'open-sm'
const otm = 'open-tm'
const ola = 'open-layers'
const obl = 'open-blocks'
const ful = 'fullscreen'
const prv = 'preview'

export const getGrapeJsOptionsConfig = (
  colors,
  emailTemplate,
  mergeTags,
  images
) => ({
  height: 'calc(100vh - 73px)',
  storageManager: false,
  colorPicker: {
    palette: [colors.length > 0 ? colors.map((color) => `#${color}`) : []]
  },
  assetManager: {
    upload: `/api/email_templates/${emailTemplate.id}/uploads`,
    uploadName: 'file',
    showUrlInput: false,
    multiUpload: false,
    autoAdd: false,
    assets: images?.map((image) => ({
      src: image.url,
      type: 'image'
    }))
  },
  canvas: {
    frameStyle: `
      body { background-color: #fff }
      * ::-webkit-scrollbar-track { background: rgba(0, 0, 0, 0.1) }
      * ::-webkit-scrollbar-thumb { background: rgba(255, 255, 255, 0.2) }
      * ::-webkit-scrollbar { width: 10px }
      *[contenteditable=true] { outline: none; }
    `
  },
  panels: {
    stylePrefix: 'pn-',
    defaults: [
      {
        id: 'commands',
        buttons: [{}]
      },
      {
        id: 'options',
        buttons: [
          {
            active: true,
            id: swv,
            className: 'fa fa-square-o',
            command: 'core:component-outline',
            context: swv,
            attributes: { title: 'View components' }
          },
          {
            id: prv,
            className: 'fa fa-eye',
            command: prv,
            context: prv,
            attributes: { title: 'Preview' }
          },
          {
            id: ful,
            className: 'fa fa-arrows-alt',
            command: ful,
            context: ful,
            attributes: { title: 'Fullscreen' }
          }
        ]
      },
      {
        id: 'views',
        buttons: [
          {
            id: osm,
            className: 'fa fa-paint-brush',
            command: osm,
            active: true,
            togglable: false,
            attributes: { title: 'Open Style Manager' }
          },
          {
            id: otm,
            className: 'fa fa-cog',
            command: otm,
            togglable: false,
            attributes: { title: 'Settings' }
          },
          {
            id: ola,
            className: 'fa fa-bars',
            command: ola,
            togglable: false,
            attributes: { title: 'Open Layer Manager' }
          },
          {
            id: obl,
            className: 'fa fa-th-large',
            command: obl,
            togglable: false,
            attributes: { title: 'Open Blocks' }
          },
          {
            id: 'attachments',
            attributes: {
              class: 'fa fa-paperclip attachments-tab',
              title: 'Attachments'
            },
            active: false,
            command: {
              run: function (editor) {
                const editPanel = document.getElementById('attachments-view')
                if (editPanel == null) {
                  const editMenuDiv = document.createElement('div')
                  editMenuDiv.id = 'attachments-view'
                  const panels = editor.Panels.getPanel('views-container')
                  panels
                    .set('appendContent', editMenuDiv)
                    .trigger('change:appendContent')

                  render(
                    <Attachments
                      attachments={emailTemplate.attachments || []}
                      templateId={emailTemplate.id}
                    />,
                    editMenuDiv
                  )
                } else {
                  editPanel.style.display = 'block'
                }
              },
              stop: function (editor) {
                const editPanel = document.getElementById('attachments-view')
                if (editPanel != null) {
                  editPanel.style.display = 'none'
                }
              }
            }
          }
        ]
      }
    ]
  },
  pluginsOpts: {
    [mergeTagPlugin]: {
      mergeTags
    }
  }
})

import { useCallback, useState } from 'react'

const usePublisher = () => {
  const [subscribers, setSubscribers] = useState([])

  const subscribe = useCallback((newSubscriber) => {
    setSubscribers((existing) => [...existing, newSubscriber])
  }, [])

  const unsubscribe = useCallback((unsubscriber) => {
    setSubscribers((existing) =>
      existing.filter((subscriber) => subscriber !== unsubscriber)
    )
  }, [])

  const notify = useCallback(
    (...notifications) => {
      subscribers.forEach((subscription) => subscription(...notifications))
    },
    [subscribers]
  )

  return { notify, subscribe, unsubscribe }
}

export default usePublisher

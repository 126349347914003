import React from 'react'

export const StepsContext = React.createContext({
  importSteps: [],
  stepsCompleted: [],
  setStepsCompleted: null,
  currentStep: null,
  previousStep: null,
  nextStep: null,
  setCurrentStep: null
})

export const useStepsCompleted = () => {
  const { stepsCompleted, setStepsCompleted, importSteps } =
    React.useContext(StepsContext)

  const addCompletedStep = React.useCallback(
    (stepName) => {
      if (!stepsCompleted.includes(stepName))
        setStepsCompleted([...stepsCompleted, stepName])
    },
    [stepsCompleted, setStepsCompleted]
  )

  const previousStepsFrom = React.useCallback(
    (stepId) => {
      const summaryStepIndex = importSteps.findIndex(
        (step) => step.id === stepId
      )
      return importSteps.slice(0, summaryStepIndex).map((step) => step.id)
    },
    [importSteps]
  )

  const isStepUnlocked = React.useCallback(
    (stepId) => {
      const previousStepsIds = previousStepsFrom(stepId)
      return previousStepsIds.every((step) => stepsCompleted.includes(step))
    },
    [previousStepsFrom, stepsCompleted]
  )

  return {
    stepsCompleted,
    setStepsCompleted,
    addCompletedStep,
    isStepUnlocked,
    previousStepsFrom
  }
}

export const useStepState = () => {
  const { currentStep, previousStep, nextStep } = React.useContext(StepsContext)

  return { currentStep, previousStep, nextStep }
}

export const useCurrentStep = (stepId) => {
  const { setCurrentStep } = React.useContext(StepsContext)

  React.useEffect(() => {
    setCurrentStep(stepId)
  }, [setCurrentStep, stepId])
}

export const useSetCurrentStep = () =>
  React.useContext(StepsContext)?.setCurrentStep

export const useImportSteps = () => {
  const { importSteps } = React.useContext(StepsContext)
  return importSteps
}

export const useImportType = () => {
  const { importType } = React.useContext(StepsContext)
  return importType
}

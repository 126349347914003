.react-app ._wrapper_wyfz7_1 {
  --header-height: 57px;
}

.react-app ._wrapper_wyfz7_1._desktop_wyfz7_5 {
  --header-height: 72px;
}

.react-app ._placeholder_wyfz7_9 {
  height: var(--header-height);
  width: 100%;
  background-color: var(--ff-bg-base-lightest);
}

.react-app ._container_wyfz7_15 {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: var(--header-height);
  width: 100%;
  border-bottom: var(--border-base-lighter);
  background-color: var(--ff-bg-base-lightest);
  transition: top 200ms ease-in-out, box-shadow 200ms ease-in-out;
}

.react-app ._container_wyfz7_15._plain_wyfz7_30 {
  justify-content: flex-start;
}

.react-app ._container_wyfz7_15._hidden_wyfz7_34 {
  top: calc(-1 * var(--header-height));
}

.react-app ._logoContainer_wyfz7_38 {
  display: block;
  margin: 0 var(--space-default);
}
.react-app ._logoContainer_wyfz7_38._desktop_wyfz7_5 {
  margin-left: var(--space-big);
}
.react-app ._logoContainer_wyfz7_38 img {
  display: block;
  height: auto;
  max-width: 150px;
  max-height: 40px;
}
.react-app ._logoContainer_wyfz7_38 ._logoLink_wyfz7_51 {
  display: block;
  height: 100%;
}
import React from 'react'

import { useSelectedData } from 'apps/Imports/contexts/selected-data'

const useOnMappingChange = () => {
  const { data, onDataChanged } = useSelectedData()

  const onChange = React.useCallback(
    (targetField, colHeader) => {
      const newMappings = data.mappings
        .map((mapping) =>
          mapping.field === targetField ? { ...mapping, field: null } : mapping
        ) // clear previously selected mapping
        .map((mapping) =>
          mapping.header === colHeader
            ? { header: colHeader, field: targetField }
            : mapping
        ) // set new mapping selected

      onDataChanged('mappings', newMappings)
    },
    [onDataChanged, data]
  )

  return onChange
}

export default useOnMappingChange

import * as React from 'react'
import { useTranslation } from 'react-i18next'

import { Button, Dropdown } from '@fullfabric/alma-mater'

import ColumnsIcon from './ColumnsIcon'
import ColumnsMenu from './ColumnsMenu'
import useColumnSelectionState from './useColumnSelectionState'

const SelectColumnsButton = ({ columns }) => {
  const { t } = useTranslation()
  const {
    onSearchChange,
    columnIdsFiltered,
    selectableColumns,
    visibleColumnCount,
    resetSearch
  } = useColumnSelectionState(columns)

  const sectionHash = selectableColumns
    .filter((column) => columnIdsFiltered.includes(column.id))
    .reduce((columnsBySection, column) => {
      const section = column.section || t('Other')

      return {
        ...columnsBySection,
        [section]: (columnsBySection[section] || []).concat(column)
      }
    }, {})

  const isSingleUntitledSection =
    Object.keys(sectionHash).length === 1 &&
    Object.keys(sectionHash)[0] === t('Other')

  return (
    <Dropdown>
      <Dropdown.Toggle>
        <Button size='small'>
          <ColumnsIcon className='marginRight-small' />{' '}
          {t('{{count}} Columns', { count: visibleColumnCount })}
        </Button>
      </Dropdown.Toggle>
      <Dropdown.Menu aria-label='select columns menu'>
        <ColumnsMenu
          onSearchChange={onSearchChange}
          sectionHash={sectionHash}
          isSingleUntitledSection={isSingleUntitledSection}
          resetSearch={resetSearch}
        />
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default SelectColumnsButton

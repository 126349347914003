import useSettingByName from 'apps/ContentPages/hooks/use-setting-by-name'

export const useUnlayerOrGrapejsEnabled = () => {
  const unlayerEnabled = useSettingByName(
    'modules.core.submodules.emails.features.unlayer_editor.enable'
  )

  const grapejsEnabled = useSettingByName(
    'modules.core.submodules.emails.features.grapejs_editor.enable'
  )

  return unlayerEnabled || grapejsEnabled
}

import React from 'react'
import { Trans } from 'react-i18next'

import moment from 'moment'

import useFetchedImportData from 'apps/Imports/components/import-report-step/hooks/use-fetched-import-data'
import { useCommunicationPolicies } from 'apps/Imports/hooks/useCommunicationPolicies'
import { useMarketingPolicy } from 'apps/Imports/hooks/useMarketingPolicy'
import arrayToSentence from 'apps/Imports/utils/array-to-sentence'
import { MARKETING_CHANNELS } from 'apps/Imports/utils/profile-import/constants'
import ReportSummaryField from '../../report-summary-field'

const PolicyConsentSummary = () => {
  const { document } = useFetchedImportData()
  const marketingPolicy = useMarketingPolicy()
  const communicationPolicies = useCommunicationPolicies()

  const policyProcessor = (document.processors || []).find(
    ({ type }) => type === 'ImportService::Processors::PoliciesAcceptance'
  )

  if (!policyProcessor) return null

  const acceptance =
    (marketingPolicy && policyProcessor.options.policies[marketingPolicy.id]) ||
    {}
  const marketingOpts = acceptance.options || {}
  const selectedOpts = Object.keys(marketingOpts).reduce((acc, channel) => {
    return MARKETING_CHANNELS[channel] && marketingOpts[channel] === 'true'
      ? [...acc, MARKETING_CHANNELS[channel]]
      : acc
  }, [])

  const dateOfConsent = moment(policyProcessor.options.policies.date_of_consent)

  return (
    <>
      {communicationPolicies
        .filter((x) => x)
        .map((pol, idx) => {
          const acceptance =
            (pol && policyProcessor.options.policies[pol.id]) || {}
          const communicationOpts = acceptance.options || {}
          const selectedOpts = Object.keys(communicationOpts).reduce(
            (acc, channel) => {
              return MARKETING_CHANNELS[channel] &&
                communicationOpts[channel] === 'true'
                ? [...acc, MARKETING_CHANNELS[channel]]
                : acc
            },
            []
          )

          return (
            <ReportSummaryField
              label={<Trans>Would like to be contacted by {pol.name}</Trans>}
              value={
                policyProcessor.options.policies[pol.id]?.accepted !==
                'true' ? (
                  <Trans>No new agreement made</Trans>
                ) : (
                  arrayToSentence(selectedOpts) || (
                    <Trans>Does not want to receive communications</Trans>
                  )
                )
              }
              marginTop={idx === 0 ? 'more' : 'small'}
            />
          )
        })}
      {marketingPolicy && (
        <ReportSummaryField
          label={<Trans>Would like to be contacted by Marketing policy</Trans>}
          value={
            policyProcessor.options.policies[marketingPolicy.id]?.accepted !==
            'true' ? (
              <Trans>No new agreement made</Trans>
            ) : (
              arrayToSentence(selectedOpts) || (
                <Trans>Does not want to receive marketing materials</Trans>
              )
            )
          }
          marginTop={communicationPolicies?.length === 0 ? 'more' : 'small'}
        />
      )}

      <ReportSummaryField
        label={<Trans>Source of consent</Trans>}
        value={policyProcessor.options.policies.source_of_consent}
        marginTop='small'
      />

      <ReportSummaryField
        label={<Trans>Date and time of consent</Trans>}
        value={
          <Trans>
            {dateOfConsent.format('LL')} at {dateOfConsent.format('LT')}
          </Trans>
        }
        marginTop='small'
      />
    </>
  )
}

export default PolicyConsentSummary

.react-app ._legendContainer_1xdc9_1 {
  list-style: none;
  margin: 0;
  padding-left: 0;
}

.react-app ._itemContainer_1xdc9_7 {
  display: inline-flex;
  align-items: center;
  margin-right: var(--space-default);
}

.react-app ._itemIcon_1xdc9_13 {
  height: 4px;
  width: 12px;
  border-radius: 2px;
  margin-right: var(--space-tiny);
  display: inline-block;
}
import { connectRouter } from 'connected-react-router'
import { combineReducers } from 'redux'

import archiveCourse from './archiveCourse'
import courseList from './courseList'
import deleteCourse from './deleteCourse'
import editCourse from './editCourse'
import newCourse from './newCourse'
import subjectList from './subjectList'

const bootstrapData = (state = {}) => state
const currentUser = (state = {}) => state
const appData = (state = {}) => state
const settings = (state = {}) => state

const createRootReducer = (history) =>
  combineReducers({
    appData,
    settings,
    bootstrapData,
    currentUser,
    subjectList,
    courseList,
    newCourse,
    editCourse,
    deleteCourse,
    archiveCourse,
    router: connectRouter(history)
  })

export default createRootReducer

import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'

import moment from 'moment'

import {
  Button,
  Field,
  Modal,
  ModalButtons,
  ModalTitle,
  Text
} from '@fullfabric/alma-mater'

import { useLocale } from 'shared/contexts/Locale'

import api from '../api'

import styles from './external-payer-request-modal/styles.module.scss'

export default function ExternalPayerRequestModal({
  isOpen,
  onClose,
  onAction,
  payable,
  readonly
}) {
  const { locale } = useLocale()
  const { t } = useTranslation()
  const [data, setData] = useState({})
  const [errors, setErrors] = useState({})

  const { external_payer_request: externalPayerRequest } = payable

  const { mutate: revokeExternalPayerRequest, isLoading: isRevoking } =
    useMutation(
      async () => {
        await api.revokeExternalPayerRequest({
          externalPayerRequestId: externalPayerRequest.id
        })
      },
      {
        onSuccess: () => onAction('revoke'),
        onError: (error) => console.error('error', error)
      }
    )

  const { mutate: createExternalPayerRequest, isLoading: isCreating } =
    useMutation(
      async () => {
        await api.createExternalPayerRequest({
          payableId: payable.id,
          payerEmail: externalPayerRequest?.email || data.email,
          payerName: externalPayerRequest?.name || data.name
        })
      },
      {
        onSuccess: () => onAction('submit'),
        onError: (error) => {
          const externalPayerRequestErrors =
            error.data['book_keeper/payment_plan/fee']?.external_payer_request
          if (externalPayerRequestErrors?.email) {
            setErrors({ email: <Trans>Email address is invalid</Trans> })
          } else {
            setErrors({ name: <Trans>Name is invalid</Trans> })
          }
          console.error('error', error)
        }
      }
    )

  const handleRevoke = () => revokeExternalPayerRequest()
  const handleSubmit = () => createExternalPayerRequest()

  return (
    <Modal
      role='dialog'
      aria-modal='true'
      aria-labelledby='externalPayerRequestModal'
      size='medium'
      isOpen={isOpen}
      header={
        <ModalTitle
          title={
            <span id='externalPayerRequestModal'>
              {externalPayerRequest ? (
                <Trans>Manage payer</Trans>
              ) : (
                <Trans>Send to payer</Trans>
              )}
            </span>
          }
          onClose={onClose}
        />
      }
      footer={
        !readonly && (
          <ModalButtons>
            {externalPayerRequest && (
              <Button
                negative
                onClick={handleRevoke}
                loading={isRevoking}
                disabled={isCreating}
                className={styles.cancelExternalPayerBtn}
              >
                <Trans>Cancel external payer</Trans>
              </Button>
            )}
            <Button onClick={onClose} disabled={isCreating || isRevoking}>
              <Trans>Close</Trans>
            </Button>
            <Button
              primary
              onClick={handleSubmit}
              loading={isCreating}
              disabled={isRevoking}
            >
              {externalPayerRequest ? (
                <Trans>Resend fee details</Trans>
              ) : (
                <Trans>Send fee</Trans>
              )}
            </Button>
          </ModalButtons>
        )
      }
    >
      {!readonly && (
        <Text
          type='f5'
          fontColor='text-base-darkest'
          className='marginBottom-default'
        >
          {externalPayerRequest ? (
            t('On {{date}}, this fee was sent to an external payer.', {
              date: moment(externalPayerRequest.created_at)
                .locale(locale)
                .format('LLLL')
            })
          ) : (
            <Trans>
              An email containing all the payment details will be sent to the
              external payer.
            </Trans>
          )}
        </Text>
      )}
      <Field
        id='email'
        type='email'
        label={<Trans>Payer's email address</Trans>}
        required
        disabled={!!externalPayerRequest}
        value={externalPayerRequest?.email}
        onChange={(id, value) => setData({ ...data, [id]: value })}
        onBlur={(id, value) => true}
        error={errors.email}
      />
      <Field
        id='name'
        type='text'
        label={<Trans>Payer's name</Trans>}
        required
        disabled={!!externalPayerRequest}
        value={externalPayerRequest?.name}
        onChange={(id, value) => setData({ ...data, [id]: value })}
        onBlur={(id, value) => true}
        error={errors.name}
      />
    </Modal>
  )
}

import React, { useCallback, useEffect, useState } from 'react'

import { useScreenClass } from '@fullfabric/alma-mater'

import usePathObserver from 'apps/ContentPages/hooks/use-path-observer'
import Link from './link'

import classNames from 'classnames'
import styles from './styles.module.scss'

const LinkAccordionFolder = ({ links, children }) => {
  const screenClass = useScreenClass()
  const isTabletNav = screenClass === 'md'
  const [isOpen, setIsOpen] = useState(false)
  const { subscribe, unsubscribe } = usePathObserver()
  const toggleOpen = () => setIsOpen((state) => !state)
  const closeAccordion = useCallback(() => setIsOpen(false), [])

  useEffect(() => {
    subscribe(closeAccordion)

    return () => {
      unsubscribe(closeAccordion)
    }
  }, [subscribe, unsubscribe, closeAccordion])

  const Toggle = () => children({ isOpen, toggleOpen })

  return (
    <>
      <div className={classNames([isOpen && styles.activeToggle])}>
        <Toggle />
      </div>

      {isOpen && (
        <div
          className={classNames(
            styles.folderContainer,
            isTabletNav && styles.tabletNav
          )}
        >
          {links.map((link, index) => (
            <Link
              key={link.path}
              external={link.external}
              to={link.path}
              label={link.navigation_label}
              first={index === 0}
              last={index === links.length - 1}
            />
          ))}
        </div>
      )}
    </>
  )
}

export default LinkAccordionFolder

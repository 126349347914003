import React from 'react'

import PropTypes from 'prop-types'

import { DropdownInput } from '@fullfabric/alma-mater'

import {
  useAllowedLocales,
  useChangeSelectedLocale,
  useSelectedLocale
} from 'apps/Policies/contexts/SelectedLocale'
import { setCookieLocale } from 'apps/Policies/utils/locales/cookie-locale'

import cx from 'classnames'
import styles from './styles.module.scss'

const LOCALE_MAP = {
  EN: 'English',
  ES: 'Español',
  FR: 'Français',
  DE: 'Deutsch',
  NL: 'Dutch',
  SV: 'Swedish',
  IT: 'Italiano',
  DA: 'Dansk'
}

const toLocaleObj = (locale) => ({
  value: locale,
  label: LOCALE_MAP[locale.slice(0, 2).toUpperCase()]
})

const LocaleSelector = ({ top, className }) => {
  const selectedLocale = useSelectedLocale()
  const onChangeSelectedLocale = useChangeSelectedLocale()
  const allowedLocales = useAllowedLocales()

  const onSelectLocale = React.useCallback(
    (newLocale) => {
      setCookieLocale(newLocale)
      onChangeSelectedLocale(newLocale)
    },
    [onChangeSelectedLocale]
  )

  if (!allowedLocales || allowedLocales.length <= 1) return null

  return (
    <DropdownInput
      id='locales'
      aria-label='Change language'
      styles={{
        control: (styles) => ({ ...styles, top: -top })
      }}
      className={cx(className, styles.localeSelector)}
      onChange={onSelectLocale}
      value={selectedLocale}
      options={allowedLocales.map(toLocaleObj)}
    />
  )
}

LocaleSelector.propTypes = {
  className: PropTypes.string
}

export default LocaleSelector

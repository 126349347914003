import React from 'react'
import { Trans } from 'react-i18next'

import PropTypes from 'prop-types'

import CardStatusLink from './CardStatusLink'
import CardTypeLink from './CardTypeLink'

import cx from 'classnames'
import styles from './styles.module.scss'

export default function InboxNav({ loading }) {
  return (
    <div className={cx(styles.navCard, loading && styles.disabled)}>
      <div className={styles.navList}>
        <CardTypeLink label={<Trans>All</Trans>} />
        <CardTypeLink type='email' label={<Trans>Emails</Trans>} />
        <CardTypeLink type='task' label={<Trans>Tasks</Trans>} />
        <CardTypeLink type='event' label={<Trans>Events</Trans>} />
        <CardTypeLink type='applic' label={<Trans>Applications</Trans>} />
        <CardTypeLink type='form' label={<Trans>Forms</Trans>} />
      </div>

      <div className={styles.navList}>
        <CardStatusLink status='archived' label={<Trans>Archived</Trans>} />
      </div>
    </div>
  )
}

InboxNav.propTypes = {
  loading: PropTypes.bool
}

.react-app ._container_ehtur_1 {
  margin-bottom: var(--space-more);
  width: 100%;
}

.react-app ._title_ehtur_6 {
  margin: 0;
}

.react-app ._subtitle_ehtur_10 {
  margin-top: var(--space-small);
  margin-bottom: 0;
}

.react-app ._thinSubtitle_ehtur_15 {
  margin: 0;
}
export const getPeriodOptions = (t) => [
  // TODO: fix behaviour in edge-cases
  // {
  //   value: "first_open_template",
  //   label: t("Since applications opened"),
  // },
  {
    value: 'this_week',
    label: t('This week')
  },
  {
    value: 'this_month',
    label: t('This month')
  },
  {
    value: 'this_year',
    label: t('This year')
  },

  {
    value: 'last_week',
    label: t('Last week')
  },
  {
    value: 'last_month',
    label: t('Last month')
  },
  {
    value: 'last_year',
    label: t('Last year')
  }
]

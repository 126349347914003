import React from 'react'

import { Col, Row } from '@fullfabric/alma-mater'

import AgeChart from './AgeChart'
import GenderChart from './GenderChart'

const DemographicsChartsRow = () => (
  <Row>
    <Col md={6}>
      <GenderChart />
    </Col>
    <Col md={6}>
      <AgeChart />
    </Col>
  </Row>
)

export default DemographicsChartsRow

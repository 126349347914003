import 'css-chunk:src/apps/ContentPages/components/fees-table/styles.module.scss';export default {
  "react-app": "react-app",
  "table": "_table_11qff_1",
  "header": "_header_11qff_18",
  "headerItem": "_headerItem_11qff_23",
  "mobile": "_mobile_11qff_32",
  "row": "_row_11qff_36",
  "borderless": "_borderless_11qff_41",
  "tableData": "_tableData_11qff_45",
  "leftmostColumn": "_leftmostColumn_11qff_54",
  "actionsColumn": "_actionsColumn_11qff_58",
  "bigger": "_bigger_11qff_61",
  "stateIconColumn": "_stateIconColumn_11qff_65",
  "placeholder": "_placeholder_11qff_68",
  "amountColumn": "_amountColumn_11qff_72",
  "arrowIconColumn": "_arrowIconColumn_11qff_77"
};
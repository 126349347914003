import React from 'react'

const ArrowRightIcon = () => (
  <svg
    width='8'
    height='12'
    viewBox='0 0 8 12'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M-1.7325e-06 10L2 12L8 6L2 -5.24537e-07L-1.9868e-06 2L4 6L-1.7325e-06 10Z'
      fill='#9399A2'
    />
  </svg>
)

export default ArrowRightIcon

import { utils } from '@fullfabric/public-api'

const getCourseSchema = async () => {
  const response = await fetch(`/api/institutions/schemas/course`, {
    credentials: 'same-origin'
  })

  return await utils.checkResponse(response)
}

export default getCourseSchema

import React from 'react'

import flattenCourseTypes from 'apps/StudyPlans/utils/flattenCourseType'

export const SubjectEditionDataContext = React.createContext({})

export const SubjectEditionDataProvider = ({
  children,
  subjectData = {
    createSubjectBeforeAdding: false,
    unit_ids: [],
    counts_towards_curricular_grade: 'depends_on_pass_fail',
    counts_towards_total_credits: 'depends_on_pass_fail',
    prerequisites: []
  },
  originalSubjectData
}) => {
  const [subjectEditionData, setSubjectEditionData] = React.useState({
    ...subjectData,
    ...flattenCourseTypes(subjectData.course_types || [])
  })
  const [subjectEditionErrors, setSubjectEditionErrors] = React.useState({})

  return (
    <SubjectEditionDataContext.Provider
      value={{
        subjectEditionData,
        setSubjectEditionData,
        subjectEditionErrors,
        setSubjectEditionErrors,
        originalSubjectData
      }}
    >
      {children}
    </SubjectEditionDataContext.Provider>
  )
}

export const useSubjectEditionData = () =>
  React.useContext(SubjectEditionDataContext).subjectEditionData

export const useOriginalSubjectData = () =>
  React.useContext(SubjectEditionDataContext).originalSubjectData

export const useSetSubjectEditionData = () =>
  React.useContext(SubjectEditionDataContext).setSubjectEditionData

export const useSubjectEditionErrors = () =>
  React.useContext(SubjectEditionDataContext).subjectEditionErrors

export const useSetSubjectEditionErrors = () =>
  React.useContext(SubjectEditionDataContext).setSubjectEditionErrors

export const useOnChangeSubjectEditionData = () => {
  const setSubjectEditionData = useSetSubjectEditionData()
  const setSubjectEditionErrors = useSetSubjectEditionErrors()

  return React.useCallback(
    (fieldName, value) => {
      setSubjectEditionData((data) => ({
        ...data,
        [fieldName]: value
      }))

      setSubjectEditionErrors((errors) => {
        const { [fieldName]: fieldError, ...remainingErrors } = errors
        return remainingErrors
      })
    },
    [setSubjectEditionData, setSubjectEditionErrors]
  )
}

import React from 'react'
import { useQueryClient } from 'react-query'
import { useParams } from 'react-router'

import arrayAsGroupedOptions from 'apps/Imports/utils/array-as-grouped-options'

const useFetchedImportData = () => {
  const { documentId } = useParams()
  const queryClient = useQueryClient()

  const importData = queryClient.getQueryData(['getImport', documentId])

  const mappingFieldOptions = React.useMemo(
    () => arrayAsGroupedOptions(importData.mapping_options),
    [importData]
  )

  return { ...importData, mappingFieldOptions }
}

export default useFetchedImportData

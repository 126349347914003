import React from 'react'
import { useRouteMatch } from 'react-router-dom'

import PropTypes from 'prop-types'

import { Link } from '@fullfabric/alma-mater'

import classNames from 'classnames'
import styles from './styles.module.scss'

export default function Linkable({
  external,
  to,
  children,
  selected,
  className,
  ...otherProps
}) {
  const match = useRouteMatch(to)
  const hasTargetPage = typeof to !== 'undefined' || typeof href !== 'undefined'
  const isCurrentPage = match && match.isExact && hasTargetPage
  const elementType = hasTargetPage ? Link : 'button'
  const navigationProps = external ? { href: to } : { to }

  const props = {
    className: classNames([
      styles.link,
      (isCurrentPage || selected) && styles.selectedLink,
      className
    ]),
    ...navigationProps,
    ...otherProps
  }

  return React.createElement(elementType, props, [children])
}

Linkable.propTypes = {
  external: PropTypes.bool,
  to: PropTypes.string,
  children: PropTypes.node,
  selected: PropTypes.bool,
  className: PropTypes.string
}

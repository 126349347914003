.react-app ._container_1pyz9_1 {
  position: fixed;
  top: 0;
  left: -100vw;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  width: 100vw;
  background-color: var(--ff-bg-base-lightest);
  transition: left 250ms ease-out;
}
.react-app ._container_1pyz9_1._desktop_1pyz9_14 {
  max-width: 414px;
}

.react-app ._container_1pyz9_1._opened_1pyz9_18 {
  left: 0;
  transition: left 250ms ease-out;
}

.react-app ._shadow_1pyz9_23 {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  opacity: 0;
  background-color: var(--ff-bg-haze);
  transition: width 0s 100ms, height 0s 100ms, opacity 100ms;
}

.react-app ._shadow_1pyz9_23._opened_1pyz9_18 {
  opacity: 1;
  width: 100vw;
  height: 100vh;
  transition: opacity 100ms;
}

.react-app ._header_1pyz9_42 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: var(--space-small) var(--space-default);
}

.react-app ._container_1pyz9_1._desktop_1pyz9_14 ._header_1pyz9_42 {
  padding: var(--space-more) var(--space-more);
  padding-left: var(--space-big);
}

.react-app ._closeButton_1pyz9_56 {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}
.react-app ._closeButton_1pyz9_56 svg {
  display: block;
}

.react-app ._navigationContainer_1pyz9_69 {
  flex: 1;
  width: 100%;
  overflow-y: hidden;
}
import * as React from 'react'
import { useTranslation } from 'react-i18next'

import { ArrowRightIcon, IconButton, Link } from '@fullfabric/alma-mater'

import ExpandCourseIcon from './ExpandCourseIcon'

import styles from '../styles.module.scss'

const ArrowCell = ({ row, data }) => {
  const { t } = useTranslation()
  const productData = data[row.index]
  const isExpandedRow = !!row.isExpanded

  if (productData.type === 'Institutions::Course') {
    return (
      <div className={styles.dataCell}>
        <IconButton
          plain
          {...row.getToggleRowExpandedProps()}
          aria-expanded={isExpandedRow}
          aria-label={t('toggle sub-table for classes which this course is in')}
          className={
            isExpandedRow ? styles.expandCourseButtonActive : undefined
          }
        >
          <ExpandCourseIcon expanded={isExpandedRow} />
        </IconButton>
      </div>
    )
  }

  if (!productData.has_study_plan) {
    return null
  }

  return (
    <div className={styles.dataCell}>
      <Link
        withoutStyles
        to={`/reports/product_performance/${productData.id}`}
        role='link'
        aria-label={t('show class products')}
      >
        <IconButton plain tag='div' aria-hidden='true'>
          <ArrowRightIcon />
        </IconButton>
      </Link>
    </div>
  )
}

export default ArrowCell

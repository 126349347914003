import getClassAttributeDistribution from './getClassAttributeDistribution'

const getClassGenderDistribution = async ({ ...params }) => {
  return await getClassAttributeDistribution({
    top: 3,
    ...params
  })
}

export default getClassGenderDistribution

import React from 'react'
import { Trans } from 'react-i18next'

import { Field } from '@fullfabric/alma-mater'

const CourseCreateFormStatus = ({ data, onChange }) => {
  return (
    <div role='form' aria-label='course status creation form'>
      <Field
        id='state_auto_mode'
        type='radio'
        label={<Trans>Status Update</Trans>}
        value={String(data.state_auto_mode)}
        onChange={(field, value) => onChange(field, value === 'true')}
        inputOptions={[
          {
            label: <Trans>Update state automatically</Trans>,
            value: 'true',
            instructions: (
              <Trans>
                The state of the class will update automatically based on your
                set dates and times
              </Trans>
            )
          },
          {
            label: <Trans>Do not update state automatically</Trans>,
            value: 'false',
            instructions: (
              <Trans>
                The state of the class must be manually changed using the drop
                down
              </Trans>
            )
          }
        ]}
      />

      {!data.state_auto_mode && (
        <Field
          id='state'
          type='dropdown'
          label={<Trans>Status</Trans>}
          value={data.state}
          onChange={onChange}
          inputOptions={{
            options: [
              { value: 'init', label: <Trans>Class new</Trans> },
              { value: 'open', label: <Trans>Applications Open</Trans> },
              { value: 'closed', label: <Trans>Applications Closed</Trans> },
              {
                value: 'in_progress',
                label: <Trans>Class in progress</Trans>
              },
              { value: 'done', label: <Trans>Class finished</Trans> }
            ]
          }}
        />
      )}
    </div>
  )
}

export default CourseCreateFormStatus

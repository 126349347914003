import React from 'react'
import { useTranslation } from 'react-i18next'

import moment from 'moment'

import InfoRow from './InfoRow'

const LastSeenInfo = ({ profile }) => {
  const { t, i18n } = useTranslation()

  if (!profile.last_seen)
    return <InfoRow main={t('Last seen')} secondary={t('Never')} />

  const createdAt = moment(profile.last_seen).locale(i18n.language)

  return (
    <InfoRow
      main={t('Last seen {{fromDate}}', { fromDate: createdAt.fromNow() })}
      secondary={createdAt.format('LL')}
    />
  )
}

export default LastSeenInfo

{
  "Email": "Email",
  "Email address is invalid": "Indirizzo email non valido",
  "I accept the privacy policy": "Accetto la normativa sulla privacy",
  "Privacy Policy": "Normativa sulla privacy",
  "Please accept the privacy policy to continue": "Accetta la normativa sulla privacy per continuare",
  "Welcome back": "Bentornato/a",
  "Please enter your password for security reasons.": "Per motivi di sicurezza, inserisci la tua password.",
  "Email address ": "Indirizzo email ",
  "Password": "Password",
  "Forgot your password?": "Hai dimenticato la password?",
  "An email has been sent to  with an authentication link that will take you to your application.": "An email has been sent to  with an authentication link that will take you to your application.",
  "Once you have accessed the authentication link you can close this page.": "Una volta aperto il link per l’autenticazione, potrai chiudere questa pagina.",
  "Didn’t receive the email?": "Non hai ricevuto l’email?",
  "Resend email with authentication link": "Invia di nuovo email con link per l’autenticazione",
  "Email sent": "Email inviata",
  "Welcome! Let's start your new application.": "Benvenuto/a! Iniziamo la tua nuova richiesta.",
  "Welcome! Let's start your new registration.": "Benvenuto/a! Iniziamo la tua nuova registrazione.",
  "Please enter the following details so that you can revisit your application if you exit it.": "Inserisci i seguenti dettagli per poter ritrovare la tua richiesta in caso di uscita.",
  "Please enter the following details so that you can revisit your registration if you exit it.": "Inserisci i seguenti dettagli per poter ritrovare la tua registrazione in caso di uscita.",
  "First name": "Nome",
  "Last name": "Cognome",
  "Back": "Indietro",
  "Continue to application": "Continua alla richiesta",
  "Continue to registration": "Continua alla registrazione",
  "Data Protection regulation": "Normativa sulla protezione dei dati",
  "Someone already registered with that email address": "Qualcuno si è già registrato con questo indirizzo email",
  "Email address can't be blank": "L’indirizzo email non può essere vuoto",
  "Continue": "Continua",
  "Participant details": "Dettagli partecipanti",
  "Please enter the details of the participant": "Inserisci i dettagli del partecipante",
  "Registration already exists": "La registrazione esiste già",
  "Application already exists": "La richiesta esiste già",
  "There are fields with errors": "Ci sono dei campi con errori",
  "Please correct the fields with errors": "Correggi i campi con errori",
  "First name can't be blank": "Il nome non può essere vuoto",
  "Last Name": "Cognome",
  "Last name can't be blank": "Il cognome non può essere vuoto",
  "Your employment details": "I tuoi dettagli sull’occupazione",
  "To register on behalf of someone else we need more information on your current employment details.": "Per registrarti per conto di qualcun altro devi fornirci più informazioni riguardo i tuoi attuali dettagli sull’occupazione.",
  "There was an error in your submission, please try again in a few seconds. If the issue persists, please contact us.": "Si è verificato un errore durante l’invio, riprova tra qualche secondo. Se l’errore persiste, contattaci.",
  "The link has expired.": "Il link è scaduto.",
  "Please re-enter your details to receive a new authentication email.": "Inserisci nuovamente i tuoi dettagli per ricevere una nuova email per l’autenticazione.",
  "Please enter your email address so that we can retrieve your information if you have visited before.": "Inserisci il tuo indirizzo email per consentirci di recuperare i tuoi dati se hai già effettuato una visita in precedenza.",
  "Please select an option": "Seleziona un’opzione",
  "I am starting a registration for myself": "Sto iniziando una registrazione per me",
  "I am starting an application for myself": "Sto iniziando una richiesta per me",
  "I am starting a registration on behalf of someone else": "Sto iniziando una registrazione per qualcun altro",
  "I am starting an application on behalf of someone else": "Sto iniziando una richiesta per qualcun altro",
  "I am continuing an existing registration": "Sto continuando una registrazione esistente",
  "I am continuing an existing application": "Sto continuando una richiesta esistente",
  "An email has been sent to {{email}} with an authentication link that will take you to your application.": "È stata inviata un’email a {{email}} con un link per l’autenticazione che ti porterà alla tua richiesta."
}

import React, { useState } from 'react'
import { Trans } from 'react-i18next'

import {
  Button,
  Field,
  Modal,
  ModalButtons,
  ModalTitle
} from '@fullfabric/alma-mater'
import { useCurrentUser } from '@fullfabric/authorization-officer'

import { useAppData } from 'shared/contexts/AppData'
import { useLocale } from 'shared/contexts/Locale'

import api from 'apps/ContentPages/api'
import Header from './header'
import {
  getPrivacyPolicy,
  isPrivacyAgreementValid,
  shouldDisplayPrivacyPolicyForm
} from './utils'

import classNames from 'classnames'
import styles from './styles.module.scss'

const PrivacyPolicyModal = () => {
  const user = useCurrentUser()
  const appData = useAppData()
  const { locale } = useLocale()
  const [agreement, setAgreement] = useState({})
  const [isAgreementValid, setIsAgreementValid] = useState(false)
  const [errorSubmitting, setErrorSubmiting] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [accepted, setAccepted] = useState()

  const privacyPolicy = getPrivacyPolicy(appData)

  const submitPolicy = async () => {
    try {
      if (isAgreementValid) {
        await api.submitPolicyAcceptance({
          agreement,
          policyId: appData.privacy_policy?.id,
          userId: user?.id
        })
        setIsSubmitted(true)
        return
      }

      setErrorSubmiting(true)
    } catch (e) {
      setIsSubmitted(true) // server error case = hide modal, show again on another page visit
    }
  }

  const onChangePolicy = (values, isValid) => {
    setAgreement(values)
    setIsAgreementValid(isValid)
    setErrorSubmiting(false)
  }

  return (
    <Modal
      contentClassName={styles.content}
      size='medium'
      isOpen={shouldDisplayPrivacyPolicyForm(appData) && !isSubmitted}
      header={<ModalTitle title={<Trans>Data Protection Regulation</Trans>} />}
      footer={
        <ModalButtons>
          <Button primary onClick={submitPolicy}>
            <Trans>Continue</Trans>
          </Button>
        </ModalButtons>
      }
    >
      <Header policy={privacyPolicy} locale={locale} />
      <Field
        className={classNames([styles.checkboxMargin, styles.checkableLabels])}
        id={'privacy-policy-agree'}
        checked={accepted === 'true'}
        type='checkbox'
        value={accepted === 'true' ? ['accepted'] : []}
        inputOptions={[
          {
            label: <Trans>I accept the privacy policy</Trans>,
            value: 'accepted'
          }
        ]}
        onChange={(_, newValue) => {
          const updatedAcceptance = `${!!newValue.find(
            (val) => val === 'accepted'
          )}`
          setAccepted(updatedAcceptance)
          onChangePolicy(
            { accepted: updatedAcceptance },
            isPrivacyAgreementValid(updatedAcceptance)
          )
        }}
        error={
          errorSubmitting && !isPrivacyAgreementValid(accepted) ? (
            <Trans>This field is required</Trans>
          ) : null
        }
      />
    </Modal>
  )
}

export default PrivacyPolicyModal

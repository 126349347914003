import { connect } from 'react-redux'

import { goBack } from 'connected-react-router'

import createCourse from 'apps/Courses/actions/createCourse'
import getCourseSchemaAction from 'apps/Courses/actions/getCourseSchema'
import NewCoursePage from 'apps/Courses/components/NewCoursePage'

const mapStateToProps = ({ newCourse: { inProgress, schema } }) => {
  return { inProgress, schema }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getCourseSchema: () => dispatch(getCourseSchemaAction()),
    onAddCourseClick: () => dispatch(createCourse()),
    onBackClick: (evt) => {
      evt.preventDefault()
      dispatch(goBack())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewCoursePage)

export const getDegreeClassPlaceholder = (locales) => {
  const generatedId = `new${Date.now().valueOf()}`
  const names = (locales || []).reduce((names, locale) => {
    names[locale] = ''
    return names
  }, {})

  return {
    [generatedId]: {
      id: generatedId,
      names
    }
  }
}

export const isDirty = ({ names, minimum, maximum }) =>
  Object.values(names).filter((val) => val).length > 0 || minimum || maximum

.react-app ._fileUploadInput_mtil3_1 {
  --border-color: var(--ff-color-grey-500);
  --border-weight: 1px;
  --dash-size: 8px;
  --gap-size: 8px;
  z-index: 1;
  width: 100%;
  height: 160px;
  border-radius: var(--border-radius-default);
  background-color: var(--ff-color-grey-000);
  display: flex;
  align-items: center;
  justify-content: center;
}
.react-app ._fileUploadInput_mtil3_1._fileValid_mtil3_15 {
  background-color: var(--ff-bg-primary-lightest);
}
.react-app ._fileUploadInput_mtil3_1._fileValid_mtil3_15 ._validText_mtil3_18 {
  color: var(--ff-color-text-primary);
}
.react-app ._fileUploadInput_mtil3_1._fileValid_mtil3_15 svg path {
  fill: var(--ff-color-text-primary);
}
.react-app ._fileUploadInput_mtil3_1._fileValid_mtil3_15:after {
  --border-color: var(--ff-color-text-primary);
}
.react-app ._fileUploadInput_mtil3_1._fileInvalid_mtil3_27 {
  background-color: var(--ff-color-red-000);
}
.react-app ._fileUploadInput_mtil3_1._fileInvalid_mtil3_27 ._invalidText_mtil3_30 {
  color: var(--ff-color-text-alert);
}
.react-app ._fileUploadInput_mtil3_1._fileInvalid_mtil3_27 svg path {
  fill: var(--ff-color-text-alert);
}
.react-app ._fileUploadInput_mtil3_1._fileInvalid_mtil3_27:after {
  --border-color: var(--ff-color-red-200);
}
.react-app ._fileUploadInput_mtil3_1:after {
  z-index: -1;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: var(--border-radius-default);
  background: linear-gradient(90deg, var(--border-color) 100%, transparent 100%) top left no-repeat, linear-gradient(90deg, transparent calc(var(--gap-size) / 2), var(--border-color) calc(var(--gap-size) / 2), var(--border-color) calc(var(--gap-size) / 2 + var(--dash-size)), transparent calc(var(--gap-size) / 2 + var(--dash-size))) top center repeat-x, linear-gradient(90deg, var(--border-color) 100%, transparent 100%) top right no-repeat, linear-gradient(0deg, var(--border-color) 100%, transparent 100%) top left no-repeat, linear-gradient(0deg, transparent calc(var(--gap-size) / 2), var(--border-color) calc(var(--gap-size) / 2), var(--border-color) calc(var(--gap-size) / 2 + var(--dash-size)), transparent calc(var(--gap-size) / 2 + var(--dash-size))) center left repeat-y, linear-gradient(0deg, var(--border-color) 100%, transparent 100%) bottom left no-repeat, linear-gradient(90deg, var(--border-color) 100%, transparent 100%) bottom left no-repeat, linear-gradient(90deg, transparent calc(var(--gap-size) / 2), var(--border-color) calc(var(--gap-size) / 2), var(--border-color) calc(var(--gap-size) / 2 + var(--dash-size)), transparent calc(var(--gap-size) / 2 + var(--dash-size))) bottom center repeat-x, linear-gradient(90deg, var(--border-color) 100%, transparent 100%) bottom right no-repeat, linear-gradient(0deg, var(--border-color) 100%, transparent 100%) top right no-repeat, linear-gradient(0deg, transparent calc(var(--gap-size) / 2), var(--border-color) calc(var(--gap-size) / 2), var(--border-color) calc(var(--gap-size) / 2 + var(--dash-size)), transparent calc(var(--gap-size) / 2 + var(--dash-size))) center right repeat-y, linear-gradient(0deg, var(--border-color) 100%, transparent 100%) bottom right no-repeat;
  background-size: var(--dash-size) var(--border-weight), calc(var(--dash-size) + var(--gap-size)) var(--border-weight), var(--dash-size) var(--border-weight), var(--border-weight) var(--dash-size), var(--border-weight) calc(var(--dash-size) + var(--gap-size)), var(--border-weight) var(--dash-size);
}

.react-app ._fileUploadContent_mtil3_52 {
  text-align: center;
}

.react-app ._acceptedFileIcons_mtil3_56 {
  position: relative;
  width: 24px;
  margin: 0 auto;
}

.react-app ._acceptedFileCheck_mtil3_62 {
  position: absolute;
  right: -8px;
  top: -6px;
}
.react-app ._applicSelectionRow_89mso_1 {
  margin-bottom: var(--space-big);
  position: relative;
}
.react-app ._applicSelectionRow_89mso_1 ._orSeparator_89mso_5 {
  position: absolute;
  top: 50%;
  background: var(--ff-bg-base-lightest);
  left: 50%;
  width: 40px;
  height: 40px;
  line-height: 40px;
  z-index: 100;
  border-radius: 50%;
  box-shadow: var(--shadow-3);
  text-align: center;
  transform: translate(-50%, -50%);
}

.react-app ._notOnMainProfileCard_89mso_20 {
  border: var(--border-base-lighter);
  border-radius: 4px;
  height: 100%;
}
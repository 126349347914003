import { utils } from '@fullfabric/public-api'

const confirmAccountWithPassword = async (token, data) => {
  const response = await fetch(`/confirm/${token}`, {
    method: 'PUT',
    credentials: 'same-origin',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ ...data, password_confirmation: data.password })
  })

  return await utils.checkResponse(response)
}

export default confirmAccountWithPassword

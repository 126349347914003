import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Badge } from '@fullfabric/alma-mater'

import { useCurrencyFormatter } from 'shared/utils/formatCurrency'

const usePaymentPlanColumns = () => {
  const { t } = useTranslation()
  const sectionName = t('Payment plan')
  const formatCurrency = useCurrencyFormatter()

  return [
    {
      Header: <Trans>Name</Trans>,
      section: sectionName,
      accessor: 'profile.full_name',
      width: 250,
      linkTo: ({ row, data }) => ({
        href: `/profiles/${data[row.index].profile_id}`,
        target: '_parent'
      })
    },
    {
      Header: <Trans>Email</Trans>,
      section: sectionName,
      accessor: 'profile._search_email',
      width: 250
    },
    {
      Header: <Trans>Payment plan name</Trans>,
      section: sectionName,
      accessor: 'name',
      width: 180,
      type: 'primary',
      linkTo: ({ row, data }) => {
        const profileId = data[row.index].profile_id
        const id = data[row.index].id

        return {
          href: `/profiles/${profileId}#financials/payment-plans/${id}/fees`,
          target: '_parent'
        }
      }
    },
    {
      Header: <Trans>Product</Trans>,
      section: sectionName,
      accessor: 'context_name',
      width: 250
    },
    {
      Header: <Trans>Publish status</Trans>,
      section: sectionName,
      accessor: 'published_at',
      width: 150,
      Cell: ({ value }) => {
        return (
          <Badge type={value ? 'blue' : 'red'}>
            {value ? <Trans>Published</Trans> : <Trans>Not published</Trans>}
          </Badge>
        )
      }
    },
    {
      Header: <Trans>Fee status</Trans>,
      section: sectionName,
      accessor: 'status',
      width: 150,
      Cell: ({ value }) => {
        const STATUS_MAP = {
          not_due: { label: <Trans>Not due</Trans>, color: 'blue' },
          overdue: { label: <Trans>Due / Overdue</Trans>, color: 'red' },
          paid: { label: <Trans>Paid</Trans>, color: 'green' }
        }
        return STATUS_MAP[value] ? (
          <Badge type={STATUS_MAP[value].color}>
            {STATUS_MAP[value].label}
          </Badge>
        ) : null
      }
    },
    {
      Header: <Trans>Overdue outstanding</Trans>,
      section: sectionName,
      accessor: 'overdue_amount',
      width: 150,
      type: 'number',
      Cell: ({ value, row, data }) =>
        formatCurrency(value || 0, data[row.index].currency)
    },
    {
      Header: <Trans>Paid</Trans>,
      section: sectionName,
      accessor: 'paid_amount',
      width: 150,
      type: 'number',
      Cell: ({ value, row, data }) =>
        formatCurrency(value || 0, data[row.index].currency)
    },
    {
      Header: <Trans>Outstanding</Trans>,
      section: sectionName,
      accessor: 'outstanding_amount',
      width: 150,
      type: 'number',
      Cell: ({ value, row, data }) =>
        formatCurrency(value || 0, data[row.index].currency)
    },
    {
      Header: <Trans>Subtotal</Trans>,
      section: sectionName,
      accessor: 'subtotal_amount',
      width: 150,
      type: 'number',
      Cell: ({ value, row, data }) =>
        formatCurrency(value || 0, data[row.index].currency)
    },
    {
      Header: <Trans>Total</Trans>,
      section: sectionName,
      accessor: 'total_amount',
      width: 150,
      type: 'number',
      Cell: ({ value, row, data }) =>
        formatCurrency(value || 0, data[row.index].currency)
    },
    {
      Header: <Trans>Discount total</Trans>,
      section: sectionName,
      accessor: 'total_discounts',
      width: 150,
      type: 'number',
      Cell: ({ value, row, data }) =>
        formatCurrency(value || 0, data[row.index].currency)
    }
  ]
}

export default usePaymentPlanColumns

import React from 'react'

const FilterContext = React.createContext()

export const FilterProvider = ({ children, filter }) => (
  <FilterContext.Provider value={filter}>{children}</FilterContext.Provider>
)

export const useFilter = () => React.useContext(FilterContext)

export default FilterContext

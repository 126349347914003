import React, { useState } from 'react'

import { OneColumnLayout } from '@fullfabric/alma-mater'

import { CAPACITY_TARGETS_CHART } from 'apps/Reports/constants/productPerformanceCharts'
import ProductPerformanceTable from '../ProductPerformanceTable'
import Head from './Head'

import styles from './styles.module.scss'

const ProductPerformancePage = () => {
  const [filter, setFilter] = useState({})
  const [search, setSearch] = useState({})
  const [sort, setSort] = useState({})
  const [chartType, setChartType] = React.useState(CAPACITY_TARGETS_CHART)

  return (
    <OneColumnLayout
      pageHead={
        <Head
          tableParams={{
            ...(filter || {}),
            ...(search || {}),
            ...(sort || {})
          }}
        />
      }
      className={styles.page}
    >
      <ProductPerformanceTable
        chartType={chartType}
        onChartTypeChange={setChartType}
        onFilterChanged={setFilter}
        onSearchChanged={setSearch}
        onSortChanged={setSort}
      />
    </OneColumnLayout>
  )
}

export default ProductPerformancePage

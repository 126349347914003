import React from 'react'
import { Route, Switch } from 'react-router'

import ApplicationsPage from 'apps/MergeProfiles/pages/ApplicationsPage'
import JourneysPage from 'apps/MergeProfiles/pages/JourneysPage'
import ProfileInfoPage from 'apps/MergeProfiles/pages/ProfileInfoPage'
import SelectDuplicateProfilePage from 'apps/MergeProfiles/pages/SelectDuplicateProfilePage'
import SummaryPage from 'apps/MergeProfiles/pages/SummaryPage'
import AppLayout from '../AppLayout'
import {
  MERGE_PROFILES_APPLICS_ROUTE,
  MERGE_PROFILES_INFO_ROUTE,
  MERGE_PROFILES_JOURNEYS_ROUTE,
  MERGE_PROFILES_ROUTE,
  MERGE_PROFILES_SUMMARY_ROUTE
} from './constants'

const MergeProfilesAppRoutes = () => {
  return (
    <AppLayout>
      <Switch>
        <Route
          exact
          path={MERGE_PROFILES_ROUTE}
          component={SelectDuplicateProfilePage}
        />
        <Route
          exact
          path={MERGE_PROFILES_INFO_ROUTE}
          component={ProfileInfoPage}
        />
        <Route
          exact
          path={MERGE_PROFILES_APPLICS_ROUTE}
          component={ApplicationsPage}
        />
        <Route
          exact
          path={MERGE_PROFILES_JOURNEYS_ROUTE}
          component={JourneysPage}
        />
        <Route
          exact
          path={MERGE_PROFILES_SUMMARY_ROUTE}
          component={SummaryPage}
        />
      </Switch>
    </AppLayout>
  )
}

export default MergeProfilesAppRoutes

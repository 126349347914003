import React from 'react'

import { Card, Loading, Row, Text } from '@fullfabric/alma-mater'

import styles from './styles.module.scss'

const StatsWidget = ({ title, value, isLoading }) => {
  return (
    <Card>
      <Text type='h4' fontColor='text-base-darkest' className={styles.title}>
        {title}
      </Text>
      {isLoading ? (
        <Row nogutter justify='center' className={styles.loadingContainer}>
          <Loading />
        </Row>
      ) : (
        <Text type='f1' fontColor='text-base-darkest'>
          {value}
        </Text>
      )}
    </Card>
  )
}

export default StatsWidget

import qs from 'qs'

import { utils } from '@fullfabric/public-api'

const getTranscriptsForProfile = async (options) => {
  const params = qs.stringify(options, { arrayFormat: 'brackets' })
  const response = await fetch(`/affairs/api/transcripts/for?${params}`)
  return await utils.checkResponse(response)
}

export default getTranscriptsForProfile

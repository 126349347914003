import React from 'react'
import { Trans } from 'react-i18next'

import { Field } from '@fullfabric/alma-mater'

import { useStudyPlan } from 'apps/StudyPlans/contexts/StudyPlanData'
import {
  useOnChangeSubjectEditionData,
  useSubjectEditionData,
  useSubjectEditionErrors
} from 'apps/StudyPlans/contexts/SubjectEditionData'
import { subjectTypeOptionsForPath } from 'apps/StudyPlans/utils/subjectTypesValues'

const ERROR_MESSAGES = {
  blank: <Trans>Can't be blank</Trans>,
  notANumber: <Trans>Must be a number</Trans>
}

const SinglePathsCourseTypeField = () => {
  const studyPlan = useStudyPlan()
  const data = useSubjectEditionData()
  const onChange = useOnChangeSubjectEditionData()
  const errors = useSubjectEditionErrors()

  const inputOptions = subjectTypeOptionsForPath(studyPlan.default_path)

  return (
    <Field
      id={`course_type_${studyPlan.default_path.id}`}
      type='radio'
      label={<Trans>Course type</Trans>}
      value={data[`course_type_${studyPlan.default_path.id}`]}
      onChange={onChange}
      inputOptions={inputOptions}
      error={ERROR_MESSAGES[errors[`course_type_${studyPlan.default_path.id}`]]}
      required
    />
  )
}

export default SinglePathsCourseTypeField

import React from 'react'
import { Trans } from 'react-i18next'

import PropTypes from 'prop-types'

import { Text } from '@fullfabric/alma-mater'

import { useAppData } from 'shared/contexts/AppData'

import locationHelpers from 'apps/Authentication/utils/locationHelpers'
import FacebookButton from './button-facebook'
import GoogleButton from './button-google'
import LinkedinButton from './button-linkedin'
import SamlButton from './button-saml'

const onLinkedInSignin = (evt) => {
  evt.preventDefault()
  locationHelpers.goTo('/auth/linkedin')
}

const onSamlSignin = (evt, appData) => {
  evt.preventDefault()

  locationHelpers.goTo('/auth/saml')
}

const SocialSection = ({ separatorText }) => {
  const appData = useAppData()
  const google = { enabled: false }
  const facebook = { enabled: false }
  const saml = {
    enabled: appData?._settings?.hash?.auth?.saml?.enable
  }
  const linkedIn = {
    enabled: appData?._settings?.hash?.auth?.linked_in?.enable
  }

  if (linkedIn.enabled || google.enabled || facebook.enabled || saml.enabled) {
    return (
      <div>
        <Text
          type='f5'
          fontColor='text-base-darker'
          marginTop='big'
          marginBottom='small'
        >
          {separatorText || <Trans>or e-mail</Trans>}
        </Text>

        <div>
          {linkedIn.enabled ? (
            <LinkedinButton onClick={onLinkedInSignin} />
          ) : null}

          {google.enabled ? <GoogleButton /> : null}

          {facebook.enabled ? <FacebookButton /> : null}

          {saml.enabled ? (
            <SamlButton onClick={(e) => onSamlSignin(e, appData)} />
          ) : null}
        </div>
      </div>
    )
  }

  return null
}

SocialSection.propTypes = {
  separatorText: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
}

export default SocialSection

import React from 'react'
import { useController, useFormContext } from 'react-hook-form'
import { Trans } from 'react-i18next'
import { useQuery } from 'react-query'

import { Field } from '@fullfabric/alma-mater'

import getThemes from 'apps/Subjects/api/getThemes'

function ThemeField() {
  const { control } = useFormContext()
  const {
    field: { ref: themeInputRef, ...dropdownProps }
  } = useController({ name: 'theme_id', control })

  const { data: themes, isLoading } = useQuery('getThemes', () => getThemes())
  const themesAsOptions = themes?.map((theme) => ({
    value: theme.id,
    label: theme.name
  }))

  return (
    <Field
      {...dropdownProps}
      placeholder={isLoading ? <Trans>Loading...</Trans> : undefined}
      type='dropdown'
      inputRef={themeInputRef}
      label={<Trans>Theme</Trans>}
      inputOptions={{
        isSearchable: true,
        isClearable: true,
        disabled: isLoading,
        isLoading,
        options: themesAsOptions || []
      }}
    />
  )
}

export default ThemeField

.react-app ._eventCard_z0sfs_1 {
  background: var(--ff-bg-base-lightest);
  padding: var(--space-default);
  border-bottom: var(--border-base-lighter);
}
.react-app ._eventCard_z0sfs_1 ._titleContainer_z0sfs_6 {
  height: 54px;
}
.react-app ._eventCard_z0sfs_1 ._titleContainer_z0sfs_6 ._title_z0sfs_6 {
  position: relative;
  top: 4px;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  max-width: 70%;
  display: inline-block;
  line-height: 18px;
}
@supports (-ms-ime-align: auto) {
  .react-app ._eventCard_z0sfs_1 ._buttons_z0sfs_20 > button:first-child {
    top: 9px !important;
  }
}
.react-app ._eventCard_z0sfs_1 ._buttons_z0sfs_20 ._actionListWrapper_z0sfs_24 > button {
  position: relative;
  top: 1px;
}
.react-app ._eventCard_z0sfs_1 ._buttons_z0sfs_20 ._actionListWrapper_z0sfs_24 a {
  margin-left: 0;
  font-weight: var(--font-weight-medium);
  color: var(--ff-color-black-600) !important;
  cursor: pointer;
}
.react-app ._eventCard_z0sfs_1 ._buttons_z0sfs_20 ._actionListWrapper_z0sfs_24 li {
  text-align: left;
}
import React from 'react'
import { useTranslation } from 'react-i18next'

import { Alert } from '@fullfabric/alma-mater'

import useIsMobileLayout from 'apps/ContentPages/components/payments-page/use-is-payments-mobile-layout'

import classNames from 'classnames'
import styles from './styles.module.scss'

const PendingPaymentAlert = () => {
  const isMobileLayout = useIsMobileLayout()
  const { t } = useTranslation()

  return (
    <div
      className={classNames(
        styles.alertSpacing,
        isMobileLayout && styles.mobile
      )}
    >
      <Alert
        type='warning'
        title={t('We are awaiting confirmation of your payment.')}
        description={t(
          'You will be sent an email when your payment has been received successfully. Please select another method if you had problems making your payment.'
        )}
      />
    </div>
  )
}

export default PendingPaymentAlert

import React from 'react'
import { Trans } from 'react-i18next'

import { Text } from '@fullfabric/alma-mater'

import styles from './styles.module.scss'

const MappingTable = ({ mappings }) => {
  return (
    <table className={styles.mappingTable}>
      <thead>
        <tr>
          <th>
            <Text type='h6' fontColor='text-base'>
              <Trans>COLUMN ON .CSV FILE</Trans>
            </Text>
          </th>
          <th>
            <Text type='h6' fontColor='text-base'>
              <Trans>FULL FABRIC FIELD</Trans>
            </Text>
          </th>
        </tr>
      </thead>

      <tbody>
        {mappings.map((m, i) => (
          <tr key={`import_mapping_summary_${i}`}>
            <td>
              <Text fontColor='text-base-darkest'>{m.header}</Text>
            </td>
            <td>
              <Text fontColor='text-base-darkest'>{m.fieldName}</Text>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

export default MappingTable

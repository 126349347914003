import React from 'react'
import { useTranslation } from 'react-i18next'

import { Alert } from '@fullfabric/alma-mater'

import ConfirmDeleteCheckbox from 'apps/MergeProfiles/components/ConfirmDeleteCheckbox'
import MergeInfoSummary from 'apps/MergeProfiles/components/MergeInfoSummary'
import PageLayout from 'apps/MergeProfiles/components/PageLayout'
import { MERGE_STEPS } from 'apps/MergeProfiles/contexts/MergeData'
import useCurrentPage from 'apps/MergeProfiles/hooks/useCurrentPage'
import MergeActionButton from './MergeActionButton'
import PreviousActionButton from './PreviousActionButton'

const SummaryPage = () => {
  useCurrentPage(MERGE_STEPS.summary)
  const { t } = useTranslation()
  const [error, setError] = React.useState(null)

  const onMergeError = React.useCallback((err) => setError(err), [setError])

  const actions = (
    <>
      <PreviousActionButton />
      <MergeActionButton onError={onMergeError} />
    </>
  )

  return (
    <>
      <PageLayout.Header
        title={t('Final summary')}
        description={t(
          'Please confirm the information on the final profile and the consequences of merging'
        )}
        actions={actions}
      />
      <PageLayout.Content>
        {error && (
          <Alert
            error
            title={t('Unexpected error.')}
            description={t(
              'The profiles failed to be merged. Please try again or contact support.'
            )}
            marginBottom='big'
          />
        )}
        <ConfirmDeleteCheckbox />
        <MergeInfoSummary />
        {error && (
          <Alert
            error
            title={t('Unexpected error.')}
            description={t(
              'The profiles failed to be merged. Please try again or contact support.'
            )}
            marginTop='big'
          />
        )}
      </PageLayout.Content>
      <PageLayout.Footer actions={actions} />
    </>
  )
}

export default SummaryPage

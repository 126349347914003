import { utils } from '@fullfabric/public-api'

const searchProfiles = async (query) => {
  if (!query) return []

  const response = await fetch(`/api/search?q=${query}`, {
    credentials: 'same-origin'
  })
  const allResults = await utils.checkResponse(response)

  return allResults.filter(
    (res) => res._backbone_type === 'Institutions.Models.Profile'
  )
}

export default searchProfiles

import { submissionDataToFormData } from '@fullfabric/schemable-forms'

import updateCourse from 'apps/Courses/api/updateCourse'
import actionTypes from '../types'

const archiveCourseAction =
  (courseToUnarchive = null) =>
  async (dispatch, getState) => {
    const {
      archiveCourse: { data: course }
    } = getState()

    dispatch({ type: actionTypes.archiveCourse.start })

    const courseToUpdate = courseToUnarchive || course

    try {
      const result = await updateCourse({
        courseId: courseToUpdate.id,
        data: submissionDataToFormData({
          archived: !courseToUpdate.archived
        })
      })

      dispatch({
        type: actionTypes.archiveCourse.success,
        payload: result
      })
    } catch (error) {
      dispatch({ type: actionTypes.archiveCourse.error, payload: error })
    }
  }

export default archiveCourseAction

import React from 'react'
import { Trans } from 'react-i18next'

import { Text } from '@fullfabric/alma-mater'

import SubjectsIcon from './SubjectsIcon'

import styles from './styles.module.scss'

const ContentPlaceholder = () => (
  <div className={styles.container}>
    <SubjectsIcon className={styles.icon} />
    <Text fontColor='text-base-darker' type='f3' tag='span'>
      <Trans>No subjects added</Trans>
    </Text>
  </div>
)

export default ContentPlaceholder

.react-app ._stateIconColumn_1p7jd_1 {
  width: 24px;
}
.react-app ._stateIconColumn_1p7jd_1 ._icon_1p7jd_4,
.react-app ._stateIconColumn_1p7jd_1 ._placeholder_1p7jd_5 {
  width: 24px;
}

.react-app ._leftmostColumn_1p7jd_9 {
  padding-left: var(--space-more) !important;
}

.react-app ._amountColumn_1p7jd_13 {
  text-align: right !important;
  width: 136px;
}

.react-app ._actionsColumn_1p7jd_18 {
  display: flex;
  align-items: center;
  gap: var(--space-less);
}

.react-app ._actionsBtn_1p7jd_24 {
  display: flex;
  align-items: center;
}

.react-app ._actionsLink_1p7jd_29._disabled_1p7jd_29:hover {
  background-color: initial;
  color: var(--ff-color-text-base-lightest) !important;
}

.react-app ._amountBeforeDiscount_1p7jd_34 {
  text-decoration: line-through;
}
import React from 'react'
import { useParams } from 'react-router'

import { AnnotatedLayout } from '@fullfabric/alma-mater'

import EditSubjectForm from 'apps/StudyPlans/components/EditSubjectForm'
import { useStudyPlan } from 'apps/StudyPlans/contexts/StudyPlanData'
import { SubjectEditionDataProvider } from 'apps/StudyPlans/contexts/SubjectEditionData'
import { SubjectSectionsBeingEditedProvider } from 'apps/StudyPlans/contexts/SubjectSectionBeingEdited'
import DeleteSubjectButton from './DeleteSubjectButton'
import NewlyAddedSubjectAlert from './NewlyAddedSubjectAlert'
import PageHeader from './PageHeader'

const EditSubjectPage = () => {
  const { subjectId } = useParams()
  const studyPlan = useStudyPlan()
  const subject = studyPlan.courses.find(
    (spSubject) => spSubject.id === subjectId
  )

  return (
    <SubjectSectionsBeingEditedProvider>
      <SubjectEditionDataProvider
        subjectData={{ ...subject }}
        originalSubjectData={subject}
      >
        <AnnotatedLayout pageHead={<PageHeader />}>
          <NewlyAddedSubjectAlert isNewSubject={subject?.just_added} />

          <EditSubjectForm />

          <DeleteSubjectButton />
        </AnnotatedLayout>
      </SubjectEditionDataProvider>
    </SubjectSectionsBeingEditedProvider>
  )
}

export default EditSubjectPage

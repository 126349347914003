.react-app ._labelWrapper_1hm1s_1 {
  text-align: left;
  word-break: break-word;
}

.react-app ._largeLinkContainer_1hm1s_6,
.react-app ._linkContainer_1hm1s_7 {
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--space-small) var(--space-more);
  border-top: var(--border-base-lighter);
}
.react-app ._largeLinkContainer_1hm1s_6:hover,
.react-app ._linkContainer_1hm1s_7:hover {
  background-color: var(--ff-bg-base-lighter);
}
.react-app ._largeLinkContainer_1hm1s_6:hover ._labelWrapper_1hm1s_1,
.react-app ._linkContainer_1hm1s_7:hover ._labelWrapper_1hm1s_1 {
  color: var(--ff-color-text-base-darkest);
}
.react-app ._largeLinkContainer_1hm1s_6:hover ._iconWrapper_1hm1s_22 svg,
.react-app ._largeLinkContainer_1hm1s_6:hover ._iconWrapper_1hm1s_22 svg path,
.react-app ._largeLinkContainer_1hm1s_6:hover ._iconWrapper_1hm1s_22 svg rect,
.react-app ._linkContainer_1hm1s_7:hover ._iconWrapper_1hm1s_22 svg,
.react-app ._linkContainer_1hm1s_7:hover ._iconWrapper_1hm1s_22 svg path,
.react-app ._linkContainer_1hm1s_7:hover ._iconWrapper_1hm1s_22 svg rect {
  fill: var(--ff-color-text-base-darkest) !important;
}
.react-app ._largeLinkContainer_1hm1s_6._mobileNav_1hm1s_30,
.react-app ._linkContainer_1hm1s_7._mobileNav_1hm1s_30 {
  background-color: var(--ff-bg-base-lighter);
  padding: var(--space-less) var(--space-more);
  padding-right: var(--space-default);
}
.react-app ._largeLinkContainer_1hm1s_6._mobileNav_1hm1s_30:active,
.react-app ._linkContainer_1hm1s_7._mobileNav_1hm1s_30:active {
  background-color: var(--ff-bg-base-lighter) !important;
}
.react-app ._largeLinkContainer_1hm1s_6._tabletNav_1hm1s_40,
.react-app ._linkContainer_1hm1s_7._tabletNav_1hm1s_40 {
  padding-left: var(--space-big);
  padding-right: var(--space-more);
  padding-top: var(--space-less);
  padding-bottom: var(--space-less);
}

.react-app ._largeLinkContainer_1hm1s_6 {
  padding-top: var(--space-default);
  padding-bottom: var(--space-default);
}
.react-app ._largeLinkContainer_1hm1s_6._mobileNav_1hm1s_30 {
  padding-top: var(--space-less);
  padding-bottom: var(--space-less);
}

@supports (padding-bottom: env(safe-area-inset-bottom)) {
  .react-app ._last_1hm1s_58 {
    --safe-area-inset-bottom: env(safe-area-inset-bottom, 12px);
    padding-bottom: calc(var(--space-less) + var(--safe-area-inset-bottom));
  }
}

.react-app ._iconWrapper_1hm1s_22 {
  height: 16px;
  margin-right: var(--space-small);
}
.react-app ._iconWrapper_1hm1s_22 svg {
  margin: 0;
}
.react-app ._iconWrapper_1hm1s_22 svg,
.react-app ._iconWrapper_1hm1s_22 svg path,
.react-app ._iconWrapper_1hm1s_22 svg rect {
  fill: var(--ff-color-text-base-darker) !important;
}
.react-app ._iconWrapper_1hm1s_22._mobileNav_1hm1s_30 {
  margin-right: var(--space-less);
}
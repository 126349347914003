import React, { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { useHistory } from 'react-router-dom'

import { Alert, Button, Field } from '@fullfabric/alma-mater'

import askResetPassword from 'apps/Authentication/api/askResetPassword'
import FormTitle from 'apps/Authentication/components/FormTitle'
import { RESET_PASSWORD_EMAIL_SENT_ROUTE } from 'apps/Authentication/constants/routes'

const ResetPasswordForm = () => {
  const { t } = useTranslation()
  const history = useHistory()

  const { register, handleSubmit, formState, setFocus } = useForm()
  const { errors } = formState
  const [errorMessage, setErrorMessage] = useState(null)
  const { ref: emailInputRef, ...emailInputProps } = register('email', {
    required: t('This field is required')
  })

  useEffect(() => {
    setFocus('email')
  }, [setFocus])

  const askResetPasswordMutation = useMutation(
    ({ email }) => askResetPassword(email),
    {
      onSuccess: () => {
        history.push(RESET_PASSWORD_EMAIL_SENT_ROUTE)
      },
      onError: (error) => {
        setErrorMessage(error.data?.message)
      }
    }
  )

  const onSubmit = useCallback(
    async (formValues) => {
      await askResetPasswordMutation.mutate(formValues)
    },
    [askResetPasswordMutation]
  )

  return (
    <form>
      <FormTitle
        title={<Trans>Forgot your password?</Trans>}
        subtitle={<Trans>Reset your password below.</Trans>}
      />

      {errorMessage ? (
        <Alert description={errorMessage} marginBottom='default' />
      ) : null}

      <Field
        {...emailInputProps}
        inputRef={emailInputRef}
        type='email'
        label={<Trans>E-mail address</Trans>}
        inputOptions={{ size: 'large' }}
        error={errors.email?.message}
      />

      <Button
        primary
        type='submit'
        onClick={handleSubmit(onSubmit)}
        loading={
          askResetPasswordMutation.isLoading ||
          askResetPasswordMutation.isSuccess
        }
      >
        <Trans>Reset password</Trans>
      </Button>
    </form>
  )
}

export default ResetPasswordForm

import React from 'react'
import { Trans } from 'react-i18next'
import { useParams } from 'react-router'

import { AddIcon, Alert, Link } from '@fullfabric/alma-mater'

import { ADD_SUBJECT_ROUTE } from 'apps/StudyPlans/components/AppRoutes/constants'
import { useOriginalSubjectData } from 'apps/StudyPlans/contexts/SubjectEditionData'

import styles from './styles.module.scss'

const NewlyAddedSubjectAlert = ({ isNewSubject }) => {
  const { classId } = useParams()
  const originalSubjectData = useOriginalSubjectData()
  const [isAlertVisible, setIsAlertVisible] = React.useState(isNewSubject)

  React.useEffect(() => {
    setIsAlertVisible(isNewSubject)
  }, [isNewSubject])

  const subjectCompleteName = originalSubjectData.course?.long_name?.replace(
    '::',
    '-'
  )

  if (!isAlertVisible) return null

  return (
    <Alert
      data-testid='success-alert'
      success
      large
      className={styles.successBanner}
      onClose={() => setIsAlertVisible(false)}
      title={<Trans>Success.</Trans>}
      description={
        <>
          <Trans>Subject</Trans> {subjectCompleteName} <Trans>added.</Trans>
          <Link
            to={ADD_SUBJECT_ROUTE.replace(':classId', classId)}
            className={styles.createAnotherButton}
          >
            <Trans>Add another</Trans>
            <AddIcon />
          </Link>
        </>
      }
    />
  )
}

export default NewlyAddedSubjectAlert

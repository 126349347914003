import isDateNear from 'apps/ContentPages/utils/is-date-near'

const getStateOfFee = (fee, { hideSecondaryFeeStatuses = false } = {}) => {
  if (hideSecondaryFeeStatuses) {
    return fee.is_paid ? 'paid' : 'not_paid'
  }

  if (fee.is_paid) {
    return 'paid'
  }

  if (fee.is_overdue) {
    return 'overdue'
  }

  if (isDateNear(fee.due_date)) {
    return 'almostDue'
  }

  return 'upcoming'
}

export default getStateOfFee

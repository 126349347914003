.react-app ._summaryContainer_h6j6b_1 {
  padding: var(--space-default, 16px);
  border-radius: var(--border-radius-default);
}

.react-app ._expandCollapseButton_h6j6b_6 {
  margin-left: 0;
  display: inline-flex;
  align-items: center;
  margin-top: var(--space-default);
}
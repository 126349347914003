import moment from 'moment'

export default function getSideEffectsData({ existingData, updatedData }) {
  const hasChangedStartsOnDate = updatedData?.starts_on
  const isAnonymousCourse = !existingData?.name?.trim()

  return hasChangedStartsOnDate && isAnonymousCourse
    ? { name: moment(updatedData.starts_on).format('DD.MM.YYYY') }
    : {}
}

import getFormattedDate from '../get-formatted-date'

const getDateAndTimeText = (dateMoment, t) => {
  const { date, time } = getFormattedDate(dateMoment)

  if (date === 'today') {
    return t('today at {{ time }}', { time })
  }

  if (date === 'tomorrow') {
    return t('tomorrow at {{ time }}', { time })
  }

  if (time) {
    return t('on {{ date }} at {{ time }}', { date, time })
  }

  return t('on {{ date }}', { date })
}

export default getDateAndTimeText

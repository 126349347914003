import React from 'react'
import { Trans } from 'react-i18next'

import { AnnotatedLayout } from '@fullfabric/alma-mater'

import { useSettingsSectionsBeingEdited } from 'apps/StudyPlans/contexts/SettingsSectionsBeingEdited'
import FinalGradeDisplay from './Display'
import FinalGradeForm from './Form'

const FinalGradeSection = () => {
  const sectionsBeingEdited = useSettingsSectionsBeingEdited()

  return (
    <AnnotatedLayout.Section
      title={<Trans>Transcript grade</Trans>}
      description={
        <Trans>
          Define how the transcript grade is displayed to staff and students.
        </Trans>
      }
    >
      {sectionsBeingEdited.finalGrades ? (
        <FinalGradeForm />
      ) : (
        <FinalGradeDisplay />
      )}
    </AnnotatedLayout.Section>
  )
}

export default FinalGradeSection

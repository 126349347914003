import { IMPORT_TYPES } from 'apps/Imports/utils/constants'
import { PRODUCT_IMPORT_STEPS } from './constants'

const getBaseUrl = (importProductType) => {
  switch (importProductType) {
    case IMPORT_TYPES.SUBJECT_AND_COURSE:
      return '/institutions/settings/courses'
    case IMPORT_TYPES.PROGRAMME_AND_CLASS:
    default:
      return '/institutions/settings/programmes'
  }
}

const getUrlFromStepId = (importProductType, stepId, importDoc = null) => {
  const baseUrl = getBaseUrl(importProductType)

  switch (stepId) {
    case PRODUCT_IMPORT_STEPS.FILE_UPLOAD:
      return `${baseUrl}/import`
    case PRODUCT_IMPORT_STEPS.MAPPING:
      return `${baseUrl}/import/mapping`
    case PRODUCT_IMPORT_STEPS.SUMMARY:
      return `${baseUrl}/import/summary`
    case PRODUCT_IMPORT_STEPS.REPORT:
      return `/import/${importDoc?.id}`
    default:
      return `${baseUrl}/import`
  }
}

export default getUrlFromStepId

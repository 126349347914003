import React from 'react'
import { Trans } from 'react-i18next'

import PageContainer from 'apps/Imports/components/page-container'
import AddToContextStep from 'apps/Imports/components/profile-import/add-to-context-step'
import AddToEventContextStep from 'apps/Imports/components/profile-import/add-to-event-context-step'
import { useCurrentStep } from 'apps/Imports/contexts/steps'
import { useImportContext } from 'apps/Imports/hooks/useImportContext'
import { PROFILE_IMPORT_STEPS } from 'apps/Imports/utils/profile-import/constants'

const AddToContextPage = () => {
  useCurrentStep(PROFILE_IMPORT_STEPS.ADD_TO_CONTEXT)
  const context = useImportContext()

  const isEventContext = context._ruby_type === 'Events::Event'

  return (
    <PageContainer
      title={<Trans>Add profiles to</Trans>}
      sidebarTitle={<Trans>Import Profiles</Trans>}
      subtitle={
        isEventContext ? (
          <Trans>
            IMPORTANT: Please note that event automation workflows will be
            triggered, if the workflow conditions are met, for both new event
            responses created and for existing event responses updated by the
            import.
          </Trans>
        ) : null
      }
    >
      {isEventContext ? <AddToEventContextStep /> : <AddToContextStep />}
    </PageContainer>
  )
}

export default AddToContextPage

import * as React from 'react'

const CustomExpandedTableRow = ({
  data,
  row,
  rowProps,
  tableProps,
  ExpandedRowComponent
}) => {
  if (!row.isExpanded || !ExpandedRowComponent) {
    return null
  }

  return (
    <ExpandedRowComponent
      data={data}
      row={row}
      rowProps={rowProps}
      tableProps={tableProps}
    />
  )
}

export default CustomExpandedTableRow

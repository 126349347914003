import React from 'react'

import LogoLink from './LogoLink'

const Header = () => (
  <header aria-label='page header'>
    <LogoLink />
  </header>
)

export default Header

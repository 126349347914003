import getClassAgeDistribution from './getClassAgeDistribution'

const getMultipleClassAgeDistribution = async ({ classIds, ...params }) => {
  const response = await Promise.all(
    classIds.map((classId) => getClassAgeDistribution({ classId, ...params }))
  )

  return response
}

export default getMultipleClassAgeDistribution

import React from 'react'

export const SubjectSectionsBeingEditedContext = React.createContext({})

export const SubjectSectionsBeingEditedProvider = ({ children }) => {
  const [sectionsBeingEdited, setSectionsBeingEdited] = React.useState({})

  return (
    <SubjectSectionsBeingEditedContext.Provider
      value={{ sectionsBeingEdited, setSectionsBeingEdited }}
    >
      {children}
    </SubjectSectionsBeingEditedContext.Provider>
  )
}

export const useSubjectSectionsBeingEdited = () =>
  React.useContext(SubjectSectionsBeingEditedContext).sectionsBeingEdited

export const useSetSubjectSectionsBeingEdited = () =>
  React.useContext(SubjectSectionsBeingEditedContext).setSectionsBeingEdited

.react-app ._pdfDocument_13ufd_1 {
  height: 100%;
  overflow: auto;
}

.react-app ._pdfPage_13ufd_6 {
  margin-top: var(--space-less);
  margin-bottom: var(--space-more);
}
.react-app ._pdfPage_13ufd_6 canvas {
  margin: 0 auto;
}

.react-app ._loading_13ufd_14 {
  height: 100%;
}
import React from 'react'

import { BoxShadow } from '@fullfabric/alma-mater'

import styles from './styles.module.scss'

const ChartCard = ({ children }) => (
  <BoxShadow type='2' rounded bgColor='white' className={styles.card}>
    {children}
  </BoxShadow>
)

export default ChartCard

import React, { useState } from 'react'
import { Trans } from 'react-i18next'

import {
  Button,
  MarketingPolicyForm,
  Modal,
  ModalButtons,
  ModalTitle,
  shouldDisplayMarketingPolicyForm
} from '@fullfabric/alma-mater'
import { useCurrentUser } from '@fullfabric/authorization-officer'

import { useAppData } from 'shared/contexts/AppData'
import { useLocale } from 'shared/contexts/Locale'

import api from 'apps/ContentPages/api'

import styles from './styles.module.scss'

const MarketingPolicyModal = () => {
  const user = useCurrentUser()
  const appData = useAppData()
  const { locale } = useLocale()
  const [agreement, setAgreement] = useState({})
  const [isAgreementValid, setIsAgreementValid] = useState(false)
  const [errorSubmitting, setErrorSubmiting] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)

  const submitPolicy = async () => {
    try {
      if (isAgreementValid) {
        await api.submitPolicyAcceptance({
          agreement,
          policyId: appData.marketing_policy?.id,
          userId: user?.id
        })
        setIsSubmitted(true)
        return
      }

      setErrorSubmiting(true)
    } catch (e) {
      setIsSubmitted(true) // server error case = hide modal, show again on another page visit
    }
  }

  const onChangePolicy = (values, isValid) => {
    setAgreement(values)
    setIsAgreementValid(isValid)
    setErrorSubmiting(false)
  }

  return (
    <Modal
      contentClassName={styles.content}
      size='medium'
      isOpen={shouldDisplayMarketingPolicyForm(appData) && !isSubmitted}
      header={<ModalTitle title={<Trans>Data Protection Regulation</Trans>} />}
      footer={
        <ModalButtons>
          <Button primary onClick={submitPolicy}>
            <Trans>Continue</Trans>
          </Button>
        </ModalButtons>
      }
    >
      <MarketingPolicyForm
        data={appData}
        locale={locale}
        onChose={onChangePolicy}
        displayErrors={errorSubmitting}
      />
    </Modal>
  )
}

export default MarketingPolicyModal

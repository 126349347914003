{
  "Skip to main content": "Passer au contenu principal",
  "In Progress": "En cours",
  "Submitted": "Soumis",
  "You haven't started or submitted an application yet": "Vous n'avez pas encore commencé ou soumis une candidature",
  "Name": "Nom",
  "Application": "Application",
  "Registration": "Enregistrement",
  "This event is now full.": "Cet événement est maintenant complet.",
  "This event has now passed.": "Cet événement est maintenant passé.",
  "Registrations are closed.": "Les inscriptions sont closes.",
  "Register": "Inscrivez-vous",
  "Thank you for filling in the form.": "Merci d'avoir rempli le formulaire.",
  "You will receive an email shortly.": "Vous recevrez un e-mail sous peu.",
  "Email not verified": "E-mail non vérifié",
  "A verification email was sent to {{email}}.": "Un e-mail de vérification a été envoyé à {{email}}.",
  "Please check your email.": "Merci de consulter vos emails.",
  "Email sent": "Email envoyé",
  "Email failed!": "E-mail a échoué!",
  "Resend verification email": "Renvoyer l'e-mail de vérification",
  "Login": "Identifiez-vous",
  "Home": "Accueil",
  "My Applications": "Mes Candidatures",
  "My Registrations": "Mes Enregistrements",
  "Other Content": "Autres contenus",
  "Menu": "Menu",
  "Close navigation": "Fermer la navigation",
  "Main Menu": "Menu principal",
  "Close": "Fermer",
  "Applications": "Candidatures",
  "No applications available": "Pas candidatures disponibles",
  "To access registrations please register or login.": "Pour accéder aux inscriptions, veuillez vous enregistrer ou vous connecter.",
  "To access applications please register or login.": "Pour accéder aux candidatures, veuillez vous enregistrer ou vous connecter.",
  "You haven't started or submitted a registration yet": "Vous n'avez pas encore commencé ou soumis une enregistrement.",
  "Registrations": "Enregistrements",
  "No registrations available": "Aucun enregistrement disponible",
  "Start registration": "Lancer l'enregistrement",
  "Start application": "Lancer la candidature",
  "Start another registration": "Lancer une autre enregistrement",
  "Start another application": "Lancer une autre candidature",
  "Continue registration": "Continuer l'enregistrement",
  "Continue application": "Continuer la candidature",
  "Closes {{ dateAndTimeText }}": "Ferme {{dateAndTimeText}}",
  "Registrations are closed": "Les enregistrements sont clos",
  "Applications are closed": "Les candidatures sont fermées",
  "Loading": "Chargement",
  "Choose a programme or subject": "Choisissez un programme ou un sujet",
  "This field is required": "Ce champ est obligatoire",
  "Choose a class or course": "Choisissez une classe ou un cours",
  "Choose a campus": "Choisir un campus",
  "Registration options": "Options d'enregistrement",
  "Application options": "Options de candidature",
  "Cancel": "Annuler",
  "Continue": "Continuez",
  "Please select an option": "Veuillez sélectionner une option",
  "You can change your selection later.": "Vous pouvez modifier votre sélection plus tard.",
  "View registration": "Voir l'enregistrement",
  "View application": "Vue la candidature",
  "Accept": "Accepter",
  "Decline": "Déclin",
  "View Submission": "Voir la soumission",
  "Conditional offer": "Offre conditionnelle",
  "Unconditional offer": "Offre inconditionnelle",
  "No events": "Pas d'événements",
  "Learn more": "Apprendre encore plus",
  "Upcoming Events": "Évènements à venir",
  "Change to English": "Changer en Français",
  "Hide map": "Cacher la carte",
  "Show map": "Afficher la carte",
  "Please fill out the form": "Veuillez remplir le formulaire ci-dessous",
  "Data Protection Regulation": "Règlement sur la protection des données",
  "Public profile": "Profil public",
  "Transcripts": "Transcriptions",
  "Calendar": "Calendrier",
  "Settings": "Paramètres",
  "Logout": "Déconnexion",
  "Someone already registered with that email address": "Quelqu'un déjà inscrit avec cette adresse e-mail",
  "Email address is invalid": "Adresse e-mail incorrecte",
  "Email already in use": "Quelqu'un a déjà enregistré cette adresse e-mail",
  "Participant details": "Détails du participant",
  "Please enter the details of the participant": "Veuillez saisir les coordonnées du participant",
  "Email address": "Adresse e-mail",
  "First name": "Prénom",
  "Last name": "Nom de famille",
  "Offers": "Offres",
  "{{ name }} (closed)": "{{name}} (fermé)",
  "Opens {{ dateAndTimeText }}": "Ouvre {{dateAndTimeText}}",
  "on {{ date }}": "le {{ date }}",
  "on {{ date }} at {{ time }}": "le {{ date }} à {{ time }}",
  "today at {{ time }}": "aujourd'hui à {{ time }}",
  "tomorrow at {{ time }}": "demain à {heure}",
  "Payments": "Paiements",
  "View your fees and make payments": "Voir vos frais et effectuer des paiements",
  "Paid <1></1>": "Payé <1></1>",
  "Due in {{timeToDueDate}}": "Dû en {{timeToDueDate}}",
  "Due today": "À échéance aujourd'hui",
  "{{timeFromDueDate}} overdue": "{{timeFromDueDate}} retard",
  "Due Date": "Date d'échéance",
  "Status": "Statut",
  "Description": "La description",
  "Amount": "Montant",
  "Payment plan for": "Plan de paiement pour",
  "Alerts": "Alertes",
  "Next payment:": "Prochain paiement:",
  "See details": "Voir les détails",
  "Discount": "Rabais",
  "Total": "Le total",
  "Paid": "Payé",
  "Awaiting confirmation": "En attente de confirmation",
  "Due soon": "Bientôt due",
  "Due / Overdue": "En retard / En souffrance",
  "Back to list": "Retour à la liste",
  "Fee details": "Détails des frais",
  "Payment Plans": "Plans de paiement",
  "Pay now": "Payez maintenant",
  "Pay with Flywire": "Payez avec Flywire",
  "Flywire billing details": "Détails de facturation de Flywire",
  "Email": "Courriel",
  "Phone number": "Numéro de téléphone",
  "Address line 1": "Ligne d'adresse 1",
  "Address line 2": "Ligne d'adresse 2",
  "Country": "Pays",
  "City": "Ville",
  "Zip code": "Code postal",
  "State": "État",
  "Awaiting payment confirmation": "En attente de confirmation de paiement",
  "We are awaiting confirmation of your payment.": "Nous attendons la confirmation de votre paiement.",
  "You will be sent an email when your payment has been received successfully. Please select another method if you had problems making your payment.": "Vous recevrez un e-mail lorsque votre paiement aura été reçu avec succès. Veuillez sélectionner une autre méthode si vous rencontrez des problèmes pour effectuer votre paiement.",
  "Missing {{amount}}": "Manquant {{amount}}",
  "Payment": "Paiement",
  "There was an error in your submission, please try again in a few seconds. If the issue persists, please contact us.": "Il y a eu une erreur dans votre soumission, veuillez réessayer dans quelques secondes. Si le problème persiste, veuillez nous contacter.",
  "{{ name }} (not currently available)": "{{ name }} (non disponible actuellement)",
  "{{ name }} (not available yet)": "{{ name }} (pas encore disponible)",
  "Registrations are currently closed.": "Les inscriptions sont actuellement fermées.",
  "Registrations will open on {{ registrationsOpenDate }}.": "Les inscriptions ouvriront le {{registrationsOpenDate}}.",
  "Select an option": "Choisir une option",
  "({{timezone}} time)": "({{timezone}} time)",
  "{{dateFullDay}} at {{dateTime}}": "{{dateFullDay}} à {{dateTime}}",
  "Product catalog": "Catalogue des produits",
  "Search for a programme or course": "Rechercher un programme ou un cours",
  "Start date": "Date de début",
  "End date": "Date de fin",
  "Duration": "Durée",
  "Location": "Emplacement",
  "Price": "Prix",
  "Application deadline": "Date limite de candidature",
  "Registration deadline": "Date limite d'inscription",
  "Apply": "Postuler",
  "Details": "Détails",
  "All": "Tous",
  "Now until the end of next month": "Aujourd'hui jusqu'à la fin du mois prochain",
  "In 1-6 months": "Dans 1 - 6 mois",
  "In 6-12 months": "Dans 6 - 12 mois",
  "In 1 year": "Dans 1 an",
  "Theme": "Thème",
  "Pay with Stripe": "Payer avec Stripe",
  "Stripe payment details": "Détails de paiement Stripe",
  "Your payment was processed": "Votre paiement a été traité",
  "Edit application": "Modifier la candidature",
  "Edit registration": "Modifier l'inscription",
  "Have a discount code?": "Avez-vous un code de réduction?",
  "In order to submit you are required to pay a fee.": "Pour soumettre, vous devez payer des frais.",
  "All fees paid": "Tous les frais payés",
  "Next step:": "Étape suivante :",
  "Sign & Submit": "Signer et soumettre",
  "Submit": "Soumettre",
  "I accept the privacy policy": "J'accepte la politique de confidentialité",
  "Privacy Policy": "Politique de confidentialité",
  "All fees paid.": "Tous les frais payés.",
  "Next step.": "Étape suivante.",
  "Finish your application by clicking the button below.": "Terminez votre demande en cliquant sur le bouton ci-dessous.",
  "Finish your registration by clicking the button below.": "Finalisez votre inscription en cliquant sur le bouton ci-dessous.",
  "Check your inbox": "Vérifiez votre boîte de réception",
  "A new link has been sent.": "Un nouveau lien a été envoyé.",
  "Access expired": "Accès expiré",
  "Click the button below to receive a new link in your inbox.": "Cliquez sur le bouton ci-dessous pour recevoir un nouveau lien dans votre boîte de réception.",
  "Send new link": "Envoyer un nouveau lien",
  "Payment link is invalid": "Le lien de paiement est invalide",
  "This payment has been canceled or does not exist.": "Ce paiement a été annulé ou n'existe pas.",
  "Fee has been paid": "Frais payés",
  "The requester has been notified.": "Le demandeur a été notifié.",
  "On {{date}}, this fee was sent to an external payer.": "Le {{date}}, ces frais ont été envoyés à un payeur externe.",
  "Name is invalid": "Le nom n'est pas valide",
  "Manage payer": "Gérer le payeur",
  "Send to payer": "Envoyer au payeur",
  "Cancel external payer": "Annuler le payeur externe",
  "Resend fee details": "Renvoyer les détails des frais",
  "Send fee": "Envoyer les frais",
  "An email containing all the payment details will be sent to the external payer.": "Un e-mail contenant tous les détails du paiement sera envoyé au payeur externe.",
  "Payer's email address": "Adresse e-mail du payeur",
  "Payer's name": "Nom du payeur",
  "Actions": "Actions",
  "External payer access was revoked": "L'accès du payeur externe a été révoqué",
  "Fee was sent to an external payer": "Les frais ont été envoyés à un payeur externe",
  "Fee paid": "Frais payés"
}

import React, { useCallback, useEffect, useState } from 'react'

import {
  useCurrentUser,
  UserRoleUtils,
  useSettings
} from '@fullfabric/authorization-officer'

import { useAppData } from 'shared/contexts/AppData'

import AcceptPolicyModal from './AcceptPolicyModal'
import ApplicantDetailsModal from './ApplicantDetailsModal'
import {
  StepInAuthenticationProvider,
  useSetStepInAuthentication
} from './contexts/StepInAuthentication'
import EmploymentDetailsModal from './EmploymentDetailsModal'
import LoginModal from './LoginModal'
import MarketingPolicyStep from './MarketingPolicyStep'
import SelectModeModal from './SelectModeModal'

function AuthenticationViaApplicPage({ fromMagicLink }) {
  return (
    <StepInAuthenticationProvider>
      <AuthenticationViaApplicContent fromMagicLink={fromMagicLink} />
    </StepInAuthenticationProvider>
  )
}

function AuthenticationViaApplicContent({ fromMagicLink }) {
  const appData = useAppData()
  const {
    options,
    privacy_policy: privacyPolicy,
    privacy_policy_acceptance: privacyPolicyAcceptance,
    marketing_policy_acceptance: marketingPolicyAcceptance,
    marketing_policy: marketingPolicy
  } = appData

  const settings = useSettings()
  const user = useCurrentUser()
  const isLoggedOut = !user

  const setStep = useSetStepInAuthentication()
  const [needsToAcceptPrivacyPolicy, setNeedsToAcceptPrivacyPolicy] = useState(
    privacyPolicy && !privacyPolicyAcceptance
  )
  const [hasPassword, setHasPassword] = useState(true)
  const [data, setData] = useState({ email: '' })

  const [selectModeModal, showSelectModeModal] = useState(true)
  const [employmentModal, showEmploymentModal] = useState(false)
  const [applicantDetailsModal, showApplicantDetailsModal] = useState(false)
  const [newContactId, setNewContactId] = useState(null)

  const needsToAcceptMarketingPolicy =
    marketingPolicy && !marketingPolicyAcceptance
  const hasOrganizationContactStep =
    settings.hash.modules.applics?.features?.organisation_contact?.enable
  const privacyPolicyIsLastStep =
    !needsToAcceptMarketingPolicy && !hasOrganizationContactStep

  const continueToApplication = useCallback(() => {
    window.location.reload()
  }, [])

  useEffect(() => {
    setStep('email') // why this effect?
  }, [setStep, data.email])

  const toApplicantModal = (contactId) => {
    if (contactId) {
      setNewContactId(contactId)
    }
    showSelectModeModal(false)
    showEmploymentModal(false)
    showApplicantDetailsModal(true)
  }

  const toEmploymentDetailsModal = () => {
    showSelectModeModal(false)
    showEmploymentModal(true)
    showApplicantDetailsModal(false)
  }

  const onBehalfStep = () => {
    if (UserRoleUtils.isContact(user)) {
      toApplicantModal()
    } else {
      toEmploymentDetailsModal()
    }
  }

  const toShowSelectionModal = () => {
    showSelectModeModal(true)
    showEmploymentModal(false)
    showApplicantDetailsModal(false)
  }

  const applicantDetailsModalBack = () => {
    if (newContactId) {
      toEmploymentDetailsModal()
    } else {
      toShowSelectionModal()
    }
  }

  const expiredMagicToken = !user && fromMagicLink

  if (isLoggedOut) {
    return (
      <LoginModal
        data={data}
        setData={setData}
        needsPrivacyPolicy={needsToAcceptPrivacyPolicy}
        setNeedsPrivacyPolicy={setNeedsToAcceptPrivacyPolicy}
        hasPassword={hasPassword}
        setHasPassword={setHasPassword}
        expiredMagicToken={expiredMagicToken}
      />
    )
  }

  if (!options.signed_privacy_policy && needsToAcceptPrivacyPolicy) {
    return (
      <AcceptPolicyModal
        data={data}
        setData={setData}
        onAccepted={() => {
          if (privacyPolicyIsLastStep) {
            continueToApplication()
            return // the return keeps the modal in display while loading the application page, vs a blank screen
          }

          setNeedsToAcceptPrivacyPolicy(false)
        }}
      />
    )
  }

  if (needsToAcceptMarketingPolicy) {
    return <MarketingPolicyStep />
  }

  if (hasOrganizationContactStep) {
    return (
      <>
        {selectModeModal && (
          <SelectModeModal next={onBehalfStep} newContactId={newContactId} />
        )}
        {applicantDetailsModal && (
          <ApplicantDetailsModal back={applicantDetailsModalBack} />
        )}
        {employmentModal && (
          <EmploymentDetailsModal
            newContactId={newContactId}
            back={toShowSelectionModal}
            next={toApplicantModal}
          />
        )}
      </>
    )
  }

  continueToApplication() // if none of the other steps matched, exit this app
  return null
}

export default AuthenticationViaApplicPage

import { useMutation } from 'react-query'

import createImport from 'apps/Imports/api/create-import'
import { useSetImportDocumentData } from 'apps/Imports/contexts/import-document'
import { useSelectedData } from 'apps/Imports/contexts/selected-data'
import { useImportType } from 'apps/Imports/contexts/steps'
import { useImportContext } from 'apps/Imports/hooks/useImportContext'
import getProductImportFormData from 'apps/Imports/utils/product-import/get-product-import-form-data'
import useGoToStep from './use-go-to-step'

const useCreateImportMutation = (nextStep = null) => {
  const context = useImportContext()
  const { data, onDataChanged } = useSelectedData()
  const importType = useImportType()

  const { setImportDocument, setImportMappableFields, setImportErrors } =
    useSetImportDocumentData()

  const { goToNextStepPage, goToStepPage } = useGoToStep()

  return useMutation(
    async () => {
      const formData = await getProductImportFormData(data, context, importType)

      return await createImport(formData)
    },
    {
      onSuccess: (importData) => {
        onDataChanged('mappings', importData.columns)
        setImportErrors(null)
        setImportDocument(importData.document)
        setImportMappableFields(importData.mapping_options)

        if (nextStep) {
          goToStepPage(nextStep)
        } else {
          goToNextStepPage()
        }
      },
      onError: (err) => {
        setImportErrors(err)
        if (nextStep) {
          goToStepPage(nextStep)
        } else {
          goToNextStepPage()
        }
      }
    }
  )
}

export default useCreateImportMutation

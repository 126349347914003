import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'

import { ActionList, MoreIcon, Table } from '@fullfabric/alma-mater'

import { EDIT_SUBJECT_ROUTE } from 'apps/StudyPlans/components/AppRoutes/constants'
import CoursesNames from 'apps/StudyPlans/components/CourseNames'
import DeleteSubjectModal from 'apps/StudyPlans/components/DeleteSubjectModal'
import PrerequisiteNames, {
  formatPrerequisiteNames
} from 'apps/StudyPlans/components/PrerequisiteNames'
import {
  useClassOf,
  useStudyPlan
} from 'apps/StudyPlans/contexts/StudyPlanData'

import styles from './styles.module.scss'

const SubjectTableRow = ({ subject }) => {
  const { t } = useTranslation()
  const classOf = useClassOf()
  const history = useHistory()
  const studyPlan = useStudyPlan()
  const [deleteModalOpen, setDeleteModalOpen] = React.useState(false)

  const onClickSubject = React.useCallback(() => {
    history.push(
      EDIT_SUBJECT_ROUTE.replace(':classId', classOf.id).replace(
        ':subjectId',
        subject.id
      )
    )
  }, [classOf.id, subject.id, history])

  const openDeleteModal = React.useCallback(
    (evt) => {
      evt.stopPropagation()
      setDeleteModalOpen(true)
    },
    [setDeleteModalOpen]
  )

  const closeDeleteModal = React.useCallback(() => {
    setDeleteModalOpen(false)
  }, [setDeleteModalOpen])

  return (
    <>
      <DeleteSubjectModal
        isOpen={deleteModalOpen}
        onCancel={closeDeleteModal}
        onDeleted={closeDeleteModal}
        subject={subject}
      />
      <Table.Row
        key={subject.id}
        onClick={onClickSubject}
        className={styles.row}
      >
        <Table.Data main>
          {subject.course && subject.course.long_name
            ? subject.course.long_name
            : '-'}
        </Table.Data>
        <Table.Data>
          <CoursesNames courses={subject.units} />
        </Table.Data>
        <Table.Data>
          {(subject.category && subject.category.name) || '-'}
        </Table.Data>
        <Table.Data align='right'>
          {typeof subject.credits === 'number' ? subject.credits : '-'}
        </Table.Data>
        <Table.Data align='right'>
          {typeof subject.hours === 'number' ? subject.hours : '-'}
        </Table.Data>
        <Table.Data align='right'>
          {typeof subject.max_number_absences === 'number'
            ? subject.max_number_absences
            : '-'}
        </Table.Data>
        <Table.Data
          title={formatPrerequisiteNames(subject.prerequisites, studyPlan, t)}
          className={styles.prerequisitesCell}
        >
          <PrerequisiteNames prerequisites={subject.prerequisites} />
        </Table.Data>
        <Table.Data align='end'>
          <ActionList
            iconButton={<MoreIcon />}
            iconButtonProps={{ basic: true }}
            menuPosition='left'
          >
            <ActionList.Option negative onClick={openDeleteModal}>
              <Trans>Delete study plan subject</Trans>
            </ActionList.Option>
          </ActionList>
        </Table.Data>
      </Table.Row>
    </>
  )
}

export default SubjectTableRow

import 'css-chunk:src/apps/Imports/components/import-report-step/components/report-errors-section/styles.module.scss';export default {
  "react-app": "react-app",
  "container": "_container_1naba_1",
  "downloadLink": "_downloadLink_1naba_6",
  "errorTableList": "_errorTableList_1naba_15",
  "tableHeaderItem": "_tableHeaderItem_1naba_21",
  "tableData": "_tableData_1naba_24",
  "profileIcon": "_profileIcon_1naba_29",
  "warningColor": "_warningColor_1naba_36",
  "failureColor": "_failureColor_1naba_40"
};
import React from 'react'
import { useTranslation } from 'react-i18next'

import {
  MERGE_STEPS,
  useMergeData
} from 'apps/MergeProfiles/contexts/MergeData'
import { useProfileSchema } from 'apps/MergeProfiles/contexts/ProfileSchema'
import Step from '../Step'

const ProfileInfoStep = () => {
  const { t } = useTranslation()
  const { data: profileSchema } = useProfileSchema()
  const {
    currentMergeStep,
    mergeData: { profileInfoFrom },
    fieldsWithDifferentValues
  } = useMergeData()

  return (
    <Step
      name={t('Profile Info')}
      active={currentMergeStep === MERGE_STEPS.info}
      completed={
        profileSchema &&
        fieldsWithDifferentValues.every(
          (field) => !!profileInfoFrom[field.name]
        )
      }
    />
  )
}

export default ProfileInfoStep

import React from 'react'

function AddressIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='16'
      height='16'
      fill='none'
      viewBox='0 0 16 16'
    >
      <path
        fill='#6A6D77'
        fillRule='evenodd'
        d='M2.667 5.333A5.34 5.34 0 018 0a5.34 5.34 0 015.333 5.333c0 2.343-3.333 8.018-4.765 10.349a.667.667 0 01-1.136 0C6 13.35 2.667 7.674 2.667 5.334zm3 0a2.333 2.333 0 104.666 0 2.333 2.333 0 00-4.666 0z'
        clipRule='evenodd'
      ></path>
    </svg>
  )
}

export default AddressIcon

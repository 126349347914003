.react-app ._spaceAround_19fhp_1 {
  margin: var(--space-tiny) 0;
}

.react-app ._link_19fhp_5 {
  margin-top: -8px;
  margin-bottom: var(--space-default);
}
.react-app ._link_19fhp_5._noSpace_19fhp_9 a {
  margin-left: 0;
}

.react-app ._resendAuthLinkContainer_19fhp_13 a {
  margin-left: 0;
}

.react-app ._hasError_19fhp_17 {
  color: red;
}

.react-app ._emailField_19fhp_21 {
  display: none;
}
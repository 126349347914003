const isNumber = (n) => !isNaN(parseFloat(n)) && isFinite(n)

const validateNumber = (data, field) =>
  data[field] && !isNumber(data[field]) ? { [field]: 'not_a_number' } : {}

const validatePriceAmount = (data) => validateNumber(data, 'price_amount')
const validateMinimumCapacity = (data) =>
  validateNumber(data, 'minimum_capacity')
const validateMaximumCapacity = (data) =>
  validateNumber(data, 'maximum_capacity')
const validateTargetCapacity = (data) => validateNumber(data, 'target_capacity')
const validateCredits = (data) => validateNumber(data, 'credits')
const validateHours = (data) => validateNumber(data, 'hours')
const validateMaxNumberAbsences = (data) =>
  validateNumber(data, 'max_number_absences')

const validatePriceCurrency = (data) =>
  (data.price_amount || data.price_amount === 0) &&
  isNumber(data.price_amount) &&
  !data.price_currency
    ? { price_currency: 'required_for_price' }
    : {}

const validateExistingSubject = (data) =>
  !data.create_new_subject && !data.subject_id ? { subject_id: 'blank' } : {}

const validateSubjectCode =
  (allSubjects = []) =>
  (data) =>
    data.create_new_subject &&
    (!data.subject_code || !(data.subject_code || '').trim())
      ? { subject_code: 'blank' }
      : data.create_new_subject &&
        allSubjects.filter(({ code }) => data.subject_code.trim() === code)
          .length > 0
      ? { subject_code: 'invalid_code' }
      : {}

const validateSubjectName = (data) =>
  data.create_new_subject &&
  (!data.subject_name || !(data.subject_name || '').trim())
    ? { subject_name: 'blank' }
    : {}

const validateNewSubject =
  (allSubjects = []) =>
  (data) => ({
    ...validateSubjectCode(allSubjects)(data),
    ...validateSubjectName(data)
  })

class ValidationError extends Error {
  constructor(errors) {
    super('validation error')
    this.validationErrors = errors
    this.name = 'ValidationError'
  }
}

const validate = (data, validators = []) => {
  const errors = validators.reduce(
    (acc, validator) => ({ ...acc, ...validator(data) }),
    {}
  )

  if (Object.keys(errors).length > 0) {
    throw new ValidationError(errors)
  }
}

export {
  isNumber,
  validatePriceAmount,
  validatePriceCurrency,
  validateExistingSubject,
  validateNewSubject,
  ValidationError,
  validateMinimumCapacity,
  validateMaximumCapacity,
  validateTargetCapacity,
  validateCredits,
  validateHours,
  validateMaxNumberAbsences,
  validate
}

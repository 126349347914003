import React from 'react'

import { ProgressiveImage } from '@fullfabric/alma-mater'

import { useAppData } from 'shared/contexts/AppData'

import useIsMobileLayout from 'apps/Authentication/hooks/useIsMobileLayout'

import styles from './styles.module.scss'

const InstitutionBackground = () => {
  const { background_url: backgroundImage } = useAppData()
  const isMobileLayout = useIsMobileLayout()

  return !isMobileLayout ? (
    <div
      className={styles['background-image-container']}
      role='img'
      aria-label='Institution background image'
    >
      <ProgressiveImage
        src={backgroundImage}
        className={styles['institution-background']}
      />
    </div>
  ) : null
}

export default InstitutionBackground

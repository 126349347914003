import React from 'react'

import { useMainTextContent } from 'apps/ContentPages/contexts/app-data'
import { BackgroundImageProvider } from 'apps/ContentPages/contexts/background-image'
import FormWithContent from './form-with-content'
import FormWithoutContent from './form-without-content'

export default function FormPage() {
  const textContent = useMainTextContent()

  const FormPageVariation = textContent ? FormWithContent : FormWithoutContent

  return (
    <BackgroundImageProvider>
      <FormPageVariation />
    </BackgroundImageProvider>
  )
}

import React from 'react'

import { Table } from '@fullfabric/alma-mater'

import cx from 'classnames'
import styles from './styles.module.scss'

const useRenderHeaderRow = (headerGroups) => {
  return React.useCallback(
    ({ className, columns, style }) => {
      return (
        <Table.Header tag='div'>
          {headerGroups.map((headerGroup) => {
            const { key: headerGroupKey, ...headerGroupProps } =
              headerGroup.getHeaderGroupProps()

            return (
              <Table.Row
                tag='div'
                key={headerGroupKey}
                {...headerGroupProps}
                className={cx(styles.headerRow, className)}
                style={style}
              >
                {columns}
              </Table.Row>
            )
          })}
        </Table.Header>
      )
    },
    [headerGroups]
  )
}

export default useRenderHeaderRow

import React from 'react'
import { Trans } from 'react-i18next'

import { Table } from '@fullfabric/alma-mater'

import HeaderItem from './HeaderItem'
import SubjectTableRow from './SubjectTableRow'

const SubjectsTable = ({ subjects }) => {
  return (
    <Table.Container>
      <Table>
        <Table.Header>
          <Table.Row>
            <HeaderItem widest>
              <Trans>Subject</Trans>
            </HeaderItem>
            <HeaderItem widest>
              <Trans>Courses</Trans>
            </HeaderItem>
            <HeaderItem>
              <Trans>Category</Trans>
            </HeaderItem>
            <HeaderItem align='right'>
              <Trans>Credits</Trans>
            </HeaderItem>
            <HeaderItem align='right'>
              <Trans>Hours</Trans>
            </HeaderItem>
            <HeaderItem minimizeWidth align='right'>
              <Trans>Max number of absences</Trans>
            </HeaderItem>
            <HeaderItem minimizeWidth>
              <Trans>Prerequisites</Trans>
            </HeaderItem>
            <HeaderItem minimizeWidth disabled />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {subjects.map((subject) => (
            <SubjectTableRow key={subject.id} subject={subject} />
          ))}
        </Table.Body>
      </Table>
    </Table.Container>
  )
}

export default SubjectsTable

.react-app ._container_1gwz2_1 {
  margin-bottom: var(--space-default);
  display: flex;
  align-items: flex-start;
}

.react-app ._container_1gwz2_1._noMargin_1gwz2_7 {
  margin-bottom: 0;
}

.react-app ._iconColumn_1gwz2_11 {
  display: flex;
  align-items: center;
  height: 24px;
  margin-right: var(--space-small);
}
import React from 'react'
import { Trans } from 'react-i18next'

import { Text } from '@fullfabric/alma-mater'

import styles from './styles.module.scss'

const ReportErrorsFooter = () => {
  return (
    <div className={styles.container}>
      <div className={styles.separator} />
      <Text>
        <Trans>How to fix this</Trans>:
      </Text>
      <ol className={styles.list}>
        <Text tag='li'>
          <Trans>Download the .csv file with failures and errors</Trans>
        </Text>
        <Text tag='li' marginTop='tiny'>
          <Trans>
            Review the reason column on the .csv file and amend the records
            accordingly
          </Trans>
        </Text>
        <Text tag='li' marginTop='tiny'>
          <Trans>Import the amended failures & error .csv file</Trans>
        </Text>
      </ol>
    </div>
  )
}

export default ReportErrorsFooter

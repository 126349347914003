import React from 'react'
import { Trans } from 'react-i18next'

import { Button, Text } from '@fullfabric/alma-mater'
import { useCurrentUser } from '@fullfabric/authorization-officer'

import WidgetSection from 'apps/ContentPages/components/widget-section'
import WidgetTitle from '../../title'

import styles from './styles.module.scss'

const PrivateWidget = ({ id, title, authorizationMessage, children }) => {
  const user = useCurrentUser()
  const isLoggedIn = !!user?.token

  return (
    <section aria-labelledby={`widget-title-${id}`}>
      <WidgetTitle id={id} title={title} />
      {isLoggedIn ? (
        children
      ) : (
        <AuthorizationWall message={authorizationMessage} />
      )}
    </section>
  )
}

const AuthorizationWall = ({ message }) => (
  <WidgetSection last>
    <Text type='f4' fontColor='text-base-darkest' marginBottom='default'>
      {message}
    </Text>
    <div className={styles.buttonContainer}>
      <Button
        tag='a'
        primary
        role='button'
        href='/signup'
        className={styles.button}
      >
        <Trans>Register</Trans>
      </Button>
      <Button
        tag='a'
        role='button'
        href='/login'
        marginLeft='small'
        className={styles.button}
      >
        <Trans>Login</Trans>
      </Button>
    </div>
  </WidgetSection>
)

export default PrivateWidget

import React, { useMemo } from 'react'

export default function Variations({
  barEdges,
  variation,
  // recharts provided:
  data,
  tooltipAxis
}) {
  const areaBaseline = tooltipAxis.y

  const areaCoords = useMemo(() => {
    if (barEdges.length < data.length) return []

    return (
      barEdges
        .map((edge, i) => {
          const nextEdge = barEdges[i + 1]

          return {
            x1: edge.rx,
            y1: edge.y,
            x2: nextEdge?.lx,
            y2: nextEdge?.y
          }
        })
        // Exclude points after the last bar and between two 0 bars:
        .filter(
          ({ y1, y2 }, idx) =>
            idx < variation.length &&
            (y1 !== areaBaseline || y2 !== areaBaseline)
        )
    )
  }, [areaBaseline, barEdges, data, variation.length])

  return (
    <>
      <VariationAreas
        areaBaseline={areaBaseline}
        variationAreaCoords={areaCoords}
      />

      <VariationLabels
        areaBaseline={areaBaseline}
        barEdges={barEdges}
        variation={variation}
        variationAreaCoords={areaCoords}
      />
    </>
  )
}

function VariationAreas({ areaBaseline, variationAreaCoords }) {
  return (
    <>
      {variationAreaCoords.map((coords, i) => (
        <path
          key={`variationArea_${i}`}
          stroke='#F7F5FF'
          fill='#F7F5FF'
          fillOpacity='1'
          d={`M${coords.x1},${areaBaseline}L${coords.x1},${coords.y1}L${coords.x2},${coords.y2}L${coords.x2},${areaBaseline}Z`}
        />
      ))}
    </>
  )
}

function VariationLabels({ areaBaseline, variation, variationAreaCoords }) {
  return (
    <>
      {variationAreaCoords.map((coords, i) => {
        const variationLabelX = (coords.x1 + coords.x2) / 2 - 27
        const variationLabelY = (coords.y1 + coords.y2) / 2 - 11

        const variationLabelIsNotCrossingXAxis =
          variationLabelY < areaBaseline - 24

        if (!variation[i]) return null

        return (
          <svg
            key={`variationLabel_${i}`}
            x={variationLabelX}
            y={
              variationLabelIsNotCrossingXAxis
                ? variationLabelY
                : areaBaseline - 24
            }
            width='55'
            height='22'
            viewBox='0 0 55 22'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M0.5 4C0.5 2.067 2.067 0.5 4 0.5H44.9631C46.0829 0.5 47.1351 1.03579 47.7937 1.9414L52.8846 8.9414C53.7772 10.1687 53.7772 11.8313 52.8846 13.0586L47.7937 20.0586C47.1351 20.9642 46.0829 21.5 44.9631 21.5H4C2.067 21.5 0.5 19.933 0.5 18V4Z'
              fill='white'
              stroke='#D8DDE2'
            />
            <text
              fill='var(--ff-color-text-base-darkest)'
              textAnchor='middle'
              dominantBaseline='middle'
              y='12'
              fontSize='12'
              fontWeight='600'
              x='27'
              width='55'
              height='22'
            >
              {Math.round(variation[i].value * 100 * 10) / 10}%
            </text>
          </svg>
        )
      })}
    </>
  )
}

import React from 'react'

import { Text } from '@fullfabric/alma-mater'

import ImportSteps from '../steps'
import DownloadImportTemplateLink from './download-import-template-link'

import styles from './styles.module.scss'

const Sidebar = ({ title }) => (
  <div className={styles.sidebar}>
    <Text type='h2' marginBottom='big' fontColor='text-base-darkest'>
      {title}
    </Text>
    <ImportSteps />

    <DownloadImportTemplateLink />
  </div>
)

export default Sidebar

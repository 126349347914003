import { useCallback, useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'

import createEvaluation from 'apps/Evaluations/api/createEvaluation'
import { useApplication } from 'apps/Evaluations/context/ApplicationData'

function useSubmitEvaluationCreation({ onSubmitted }) {
  const queryClient = useQueryClient()
  const { applicationId } = useParams()
  const [hasServerError, setHasServerError] = useState(false)

  const { data: applicData } = useApplication()

  const submitEvaluationMutation = useMutation(
    ({ formValues }) => {
      const newEvaluationValue = {
        applic_id: applicationId,
        ...formValues
      }

      if (newEvaluationValue.choice === 'primary') {
        newEvaluationValue.choice_class_of_id =
          applicData?.primary_choice_contexts[0]?.context_id
      }

      if (newEvaluationValue.choice === 'secondary') {
        newEvaluationValue.choice_class_of_id =
          applicData?.secondary_choice_contexts[0]?.context_id
      }

      setHasServerError(false)
      return createEvaluation(newEvaluationValue)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          'tableDataFetch',
          '/evaluations/api/evaluations'
        ])

        onSubmitted()
      },
      onError: () => {
        setHasServerError(true)
      }
    }
  )

  const onSubmit = useCallback(
    async (formValues) => {
      submitEvaluationMutation.mutate({ formValues })
    },
    [submitEvaluationMutation]
  )

  return {
    onSubmit,
    hasServerError,
    isSubmitting: submitEvaluationMutation.isLoading
  }
}

export default useSubmitEvaluationCreation

import React from 'react'
import { Trans } from 'react-i18next'

import { Field } from '@fullfabric/alma-mater'

import ImportActions from 'apps/Imports/components/profile-import/import-actions'
import { useSelectedData } from 'apps/Imports/contexts/selected-data'
import { useImportContext } from 'apps/Imports/hooks/useImportContext'
import { emptyChangeHandler } from './utils'

import styles from './styles.module.scss'

const AddToEventContextStep = () => {
  const event = useImportContext()
  const {
    data: { addToEventData },
    onDataChanged
  } = useSelectedData()
  const onAddToEventDataChanged = React.useCallback(
    (field, value) => {
      onDataChanged('addToEventData', { ...addToEventData, [field]: value })
    },
    [onDataChanged, addToEventData]
  )

  const responseType = addToEventData.responseType
  const attendance = addToEventData.attendance

  return (
    <>
      <Field
        id='contextId'
        type='dropdown'
        value={event.id}
        onChange={emptyChangeHandler}
        inputOptions={{
          disabled: true,
          options: [{ value: event.id, label: event.name }]
        }}
        className={styles.contextDropdown}
        label={<Trans>Event</Trans>}
      />

      <Field
        id='responseType'
        type='radio'
        label={<Trans>Registration response</Trans>}
        value={responseType}
        // onChange={(field, value) => onDataChanged(field, value, responseType)}
        onChange={onAddToEventDataChanged}
        className={styles.radioFields}
        inputOptions={[
          {
            value: 'Events::Response::Yes',
            label: <Trans>Yes</Trans>
          },
          {
            value: 'Events::Response::Maybe',
            label: <Trans>Maybe</Trans>
          },
          {
            value: 'Events::Response::No',
            label: <Trans>No</Trans>
          },
          {
            value: 'defined_in_the_import_file',
            label: <Trans>Defined in the import file</Trans>
          }
        ]}
      />

      <Field
        id='attendance'
        type='radio'
        label={<Trans>Attendance</Trans>}
        value={attendance}
        // onChange={(field, value) => onDataChanged(field, value, attendance)}
        onChange={onAddToEventDataChanged}
        className={styles.radioFields}
        inputOptions={[
          {
            value: 'true',
            label: <Trans>Yes</Trans>
          },
          {
            value: 'false',
            label: <Trans>No</Trans>
          },
          {
            value: 'defined_in_the_import_file',
            label: <Trans>Defined in the import file</Trans>
          }
        ]}
      />

      <ImportActions />
    </>
  )
}

export default AddToEventContextStep

import React from 'react'

import { ErrorIcon, Row, Table, Text } from '@fullfabric/alma-mater'

const ErrorRow = ({ error }) => {
  return (
    <Table.Row role='row' aria-label='error row'>
      <Table.Data colSpan='100' align='center'>
        <Row nogutter align='center' justify='center'>
          <ErrorIcon color='text-alert' />
          <Text tag='span' fontColor='text-alert' marginLeft='tiny'>
            {error.message}
          </Text>
        </Row>
      </Table.Data>
    </Table.Row>
  )
}

export default ErrorRow

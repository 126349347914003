import 'css-chunk:src/apps/Imports/components/page-container/components/steps/styles.module.scss';export default {
  "react-app": "react-app",
  "stepsContainer": "_stepsContainer_yp9o1_1",
  "stepItem": "_stepItem_yp9o1_8",
  "stepItemLabel": "_stepItemLabel_yp9o1_12",
  "stepItemCircle": "_stepItemCircle_yp9o1_16",
  "stepCompleted": "_stepCompleted_yp9o1_26",
  "stepActive": "_stepActive_yp9o1_30",
  "line": "_line_yp9o1_35",
  "lineCompleted": "_lineCompleted_yp9o1_43"
};
import { utils } from '@fullfabric/public-api'

const deleteCategories = async (studyPlanId, categoryIds) => {
  const response = await fetch(
    `/affairs/api/study_plan/${studyPlanId}/categories`,
    {
      credentials: 'same-origin',
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ ids: categoryIds })
    }
  )

  return await utils.checkResponse(response)
}

export default deleteCategories

.react-app ._highlight_106aa_1 {
  height: var(--space-small);
  width: 100%;
  margin-bottom: var(--space-default);
}

.react-app ._highlight_106aa_1._overdue_106aa_7 {
  background-color: var(--ff-bg-alert);
}

.react-app ._highlight_106aa_1._paid_106aa_11 {
  background-color: var(--ff-color-green-300);
}

.react-app ._content_106aa_15 {
  width: 100%;
  padding: 0 var(--space-more);
  border-bottom: var(--border-base-lighter);
}

.react-app ._content_106aa_15._mobile_106aa_21 {
  padding: 0 var(--space-default);
}

.react-app ._stackMore_106aa_25 {
  margin-bottom: var(--space-more);
}

.react-app ._stackDefault_106aa_29 {
  margin-bottom: var(--space-default);
}

.react-app ._stackSmall_106aa_33 {
  margin-bottom: var(--space-small);
}

.react-app ._stackTiny_106aa_37 {
  margin-bottom: var(--space-tiny);
}
import React from 'react'
import { Trans } from 'react-i18next'

import { AnnotatedLayout } from '@fullfabric/alma-mater'

const CourseSectionAcademicDetails = ({ children }) => {
  return (
    <section aria-labelledby='academic-details-section-title'>
      <AnnotatedLayout.Section
        title={
          <span id='academic-details-section-title'>
            <Trans>Academic Details</Trans>
          </span>
        }
        description={
          <Trans>
            These academic details will be applied to students' transcripts.
          </Trans>
        }
      >
        {children}
      </AnnotatedLayout.Section>
    </section>
  )
}

export default CourseSectionAcademicDetails

import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'

import { IconButton, SettingsIcon } from '@fullfabric/alma-mater'
import { IfPermit } from '@fullfabric/authorization-officer'

import { CONTRACT_SETTINGS_ROUTE } from 'apps/Billing/components/AppRoutes'

const SettingsButton = ({ contractId }) => {
  const { t } = useTranslation()
  const history = useHistory()

  return (
    <IfPermit to='billing.billing_change'>
      <IconButton
        onClick={() =>
          history.push(
            CONTRACT_SETTINGS_ROUTE.replace(':contractId', contractId)
          )
        }
      >
        <SettingsIcon title={t('Edit contract')} />
      </IconButton>
    </IfPermit>
  )
}

export default SettingsButton

import React from 'react'
import { useQuery } from 'react-query'

import { getCountries } from '@fullfabric/public-api'

const CountriesContext = React.createContext(null)

const useCountries = () => {
  return React.useContext(CountriesContext)
}

const CountriesDataProvider = ({ children }) => {
  const { data, isLoading } = useQuery(['get-countries'], getCountries)

  return (
    <CountriesContext.Provider
      value={{
        data,
        isLoading
      }}
    >
      {children}
    </CountriesContext.Provider>
  )
}

export default CountriesContext
export { useCountries, CountriesDataProvider }

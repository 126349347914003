import React from 'react'
import { Trans } from 'react-i18next'

import { Button } from '@fullfabric/alma-mater'

import { useImportDocument } from 'apps/Imports/contexts/import-document'
import { useSelectedData } from 'apps/Imports/contexts/selected-data'
import { useStepsCompleted, useStepState } from 'apps/Imports/contexts/steps'
import { useCommunicationPolicies } from 'apps/Imports/hooks/useCommunicationPolicies'
import { useDataProcessingPolicy } from 'apps/Imports/hooks/useDataProcessingPolicy'
import { useImportContext } from 'apps/Imports/hooks/useImportContext'
import { useMarketingPolicy } from 'apps/Imports/hooks/useMarketingPolicy'
import { PROFILE_IMPORT_STEPS } from 'apps/Imports/utils/profile-import/constants'
import { isStepCompleted } from 'apps/Imports/utils/profile-import/is-step-completed'
import useCreateImportMutation from '../hooks/use-create-import-mutation'
import useGoToStep from '../hooks/use-go-to-step'
import useUpdateImportMutation from '../hooks/use-update-import-mutation'

import styles from '../styles.module.scss'

const NextStepButton = () => {
  const context = useImportContext()
  const marketingPolicy = useMarketingPolicy()
  const dataProcessingPolicy = useDataProcessingPolicy()
  const communicationPolicies = useCommunicationPolicies()

  const { isStepUnlocked } = useStepsCompleted()
  const { data } = useSelectedData()
  const { currentStep, nextStep } = useStepState()
  const importDocument = useImportDocument()

  const { goToNextStepPage } = useGoToStep()
  const updateImportMutation = useUpdateImportMutation()
  const createImportMutation = useCreateImportMutation()

  const onNextStepClick = React.useCallback(async () => {
    if (nextStep === PROFILE_IMPORT_STEPS.MAPPING && !importDocument) {
      createImportMutation.mutate()
    } else if (currentStep === PROFILE_IMPORT_STEPS.SUMMARY) {
      updateImportMutation.mutate()
    } else {
      goToNextStepPage()
    }
  }, [
    nextStep,
    currentStep,
    createImportMutation,
    updateImportMutation,
    goToNextStepPage,
    importDocument
  ])

  const mappingUnlocked = isStepUnlocked(PROFILE_IMPORT_STEPS.MAPPING)

  const nextStepDisabled = !isStepCompleted(currentStep, data, {
    marketingPolicy,
    dataProcessingPolicy,
    communicationPolicies,
    context,
    importDocument
  })

  return (
    <Button
      primary={
        !mappingUnlocked ||
        nextStep === PROFILE_IMPORT_STEPS.MAPPING ||
        [PROFILE_IMPORT_STEPS.MAPPING, PROFILE_IMPORT_STEPS.SUMMARY].includes(
          currentStep
        )
      }
      disabled={nextStepDisabled}
      onClick={onNextStepClick}
      className={styles.actionButtons}
      loading={createImportMutation.isLoading || updateImportMutation.isLoading}
    >
      {currentStep === PROFILE_IMPORT_STEPS.SUMMARY ? (
        <Trans>Confirm and Import</Trans>
      ) : (
        <Trans>Next step</Trans>
      )}
    </Button>
  )
}

export default NextStepButton

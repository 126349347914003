import React from 'react'

import ContentGrid from 'apps/ContentPages/components/page-layout/content-grid'

const WidgetRegion = ({ fixed, children: widgets }) => {
  const Column = fixed ? ContentGrid.FixedWidgetsCol : ContentGrid.WidgetsCol

  return <Column>{widgets}</Column>
}

export default WidgetRegion

import { useOnChangesSaved } from 'apps/StudyPlans/contexts/ChangesSaved'
import { useSetStudyPlan } from 'apps/StudyPlans/contexts/StudyPlanData'
import { useSetSubjectEditionData } from 'apps/StudyPlans/contexts/SubjectEditionData'
import { useSetSubjectSectionsBeingEdited } from 'apps/StudyPlans/contexts/SubjectSectionBeingEdited'
import flattenCourseTypes from 'apps/StudyPlans/utils/flattenCourseType'

const { default: updateSubject } = require('apps/StudyPlans/api/updateSubject')
const { useMutation } = require('react-query')

const useSaveSubjectMutation = ({ sectionName, setOpenPropagationModal }) => {
  const setStudyPlan = useSetStudyPlan()
  const setSubjectData = useSetSubjectEditionData()
  const setSectionsBeingEdited = useSetSubjectSectionsBeingEdited()
  const onChangesSaved = useOnChangesSaved()

  return useMutation(
    async ({
      studyPlanId,
      dataToUpdate,
      shouldApplyChangesToTranscripts = false
    }) => {
      return await updateSubject(
        studyPlanId,
        dataToUpdate,
        shouldApplyChangesToTranscripts
      )
    },
    {
      onSuccess: (updatedSubject) => {
        onChangesSaved()
        setOpenPropagationModal(false)
        setSectionsBeingEdited((sections) => ({
          ...sections,
          [sectionName]: false
        }))

        setSubjectData({
          ...updatedSubject,
          ...flattenCourseTypes(updatedSubject.course_types || [])
        })

        setStudyPlan((studyPlan) => {
          const subjectIndex = studyPlan.courses.findIndex(
            (s) => s.id === updatedSubject.id
          )
          return {
            ...studyPlan,
            courses: [
              ...studyPlan.courses.slice(0, subjectIndex),
              updatedSubject,
              ...studyPlan.courses.slice(subjectIndex + 1)
            ]
          }
        })
      }
    }
  )
}

export default useSaveSubjectMutation

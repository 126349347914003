import * as React from 'react'
import { useTranslation } from 'react-i18next'

import moment from 'moment'

import { Text } from '@fullfabric/alma-mater'

import styles from '../styles.module.scss'

const StartsOnCell = ({ row, data }) => {
  const { i18n, t } = useTranslation()

  const value = data[row.index].starts_on

  const startsOnMoment = value ? moment(value).locale(i18n.language) : null

  return (
    <div className={styles.dataCell}>
      <Text type='f5' fontColor='text-base-darkest'>
        {startsOnMoment
          ? startsOnMoment.format('D MMM YYYY')
          : t('Not specified')}
      </Text>
      <Text type='f6' fontColor='text-base-darker' marginTop='small'>
        {startsOnMoment ? startsOnMoment.fromNow() : t('Not specified')}
      </Text>
    </div>
  )
}

export default StartsOnCell

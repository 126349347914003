import axios from 'axios'
import qs from 'qs'

const fetchWithUrl = async (url, params, options, fetchResponseParser) => {
  const response = await axios.get(
    url + `?${qs.stringify(params, { arrayFormat: 'brackets' })}`,
    options
  )
  return fetchResponseParser ? fetchResponseParser(response) : response.data
}

const fetchWithFunction = async (fetchFn, params) => {
  return await fetchFn(params)
}

const fetchEmptyFn = () => Promise.resolve([])

const fetchTableDataFn = async ({ pageParam, queryKey, ...other }) => {
  const fetchURL = queryKey[1]
  const fetchFn = queryKey[2]
  const fetchParams = queryKey[3] || {}
  const fetchOptions = queryKey[4]
  const fetchedDataParser = queryKey[5]
  const fetchResponseParser = queryKey[6]
  const loadMoreConfig = queryKey[7]

  const page = pageParam || 0

  const requestParams = { ...fetchParams }

  if (loadMoreConfig) {
    const { pageKey, offsetKey, sizeKey, pageSize } = loadMoreConfig

    if (pageKey) requestParams[pageKey] = page
    else requestParams[offsetKey] = page * pageSize

    requestParams[sizeKey] = pageSize
  }

  const performFetch = fetchURL
    ? fetchWithUrl.bind(
        null,
        fetchURL,
        requestParams,
        fetchOptions,
        fetchResponseParser
      )
    : fetchFn
    ? fetchWithFunction.bind(null, fetchFn, requestParams)
    : fetchEmptyFn

  const data = await performFetch()

  const resultData = loadMoreConfig ? { ...data, page } : data

  return fetchedDataParser ? fetchedDataParser(resultData) : resultData
}

export default fetchTableDataFn

import React from 'react'

const SubjectsIcon = ({ height = 24, width = 24, className }) => (
  <svg
    height={height}
    width={width}
    viewBox={`0 0 ${height} ${width}`}
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M2 0h9v24H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2zm7 9.5a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0zM8.221 21H2.778C2.348 21 2 20.552 2 20s.348-1 .778-1h5.444c.43 0 .778.448.778 1s-.348 1-.778 1zm-5.443-4h5.444c.43 0 .778-.448.778-1s-.348-1-.778-1H2.778c-.43 0-.778.448-.778 1s.348 1 .778 1zM22 0c1.105 0 2 .893 2 1.998V22.01c0 1.104-.895 1.99-2 1.99h-9V0h9zm0 9.5a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0zM21.221 21h-5.443c-.43 0-.778-.448-.778-1s.348-1 .778-1h5.444c.43 0 .778.448.778 1s-.348 1-.778 1zm-5.443-4h5.444c.43 0 .778-.448.778-1s-.348-1-.778-1h-5.444c-.43 0-.778.448-.778 1s.348 1 .778 1z'
      fill='#9399A2'
    />
  </svg>
)

export default SubjectsIcon

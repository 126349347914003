import React from 'react'
import { useTranslation } from 'react-i18next'

import { PageHead } from '@fullfabric/alma-mater'

import TablePageLayout from 'shared/components/TablePageLayout'

import ImportsList from 'apps/Imports/components/imports-list'

const ImportsListPage = () => {
  const { t } = useTranslation()

  return (
    <TablePageLayout>
      <PageHead title={t('Imports')} />
      <TablePageLayout.TableSection>
        <ImportsList />
      </TablePageLayout.TableSection>
    </TablePageLayout>
  )
}

export default ImportsListPage

.react-app ._nameFieldsRow_do4kr_1 {
  display: flex;
  flex-wrap: wrap;
}
.react-app ._nameFieldsRow_do4kr_1._mobile_do4kr_5 {
  flex-direction: column;
}
.react-app ._nameFieldsRow_do4kr_1 ._field_do4kr_8 {
  flex: 1;
}
.react-app ._nameFieldsRow_do4kr_1 ._spacingBetweenFields_do4kr_11 {
  margin-right: var(--space-default);
}
/* eslint-disable no-case-declarations */

import { combineReducers } from 'redux'

import actionTypes from 'apps/Courses/actions/types'

const initialDataState = {
  create_new_subject: false,
  subject_id: null,
  subject_code: '',
  subject_name: '',
  name: '',
  owner_id: '',
  campus_ids: [],
  opens_on: null,
  closes_on: null,
  starts_on: null,
  ends_on: null,
  state_auto_mode: true,
  state: 'init',
  price_amount: '',
  price_currency: null,
  minimum_capacity: null,
  maximum_capacity: null,
  target_capacity: null
}

const data = (state = initialDataState, action) => {
  switch (action.type) {
    case actionTypes.newCourseDataChanged:
      return { ...state, ...action.payload }
    case actionTypes.newSubjectClick:
      return { ...state, create_new_subject: !state.create_new_subject }
    case actionTypes.createCourse.success:
      return { ...initialDataState }
    default:
      return state
  }
}

const schema = (state = null, action) => {
  switch (action.type) {
    case actionTypes.getCourseSchema.success:
      return { ...action.payload }
    default:
      return state
  }
}

const inProgress = (state = false, action) => {
  switch (action.type) {
    case actionTypes.createCourse.start:
      return true
    case actionTypes.createCourse.success:
    case actionTypes.createCourse.error:
      return false
    default:
      return state
  }
}

const errors = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.createCourse.error:
      if (action.payload && action.payload.validationErrors) {
        return { ...state, ...action.payload.validationErrors }
      } else {
        return { ...state, ...action.payload }
      }
    case actionTypes.newCourseClearError:
      return {}
    // Necessary?
    case actionTypes.newCourseDataChanged:
      const key = Object.keys(action.payload)[0]
      const { [key]: keyToRemove, ...newState } = state
      return newState
    case actionTypes.createCourse.success:
      return {}
    default:
      return state
  }
}

export default combineReducers({ data, schema, inProgress, errors })

import React from 'react'
import { Trans } from 'react-i18next'

import MappingSummarySection from 'apps/Imports/components/summary-mapping-section'
import SimpleSummarySection from 'apps/Imports/components/summary-simple-section'
import { useSelectedData } from 'apps/Imports/contexts/selected-data'
import { useImportType } from 'apps/Imports/contexts/steps'
import { PRODUCT_IMPORT_STEPS } from 'apps/Imports/utils/product-import/constants'
import getUrlFromStepId from 'apps/Imports/utils/product-import/get-url-from-step-id'
import ImportActions from '../import-actions'

const ImportSummaryStep = () => {
  const { data } = useSelectedData()
  const importType = useImportType()

  return (
    <>
      <SimpleSummarySection
        linkTo={getUrlFromStepId(importType, PRODUCT_IMPORT_STEPS.FILE_UPLOAD)}
        stepId={PRODUCT_IMPORT_STEPS.FILE_UPLOAD}
        fieldsData={[
          {
            label: <Trans>File name</Trans>,
            value: data.file?.name
          }
        ]}
      />

      <MappingSummarySection
        url={getUrlFromStepId(importType, PRODUCT_IMPORT_STEPS.MAPPING)}
        stepId={PRODUCT_IMPORT_STEPS.MAPPING}
      />

      <ImportActions />
    </>
  )
}

export default ImportSummaryStep

import React from 'react'

const ContinueApplicationIcon = () => {
  return (
    <svg
      width='32'
      height='32'
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M26 21H31V15'
        stroke='black'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M30.748 21.0002C29.8212 17.4067 26.5448 14.9224 22.8346 14.9998C19.1243 15.0772 15.9544 17.6961 15.1783 21.3251C14.4023 24.9541 16.2238 28.6404 19.5779 30.2285C22.932 31.8166 26.9379 30.8896 29.2533 27.9895'
        stroke='black'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8 9C10.2091 9 12 7.20914 12 5C12 2.79086 10.2091 1 8 1C5.79086 1 4 2.79086 4 5C4 7.20914 5.79086 9 8 9Z'
        stroke='black'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14.324 14.9935C12.9183 12.0379 9.65269 10.4505 6.46018 11.171C3.26767 11.8914 1.0002 14.7274 1 18.0001V21.0001H4L5 31.0001H11L11.696 24.0455'
        stroke='black'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M22 8C23.933 8 25.5 6.433 25.5 4.5C25.5 2.567 23.933 1 22 1C20.067 1 18.5 2.567 18.5 4.5C18.5 6.433 20.067 8 22 8Z'
        stroke='black'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M27.2 12.0001C26.2315 10.3237 24.5136 9.2176 22.5867 9.0298C20.6598 8.84201 18.7607 9.59555 17.4867 11.0534'
        stroke='black'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default ContinueApplicationIcon

import { utils } from '@fullfabric/public-api'

export default async function duplicateEmailTemplate(id, data) {
  const response = await fetch(`/api/email_templates/${id}/duplicate`, {
    credentials: 'same-origin',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      name: data.name
    })
  })

  return await utils.checkResponse(response)
}

import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'

import { exportSize } from 'shared/api/exports'

export default function useExportCount({ type, opts }) {
  const [exportCount, setExportCount] = useState(0)
  const [exportCountReady, setExportCountReady] = useState(false)

  const { data } = useQuery(['exportSize', type, opts], () =>
    exportSize(type, opts)
  )

  useEffect(() => {
    if (data) {
      setExportCount(data.size)
      setExportCountReady(true)
    }
  }, [data])

  return { exportCount, exportCountReady }
}

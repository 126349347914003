import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'

import { Button, PageHead } from '@fullfabric/alma-mater'
import { useCurrentUser } from '@fullfabric/authorization-officer'

import SuccessPopupMessage from 'shared/components/SuccessPopupMessage'
import TablePageLayout from 'shared/components/TablePageLayout'
import useModal from 'shared/hooks/useModal'

import PaymentPlansTable from 'apps/Financial/components/PaymentPlansTable'
import { SchemasProvider } from 'apps/Financial/components/SchemasProvider'
import ColumnStore from 'apps/Financial/services/ColumnStore'
import BulkPublishStatusUpdateModal from './BulkPublishStatusUpdateModal'
import PaymentPlansExportModal from './PaymentPlansExportModal'

const PAYMENT_PLANS_FETCH_URL = '/book_keeper/api/profiles/plans'

export default function PaymentPlanListPage() {
  const { t } = useTranslation()
  const user = useCurrentUser()
  const [filters, setFilters] = useState({})
  const [search, setSearch] = useState({})
  const [feedbackMessage, setFeedbackMessage] = useState(null)
  const [feedbackMessageIsDisplayed, setFeedbackMessageIsDisplayed] =
    useState(false)
  const [
    bulkPublishStatusUpdateModalOpen,
    setBulkPublishStatusUpdateModalOpen
  ] = useState(false)
  const [isExportModelOpen, handleExportModelOpened, handleExportModelClosed] =
    useModal()

  const queryClient = useQueryClient()

  return (
    <>
      {isExportModelOpen && (
        <PaymentPlansExportModal
          columns={ColumnStore.getStoredVisibleColumnsForUser(user?.id)}
          isOpen
          filters={filters}
          search={search?.search}
          onClose={handleExportModelClosed}
        />
      )}

      <SchemasProvider>
        <TablePageLayout>
          <PageHead
            title={t('Payment plans')}
            action={
              <>
                <Button
                  marginRight='small'
                  onClick={() => setBulkPublishStatusUpdateModalOpen(true)}
                >
                  {t('Update status')}
                </Button>

                <Button onClick={handleExportModelOpened}>{t('Export')}</Button>
              </>
            }
            noBorder
          />

          <TablePageLayout.TableSection>
            <PaymentPlansTable
              fetchUrl={PAYMENT_PLANS_FETCH_URL}
              setSearch={setSearch}
              setFilters={setFilters}
            />
          </TablePageLayout.TableSection>
        </TablePageLayout>
      </SchemasProvider>

      <BulkPublishStatusUpdateModal
        isOpen={bulkPublishStatusUpdateModalOpen}
        onClose={() => setBulkPublishStatusUpdateModalOpen(false)}
        search={search}
        filters={filters}
        onUpdate={(action) => {
          queryClient.invalidateQueries([
            'tableDataFetch',
            PAYMENT_PLANS_FETCH_URL
          ])
          setBulkPublishStatusUpdateModalOpen(false)
          setFeedbackMessage(
            action === 'publish'
              ? t('Payment plans published')
              : t('Payment plans unpublished')
          )
          setFeedbackMessageIsDisplayed(true)
        }}
      />

      <SuccessPopupMessage
        isDisplayed={feedbackMessageIsDisplayed}
        onAfterTimeout={() => setFeedbackMessageIsDisplayed(false)}
        message={feedbackMessage}
      />
    </>
  )
}

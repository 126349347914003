.react-app #_emailType_1n4av_1 {
  display: inline;
  width: 100px;
  float: right;
}

.react-app ._pageHead_1n4av_7 {
  width: 100%;
}

.react-app ._layoutContainer_1n4av_11 {
  overflow: auto;
  height: 100%;
}
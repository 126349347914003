import 'css-chunk:src/apps/MergeProfiles/components/ProfileInfoSelector/styles.module.scss';export default {
  "react-app": "react-app",
  "baseRow": "_baseRow_1kvkl_1",
  "baseCol": "_baseCol_1kvkl_8",
  "loading": "_loading_1kvkl_15",
  "sectionContainer": "_sectionContainer_1kvkl_22",
  "row": "_row_1kvkl_26",
  "sectionTitle": "_sectionTitle_1kvkl_34",
  "field": "_field_1kvkl_35",
  "mainContainer": "_mainContainer_1kvkl_40",
  "errorAlert": "_errorAlert_1kvkl_40",
  "multi": "_multi_1kvkl_51",
  "valuesContainer": "_valuesContainer_1kvkl_51",
  "valueText": "_valueText_1kvkl_54",
  "subFieldLabelText": "_subFieldLabelText_1kvkl_61",
  "subFieldValueContainer": "_subFieldValueContainer_1kvkl_65",
  "smartEmailSection": "_smartEmailSection_1kvkl_72",
  "smartEmailTitle": "_smartEmailTitle_1kvkl_75",
  "emailRow": "_emailRow_1kvkl_78",
  "emailInputCol": "_emailInputCol_1kvkl_81"
};
import React from 'react'
import { useTranslation } from 'react-i18next'

import { Filter } from '@fullfabric/alma-mater'

import getLifecyclesAsOptions from 'shared/api/getLifecyclesAsOptions'

import getProgrammeClasses from 'apps/Reports/api/getProgrammeClasses'
import getProgrammes from 'apps/Reports/api/getProgrammes'

import styles from './styles.module.scss'

const ProductFilter = ({ filter, onFilterChange, onOptionsLoaded }) => {
  const { t } = useTranslation()

  return (
    <Filter
      onFilterChange={onFilterChange}
      onOptionsLoaded={onOptionsLoaded}
      selectedFilter={filter}
      containerClassName={styles.filter}
      filters={[
        {
          id: 'programme',
          searchField: 'name',
          name: t('Product'),
          type: 'single',
          valueField: 'id',
          labelField: 'name',
          defaultValue: (opts) => opts[0]?.id,
          loadOptions: getProgrammes
        },
        {
          id: 'classes',
          searchField: 'name',
          name: t('Intakes'),
          type: 'multi',
          valueField: 'id',
          labelField: 'name',
          defaultValue: (opts) => (opts[0] ? [opts[0].id] : []),
          loadOptionsParams: filter.programme,
          loadOptions: getProgrammeClasses
        },
        {
          id: 'states',
          searchField: 'label',
          defaultLabel: t('All lifecycles'),
          defaultValue: (opts) =>
            opts.find((opt) => opt?.value === 'prospect::started_application')
              ? ['prospect::started_application']
              : [opts[0]?.value],
          name: t('Lifecycles'),
          labelValueSeparator: ' are',
          type: 'multi',
          loadOptions: getLifecyclesAsOptions
        },
        {
          id: 'withdrawn',
          name: t('Withdrawn'),
          defaultValue: 'false',
          type: 'single',
          options: [
            { value: 'true', label: t('has withdrawn') },
            { value: 'false', label: t('has not withdrawn') },
            { value: 'all', label: t('all') }
          ]
        }
      ]}
    />
  )
}

export default ProductFilter

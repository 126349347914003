.react-app ._container_1rutz_1 {
  padding: var(--space-small) var(--space-less);
}
.react-app ._container_1rutz_1 p {
  margin: 0;
}

.react-app ._iconWrapper_1rutz_8 {
  margin-bottom: var(--space-tiny);
}
.react-app ._iconWrapper_1rutz_8 svg {
  display: block;
  fill: var(--ff-color-text-base-darker) !important;
}

.react-app ._clickableWrapper_1rutz_16 {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  display: flex;
  flex-direction: column;
  align-items: center;
}
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { RadioInput, Text } from '@fullfabric/alma-mater'

import { useMergeData } from 'apps/MergeProfiles/contexts/MergeData'
import getFieldValue from 'apps/MergeProfiles/utils/getFieldValue'
import getLocaledLabel from 'apps/MergeProfiles/utils/getLocaledLabel'
import { BaseCol, BaseRow } from './Base'

import classNames from 'classnames'
import styles from '../styles.module.scss'

const noValue = (
  <Text tag='span'>
    <Trans>No value</Trans>
  </Text>
)

const SimpleFieldRow = ({ field, error, onSelectDuplicate, onSelectMain }) => {
  const { i18n } = useTranslation()
  const {
    mergeData: { sourceProfile, targetProfile, profileInfoFrom }
  } = useMergeData()
  const duplicateProfileFieldValue = getFieldValue(field, sourceProfile)
  const mainProfileFieldValue = getFieldValue(field, targetProfile)
  const localizedFieldName = getLocaledLabel(field.locals, i18n.language)

  return (
    <BaseRow
      className={classNames(styles.row, styles.field)}
      role='row'
      aria-label={`${localizedFieldName} profile field`}
    >
      <BaseCol>
        <Text type='h5' fontColor='text-base-darkest'>
          {localizedFieldName}
        </Text>
        {error && (
          <Text type='f6' fontColor='text-alert' marginTop='tiny'>
            <Trans>Field is required</Trans>
          </Text>
        )}
      </BaseCol>
      <BaseCol>
        <RadioInput
          id={`duplicateProfile_${field.name}`}
          aria-label={`duplicate profile ${field.name}`}
          name={field.name}
          value='duplicate'
          checked={profileInfoFrom[field.name] === 'duplicate'}
          label={duplicateProfileFieldValue || noValue}
          onChange={onSelectDuplicate}
        />
      </BaseCol>
      <BaseCol>
        <RadioInput
          id={`mainProfile_${field.name}`}
          aria-label={`main profile ${field.name}`}
          name={field.name}
          value='main'
          checked={profileInfoFrom[field.name] === 'main'}
          label={mainProfileFieldValue || noValue}
          onChange={onSelectMain}
        />
      </BaseCol>
    </BaseRow>
  )
}

export default SimpleFieldRow

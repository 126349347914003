/* eslint-disable camelcase */

import React from 'react'
import { Trans } from 'react-i18next'

import PropTypes from 'prop-types'

import {
  Button,
  Col,
  Dropdown,
  IconButton,
  Label,
  MoreIcon,
  Row,
  Text
} from '@fullfabric/alma-mater'

import { useSetCardToDelete } from 'apps/Inbox/contexts/deleteCard'
import { useIncompleteTask } from 'apps/Inbox/hooks/handleIncompleteTaskHook'
import useHandleComplete from 'apps/Inbox/hooks/useHandleComplete'
import { taskRoute } from 'apps/Inbox/utils/cardUtils'
import InboxTaskCardDescriptor from '../InboxTaskCardDescriptor'

import styles from './styles.module.scss'

export default function InboxTaskCard({ card }) {
  const setCardToDelete = useSetCardToDelete()
  const incompleteTaskMutation = useIncompleteTask()
  const completeTaskMutation = useHandleComplete()

  const {
    id: cardId,
    entity: {
      target,
      title,
      assigned_at: assignedAt,
      assigned_to: assignedTo,
      completed_by: completedBy,
      completed_at: completedAt
    },
    status,
    status_updated_by: statusUpdatedBy,
    status_updated_at: statusUpdatedAt,
    actor,
    updated_at
  } = card

  return (
    <div key={cardId} className={styles.taskCard}>
      <Row middle='xs'>
        <Col xs={9} className={styles.titleContainer}>
          <Label color='red-600'>
            <Trans>Task</Trans>
          </Label>

          <Text
            className={styles.title}
            tag='span'
            type='h4'
            marginLeft='small'
            fontColor='text-base-darkest'
          >
            {title || <Trans>(no title)</Trans>}
          </Text>

          <InboxTaskCardDescriptor
            status={status}
            statusUpdatedBy={statusUpdatedBy}
            statusUpdatedAt={statusUpdatedAt}
            actor={actor}
            target={target}
            assignedTo={assignedTo}
            assignedAt={assignedAt}
            completedBy={completedBy}
            completedAt={completedAt}
            updatedAt={updated_at}
          />
        </Col>

        <Col xs={3}>
          <Row middle='xs' end='xs'>
            <Col xs={12} className={styles.buttons}>
              <Button
                size='small'
                marginRight='small'
                onClick={() => {
                  status === 'active'
                    ? completeTaskMutation({ card })
                    : incompleteTaskMutation({ card })
                }}
              >
                {status === 'active' ? (
                  <Trans>Complete task</Trans>
                ) : (
                  <Trans>Mark as incomplete</Trans>
                )}
              </Button>

              <Dropdown menuWrapperClassName={styles.taskActionsDropdown}>
                <Dropdown.Toggle>
                  <IconButton small>
                    <MoreIcon />
                  </IconButton>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {status === 'active' && (
                    <Dropdown.Menu.Option
                      className={styles.editTaskOption}
                      href={taskRoute(target.id)}
                      target='_top'
                    >
                      <Trans>Edit task</Trans>
                    </Dropdown.Menu.Option>
                  )}
                  <Dropdown.Menu.Option
                    negative
                    onClick={() => setCardToDelete(card)}
                  >
                    Delete
                  </Dropdown.Menu.Option>
                </Dropdown.Menu>
              </Dropdown>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  )
}

InboxTaskCard.propTypes = {
  card: PropTypes.shape({
    id: PropTypes.string.isRequired,
    entity: PropTypes.shape({
      target: PropTypes.object,
      title: PropTypes.string,
      assigned_at: PropTypes.string,
      assigned_to: PropTypes.object,
      completed_by: PropTypes.object,
      completed_at: PropTypes.string
    }).isRequired,
    status: PropTypes.string.isRequired,
    status_updated_by: PropTypes.object,
    status_updated_at: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    actor: PropTypes.object,
    updated_at: PropTypes.string
  }).isRequired
}

import React from 'react'
import { useTranslation } from 'react-i18next'

import { Text } from '@fullfabric/alma-mater'

import { useLocale } from 'shared/contexts/Locale'

import {
  formatDueDate,
  timeFromDueDate,
  timeToDueDate
} from 'apps/ContentPages/services/payments/format-due-date'

const DueDateDetail = ({ fee, feeState }) => {
  const { t } = useTranslation()
  const { locale } = useLocale()
  const dueDate = fee.due_date
  const isOverdue = feeState === 'overdue'
  const isAlmostDue = feeState === 'almostDue'
  const shouldShowRelativeTime = feeState !== 'paid'

  return (
    <>
      <Text tag='span' type='h5' fontColor='text-base-darker'>
        {formatDueDate(dueDate, locale)}
      </Text>
      {shouldShowRelativeTime && (
        <Text
          tag='span'
          type='h5'
          fontColor={
            isOverdue
              ? 'text-alert'
              : isAlmostDue
              ? 'text-warning'
              : 'text-base'
          }
          marginLeft='small'
        >
          {isOverdue
            ? timeFromDueDate(dueDate, locale, t)
            : timeToDueDate(dueDate, locale, t)}
        </Text>
      )}
    </>
  )
}

export default DueDateDetail

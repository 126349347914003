import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { CheckboxInput, Text } from '@fullfabric/alma-mater'

import { useMergeData } from 'apps/MergeProfiles/contexts/MergeData'

import styles from './styles.module.scss'

const ERRORS = {
  empty: <Trans>This field is required</Trans>
}

const ConfirmDeleteCheckbox = () => {
  const { t } = useTranslation()
  const { mergeData, mergeError, updateMergeData, removeMergeError } =
    useMergeData()

  const onConfirmDeleteChange = React.useCallback(
    (checked) => {
      updateMergeData({ confirmDuplicateProfileDeletion: checked })
      removeMergeError('confirmDuplicateProfileDeletion')
    },
    [removeMergeError, updateMergeData]
  )

  return (
    <div className={styles.container}>
      <Text type='h6' fontColor='yellow-800' marginBottom='tiny'>
        <Trans>Warning note:</Trans>
      </Text>
      <Text type='f4' fontColor='text-base-darkest' marginBottom='small'>
        <Trans>
          The duplicate profile will be permanently deleted, destroying all
          associated information.
        </Trans>
      </Text>
      <CheckboxInput
        id='confirmDuplicateProfileDeletion'
        checked={mergeData.confirmDuplicateProfileDeletion}
        onChange={onConfirmDeleteChange}
        label={t('I understand the consequences, delete the duplicate profile')}
      />
      {ERRORS[mergeError.confirmDuplicateProfileDeletion] && (
        <Text type='f6' fontColor='text-alert' marginTop='tiny'>
          {ERRORS[mergeError.confirmDuplicateProfileDeletion]}
        </Text>
      )}
    </div>
  )
}

export default ConfirmDeleteCheckbox

import qs from 'qs'

import { utils } from '@fullfabric/public-api'

const getGranularityForPeriod = (period) =>
  period.includes('week') || period.includes('month') ? 'daily' : 'weekly'

const getApplicStatsTimeseries = async ({ params, type }) => {
  const periodFilter = params.period || 'this_year'
  const granularity = getGranularityForPeriod(periodFilter)

  const templatesFilter = params.templates
    ? { templates: JSON.stringify(params.templates) }
    : {}

  const queryParams = qs.stringify({
    ...params,
    ...templatesFilter,
    period: periodFilter
  })

  const response = await fetch(
    `/applics/data/ts/${type}/${granularity}?${queryParams}`,
    {
      credentials: 'same-origin'
    }
  )

  return await utils.checkResponse(response)
}

export default getApplicStatsTimeseries

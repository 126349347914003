import React from 'react'

import { Col, Container, Row, Visible } from '@fullfabric/alma-mater'

import useIsMobileLayout from 'apps/ContentPages/hooks/use-is-mobile-layout'
import useIsMobileNavigation from 'apps/ContentPages/hooks/use-is-mobile-navigation'
import BackgroundImage from '../background-layer/background-image'
import Header from '../header'
import NavigationInHeader from '../navigation-in-header'

import classNames from 'classnames'
import styles from './styles.module.scss'

const FunctionalPageLayout = ({
  title,
  hideBackgroundImage,
  contentClassName,
  children
}) => {
  const isMobileLayout = useIsMobileLayout()
  const isMobileNavigation = useIsMobileNavigation()

  return (
    <>
      <Visible xs sm md>
        <Header>
          <NavigationInHeader />
        </Header>
      </Visible>
      <Container fluid className={styles.functionalGrid}>
        <Row nogutter gutterWidth={0}>
          <Col
            xs={12}
            lg={hideBackgroundImage ? 12 : 11}
            xl={hideBackgroundImage ? 12 : 8}
          >
            <main
              className={classNames([
                styles.contentCol,
                contentClassName,
                isMobileLayout && styles.small,
                !isMobileLayout && styles.big,
                !isMobileNavigation && styles.desktopLayout
              ])}
            >
              {title ? (
                <Row nogutter gutterWidth={0}>
                  <Col>{title}</Col>
                </Row>
              ) : null}
              <section id='main-content' aria-labelledby='page-title'>
                {children}
              </section>
            </main>
          </Col>
          {!hideBackgroundImage && (
            <Visible lg xl>
              <Col lg={1} xl={4}>
                <BackgroundImage />
              </Col>
            </Visible>
          )}
        </Row>
      </Container>
    </>
  )
}

export default FunctionalPageLayout

import React from 'react'
import { createPortal } from 'react-dom'

import { FeedbackMessage } from '@fullfabric/alma-mater'

import cx from 'classnames'
import styles from './styles.module.scss'

export const FeedbackMessageContext = React.createContext()

export const useDisplayFeedbackMessage = () =>
  React.useContext(FeedbackMessageContext)?.displayMessage

export const useChangeFeedbackMessageTimeout = () =>
  React.useContext(FeedbackMessageContext)?.changeTimeoutInterval

const ANIMATION_DURATION = 800
const DEFAULT_MESSAGE_TIMEOUT = 3000

const FeedbackMessageProvider = ({
  children,
  defaultMessageTimeout = DEFAULT_MESSAGE_TIMEOUT
}) => {
  const [open, setOpen] = React.useState(false)
  const [message, setMessage] = React.useState()
  const [options, setOptions] = React.useState({})

  const displayMessage = React.useCallback(
    (message, opts = {}) => {
      setMessage(message)
      setOptions(opts)
    },
    [setMessage, setOptions]
  )

  React.useEffect(() => {
    const messageTimeout = options?.timeout || defaultMessageTimeout

    setOpen(!!message)

    const timeoutHandle = setTimeout(() => {
      setOpen(false)

      options?.onFeedbackMessageClose && options.onFeedbackMessageClose()
    }, messageTimeout)

    const messageTimeoutHandle = setTimeout(() => {
      setMessage(null)
      setOptions({})
    }, messageTimeout + ANIMATION_DURATION) // Cleanup message after component is hidden

    return () => {
      // Cleanup timeouts when component is unmounted/re-rendered
      clearTimeout(timeoutHandle)
      clearTimeout(messageTimeoutHandle)
    }
  }, [message, options, defaultMessageTimeout])

  const { className: customClassName, ...remainingCustomFeedbackProps } =
    options?.customFeedbackProps || {}

  return (
    <FeedbackMessageContext.Provider value={{ displayMessage }}>
      {children}

      {createPortal(
        <FeedbackMessage
          isOpen
          message={message || ''}
          type='success'
          className={cx(
            styles.feedbackMessage,
            customClassName,
            open && styles.opened
          )}
          {...remainingCustomFeedbackProps}
        />,
        document.body
      )}
    </FeedbackMessageContext.Provider>
  )
}

export default FeedbackMessageProvider

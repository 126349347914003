import React from 'react'
import { useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { useHistory } from 'react-router-dom'

import PropTypes from 'prop-types'

import { Alert, Button, Field } from '@fullfabric/alma-mater'

import { useAppData } from 'shared/contexts/AppData'

import confirmAccountWithPassword from 'apps/Authentication/api/confirmAccountWithPassword'
import resetPassword from 'apps/Authentication/api/resetPassword'
import FormTitle from 'apps/Authentication/components/FormTitle'
import { LOGIN_ROUTE } from 'apps/Authentication/constants/routes'
import useIsMobileLayout from 'apps/Authentication/hooks/useIsMobileLayout'
import locationHelpers from 'apps/Authentication/utils/locationHelpers'
import useTranslatePolicyErrorMessage from './useTranslatePolicyErrorMessage'

const ChooseNewPasswordForm = ({ type, token }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const isInMobileLayout = useIsMobileLayout()

  const { password_policy: passwordPolicy } = useAppData()
  const translatePolicyErrorMessage =
    useTranslatePolicyErrorMessage(passwordPolicy)

  const { register, handleSubmit, formState, setFocus } = useForm()
  const { errors } = formState
  const { ref: passwordInputRef, ...passwordInputProps } = register(
    'password',
    { required: t('This field is required') }
  )
  const [errorMessage, setErrorMessage] = React.useState(null)

  React.useEffect(() => {
    setFocus('password')
  }, [setFocus])

  const submitNewPasswordMutation = useMutation(
    ({ token, data }) =>
      type === 'confirmation'
        ? confirmAccountWithPassword(token, data)
        : resetPassword(token, data),
    {
      onSuccess: (data) => {
        if (type === 'confirmation') {
          history.push({
            pathname: LOGIN_ROUTE,
            state: {
              info: t('Thank you. Please log in below.')
            }
          })
        } else {
          locationHelpers.goTo(data.redirect_to || '/')
        }
      },
      onError: (err) => {
        if (err.data?.error === 'already-active') {
          history.push({
            pathname: LOGIN_ROUTE,
            state: { info: err.data.messages }
          })

          return
        }

        if (err.status === 406 && err.data?.messages) {
          const errorMessages = Array.isArray(err.data?.messages)
            ? err.data?.messages
            : [err.data?.messages]
          const errorMessage = errorMessages
            .map((msg) => translatePolicyErrorMessage(`Password ${msg}`))
            .join(', ')

          setErrorMessage(errorMessage)

          return
        }

        setErrorMessage(
          <Trans>There was a server problem. Please try again.</Trans>
        )
      }
    }
  )

  const onSubmit = React.useCallback(
    async (formValues) => {
      submitNewPasswordMutation.mutate({ token, data: formValues })
    },
    [token, submitNewPasswordMutation]
  )

  const title =
    type === 'expired' ? (
      <Trans>Password Reset</Trans>
    ) : (
      <Trans>Almost there</Trans>
    )

  const subtitle =
    type === 'expired' ? (
      <React.Fragment>
        <Trans>Your password has expired.</Trans>
        <br />
        <Trans>Please type a new password.</Trans>
      </React.Fragment>
    ) : (
      <Trans>Please choose a password</Trans>
    )

  const fieldLabel =
    type === 'expired' ? <Trans>New Password</Trans> : <Trans>Password</Trans>

  const submitButtonLabel =
    type === 'expired' ? (
      <Trans>Reset password and sign in</Trans>
    ) : (
      <Trans>Set password and sign in</Trans>
    )

  return (
    <form>
      <FormTitle title={title} subtitle={subtitle} />

      {errorMessage ? (
        <Alert description={errorMessage} marginBottom='default' />
      ) : null}

      <Field
        {...passwordInputProps}
        inputRef={passwordInputRef}
        type='passwordStrengthChecked'
        inputOptions={{
          tooltipPreferredPosition: isInMobileLayout ? 'top' : 'right',
          passwordPolicy: {
            minDigits: passwordPolicy.minimum_digits_length,
            minAlpha: passwordPolicy.minimum_alphas_length,
            minLength: passwordPolicy.minimum_length,
            minSymbols: passwordPolicy.minimum_symbols_length
          }
        }}
        label={fieldLabel}
        error={errors.password?.message}
      />

      <Button
        primary
        type='submit'
        onClick={handleSubmit(onSubmit)}
        loading={
          submitNewPasswordMutation.isLoading ||
          submitNewPasswordMutation.isSuccess // if the request succeeded, button should be loading while redirecting
        }
      >
        {submitButtonLabel}
      </Button>
    </form>
  )
}

ChooseNewPasswordForm.propTypes = {
  type: PropTypes.string,
  token: PropTypes.string
}

export default ChooseNewPasswordForm

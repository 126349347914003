export const getPrivacyPolicy = (data) => data && data.privacy_policy

export const shouldDisplayPrivacyPolicyForm = (data, includeLoggedOut) => {
  const privacyPolicy = getPrivacyPolicy(data)
  const { _user: loggedInUser } = data || {}

  const hasPolicy = privacyPolicy && privacyPolicy.published
  const isUserLoggedIn = loggedInUser && loggedInUser.token
  const isStaff =
    isUserLoggedIn && loggedInUser.roles.some((role) => role.state === 'staff')

  if (includeLoggedOut) {
    return hasPolicy && !isStaff
  }

  return hasPolicy && isUserLoggedIn && !isStaff
}

export const isPrivacyAgreementValid = (accepted) => accepted === 'true'

import React from 'react'

const useUpdateHiddenColumns = (
  allColumns,
  visibleColumns,
  onHiddenColumnsChanged
) => {
  React.useEffect(() => {
    if (onHiddenColumnsChanged) {
      const hiddenColumnIds = allColumns
        .filter(({ isVisible }) => !isVisible)
        .map(({ id }) => id)

      const visibleColumnIds = visibleColumns.map(({ id }) => id)

      onHiddenColumnsChanged(hiddenColumnIds, visibleColumnIds)
    }
  }, [onHiddenColumnsChanged, visibleColumns, allColumns])
}

export default useUpdateHiddenColumns

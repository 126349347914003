import React from 'react'
import { useTranslation } from 'react-i18next'

import WizardPage from 'apps/Applics/components/TemplateWizardPage'
import TemplateNameForm from './Form'

const TemplateNameWizardPage = () => {
  const { t } = useTranslation()

  return (
    <WizardPage
      title={t('Create a new application form.')}
      subtitle={t("Let's start by naming your application form")}
      description={t(
        `The name will appear as the title on the application form. Use a name which describes the possible study choices for the form e.g. Postgraduate Degree Application Form.`
      )}
    >
      <TemplateNameForm />
    </WizardPage>
  )
}

export default TemplateNameWizardPage

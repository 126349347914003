{
  "Analytical": "Analytisk",
  "Strictly necessary": "Strikt nödvändig",
  "Functional": "Funktionell",
  "Advertising": "Cookies för reklam",
  "Submit": "Skicka",
  "Thank you": "Tack",
  "You have confirmed your marketing preferences.": "Du har bekräftat dina marknadsföringsinställningar.",
  "You did not accept the Privacy Policy.": "Du accepterade inte integritetspolicyn.",
  "You must contact us to delete your data.": "Du måste kontakta oss för att radera dina data.",
  "Change privacy policy agreement": "Ändra integritetspolicyavtal",
  "Privacy Policy": "Integritetspolicy",
  "Next: Update marketing preferences": "Nästa: Uppdatera marknadsföringsinställningar",
  "I Accept the Privacy Policy": "Jag accepterar integritetspolicyn",
  "I DO NOT accept the Privacy Policy": "Jag accepterar INTE integritetspolicyn",
  "If you do not accept the privacy policy, you must contact the data processor to delete your data.": "Om du inte accepterar integritetspolicyn måste du kontakta universitetet för att radera dina data.",
  "You have updated your marketing preferences.": "Du har uppdaterat dina marknadsföringsinställningar.",
  "Log in": "Logga in",
  "Finish your registration": "Slutför din registrering",
  "Marketing Policy": "Marknadsföringspolicy",
  "I would like to receive information by Email": "Jag vill ta emot information via e-post",
  "I would like to receive information by Phone": "Jag vill ta emot information via telefon",
  "I would like to receive information by SMS": "Jag vill ta emot information via SMS",
  "I would like to receive information by Mail": "Jag vill ta emot information via post",
  "I do not want to be contacted with this type of information": "Jag vill inte bli kontaktad med denna typ av information",
  "Cookie Policy": "Cookie-policy",
  "Back to Portal": "Tillbaka till portalen",
  "Manage your preferences": "Hantera dina inställningar",
  "Save cookie preferences": "Spara cookie-inställningar",
  "You accepted our privacy policy on our website - {{institutionHost}} - on {{date}}": "Du accepterade vår integritetspolicy på vår webbplats - {{institutionHost}} - den {{date}}",
  "You are receiving this email because you accepted our marketing policy on our website - {{institutionHost}} - on {{date}}": "Du får detta e-postmeddelande eftersom du accepterade vår marknadsföringspolicy på vår webbplats - {{institutionHost}} - den {{date}}",
  "You accepted our privacy policy when creating an account on {{date}}": "Du accepterade vår integritetspolicy när du skapade ett konto den {{date}}",
  "You accepted our privacy policy on {{date}} from our email": "Du accepterade vår integritetspolicy den {{date}} från vårt e-postmeddelande",
  "You are receiving this email because you accepted our marketing policy on {{date}} from our email": "Du får detta e-postmeddelande eftersom du godkände vår marknadsföringspolicy den {{date}} från vår e-post",
  "You are receiving this email because a person created a profile for you and obtained your consent from {{consentSource}} on {{date}}": "Du får detta e-postmeddelande eftersom en person skapade en profil för dig och fick ditt samtycke från {{consentSource}} den {{date}}",
  "You accepted our privacy policy when submitting the {{entity}} form on {{date}}": "Du godkände vår integritetspolicy när du skickade in {{entity}} formuläret den {{date}}",
  "You accepted our marketing policy when submitting the {{entity}} form on {{date}}": "Du godkände vår marknadsföringspolicy när du skickade in {{entity}} formuläret den {{date}}",
  "You accepted our privacy policy when registering for the {{entity}} event on {{date}}": "Du godkände vår integritetspolicy när du registrerade dig för {{entity}} evenemanget den {{date}}",
  "You accepted our marketing policy when registering for the {{entity}} event on {{date}}": "Du godkände vår marknadsföringspolicy när du registrerade dig för {{entity}} evenemanget den {{date}}",
  "Unsubscribe": "Avsluta prenumeration",
  "Yes, unsubscribe": "Ja, avsluta prenumeration",
  "No, stay subscribed": "Nej, behåll prenumerationen",
  "Manage marketing preferences": "Hantera marknadsföringsinställningar",
  "Unsubscribe successful": "Prenumerationen avslutades framgångsrikt",
  "You are still subscribed": "Du är fortfarande prenumererad",
  "Back to homepage": "Tillbaka till startsidan",
  "Are you sure you want to unsubscribe from the <0>{{topicName}}</0> topic?": "Är du säker på att du vill avsluta prenumerationen på ämnet <0>{{topicName}}</0>?",
  "You have been unsubscribed from the <0>{{topicName}}</0> topic.": "Du har avslutat prenumerationen på ämnet <0>{{topicName}}</0>.",
  "You have not been unsubscribed from the <0>{{topicName}}</0> topic.": "Du har inte avslutat prenumerationen på ämnet <0>{{topicName}}</0>."
}

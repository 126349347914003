const formatGenderDataseries = (
  genderDistributionPerIntake,
  intakesData,
  mainIntakeIndex = 0
) => {
  const mainIntakeGenderData = genderDistributionPerIntake[mainIntakeIndex]

  const genderNames = Object.keys(mainIntakeGenderData)
    .filter((keyName) => keyName !== 'dimension')
    .sort(
      (keyA, keyB) => mainIntakeGenderData[keyB] - mainIntakeGenderData[keyA]
    )

  const valuesByIntakeByGender = genderNames.reduce((acc, genderName) => {
    const valuesByIntake = genderDistributionPerIntake.reduce(
      (accByIntake, intakeGenderData, intakeIndex) => {
        const value = intakeGenderData[genderName] || 0
        const intakeName = intakesData[intakeIndex].name

        return {
          ...accByIntake,
          [intakeName]: value
        }
      },
      {}
    )

    return { ...acc, [genderName]: valuesByIntake }
  }, {}) // transforms data from array of data points to hash of {genderName: value}

  return genderNames.map((genderName) => ({
    gender: genderName,
    ...valuesByIntakeByGender[genderName]
  })) // array of objects compatible with recharts
}

export default formatGenderDataseries

import React from 'react'
import { Trans } from 'react-i18next'

import getCourseName from 'apps/StudyPlans/utils/getCourseName'

const CoursesNames = ({ courses }) => (
  <>
    {courses?.length > 0 ? (
      courses.map((c) => getCourseName(c)).join(', ')
    ) : (
      <Trans>All courses</Trans>
    )}
  </>
)

export default CoursesNames

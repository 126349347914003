import React from 'react'

import { Text } from '@fullfabric/alma-mater'

import WidgetSection from '../../widget-section'

import styles from './styles.module.scss'

const WidgetTitle = ({ id, title }) => (
  <WidgetSection single>
    <Text
      id={`widget-title-${id}`}
      tag='h2'
      type='h3'
      fontColor='text-base-darkest'
      className={styles.title}
    >
      {title}
    </Text>
  </WidgetSection>
)

export default WidgetTitle

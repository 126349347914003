.react-app ._pageHead_1iqc2_1 {
  width: 100%;
  border-bottom: none;
  padding-bottom: 0;
  margin-bottom: var(--space-tiny);
}
.react-app ._pageHead_1iqc2_1 p {
  margin-top: var(--space-tiny);
  margin-bottom: var(--space-tiny);
  color: var(--ff-color-primary-darker);
}

.react-app ._cookiePolicy_1iqc2_13 {
  background: var(--ff-bg-base-lighter);
  padding-bottom: var(--space-huge);
}
.react-app ._cookiePolicy_1iqc2_13 p._cookiePolicyContent_1iqc2_17 {
  color: var(--ff-color-primary-darker);
}
.react-app ._cookiePolicy_1iqc2_13 p._cookiePolicyContent_1iqc2_17 b {
  font-weight: 600;
}
import React from 'react'
import { Trans } from 'react-i18next'
import { useMutation } from 'react-query'

import { ActionList, MoreIcon } from '@fullfabric/alma-mater'

import createElectiveGroup from 'apps/StudyPlans/api/createElectiveGroup'
import DeleteElectiveGroupModal from 'apps/StudyPlans/components/SubjectGroupsList/DeleteElectiveGroupModal'
import ElectiveSubjectsSettingsModal from 'apps/StudyPlans/components/SubjectGroupsList/ElectiveSubjectsSettingsModal'
import RenameElectiveGroupModal from 'apps/StudyPlans/components/SubjectGroupsList/RenameElectiveGroupModal'
import { useSelectedPath } from 'apps/StudyPlans/contexts/SelectedPath'
import {
  useSetStudyPlan,
  useStudyPlan
} from 'apps/StudyPlans/contexts/StudyPlanData'
import replaceStudyPlanPath from 'apps/StudyPlans/utils/replaceStudyPlanPath'

import styles from './styles.module.scss'

const OpenGroupSettingsAction = ({ electiveGroup, multipleElectiveGroups }) => {
  const studyPlan = useStudyPlan()
  const setStudyPlan = useSetStudyPlan()
  const selectedPath = useSelectedPath()

  const [settingsModalOpen, setSettingsModalOpen] = React.useState(false)
  const [renameModalOpen, setRenameModalOpen] = React.useState(false)
  const [deleteModalOpen, setDeleteModalOpen] = React.useState(false)

  const { mutate: createGroupMutation } = useMutation(
    async ({ studyPlanId, pathId }) => {
      return await createElectiveGroup(studyPlanId, pathId)
    },
    {
      onSuccess: (updatedPath) => {
        const updatedStudyPlan = replaceStudyPlanPath(studyPlan, updatedPath)
        setStudyPlan(updatedStudyPlan)
      }
    }
  )
  const onAddElectiveGroupClick = React.useCallback(() => {
    createGroupMutation({ studyPlanId: studyPlan.id, pathId: selectedPath.id })
  }, [studyPlan, selectedPath, createGroupMutation])

  return (
    <>
      <ActionList
        iconButton={<MoreIcon />}
        iconButtonProps={{
          basic: true,
          'data-testid': 'elective-options-button'
        }}
        alignEnd
      >
        <ActionList.Option
          className={styles.actionOption}
          onClick={() => setSettingsModalOpen(true)}
        >
          <Trans>Elective group settings</Trans>
        </ActionList.Option>
        <ActionList.Option
          className={styles.actionOption}
          onClick={onAddElectiveGroupClick}
        >
          <Trans>Add elective group</Trans>
        </ActionList.Option>
        {multipleElectiveGroups && (
          <ActionList.Option
            className={styles.actionOption}
            onClick={() => setRenameModalOpen(true)}
          >
            <Trans>Rename elective group</Trans>
          </ActionList.Option>
        )}
        {multipleElectiveGroups && (
          <ActionList.Option
            className={styles.actionOption}
            onClick={() => setDeleteModalOpen(true)}
          >
            <Trans>Delete elective group</Trans>
          </ActionList.Option>
        )}
      </ActionList>
      <ElectiveSubjectsSettingsModal
        isOpen={settingsModalOpen}
        onCancel={() => setSettingsModalOpen(false)}
        electiveGroup={electiveGroup}
      />
      <RenameElectiveGroupModal
        isOpen={renameModalOpen}
        onCancel={() => setRenameModalOpen(false)}
        electiveGroup={electiveGroup}
      />
      <DeleteElectiveGroupModal
        isOpen={deleteModalOpen}
        onCancel={() => setDeleteModalOpen(false)}
        electiveGroup={electiveGroup}
      />
    </>
  )
}

export default OpenGroupSettingsAction

import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '@fullfabric/alma-mater'

import CreationModal from './CreationModal'

const CreateEvaluationButton = ({ onCreated }) => {
  const { t } = useTranslation()
  const [isCreationModalOpen, setCreationModalOpen] = useState(false)

  return (
    <>
      <Button primary size='small' onClick={() => setCreationModalOpen(true)}>
        {t('Create new evaluation')}
      </Button>

      {isCreationModalOpen ? (
        <CreationModal
          isOpen={isCreationModalOpen}
          handleClose={() => setCreationModalOpen(false)}
          onSubmitted={onCreated}
        />
      ) : null}
    </>
  )
}

export default CreateEvaluationButton

import * as React from 'react'

import { identity, pickBy } from 'lodash'

import {
  accept as acceptPolicy,
  hasLocalAcceptance as hasLocalPolicyAcceptance,
  submitLocalAcceptance as submitLocalPolicyAcceptance
} from '@fullfabric/policies'

import hasPreviouslyReplied from './has-previously-replied'
import shouldShowBanner from './should-show-banner'

function useCookiePrompt(cookiePolicy, user, userIsStaff, onAccepted = null) {
  const policyId = cookiePolicy?.id

  const [isPromptShowing, setIsPromptShowing] = React.useState(
    shouldShowBanner(hasLocalPolicyAcceptance(policyId), user, userIsStaff)
  )

  React.useEffect(() => {
    // needed if user or policy is loaded after first render
    setIsPromptShowing(
      shouldShowBanner(hasLocalPolicyAcceptance(policyId), user, userIsStaff)
    )
  }, [policyId, user, userIsStaff])

  React.useEffect(() => {
    const nonStaffUserIsLoggedIn = !!user && !userIsStaff

    if (
      nonStaffUserIsLoggedIn &&
      !hasPreviouslyReplied(user) &&
      hasLocalPolicyAcceptance(policyId)
    ) {
      submitLocalPolicyAcceptance(policyId, user.id)
    }
  }, [user, policyId, userIsStaff])

  const onAcceptCookies = React.useCallback(() => {
    setIsPromptShowing(false)

    acceptPolicy(policyId, {
      policyOptions: pickBy(cookiePolicy?.cookie_types, identity),
      profileId: user?.id
    })

    onAccepted?.()
  }, [user, cookiePolicy, policyId, onAccepted])

  return { isPromptShowing, onAcceptCookies }
}

export default useCookiePrompt

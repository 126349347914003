import React from 'react'
import { Trans } from 'react-i18next'

import { Button } from '@fullfabric/alma-mater'

import { useImportDocument } from 'apps/Imports/contexts/import-document'
import { useSelectedData } from 'apps/Imports/contexts/selected-data'
import { useStepsCompleted, useStepState } from 'apps/Imports/contexts/steps'
import { useDataProcessingPolicy } from 'apps/Imports/hooks/useDataProcessingPolicy'
import { useImportContext } from 'apps/Imports/hooks/useImportContext'
import { useMarketingPolicy } from 'apps/Imports/hooks/useMarketingPolicy'
import { PROFILE_IMPORT_STEPS } from 'apps/Imports/utils/profile-import/constants'
import { areStepsCompleted } from 'apps/Imports/utils/profile-import/is-step-completed'
import useGoToStep from '../hooks/use-go-to-step'

import styles from '../styles.module.scss'

const BackToSummaryButton = () => {
  const { data } = useSelectedData()
  const context = useImportContext()
  const marketingPolicy = useMarketingPolicy()
  const dataProcessingPolicy = useDataProcessingPolicy()
  const { currentStep } = useStepState()
  const { isStepUnlocked, previousStepsFrom } = useStepsCompleted()
  const { goToStepPage } = useGoToStep()
  const importDocument = useImportDocument()

  const onBackToSummary = React.useCallback(() => {
    goToStepPage(PROFILE_IMPORT_STEPS.SUMMARY)
  }, [goToStepPage])

  const summaryDisabled = !areStepsCompleted(
    previousStepsFrom(PROFILE_IMPORT_STEPS.SUMMARY),
    data,
    { context, marketingPolicy, dataProcessingPolicy, importDocument }
  )

  if (!isStepUnlocked(PROFILE_IMPORT_STEPS.SUMMARY)) return null
  if (currentStep === PROFILE_IMPORT_STEPS.SUMMARY) return null

  return (
    <Button
      primary
      disabled={summaryDisabled}
      onClick={onBackToSummary}
      className={styles.actionButtons}
    >
      <Trans>Back to Summary</Trans>
    </Button>
  )
}

export default BackToSummaryButton

import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import moment from 'moment'

import { BoxShadow } from '@fullfabric/alma-mater'

import capitalizeString from 'apps/MergeProfiles/utils/capitalizeString'
import AssociatedJourneyInfoRow from './AssociatedJourneyInfoRow'
import CardActions from './CardActions'
import CardInfoRow from './CardInfoRow'
import CardTitle from './CardTitle'
import FeeInfoRow from './FeeInfoRow'

import classNames from 'classnames'
import styles from './styles.module.scss'

const ApplicationCard = ({
  applic,
  profile,
  profileType,
  summary,
  summaryType
}) => {
  const { t, i18n } = useTranslation()

  const applicName = `${applic.context_name} ${applic.template_name}`
  const lastUpdatedAt =
    applic.last_updated_at &&
    moment(applic.last_updated_at).locale(i18n.language)
  const deadline =
    applic.deadline && moment(applic.deadline).locale(i18n.language)
  const submittedAt =
    applic.submitted_at && moment(applic.submitted_at).locale(i18n.language)

  return (
    <BoxShadow
      type={summary ? '0' : '2'}
      rounded={!summary}
      bgColor='base-lightest'
      className={classNames(
        styles.applicCard,
        summaryType === 'added' && styles.applicAdded,
        summaryType === 'discarded' && styles.applicDiscarded
      )}
      role='cell'
      aria-label={
        summary
          ? `${
              summaryType ? summaryType + ' ' : ''
            }application summary for ${applicName}`
          : `${profileType} profile application for ${applicName}`
      }
    >
      <CardTitle title={applicName} big={summary} summaryType={summaryType} />

      <div className={styles.cardInfoRegion}>
        <CardInfoRow
          title={<Trans>State</Trans>}
          value={capitalizeString(applic.state)}
          secondaryValue={
            applic.state === 'started'
              ? t('{{completion}}% completed', {
                  completion: applic.completion_rate
                })
              : submittedAt?.format('LL')
          }
        />
        <AssociatedJourneyInfoRow applic={applic} profile={profile} />
        <CardInfoRow
          title={<Trans>Activity</Trans>}
          value={lastUpdatedAt?.fromNow()}
          secondaryValue={lastUpdatedAt?.format('LL')}
        />
        <CardInfoRow
          title={<Trans>Deadline</Trans>}
          value={deadline?.fromNow()}
          secondaryValue={deadline?.format('LL')}
        />
        <FeeInfoRow applic={applic} />
      </div>

      {!summary && (
        <CardActions
          applic={applic}
          profile={profile}
          profileType={profileType}
        />
      )}
    </BoxShadow>
  )
}

export default ApplicationCard

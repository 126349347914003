import React from 'react'

import { useCurrentUser } from '@fullfabric/authorization-officer'

import ColumnStore from 'apps/Financial/services/ColumnStore'

const useStoredHiddenColumns = (defaultHiddenColumns = []) => {
  const user = useCurrentUser()

  const onHiddenColumnsChanged = React.useCallback(
    (hiddenColumns, visibleColumns) => {
      ColumnStore.setStoredColumnsForUser(
        user.id,
        hiddenColumns,
        visibleColumns
      )
    },
    [user.id]
  )

  const hiddenColumns =
    ColumnStore.getStoredHiddenColumnsForUser(user.id) || defaultHiddenColumns

  return { onHiddenColumnsChanged, hiddenColumns }
}

export default useStoredHiddenColumns

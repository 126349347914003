import React from 'react'

import { ClassOptionsProvider } from './ClassOptionsContext'
import DemographicsChartsRow from './DemographicsChartsRow'
import { FilterProvider } from './FilterContext'
import IntakesOverviewChart from './IntakesOverviewChart'
import NationalityDistributionChart from './NationalityDistributionChart'
import StatsWidgetsRow from './StatsWidgetsRow'
import WarningMessage from './WarningMessage'

function ProductOverviewReport({ filter, baseClass, classOptions }) {
  return (
    <FilterProvider filter={filter}>
      <ClassOptionsProvider baseClass={baseClass} allClasses={classOptions}>
        <WarningMessage />
        <StatsWidgetsRow />
        <IntakesOverviewChart />
        <NationalityDistributionChart />
        <DemographicsChartsRow />
      </ClassOptionsProvider>
    </FilterProvider>
  )
}

export default ProductOverviewReport

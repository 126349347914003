import 'css-chunk:src/apps/Imports/components/mapping-step/all-fields-section/styles.module.scss';export default {
  "react-app": "react-app",
  "fieldArrowIcon": "_fieldArrowIcon_1rjt7_1",
  "requiredFieldColData": "_requiredFieldColData_1rjt7_7",
  "allFieldsSection": "_allFieldsSection_1rjt7_11",
  "allFieldsList": "_allFieldsList_1rjt7_15",
  "allFieldsHeaderItem": "_allFieldsHeaderItem_1rjt7_19",
  "allFieldsColData": "_allFieldsColData_1rjt7_20",
  "samplesColumnHeader": "_samplesColumnHeader_1rjt7_24",
  "samplesColumnText": "_samplesColumnText_1rjt7_27",
  "allFieldsCsvFieldCol": "_allFieldsCsvFieldCol_1rjt7_33",
  "arrow": "_arrow_1rjt7_40"
};
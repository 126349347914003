import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '@fullfabric/alma-mater'
import { IfPermit } from '@fullfabric/authorization-officer'

import CreationModal from './CreationModal'

export default function CreateSegmentButton() {
  const { t } = useTranslation()
  const [isCreationModalOpen, setCreationModalOpen] = useState(false)

  return (
    <IfPermit to='core.segments.shared_segments_change'>
      <Button primary size='small' onClick={() => setCreationModalOpen(true)}>
        {t('Create a new segment')}
      </Button>

      {isCreationModalOpen && (
        <CreationModal
          isOpen={isCreationModalOpen}
          handleClose={() => setCreationModalOpen(false)}
        />
      )}
    </IfPermit>
  )
}

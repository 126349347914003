import React from 'react'

import { Table, Text } from '@fullfabric/alma-mater'

import classNames from 'classnames'
import styles from './styles.module.scss'

const HeaderItem = ({ align, widest, minimizeWidth, children, ...props }) => (
  <Table.HeaderItem
    className={classNames(
      styles.headerItem,
      minimizeWidth && styles.minimizeWidth,
      widest && styles.widest,
      align === 'right' && styles.alignRight
    )}
    {...props}
  >
    <Text tag='span' type='h6' fontColor='text-base-darker'>
      {children}
    </Text>
  </Table.HeaderItem>
)

export default HeaderItem

import React from 'react'
import { useTranslation } from 'react-i18next'

import { Text } from '@fullfabric/alma-mater'

import styles from './styles.module.scss'

function LineGraphPlaceholder() {
  const { t } = useTranslation()

  return (
    <div className={styles.placeholderContainer}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='972'
        height='322'
        fill='none'
        viewBox='0 0 972 322'
        preserveAspectRatio='none'
        role='img'
        aria-labelledby='lineGraphPlaceholderTitle'
      >
        <title id='lineGraphPlaceholderTitle'>
          Graph placeholder for error message
        </title>
        <path fill='#D8DDE2' d='M24 305H972V306H24z'></path>
        <path fill='#EDF0F3' d='M24 55H972V56H24z'></path>
        <path fill='#EDF0F3' d='M24 105H972V106H24z'></path>
        <path fill='#EDF0F3' d='M24 155H972V156H24z'></path>
        <path fill='#EDF0F3' d='M24 205H972V206H24z'></path>
        <path fill='#EDF0F3' d='M24 255H972V256H24z'></path>
        <path fill='#D8DDE2' d='M68 306H69V310H68z'></path>
        <path fill='#D8DDE2' d='M146 306H147V310H146z'></path>
        <path fill='#D8DDE2' d='M224 306H225V310H224z'></path>
        <path fill='#D8DDE2' d='M302 306H303V310H302z'></path>
        <path fill='#D8DDE2' d='M380 306H381V310H380z'></path>
        <path fill='#D8DDE2' d='M458 306H459V310H458z'></path>
        <path fill='#D8DDE2' d='M536 306H537V310H536z'></path>
        <path fill='#D8DDE2' d='M614 306H615V310H614z'></path>
        <path fill='#D8DDE2' d='M692 306H693V310H692z'></path>
        <path fill='#D8DDE2' d='M770 306H771V310H770z'></path>
        <path fill='#D8DDE2' d='M848 306H849V310H848z'></path>
        <path fill='#D8DDE2' d='M926 306H927V310H926z'></path>
        <rect width='15' height='8' x='61' y='314' fill='#EDF0F3' rx='2'></rect>
        <rect
          width='15'
          height='8'
          x='139'
          y='314'
          fill='#EDF0F3'
          rx='2'
        ></rect>
        <rect
          width='15'
          height='8'
          x='217'
          y='314'
          fill='#EDF0F3'
          rx='2'
        ></rect>
        <rect
          width='15'
          height='8'
          x='295'
          y='314'
          fill='#EDF0F3'
          rx='2'
        ></rect>
        <rect
          width='15'
          height='8'
          x='373'
          y='314'
          fill='#EDF0F3'
          rx='2'
        ></rect>
        <rect
          width='15'
          height='8'
          x='451'
          y='314'
          fill='#EDF0F3'
          rx='2'
        ></rect>
        <rect
          width='15'
          height='8'
          x='529'
          y='314'
          fill='#EDF0F3'
          rx='2'
        ></rect>
        <rect
          width='15'
          height='8'
          x='607'
          y='314'
          fill='#EDF0F3'
          rx='2'
        ></rect>
        <rect
          width='15'
          height='8'
          x='685'
          y='314'
          fill='#EDF0F3'
          rx='2'
        ></rect>
        <rect
          width='15'
          height='8'
          x='763'
          y='314'
          fill='#EDF0F3'
          rx='2'
        ></rect>
        <rect
          width='15'
          height='8'
          x='841'
          y='314'
          fill='#EDF0F3'
          rx='2'
        ></rect>
        <rect
          width='15'
          height='8'
          x='919'
          y='314'
          fill='#EDF0F3'
          rx='2'
        ></rect>
        <path
          stroke='#E9E2F8'
          strokeWidth='2'
          d='M63.5 243.5l77.5-71 78 24 78-43 78-40.5 78-3 78-22h156l78.5-32.5'
        ></path>
        <path
          stroke='#DCEEFB'
          strokeWidth='2'
          d='M63.5 135.5l77.5 71 78-64 78 43 78-39.5 78 3 78-18 77.5 64H687l78.5-24h77l78-76.5'
        ></path>
        <rect width='14' height='8' x='1' y='46' fill='#EDF0F3' rx='2'></rect>
        <rect width='14' height='8' x='1' y='102' fill='#EDF0F3' rx='2'></rect>
        <rect width='14' height='8' x='1' y='149' fill='#EDF0F3' rx='2'></rect>
        <rect width='14' height='8' x='1' y='197' fill='#EDF0F3' rx='2'></rect>
        <rect width='14' height='8' x='1' y='245' fill='#EDF0F3' rx='2'></rect>
        <rect width='14' height='8' x='1' y='295' fill='#EDF0F3' rx='2'></rect>
        <rect width='9' height='11' fill='#EDF0F3' rx='2'></rect>
        <rect width='35' height='11' x='13' fill='#EDF0F3' rx='2'></rect>
        <rect width='32' height='11' x='52' fill='#EDF0F3' rx='2'></rect>
        <rect width='63' height='11' x='100' fill='#EDF0F3' rx='2'></rect>
        <rect width='9' height='11' x='167' fill='#EDF0F3' rx='2'></rect>
        <rect width='35' height='11' x='180' fill='#EDF0F3' rx='2'></rect>
        <rect width='35' height='11' x='231' fill='#EDF0F3' rx='2'></rect>
        <rect width='9' height='11' x='270' fill='#EDF0F3' rx='2'></rect>
        <rect width='32' height='11' x='283' fill='#EDF0F3' rx='2'></rect>
      </svg>
      <Text tag='span' type='h6' className={styles.placeholderText}>
        {t('No data available')}
      </Text>
    </div>
  )
}

export default LineGraphPlaceholder

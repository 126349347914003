export function hideEmail(email) {
  if (!email) {
    return
  }

  const [emailToken, ...org] = email.split('@')

  const toShowLen = Math.min(Math.ceil(emailToken.length / 3), 4)
  const toShow = emailToken.substring(0, toShowLen)
  const hiddenLen = Math.min(Math.ceil(emailToken.length - toShowLen), 4)

  const hiddenPart = '*'.repeat(hiddenLen)

  return `${toShow}${hiddenPart}@${org.join('@')}`
}

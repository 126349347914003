import React from 'react'
import { Trans } from 'react-i18next'
import { Link } from 'react-router-dom'

import { ArrowLeftIcon, Button, PageHead } from '@fullfabric/alma-mater'

import {
  NEW_SUBJECT_ROUTE,
  SOURCE_ROUTE
} from 'apps/Subjects/components/AppRoutes'

import styles from './styles.module.scss'

const SubjectsListPageHeader = () => (
  <PageHead
    noBorder
    className={styles.pageHead}
    title={<Trans>Subjects</Trans>}
    action={
      <Link to={NEW_SUBJECT_ROUTE}>
        <Button size='small' primary>
          <Trans>Add new subject</Trans>
        </Button>
      </Link>
    }
    breadcrumbs={[
      {
        label: <Trans>Back to Institution</Trans>,
        icon: ArrowLeftIcon,
        href: SOURCE_ROUTE,
        target: '_top'
      }
    ]}
  />
)

export default SubjectsListPageHeader

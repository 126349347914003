import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

import DataTable from 'shared/components/DataTable'

import getProductPerformance from 'apps/Reports/api/getProductPerformance'
import useProductPerformanceColumns from 'apps/Reports/hooks/useProductPerformanceColumns'

import styles from './styles.module.scss'

const ProductPerformanceClassDetailRow = ({ classId, chartType }) => {
  const { t } = useTranslation()
  const { data: classProductPerformance, isLoading } = useQuery(
    ['getProductPerformance', classId],
    () => getProductPerformance(classId),
    {
      staleTime: 0,
      refetchOnWindowFocus: false
    }
  )

  const columns = useProductPerformanceColumns({
    chartType
  })

  return (
    <div role='region' aria-label={t('region for class data')}>
      <DataTable
        fixedLayout
        loading={isLoading && 1}
        data={classProductPerformance && [classProductPerformance]}
        columns={columns}
        className={styles.singleRowTable}
      />
    </div>
  )
}

export default ProductPerformanceClassDetailRow

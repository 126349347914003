import 'css-chunk:src/apps/ContentPages/components/fees-table/fee-data-row/styles.module.scss';export default {
  "react-app": "react-app",
  "stateIconColumn": "_stateIconColumn_1p7jd_1",
  "icon": "_icon_1p7jd_4",
  "placeholder": "_placeholder_1p7jd_5",
  "leftmostColumn": "_leftmostColumn_1p7jd_9",
  "amountColumn": "_amountColumn_1p7jd_13",
  "actionsColumn": "_actionsColumn_1p7jd_18",
  "actionsBtn": "_actionsBtn_1p7jd_24",
  "actionsLink": "_actionsLink_1p7jd_29",
  "disabled": "_disabled_1p7jd_29",
  "amountBeforeDiscount": "_amountBeforeDiscount_1p7jd_34"
};
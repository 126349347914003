import React from 'react'
import { useLocation } from 'react-router'

import AuthenticationViaApplic from 'apps/Authentication/components/AuthenticationViaApplic'

export default function AuthenticationViaApplicPage() {
  const location = useLocation()
  const fromMagicLink = location.search?.includes('magic=true')

  return <AuthenticationViaApplic fromMagicLink={fromMagicLink} />
}

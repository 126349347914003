import React, { useCallback } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'

import { Button } from '@fullfabric/alma-mater'

import editSubject from 'apps/Subjects/api/editSubject'
import SubjectBasicsForm from 'apps/Subjects/components/SubjectBasicsForm'
import isExistingCodeError from 'apps/Subjects/utils/isExistingCodeError'

const BasicsEditForm = ({
  subjectId,
  initialData,
  onStartSubmit,
  onSubmitted,
  onNotSubmitted
}) => {
  const { t } = useTranslation()
  const formMethods = useForm({
    defaultValues: initialData
  })
  const queryClient = useQueryClient()
  const editSubjectMutation = useMutation(
    ({ subjectId, formValues }) => {
      onStartSubmit()
      return editSubject(subjectId, formValues)
    },
    {
      onSuccess: (data) => {
        queryClient.setQueryData(['getSubject', subjectId], data)
        onSubmitted(data)
      },
      onError: (e) => {
        if (isExistingCodeError(e)) {
          onNotSubmitted(t('The subject code already exists.'))
        } else {
          onNotSubmitted(
            t(
              'There was an error, please try again in a few seconds. If the issue persists, please contact us.'
            )
          )
        }
      }
    }
  )

  const onSubmit = useCallback(
    async (formValues) => {
      editSubjectMutation.mutate({ subjectId, formValues })
    },
    [editSubjectMutation, subjectId]
  )

  return (
    <FormProvider {...formMethods}>
      <SubjectBasicsForm />
      <Button
        size='small'
        type='button'
        primary
        onClick={formMethods.handleSubmit(onSubmit)}
        loading={editSubjectMutation.isLoading}
      >
        <Trans>Save changes</Trans>
      </Button>
    </FormProvider>
  )
}

export default BasicsEditForm

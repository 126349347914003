.react-app ._requiredFieldsListContainer_u2oev_1 {
  overflow: initial;
}

.react-app ._requiredFieldsHeader_u2oev_5 {
  background-color: var(--ff-color-grey-000);
  padding: var(--space-default);
  margin-bottom: var(--space-small);
}

.react-app ._requiredFieldDropdown_u2oev_11 {
  display: inline-block;
  width: calc(100% - var(--space-small));
}

.react-app ._arrow_u2oev_16 {
  margin-left: var(--space-small);
  vertical-align: middle;
}

.react-app ._requiredFieldColData_u2oev_21 {
  position: relative;
}

.react-app ._requiredFieldsList_u2oev_1 {
  table-layout: fixed;
}
.react-app ._requiredFieldsList_u2oev_1 ._samplesColumnHeader_u2oev_28 {
  width: 40%;
}
.react-app ._requiredFieldsList_u2oev_1 ._samplesColumnText_u2oev_31 {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.react-app ._requiredFieldsList_u2oev_1 ._textCol_u2oev_36 {
  vertical-align: middle;
}
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Field, Text } from '@fullfabric/alma-mater'

import getLocalizedText from 'shared/utils/getLocalizedText'

import styles from './styles.module.scss'

const PrivacyPolicyField = ({ data, setData, errors, privacyPolicy }) => {
  const { i18n } = useTranslation()
  const { language } = i18n

  const policyText = {
    __html: getLocalizedText(privacyPolicy?.locales, language, 'html')
  }

  const setPolicy = (privacyPolicy) => {
    setData({
      ...data,
      policies: {
        [privacyPolicy.id]: {
          accepted: true
        }
      }
    })
  }

  const unsetPolicy = () => {
    setData({ ...data, policies: undefined })
  }

  const policyFieldOpts = [
    { label: <Trans>I accept the privacy policy</Trans>, value: true }
  ]

  return (
    <>
      <Text type='h5' fontColor='text-base-darkest'>
        <Trans>Privacy Policy</Trans>
      </Text>
      <Text
        type='f6'
        fontColor='text-base-darker'
        className={styles.spaceAround}
        dangerouslySetInnerHTML={policyText}
        marginBottom='small'
      />
      <Field
        type='checkbox'
        id='policy_acceptance'
        onChange={(id, value) => {
          if (value) {
            setPolicy(privacyPolicy)
          } else {
            unsetPolicy()
          }
        }}
        value={!!data.policies}
        inputOptions={policyFieldOpts}
        error={
          errors && <Trans>Please accept the privacy policy to continue</Trans>
        }
      />
    </>
  )
}

export default PrivacyPolicyField

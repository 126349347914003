import { utils } from '@fullfabric/public-api'

/**
 * Generates a new Anti-Spam digest for an event and refreshes it's request ID
 * on the current page.
 *
 * Events aren't fetched via API, so the only way to do this otherwise would be
 * to refresh the page. That would mean losing the form's state, which is not
 * a great experience, so we do the replacement manually instead.
 *
 * @param {String} eventId An event's ID
 */
export default async function refreshEventDigest(eventId) {
  const metaRequestId = document.querySelector("meta[name='request-id']")
  if (!metaRequestId) return

  const response = await fetch(`/api/events/${eventId}/anti_spam`, {
    method: 'POST',
    credentials: 'same-origin'
  })

  const headers = utils.extractHeaders(response, ['x-request-id'])
  metaRequestId.content = headers['x-request-id']
}

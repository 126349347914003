.react-app ._imageRow_1hopd_1 {
  max-width: 552px;
  margin: 0 auto var(--space-default);
  display: flex;
  gap: var(--space-default);
}

.react-app ._imageCard_1hopd_8 {
  flex: 1;
  border: var(--border-base-lighter);
  overflow: hidden;
  border-radius: 12px;
}
.react-app ._imageCard_1hopd_8 ._imageContainer_1hopd_14 {
  height: 90px;
  border-bottom: var(--border-base-lighter);
}
.react-app ._imageCard_1hopd_8 ._textContainer_1hopd_18 {
  padding: var(--space-default);
}

.react-app ._checkbox_1hopd_22 {
  margin-bottom: 1px !important;
}
.react-app ._profileDetailButtons_1s1xi_1 {
  margin-top: var(--space-more);
  margin-right: var(--space-small);
  margin-bottom: var(--space-huge);
}

.react-app ._sourceCategoryType_1s1xi_7 {
  margin-top: var(--space-small);
}

.react-app ._uniqueSourceCategoryCaption_1s1xi_11 {
  margin-left: 26px;
  margin-top: -16px;
}

.react-app ._sourceCategoryAutocomplete_1s1xi_16 {
  z-index: 11;
}

.react-app ._tags_1s1xi_20 {
  z-index: 10;
}
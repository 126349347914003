import React from 'react'

import { useCookiePromptRef } from 'shared/contexts/CookiePromptRef'
import useCookieBannerHeight from 'shared/hooks/useCookieBannerHeight'

export default function BlankSpaceForCookieBanner() {
  const cookieBannerRef = useCookiePromptRef()
  const cookieBannerHeight = useCookieBannerHeight(cookieBannerRef)

  return <div style={{ height: `${cookieBannerHeight}px` }} />
}

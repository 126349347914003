import React from 'react'
import { Trans } from 'react-i18next'

import { Button } from '@fullfabric/alma-mater'

import { redirectToOffer } from './utils'

const ContinueButton = ({ offer }) => (
  <Button primary onClick={() => redirectToOffer(offer)}>
    <Trans>Continue</Trans>
  </Button>
)

export default ContinueButton

import qs from 'qs'

import { utils } from '@fullfabric/public-api'

const getPaymentPlan = async (profileId, options) => {
  const response = await fetch(
    `/book_keeper/api/profiles/${profileId}/plan?${qs.stringify(options)}`,
    { credentials: 'same-origin' }
  )
  return await utils.checkResponse(response)
}

export default getPaymentPlan

import React from 'react'

const useTableFilter = (
  initialFilterArg,
  filterStorageKey,
  onFilterChanged
) => {
  const storedFilter =
    filterStorageKey && localStorage.getItem(filterStorageKey)
  const initialFilter =
    initialFilterArg || (storedFilter && JSON.parse(storedFilter))

  const [filterState, setFilterState] = React.useState(initialFilter || {})

  React.useEffect(() => {
    onFilterChanged && onFilterChanged(filterState)
  }, [filterState, onFilterChanged])

  const handleFilterChange = React.useCallback(
    (updatedFilter) => {
      setFilterState(updatedFilter)

      filterStorageKey &&
        localStorage.setItem(
          filterStorageKey,
          JSON.stringify(updatedFilter || {})
        )
    },
    [filterStorageKey]
  )

  return { handleFilterChange, filterState }
}

export default useTableFilter

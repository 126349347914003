import React from 'react'

const ArrowIcon = () => (
  <svg
    width='4'
    height='6'
    viewBox='0 0 4 6'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path d='M4 3L0 0V6L4 3Z' fill='#D22A0C' />
  </svg>
)

export default ArrowIcon

const RANGES = [
  [0, 17, '< 18'],
  [18, 24, '18-24'],
  [25, 34, '25-34'],
  [35, 44, '35-44'],
  [45, 54, '45-54'],
  [55, null, '> 54']
]

const RANGE_LABEL = 2

const formatAgeDataseries = (ageDistributionInIntakes, intakesData) => {
  const countPerRangePerIntake = ageDistributionInIntakes.reduce(
    (byIntake, distributionInIntake, intakeIndex) => {
      const countPerRange = Object.keys(distributionInIntake).reduce(
        (reduced, ageAsString) => {
          const ageAsNumber = Number.parseInt(ageAsString, 10)
          const targetRange = RANGES.find(([min, max]) =>
            max ? ageAsNumber >= min && ageAsNumber <= max : ageAsNumber >= min
          )

          if (!targetRange) {
            return reduced
          }

          const targetRangeLabel = targetRange[RANGE_LABEL]

          const countForAge = Object.values(
            distributionInIntake[ageAsString]
          ).reduce((acc, curr) => acc + curr, 0)

          return {
            ...reduced,
            [targetRangeLabel]: reduced[targetRangeLabel] + countForAge
          }
        },
        initialCountByRange
      )

      const targetIntakeName = intakesData[intakeIndex]?.name

      return { ...byIntake, [targetIntakeName]: countPerRange }
    },
    {}
  )

  return RANGES.map(([min, max, rangeName]) => {
    const countPerIntake = intakesData.reduce((reduced, intakeData) => {
      const intakeName = intakeData.name
      return {
        ...reduced,
        [intakeName]: countPerRangePerIntake[intakeName][rangeName]
      }
    }, {})

    return {
      range: rangeName,
      ...countPerIntake
    }
  })
}

const initialCountByRange = RANGES.reduce(
  (hashed, [min, max, label]) => ({ ...hashed, [label]: 0 }),
  {}
)

export default formatAgeDataseries

import React from 'react'

const LeftAlignedXAxisLabel = ({ offset = 8, value, viewBox }) => (
  <text
    class='recharts-text recharts-label'
    x='0'
    y={viewBox.y + viewBox.height + offset}
    text-anchor='start'
  >
    <tspan dy='0.71em' x='0'>
      {value}
    </tspan>
  </text>
)

export default LeftAlignedXAxisLabel

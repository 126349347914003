import React from 'react'
import { useTranslation } from 'react-i18next'

import AutoloadDataTable from 'shared/components/AutoloadDataTable'

import AssigneeCell from './AssigneeCell'
import CreatedByCell from './CreatedByCell'
import EvaluationNameCell from './EvaluationNameCell'
import ProductChoiceCell from './ProductChoiceCell'
import StatusCell from './StatusCell'

const EvaluationsTable = ({ applicationId }) => {
  const { t } = useTranslation()

  return (
    <AutoloadDataTable
      fetchURL={'/evaluations/api/evaluations'}
      fetchParams={{
        rules: {
          values: {
            type: '::Segments::Rules::Evaluations::WithApplication',
            value: applicationId
          }
        },
        sort: {
          field: 'created_at',
          direction: -1
        }
      }}
      emptyMessage={t('No data')}
      columns={[
        {
          Header: t('Evaluation template'),
          accessor: 'evaluation.name',
          Cell: EvaluationNameCell
        },
        {
          Header: t('Status'),
          accessor: 'evaluation.state',
          Cell: StatusCell
        },
        {
          Header: t('Created by'),
          accessor: 'evaluation.created_by_name',
          Cell: CreatedByCell
        },
        {
          Header: t('Assignee'),
          accessor: 'evaluation.assigned_to_name',
          Cell: AssigneeCell
        },
        {
          Header: t('Product choice'),
          accessor: 'evaluation.choice_class_of_name',
          Cell: ProductChoiceCell
        }
      ]}
    />
  )
}

export default EvaluationsTable

import React from 'react'
import { Trans } from 'react-i18next'

import useFetchedImportData from 'apps/Imports/components/import-report-step/hooks/use-fetched-import-data'
import ReportSummaryField from '../../report-summary-field'

const ProfileDetailsSummary = () => {
  const { document } = useFetchedImportData()

  const sourceProcessor = document?.processors?.find(
    ({ type }) => type === 'ImportService::Processors::Source'
  )
  const sourceCategory = sourceProcessor?.options?.category

  const tagsProcessor = document?.processors?.find(
    ({ type }) => type === 'ImportService::Processors::Tags'
  )
  const tags = tagsProcessor?.options?.tag || []

  return (
    <>
      {sourceCategory ? (
        <ReportSummaryField
          label={<Trans>Source category</Trans>}
          value={sourceCategory}
          marginTop='more'
        />
      ) : null}

      {tags.length > 0 ? (
        <ReportSummaryField
          label={<Trans>Tags</Trans>}
          value={tags.join(', ')}
          marginTop={!sourceCategory ? 'more' : 'small'}
        />
      ) : null}
    </>
  )
}

export default ProfileDetailsSummary

export const SINGLE_VALUE_TYPES = [
  'Schemable::Fields::Types::SingleLineText',
  'Schemable::Fields::Types::Number',
  'Schemable::Fields::Types::ParagraphText',
  'Schemable::Fields::Types::SequentialId',
  'Schemable::Fields::Types::Country',
  'Schemable::Fields::Types::DateTime',
  'Schemable::Fields::Types::Date',
  'Schemable::Fields::Types::Email'
]

export const MULTI_VALUE_1D_TYPES = [
  'Schemable::Fields::Types::CheckBox',
  'Schemable::Fields::Types::Address',
  'Schemable::Fields::Types::CascadingDropDown'
]

export const ALLOWED_FIELD_TYPES = [
  ...SINGLE_VALUE_TYPES,
  ...MULTI_VALUE_1D_TYPES,
  'Schemable::Fields::Types::File',
  'Schemable::Fields::Types::DropDown'
]

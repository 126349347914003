import assert from 'assert'

import { utils } from '@fullfabric/public-api'

const createCourse = async (formData) => {
  assert.ok(
    formData instanceof FormData,
    'formData must be provided and be a FormData instance'
  )

  const response = await fetch('/api/courses', {
    credentials: 'same-origin',
    method: 'POST',
    body: formData
  })

  return await utils.checkResponse(response)
}

export default createCourse

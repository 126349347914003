import React from 'react'
import { useTranslation } from 'react-i18next'

import { Table, Text } from '@fullfabric/alma-mater'

import Plural from 'shared/components/Plural'

import styles from './styles.module.scss'

export const Description = ({ entry }) => {
  const [t] = useTranslation()

  let description = entry.description
  if (entry.type === 'email') {
    description = (
      <div className={styles.profileDescription}>
        <Text fontColor='text-base-darkest'>{entry.subject}</Text>
        <Text>{t('From:', { from: entry.from })}</Text>
        <Text>{t('To:', { to: entry.to })}</Text>
      </div>
    )
  } else if (entry.type === 'workflow_stat') {
    description = (
      <span>
        <Plural count={entry.total} singular='workflow' plural='workflows' />{' '}
        <Text>
          {t('Running for', {
            description: entry.description,
            profiles: entry.profile_samples.join(', ')
          })}
        </Text>
      </span>
    )
  }

  return <Table.Data>{description}</Table.Data>
}

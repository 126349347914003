import React from 'react'

const ScannerTarget = ({ className }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='360'
    height='360'
    viewBox='0 0 360 360'
    className={className}
  >
    <g fill='#9E9E9E' fillOpacity='.5' fillRule='evenodd'>
      <path d='M340 0h-71a5 5 0 0 0-5 5v14a5 5 0 0 0 5 5h62a5 5 0 0 1 5 5v62a5 5 0 0 0 5 5h14a5 5 0 0 0 5-5V20c0-11.046-8.954-20-20-20zM20 0h71a5 5 0 0 1 5 5v14a5 5 0 0 1-5 5H29a5 5 0 0 0-5 5v62a5 5 0 0 1-5 5H5a5 5 0 0 1-5-5V20C0 8.954 8.954 0 20 0zM20 360h71a5 5 0 0 0 5-5v-14a5 5 0 0 0-5-5H29a5 5 0 0 1-5-5v-62a5 5 0 0 0-5-5H5a5 5 0 0 0-5 5v71c0 11.046 8.954 20 20 20zM340 360h-71a5 5 0 0 1-5-5v-14a5 5 0 0 1 5-5h62a5 5 0 0 0 5-5v-62a5 5 0 0 1 5-5h14a5 5 0 0 1 5 5v71c0 11.046-8.954 20-20 20z' />
    </g>
  </svg>
)

export default ScannerTarget

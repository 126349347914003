.react-app ._widgetContainer_m46kv_1 {
  margin-top: var(--space-more);
}

.react-app ._title_m46kv_5 {
  text-transform: uppercase;
}

.react-app ._cardRow_m46kv_9 {
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
  width: 100%;
  overflow-x: auto;
  padding: var(--space-more) var(--space-small);
  padding-top: var(--space-small);
  scrollbar-width: none;
}
.react-app ._cardRow_m46kv_9::-webkit-scrollbar {
  display: none;
}
.react-app ._cardRow_m46kv_9 ._cardContainer_m46kv_22 {
  padding-right: var(--space-small);
  flex: 0 0 auto;
  width: calc(100vw - 32px);
  max-width: 375px;
}
.react-app ._cardRow_m46kv_9 ._cardContainer_m46kv_22._singleCard_m46kv_28 {
  width: 100%;
  padding: 0;
}
.react-app ._cardRow_m46kv_9 ._card_m46kv_9 {
  width: 100%;
}

.react-app ._card_m46kv_9 {
  box-shadow: var(--shadow-3);
  border-radius: 4px;
  border: var(--border-base-lighter);
  background-color: var(--ff-bg-base-lightest);
}
.react-app ._header_3xddw_1 {
  padding: var(--space-more);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.react-app ._header_3xddw_1._mobile_3xddw_8 {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--space-default) var(--space-less);
}

.react-app ._title_3xddw_15 {
  display: inline-flex;
  align-items: center;
}

.react-app ._mobile_3xddw_8 ._title_3xddw_15 {
  flex-direction: column;
  align-items: flex-start;
}

.react-app ._loadingBlock_3xddw_25 {
  height: 30px;
  width: 260px;
}

.react-app ._loadingBlock_3xddw_25._mobile_3xddw_8 {
  height: 24px;
  width: 230px;
}
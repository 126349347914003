import React from 'react'
import { Trans } from 'react-i18next'

import { CheckboxInput, RadioInput, Text } from '@fullfabric/alma-mater'

import { useSelectedData } from 'apps/Imports/contexts/selected-data'
import { useDataProcessingPolicy } from 'apps/Imports/hooks/useDataProcessingPolicy'
import { useMarketingPolicy } from 'apps/Imports/hooks/useMarketingPolicy'

import styles from './styles.module.scss'

const MarketingPolicySection = () => {
  const {
    data: { consentData },
    onDataChanged
  } = useSelectedData()
  const marketingPolicy = useMarketingPolicy()
  const dataProcessingConsent = useDataProcessingPolicy()

  const onConsentDataChanged = React.useCallback(
    (data) => {
      onDataChanged('consentData', { ...consentData, ...data })
    },
    [onDataChanged, consentData]
  )

  const marketingChannels = marketingPolicy?.channels || {}
  const marketingAgreement = consentData.marketingAgreement
  const marketingOptions = consentData.marketingOptions
  const disabled =
    dataProcessingConsent?.published && !consentData.dataProcessingConsent

  const disabledTextColor = disabled ? 'text-base-darker' : 'text-base-darkest'
  const channelsDisabled = disabled || marketingAgreement !== 'accepted'
  const channelsDisabledTextColor = channelsDisabled
    ? 'text-base-darker'
    : 'text-base-darkest'

  if (!marketingPolicy.published) return null
  if (consentData.consentType !== 'same') return null

  return (
    <>
      <Text
        type='h4'
        marginBottom='small'
        marginTop='more'
        fontColor={disabledTextColor}
      >
        <Trans>Marketing Policy</Trans>
      </Text>
      <Text marginBottom='small' fontColor={disabledTextColor}>
        <Trans>
          Indicate these persons' consent for being contacted with marketing
          materials
        </Trans>
      </Text>

      <RadioInput
        name='marketingOption'
        id='noNewAgreement'
        disabled={disabled}
        className={styles.marketingOptionsRadiobuttons}
        checked={marketingAgreement === 'no'}
        onChange={() => onConsentDataChanged({ marketingAgreement: 'no' })}
        label={
          <Text fontColor={disabledTextColor}>
            <Trans>No new agreement made</Trans>
          </Text>
        }
      />

      <RadioInput
        name='marketingOption'
        id='doesNotWant'
        key='doesNotWantRadio'
        disabled={disabled}
        className={styles.marketingOptionsRadiobuttons}
        checked={marketingAgreement === 'doesNotWant'}
        onChange={() =>
          onConsentDataChanged({ marketingAgreement: 'doesNotWant' })
        }
        label={
          <Text fontColor={disabledTextColor}>
            <Trans>Does not want to receive marketing materials</Trans>
          </Text>
        }
      />

      <RadioInput
        name='marketingOption'
        id='wantsMarketing'
        key='wantsMarketingRadio'
        disabled={disabled}
        className={styles.marketingOptionsRadiobuttons}
        onChange={() =>
          onConsentDataChanged({ marketingAgreement: 'accepted' })
        }
        checked={marketingAgreement === 'accepted'}
        label={
          <Text fontColor={disabledTextColor}>
            <Trans>Would like to be contacted by:</Trans>
          </Text>
        }
      />
      {Object.keys(marketingChannels).map((channel) => {
        return marketingChannels[channel] ? (
          <CheckboxInput
            id={`marketing-${channel}`}
            key={channel}
            disabled={channelsDisabled}
            checked={marketingOptions[channel]}
            className={styles.marketingChannelCheckbox}
            onChange={(checked) =>
              onConsentDataChanged({
                marketingOptions: {
                  ...marketingOptions,
                  [channel]: checked
                }
              })
            }
            label={
              <Text fontColor={channelsDisabledTextColor}>
                {channel === 'email' ? <Trans>Email</Trans> : null}
                {channel === 'phone' ? <Trans>Phone</Trans> : null}
                {channel === 'sms' ? <Trans>SMS</Trans> : null}
                {channel === 'mail' ? <Trans>Mail</Trans> : null}
              </Text>
            }
          />
        ) : null
      })}
    </>
  )
}

export default MarketingPolicySection

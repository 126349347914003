import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import {
  ArrowLeftIcon,
  Breadcrumbs,
  CloseIcon,
  IconButton,
  Row,
  Text
} from '@fullfabric/alma-mater'

import useIsMobileLayout from 'apps/ContentPages/components/payments-page/use-is-payments-mobile-layout'
import DueDateDetail from './due-date-detail'
import FeeStatePill from './fee-state-pill'

import classNames from 'classnames'
import styles from './styles.module.scss'

const Header = ({ fee, state, handleClose }) => {
  const { t } = useTranslation()
  const isMobileLayout = useIsMobileLayout()
  const isPaid = state === 'paid'
  const isOverdue = state === 'overdue'

  return (
    <>
      <div
        className={classNames(
          styles.highlight,
          isOverdue && styles.overdue,
          isPaid && styles.paid
        )}
      />
      <div
        className={classNames(styles.content, isMobileLayout && styles.mobile)}
      >
        <Row
          nogutter
          justify='between'
          align='center'
          className={isMobileLayout ? styles.stackMore : styles.stackDefault}
        >
          {isMobileLayout ? (
            <>
              <Breadcrumbs
                breadcrumbs={[
                  {
                    icon: ArrowLeftIcon,
                    label: t('Back to list'),
                    onClick: handleClose
                  }
                ]}
              />
              <FeeStatePill isPaid={isPaid} isOverdue={isOverdue} />
            </>
          ) : (
            <>
              <Text
                type='h6'
                fontColor='text-base-darker'
                style={{ textTransform: 'uppercase' }}
              >
                <Trans>Fee details</Trans>
              </Text>
              <IconButton plain smaller onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </>
          )}
        </Row>
        <Row
          nogutter
          justify='between'
          align='center'
          className={isMobileLayout ? styles.stackSmall : styles.stackTiny}
        >
          <Text
            type={isMobileLayout ? 'f2' : 'f1'}
            fontColor='text-base-darkest'
          >
            {fee?.description}
          </Text>
          {!isMobileLayout && (
            <FeeStatePill isPaid={isPaid} isOverdue={isOverdue} />
          )}
        </Row>
        <Row
          nogutter
          justify='start'
          align='center'
          className={styles.stackMore}
        >
          <DueDateDetail fee={fee} feeState={state} />
        </Row>
      </div>
    </>
  )
}

export default Header

import React from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '@fullfabric/alma-mater'

import { useEvaluation } from 'apps/Evaluations/context/EvaluationData'
import {
  SUBMISSION_ACTIONS,
  useDispatchSubmissionUpdate
} from 'apps/Evaluations/context/EvaluationFormData'

const UnsubmitEvaluationButton = () => {
  const { isLoadingEvaluationData, canChangeEvaluation } = useEvaluation()
  const { t } = useTranslation()
  const dispatchSubmissionUpdate = useDispatchSubmissionUpdate()

  if (!canChangeEvaluation) return null

  return (
    <Button
      size='small'
      marginRight='more'
      loading={isLoadingEvaluationData}
      onClick={() =>
        dispatchSubmissionUpdate({
          type: SUBMISSION_ACTIONS.CONFIRM_UNSUBMISSION
        })
      }
    >
      {t('Unsubmit evaluation')}
    </Button>
  )
}

export default UnsubmitEvaluationButton

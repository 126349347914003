import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'

import {
  CloseIcon,
  IconButton,
  Link,
  LoadingPlaceholder,
  Row,
  Text
} from '@fullfabric/alma-mater'

import EvaluationActionList from 'apps/Evaluations/components/EvaluationPageHeader/EvaluationActionListButton'
import { useEvaluation } from 'apps/Evaluations/context/EvaluationData'
import {
  SUBMISSION_STATE,
  useEvaluationForm,
  useSubmissionState
} from 'apps/Evaluations/context/EvaluationFormData'
import { useEvaluationProfile } from 'apps/Evaluations/context/EvaluationProfileData'
import DeleteModal from './DeleteModal'
import ReassignModal from './ReassignModal'
import SubmissionModal from './SubmissionModal'
import SubmitEvaluationButton from './SubmitEvaluationButton'
import UnsubmitEvaluationButton from './UnsubmitEvaluationButton'

import styles from './styles.module.scss'

const EvaluationPageHeader = ({
  profileId,
  applicationId,
  formRef,
  showApplication,
  setShowApplication
}) => {
  const { t } = useTranslation()
  const history = useHistory()
  const { isLoadingEvaluationData } = useEvaluation()
  const { data: profileData, isLoading: isLoadingProfile } =
    useEvaluationProfile()
  const { evaluationAssignee } = useEvaluationForm()
  const submissionState = useSubmissionState()
  const { search } = useLocation()

  const [isShowingDeleteModal, setIsShowingDeleteModal] = useState(false)
  const [isShowingReassignModal, setIsShowingReassignModal] = useState(false)

  const searchParams = new URLSearchParams(search)
  const isShowingSubmissionModal =
    submissionState.modal !== SUBMISSION_STATE.MODAL.HIDDEN
  const evaluationTemplateListId = searchParams.get(
    'evaluations-template-list-id'
  )
  const backUrl = evaluationTemplateListId
    ? `/evaluations/templates/${evaluationTemplateListId}/evaluations#${evaluationTemplateListId}`
    : `/evaluations/profiles/${profileId}/applications/${applicationId}/evaluations`

  if (isLoadingProfile || isLoadingEvaluationData) {
    return (
      <div role='heading' aria-level='1' className={styles.pageHead}>
        <Row>
          <LoadingPlaceholder
            height='10px'
            width='30px'
            marginRight='default'
            marginLeft='default'
          />
          <LoadingPlaceholder height='10px' width='200px' />
        </Row>
      </div>
    )
  }

  return (
    <div className={styles.pageHead}>
      <div className={styles.pageLeftHead}>
        <Link withoutStyles href={backUrl} className={styles.closeButton}>
          <IconButton
            plain
            smaller
            onClick={() => {
              history?.goBack()
            }}
          >
            <CloseIcon />
          </IconButton>
        </Link>

        <div role='heading' aria-level='1' className={styles.titleContainer}>
          <Text bold type='h4' fontColor='text-base-darkest' marginLeft='small'>
            {`${t('Evaluation')}: ${profileData?.full_name}`}
          </Text>

          <Text type='f6' marginLeft='small'>
            {evaluationAssignee}
          </Text>
        </div>
      </div>

      <div>
        <EvaluationActionList
          onClickDeleteEvaluation={() => {
            setIsShowingDeleteModal(true)
          }}
          onClickReassignEvaluation={() => {
            setIsShowingReassignModal(true)
          }}
          showApplication={showApplication}
          onShowApplicationClick={setShowApplication}
        />
        <DeleteModal profileId={profileId} applicationId={applicationId} />

        {submissionState.button === SUBMISSION_STATE.BUTTON.UNSUBMIT ? (
          <UnsubmitEvaluationButton />
        ) : (
          <SubmitEvaluationButton formRef={formRef} />
        )}

        {isShowingSubmissionModal ? <SubmissionModal /> : null}

        <DeleteModal
          isOpen={isShowingDeleteModal}
          profileId={profileId}
          applicationId={applicationId}
          onClickClose={() => setIsShowingDeleteModal(false)}
        />

        <ReassignModal
          isOpen={isShowingReassignModal}
          handleClose={() => setIsShowingReassignModal(false)}
        />
      </div>
    </div>
  )
}

export default EvaluationPageHeader

import React from 'react'

import { AnnotatedLayout, Row } from '@fullfabric/alma-mater'

import AddSubjectForm from 'apps/StudyPlans/components/AddSubjectForm'
import { SubjectEditionDataProvider } from 'apps/StudyPlans/contexts/SubjectEditionData'
import AddSubjectButton from './AddSubjectButton'
import PageHeader from './PageHeader'

const AddSubjectPage = () => {
  return (
    <SubjectEditionDataProvider>
      <AnnotatedLayout pageHead={<PageHeader />}>
        <AddSubjectForm />

        <Row nogutter justify='end'>
          <AddSubjectButton />
        </Row>
      </AnnotatedLayout>
    </SubjectEditionDataProvider>
  )
}

export default AddSubjectPage

.react-app ._badgesAligner_1wk5p_1 {
  display: inline-flex;
  flex-wrap: wrap;
}

.react-app ._badgeContainer_1wk5p_6 {
  display: inline-flex;
  flex-wrap: nowrap;
}

.react-app ._badgeSpacing_1wk5p_11 {
  margin-right: var(--space-tiny);
}
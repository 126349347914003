import React from 'react'
import { Trans } from 'react-i18next'

import { PageHead } from '@fullfabric/alma-mater'

import TablePageLayout from 'shared/components/TablePageLayout'

import DelayedJobsTable from './DelayedJobs/DelayedJobsTable'

import styles from './DelayedJobs.module.scss'

export default function DelayedJobs() {
  return (
    <TablePageLayout>
      <PageHead noBorder title={<Trans>Delayed jobs</Trans>} />

      <TablePageLayout.TableSection className={styles.container}>
        <DelayedJobsTable />
      </TablePageLayout.TableSection>
    </TablePageLayout>
  )
}

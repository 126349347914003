/* eslint-disable */

import React, { useState } from 'react'
import { Trans } from 'react-i18next'

import axios from 'axios'
import qs from 'qs'

import { Modal, Text } from '@fullfabric/alma-mater'

import { useAppData } from 'shared/contexts/AppData'

import AuthenticationLiteModalForm from 'apps/Authentication/components/AuthLiteForm'
import PrivacyPolicyField from 'apps/Authentication/components/AuthLiteForm/body/policy'
import {
  useSetStepInAuthentication,
  useStepInAuthentication
} from '../contexts/StepInAuthentication'

import styles from '../styles.module.scss'

const AcceptPolicyModal = ({ data, setData, onAccepted }) => {
  const {
    template_name,
    logo_url,
    authenticated_profile_id: authenticatedProfileId,
    privacy_policy: privacyPolicy
  } = useAppData()
  const step = useStepInAuthentication()
  const setStep = useSetStepInAuthentication()

  const [errors, setErrors] = useState({
    profile: {}
  })

  const onSubmit = async ({ onError }) => {
    const { policies } = data
    let queryData = `auth_key=${encodeURIComponent(authenticatedProfileId)}`

    if (!privacyPolicy || !policies || !policies[privacyPolicy.id]) {
      setErrors({ ...errors, privacy: true })
      onError()
      return
    }

    queryData += `&${qs.stringify({ policies })}`
    queryData += `&acceptor_type=Profiles::Profile`
    queryData += `&acceptor_id=${authenticatedProfileId}`
    queryData += `&source_type=Policies::Source::Signin`

    // taken from authentication app
    const requestParams = {
      url: '/policies/api/acceptances',
      method: 'post',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      data: queryData
    }

    try {
      await axios(requestParams)
      onAccepted()
    } catch {
      onError()
      setErrors({ auth: error.response.data.message })
    }
  }

  return (
    <Modal size='medium' isOpen={true} className={styles.modal}>
      <img alt='Logo' src={logo_url} />
      <Text type='h6' fontColor='text-base-darker'>
        {template_name}
      </Text>
      <AuthenticationLiteModalForm
        showModalActions
        onSubmit={onSubmit}
        step='privacyPolicy'
        setStep={setStep}
        authenticatedProfileId={authenticatedProfileId}
      >
        <Text
          type='h2'
          fontColor='text-base-darkest'
          marginBottom={step === 'email' ? 'more' : 'tiny'}
        >
          <Trans>Data Protection regulation</Trans>
        </Text>
        <PrivacyPolicyField
          data={data}
          setData={setData}
          errors={errors.privacy}
          privacyPolicy={privacyPolicy}
        />
      </AuthenticationLiteModalForm>
    </Modal>
  )
}

export default AcceptPolicyModal

import formatStudyPlanCourseData from 'apps/StudyPlans/utils/formatStudyPlanCourseData'

export const getDataToUpdate = (subjectData, fieldsToUpdate) => {
  const formattedSubjectData = formatStudyPlanCourseData(subjectData)

  return ['id', ...fieldsToUpdate].reduce(
    (acc, fieldName) => ({
      ...acc,
      [fieldName]: formattedSubjectData[fieldName]
    }),
    {}
  )
}

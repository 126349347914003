import React from 'react'

const ArrowRightIcon = () => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M5 3L6 2L12 8L6 14L5 13L10 8L5 3Z' fill='#9399A2' />
    </svg>
  )
}

export default ArrowRightIcon

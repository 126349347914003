import 'css-chunk:src/apps/MergeProfiles/components/ApplicationCard/styles.module.scss';export default {
  "react-app": "react-app",
  "applicCard": "_applicCard_feb2z_1",
  "applicAdded": "_applicAdded_feb2z_6",
  "applicDiscarded": "_applicDiscarded_feb2z_9",
  "summaryType": "_summaryType_feb2z_9",
  "cardInfoRegion": "_cardInfoRegion_feb2z_16",
  "cardNotice": "_cardNotice_feb2z_19",
  "cardButtons": "_cardButtons_feb2z_27",
  "jouneysNotice": "_jouneysNotice_feb2z_32",
  "cardButton": "_cardButton_feb2z_27",
  "buttonIcon": "_buttonIcon_feb2z_41",
  "added": "_added_feb2z_45",
  "discarded": "_discarded_feb2z_52"
};
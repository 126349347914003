import React from 'react'
import { Trans } from 'react-i18next'

import HomeIcon from './home-icon'
import MyApplicationsIcon from './my-applications-icon'
import PrimaryLink from './primary-link'
import ProductCatalogIcon from './product-catalog-icon'

export default function PrimaryNavigation({ options }) {
  return (
    <>
      {options.find((option) => option === '/') && (
        <PrimaryLink
          external
          icon={<HomeIcon />}
          label={<Trans>Home</Trans>}
          to={'/'}
        />
      )}

      {options.find((option) => option === '/my-applications') && (
        <PrimaryLink
          icon={<MyApplicationsIcon />}
          label={<Trans>My Applications</Trans>}
          to={'/my-applications'}
        />
      )}

      {options.find((option) => option === '/my-registrations') && (
        <PrimaryLink
          icon={<MyApplicationsIcon />}
          label={<Trans>My Registrations</Trans>}
          to={'/my-registrations'}
        />
      )}

      {options.find((option) => option === '/product-catalog') && (
        <PrimaryLink
          icon={<ProductCatalogIcon />}
          label={<Trans>Product catalog</Trans>}
          to={'/product-catalog'}
        />
      )}
    </>
  )
}

.react-app ._bodyContainer_1v5vg_1 {
  max-width: 500px;
}

.react-app ._expandedFormPadding_1v5vg_5 {
  padding-left: var(--space-more);
  padding-right: var(--space-more);
}

.react-app ._expandedFormSpacing_1v5vg_10 {
  margin-left: calc(-1 * var(--space-more));
  margin-right: calc(-1 * var(--space-more));
  padding-left: var(--space-more);
  padding-right: var(--space-more);
}
import React from 'react'
import { Trans } from 'react-i18next'

import FileUploadStep from 'apps/Imports/components/file-upload-step'
import PageContainer from 'apps/Imports/components/page-container'
import ImportActions from 'apps/Imports/components/product-import/import-actions'
import SidebarTitle from 'apps/Imports/components/sidebar-title'
import { useCurrentStep } from 'apps/Imports/contexts/steps'
import { PRODUCT_IMPORT_STEPS } from 'apps/Imports/utils/product-import/constants'

const FileUploadPage = () => {
  useCurrentStep(PRODUCT_IMPORT_STEPS.FILE_UPLOAD)

  return (
    <PageContainer
      title={<Trans>File upload</Trans>}
      sidebarTitle={<SidebarTitle />}
      subtitle={
        <Trans>
          You must upload a .csv file. From Excel choose to 'Save as' Comma
          Separated Values (.csv).
        </Trans>
      }
    >
      <FileUploadStep />
      <ImportActions />
    </PageContainer>
  )
}

export default FileUploadPage

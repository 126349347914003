.react-app ._applicsTabContainer_1w598_1 {
  margin: var(--space-more) 0;
}
.react-app ._applicsTabContainer_1w598_1 ._applicSummaryRow_1w598_4 {
  padding: var(--space-default) 0;
  border-bottom: var(--border-base-lighter);
}
.react-app ._applicsTabContainer_1w598_1 ._applicSummaryRow_1w598_4 ._applicSummaryCardCol_1w598_8 {
  width: auto !important;
  flex: 0 0 auto !important;
}
.react-app ._applicsTabContainer_1w598_1 ._applicSummaryRow_1w598_4 ._applicSummaryCardCol_1w598_8._halfWidth_1w598_12 {
  max-width: 50% !important;
}
.react-app ._applicsTabContainer_1w598_1 ._applicSummaryRow_1w598_4:first-child {
  padding-top: 0;
}
.react-app ._applicsTabContainer_1w598_1 ._applicSummaryRow_1w598_4:last-child {
  padding-bottom: 0;
  border-bottom: none;
}
.react-app ._applicsTabContainer_1w598_1 ._noApplics_1w598_22 {
  margin-left: var(--space-more);
  margin-bottom: 100px;
  display: flex;
  align-items: center;
}
.react-app ._applicsTabContainer_1w598_1 ._noApplics_1w598_22 svg {
  margin-right: var(--space-small);
}
import React from 'react'

import { Text } from '@fullfabric/alma-mater'

const ReportSummaryField = ({ label, value, ...props }) =>
  label ? (
    <Text fontColor='text-base-darkest' {...props}>
      {label}
      &nbsp;
      <Text tag='span'>{value}</Text>
    </Text>
  ) : null

export default ReportSummaryField

/* eslint-disable camelcase */

import { connect } from 'react-redux'

import getSubjectsAction from '../../../actions/getSubjects'
import actionTypes from '../../../actions/types'
import SubjectField from '../../../components/Fields/Subject'

const mapStateToProps = ({
  subjectList: { data, loading },
  newCourse: {
    data: { create_new_subject }
  }
}) => ({
  subjectList: data,
  loading,
  isNewSubject: create_new_subject
})

const mapDispatchToProps = (dispatch) => ({
  getSubjects: () => dispatch(getSubjectsAction()),
  onNewSubjectClick: (evt) => {
    evt.preventDefault()
    dispatch({ type: actionTypes.newSubjectClick })
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(SubjectField)

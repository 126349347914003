export const getDataWithoutTargetEntry = ({ oldQueryData, entry }) =>
  oldQueryData?.pages?.map((page) => {
    const targetEntryIndex = page.data.findIndex((e) => e.id === entry.id)
    const isInThisPage = targetEntryIndex > -1

    if (!isInThisPage) {
      return page
    }

    return {
      ...page,
      data: [
        ...page.data.slice(0, targetEntryIndex),
        ...page.data.slice(targetEntryIndex + 1)
      ]
    }
  }) || []

const removeEntryInQuery = ({
  queryFilter,
  cachedQuery,
  queryClient,
  entry
}) => {
  if (!cachedQuery) {
    return
  }

  const queryKey = cachedQuery[0]
  const oldQueryData = cachedQuery[1]

  const updatedQueryData = getDataWithoutTargetEntry({
    oldQueryData,
    entry
  })

  queryClient.setQueryData(queryKey, (data) => {
    return {
      pages: updatedQueryData,
      pageParams: data.pageParams
    }
  })

  queryClient.invalidateQueries(queryFilter)
}

export default removeEntryInQuery

import React from 'react'
import { Trans } from 'react-i18next'

import { Col, Field, Row } from '@fullfabric/alma-mater'

import SubjectUpdateActions from 'apps/StudyPlans/components/SubjectUpdateActions'
import { useStudyPlan } from 'apps/StudyPlans/contexts/StudyPlanData'
import {
  useOnChangeSubjectEditionData,
  useSubjectEditionData,
  useSubjectEditionErrors
} from 'apps/StudyPlans/contexts/SubjectEditionData'
import CategoryField from './CategoryField'
import CoursesField from './CoursesField'
import CourseTypeField from './CourseTypeField'
import PrerequisitesField from './PrerequisitesField'
import SubjectField from './SubjectField'

import styles from './styles.module.scss'

const ERROR_MESSAGES = {
  blank: <Trans>Can't be blank</Trans>,
  notANumber: <Trans>Must be a number</Trans>
}

const SubjectBasicForm = () => {
  const data = useSubjectEditionData()
  const studyPlan = useStudyPlan()
  const errors = useSubjectEditionErrors()
  const onChange = useOnChangeSubjectEditionData()

  const isEditing = data.id ? true : null

  return (
    <>
      <Row>
        {isEditing ? (
          <Col xs={12}>
            <Field
              id='course_name'
              onChange={() => {}}
              type='text'
              label={<Trans>Subject</Trans>}
              value={data.course && data.course.long_name}
              inputOptions={{ disabled: true }}
            />
          </Col>
        ) : (
          <Col xs={12} className={styles.subjectFieldContainer}>
            <SubjectField />
          </Col>
        )}
      </Row>
      <Row>
        <Col xs={12}>
          <CoursesField />
        </Col>
      </Row>
      {!studyPlan.multiple_paths && (
        <Row>
          <Col xs={12}>
            <CourseTypeField />
          </Col>
        </Row>
      )}
      {!studyPlan.multiple_paths && (
        <Row>
          <Col xs={12}>
            <PrerequisitesField />
          </Col>
        </Row>
      )}
      <Row>
        <Col xs={12}>
          <CategoryField />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Field
            id='credits'
            type='text'
            value={data.credits || data.credits === 0 ? `${data.credits}` : ''}
            label={<Trans>Credits</Trans>}
            onChange={onChange}
            error={ERROR_MESSAGES[errors.credits]}
            required
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Field
            id='hours'
            type='text'
            value={data.hours || data.hours === 0 ? `${data.hours}` : ''}
            label={<Trans>Hours</Trans>}
            onChange={onChange}
            error={ERROR_MESSAGES[errors.hours]}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Field
            id='max_number_absences'
            type='text'
            value={
              data.max_number_absences || data.max_number_absences === 0
                ? `${data.max_number_absences}`
                : ''
            }
            label={<Trans>Max number of absences</Trans>}
            onChange={onChange}
            error={ERROR_MESSAGES[errors.max_number_absences]}
          />
        </Col>
      </Row>
      {isEditing && (
        <SubjectUpdateActions
          sectionName='basic'
          fieldsToUpdate={[
            'unit_ids',
            !studyPlan.multiple_paths && 'course_types',
            !studyPlan.multiple_paths && 'prerequisites',
            'category_id',
            'credits',
            'hours',
            'max_number_absences'
          ].filter((x) => x)}
        />
      )}
    </>
  )
}

export default SubjectBasicForm

import React from 'react'
import { Trans } from 'react-i18next'
import { useMutation } from 'react-query'

import { Button } from '@fullfabric/alma-mater'

import mergeProfiles from 'apps/MergeProfiles/api/mergeProfiles'
import { useMergeData } from 'apps/MergeProfiles/contexts/MergeData'

const MergeActionButton = ({ onError }) => {
  const { mutate, isLoading: mergeInProgress } = useMutation(mergeProfiles, {
    throwOnError: true
  })
  const { mergeData, updateMergeError } = useMergeData()
  const onMergeProfilesClick = async () => {
    if (!mergeData.confirmDuplicateProfileDeletion) {
      updateMergeError({ confirmDuplicateProfileDeletion: 'empty' })
      return
    }

    try {
      await mutate({ ...mergeData })

      window.location.replace(`/profiles/${mergeData.targetProfileId}`)
    } catch (error) {
      onError && onError(error)
    }
  }

  return (
    <Button primary loading={mergeInProgress} onClick={onMergeProfilesClick}>
      <Trans>Confirm and merge</Trans>
    </Button>
  )
}

export default MergeActionButton

import { utils } from '@fullfabric/public-api'

const bulkUnpublishPaymentPlans = async ({ dryRun, search, filters }) => {
  const params = { dry_run: dryRun, ...search, ...filters }

  const response = await fetch(
    `/book_keeper/api/profiles/plans/bulk_unpublish`,
    {
      credentials: 'same-origin',
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(params)
    }
  )

  return await utils.checkResponse(response)
}

export default bulkUnpublishPaymentPlans

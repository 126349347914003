import React from 'react'
import { useTranslation } from 'react-i18next'

import { Filter } from '@fullfabric/alma-mater'
import { useCurrentUser } from '@fullfabric/authorization-officer'

import getApplicTemplates from 'apps/Reports/api/getApplicTemplates'
import { getPeriodOptions } from './constants'
import CumulativeWeeklyApplicsChart from './CumulativeWeeklyApplicsChart'
import { FilterProvider } from './FilterContext'
import StatsWidgetsRow from './StatsWidgetsRow'
import WeeklyApplicsChartsRow from './WeeklyApplicsChartsRow'

import styles from './styles.module.scss'

const INITIAL_FILTER = { period: 'this_year' }

const ApplicPerformanceReport = ({ filterStorageKey }) => {
  const user = useCurrentUser()
  const { t } = useTranslation()
  const [filter, setFilter] = React.useState(() => {
    const storedFilter = localStorage.getItem(filterStorageKey + user.id)
    return storedFilter ? JSON.parse(storedFilter) : INITIAL_FILTER
  })

  return (
    <>
      <Filter
        onFilterChange={(selectedFilter) => {
          setFilter(selectedFilter)
          localStorage.setItem(
            filterStorageKey + user.id,
            JSON.stringify(selectedFilter || {})
          )
        }}
        selectedFilter={filter}
        containerClassName={styles.filter}
        filters={[
          {
            id: 'templates',
            searchField: 'label',
            defaultValue: null,
            defaultLabel: t('All applications'),
            name: t('Application template'),
            type: 'multi',
            loadOptions: getApplicTemplates
          },
          {
            id: 'period',
            name: t('Period'),
            type: 'single',
            defaultValue: 'this_year',
            options: getPeriodOptions(t)
          }
        ]}
      />
      <FilterProvider filter={filter}>
        <StatsWidgetsRow />
        <CumulativeWeeklyApplicsChart />
        <WeeklyApplicsChartsRow />
      </FilterProvider>
    </>
  )
}

export default ApplicPerformanceReport

import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { omit } from 'lodash'
import moment from 'moment'

import {
  useCurrentUser,
  UserRoleUtils,
  useSettings
} from '@fullfabric/authorization-officer'
import useAnalytics from '@fullfabric/ff-analytics'
import { userHasAcceptedTypeOfCookie } from '@fullfabric/policies'

import { useAppData } from 'shared/contexts/AppData'
import { LocaleProvider } from 'shared/contexts/Locale'
import getInitialLocale from 'shared/utils/getInitialLocale'

import CookiePromptWrapper from './CookiePromptWrapper'
import AppsRoutes from './Routes'

const LocalizedApp = () => {
  const { i18n } = useTranslation()
  const user = useCurrentUser()
  const settings = useSettings()
  const locales = settings.hash.locales
  const initialLocale = getInitialLocale(user, locales)
  const appData = useAppData()

  const {
    cookies_policy: cookiesPolicy,
    current_cookies_policy: currentCookiesPolicy
  } = appData

  useEffect(() => {
    if (i18n.language !== initialLocale) {
      i18n.changeLanguage(initialLocale)
    }
  }, [i18n, initialLocale])

  useEffect(() => {
    moment.locale(initialLocale)
  }, [initialLocale])

  const cookiesAccepted = useCallback(() => {
    const cookieType = cookiesPolicy?.cookie_types

    if (cookieType?.analytical) {
      setAllowsAnalytics(true)
    }
    if (cookieType?.advertising) {
      setAllowsAdvertising(true)
    }
  }, [cookiesPolicy?.cookie_types])

  const [allowsAdvertising, setAllowsAdvertising] = useState(
    userHasAcceptedTypeOfCookie({
      cookiePolicy: appData.cookies_policy,
      cookieType: 'advertising',
      userAcceptance: user?.cookies_acceptance
    })
  )
  const [allowsAnalytics, setAllowsAnalytics] = useState(
    userHasAcceptedTypeOfCookie({
      cookiePolicy: appData.cookies_policy,
      cookieType: 'analytical',
      userAcceptance: user?.cookies_acceptance
    })
  )

  const isStaffUser = user && UserRoleUtils.isStaff(user)
  const integrations = settings?.hash?.integrations || {}

  useAnalytics(
    {
      advertising: allowsAdvertising,
      analytics: allowsAnalytics
    },
    // only send GTM and GA integrations data if user is not staff
    isStaffUser ? omit(integrations, ['ga', 'gtm']) : integrations,
    {
      currentCookiesPolicy
    }
  )

  return (
    <LocaleProvider initialLocale={initialLocale} locales={locales}>
      <CookiePromptWrapper onCookiesAccepted={cookiesAccepted}>
        <AppsRoutes />
      </CookiePromptWrapper>
    </LocaleProvider>
  )
}

export default LocalizedApp

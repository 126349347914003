import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { useAppData } from 'shared/contexts/AppData'

import ApplicationsList from 'apps/ContentPages/components/applications-list'
import PageLayout from 'apps/ContentPages/components/page-layout'
import SidebarLayout from 'apps/ContentPages/components/sidebar-layout'
import StudentHubPageTitle from 'apps/ContentPages/components/student-hub-page-title'
import {
  BackgroundImageProvider,
  useChangeBackgroundImage
} from 'apps/ContentPages/contexts/background-image'
import { CurrentPageDataProvider } from 'apps/ContentPages/contexts/current-page-data'

const MyApplicationsPage = ({ type }) => {
  const { t } = useTranslation()
  const changeBackgroundImage = useChangeBackgroundImage()

  useEffect(() => {
    changeBackgroundImage()
  }, [changeBackgroundImage])

  return (
    <SidebarLayout>
      <PageLayout
        type='functional'
        title={
          <StudentHubPageTitle
            title={
              type === 'registrations'
                ? t('My Registrations')
                : t('My Applications')
            }
          />
        }
        withNavigationMenu
      >
        <ApplicationsList />
      </PageLayout>
    </SidebarLayout>
  )
}

const MyApplicationsPageContainer = ({ preview }) => {
  const location = useLocation()
  const { _navigation } = useAppData()
  const dataType = location.pathname.includes('registrations')
    ? 'registrations'
    : 'applications'

  return (
    <CurrentPageDataProvider
      previewMode={preview}
      currentPath={location.pathname}
      navigationOptions={_navigation}
    >
      <BackgroundImageProvider>
        <MyApplicationsPage type={dataType} />
      </BackgroundImageProvider>
    </CurrentPageDataProvider>
  )
}

export default MyApplicationsPageContainer

import React from 'react'

import { Card as AlmaMaterCard } from '@fullfabric/alma-mater'

import classNames from 'classnames'
import styles from './styles.module.scss'

const Card = ({ className, children }) => (
  <AlmaMaterCard className={classNames(styles.card, className)}>
    {children}
  </AlmaMaterCard>
)

export default Card

import { connect } from 'react-redux'

import archiveCourseAction from 'apps/Courses/actions/archiveCourse'
import actionTypes from 'apps/Courses/actions/types'
import ArchiveCourseModal from 'apps/Courses/components/ArchiveCourseModal'

const mapStateToProps = ({ archiveCourse: { modalOpen, inProgress } }) => ({
  isOpen: modalOpen,
  inProgress
})

const mapDispatchToProps = (dispatch) => ({
  onClose: () =>
    dispatch({
      type: actionTypes.openArchiveCourseModal,
      payload: { course: {}, open: false }
    }),
  onArchive: () => dispatch(archiveCourseAction())
})

export default connect(mapStateToProps, mapDispatchToProps)(ArchiveCourseModal)

import 'css-chunk:src/apps/Policies/components/PolicyManagementPageLayout/styles.module.scss';export default {
  "react-app": "react-app",
  "appContainer": "_appContainer_12iah_1",
  "contentColumn": "_contentColumn_12iah_2",
  "logoLink": "_logoLink_12iah_19",
  "logoImage": "_logoImage_12iah_28",
  "localeColumn": "_localeColumn_12iah_36",
  "offsetColumn": "_offsetColumn_12iah_37",
  "scrollContainer": "_scrollContainer_12iah_52",
  "localeSelector": "_localeSelector_12iah_59",
  "imageColumn": "_imageColumn_12iah_70",
  "backgroundImage": "_backgroundImage_12iah_79"
};
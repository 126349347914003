import React from 'react'
import { useTranslation } from 'react-i18next'

import { Filter } from '@fullfabric/alma-mater'

import getProducts from 'apps/Reports/api/getProducts'
import { useFilter, useFilterCallbacks } from '../FilterContext'

import styles from './styles.module.scss'

const ReportFilter = () => {
  const { t } = useTranslation()
  const filter = useFilter()
  const { onFilterChange, onFilterOptionsLoaded } = useFilterCallbacks()

  return (
    <Filter
      onFilterChange={onFilterChange}
      onOptionsLoaded={onFilterOptionsLoaded}
      selectedFilter={filter}
      containerClassName={styles.filter}
      filters={[
        {
          id: 'context',
          searchField: 'full_name',
          name: t('Product'),
          type: 'multi',
          valueField: 'context_id',
          labelField: 'full_name',
          valueSeparator: 'and',
          defaultValue: (opts) => opts?.[0]?.context_id,
          loadOptions: getProducts
        }
      ].filter((x) => x)}
    />
  )
}

export default ReportFilter

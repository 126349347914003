.react-app ._editContainer_1vpzl_1 {
  display: flex;
  gap: var(--space-tiny);
  width: 80%;
  max-width: 760px;
  margin-left: var(--space-more);
  min-height: 24px;
  box-sizing: border-box;
}

.react-app ._input_1vpzl_11 {
  height: auto !important;
  margin-bottom: 0 !important;
}
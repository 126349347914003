import { utils } from '@fullfabric/public-api'

const createStripePaymentIntent = async (payableType, payableId) => {
  const data = new FormData()
  data.append('payable_type', payableType)
  data.append('payable_id', payableId)

  const response = await fetch('/payments/stripe/api/intents', {
    credentials: 'same-origin',
    method: 'POST',
    body: data
  })

  return await utils.checkResponse(response)
}

export default createStripePaymentIntent

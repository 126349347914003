.react-app ._functionalGrid_1yzsm_1 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.react-app ._contentCol_1yzsm_6 {
  overflow: auto;
}
.react-app ._contentCol_1yzsm_6._desktopLayout_1yzsm_9 {
  height: 100vh;
}

.react-app ._small_1yzsm_13 {
  padding: var(--space-more) var(--space-default) !important;
}

.react-app ._big_1yzsm_17 {
  padding: var(--space-big) !important;
}
import React from 'react'

import PropTypes from 'prop-types'

import CustomTextContent from '../../custom-text-content'
import WidgetSection from '../../widget-section'

export default function TextWidget({ widget }) {
  const { content: contentAsHtml } = widget

  return (
    <WidgetSection single>
      <CustomTextContent
        htmlContent={contentAsHtml}
        type={'f5'}
        fontColor='text-base-darker'
      />
    </WidgetSection>
  )
}

TextWidget.propTypes = {
  widget: PropTypes.shape({
    content: PropTypes.string.isRequired
  }).isRequired
}

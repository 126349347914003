{
  "A word by itself is easy to guess": "Et enkeltstående ord er nemt at gætte",
  "Add \"{{val}}\"": "Tilføj \"{{val}}\"",
  "Add another word or two. Uncommon words are better.": "Tilføj endnu et ord eller to. Usædvanlige ord er bedre.",
  "All-uppercase is almost as easy to guess as all-lowercase": "Kun store bogstaver er næsten lige så nemt at gætte, som kun små bogstaver",
  "Almost there": "Du er der næsten",
  "Already have an account?": "Har du allerede en konto?",
  "Avoid dates and years that are associated with you": "Undgå datoer og år der er associeret med dig",
  "Avoid recent years": "Undgå seneste år",
  "Avoid repeated words and characters": "Undgå gentagen af ord og tegn",
  "Avoid sequences": "Undgå sekvenser",
  "Avoid years that are associated with you": "Undgå år der er associeret med dig",
  "Back to login": "Tilbage til login",
  "Cancel": "Afbryd",
  "Capitalization doesn't help very much": "Store bogstaver hjælper minimalt",
  "Common names and surnames are easy to guess": "Almindelige navne og efternavne er nemme at gætte",
  "Continue": "Fortsæt",
  "Create an account": "Opret en konto",
  "Create new account": "Opret en ny konto",
  "Data Protection Regulation": "Databeskyttelsesforordning",
  "Dates are often easy to guess": "Datoer er ofte nemme at gætte",
  "Didn't receive the sms?": "Modtog du ikke sms’en?",
  "E-mail address": "E-mail adresse",
  "First name": "Fornavn",
  "For security reasons account activation links are perishable. You can get a new activation link by filling the sign up form.": "Af sikkerhedsmæssige grunde er kontoaktiveringslinks gyldige i kort tid. Du kan få et nyt aktiveringslink ved at udfylde tilmeldingsformularen.",
  "Forgot your password?": "Glemt din adgangskode?",
  "Great, your account has been created!": "Fantastisk, din konto er oprettet!",
  "I accept the privacy policy": "Jeg godkender privatlivspolitikken",
  "Institutional login": "Institutionelt log-in",
  "Last name": "Efternavn",
  "Link existing account": "Link den eksisterende konto",
  "LinkedIn": "LinkedIn",
  "Log in": "Log ind",
  "Log in securely": "Log sikkert ind",
  "Names and surnames by themselves are easy to guess": "Navne og efternavne stående for sig selv er nemme at gætte",
  "New Password": "Ny adgangskode",
  "No Results": "Ingen resultater",
  "No need for symbols, digits, or uppercase letters": "Intet behov for symboler, cifre eller store bogstaver",
  "Not registered?": "Ikke registreret?",
  "Nothing found for": "Intet fundet for",
  "Or use these alternatives": "Eller brug disse alternativer:",
  "Password": "Adgangskode",
  "Password Reset": "Nulstilling af adgangskode",
  "Please accept the privacy policy to continue": "Godkend privatlivspolitikken for at fortsætte",
  "Please check your email for instructions on resetting your password": "Tjek venligst din e-mail for instruktioner om at nulstille din adgangskode",
  "Please check your mailbox for instructions on verifying your registration.": "Tjek venligst din postkasse for instruktioner om at bekræfte din registrering.",
  "Please choose a password": "Vælg venligst en adgangskode",
  "Please confirm your account details below": "Bekræft venligst din kontos informationer nedenfor",
  "Please contact your university's IT support department in order to get a new activation link to access Full Fabric.": "Kontakt venligst dit universitets IT-supportafdeling, for et nyt aktiveringslink for adgang til Full Fabric.",
  "Please type a new password.": "Skriv venligst en ny adgangskode.",
  "Predictable substitutions like \"@\" instead of \"a\" don't help very much": "Forudsigelig udskiftning såsom \"@\" i stedet for \"a\" hjælper minimalt",
  "Predictable substitutions like '@' instead of 'a' don't help very much": "Forudsigelig udskiftning såsom '@' i stedet for ‘a' hjælper minimalt",
  "Privacy policy": "Privatlivspolitik",
  "Recent years are easy to guess": "De seneste år nemme at gætte",
  "Repeats like \"aaa\" are easy to guess": "Gentagelser såsom \"aaa\" er nemme at gætte",
  "Repeats like \"abcabcabc\" are only slightly harder to guess than \"abc\"": "Gentagelser såsom \"abcabcabc\" er minimalt sværere at gætte end \"abc\"",
  "Reset password": "Nulstil adgangskode",
  "Reset password and sign in": "Nulstil adgangskode og log ind",
  "Reset your password below.": "Nulstil din adgangskode nedenfor.",
  "Return to log in page": "Vend tilbage til login-siden",
  "Reversed words aren't much harder to guess": "Ord skrevet baglæns er ikke svære at gætte",
  "Send again": "Send igen",
  "Sequences like abc or 6543 are easy to guess": "Sekvenser såsom abc eller 6543 er nemme at gætte",
  "Set password and sign in": "Indstil adgangskode og log ind",
  "Short keyboard patterns are easy to guess": "Korte tastaturrækker er nemme at gætte",
  "Sign in": "Log ind",
  "Sign up": "Tilmeld dig",
  "Skip to main content": "Spring til hovedindhold",
  "Straight rows of keys are easy to guess": "Lige tastaturrækker er nemme at gætte",
  "Suggestions": "Forslag",
  "Thank you.": "Tak.",
  "There was a server problem. Please try again.": "Der var et serverproblem. Prøv venligst igen.",
  "This is a top-10 common password": "Dette er en top- 10 mest almindelige adgangskoder",
  "This is a top-100 common password": "Dette er en top- 100 mest almindelige adgangskoder",
  "This is a very common password": "Dette er en meget almindelig adgangskode",
  "This is similar to a commonly used password": "Dette svarer til en almindeligt anvendt adgangskode",
  "Two Factor Authentication Token": "To-faktor autentificering token",
  "Two step verification": "Totrinsbekræftelse",
  "Use a few words, avoid common phrases": "Brug et par ord, undgå almindelige sætninger",
  "Use a longer keyboard pattern with more turns": "Brug et længere tastatur mønster med flere skift",
  "Use one of these alternatives to signup": "Brug en af disse alternativer til at registrere dig",
  "Use one of these methods to log in": "Brug en af disse metoder til at logge på",
  "We sent a sms to the number <1>{{phoneNumber}}</1> with the verification code": "Vi har sendt en SMS til <1>{{phoneNumber}}</1> med en bekræftelseskode",
  "Welcome": "Velkommen",
  "Your activation link has expired.": "Dit aktiveringslink er udløbet.",
  "Your password has expired.": "Din adgangskode er udløbet.",
  "Your password is good": "Din adgangskode er stærk",
  "Your password is reasonably adequate": "Din adgangskode er middel",
  "Your password is too weak": "Din adgangskode er for svag",
  "or e-mail": "eller e-mail",
  "or link an existing account": "eller link en eksistrende konto",
  "{{minAlpha}} letters": "{{minAlpha}} bogstaver",
  "{{minDigits}} digits": "{{minDigits}} cifre",
  "{{minLength}} characters": "{{minLength}} tegn",
  "{{minSymbols}} symbols": "{{minSymbols}} symboler",
  "Password must be at least {{minLength}} characters long": "Adgangskoden skal have mindst {{minLength}} tegn",
  "Password must have at least {{count}} characters": "Adgangskoden skal have mindst {{count}} tegn",
  "Password must have at least {{count}} characters_plural": "Adgangskode skal indeholde mindst {{count}} tegn",
  "Password must have at least {{count}} digit": "Adgangskoden skal have mindst {{count}} cifre",
  "Password must have at least {{count}} digit_plural": "Adgangskode skal indeholde mindst {{count}} nummer",
  "Password must have at least {{count}} symbol": "Adgangskode skal indeholde mindst {{count}} symbol",
  "Password must have at least {{count}} symbol_plural": "Adgangskode skal indeholde mindst {{count}} symboler",
  "Password was already used": "Adgangskoden er allerede i brug",
  "Thank you. Please log in below.": "Tak skal du have. Log venligst ind herunder.",
  "This field is required": "Dette felt er påkrævet",
  "Privacy Policy": "Privatlivspolitik",
  "Link to an existing account": "Link en eksisterende konto",
  "There are fields with errors.": "Der er felter med fejl",
  "Please correct the fields with errors.": "Ret venligst felterne med fejl.",
  "We sent a sms to the number {{ phoneNumber }} with the verification code": "Vi har sendt en SMS til {{phoneNumber}} med en bekræftelseskode"
}

export const isOfferInProgress = (offer) =>
  offer && offer.state === 'in_progress'

export const isOfferSubmitted = (offer) => offer && offer.state === 'submitted'

export const redirectToOffer = (offer) => {
  if (!offer) return

  window.location.href = `/offers/offers/${offer.id}`
}

import React from 'react'
import { useHistory } from 'react-router'

import { useStepsCompleted, useStepState } from 'apps/Imports/contexts/steps'
import { useImportContext } from 'apps/Imports/hooks/useImportContext'
import importUrlFromStepId from 'apps/Imports/utils/profile-import/get-url-from-step-id'

const useGoToStep = () => {
  const history = useHistory()
  const context = useImportContext()
  const { currentStep, nextStep } = useStepState()
  const { addCompletedStep } = useStepsCompleted()

  const goToStepPage = React.useCallback(
    (stepId) => {
      const pageUrl = importUrlFromStepId(context, stepId)
      history.push(pageUrl)
    },
    [context, history]
  )

  const goToNextStepPage = React.useCallback(() => {
    addCompletedStep(currentStep)
    goToStepPage(nextStep)
  }, [addCompletedStep, currentStep, goToStepPage, nextStep])

  return { goToNextStepPage, goToStepPage }
}

export default useGoToStep

import React from 'react'
import { Trans } from 'react-i18next'

import moment from 'moment'

import { ArrowRightIcon, Col, Link, Row, Text } from '@fullfabric/alma-mater'
import { useCurrentUser } from '@fullfabric/authorization-officer'

import { useLocale } from 'shared/contexts/Locale'

import { formatCurrency } from 'apps/ContentPages/utils/format-price'
import getLocalizedText from 'apps/ContentPages/utils/get-localized-text'
import getProductDuration from 'apps/ContentPages/utils/get-product-duration'
import getProductTargetApplicTemplate from 'apps/ContentPages/utils/get-product-target-applic-template'
import getStartApplicationLink from 'apps/ContentPages/utils/get-start-application-link'
import Card from './card'
import ProductDescription from './product-description'

import cx from 'classnames'
import styles from './styles.module.scss'

const ProductCard = ({ product }) => {
  const user = useCurrentUser()
  const { locale } = useLocale()
  const applicTemplate = getProductTargetApplicTemplate(product)

  const duration = getProductDuration(product?.starts_on, product?.ends_on)

  const campuses =
    product.campuses &&
    product.campuses.length > 0 &&
    product.campuses.map((campus) => campus.name).join(', ')

  const priceValue =
    product.price_amount &&
    product.price_currency &&
    formatCurrency(product.price_amount, product.price_currency, user?.locale)

  const linkHref = getStartApplicationLink(product, applicTemplate)

  const hasDetailedContent = !!getLocalizedText(
    product.catalog_page_data,
    locale,
    'html',
    'en-GB'
  )

  return (
    <Link withoutStyles href={linkHref} className={styles.cardLinkWrapper}>
      <Card className={styles.clickableCard}>
        <Text type='h3' fontColor='text-base-darkest'>
          {product.context_parent_name}
        </Text>

        <Text type='h5'>{product.context_name}</Text>

        <Row>
          <Col xs={6}>
            <Text type='h6' marginTop='default'>
              <Trans>Start date</Trans>
            </Text>

            <Text type='f5' fontColor='text-base-darkest'>
              {product.starts_on
                ? moment(product.starts_on).format('MMM D, yyyy')
                : '-'}
            </Text>
          </Col>

          {product.ends_on && (
            <Col xs={6}>
              <Text type='h6' marginTop='default'>
                <Trans>End date</Trans>
              </Text>

              <Text type='f5' fontColor='text-base-darkest'>
                {moment(product.ends_on).format('MMM D, yyyy')}
              </Text>
            </Col>
          )}
        </Row>

        {(duration || campuses) && (
          <Row>
            {duration && (
              <Col xs={6}>
                <Text type='h6' marginTop='less'>
                  <Trans>Duration</Trans>
                </Text>

                <Text type='f5' fontColor='text-base-darkest'>
                  {duration}
                </Text>
              </Col>
            )}

            {campuses && (
              <Col xs={6}>
                <Text type='h6' marginTop='less'>
                  <Trans>Location</Trans>
                </Text>

                <Text type='f5' fontColor='text-base-darkest'>
                  {campuses}
                </Text>
              </Col>
            )}
          </Row>
        )}

        {(product.closes_on || priceValue) && (
          <Row>
            {priceValue && (
              <Col xs={6}>
                <Text type='h6' marginTop='default'>
                  <Trans>Price</Trans>
                </Text>

                <Text type='f5' fontColor='text-base-darkest'>
                  {priceValue}
                </Text>
              </Col>
            )}

            {product.closes_on && (
              <Col xs={6}>
                <Text type='h6' marginTop='default'>
                  {applicTemplate?.type === 'registration' ? (
                    <Trans>Registration deadline</Trans>
                  ) : (
                    <Trans>Application deadline</Trans>
                  )}
                </Text>

                <Text type='f5' fontColor='text-base-darkest'>
                  {moment(product.closes_on).format('MMM D, yyyy')}
                </Text>
              </Col>
            )}
          </Row>
        )}

        <ProductDescription product={product} />

        <div className={styles.cardLinkContainer}>
          {hasDetailedContent && (
            <Link
              secondary
              underline
              className={cx(styles.cardLink, styles.detailsLink)}
              to={`/product-catalog/${product.context_id}`}
            >
              <Trans>Details</Trans>
            </Link>
          )}
          {linkHref && (
            <Link underline className={styles.cardLink} href={linkHref}>
              <div className={styles.cardLinkContentWrapper}>
                {applicTemplate?.type === 'application' ? (
                  <Trans>Apply</Trans>
                ) : (
                  <Trans>Register</Trans>
                )}{' '}
                <ArrowRightIcon />
              </div>
            </Link>
          )}
        </div>
      </Card>
    </Link>
  )
}

ProductCard.Card = Card

export default ProductCard

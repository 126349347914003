import React, { useEffect, useRef, useState } from 'react'

import { Col, Row, useScreenClass } from '@fullfabric/alma-mater'

import useIsMobileLayout from 'apps/ContentPages/hooks/use-is-mobile-layout'

import classNames from 'classnames'
import styles from './styles.module.scss'

const NARROW_CONTENT_COL_CONFIG = {
  xs: 12,
  md: 7
}

const CONTENT_COL_CONFIG = {
  xs: 12,
  md: 9,
  lg: 8
}

const ContentGrid = ({ withWidgets, children }) => {
  const isMobileLayout = useIsMobileLayout()
  const isDesktopLayout = !isMobileLayout

  return (
    <div
      className={classNames([
        styles.container,
        withWidgets && styles.withWidgets,
        isDesktopLayout && styles.desktop,
        isMobileLayout && styles.mobile
      ])}
    >
      {children}
    </div>
  )
}

const ContentRow = ({ justify = 'start', children }) => {
  return (
    <Row nogutter gutterWidth={0} justify={justify}>
      {children}
    </Row>
  )
}

const ContentCol = ({ isNarrow, children }) => {
  const contentColProps = isNarrow
    ? NARROW_CONTENT_COL_CONFIG
    : CONTENT_COL_CONFIG

  return (
    <Col className={styles.contentCol} {...contentColProps}>
      {children}
    </Col>
  )
}

const WidgetsCol = ({ children }) => {
  const screenClass = useScreenClass()
  const isXLLayout = screenClass === 'xl'

  return (
    <Col
      className={classNames([
        styles.widgetsCol,
        isXLLayout && styles.largeWidgets
      ])}
      xs={12}
      md={5}
    >
      {children}
    </Col>
  )
}

const FixedWidgetsCol = ({ children }) => {
  const screenClass = useScreenClass()
  const containerRef = useRef()
  const [columnWidth, setColumnWidth] = useState(0)

  const isMobileLayout = ['xs', 'sm'].includes(screenClass)
  const isXLLayout = screenClass === 'xl'

  const resizeColumn = React.useCallback(() => {
    if (containerRef?.current?.offsetWidth) {
      setColumnWidth(`${containerRef.current.offsetWidth}px`)
    }
  }, [])

  useEffect(() => {
    window.addEventListener('resize', resizeColumn)

    return () => {
      window.removeEventListener('resize', resizeColumn)
    }
  }, [resizeColumn])

  useEffect(() => {
    resizeColumn()
  }, [resizeColumn])

  const referenceClassName = !isMobileLayout ? styles.reference : null
  const widgetsWrapperClassName = !isMobileLayout ? styles.widgets : null

  return (
    <Col
      className={classNames([
        styles.widgetsCol,
        isMobileLayout && styles.prioritizedOverContent,
        !isMobileLayout && styles.fixedWidgetsCol,
        isXLLayout && styles.largeWidgets
      ])}
      xs={12}
      md={5}
    >
      <div className={referenceClassName} ref={containerRef}>
        <div
          className={widgetsWrapperClassName}
          style={{
            width: isMobileLayout ? '100%' : columnWidth || 'auto'
          }}
        >
          {children}
        </div>
      </div>
    </Col>
  )
}

ContentGrid.Row = ContentRow
ContentGrid.ContentCol = ContentCol
ContentGrid.WidgetsCol = WidgetsCol
ContentGrid.FixedWidgetsCol = FixedWidgetsCol

export default ContentGrid

import React from 'react'
import { Trans } from 'react-i18next'

import MappingStep from 'apps/Imports/components/mapping-step'
import PageContainer from 'apps/Imports/components/page-container'
import ImportActions from 'apps/Imports/components/product-import/import-actions'
import SidebarTitle from 'apps/Imports/components/sidebar-title'
import { useCurrentStep } from 'apps/Imports/contexts/steps'
import { PRODUCT_IMPORT_STEPS } from 'apps/Imports/utils/product-import/constants'

const MappingPage = () => {
  useCurrentStep(PRODUCT_IMPORT_STEPS.MAPPING)

  return (
    <PageContainer
      title={<Trans>Mapping</Trans>}
      sidebarTitle={<SidebarTitle />}
      subtitle={
        <Trans>We tried to guess the mapping but need some more help.</Trans>
      }
      contentColumns={9}
    >
      <MappingStep />
      <ImportActions />
    </PageContainer>
  )
}

export default MappingPage

import React from 'react'

import LoadingPlaceholderBlock from 'apps/ContentPages/components/loading-placeholder-block'
import WidgetSection from 'apps/ContentPages/components/widget-section'

import styles from './styles.module.scss'

const Placeholder = () => (
  <WidgetSection last>
    <LoadingPlaceholderBlock className={styles.title} />
    <LoadingPlaceholderBlock className={styles.subtitle} />
    <LoadingPlaceholderBlock className={styles.date} />
    <LoadingPlaceholderBlock className={styles.button} />
  </WidgetSection>
)

export default Placeholder

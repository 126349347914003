import React from 'react'

import { useAppData } from 'shared/contexts/AppData'

import useIsMobileNavigation from 'apps/ContentPages/hooks/use-is-mobile-navigation'
import LinkGroup from './link-group'
import SecondaryLink from './secondary-link'
import Title from './title'

import classNames from 'classnames'
import styles from './styles.module.scss'

export default function SecondaryNavigation({ primaryOptions }) {
  const { _navigation: allLinkGroups } = useAppData()
  const linkGroups = getSecondaryLinks(allLinkGroups || [], primaryOptions)
  const isMobileNavigation = useIsMobileNavigation()

  if (linkGroups.length === 0) {
    return null
  }

  return (
    <div
      className={classNames(
        styles.container,
        isMobileNavigation && styles.mobileNav
      )}
    >
      <Title />

      {linkGroups.map((linkGroup, index) =>
        linkGroup.length > 1 ? (
          <LinkGroup key={index} group={linkGroup} />
        ) : (
          <SecondaryLink
            key={index}
            to={linkGroup[0].path}
            label={linkGroup[0].navigation_label}
          />
        )
      )}
    </div>
  )
}

function getSecondaryLinks(linkGroups, primaryLinks = []) {
  const primaryLinksSet = new Set(primaryLinks)

  return (
    linkGroups
      // excludes empty groups and primary links; note that primary navigation has
      // only one link per group
      .filter(
        (group) => group.length > 0 && !primaryLinksSet.has(group[0].path)
      )
      .map((group) =>
        [...group].sort((linkA, linkB) => linkA.order - linkB.order)
      ) // sorts within each group
      .sort((groupA, groupB) => groupA[0].order - groupB[0].order)
  ) // sorts groups
}

import React from 'react'
import { Trans } from 'react-i18next'

import { Text } from '@fullfabric/alma-mater'

import styles from './styles.module.scss'

const Header = ({ policy, locale }) => {
  const descriptionContent = policy.locales.find(
    (content) => content.locale === locale
  )

  const descriptionText = descriptionContent && descriptionContent.html

  return (
    <div className={styles.container}>
      <Text type='h4' fontColor='text-base-darkest' className={styles.title}>
        <Trans>Privacy Policy</Trans>
      </Text>
      {descriptionText && (
        <Text
          tag='div'
          type='f5'
          fontColor='text-base-darkest'
          dangerouslySetInnerHTML={{
            __html: descriptionText
          }}
          className={styles.description}
        />
      )}
    </div>
  )
}

export default Header

{
  "Analytical": "Cookies analíticas",
  "Strictly necessary": "Cookies estrictamente necesarias",
  "Functional": "Cookies funcionales",
  "Advertising": "Cookies publicitarias",
  "Submit": "Enviar",
  "Thank you": "Gracias",
  "You have confirmed your marketing preferences.": "Has confirmado tus preferencias de marketing.",
  "You did not accept the Privacy Policy.": "No aceptó la Política de privacidad.",
  "You must contact us to delete your data.": "Debe ponerse en contacto con nosotros para borrar sus datos.",
  "Change privacy policy agreement": "Cambiar el acuerdo de política de privacidad",
  "Privacy Policy": "Política de Privacidad",
  "Next: Update marketing preferences": "Siguiente: actualizar las preferencias de marketing",
  "I Accept the Privacy Policy": "Acepto la política de privacidad",
  "I DO NOT accept the Privacy Policy": "No acepto la política de privacidad",
  "If you do not accept the privacy policy, you must contact the data processor to delete your data.": "Si no acepta la política de privacidad, deberá ponerse en contacto con el encargado del tratamiento para eliminar sus datos.",
  "You have updated your marketing preferences.": "Has actualizado tus preferencias de \"marketing\".",
  "Log in": "Iniciar sesión",
  "Finish your registration": "Finalice su registro",
  "Marketing Policy": "Política de Marketing",
  "I would like to receive information by Email": "Me gustaría recibir información por correo electrónico",
  "I would like to receive information by Phone": "Me gustaría recibir información por teléfono",
  "I would like to receive information by SMS": "Me gustaría recibir información por SMS",
  "I would like to receive information by Mail": "Me gustaría recibir información por correo",
  "I do not want to be contacted with this type of information": "No me gustaría para recibir este tipo de información",
  "Cookie Policy": "Política de cookies",
  "Back to Portal": "Volver al portal",
  "Manage your preferences": "Gestionar sus preferencias",
  "Save cookie preferences": "Guardar preferencias de cookies",
  "You accepted our privacy policy on our website - {{institutionHost}} - on {{date}}": "Aceptó nuestra política de privacidad en nuestro sitio web - {{institutionHost}} el {{date}}",
  "You are receiving this email because you accepted our marketing policy on our website - {{institutionHost}} - on  {{date}}": "Recibe este correo electrónico porque aceptó nuestra política de marketing en nuestro sitio web - {{institutionHost}} - el {{date}}",
  "You accepted our privacy policy when creating an account on {{date}}": "Aceptaste nuestra política de privacidad cuando creaste una cuenta el {{date}}",
  "You accepted our privacy policy on {{date}} from our email": "Aceptaste nuestra política de privacidad el {{date}} en nuestro correo electrónico",
  "You are receiving this email because you accepted our marketing policy on {{date}} from our email": "Recibe este correo electrónico porque aceptó nuestra política de marketing el {{date}} en nuestro correo electrónico",
  "You are receiving this email because a person created a profile for you and obtained your consent from {{consentSource}} on {{date}}": "Recibes este correo electrónico porque una persona te ha creado un perfil y ha obtenido tu consentimiento de \"{{consentSource}}\" el {{date}}",
  "You accepted our privacy policy when submitting the {{entity}} form on {{date}}": "Has aceptado nuestra política de privacidad al enviar el formulario \"{{entity}}\" el {{date}}",
  "You accepted our marketing policy when submitting the {{entity}} form on {{date}}": "Has aceptado nuestra política de mercadotecnia al enviar el formulario \"{{entity}}\" el {{date}}",
  "You accepted our privacy policy when registering for the {{entity}} event on {{date}}": "Has aceptado nuestra política de privacidad al registrarte en el evento \"{{entity}}\" el {{date}}",
  "You accepted our marketing policy when registering for the {{entity}} event on {{date}}": "Has aceptado nuestra política de mercadotecnia al registrarte en el evento \"{{entity}}\" el {{date}}",
  "Unsubscribe": "Darse de baja",
  "Yes, unsubscribe": "Sí, darse de baja",
  "No, stay subscribed": "No, mantente suscrito",
  "Manage marketing preferences": "Administrar preferencias de marketing",
  "Manage preferences for <0>{{policyName}}</0>": "Administrar preferencias de <0>{{policyName}}</0>",
  "Unsubscribe successful": "Darse de baja exitoso",
  "You are still subscribed": "Sigues suscrito",
  "Back to homepage": "Volver a la página de inicio",
  "Are you sure you want to unsubscribe from the <0>{{topicName}}</0> topic?": "¿Está seguro de que desea darse de baja del tema <0>{{topicName}}</0>?",
  "You have been unsubscribed from the <0>{{topicName}}</0> topic.": "Ha cancelado su suscripción al tema <0>{{topicName}}</0>.",
  "You have not been unsubscribed from the <0>{{topicName}}</0> topic.": "No se ha dado de baja del tema <0>{{topicName}}</0>."
}

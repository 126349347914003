import React from 'react'
import { useTranslation } from 'react-i18next'

import { useLocale, useSetLocale } from 'shared/contexts/Locale'
import normalizeLocale from 'shared/utils/normalizeLocale'

import Link from '../link'
import WorldIcon from './world-icon'

export default function LocaleButton() {
  const { t } = useTranslation()
  const { alternativeLocale } = useLocale()
  const setLocale = useSetLocale()

  const onClick = (event) => {
    event.preventDefault()

    setLocale(alternativeLocale)
    location.reload() // eslint-disable-line no-restricted-globals
  }

  const label = t('Change to English', {
    lng: normalizeLocale(alternativeLocale)
  })

  return (
    <Link
      last
      data-testid='locale-button'
      icon={<WorldIcon />}
      label={label}
      onClick={onClick}
    ></Link>
  )
}

import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ActionList, MoreIcon } from '@fullfabric/alma-mater'
import { IfPermit } from '@fullfabric/authorization-officer'

import DeleteModal from './DeleteModal'

function MoreOptionsCell({ value, row, data }) {
  const { t } = useTranslation()
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)

  const onSelectDelete = useCallback((evt) => {
    evt.stopPropagation()
    setIsDeleteModalOpen(true)
  }, [])

  const targetId = value
  const rowData = data[row.index]
  const isSegmentInUse = rowData?.is_used

  return (
    <IfPermit to='core.segments.shared_segments_change'>
      <ActionList
        iconButtonProps={{ plain: true }}
        iconButton={<MoreIcon />}
        alignEnd
      >
        <ActionList.Option
          disabled={isSegmentInUse}
          negative={!isSegmentInUse}
          onClick={isSegmentInUse ? () => {} : onSelectDelete}
        >
          <span data-testid='delete-segment-menu-option'>
            {isSegmentInUse
              ? t("Can't delete segment (in use)")
              : t('Delete segment')}
          </span>
        </ActionList.Option>
      </ActionList>
      {isDeleteModalOpen ? (
        <DeleteModal
          isOpen={isDeleteModalOpen}
          targetId={targetId}
          handleClose={() => {
            setIsDeleteModalOpen(false)
          }}
        />
      ) : null}
    </IfPermit>
  )
}

export default MoreOptionsCell

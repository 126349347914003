.react-app ._container_f16tp_1 {
  height: 190px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: var(--space-default);
  border: var(--border-base-lighter);
  border-style: dashed;
  border-radius: 3px;
}

.react-app ._icon_f16tp_13 * {
  fill: var(--ff-color-grey-200);
}
const CODE_ERROR_MESSAGES = [
  'Findes',
  'exists',
  'existe',
  'is al in gebruik',
  'existerar redan'
]

const isExistingCodeError = (error) => {
  if (!error?.data || !error?.data['institutions/subject']?.code) {
    return false
  }

  return CODE_ERROR_MESSAGES.includes(
    error.data['institutions/subject']?.code[0]
  )
}

export default isExistingCodeError

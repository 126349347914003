import React from 'react'
import { Trans } from 'react-i18next'

import moment from 'moment'

import { Field, Text } from '@fullfabric/alma-mater'

import { useSelectedData } from 'apps/Imports/contexts/selected-data'
import { useDataProcessingPolicy } from 'apps/Imports/hooks/useDataProcessingPolicy'

import styles from './styles.module.scss'

const ConsentDetailsSection = () => {
  const {
    data: { consentData },
    onDataChanged
  } = useSelectedData()
  const dataProcessingConsent = useDataProcessingPolicy()

  const onConsentDataChanged = React.useCallback(
    (field, value) => {
      onDataChanged('consentData', { ...consentData, [field]: value })
    },
    [onDataChanged, consentData]
  )

  if (consentData.consentType !== 'same') return null

  const isDataProcessingConsentSelected =
    !dataProcessingConsent?.published || consentData.dataProcessingConsent

  return (
    <>
      <Text
        type='h4'
        marginBottom='small'
        marginTop='more'
        fontColor={
          isDataProcessingConsentSelected
            ? 'text-base-darkest'
            : 'text-base-darker'
        }
      >
        <Trans>Consent Details</Trans>
      </Text>

      <Field
        id='sourceOfConsent'
        type='textarea'
        value={consentData.sourceOfConsent}
        onChange={onConsentDataChanged}
        className={styles.consentFields}
        inputOptions={{ disabled: !isDataProcessingConsentSelected }}
        label={
          <Text
            fontColor={
              isDataProcessingConsentSelected
                ? 'text-base-darkest'
                : 'text-base-darker'
            }
          >
            <Trans>Source of consent</Trans>
          </Text>
        }
        help={
          <Trans>
            State where you received these persons' consent. Please note that
            this text will be displayed on marketing communications to these
            persons.
          </Trans>
        }
      />

      <Field
        id='dateOfConsent'
        type='date'
        onChange={(field, value) => {
          onConsentDataChanged(
            field,
            moment.isMoment(value) ? value.format() : value
          )
        }}
        value={
          moment(consentData.dateOfConsent).isValid()
            ? moment(consentData.dateOfConsent)
            : consentData.dateOfConsent
        }
        className={styles.consentFields}
        inputOptions={{
          className: styles.dateOfConsent,
          dateFormat: 'dddd, MMM Do, YYYY',
          timeFormat: '[at] HH:mm',
          inputProps: { disabled: !isDataProcessingConsentSelected }
        }}
        label={
          <Text
            fontColor={
              isDataProcessingConsentSelected
                ? 'text-base-darkest'
                : 'text-base-darker'
            }
          >
            <Trans>Date of consent</Trans>
          </Text>
        }
        help={<Trans>State when these persons' gave their consent</Trans>}
      />
    </>
  )
}

export default ConsentDetailsSection

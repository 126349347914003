import 'css-chunk:src/apps/ContentPages/components/applications-list/styles.module.scss';export default {
  "react-app": "react-app",
  "row": "_row_v3y5l_1",
  "status": "_status_v3y5l_8",
  "small": "_small_v3y5l_13",
  "rowSmall": "_rowSmall_v3y5l_18",
  "last": "_last_v3y5l_24",
  "contentCol": "_contentCol_v3y5l_27",
  "first": "_first_v3y5l_33",
  "arrowCol": "_arrowCol_v3y5l_36",
  "classOfIdsRow": "_classOfIdsRow_v3y5l_40",
  "colNoGutter": "_colNoGutter_v3y5l_45",
  "paddingWithBorder": "_paddingWithBorder_v3y5l_50",
  "subTitle": "_subTitle_v3y5l_57",
  "smallest": "_smallest_v3y5l_61",
  "label": "_label_v3y5l_65",
  "centeredHolder": "_centeredHolder_v3y5l_69",
  "emptyMessageMobile": "_emptyMessageMobile_v3y5l_75",
  "loadingMobile": "_loadingMobile_v3y5l_79",
  "informationIcon": "_informationIcon_v3y5l_83"
};
import React from 'react'

import { Button, Col, Row, Text } from '@fullfabric/alma-mater'

const PolicyAcceptanceFeedback = ({
  icon,
  title,
  subtitle,
  nextActionText,
  onNextClick
}) => (
  <Row>
    <Col xs={12} className='marginBottom-small'>
      {icon}
    </Col>
    <Col xs={12}>
      <Text type='h1' fontColor='text-base-darkest' marginBottom='default'>
        {title}
      </Text>
    </Col>

    <Col xs={12}>
      <Text marginBottom='huge'>{subtitle}</Text>
    </Col>

    {nextActionText && (
      <Col xs={12}>
        <Button primary onClick={onNextClick}>
          {nextActionText}
        </Button>
      </Col>
    )}
  </Row>
)

export default PolicyAcceptanceFeedback

{
  "Email": "Email",
  "Email address is invalid": "Email address is invalid",
  "I accept the privacy policy": "I accept the privacy policy",
  "Privacy Policy": "Privacy Policy",
  "Please accept the privacy policy to continue": "Please accept the privacy policy to continue",
  "Welcome back": "Welcome back",
  "Please enter your password for security reasons.": "Please enter your password for security reasons.",
  "Email address ": "Email address ",
  "Password": "Password",
  "Forgot your password?": "Forgot your password?",
  "An email has been sent to  with an authentication link that will take you to your application.": "An email has been sent to  with an authentication link that will take you to your application.",
  "Once you have accessed the authentication link you can close this page.": "Once you have accessed the authentication link you can close this page.",
  "Didn’t receive the email?": "Didn’t receive the email?",
  "Resend email with authentication link": "Resend email with authentication link",
  "Email sent": "Email sent",
  "Welcome! Let's start your new application.": "Welcome! Let's start your new application.",
  "Welcome! Let's start your new registration.": "Welcome! Let's start your new registration.",
  "Please enter the following details so that you can revisit your application if you exit it.": "Please enter the following details so that you can revisit your application if you exit it.",
  "Please enter the following details so that you can revisit your registration if you exit it.": "Please enter the following details so that you can revisit your registration if you exit it.",
  "First name": "First name",
  "Last name": "Last name",
  "Back": "Back",
  "Continue to application": "Continue to application",
  "Continue to registration": "Continue to registration",
  "Data Protection regulation": "Data Protection regulation",
  "Someone already registered with that email address": "Someone already registered with that email address",
  "Email address can't be blank": "Email address can't be blank",
  "Continue": "Continue",
  "Participant details": "Participant details",
  "Please enter the details of the participant": "Please enter the details of the participant",
  "Registration already exists": "Registration already exists",
  "Application already exists": "Application already exists",
  "There are fields with errors": "There are fields with errors",
  "Please correct the fields with errors": "Please correct the fields with errors",
  "First name can't be blank": "First name can't be blank",
  "Last Name": "Last Name",
  "Last name can't be blank": "Last name can't be blank",
  "Your employment details": "Your employment details",
  "To register on behalf of someone else we need more information on your current employment details.": "To register on behalf of someone else we need more information on your current employment details.",
  "There was an error in your submission, please try again in a few seconds. If the issue persists, please contact us.": "There was an error in your submission, please try again in a few seconds. If the issue persists, please contact us.",
  "The link has expired.": "The link has expired.",
  "Please re-enter your details to receive a new authentication email.": "Please re-enter your details to receive a new authentication email.",
  "Please enter your email address so that we can retrieve your information if you have visited before.": "Please enter your email address so that we can retrieve your information if you have visited before.",
  "Please select an option": "Please select an option",
  "I am starting a registration for myself": "I am starting a registration for myself",
  "I am starting an application for myself": "I am starting an application for myself",
  "I am starting a registration on behalf of someone else": "I am starting a registration on behalf of someone else",
  "I am starting an application on behalf of someone else": "I am starting an application on behalf of someone else",
  "I am continuing an existing registration": "I am continuing an existing registration",
  "I am continuing an existing application": "I am continuing an existing application",
  "An email has been sent to <1>{{email}}</1> with an authentication link that will take you to your application.": "An email has been sent to <1>{{email}}</1> with an authentication link that will take you to your application.",
  "An email has been sent to {{email}} with an authentication link that will take you to your application.": "An email has been sent to {{email}} with an authentication link that will take you to your application."
}

import React from 'react'

import styles from './styles.module.scss'

function Container({ measurerRef, children }) {
  return (
    <div ref={measurerRef}>
      <svg className={styles.chartContainer}>{children}</svg>
    </div>
  )
}

export default Container

import 'css-chunk:src/apps/ContentPages/components/payments-drawer/fee-info/header/styles.module.scss';export default {
  "react-app": "react-app",
  "highlight": "_highlight_106aa_1",
  "overdue": "_overdue_106aa_7",
  "paid": "_paid_106aa_11",
  "content": "_content_106aa_15",
  "mobile": "_mobile_106aa_21",
  "stackMore": "_stackMore_106aa_25",
  "stackDefault": "_stackDefault_106aa_29",
  "stackSmall": "_stackSmall_106aa_33",
  "stackTiny": "_stackTiny_106aa_37"
};
.react-app ._container_1sxaq_1 {
  display: flex;
  align-items: center;
  padding: var(--space-less) 0;
  padding-left: var(--space-more);
  padding-right: var(--space-default);
}
.react-app ._container_1sxaq_1._tabletNav_1sxaq_8 {
  padding-left: var(--space-big);
  padding-right: var(--space-more);
}
.react-app ._container_1sxaq_1 p {
  margin: 0 !important;
}

.react-app ._iconWrapper_1sxaq_16 {
  height: 16px;
  margin-right: var(--space-default);
}
.react-app ._iconWrapper_1sxaq_16 svg {
  margin-left: 0 !important;
}
.react-app ._iconWrapper_1sxaq_16._mobileNav_1sxaq_23 {
  margin-right: var(--space-less);
}

.react-app ._labelWrapper_1sxaq_27 {
  color: var(--ff-color-text-base-darkest);
  text-align: left;
}
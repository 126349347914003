import React from 'react'
import { Trans } from 'react-i18next'

import { Field } from '@fullfabric/alma-mater'

import { useStudyPlan } from 'apps/StudyPlans/contexts/StudyPlanData'
import {
  useOnChangeSubjectEditionData,
  useSubjectEditionData
} from 'apps/StudyPlans/contexts/SubjectEditionData'

const CategoryField = () => {
  const studyPlan = useStudyPlan()
  const subjectData = useSubjectEditionData()
  const onChange = useOnChangeSubjectEditionData()

  return (
    <Field
      id='category_id'
      type='autocomplete'
      label={<Trans>Category</Trans>}
      value={subjectData.category_id}
      onChange={(id, val) => onChange(id, val || null)}
      inputOptions={{
        isClearable: true,
        placeholder: <Trans>- Select -</Trans>,
        options: (studyPlan?.categories || []).map((category) => ({
          value: category.id,
          label: category.name
        }))
      }}
    />
  )
}

export default CategoryField

import { utils } from '@fullfabric/public-api'

const createStudyPlan = async (classOfId) => {
  const response = await fetch(
    '/affairs/api/study_plans/find_or_create_by_class',
    {
      credentials: 'same-origin',
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ class_of_id: classOfId })
    }
  )

  return await utils.checkResponse(response)
}

export default createStudyPlan

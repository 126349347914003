import { useMutation, useQueryClient } from 'react-query'

import updateCard from 'apps/Inbox/api/updateCard'
import { useCardFilters } from '../contexts/CardFilters'

const useHandleArchive = () => {
  const { cardStatus, cardType } = useCardFilters()
  const queryClient = useQueryClient()

  const { mutate: handleArchiveTaskMutation } = useMutation(
    async ({ card }) => {
      // we're marking the card as archived anyways because there might be an inconsistency between current state and DB
      const updatedCard = await updateCard({
        ...card,
        status: card.status === 'active' ? 'archived' : 'active'
      })

      return { updates: { updatedCard }, card, cardStatus, cardType }
    },
    {
      onMutate: async ({ card }) => {
        const previousCards =
          queryClient.getQueryData(['cards', cardStatus, cardType]) || []

        const newCards = previousCards?.pages.map((page) => {
          const data = page.data.filter((val) => val.id !== card.id)
          return { ...page, data }
        })

        queryClient.setQueriesData(['cards', cardStatus, cardType], (data) => ({
          pages: newCards,
          pageParams: data.pageParams
        }))
        return { previousCards, cardStatus, cardType, card }
      },
      onError: (_err, _data, _context) =>
        queryClient.invalidateQueries(['cards', cardStatus, cardType])
    }
  )

  return handleArchiveTaskMutation
}

export { useHandleArchive }

import React from 'react'

import classNames from 'classnames'
import styles from '../styles.module.scss'

export const BaseRow = ({ children, className, ...props }) => (
  <div {...props} className={classNames(className, styles.baseRow)}>
    {children}
  </div>
)
export const BaseCol = ({ children, className, ...props }) => (
  <div {...props} className={classNames(className, styles.baseCol)}>
    {children}
  </div>
)

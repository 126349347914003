import React from 'react'
import { useTranslation } from 'react-i18next'

import moment from 'moment'

import { Text } from '@fullfabric/alma-mater'

const CreatedByCell = ({ value, data, row }) => {
  const { i18n } = useTranslation()

  const rowData = data[row.index]
  const evaluationCreationDate = rowData.evaluation.created_at
  const createdAtMoment = moment(evaluationCreationDate).locale(i18n.language) // assumes there's always a created_at date
  const createdDate = createdAtMoment?.format('ddd DD MMM, YYYY hh:mm a')

  return (
    <>
      {value}
      <Text tag='div' marginTop='small' type='f6' fontColor='text-base-darker'>
        {createdDate}
      </Text>
    </>
  )
}

export default CreatedByCell

import React from 'react'
import { Trans } from 'react-i18next'

import {
  Button,
  ErrorIcon,
  InfoIcon,
  SuccessIcon,
  Text
} from '@fullfabric/alma-mater'

import { useMergeData } from 'apps/MergeProfiles/contexts/MergeData'
import { getApplicAssociatedContextIds } from 'apps/MergeProfiles/utils/applicsHelper'

import classNames from 'classnames'
import styles from './styles.module.scss'

const CardActions = ({ applic, profile, profileType }) => {
  const {
    mergeData: { applicsFrom },
    updateMergeData
  } = useMergeData()

  const contextIdsToPreselectJourneys = React.useMemo(
    () =>
      getApplicAssociatedContextIds(applic).filter((contextId) =>
        profile.roles.map((r) => r.context_id).includes(contextId)
      ),
    [applic, profile.roles]
  )

  const onDiscardClick = React.useCallback(() => {
    updateMergeData({
      applicsFrom: {
        ...applicsFrom,
        [applic.template_id]: profileType === 'duplicate' ? 'main' : 'duplicate'
      }
    })
  }, [updateMergeData, applicsFrom, profileType, applic])

  const onAddClick = React.useCallback(() => {
    updateMergeData({
      applicsFrom: {
        ...applicsFrom,
        [applic.template_id]: profileType
      }
    })
  }, [updateMergeData, applicsFrom, profileType, applic])

  const hasSelection = !!applicsFrom[applic.template_id]
  const isAdded = applicsFrom[applic.template_id] === profileType
  const isDiscarded = hasSelection && !isAdded

  return (
    <div className={styles.cardButtons}>
      {contextIdsToPreselectJourneys.length > 0 && (
        <div className={styles.jouneysNotice}>
          <InfoIcon width={12} height={12} />
          <Text>
            <Trans>
              The action selected here will also be taken on the associated
              journeys.
            </Trans>
          </Text>
        </div>
      )}
      <div>
        <Button
          size='small'
          onClick={onAddClick}
          className={classNames(styles.cardButton, isAdded && styles.added)}
        >
          {isAdded && (
            <SuccessIcon color='text-success' className={styles.buttonIcon} />
          )}
          {profileType === 'duplicate' ? (
            <Trans>Add application</Trans>
          ) : (
            <Trans>Keep application</Trans>
          )}
        </Button>
        <Button
          size='small'
          onClick={onDiscardClick}
          className={classNames(
            styles.cardButton,
            isDiscarded && styles.discarded
          )}
        >
          {isDiscarded && (
            <ErrorIcon color='text-alert' className={styles.buttonIcon} />
          )}
          {profileType === 'duplicate' ? (
            <Trans>Discard application</Trans>
          ) : (
            <Trans>Delete application</Trans>
          )}
        </Button>
      </div>
    </div>
  )
}

export default CardActions

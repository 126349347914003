import React from 'react'
import { Trans } from 'react-i18next'

import { Button } from '@fullfabric/alma-mater'

import { useImportDocument } from 'apps/Imports/contexts/import-document'
import { useSelectedData } from 'apps/Imports/contexts/selected-data'
import { useStepsCompleted, useStepState } from 'apps/Imports/contexts/steps'
import { useDataProcessingPolicy } from 'apps/Imports/hooks/useDataProcessingPolicy'
import { useImportContext } from 'apps/Imports/hooks/useImportContext'
import { useMarketingPolicy } from 'apps/Imports/hooks/useMarketingPolicy'
import { PROFILE_IMPORT_STEPS } from 'apps/Imports/utils/profile-import/constants'
import { areStepsCompleted } from 'apps/Imports/utils/profile-import/is-step-completed'
import useCreateImportMutation from '../hooks/use-create-import-mutation'
import useGoToStep from '../hooks/use-go-to-step'

import styles from '../styles.module.scss'

const GoToMappingButton = () => {
  const context = useImportContext()
  const marketingPolicy = useMarketingPolicy()
  const dataProcessingPolicy = useDataProcessingPolicy()
  const { data } = useSelectedData()
  const { currentStep, nextStep } = useStepState()
  const { isStepUnlocked, previousStepsFrom } = useStepsCompleted()
  const createImportMutation = useCreateImportMutation(
    PROFILE_IMPORT_STEPS.MAPPING
  )
  const importDocument = useImportDocument()
  const { goToStepPage } = useGoToStep()

  const onBackToMapping = React.useCallback(() => {
    if (!importDocument) {
      createImportMutation.mutate()
    } else {
      goToStepPage(PROFILE_IMPORT_STEPS.MAPPING)
    }
  }, [createImportMutation, goToStepPage, importDocument])

  const mappingUnlocked = isStepUnlocked(PROFILE_IMPORT_STEPS.MAPPING)
  const summaryUnlocked = isStepUnlocked(PROFILE_IMPORT_STEPS.SUMMARY)

  const goToMappingDisabled = !areStepsCompleted(
    previousStepsFrom(PROFILE_IMPORT_STEPS.MAPPING),
    data,
    { context, marketingPolicy, dataProcessingPolicy, importDocument }
  )

  const mappingLoading = createImportMutation.isLoading

  if (currentStep === PROFILE_IMPORT_STEPS.MAPPING) return null
  if (nextStep === PROFILE_IMPORT_STEPS.MAPPING) return null
  if (!mappingUnlocked) return null
  if (summaryUnlocked) return null

  return (
    <Button
      primary
      disabled={goToMappingDisabled}
      loading={mappingLoading}
      onClick={onBackToMapping}
      className={styles.actionButtons}
    >
      <Trans>Go to Mapping</Trans>
    </Button>
  )
}

export default GoToMappingButton

import React from 'react'

import useIsMobileLayout from 'apps/ContentPages/components/payments-page/use-is-payments-mobile-layout'
import FeesTable from '../../../fees-table'
import Header from './header'

import classNames from 'classnames'
import styles from './styles.module.scss'

const PaymentPlanCard = ({ paymentPlan, opts = {} }) => {
  const isInMobileLayout = useIsMobileLayout()

  return (
    <div
      className={classNames(
        styles.container,
        isInMobileLayout && styles.mobile
      )}
    >
      <Header paymentPlan={paymentPlan} opts={opts} />
      <FeesTable
        fees={paymentPlan?.fees}
        discounts={paymentPlan?.discounts}
        paymentPlan={paymentPlan}
        opts={opts}
      />
    </div>
  )
}

export default PaymentPlanCard

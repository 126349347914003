import React from 'react'
import { useTranslation } from 'react-i18next'

import { DropdownInput, Text } from '@fullfabric/alma-mater'

import { useMergeData } from 'apps/MergeProfiles/contexts/MergeData'
import getLocaledLabel from 'apps/MergeProfiles/utils/getLocaledLabel'
import { BaseCol, BaseRow } from '../Base'
import useSmartEmailOptions from './useSmartEmailOptions'

import styles from '../../styles.module.scss'

const EmailRow = ({ field }) => {
  const { i18n } = useTranslation()
  const localizedFieldName = getLocaledLabel(field.locals, i18n.language)

  const {
    mergeData: { smartEmailInfoFrom },
    updateMergeData
  } = useMergeData()

  const onEmailChange = React.useCallback(
    (value) => {
      updateMergeData({
        smartEmailInfoFrom: { ...smartEmailInfoFrom, [field.name]: value }
      })
    },
    [updateMergeData, smartEmailInfoFrom, field]
  )

  const dropdownOptions = useSmartEmailOptions(field)

  return (
    <BaseRow
      className={styles.emailRow}
      role='row'
      aria-label={`${localizedFieldName} profile field`}
    >
      <BaseCol role='cell'>
        <Text type='h5' fontColor='text-base-darkest'>
          {localizedFieldName}
        </Text>
      </BaseCol>

      <BaseCol role='cell' className={styles.emailInputCol}>
        <DropdownInput
          id={field.name}
          aria-label={`${localizedFieldName} selector`}
          value={smartEmailInfoFrom[field.name]}
          options={dropdownOptions}
          onChange={onEmailChange}
        />
      </BaseCol>
    </BaseRow>
  )
}

export default EmailRow

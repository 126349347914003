import React, { useEffect, useState } from 'react'
import { useController, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

import { Field } from '@fullfabric/alma-mater'

import getEvaluationTemplates from 'apps/Evaluations/api/getEvaluationTemplates'
import { useApplicationTemplate } from 'apps/Evaluations/context/ApplicationData'

const EvaluationTemplateField = ({ name }) => {
  const { t } = useTranslation()
  const { control, formState, setValue } = useFormContext()
  const { errors } = formState

  const {
    field: { ref: evaluationTemplateInputRef, ...evaluationTemplateInputProps }
  } = useController({
    name,
    control,
    rules: {
      required: t("Can't be blank")
    }
  })

  const [evalTemplatesForApplicTemplate, setEvalTemplatesForApplicTemplate] =
    useState([])
  const { data: applicTemplateData } = useApplicationTemplate()
  const { data: evalTemplatesData, isLoading: isLoadingEvaluationTemplates } =
    useQuery(['getEvaluationTemplates'], getEvaluationTemplates)

  useEffect(() => {
    let templateOptions = []
    if (
      evalTemplatesData?.length &&
      applicTemplateData?.evaluations_type === 'selected' &&
      applicTemplateData?.evaluations_ids.length
    ) {
      templateOptions = evalTemplatesData.filter(
        (tmpl) => !!applicTemplateData?.evaluations_ids?.includes(tmpl.id)
      )
    } else if (evalTemplatesData?.length) {
      templateOptions = evalTemplatesData
    }
    if (templateOptions.length === 1) {
      setValue('evaluation_template_id', templateOptions[0].id)
    }
    setEvalTemplatesForApplicTemplate(templateOptions)
  }, [setValue, applicTemplateData, evalTemplatesData])

  return (
    <Field
      {...evaluationTemplateInputProps}
      id='new-evaluation-template-field'
      required
      inputRef={evaluationTemplateInputRef}
      type='autocomplete'
      label={t('Evaluation template')}
      error={errors[name]?.message}
      inputOptions={{
        inModal: true,
        options: evalTemplatesForApplicTemplate?.map((template) => ({
          value: template.id,
          label: template.name
        })),
        isLoading: isLoadingEvaluationTemplates
      }}
    />
  )
}

export default EvaluationTemplateField

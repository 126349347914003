import React from 'react'
import { Trans } from 'react-i18next'

import { AlertFullIcon, Text } from '@fullfabric/alma-mater'

import styles from './styles.module.scss'

export default function InvalidCard() {
  return (
    <div className={styles.noticeContainer}>
      <AlertFullIcon color='text-alert' width={24} height={24} />
      <Text
        type='h3'
        fontColor='text-base-darkest'
        className={'marginTop-default'}
      >
        <Trans>Payment link is invalid</Trans>
      </Text>
      <Text type='f4' fontColor='text-base-darkest'>
        <Trans>This payment has been canceled or does not exist.</Trans>
      </Text>
    </div>
  )
}

import React from 'react'
import { Trans } from 'react-i18next'
import { useMutation } from 'react-query'

import {
  AlertFullIcon,
  Button,
  Modal,
  ModalButtons,
  ModalTitle,
  Text
} from '@fullfabric/alma-mater'

import deleteElectiveGroup from 'apps/StudyPlans/api/deleteElectiveGroup'
import { useSelectedPath } from 'apps/StudyPlans/contexts/SelectedPath'
import {
  useSetStudyPlan,
  useStudyPlan
} from 'apps/StudyPlans/contexts/StudyPlanData'

const DeleteElectiveGroupModal = ({ isOpen, onCancel, electiveGroup }) => {
  const studyPlan = useStudyPlan()
  const setStudyPlan = useSetStudyPlan()
  const selectedPath = useSelectedPath()

  const { mutate: deleteGroupMutation, isLoading } = useMutation(
    ({ studyPlanId, selectedPathId, groupId }) => {
      return deleteElectiveGroup(studyPlanId, selectedPathId, groupId)
    },
    {
      onSuccess: (updatedStudyPlan) => {
        onCancel && onCancel()
        setStudyPlan(updatedStudyPlan)
      }
    }
  )
  const onDelete = React.useCallback(() => {
    deleteGroupMutation({
      studyPlanId: studyPlan.id,
      selectedPathId: selectedPath.id,
      groupId: electiveGroup.id
    })
  }, [studyPlan.id, selectedPath.id, electiveGroup.id, deleteGroupMutation])

  const groupHasRegisteredCourses = studyPlan.courses.some((course) => {
    const courseTypeForGroup = course.course_types.find(
      (courseType) => courseType.group_id === electiveGroup.id
    )

    return courseTypeForGroup?.is_registered_in_any_transcript
  })

  const cannotBeDeleted = groupHasRegisteredCourses

  return (
    <Modal
      size={cannotBeDeleted ? 'small' : 'medium'}
      isOpen={isOpen}
      header={
        <ModalTitle
          title={
            <>
              <AlertFullIcon color='text-alert' className='marginRight-small' />
              <Trans>Delete elective group</Trans>
            </>
          }
          onClose={onCancel}
        />
      }
      footer={
        cannotBeDeleted ? (
          <ModalButtons>
            <Button onClick={onCancel}>
              <Trans>Close</Trans>
            </Button>
          </ModalButtons>
        ) : (
          <ModalButtons>
            <Button onClick={onCancel} disabled={isLoading}>
              <Trans>Cancel</Trans>
            </Button>
            <Button onClick={onDelete} loading={isLoading} negative primary>
              <Trans>Delete elective group</Trans>
            </Button>
          </ModalButtons>
        )
      }
      onClose={onCancel}
    >
      {cannotBeDeleted ? (
        <Text tag='p' type='h5' fontColor='text-base-darkest'>
          <Trans>
            It is not possible to remove the elective group since it contains
            courses with registrations.
          </Trans>
        </Text>
      ) : (
        <>
          <Text tag='p' type='h4' fontColor='text-base-darkest'>
            <Trans>Are you ABSOLUTELY sure?</Trans>
          </Text>
          <Text
            tag='p'
            type='f5'
            marginBottom='default'
            fontColor='text-base-darkest'
          >
            <Trans>This action CANNOT be undone.</Trans>
          </Text>
          <Text tag='p' type='h5' fontColor='text-base-darkest'>
            <Trans>This action will:</Trans>
          </Text>
          <Text tag='p' type='f5' marginBottom='small'>
            <Trans>
              Delete the study plan subject from student transcripts where the
              student has not registered to the subject
            </Trans>
          </Text>
          <Text tag='p' type='h5' fontColor='text-base-darkest'>
            <Trans>This action will NOT:</Trans>
          </Text>
          <Text tag='p' type='f5' marginBottom='small'>
            <Trans>
              Remove the study plan subject from student transcripts where the
              student has registered to the subject
            </Trans>
          </Text>
        </>
      )}
    </Modal>
  )
}

export default DeleteElectiveGroupModal

import React from 'react'
import { Trans } from 'react-i18next'
import { useMutation } from 'react-query'

import {
  ArrowRightIcon,
  Card,
  DraftIcon,
  DuplicateIcon,
  Modal,
  ModalTitle,
  Text
} from '@fullfabric/alma-mater'

import createStudyPlan from 'apps/StudyPlans/api/createStudyPlan'
import {
  useClassOf,
  useSetStudyPlan
} from 'apps/StudyPlans/contexts/StudyPlanData'

import styles from './styles.module.scss'

export default function BaseModal({ handleDuplicateStudyPlanClick }) {
  const classOf = useClassOf()
  const setStudyPlan = useSetStudyPlan()

  const { mutate: createStudyPlanMutation } = useMutation(
    () => createStudyPlan(classOf.id),
    {
      onSuccess: (createdStudyPlan) => setStudyPlan(createdStudyPlan)
    }
  )

  const handleNewStudyPlan = () => createStudyPlanMutation()

  return (
    <Modal
      isOpen
      size='medium'
      header={<ModalTitle title={<Trans>Create study plan</Trans>} />}
    >
      <>
        <Card className={styles.clickableCard} onClick={handleNewStudyPlan}>
          <DraftIcon color='text-base-darkest' className={styles.icon} />
          <div className={styles.cardText}>
            <Text
              type='h5'
              fontColor='text-base-darkest'
              className={styles.cardText}
            >
              <Trans>Create an empty study plan</Trans>
            </Text>
            <Text type='f5' fontColor='text-base' className={styles.cardText}>
              <Trans>Manually add all of the information</Trans>
            </Text>
          </div>
          <ArrowRightIcon />
        </Card>
        <Card
          className={styles.clickableCard}
          onClick={handleDuplicateStudyPlanClick}
        >
          <DuplicateIcon color='text-base-darkest' className={styles.icon} />
          <div className={styles.cardText}>
            <Text
              type='h5'
              fontColor='text-base-darkest'
              className={styles.cardText}
            >
              <Trans>Duplicate an existing study plan</Trans>
            </Text>
            <Text type='f5' fontColor='text-base' className={styles.cardText}>
              <Trans>Copy the study plan of another class</Trans>
            </Text>
          </div>
          <ArrowRightIcon />
        </Card>
      </>
    </Modal>
  )
}

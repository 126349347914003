import { utils } from '@fullfabric/public-api'

import { baseTemplateGrapejs } from 'apps/EmailTemplates/constants/baseTemplateGrapejs'

export default async function createEmailTemplate(data) {
  const response = await fetch('/api/email_templates/', {
    credentials: 'same-origin',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      _type: 'Emails::Templates::Shared',
      name: data.name,
      default_subject: data.default_subject,
      editor: data.email_builder,
      ...(data.email_builder === 'grapejs' && { mjml: baseTemplateGrapejs })
    })
  })

  return await utils.checkResponse(response)
}

import React from 'react'

import ChartCard from 'apps/Reports/components/ChartCard'
import { commonStyles } from 'apps/Reports/components/CustomChartElements'
import Chart from './components/Chart'
import ChartTitle from './components/ChartTitle'
import useTimeframeIntervalState from './hooks/useTimeframeIntervalState'

import classNames from 'classnames'
import styles from './styles.module.scss'

const IntakesOverviewChart = () => {
  const {
    isWeeklyTimeframeSelected,
    updateTimeframeInterval,
    timeframeInterval
  } = useTimeframeIntervalState()

  return (
    <ChartCard>
      <ChartTitle
        onChangeTimeframeInterval={updateTimeframeInterval}
        isWeeklyTimeframeSelected={isWeeklyTimeframeSelected}
      />
      <div
        className={classNames([
          commonStyles.chartContainer,
          styles.chartContainer
        ])}
      >
        <Chart timeframeInterval={timeframeInterval} />
      </div>
    </ChartCard>
  )
}

export default IntakesOverviewChart

import React from 'react'
import { Trans } from 'react-i18next'

import { Text } from '@fullfabric/alma-mater'

import {
  getRoleContextName,
  getRoleContextSubtitle
} from 'apps/MergeProfiles/utils/journeyHelpers'
import CardTitle from '../JourneyCard/Title'

import styles from './styles.module.scss'

const JourneyNotOnMainProfileCard = ({ context }) => {
  return (
    <div className={styles.notOnMainProfileCard}>
      <CardTitle
        title={getRoleContextName(context)}
        subtitle={getRoleContextSubtitle(context)}
      />

      <Text marginTop='default' paddingLeftRight='more'>
        <Trans>Main profile doesn’t have this journey</Trans>
      </Text>
    </div>
  )
}

export default JourneyNotOnMainProfileCard

import React from 'react'

import { ErrorIcon, Row, Table, Text } from '@fullfabric/alma-mater'

import styles from '../styles.module.scss'

const ErrorRow = ({ error }) => {
  return (
    <Table.Row tag='div' role='row' aria-label='error row'>
      <Table.Data tag='div' align='center' className={styles.errorRowCell}>
        <Row nogutter align='center' justify='center'>
          <ErrorIcon color='text-alert' />
          <Text tag='span' fontColor='text-alert' marginLeft='tiny'>
            {error.message}
          </Text>
        </Row>
      </Table.Data>
    </Table.Row>
  )
}

export default ErrorRow

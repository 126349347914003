import React from 'react'
import { Trans } from 'react-i18next'

import { CheckboxInput } from '@fullfabric/alma-mater'

const COOKIES_TRANSLATION = {
  analytical: <Trans>Analytical</Trans>,
  strictly_necessary: <Trans>Strictly necessary</Trans>,
  functional: <Trans>Functional</Trans>,
  advertising: <Trans>Advertising</Trans>
}

const CookieTypeCheckbox = ({ checked, cookieType, onChange }) => {
  const handleChanged = React.useCallback(
    (value) => {
      onChange(cookieType, value)
    },
    [cookieType, onChange]
  )

  return (
    <CheckboxInput
      key={cookieType}
      id={cookieType}
      label={COOKIES_TRANSLATION[cookieType]}
      checked={cookieType === 'strictly_necessary' || checked}
      disabled={cookieType === 'strictly_necessary'}
      onChange={handleChanged}
    />
  )
}

export default CookieTypeCheckbox

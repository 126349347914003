import React from 'react'
import { Route } from 'react-router'
import { Switch } from 'react-router-dom'

import EmailTemplatesTablePage from 'apps/EmailTemplates/pages/EmailTemplatesTablePage'
import EmailTemplateUsage from 'apps/EmailTemplates/pages/EmailTemplateUsage'
import {
  EMAIL_TEMPLATE_ROUTE,
  SHARED_EMAIL_TEMPLATES_ROUTE,
  USAGE_EMAIL_TEMPLATE_ROUTE
} from './constants'
import EmailEditorPage from './pages/EmailEditorPage'

export default function EmailTemplatesRoutes() {
  return (
    <Switch>
      <Route
        exact
        path={SHARED_EMAIL_TEMPLATES_ROUTE}
        component={EmailTemplatesTablePage}
      />
      <Route
        exact
        path={USAGE_EMAIL_TEMPLATE_ROUTE}
        component={EmailTemplateUsage}
      />
      <Route exact path={EMAIL_TEMPLATE_ROUTE} component={EmailEditorPage} />
    </Switch>
  )
}

import React from 'react'
import { Trans } from 'react-i18next'

import { Field } from '@fullfabric/alma-mater'

const ERROR_MESSAGES = {
  not_a_number: <Trans>Must be a number</Trans>
}

const CourseCreateFormAcademicDetails = ({ data, errors, onChange }) => (
  <form aria-label='course academic details data form'>
    <Field
      id='credits'
      type='text'
      label={<Trans>Credits</Trans>}
      error={ERROR_MESSAGES[errors.credits]}
      value={data.credits ? data.credits.toString() : ''}
      onChange={onChange}
    />

    <Field
      id='hours'
      type='text'
      label={<Trans>Hours</Trans>}
      error={ERROR_MESSAGES[errors.hours]}
      value={data.hours ? data.hours.toString() : ''}
      onChange={onChange}
    />

    <Field
      id='max_number_absences'
      type='text'
      label={<Trans>Max number of absences</Trans>}
      error={ERROR_MESSAGES[errors.max_number_absences]}
      value={
        data.max_number_absences ? data.max_number_absences.toString() : ''
      }
      onChange={onChange}
    />
  </form>
)

export default CourseCreateFormAcademicDetails

import { combineReducers } from 'redux'

import actionTypes from 'apps/Courses/actions/types'

const data = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.openArchiveCourseModal:
      return { ...action.payload.course }
    case actionTypes.archiveCourse.success:
      return {}
    default:
      return state
  }
}

const modalOpen = (state = false, action) => {
  switch (action.type) {
    case actionTypes.openArchiveCourseModal:
      return action.payload.open
    case actionTypes.archiveCourse.success:
      return false
    default:
      return state
  }
}

const inProgress = (state = false, action) => {
  switch (action.type) {
    case actionTypes.archiveCourse.start:
      return true
    case actionTypes.archiveCourse.success:
    case actionTypes.archiveCourse.error:
      return false
    default:
      return state
  }
}

export default combineReducers({ data, inProgress, modalOpen })

import React, { createContext, useContext } from 'react'
import { useQuery } from 'react-query'

import {
  useCurrentUser,
  usePermission
} from '@fullfabric/authorization-officer'

import getEvaluation from 'apps/Evaluations/api/getEvaluation'

const EvaluationContext = createContext(null)

const useEvaluation = () => {
  return useContext(EvaluationContext)
}
const EvaluationDataProvider = ({ evaluationId, children }) => {
  const {
    data: evaluationData,
    isLoading: isLoadingEvaluationData,
    error: evaluationDataError
  } = useQuery(['getEvaluation', evaluationId], () =>
    getEvaluation(evaluationId)
  )
  const user = useCurrentUser()
  const hasChangePermission = usePermission('evaluations.evaluations_change')
  const isTeamEvaluation = evaluationData?.team_evaluation
  const userIsAsignee = evaluationData?.assigned_to_id === user?.id
  const canChangeEvaluation =
    user.is_admin ||
    (hasChangePermission && (isTeamEvaluation || userIsAsignee))

  return (
    <EvaluationContext.Provider
      value={{
        evaluationData,
        isLoadingEvaluationData,
        evaluationDataError,
        canChangeEvaluation,
        hasChangePermission
      }}
    >
      {children}
    </EvaluationContext.Provider>
  )
}

export default EvaluationContext
export { useEvaluation, EvaluationDataProvider }

.react-app ._marketingOptionCol_mzhvu_1 {
  margin-top: var(--space-small);
}

.react-app ._marketingOptionCheckbox_mzhvu_5 {
  display: flex;
}
.react-app ._marketingOptionCheckbox_mzhvu_5 label {
  text-align: left;
}
.react-app ._marketingOptionCheckbox_mzhvu_5 input[type=checkbox],
.react-app ._marketingOptionCheckbox_mzhvu_5 input[type=radio] {
  vertical-align: super;
}

.react-app ._noCommsCheckbox_mzhvu_16 {
  margin-top: var(--space-default);
}
import React from 'react'
import { Trans } from 'react-i18next'

import { Button } from '@fullfabric/alma-mater'

import CardContent from 'apps/StudyPlans/components/CardContent'
import CardInfo from 'apps/StudyPlans/components/CardInfo'
import { useSubjectEditionData } from 'apps/StudyPlans/contexts/SubjectEditionData'
import { useSetSubjectSectionsBeingEdited } from 'apps/StudyPlans/contexts/SubjectSectionBeingEdited'
import subjectAccountabilityValues from 'apps/StudyPlans/utils/subjectAccountabilityValues'

const SubjectFinalGradeDisplay = () => {
  const data = useSubjectEditionData()
  const setSubjectBeingEdited = useSetSubjectSectionsBeingEdited()
  const onClickEdit = React.useCallback(() => {
    setSubjectBeingEdited((sections) => ({ ...sections, finalGrade: true }))
  }, [setSubjectBeingEdited])

  const finalGradeAccountability = subjectAccountabilityValues.find(
    ({ value }) => value === data.counts_towards_curricular_grade
  )
  const finalCreditsAccountability = subjectAccountabilityValues.find(
    ({ value }) => value === data.counts_towards_total_credits
  )

  return (
    <CardContent>
      <CardInfo
        title={<Trans>Counts toward final grade?</Trans>}
        main={finalGradeAccountability && finalGradeAccountability.label}
        containerClassName='marginBottom-default'
      />
      <CardInfo
        title={<Trans>Counts toward final credits awarded?</Trans>}
        main={finalCreditsAccountability && finalCreditsAccountability.label}
      />
      <div className='marginTop-more'>
        <Button size='small' onClick={() => onClickEdit(data)}>
          <Trans>Edit</Trans>
        </Button>
      </div>
    </CardContent>
  )
}

export default SubjectFinalGradeDisplay

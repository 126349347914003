import Pusher from 'pusher-js'

let pusher = null

export const pusherConfig = (pusherCfg) => {
  if (pusher) {
    return pusher
  }
  pusher = new Pusher(pusherCfg.key, {
    cluster: pusherCfg.cluster,
    authEndpoint: '/api/pusher/auth',
    encrypted: pusherCfg.encrypted
  })
  return pusher
}

export default pusherConfig

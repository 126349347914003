import { ALLOWED_FIELD_TYPES } from 'apps/MergeProfiles/utils/constants'
import isFieldValueEqualForProfiles from 'apps/MergeProfiles/utils/isFieldValueEqualForProfiles'

const getFieldsWithDifferentValues = (schema, sourceProfile, targetProfile) => {
  const fields = schema.sections
    .map((section) => section.fields)
    .reduce((acc, fields) => [...acc, ...fields], [])

  return fields
    .filter((field) => ALLOWED_FIELD_TYPES.includes(field.type))
    .filter(
      (field) =>
        !isFieldValueEqualForProfiles(field, sourceProfile, targetProfile)
    )
}

export default getFieldsWithDifferentValues

const sendVerificationEmail = async () => {
  return fetch('/api/accounts/verification', {
    credentials: 'same-origin'
  }).then((result) => {
    if (result.ok) {
      return result.json()
    }
    throw new Error(result.statusText)
  })
}

export default sendVerificationEmail

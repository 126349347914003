import React from 'react'

import { isEmpty } from 'lodash-es'

import { Text } from '@fullfabric/alma-mater'

import WidgetSection from 'apps/ContentPages/components/widget-section'
import Placeholder from './placeholder'
import TemplateInfo from './template-info'
import { TemplateInfoProvider } from './template-info-context'
import { TypeOfWidgetProvider } from './type-of-widget-context'
import useApplicationTemplatesData from './use-application-templates-data'

const BaseApplicationsWidget = ({
  widgetId,
  templateIds,
  emptyMessage,
  forRegistrations
}) => {
  const { isLoading, templatesData } = useApplicationTemplatesData(
    templateIds,
    forRegistrations
  )

  if (isEmpty(templateIds)) {
    return (
      <WidgetSection last>
        <Text type='f4' fontColor='text-base-darkest'>
          {emptyMessage}
        </Text>
      </WidgetSection>
    )
  }

  if (isLoading) {
    return <Placeholder />
  }

  return (
    <TypeOfWidgetProvider
      isRegistration={forRegistrations}
      isApplication={!forRegistrations}
    >
      {templatesData.map(
        (
          { template, linkedApplication, linkedApplications, classOfTemplate },
          index
        ) => (
          <WidgetSection
            key={template.id}
            last={index === templatesData.length - 1}
          >
            <TemplateInfoProvider
              template={template}
              linkedApplication={linkedApplication}
              linkedApplications={linkedApplications}
              classOfTemplate={classOfTemplate}
            >
              <TemplateInfo widgetId={widgetId} />
            </TemplateInfoProvider>
          </WidgetSection>
        )
      )}
    </TypeOfWidgetProvider>
  )
}

export default BaseApplicationsWidget

import React, { forwardRef } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Button, Link, Text } from '@fullfabric/alma-mater'

import useIsMobileLayout from '../../../hooks/use-is-mobile-layout'

import classNames from 'classnames'
import styles from '../styles.module.scss'

const FooterDisplay = forwardRef(
  ({ cookieText, cookieBtnText, acceptAllCookiesAndDismiss }, ref) => {
    const isMobileLayout = useIsMobileLayout()
    const { t } = useTranslation()

    return (
      <div
        ref={ref}
        role='dialog'
        aria-label={t('Cookie policy')}
        className={classNames([
          styles.cookieBannerContainer,
          isMobileLayout && styles.small
        ])}
      >
        <div className={styles.flexCols}>
          <div xs='content' className={styles.textCol}>
            <Text
              type='f4'
              tag='span'
              fontColor='text-base-lightest'
              dangerouslySetInnerHTML={cookieText}
              className={styles.cookieText}
            />

            <Text type='f4' fontColor='text-base-lightest' tag='span'>
              {' '}
              <Link href='/policies/cookies' className={styles.noEndMargin}>
                <Text
                  type='f4'
                  tag='span'
                  fontColor='text-base-lightest'
                  className={styles.linkText}
                >
                  <Trans>
                    Click here to manage your cookie preferences and for further
                    information
                  </Trans>
                </Text>
              </Link>
              .
            </Text>
          </div>

          <div className={styles.buttonCol}>
            <div>
              <Button size='small' onClick={acceptAllCookiesAndDismiss}>
                <p dangerouslySetInnerHTML={cookieBtnText} />
              </Button>
            </div>
          </div>
        </div>
      </div>
    )
  }
)

export default FooterDisplay

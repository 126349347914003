import React from 'react'
import { Trans } from 'react-i18next'

import { BoxShadow, Text } from '@fullfabric/alma-mater'

import { useProfileSchema } from 'apps/MergeProfiles/contexts/ProfileSchema'
import EmailRow from './Rows/Email'

import styles from './styles.module.scss'

const SmartEmailSection = () => {
  const { smartEmailFields } = useProfileSchema()

  return (
    <BoxShadow
      type='0'
      className={styles.smartEmailSection}
      bgColor='base-light'
      rounded
      role='rowgroup'
    >
      <Text
        type='f3'
        fontColor='text-base-darkest'
        className={styles.smartEmailTitle}
      >
        <Trans>Smart email addresses</Trans>
      </Text>

      {smartEmailFields.map((field) => (
        <EmailRow
          key={field.id}
          field={field}
          allSmartEmailFields={smartEmailFields}
          displayNoValueOption={field.name !== 'email'}
        />
      ))}
    </BoxShadow>
  )
}

export default SmartEmailSection

import React from 'react'

import { Col, LoadingPlaceholder, Row } from '@fullfabric/alma-mater'

import ProductCard from '../../product-card'

const LoadingCard = () => {
  return (
    <ProductCard.Card>
      <LoadingPlaceholder type='h3' width='50%' />
      <LoadingPlaceholder type='h5' width='70%' />
      <Row>
        <Col xs={6} style={{ display: 'flex', flexDirection: 'column' }}>
          <LoadingPlaceholder marginTop='default' type='h6' width='60%' />
          <LoadingPlaceholder type='f5' />
        </Col>
        <Col xs={6} style={{ display: 'flex', flexDirection: 'column' }}>
          <LoadingPlaceholder marginTop='default' type='h6' width='60%' />
          <LoadingPlaceholder type='f5' />
        </Col>
      </Row>
      <Row>
        <Col xs={6} style={{ display: 'flex', flexDirection: 'column' }}>
          <LoadingPlaceholder marginTop='default' type='h6' width='60%' />
          <LoadingPlaceholder type='f5' />
        </Col>
        <Col xs={6} style={{ display: 'flex', flexDirection: 'column' }}>
          <LoadingPlaceholder marginTop='default' type='h6' width='60%' />
          <LoadingPlaceholder type='f5' />
        </Col>
      </Row>
      <Row>
        <Col xs={6} style={{ display: 'flex', flexDirection: 'column' }}>
          <LoadingPlaceholder marginTop='default' type='h6' width='60%' />
          <LoadingPlaceholder type='f5' />
        </Col>
      </Row>
    </ProductCard.Card>
  )
}

export default LoadingCard

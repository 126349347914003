import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import './index.css'

import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'

import * as serviceWorker from 'serviceWorker'

import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'

import FullScreenError from 'shared/components/FullScreenError'
import ReactQueryClientProvider from 'shared/utils/react-query-client-provider'

import App from 'apps/AppRoot'

if (process.env.NODE_ENV !== 'production') {
  const axe = require('@axe-core/react')
  axe(React, ReactDOM, 1000)
}

export function render() {
  if (window.top && window.top.document.title !== document.title) {
    window.top.document.title = document.title
  }

  if (process.env.NODE_ENV !== 'production') {
    Sentry.init({
      dsn: 'https://8aeb690b7139423f9273e9da3d090778@o2252.ingest.sentry.io/32262',
      integrations: [new BrowserTracing()],

      // We recommend adjusting this value in production, or using tracesSampler
      // for finer control.
      //
      // Dev note: this app isn't used a lot, so I kept it at 1.0.
      tracesSampleRate: 1.0
    })
  }

  ReactDOM.render(
    <React.StrictMode>
      <Sentry.ErrorBoundary
        fallback={
          <FullScreenError errorMessage='An error has occurred. If it continues, please contact support.' />
        }
      >
        <ReactQueryClientProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </ReactQueryClientProvider>
      </Sentry.ErrorBoundary>
    </React.StrictMode>,
    document.getElementById('root')
  )

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA
  serviceWorker.unregister()
}

import React, { useState } from 'react'
import { Trans } from 'react-i18next'

import { Button } from '@fullfabric/alma-mater'

import AddCourseModal from 'apps/Applics/components/StaffSubjectSelection/AddCourseModal'
import { useCoursesData } from 'apps/Applics/contexts/StaffSubjectSelection/StudyPlanData'
import { getSelectedPath } from 'apps/Applics/utils/getSelectedPath'

const compareStrings = (a, b) => (a > b ? 1 : b > a ? -1 : 0)

const AddCoursesAction = ({ studyPlan, applic }) => {
  const [open, setOpen] = useState(false)
  const availableCoursesForStudyPlan = useCoursesData()

  const selectedPath = getSelectedPath(applic, studyPlan?.paths)

  const studyPlanPathSubjects =
    studyPlan?.courses.filter((course) =>
      course.course_types?.find(
        (courseType) => courseType.path_id === selectedPath.id
      )
    ) || []

  const selectedCourses = applic?.primary_choice_class_study_plan_courses || []

  const courseSelection = availableCoursesForStudyPlan
    .sort((a, b) => compareStrings(a.full_name, b.full_name))
    .map((course) => {
      const spSubjectForCourse = studyPlanPathSubjects.find((spSubject) => {
        const allowsSpecificCourse = !!spSubject.units?.find(
          (unit) => unit.id === course.id
        )

        const allowsAllCoursesForSubject =
          !allowsSpecificCourse && spSubject.course_id === course.subject_id

        return allowsSpecificCourse || allowsAllCoursesForSubject
      })

      return { course, studyPlanSubject: spSubjectForCourse }
    })
    .filter(({ studyPlanSubject }) => studyPlanSubject)
    .map(({ course, studyPlanSubject }) => ({
      course,
      studyPlanSubject,
      courseType: studyPlanSubject.course_types.find(
        (type) => type.path_id === selectedPath.id
      )
    }))
    .sort((a, b) => compareStrings(a.courseType.type, b.courseType.type))
    .map(({ course, studyPlanSubject, courseType }) => {
      const isSelected = !!selectedCourses.find(
        (selected) => selected.course_id === course.id
      )

      return {
        name: `${course.full_name} - ${courseType?.type}`,
        selected: isSelected,
        unitId: course.id,
        studyPlanSubjectId: studyPlanSubject.id
      }
    })

  const closeModal = () => {
    setOpen(false)
  }

  const openModal = () => {
    setOpen(true)
  }

  return (
    <>
      <Button
        primary
        disabled={!courseSelection}
        role='openAdd'
        onClick={openModal}
      >
        <Trans>Add Course</Trans>
      </Button>
      <AddCourseModal
        applic={applic}
        courseSelection={courseSelection}
        isOpen={open}
        handleClose={closeModal}
      />
    </>
  )
}

export default AddCoursesAction

import { useQueryClient } from 'react-query'

import { cloneDeep } from 'lodash'

export function usePagedQueriesUpdater(key) {
  const queryClient = useQueryClient()

  const updater = (cb) => {
    queryClient.setQueriesData(key, (oldData) => {
      const newData = cloneDeep(oldData)
      newData.pages.forEach((page, idx) => cb(page, idx))
      return newData
    })
  }

  return updater
}

import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'

import { Button, PlainButton, Row } from '@fullfabric/alma-mater'

import { useReferrerPath } from 'apps/Applics/contexts/TemplateContextContext'

import styles from './styles.module.scss'

const TemplateWizardNavigation = ({
  targetFormId,
  onNext,
  previousStep,
  isSubmitting
}) => {
  const { t } = useTranslation()
  const history = useHistory()
  const referrerPageHref = useReferrerPath()

  const advanceButtonProps = targetFormId
    ? {
        type: 'submit',
        form: targetFormId
      }
    : {
        onClick: onNext
      }

  return (
    <Row
      gutterWidth={0}
      nogutter
      justify='between'
      className={styles.container}
    >
      <PlainButton
        type='button'
        disabled={isSubmitting}
        onClick={() => {
          window.location.assign(referrerPageHref)
        }}
      >
        {t('Cancel')}
      </PlainButton>
      <div>
        {typeof previousStep === 'number' ? (
          <Button
            role='button'
            disabled={isSubmitting}
            onClick={() => {
              history.push(`/templates/new/step/${previousStep}`)
            }}
            marginRight='small'
          >
            {t('Back')}
          </Button>
        ) : null}
        <Button loading={isSubmitting} primary {...advanceButtonProps}>
          {t('Next')}
        </Button>
      </div>
    </Row>
  )
}

export default TemplateWizardNavigation

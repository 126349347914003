import React from 'react'

import { useMergeData } from 'apps/MergeProfiles/contexts/MergeData'
import { isClassOrCourseRole } from 'apps/MergeProfiles/utils/journeyHelpers'
import JourneySummaryRow from './JourneySummaryRow'
import NoJourneysText from './NoJourneysText'

import styles from './styles.module.scss'

const JourneysTab = () => {
  const {
    mergeData: { targetProfile, journeysFrom }
  } = useMergeData()

  const targetProfileJourneyContextIds = targetProfile.roles
    .filter(isClassOrCourseRole)
    .map((role) => role.context_id)

  const targetProfileJourneyContextIdsAfterMerge = Array.from(
    new Set([...Object.keys(journeysFrom), ...targetProfileJourneyContextIds])
  )

  if (targetProfileJourneyContextIdsAfterMerge.length === 0)
    return <NoJourneysText />

  return (
    <div className={styles.journeysTabContainer} role='rowgroup'>
      {targetProfileJourneyContextIdsAfterMerge.map((contextId) => (
        <JourneySummaryRow key={contextId} contextId={contextId} />
      ))}
    </div>
  )
}

export default JourneysTab

import React from 'react'
import { useTranslation } from 'react-i18next'

import { useCurrentUser, useSettings } from '@fullfabric/authorization-officer'

import normalizeLocale from 'shared/utils/normalizeLocale'

import { getInitialLocale } from 'apps/Policies/utils/locales'

export const SelectedLocaleContext = React.createContext()

export const useSelectedLocale = () =>
  React.useContext(SelectedLocaleContext)?.selectedLocale

export const useChangeSelectedLocale = () =>
  React.useContext(SelectedLocaleContext)?.setSelectedLocale

export const useAllowedLocales = () =>
  React.useContext(SelectedLocaleContext)?.allowedLocales

export const SelectedLocaleProvider = ({ children }) => {
  const { i18n } = useTranslation()
  const user = useCurrentUser()
  const settings = useSettings()

  const [selectedLocale, setSelectedLocale] = React.useState(() => {
    const locales = settings?.hash?.locales
    const initialLocale = getInitialLocale(user, locales || [])
    return initialLocale
  })

  React.useEffect(() => {
    const locale = normalizeLocale(selectedLocale || 'en-GB')
    if (i18n.language !== locale) {
      i18n.changeLanguage(locale)
    }
  }, [i18n, selectedLocale])

  return (
    <SelectedLocaleContext.Provider
      value={{
        selectedLocale,
        setSelectedLocale,
        allowedLocales: settings?.hash?.locales
      }}
    >
      {children}
    </SelectedLocaleContext.Provider>
  )
}

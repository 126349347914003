import React from 'react'

import ImportActions from 'apps/Imports/components/profile-import/import-actions'
import { useCommunicationPolicies } from 'apps/Imports/hooks/useCommunicationPolicies'
import CommunicationPolicySection from './components/communication-policy-selection'
import ConsentDetailsSection from './components/consent-details-section'
import DataProcessingPolicySection from './components/data-processing-policy-section'
import MarketingPolicySection from './components/marketing-policy-section'

const ConsentStep = () => {
  const communicationPolicies = useCommunicationPolicies()

  return (
    <>
      <DataProcessingPolicySection />
      <MarketingPolicySection />

      {communicationPolicies.map((comPol) => (
        <CommunicationPolicySection
          key={comPol.id}
          communicationPolicyVersion={comPol}
        />
      ))}
      <ConsentDetailsSection />

      <ImportActions />
    </>
  )
}

export default ConsentStep

import React from 'react'

import { Table } from '@fullfabric/alma-mater'

import cx from 'classnames'
import styles from './styles.module.scss'

const rowCallbackParams = {
  onRowClick: 'onClick',
  onRowDoubleClick: 'onDoubleClick',
  onRowMouseOut: 'onMouseOut',
  onRowMouseOver: 'onMouseOver',
  onRowRightClick: 'onContextMenu'
}

const useRenderRow = (rows, clickable) => {
  return React.useCallback(
    ({ className, columns, index, key, rowData, style, ...params }) => {
      const a11yProps = { 'aria-rowindex': index + 1 }

      if (
        Object.keys(rowCallbackParams).some(
          (rowCallbackName) => params[rowCallbackName]
        )
      ) {
        a11yProps['aria-label'] = 'row'
        a11yProps.tabIndex = 0

        Object.keys(rowCallbackParams).forEach((rowCallback) => {
          if (params[rowCallback]) {
            a11yProps[rowCallbackParams[rowCallback]] = (event) =>
              params[rowCallback]({ event, index, rowData })
          }
        })
      }

      const row = rows[index]
      return (
        <Table.Row
          tag='div'
          key={key}
          className={cx(className, styles.row, clickable && styles.clickable)}
          {...a11yProps}
          {...row.getRowProps({
            style
          })}
        >
          {columns}
        </Table.Row>
      )
    },
    [rows, clickable]
  )
}
export default useRenderRow

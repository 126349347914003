import { useEffect, useState } from 'react'

import usePublisher from './use-publisher'

const useObserver = (subject) => {
  const [previousSubject, setPreviousSubject] = useState(subject)
  const { subscribe, unsubscribe, notify } = usePublisher()

  useEffect(() => {
    if (subject !== previousSubject) {
      notify(subject) // calls callback functions registered with `subscribe`
      setPreviousSubject(subject)
    }
  }, [previousSubject, subject, notify])

  return { subscribe, unsubscribe }
}

export default useObserver

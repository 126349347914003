.react-app ._customCell_16rcw_1 {
  padding: 0;
}

.react-app ._smallCell_16rcw_5 {
  font-size: 0.7rem;
}

.react-app ._iconButton_16rcw_9 svg {
  margin-left: 0.25rem;
}

.react-app ._tableButton_16rcw_13 {
  font-weight: 400;
}

.react-app ._tableBadge_16rcw_17 {
  margin: 0;
}
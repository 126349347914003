import React from 'react'

export const AppDataContext = React.createContext({})

export const AppDataProvider = ({ value, children }) => {
  const appData = value || window.SERVER_DATA || {}

  return (
    <AppDataContext.Provider value={appData}>
      {children}
    </AppDataContext.Provider>
  )
}

export const useAppData = () => React.useContext(AppDataContext)

import React from 'react'
import { Trans } from 'react-i18next'

import { Button, Col, Row, Text } from '@fullfabric/alma-mater'

import { isNumber } from 'apps/Courses/utils/validationHelper'

import styles from './styles.module.scss'

const ValueDisplay = ({ label, value, useTopMargin, ariaLabelledBy }) => (
  <Row className={useTopMargin && 'marginTop-default'}>
    <Col xs={12}>
      <ul aria-labelledby={ariaLabelledBy}>
        <Text tag='li' fontColor='text-base' bold aria-hidden='true'>
          {label}
        </Text>
        {isNumber(value) ? (
          <Text
            tag='li'
            type='f4'
            marginTop='tiny'
            fontColor='text-base-darkest'
          >
            {value}
          </Text>
        ) : (
          <Text tag='li' type='f4' fontColor='text-base' marginTop='tiny'>
            <Trans>Not defined</Trans>
          </Text>
        )}
      </ul>
    </Col>
  </Row>
)

const CourseEditDisplayCapacity = ({ data, onEditClick }) => (
  <section aria-label='course capacity data read-only'>
    <ValueDisplay
      ariaLabelledBy='min-capacity-label'
      label={
        <span id='min-capacity-label'>
          <Trans>Minimum capacity</Trans>
        </span>
      }
      value={data.minimum_capacity}
    />
    <ValueDisplay
      ariaLabelledBy='max-capacity-label'
      label={
        <span id='max-capacity-label'>
          <Trans>Maximum capacity</Trans>
        </span>
      }
      value={data.maximum_capacity}
      useTopMargin
    />
    <ValueDisplay
      ariaLabelledBy='target-capacity-label'
      label={
        <span id='target-capacity-label'>
          <Trans>Target capacity</Trans>
        </span>
      }
      value={data.target_capacity}
      useTopMargin
    />

    <div className='marginTop-default'>
      <Button
        size='small'
        aria-label='edit course capacity'
        onClick={() => onEditClick(data)}
        className={styles.editButton}
      >
        <Trans>Edit</Trans>
      </Button>
    </div>
  </section>
)

export default CourseEditDisplayCapacity

import React from 'react'
import { Trans } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { BoxShadow, Link, Row, Text } from '@fullfabric/alma-mater'

import styles from './styles.module.scss'

const ReportCard = ({ id, legacy, title, description, imageUrl }) => {
  const history = useHistory()
  const to = `/reports/${id}`
  const href = `/legacy_reports#${id}/show`
  const linkProps = legacy
    ? {
        target: '_top',
        href
      }
    : { to }

  return (
    <BoxShadow
      type='2'
      rounded
      bgColor='base-lightest'
      className={styles.reportCard}
      onClick={() =>
        legacy ? window.top.location.replace(href) : history.push(to)
      }
    >
      <div
        className={styles.imageContainer}
        style={{ backgroundImage: `url(${imageUrl})` }}
      />
      <Row nogutter className={styles.infoContainer}>
        <Text type='h4' fontColor='text-base-darkest' marginBottom='tiny'>
          {title}
        </Text>
        <Text marginBottom='default' className={styles.description}>
          {description}
        </Text>
        <Link {...linkProps} className={styles.reportLink}>
          <Trans>See report</Trans>
        </Link>
      </Row>
    </BoxShadow>
  )
}

export default ReportCard

import { connect } from 'react-redux'

import actionTypes from 'apps/Courses/actions/types'
import CourseCreateFormStatus from 'apps/Courses/components/CourseCreateFormStatus'

const mapStateToProps = ({ newCourse: { data } }) => ({ data })

const mapDispatchToProps = (dispatch) => ({
  onChange: (field, value) =>
    dispatch({
      type: actionTypes.newCourseDataChanged,
      payload: { [field]: value }
    })
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CourseCreateFormStatus)

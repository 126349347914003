import React from 'react'
import { useMutation } from 'react-query'
import { useHistory } from 'react-router'

import updateImport from 'apps/Imports/api/update-import'
import {
  useImportDocument,
  useSetImportDocumentData
} from 'apps/Imports/contexts/import-document'
import { useSelectedData } from 'apps/Imports/contexts/selected-data'
import { useImportType } from 'apps/Imports/contexts/steps'
import { useImportContext } from 'apps/Imports/hooks/useImportContext'
import getProductImportFormData from 'apps/Imports/utils/product-import/get-product-import-form-data'

const useUpdateImportMutation = () => {
  const history = useHistory()
  const context = useImportContext()
  const { data } = useSelectedData()
  const importDocument = useImportDocument()
  const { setImportDocument, setImportErrors } = useSetImportDocumentData()
  const importType = useImportType()

  const goToImportLoadingPage = React.useCallback(() => {
    history.push(`/import/${importDocument.id}/loading`)
  }, [history, importDocument])

  return useMutation(
    async () => {
      const formData = await getProductImportFormData(data, context, importType)

      return await updateImport(importDocument.id, formData)
    },
    {
      onSuccess: (updatedImportDocument) => {
        setImportErrors(null)
        setImportDocument(updatedImportDocument)

        goToImportLoadingPage()
      },
      onError: (error) => {
        setImportErrors(error)
      }
    }
  )
}

export default useUpdateImportMutation

import { useImportDocument } from 'apps/Imports/contexts/import-document'
import { useSelectedData } from 'apps/Imports/contexts/selected-data'

const useMappingSamples = () => {
  const { data } = useSelectedData()
  const importDocument = useImportDocument()

  const contentsSample = importDocument?.contents.slice(0, 3) || []
  const samples = data.mappings
    .map((m) => m.header)
    .reduce(
      (acc, header, index) => ({
        ...acc,
        [header]: contentsSample
          .map(({ data }) => data[index])
          .filter((colData) => colData && colData.trim() !== '')
          .join(', ')
      }),
      {}
    )

  return samples
}

export default useMappingSamples

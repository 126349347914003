import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useHistory } from 'react-router'

import { useAppData } from 'shared/contexts/AppData'

import { LOGIN_ROUTE } from 'apps/Authentication/constants/routes'
import locationHelpers from 'apps/Authentication/utils/locationHelpers'
import AltAuthOptions from './AltAuthOptions'
import LoginForm from './LoginForm'
import TwoFaForm from './TwoFAForm'

function AuthForm({ isInSecondAuthStep, ...authProps }) {
  const history = useHistory()
  const appData = useAppData()
  const isIdentityEnabled = appData?._settings?.hash?.auth?.identity?.enable
  const AuthenticationStep = isIdentityEnabled
    ? isInSecondAuthStep
      ? TwoFaForm
      : LoginForm
    : AltAuthOptions

  const [twoFaPhoneNumber, setTwoFaPhoneNumber] = React.useState('')

  const onFirstStepCompleted = (data) => {
    setTwoFaPhoneNumber(data.two_fa_phone_number)

    const [basePath, urlHash] = data.url.split('#')
    history.push(basePath === '/login' ? `${LOGIN_ROUTE}#${urlHash}` : data.url)
  }

  const onAuthCompleted = (data) => {
    locationHelpers.goTo(data.url)
  }

  const formMethods = useForm()

  return (
    <FormProvider {...formMethods}>
      <AuthenticationStep
        twoFaPhoneNumber={twoFaPhoneNumber}
        onFirstStepCompleted={onFirstStepCompleted}
        onAuthCompleted={onAuthCompleted}
        {...authProps}
      />
    </FormProvider>
  )
}

export default AuthForm

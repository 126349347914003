import React from 'react'
import { Document, Page, pdfjs } from 'react-pdf'

import FullScreenLoading from 'shared/components/FullScreenLoading'

import styles from './styles.module.scss'

const ApplicationPdfViewer = ({
  viewOption,
  uploadIndex,
  containerRef,
  scale = 1
}) => {
  pdfjs.GlobalWorkerOptions.workerSrc =
    process.env.NODE_ENV === 'production'
      ? `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`
      : `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

  // pdfjs.GlobalWorkerOptions.workerSrc = `node_modules/pdfjs-dist/legacy/build/pdf.worker.js`; // ideally we would get it from our node_modules

  const [numPages, setNumPages] = React.useState(null)
  const [contentHeight, setContentHeight] = React.useState()

  React.useEffect(() => {
    setContentHeight(containerRef?.current?.clientHeight)
  }, [containerRef])

  const upload = viewOption.uploads[uploadIndex]
  const uploadPath = upload?.pdf_path ? upload?.pdf_path : upload?.path
  const pdfUrl = `/${uploadPath}`

  // if (process.env.NODE_ENV === 'test') return null

  return (
    <Document
      file={pdfUrl}
      className={styles.pdfDocument}
      onLoadSuccess={({ numPages }) => setNumPages(numPages)}
      onSourceError={(e) => console.log('error on pdf source', e)}
      onLoadError={(e) => console.log('error on pdf load', e)}
      loading={
        <FullScreenLoading
          style={{ height: contentHeight ? `${contentHeight}px` : '100%' }}
          className={styles.loading}
        />
      }
    >
      {[...Array(numPages)].map((_, index) => (
        <Page
          key={`page-${index}`}
          className={styles.pdfPage}
          height={contentHeight}
          pageNumber={index + 1}
          scale={scale}
          renderAnnotationLayer={true}
        ></Page>
      ))}
    </Document>
  )
}

export default ApplicationPdfViewer

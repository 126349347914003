import React from 'react'

import PlaceholderTableRow from './Row'

const N_DEFAULT_ROWS = 10

function PlaceholderTable({ rows, columns, virtualized }) {
  const numberOfLoadingRows =
    typeof rows === 'number' && rows > 0 ? rows : N_DEFAULT_ROWS
  const loadingRows = new Array(numberOfLoadingRows).fill(true)

  return loadingRows.map((_, rowIndex) => (
    <PlaceholderTableRow
      key={rowIndex}
      virtualized={virtualized}
      columns={columns}
      level={rowIndex}
    />
  ))
}

export default PlaceholderTable

import React from 'react'

import SavedChangesPopup from 'apps/StudyPlans/components/SavedChangesPopup'
import { ChangesSavedContext } from 'apps/StudyPlans/contexts/ChangesSaved'
import CategoriesSection from './CategoriesSection'
import CreditsSection from './CreditsSection'
import DegreeClassSection from './DegreeClassSection'
import FinalGradeSection from './FinalGradeSection'
import SubjectsSelectionSection from './SubjectSelectionSection'

const StudyPlanSettingsForm = () => {
  const [changesSaved, setChangesSaved] = React.useState(false)
  const onChangesSaved = React.useCallback(() => {
    setChangesSaved(true)
    setTimeout(() => setChangesSaved(false), 3000)
  }, [])

  return (
    <ChangesSavedContext.Provider value={onChangesSaved}>
      <CreditsSection />
      <SubjectsSelectionSection />
      <FinalGradeSection />
      <DegreeClassSection />
      <CategoriesSection />
      <SavedChangesPopup show={changesSaved} />
    </ChangesSavedContext.Provider>
  )
}

export default StudyPlanSettingsForm

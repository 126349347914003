import React, { useState } from 'react'
import { Trans } from 'react-i18next'

import { Alert, Button, Field } from '@fullfabric/alma-mater'

import createLinkedinAccount from 'apps/Authentication/api/createLinkedinAccount'
import AuthContentLayout from 'apps/Authentication/components/AuthContentLayout'
import FormTitle from 'apps/Authentication/components/FormTitle'
import locationHelpers from 'apps/Authentication/utils/locationHelpers'

const LinkAccountForm = ({ onClickCreateAccount }) => {
  const [data, setData] = useState({ email: '', password: '' })
  const [error, setError] = useState(null)
  const [inProgress, setInProgress] = useState(false)

  const onSubmit = async (evt) => {
    evt.preventDefault()
    setInProgress(true)
    setError(null)

    try {
      const result = await createLinkedinAccount(data)
      locationHelpers.goTo(result.redirect_to || '/')
    } catch (error) {
      setInProgress(false)
      setError({ message: error.data.message })
    }
  }

  const dataChangeHandler = (field, value) =>
    setData({ ...data, [field]: value })

  return (
    <>
      <FormTitle title={<Trans>Almost there</Trans>} />
      <form>
        {error?.message ? (
          <Alert description={error.message} marginBottom='default' />
        ) : null}

        <Field
          type='email'
          id='email'
          label={<Trans>E-mail address</Trans>}
          inputOptions={{ 'data-testid': 'existing-email' }}
          value={data && data.email}
          error={error && error.email}
          onChange={dataChangeHandler}
        />

        <Field
          type='password'
          id='password'
          label={<Trans>Password</Trans>}
          value={data && data.password}
          error={error && error.password}
          onChange={dataChangeHandler}
        />

        <Button primary type='submit' onClick={onSubmit} loading={inProgress}>
          <Trans>Link existing account</Trans>
        </Button>
      </form>
      <AuthContentLayout.Footer
        question={<Trans>Not registered?</Trans>}
        linkTo={onClickCreateAccount}
        linkText={<Trans>Create an account</Trans>}
      />
    </>
  )
}

export default LinkAccountForm

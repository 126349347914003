import React from 'react'

import SubjectBasicSection from 'apps/StudyPlans/components/SubjectBasicSection'
import SubjectFinalGradeSection from 'apps/StudyPlans/components/SubjectFinalGradeSection'
import SubjectPathsSection from 'apps/StudyPlans/components/SubjectPathsSection'
import { useStudyPlan } from 'apps/StudyPlans/contexts/StudyPlanData'

const AddSubjectForm = () => {
  const studyPlan = useStudyPlan()

  return (
    <>
      <SubjectBasicSection beingEdited />
      {studyPlan.multiple_paths && <SubjectPathsSection beingEdited />}
      <SubjectFinalGradeSection beingEdited />
    </>
  )
}

export default AddSubjectForm

import React from 'react'

import ApplicPerformanceHomepage from 'apps/Reports/components/ApplicPerformanceHomepage'
import useChangeDocumentTitle from 'apps/Reports/hooks/useChangeDocumentTitle'

const Homepage = () => {
  useChangeDocumentTitle('Home')

  return <ApplicPerformanceHomepage />
}

export default Homepage

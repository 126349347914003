import React from 'react'

import { Col } from '@fullfabric/alma-mater'

import ApplicationCard from 'apps/MergeProfiles/components/ApplicationCard'

import classNames from 'classnames'
import styles from './styles.module.scss'

const ApplicSummaryCard = ({ applic, profile, type, halfWidth }) => {
  return (
    <Col
      className={classNames(
        styles.applicSummaryCardCol,
        halfWidth && styles.halfWidth
      )}
    >
      <ApplicationCard
        applic={applic}
        profile={profile}
        summary
        summaryType={type}
      />
    </Col>
  )
}

export default ApplicSummaryCard

.react-app ._skipLink_nctrb_1 {
  position: fixed;
  top: -200px;
}
.react-app ._skipLink_nctrb_1:focus, .react-app ._skipLink_nctrb_1:active {
  top: 0;
}
.react-app ._skipLink_nctrb_1._mobile_nctrb_8 {
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
}
import 'css-chunk:src/apps/Applics/components/TemplateWizardPage/Layout/styles.module.scss';export default {
  "react-app": "react-app",
  "outerContainer": "_outerContainer_1m6w3_1",
  "container": "_container_1m6w3_11",
  "contentColumnSpacing": "_contentColumnSpacing_1m6w3_15",
  "contentColumnMobileSpacing": "_contentColumnMobileSpacing_1m6w3_19",
  "contentColumn": "_contentColumn_1m6w3_15",
  "headerContainer": "_headerContainer_1m6w3_32",
  "contentPositioner": "_contentPositioner_1m6w3_36",
  "previewColumn": "_previewColumn_1m6w3_44",
  "backgroundLayer": "_backgroundLayer_1m6w3_52",
  "bgImageContainer": "_bgImageContainer_1m6w3_61",
  "bgImage": "_bgImage_1m6w3_61"
};
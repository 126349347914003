import React, { useEffect, useReducer, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { useLocation } from 'react-router'
import { animated, useSpring } from 'react-spring'

import qs from 'qs'

import {
  AddIcon,
  Alert,
  AnnotatedLayout,
  Button,
  FeedbackMessage,
  Link
} from '@fullfabric/alma-mater'

import getSubject from 'apps/Subjects/api/getSubject'
import { NEW_SUBJECT_ROUTE } from 'apps/Subjects/components/AppRoutes'
import SubjectBasicsSection from 'apps/Subjects/components/SubjectBasicsSection'
import {
  useSetTargetSubject,
  useTargetSubject
} from 'apps/Subjects/contexts/TargetSubjectContext'
import BasicsData from './BasicsData'
import BasicsEditForm from './BasicsEditForm'
import Header from './Header'

import styles from './styles.module.scss'

const editingStatusReducer = (state = 'unchanged', action) => {
  switch (action) {
    case 'edit':
      return 'editing'
    case 'saveSuccess':
      return 'edited'
    default:
      return state
  }
}

const popupPositionReducer = (state = 'down', action) => {
  switch (action) {
    case 'show':
      return 'rising'
    case 'keep':
      return 'up'
    case 'hide':
      return 'down'
    default:
      return state
  }
}

const SubjectDetailsPage = ({ subjectId }) => {
  const { t } = useTranslation()
  const { search } = useLocation()
  const [editingStatus, dispatchEditionAction] = useReducer(
    editingStatusReducer,
    'unchanged'
  )
  const [submissionError, setSubmissionError] = useState()
  const setTargetSubject = useSetTargetSubject()
  const cachedSubjectData = useTargetSubject()
  const shouldFetchSubject =
    !cachedSubjectData || cachedSubjectData.id !== subjectId

  const { data: fetchedSubjectData, isLoading: isLoadingSubject } = useQuery(
    ['getSubject', subjectId],
    () => getSubject(subjectId),
    {
      enabled: shouldFetchSubject
    }
  )

  const [popupPosition, dispatchPopupAction] = useReducer(
    popupPositionReducer,
    'down'
  )
  const successPopupAnimation = useSpring({
    bottom: popupPosition !== 'down' ? '0px' : '-150px'
  })

  useEffect(() => {
    if (popupPosition === 'rising') {
      dispatchPopupAction('keep')
      setTimeout(() => {
        dispatchPopupAction('hide')
      }, 3000)
    }
  }, [popupPosition])

  useEffect(() => {
    if (shouldFetchSubject && !isLoadingSubject && fetchedSubjectData) {
      setTargetSubject(fetchedSubjectData)
    }
  }, [
    shouldFetchSubject,
    isLoadingSubject,
    fetchedSubjectData,
    setTargetSubject
  ])

  const subjectData = cachedSubjectData || fetchedSubjectData

  const searchParams = qs.parse(
    search && search[0] === '?' ? search.slice(1) : search
  )
  const isRecentlyCreatedSubject = searchParams.recently_created === 'true'

  return (
    <AnnotatedLayout
      alert={
        submissionError ? (
          <Alert error role='alert' title={submissionError} />
        ) : isRecentlyCreatedSubject && editingStatus === 'unchanged' ? (
          <Alert
            success
            role='alert'
            title={
              <Trans>
                Subject "{{ createdSubjectName: subjectData?.name }}" created.
                <Link
                  className={styles.createAnotherButton}
                  to={NEW_SUBJECT_ROUTE}
                >
                  Add another
                  <AddIcon />
                </Link>
              </Trans>
            }
          />
        ) : null
      }
      className={styles.page}
      pageHead={
        <Header title={isLoadingSubject ? t('Loading') : subjectData?.name} />
      }
    >
      <SubjectBasicsSection>
        {editingStatus === 'editing' ? (
          <BasicsEditForm
            subjectId={subjectId}
            initialData={subjectData}
            onStartSubmit={() => {
              setSubmissionError(null)
            }}
            onSubmitted={(editedSubject) => {
              dispatchEditionAction('saveSuccess')
              dispatchPopupAction('show')
              setTargetSubject(editedSubject)
            }}
            onNotSubmitted={(errorMessage) => {
              setSubmissionError(errorMessage)
            }}
          />
        ) : (
          <>
            <BasicsData isLoading={isLoadingSubject} data={subjectData} />
            <Button
              size='small'
              type='button'
              onClick={() => dispatchEditionAction('edit')}
              disabled={isLoadingSubject}
            >
              <Trans>Edit</Trans>
            </Button>
          </>
        )}
      </SubjectBasicsSection>
      <animated.div
        aria-hidden={popupPosition === 'down'}
        className={styles.successPopup}
        style={successPopupAnimation}
      >
        <FeedbackMessage isOpen type='success' message='Changes saved' />
      </animated.div>
    </AnnotatedLayout>
  )
}

export default SubjectDetailsPage

import React from 'react'
import { Trans } from 'react-i18next'

import { Table } from '@fullfabric/alma-mater'

const EmptyDataRow = ({ emptyMessage }) => {
  return (
    <Table.Row>
      <Table.Data colSpan='100' align='center'>
        {emptyMessage || <Trans>Empty list</Trans>}
      </Table.Data>
    </Table.Row>
  )
}

export default EmptyDataRow

import React from 'react'

import { useCurrentUser } from '@fullfabric/authorization-officer'

import buildInlinePostInputs from 'shared/utils/buildInlinePostInputs'
import { inlinePost } from 'shared/utils/inlinePost'

import ColumnStore from 'apps/Financial/services/ColumnStore'

const useExportTransactionsHandler = () => {
  const user = useCurrentUser()

  return React.useCallback(
    ({ search, filters }) => {
      const visibleColumns = ColumnStore.getStoredVisibleColumnsForUser(
        user?.id
      )

      const params = { columns: visibleColumns, ...search, ...filters }

      inlinePost(
        `/book_keeper/api/transactions/export.xlsx?limit=Infinity`,
        buildInlinePostInputs(params),
        {
          newWindow: true
        }
      )
    },
    [user?.id]
  )
}

export default useExportTransactionsHandler

import getFieldValue from 'apps/MergeProfiles/utils/getFieldValue'

const simpleEquality = (a, b) => (!a && !b) || a === b
const arrayEquality = (a, b) => {
  if (a === b) return true
  if (a == null || b == null) return false
  if (a.length !== b.length) return false
  if (a.length === 0 && b.length === 0) return true

  // If you don't care about the order of the elements inside
  // the array, you should sort both arrays here.
  // Please note that calling sort on an array will modify that array.
  // you might want to clone your array first.

  for (let i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false
  }
  return true
}

const fileEquality = (a, b) => {
  if (!a && !b) return true
  if (Array.isArray(a) && a.length === 0 && Array.isArray(b) && b.length === 0)
    return true

  return false
}

const EQUALITY_FN_BY_TYPE = {
  'Schemable::Fields::Types::DropDown': arrayEquality,
  'Schemable::Fields::Types::CheckBox': arrayEquality,
  'Schemable::Fields::Types::Address': arrayEquality,
  'Schemable::Fields::Types::File': fileEquality
}

const isFieldValueEqualForProfiles = (field, profileA, profileB) => {
  const profileAFieldValueRaw = getFieldValue(field, profileA)
  const profileBFieldValueRaw = getFieldValue(field, profileB)

  const areEqual = EQUALITY_FN_BY_TYPE[field.type] || simpleEquality

  return areEqual(profileAFieldValueRaw, profileBFieldValueRaw)
}

export default isFieldValueEqualForProfiles

import React from 'react'
import { Trans } from 'react-i18next'
import { useParams } from 'react-router'

import { ArrowLeftIcon, PageHead } from '@fullfabric/alma-mater'

import { HOME_ROUTE } from 'apps/StudyPlans/components/AppRoutes/constants'
import { useOriginalSubjectData } from 'apps/StudyPlans/contexts/SubjectEditionData'

const PageHeader = () => {
  const { classId } = useParams()
  const originalSubjectData = useOriginalSubjectData()
  const subjectCompleteName = originalSubjectData.course?.long_name?.replace(
    '::',
    '-'
  )

  return (
    <PageHead
      title={subjectCompleteName || ''}
      breadcrumbs={[
        {
          label: <Trans>Back to Study plan</Trans>,
          icon: ArrowLeftIcon,
          to: HOME_ROUTE.replace(':classId', classId)
        }
      ]}
    />
  )
}

export default PageHeader

import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useState
} from 'react'

import { useEvaluation } from 'apps/Evaluations/context/EvaluationData'

const EvaluationFormContext = createContext(null)
const SetEvaluationFormContext = createContext(null)
const DispatchSubmissionUpdateContext = createContext(null)
const SubmissionStateContext = createContext(null)

const useEvaluationForm = () => {
  return useContext(EvaluationFormContext)
}

const useSetEvaluationForm = () => {
  return useContext(SetEvaluationFormContext)
}

const useDispatchSubmissionUpdate = () => {
  return useContext(DispatchSubmissionUpdateContext)
}

const useSubmissionState = () => {
  return useContext(SubmissionStateContext)
}

export const SUBMISSION_STATE = {
  FORM: {
    DISABLED: 'DISABLED',
    EDITABLE: 'EDITABLE'
  },
  MODAL: {
    HIDDEN: 'HIDDEN',
    CONFIRM_SUBMIT: 'CONFIRM_SUBMIT',
    SUBMITTING: 'SUBMITTING',
    SUBMISSION_ERROR: 'SUBMISSION_ERROR',
    CONFIRM_UNSUBMIT: 'CONFIRM_UNSUBMIT',
    UNSUBMITTING: 'UNSUBMITTING',
    SUCCESS: 'SUCCESS'
  },
  BUTTON: {
    SUBMIT: 'SUBMIT',
    UNSUBMIT: 'UNSUBMIT'
  }
}

const loadingEvaluationState = {
  button: SUBMISSION_STATE.BUTTON.SUBMIT,
  modal: SUBMISSION_STATE.MODAL.HIDDEN,
  form: SUBMISSION_STATE.FORM.DISABLED
}

const startedEvaluationInitState = {
  button: SUBMISSION_STATE.BUTTON.SUBMIT,
  modal: SUBMISSION_STATE.MODAL.HIDDEN,
  form: SUBMISSION_STATE.FORM.EDITABLE
}

const submittedEvaluationInitState = {
  button: SUBMISSION_STATE.BUTTON.UNSUBMIT,
  modal: SUBMISSION_STATE.MODAL.HIDDEN,
  form: SUBMISSION_STATE.FORM.DISABLED
}

export const SUBMISSION_ACTIONS = {
  INIT: 'INIT',
  CONFIRM_SUBMISSION: 'CONFIRM_SUBMISSION',
  SUBMISSION_START: 'SUBMISSION_START',
  SUBMISSION_SUCCESS: 'SUBMISSION_SUCCESS',
  SUBMISSION_ERROR: 'SUBMISSION_ERROR',
  CONFIRM_UNSUBMISSION: 'CONFIRM_UNSUBMISSION',
  UNSUBMISSION_START: 'UNSUBMISSION_START',
  UNSUBMISSION_SUCCESS: 'UNSUBMISSION_SUCCESS',
  UNSUBMISSION_ERROR: 'UNSUBMISSION_ERROR',
  CLOSE_MODAL: 'CLOSE_MODAL'
}

const submissionStateReducer = (state, action) => {
  switch (action.type) {
    case SUBMISSION_ACTIONS.INIT:
      return { ...action.payload }
    case SUBMISSION_ACTIONS.CONFIRM_SUBMISSION:
      return {
        ...state,
        modal: SUBMISSION_STATE.MODAL.CONFIRM_SUBMIT
      }
    case SUBMISSION_ACTIONS.SUBMISSION_START:
      return {
        ...state,
        modal: SUBMISSION_STATE.MODAL.SUBMITTING
      }
    case SUBMISSION_ACTIONS.SUBMISSION_SUCCESS:
      return {
        button: SUBMISSION_STATE.BUTTON.UNSUBMIT,
        modal: SUBMISSION_STATE.MODAL.SUCCESS,
        form: SUBMISSION_STATE.FORM.DISABLED
      }
    case SUBMISSION_ACTIONS.SUBMISSION_ERROR:
      return {
        ...state,
        modal: SUBMISSION_STATE.MODAL.SUBMISSION_ERROR
      }
    case SUBMISSION_ACTIONS.CONFIRM_UNSUBMISSION:
      return {
        ...state,
        modal: SUBMISSION_STATE.MODAL.CONFIRM_UNSUBMIT
      }
    case SUBMISSION_ACTIONS.UNSUBMISSION_START:
      return {
        ...state,
        modal: SUBMISSION_STATE.MODAL.UNSUBMITTING
      }
    case SUBMISSION_ACTIONS.UNSUBMISSION_SUCCESS:
      return {
        button: SUBMISSION_STATE.BUTTON.SUBMIT,
        modal: SUBMISSION_STATE.MODAL.HIDDEN,
        form: SUBMISSION_STATE.FORM.EDITABLE
      }
    case SUBMISSION_ACTIONS.CLOSE_MODAL:
      return {
        ...state,
        modal: SUBMISSION_STATE.MODAL.HIDDEN
      }
    case SUBMISSION_ACTIONS.UNSUBMISSION_ERROR:
    default:
      return state
  }
}

const EvaluationFormDataProvider = ({ children }) => {
  const { evaluationData } = useEvaluation()

  const [evaluationFormData, setEvaluationFormData] = useState({})
  const [submittedEvaluationData, setSubmittedEvaluationData] = useState({})
  const [submissionState, dispatchSubmissionUpdate] = useReducer(
    submissionStateReducer,
    loadingEvaluationState
  )

  const patchEvaluationFormData = useCallback(
    (patch) => setEvaluationFormData((formData) => ({ ...formData, ...patch })),
    [setEvaluationFormData]
  )

  const evaluationAssignee = evaluationFormData?.assigned_to?.full_name
    ? `assigned to ${evaluationFormData?.assigned_to.full_name}`
    : evaluationFormData?.team_evaluation
    ? 'team evaluation'
    : ''

  useEffect(() => {
    if (evaluationData) {
      dispatchSubmissionUpdate({
        type: SUBMISSION_ACTIONS.INIT,
        payload:
          evaluationData.state === 'submitted'
            ? submittedEvaluationInitState
            : startedEvaluationInitState
      })
      setEvaluationFormData({
        ...evaluationData
      })
    }
  }, [evaluationData, setEvaluationFormData])

  return (
    <DispatchSubmissionUpdateContext.Provider value={dispatchSubmissionUpdate}>
      <SubmissionStateContext.Provider value={submissionState}>
        <SetEvaluationFormContext.Provider
          value={{
            patchEvaluationFormData,
            setEvaluationFormData,
            setSubmittedEvaluationData
          }}
        >
          <EvaluationFormContext.Provider
            value={{
              evaluationFormData,
              submittedEvaluationData,
              evaluationAssignee
            }}
          >
            {children}
          </EvaluationFormContext.Provider>
        </SetEvaluationFormContext.Provider>
      </SubmissionStateContext.Provider>
    </DispatchSubmissionUpdateContext.Provider>
  )
}

export default EvaluationFormContext
export {
  useSetEvaluationForm,
  useEvaluationForm,
  EvaluationFormDataProvider,
  useSubmissionState,
  useDispatchSubmissionUpdate
}

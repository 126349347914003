.react-app ._institution-background_1oyec_1 {
  min-width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.react-app ._background-image-container_1oyec_10 {
  overflow: hidden;
  position: relative;
  height: 100%;
  width: 100%;
}
const getPropsForColumnType = (type = 'text') => {
  switch (type) {
    case 'primary':
      return { main: true }
    case 'number':
      return { numeric: true }
    default:
      return {}
  }
}

const getPropsForHeaderColumnType = (type = 'text') => {
  switch (type) {
    case 'number':
      return { numeric: true }
    default:
      return {}
  }
}

const useColumnTypes = (hooks) => {
  hooks.getHeaderProps.push((headerProps, { column }) => {
    const propsForHeaderColumnType = getPropsForHeaderColumnType(column.type)
    return { ...headerProps, ...propsForHeaderColumnType }
  })

  hooks.getCellProps.push((cellProps, { cell }) => {
    const propsForColumnType = getPropsForColumnType(cell.column.type)
    return { ...cellProps, ...propsForColumnType }
  })
}

export default useColumnTypes

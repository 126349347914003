import React from 'react'
import { useController, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

import { Field } from '@fullfabric/alma-mater'
import { useCurrentUser } from '@fullfabric/authorization-officer'

import getStaffOptions from 'shared/api/getStaffOptions'

import { useEvaluation } from 'apps/Evaluations/context/EvaluationData'

const AssigneeField = ({ name }) => {
  const { t } = useTranslation()
  const { control, formState } = useFormContext()
  const { errors } = formState

  const user = useCurrentUser()
  const isSupportStaff = user?.teams_names?.some(
    (teamName) => teamName === 'Support'
  )
  const { data: staffProfilesData, isLoading: isLoadingStaffProfilesData } =
    useQuery(
      ['getStaffProfiles', isSupportStaff],
      () => getStaffOptions({ includeSupportStaff: isSupportStaff }),
      { enabled: !!user }
    )
  const { evaluationData } = useEvaluation()
  const currentAssignee = evaluationData?.assigned_to_id
  const isEvaluatorRequired = !evaluationData?.team_evaluation

  const {
    field: { ref: evaluatorInputRef, ...evaluatorProps }
  } = useController({
    name: 'assigned_to_id',
    control,
    rules: {
      required: {
        value: isEvaluatorRequired,
        message: t("Can't be blank")
      }
    },
    defaultValue: currentAssignee
  })

  return (
    <Field
      {...evaluatorProps}
      required={isEvaluatorRequired}
      inputRef={evaluatorInputRef}
      type='autocomplete'
      label={t('Assign to an evaluator')}
      error={errors.assigned_to_id?.message}
      inputOptions={{
        isClearable: !isEvaluatorRequired,
        inModal: true,
        options:
          staffProfilesData?.map((profile) => ({
            value: profile.value,
            label: profile.label
          })) || [],
        isLoading: isLoadingStaffProfilesData
      }}
    />
  )
}

export default AssigneeField

import CustomTooltip from './CustomTooltip'
import DatesXAxisTick from './DatesXAxisTick'
import DynamicWeekXAxisTick from './DynamicWeekXAxisTick'
import renderCustomCartesianGrid from './renderCustomCartesianGrid'
import renderCustomXAxis from './renderCustomXAxis'
import renderCustomYAxis from './renderCustomYAxis'
import XAxisTick from './XAxisTick'
import YAxisTick from './YAxisTick'
import YearWeeksXAxisTick from './YearWeeksXAxisTick'

import commonStyles from './commonStyles.module.scss'

const DEFAULT_SPACE_TO_CONTENT = 40
const MAIN_INTAKE_FILL = '#0967D2'
const FILL_MAP = ['var(--ff-color-grey-300)', 'var(--ff-color-grey-500)']

export {
  DEFAULT_SPACE_TO_CONTENT,
  MAIN_INTAKE_FILL,
  FILL_MAP,
  renderCustomCartesianGrid,
  renderCustomXAxis,
  XAxisTick,
  DatesXAxisTick,
  DynamicWeekXAxisTick,
  YearWeeksXAxisTick,
  renderCustomYAxis,
  YAxisTick,
  CustomTooltip,
  commonStyles
}

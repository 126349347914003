import React from 'react'

import BaseChartCell from '../ProductPerformanceBaseChartCell'
import useChartWidth from '../ProductPerformanceBaseChartCell/useChartWidth'
import ChartContent from './ChartContent'
import ClassCapacityLabel from './ClassCapacityLabel'
import CourseCapacityLabel from './CourseCapacityLabel'

export const CLASS_SIDE_COLOR = '#A26DD4'
export const COURSE_SIDE_COLOR = '#F49342'
export const formatAsPercentage = (val) => Math.round(val * 1000) / 10

const BreakdownChart = ({ product }) => {
  const { chartWidth, measurerRef } = useChartWidth()
  const isCourse = product.type === 'Institutions::Course'

  const classCapacity =
    (isCourse ? product.capacity_from_transcripts : product.current_capacity) ||
    0
  const courseCapacity = (isCourse && product.capacity_from_journeys) || 0
  const totalCapacity = isCourse
    ? classCapacity + courseCapacity
    : product.current_capacity || 0

  const classCapacityRatio = classCapacity / totalCapacity
  const courseCapacityRatio = courseCapacity / totalCapacity
  const classCapacityBarLength = classCapacityRatio * chartWidth
  const courseCapacityBarLength = courseCapacityRatio * chartWidth

  return (
    <BaseChartCell measurerRef={measurerRef}>
      <ClassCapacityLabel
        totalCapacity={totalCapacity}
        capacity={classCapacity}
        capacityRatio={classCapacityRatio}
      />

      <CourseCapacityLabel
        forCourseProduct={isCourse}
        position={chartWidth}
        totalCapacity={totalCapacity}
        capacity={courseCapacity}
        capacityRatio={courseCapacityRatio}
      />

      <ChartContent
        leftSideSize={classCapacityBarLength}
        rightSideSize={courseCapacityBarLength}
        classCapacity={classCapacity}
        courseCapacity={courseCapacity}
        totalCapacity={totalCapacity}
        totalSize={chartWidth}
      />
    </BaseChartCell>
  )
}

export default BreakdownChart

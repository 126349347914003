import { utils } from '@fullfabric/public-api'

const authenticate = async (data) => {
  const response = await fetch('/auth/ff/callback', {
    method: 'POST',
    credentials: 'same-origin',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    body: `auth_key=${encodeURIComponent(
      data.email
    )}&password=${encodeURIComponent(data.password)}${
      data.twoFaToken
        ? `&two_fa_token=${encodeURIComponent(data.twoFaToken)}`
        : ''
    }`
  })

  return await utils.checkResponse(response)
}

export default authenticate

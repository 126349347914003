import React from 'react'
import { useTranslation } from 'react-i18next'

import { Text } from '@fullfabric/alma-mater'

import ArrowDownIcon from '../ArrowDownIcon'
import ArrowUpIcon from '../ArrowUpIcon'

import styles from '../styles.module.scss'

export const percentageDifference = (recent, old) =>
  recent !== 0 && old !== 0 ? ((recent - old) / old) * 100 : NaN

const roundTo2Cases = (value) => Math.round(value * 100) / 100

const ComparisonInfoRow = ({ label, value, referenceValue }) => {
  const { t } = useTranslation()

  const hasValidData =
    typeof value === 'number' && typeof referenceValue === 'number'

  const valueGrowth = hasValidData
    ? percentageDifference(referenceValue, value)
    : NaN

  const hasValueDecreased = isNaN(valueGrowth)
    ? referenceValue < value
    : valueGrowth < 0

  return (
    <div className={styles.infoRow} data-testid='comparisonInfoRow'>
      <Text type='f6' fontColor='text-base-darkest'>
        <Text
          tag='span'
          type='f6'
          fontColor='text-base-darker'
          marginRight='tiny'
        >
          vs
        </Text>
        {label}
      </Text>
      <div className={styles.valueContainer}>
        {hasValidData ? (
          <>
            <Text tag='span' marginRight='tiny'>
              {hasValueDecreased ? <ArrowDownIcon /> : <ArrowUpIcon />}
            </Text>
            <Text
              type='h6'
              fontColor={hasValueDecreased ? 'text-alert' : 'text-success'}
              marginRight='small'
            >
              {isNaN(valueGrowth)
                ? '---'
                : `${Math.abs(roundTo2Cases(valueGrowth))}%`}
            </Text>
            <Text type='h5' fontColor='text-base-darkest'>
              {value}
            </Text>
          </>
        ) : (
          <Text type='f6' fontColor='text-base-darker'>
            {t('Not available')}
          </Text>
        )}
      </div>
    </div>
  )
}

export default ComparisonInfoRow

import React, { useEffect, useState } from 'react'

import { Loading } from '@fullfabric/alma-mater'
import { useCurrentUser } from '@fullfabric/authorization-officer'

import getMyApplications from 'apps/ContentPages/api/get-my-applications'
import useIsMobileNavigation from 'apps/ContentPages/hooks/use-is-mobile-navigation'
import useSettingByName from 'apps/ContentPages/hooks/use-setting-by-name'
import Footer from './footer'
import LogoLink from './logo-link'
import PrimaryNavigation from './primary-navigation'
import SecondaryNavigation from './secondary-navigation'

import classNames from 'classnames'
import styles from './styles.module.scss'

export default function Navigation() {
  const isMobileNavigation = useIsMobileNavigation()
  const user = useCurrentUser()
  const [applics, setApplics] = useState(null)
  const [loading, setLoading] = useState(true)
  const productCatalogActive = useSettingByName(
    'modules.content.features.product_catalog.enable'
  )

  const isContact = user?.roles?.some((role) => role.state === 'contact')

  useEffect(() => {
    if (isContact) {
      ;(async () => {
        const applicationData = await getMyApplications()
        setApplics(applicationData)
        setLoading(false)
      })()
    } else {
      setLoading(false)
    }
  }, [isContact])

  const hasApplics = !loading && applics && !!applics.length
  const regOrAppTypes =
    (isContact &&
      hasApplics &&
      applics.map((applic) => applic.type).flat(Infinity)) ||
    []
  const primaryNavOptions = [
    '/',
    regOrAppTypes.includes('registration') && '/my-registrations',
    regOrAppTypes.includes('application') && '/my-applications',
    productCatalogActive && '/product-catalog'
  ].filter((x) => x)

  return (
    <nav role='navigation' aria-label='navigation' className={styles.container}>
      <div
        className={classNames([
          styles.navigationContainer,
          isMobileNavigation && styles.mobile
        ])}
      >
        <LogoLink />

        {loading ? (
          <div className={styles.centeredHolder}>
            <Loading className={styles.loadingMobile} type='triple' />
          </div>
        ) : (
          <>
            <PrimaryNavigation options={primaryNavOptions} />
            <SecondaryNavigation primaryOptions={primaryNavOptions} />
          </>
        )}
      </div>
      <Footer />
    </nav>
  )
}

import { utils } from '@fullfabric/public-api'

const startApplicationOnBehalf = async (templateId, data) => {
  const response = await fetch(`/api/templates/${templateId}/start_on_behalf`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })

  return await utils.checkResponse(response)
}

export default startApplicationOnBehalf

import { connect } from 'react-redux'

import actionTypes from 'apps/Courses/actions/types'
import updateCourse from 'apps/Courses/actions/updateCourse'
import CourseEditFormStatus from 'apps/Courses/components/CourseEditFormStatus'

const mapStateToProps = ({
  editCourse: {
    statusSection: { data, inProgress }
  }
}) => ({
  data,
  inProgress
})

const mapDispatchToProps = (dispatch) => ({
  onCancelClick: () =>
    dispatch({ type: actionTypes.cancelEditStatusSectionData }),
  onChange: (field, value) =>
    dispatch({
      type: actionTypes.editCourseStatusSectionDataChanged,
      payload: { [field]: value }
    }),
  onSaveClick: () =>
    dispatch(updateCourse(actionTypes.updateCourseStatusSection))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CourseEditFormStatus)

import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Text } from '@fullfabric/alma-mater'
import { useCurrentUser } from '@fullfabric/authorization-officer'

import styles from './styles.module.scss'

const DefaultHeader = () => {
  const { t } = useTranslation()
  const user = useCurrentUser()

  return (
    <>
      <div className={styles.welcomeText}>
        <Text type='h1' fontColor='text-base-darkest' marginBottom='tiny'>
          {t('Hello {{name}},', { name: user?.first_name })}
        </Text>
        <Text type='f4' fontColor='text-base-darker'>
          <Trans>
            Please find an overview of your admissions performance to date.
          </Trans>
        </Text>
      </div>
    </>
  )
}

export default DefaultHeader

import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { submitEventResponse } from 'apps/ContentPages/api'
import processSubmissionError from 'apps/ContentPages/utils/processSubmissionError'

export default function useHandleSubmit({
  redirectTo,
  eventId,
  onStartSubmit,
  onSubmitted = () => {},
  onNotSubmitted
}) {
  const { t } = useTranslation()
  const location = useLocation()

  const handleSubmit = useCallback(
    async (submissionData) => {
      try {
        onStartSubmit && onStartSubmit()

        submissionData.set('data[_type]', 'Events::Response::Yes')
        const response = await submitEventResponse(eventId, submissionData)

        if (redirectTo) window.open(redirectTo, 'event-success-redirect-tab')

        onSubmitted()

        const isInSubmittedPage = location.pathname.match(/\/submitted\/?$/) // ending with /submitted
        if (!isInSubmittedPage) {
          const separator = location.pathname.endsWith('/') ? '' : '/' // avoids a pathname like `path.com//submitted`
          window.history.replaceState(
            {},
            '',
            `${location.pathname}${separator}r/${response?.id}/submitted`
          )
        }
      } catch (err) {
        const submissionError = processSubmissionError(err, t)

        onNotSubmitted && onNotSubmitted(submissionError)
      }
    },
    [
      eventId,
      redirectTo,
      onSubmitted,
      location.pathname,
      onStartSubmit,
      onNotSubmitted,
      t
    ]
  )

  return handleSubmit
}

import React from 'react'

import {
  Badge,
  CloseIcon,
  DeleteIcon,
  DownloadIcon,
  IconButton,
  Link,
  Text
} from '@fullfabric/alma-mater'

import classNames from 'classnames'
import styles from './styles.module.scss'

export default function AttachmentFile({
  id,
  name,
  uploading,
  error,
  onDelete,
  onErrorDismiss,
  path
}) {
  const onDeleteClick = (e) => {
    e.preventDefault()
    onDelete(id)
  }
  const onHideErrorClick = (e) => {
    e.preventDefault()
    onErrorDismiss()
  }

  const downloadPath = path && path.match(/https?/) ? path : `/${path}`

  return (
    <div
      data-testid='file'
      className={classNames(styles.row, { [styles.rowWithError]: !!error })}
    >
      <label className={styles.name} title={name}>
        {name}
      </label>
      {error && (
        <div className={styles.actions}>
          <Text className={styles.error} type='f6' fontColor='text-warning'>
            {error}
          </Text>
          <IconButton small onClick={onHideErrorClick} title='Hide'>
            <CloseIcon />
          </IconButton>
        </div>
      )}
      {!error &&
        (uploading ? (
          <Badge type='purple'>Uploading</Badge>
        ) : (
          <div className={styles.actions}>
            <Link
              withoutStyles
              href={downloadPath}
              rel='noopener noreferrer'
              title='Download'
              aria-label='Download'
            >
              <IconButton small>
                <DownloadIcon />
              </IconButton>
            </Link>
            <IconButton
              small
              onClick={onDeleteClick}
              title='Delete'
              aria-label='Delete file'
            >
              <DeleteIcon />
            </IconButton>
          </div>
        ))}
    </div>
  )
}

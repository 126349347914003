import React from 'react'

import { Badge } from '@fullfabric/alma-mater'

const STATE_DESCRIPTIONS = {
  started: {
    description: 'Started',
    labelType: 'purple'
  },
  submitted: {
    description: 'Submitted',
    labelType: 'green'
  }
}

const StatusCell = ({ value }) => {
  const badgeProps = STATE_DESCRIPTIONS[value]

  return (
    <Badge type={badgeProps?.labelType} className='marginTop-tiny'>
      {badgeProps?.description}
    </Badge>
  )
}

export default StatusCell

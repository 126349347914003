import { utils } from '@fullfabric/public-api'

const deleteEvaluation = async (evaluationId) => {
  const response = await fetch(`/evaluations/api/evaluations/${evaluationId}`, {
    credentials: 'same-origin',
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' }
  })

  return await utils.checkResponse(response)
}

export default deleteEvaluation

.react-app ._section_11pn0_1 {
  padding: var(--space-default);
  border-bottom: var(--border-base-lighter);
}
.react-app ._section_11pn0_1 ._noBorder_11pn0_5 {
  border-bottom: none;
}

.react-app ._icon_11pn0_9 {
  margin-right: var(--space-small);
  flex: 0 0 auto;
}

.react-app ._section_11pn0_1 ._infoRow_11pn0_14:last-child {
  margin-bottom: 0;
}

.react-app ._infoRow_11pn0_14 {
  margin-bottom: var(--space-default);
}

.react-app ._withIcon_11pn0_22 {
  display: flex;
  align-items: baseline;
}

.react-app ._loadingRow_11pn0_27 {
  padding: var(--space-default) 0;
}
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'

import {
  AlertFullIcon,
  Button,
  Modal,
  ModalButtons,
  ModalTitle,
  Text
} from '@fullfabric/alma-mater'

import deleteEvaluation from 'apps/Evaluations/api/deleteEvaluation'
import { useEvaluationForm } from 'apps/Evaluations/context/EvaluationFormData'

import styles from './styles.module.scss'

const DeleteModal = ({ profileId, applicationId, onClickClose, isOpen }) => {
  const { t } = useTranslation()
  const { evaluationFormData } = useEvaluationForm()
  const history = useHistory()

  const handleDeleteEvaluation = async () => {
    await deleteEvaluation(evaluationFormData.id)
    history.push(
      `/evaluations/profiles/${profileId}/applications/${applicationId}/evaluations?recently-deleted-evaluation=true`
    )
  }

  return (
    <Modal
      role='dialog'
      aria-labelledby='submission-modal-title'
      isOpen={isOpen}
      size='medium'
      header={
        <ModalTitle
          title={
            <>
              <AlertFullIcon color='text-alert' className={styles.alertIcon} />
              <span id='submission-modal-title'>{t('Delete evaluation')}</span>
            </>
          }
          onClose={() => onClickClose()}
        />
      }
      footer={
        <ModalButtons>
          <Button onClick={() => onClickClose()}>{t('Cancel')}</Button>
          <Button negative primary onClick={handleDeleteEvaluation}>
            {t('I understand, delete this evaluation')}
          </Button>
        </ModalButtons>
      }
    >
      <>
        <Text fontColor='text-base-darkest'>
          {t('This action CANNOT be undone. This action will')}:
        </Text>
        <ul className={styles.listItem}>
          <li>
            <Text fontColor='text-base-darkest' marginTop='tiny'>
              {t('Permanently delete this evaluation')}
            </Text>
          </li>
        </ul>
      </>
    </Modal>
  )
}

export default DeleteModal

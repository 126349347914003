import React from 'react'

import { Text } from '@fullfabric/alma-mater'

import ArrowRightIcon from './ArrowRightIcon'

import styles from '../../styles.module.scss'

const StartApplicOption = ({ IconComponent, label, onClick, link }) => {
  return (
    <a className={styles.container} onClick={onClick} href={link || '#'}>
      <div className={styles.iconAndLabel}>
        <IconComponent />
        <Text
          type='f4'
          fontColor='text-base-darkest'
          marginBottom='more'
          className={styles.label}
        >
          {label}
        </Text>
      </div>
      <ArrowRightIcon />
    </a>
  )
}

export default StartApplicOption

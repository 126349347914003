import React from 'react'

import { useLocale } from 'shared/contexts/Locale'

import PageMainTextContent from 'apps/ContentPages/components/page-main-text-content'
import { useChangeBackgroundImage } from 'apps/ContentPages/contexts/background-image'
import getLocalizedText from 'apps/ContentPages/utils/get-localized-text'

const ProductCatalogDetails = ({ product, isLoading }) => {
  const { locale } = useLocale()
  const textContent = getLocalizedText(
    product?.catalog_page_data,
    locale,
    'html',
    'en-GB'
  )
  const changeBackgroundImage = useChangeBackgroundImage()

  React.useEffect(() => {
    changeBackgroundImage()
  }, [changeBackgroundImage])

  return (
    <PageMainTextContent isLoading={isLoading}>
      {textContent}
    </PageMainTextContent>
  )
}

export default ProductCatalogDetails

import React from 'react'

import { Filter } from '@fullfabric/alma-mater'

function TableFilter({
  filters,
  selectedFilter,
  onFilterChange,
  ...otherFilterProps
}) {
  return (
    <Filter
      onFilterChange={onFilterChange}
      selectedFilter={selectedFilter}
      filters={filters}
      {...otherFilterProps}
    />
  )
}

export default TableFilter

import getEmailFieldValues from './getEmailFieldValues'

const getMergeParamsFromData = (mergeData) => {
  const {
    sourceProfileId,
    confirmDuplicateProfileDeletion,
    profileInfoFrom,
    smartEmailInfoFrom,
    targetProfile,
    sourceProfile,
    journeysFrom,
    applicsFrom
  } = mergeData

  const copyFieldsFromSource = Object.keys(profileInfoFrom).filter(
    (fieldName) => profileInfoFrom[fieldName] === 'duplicate'
  )

  const emailFieldValues = getEmailFieldValues(
    smartEmailInfoFrom,
    sourceProfile,
    targetProfile
  )

  return {
    source_id: sourceProfileId,
    delete_source_profile: confirmDuplicateProfileDeletion,
    copy_fields_from_source_profile: copyFieldsFromSource,
    update_target_values_after_merge: emailFieldValues,
    use_source_profile_journeys_for_contexts: Object.keys(journeysFrom).filter(
      (contextId) => journeysFrom[contextId] === 'duplicate'
    ),
    use_source_profile_applics_for_templates: Object.keys(applicsFrom).filter(
      (applicTemplateId) => applicsFrom[applicTemplateId] === 'duplicate'
    )
  }
}

export default getMergeParamsFromData
export { getEmailFieldValues }

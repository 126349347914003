import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Button } from '@fullfabric/alma-mater'

import CardContent from 'apps/StudyPlans/components/CardContent'
import CardInfo from 'apps/StudyPlans/components/CardInfo'
import CoursesNames from 'apps/StudyPlans/components/CourseNames'
import { formatPrerequisiteNames } from 'apps/StudyPlans/components/PrerequisiteNames'
import { useStudyPlan } from 'apps/StudyPlans/contexts/StudyPlanData'
import { useSubjectEditionData } from 'apps/StudyPlans/contexts/SubjectEditionData'
import { useSetSubjectSectionsBeingEdited } from 'apps/StudyPlans/contexts/SubjectSectionBeingEdited'
import { subjectTypeOptionsForPath } from 'apps/StudyPlans/utils/subjectTypesValues'

const SubjectBasicDisplay = () => {
  const { t } = useTranslation()
  const studyPlan = useStudyPlan()
  const data = useSubjectEditionData()
  const setSubjectBeingEdited = useSetSubjectSectionsBeingEdited()
  const onClickEdit = React.useCallback(() => {
    setSubjectBeingEdited((sections) => ({ ...sections, basic: true }))
  }, [setSubjectBeingEdited])

  const courseTypeForPath = (data.course_types || []).filter(
    (ct) => ct.path_id === studyPlan.default_path.id
  )[0]

  const courseType =
    courseTypeForPath &&
    subjectTypeOptionsForPath(studyPlan.default_path).filter(
      (option) => option.value === courseTypeForPath.group_id
    )[0]

  const courseTypeLabel = courseType && courseType.label

  return (
    <CardContent>
      <CardInfo
        title={<Trans>Subject</Trans>}
        main={data.course && data.course.long_name}
        containerClassName='marginBottom-default'
      />
      <CardInfo
        title={<Trans>Courses</Trans>}
        main={<CoursesNames courses={data.units} />}
        containerClassName='marginBottom-default'
      />
      {!studyPlan.multiple_paths && (
        <CardInfo
          title={<Trans>Course type</Trans>}
          main={courseTypeLabel}
          containerClassName='marginBottom-default'
        />
      )}
      {!studyPlan.multiple_paths && (
        <CardInfo
          title={<Trans>Prerequisites</Trans>}
          main={formatPrerequisiteNames(data.prerequisites, studyPlan, t)}
          containerClassName='marginBottom-default'
        />
      )}
      <CardInfo
        title={<Trans>Category</Trans>}
        main={data.category && data.category.name}
        containerClassName='marginBottom-default'
      />
      <CardInfo
        title={<Trans>Credits</Trans>}
        main={data.credits}
        containerClassName='marginBottom-default'
      />
      <CardInfo
        title={<Trans>Hours</Trans>}
        main={data.hours}
        containerClassName='marginBottom-default'
      />
      <CardInfo
        title={<Trans>Max number of absences</Trans>}
        main={data.max_number_absences}
        containerClassName='marginBottom-default'
      />
      <div className='marginTop-default'>
        <Button size='small' onClick={() => onClickEdit(data)}>
          <Trans>Edit</Trans>
        </Button>
      </div>
    </CardContent>
  )
}

export default SubjectBasicDisplay

.react-app ._appContainer_12iah_1,
.react-app ._contentColumn_12iah_2 {
  height: 100%;
}

.react-app ._appContainer_12iah_1 {
  background: var(--ff-bg-base-lighter);
  min-height: 100%;
  height: 100%;
  overflow: hidden;
}
.react-app ._appContainer_12iah_1 * {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Helvetica Neue", Arial, sans-serif !important;
}
.react-app ._appContainer_12iah_1 .material-icons {
  font-family: "Material Icons" !important;
}

.react-app ._logoLink_12iah_19 {
  width: 100%;
  display: inline-block;
  padding: 0 !important;
}
.react-app ._logoLink_12iah_19:hover {
  border-bottom: none !important;
}

.react-app ._logoImage_12iah_28 {
  margin-top: var(--space-big);
  margin-bottom: var(--space-big);
  max-width: 100%;
  max-height: 80px;
}

.react-app ._contentColumn_12iah_2,
.react-app ._localeColumn_12iah_36,
.react-app ._offsetColumn_12iah_37 {
  background: var(--ff-bg-base-lighter);
}

.react-app ._contentColumn_12iah_2 {
  -ms-overflow-style: none;
  overflow: hidden;
}
.react-app ._contentColumn_12iah_2::-webkit-scrollbar {
  display: none;
}
.react-app ._contentColumn_12iah_2::-moz-scrollbar {
  display: none;
}

.react-app ._scrollContainer_12iah_52 {
  overflow-y: auto;
  padding-right: 60px;
  height: 100%;
  width: 100%;
}

.react-app ._localeSelector_12iah_59 {
  margin-top: var(--space-default);
  margin-right: var(--space-small);
  position: absolute !important;
  right: 8px;
}

.react-app ._localeColumn_12iah_36 {
  position: relative;
}

.react-app ._imageColumn_12iah_70 {
  position: sticky !important;
  top: 0 !important;
  height: 100%;
  width: 100%;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.react-app ._backgroundImage_12iah_79 {
  overflow: hidden;
  position: relative;
  height: 100vh;
  width: 100%;
  background-color: var(--ff-bg-base-light);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.react-app ._backgroundImage_12iah_79 {
  animation: blinking-grey 750ms ease-in-out infinite alternate both;
}

@media only screen and (max-width: 768px) {
  .react-app ._appContainer_12iah_1 ._imageColumn_12iah_70 {
    overflow: hidden;
  }
}
import React from 'react'
import { useTranslation } from 'react-i18next'

import { Text } from '@fullfabric/alma-mater'

import styles from './styles.module.scss'

function BarGraphPlaceholder() {
  const { t } = useTranslation()
  return (
    <div className={styles.placeholderContainer}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='460'
        height='276'
        fill='none'
        viewBox='0 0 460 276'
        preserveAspectRatio='none'
      >
        <g fill='#EDF0F3' clipPath='url(#clip0_1251_3780)'>
          <path d='M24.473 259H484.473V260H24.473z'></path>
          <path d='M24.473 43H484.473V44H24.473z'></path>
          <path d='M24.473 151H484.473V152H24.473z'></path>
          <path d='M68 260H69V264H68z'></path>
          <path d='M158 260H159V264H158z'></path>
          <path d='M248 260H249V264H248z'></path>
          <path d='M338 260H339V264H338z'></path>
          <path d='M428 260H429V264H428z'></path>
          <rect width='15' height='8' x='61' y='268' rx='2'></rect>
          <rect width='15' height='8' x='151' y='268' rx='2'></rect>
          <rect width='15' height='8' x='241' y='268' rx='2'></rect>
          <rect width='15' height='8' x='331' y='268' rx='2'></rect>
          <rect width='15' height='8' x='421' y='268' rx='2'></rect>
          <rect width='14' height='8' x='1' y='40' rx='2'></rect>
          <rect width='14' height='8' x='1' y='149' rx='2'></rect>
          <rect width='14' height='8' x='1' y='245' rx='2'></rect>
          <path d='M51 194a2 2 0 012-2h12a2 2 0 012 2v64H51v-64zM71 142a2 2 0 012-2h12a2 2 0 012 2v116H71V142zM141 111a2 2 0 012-2h12a2 2 0 012 2v147h-16V111zM161 198a2 2 0 012-2h12a2 2 0 012 2v60h-16v-60zM231 149a2 2 0 012-2h12a2 2 0 012 2v109h-16V149zM251 96a2 2 0 012-2h12a2 2 0 012 2v162h-16V96zM321 127a2 2 0 012-2h12a2 2 0 012 2v131h-16V127zM341 172a2 2 0 012-2h12a2 2 0 012 2v86h-16v-86zM411 74a2 2 0 012-2h12a2 2 0 012 2v184h-16V74zM431 158a2 2 0 012-2h12a2 2 0 012 2v100h-16V158z'></path>
          <rect width='9' height='11' rx='2'></rect>
          <rect width='35' height='11' x='13' rx='2'></rect>
          <rect width='32' height='11' x='52' rx='2'></rect>
          <rect width='63' height='11' x='100' rx='2'></rect>
          <rect width='9' height='11' x='167' rx='2'></rect>
          <rect width='35' height='11' x='180' rx='2'></rect>
          <rect width='35' height='11' x='231' rx='2'></rect>
          <rect width='9' height='11' x='270' rx='2'></rect>
          <rect width='32' height='11' x='283' rx='2'></rect>
        </g>
        <defs>
          <clipPath id='clip0_1251_3780'>
            <path fill='#fff' d='M0 0H460V276H0z'></path>
          </clipPath>
        </defs>
      </svg>
      <Text tag='span' type='h6' className={styles.placeholderText}>
        {t('No data available')}
      </Text>
    </div>
  )
}

export default BarGraphPlaceholder

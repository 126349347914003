import { useCallback, useMemo } from 'react'

import { useTusUploads } from 'shared/hooks/useTusUploads'

import {
  getDateOnlyString,
  uploadErrorsAsErrorsInForm,
  uploadStatusAsFieldOptions,
  useDateFields,
  useFileUploadFields,
  useFileUploadFieldSaver,
  validateOpts
} from './helpers'

/**
 * Helper hook that goes hand-in-hand with the `SchemableForm` component. Given
 * a Schemable Document, it returns handlers for persisting changes in the form,
 * including file uploads.
 *
 * @param {Object} schemableDocument A Schemable Document (e.g. within an Evaluation).
 * @param {String} opts.documentBaseUrl The base URL for document paths.
 * @param {Function} opts.saveField A function to save the value from a field in the
 *                                  Schemable Document in the remote API.
 * @param {String} opts.userId The ID of the user that is currently logged in.
 * @returns {Object} Props to pass to the `SchemableForm` component: `error`,
 *                  `handleSaveField`, `countriesAsOptions`, and `fieldOptions`.
 */
export default function useSchemableDocumentForm(schemableDocument, opts = {}) {
  const { documentBaseUrl, saveField, userId } = validateOpts(opts)
  const dateFields = useDateFields(schemableDocument)
  const fileUploadFields = useFileUploadFields(schemableDocument)

  const uploadEndpoint = `${documentBaseUrl}/documents/${schemableDocument?.id}/files/resumable`
  const {
    error: uploadsError,
    prepareUpload,
    startUpload,
    status,
    tusUploads
  } = useTusUploads({
    endpoint: uploadEndpoint,
    userId,
    uploadScopeId: schemableDocument?.id
  })
  const saveFileUploadField = useFileUploadFieldSaver({
    schemableDocument,
    prepareUpload,
    startUpload
  })

  const handleSaveField = useCallback(
    async (fieldName, value) => {
      const isDateField = dateFields.includes(fieldName)
      const isFileUploadField = fileUploadFields.includes(fieldName)
      const isSimpleField = !isFileUploadField && !isDateField

      if (isSimpleField) {
        saveField(fieldName, value)
        return
      }

      if (isDateField) {
        const dateInValueOnly = getDateOnlyString(value)
        saveField(fieldName, dateInValueOnly)
        return
      }

      if (isFileUploadField) {
        await saveFileUploadField({
          fieldName,
          value
        })
      }
    },
    [dateFields, fileUploadFields, saveField, saveFileUploadField]
  )

  const formError = useMemo(
    () => uploadErrorsAsErrorsInForm(tusUploads, uploadsError),
    [tusUploads, uploadsError]
  )

  const formFieldOptions = useMemo(
    () => uploadStatusAsFieldOptions(tusUploads, status),
    [tusUploads, status]
  )

  return {
    error: formError,
    fieldOptions: formFieldOptions,
    handleSaveField
  }
}

{
  "Change Path": "Change Path",
  "Cancel": "Cancel",
  "Are you ABSOLUTELY sure?": "Are you ABSOLUTELY sure?",
  "This action CANNOT be undone.": "This action CANNOT be undone.",
  "This action will:": "This action will:",
  "Change the path of the programme choice": "Change the path of the programme choice",
  "Delete all courses selected on the application": "Delete all courses selected on the application",
  "This action will NOT:": "This action will NOT:",
  "Add new courses for the new path to the application": "Add new courses for the new path to the application",
  "Select at least  courses": "Select at least  courses",
  "Select at most  courses": "Select at most  courses",
  "Complete": "Complete",
  "Incomplete": "Incomplete",
  "Delete Course": "Delete Course",
  "Delete": "Delete",
  "Remove the course from the application": "Remove the course from the application",
  "Path:": "Path:",
  "There was an error": "__STRING_NOT_TRANSLATED__",
  "Programme stats": "__STRING_NOT_TRANSLATED__",
  "a minimum of <1>{{count}}</1> course must be selected": "a minimum of <1>{{count}}</1> course must be selected",
  "a minimum of <1>{{count}}</1> course must be selected_plural": "a minimum of <1>{{count}}</1> courses must be selected",
  "a maximum of <1>{{count}}</1> course can be selected": "a maximum of <1>{{count}}</1> course can be selected",
  "a maximum of <1>{{count}}</1> course can be selected_plural": "a maximum of <1>{{count}}</1> courses can be selected",
  " Courses": " Courses",
  "All of these courses are required.": "All of these courses are required.",
  "minSelection": "a minimum of {{count}} course must be selected",
  "minSelection_plural": "a minimum of <1>{{count}}</1> courses must be selected",
  "maxSelection": "a maximum of {{count}} course can be selected",
  "maxSelection_plural": "a maximum of <1>{{count}}</1> courses can be selected"
}

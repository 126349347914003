import React from 'react'
import { Route, Switch } from 'react-router'

import ApplicationPerformance from 'apps/Reports/pages/ApplicationPerformance'
import FixedClassConversion from 'apps/Reports/pages/FixedClassConversion'
import FixedClassOverview from 'apps/Reports/pages/FixedClassOverview'
import FixedClassProductPerformance from 'apps/Reports/pages/FixedClassProductPerformance'
import Homepage from 'apps/Reports/pages/Homepage'
import ProductConversion from 'apps/Reports/pages/ProductConversion'
import ProductOverview from 'apps/Reports/pages/ProductOverview'
import ProductPerformance from 'apps/Reports/pages/ProductPerformance'
import ReportsList from 'apps/Reports/pages/ReportsList'
import {
  REPORTS_APPLIC_PERFORMANCE_ROUTE,
  REPORTS_CLASS_CONVERSION_ROUTE,
  REPORTS_CLASS_OVERVIEW_ROUTE,
  REPORTS_HOME_ROUTE,
  REPORTS_HOMEPAGE_ROUTE,
  REPORTS_PRODUCT_CONVERSION_ROUTE,
  REPORTS_PRODUCT_OVERVIEW_ROUTE,
  REPORTS_PRODUCT_PERFORMANCE_DETAIL_ROUTE,
  REPORTS_PRODUCT_PERFORMANCE_ROUTE
} from './constants'

const ReportsAppRoutes = () => {
  return (
    <Switch>
      <Route path={REPORTS_HOMEPAGE_ROUTE} component={Homepage} />
      <Route
        path={REPORTS_CLASS_OVERVIEW_ROUTE}
        component={FixedClassOverview}
      />
      <Route
        path={REPORTS_CLASS_CONVERSION_ROUTE}
        component={FixedClassConversion}
      />
      <Route
        exact
        path={REPORTS_PRODUCT_PERFORMANCE_ROUTE}
        component={ProductPerformance}
      />
      <Route
        exact
        path={REPORTS_PRODUCT_PERFORMANCE_DETAIL_ROUTE}
        component={FixedClassProductPerformance}
      />
      <Route
        exact
        path={REPORTS_PRODUCT_OVERVIEW_ROUTE}
        component={ProductOverview}
      />
      <Route
        exact
        path={REPORTS_APPLIC_PERFORMANCE_ROUTE}
        component={ApplicationPerformance}
      />
      <Route
        exact
        path={REPORTS_PRODUCT_CONVERSION_ROUTE}
        component={ProductConversion}
      />
      <Route exact path={REPORTS_HOME_ROUTE} component={ReportsList} />
    </Switch>
  )
}

export default ReportsAppRoutes

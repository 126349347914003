import React from 'react'
import { Trans } from 'react-i18next'

import { Text } from '@fullfabric/alma-mater'

import classNames from 'classnames'
import styles from './styles.module.scss'

const LegendContent = ({ payload, hasVariationData }) => {
  return (
    <ul className={classNames([styles.legendContainer, styles.barChartLegend])}>
      {payload.map((entry) => (
        <li key={entry.value} className={styles.itemContainer}>
          <span
            style={{ backgroundColor: entry.color }}
            className={styles.itemIcon}
          />
          <Text>
            <Trans>Total profiles</Trans>
          </Text>
        </li>
      ))}
      {hasVariationData && (
        <li className={styles.itemContainer}>
          <span
            style={{ backgroundColor: '#E9E2F8' }}
            className={styles.itemIcon}
          />
          <Text>
            <Trans>Conversion rate</Trans>
          </Text>
        </li>
      )}
    </ul>
  )
}

export default LegendContent

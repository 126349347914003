import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Text } from '@fullfabric/alma-mater'

import { useMergeData } from 'apps/MergeProfiles/contexts/MergeData'
import {
  buildCascadingFieldLabels,
  mapCascadingValues
} from 'apps/MergeProfiles/utils/cascadingDropDown'
import getFieldValue from 'apps/MergeProfiles/utils/getFieldValue'
import getLocaledLabel from 'apps/MergeProfiles/utils/getLocaledLabel'
import { getEmailFieldValues } from 'apps/MergeProfiles/utils/getMergeParamsFromData'

import classNames from 'classnames'
import styles from '../styles.module.scss'

const noValue = (
  <Text type='h5' className={styles.summaryFieldValue}>
    <Trans>No value</Trans>
  </Text>
)

const getProfileFieldValueAsArray = (field, profile) => {
  const rawValue = getFieldValue(field, profile)
  if (Array.isArray(rawValue)) {
    if (rawValue.length !== 0) return rawValue
  } else {
    if (rawValue) return [rawValue]
  }

  return null
}

const SummaryField = ({ field }) => {
  const { i18n } = useTranslation()
  const {
    mergeData: {
      targetProfile,
      sourceProfile,
      profileInfoFrom,
      smartEmailInfoFrom
    }
  } = useMergeData()

  const fieldName = getLocaledLabel(field.locals, i18n.language)
  const mainProfileFieldValue = getProfileFieldValueAsArray(
    field,
    targetProfile
  )
  const duplicateProfileFieldValue = getProfileFieldValueAsArray(
    field,
    sourceProfile
  )

  const isEmailField = field.type === 'Schemable::Fields::Types::SmartEmail'
  const isAddressField = field.type === 'Schemable::Fields::Types::Address'
  const isCascadingDropdown =
    field.type === 'Schemable::Fields::Types::CascadingDropDown'

  const emailFieldValues = isEmailField
    ? getEmailFieldValues(smartEmailInfoFrom, sourceProfile, targetProfile)
    : {}

  const fieldChanged = !isEmailField
    ? profileInfoFrom[field.name] === 'duplicate'
    : Object.keys(emailFieldValues).includes(field.name) &&
      emailFieldValues[field.name] !== targetProfile[field.name]

  const fieldValue = fieldChanged
    ? isEmailField
      ? [emailFieldValues[field.name]]
      : duplicateProfileFieldValue
    : mainProfileFieldValue

  const isFieldAddressAndIsEmpty =
    isAddressField && fieldValue?.every((x) => !x)

  const isFieldAddressAndMainProfileValueIsEmpty =
    isAddressField && mainProfileFieldValue?.every((x) => !x)

  const cascadingFieldLabels =
    isCascadingDropdown && buildCascadingFieldLabels(field, i18n)

  return (
    <div
      className={classNames(
        styles.summaryField,
        fieldChanged && styles.changed,
        isCascadingDropdown && styles.cascading
      )}
    >
      <Text type='h6' marginBottom='tiny'>
        {fieldName}

        {fieldChanged && (
          <Text
            tag='span'
            type='f6'
            fontColor='text-success'
            marginLeft='small'
          >
            <Trans>!Changed!</Trans>
          </Text>
        )}
      </Text>

      <div className={styles.summaryFieldValueWrapper}>
        <div
          className={classNames(
            styles.keptFieldValuesContainer,
            fieldChanged && mainProfileFieldValue && styles.hasChanges
          )}
        >
          {!isFieldAddressAndIsEmpty && fieldValue
            ? fieldValue.map((value, idx) => (
                <FieldValue
                  cascadingLabels={cascadingFieldLabels}
                  className={styles.summaryFieldValue}
                  key={idx}
                  value={value}
                />
              ))
            : noValue}
        </div>

        {fieldChanged && mainProfileFieldValue && (
          <div className={styles.changedFieldValuesContainer}>
            {!isFieldAddressAndMainProfileValueIsEmpty &&
              mainProfileFieldValue.map((value, idx) => (
                <FieldValue
                  cascadingLabels={cascadingFieldLabels}
                  className={classNames(
                    styles.summaryFieldValue,
                    styles.oldValue
                  )}
                  key={idx}
                  value={value}
                />
              ))}
          </div>
        )}
      </div>
    </div>
  )
}

export default SummaryField

function FieldValue({ className, cascadingLabels, value }) {
  if (cascadingLabels) {
    return mapCascadingValues(
      value,
      cascadingLabels,
      (subFieldLabel, value) => (
        <div className={styles.summaryFieldSubField} key={subFieldLabel}>
          <Text marginBottom='tiny' type='h6'>
            {subFieldLabel}
          </Text>

          <Text className={className} fontColor='text-base-darkest' type='h5'>
            {value}
          </Text>
        </div>
      )
    )
  }

  return (
    <Text className={className} fontColor='text-base-darkest' type='h5'>
      {value || '-'}
    </Text>
  )
}

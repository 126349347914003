import qs from 'qs'

const getApplicationTemplatesAppliedBy = (email) => {
  const params = qs.stringify({ email })

  return fetch(`/api/users/lookup?${params}`, {
    credentials: 'same-origin'
  }).then((result) => result.json())
}

export default getApplicationTemplatesAppliedBy

import React from 'react'
import { Trans } from 'react-i18next'

import useFetchedImportData from 'apps/Imports/components/import-report-step/hooks/use-fetched-import-data'
import { IMPORT_TYPES } from 'apps/Imports/utils/constants'
import ReportSummaryField from '../../report-summary-field'
import ClassOfContextSummary from './class-of-context-summary'
import EventContextSummary from './event-context-summary'
import MappingSummary from './mapping-summary'
import PolicyConsentSummary from './policy-consent-summary'
import ProfileDetailsSummary from './profile-details-summary'

const ExpandedSummary = () => {
  const { document } = useFetchedImportData()

  const isProfileImport = document._ruby_type === IMPORT_TYPES.PROFILE

  return (
    <>
      {isProfileImport ? (
        <ReportSummaryField
          label={<Trans>Update existing profiles?</Trans>}
          value={
            document?.extract_profile_processor_options?.update ? (
              <Trans>Yes, update existing profiles</Trans>
            ) : (
              <Trans>No, only add new profiles</Trans>
            )
          }
          marginTop='more'
        />
      ) : null}

      <PolicyConsentSummary />
      <ClassOfContextSummary />
      <EventContextSummary />
      <ProfileDetailsSummary />
      <MappingSummary />
    </>
  )
}

export default ExpandedSummary

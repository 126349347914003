import React, { Suspense, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useParams } from 'react-router-dom'

import { IfPermit } from '@fullfabric/authorization-officer'

import FullScreenLoading from 'shared/components/FullScreenLoading'
import SuccessPopupMessage from 'shared/components/SuccessPopupMessage'
import TablePageLayout from 'shared/components/TablePageLayout'

import CreateEvaluationButton from 'apps/Evaluations/components/CreateEvaluationButton'
import EvaluationsListPageHeader from 'apps/Evaluations/components/EvaluationsListPageHeader'
import EvaluationsTable from 'apps/Evaluations/components/EvaluationsTable'
import { ApplicationDataProvider } from 'apps/Evaluations/context/ApplicationData'

const EvaluationsListPage = () => {
  const { t } = useTranslation()
  const { profileId, applicationId } = useParams()

  const [feedbackMessage, setFeedbackMessage] = useState(undefined)
  const { search } = useLocation()
  const searchParams = new URLSearchParams(search)
  const evaluationRecentlyDeleted = searchParams.get(
    'recently-deleted-evaluation'
  )

  useEffect(() => {
    if (evaluationRecentlyDeleted) {
      setFeedbackMessage(t('Evaluation deleted'))
    }
    return () => setFeedbackMessage(undefined)
  }, [evaluationRecentlyDeleted, t])

  return (
    <Suspense fallback={<FullScreenLoading />}>
      <ApplicationDataProvider applicationId={applicationId}>
        <TablePageLayout>
          <header>
            <EvaluationsListPageHeader
              profileId={profileId}
              applicationId={applicationId}
              action={
                <IfPermit to='evaluations.evaluations_change'>
                  <CreateEvaluationButton
                    onCreated={() =>
                      setFeedbackMessage(t('Evaluation created'))
                    }
                  />
                </IfPermit>
              }
            />
          </header>
          <TablePageLayout.TableSection>
            <EvaluationsTable applicationId={applicationId} />
          </TablePageLayout.TableSection>
        </TablePageLayout>
        <SuccessPopupMessage
          isDisplayed={feedbackMessage}
          onAfterTimeout={() => setFeedbackMessage(undefined)}
          message={feedbackMessage}
        />
      </ApplicationDataProvider>
    </Suspense>
  )
}

export default EvaluationsListPage

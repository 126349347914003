import { useEffect, useRef, useState } from 'react'

const useHideOnScroll = (minScroll = 0, threshold = minScroll) => {
  const [isVisible, setIsVisible] = useState(true)
  const windowScroll = useRef(window.scrollY)

  useEffect(() => {
    const handleScroll = () => {
      const isInAlwaysVisibleArea = window.scrollY < minScroll
      const scrolledEnough =
        window.scrollY !== windowScroll.current &&
        Math.abs(window.scrollY - windowScroll.current) >= threshold
      const scrolledUp = windowScroll.current > window.scrollY

      if (scrolledEnough || isInAlwaysVisibleArea) {
        setIsVisible(scrolledUp || isInAlwaysVisibleArea)
        windowScroll.current = window.scrollY
      }
    }

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [minScroll, threshold])

  return isVisible
}

export default useHideOnScroll

import React from 'react'

import { CartesianGrid } from 'recharts'

const renderCustomCartesianGrid = ({ ...configuration } = {}) => (
  <CartesianGrid
    stroke='#EDF0F3'
    strokeWidth='1'
    vertical={false}
    {...configuration}
  />
)

export default renderCustomCartesianGrid

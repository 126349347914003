.react-app ._container_2hq07_1 {
  position: relative;
  width: 100%;
}

.react-app ._parentLink_2hq07_6 {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.react-app ._accordionToggle_2hq07_13 {
  padding: var(--space-small) var(--space-default);
  padding-left: var(--space-more);
}
.react-app ._accordionToggle_2hq07_13._opened_2hq07_17 {
  background-color: var(--ff-bg-base-lighter);
}

.react-app ._arrow_2hq07_21 {
  display: block;
  margin-left: var(--space-small);
}
.react-app ._arrow_2hq07_21 svg {
  fill: var(--ff-color-text-base-darker) !important;
}

.react-app ._mobileArrowContainer_2hq07_29 {
  margin-left: var(--space-less);
}

.react-app ._mobileArrow_2hq07_29 {
  transform: rotate(90deg);
  fill: var(--ff-color-text-base-darkest) !important;
  transition: transform 300ms ease-in-out;
}
.react-app ._mobileArrow_2hq07_29._openedArrow_2hq07_38 {
  transform: rotate(-90deg);
}

.react-app ._folder_2hq07_42 {
  position: absolute;
  z-index: 1;
  top: 0;
  left: calc(100% - var(--space-small));
  padding: var(--space-small) 0;
  background-color: var(--ff-bg-base-lightest);
  box-shadow: var(--shadow-3);
}
.react-app ._folder_2hq07_42 a {
  white-space: nowrap;
  padding: var(--space-less) var(--space-default);
}
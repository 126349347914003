import React from 'react'
import { Route, Switch } from 'react-router'

import LoginRoutes from 'apps/Authentication/components/AppRoutes/LoginRoutes'
import {
  AUTHENTICATION_VIA_APPLIC_ROUTE,
  FORGOT_PASSWORD_ROUTE,
  LOGIN_ROUTE,
  RESET_PASSWORD_EMAIL_SENT_ROUTE,
  SIGNUP_ROUTE
} from 'apps/Authentication/constants/routes'

const ForgotPasswordPage = React.lazy(() =>
  import('apps/Authentication/pages/ForgotPasswordPage')
)
const ResetEmailSentPage = React.lazy(() =>
  import('apps/Authentication/pages/ResetEmailSentPage')
)
const SignupPage = React.lazy(() =>
  import('apps/Authentication/pages/SignupPage')
)
const AuthenticationViaApplicPage = React.lazy(() =>
  import('apps/Authentication/pages/AuthenticationViaApplicPage')
)

const LoginAppRoutes = () => {
  return (
    <Switch>
      <Route path={LOGIN_ROUTE} component={LoginRoutes} />
      <Route path={SIGNUP_ROUTE} component={SignupPage} />
      <Route path={FORGOT_PASSWORD_ROUTE} component={ForgotPasswordPage} />
      <Route
        path={RESET_PASSWORD_EMAIL_SENT_ROUTE}
        component={ResetEmailSentPage}
      />
      <Route
        path={AUTHENTICATION_VIA_APPLIC_ROUTE}
        component={AuthenticationViaApplicPage}
      />
    </Switch>
  )
}

export default LoginAppRoutes

import { useCallback, useState } from 'react'
import { useQuery } from 'react-query'

import capitalize from 'lodash/capitalize'

import getProductConversion from 'apps/Reports/api/getProductConversion'

export default function useFunnelChartData(
  filter,
  selectedLifecycles,
  fixedContextId
) {
  const { data, isLoading, error } = useQuery(
    ['getProductConversion', filter, selectedLifecycles],
    () =>
      getProductConversion({
        contextId: fixedContextId || filter.context,
        states: selectedLifecycles
      }),
    {
      enabled:
        selectedLifecycles?.length > 0 && !!(fixedContextId || filter?.context)
    }
  )

  const [barEdges, setBarEdges] = useState([])

  const updateBarEdge = useCallback((edgeIdx, { lx, rx, y }) => {
    setBarEdges((currentEdges) => {
      const barEdges = [...currentEdges]
      barEdges[edgeIdx] = { lx, rx, y }
      return barEdges
    })
  }, [])

  const humanizedData = data && {
    ...data,
    stats: data?.stats?.map((dataPoint) => ({
      ...dataPoint,
      state: dataPoint.state
        .split('::')
        .map(capitalize)
        .join(' - ')
        .replace('_', ' ')
    }))
  }

  return {
    barEdges,
    isLoading,
    error,
    data: humanizedData,
    updateBarEdge
  }
}

import React from 'react'

export const SubjectCreationHandlerContext = React.createContext()

export const SubjectCreationHandlerProvider = ({ children, handler }) => {
  return (
    <SubjectCreationHandlerContext.Provider value={handler}>
      {children}
    </SubjectCreationHandlerContext.Provider>
  )
}

export const useHandleCreateSubject = () => {
  return React.useContext(SubjectCreationHandlerContext)
}

import { utils } from '@fullfabric/public-api'

const saveEvaluation = async (evaluationId, documentId, data) => {
  const response = await fetch(
    `/evaluations/api/evaluations/${evaluationId}/documents/${documentId}`,
    {
      credentials: 'same-origin',
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    }
  )

  return await utils.checkResponse(response)
}

export default saveEvaluation

import React from 'react'
import { Trans } from 'react-i18next'

import { Text } from '@fullfabric/alma-mater'

import CardTitle from 'apps/MergeProfiles/components/ApplicationCard/CardTitle'

import styles from './styles.module.scss'

const ApplicationNotInMainProfileCard = ({ applic }) => {
  return (
    <div className={styles.notOnMainProfileCard}>
      <CardTitle
        title={`${applic.context_name} ${applic.template_name}`}
        // subtitle={getRoleContextSubtitle(context)}
      />

      <Text marginTop='default' paddingLeftRight='more'>
        <Trans>Main profile doesn’t have this application</Trans>
      </Text>
    </div>
  )
}

export default ApplicationNotInMainProfileCard

import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useInView } from 'react-intersection-observer'

import { Loading, Table, Text } from '@fullfabric/alma-mater'

import cx from 'classnames'
import styles from './styles.module.scss'

export default function LoadMoreRow({
  onClick,
  virtualized,
  loadingNextPage,
  pageSize,
  page,
  total,
  autoPaginate
}) {
  const remainingSize = total - (page + 1) * pageSize
  const nextPageSize = Math.min(remainingSize, pageSize)

  const { ref, inView } = useInView({ threshold: 0.1 })

  useEffect(() => {
    if (autoPaginate && inView && !loadingNextPage) {
      onClick()
    }
  }, [inView, autoPaginate, onClick, loadingNextPage])

  const { t } = useTranslation()

  return (
    <Table.Row
      ref={autoPaginate ? ref : null}
      role='row'
      tag='div'
      aria-label='load more row'
      onClick={onClick}
      className={cx(styles.loadMoreRow, loadingNextPage && styles.loadingNext)}
    >
      <Table.Data
        role='cell'
        tag='div'
        colSpan='100'
        align='center'
        className={cx(styles.loadMoreCell, virtualized && styles.virtualized)}
      >
        {loadingNextPage ? (
          <Loading />
        ) : (
          <Text tag='span' type='h6' fontColor='text-base-darkest'>
            {t('Load {{pageSize}} ', { pageSize: nextPageSize })}

            <Text tag='span' type='h6'>
              {t('of {{remainingSize}}', {
                remainingSize: total - (page + 1) * pageSize
              })}
            </Text>
          </Text>
        )}
      </Table.Data>
    </Table.Row>
  )
}

import { capitalize } from 'lodash'

import getLocaledLabel from '../getLocaledLabel'

/**
 * Maps through each sub-field value in a CascadingDropDown field value calling
 * the given `callback` function.
 *
 * @param {Object} value The value for a cascading dropdown field
 * @param {Object} cascadingLabels A map of cascading sub-field names to their
 *                                 localized labels.
 * @param {Function} cb A callback function that will be called for each
 *                      sub-field with `(label, value)`.
 * @returns The result of calling `cb` for each sub-field.
 */
export function mapCascadingValues(value, cascadingLabels, cb) {
  return Object.entries(value).map(([subFieldName, { label }]) => {
    const subFieldLabel =
      cascadingLabels[subFieldName] || capitalize(subFieldName)

    return cb(subFieldLabel, label)
  })
}

/**
 * Builds a map of cascading sub-field names to their localized labels.
 *
 * @param {Object} field A `CascadingDropDown` field
 * @param {Object} i18n The localization object.
 * @returns The built map.
 */
export function buildCascadingFieldLabels(field, i18n) {
  return field.cascading_drop_down_fields.reduce(
    (labels, { name, locals }) => ({
      ...labels,
      [name]: getLocaledLabel(locals, i18n.language)
    }),
    {}
  )
}

import React from 'react'

import { Text } from '@fullfabric/alma-mater'

import activeDotIcon from './active_dot.svg'
import completeIcon from './complete.svg'
import inactiveDotIcon from './inactive_dot.svg'

import styles from './styles.module.scss'

const Step = ({ name, active, completed, ...props }) => {
  const dotImage = active
    ? activeDotIcon
    : completed
    ? completeIcon
    : inactiveDotIcon

  return (
    <div
      role='progressbar'
      aria-label={name}
      className={styles.stepContainer}
      aria-current={active ? 'true' : undefined}
      {...props}
    >
      <img src={dotImage} alt={`${name} step`} className={styles.dot} />

      <Text type='h4' fontColor={active ? 'text-primary' : 'text-base-darker'}>
        {name}
      </Text>
    </div>
  )
}

export default Step

import React, { useMemo, useState } from 'react'

import PropTypes from 'prop-types'

import { Field, Modal } from '@fullfabric/alma-mater'

import useExportCount from 'shared/hooks/exports/useExportCount'
import useExportCreate from 'shared/hooks/exports/useExportCreate'

import ExportStatusCallout from './PaymentPlansExportModal/ExportStatusCallout'
import Footer from './PaymentPlansExportModal/Footer'
import Header from './PaymentPlansExportModal/Header'

import styles from './PaymentPlansExportModal/styles.module.scss'

const FORMAT_OPTIONS = [
  { value: 'csv', label: 'CSV' },
  { value: 'xlsx', label: 'Excel' }
]

const EXPORT_TYPE = 'payment_plan'

export default function PaymentPlansExportModal({
  columns,
  filters,
  isOpen,
  search,
  onClose
}) {
  const exportOpts = useMemo(
    () => ({
      fields_to_show: columns,
      q: search,
      publish_status: filters?.publish_status,
      fee_statuses: filters?.status,
      lifecycles: filters?.lifecycle,
      context_ids: filters?.context_ids
    }),
    [columns, filters, search]
  )

  const { exportCount, exportCountReady } = useExportCount({
    type: EXPORT_TYPE,
    opts: exportOpts
  })

  const {
    createExport,
    creating: exporting,
    error: exportCreateError,
    export: xport
  } = useExportCreate()

  const [format, setFormat] = useState('csv')

  return (
    <Modal
      id='export-modal'
      footer={
        <Footer
          exporting={exporting}
          ready={exportCountReady}
          showExport={!xport}
          onClose={onClose}
          onExport={() =>
            createExport({ format, opts: exportOpts, type: EXPORT_TYPE })
          }
        />
      }
      header={<Header onClose={onClose} />}
      isOpen={isOpen}
      size='medium'
    >
      <ExportStatusCallout
        xport={xport}
        exportCreateError={exportCreateError}
        exportCount={exportCount}
        exportCountReady={exportCountReady}
      />

      {!xport && (
        <form className={styles.form}>
          <Field
            id='export-format-select'
            disabled={exporting}
            type='dropdown'
            label='Export format'
            inputOptions={{ inModal: true }}
            options={FORMAT_OPTIONS}
            value={format}
            onChange={(_id, format) => setFormat(format)}
          />
        </form>
      )}
    </Modal>
  )
}

PaymentPlansExportModal.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.string).isRequired,
  filters: PropTypes.object,
  isOpen: PropTypes.bool.isRequired,
  search: PropTypes.string,
  onClose: PropTypes.func.isRequired
}

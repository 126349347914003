import React from 'react'
import { useQuery } from 'react-query'

import getStudyPlanSubject from 'apps/StudyPlans/api/getStudyPlanSubject'
import { useStudyPlan } from 'apps/StudyPlans/contexts/StudyPlanData'
import {
  useOriginalSubjectData,
  useSetSubjectEditionErrors,
  useSubjectEditionData
} from 'apps/StudyPlans/contexts/SubjectEditionData'
import { getDataToUpdate } from './utils'
import validateEditSubjectData from './validateEditSubjectData'

const useOnSaveSubjectClick = ({
  sectionName,
  fieldsToUpdate,
  setOpenDeleteFailedModal,
  setOpenPropagationModal,
  updateSubjectMutation
}) => {
  const studyPlan = useStudyPlan()
  const subjectData = useSubjectEditionData()
  const originalSubjectData = useOriginalSubjectData()
  const setSubjectEditionErrors = useSetSubjectEditionErrors()

  const { data: reloadedStudyPlanSubject, isLoading: isReloadingSubject } =
    useQuery(['studyPlanSubject', studyPlan.id, subjectData.id], async () => {
      return await getStudyPlanSubject(studyPlan.id, subjectData.id)
    })

  const onClickSave = React.useCallback(() => {
    const dataToUpdate = getDataToUpdate(subjectData, fieldsToUpdate)

    const errors = validateEditSubjectData(
      subjectData,
      dataToUpdate,
      sectionName,
      fieldsToUpdate
    )

    if (errors) {
      setSubjectEditionErrors((existingErrors) => ({
        ...existingErrors,
        ...errors
      }))

      return
    }

    const pathsWithCoursesWithRegistration = originalSubjectData.course_types
      .filter((courseType) => courseType.is_registered_in_any_transcript)
      .map((courseType) => courseType.path_id)

    const allCourseWithRegistrationHaveType =
      pathsWithCoursesWithRegistration.every(
        (pathId) => subjectData[`course_type_${pathId}`]
      )

    const isWithinAnyTranscript =
      reloadedStudyPlanSubject.is_within_any_transcript

    if (
      sectionName === 'paths' &&
      isWithinAnyTranscript &&
      !allCourseWithRegistrationHaveType
    ) {
      setOpenDeleteFailedModal(true)
    } else if (isWithinAnyTranscript) {
      setOpenPropagationModal(true)
    } else {
      updateSubjectMutation({ studyPlanId: studyPlan.id, dataToUpdate })
    }
  }, [
    fieldsToUpdate,
    originalSubjectData?.course_types,
    reloadedStudyPlanSubject?.is_within_any_transcript,
    sectionName,
    setOpenDeleteFailedModal,
    setOpenPropagationModal,
    setSubjectEditionErrors,
    studyPlan?.id,
    subjectData,
    updateSubjectMutation
  ])

  return { onClickSave, isReloadingSubject }
}

export default useOnSaveSubjectClick

import { utils } from '@fullfabric/public-api'

const updateImport = async (importDocumentId, data) => {
  const response = await fetch(`/api/import/${importDocumentId}`, {
    credentials: 'same-origin',
    method: 'PUT',
    body: data
  })

  return await utils.checkResponse(response)
}

export default updateImport

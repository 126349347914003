import { useMutation, useQueryClient } from 'react-query'

import { useCurrentUser } from '@fullfabric/authorization-officer'

import followProfile from 'apps/Inbox/api/followProfile'
import unfollowProfile from 'apps/Inbox/api/unfollowProfile'
import { useCardFilters } from '../contexts/CardFilters'

export function useHandleFollow() {
  const onMutate = useFollowMutate()
  const onError = useOnError()

  const { mutate: handleFollowMutation } = useMutation(
    async (profile) => await followProfile(profile),
    { onMutate, onError }
  )

  return handleFollowMutation
}

export function useHandleUnfollow() {
  const onMutate = useFollowMutate()
  const onError = useOnError()

  const { mutate: handleFollowMutation } = useMutation(
    async (profile) => await unfollowProfile(profile),
    { onMutate, onError }
  )

  return handleFollowMutation
}

function useFollowMutate() {
  const currentUser = useCurrentUser()
  const queryClient = useQueryClient()
  const { cardStatus, cardType } = useCardFilters()

  return async (profile) => {
    const followedProfile = profile.followers.includes(currentUser.id)

    const newProfile = followedProfile
      ? {
          ...profile,
          followers: profile.followers.filter((pid) => pid !== currentUser.id)
        }
      : {
          ...profile,
          followers: [...profile.followers, currentUser.id]
        }

    const previousCards =
      queryClient.getQueryData(['cards', cardStatus, cardType]) || []

    const newCards = previousCards?.pages.map((page) => {
      const data = page.data.map((card) => updateCard(card, newProfile))
      return { ...page, data }
    })

    queryClient.setQueriesData(['cards', cardStatus, cardType], (data) => ({
      pages: newCards,
      pageParams: data.pageParams
    }))

    return { previousCards, cardType }
  }
}

function useOnError() {
  const queryClient = useQueryClient()
  return (_err, _data, context) => {
    const { cardStatus, cardType } = context
    queryClient.invalidateQueries(['cards', cardStatus, cardType])
  }
}

function updateCard(card, updatedProfile) {
  if (card.actor.id === updatedProfile.id) {
    return { ...card, actor: updatedProfile }
  }

  if (card.type === 'Inbox::Card::Task') {
    if (card.entity.target.id === updatedProfile.id) {
      return {
        ...card,
        entity: { ...card.entity, target: updatedProfile }
      }
    }
    if (card.entity.assigned_to.id === updatedProfile.id) {
      return {
        ...card,
        entity: { ...card.entity, assigned_to: updatedProfile }
      }
    }
  }

  if (card.type === 'Inbox::Card::Email') {
    let updatedKey = {}
    const emailProperties = ['to_emails', 'cc_emails', 'bcc_emails']

    emailProperties.forEach((property) => {
      if (
        card.entity[property]
          .map((profile) => profile.id)
          .includes(updatedProfile.id)
      ) {
        updatedKey = {
          ...card,
          entity: {
            ...card.entity,
            [property]: card.entity[property].map((propProf) =>
              propProf.id !== updatedProfile.id ? propProf : updatedProfile
            )
          }
        }
      }
    })

    if (Object.entries(updatedKey).length !== 0) return updatedKey
  }

  return card
}

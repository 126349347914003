import React from 'react'

import { IfHasModule } from '@fullfabric/authorization-officer'

import ApplicationsStep from './Steps/Applications'
import JourneysStep from './Steps/Journeys'
import ProfileInfoStep from './Steps/ProfileInfo'
import SelectDuplicateProfileStep from './Steps/SelectDuplicateProfile'
import SummaryStep from './Steps/Summary'

const Progress = () => {
  return (
    <div aria-label='progress'>
      <SelectDuplicateProfileStep />

      <ProfileInfoStep />

      <IfHasModule moduleName='applics'>
        <ApplicationsStep />
      </IfHasModule>

      <JourneysStep />

      <SummaryStep />
    </div>
  )
}

export default Progress

import React from 'react'
import { Trans } from 'react-i18next'

import SubjectsSection from 'apps/StudyPlans/components/SubjectGroupsList/SubjectsSection'
import { useExtraCurricularSubjectsForSelectedPath } from 'apps/StudyPlans/contexts/SelectedPath'

const ExtracurricularSubjectsSection = () => {
  const subjects = useExtraCurricularSubjectsForSelectedPath()

  return (
    <SubjectsSection
      title={<Trans>Extracurricular subjects</Trans>}
      subjects={subjects}
    />
  )
}

export default ExtracurricularSubjectsSection

import React from 'react'
import { Trans } from 'react-i18next'

import { Text } from '@fullfabric/alma-mater'

import { useLocale } from 'shared/contexts/Locale'

import getLocalizedText from 'apps/ContentPages/utils/get-localized-text'

import styles from '../styles.module.scss'

const ProductDescription = ({ product }) => {
  const { locale } = useLocale()
  const localizedDescription = getLocalizedText(
    product.description,
    locale,
    'html'
  )

  if (!localizedDescription) {
    return null
  }

  return (
    <div className={styles.cardInfoRow}>
      <Text type='h6'>
        <Trans>Description</Trans>:
      </Text>

      <Text
        tag='div'
        type='f5'
        fontColor='text-base-darkest'
        dangerouslySetInnerHTML={{ __html: localizedDescription }}
      />
    </div>
  )
}

export default ProductDescription

import React from 'react'
import { render } from 'react-dom'

import { MergeTags } from '../../MergeTags'

export const mergeTagPlugin = (editor, options) => {
  const type = 'merge-tag'
  const defType = editor.DomComponents.getType('default')

  editor.DomComponents.addType(type, {
    isComponent: (el) => el.tagName === type.toUpperCase(),
    model: {
      defaults: {
        tagName: type,
        draggable: false,
        editable: false,
        droppable: false,
        traits: []
      }
    },
    view: {
      render() {
        // renders the component and we override the style to be easy to understand that is a tag
        defType.view.prototype.render.apply(this, arguments)
        this.el.style =
          'border: 1px dashed lightblue; display: inline-block; padding: 2px 5px'
        this.el.innerHTML = this.model.getAttributes().label
        this.el.setAttribute('contenteditable', false)

        return this
      }
    }
  })

  editor.onReady(() => {
    editor.RichTextEditor.add(type, {
      icon: "<i class='fa fa-tag'></i>",
      attributes: {
        title: 'Insert merge tag',
        class: 'merge-tag-icon',
        id: 'mergeTags'
      },
      result: () => null
    })

    const toolbar =
      editor.RichTextEditor.getToolbarEl().querySelector('#mergeTags')
    render(<MergeTags editor={editor} mergeTags={options.mergeTags} />, toolbar)
  })
}

import React from 'react'
import { Trans } from 'react-i18next'

import { AnnotatedLayout } from '@fullfabric/alma-mater'

const CourseSectionDates = ({ children }) => {
  return (
    <AnnotatedLayout.Section
      title={<Trans>Dates</Trans>}
      description={
        <Trans>
          The dates will automatically update the status if the status is set to
          be automatically updated. The dates will not have any effect on the
          status if the status is set to be manually updated.
        </Trans>
      }
    >
      {children}
    </AnnotatedLayout.Section>
  )
}

export default CourseSectionDates

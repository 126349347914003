import React from 'react'
import { Trans } from 'react-i18next'

import { Col, Row, Text } from '@fullfabric/alma-mater'

import { useSelectedPath } from 'apps/StudyPlans/contexts/SelectedPath'
import { useStudyPlan } from 'apps/StudyPlans/contexts/StudyPlanData'
import PathItemButton from './item-button'

const PathsSection = () => {
  const selectedPath = useSelectedPath()
  const studyPlan = useStudyPlan()
  const selectedPathId = selectedPath?.id || studyPlan.paths[0].id

  return (
    studyPlan.multiple_paths && (
      <Row className='marginBottom-more'>
        <Col xs={12}>
          <Text bold fontColor='text-base-darkest' tag='span'>
            <Trans>Paths:</Trans>
          </Text>

          {studyPlan.paths.map((path) => (
            <PathItemButton
              key={path.id}
              id={path.id}
              selected={selectedPathId === path.id}
              name={path.name}
            />
          ))}
        </Col>
      </Row>
    )
  )
}

export default PathsSection

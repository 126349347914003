import React from 'react'
import { Trans } from 'react-i18next'

import { Text } from '@fullfabric/alma-mater'

import { ReactComponent as FileUploadIcon } from './icons/file-upload.svg'

import styles from './styles.module.scss'

const FileValidContent = () => (
  <div className={styles.fileUploadContent}>
    <FileUploadIcon />
    <Text className={styles.validText}>
      <Trans>Upload file</Trans>
    </Text>
  </div>
)

export default FileValidContent

import React from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

import moment from 'moment'

import getProductStatsMonthlyChange from 'apps/Reports/api/getProductStatsMonthlyChange'
import { useMainIntake } from '../../ClassOptionsContext'
import { useFilter } from '../../FilterContext'
import ComparisonWidget from '../ComparisonWidget'

const cardinalToOrdinal = (cardinal) => moment().date(cardinal).format('Do')

const IntakeMonthlyChangeWidget = () => {
  const { t } = useTranslation()
  const filter = useFilter()
  const mainIntake = useMainIntake()

  const { data, error, isLoading } = useQuery(
    [
      'getProductStatsMonthlyChange',
      mainIntake?.id,
      filter.states,
      filter.withdrawn
    ],
    () =>
      getProductStatsMonthlyChange(
        mainIntake?.id,
        filter.states,
        filter.withdrawn
      ),
    { retry: 1, enabled: !!mainIntake?.id }
  )

  const periodDescription =
    data?.from_day &&
    data?.to_day &&
    t('{{from}} to {{to}}', {
      from: cardinalToOrdinal(data.from_day),
      to: cardinalToOrdinal(data.to_day)
    })

  return (
    <div data-testid='intakeMonthlyChangeWidget'>
      <ComparisonWidget
        error={error}
        isLoading={isLoading}
        title={t('Month-to-date')}
        description={periodDescription}
        mainInfo={{
          label: mainIntake?.name,
          value: data?.current_month
        }}
        comparisonInfo={[
          {
            label: t('Last month'),
            value: data?.last_month
          },
          {
            label: t('Two months ago'),
            value: data?.two_months
          }
        ]}
      />
    </div>
  )
}

export default IntakeMonthlyChangeWidget

import React from 'react'
import { Trans } from 'react-i18next'

import { Label, TableData, TableRow, Text } from '@fullfabric/alma-mater'

import ArrowRight from './arrow-right-icon'
import { goToApplic } from './utils/applic-row-utilities'

import styles from './styles.module.scss'

const ApplicationRow = ({ applic }) => (
  <TableRow className={styles.row} onClick={() => goToApplic(applic)}>
    <TableData main>
      <Text tag='div' type='h5' fontColor='text-base-darkest'>
        {applic.full_name}
      </Text>
    </TableData>
    <TableData main complementaryInfo={applic.template_name}>
      {applic.primary_choice_contexts.map((context) => (
        <Text tag='p' type='f5' fontColor='text-base-darkest'>
          {context.context_long_name}
        </Text>
      ))}
    </TableData>
    <TableData>
      <div className={styles.status}>
        {applic.state === 'started' && (
          <Label color='purple-500' className={styles.label}>
            <Trans>In Progress</Trans>
          </Label>
        )}
        {applic.state === 'submitted' && (
          <Label color='green-700' outline className={styles.label}>
            <Trans>Submitted</Trans>
          </Label>
        )}
        <ArrowRight marginLeft-normal />
      </div>
    </TableData>
  </TableRow>
)

export default ApplicationRow

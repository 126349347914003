import { utils } from '@fullfabric/public-api'

export default async function sendInstructionsToStaff(staffId) {
  const response = await fetch(`/api/staff/${staffId}/send_instructions`, {
    method: 'PATCH',
    credentials: 'same-origin'
  })

  return await utils.checkResponse(response)
}

import React from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

import { Bar, BarChart, Legend, ResponsiveContainer, Tooltip } from 'recharts'

import { Loading } from '@fullfabric/alma-mater'

import getMultipleClassGenderDistribution from 'apps/Reports/api/getMultipleClassGenderDistribution'
import {
  commonStyles,
  CustomTooltip,
  DEFAULT_SPACE_TO_CONTENT,
  renderCustomCartesianGrid,
  renderCustomXAxis,
  renderCustomYAxis,
  XAxisTick
} from 'apps/Reports/components/CustomChartElements'
import { useFilter } from 'apps/Reports/components/ProductOverviewReport/FilterContext'
import GraphPlaceholder from 'apps/Reports/components/ProductOverviewReport/GraphPlaceholder'
import useReportFieldsSettings from 'apps/Reports/components/ProductOverviewReport/hooks/useReportFieldsSettings'
import LegendContent from 'apps/Reports/components/ProductOverviewReport/LegendContent'
import { getBarFill } from 'apps/Reports/components/ProductOverviewReport/utils/getChartColor'
import isEmptyDataSeries from 'apps/Reports/components/ProductOverviewReport/utils/isEmptyDataSeries'
import {
  useMainIntakeIndex,
  useSelectedIntakesData
} from '../../ClassOptionsContext'
import formatWithdrawnFilterValue from '../../utils/formatWithdrawnFilterValue'
import formatGenderDataseries from './formatGenderDataseries'

const Chart = () => {
  const filter = useFilter()
  const { gender: dimension } = useReportFieldsSettings()
  const { t } = useTranslation()
  const mainIntakeIndex = useMainIntakeIndex()
  const selectedIntakes = useSelectedIntakesData()
  const classIds = selectedIntakes?.map(({ id }) => id)
  const withdrawn = formatWithdrawnFilterValue(filter.withdrawn)
  const states = filter.states

  const {
    data: genderDataPerIntake,
    isLoading,
    error
  } = useQuery(
    [
      'getMultipleClassGenderDistribution',
      {
        dimension,
        classIds,
        withdrawn,
        states
      }
    ],
    () =>
      getMultipleClassGenderDistribution({
        dimension,
        classIds,
        withdrawn,
        states
      }),
    { enabled: !!selectedIntakes && selectedIntakes.length > 0 }
  )

  if (isLoading) {
    return <Loading />
  }

  if (error || isEmptyDataSeries(genderDataPerIntake)) {
    return <GraphPlaceholder.Bar />
  }

  const formattedDataSeries = formatGenderDataseries(
    genderDataPerIntake,
    selectedIntakes,
    mainIntakeIndex
  )

  return (
    <ResponsiveContainer>
      <BarChart
        data={formattedDataSeries}
        margin={{ top: 12, right: DEFAULT_SPACE_TO_CONTENT, bottom: 16 }}
        barSize={24}
        barGap={4}
        className={commonStyles.customBarChart}
      >
        <Tooltip
          content={
            <CustomTooltip
              mainDataName={selectedIntakes[mainIntakeIndex].name}
              labelFormatter={(l) => t('Gender: {{gender}}', { gender: l })}
            />
          }
        />
        {renderCustomCartesianGrid()}
        {renderCustomYAxis()}
        {renderCustomXAxis({
          dataKey: 'gender',
          tick: <XAxisTick />
        })}
        {selectedIntakes.map((intake, intakeIndex) => (
          <Bar
            key={intakeIndex}
            dataKey={intake.name}
            fill={getBarFill(intakeIndex, mainIntakeIndex)}
          />
        ))}
        <Legend
          wrapperStyle={{
            left: 0,
            top: 0,
            display: 'flex',
            alignItems: 'start'
          }}
          height={44}
          verticalAlign='top'
          align='left'
          content={<LegendContent intakes={selectedIntakes} isBarChartLegend />}
        />
      </BarChart>
    </ResponsiveContainer>
  )
}

export default Chart

const getLocalizedText = (
  locals,
  language = window.locale,
  attribute = 'label'
) => {
  if (!locals) {
    return ''
  }

  const first = locals[0]
  const firstFoundText = first && first[attribute]

  const english = locals.find((l) => l.locale === 'en-GB')
  const englishText = english && english[attribute]

  const user = locals.find(
    (l) => typeof l.locale === 'string' && l.locale.startsWith(language)
  )
  const userLanguageText = user && user[attribute]

  return userLanguageText || englishText || firstFoundText || ''
}

export default getLocalizedText

export const getSelectedPath = (applic = {}, paths) => {
  const studyPlanPaths = paths || []
  return (
    getPathsWithState(applic, studyPlanPaths).find((path) => path.selected) ||
    studyPlanPaths[0] ||
    {}
  )
}

export const getPathsWithState = (applic, paths) => {
  return (paths || []).map((path) => {
    return {
      ...path,
      selected: path.id === applic?.primary_choice_class_path_id
    }
  })
}

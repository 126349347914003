/* eslint-disable no-case-declarations */

import { LOCATION_CHANGE } from 'connected-react-router'
import { combineReducers } from 'redux'

import actionTypes from 'apps/Courses/actions/types'
import { COURSE_LIST_ROUTE } from 'apps/Courses/utils/routeConstants'
import academicDetailsSection from './academicDetailsSection'
import basicSection from './basicSection'
import capacitySection from './capacitySection'
import datesSection from './datesSection'
import schemaSection from './schemaSection'
import statusSection from './statusSection'

const data = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.createCourse.success:
    case actionTypes.getCourse.success:
    case actionTypes.updateCourseBasicSection.success:
    case actionTypes.updateCourseDatesSection.success:
    case actionTypes.updateCourseStatusSection.success:
    case actionTypes.updateCourseAcademicDetailsSection.success:
    case actionTypes.updateCourseCapacitySection.success:
    case actionTypes.updateCourseSchemaSection.success:
    case actionTypes.archiveCourse.success:
      return { ...action.payload }
    default:
      return state
  }
}

const schema = (state = null, action) => {
  switch (action.type) {
    case actionTypes.getCourseSchema.success:
      return { ...action.payload }
    default:
      return state
  }
}

const loaded = (state = false, action) => {
  switch (action.type) {
    case actionTypes.createCourse.success:
      return true
    default:
      return state
  }
}

const loadingData = (state = false, action) => {
  switch (action.type) {
    case actionTypes.getCourse.start:
      return true
    case actionTypes.getCourse.success:
    case actionTypes.getCourse.error:
      return false
    default:
      return state
  }
}

const loadingSchema = (state = false, action) => {
  switch (action.type) {
    case actionTypes.getCourseSchema.start:
      return true
    case actionTypes.getCourseSchema.success:
    case actionTypes.getCourseSchema.error:
      return false
    default:
      return state
  }
}

const newlyCreated = (state = false, action) => {
  switch (action.type) {
    case actionTypes.createCourse.success:
      return true
    case actionTypes.updateCourseBasicSection.start:
    case actionTypes.updateCourseDatesSection.start:
    case actionTypes.updateCourseStatusSection.start:
    case actionTypes.updateCourseAcademicDetailsSection.start:
    case actionTypes.updateCourseCapacitySection.start:
    case actionTypes.updateCourseSchemaSection.start:
    case actionTypes.editBasicSectionData:
    case actionTypes.editDatesSectionData:
    case actionTypes.editStatusSectionData:
    case actionTypes.editAcademicDetailsSectionData:
    case actionTypes.editCapacitySectionData:
    case actionTypes.editSchemaSectionData:
      return false
    case LOCATION_CHANGE:
      const wentToCourseListPage =
        action.payload.location.pathname === COURSE_LIST_ROUTE
      return wentToCourseListPage ? false : state
    default:
      return state
  }
}

const isShowingPopup = (state = false, action) => {
  switch (action.type) {
    case actionTypes.updateCourseAcademicDetailsSection.success:
    case actionTypes.updateCourseBasicSection.success:
    case actionTypes.updateCourseCapacitySection.success:
    case actionTypes.updateCourseDatesSection.success:
    case actionTypes.updateCourseSchemaSection.success:
    case actionTypes.updateCourseStatusSection.success:
      return true
    case actionTypes.hideSavedChangesPopup:
    case LOCATION_CHANGE:
      return false
    default:
      return state
  }
}

export default combineReducers({
  data,
  schema,
  loaded,
  loadingData,
  loadingSchema,
  newlyCreated,
  isShowingPopup,
  basicSection,
  datesSection,
  statusSection,
  academicDetailsSection,
  capacitySection,
  schemaSection
})

import React from 'react'
import { Trans } from 'react-i18next'
import { Link } from 'react-router-dom'

import { ArrowLeftIcon, Button, PageHead } from '@fullfabric/alma-mater'

import TablePageLayout from 'shared/components/TablePageLayout'

import CourseList from 'apps/Courses/containers/CourseList'
import {
  IMPORT_COURSE_ROUTE,
  NEW_COURSE_ROUTE,
  SOURCE_ROUTE
} from 'apps/Courses/utils/routeConstants'

import styles from './styles.module.scss'

const CourseListPage = () => {
  const pageHead = (
    <PageHead
      noBorder
      className={styles.pageHead}
      title={<Trans>Courses</Trans>}
      action={
        <>
          <Link to={NEW_COURSE_ROUTE}>
            <Button size='small' primary>
              <Trans>Add new course</Trans>
            </Button>
          </Link>
          <a
            href={IMPORT_COURSE_ROUTE}
            target='_top'
            className='marginLeft-small'
          >
            <Button size='small'>
              <Trans>Import from file</Trans>
            </Button>
          </a>
        </>
      }
      breadcrumbs={[
        {
          label: <Trans>Back to Institution</Trans>,
          icon: ArrowLeftIcon,
          href: SOURCE_ROUTE,
          target: '_top'
        }
      ]}
    />
  )

  return (
    <TablePageLayout>
      {pageHead}

      <TablePageLayout.TableSection>
        <CourseList />
      </TablePageLayout.TableSection>
    </TablePageLayout>
  )
}

export default CourseListPage

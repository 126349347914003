export default async function submitPolicyAcceptance({
  authenticationId,
  policyId,
  acceptorId,
  acceptorType = 'Profiles::Profile',
  sourceType,
  acceptanceOptions
}) {
  const data = {
    auth_key: authenticationId
      ? encodeURIComponent(authenticationId)
      : undefined,
    acceptor_type: acceptorType,
    acceptor_id: acceptorId,
    source_type: sourceType,
    policies: {
      [policyId]: {
        accepted: true,
        options: acceptanceOptions
      }
    }
  }

  const result = await fetch('/policies/api/acceptances', {
    credentials: 'same-origin',
    headers: { 'Content-Type': 'application/json' },
    method: 'POST',
    body: JSON.stringify(data)
  })

  return await result.json()
}

export const SOURCES = {
  PORTAL: 'Policies::Source::Portal',
  SIGN_IN: 'Policies::Source::Signin',
  SIGN_UP: 'Policies::Source::Signup'
}

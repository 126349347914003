import React from 'react'
import { Trans } from 'react-i18next'

import { Button, Col, Row, Text } from '@fullfabric/alma-mater'

import { isNumber } from 'apps/Courses/utils/validationHelper'

import styles from './styles.module.scss'

const ValueDisplay = ({ label, value, useTopMargin, ariaLabelledBy }) => (
  <Row className={useTopMargin && 'marginTop-default'}>
    <Col xs={12}>
      <ul aria-labelledby={ariaLabelledBy}>
        <Text tag='li' fontColor='text-base' bold aria-hidden='true'>
          {label}
        </Text>

        {isNumber(value) ? (
          <Text
            tag='li'
            type='f4'
            marginTop='tiny'
            fontColor='text-base-darkest'
          >
            {value}
          </Text>
        ) : (
          <Text tag='li' type='f4' fontColor='text-base' marginTop='tiny'>
            <Trans>Not defined</Trans>
          </Text>
        )}
      </ul>
    </Col>
  </Row>
)

export default function CourseEditDisplayAcademicDetails({
  data,
  onEditClick
}) {
  return (
    <section aria-label='course academic details data read-only'>
      <ValueDisplay
        ariaLabelledBy='credits-label'
        label={
          <span id='credits-label'>
            <Trans>Credits</Trans>
          </span>
        }
        value={data.credits}
      />

      <ValueDisplay
        useTopMargin
        ariaLabelledBy='hours-label'
        label={
          <span id='hours-label'>
            <Trans>Hours</Trans>
          </span>
        }
        value={data.hours}
      />

      <ValueDisplay
        useTopMargin
        ariaLabelledBy='absences-label'
        label={
          <span id='absences-label'>
            <Trans>Max number of absences</Trans>
          </span>
        }
        value={data.max_number_absences}
      />

      <div className='marginTop-default'>
        <Button
          size='small'
          aria-label='edit course academic details'
          onClick={() => onEditClick(data)}
          className={styles.editButton}
        >
          <Trans>Edit</Trans>
        </Button>
      </div>
    </section>
  )
}

import React from 'react'

import { useApplicData } from 'apps/Applics/contexts/StaffSubjectSelection/ApplicData'
import { useStudyPlanData } from 'apps/Applics/contexts/StaffSubjectSelection/StudyPlanData'
import courseTypes from 'apps/Applics/utils/constants/courseTypes'
import { getSelectedPath } from 'apps/Applics/utils/getSelectedPath'
import SelectedCourseGroup from './components/SelectedCourseGroup'

const SelectedCourses = ({ types }) => {
  const studyPlan = useStudyPlanData()
  const applic = useApplicData()

  const selectedPath = getSelectedPath(applic, studyPlan?.paths)
  const courseGroupsToDisplay = (selectedPath?.course_groups || [])
    .filter((group) => types.includes(group.type))
    .sort((a, b) => a.order > b.order)

  const hasMultipleElectiveGroups =
    courseGroupsToDisplay?.filter(
      (group) => group.type === courseTypes.ELECTIVE
    ).length > 0

  return (
    <>
      {courseGroupsToDisplay.map((group) => {
        return (
          <SelectedCourseGroup
            key={group.id}
            courseGroup={group}
            selectedPath={selectedPath}
            hasMultipleElectiveGroups={hasMultipleElectiveGroups}
          />
        )
      })}
    </>
  )
}

export default SelectedCourses

import React from 'react'

import { useImportFieldOptions } from 'apps/Imports/contexts/import-document'
import { useSelectedData } from 'apps/Imports/contexts/selected-data'

export default function useMappingFieldOptions() {
  const { data } = useSelectedData()
  const mappingFieldOptions = useImportFieldOptions()

  const csvHeaderOptions = React.useMemo(
    () =>
      (data.mappings || []).map((m) => ({
        value: m.header,
        label: m.header,
        disabled: !!m.field
      })),
    [data]
  )

  const fieldNamesMap = React.useMemo(
    () =>
      (mappingFieldOptions || [])
        .map((opt) => opt.options)
        .reduce((acc, opts) => [...acc, ...opts], [])
        .reduce((acc, opt) => ({ ...acc, [opt.value]: opt.label }), {}),
    [mappingFieldOptions]
  )

  return { mappingFieldOptions, csvHeaderOptions, fieldNamesMap }
}

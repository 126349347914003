import React, { useState } from 'react'
import { Trans } from 'react-i18next'

import PropTypes from 'prop-types'

import { Button, Text } from '@fullfabric/alma-mater'

import classNames from 'classnames'
import styles from './styles.module.scss'

const icon = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
  >
    <g fill='none' fillRule='evenodd'>
      <path
        fill='#0071A1'
        d='M22.224 0H1.77C.793 0 0 .775 0 1.73v20.538C0 23.224.793 24 1.77 24h20.454c.979 0 1.775-.776 1.775-1.732V1.731C24 .775 23.203 0 22.224 0'
      />
      <path
        fill='#FFFFFE'
        d='M5.339 3.304a2.064 2.064 0 1 1-.003 4.128 2.064 2.064 0 0 1 .003-4.128zM3.556 20.451h3.563V8.997H3.556v11.454zM9.352 8.997h3.412v1.566h.05c.474-.9 1.635-1.85 3.367-1.85 3.604 0 4.27 2.372 4.27 5.456v6.282h-3.559v-5.57c0-1.328-.023-3.037-1.85-3.037-1.852 0-2.135 1.447-2.135 2.942v5.665H9.352V8.997'
      />
    </g>
  </svg>
)

const iconHover = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
  >
    <g fill='none' fillRule='evenodd'>
      <path
        fill='#FFF'
        d='M22.224 0H1.77C.793 0 0 .775 0 1.73v20.538C0 23.224.793 24 1.77 24h20.454c.979 0 1.775-.776 1.775-1.732V1.731C24 .775 23.203 0 22.224 0'
      />
      <path
        fill='#0077B5'
        d='M5.339 3.304a2.064 2.064 0 1 1-.003 4.128 2.064 2.064 0 0 1 .003-4.128zM3.556 20.451h3.563V8.997H3.556v11.454zM9.352 8.997h3.412v1.566h.05c.474-.9 1.635-1.85 3.367-1.85 3.604 0 4.27 2.372 4.27 5.456v6.282h-3.559v-5.57c0-1.328-.023-3.037-1.85-3.037-1.852 0-2.135 1.447-2.135 2.942v5.665H9.352V8.997'
      />
    </g>
  </svg>
)

const LinkedinButton = ({ className, ...props }) => {
  const [hover, setHover] = useState(false)
  return (
    <Button
      className={classNames(
        styles.thirdPartyLoginButton,
        styles['linkedin-button'],
        className
      )}
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      {...props}
    >
      <div className={styles.buttonContent}>
        <div className={styles.iconAndText}>
          {hover ? iconHover : icon}
          <Text type='h5' tag='span'>
            <Trans>LinkedIn</Trans>
          </Text>
        </div>
        <svg
          width='12'
          height='12'
          viewBox='0 0 12 12'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M12 5.98163C11.9991 5.63731 11.8508 5.30981 11.5926 5.08174L5.99725 0.162862C5.69683 -0.0827796 5.25559 -0.0465989 4.9993 0.244692C4.74301 0.535982 4.76377 0.977699 5.04626 1.24373L9.36926 5.04325C9.40807 5.07764 9.4216 5.1324 9.40328 5.18088C9.38495 5.22937 9.33856 5.26153 9.28667 5.26172H0.720751C0.322691 5.26172 0 5.58403 0 5.98163C0 6.37922 0.322691 6.70154 0.720751 6.70154H9.28517C9.33703 6.70204 9.38325 6.73431 9.40153 6.78278C9.41981 6.83125 9.40639 6.88596 9.36776 6.92051L5.04475 10.72C4.83885 10.8862 4.74066 11.1516 4.78901 11.4116C4.83736 11.6715 5.02445 11.8841 5.27636 11.9652C5.52826 12.0464 5.80444 11.9831 5.99575 11.8004L11.5921 6.88152C11.8506 6.65361 11.9991 6.32605 12 5.98163Z'
            fill='#C0CCDA'
          />
        </svg>
      </div>
    </Button>
  )
}

LinkedinButton.propTypes = {
  className: PropTypes.string
}

export default LinkedinButton

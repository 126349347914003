import React from 'react'

import LoadingPlaceholderBlock from 'apps/ContentPages/components/loading-placeholder-block'
import useIsMobileLayout from 'apps/ContentPages/components/payments-page/use-is-payments-mobile-layout'

import classNames from 'classnames'
import styles from './styles.module.scss'

const PaymentsPageLayout = {}

const Container = ({ children }) => {
  const isMobileLayout = useIsMobileLayout()
  const isMobileNavigation = isMobileLayout
  return (
    <div
      className={isMobileNavigation ? styles.viewportMobile : styles.viewport}
    >
      <div
        className={isMobileLayout ? styles.containerMobile : styles.container}
      >
        {children}
      </div>
    </div>
  )
}

const ContentGrid = ({ children }) => {
  const isMobileLayout = useIsMobileLayout()

  return (
    <div className={isMobileLayout ? styles.mobileGrid : styles.grid}>
      {children}
    </div>
  )
}

const Placeholder = ({ className }) => (
  <LoadingPlaceholderBlock
    className={classNames(styles.placeholder, className)}
  />
)

const MainContentArea = ({ label, children }) => (
  <div className={styles.feeListContainer} role='region' aria-label={label}>
    {children}
  </div>
)

const WidgetArea = ({ label, children }) => {
  return (
    <div
      role='region'
      aria-label={label}
      className={styles.warningWidgetContainer}
    >
      {children}
    </div>
  )
}

PaymentsPageLayout.Container = Container
PaymentsPageLayout.ContentGrid = ContentGrid
PaymentsPageLayout.Placeholder = Placeholder
PaymentsPageLayout.MainContentArea = MainContentArea
PaymentsPageLayout.WidgetArea = WidgetArea

export default PaymentsPageLayout

import {
  validate,
  validateCredits,
  validateHours,
  validateMaximumCapacity,
  validateMaxNumberAbsences,
  validateMinimumCapacity,
  validatePriceAmount,
  validatePriceCurrency,
  validateTargetCapacity
} from '../validationHelper'

const validateUpdate = (data) => {
  const validators = [
    validatePriceAmount,
    validatePriceCurrency,
    validateMinimumCapacity,
    validateMaximumCapacity,
    validateTargetCapacity,
    validateCredits,
    validateHours,
    validateMaxNumberAbsences
  ]
  validate(data, validators)
}

export default validateUpdate

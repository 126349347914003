import React from 'react'
import { Trans } from 'react-i18next'

import {
  ArrowLeftIcon,
  Button,
  IconButton,
  Link,
  PageHead,
  SettingsIcon
} from '@fullfabric/alma-mater'

import {
  ADD_SUBJECT_ROUTE,
  SETTINGS_ROUTE
} from 'apps/StudyPlans/components/AppRoutes/constants'
import { useClassOf } from 'apps/StudyPlans/contexts/StudyPlanData'

import styles from './styles.module.scss'

const PageHeader = () => {
  const classOf = useClassOf()

  return (
    <PageHead
      title={<Trans>Study plan</Trans>}
      action={
        <React.Fragment>
          <Link
            withoutStyles
            to={ADD_SUBJECT_ROUTE.replace(':classId', classOf.id)}
          >
            <Button primary marginRight='small'>
              <Trans>Add study plan subject</Trans>
            </Button>
          </Link>
          <Link
            withoutStyles
            to={SETTINGS_ROUTE.replace(':classId', classOf.id)}
            data-testid='settings-link'
          >
            <IconButton className={styles.settingsButton}>
              <SettingsIcon />
            </IconButton>
          </Link>
        </React.Fragment>
      }
      breadcrumbs={[
        {
          label: classOf.name_with_programme,
          icon: ArrowLeftIcon,
          href: `/classes/${classOf.id}`,
          target: '_top'
        }
      ]}
    />
  )
}

export default PageHeader

.react-app ._container_1lw4c_1 {
  padding: var(--space-more);
  border-bottom: var(--border-base-lighter);
}

.react-app ._singleSection_1lw4c_6 {
  padding-top: 0;
  padding-bottom: 0;
  border-bottom: none;
}

.react-app ._first_1lw4c_12 {
  padding-top: 0;
}

.react-app ._last_1lw4c_16 {
  padding-bottom: 0;
  border-bottom: none;
}
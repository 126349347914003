import React from 'react'
import { Trans } from 'react-i18next'

import {
  ActionList,
  Button,
  PointerDownIcon,
  Row,
  Text
} from '@fullfabric/alma-mater'

import { TIMEFRAME_INTERVAL } from '../constants'

const ChartTitle = ({
  isWeeklyTimeframeSelected,
  onChangeTimeframeInterval
}) => {
  return (
    <Row nogutter justify='between' align='center'>
      <Text type='h4' fontColor='text-base-darkest' marginBottom='tiny'>
        <Trans>Intakes overview</Trans>
      </Text>
      <ActionList
        button={
          <Button size='small'>
            {isWeeklyTimeframeSelected ? (
              <Trans>Weeks</Trans>
            ) : (
              <Trans>Months</Trans>
            )}{' '}
            <PointerDownIcon color='text-base-darker' />
          </Button>
        }
      >
        <ActionList.Option
          onClick={() => onChangeTimeframeInterval(TIMEFRAME_INTERVAL.WEEKLY)}
        >
          Weeks
        </ActionList.Option>
        <ActionList.Option
          onClick={() => onChangeTimeframeInterval(TIMEFRAME_INTERVAL.MONTHLY)}
        >
          Months
        </ActionList.Option>
      </ActionList>
    </Row>
  )
}

export default ChartTitle

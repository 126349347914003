import React from 'react'
import { Trans } from 'react-i18next'

import { Link, Text } from '@fullfabric/alma-mater'

import { ReactComponent as CsvIconCheck } from './icons/csv-check.svg'
import { ReactComponent as CsvIcon } from './icons/csv.svg'

import styles from './styles.module.scss'

const FileAcceptedContent = ({ fileName, onOpenFileDialog }) => (
  <div className={styles.fileUploadContent}>
    <div className={styles.acceptedFileIcons}>
      <CsvIconCheck className={styles.acceptedFileCheck} />
      <CsvIcon />
    </div>
    <Text marginBottom='default' marginTop='tiny'>
      {fileName}
    </Text>
    <Link onClick={onOpenFileDialog}>
      <Trans>Replace file</Trans>
    </Link>
  </div>
)

export default FileAcceptedContent

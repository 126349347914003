.react-app ._journeyCard_oovom_1 {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.react-app ._journeyCard_oovom_1._journeyAdded_oovom_6 {
  border-left: 4px solid var(--ff-color-green-300);
}
.react-app ._journeyCard_oovom_1._journeyDiscarded_oovom_9 p:not(._summaryType_oovom_9) {
  text-decoration-line: line-through;
  color: var(--ff-color-text-base-darker) !important;
}
.react-app ._journeyCard_oovom_1 ._cardInfoRegion_oovom_13 {
  flex: 1 0 auto;
}
.react-app ._journeyCard_oovom_1 ._cardNotice_oovom_16 {
  display: flex;
  align-items: center;
}
.react-app ._journeyCard_oovom_1 ._cardNotice_oovom_16 svg {
  margin-right: var(--space-tiny);
}
.react-app ._journeyCard_oovom_1 ._associatedContextNoticeContainer_oovom_23 {
  padding: var(--space-small);
  text-align: center;
}
.react-app ._journeyCard_oovom_1 ._associatedContextNoticeContainer_oovom_23._discarded_oovom_27 {
  background: var(--ff-color-yellow-000);
  border-bottom: 1px solid var(--ff-color-yellow-100);
}
.react-app ._journeyCard_oovom_1 ._associatedContextNoticeContainer_oovom_23._added_oovom_31 {
  background: var(--ff-bg-success-lightest);
  border-bottom: 1px solid var(--ff-color-green-100);
}

.react-app ._cardButtons_oovom_36 {
  border-top: var(--border-base-lighter);
  margin-top: var(--space-default);
  padding: var(--space-default) var(--space-more);
}
.react-app ._cardButtons_oovom_36 ._cardButton_oovom_36 ._buttonIcon_oovom_41 {
  margin-right: var(--space-small);
  vertical-align: -1px;
}
.react-app ._cardButtons_oovom_36 ._added_oovom_31,
.react-app ._cardButtons_oovom_36 ._added_oovom_31:hover {
  background: var(--ff-bg-success-lightest) !important;
  border: 1px solid var(--ff-bg-success-lightest) !important;
  box-shadow: none;
  color: var(--ff-color-text-success) !important;
}
.react-app ._cardButtons_oovom_36 ._discarded_oovom_27,
.react-app ._cardButtons_oovom_36 ._discarded_oovom_27:hover {
  background: var(--ff-bg-alert-lightest) !important;
  border: 1px solid var(--ff-bg-alert-lightest) !important;
  box-shadow: none;
  color: var(--ff-color-text-alert) !important;
}
.react-app ._cardButtons_oovom_36 button:last-child {
  margin-left: var(--space-small);
}
import React, { useEffect, useState } from 'react'
import { useController, useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'

import {
  Button,
  Field,
  Modal,
  ModalButtons,
  ModalTitle,
  Text
} from '@fullfabric/alma-mater'

import createStaff from 'shared/api/staff/createStaff'
import getStaff from 'shared/api/staff/getStaff'
import { usePagedQueriesUpdater } from 'shared/hooks/usePagedQueriesUpdater'

export default function CreateUserButton() {
  const { t } = useTranslation()
  const [isCreationModalOpen, setCreationModalOpen] = useState(false)

  const {
    register,
    handleSubmit,
    control,
    formState,
    reset: resetForm,
    setFocus
  } = useForm()

  const { ref: firstNameInputRef, ...firstNameInputProps } = register(
    'first_name',
    { required: t("Can't be blank") }
  )

  const { ref: lastNameInputRef, ...lastNameInputProps } = register(
    'last_name',
    { required: t("Can't be blank") }
  )

  const { ref: emailInputRef, ...emailInputProps } = register('email', {
    required: t("Can't be blank")
  })

  const {
    field: {
      ref: adminInputRef,
      onChange: handleAdminInputChanged,
      ...adminInputProps
    }
  } = useController({ name: 'is_admin', control, defaultValue: false })

  useEffect(() => {
    isCreationModalOpen && setFocus('name')
  }, [isCreationModalOpen, setFocus])

  const closeModal = () => {
    setCreationModalOpen(false)
    resetForm()
  }

  const createUserMutation = useCreateUserMutation(closeModal)
  const onSubmit = (formValues) => createUserMutation.mutate({ formValues })

  const firstNameError =
    formState.errors.first_name?.message ||
    createUserMutation.error?.data?.['profiles/profile']?.first_name

  const lastNameError =
    formState.errors.last_name?.message ||
    createUserMutation.error?.data?.['profiles/profile']?.last_name

  const emailError =
    formState.errors.email?.message ||
    createUserMutation.error?.data?.['profiles/profile']?.email

  return (
    <>
      <Button primary size='small' onClick={() => setCreationModalOpen(true)}>
        <Trans>Create a new staff member</Trans>
      </Button>

      {isCreationModalOpen && (
        <Modal
          id='create-staff-member-modal'
          role='dialog'
          aria-labelledby='create-staff-member-modal-title'
          isOpen={isCreationModalOpen}
          size='small'
          header={
            <ModalTitle
              title={
                <span id='create-staff-member-modal-title'>
                  <Trans>Create a new staff member</Trans>
                </span>
              }
              onClose={closeModal}
            />
          }
          footer={
            <ModalButtons>
              <Button
                onClick={closeModal}
                disabled={createUserMutation.isLoading}
              >
                <Trans>Cancel</Trans>
              </Button>

              <Button
                primary
                type='submit'
                form='create-staff-member-modal-form'
                onClick={handleSubmit(onSubmit)}
                loading={createUserMutation.isLoading}
              >
                <Trans>Create staff member</Trans>
              </Button>
            </ModalButtons>
          }
        >
          <form id='create-staff-member-modal-form'>
            <Field
              {...firstNameInputProps}
              type='text'
              label={<Trans>First name</Trans>}
              error={firstNameError}
              inputRef={firstNameInputRef}
            />

            <Field
              {...lastNameInputProps}
              type='text'
              label={<Trans>Last name</Trans>}
              error={lastNameError}
              inputRef={lastNameInputRef}
            />

            <Field
              {...emailInputProps}
              type='text'
              label={<Trans>Email</Trans>}
              error={emailError}
              inputRef={emailInputRef}
            />

            <Field
              {...adminInputProps}
              type='dropdown'
              label={<Trans>Admin?</Trans>}
              inputOptions={{
                inModal: true,
                isClearable: false,
                options: [
                  {
                    label: <Trans>Yes</Trans>,
                    value: true
                  },
                  {
                    label: <Trans>No</Trans>,
                    value: false
                  }
                ]
              }}
              onChange={(_name, value) => handleAdminInputChanged(value)}
            />
          </form>

          {createUserMutation.isError && (
            <UserCreationError error={createUserMutation.error} />
          )}
        </Modal>
      )}
    </>
  )
}

function useCreateUserMutation(onSuccessCb) {
  const updatePages = usePagedQueriesUpdater([
    'tableDataFetch',
    undefined,
    getStaff
  ])

  return useMutation(({ formValues }) => createStaff(formValues), {
    onSuccess: (user) => {
      updatePages((page, idx) => idx === 0 && page.data.unshift(user))
      onSuccessCb(user)
    }
  })
}

function UserCreationError({ error }) {
  // These will be displayed on the fields directly:
  if (error.status === 406 && error.data?.['profiles/profile']) return null

  return (
    <Text fontColor='text-alert' type='f5' marginTop='default'>
      <Trans>
        There was an error, please refresh the page and try again. If the issue
        persists, please contact us.
      </Trans>
    </Text>
  )
}

/* eslint-disable no-case-declarations */

import { LOCATION_CHANGE } from 'connected-react-router'
import { combineReducers } from 'redux'

import actionTypes from '../../../actions/types'

const data = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.editAcademicDetailsSectionData:
      return action.payload
    case actionTypes.cancelEditAcademicDetailsSectionData:
    case LOCATION_CHANGE:
      return {}
    case actionTypes.editCourseAcademicDetailsSectionDataChanged:
      return { ...state, ...action.payload }
    default:
      return state
  }
}

const isEditing = (state = false, action) => {
  switch (action.type) {
    case actionTypes.editAcademicDetailsSectionData:
      return true
    case actionTypes.cancelEditAcademicDetailsSectionData:
    case actionTypes.updateCourseAcademicDetailsSection.success:
    case LOCATION_CHANGE:
      return false
    default:
      return state
  }
}

const inProgress = (state = false, action) => {
  switch (action.type) {
    case actionTypes.updateCourseAcademicDetailsSection.start:
      return true
    case actionTypes.updateCourseAcademicDetailsSection.success:
    case actionTypes.updateCourseAcademicDetailsSection.error:
      return false
    default:
      return state
  }
}

const errors = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.updateCourseAcademicDetailsSection.error:
      if (action.payload && action.payload.validationErrors)
        return { ...state, ...action.payload.validationErrors }
      else return { ...state, ...action.payload }
    case actionTypes.editCourseAcademicDetailsSectionDataChanged:
      const key = Object.keys(action.payload)[0]
      const { [key]: keyToRemove, ...newState } = state
      return newState
    case LOCATION_CHANGE:
    case actionTypes.updateCourseAcademicDetailsSection.success:
    case actionTypes.cancelEditAcademicDetailsSectionData:
      return {}
    default:
      return state
  }
}

export default combineReducers({
  data,
  isEditing,
  inProgress,
  errors
})

/* eslint-disable */

import React, { useState } from 'react'
import { Trans } from 'react-i18next'

import {
  Alert,
  Button,
  Field,
  Modal,
  ModalButtons,
  PlainButton,
  Text
} from '@fullfabric/alma-mater'

import { useAppData } from 'shared/contexts/AppData'

import startApplicationOnBehalf from 'apps/Authentication/api/startApplicationOnBehalf'

import styles from '../styles.module.scss'

const initErrorState = {
  email: null,
  first_name: null,
  last_name: null
}

const ApplicantDetailsModal = ({ back }) => {
  const {
    logo_url,
    authenticated_profile_id,
    options,
    template_id,
    template_type
  } = useAppData()

  const isRegistration = template_type === 'registration'

  const [loading, setLoading] = useState(false)

  const userFormState = {
    email: '',
    first_name: '',
    last_name: ''
  }

  const [formState, setFormState] = useState(userFormState)
  const [formErrors, setFormErrors] = useState(initErrorState)

  const saveFormProgress = (name, value) => {
    clearErrors()
    setFormState({
      ...formState,
      [name]: value
    })
  }

  const clearErrors = () => {
    setFormErrors(initErrorState)
  }

  const setErrors = (apiError) => {
    setLoading(false)
    setFormErrors({
      ...formErrors,
      ...(apiError?.data?.errors || {})
    })
  }

  const submitApplication = () => {
    let params = {}
    if (options.context) {
      params.context = options.context
    }
    params = {
      ...params,
      profile_params: formState,
      on_behalf_id: authenticated_profile_id,
      ignore_state: true
    }

    setLoading(true)
    return startApplicationOnBehalf(template_id, params)
      .then((applic) => {
        const url = `/applications/${applic.id}`
        window.location.replace(url)
      })
      .catch(setErrors)
  }

  const showEmailError = () => {
    switch (formErrors.email) {
      case 'invalid':
        return <Trans>Email address is invalid</Trans>
      case 'taken':
        return <Trans>Someone already registered with that email address</Trans>
      default:
        return <Trans>Email address can't be blank</Trans>
    }
  }

  const hasFormErrorsNotApplic = () => {
    const { email, first_name, last_name } = formErrors

    if (formErrors.applic) return false
    if (email || first_name || last_name) return true
    return false
  }

  return (
    <Modal
      size='medium'
      isOpen={true}
      className={styles.modal}
      footer={
        <ModalButtons>
          <PlainButton onClick={back}>
            <Trans>Back</Trans>
          </PlainButton>
          <Button primary onClick={submitApplication} loading={loading}>
            <Trans>Continue</Trans>
          </Button>
        </ModalButtons>
      }
    >
      <img alt='Logo' src={logo_url} />

      <Text type='h2' fontColor='text-base-darkest' marginBottom='more'>
        <Trans>Participant details</Trans>
      </Text>

      <Text type='f4' fontColor='text-base-darkest' marginBottom='small'>
        <Trans>Please enter the details of the participant</Trans>
      </Text>

      {formErrors.applic && (
        <Alert
          className={styles.alert}
          error
          title={
            isRegistration ? (
              <Trans>Registration already exists</Trans>
            ) : (
              <Trans>Application already exists</Trans>
            )
          }
        />
      )}

      {hasFormErrorsNotApplic() && (
        <Alert
          className={styles.alert}
          error
          title={<Trans>There are fields with errors</Trans>}
          description={<Trans>Please correct the fields with errors</Trans>}
        />
      )}

      <Field
        type='email'
        id='email'
        required
        label={<Trans>Email</Trans>}
        value={formState.email}
        onChange={saveFormProgress}
        error={formErrors.email ? showEmailError() : null}
      />

      <Field
        type='text'
        id='first_name'
        required
        label={<Trans>First name</Trans>}
        value={formState.first_name}
        onChange={saveFormProgress}
        error={
          formErrors.first_name ? (
            <Trans>First name can't be blank</Trans>
          ) : null
        }
      />

      <Field
        type='text'
        id='last_name'
        required
        label={<Trans>Last name</Trans>}
        value={formState.last_name}
        onChange={saveFormProgress}
        error={
          formErrors.last_name ? <Trans>Last name can't be blank</Trans> : null
        }
      />
    </Modal>
  )
}

export default ApplicantDetailsModal

import React from 'react'

import PropTypes from 'prop-types'

import { Text } from '@fullfabric/alma-mater'

import classNames from 'classnames'
import styles from './styles.module.scss'

const FormTitle = ({ title, subtitle, className }) => {
  return (
    <div className={classNames(styles.formTitle, className)}>
      <Text
        tag='h1'
        fontColor='text-base-darkest'
        type='h2'
        className={styles.title}
      >
        {title}
      </Text>
      {subtitle ? (
        <Text
          tag='h4'
          type='f5'
          fontColor='text-base-darker'
          className={styles.subtitle}
        >
          {subtitle}
        </Text>
      ) : null}
    </div>
  )
}

FormTitle.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  className: PropTypes.string
}

export default FormTitle

import React from 'react'

import getApplicationContextId from 'apps/ContentPages/utils/get-application-context-id'
import isContextSelectionRequired from 'apps/ContentPages/utils/is-context-selection-required'
import SubmittedApplication from './submitted-application'

import styles from './styles.module.scss'

const SubmittedApplications = ({
  template,
  submittedApplications,
  contexts = []
}) => {
  if (!submittedApplications || !submittedApplications.length) {
    return null
  }

  const contextsById = contexts.reduce(
    (hashed, context) => ({ ...hashed, [context.context_id]: context }),
    {}
  )

  const applicationsContext = submittedApplications.map((application) => {
    return {
      contextId: getApplicationContextId(application),
      applicationId: application.id
    }
  })

  return (
    <div className={styles.groupContainer}>
      {applicationsContext.map(({ applicationId, contextId }, index) => {
        const applicationContext = contextId && contextsById[contextId]

        return (
          <SubmittedApplication
            key={applicationId}
            withChosenContext={isContextSelectionRequired(template)}
            applicationContext={applicationContext}
            onViewApplication={() => {
              window.location.href = `/applications/${applicationId}`
            }}
            last={index === submittedApplications.length - 1}
          />
        )
      })}
    </div>
  )
}

export default SubmittedApplications

import { utils } from '@fullfabric/public-api'

const getStudyPlan = async (key, id) => {
  const response = await fetch(`/affairs/api/study_plan/${id}`, {
    credentials: 'same-origin'
  })

  return await utils.checkResponse(response)
}

export default getStudyPlan

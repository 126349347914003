import React from 'react'
import { Trans } from 'react-i18next'
import { useQuery } from 'react-query'

import { Field } from '@fullfabric/alma-mater'

import getStaffOptions from 'shared/api/getStaffOptions'

export default function OwnerField({ value, onChange }) {
  const { data: staffList, isLoading } = useQuery(
    'staffOptions',
    getStaffOptions
  )

  return (
    <Field
      id='owner_id'
      type='autocomplete'
      label={<Trans>Owner</Trans>}
      onChange={onChange}
      value={value}
      inputOptions={{
        placeholder: <Trans>- Select -</Trans>,
        isLoading,
        options: staffList || []
      }}
    />
  )
}

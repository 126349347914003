import React from 'react'
import { useTranslation } from 'react-i18next'

import { ArrowLeftIcon, PageHead } from '@fullfabric/alma-mater'

const Head = () => {
  const { t } = useTranslation()

  return (
    <PageHead
      noBorder
      breadcrumbs={[
        {
          label: t('Back to list of reports'),
          icon: ArrowLeftIcon,
          to: '/reports'
        }
      ]}
      title={t('Product overview')}
    />
  )
}

export default Head

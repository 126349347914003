import React from 'react'

const YAxisTick = ({
  stroke,
  payload,
  verticalAnchor,
  visibleTicksCount,
  ...otherProps
}) => {
  const formattedValue =
    payload.value > 1000 ? `${payload.value / 1000}k` : payload.value

  return (
    <text
      {...otherProps}
      fill={'var(--ff-color-text-base-darker)'}
      fontSize={12}
    >
      {formattedValue}
    </text>
  )
}

export default YAxisTick

import React from 'react'

import { OneColumnLayout } from '@fullfabric/alma-mater'

import ProductPerformanceClassDetailRow from 'apps/Reports/components/ProductPerformanceClassDetailRow'
import ProductPerformanceTable from 'apps/Reports/components/ProductPerformanceTable'
import { CAPACITY_TARGETS_CHART } from 'apps/Reports/constants/productPerformanceCharts'
import Head from './Head'

import styles from './styles.module.scss'

const FixedClassProductPerformancePage = ({ classId }) => {
  const [chartType, setChartType] = React.useState(CAPACITY_TARGETS_CHART)

  return (
    <OneColumnLayout
      pageHead={<Head classId={classId} />}
      className={styles.page}
    >
      <ProductPerformanceClassDetailRow
        classId={classId}
        chartType={chartType}
      />
      <ProductPerformanceTable
        baseClassId={classId}
        chartType={chartType}
        onChartTypeChange={setChartType}
      />
    </OneColumnLayout>
  )
}

export default FixedClassProductPerformancePage

import React from 'react'
import { Trans } from 'react-i18next'

import { StepsContext } from 'apps/Imports/contexts/steps'
import { IMPORT_TYPES } from 'apps/Imports/utils/constants'
import { PRODUCT_IMPORT_STEPS } from 'apps/Imports/utils/product-import/constants'

export const ProductImportStepsProvider = ({
  children,
  importType = IMPORT_TYPES.PROGRAMME_AND_CLASS
}) => {
  const [stepsCompleted, setStepsCompleted] = React.useState([])
  const [currentStep, setCurrentStep] = React.useState(
    PRODUCT_IMPORT_STEPS.FILE_UPLOAD
  )

  const importSteps = [
    {
      id: PRODUCT_IMPORT_STEPS.FILE_UPLOAD,
      label: <Trans>File upload</Trans>,
      active: currentStep === PRODUCT_IMPORT_STEPS.FILE_UPLOAD,
      completed:
        stepsCompleted.includes(PRODUCT_IMPORT_STEPS.FILE_UPLOAD) &&
        currentStep !== PRODUCT_IMPORT_STEPS.FILE_UPLOAD
    },
    {
      id: PRODUCT_IMPORT_STEPS.MAPPING,
      label: <Trans>Mapping</Trans>,
      active: currentStep === PRODUCT_IMPORT_STEPS.MAPPING,
      completed:
        stepsCompleted.includes(PRODUCT_IMPORT_STEPS.MAPPING) &&
        currentStep !== PRODUCT_IMPORT_STEPS.MAPPING
    },
    {
      id: PRODUCT_IMPORT_STEPS.SUMMARY,
      label: <Trans>Summary</Trans>,
      active: currentStep === PRODUCT_IMPORT_STEPS.SUMMARY,
      completed:
        stepsCompleted.includes(PRODUCT_IMPORT_STEPS.SUMMARY) &&
        currentStep !== PRODUCT_IMPORT_STEPS.SUMMARY
    },
    {
      id: PRODUCT_IMPORT_STEPS.REPORT,
      label: <Trans>Report</Trans>,
      active: currentStep === PRODUCT_IMPORT_STEPS.REPORT,
      completed: false
    }
  ].filter((x) => x)

  const currentStepIndex = importSteps.findIndex(
    (step) => step.id === currentStep
  )
  const previousStep =
    currentStepIndex > 0 ? importSteps[currentStepIndex - 1]?.id : null

  const nextStep =
    currentStepIndex !== importSteps.length - 1
      ? importSteps[currentStepIndex + 1]?.id
      : null

  return (
    <StepsContext.Provider
      value={{
        stepsCompleted,
        setStepsCompleted,
        currentStep,
        previousStep,
        nextStep,
        setCurrentStep,
        importSteps,
        importType
      }}
    >
      {children}
    </StepsContext.Provider>
  )
}

export default ProductImportStepsProvider

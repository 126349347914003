import React from 'react'
import { useQuery } from 'react-query'

import { useCurrentUser } from '@fullfabric/authorization-officer'

import api from 'apps/ContentPages/api'
import { PaymentsDrawerStateProvider } from '../../contexts/payments-drawer'
import PaymentsDrawer from '../payments-drawer'
import AlertWidgetArea from './alert-widget-area'
import Header from './header'
import PaymentsPageLayout from './layout'
import PaymentPlanListArea from './payment-plan-list-area'

const PaymentsPage = ({ applic }) => {
  const user = useCurrentUser()

  const { data: paymentPlans, status } = useQuery(
    ['get-payment-plans', user?.id],
    () => api.getPaymentPlans(user?.id),
    { enabled: !!user?.id }
  )

  return (
    <PaymentsPageLayout.Container>
      <PaymentsDrawerStateProvider>
        <Header />
        <PaymentsPageLayout.ContentGrid>
          <PaymentPlanListArea paymentPlans={paymentPlans} />
          <AlertWidgetArea
            paymentPlans={paymentPlans}
            isLoading={status === 'loading'}
          />
        </PaymentsPageLayout.ContentGrid>
        <PaymentsDrawer />
      </PaymentsDrawerStateProvider>
    </PaymentsPageLayout.Container>
  )
}

export default PaymentsPage

.react-app ._alertCell_huozy_1 {
  background-color: var(--ff-bg-base-lighter);
  text-align: center;
  vertical-align: middle;
  padding: var(--space-more);
}

.react-app ._centeredCellContent_huozy_8 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.react-app ._tableTitleCell_huozy_14 {
  background-color: var(--ff-bg-base-lighter);
}

.react-app ._tableDataRow_huozy_18 {
  background-color: var(--ff-bg-base-lighter);
}
import React from 'react'

import { useElements, useStripe } from '@stripe/react-stripe-js'

const useStripePaymentSubmission = ({ onError, onPaymentSuccess }) => {
  const stripe = useStripe()
  const elements = useElements()
  const [isPaymentProcessing, setIsPaymentProcessing] = React.useState(false)

  const handleSubmit = React.useCallback(
    async (evt) => {
      // We don't want to let default form submission happen here,
      // which would refresh the page.
      evt.preventDefault()

      setIsPaymentProcessing(true)

      if (!stripe || !elements) {
        // Stripe.js has not yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return
      }

      const result = await stripe.confirmPayment({
        // `Elements` instance that was used to create the Payment Element
        elements,
        confirmParams: {
          return_url: window.location.href // 'https://example.com/order/123/complete',
        },
        redirect: 'if_required'
      })

      setIsPaymentProcessing(false)

      if (result.error) {
        // Show error to your customer (for example, payment details incomplete)
        console.log(result.error)
        onError && onError(result.error)
      } else {
        // Your customer will be redirected to your `return_url`. For some payment
        // methods like iDEAL, your customer will be redirected to an intermediate
        // site first to authorize the payment, then redirected to the `return_url`.
        onPaymentSuccess && onPaymentSuccess(result)
      }
    },
    [stripe, elements, onPaymentSuccess, onError]
  )

  return { handleSubmit, canSubmit: !!stripe, isPaymentProcessing }
}

export default useStripePaymentSubmission

import React, { useState } from 'react'
import { Trans } from 'react-i18next'

import { Button, InfoIcon, Text } from '@fullfabric/alma-mater'

import api from 'apps/ContentPages/api'

import styles from './styles.module.scss'

export default function ExpiredCard({ externalPayerRequest }) {
  const [isRenewing, setIsRenewing] = useState(false)
  const [renewed, setRenewed] = useState(false)

  const handleSendNewLink = async () => {
    try {
      setIsRenewing(true)
      await api.renewExternalPayerRequest({
        externalPayerRequestId: externalPayerRequest.id
      })
      setRenewed(true)
    } catch (error) {
      setIsRenewing(false)
      console.error('error', error)
    }
  }

  return (
    <div className={styles.noticeContainer}>
      <InfoIcon width={24} height={24} />

      {renewed ? (
        <>
          <Text
            type='h3'
            fontColor='text-base-darkest'
            className={'marginTop-default'}
          >
            <Trans>Check your inbox</Trans>
          </Text>
          <Text type='f4' fontColor='text-base-darkest'>
            <Trans>A new link has been sent.</Trans>
          </Text>
        </>
      ) : (
        <>
          <Text
            type='h3'
            fontColor='text-base-darkest'
            className={'marginTop-default'}
          >
            <Trans>Access expired</Trans>
          </Text>
          <Text type='f4' fontColor='text-base-darkest'>
            <Trans>
              Click the button below to receive a new link in your inbox.
            </Trans>
          </Text>
          <Button
            primary
            size='medium'
            onClick={handleSendNewLink}
            className={'marginTop-more'}
            loading={isRenewing}
          >
            <Trans>Send new link</Trans>
          </Button>
        </>
      )}
    </div>
  )
}

import React from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { useLocation, useParams } from 'react-router-dom'

import moment from 'moment'

import {
  ArrowLeftIcon,
  Button,
  CalendarIcon,
  Link,
  PageHead,
  UserIcon
} from '@fullfabric/alma-mater'

import TablePageLayout from 'shared/components/TablePageLayout'
import { useAppData } from 'shared/contexts/AppData'

import getProfile from 'apps/Applics/api/getProfile'
import { SHARED_EMAIL_TEMPLATES_ROUTE } from 'apps/EmailTemplates/constants'
import { UsageTable } from './UsageTable'

import styles from './styles.module.scss'

export default function EmailTemplateUsage() {
  const [t] = useTranslation()
  const { id } = useParams()
  const { state } = useLocation()

  const { email_template: emailTemplate } = useAppData()

  const { data: profile } = useQuery({
    queyKey: ['profile', emailTemplate.created_by_id],
    queryFn: () => getProfile(undefined, emailTemplate.created_by_id),
    enabled: Boolean(emailTemplate.created_by_id),
    suspense: true
  })

  return (
    <TablePageLayout>
      <PageHead
        noBorder
        breadcrumbs={[
          {
            label: 'Back',
            icon: ArrowLeftIcon,
            to: {
              target: '_parent',
              pathname: state?.backTo
                ? state.backTo
                : SHARED_EMAIL_TEMPLATES_ROUTE
            }
          }
        ]}
        title={t('Usage')}
        prefix={emailTemplate.name}
        secondaryInfo={
          <div className={styles.secondaryInfo}>
            <PageHead.SecondaryInfo icon={<UserIcon />}>
              {profile.name}
            </PageHead.SecondaryInfo>
            <PageHead.SecondaryInfo icon={<CalendarIcon />}>
              {moment(emailTemplate.created_at).format('dddd, MMMM D, YYYY')}
            </PageHead.SecondaryInfo>
          </div>
        }
        action={
          <Link
            href={
              emailTemplate.editor === 'classic'
                ? `/email_templates#${emailTemplate.id}/editor`
                : `/shared_email_templates/${emailTemplate.id}`
            }
            target='_parent'
          >
            <Button size='small'>{t('Edit email')}</Button>
          </Link>
        }
      />
      <UsageTable id={id} />
    </TablePageLayout>
  )
}

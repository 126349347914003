import React from 'react'
import { Trans } from 'react-i18next'

import moment from 'moment'

import SimpleSummarySection from 'apps/Imports/components/summary-simple-section'
import { useSelectedData } from 'apps/Imports/contexts/selected-data'
import { useCommunicationPolicies } from 'apps/Imports/hooks/useCommunicationPolicies'
import { useDataProcessingPolicy } from 'apps/Imports/hooks/useDataProcessingPolicy'
import { useImportContext } from 'apps/Imports/hooks/useImportContext'
import { useMarketingPolicy } from 'apps/Imports/hooks/useMarketingPolicy'
import arrayToSentence from 'apps/Imports/utils/array-to-sentence'
import {
  MARKETING_CHANNELS,
  PROFILE_IMPORT_STEPS
} from 'apps/Imports/utils/profile-import/constants'
import importUrlFromStepId from 'apps/Imports/utils/profile-import/get-url-from-step-id'

const ConsentSummarySection = () => {
  const context = useImportContext()
  const marketingPolicy = useMarketingPolicy()
  const dataProcessingConsentPolicy = useDataProcessingPolicy()
  const communicationPolicies = useCommunicationPolicies()
  const {
    data: { consentData }
  } = useSelectedData()

  const {
    consentType,
    marketingAgreement,
    marketingOptions,
    sourceOfConsent,
    dateOfConsent
  } = consentData

  if (
    !marketingPolicy ||
    (!marketingPolicy.published && !dataProcessingConsentPolicy.published)
  )
    return null

  const fieldsData = []
  if (marketingPolicy.published) {
    fieldsData.push({
      label: <Trans>They consent to be contacted via Marketing Policy</Trans>,
      value:
        consentType === 'same' ? (
          (marketingAgreement === 'no' && (
            <Trans>No new agreement made</Trans>
          )) ||
          (marketingAgreement === 'doesNotWant' && (
            <Trans>Does not want to receive marketing materials</Trans>
          )) ||
          arrayToSentence(
            Object.keys(marketingOptions).reduce((acc, channel) => {
              return MARKETING_CHANNELS[channel] && marketingOptions[channel]
                ? [...acc, MARKETING_CHANNELS[channel]]
                : acc
            }, [])
          )
        ) : (
          <Trans>via Mapping</Trans>
        )
    })
  }
  if (communicationPolicies.some((comPol) => comPol.published)) {
    communicationPolicies.forEach((comPol) => {
      if (!comPol.published) {
        return
      }
      const currentData = consentData[comPol.id] || {}
      const communicationOptions = currentData.communicationOptions || {}
      const communicationAgreement = currentData.communicationAgreement || {}

      fieldsData.push({
        label: <Trans>They consent to be contacted via {comPol.name}</Trans>,
        value:
          consentData.consentType === 'same' ? (
            (communicationAgreement === 'no' && (
              <Trans>No new agreement made</Trans>
            )) ||
            (communicationAgreement === 'doesNotWant' && (
              <Trans>Does not want to receive marketing materials</Trans>
            )) ||
            arrayToSentence(
              Object.keys(communicationOptions).reduce((acc, channel) => {
                return MARKETING_CHANNELS[channel] &&
                  communicationOptions[channel]
                  ? [...acc, MARKETING_CHANNELS[channel]]
                  : acc
              }, [])
            )
          ) : (
            <Trans>via Mapping</Trans>
          )
      })
    })
  }
  fieldsData.push({
    label: <Trans>Source of consent</Trans>,
    value: consentType === 'same' ? sourceOfConsent : <Trans>via Mapping</Trans>
  })
  fieldsData.push({
    label: <Trans>Date and time of consent</Trans>,
    value:
      consentType === 'same' ? (
        <Trans>
          {moment(dateOfConsent).format('LL')} at{' '}
          {moment(dateOfConsent).format('LT')}
        </Trans>
      ) : (
        <Trans>via Mapping</Trans>
      )
  })

  return (
    <SimpleSummarySection
      linkTo={importUrlFromStepId(context, PROFILE_IMPORT_STEPS.POLICY_CONSENT)}
      stepId={PROFILE_IMPORT_STEPS.POLICY_CONSENT}
      fieldsData={fieldsData.filter((x) => x)}
    />
  )
}

export default ConsentSummarySection

{
  "Analytical": "Analytiske cookies",
  "Strictly necessary": "Strengt nødvendige cookies",
  "Functional": "Funktionelle cookies",
  "Advertising": "Cookies til annoncering",
  "Submit": "Indsend",
  "Thank you": "Tak",
  "You have confirmed your marketing preferences.": "Du har bekræftet dine marketingspræferencer.",
  "You did not accept the Privacy Policy.": "Du har ikke godkendt privatlivspolitikken.",
  "You must contact us to delete your data.": "Du skal kontakte os for at slette din data.",
  "Change privacy policy agreement": "Skift privatlivspolitikkens aftale",
  "Privacy Policy": "Privatlivspolitik",
  "Next: Update marketing preferences": "Næste: Opdater marketingspræferencer",
  "I Accept the Privacy Policy": "Jeg godkender privatlivspolitikken",
  "I DO NOT accept the Privacy Policy": "Jeg godkender IKKE privatlivspolitikken",
  "If you do not accept the privacy policy, you must contact the data processor to delete your data.": "Hvis du ikke accepterer privatlivspolitikken, skal du kontakte databehandleren for at få slettet dine data.",
  "You have updated your marketing preferences.": "Du har opdateret dine marketingspræferencer.",
  "Log in": "Log ind",
  "Finish your registration": "Afslut din registrering",
  "Marketing Policy": "Marketingpolitik",
  "I would like to receive information by Email": "Jeg vil gerne modtage information via mail",
  "I would like to receive information by Phone": "Jeg ønsker at modtage information via telefon",
  "I would like to receive information by SMS": "Jeg ønsker at modtage information via SMS",
  "I would like to receive information by Mail": "Jeg ønsker at modtage information via brev",
  "I do not want to be contacted with this type of information": "Jeg ønsker ikke at blive kontaktet med denne type information",
  "Cookie Policy": "Cookiepolitik",
  "Back to Portal": "Tilbage til portalen",
  "Manage your preferences": "Administrer dine præferencer",
  "Save cookie preferences": "Gem cookiepræferencer",
  "You accepted our privacy policy on our website - {{institutionHost}} - on {{date}}": "Du godkendte vores privatlivspolitik på vores hjemmeside - {{institutionHost}} - på {{date}}",
  "You are receiving this email because you accepted our marketing policy on our website - {{institutionHost}} - on  {{date}}": "Du modtager denne e-mail, da du har godkendt vores marketingspolitik på vores hjemmeside - {{institutionHost}} - på {{date}}",
  "You accepted our privacy policy when creating an account on {{date}}": "Du godkendte vores privatlivspolitik, da du oprettede en profil på {{date}}",
  "You accepted our privacy policy on {{date}} from our email": "Du godkendte vores privatlivspolitik på {{date}} fra vores e-mail",
  "You are receiving this email because you accepted our marketing policy on {{date}} from our email": "Du modtager denne e-mail, da du har godkendt vores marketingspolitik på {{date}} fra vores e-mail",
  "You are receiving this email because a person created a profile for you and obtained your consent from {{consentSource}} on {{date}}": "Du modtager denne e-mail, da en person har oprettet en profil for dig med dit samtykke fra \" {{consentSource}}\" på {{date}}",
  "You accepted our privacy policy when submitting the {{entity}} form on {{date}}": "Du godkendte vores privatlivspolitik, da du indsendte \" {{entity}}” formularen på {{date}}",
  "You accepted our marketing policy when submitting the {{entity}} form on {{date}}": "Du godkendte vores marketingspolitik, da du indsendte \" {{entity}}” formularen på {{date}}",
  "You accepted our privacy policy when registering for the {{entity}} event on {{date}}": "u godkendte vores privatlivspolitik, da du registrerede dig til \" {{entity}}” eventet på {{date}}",
  "You accepted our marketing policy when registering for the {{entity}} event on {{date}}": "Du godkendte vores marketingspolitik, da du registrerede til \" {{entity}}” begivenhed på {{date}}",
  "Unsubscribe": "Opsige abonnement",
  "Yes, unsubscribe": "Ja, afmeld",
  "No, stay subscribed": "Nej, forbliv abonnent",
  "Manage marketing preferences": "Administrer markedsføringspræferencer",
  "Manage preferences for <0>{{policyName}}</0>": "Administrer præferencer for <0>{{policyName}}</0>",
  "Unsubscribe successful": "Afmeldelsen lykkedes",
  "You are still subscribed": "Du er stadig tilmeldt",
  "Back to homepage": "Tilbage til startsiden",
  "Are you sure you want to unsubscribe from the <0>{{topicName}}</0> topic?": "Er du sikker på, at du vil afmelde emnet <0>{{topicName}}</0>?",
  "You have been unsubscribed from the <0>{{topicName}}</0> topic.": "Du er blevet afmeldt <0>{{topicName}}</0>-emnet.",
  "You have not been unsubscribed from the <0>{{topicName}}</0> topic.": "Du er ikke blevet afmeldt <0>{{topicName}}</0>-emnet."
}

import React, { useCallback, useEffect, useState } from 'react'

import usePathObserver from 'apps/ContentPages/hooks/use-path-observer'
import Menu from './menu'
import MenuButton from './menu-button'

const NavigationInHeader = () => {
  const [isMenuOpened, setIsMenuOpened] = useState(false)
  const { subscribe, unsubscribe } = usePathObserver()
  const closeMenu = useCallback(() => setIsMenuOpened(false), [])

  useEffect(() => {
    subscribe(closeMenu)

    return () => {
      unsubscribe(closeMenu)
    }
  }, [subscribe, unsubscribe, closeMenu])

  return (
    <>
      <MenuButton onClick={() => setIsMenuOpened(true)} />
      <Menu opened={isMenuOpened} handleClose={closeMenu} />
    </>
  )
}

export default NavigationInHeader

export const CONTRACTS_TABLE_QUERY_KEY_PART = [
  'tableDataFetch',
  '/billing/api/contracts'
]

export const getApplicsUnderContractQueryKeyFilter = (contractId) => [
  'tableDataFetch',
  `/billing/api/tabular_data/billing_applics/${contractId}`
]

export const GET_CONTRACT_QUERY_KEY = 'getContract'

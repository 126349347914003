.react-app ._loginForm_zlkvc_1 {
  margin-bottom: 0;
}
.react-app ._loginForm_zlkvc_1 ._loginActions_zlkvc_4 {
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.react-app ._loginForm_zlkvc_1 ._alertMessage_zlkvc_10 {
  margin-bottom: var(--space-default);
}
.react-app ._loginForm_zlkvc_1 ._forgotPassword_zlkvc_13 {
  text-align: right;
}
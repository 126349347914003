import React from 'react'

import { useMergeData } from 'apps/MergeProfiles/contexts/MergeData'
import {
  isMultipleValueUnidimensionField,
  isSingleValueField
} from 'apps/MergeProfiles/utils/fieldTypeHelpers'
import MultiValue1DFieldRow from './MultiValue1DField'
import SimpleFieldRow from './SimpleField'

const FieldBaseRow = ({ field }) => {
  const { mergeError, updateProfileInfoFrom } = useMergeData()

  const onSelectDuplicate = React.useCallback(() => {
    updateProfileInfoFrom({ [field.name]: 'duplicate' })
  }, [field.name, updateProfileInfoFrom])

  const onSelectMain = React.useCallback(() => {
    updateProfileInfoFrom({ [field.name]: 'main' })
  }, [field.name, updateProfileInfoFrom])

  const error = mergeError.profileInfoFromFieldsWithoutSelection?.filter(
    (fieldWithoutSelection) => fieldWithoutSelection.id === field.id
  )[0]

  const FieldRow = isSingleValueField(field)
    ? SimpleFieldRow
    : isMultipleValueUnidimensionField(field)
    ? MultiValue1DFieldRow
    : null

  return (
    FieldRow && (
      <FieldRow
        field={field}
        error={error}
        onSelectDuplicate={onSelectDuplicate}
        onSelectMain={onSelectMain}
      />
    )
  )
}

export default FieldBaseRow

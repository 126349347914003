/* eslint-disable camelcase */

import { connect } from 'react-redux'

import actionTypes from 'apps/Courses/actions/types'
import updateCourse from 'apps/Courses/actions/updateCourse'
import CourseEditFormBasic from 'apps/Courses/components/CourseEditFormBasic'

const mapStateToProps = ({
  editCourse: {
    basicSection: { data, inProgress, errors }
  },
  appData: { h: { currency_options = [], campuses_options = [] } = {} } = {}
}) => ({
  data,
  inProgress,
  errors,
  currencyOptions: currency_options.map(([label, value]) => ({
    value,
    label
  })),
  campusesOptions: campuses_options.map(([label, value]) => ({
    value,
    label
  }))
})

const mapDispatchToProps = (dispatch) => ({
  onCancelClick: () =>
    dispatch({ type: actionTypes.cancelEditBasicSectionData }),
  onChange: (field, value) =>
    dispatch({
      type: actionTypes.editCourseBasicSectionDataChanged,
      payload: { [field]: value }
    }),
  onSaveClick: () =>
    dispatch(updateCourse(actionTypes.updateCourseBasicSection))
})

export default connect(mapStateToProps, mapDispatchToProps)(CourseEditFormBasic)

import React from 'react'

import { useIncompleteTask } from 'apps/Inbox/hooks/handleIncompleteTaskHook'

export const ScheduledToCompleteContext = React.createContext()

export const ScheduledToCompleteProvider = ({ children }) => {
  const [scheduledToComplete, setScheduledToComplete] = React.useState([])

  return (
    <ScheduledToCompleteContext.Provider
      value={{
        scheduledToComplete,
        setScheduledToComplete
      }}
    >
      {children}
    </ScheduledToCompleteContext.Provider>
  )
}

export const useScheduledToComplete = () =>
  React.useContext(ScheduledToCompleteContext)?.scheduledToComplete

export const useSetScheduledToComplete = () => {
  const { setScheduledToComplete, scheduledToComplete } =
    React.useContext(ScheduledToCompleteContext) || {}

  const addToList = ({ card, index, pending }) => {
    const { entity: task } = card

    setScheduledToComplete([
      ...scheduledToComplete,
      { card, task, index, pending }
    ])
  }
  return addToList
}

export const useUnsetScheduledToComplete = () => {
  const incompleteTaskMutation = useIncompleteTask()

  const { setScheduledToComplete, scheduledToComplete } =
    React.useContext(ScheduledToCompleteContext) || {}

  const removeFromList = ({ card }) => {
    const newScheduledToComplete = scheduledToComplete.filter(
      (sched) => sched.card.id !== card.id
    )
    incompleteTaskMutation({ card })
    setScheduledToComplete([...newScheduledToComplete])
  }
  return removeFromList
}

export const useCloseScheduledToComplete = () => {
  const { setScheduledToComplete, scheduledToComplete } =
    React.useContext(ScheduledToCompleteContext) || {}

  const closeNotification = ({ card }) => {
    const newScheduledToComplete = scheduledToComplete.map((sched) => {
      if (sched.card.id === card.id) {
        sched.hide = true
      }
      return sched
    })

    setScheduledToComplete([...newScheduledToComplete])
  }
  return closeNotification
}

import React from 'react'

import { useScreenClass } from '@fullfabric/alma-mater'

import Logo from '../../logo'

import styles from './styles.module.scss'

const LogoLink = () => {
  const screenClass = useScreenClass()
  const isMobileNavigation = ['sm', 'md'].includes(screenClass)

  if (isMobileNavigation) {
    return null
  }

  return (
    <a href='/' className={styles.container}>
      <Logo className={styles.logo} />
    </a>
  )
}

export default LogoLink

import React from 'react'

import { useImportSteps } from 'apps/Imports/contexts/steps'
import StepItem from './step-item'
import StepLine from './step-line'

import styles from './styles.module.scss'

const Steps = () => {
  const steps = useImportSteps()

  return (
    <ul className={styles.stepsContainer}>
      {steps.map((step, index) => {
        const stepItem = <StepItem key={index} {...step} index={index + 1} />

        if (index === steps.length - 1) {
          return stepItem
        } else {
          return (
            <React.Fragment key={`line-${index}`}>
              {stepItem}
              <StepLine {...step} />
            </React.Fragment>
          )
        }
      })}
    </ul>
  )
}

export default Steps

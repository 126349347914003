import React from 'react'

import { OneColumnLayout } from '@fullfabric/alma-mater'

import ApplicPerformanceReport from 'apps/Reports/components/ApplicPerformanceReport'
import Head from './Head'

import styles from './styles.module.scss'

const STORAGE_FILTER_KEY = 'applic_performance:filter:'

const ApplicPerformancePage = () => (
  <OneColumnLayout pageHead={<Head />} className={styles.pageSpacing}>
    <ApplicPerformanceReport filterStorageKey={STORAGE_FILTER_KEY} />
  </OneColumnLayout>
)

export default ApplicPerformancePage

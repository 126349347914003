import React from 'react'
import { Trans } from 'react-i18next'

import { useCurrentUser } from '@fullfabric/authorization-officer'

import Link from '../link'
import LoggedInMenu from './logged-in-menu'
import UserIcon from './user-icon'

import styles from './styles.module.scss'

const LoginLink = ({ last }) => {
  const user = useCurrentUser()
  const isLoggedIn = !!user

  if (isLoggedIn) {
    return <LoggedInMenu />
  }

  return (
    <Link
      external
      large
      last={last}
      icon={<UserIcon />}
      label={
        <>
          <Trans>Login</Trans> • <Trans>Register</Trans>
        </>
      }
      to='/login'
      className={styles.link}
    />
  )
}

export default LoginLink

import React from 'react'

import moment from 'moment'

import { Table } from '@fullfabric/alma-mater'

import styles from './Cell.module.scss'

export default function DateTimeCell({ value }) {
  const date = moment(value)
  const formattedTime = date.format('MMM Do YYYY, h:mm:ss a')

  return (
    <Table.Data
      tag='div'
      className={styles.customCell}
      complementaryInfo={date.fromNow()}
    >
      {formattedTime}
    </Table.Data>
  )
}

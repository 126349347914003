import React from 'react'
import { useTranslation } from 'react-i18next'

import { Text } from '@fullfabric/alma-mater'
import { useCurrentUser } from '@fullfabric/authorization-officer'

import { useLocale } from 'shared/contexts/Locale'

import { useLinkedApplication, useTemplate } from '../../template-info-context'
import { useTypeOfWidget } from '../../type-of-widget-context'
import getDateAndTimeText from './get-date-and-time-text'
import getDateInUserTimezone from './get-date-in-user-timezone'
import getLabelColor from './get-label-color'
import getLabelText from './get-label-text'
import getTargetDate from './get-target-date'

const TemplateDate = ({ state }) => {
  const { t } = useTranslation()
  const user = useCurrentUser()
  const { locale } = useLocale()

  const template = useTemplate()
  const application = useLinkedApplication()
  const { isRegistration } = useTypeOfWidget()

  const rawDate = getTargetDate({ state, template, application })
  const formattedDate = getDateInUserTimezone(
    rawDate,
    user?.timezoneName,
    locale
  )

  const dateAndTimeText = formattedDate
    ? getDateAndTimeText(formattedDate, t)
    : null

  const textColor = getLabelColor(state, formattedDate)
  const labelText = getLabelText(state, dateAndTimeText, isRegistration, t)

  if (!labelText) {
    return null
  }

  return (
    <Text type='h6' marginTop='small' fontColor={textColor}>
      {labelText}
    </Text>
  )
}

export default TemplateDate

import React from 'react'
import { Trans } from 'react-i18next'

import { CheckboxInput, Field, Link, Text } from '@fullfabric/alma-mater'

import { useSelectedData } from 'apps/Imports/contexts/selected-data'
import { useDataProcessingPolicy } from 'apps/Imports/hooks/useDataProcessingPolicy'

import styles from './styles.module.scss'

const DataProcessingPolicySection = () => {
  const dataProcessingPolicy = useDataProcessingPolicy()
  const {
    data: { consentData },
    onDataChanged
  } = useSelectedData()

  const onConsentDataChanged = React.useCallback(
    (field, value) => {
      const dependantChanges =
        field === 'consentType' && value === 'unique'
          ? {
              sourceOfConsent: '',
              dateOfConsent: null
            }
          : {}

      onDataChanged('consentData', {
        ...consentData,
        ...dependantChanges,
        [field]: value
      })
    },
    [onDataChanged, consentData]
  )

  const dataProcessingConsent = consentData.dataProcessingConsent
  const consentType = consentData.consentType

  if (!dataProcessingPolicy?.published) return null

  return (
    <>
      <Text
        type='h4'
        marginTop='default'
        marginBottom='more'
        fontColor='text-base-darkest'
      >
        <Trans>How would you like to import the persons' consent?</Trans>
      </Text>
      <Field
        id='consentType'
        type='radio'
        value={consentType}
        onChange={onConsentDataChanged}
        inputOptions={[
          {
            value: 'same',
            label: <Trans>Same consent for each row in the import file</Trans>
          },
          {
            value: 'unique',
            label: (
              <Trans>Unique consent for each row in the import file</Trans>
            ),
            className: styles.uniqueConsentCaption,
            instructions:
              consentType === 'unique' ? (
                <Trans>
                  Please map the columns in your import in the Mapping step.
                  Please download an import template
                  <Link
                    href={`/api/import/import_template_sample`}
                    target='_blank'
                  >
                    here
                  </Link>
                </Trans>
              ) : null
          }
        ]}
        className={styles.consentType}
      />

      {consentType !== 'unique' ? (
        <>
          <Text type='h4' marginBottom='small' fontColor='text-base-darkest'>
            <Trans>Data processing consent</Trans>
          </Text>
          <CheckboxInput
            id='dataProcessingConsent'
            checked={dataProcessingConsent}
            label={
              <Trans>
                These persons have consented to having their data processed
              </Trans>
            }
            onChange={(checked) =>
              onConsentDataChanged('dataProcessingConsent', checked)
            }
          />
        </>
      ) : null}
    </>
  )
}

export default DataProcessingPolicySection

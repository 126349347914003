import React from 'react'
import { useTranslation } from 'react-i18next'

import { CLASS_SIDE_COLOR, formatAsPercentage } from '..'

const ClassCapacityLabel = ({ totalCapacity, capacity, capacityRatio }) => {
  const { t } = useTranslation()
  const mainLabelPart =
    totalCapacity > 0
      ? t(`Class:{{capacityPercentage}}%`, {
          capacityPercentage: formatAsPercentage(capacityRatio)
        })
      : t(`Class:0`)
  const secondaryLabelPart = totalCapacity > 0 ? `(${capacity})` : ''

  return (
    <>
      <text
        textAnchor={'start'}
        x={0}
        y='14'
        data-testid='class-capacity-value'
      >
        <tspan fill={CLASS_SIDE_COLOR} fontWeight='600'>
          {mainLabelPart}
        </tspan>
        {secondaryLabelPart ? (
          <tspan fill={CLASS_SIDE_COLOR} fontWeight='400'>
            {secondaryLabelPart}
          </tspan>
        ) : null}
      </text>
    </>
  )
}

export default ClassCapacityLabel

import React from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'

import { Loading } from '@fullfabric/alma-mater'

import api from 'apps/ContentPages/api'
import FeedbackMessageProvider from 'apps/ContentPages/components/feeback-message-provider'
import ApplicPaymentArea from '../components/applic-payment-area'
import PaymentsDrawer from '../components/payments-drawer'
import { PaymentsDrawerStateProvider } from '../contexts/payments-drawer'

export default function ApplicPaymentPage() {
  const params = useParams()

  const { data: applic, isLoading } = useQuery(
    ['get-applic', params.applicId],
    () => api.getApplic(params.applicId),
    {
      onSuccess: (data) => templateQuery.refetch()
    }
  )

  const templateQuery = useQuery(
    ['get-application-and-registration-templates', applic?.template_id],
    () => api.getApplicationAndRegistrationTemplates([applic?.template_id])
  )

  if (isLoading || templateQuery.isLoading) {
    return <Loading />
  }

  return (
    <FeedbackMessageProvider>
      <PaymentsDrawerStateProvider>
        <ApplicPaymentArea template={templateQuery.data[0]} applic={applic} />
        <PaymentsDrawer />
      </PaymentsDrawerStateProvider>
    </FeedbackMessageProvider>
  )
}

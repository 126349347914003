import React from 'react'
import { useFlexLayout, useRowSelect, useSortBy, useTable } from 'react-table'
import {
  CellMeasurerCache,
  Column as RVColumn,
  Table as RVTable,
  WindowScroller
} from 'react-virtualized'

import { flatten } from 'array-flatten'

import { TABLE_SORT_DEFAULT_CONFIG } from '../../../constants'
import useCellsWithLinks from '../../../hooks/useCellsWithLinks'
import useColumnTypes from '../../../hooks/useColumnTypes'
import useRowSelectCheckbox from '../../../hooks/useRowSelectCheckbox'
import LoadMoreRow from '../../LoadMoreRow'
import PlaceholderTable from '../../PlaceholderTable'
import getHeaderRenderer from './getHeaderRenderer'
import EmptyRow from './rows/Empty'
import ErrorRow from './rows/Error'
import useOffsetTop from './useOffsetTop'
import useRenderCell from './useRenderCell'
import useRenderHeaderRow from './useRenderHeaderRow'
import useRenderRow from './useRenderRow'

export default function VDataTable({
  data = [],
  autoPaginate,
  columns,
  width,
  emptyMessage,
  loading,
  className,
  tableProps,
  error,
  onColumnClick,
  onRowClick,
  onRowDoubleClick,
  onRowMouseOut,
  onRowMouseOver,
  onRowRightClick,
  onRowsRendered,
  overscanRowCount,
  scrollElement,
  containerRef,
  onChangeSort,
  selectableRows,
  onSelectionChanged,
  onLoadMoreClick,
  loadMoreProps
}) {
  const memoData = React.useMemo(() => data, [data])
  const memoColumns = React.useMemo(() => columns, [columns])

  const isSortableTable = !!onChangeSort
  const sortByConfig = isSortableTable ? TABLE_SORT_DEFAULT_CONFIG : {}

  const {
    headerGroups,
    rows,
    prepareRow,
    visibleColumns,
    setSortBy,
    ...otherTableProps
  } = useTable(
    {
      columns: memoColumns,
      data: memoData,
      autoResetSelectedRows: false,
      autoResetSortBy: false,
      ...sortByConfig,
      ...(tableProps || {})
    },
    useFlexLayout,
    useColumnTypes,
    useCellsWithLinks,
    ...(selectableRows ? [useRowSelect, useRowSelectCheckbox] : []),
    ...(isSortableTable ? [useSortBy] : [])
  )

  React.useEffect(() => {
    selectableRows &&
      onSelectionChanged &&
      onSelectionChanged(
        otherTableProps.selectedFlatRows.map((row) => row.original),
        otherTableProps.selectedFlatRows
      )
  }, [selectableRows, onSelectionChanged, otherTableProps.selectedFlatRows])

  const cellMeasureCache = React.useMemo(
    () =>
      new CellMeasurerCache({
        fixedHeight: false,
        fixedWidth: true
      }),
    []
  )

  const renderRow = useRenderRow(rows, !!(onRowClick || onRowDoubleClick))
  const renderCell = useRenderCell(prepareRow, rows, cellMeasureCache, memoData)
  const renderHeaderRow = useRenderHeaderRow(headerGroups)

  const offsetTop = useOffsetTop(containerRef)

  const handleSort = React.useCallback(
    ({ sortBy: columnId }) => {
      const columnData = memoColumns.find(
        (c) => c.accessor === columnId || c.id === columnId
      )

      if (!columnData.disableSortBy) {
        return onChangeSort({ id: columnId }, setSortBy)
      }
    },
    [onChangeSort, setSortBy, memoColumns]
  )

  return (
    <WindowScroller scrollElement={scrollElement}>
      {({ height, isScrolling, scrollTop, registerChild }) => {
        return (
          <div ref={registerChild}>
            <RVTable
              width={Math.max(
                width || 0,
                flatten(headerGroups.map((hg) => hg.headers))
                  .map((column) => column.width)
                  .reduce((a, b) => a + b, 0)
              )}
              autoHeight
              className={className}
              height={height}
              isScrolling={isScrolling}
              scrollTop={
                scrollTop - offsetTop > 0 ? scrollTop - offsetTop : scrollTop
              }
              headerHeight={30}
              headerRowRenderer={renderHeaderRow}
              rowHeight={cellMeasureCache.rowHeight}
              rowCount={data.length}
              rowGetter={({ index }) => data[index]}
              rowRenderer={renderRow}
              noRowsRenderer={() =>
                loading ? (
                  <PlaceholderTable virtualized columns={visibleColumns} />
                ) : !loading && error?.message ? (
                  <ErrorRow error={error} />
                ) : (
                  !loading &&
                  !error?.message &&
                  data.length === 0 && <EmptyRow message={emptyMessage} />
                )
              }
              onColumnClick={onColumnClick}
              sort={onChangeSort && handleSort}
              onRowClick={onRowClick}
              onRowDoubleClick={onRowDoubleClick}
              onRowMouseOut={onRowMouseOut}
              onRowMouseOver={onRowMouseOver}
              onRowRightClick={onRowRightClick}
              onRowsRendered={onRowsRendered}
              overscanRowCount={overscanRowCount}
            >
              {flatten(headerGroups.map((hg) => hg.headers)).map((column) => {
                return (
                  <RVColumn
                    key={column.id}
                    headerRenderer={getHeaderRenderer(column)}
                    flexGrow={1}
                    cellRenderer={renderCell}
                    dataKey={column.id}
                    label={column.render('Header')}
                    width={column.width}
                    maxWidth={column.maxWidth}
                    minWidth={column.minWidth}
                  />
                )
              })}
            </RVTable>

            {onLoadMoreClick && (
              <LoadMoreRow
                virtualized
                autoPaginate={autoPaginate}
                onClick={onLoadMoreClick}
                loadingNextPage={loadMoreProps?.loadingNextPage}
                pageSize={loadMoreProps?.pageSize}
                page={loadMoreProps?.page}
                total={loadMoreProps?.total}
              />
            )}
          </div>
        )
      }}
    </WindowScroller>
  )
}

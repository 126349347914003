import React from 'react'

import { Modal } from '@fullfabric/alma-mater'

import {
  SUBMISSION_STATE,
  useSubmissionState
} from 'apps/Evaluations/context/EvaluationFormData'
import ConfirmationContent from './ConfirmationContent'
import SuccessContent from './SuccessContent'
import UnsubmissionContent from './UnsubmissionContent'

const modalContentMapper = {
  confirmSubmit: {
    main: ConfirmationContent,
    footer: ConfirmationContent.Footer,
    header: ConfirmationContent.Header
  },
  submissionSuccess: {
    main: SuccessContent,
    footer: SuccessContent.Footer,
    header: SuccessContent.Header
  },
  confirmUnsubmit: {
    main: UnsubmissionContent,
    footer: UnsubmissionContent.Footer,
    header: UnsubmissionContent.Header
  }
}

const buildModalContent = (modalState) => {
  switch (modalState) {
    case SUBMISSION_STATE.MODAL.CONFIRM_SUBMIT:
    case SUBMISSION_STATE.MODAL.SUBMISSION_ERROR:
      return modalContentMapper.confirmSubmit
    case SUBMISSION_STATE.MODAL.SUCCESS:
    case SUBMISSION_STATE.MODAL.SUBMITTING:
      return modalContentMapper.submissionSuccess
    case SUBMISSION_STATE.MODAL.CONFIRM_UNSUBMIT:
    case SUBMISSION_STATE.MODAL.UNSUBMITTING:
      return modalContentMapper.confirmUnsubmit
    default:
      return {}
  }
}

const SubmissionModal = () => {
  const submissionState = useSubmissionState()

  const isOpen = submissionState.modal !== SUBMISSION_STATE.MODAL.HIDDEN

  const {
    main: ModalContent,
    footer: ModalFooter,
    header: ModalHeader
  } = buildModalContent(submissionState.modal)

  return (
    <Modal
      role='dialog'
      aria-labelledby='submission-modal-title'
      isOpen={isOpen}
      size='medium'
      header={<ModalHeader />}
      footer={<ModalFooter />}
    >
      <ModalContent />
    </Modal>
  )
}

export default SubmissionModal

.react-app ._image_1shdb_1 {
  overflow: hidden;
  position: relative;
  height: 100vh;
  width: 100%;
  background-color: var(--ff-bg-base-light);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.react-app ._image_1shdb_1 {
  animation: blinking-grey 750ms ease-in-out infinite alternate both;
}

.react-app ._image_1shdb_1._mobile_1shdb_15 {
  width: 100%;
  height: 100%;
  margin-right: 0;
}
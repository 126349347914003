import React, { useState } from 'react'
import { Trans } from 'react-i18next'

import { ActionList, MoreIcon } from '@fullfabric/alma-mater'

import ChangePathModal from 'apps/Applics/components/StaffSubjectSelection/ChangePathModal'
import { getPathsWithState } from 'apps/Applics/utils/getSelectedPath'

import styles from './styles.module.scss'

const ChangePathAction = ({ studyPlan, applic }) => {
  const studyPlanPaths = studyPlan?.paths
  const selectedPaths = getPathsWithState(applic, studyPlanPaths)

  const [open, setOpen] = useState(false)

  const closeModal = () => {
    setOpen(false)
  }

  const openModal = () => {
    setOpen(true)
  }

  return (
    <>
      <ActionList
        iconButton={<MoreIcon />}
        menuPosition='down'
        alignEnd={false}
        iconButtonProps={{
          className: styles.spaceBefore
        }}
      >
        <ActionList.Option
          negative
          disabled={!studyPlanPaths}
          onClick={openModal}
        >
          <Trans>Change Path</Trans>
        </ActionList.Option>
      </ActionList>
      <ChangePathModal
        applic={applic}
        studyPlanPaths={selectedPaths}
        isOpen={open}
        handleClose={closeModal}
      />
    </>
  )
}

export default ChangePathAction

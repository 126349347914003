import React from 'react'

const PremiumIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='12'
      height='12'
      fill='none'
      viewBox='0 0 12 12'
    >
      <path
        fill='#CB6E17'
        fillRule='evenodd'
        d='M9.766.394l2.196 2.907c.043.057.05.132.018.196a.188.188 0 01-.168.103H.188a.188.188 0 01-.168-.103.185.185 0 01.018-.196L2.234.394A.991.991 0 013.027 0h5.947a.99.99 0 01.792.394zm2.23 4.42a.03.03 0 010 .028l-5.243 6.77a.962.962 0 01-.759.388.962.962 0 01-.758-.388L.003 4.842a.03.03 0 010-.028.024.024 0 01.02-.014H11.98a.025.025 0 01.017.014z'
        clipRule='evenodd'
      ></path>
    </svg>
  )
}

export default PremiumIcon

import React from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

import { Col, Row } from '@fullfabric/alma-mater'

import getApplicsCumulativeTimeseries from 'apps/Reports/api/getApplicsCumulativeTimeseries'
import { useFilter } from '../FilterContext'
import StatsWidget from './StatsWidget'

import styles from './styles.module.scss'

const StatsWidgetsRow = () => {
  const { t } = useTranslation()
  const filter = useFilter()
  const { data, isLoading } = useQuery(
    ['getApplicsCumulativeTimeseries', filter],
    () => getApplicsCumulativeTimeseries(filter)
  )

  const timeseries = data || []
  const stats = timeseries[timeseries.length - 1]

  return (
    <Row className={styles.container}>
      <Col xs={4}>
        <StatsWidget
          title={t('Total started applications')}
          value={stats?.starts}
          isLoading={isLoading}
        />
      </Col>
      <Col xs={4}>
        <StatsWidget
          title={t('Total submitted applications')}
          value={stats?.submissions}
          isLoading={isLoading}
        />
      </Col>
      <Col xs={4}>
        <StatsWidget
          title={t('Conversion rate')}
          value={
            stats?.starts &&
            `${Math.round((stats.submissions / stats.starts) * 1000) / 10}%`
          }
          isLoading={isLoading}
        />
      </Col>
    </Row>
  )
}

export default StatsWidgetsRow

import 'css-chunk:src/apps/Imports/components/file-upload-step/components/file-upload-input/styles.module.scss';export default {
  "react-app": "react-app",
  "fileUploadInput": "_fileUploadInput_mtil3_1",
  "fileValid": "_fileValid_mtil3_15",
  "validText": "_validText_mtil3_18",
  "fileInvalid": "_fileInvalid_mtil3_27",
  "invalidText": "_invalidText_mtil3_30",
  "fileUploadContent": "_fileUploadContent_mtil3_52",
  "acceptedFileIcons": "_acceptedFileIcons_mtil3_56",
  "acceptedFileCheck": "_acceptedFileCheck_mtil3_62"
};
import createImport from './create-import'
import getImport from './get-import'
import getSourceCategories from './get-source-categories'
import getTags from './get-tags'
import updateImport from './update-import'

const api = {
  createImport,
  updateImport,
  getImport,
  getSourceCategories,
  getTags
}

export default api

import React from 'react'
import { useQuery } from 'react-query'

import getJourneysSchema from 'apps/Financial/api/getJourneysSchema'
import getProfileSchema from 'apps/Financial/api/getProfileSchema'

const ProfileSchemaContext = React.createContext(null)
const JourneysSchemaContext = React.createContext(null)

export const useProfileSchema = () => React.useContext(ProfileSchemaContext)
export const useJourneysSchema = () => React.useContext(JourneysSchemaContext)

export const SchemasProvider = ({ children }) => {
  const { data: profileSchema } = useQuery('profileSchema', getProfileSchema, {
    suspense: true,
    staleTime: Infinity
  })
  const { data: journeySchema } = useQuery(
    'journeysSchema',
    getJourneysSchema,
    {
      suspense: true,
      staleTime: Infinity
    }
  )

  return (
    <ProfileSchemaContext.Provider value={profileSchema}>
      <JourneysSchemaContext.Provider value={journeySchema}>
        {children}
      </JourneysSchemaContext.Provider>
    </ProfileSchemaContext.Provider>
  )
}

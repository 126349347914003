import { utils } from '@fullfabric/public-api'

const getProfileSchema = async () => {
  const response = await fetch('/api/institutions/schemas/profile', {
    credentials: 'same-origin'
  })
  return await utils.checkResponse(response)
}

export default getProfileSchema

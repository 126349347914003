import { utils } from '@fullfabric/public-api'

const getCard = async (cardId) => {
  const response = await fetch(`/inbox/api/cards/${cardId}`, {
    credentials: 'same-origin'
  })

  const result = await utils.checkResponse(response)
  const headers = response.headers.get('x-fullfabric-json')

  return { data: result, headers: JSON.parse(headers) }
}

export default getCard

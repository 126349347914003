const mergeImplicitFilter = (baseClassId, explicitFilter) => {
  if (!baseClassId) {
    return explicitFilter
  }

  const mergedClasses = [baseClassId, ...(explicitFilter?.classes || [])]

  return { ...explicitFilter, classes: mergedClasses }
}

export default mergeImplicitFilter

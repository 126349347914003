import React from 'react'
import { Trans } from 'react-i18next'

import { Text } from '@fullfabric/alma-mater'

import useIsMobileLayout from 'apps/ContentPages/components/payments-page/use-is-payments-mobile-layout'
import amountToPrice from 'apps/ContentPages/services/payments/amount-to-price'
import calculateDiscountAmount from 'apps/ContentPages/services/payments/calculate-discount-amount'

import classNames from 'classnames'
import styles from './styles.module.scss'

const AmountDetailsTable = ({ fee, discounts }) => {
  const isMobileLayout = useIsMobileLayout()
  return (
    <table
      aria-label='fee amount details'
      className={classNames(styles.table, isMobileLayout && styles.mobile)}
    >
      <thead>
        <tr>
          <Text
            tag='th'
            type='f5'
            fontColor='text-base-darker'
            className={classNames(styles.leftColumn, styles.headerCell)}
          >
            <Trans>Description</Trans>
          </Text>
          <Text
            tag='th'
            type='f5'
            fontColor='text-base-darker'
            className={classNames(styles.rightColumn, styles.headerCell)}
          >
            <Trans>Amount</Trans>
          </Text>
        </tr>
      </thead>
      <tbody>
        <tr aria-label='fee original price'>
          <Text
            tag='td'
            type={isMobileLayout ? 'f5' : 'f4'}
            fontColor='text-base-darkest'
            className={classNames(styles.leftColumn, styles.dataCell)}
          >
            {fee.description}
          </Text>
          <Text
            tag='td'
            type='f4'
            fontColor='text-base-darkest'
            className={classNames(styles.rightColumn, styles.dataCell)}
          >
            {amountToPrice(fee.currency, fee.amount)}
          </Text>
        </tr>
        {discounts.map((discount) => (
          <tr key={discount.id} aria-label='discount'>
            <td className={classNames(styles.leftColumn, styles.dataCell)}>
              <Text
                type={isMobileLayout ? 'f5' : 'f4'}
                fontColor='text-base-darkest'
              >
                {discount.description}
                {discount.type === 'percentage' &&
                  ` - ${discount.percentage * 100}%`}
              </Text>
              <Text type='f6' fontColor='text-base-darker' marginTop='tiny'>
                <Trans>Discount</Trans>
              </Text>
            </td>
            <Text
              tag='td'
              type='f4'
              fontColor='text-base-darkest'
              className={classNames(styles.rightColumn, styles.dataCell)}
            >
              -{' '}
              {amountToPrice(
                fee.currency,
                calculateDiscountAmount(fee, discount)
              )}
            </Text>
          </tr>
        ))}
        {fee.transactions
          ?.filter(
            (transaction) =>
              transaction.state === 'guaranteed' ||
              transaction.state === 'succeeded'
          )
          .map((transaction, index) => (
            <tr key={transaction.id} aria-label='discount'>
              <td className={classNames(styles.leftColumn, styles.dataCell)}>
                <Text
                  type={isMobileLayout ? 'f5' : 'f4'}
                  fontColor='text-base-darkest'
                >
                  <Trans>Payment</Trans> #{index + 1}
                </Text>
                <Text type='f6' fontColor='text-base-darker' marginTop='tiny'>
                  <Trans>Payment</Trans>
                </Text>
              </td>
              <Text
                tag='td'
                type='f4'
                fontColor='text-base-darkest'
                className={classNames(styles.rightColumn, styles.dataCell)}
              >
                - {amountToPrice(transaction.currency, transaction.amount)}
              </Text>
            </tr>
          ))}
        <tr aria-label='fee total price' className={styles.totalRow}>
          <Text
            tag='td'
            type={isMobileLayout ? 'f4' : 'f3'}
            fontColor='text-base-darkest'
            className={classNames(styles.leftColumn, styles.totalCell)}
          >
            <Trans>Total</Trans>
          </Text>
          <Text
            tag='td'
            type={isMobileLayout ? 'h4' : 'h3'}
            fontColor='text-base-darkest'
            className={classNames(styles.rightColumn, styles.totalCell)}
          >
            {amountToPrice(fee.currency, fee.outstanding_amount)}
          </Text>
        </tr>
      </tbody>
    </table>
  )
}

export default AmountDetailsTable

import getSubjects from 'apps/Courses/api/getSubjects'
import actionTypes from '../types'

const getSubjectsAction = () => async (dispatch, getState) => {
  const {
    subjectList: { loaded }
  } = getState()

  if (!loaded) {
    dispatch({ type: actionTypes.getSubjects.start })
    try {
      const subjects = await getSubjects()
      dispatch({ type: actionTypes.getSubjects.success, payload: subjects })
    } catch (error) {
      dispatch({ type: actionTypes.getSubjects.error, payload: error })
    }
  }
}

export default getSubjectsAction

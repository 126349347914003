import moment from 'moment'

const getFormattedDate = (targetDateMoment) => {
  const nowMoment = moment()
  const differenceInDays = targetDateMoment.diff(nowMoment, 'days')
  const isTomorrow = moment(nowMoment)
    .add(1, 'day')
    .isSame(targetDateMoment, 'day')

  const info = { daysToDeadline: differenceInDays }

  if (differenceInDays >= 30) {
    const date = targetDateMoment.format('ll')

    return { ...info, date }
  }

  if (differenceInDays >= 7 && differenceInDays < 30) {
    const date = targetDateMoment.format('dddd D MMM')

    return { ...info, date }
  }

  const time = targetDateMoment.format(`LT`)

  if (differenceInDays < 7 && differenceInDays >= 1 && !isTomorrow) {
    const date = targetDateMoment.format(`dddd D`)

    return { ...info, date, time }
  }

  if (isTomorrow) {
    return { ...info, date: 'tomorrow', time }
  }

  if (differenceInDays === 0) {
    return { ...info, date: 'today', time }
  }

  return {}
}

export default getFormattedDate

import React from 'react'
import { Trans } from 'react-i18next'

import { AnnotatedLayout } from '@fullfabric/alma-mater'

import SubjectFinalGradeDisplay from './Display'
import SubjectFinalGradeForm from './Form'

const SubjectFinalGradeSection = ({ beingEdited }) => (
  <AnnotatedLayout.Section
    title={<Trans>Final grade average & total credits awarded</Trans>}
    description={
      <Trans>
        Define whether this subject contributes towards the final grade average
        and the total credits awarded on students' transcripts.
      </Trans>
    }
  >
    {beingEdited ? <SubjectFinalGradeForm /> : <SubjectFinalGradeDisplay />}
  </AnnotatedLayout.Section>
)

export default SubjectFinalGradeSection

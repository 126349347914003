.react-app ._mobileLoadingPlaceholder_orlao_1 {
  height: 140px !important;
  margin: var(--space-more) var(--space-small);
  margin-top: var(--space-small);
}

.react-app ._mobileTitle_orlao_7 {
  text-transform: uppercase;
}

.react-app ._marginBetweenRegions_orlao_11 {
  margin-bottom: var(--space-more);
}

.react-app ._marginToEdgeOfPage_orlao_15 {
  margin-right: var(--space-big);
}
import getStartedApplicsStatsTimeseries from 'apps/Reports/api/getStartedApplicsTimeseries'
import getSubmittedApplicsStatsTimeseries from 'apps/Reports/api/getSubmittedApplicsTimeseries'

const getApplicsCumulativeTimeseries = async (filter = {}) => {
  const params = {
    ...filter,
    cumulative: true
  }

  const [started, submitted] = await Promise.all([
    getStartedApplicsStatsTimeseries(params),
    getSubmittedApplicsStatsTimeseries(params)
  ])

  return started.map((startedDataPoint, index) => {
    const submittedDataPoint = submitted[index] || {}
    return { ...startedDataPoint, ...submittedDataPoint }
  })
}

export default getApplicsCumulativeTimeseries

import React from 'react'
import { Route, Switch } from 'react-router'

import PaymentPlanListPage from 'apps/Financial/pages/PaymentPlanListPage'
import TransactionsPage from 'apps/Financial/pages/TransactionsPage'

export default function FinancialAppRoutes() {
  return (
    <Switch>
      <Route
        exact
        path='/book_keeper/payment_plans'
        component={PaymentPlanListPage}
      />
      <Route
        exact
        path='/book_keeper/transactions'
        component={TransactionsPage}
      />
    </Switch>
  )
}

import React from 'react'
import { Route, Switch } from 'react-router-dom'

import InboxPage from 'apps/Inbox/pages/InboxPage'
import * as routes from './constants'

const InboxAppRoutes = () => {
  return (
    <Switch>
      <Route exact path={routes.HOME_ROUTE} component={InboxPage} />
    </Switch>
  )
}

export default InboxAppRoutes

.react-app ._headerRow_1bsx7_1 {
  text-transform: none !important;
  font-weight: inherit !important;
  display: flex !important;
}
.react-app ._headerRow_1bsx7_1 div[aria-label="checkbox header"] {
  margin-top: calc(-1 * var(--space-tiny));
}

.react-app ._numericCell_1bsx7_10 {
  width: auto !important;
}

.react-app ._cell_1bsx7_14,
.react-app ._cell_1bsx7_14 *:not(button) {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.react-app ._cell_1bsx7_14 button,
.react-app ._cell_1bsx7_14 input,
.react-app ._cell_1bsx7_14 label,
.react-app ._cell_1bsx7_14 div[aria-label="checkbox cell"],
.react-app ._cell_1bsx7_14 div[aria-label="checkbox cell"] * {
  overflow: visible;
  white-space: normal;
  text-overflow: unset;
  box-sizing: content-box;
}

.react-app ._row_1bsx7_32 {
  z-index: 1;
}
.react-app ._row_1bsx7_32.ReactVirtualized__Table__row {
  align-items: flex-start;
}
.react-app ._row_1bsx7_32._clickable_1bsx7_38 {
  cursor: pointer;
}
.react-app ._row_1bsx7_32._clickable_1bsx7_38:hover {
  z-index: 0;
}

.react-app ._colHeaderItem_1bsx7_45 {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.react-app ._loadingRowCell_1bsx7_51,
.react-app ._emptyRowCell_1bsx7_52,
.react-app ._errorRowCell_1bsx7_53 {
  width: 100vw;
}
import * as React from 'react'
import { useTranslation } from 'react-i18next'

import moment from 'moment'

import {
  ArrowCell,
  ChartCell,
  ProductCell,
  ProductTypeCell,
  StartsOnCell
} from 'apps/Reports/components/ProductPerformanceCells'
import { CAPACITY_TARGETS_CHART } from 'apps/Reports/constants/productPerformanceCharts'
import useHasCourseJourneysSetting from 'apps/Reports/hooks/useHasCourseJourneysSetting'

function useProductPerformanceColumns({ chartType }) {
  const { t, i18n } = useTranslation()
  const hasCourseJourneys = useHasCourseJourneysSetting()

  const baseColumns = [
    {
      Header: t('Product'),
      accessor: 'parent_name',
      width: 360,
      disableSortBy: true,
      Cell: ProductCell
    },
    {
      Header: t('Starts on'),
      id: 'starts_on',
      accessor: (data) => {
        const value = data.starts_on
        return value
          ? moment(value).locale(i18n.language).format('D MMM YYYY')
          : null
      },
      width: 160,
      Cell: StartsOnCell
    }
  ]

  const baseChartColumn = {
    flexibleWidth: true,
    disableSortBy: true,
    Cell: ({ ...props }) => {
      return <ChartCell {...props} chartType={chartType} />
    }
  }

  const chartColumn =
    chartType === CAPACITY_TARGETS_CHART
      ? {
          Header: t('Current capacity'),
          id: 'current_capacity',
          accessor: () => t('Capacity chart'),
          ...baseChartColumn
        }
      : {
          Header: t('Capacity breakdown'),
          id: 'capacity_breakdown',
          accessor: () => t('Capacity breakdown chart'),
          ...baseChartColumn
        }

  const typeColumn = {
    Header: t('Type'),
    id: 'type',
    accessor: (data) => {
      const { type } = data || {}
      const isCourse = type === 'Institutions::Course'
      const label = isCourse ? t('Course') : t('Class')

      return label
    },
    width: 160,
    disableSortBy: true,
    Cell: ProductTypeCell
  }

  const arrowColumn = {
    id: 'product_details',
    width: 60,
    disableSortBy: true,
    Cell: ArrowCell
  }

  const columns = [
    ...baseColumns,
    !!hasCourseJourneys && typeColumn,
    chartColumn,
    !!hasCourseJourneys && arrowColumn
  ].filter((c) => !!c)

  return columns
}

export default useProductPerformanceColumns

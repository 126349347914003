import React from 'react'
import { Route, Switch } from 'react-router-dom'

import SubjectSelectionPage from 'apps/Applics/pages/SubjectSelectionPage'

const HOME_ROUTE = '/applications/:applicId/subjects'

const ApplicsAppRoutes = () => (
  <Switch>
    <Route exact path={HOME_ROUTE} component={SubjectSelectionPage} />
  </Switch>
)

export default ApplicsAppRoutes

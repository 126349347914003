import 'i18n'
import '@fullfabric/alma-mater/dist/index.css'
import '@fullfabric/schemable-forms/dist/index.css'
import '@fullfabric/schemable-forms/node_modules/@fullfabric/alma-mater/dist/index.css'

import React from 'react'

import { AppShell } from '@fullfabric/alma-mater'
import { AuthorizationProvider } from '@fullfabric/authorization-officer'

import FullScreenLoading from 'shared/components/FullScreenLoading'
import { AppDataProvider } from 'shared/contexts/AppData'

import LocalizedApp from './components/LocalizedApp'

import styles from './index.module.scss'

export default function App({ byoQueryClient }) {
  return (
    <AppShell className={styles.App}>
      <AuthorizationProvider
        byoQueryClient={byoQueryClient}
        loadingFallback={
          process.env.NODE_ENV === 'test' ? null : <FullScreenLoading />
        }
      >
        <AppDataProvider>
          <LocalizedApp />
        </AppDataProvider>
      </AuthorizationProvider>
    </AppShell>
  )
}

App.defaultProps = {
  byoQueryClient: false
}

.react-app ._loadingContainer_1ch2l_1 {
  padding-top: var(--space-more);
  padding-bottom: var(--space-less);
}

.react-app ._titleRow_1ch2l_6 {
  display: flex;
  align-items: baseline;
}

.react-app ._title_1ch2l_6 {
  margin-bottom: var(--space-small);
}

.react-app ._infoRow_1ch2l_15 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: var(--space-small);
}
.react-app ._infoRow_1ch2l_15 ._valueContainer_1ch2l_21 {
  display: inline-flex;
  align-items: center;
}

.react-app ._placeholderContainer_1ch2l_26 {
  position: relative;
  width: 100%;
}
.react-app ._placeholderContainer_1ch2l_26 > svg {
  width: 100%;
}

.react-app ._placeholderText_1ch2l_34 {
  position: absolute;
  top: 6px;
  left: 50%;
  transform: translateX(-50%);
  padding: var(--space-small) var(--space-default);
  border-radius: 17px;
  border: var(--border-base-lighter);
  background: var(--ff-bg-base-lightest);
}
import React from 'react'
import { useFormContext } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'

import PropTypes from 'prop-types'

import { Alert, Button, Field, Link } from '@fullfabric/alma-mater'

import { useAppData } from 'shared/contexts/AppData'

import authenticate from 'apps/Authentication/api/authenticate'
import FormTitle from 'apps/Authentication/components/FormTitle'
import SocialSection from 'apps/Authentication/components/SocialSection'
import {
  FORGOT_PASSWORD_ROUTE,
  SIGNUP_ROUTE
} from 'apps/Authentication/constants/routes'

import styles from './styles.module.scss'

const LoginForm = ({
  info: flashInfo,
  onFirstStepCompleted,
  onAuthCompleted
}) => {
  const { t } = useTranslation()

  const appData = useAppData()

  const [info, setInfo] = React.useState(() =>
    appData && appData.flash && appData.flash.trim() !== ''
      ? appData.flash.trim()
      : flashInfo
  )
  const [errorMessage, setErrorMessage] = React.useState(null)

  const { register, handleSubmit, formState, setFocus } = useFormContext()
  const { errors } = formState
  const { ref: emailInputRef, ...emailInputProps } = register('email', {
    required: t('This field is required')
  })
  const { ref: passwordInputRef, ...passwordInputProps } = register(
    'password',
    { required: t('This field is required') }
  )

  React.useEffect(() => {
    setFocus('email')
  }, [setFocus]) // should only run on initial renders of each state

  const authenticationMutation = useMutation(
    (formValues) => authenticate(formValues),
    {
      onSuccess: (response) => {
        if (response.url?.includes('#2fa')) {
          onFirstStepCompleted(response)
        } else {
          onAuthCompleted(response)
        }
      },
      onError: (error) => {
        setErrorMessage(error.data?.message)
      }
    }
  )

  const onSubmit = React.useCallback(
    async (formValues) => {
      await authenticationMutation.mutate(formValues)
    },
    [authenticationMutation]
  )

  return (
    <form className={styles.loginForm}>
      <FormTitle
        title={<Trans>Welcome</Trans>}
        subtitle={
          <>
            <Trans>Not registered?</Trans>
            <Link to={SIGNUP_ROUTE}>
              <Trans>Create an account</Trans>
            </Link>
          </>
        }
      />

      {errorMessage ? (
        <Alert type='alert' description={errorMessage} marginBottom='default' />
      ) : null}

      {info ? (
        <Alert
          type='warning'
          onClose={() => setInfo(null)}
          description={info}
          marginBottom='default'
        />
      ) : null}

      <Field
        {...emailInputProps}
        inputRef={emailInputRef}
        type='email'
        label={<Trans>E-mail address</Trans>}
        error={errors.email?.message}
      />
      <Field
        {...passwordInputProps}
        inputRef={passwordInputRef}
        type='password'
        label={<Trans>Password</Trans>}
        error={errors.password?.message}
      />

      <div className={styles.loginActions}>
        <Button
          primary
          type='submit'
          loading={
            authenticationMutation.isLoading || authenticationMutation.isSuccess
          }
          onClick={handleSubmit(onSubmit)}
        >
          <Trans>Log in</Trans>
        </Button>

        <Link className={styles.forgotPassword} to={FORGOT_PASSWORD_ROUTE}>
          <Trans>Forgot your password?</Trans>
        </Link>
      </div>

      <div style={{ marginBottom: 'var(--space-huge)' }}>
        <SocialSection
          separatorText={<Trans>Or use these alternatives</Trans>}
        />
      </div>
    </form>
  )
}

LoginForm.propTypes = {
  info: PropTypes.node
}

export default LoginForm

import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import moment from 'moment'

import { ArrowRightIcon as ArrowIcon, Link, Text } from '@fullfabric/alma-mater'

import { useLocale } from 'shared/contexts/Locale'

import styles from './styles.module.scss'

const Event = ({ data }) => {
  const { locale } = useLocale()
  const { t } = useTranslation()
  const dateMoment = moment(data.start).locale(locale)

  const dateDay = dateMoment.format('ll')
  const dateDayOfWeek = dateMoment.format('dddd')
  const dateTime = dateMoment.format('LT')

  const dateFullDay = `${dateDayOfWeek} ${dateDay}`

  return (
    <>
      <div data-testid='upcoming-event-data'>
        <Text type='h4' fontColor='text-base-darkest'>
          {data.name}
        </Text>
        <Text type='f5' fontColor='text-base-darker'>
          {t('{{dateFullDay}} at {{dateTime}}', { dateFullDay, dateTime })}{' '}
          {t('({{timezone}} time)', {
            timezone: data.timezone
          })}
        </Text>
      </div>
      <div className={styles.linkContainer}>
        <Link href={`/e/${data.id}`} className={styles.linkToEvent}>
          <Trans>Learn more</Trans>
          <ArrowIcon className={styles.linkIcon} />
        </Link>
      </div>
    </>
  )
}

export default Event

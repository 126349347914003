import React from 'react'

function ExpandCourseIcon({ expanded }) {
  if (expanded) {
    return (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='12'
        height='13'
        fill='none'
        viewBox='0 0 12 13'
      >
        <path
          fill='#6A6D77'
          fillRule='evenodd'
          d='M6 3a1 1 0 01.707.293l4 4a1 1 0 01-1.414 1.414L6 5.414 2.707 8.707a1 1 0 01-1.414-1.414l4-4A1 1 0 016 3z'
          clipRule='evenodd'
        ></path>
      </svg>
    )
  }

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='12'
      height='13'
      fill='none'
      viewBox='0 0 12 13'
    >
      <path
        fill='#6A6D77'
        fillRule='evenodd'
        d='M1.293 4.293a1 1 0 011.414 0L6 7.586l3.293-3.293a1 1 0 011.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z'
        clipRule='evenodd'
      ></path>
    </svg>
  )
}

export default ExpandCourseIcon

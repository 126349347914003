import React from 'react'
import { Trans } from 'react-i18next'

import { ErrorIcon, SuccessIcon, Text } from '@fullfabric/alma-mater'

import classNames from 'classnames'

const FeeInfoRow = ({ applic }) => {
  const paymentState = applic.payment?.state || 'not_paid'
  const feeNotPaid = paymentState === 'not_paid'

  return (
    <div className={classNames('marginTop-default', 'paddingLeft-more')}>
      <Text fontColor={feeNotPaid ? 'text-alert' : 'text-success'}>
        {feeNotPaid ? (
          <ErrorIcon color='text-alert' />
        ) : (
          <SuccessIcon color='text-success' />
        )}{' '}
        {paymentState === 'not_paid' && <Trans>Fee not paid</Trans>}
        {paymentState === 'waived' && <Trans>Fee has been waived</Trans>}
        {paymentState === 'paid' && <Trans>Fee paid</Trans>}
      </Text>
    </div>
  )
}

export default FeeInfoRow

import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

import { Button, Dropdown, PointerDownIcon } from '@fullfabric/alma-mater'

import getLifecyclesAsOptions from 'shared/api/getLifecyclesAsOptions'

import { useSelectedLifecycles } from '../FilterContext'

const LifecyclesSelector = () => {
  const { t } = useTranslation()
  const { data: allLifecycles, isLoading } = useQuery(
    'getLifecycles',
    getLifecyclesAsOptions
  )
  const { selectedLifecycles, onSelectedLifecyclesChange } =
    useSelectedLifecycles()

  const [currentSelection, setCurrentSelection] =
    React.useState(selectedLifecycles)

  const onCurrentSelectionChange = React.useCallback(
    (lifecycle, checked) => {
      setCurrentSelection(
        checked
          ? [...currentSelection, lifecycle]
          : currentSelection.filter((item) => item !== lifecycle)
      )
    },
    [currentSelection]
  )

  const [search, setSearch] = React.useState('')

  const applySelection = React.useCallback(() => {
    onSelectedLifecyclesChange(currentSelection)
    setSearch('')
  }, [onSelectedLifecyclesChange, currentSelection])

  const filteredLifecycles = search
    ? allLifecycles?.filter((l) => l.label.toLowerCase().includes(search))
    : allLifecycles

  return (
    <Dropdown alignEnd>
      <Dropdown.Toggle>
        <Button size='small'>
          {t('{{count}} steps', { count: selectedLifecycles.length })}
          <PointerDownIcon color='text-base-darker' />
        </Button>
      </Dropdown.Toggle>
      <Dropdown.Menu
        aria-label='lifecycle filter'
        onClickOutside={applySelection}
      >
        <Dropdown.Menu.Search onSearchChange={setSearch} />

        {isLoading && <Dropdown.Menu.OptionsLoading />}

        {filteredLifecycles?.map((lifecycle) => {
          return (
            <Dropdown.Menu.CheckableOption
              id={lifecycle.value}
              key={lifecycle.value}
              checked={currentSelection.includes(lifecycle.value)}
              onChange={onCurrentSelectionChange}
            >
              {lifecycle.label}
            </Dropdown.Menu.CheckableOption>
          )
        })}
        {allLifecycles && (
          <Dropdown.Menu.Button onClick={applySelection}>
            <Trans>Apply</Trans>
          </Dropdown.Menu.Button>
        )}
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default LifecyclesSelector

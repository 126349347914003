import React from 'react'

import PropTypes from 'prop-types'

import InboxApplicCard from '../InboxApplicCard'
import InboxEmailCard from '../InboxEmailCard'
import InboxEventCard from '../InboxEventCard'
import InboxFormCard from '../InboxFormCard'
import InboxTaskCard from '../InboxTaskCard'

const COMPONENT_MAPPING = {
  'Inbox::Card::Email': InboxEmailCard,
  'Inbox::Card::Task': InboxTaskCard,
  'Inbox::Card::Event': InboxEventCard,
  'Inbox::Card::Applic': InboxApplicCard,
  'Inbox::Card::Form': InboxFormCard
}

export default function InboxCard({ card }) {
  const CardComponent = COMPONENT_MAPPING[card.type]
  return <CardComponent card={card} />
}

InboxCard.propTypes = {
  card: PropTypes.object.isRequired
}

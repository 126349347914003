import React from 'react'
import { useParams } from 'react-router'

import SubjectDetailsPageComponent from 'apps/Subjects/components/SubjectDetailsPage'

const SubjectDetailsPage = () => {
  const { id: subjectId } = useParams()

  return <SubjectDetailsPageComponent subjectId={subjectId} />
}

export default SubjectDetailsPage

import { utils } from '@fullfabric/public-api'

const getClassOf = async (_key, classId) => {
  const response = await fetch(`/api/classes/${classId}?skip_stats=true`, {
    credentials: 'same-origin'
  })

  return await utils.checkResponse(response)
}

export default getClassOf

import React from 'react'

import { startCase } from 'lodash'

import { Table } from '@fullfabric/alma-mater'

import { useTeams } from 'shared/contexts/TeamsAndStaffContext'

import styles from './styles.module.scss'

function CreatedByCell({ value, row, data }) {
  const rowData = data[row.index]
  const formattedCreatorName = startCase(value)

  const teams = useTeams()
  const teamId = (rowData.teams_ids || [])[0]
  const team = teams?.find((t) => t.id === teamId)

  if (!team?.name) {
    return formattedCreatorName
  }

  return (
    <Table.Data
      tag='div'
      complementaryInfo={team?.name}
      className={styles.customCell}
    >
      {formattedCreatorName}
    </Table.Data>
  )
}

export default CreatedByCell

.react-app ._outerContainer_1m6w3_1 {
  padding: var(--space-big) 0 !important;
  background-color: var(--ff-bg-base-lighter);
  min-height: 100vh;
  display: flex;
  width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
}

.react-app ._container_1m6w3_11 {
  width: 100%;
}

.react-app ._contentColumnSpacing_1m6w3_15 {
  padding-left: var(--space-big) !important;
}

.react-app ._contentColumnMobileSpacing_1m6w3_19 {
  padding: 0 var(--space-more) !important;
}

.react-app ._contentColumn_1m6w3_15 {
  position: relative;
  z-index: 0;
  height: 100%;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}

.react-app ._headerContainer_1m6w3_32 {
  position: relative;
}

.react-app ._contentPositioner_1m6w3_36 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.react-app ._previewColumn_1m6w3_44 {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 0;
}

.react-app ._backgroundLayer_1m6w3_52 {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 0;
}

.react-app ._bgImageContainer_1m6w3_61 {
  position: fixed;
  height: 100vh;
  right: 0;
  top: 0;
}

.react-app ._bgImage_1m6w3_61 {
  height: 100vh;
  width: auto;
}
import React from 'react'
import { Trans } from 'react-i18next'

import { Button } from '@fullfabric/alma-mater'

import CourseCreateFormDates from '../CourseCreateFormDates'

import styles from './styles.module.scss'

const CourseEditFormDates = ({
  data,
  onChange,
  inProgress,
  onSaveClick,
  onCancelClick
}) => {
  return (
    <>
      <CourseCreateFormDates data={data} onChange={onChange} />

      <div className='marginTop-default'>
        <Button primary size='small' loading={inProgress} onClick={onSaveClick}>
          <Trans>Save changes</Trans>
        </Button>

        <Button
          size='small'
          onClick={onCancelClick}
          className={styles.cancelButton}
        >
          <Trans>Cancel</Trans>
        </Button>
      </div>
    </>
  )
}

export default CourseEditFormDates

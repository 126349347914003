import moment from 'moment'

export const formatDueDate = (date, locale) => {
  return moment(date).locale(locale).format('ll')
}

export const timeToDueDate = (date, locale, t) => {
  return t(`Due in {{timeToDueDate}}`, {
    timeToDueDate: moment(date).locale(locale).toNow(true)
  })
}

export const timeFromDueDate = (date, locale, t) => {
  const daysSinceDueDate = moment().diff(date, 'days')

  if (daysSinceDueDate === 0) {
    return t('Due today')
  }

  const timeFromDueDate = moment(date).locale(locale).fromNow(true)

  return t(`{{timeFromDueDate}} overdue`, {
    timeFromDueDate: `${timeFromDueDate
      .charAt(0)
      .toUpperCase()}${timeFromDueDate.slice(1)}`
  })
}

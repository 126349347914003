import React from 'react'

import {
  BAR_Y,
  MARKER_BAR_COLOR,
  MARKER_HEIGHT,
  MARKER_LABEL_COLOR,
  MARKER_VALUE_COLOR,
  TEXT_ANCHOR_MARKER_THRESHOLD,
  TEXT_MARGIN
} from 'apps/Reports/constants/productPerformanceCharts'

const Marker = ({ position, label, value }) => {
  const positionWithMarginRight = position - TEXT_MARGIN
  const positionWithMarginLeft = position + TEXT_MARGIN

  return (
    <>
      <rect
        y={BAR_Y}
        x={position}
        width='1'
        height={MARKER_HEIGHT}
        fill={MARKER_BAR_COLOR}
      />
      <text
        textAnchor={
          positionWithMarginRight > TEXT_ANCHOR_MARKER_THRESHOLD
            ? 'end'
            : 'start'
        }
        x={
          positionWithMarginRight > TEXT_ANCHOR_MARKER_THRESHOLD
            ? positionWithMarginRight
            : positionWithMarginLeft
        }
        y={BAR_Y + MARKER_HEIGHT - TEXT_MARGIN}
      >
        <tspan fill={MARKER_LABEL_COLOR}>{label}: </tspan>
        <tspan fill={MARKER_VALUE_COLOR} fontWeight='600'>
          {value}
        </tspan>
      </text>
    </>
  )
}

export default Marker

import React from 'react'
import { Trans } from 'react-i18next'

import { AnnotatedLayout } from '@fullfabric/alma-mater'

import SubjectPathsSectionDisplay from './Display'
import SubjectPathsSectionForm from './Form'

const SubjectPathsSection = ({ beingEdited }) => (
  <AnnotatedLayout.Section
    title={<Trans>Paths & course types</Trans>}
    description={
      <Trans>
        Define which paths the subject will appear in and select the course type
        of the subject for each of those paths.
      </Trans>
    }
  >
    {beingEdited ? <SubjectPathsSectionForm /> : <SubjectPathsSectionDisplay />}
  </AnnotatedLayout.Section>
)

export default SubjectPathsSection

import React from 'react'
import { Trans } from 'react-i18next'

import { AnnotatedLayout } from '@fullfabric/alma-mater'

import styles from './styles.module.scss'

const CourseSectionBasic = ({ children }) => {
  return (
    <section aria-labelledby='basic-section-title'>
      <AnnotatedLayout.Section
        title={
          <span id='basic-section-title'>
            <Trans>Basic</Trans>
          </span>
        }
        description={
          <Trans>
            A course represents the studies of a particular subject over a
            specific time period.
          </Trans>
        }
        contentClassName={styles.basicSection}
      >
        {children}
      </AnnotatedLayout.Section>
    </section>
  )
}

export default CourseSectionBasic

import {
  ApplicationTemplatesWidget,
  MyApplicationsWidget,
  MyRegistrationsWidget,
  RegistrationTemplatesWidget
} from './applications'
import FormWidget from './FormWidget'
import OffersWidget from './OffersWidget'
import TextWidget from './text'
import UpcomingEventsWidget from './upcoming-events'

const widgetsByType = {
  'Content::Widgets::Applications': {
    component: ApplicationTemplatesWidget
  },
  'Content::Widgets::Applics': {
    component: MyApplicationsWidget
  },
  'Content::Widgets::Text': { component: TextWidget },
  'Content::Widgets::Registrations': {
    component: RegistrationTemplatesWidget
  },
  'Content::Widgets::MyRegistrations': {
    component: MyRegistrationsWidget
  },
  'Content::Widgets::Events': {
    component: UpcomingEventsWidget
  },
  'Content::Widgets::Offers': {
    component: OffersWidget,
    hasOwnContainer: true
  },
  'Content::Widgets::Form': { component: FormWidget }
}

export default widgetsByType

import React from 'react'
import { Trans } from 'react-i18next'
import { queryCache, useMutation } from 'react-query'
import { useHistory } from 'react-router'

import { Button } from '@fullfabric/alma-mater'

import addSubject from 'apps/StudyPlans/api/addSubject'
import createSubject from 'apps/StudyPlans/api/createSubject'
import { EDIT_SUBJECT_ROUTE } from 'apps/StudyPlans/components/AppRoutes/constants'
import {
  useClassOf,
  useSetStudyPlan,
  useStudyPlan
} from 'apps/StudyPlans/contexts/StudyPlanData'
import {
  useSetSubjectEditionErrors,
  useSubjectEditionData
} from 'apps/StudyPlans/contexts/SubjectEditionData'
import formatStudyPlanCourseData from 'apps/StudyPlans/utils/formatStudyPlanCourseData'
import validateNewSubjectData from './validateNewSubjectData'

const AddSubjectButton = () => {
  const classOf = useClassOf()
  const studyPlan = useStudyPlan()
  const setStudyPlan = useSetStudyPlan()
  const newSubjectData = useSubjectEditionData()
  const setSubjectEditionErrors = useSetSubjectEditionErrors()
  const history = useHistory()

  const { mutate: addSubjectMutation, isLoading } = useMutation(
    async ({ subjectData, studyPlanId }) => {
      if (subjectData.createSubjectBeforeAdding) {
        const createdSubject = await createSubject({
          code: subjectData.subject_code,
          name: subjectData.subject_name
        })

        const allSubjectsQuery = queryCache.getQueryData('subjects')
        queryCache.setQueryData('subjects', [
          ...allSubjectsQuery,
          createdSubject
        ])

        subjectData.course_id = createdSubject.id
      }

      const subject = await addSubject(studyPlanId, subjectData)
      return subject
    },
    {
      onSuccess: (subject) => {
        setStudyPlan((currentStudyPlan) => ({
          ...currentStudyPlan,
          courses: [
            ...currentStudyPlan.courses,
            { ...subject, just_added: true }
          ]
        }))

        history.push(
          EDIT_SUBJECT_ROUTE.replace(':classId', classOf.id).replace(
            ':subjectId',
            subject.id
          )
        )
      },
      onError: (error) => {
        const isDuplicateSubject = (
          error.data?.['affairs/study_plan/template']?.courses || []
        ).some((error) => /duplicated/.test(error))

        if (error.status === 406 && isDuplicateSubject) {
          return setSubjectEditionErrors((existingErrors) => ({
            ...existingErrors,
            course_id: 'duplicate'
          }))
        }

        // Not sure how to handle, just log to console:
        console.error('Error creating subject', error)
      }
    }
  )

  const onClickAddSubject = React.useCallback(() => {
    const subjectData = formatStudyPlanCourseData(newSubjectData)

    const errors = validateNewSubjectData(subjectData, studyPlan)
    if (errors) {
      setSubjectEditionErrors((existingErrors) => ({
        ...existingErrors,
        ...errors
      }))

      return
    }

    addSubjectMutation({ studyPlanId: studyPlan.id, subjectData })
  }, [newSubjectData, setSubjectEditionErrors, studyPlan, addSubjectMutation])

  return (
    <Button
      primary
      loading={isLoading}
      marginBottom='more'
      onClick={onClickAddSubject}
    >
      <Trans>Add subject</Trans>
    </Button>
  )
}

export default AddSubjectButton

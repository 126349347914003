import React from 'react'
import { useTranslation } from 'react-i18next'

import { ArrowLeftIcon, PageHead } from '@fullfabric/alma-mater'

import TitleAndSubtitle from 'apps/Reports/components/TitleWithSubtitle'

function Head({ classData }) {
  const { t } = useTranslation()

  return (
    <PageHead
      noBorder
      breadcrumbs={[
        {
          label: t('Back to class'),
          icon: ArrowLeftIcon,
          href: classData ? `/classes/${classData.id}` : undefined,
          target: '_parent'
        }
      ]}
      title={
        <TitleAndSubtitle
          isLoading={!classData}
          subtitle={`${classData?.programme_name} ${classData?.name}`}
          title={t('Product overview')}
        />
      }
    />
  )
}

export default Head

import React from 'react'

import { BoxShadow } from '@fullfabric/alma-mater'

import ViewerContainer from '../ViewerContainer'

const ImageViewer = ({ viewOption, uploadIndex, scale }) => {
  const [loading, setLoading] = React.useState(true)
  const [error, setError] = React.useState(false)

  return (
    <ViewerContainer
      viewOption={viewOption}
      uploadIndex={uploadIndex}
      scale={scale}
      loading={loading}
      error={error}
      style={{ transformOrigin: '0px center' }}
    >
      <BoxShadow
        type='2'
        rounded
        style={{ display: 'flex', overflow: 'hidden' }}
      >
        <img
          src={`/${viewOption?.uploads[uploadIndex]?.path}`}
          // src="https://download.samplelib.com/png/sample-boat-400x300.png"
          alt={viewOption.label}
          onLoad={() => setLoading(false)}
          onError={() => {
            setLoading(false)
            setError(true)
          }}
        />
      </BoxShadow>
    </ViewerContainer>
  )
}

export default ImageViewer

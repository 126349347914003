import React from 'react'

import BaseChartCell from 'apps/Reports/components/ProductPerformanceBaseChartCell'
import useChartWidth from 'apps/Reports/components/ProductPerformanceBaseChartCell/useChartWidth'
import {
  ABOVE_TARGET_BAR_COLOR,
  BAR_HEIGHT,
  BAR_Y,
  BELOW_MIN_BAR_COLOR,
  BELOW_TARGET_BAR_COLOR,
  BORDER_RADIUS,
  PLACEHOLDER_BAR_COLOR
} from 'apps/Reports/constants/productPerformanceCharts'
import CurrentValueDisplay from './CurrentValueDisplay'
import Marker from './Marker'
import Placeholder from './Placeholder'

import styles from './styles.module.scss'

const CapacityChart = ({ product }) => {
  const {
    minimum_capacity: min,
    current_capacity: current,
    target_capacity: target,
    maximum_capacity: max
  } = product
  const hasDefinedAllCapacities = max > 0 && min > 0 && target > 0
  const { chartWidth, measurerRef } = useChartWidth()

  if (!hasDefinedAllCapacities) {
    return (
      <div ref={measurerRef} className={styles.placeholderContainer}>
        <Placeholder width={chartWidth} />
      </div>
    )
  }

  const minCapacityBarLength = (min / max) * chartWidth
  const targetCapacityBarLength = (target / max) * chartWidth

  const currentCapacityBarWidth =
    current >= 0
      ? current > max
        ? chartWidth
        : (current / max) * chartWidth
      : 0

  const currentCapacityBarColor =
    current < min
      ? BELOW_MIN_BAR_COLOR
      : current < target
      ? BELOW_TARGET_BAR_COLOR
      : ABOVE_TARGET_BAR_COLOR

  return (
    <BaseChartCell measurerRef={measurerRef}>
      <CurrentValueDisplay
        current={current}
        min={min}
        max={max}
        target={target}
        currentCapacityBarWidth={currentCapacityBarWidth}
      />

      <rect
        data-testid='max-capacity-rect'
        x='0'
        y={BAR_Y}
        rx={BORDER_RADIUS}
        ry={BORDER_RADIUS}
        width={chartWidth}
        height={BAR_HEIGHT}
        fill={PLACEHOLDER_BAR_COLOR}
      />
      {current >= 0 ? (
        <rect
          data-testid='current-capacity-rect'
          x='0'
          y={BAR_Y}
          rx={BORDER_RADIUS}
          ry={BORDER_RADIUS}
          width={
            currentCapacityBarWidth <= chartWidth
              ? currentCapacityBarWidth
              : chartWidth
          }
          height={BAR_HEIGHT}
          fill={currentCapacityBarColor}
        />
      ) : null}
      <Marker position={minCapacityBarLength} label='m' value={min} />
      <Marker position={targetCapacityBarLength} label='T' value={target} />
      <Marker position={chartWidth} label='M' value={max} />
    </BaseChartCell>
  )
}

export default CapacityChart

.react-app ._pageHead_1d8s6_1 {
  width: 100%;
}

.react-app ._footer_1d8s6_5 {
  display: flex;
  justify-content: flex-end;
  margin: 0;
  padding-bottom: var(--space-more);
}

.react-app ._layoutContainer_1d8s6_12 {
  overflow: auto;
}
.react-app ._layoutContainer_1d8s6_12 ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.react-app ._layoutContainer_1d8s6_12 ul li {
  padding: 0;
  margin: 0;
}

.react-app ._createAnotherButton_1d8s6_25 {
  margin-left: var(--space-small);
}
.react-app ._container_k6h84_1 {
  max-width: 850px;
}
.react-app ._container_k6h84_1 ._header_k6h84_4 {
  margin-top: var(--space-default);
}
.react-app ._container_k6h84_1 ._customTabName_k6h84_7 {
  margin-top: var(--space-less);
  margin-bottom: var(--space-tiny);
}
.react-app ._container_k6h84_1 ._applicPaymentCard_k6h84_11 {
  margin-top: var(--space-more);
}
.react-app ._container_8d53q_1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--space-less) 0;
  border-bottom: var(--border-base-lighter);
}

.react-app ._infoContainer_8d53q_9 {
  max-width: 450px;
}

.react-app ._toggleContainer_8d53q_13 {
  margin-left: var(--space-default);
}

.react-app ._titleContainer_8d53q_17 {
  display: flex;
  align-items: center;
  margin-bottom: var(--space-tiny);
}
/**
 * Injects a form element into the DOM with the given URL and hidden inputs,
 * submits it, and then removes it.
 *
 * This can be used to trigger a POST request with body via JS.
 *
 * @param {String} url Target URL.
 * @param {Array[String[]]} inputs Two-element arrays of [name, value].
 * @param {Boolean} opts.newWindow Whether to POST in a new window.
 */
export function inlinePost(url, inputs, { newWindow = false } = {}) {
  const form = document.createElement('form')
  form.method = 'POST'
  form.action = url
  if (newWindow) form.target = '_blank'

  const input = document.createElement('input')
  input.type = 'hidden'
  input.name = 'authenticity_token'
  input.value = document.querySelector("meta[name='csrf-token']")?.content
  form.appendChild(input)

  for (const [name, value] of inputs) {
    const input = document.createElement('input')
    input.type = 'hidden'
    input.name = name
    input.value = value
    form.appendChild(input)
  }

  document.body.appendChild(form)
  form.submit()
  document.body.removeChild(form)
}

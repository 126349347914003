import { utils } from '@fullfabric/public-api'

const addSubject = async (studyPlanId, data) => {
  const response = await fetch(
    `/affairs/api/study_plan/${studyPlanId}/courses`,
    {
      credentials: 'same-origin',
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ course: { ...data, study_plan_id: studyPlanId } })
    }
  )

  return await utils.checkResponse(response)
}

export default addSubject

const getWithdrawnRulesFromValue = (withdrawn) => {
  if (typeof withdrawn === 'undefined') {
    return {}
  }

  let rules = {
    rules: [{ type: 'Segments::Rules::Context::HasNotWithdrawn' }]
  }

  if (withdrawn === true) {
    rules = {
      rules: [{ type: 'Segments::Rules::Context::HasWithdrawn' }]
    }
  }

  return rules
}

export default getWithdrawnRulesFromValue

import { connect } from 'react-redux'

import moment from 'moment'

import actionTypes from 'apps/Courses/actions/types'
import CourseEditDisplayDates from 'apps/Courses/components/CourseEditDisplayDates'

const mapStateToProps = ({ editCourse: { data } }) => ({ data })

const getDateStringValue = (date) =>
  date && moment(date).isValid() ? moment(date).utc().format() : ''

const mapDispatchToProps = (dispatch) => ({
  onEditClick: (course) => {
    dispatch({
      type: actionTypes.editDatesSectionData,
      payload: {
        opens_on: getDateStringValue(course.opens_on),
        closes_on: getDateStringValue(course.closes_on),
        starts_on: getDateStringValue(course.starts_on),
        ends_on: getDateStringValue(course.ends_on)
      }
    })
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CourseEditDisplayDates)

import React from 'react'

const MyApplicationsIcon = () => (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8 0C6.89543 0 6 0.895431 6 2V3.42857C6 3.74416 6.25584 4 6.57143 4H9.42857C9.74416 4 10 3.74416 10 3.42857V2C10 0.895431 9.10457 0 8 0ZM8 1C7.44772 1 7 1.44768 7 1.99993V1.9986C6.99965 2.26403 7.10485 2.5187 7.29242 2.70652C7.48 2.89433 7.73455 2.99986 8 2.99986C8.55228 2.99986 9 2.55218 9 1.99993C9 1.44768 8.55228 1 8 1Z'
      fill='black'
    />
    <rect x='5' y='6' width='6' height='1' rx='0.5' fill='#18191F' />
    <rect x='5' y='8' width='6' height='1' rx='0.5' fill='#18191F' />
    <rect x='5' y='10' width='4' height='1' rx='0.5' fill='#18191F' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M1 3C1 1.89543 1.89543 1 3 1H5V3H4C3.44772 3 3 3.44772 3 4V12C3 13.1046 3.89543 14 5 14H11C12.1046 14 13 13.1046 13 12V4C13 3.44772 12.5523 3 12 3H11V1H13C14.1046 1 15 1.89543 15 3V13C15 14.6569 13.6569 16 12 16H4C2.34315 16 1 14.6569 1 13V3Z'
      fill='#18191F'
    />
  </svg>
)

export default MyApplicationsIcon

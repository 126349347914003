import React from 'react'
import { Trans } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'

import { Button } from '@fullfabric/alma-mater'

import { useMergeData } from 'apps/MergeProfiles/contexts/MergeData'

const NextButton = () => {
  const history = useHistory()
  const { id: sourceProfileId } = useParams()
  const { mergeData, updateMergeError } = useMergeData()
  const onProfilesSelected = React.useCallback(() => {
    if (!mergeData.sourceProfileId) {
      updateMergeError({ sourceProfile: 'empty' })
      return
    }

    if (!mergeData.sourceProfile?.can_be_merged_into_and_deleted) {
      return
    }

    history.push(`/profiles/${sourceProfileId}/merge/info`)
  }, [
    history,
    mergeData.sourceProfileId,
    mergeData.sourceProfile,
    sourceProfileId,
    updateMergeError
  ])

  return (
    <Button
      primary
      onClick={onProfilesSelected}
      disabled={
        mergeData.sourceProfile &&
        !mergeData.sourceProfile.can_be_merged_into_and_deleted
      }
    >
      <Trans>Next</Trans>
    </Button>
  )
}

export default NextButton

const formatNationalityDataseries = (
  nationalitiesInIntakes,
  intakesData,
  mainIntakeIndex = 0
) => {
  const nationalitiesInMainIntake = nationalitiesInIntakes[mainIntakeIndex]

  const topNationalities = Object.keys(nationalitiesInMainIntake)
    .filter((key) => key !== 'dimension')
    .sort((a, b) => nationalitiesInMainIntake[b] - nationalitiesInMainIntake[a])
    .slice(0, 10)

  return topNationalities.map((nationality) => {
    const valuesByIntake = intakesData.reduce((hashed, intake, intakeIndex) => {
      return {
        ...hashed,
        [intake.name]: nationalitiesInIntakes[intakeIndex][nationality] || 0
      }
    }, {})

    return { nationality, ...valuesByIntake }
  })
}

export default formatNationalityDataseries

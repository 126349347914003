import React from 'react'
import { useTranslation } from 'react-i18next'

import {
  ArrowLeftIcon,
  Breadcrumbs,
  CloseIcon,
  IconButton,
  Text
} from '@fullfabric/alma-mater'

import {
  useGoBackOnPaymentsDrawer,
  usePaymentsDrawerHistorySize
} from 'apps/ContentPages/contexts/payments-drawer'

import classNames from 'classnames'
import styles from './styles.module.scss'

const ContainerPanel = ({ title, children }) => {
  const { t } = useTranslation()
  const drawerHistorySize = usePaymentsDrawerHistorySize()
  const handleClose = useGoBackOnPaymentsDrawer()
  const isOnlyPage = drawerHistorySize === 1

  return (
    <div className={styles.containerWrapper}>
      <div
        data-testid='payments-drawer-header'
        className={classNames(
          styles.container,
          !isOnlyPage && styles.verticalFlow
        )}
      >
        {!isOnlyPage && (
          <Breadcrumbs
            className={styles.backLink}
            breadcrumbs={[
              {
                icon: ArrowLeftIcon,
                label: t('Back to list'),
                onClick: handleClose
              }
            ]}
          />
        )}
        <Text type='h2' fontColor='text-primary-darkest'>
          {title}
        </Text>
        {isOnlyPage && (
          <IconButton plain smaller onClick={handleClose}>
            <CloseIcon title='Close drawer' />
          </IconButton>
        )}
      </div>
      {children}
    </div>
  )
}

export default ContainerPanel

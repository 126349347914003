import React from 'react'

export const CookiePromptRefContext = React.createContext({})

export const CookiePromptRefProvider = ({ value, children }) => {
  return (
    <CookiePromptRefContext.Provider value={value}>
      {children}
    </CookiePromptRefContext.Provider>
  )
}

export const useCookiePromptRef = () => React.useContext(CookiePromptRefContext)

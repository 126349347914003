/* eslint-disable no-case-declarations */

import { LOCATION_CHANGE } from 'connected-react-router'
import { combineReducers } from 'redux'

import actionTypes from '../../../actions/types'

const data = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.editSchemaSectionData:
      return action.payload.data
    case actionTypes.cancelEditSchemaSectionData:
    case LOCATION_CHANGE:
      return {}
    case actionTypes.editCourseSchemaSectionDataChanged:
      return { ...state, ...action.payload }
    default:
      return state
  }
}

const schema = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.editSchemaSectionData:
      return action.payload.schema
    case actionTypes.cancelEditSchemaSectionData:
    case LOCATION_CHANGE:
      return {}
    case actionTypes.editCourseSchemaSectionDataChanged:
      return { ...state, ...action.payload }
    default:
      return state
  }
}

const isEditing = (state = false, action) => {
  switch (action.type) {
    case actionTypes.editSchemaSectionData:
      return true
    case actionTypes.cancelEditSchemaSectionData:
    case actionTypes.updateCourseSchemaSection.success:
    case LOCATION_CHANGE:
      return false
    default:
      return state
  }
}

const inProgress = (state = false, action) => {
  switch (action.type) {
    case actionTypes.updateCourseSchemaSection.start:
      return true
    case actionTypes.updateCourseSchemaSection.success:
    case actionTypes.updateCourseSchemaSection.error:
      return false
    default:
      return state
  }
}

const errors = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.updateCourseSchemaSection.error:
      if (action.payload && action.payload.validationErrors)
        return { ...state, ...action.payload.validationErrors }
      else return { ...state, ...action.payload }
    case actionTypes.editCourseSchemaSectionDataChanged:
      const key = Object.keys(action.payload)[0]
      const { [key]: keyToRemove, ...newState } = state
      return newState
    case LOCATION_CHANGE:
    case actionTypes.updateCourseSchemaSection.success:
    case actionTypes.cancelEditSchemaSectionData:
      return {}
    default:
      return state
  }
}

export default combineReducers({
  data,
  schema,
  isEditing,
  inProgress,
  errors
})

import React from 'react'

import { Col, Container, Row, Text } from '@fullfabric/alma-mater'

import Sidebar from 'apps/Imports/components/page-container/components/sidebar'
import CloseButton from './components/close-button'

import classNames from 'classnames'
import styles from './styles.module.scss'

const PageContainer = ({
  title,
  subtitle,
  children,
  contentColumns = 7,
  columnOffset = 1,
  className,
  sidebarTitle
}) => {
  return (
    <div className={styles.appContainer}>
      <Sidebar title={sidebarTitle} />

      <div
        className={classNames(styles.contentRegionContainer, 'shadow-cover')}
      >
        <Container fluid className={styles.container}>
          <Row className={styles.scrollContainer}>
            <Col xs={columnOffset} />

            <Col xs={contentColumns}>
              <div className={classNames(styles.contentContainer, className)}>
                {title ? (
                  <Text
                    type='h2'
                    role='heading'
                    aria-level='2'
                    fontColor='text-base-darkest'
                    marginBottom={subtitle ? null : 'more'}
                  >
                    {title}{' '}
                  </Text>
                ) : null}
                {subtitle ? (
                  <Text marginBottom='more' marginTop='tiny' type='f4'>
                    {subtitle}
                  </Text>
                ) : null}

                {children}
              </div>
            </Col>

            <Col xs={11 - (contentColumns + columnOffset)} />

            <Col xs={1}>
              <CloseButton />
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
}

export default PageContainer

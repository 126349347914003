import { utils } from '@fullfabric/public-api'

import sortStrings from 'shared/utils/sortStrings'

const getThemes = async () => {
  const response = await fetch('/api/institutions/themes', {
    credentials: 'same-origin'
  })

  const result = await utils.checkResponse(response)

  return [...result].sort((pA, pB) => sortStrings(pA.name, pB.name))
}

export default getThemes

import React from 'react'
import { Trans } from 'react-i18next'

import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { Loading } from '@fullfabric/alma-mater'

import { useLocale } from 'shared/contexts/Locale'

import { usePaymentsDrawerData } from 'apps/ContentPages/contexts/payments-drawer'
import ContainerPanel from '../container-panel'
import PaymentForm from './payment-form'
import useStripeClientKey from './use-stripe-client-key'

import styles from './styles.module.scss'

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(
  window.SERVER_DATA?._settings?.hash?.integrations?.payments?.stripe
    ?.publishable_key
)

const StripePayment = () => {
  const { fee } = usePaymentsDrawerData()
  const stripeClientKey = useStripeClientKey(fee)
  const { locale } = useLocale()

  return (
    <ContainerPanel title={<Trans>Stripe payment details</Trans>}>
      {stripeClientKey ? (
        <Elements
          stripe={stripePromise}
          options={{
            // passing the client secret obtained from the server
            clientSecret: stripeClientKey,
            locale
          }}
        >
          <PaymentForm />
        </Elements>
      ) : (
        <div className={styles.loading}>
          <Loading />
        </div>
      )}
    </ContainerPanel>
  )
}

export default StripePayment

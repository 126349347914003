import React from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'

import {
  Alert,
  Button,
  ModalButtons,
  ModalTitle,
  Text
} from '@fullfabric/alma-mater'

import submitEvaluation from 'apps/Evaluations/api/submitEvaluation'
import {
  SUBMISSION_ACTIONS,
  SUBMISSION_STATE,
  useDispatchSubmissionUpdate,
  useEvaluationForm,
  useSetEvaluationForm,
  useSubmissionState
} from 'apps/Evaluations/context/EvaluationFormData'

const ConfirmationContent = () => {
  const { t } = useTranslation()
  const submissionState = useSubmissionState()

  return (
    <>
      {submissionState.modal === SUBMISSION_STATE.MODAL.SUBMISSION_ERROR ? (
        <Alert
          type='warning'
          title='Submit error'
          description='Something went wrong on our side, please try again.'
          marginTop='default'
        />
      ) : null}
      <Text fontColor='text-base-darkest'>
        {t('Are you sure you want to submit this evaluation?')}
      </Text>
    </>
  )
}

const Header = () => {
  const { t } = useTranslation()
  const dispatchSubmissionUpdate = useDispatchSubmissionUpdate()

  return (
    <ModalTitle
      title={<span id='submission-modal-title'>{t('Submit evaluation')}</span>}
      onClose={() =>
        dispatchSubmissionUpdate({ type: SUBMISSION_ACTIONS.CLOSE_MODAL })
      }
    />
  )
}

const Footer = () => {
  const { t } = useTranslation()
  const dispatchSubmissionUpdate = useDispatchSubmissionUpdate()
  const { setSubmittedEvaluationData } = useSetEvaluationForm()
  const { evaluationFormData } = useEvaluationForm()

  const submitEvaluationMutation = useMutation(
    ({ formdataId }) => submitEvaluation(formdataId),
    {
      onSuccess: (submittedEvaluation) => {
        setSubmittedEvaluationData(submittedEvaluation)
        dispatchSubmissionUpdate({
          type: SUBMISSION_ACTIONS.SUBMISSION_SUCCESS
        })
      },

      onError: () => {
        dispatchSubmissionUpdate({ type: SUBMISSION_ACTIONS.SUBMISSION_ERROR })
      }
    }
  )

  const handleSubmitEvaluation = async () => {
    dispatchSubmissionUpdate({ type: SUBMISSION_ACTIONS.SUBMISSION_START })
    submitEvaluationMutation.mutate({ formdataId: evaluationFormData.id })
  }

  return (
    <ModalButtons>
      <Button
        onClick={() =>
          dispatchSubmissionUpdate({ type: SUBMISSION_ACTIONS.CLOSE_MODAL })
        }
      >
        {t('Cancel')}
      </Button>
      <Button primary onClick={handleSubmitEvaluation}>
        {t('Confirm, submit evaluation')}
      </Button>
    </ModalButtons>
  )
}
ConfirmationContent.Header = Header
ConfirmationContent.Footer = Footer

export default ConfirmationContent

import React from 'react'
import { Trans } from 'react-i18next'
import { useHistory, useRouteMatch } from 'react-router-dom'

import { OneColumnLayout, PageHead, Tabs } from '@fullfabric/alma-mater'

import CreateTeamButton from './StaffManagement/CreateTeamButton'
import CreateUserButton from './StaffManagement/CreateUserButton'
import TeamsTable from './StaffManagement/TeamsTable'
import UsersTable from './StaffManagement/UsersTable'

export default function StaffManagement() {
  const match = useRouteMatch('/staff/management/:tab')
  const history = useHistory()

  const handleTabChanged = (tab) => history.replace(`/staff/management/${tab}`)

  const selectedTab = match?.params?.tab || 'users'

  let action = null
  if (selectedTab === 'teams') action = <CreateTeamButton />
  else if (selectedTab === 'users') action = <CreateUserButton />

  return (
    <OneColumnLayout
      pageHead={
        <PageHead
          action={action}
          title={<Trans>Staff management</Trans>}
          noBorder
        />
      }
    >
      <Tabs defaultActiveTab={selectedTab} onTabChange={handleTabChanged}>
        <Tabs.Panel label='Users' id='users'>
          <UsersTable />
        </Tabs.Panel>

        <Tabs.Panel label='Teams' id='teams'>
          <TeamsTable />
        </Tabs.Panel>
      </Tabs>
    </OneColumnLayout>
  )
}

import { useQuery } from 'react-query'

import { utils } from '@fullfabric/public-api'

export const useEmailTemplateImages = (id) => {
  return useQuery({
    queryKey: ['emailTemplate', id, 'images'],
    queryFn: async () => {
      const response = await fetch(`/api/email_templates/${id}/uploads`, {
        credentials: 'same-origin'
      })
      return utils.checkResponse(response)
    },
    enabled: Boolean(id)
  })
}

import React, { useReducer } from 'react'
import { Trans } from 'react-i18next'

import { MarketingPolicyForm, Modal, Text } from '@fullfabric/alma-mater'
import { useCurrentUser } from '@fullfabric/authorization-officer'

import submitPolicyAcceptance, {
  SOURCES
} from 'shared/api/submitPolicyAcceptance'
import { useAppData } from 'shared/contexts/AppData'

import AuthenticationLiteModalForm from '../../AuthLiteForm'
import { useSetStepInAuthentication } from '../contexts/StepInAuthentication'

import styles from '../styles.module.scss'

export default function MarketingPolicyStep() {
  const user = useCurrentUser()
  const appData = useAppData()
  const { marketing_policy: marketingPolicy } = appData
  const setStep = useSetStepInAuthentication()
  const [
    { acceptance, isValid, isDirty, submitCount, errorSubmitting },
    dispatch
  ] = useReducer(modalStateReducer, initialState)

  const onSubmit = async ({ onSuccess, onError }) => {
    dispatch({ type: actionTypes.submitStart })

    if (!isValid) {
      return
    }

    try {
      await submitPolicyAcceptance({
        policyId: marketingPolicy?.id,
        acceptorId: user?.id,
        sourceType: SOURCES.SIGN_IN,
        acceptanceOptions: acceptance?.options
      })
      dispatch({ type: actionTypes.submitEnd })
      window.location.reload()
    } catch (error) {
      onError()
      dispatch({ type: actionTypes.submitError })
    }
  }

  return (
    <Modal
      size='medium'
      isOpen
      className={styles.modal}
      aria-labelledby='authentication-marketing-policy-step-title'
    >
      <AuthenticationLiteModalForm
        showModalActions
        step='marketingPolicy'
        setStep={setStep}
        onSubmit={onSubmit}
        authenticatedProfileId={user?.id}
      >
        <MarketingPolicyForm
          data={appData}
          locale={window.locale || 'en-GB'}
          displayErrors={isDirty || submitCount > 0}
          onChange={(values, isValid) => {
            dispatch({ type: actionTypes.change, payload: { values, isValid } })
          }}
          renderTitle={({ title }) => (
            <Text
              type='h2'
              fontColor='text-base-darkest'
              marginBottom='more'
              id='authentication-marketing-policy-step-title'
            >
              {title}
            </Text>
          )}
        />
        {errorSubmitting && (
          <Text marginTop='tiny' fontColor='text-alert'>
            <Trans>
              There was an error in your submission, please try again in a few
              seconds. If the issue persists, please contact us.
            </Trans>
          </Text>
        )}
      </AuthenticationLiteModalForm>
    </Modal>
  )
}

const initialState = {
  acceptance: {},
  isValid: false,
  errorSubmitting: false,
  isDirty: false,
  submitCount: 0
}

const actionTypes = {
  change: 'change',
  submitStart: 'submitStart',
  submitEnd: 'submitEnd',
  submitError: 'submitError'
}

function modalStateReducer(state, action) {
  switch (action.type) {
    case actionTypes.change:
      return {
        ...state,
        acceptance: action.payload?.values,
        isValid: action.payload?.isValid,
        isDirty: true
      }
    case actionTypes.submitStart:
      return {
        ...state,
        submitCount: state.submitCount + 1,
        errorSubmitting: false
      }
    case actionTypes.submitError:
      return {
        ...state,
        errorSubmitting: true
      }
    default:
      return state
  }
}

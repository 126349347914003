{
  "Hello {{name}},": "Hello {{name}},",
  "Please find an overview of your admissions performance to date.": "Please find an overview of your admissions performance to date.",
  "Back to list of reports": "Back to list of reports",
  "Application performance": "Application performance",
  "Since applications opened": "Since applications opened",
  "This week": "This week",
  "This month": "This month",
  "This year": "This year",
  "Last week": "Last week",
  "Last month": "Last month",
  "Last year": "Last year",
  "Started and submitted applications over time": "Started and submitted applications over time",
  "Cumulative": "Cumulative",
  "Started": "Started",
  "Submitted": "Submitted",
  "All applications": "All applications",
  "Application template": "Application template",
  "Period": "Period",
  "Total started applications": "Total started applications",
  "Total submitted applications": "Total submitted applications",
  "Conversion rate": "Conversion rate",
  "Non-cumulative": "Non-cumulative",
  "Started applications": "Started applications",
  "Submitted applications": "Submitted applications",
  "Age group: {{ageGroup}}": "Age group: {{ageGroup}}",
  "Age": "Age",
  "Gender: {{gender}}": "Gender: {{gender}}",
  "Gender": "Gender",
  "Unexpected error": "Unexpected error",
  "Product overview": "Product overview",
  "Warning": "Warning",
  "Some of the selected intakes don’t have an application close date set.": "Some of the selected intakes don’t have an application close date set.",
  "Loading": "Loading",
  "Week {{weekNumber}}": "Week {{weekNumber}}",
  "Intakes overview": "Intakes overview",
  "Nationality: {{nationality}}": "Nationality: {{nationality}}",
  "Top 10 nationalities": "Top 10 nationalities",
  "Product": "Product",
  "Intakes": "Intakes",
  "All lifecycles": "All lifecycles",
  "Lifecycle": "Lifecycle",
  "Not available": "Not available",
  "1 week to deadline": "1 week to deadline",
  "{{weeks}} weeks to deadline": "{{weeks}} weeks to deadline",
  "Current": "Current",
  "{{from}} to {{to}}": "{{from}} to {{to}}",
  "Month-to-date": "Month-to-date",
  "Two months ago": "Two months ago",
  "Product performance": "Product performance",
  "Class new": "Class new",
  "Applications open": "Applications open",
  "Applications closed": "Applications closed",
  "Class in progress": "Class in progress",
  "Class finished": "Class finished",
  "Less than the minimum": "Less than the minimum",
  "More than or equal to the minimum": "More than or equal to the minimum",
  "Less than the target": "Less than the target",
  "More than or equal to the target": "More than or equal to the target",
  "Less than the maximum": "Less than the maximum",
  "More than or equal to the maximum": "More than or equal to the maximum",
  "Class/course state": "Class/course state",
  "Owner": "Owner",
  "Capacity": "Capacity",
  "Search for a product": "Search for a product",
  "Class starts on": "Class starts on",
  "Current capacity": "Current capacity",
  "No data": "No data",
  "Please specify the product's capacities": "Please specify the product's capacities",
  "Not specified": "Not specified",
  "Monitor the success of the start of your admissions funnel": "Monitor the success of the start of your admissions funnel",
  "Monitor your products’ conversions against your targets": "Monitor your products’ conversions against your targets",
  "Monitor the admissions progression for a product and the demographic distribution of its candidates.": "Monitor the admissions progression for a product and the demographic distribution of its candidates.",
  "Source category analysis report": "Source category analysis report",
  "Compare the success of your different marketing activities": "Compare the success of your different marketing activities",
  "Source method analysis report": "Source method analysis report",
  "Identify how your leads are entering your database": "Identify how your leads are entering your database",
  "Reports": "Reports",
  "See report": "See report",
  "Back to class": "Back to class",
  "Product conversion": "Product conversion",
  "Compare with": "Compare with",
  "Lifecycles": "Lifecycles",
  "Withdrawn": "Withdrawn",
  "has withdrawn": "has withdrawn",
  "has not withdrawn": "has not withdrawn",
  "all": "all",
  "Total profiles": "Total profiles",
  "Funnel": "Funnel",
  "{{count}} steps": "{{count}} step",
  "{{count}} steps_plural": "{{count}} steps",
  "Intake": "Intake",
  "No data available": "No data available",
  "Month {{monthNumber}}": "Month {{monthNumber}}",
  "Weeks until class starts": "Weeks until class starts",
  "Months until class starts": "Months until class starts",
  "Weeks": "Weeks",
  "Months": "Months",
  "Application close date ({{intakeName}})": "Application close date ({{intakeName}})",
  "Some intakes are missing dates.": "Some intakes are missing dates.",
  "Please add dates to the intakes.": "Please add dates to the intakes.",
  "Starts on": "Starts on",
  "Monitor your conversion rates between different lifecycle states in your funnel for a particular product intake.": "Monitor your conversion rates between different lifecycle states in your funnel for a particular product intake.",
  "Classes with this course included:": "Classes with this course included:",
  "Capacity chart": "Capacity chart"
}

import React from 'react'
import { useQuery } from 'react-query'

import moment from 'moment'

import api from 'apps/ContentPages/api'
import templateHasContextSelection from 'apps/ContentPages/utils/is-context-selection-required'
import {
  useClassOfTemplate,
  useLinkedApplication,
  useLinkedApplications,
  useTemplate
} from '../template-info-context'
import Actions from './actions'
import TemplateDate from './date'
import LoadingActionsPlaceholder from './loading-actions-placeholder'
import TemplateName from './name'
import ProgrammeAndClassModal from './programme-and-class-modal'
import { StartApplicationStateProvider } from './start-application-state-context'
import SubmittedApplications from './submitted-applications'
import ThirdPartyApplicationModal from './third-party-application-modal'

const OPENED_STATES = ['extended', 'open', 'openIndefinitely']

const TemplateInfo = ({ widgetId }) => {
  const template = useTemplate()
  const linkedApplication = useLinkedApplication()
  const linkedApplications = useLinkedApplications()
  const classOfTemplate = useClassOfTemplate()
  const { data: applicationContexts, status: fetchingStatus } = useQuery(
    ['getTemplateChoices', template.id, widgetId],
    () => api.getTemplateChoices(template.id),
    { enabled: templateHasContextSelection(template) }
  )

  const templateState = getTemplateState({
    template,
    application: linkedApplication,
    classOfTemplate
  })

  const isAcceptingApplications = OPENED_STATES.includes(templateState)
  const submittedApplications = (linkedApplications || []).filter(
    (application) => application.state === 'submitted'
  )

  return (
    <div data-testid='template-info'>
      <TemplateName />
      <TemplateDate state={templateState} />
      {fetchingStatus === 'loading' ? (
        <LoadingActionsPlaceholder />
      ) : (
        <>
          <SubmittedApplications
            template={template}
            submittedApplications={submittedApplications}
            contexts={applicationContexts}
          />
          {isAcceptingApplications && (
            <StartApplicationStateProvider templateData={template}>
              <>
                <Actions contexts={applicationContexts} />
                <ProgrammeAndClassModal />
                <ThirdPartyApplicationModal />
              </>
            </StartApplicationStateProvider>
          )}
        </>
      )}
    </div>
  )
}

const getTemplateState = ({ template, application, classOfTemplate }) => {
  const isOpen = template.is_open
  const isClosed = !isOpen
  const isOpenButClassIsClosed =
    isOpen && classOfTemplate && classOfTemplate.state !== 'open'

  if (isOpenButClassIsClosed) {
    return 'closed'
  }

  if (application && hasExtendedDeadline(application)) {
    return 'extended'
  }

  if (isOpen && template.closes_at) {
    return 'open'
  }

  if (isClosed && moment(template.current_time).isBefore(template.opens_at)) {
    return 'upcoming'
  }

  if (isClosed) {
    return 'closed'
  }

  return 'openIndefinitely'
}

const hasExtendedDeadline = (application) => {
  if (!application || !application.deadline) {
    return false
  }

  const deadlineMoment = moment(application.deadline).utc()
  const todayMoment = moment().startOf('day').utc()

  return deadlineMoment.isValid() && deadlineMoment.isSameOrAfter(todayMoment)
}

export default TemplateInfo

import React from 'react'
import { Trans } from 'react-i18next'

import { Button, Col, Row } from '@fullfabric/alma-mater'

import CourseCreateFormAcademicDetails from '../CourseCreateFormAcademicDetails'

import styles from './styles.module.scss'

const CourseEditFormAcademicDetails = ({
  data,
  onChange,
  inProgress,
  errors,
  onSaveClick,
  onCancelClick
}) => {
  return (
    <section aria-label='course academic details data editable'>
      <Row>
        <Col xs={12}>
          <CourseCreateFormAcademicDetails
            data={data}
            onChange={onChange}
            errors={errors}
          />
        </Col>
      </Row>

      <div className='marginTop-default'>
        <Button primary size='small' loading={inProgress} onClick={onSaveClick}>
          <Trans>Save changes</Trans>
        </Button>

        <Button
          size='small'
          onClick={onCancelClick}
          className={styles.cancelButton}
        >
          <Trans>Cancel</Trans>
        </Button>
      </div>
    </section>
  )
}

export default CourseEditFormAcademicDetails

import React, { useEffect } from 'react'
import { Trans } from 'react-i18next'

import {
  AnnotatedLayout,
  ArrowLeftIcon,
  PageHead
} from '@fullfabric/alma-mater'

import CourseCreateFormAcademicDetails from 'apps/Courses/containers/CourseCreateFormAcademicDetails'
import CourseCreateFormBasic from 'apps/Courses/containers/CourseCreateFormBasic'
import CourseCreateFormCapacity from 'apps/Courses/containers/CourseCreateFormCapacity'
import CourseCreateFormDates from 'apps/Courses/containers/CourseCreateFormDates'
import CourseCreateFormSchema from 'apps/Courses/containers/CourseCreateFormSchema'
import CourseCreateFormStatus from 'apps/Courses/containers/CourseCreateFormStatus'
import CourseAcademicDetailsSection from '../CourseSectionAcademicDetails'
import CourseBasicSection from '../CourseSectionBasic'
import CourseSectionCapacity from '../CourseSectionCapacity'
import CourseDatesSection from '../CourseSectionDates'
import CourseSchemaSection from '../CourseSectionSchema'
import CourseStatusSection from '../CourseSectionStatus'
import ActionButton from './ActionButton'

import styles from './styles.module.scss'

const schemaHasFields = (schema) =>
  schema &&
  schema.sections.length > 0 &&
  schema.sections.flatMap((section) => section.fields).length > 0

const NewCoursePage = ({
  inProgress,
  schema,
  onAddCourseClick,
  getCourseSchema,
  onBackClick
}) => {
  useEffect(() => {
    getCourseSchema()
  }, [getCourseSchema])

  const pageHead = (
    <PageHead
      className={styles.pageHead}
      title={<Trans>New Course</Trans>}
      action={
        <ActionButton
          size='small'
          loading={inProgress}
          onClick={onAddCourseClick}
        />
      }
      breadcrumbs={[
        {
          label: <Trans>Back</Trans>,
          icon: ArrowLeftIcon,
          onClick: onBackClick
        }
      ]}
    />
  )

  return (
    <AnnotatedLayout pageHead={pageHead} className={styles.layoutContainer}>
      <CourseBasicSection>
        <CourseCreateFormBasic />
      </CourseBasicSection>

      <CourseDatesSection>
        <CourseCreateFormDates />
      </CourseDatesSection>

      <CourseStatusSection>
        <CourseCreateFormStatus />
      </CourseStatusSection>

      <CourseAcademicDetailsSection>
        <CourseCreateFormAcademicDetails />
      </CourseAcademicDetailsSection>

      <CourseSectionCapacity>
        <CourseCreateFormCapacity />
      </CourseSectionCapacity>

      {schema && schemaHasFields(schema) && (
        <CourseSchemaSection>
          <CourseCreateFormSchema />
        </CourseSchemaSection>
      )}

      <div className={styles.footer}>
        <ActionButton loading={inProgress} onClick={onAddCourseClick} />
      </div>
    </AnnotatedLayout>
  )
}

export default NewCoursePage

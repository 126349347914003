import React from 'react'

import { Visible } from '@fullfabric/alma-mater'

import useIsMobileLayout from 'apps/ContentPages/hooks/use-is-mobile-layout'
import LocaleSwitcherDropdown from '../../locale-switcher-dropdown'
import BackgroundLayer from '../background-layer'
import ContentGrid from '../content-grid'
import Header from '../header'
import NavigationInHeader from '../navigation-in-header'
import WidgetRegion from './widget-region'

import styles from './styles.module.scss'

const ActionPageLayout = ({
  withNavigationMenu,
  title,
  withPrioritizedWidgets,
  withFixedWidgets,
  widgets,
  children
}) => {
  const isMobileLayout = useIsMobileLayout()
  const hasWidgets = !!widgets
  const showDedicatedLocaleSwitcher = !withNavigationMenu
  const showWidgetsAfterContent = !withPrioritizedWidgets || !isMobileLayout
  const showWidgetsBeforeContent = isMobileLayout && withPrioritizedWidgets

  return (
    <>
      <Visible xs sm>
        <Header>
          {withNavigationMenu ? (
            <NavigationInHeader />
          ) : (
            <div className={styles.localeSpacing}>
              <LocaleSwitcherDropdown />
            </div>
          )}
        </Header>
      </Visible>
      <BackgroundLayer narrow={hasWidgets}>
        <Visible md>
          <Header>
            {withNavigationMenu ? (
              <NavigationInHeader />
            ) : (
              <div className={styles.localeSpacing}>
                <LocaleSwitcherDropdown />
              </div>
            )}
          </Header>
        </Visible>
        <main>
          <ContentGrid withWidgets={hasWidgets}>
            {title || showDedicatedLocaleSwitcher ? (
              <ContentGrid.Row justify='between'>
                <ContentGrid.ContentCol isNarrow={hasWidgets}>
                  {title}
                </ContentGrid.ContentCol>
                {showDedicatedLocaleSwitcher && (
                  <Visible lg xl>
                    <LocaleSwitcherDropdown floating />
                  </Visible>
                )}
              </ContentGrid.Row>
            ) : null}
            <ContentGrid.Row>
              {showWidgetsBeforeContent && hasWidgets ? (
                <WidgetRegion fixed={withFixedWidgets}>{widgets}</WidgetRegion>
              ) : null}
              <ContentGrid.ContentCol isNarrow={hasWidgets}>
                <section id='main-content' aria-labelledby='page-title'>
                  {children}
                </section>
              </ContentGrid.ContentCol>
              {showWidgetsAfterContent && hasWidgets ? (
                <WidgetRegion fixed={withFixedWidgets}>{widgets}</WidgetRegion>
              ) : null}
            </ContentGrid.Row>
          </ContentGrid>
        </main>
      </BackgroundLayer>
    </>
  )
}

export default ActionPageLayout

import React from 'react'
import { useQuery } from 'react-query'

import getProfile from 'apps/Evaluations/api/getProfile'

const EvaluationProfileContext = React.createContext(null)

const useEvaluationProfile = () => {
  return React.useContext(EvaluationProfileContext)
}

const EvaluationProfileDataProvider = ({ profileId, children }) => {
  const { data, isLoading, error } = useQuery(['getProfile', profileId], () =>
    getProfile(profileId)
  )

  return (
    <EvaluationProfileContext.Provider
      value={{
        data,
        isLoading,
        error
      }}
    >
      {children}
    </EvaluationProfileContext.Provider>
  )
}

export default EvaluationProfileContext
export { useEvaluationProfile, EvaluationProfileDataProvider }

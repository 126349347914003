import React from 'react'

export const QRCodeScanResultContext = React.createContext(null)
export const QRCodeScanResultProvider = ({ children }) => {
  const [latestEventResponse, setLatestEventResponse] = React.useState(null)

  return (
    <QRCodeScanResultContext.Provider
      value={{
        latestEventResponse,
        setLatestEventResponse
      }}
    >
      {children}
    </QRCodeScanResultContext.Provider>
  )
}

export const useLatestEventResponse = () => {
  const { latestEventResponse, setLatestEventResponse } =
    React.useContext(QRCodeScanResultContext) || {}

  return { latestEventResponse, setLatestEventResponse }
}

import { connect } from 'react-redux'

import newCourseDataChanged from 'apps/Courses/actions/newCourseDataChanged'
import CourseCreateFormAcademicDetails from 'apps/Courses/components/CourseCreateFormAcademicDetails'

const mapStateToProps = ({ newCourse: { data, errors } }) => ({
  data,
  errors
})

const mapDispatchToProps = (dispatch) => ({
  onChange: (field, value) => dispatch(newCourseDataChanged({ [field]: value }))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CourseCreateFormAcademicDetails)

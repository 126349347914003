import React from 'react'
import { Trans } from 'react-i18next'

const CloseIcon = () => (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <title>
      <Trans>Close navigation</Trans>
    </title>
    <path
      d='M9.53304 8.11789C9.5017 8.08662 9.48408 8.04416 9.48408 7.99989C9.48408 7.95562 9.5017 7.91316 9.53304 7.8819L15.7081 1.70749C16.0986 1.31648 16.0981 0.682993 15.7071 0.292544C15.3161 -0.0979049 14.6826 -0.0974572 14.2922 0.293544L8.11777 6.46529C8.0865 6.49663 8.04405 6.51425 7.99977 6.51425C7.9555 6.51425 7.91305 6.49663 7.88178 6.46529L1.70737 0.293544C1.31692 -0.0970891 0.683728 -0.0972383 0.293095 0.293211C-0.0975379 0.68366 -0.0976871 1.31685 0.292762 1.70749L6.46651 7.8819C6.49785 7.91316 6.51547 7.95562 6.51547 7.99989C6.51547 8.04416 6.49785 8.08662 6.46651 8.11789L0.292762 14.293C0.0401857 14.5457 -0.058393 14.9139 0.0341594 15.259C0.126712 15.6041 0.396335 15.8736 0.741463 15.966C1.08659 16.0584 1.45479 15.9596 1.70737 15.7069L7.88178 9.53183C7.91305 9.50048 7.9555 9.48287 7.99977 9.48287C8.04405 9.48287 8.0865 9.50048 8.11777 9.53183L14.2922 15.7069C14.6826 16.0975 15.3158 16.0977 15.7065 15.7072C16.0971 15.3168 16.0972 14.6836 15.7068 14.293L9.53304 8.11789Z'
      fill='#6B6E78'
    />
  </svg>
)

export default CloseIcon

{
  "Email": "Email-Adresse",
  "Email address is invalid": "E-Mail-Adresse ist ungültig",
  "I accept the privacy policy": "Ich akzeptiere die Datenschutzerklärung",
  "Privacy Policy": "Datenschutzerklärung",
  "Please accept the privacy policy to continue": "Bitte akzeptieren Sie die Datenschutzerklärung, um fortzufahren",
  "Welcome back": "Willkommen zurück",
  "Please enter your password for security reasons.": "Bitte geben Sie aus Sicherheitsgründen Ihr Passwort ein.",
  "Email address": "E-Mail-Addresse",
  "Password": "Passwort",
  "Forgot your password?": "Haben Sie Ihr Passwort vergessen?",
  "An email has been sent to  with an authentication link that will take you to your application.": "An email has been sent to  with an authentication link that will take you to your application.",
  "Once you have accessed the authentication link you can close this page.": "Sobald Sie den Authentifizierungslink aufgerufen haben, können Sie diese Seite schließen.",
  "Didn’t receive the email?": "Sie haben keine E-Mail erhalten?",
  "Resend email with authentication link": "Authentifizierungs-E-Mail erneut senden",
  "Email sent": "E-Mail gesendet",
  "Welcome! Let's start your new application.": "Herzlich willkommen! Beginnen wir mit Ihrer neuen Bewerbung.",
  "Welcome! Let's start your new registration.": "Herzlich willkommen! Beginnen wir mit Ihrer neuen Anmeldung.",
  "Please enter the following details so that you can revisit your application if you exit it.": "Bitte geben Sie die folgenden Angaben ein, damit Sie Ihre Bewerbung erneut aufrufen können, wenn Sie sie verlassen haben.",
  "Please enter the following details so that you can revisit your registration if you exit it.": "Bitte geben Sie die folgenden Angaben ein, damit Sie Ihre Anmeldung erneut aufrufen können, wenn Sie sie verlassen haben. ",
  "First name": "Vorname",
  "Last name": "Nachname",
  "Back": "Zurück",
  "Continue to application": "Weiter zur Bewerbung",
  "Continue to registration": "Weiter zur Anmeldung",
  "Data Protection regulation": "Datenschutzgrundverordnung",
  "Someone already registered with that email address": "Jemand hat sich bereits mit dieser E-Mail-Adresse registriert",
  "Email address can't be blank": "E-Mail-Adresse kann nicht leer sein",
  "Continue": "Weiter",
  "Participant details": "Teilnehmerdaten",
  "Please enter the details of the participant": "Bitte geben Sie die Daten des Teilnehmers ein.",
  "Registration already exists": "Registrierung bereits vorhanden",
  "Application already exists": "Applikation existiert bereits",
  "There are fields with errors": "Es gibt fehlerhafte Felder.",
  "Please correct the fields with errors": "Bitte korrigieren Sie die fehlerhaften Felder.",
  "First name can't be blank": "Vorname kann nicht leer sein",
  "Last Name": "Nachname",
  "Last name can't be blank": "Nachname kann nicht leer sein",
  "Your employment details": "Angaben zu Ihrer Beschäftigung",
  "To register on behalf of someone else we need more information on your current employment details.": "Um jemand anderen anzumelden, brauchen wir mehr Informationen zu Ihrer aktuellen Beschäftigung.",
  "There was an error in your submission, please try again in a few seconds. If the issue persists, please contact us.": "Bei Ihrer Eingabe ist ein Fehler aufgetreten, bitte versuchen Sie es in ein paar Sekunden erneut. Wenn das Problem weiterhin besteht, kontaktieren Sie uns bitte.",
  "The link has expired.": "Der Link ist abgelaufen.",
  "Please re-enter your details to receive a new authentication email.": "Bitte geben Sie Ihre Daten erneut ein, um eine neue Authentifizierungs-E-Mail zu erhalten.",
  "Please enter your email address so that we can retrieve your information if you have visited before.": "Bitte geben Sie Ihre E-Mail-Adresse ein, damit wir Ihre Informationen wiederherstellen können, falls Sie uns schon einmal besucht haben.",
  "Please select an option": "Bitte wählen Sie eine Option",
  "I am starting a registration for myself": "Ich starte eine Anmeldung für mich selbst.",
  "I am starting an application for myself": "Ich starte eine Bewerbung für mich selbst.",
  "I am starting a registration on behalf of someone else": "Ich starte eine Anmeldung für jemand anderen.",
  "I am starting an application on behalf of someone else": "Ich starte eine Bewerbung für jemand anderen.",
  "I am continuing an existing registration": "Ich setze eine bestehende Anmeldung fort.",
  "I am continuing an existing application": "Ich setze eine bestehende Bewerbung fort.",
  "Email address ": "E-Mail-Addresse ",
  "An email has been sent to {{email}} with an authentication link that will take you to your application.": "Wir haben eine E-Mail mit einem Verifizierungslink an {{email}} gesendet, auf den Sie klicken müssen, bevor Sie Ihre Bewerbung abschicken können."
}

import { utils } from '@fullfabric/public-api'

const getContextOptionsForPaymentPlans = async (_) => {
  const response = await fetch(
    '/book_keeper/api/all_payment_plan_product_options'
  )
  return await utils.checkResponse(response)
}

export default getContextOptionsForPaymentPlans

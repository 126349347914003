import React from 'react'
import { Trans } from 'react-i18next'
import { useLocation } from 'react-router'

import { CloseIcon, Text } from '@fullfabric/alma-mater'

import { useImportDocument } from 'apps/Imports/contexts/import-document'
import { useImportContext } from 'apps/Imports/hooks/useImportContext'
import { IMPORT_TYPES } from 'apps/Imports/utils/constants'
import getOrGuessImportType from 'apps/Imports/utils/get-or-guess-import-type'
import { getBaseUrl } from 'apps/Imports/utils/profile-import/get-base-import-url'

import styles from './styles.module.scss'

const closeProfileImport = (context, location, importDocument) => {
  const importType = getOrGuessImportType(location, importDocument)

  if (importType === IMPORT_TYPES.PROGRAMME_AND_CLASS) {
    window.location.replace('/institutions/settings#programmes')
  } else if (importType === IMPORT_TYPES.SUBJECT_AND_COURSE) {
    window.location.replace('/institutions/settings/courses')
  } else if (context._backbone_type !== 'Events.Models.Event') {
    const pageSufix =
      context._backbone_type === 'Institutions.Models.ClassOf' ? '' : '#all'
    const exitPage = getBaseUrl(context, pageSufix)

    window.location.replace(exitPage)
  } else {
    window.location.replace(`/events#${context.id}/show`)
  }
}

const CloseButton = () => {
  const context = useImportContext()
  const location = useLocation()
  const importDocument = useImportDocument()

  React.useEffect(() => {
    const closeApp = (evt) => {
      if ((evt || window.event).keyCode === 27) {
        closeProfileImport(context, location, importDocument)
      }
    }

    document.addEventListener('keydown', closeApp)

    return () => {
      document.removeEventListener('keydown', closeApp)
    }
  }, [context, location, importDocument])

  return (
    <button
      className={styles.closeButton}
      onClick={() => closeProfileImport(context, location, importDocument)}
    >
      <CloseIcon title='close' color='grey-400' className={styles.closeIcon} />
      <Text>
        <Trans>ESC</Trans>
      </Text>
    </button>
  )
}

export default CloseButton

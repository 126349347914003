import React from 'react'
import { Route, Switch } from 'react-router'

import SegmentsTablePage from 'apps/Segments/pages/SegmentsTablePage'

export default function SegmentsAppRoutes() {
  return (
    <Switch>
      <Route exact path='/segments/shared' component={SegmentsTablePage} />
    </Switch>
  )
}

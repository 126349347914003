import { connect } from 'react-redux'

import actionTypes from 'apps/Courses/actions/types'
import updateCourse from 'apps/Courses/actions/updateCourse'
import CourseEditFormCapacity from 'apps/Courses/components/CourseEditFormCapacity'

const mapStateToProps = ({
  editCourse: {
    capacitySection: { data, inProgress, errors }
  }
}) => ({
  data,
  inProgress,
  errors
})

const mapDispatchToProps = (dispatch) => ({
  onCancelClick: () =>
    dispatch({ type: actionTypes.cancelEditCapacitySectionData }),
  onChange: (field, value) =>
    dispatch({
      type: actionTypes.editCourseCapacitySectionDataChanged,
      payload: { [field]: value }
    }),
  onSaveClick: () =>
    dispatch(updateCourse(actionTypes.updateCourseCapacitySection))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CourseEditFormCapacity)

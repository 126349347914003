import React from 'react'

import { usePageContent } from 'apps/ContentPages/contexts/current-page-data'
import PageTitle from './page-title'

const StudentHubPageTitle = ({ title = null, subtitle = null }) => {
  const { isLoading, content = {} } = usePageContent()
  const institutionName = window.INITIAL_APP_TITLE?.split(' :: ').pop() || ''

  React.useEffect(() => {
    document.title = `${title || content.title} :: ${institutionName}`
  }, [title, content, institutionName])

  return (
    <PageTitle isLoading={isLoading} subtitle={subtitle || content.subtitle}>
      {title || content.title}
    </PageTitle>
  )
}

export default StudentHubPageTitle

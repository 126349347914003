import React from 'react'

import { useImportDocument } from 'apps/Imports/contexts/import-document'
import {
  useCurrentStep,
  useImportSteps,
  useStepsCompleted
} from 'apps/Imports/contexts/steps'
import ReportChartSection from './components/report-chart-section'
import ReportErrorsSection from './components/report-errors-section'
import ReportSummarySection from './components/report-summary-section'

const ImportReportStep = ({ lastStep }) => {
  useCurrentStep(lastStep)
  const { setStepsCompleted, stepsCompleted } = useStepsCompleted()
  const importSteps = useImportSteps()
  const importDocument = useImportDocument()

  React.useEffect(() => {
    if (!importDocument) return

    const reportStepIndex = importSteps.findIndex(
      (step) => step.id === lastStep
    )
    const previousSteps = importSteps.slice(0, reportStepIndex)
    const previousStepIds = previousSteps.map((step) => step.id)

    if (
      stepsCompleted.length !== previousStepIds.length ||
      !previousStepIds.every((stepId) => stepsCompleted.includes(stepId))
    )
      setStepsCompleted(previousStepIds)
  }, [importSteps, setStepsCompleted, importDocument, stepsCompleted, lastStep])

  return (
    <>
      <ReportSummarySection />
      <ReportChartSection />
      <ReportErrorsSection />
    </>
  )
}

export default ImportReportStep

import React from 'react'
import { Trans } from 'react-i18next'

import { Button, Text } from '@fullfabric/alma-mater'

import CardContent from 'apps/StudyPlans/components/CardContent'
import CardInfoBullet from 'apps/StudyPlans/components/CardInfoBullet'
import { useSetSettingsSectionsBeingEdited } from 'apps/StudyPlans/contexts/SettingsSectionsBeingEdited'
import { useStudyPlan } from 'apps/StudyPlans/contexts/StudyPlanData'

const CategoriesSectionDisplay = () => {
  const studyPlan = useStudyPlan()
  const setSectionBeingEdited = useSetSettingsSectionsBeingEdited()
  const onEditClick = React.useCallback(() => {
    setSectionBeingEdited((sections) => ({ ...sections, categories: true }))
  }, [setSectionBeingEdited])
  const { categories } = studyPlan
  return (
    <React.Fragment>
      <CardContent
        title={<Trans>Categories</Trans>}
        actions={
          <Button size='small' onClick={onEditClick}>
            <Trans>Edit</Trans>
          </Button>
        }
      >
        {categories && categories.length ? (
          categories.map((category) => (
            <CardInfoBullet
              key={category.id}
              main={category.name}
              secondary={
                <span>
                  {category.credits ? `${category.credits}` : '0'}{' '}
                  {category.credits === 1 ? (
                    <Trans>credit</Trans>
                  ) : (
                    <Trans>credits</Trans>
                  )}
                </span>
              }
            />
          ))
        ) : (
          <Text type='f4' fontColor='text-base' marginTop='tiny'>
            <Trans>No category defined</Trans>
          </Text>
        )}
      </CardContent>
    </React.Fragment>
  )
}

export default CategoriesSectionDisplay

import { utils } from '@fullfabric/public-api'

const getContract = async (id) => {
  const response = await fetch(`/billing/api/contracts/${id}`, {
    credentials: 'same-origin'
  })

  return await utils.checkResponse(response)
}

export default getContract

import { utils } from '@fullfabric/public-api'

const editContract = async (id, data) => {
  const response = await fetch(`/billing/api/contracts/${id}`, {
    credentials: 'same-origin',
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  })

  return await utils.checkResponse(response)
}

export default editContract

import { connect } from 'react-redux'

import moment from 'moment'

import actionTypes from 'apps/Courses/actions/types'
import updateCourse from 'apps/Courses/actions/updateCourse'
import CourseEditFormDates from 'apps/Courses/components/CourseEditFormDates'

const mapStateToProps = ({
  editCourse: {
    datesSection: { data, inProgress }
  }
}) => ({
  data,
  inProgress
})

const mapDispatchToProps = (dispatch) => ({
  onCancelClick: () =>
    dispatch({ type: actionTypes.cancelEditDatesSectionData }),
  onChange: (field, value) => {
    const date = moment(value)
    const formattedValue = value && date.isValid() ? date.utc().format() : ''

    dispatch({
      type: actionTypes.editCourseDatesSectionDataChanged,
      payload: { [field]: formattedValue }
    })
  },
  onSaveClick: () =>
    dispatch(updateCourse(actionTypes.updateCourseDatesSection))
})

export default connect(mapStateToProps, mapDispatchToProps)(CourseEditFormDates)

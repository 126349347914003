import React, { useState } from 'react'
import { Trans } from 'react-i18next'

import {
  Button,
  CloseIcon,
  Field,
  IconButton,
  PlainButton,
  PointerDownIcon,
  PointerRightIcon,
  Text
} from '@fullfabric/alma-mater'

import api from 'apps/ContentPages/api'

import styles from './payable-discounts/styles.module.scss'

export default function PayableDiscounts({
  payable,
  externalPayerRequest = null,
  triggerRefetch
}) {
  const discountsApplied = payable.discounts
  const [displayDiscounts, setDisplayDiscounts] = useState(
    discountsApplied.length > 0
  )
  const [discountCode, setDiscountCode] = useState(null)
  const [discountError, setDiscountError] = useState(null)

  const removeDiscount = async (discountId) => {
    setDiscountError(null)

    try {
      if (externalPayerRequest) {
        await api.removeExternalPayerDiscount({
          discountId,
          externalPayerRequestId: externalPayerRequest.id
        })
      } else if (payable._ruby_type === 'Applics::Applic') {
        await api.removeApplicDiscount({
          discountId
        })
      } else {
        await api.removePaymentPlanDiscount({
          profileId: payable.profile_id,
          paymentPlanId: payable.id,
          discountId
        })
      }

      triggerRefetch()
    } catch (error) {
      setDiscountError(error.data.message)
    }
  }

  const onSubmit = async () => {
    setDiscountError(null)

    try {
      if (externalPayerRequest) {
        await api.addExternalPayerDiscount({
          code: discountCode,
          externalPayerRequestId: externalPayerRequest.id
        })
      } else if (payable._ruby_type === 'Applics::Applic') {
        await api.addApplicDiscount({
          applicId: payable.id,
          code: discountCode
        })
      } else {
        await api.addPaymentPlanDiscount({
          profileId: payable.profile_id,
          paymentPlanId: payable.id,
          code: discountCode
        })
      }

      triggerRefetch()
    } catch (error) {
      setDiscountError(error.data.message)
    }
  }

  return (
    <>
      <div className={styles.discountsBtn}>
        <PlainButton
          secondary
          onClick={() => setDisplayDiscounts(!displayDiscounts)}
        >
          <Trans>Have a discount code?</Trans>
          {displayDiscounts ? (
            <PointerDownIcon title='display discount code input' />
          ) : (
            <PointerRightIcon title='hide discount code input' />
          )}
        </PlainButton>
      </div>
      <div className={styles.discountsContainer}>
        {displayDiscounts && (
          <>
            <div className={styles.discountsInput}>
              <Field
                id='code'
                small
                type='text'
                error={discountError}
                className={styles.discountInput}
                onChange={(_e, code) => setDiscountCode(code)}
              />
              {discountsApplied.map((discount) => (
                <div className={styles.discountsApplied}>
                  <Text>{discount.description}</Text>
                  <IconButton
                    className={styles.iconButton}
                    smaller
                    tag='div'
                    plain
                    onClick={() => removeDiscount(discount.id)}
                  >
                    <CloseIcon />
                  </IconButton>
                </div>
              ))}
            </div>
            <Button size='small' marginLeft='small' onClick={() => onSubmit()}>
              <Trans>Apply</Trans>
            </Button>
          </>
        )}
      </div>
    </>
  )
}

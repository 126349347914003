import React from 'react'
import { useTranslation } from 'react-i18next'

import PageLayout from 'apps/MergeProfiles/components/PageLayout'
import ProfileInfoSelector from 'apps/MergeProfiles/components/ProfileInfoSelector'
import { MERGE_STEPS } from 'apps/MergeProfiles/contexts/MergeData'
import useCurrentPage from 'apps/MergeProfiles/hooks/useCurrentPage'
import NextActionButton from './NextActionButton'
import PreviousActionButton from './PreviousActionButton'

const ProfileInfoPage = () => {
  useCurrentPage(MERGE_STEPS.info)
  const { t } = useTranslation()

  const actions = (
    <>
      <PreviousActionButton />
      <NextActionButton />
    </>
  )

  return (
    <>
      <PageLayout.Header
        title={t('Profile Info')}
        description={t(
          'Select which information you would like to keep in the final profile.'
        )}
        actions={actions}
      />
      <PageLayout.Content>
        <ProfileInfoSelector />
      </PageLayout.Content>
      <PageLayout.Footer actions={actions} />
    </>
  )
}

export default ProfileInfoPage

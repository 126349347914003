import React from 'react'

import { memoize } from 'lodash'

import { Table } from '@fullfabric/alma-mater'

import getSortDirectionFromColumn from '../../../utils/getSortDirectionFromColumn'
import normalizeColumnTitle from '../../../utils/normalizeColumnTitle'

import cx from 'classnames'
import styles from './styles.module.scss'

const getHeaderRenderer = (column, onClick) => {
  return memoize(
    () => {
      const sortProps =
        column.getSortByToggleProps &&
        column.getSortByToggleProps((props) => ({
          ...props,
          title: normalizeColumnTitle(column),
          onClick: undefined // leave click handling to virtualized table's onHeaderClick
        }))

      const { key: colKey, ...columnHeaderProps } =
        column.getHeaderProps(sortProps)

      return (
        <Table.HeaderItem
          tag='div'
          key={colKey}
          className={cx(
            styles.colHeaderItem,
            columnHeaderProps.numeric && styles.numericCell
          )}
          sort={getSortDirectionFromColumn(column)}
          {...columnHeaderProps}
        >
          {column.render('Header')}
        </Table.HeaderItem>
      )
    },
    () => `${JSON.stringify(column)}${JSON.stringify()}`
  )
}

export default getHeaderRenderer

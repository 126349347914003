/* eslint-disable no-prototype-builtins */

import React from 'react'
import { Trans } from 'react-i18next'

import { Button, ErrorIcon, SuccessIcon } from '@fullfabric/alma-mater'

import { useMergeData } from 'apps/MergeProfiles/contexts/MergeData'

import classNames from 'classnames'
import styles from './styles.module.scss'

const JourneyCardActions = ({ context, profileType }) => {
  const {
    mergeData: { journeysFrom, journeysFromApplics },
    updateMergeData
  } = useMergeData()

  const onDiscardClick = React.useCallback(() => {
    updateMergeData({
      journeysFrom: {
        ...journeysFrom,
        [context.id]: profileType === 'duplicate' ? 'main' : 'duplicate'
      }
    })
  }, [updateMergeData, journeysFrom, profileType, context])

  const onAddClick = React.useCallback(() => {
    updateMergeData({
      journeysFrom: {
        ...journeysFrom,
        [context.id]: profileType
      }
    })
  }, [updateMergeData, journeysFrom, profileType, context])

  const hasSelection = !!journeysFrom[context.id]
  const preselectedWithinApplic = journeysFromApplics.hasOwnProperty(context.id)
  const isAdded = journeysFrom[context.id] === profileType
  const isDiscarded = hasSelection && !isAdded

  return (
    <div className={styles.cardButtons}>
      <Button
        size='small'
        onClick={onAddClick}
        className={classNames(styles.cardButton, isAdded && styles.added)}
        disabled={preselectedWithinApplic}
      >
        {isAdded && (
          <SuccessIcon color='text-success' className={styles.buttonIcon} />
        )}
        {profileType === 'duplicate' ? (
          <Trans>Add journey</Trans>
        ) : (
          <Trans>Keep journey</Trans>
        )}
      </Button>

      <Button
        size='small'
        onClick={onDiscardClick}
        className={classNames(
          styles.cardButton,
          isDiscarded && styles.discarded
        )}
        disabled={preselectedWithinApplic}
      >
        {isDiscarded && (
          <ErrorIcon color='text-alert' className={styles.buttonIcon} />
        )}
        {profileType === 'duplicate' ? (
          <Trans>Discard journey</Trans>
        ) : (
          <Trans>Delete journey</Trans>
        )}
      </Button>
    </div>
  )
}

export default JourneyCardActions

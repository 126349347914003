import React from 'react'
import { Trans } from 'react-i18next'

import { Col, DeleteIcon, Field, IconButton, Row } from '@fullfabric/alma-mater'

import { useUpdatedCategories } from '.'
import { getCategoryPlaceholder, getErrorsInCategory } from './utils'

import styles from './styles.module.scss'

const SingleCategoryForm = ({ category }) => {
  const { categories, setCategories } = useUpdatedCategories()

  const onCategoryChange = React.useCallback(
    (fieldId, value) => {
      const [fieldName, categoryId] = fieldId.split('_')
      setCategories((categories) => {
        const updatedCategory = {
          ...categories[categoryId],
          [fieldName]: value,
          edited: true
        }
        return {
          ...categories,
          [categoryId]: {
            ...updatedCategory,
            errors: getErrorsInCategory(updatedCategory)
          }
        }
      })
    },
    [setCategories]
  )

  const onDeleteCategory = React.useCallback(() => {
    const { [category.id]: deletedCategory, ...otherCategories } = categories
    const resultingCategories =
      Object.keys(otherCategories).length === 0
        ? getCategoryPlaceholder()
        : otherCategories

    setCategories(resultingCategories)
  }, [category, categories, setCategories])

  return (
    <Row key={category.id}>
      <Col xs={9}>
        <Field
          id={`name_${category.id}`}
          type='text'
          value={category.name || ''}
          label={<Trans>Name</Trans>}
          onChange={onCategoryChange}
          error={category.errors?.name}
        />
      </Col>
      <Col xs={2}>
        <Field
          id={`credits_${category.id}`}
          type='text'
          value={category.credits >= 0 ? `${category.credits}` : ''}
          label={<Trans>Credits</Trans>}
          onChange={onCategoryChange}
          error={category.errors?.credits}
        />
      </Col>
      <Col xs={1} className={styles.deleteIcon}>
        <IconButton plain onClick={onDeleteCategory}>
          <DeleteIcon />
        </IconButton>
      </Col>
    </Row>
  )
}

export default SingleCategoryForm

import React, { useState } from 'react'
import { Trans } from 'react-i18next'
import { useMutation } from 'react-query'

import { Button, Col, Field, Row } from '@fullfabric/alma-mater'

import updateStudyPlan from 'apps/StudyPlans/api/updateStudyPlan'
import { useOnChangesSaved } from 'apps/StudyPlans/contexts/ChangesSaved'
import { useSetSettingsSectionsBeingEdited } from 'apps/StudyPlans/contexts/SettingsSectionsBeingEdited'
import {
  useSetStudyPlan,
  useStudyPlan
} from 'apps/StudyPlans/contexts/StudyPlanData'

const CreditsSectionForm = () => {
  const studyPlan = useStudyPlan()
  const setStudyPlan = useSetStudyPlan()
  const [credits, setCredits] = useState(() => studyPlan.credits)
  const onChangesSaved = useOnChangesSaved()
  const setSectionsBeingEdited = useSetSettingsSectionsBeingEdited()
  const { mutate: updateCreditsMutation, isLoading } = useMutation(
    ({ studyPlanId, credits }) => {
      return updateStudyPlan(studyPlanId, { credits })
    },
    {
      onSuccess: (updatedStudyPlan) => {
        onChangesSaved()
        setStudyPlan({ ...studyPlan, ...updatedStudyPlan })
        setSectionsBeingEdited((sections) => ({
          ...sections,
          credits: false
        }))
      }
    }
  )

  const onSaveChanges = React.useCallback(() => {
    updateCreditsMutation({
      studyPlanId: studyPlan.id,
      credits
    })
  }, [updateCreditsMutation, studyPlan, credits])

  const onCancel = React.useCallback(() => {
    setCredits(studyPlan.credits)
    setSectionsBeingEdited((sections) => ({
      ...sections,
      credits: false
    }))
  }, [setCredits, studyPlan, setSectionsBeingEdited])

  return (
    <>
      <Row>
        <Col xs={6}>
          <Field
            id='credits'
            type='text'
            value={credits ? `${credits}` : ''}
            label={<Trans>Required number of credits</Trans>}
            onChange={(id, value) => setCredits(value)}
          />
        </Col>
      </Row>
      <div className='marginTop-small'>
        <Button
          primary
          size='small'
          onClick={onSaveChanges}
          loading={isLoading}
          disabled={isLoading}
        >
          <Trans>Save changes</Trans>
        </Button>
        <Button
          size='small'
          onClick={onCancel}
          className='marginLeft-small'
          disabled={isLoading}
        >
          <Trans>Cancel</Trans>
        </Button>
      </div>
    </>
  )
}

export default CreditsSectionForm

.react-app ._table_11qff_1 {
  width: 100%;
  border: none;
  table-layout: fixed;
}
.react-app ._table_11qff_1 td:nth-child(1) {
  width: 24px;
}
.react-app ._table_11qff_1,
.react-app ._table_11qff_1 thead,
.react-app ._table_11qff_1 tr,
.react-app ._table_11qff_1 th,
.react-app ._table_11qff_1 td {
  border-collapse: collapse;
  vertical-align: middle;
}

.react-app ._header_11qff_18 {
  border: none;
  background-color: var(--ff-bg-base-lighter);
}

.react-app ._headerItem_11qff_23 {
  border: none;
  padding: var(--space-small) 0;
  padding-right: var(--space-more);
  text-align: left;
  vertical-align: top;
  box-sizing: content-box;
}

.react-app ._headerItem_11qff_23._mobile_11qff_32 {
  padding: var(--space-small) var(--space-less);
}

.react-app ._row_11qff_36 {
  border-top: 1px solid transparent;
  border-bottom: var(--border-base-lighter);
}

.react-app ._row_11qff_36._borderless_11qff_41 {
  border-bottom: none;
}

.react-app ._tableData_11qff_45 {
  border: none;
  padding: 0;
  padding: var(--space-more) 0;
  padding-right: var(--space-more);
  text-align: left;
  vertical-align: top;
}

.react-app ._leftmostColumn_11qff_54 {
  padding-left: var(--space-more);
}

.react-app ._actionsColumn_11qff_58 {
  width: 120px;
}
.react-app ._actionsColumn_11qff_58._bigger_11qff_61 {
  width: 230px;
}

.react-app ._stateIconColumn_11qff_65 {
  width: 24px;
}
.react-app ._stateIconColumn_11qff_65 ._placeholder_11qff_68 {
  width: 24px;
}

.react-app ._amountColumn_11qff_72 {
  text-align: right !important;
  width: 136px;
}

.react-app ._arrowIconColumn_11qff_77 {
  width: 16px;
}
.react-app ._arrowIconColumn_11qff_77 ._placeholder_11qff_68 {
  width: 16px;
}

.react-app ._tableData_11qff_45._mobile_11qff_32 {
  padding: var(--space-default) var(--space-less);
}
import React from 'react'
import { Trans } from 'react-i18next'
import { useHistory } from 'react-router'

import { BoxShadow, Link } from '@fullfabric/alma-mater'

import { useSetCurrentStep } from 'apps/Imports/contexts/steps'

import styles from './styles.module.scss'

const SummarySection = ({ linkTo, children, stepId }) => {
  const history = useHistory()
  const setCurrentStep = useSetCurrentStep()

  const goToStep = React.useCallback(
    (evt) => {
      evt.preventDefault()
      stepId && setCurrentStep(stepId)
      history.push(linkTo)
    },
    [history, linkTo, setCurrentStep, stepId]
  )

  return (
    <BoxShadow type='3' className={styles.section}>
      <Link onClick={goToStep} className={styles.editLink}>
        <Trans>Edit</Trans>
      </Link>
      {children}
    </BoxShadow>
  )
}

export default SummarySection

.react-app ._container_wxxm6_1 {
  width: 84%;
  margin: 0 auto;
}

.react-app ._content_wxxm6_6 {
  height: 100%;
  max-height: 100vh;
  box-shadow: var(--shadow-4);
  border-radius: 8px;
}

.react-app ._blinking_wxxm6_13 {
  animation: _blink-animation_wxxm6_1 1s steps(2, start) infinite;
}

@keyframes _blink-animation_wxxm6_1 {
  to {
    visibility: hidden;
  }
}
import qs from 'qs'

import { utils } from '@fullfabric/public-api'

const getOffers = async (options) => {
  const params = qs.stringify(options)
  const response = await fetch(`/offers/api/offers?${params}`, {
    credentials: 'same-origin'
  })
  return await utils.checkResponse(response)
}

export default getOffers

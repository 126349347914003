import { utils } from '@fullfabric/public-api'

const getCampuses = async () => {
  const response = await fetch(`/api/campuses`, {
    credentials: 'same-origin'
  })

  return await utils.checkResponse(response)
}

export default getCampuses

/* eslint-disable no-prototype-builtins */

import React from 'react'
import { Trans } from 'react-i18next'
import { useQuery } from 'react-query'

import { BoxShadow } from '@fullfabric/alma-mater'

import getProfile from 'apps/MergeProfiles/api/getProfile'
import { useMergeData } from 'apps/MergeProfiles/contexts/MergeData'
import capitalizeString from 'apps/MergeProfiles/utils/capitalizeString'
import { formatRoleState } from 'apps/MergeProfiles/utils/formatRoleState'
import {
  getRoleContextName,
  getRoleContextSubtitle
} from 'apps/MergeProfiles/utils/journeyHelpers'
import Actions from './Actions'
import HasAssociatedContextInApplicsNotice from './HasAssociatedContextInApplicsNotice'
import InfoRow from './InfoRow'
import Notice from './Notice'
import OtherInfoRow from './OtherInfoRow'
import Title from './Title'

import classNames from 'classnames'
import styles from './styles.module.scss'

const JourneyCard = ({ role, context, profileType, summary, summaryType }) => {
  const { data: owner, isLoading: ownerLoading } = useQuery(
    ['getProfile', role.owner_id, 'full:false'],
    () => getProfile(role.owner_id, { full: false })
  )
  const {
    mergeData: { journeysFromApplics }
  } = useMergeData()

  const contextName = getRoleContextName(context)

  return (
    <BoxShadow
      type={summary ? '0' : '2'}
      rounded={!summary}
      bgColor='base-lightest'
      className={classNames(
        styles.journeyCard,
        summaryType === 'added' && styles.journeyAdded,
        summaryType === 'discarded' && styles.journeyDiscarded
      )}
      role='cell'
      aria-label={
        summary
          ? `${
              summaryType ? summaryType + ' ' : ''
            }journey summary for ${contextName}`
          : `${profileType} profile journey for ${contextName}`
      }
    >
      {!summary && journeysFromApplics.hasOwnProperty(role.context_id) && (
        <HasAssociatedContextInApplicsNotice
          discarded={journeysFromApplics[role.context_id] !== profileType}
        />
      )}

      <Title
        title={contextName}
        subtitle={getRoleContextSubtitle(context)}
        big={summary}
        summaryType={summaryType}
        notice={
          !summary && <Notice context={context} profileType={profileType} />
        }
      />

      <div className={styles.cardInfoRegion}>
        <InfoRow
          title={<Trans>Lifecycle status</Trans>}
          value={formatRoleState(role)}
        />

        <InfoRow
          title={<Trans>Potential</Trans>}
          value={capitalizeString(role.potential)}
        />

        <InfoRow
          title={<Trans>Owner</Trans>}
          isLoading={ownerLoading}
          value={owner?.full_name}
        />

        <OtherInfoRow role={role} />
      </div>

      {!summary && <Actions context={context} profileType={profileType} />}
    </BoxShadow>
  )
}

export default JourneyCard

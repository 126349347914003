import { utils } from '@fullfabric/public-api'

export default async function createApplicTemplate(data) {
  const response = await fetch('/api/applics/templates', {
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json'
    },
    method: 'POST',
    body: JSON.stringify({ template: data })
  })

  return await utils.checkResponse(response)
}

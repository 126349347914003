import React, { useEffect, useState } from 'react'
import { Trans } from 'react-i18next'

import { Text, useScreenClass } from '@fullfabric/alma-mater'

import useIsMobileLayout from 'apps/ContentPages/hooks/use-is-mobile-layout'
import AlertIcon from './alert-icon'
import ArrowIcon from './arrow-icon'
import ResendVerificationEmail from './resend-verification-email'
import { hideEmail } from './utils'

import classNames from 'classnames'
import styles from './styles.module.scss'

const EmailValidationAccordeon = ({ user }) => {
  const screenClass = useScreenClass()
  const isMobileLayout = useIsMobileLayout()
  const [open, setOpen] = useState(false)

  useEffect(() => {
    setOpen(false)
  }, [])

  return (
    <>
      <button className={styles.menuToggle} onClick={() => setOpen(!open)}>
        <div
          className={classNames([
            styles.link,
            screenClass === 'md' && styles.tablet,
            open && styles.open
          ])}
        >
          <span
            className={classNames([
              styles.icon,
              isMobileLayout && styles.mobile
            ])}
          >
            <AlertIcon />
          </span>
          <Text
            type='h6'
            fontColor={open ? 'text-base-lightest' : 'text-alert'}
            tag='span'
            className={styles.textContainer}
          >
            <Trans>Email not verified</Trans>
            <span
              className={classNames([styles.arrowSmall, open && styles.open])}
            >
              <ArrowIcon />
            </span>
          </Text>
        </div>
      </button>
      {open && (
        <div className={styles.accordionContent}>
          <Text type='f5' fontColor='text-base-darkest'>
            <Trans>
              A verification email was sent to{' '}
              {{ email: hideEmail(user.email) }}.
            </Trans>
          </Text>
          <Text type='f5' fontColor='text-base-darkest' marginBottom='small'>
            <Trans>Please check your email.</Trans>
          </Text>
          <ResendVerificationEmail />
        </div>
      )}
    </>
  )
}

export default EmailValidationAccordeon

import React from 'react'
import { Trans } from 'react-i18next'

import useFetchedImportData from 'apps/Imports/components/import-report-step/hooks/use-fetched-import-data'
import { useImportContext } from 'apps/Imports/hooks/useImportContext'
import {
  ATTENDANCE_TRANSLATIONS,
  RESPONSE_TYPE_TRANSLATIONS
} from 'apps/Imports/utils/profile-import/constants'
import ReportSummaryField from '../../report-summary-field'

const EventContextSummary = () => {
  const { document } = useFetchedImportData()
  const context = useImportContext()

  const eventProcessor = document?.processors?.find(
    ({ type }) => type === 'ImportService::Processors::EventRegistration'
  )

  const responseType = eventProcessor?.options?.response
  const attendance = eventProcessor?.options?.attendance

  return (
    <>
      {context._backbone_type === 'Events.Models.Event' ? (
        <ReportSummaryField
          label={<Trans>Event</Trans>}
          value={context.name}
          marginTop='more'
          marginBottom='small'
        />
      ) : null}

      {responseType ? (
        <ReportSummaryField
          label={<Trans>Registration Response</Trans>}
          value={RESPONSE_TYPE_TRANSLATIONS[responseType]}
          marginBottom='small'
        />
      ) : null}

      {attendance ? (
        <ReportSummaryField
          label={<Trans>Attendance</Trans>}
          value={ATTENDANCE_TRANSLATIONS[attendance]}
          marginBottom='small'
        />
      ) : null}
    </>
  )
}

export default EventContextSummary

import React from 'react'
import { Route, Switch } from 'react-router'

import { TargetSubjectProvider } from 'apps/Subjects/contexts/TargetSubjectContext'
import CreateSubjectPage from 'apps/Subjects/pages/CreateSubject'
import SubjectDetailsPage from 'apps/Subjects/pages/SubjectDetails'
import SubjectsListPage from 'apps/Subjects/pages/SubjectsList'

export const SOURCE_ROUTE = '/institutions/settings#programmes'
export const HOME_ROUTE = '/institutions/settings/subjects/'
export const SUBJECT_LIST_ROUTE = HOME_ROUTE
export const NEW_SUBJECT_ROUTE = '/institutions/settings/subjects/new'
export const SUBJECT_DETAILS_ROUTE = '/institutions/settings/subjects/:id'

const SubjectsAppRoutes = () => {
  return (
    <TargetSubjectProvider>
      <Switch>
        <Route exact path={HOME_ROUTE} component={SubjectsListPage} />
        <Route exact path={NEW_SUBJECT_ROUTE} component={CreateSubjectPage} />
        <Route
          exact
          path={SUBJECT_DETAILS_ROUTE}
          component={SubjectDetailsPage}
        />
      </Switch>
    </TargetSubjectProvider>
  )
}

export default SubjectsAppRoutes

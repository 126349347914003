import React from 'react'
import { Trans } from 'react-i18next'

import { Button, Text } from '@fullfabric/alma-mater'

import { useTypeOfWidget } from '../../../type-of-widget-context'

import classNames from 'classnames'
import styles from './styles.module.scss'

const SubmittedApplication = ({
  withChosenContext,
  applicationContext,
  onViewApplication,
  last
}) => {
  const { isRegistration } = useTypeOfWidget()
  const contextLabel =
    applicationContext &&
    `${applicationContext.context_parent_name} ${applicationContext.context_name}`

  return (
    <div
      className={classNames(
        withChosenContext && !last && styles.spacedContainer
      )}
    >
      {contextLabel ? (
        <Text type='f5' fontColor='text-base-darkest' marginBottom='tiny'>
          {contextLabel}
        </Text>
      ) : null}
      <Button size='small' onClick={onViewApplication}>
        {isRegistration ? (
          <Trans>View registration</Trans>
        ) : (
          <Trans>View application</Trans>
        )}
      </Button>
    </div>
  )
}

export default SubmittedApplication

import { utils } from '@fullfabric/public-api'

const createImport = async (data) => {
  const params = {
    credentials: 'same-origin',
    method: 'POST',
    body: data
  }

  const response = await fetch('/api/import', params)
  return await utils.checkResponse(response)
}

export default createImport

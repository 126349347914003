import React, { useState } from 'react'

import PropTypes from 'prop-types'

import { Button } from '@fullfabric/alma-mater'

import classNames from 'classnames'
import styles from './styles.module.scss'

const icon = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
  >
    <defs>
      <path
        id='a'
        d='M11.52 2.94c0 3.653-1.31 6.73-3.579 8.82H4.064V8.748a5.522 5.522 0 0 0 2.395-3.621H0V.485h11.301a13.7 13.7 0 0 1 .219 2.455z'
      />
      <path
        id='c'
        d='M11 10.667c-4.69 0-8.75-2.69-10.724-6.611V.946h4.01C5.228 3.784 7.874 5.894 11 5.894c1.615 0 2.99-.425 4.064-1.145l3.877 3.012C16.956 9.592 14.24 10.667 11 10.667z'
      />
      <path
        id='e'
        d='M5.285 7.947l-4.009 3.109A12.007 12.007 0 0 1 0 5.666C0 3.732.464 1.898 1.276.278h4.01v3.11c-.24.72-.377 1.489-.377 2.28 0 .79.136 1.56.376 2.28z'
      />
      <path
        id='g'
        d='M11 4.773c-3.125 0-5.77 2.111-6.715 4.947L.276 6.61A11.997 11.997 0 0 1 11 0c3.235 0 5.95 1.19 8.03 3.125l-3.443 3.442C14.344 5.379 12.76 4.773 11 4.773z'
      />
    </defs>
    <g fill='none' fillRule='evenodd'>
      <g transform='translate(12 9.333)'>
        <mask id='b' fill='#fff'>
          <use xlinkHref='#a' />
        </mask>
        <path
          fill='#318AF5'
          d='M-.027 11.893h11.68V.373H-.027z'
          mask='url(#b)'
        />
      </g>
      <g transform='translate(1 13.333)'>
        <mask id='d' fill='#fff'>
          <use xlinkHref='#c' />
        </mask>
        <path fill='#3DA751' d='M.253 10.773h18.72V.853H.253z' mask='url(#d)' />
      </g>
      <g transform='translate(0 6.333)'>
        <mask id='f' fill='#fff'>
          <use xlinkHref='#e' />
        </mask>
        <path
          fill='#FDB900'
          d='M-.027 11.213h5.44V.173h-5.44z'
          mask='url(#f)'
        />
      </g>
      <g transform='translate(1)'>
        <mask id='h' fill='#fff'>
          <use xlinkHref='#g' />
        </mask>
        <path fill='#EA3E36' d='M.253 9.867h18.88v-9.92H.253z' mask='url(#h)' />
      </g>
    </g>
  </svg>
)

const iconHover = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
  >
    <defs>
      <path
        id='a'
        d='M11.52 2.94c0 3.653-1.31 6.73-3.579 8.82H4.064V8.748a5.522 5.522 0 0 0 2.395-3.621H0V.485h11.301a13.7 13.7 0 0 1 .219 2.455z'
      />
      <path
        id='c'
        d='M11 10.667c-4.69 0-8.75-2.69-10.724-6.611V.946h4.01C5.228 3.784 7.874 5.894 11 5.894c1.615 0 2.99-.425 4.064-1.145l3.877 3.012C16.956 9.592 14.24 10.667 11 10.667z'
      />
      <path
        id='e'
        d='M5.285 7.947l-4.009 3.109A12.007 12.007 0 0 1 0 5.666C0 3.732.464 1.898 1.276.278h4.01v3.11c-.24.72-.377 1.489-.377 2.28 0 .79.136 1.56.376 2.28z'
      />
      <path
        id='g'
        d='M11 4.773c-3.125 0-5.77 2.111-6.715 4.947L.276 6.61A11.997 11.997 0 0 1 11 0c3.235 0 5.95 1.19 8.03 3.125l-3.443 3.442C14.344 5.379 12.76 4.773 11 4.773z'
      />
    </defs>
    <g fill='none' fillRule='evenodd'>
      <g transform='translate(12 9.333)'>
        <mask id='b' fill='#fff'>
          <use xlinkHref='#a' />
        </mask>
        <path fill='#FFF' d='M-.027 11.893h11.68V.373H-.027z' mask='url(#b)' />
      </g>
      <g transform='translate(1 13.333)'>
        <mask id='d' fill='#fff'>
          <use xlinkHref='#c' />
        </mask>
        <path fill='#FFF' d='M.253 10.773h18.72V.853H.253z' mask='url(#d)' />
      </g>
      <g transform='translate(0 6.333)'>
        <mask id='f' fill='#fff'>
          <use xlinkHref='#e' />
        </mask>
        <path fill='#FFF' d='M-.027 11.213h5.44V.173h-5.44z' mask='url(#f)' />
      </g>
      <g transform='translate(1)'>
        <mask id='h' fill='#fff'>
          <use xlinkHref='#g' />
        </mask>
        <path fill='#FFF' d='M.253 9.867h18.88v-9.92H.253z' mask='url(#h)' />
      </g>
    </g>
  </svg>
)

const GoogleButton = ({ className, ...props }) => {
  const [hover, setHover] = useState(false)
  return (
    <Button
      type='outline'
      className={classNames(
        styles.thirdPartyLoginButton,
        styles['google-button'],
        className
      )}
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      {...props}
    >
      {hover ? iconHover : icon}
    </Button>
  )
}

GoogleButton.propTypes = {
  className: PropTypes.string
}

export default GoogleButton

import React from 'react'
import { Trans } from 'react-i18next'

import { Text } from '@fullfabric/alma-mater'
import { useCurrentUser } from '@fullfabric/authorization-officer'

import InboxCardProfileTooltip from 'apps/Inbox/components/InboxCardProfileTooltip'

export default function InboxCardSubject({ actor }) {
  const currentUser = useCurrentUser()

  if (actor.id === currentUser.id) {
    return (
      <Text
        type='f5'
        tag='span'
        fontColor='text-base-darkest'
        marginTop='small'
      >
        <Trans>You</Trans>{' '}
      </Text>
    )
  }

  return (
    <>
      <InboxCardProfileTooltip actor={actor} />{' '}
    </>
  )
}

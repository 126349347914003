import { utils } from '@fullfabric/public-api'

const addApplicDiscount = async ({ applicId, code }) => {
  const data = {
    payable_id: applicId,
    payable_type: 'Applics::Applic',
    code
  }

  const response = await fetch('/book_keeper/api/discounts', {
    credentials: 'same-origin',
    headers: { 'Content-Type': 'application/json' },
    method: 'POST',
    body: JSON.stringify(data)
  })

  return await utils.checkResponse(response)
}

export default addApplicDiscount

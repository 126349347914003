import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'

import {
  Button,
  Modal,
  ModalButtons,
  ModalTitle,
  Text
} from '@fullfabric/alma-mater'

import deleteSubject from 'apps/Subjects/api/deleteSubject'
import removeEntryFromQuery from './removeEntryFromQuery'

export const SUBJECTS_TABLE_QUERY_KEY_PART = [
  'tableDataFetch',
  '/api/tabular_data/subjects'
]

const DeleteSubjectConfirmationModal = ({
  isOpen,
  handleClose,
  subjectId,
  subjectName
}) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const deleteSubjectMutation = useMutation(() => deleteSubject(subjectId), {
    onSuccess: () => {
      const [cachedQuery] = queryClient.getQueriesData(
        SUBJECTS_TABLE_QUERY_KEY_PART
      )

      removeEntryFromQuery({
        queryFilter: SUBJECTS_TABLE_QUERY_KEY_PART,
        cachedQuery,
        queryClient,
        entry: { id: subjectId }
      })

      handleClose()
    }
  })

  return (
    <Modal
      role='dialog'
      aria-modal='true'
      aria-labelledby='deleteSubjectConfirmationModal'
      size='medium'
      isOpen={isOpen}
      header={
        <ModalTitle
          title={
            <span id='deleteSubjectConfirmationModal'>
              {t('Delete subject')}
            </span>
          }
          onClose={handleClose}
        />
      }
      footer={
        <ModalButtons>
          <Button
            disabled={deleteSubjectMutation.isLoading}
            onClick={handleClose}
          >
            {t('Cancel')}
          </Button>
          <Button
            primary
            negative
            loading={deleteSubjectMutation.isLoading}
            onClick={() => deleteSubjectMutation.mutate(subjectId)}
          >
            {t('I understand the consequences, delete this subject')}
          </Button>
        </ModalButtons>
      }
    >
      <Text type='h4'>
        <Trans>Are you ABSOLUTELY sure?</Trans>
      </Text>
      <Text type='f5'>
        <Trans>This action CANNOT be undone. This action will:</Trans>
      </Text>
      <ul>
        <Text tag='li'>
          <Trans>Permanently delete the subject {{ subjectName }}</Trans>
        </Text>
        <Text tag='li'>
          <Trans>Permanently delete all courses under this subject</Trans>
        </Text>
        <Text tag='li'>
          <Trans>
            Permanently remove all profiles from courses under this subject
          </Trans>
        </Text>
        <Text tag='li'>
          <Trans>
            Permanently delete all academic information for courses under this
            subject
          </Trans>
        </Text>
        <Text tag='li'>
          <Trans>
            Permanently delete all study plan subjects for this subject
          </Trans>
        </Text>
        <Text tag='li'>
          <Trans>
            Permanently delete all transcript courses for this subject
          </Trans>
        </Text>
      </ul>
    </Modal>
  )
}

export default DeleteSubjectConfirmationModal

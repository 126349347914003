import React from 'react'
import { Trans } from 'react-i18next'

import { InfoIcon, Text } from '@fullfabric/alma-mater'

import styles from './styles.module.scss'

const NoJourneysText = () => {
  return (
    <div className={styles.journeysTabContainer} role='rowgroup'>
      <Text
        type='h4'
        fontColor='text-base-darkest'
        className={styles.noJourneys}
        role='row'
      >
        <InfoIcon width={16} height={16} />
        <Trans>No journeys</Trans>
      </Text>
    </div>
  )
}

export default NoJourneysText

const isClosingDate = (state) => state === 'extended' || state === 'open'

const isOpeningDate = (state) => state === 'upcoming'

const isPastDate = (state) => state === 'closed'

const getLabelText = (state, dateAndTimeText, isRegistration, t) => {
  if (isClosingDate(state)) {
    return t('Closes {{ dateAndTimeText }}', { dateAndTimeText })
  }

  if (isOpeningDate(state)) {
    return t('Opens {{ dateAndTimeText }}', { dateAndTimeText })
  }

  if (isPastDate(state)) {
    return isRegistration
      ? t('Registrations are closed')
      : t('Applications are closed')
  }

  return null
}

export default getLabelText

import React from 'react'
import { useTranslation } from 'react-i18next'

import moment from 'moment'

import XAxisTick from './XAxisTick'

const DatesXAxisTick = ({ dateFormat, payload, ...otherProps }) => {
  const { i18n } = useTranslation()
  const date = moment(payload.value).locale(i18n.language)

  return <XAxisTick customValue={date.format(dateFormat)} {...otherProps} />
}

export default DatesXAxisTick

import React, { useReducer } from 'react'
import { Trans } from 'react-i18next'
import { useMutation } from 'react-query'

import {
  Alert,
  Button,
  ButtonGroup,
  Modal,
  Text,
  TextInput
} from '@fullfabric/alma-mater'

import { useAppData } from 'shared/contexts/AppData'

import updateEventResponse from 'apps/Events/api/updateEventResponse'
import { useCurrentFeedbackMessage } from 'apps/Events/components/FeedbackMessageProvider'
import { useLatestEventResponse } from 'apps/Events/contexts/QRCodeScanResultContext'
import { GUEST_COUNT_OPTIONS } from './constants'
import { GUEST_COUNT_INITIAL_STATE, guestCountReducer } from './reducers'
import { isNormalInteger } from './utils'

import styles from './styles.module.scss'

const AskGuestCountModal = ({ isOpen, onClose }) => {
  const { event } = useAppData()
  const { latestEventResponse } = useLatestEventResponse()
  const [state, dispatch] = useReducer(
    guestCountReducer,
    GUEST_COUNT_INITIAL_STATE
  )
  const feedbackMessage = useCurrentFeedbackMessage()

  const { mutate: onConfirm, isLoading: isConfirmInProgress } = useMutation(
    async ({ eventId, evtResponseId, numberOfGuests }) => {
      if (!evtResponseId) return

      return await updateEventResponse(eventId, evtResponseId, {
        number_of_guests: numberOfGuests
      })
    },
    {
      onSuccess: () => {
        onClose()
      }
    }
  )

  return (
    <Modal
      size='complete'
      isOpen={isOpen}
      contentClassName={styles.contentContainer}
    >
      <div style={{ width: '100%' }}>
        {feedbackMessage ? <Alert success message={feedbackMessage} /> : null}
        {/* <Banner
        type={feedback.type}
        title={<Trans id={feedback.title} tag={null} />}
        message={<Trans id={feedback.message} tag={null} />}
        className={styles.feedback}
        onClose={onFeedbackClose}
      /> */}
        <div className={styles.content}>
          <Text
            type='h2'
            fontColor='text-base-darkest'
            className={styles.inputLabel}
          >
            <Trans>How many guests have you brought to the event?</Trans>
          </Text>

          <div className={styles.inputsContainer}>
            <ButtonGroup
              options={GUEST_COUNT_OPTIONS}
              value={state.value}
              onClick={(value) => dispatch({ type: 'value_change', value })}
            />
            <TextInput
              className={styles.numberTextInput}
              placeholder='-'
              aria-label='Other number of guests'
              hasError={state.numberError}
              value={state.numberValue}
              onFocus={() => dispatch({ type: 'number_change', value: '' })}
              onChange={(evt) => {
                const value = evt.currentTarget.value

                if (isNormalInteger(value) || value === '') {
                  dispatch({ type: 'number_change', value })
                } else {
                  dispatch({ type: 'not_a_number_error' })
                }
              }}
            />
          </div>
        </div>

        <div className={styles.footer}>
          <Button
            primary
            type='submit'
            className={styles.actionButton}
            onClick={() => {
              onConfirm({
                eventId: event.id,
                evtResponseId: latestEventResponse?.id,
                numberOfGuests:
                  state.value !== 'other'
                    ? state.value
                    : parseInt(state.numberValue)
              })
            }}
            disabled={
              state.numberError ||
              (state.value === 'other' && !state.numberValue)
            }
            loading={isConfirmInProgress}
          >
            <Trans>Confirm</Trans>
          </Button>
          <Button onClick={onClose}>
            <Trans>Cancel</Trans>
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default AskGuestCountModal

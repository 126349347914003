import React from 'react'

import { BoxShadow, useScreenClass } from '@fullfabric/alma-mater'

import styles from './styles.module.scss'

const WidgetContainer = ({ children, id }) => {
  const screenClass = useScreenClass()
  const isMobileLayout = ['xs', 'sm', 'md'].includes(screenClass)

  return (
    <BoxShadow
      type={isMobileLayout ? '2' : '4'}
      className={styles.widgetContainer}
      data-testid='widget-container'
    >
      {children}
    </BoxShadow>
  )
}

export default WidgetContainer

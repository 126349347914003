import React from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'

import { Dropdown, IconButton, MoreIcon } from '@fullfabric/alma-mater'

import editBillingActivity from 'apps/Billing/api/editBillingActivity'
import { getApplicsUnderContractQueryKeyFilter } from 'apps/Billing/constants/queryParts'
import updateApplicInQuery from './updateApplicInQuery'

const MoreOptionsCell = ({ row, data, contractId }) => {
  const applicationData = data[row.index]
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  const editBillableAttributeMutation = useMutation(
    ({ billable }) => editBillingActivity(applicationData.id, billable),
    {
      onSuccess: (editedApplic) => {
        const [cachedQuery] = queryClient.getQueriesData(
          getApplicsUnderContractQueryKeyFilter(contractId)
        )

        updateApplicInQuery({
          queryFilter: getApplicsUnderContractQueryKeyFilter(contractId),
          cachedQuery,
          queryClient,
          updatedEntry: editedApplic
        })
      }
    }
  )

  return (
    <Dropdown>
      <Dropdown.Toggle>
        <IconButton plain>
          <MoreIcon />
        </IconButton>
      </Dropdown.Toggle>

      <Dropdown.Menu aria-label={t('Row options menu')}>
        {applicationData.billable ? (
          <Dropdown.Menu.Option
            onClick={() =>
              editBillableAttributeMutation.mutate({ billable: false })
            }
            aria-labelledby='markAsNonBillableOptionText'
          >
            <span id='markAsNonBillableOptionText'>
              {t('Mark as non-billable')}
            </span>
          </Dropdown.Menu.Option>
        ) : (
          <Dropdown.Menu.Option
            onClick={() =>
              editBillableAttributeMutation.mutate({ billable: true })
            }
            aria-labelledby='markAsBillableOptionText'
          >
            <span id='markAsBillableOptionText'>{t('Mark as billable')}</span>
          </Dropdown.Menu.Option>
        )}
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default MoreOptionsCell

import React from 'react'

import { Text } from '@fullfabric/alma-mater'
import { useSettings } from '@fullfabric/authorization-officer'

import styles from './styles.module.scss'

const CardInfoBulletLocalized = ({ main, secondary }) => {
  const settings = useSettings()

  return (
    <>
      {settings.hash.locales.map((locale, index) => (
        <Text
          key={index}
          type='f4'
          fontColor='text-base-darkest'
          className={
            index === settings.hash.locales.length - 1
              ? styles.marginBottom
              : null
          }
          marginBottom='tiny'
        >
          <span className={styles.locale}>
            {locale.split('-')[0].toUpperCase()}
          </span>{' '}
          <span
            className={index === 0 ? styles.mainLocale : styles.secondaryLocale}
          >
            {main[locale]}
          </span>
          {index === 0 && secondary ? (
            <span className={styles.textBaseColor}> {secondary}</span>
          ) : null}
        </Text>
      ))}
    </>
  )
}

export default CardInfoBulletLocalized

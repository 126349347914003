import React, { useEffect } from 'react'
import { Trans } from 'react-i18next'

import { AddIcon, Col, Field, Link, Row } from '@fullfabric/alma-mater'

import styles from './styles.module.scss'

const ERROR_MESSAGES = {
  blank: <Trans>Can't be blank</Trans>,
  invalid_code: <Trans>Code already exists</Trans>
}

const SubjectField = ({
  data = {},
  onChange,
  errors = {},
  isNewSubject,
  loading,
  subjectList,
  getSubjects,
  onNewSubjectClick
}) => {
  useEffect(() => {
    getSubjects()
  }, [getSubjects])

  return (
    <>
      <Link className={styles.createNewSubject} onClick={onNewSubjectClick}>
        {isNewSubject ? (
          <Trans>Select an existing subject</Trans>
        ) : (
          <Trans>Create new subject</Trans>
        )}
        <AddIcon />
      </Link>

      {isNewSubject ? (
        <Row>
          <Col xs={4}>
            <Field
              id='subject_code'
              type='text'
              label={<Trans>Add a subject code</Trans>}
              onChange={onChange}
              error={ERROR_MESSAGES[errors.subject_code]}
              value={data.subject_code}
              required
            />
          </Col>

          <Col xs={8}>
            <Field
              id='subject_name'
              type='text'
              label={<Trans>Add a subject name</Trans>}
              onChange={onChange}
              value={data.subject_name}
              error={ERROR_MESSAGES[errors.subject_name]}
              required
            />
          </Col>
        </Row>
      ) : (
        <Field
          id='subject_id'
          type='autocomplete'
          label={<Trans>Select a subject</Trans>}
          required
          error={ERROR_MESSAGES[errors.subject_id]}
          onChange={onChange}
          value={data.subject_id}
          inputOptions={{
            placeholder: <Trans>- Select -</Trans>,
            isLoading: loading,
            options: subjectList.map((subject) => ({
              value: subject.id,
              label: `${subject.code} - ${subject.name}`
            }))
          }}
        />
      )}
    </>
  )
}

export default SubjectField

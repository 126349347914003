import React from 'react'
import { Trans } from 'react-i18next'

import { startCase } from 'lodash'

import useFetchedImportData from 'apps/Imports/components/import-report-step/hooks/use-fetched-import-data'
import { useImportContext } from 'apps/Imports/hooks/useImportContext'
import { useStaffs } from 'apps/Imports/hooks/useStaffs'
import ReportSummaryField from '../../report-summary-field'

const ClassOfContextSummary = () => {
  const { document } = useFetchedImportData()
  const context = useImportContext()
  const staffs = useStaffs()

  const roleProcessor = document?.processors?.find(
    ({ type }) => type === 'ImportService::Processors::Role'
  )
  const [state, substate] =
    roleProcessor?.options?.state_and_substate?.split('::') || []

  const ownerProcessor = document?.processors?.find(
    ({ type }) => type === 'ImportService::Processors::Owner'
  )
  const ownerId = ownerProcessor?.options?.owner
  const owner = ownerId && staffs.find((staff) => staff.id === ownerId)

  // <ProgrammeAndClassField marginTop="more" marginBottom="small" />
  //   <StateAndSubstateField marginBottom="small" />
  //   <OwnerField />

  //   return {
  //     label: <Trans>Owner</Trans>,
  //     value: owner.full_name
  //   }
  // }

  // export default createReportPageField(mapStateToProps)

  return (
    <>
      {document.context_type === 'Institutions::ClassOf' ? (
        <ReportSummaryField
          label={<Trans>Programme and Class</Trans>}
          value={context.name_with_programme}
          marginTop='more'
          marginBottom='small'
        />
      ) : null}

      {roleProcessor?.options?.state_and_substate ? (
        <ReportSummaryField
          label={<Trans>State and Substate</Trans>}
          value={`${startCase(state)}::${startCase(substate)}`}
          marginBottom='small'
        />
      ) : null}

      {owner ? (
        <ReportSummaryField
          label={<Trans>Owner</Trans>}
          value={owner.full_name}
        />
      ) : null}
    </>
  )
}

export default ClassOfContextSummary

import React from 'react'

const TypeOfWidgetContext = React.createContext()

export const TypeOfWidgetProvider = ({
  isRegistration,
  isApplication,
  ...props
}) => (
  <TypeOfWidgetContext.Provider
    value={{ isRegistration, isApplication }}
    {...props}
  />
)

export const useTypeOfWidget = () => {
  const context = React.useContext(TypeOfWidgetContext)

  if (typeof context === 'undefined') {
    throw new Error('useTypeOfWidget must be within a TypeOfWidgetProvider')
  }

  return context
}

/**
 * Returns target text from array of localized content in the desired locale
 * @param {Array<Object>} locales Array of localized content, as usually comes from the backend (e.g: [{locale: 'en', title: 'English title'}])
 * @param {String} targetLocale The locale code (e.g: 'EN')
 * @param {String} targetText The property name of the text to extract
 */

const getLocalizedText = (
  locales = [],
  targetLocale,
  targetText,
  fallbackLocale = null
) => {
  const localizedContainer =
    locales?.find((textContent) => textContent.locale === targetLocale) ||
    locales?.find((textContent) => textContent.locale === fallbackLocale) ||
    {}

  return localizedContainer[targetText]
}

export default getLocalizedText

/* eslint-disable camelcase */

import { utils } from '@fullfabric/public-api'

import sortStrings from 'shared/utils/sortStrings'

const getApplicTemplates = async () => {
  const response = await fetch('/api/applics/templates?opened_only=true', {
    credentials: 'same-origin'
  })
  const result = await utils.checkResponse(response)
  return result
    .map(({ id, long_name, name, context_type }) => ({
      value: id,
      label: context_type === 'Institutions::Institution' ? name : long_name
    }))
    .sort((a, b) => sortStrings(a.label, b.label))
}

export default getApplicTemplates

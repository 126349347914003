/* eslint-disable no-case-declarations */

import { LOCATION_CHANGE } from 'connected-react-router'
import { combineReducers } from 'redux'

import actionTypes from '../../../actions/types'

const data = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.editCapacitySectionData:
      return action.payload
    case actionTypes.cancelEditCapacitySectionData:
    case LOCATION_CHANGE:
      return {}
    case actionTypes.editCourseCapacitySectionDataChanged:
      return { ...state, ...action.payload }
    default:
      return state
  }
}

const isEditing = (state = false, action) => {
  switch (action.type) {
    case actionTypes.editCapacitySectionData:
      return true
    case actionTypes.cancelEditCapacitySectionData:
    case actionTypes.updateCourseCapacitySection.success:
    case LOCATION_CHANGE:
      return false
    default:
      return state
  }
}

const inProgress = (state = false, action) => {
  switch (action.type) {
    case actionTypes.updateCourseCapacitySection.start:
      return true
    case actionTypes.updateCourseCapacitySection.success:
    case actionTypes.updateCourseCapacitySection.error:
      return false
    default:
      return state
  }
}

const errors = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.updateCourseCapacitySection.error:
      if (action.payload && action.payload.validationErrors)
        return { ...state, ...action.payload.validationErrors }
      else return { ...state, ...action.payload }
    case actionTypes.editCourseCapacitySectionDataChanged:
      const key = Object.keys(action.payload)[0]
      const { [key]: keyToRemove, ...newState } = state
      return newState
    case LOCATION_CHANGE:
    case actionTypes.updateCourseCapacitySection.success:
    case actionTypes.cancelEditCapacitySectionData:
      return {}
    default:
      return state
  }
}

export default combineReducers({ data, isEditing, inProgress, errors })

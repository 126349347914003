import React from 'react'
import { useTranslation } from 'react-i18next'

import {
  AlertFullIcon,
  Button,
  ModalButtons,
  ModalTitle,
  Text
} from '@fullfabric/alma-mater'

import unsubmitEvaluation from 'apps/Evaluations/api/unsubmitEvaluation'
import {
  SUBMISSION_ACTIONS,
  useDispatchSubmissionUpdate,
  useEvaluationForm,
  useSetEvaluationForm
} from 'apps/Evaluations/context/EvaluationFormData'

import styles from './styles.module.scss'

const Header = () => {
  const { t } = useTranslation()
  const dispatchSubmissionUpdate = useDispatchSubmissionUpdate()

  return (
    <ModalTitle
      title={
        <>
          <AlertFullIcon color='text-alert' className={styles.alertIcon} />
          <span id='submission-modal-title'>{t('Unsubmit evaluation')}</span>
        </>
      }
      onClose={() => {
        dispatchSubmissionUpdate({ type: SUBMISSION_ACTIONS.CLOSE_MODAL })
      }}
    />
  )
}

const UnsubmissionContent = () => {
  const { t } = useTranslation()

  return (
    <>
      <Text fontColor='text-base-darkest'>{t('This action will')}:</Text>
      <ul className={styles.listItem}>
        <li>
          <Text fontColor='text-base-darkest' marginTop='tiny'>
            {t(
              'Change the evaluation state from submitted to started and make it editable again'
            )}
          </Text>
        </li>
      </ul>
    </>
  )
}

const Footer = () => {
  const { t } = useTranslation()
  const { evaluationFormData } = useEvaluationForm()
  const { setEvaluationFormData } = useSetEvaluationForm()
  const dispatchSubmissionUpdate = useDispatchSubmissionUpdate()

  const handleUnsubmitEvaluation = async () => {
    dispatchSubmissionUpdate({ type: SUBMISSION_ACTIONS.UNSUBMISSION_START })
    const unsubmittedEvaluation = await unsubmitEvaluation(
      evaluationFormData.id,
      evaluationFormData
    )
    setEvaluationFormData(unsubmittedEvaluation)
    dispatchSubmissionUpdate({ type: SUBMISSION_ACTIONS.UNSUBMISSION_SUCCESS })
  }

  return (
    <ModalButtons>
      <Button
        onClick={() => {
          dispatchSubmissionUpdate({ type: SUBMISSION_ACTIONS.CLOSE_MODAL })
        }}
      >
        {t('Cancel')}
      </Button>
      <Button negative primary onClick={handleUnsubmitEvaluation}>
        {t('I understand, unsubmit this evaluation')}
      </Button>
    </ModalButtons>
  )
}

UnsubmissionContent.Header = Header
UnsubmissionContent.Footer = Footer

export default UnsubmissionContent

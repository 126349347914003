import React from 'react'
import { Trans } from 'react-i18next'

import { Badge } from '@fullfabric/alma-mater'

const STATE_MAP = {
  externalPayer: { color: 'purple', translation: 'Sent to payer' },
  pendingPayment: { color: 'yellow', translation: 'Awaiting confirmation' },
  paid: { translation: 'Paid', color: 'green' },
  overdue: { translation: 'Due / Overdue', color: 'red' },
  almostDue: { translation: 'Due soon', color: 'yellow' },
  upcoming: { translation: 'Not paid', color: 'grey' },
  not_paid: { translation: 'Not paid', color: 'grey' }
}

const getState = (
  stateOfFee,
  hasPendingPayment,
  hasExternalPayerRequest,
  isExternalPayerPayment
) => {
  if (hasPendingPayment) {
    return 'pendingPayment'
  }

  if (isExternalPayerPayment) {
    return 'not_paid'
  }

  if (hasExternalPayerRequest && stateOfFee !== 'paid') {
    return 'externalPayer'
  }

  return stateOfFee
}

export default function StateBadge({
  stateOfFee,
  hasPendingPayment,
  hasExternalPayerRequest,
  isExternalPayerPayment
}) {
  const state = getState(
    stateOfFee,
    hasPendingPayment,
    hasExternalPayerRequest,
    isExternalPayerPayment
  )
  const { color, translation } = STATE_MAP[state]

  return (
    <Badge type={color}>
      <Trans>{translation}</Trans>
    </Badge>
  )
}

import qs from 'qs'

const getApplicationsByProfile = (email) => {
  const params = qs.stringify({ email })

  return fetch(`/api/applics/applics_by?${params}`, {
    credentials: 'same-origin'
  }).then((result) => result.json())
}

export default getApplicationsByProfile

.react-app ._alertContainer_hmhrg_1 {
  margin-bottom: var(--space-default);
}

.react-app ._alertContainerInCard_hmhrg_5 {
  margin-top: calc(-1 * var(--space-small));
  margin-bottom: var(--space-default);
}

.react-app ._cardTitle_hmhrg_10 {
  margin: 0;
}

.react-app ._title_hmhrg_14 {
  margin-bottom: var(--space-default);
  margin-top: 0;
}

.react-app ._separator_hmhrg_19 {
  margin-left: calc(-1 * var(--space-more));
  width: calc(100% + 2 * var(--space-more)) !important;
}

.react-app ._section_hmhrg_24 {
  padding-top: var(--space-more);
}
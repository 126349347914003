import React from 'react'
import { Trans } from 'react-i18next'

export const STATE_DESCRIPTIONS = {
  init: {
    description: <Trans>Init</Trans>,
    color: 'grey-500',
    labelType: 'grey'
  },
  queued: {
    description: <Trans>Queued</Trans>,
    color: 'yellow-500',
    labelType: 'yellow'
  },
  started: {
    description: <Trans>Started</Trans>,
    color: 'primary-600',
    labelType: 'blue'
  },
  imported: {
    description: <Trans>Imported</Trans>,
    color: 'green-700',
    labelType: 'green'
  },
  aborted: {
    description: <Trans>Aborted</Trans>,
    color: 'red-200',
    labelType: 'red'
  }
}

const arrayAsGroupedOptions = (optionsAsArray) => {
  return optionsAsArray.map(([sectionName, sectionFields]) => ({
    label: sectionName,
    options: sectionFields.map(([fieldLabel, fieldName]) => ({
      label: fieldLabel,
      value: fieldName
    }))
  }))
}

export default arrayAsGroupedOptions

.react-app ._shadow_109fy_1 {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(31, 32, 38, 0.1);
}

.react-app ._pane_109fy_11 {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 2;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  width: 100vw;
  background-color: var(--ff-bg-base-lightest);
}
.react-app ._pane_109fy_11._desktop_109fy_24 {
  max-width: 600px;
}
import moment from 'moment'

const getContractName = (contract) => {
  const startDate = moment(contract.start_date).format('MMMM DD, YYYY')
  const endDate = moment(contract.end_date).format('MMMM DD, YYYY')

  return `${startDate} - ${endDate}`
}

export default getContractName

import React from 'react'

import { isEmpty } from 'lodash-es'

import { useCurrentUser } from '@fullfabric/authorization-officer'

import useIsMobileNavigation from 'apps/ContentPages/hooks/use-is-mobile-navigation'
import EmailValidationAccordeon from './email-validation-accordeon'
import EmailValidationPopUp from './email-validation-popup'

const EmailValidation = () => {
  const user = useCurrentUser()
  const isMobileNavigation = useIsMobileNavigation()

  if (isEmpty(user) || user.has_verified_email) {
    return null
  }

  return isMobileNavigation ? (
    <EmailValidationAccordeon user={user} />
  ) : (
    <EmailValidationPopUp user={user} />
  )
}

export default EmailValidation

import React from 'react'
import { useTranslation } from 'react-i18next'

import { Text } from '@fullfabric/alma-mater'

import getLocaledLabel from 'apps/MergeProfiles/utils/getLocaledLabel'
import SummaryField from './SummaryField'

import styles from '../styles.module.scss'

const SummaryFieldSection = ({ section }) => {
  const { i18n } = useTranslation()

  const sectionName = getLocaledLabel(section.locals, i18n.language)
  const sortedFields = section.fields.sort((a, b) => a.order - b.order)

  return (
    <div className={styles.summaryFieldSection}>
      <Text
        type='f3'
        fontColor='text-base-darkest'
        className={styles.sectionTitle}
      >
        {sectionName}
      </Text>

      {sortedFields.map((field) => (
        <SummaryField key={field.id} field={field} />
      ))}
    </div>
  )
}

export default SummaryFieldSection

import React, { useContext, useState } from 'react'

const StepInAuthentication = React.createContext()
const SetStepInAuthentication = React.createContext()

export function StepInAuthenticationProvider({ children }) {
  const [step, setStep] = useState()

  return (
    <SetStepInAuthentication.Provider value={setStep}>
      <StepInAuthentication.Provider value={step}>
        {children}
      </StepInAuthentication.Provider>
    </SetStepInAuthentication.Provider>
  )
}

export function useStepInAuthentication() {
  return useContext(StepInAuthentication)
}

export function useSetStepInAuthentication() {
  return useContext(SetStepInAuthentication)
}

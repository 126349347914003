import * as React from 'react'

import moment from 'moment'

import { Link } from '@fullfabric/alma-mater'

const defaultFieldInfoStrategy = {
  extractValue: (cell) => cell.value || null,
  extractTitle: (cell) =>
    [cell.value]
      .filter((x) => x)
      .reduce((flat, curr) => flat.concat(curr), [])
      .join(', ')
}

const getSmartEmailValue = (rowData, fieldName, accessorPrefix = '') => {
  const schemableData = accessorPrefix ? rowData[accessorPrefix] : rowData

  const smartEmails = schemableData?.emails || []
  const fieldValue =
    smartEmails.filter((email) => email.name === fieldName)[0] || {}

  return fieldValue.value || null
}
const smartEmailFieldInfoStrategy = {
  extractValue: (cell, field, columnSchema) =>
    getSmartEmailValue(
      cell?.row?.original || {},
      field.name,
      columnSchema?.accessorPrefix
    ),
  extractTitle: (cell, field, columnSchema) =>
    getSmartEmailValue(
      cell?.row?.original || {},
      field.name,
      columnSchema?.accessorPrefix
    )
}

const fileFieldInfoStrategy = {
  extractValue: (cell) => {
    const numUploads = cell.value?.uploads?.length || 0
    return (
      cell.value?.uploads?.map((upload, idx) => (
        <React.Fragment key={idx}>
          <Link secondary key={upload.id} href={`/${upload.path}`}>
            {upload.name}
          </Link>
          {idx !== numUploads - 1 ? <br /> : null}
        </React.Fragment>
      )) || null
    )
  },
  extractTitle: (cell) => {
    return cell.value?.uploads?.map((upload) => upload.name).join(', ')
  }
}

const getDateInfo = (cell) =>
  cell.value ? moment(cell.value).format('dddd ll') : null
const dateFieldInfoStrategy = {
  extractValue: getDateInfo,
  extractTitle: getDateInfo
}

const getDateTimeInfo = (cell) =>
  cell.value ? moment(cell.value).format('dddd ll [at] HH:mm') : null
const dateTimeFieldInfoStrategy = {
  extractValue: getDateTimeInfo,
  extractTitle: getDateTimeInfo
}

const getAddressInfo = (addrValue) => {
  const addr1 = [addrValue?.line_1, addrValue?.line_2, addrValue?.line_3]
    .filter((x) => x)
    .join(', ')
  const addr2 = [addrValue?.city, addrValue?.post_code]
    .filter((x) => x)
    .join(', ')
  const addr3 = [addrValue?.state, addrValue?.country]
    .filter((x) => x)
    .join(', ')

  return [addr1, addr2, addr3]
}
const addressFieldInfoStrategy = {
  extractValue: (cell) => {
    if (!cell.value) return null
    const [addr1, addr2, addr3] = getAddressInfo(cell.value)

    return (
      <React.Fragment>
        {addr1}
        {addr1 && <br />}

        {addr2}
        {addr2 && <br />}

        {addr3}
      </React.Fragment>
    )
  },
  extractTitle: (cell) => {
    if (!cell.value) return null

    const addrInfo = getAddressInfo(cell.value)
    return addrInfo.filter((x) => x).join(', ')
  }
}

const optionFieldInfoStrategy = {
  ...defaultFieldInfoStrategy,
  extractValue: (cell) =>
    [cell.value].reduce((flat, curr) => flat.concat(curr), []).join(', ')
}

const getCascadingDropdownInfo = (value = {}) => {
  if (!value) return null

  return Object.keys(value)
    .filter((key) => key !== '_id')
    .map((key) => value[key])
    .filter((x) => x)
    .join(', ')
}
const cascadingDropdownFieldInfoStrategy = {
  extractValue: (cell) => cell?.value && getCascadingDropdownInfo(cell?.value),
  extractTitle: (cell) => cell?.value && getCascadingDropdownInfo(cell?.value)
}

const matrixFieldsInfoStrategy = {
  extractValue: () => null,
  extractTitle: () => null
}

const fieldInfoStrategyFactory = (field) => {
  switch (field.type) {
    case 'Schemable::Fields::Types::File':
      return fileFieldInfoStrategy
    case 'Schemable::Fields::Types::Date':
      return dateFieldInfoStrategy
    case 'Schemable::Fields::Types::DateTime':
      return dateTimeFieldInfoStrategy
    case 'Schemable::Fields::Types::Address':
      return addressFieldInfoStrategy
    case 'Schemable::Fields::Types::DropDown':
    case 'Schemable::Fields::Types::CheckBox':
      return optionFieldInfoStrategy
    case 'Schemable::Fields::Types::SmartEmail':
      return smartEmailFieldInfoStrategy
    case 'Schemable::Fields::Types::CascadingDropDown':
      return cascadingDropdownFieldInfoStrategy
    case 'Schemable::Fields::Types::Matrix':
    case 'Schemable::Fields::Types::ExtendableMatrix':
      return matrixFieldsInfoStrategy
    default:
      return defaultFieldInfoStrategy
  }
}

export default fieldInfoStrategyFactory

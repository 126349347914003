import getCourseSchema from 'apps/Courses/api/getCourseSchema'
import actionTypes from '../types'

const getCourseSchemaAction = () => async (dispatch, getState) => {
  const {
    editCourse: { schema }
  } = getState()

  if (schema) return

  try {
    dispatch({ type: actionTypes.getCourseSchema.start })
    const schema = await getCourseSchema()
    dispatch({ type: actionTypes.getCourseSchema.success, payload: schema })
  } catch (error) {
    dispatch({ type: actionTypes.getCourseSchema.error, payload: error })
  }
}

export default getCourseSchemaAction

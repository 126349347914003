import React from 'react'
import { Trans } from 'react-i18next'

import { Button } from '@fullfabric/alma-mater'

import { useRegisterEventClickHandler } from 'apps/ContentPages/contexts/register-event-click-handler'

import styles from './styles.module.scss'

const RegisterButton = () => {
  const onClickRegister = useRegisterEventClickHandler()

  return (
    <Button primary className={styles.button} onClick={onClickRegister}>
      <Trans>Register</Trans>
    </Button>
  )
}

export default RegisterButton

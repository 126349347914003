/* eslint-disable camelcase */

import { connect } from 'react-redux'

import newCourseDataChanged from 'apps/Courses/actions/newCourseDataChanged'
import CourseCreateFormBasic from 'apps/Courses/components/CourseCreateFormBasic'

const mapStateToProps = ({
  newCourse: { data, errors },
  appData: { h: { currency_options = [], campuses_options = [] } = {} } = {}
}) => ({
  data,
  errors,
  currencyOptions: currency_options.map(([label, value]) => ({
    value,
    label
  })),
  campusesOptions: campuses_options.map(([label, value]) => ({
    value,
    label
  }))
})

const mapDispatchToProps = (dispatch) => ({
  onChange: (field, value) => dispatch(newCourseDataChanged({ [field]: value }))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CourseCreateFormBasic)

import React from 'react'

import { Row } from '@fullfabric/alma-mater'

import { useMergeData } from 'apps/MergeProfiles/contexts/MergeData'
import JourneySummaryCard from './JourneySummaryCard'

import styles from './styles.module.scss'

const JourneySummaryRow = ({ contextId }) => {
  const {
    mergeData: { sourceProfile, targetProfile, journeysFrom }
  } = useMergeData()

  const targetProfileRole = targetProfile.roles.find(
    (role) => role.context_id === contextId
  )
  const sourceProfileRole = sourceProfile.roles.find(
    (role) => role.context_id === contextId
  )

  const shoudUseDuplicateProfileJourney =
    journeysFrom[contextId] === 'duplicate'

  const keptJourney = shoudUseDuplicateProfileJourney
    ? sourceProfileRole
    : targetProfileRole

  const discardedJourney = shoudUseDuplicateProfileJourney
    ? targetProfileRole
    : null

  if (!keptJourney) return null

  return (
    <Row
      role='row'
      aria-label='journey summary row'
      nogutter
      className={styles.journeySummaryRow}
    >
      <JourneySummaryCard
        role={keptJourney}
        type={shoudUseDuplicateProfileJourney ? 'added' : null}
      />
      {discardedJourney && (
        <JourneySummaryCard role={discardedJourney} type='discarded' />
      )}
    </Row>
  )
}

export default JourneySummaryRow

import getClassGenderDistribution from './getClassGenderDistribution'

const getMultipleClassGenderDistribution = async ({ classIds, ...params }) => {
  const response = await Promise.all(
    classIds.map((classId) =>
      getClassGenderDistribution({ classId, ...params })
    )
  )

  return response
}

export default getMultipleClassGenderDistribution

const formatWithdrawnFilterValue = (rawValue) => {
  switch (rawValue) {
    case 'all':
      return undefined
    case 'true':
      return true
    case 'false':
    default:
      return false
  }
}

export default formatWithdrawnFilterValue

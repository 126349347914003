import React from 'react'
import { Trans } from 'react-i18next'

import { AnnotatedLayout } from '@fullfabric/alma-mater'

const CourseSectionCapacity = ({ children }) => {
  return (
    <section aria-labelledby='capacity-section-title'>
      <AnnotatedLayout.Section
        title={
          <span id='capacity-section-title'>
            <Trans>Capacity</Trans>
          </span>
        }
        description={
          <Trans>
            The capacity is a count of the number of candidates that are in the
            course.
          </Trans>
        }
      >
        {children}
      </AnnotatedLayout.Section>
    </section>
  )
}

export default CourseSectionCapacity

import React from 'react'
import { Trans } from 'react-i18next'

import { ArrowLeftIcon, PageHead } from '@fullfabric/alma-mater'

import { HOME_ROUTE } from 'apps/Subjects/components/AppRoutes'
import ActionButton from '../ActionButton'

const Header = ({ loading }) => {
  return (
    <PageHead
      title={<Trans>New Subject</Trans>}
      action={<ActionButton loading={loading} />}
      breadcrumbs={[
        {
          label: <Trans>Back</Trans>,
          icon: ArrowLeftIcon,
          to: HOME_ROUTE
        }
      ]}
    />
  )
}

export default Header

import React from 'react'
import { useController, useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'

import { Button, Field } from '@fullfabric/alma-mater'

import { useAppData } from 'shared/contexts/AppData'

import createLinkedinAccount from 'apps/Authentication/api/createLinkedinAccount'
import AuthContentLayout from 'apps/Authentication/components/AuthContentLayout'
import FormErrorsAlert from 'apps/Authentication/components/FormErrorsAlert'
import FormTitle from 'apps/Authentication/components/FormTitle'
import PrivacyPolicyField from 'apps/Authentication/components/PrivacyPolicyField'
import locationHelpers from 'apps/Authentication/utils/locationHelpers'

const LinkedinSignupForm = ({ onClickLinkAccount }) => {
  const { t } = useTranslation()
  const { privacy_policy: privacyPolicy, data: storedData } = useAppData()

  const { register, control, formState, handleSubmit } = useForm()
  const { errors: localErrors, isValid, isSubmitted } = formState
  const { ref: firstNameInputRef, ...firstNameInputProps } = register(
    'first_name',
    { required: t('This field is required'), value: storedData?.first_name }
  )
  const { ref: lastNameInputRef, ...lastNameInputProps } = register(
    'last_name',
    { required: t('This field is required'), value: storedData?.last_name }
  )
  const { ref: emailInputRef, ...emailInputProps } = register('email', {
    required: t('This field is required'),
    value: storedData?.email
  })
  const {
    field: { ref: privacyPolicyRef, ...privacyPolicyProps }
  } = useController({
    name: 'privacyPolicy',
    control,
    rules: {
      required: privacyPolicy && t('This field is required')
    }
  })

  const [inProgress, setInProgress] = React.useState(false)
  const failedValidation = isSubmitted && !isValid
  const [errorAlertMessage, setErrorAlertMessage] = React.useState({})
  const [serverErrors, setServerErrors] = React.useState(null)

  const onSubmit = async (formValues) => {
    setInProgress(true)
    setErrorAlertMessage({})

    const { privacyPolicy: privacyPolicyValue, ...signupData } = formValues

    const policyData = { policies: {} }
    if (privacyPolicy) {
      const hasAcceptedPrivacyPolicy =
        privacyPolicyValue && privacyPolicyValue[0] === privacyPolicy.id

      policyData.policies = {
        [privacyPolicy.id]: {
          accepted: hasAcceptedPrivacyPolicy ? 'true' : 'false',
          options: hasAcceptedPrivacyPolicy ? { agreement: 'accepted' } : {}
        }
      }
    }

    try {
      const result = await createLinkedinAccount({
        ...signupData,
        commit: 'Confirm',
        ...policyData
      })
      locationHelpers.goTo(result.redirect_to || '/')
    } catch (error) {
      setInProgress(false)
      const policyError =
        error.data?.policies &&
        error.data.policies[privacyPolicy?.id] &&
        error.data.policies[privacyPolicy?.id][0]

      setErrorAlertMessage({
        title: error.data?.account?.title,
        description: error.data?.account?.base
      })
      setServerErrors({
        ...(error.data ? error.data.profile : {}),
        policyError
      })
    }
  }

  return (
    <>
      <FormTitle
        title={<Trans>Almost there</Trans>}
        subtitle={<Trans>Please confirm your account details below</Trans>}
      />

      <form>
        <FormErrorsAlert
          failedValidation={failedValidation}
          {...errorAlertMessage}
        />

        <Field
          type='text'
          label={<Trans>First name</Trans>}
          {...firstNameInputProps}
          inputRef={firstNameInputRef}
          error={serverErrors?.first_name || localErrors.first_name?.message}
        />

        <Field
          type='text'
          label={<Trans>Last name</Trans>}
          {...lastNameInputProps}
          inputRef={lastNameInputRef}
          error={serverErrors?.last_name || localErrors.last_name?.message}
        />

        <Field
          type='email'
          label={<Trans>E-mail address</Trans>}
          {...emailInputProps}
          inputRef={emailInputRef}
          error={serverErrors?.email || localErrors.email?.message}
        />

        <PrivacyPolicyField
          {...privacyPolicyProps}
          inputRef={privacyPolicyRef}
          error={
            serverErrors?.policyError || localErrors.privacyPolicy?.message
          }
        />

        <Button
          primary
          type='submit'
          loading={inProgress}
          onClick={handleSubmit(onSubmit)}
        >
          <Trans>Create new account</Trans>
        </Button>
      </form>

      <AuthContentLayout.Footer
        question={<Trans>Already have an account?</Trans>}
        linkTo={onClickLinkAccount}
        linkText={t('Link to an existing account')}
      />
    </>
  )
}

export default LinkedinSignupForm

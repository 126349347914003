import { useImportDocument } from 'apps/Imports/contexts/import-document'
import { useSelectedData } from 'apps/Imports/contexts/selected-data'
import { useImportContext } from 'apps/Imports/hooks/useImportContext'
import getRequiredImportFields from 'apps/Imports/utils/get-required-import-fields'

const useRequiredMappingFields = () => {
  const { data } = useSelectedData()
  const context = useImportContext()
  const importDocument = useImportDocument()

  return getRequiredImportFields(importDocument, context, data)
}

export default useRequiredMappingFields

import React, { useLayoutEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router'

const TOTAL_STEPS = 2
export const TEMPLATE_NAME_STEP = 0
export const TEMPLATE_TABS_STEP = 1

export const NavigationStateContext = React.createContext()

export const getCurrentStepNumber = (pathname) => {
  const stepRegex = /\/step\/\d/i
  const match = pathname.match(stepRegex)

  if (!match) {
    return
  }

  return parseInt(match[0].split('/').pop(), 10)
}

export const WizardNavigationProvider = ({ children }) => {
  const { pathname } = useLocation()
  const [currentStep, setCurrentStep] = useState(getCurrentStepNumber(pathname))

  useLayoutEffect(() => {
    setCurrentStep(getCurrentStepNumber(pathname))
  }, [pathname])

  const navigationState = useMemo(
    () => ({ currentStep, totalSteps: TOTAL_STEPS }),
    [currentStep]
  )

  return (
    <NavigationStateContext.Provider value={navigationState}>
      {children}
    </NavigationStateContext.Provider>
  )
}

export const useWizardNavigationState = () => {
  return React.useContext(NavigationStateContext)
}

.react-app ._locale_1lyxv_1 {
  color: var(--ff-color-text-base-darker);
  display: inline-flex;
  vertical-align: bottom;
  margin-right: 5px;
  font-size: 11px;
  font-weight: 600;
}

.react-app ._textBaseColor_1lyxv_10 {
  color: #9399a2;
  margin-left: 5px;
}

.react-app ._marginBottom_1lyxv_15 {
  margin-bottom: 10px !important;
}

.react-app ._mainLocale_1lyxv_19 {
  font-size: 16px;
}

.react-app ._secondaryLocale_1lyxv_23 {
  font-size: 14px;
  font-weight: 400;
}
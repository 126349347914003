import { utils } from '@fullfabric/public-api'

const askResetPassword = async (email) => {
  const response = await fetch('/reset', {
    method: 'POST',
    credentials: 'same-origin',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ email })
  })

  return await utils.checkResponse(response)
}

export default askResetPassword

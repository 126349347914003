import React from 'react'
import { Trans } from 'react-i18next'

import { Alert } from '@fullfabric/alma-mater'

const ImportCreationErrorAlert = () => (
  <Alert
    error
    title={<Trans>Error with importing file.</Trans>}
    description={
      <>
        <Trans>
          Please check for the following problems which could have caused the
          error:
        </Trans>
        <ul>
          <li>
            <Trans>
              Incorrect separator for the .CSV file selected when importing
            </Trans>
          </li>
          <li>
            <Trans>Missing column headers in the .CSV file</Trans>
          </li>
          <li>
            <Trans>
              Select UTF-8 as the encoding when saving the .CSV file
            </Trans>
          </li>
        </ul>
        <Trans>
          Please amend the file as necessary and then upload and import it
          again.
        </Trans>
        <br />
        <i>
          <Trans>
            If you continue to experience issues please contact support.
          </Trans>
        </i>
      </>
    }
  />
)

export default ImportCreationErrorAlert

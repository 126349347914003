import { utils } from '@fullfabric/public-api'

const submitContract = async (data) => {
  const response = await fetch('/billing/api/contracts', {
    credentials: 'same-origin',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  })

  return await utils.checkResponse(response)
}

export default submitContract

import qs from 'qs'

import { utils } from '@fullfabric/public-api'

async function requestProductPerformanceExport(params) {
  const queryParams = qs.stringify(params, { arrayFormat: 'brackets' })

  const response = await fetch(
    `/api/reports/product_performance.xlsx?${queryParams}`,
    {
      credentials: 'same-origin'
    }
  )

  return await utils.checkResponse(response)
}

export default requestProductPerformanceExport

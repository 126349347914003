import React from 'react'

import classNames from 'classnames'
import styles from './styles.module.scss'

const DetailSection = ({ children, noBorder, ...props }) => (
  <div
    {...props}
    className={classNames(styles.section, noBorder && styles.noBorder)}
  >
    {children}
  </div>
)

export default DetailSection

import React from 'react'
import { Trans } from 'react-i18next'

import {
  AlertFullIcon,
  Button,
  Modal,
  ModalButtons,
  ModalTitle,
  Text
} from '@fullfabric/alma-mater'

const DeleteMultiplePathsConfirmationModal = ({
  isOpen,
  onCancel,
  onConfirm
}) => (
  <Modal
    size='small'
    isOpen={isOpen}
    onClose={onCancel}
    header={
      <ModalTitle
        title={
          <>
            <AlertFullIcon color='text-alert' className='marginRight-small' />
            <Trans>Delete multiple paths</Trans>
          </>
        }
        onClose={onCancel}
      />
    }
    footer={
      <ModalButtons>
        <Button onClick={onCancel}>
          <Trans>Cancel</Trans>
        </Button>
        <Button negative primary onClick={onConfirm}>
          <Trans>Yes, delete multiple paths</Trans>
        </Button>
      </ModalButtons>
    }
  >
    <Text tag='p' type='h4' fontColor='text-base-darkest'>
      <Trans>Are you ABSOLUTELY sure?</Trans>
    </Text>
    <Text
      tag='p'
      type='f5'
      marginBottom='default'
      fontColor='text-base-darkest'
    >
      <Trans>This action CANNOT be undone.</Trans>
    </Text>
    <Text tag='p' type='h5' fontColor='text-base-darkest'>
      <Trans>This action will:</Trans>
    </Text>
    <Text tag='p' type='f5' marginBottom='small'>
      <Trans>
        Delete all paths and their courses apart from the first path. The
        remaining study plan will only contain the courses in the first path.
      </Trans>
    </Text>
  </Modal>
)

export default DeleteMultiplePathsConfirmationModal

import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import moment from 'moment'

import { Button, Col, Row, Text } from '@fullfabric/alma-mater'

import getPeriodicityLabel from 'apps/Billing/utils/getPeriodicityLabel'

const DATE_DISPLAY_FORMAT = 'dddd, MMMM D, YYYY'

const ContractBasicData = ({ contract, isLoading, onEdit }) => {
  const { t } = useTranslation()
  return (
    <>
      <div role='list'>
        <Row>
          <Col
            xs={6}
            role='listitem'
            aria-labelledby='contract-start-date-label contract-start-date-value'
          >
            <Text
              id='contract-start-date-label'
              type='h5'
              fontColor='text-base-darker'
              marginBottom='tiny'
            >
              <Trans>Start date</Trans>
            </Text>

            <Text
              id='contract-start-date-value'
              type='f4'
              fontColor='text-base-darkest'
              marginBottom='default'
            >
              {isLoading ? (
                <Trans>Loading</Trans>
              ) : (
                moment(contract?.start_date).format(DATE_DISPLAY_FORMAT)
              )}
            </Text>
          </Col>

          <Col
            xs={6}
            role='listitem'
            aria-labelledby='contract-end-date-label contract-end-date-value'
          >
            <Text
              id='contract-end-date-label'
              type='h5'
              fontColor='text-base-darker'
              marginBottom='tiny'
            >
              <Trans>End date</Trans>
            </Text>

            <Text
              id='contract-end-date-value'
              type='f4'
              fontColor='text-base-darkest'
              marginBottom='default'
            >
              {isLoading ? (
                <Trans>Loading</Trans>
              ) : (
                moment(contract?.end_date).format(DATE_DISPLAY_FORMAT)
              )}
            </Text>
          </Col>
        </Row>
        <div
          role='listitem'
          aria-labelledby='contract-periodicity-label contract-periodicity-value'
        >
          <Text
            type='h5'
            fontColor='text-base-darker'
            marginBottom='tiny'
            id='contract-periodicity-label'
          >
            <Trans>Periodicity</Trans>
          </Text>

          <Text
            type='f4'
            fontColor='text-base-darkest'
            marginBottom='default'
            id='contract-periodicity-value'
          >
            {isLoading ? (
              <Trans>Loading</Trans>
            ) : (
              getPeriodicityLabel(contract?.billing_periodicity, t)
            )}
          </Text>
        </div>
      </div>

      <Button size='small' type='button' onClick={onEdit} disabled={isLoading}>
        <Trans>Edit</Trans>
      </Button>
    </>
  )
}

export default ContractBasicData

.react-app ._form_2fam3_1 {
  width: 100%;
  padding: var(--space-more);
}

.react-app ._loading_2fam3_6 {
  padding: var(--space-more);
  display: flex;
  justify-content: center;
}

.react-app ._submitBtn_2fam3_12 {
  width: 100%;
  padding: var(--space-less);
  margin-top: var(--space-default);
}

.react-app ._errorAlert_2fam3_18 {
  border-radius: 0;
}
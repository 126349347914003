import React from 'react'
import { Trans } from 'react-i18next'

export const MARKETING_CHANNELS = {
  email: 'Email',
  sms: 'SMS',
  phone: 'Phone',
  mail: 'Mail'
}

export const RESPONSE_TYPE_TRANSLATIONS = {
  'Events::Response::Yes': <Trans>Yes</Trans>,
  'Events::Response::Maybe': <Trans>Maybe</Trans>,
  'Events::Response::No': <Trans>No</Trans>,
  defined_in_the_import_file: <Trans>Defined in the import file</Trans>
}

export const ATTENDANCE_TRANSLATIONS = {
  true: <Trans>Yes</Trans>,
  false: <Trans>No</Trans>,
  defined_in_the_import_file: <Trans>Defined in the import file</Trans>
}

export const PROFILE_IMPORT_STEPS = {
  FILE_UPLOAD: 'fileUpload',
  IMPORT_SETTINGS: 'importSettings',
  POLICY_CONSENT: 'policyConsent',
  ADD_TO_CONTEXT: 'addToContext',
  PROFILE_DETAILS: 'profileDetails',
  MAPPING: 'mapping',
  SUMMARY: 'summary',
  REPORT: 'report'
}

export const INITIAL_PROFILE_IMPORT_STATE = {
  file: null,
  separator: null,
  updateExistingProfiles: null,
  consentData: {
    dataProcessingConsent: false,
    consentType: 'same',
    marketingAgreement: null,
    marketingOptions: { email: false, phone: false, sms: false, mail: false },
    sourceOfConsent: '',
    dateOfConsent: null
  },
  addToData: { stateSubstateId: null, ownerId: null },
  addToEventData: { responseType: null, attendance: null },
  sourceCategory: null,
  overwriteSourceCategory: 'none',
  sourceCategoryType: 'same',
  tags: [],
  mappings: []
}

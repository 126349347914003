import React from 'react'
import { Route, Switch } from 'react-router'

import { StudyPlanDataProvider } from 'apps/StudyPlans/contexts/StudyPlanData'
import AddSubjectPage from 'apps/StudyPlans/pages/AddSubjectPage'
import EditSubjectPage from 'apps/StudyPlans/pages/EditSubjectPage'
import SettingsPage from 'apps/StudyPlans/pages/SettingsPage'
import SubjectsPage from 'apps/StudyPlans/pages/SubjectsPage'
import {
  ADD_SUBJECT_ROUTE,
  EDIT_SUBJECT_ROUTE,
  HOME_ROUTE,
  SETTINGS_ROUTE
} from './constants'

const StudyPlanAppRoutes = () => {
  return (
    <StudyPlanDataProvider>
      <Switch>
        <Route exact path={HOME_ROUTE} component={SubjectsPage} />
        <Route exact path={SETTINGS_ROUTE} component={SettingsPage} />
        <Route exact path={ADD_SUBJECT_ROUTE} component={AddSubjectPage} />
        <Route exact path={EDIT_SUBJECT_ROUTE} component={EditSubjectPage} />
      </Switch>
    </StudyPlanDataProvider>
  )
}

export default StudyPlanAppRoutes

import { useCallback, useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'

import reassignEvaluation from 'apps/Evaluations/api/reassignEvaluation'
import { useEvaluationForm } from 'apps/Evaluations/context/EvaluationFormData'

function useReassignEvaluation({ onSubmitted }) {
  const queryClient = useQueryClient()
  const [hasServerError, setHasServerError] = useState(false)

  const { evaluationFormData } = useEvaluationForm()

  const reassignEvaluationMutation = useMutation(
    ({ formValues }) => {
      setHasServerError(false)
      return reassignEvaluation(evaluationFormData.id, formValues)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          'tableDataFetch',
          '/evaluations/api/evaluations'
        ])
        queryClient.invalidateQueries(['getEvaluation'])

        onSubmitted()
      },
      onError: () => {
        setHasServerError(true)
      }
    }
  )

  const onSubmit = useCallback(
    async (formValues) => {
      reassignEvaluationMutation.mutate({ formValues })
    },
    [reassignEvaluationMutation]
  )

  return {
    onSubmit,
    hasServerError,
    isSubmitting: reassignEvaluationMutation.isLoading
  }
}

export default useReassignEvaluation

import React from 'react'

import styles from './styles.module.scss'

const ContentRegion = React.forwardRef(({ children }, ref) => {
  return (
    <div ref={ref} className={styles.contentRegion}>
      {children}
    </div>
  )
})

export default ContentRegion

import React from 'react'
import { Trans } from 'react-i18next'

import { Text } from '@fullfabric/alma-mater'

import { relativeTimestamp } from 'apps/Inbox//utils/cardUtils'
import InboxCardProfileTooltip from 'apps/Inbox/components/InboxCardProfileTooltip'
import InboxCardSubject from 'apps/Inbox/components/InboxCardSubject'

import styles from './styles.module.scss'

export default function InboxApplicCardDescriptor({
  status,
  statusUpdatedBy,
  actor,
  subType,
  updatedAt
}) {
  if (status === 'archived') {
    return (
      <div className={styles.cardDescriptor}>
        <InboxCardProfileTooltip
          actor={statusUpdatedBy}
        ></InboxCardProfileTooltip>{' '}
        <Text
          type='f5'
          tag='span'
          fontColor='text-base-darkest'
          marginTop='small'
        >
          <Trans>archived this card.</Trans>
        </Text>
        <Text tag='span' marginLeft='tiny' fontColor='text-base'>
          {relativeTimestamp(updatedAt)}
        </Text>
      </div>
    )
  }

  return (
    <div className={styles.cardDescriptor}>
      <InboxCardSubject actor={actor}></InboxCardSubject>

      <Text
        type='f5'
        tag='span'
        fontColor='text-base-darkest'
        marginTop='small'
      >
        {subType === 'applic::started' ? (
          <Trans>started this application.</Trans>
        ) : (
          <Trans>submitted this application.</Trans>
        )}{' '}
      </Text>

      <Text tag='span' marginLeft='tiny' fontColor='text-base'>
        {relativeTimestamp(updatedAt)}
      </Text>
    </div>
  )
}

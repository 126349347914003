import React from 'react'
import { useQuery } from 'react-query'

import { Loading } from '@fullfabric/alma-mater'

import getMyApplics from 'apps/MergeProfiles/api/getApplicsBy'
import { useMergeData } from 'apps/MergeProfiles/contexts/MergeData'
import ApplicSummaryRow from './ApplicSummaryRow'
import NoApplicsText from './NoApplicsText'

import styles from './styles.module.scss'

const ApplicationsTab = () => {
  const {
    mergeData: { targetProfile, sourceProfile, applicsFrom }
  } = useMergeData()

  const {
    data: targetProfileApplics,
    isLoading: isTargetProfileApplicsLoading
  } = useQuery(['applicsBy', targetProfile.id], () =>
    getMyApplics(targetProfile.id)
  )
  const {
    data: sourceProfileApplics,
    isLoading: isSourceProfileApplicsLoading
  } = useQuery(['applicsBy', sourceProfile.id], () =>
    getMyApplics(sourceProfile.id)
  )

  if (isTargetProfileApplicsLoading || isSourceProfileApplicsLoading)
    return <Loading />

  const targetProfileApplicTemplateIdsAfterMerge = Array.from(
    new Set([
      ...Object.keys(applicsFrom),
      ...targetProfileApplics.map((applic) => applic.template_id)
    ])
  )

  if (targetProfileApplicTemplateIdsAfterMerge.length === 0)
    return <NoApplicsText />

  return (
    <div className={styles.applicsTabContainer} role='rowgroup'>
      {targetProfileApplicTemplateIdsAfterMerge.map((applicTemplateId) => (
        <ApplicSummaryRow
          key={applicTemplateId}
          applicTemplateId={applicTemplateId}
          targetProfileApplics={targetProfileApplics}
          sourceProfileApplics={sourceProfileApplics}
        />
      ))}
    </div>
  )
}

export default ApplicationsTab

/* eslint-disable camelcase */
const flattenCourseTypes = (course_types) =>
  course_types.reduce(
    (acc, typeForPath) => ({
      ...acc,
      [`course_type_${typeForPath.path_id}`]: typeForPath.group_id
    }),
    {}
  )

export default flattenCourseTypes

import { useCallback, useEffect } from 'react'

export function handleKeyDown({ event, hotkey, onTriggeredShortcut }) {
  const isPressingShift =
    !event.ctrlKey && !event.altKey && !event.metaKey && event.shiftKey
  const isWrittingInInput = ['input', 'textarea'].includes(
    event.target.tagName?.toLowerCase()
  )

  if (
    !isWrittingInInput &&
    isPressingShift &&
    event.key?.toLowerCase() === hotkey
  ) {
    onTriggeredShortcut()
  }
}

function useShiftShortcut(hotkey, onTriggeredShortcut) {
  const onKeyDown = useCallback(
    (event) => {
      handleKeyDown({ event, hotkey, onTriggeredShortcut })
    },
    [hotkey, onTriggeredShortcut]
  )

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown)

    return () => {
      document.removeEventListener('keydown', onKeyDown)
    }
  }, [onKeyDown])
}

export default useShiftShortcut

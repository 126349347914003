export const canSelectSinglePath = (multiplePaths, studyPlan) =>
  !multiplePaths ||
  (studyPlan.paths || []).every((path) => !path.related_to_transcripts)

export const needsConfirmationToDeletePaths = (multiplePaths, studyPlan) =>
  !multiplePaths &&
  (studyPlan.paths || []).length > 1 &&
  studyPlan.courses.length > 0

export const getPathPlaceholder = () => {
  const generatedId = `new${Date.now().valueOf()}`
  return { [generatedId]: { id: generatedId, name: '' } }
}

const markBlankPathErrors = (paths) => {
  const blankPathsWithError = Object.values(paths)
    .filter((path) => !path.name || path.name.trim() === '')
    .reduce(
      (acc, path) => ({
        ...acc,
        [path.id]: { ...path, error: 'blank' }
      }),
      {}
    )

  return [
    { ...paths, ...blankPathsWithError },
    Object.keys(blankPathsWithError).length > 0
  ]
}

const markDuplicatePathErrors = (paths) => {
  const pathNameCount = Object.values(paths).reduce(
    (acc, path) => ({
      ...acc,
      [path.name.trim().toLowerCase()]: [
        ...(acc[path.name.trim().toLowerCase()] || []),
        path.id
      ]
    }),
    {}
  )

  const dulpicatePathNames = Object.keys(pathNameCount).filter(
    (name) => pathNameCount[name].length > 1
  )

  const dulpicatePathsWithError = Object.values(paths)
    .filter((path) =>
      dulpicatePathNames.includes(path.name.trim().toLowerCase())
    )
    .filter((path) => {
      const idsWithSameName =
        pathNameCount[path.name.trim().toLowerCase()].slice(1)
      return idsWithSameName.includes(path.id)
    })
    .reduce(
      (acc, path) => ({
        ...acc,
        [path.id]: { ...path, error: 'exists' }
      }),
      {}
    )

  return [
    { ...paths, ...dulpicatePathsWithError },
    dulpicatePathNames.length > 0
  ]
}

export const validatePaths = (paths) => {
  const [pathsWithBlankValidation, hasBlankErrors] = markBlankPathErrors(paths)
  const [pathsWithDuplicateValidation, hasDuplicates] = markDuplicatePathErrors(
    pathsWithBlankValidation
  )

  return [pathsWithDuplicateValidation, hasBlankErrors || hasDuplicates]
}

import React from 'react'
import { Trans } from 'react-i18next'
import { useHistory, useParams } from 'react-router'

import { Button } from '@fullfabric/alma-mater'
import {
  AuthorizationOfficer,
  useSettings
} from '@fullfabric/authorization-officer'

const PreviousButton = () => {
  const history = useHistory()
  const { id: sourceProfileId } = useParams()
  const appSettings = useSettings()
  const applicsEnabled = AuthorizationOfficer.hasModule('applics', appSettings)

  const onPreviousClick = React.useCallback(() => {
    if (applicsEnabled) {
      history.push(`/profiles/${sourceProfileId}/merge/applics`)
    } else {
      history.push(`/profiles/${sourceProfileId}/merge/info`)
    }
  }, [history, sourceProfileId, applicsEnabled])

  return (
    <Button onClick={onPreviousClick}>
      <Trans>Previous</Trans>
    </Button>
  )
}

export default PreviousButton

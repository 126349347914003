import React, { useState } from 'react'
import { useHistory } from 'react-router'

import { SUBJECT_DETAILS_ROUTE } from 'apps/Subjects/components/AppRoutes'

const SetTargetSubjectContext = React.createContext()
const TargetSubjectContext = React.createContext()

export const TargetSubjectProvider = ({ children }) => {
  const [targetSubject, setTargetSubject] = useState({})

  return (
    <SetTargetSubjectContext.Provider value={setTargetSubject}>
      <TargetSubjectContext.Provider value={targetSubject}>
        {children}
      </TargetSubjectContext.Provider>
    </SetTargetSubjectContext.Provider>
  )
}

export const useTargetSubject = () => {
  return React.useContext(TargetSubjectContext)
}

export const useSetTargetSubject = () => {
  return React.useContext(SetTargetSubjectContext)
}

export const useGoToSubjectPage = ({ afterCreatingSubject } = {}) => {
  const { push: historyPush } = useHistory()
  const setTargetSubject = useSetTargetSubject()
  const searchParams = afterCreatingSubject ? '?recently_created=true' : ''

  return React.useCallback(
    ({ id, ...subjectData }) => {
      setTargetSubject({ id, ...subjectData })
      historyPush(`${SUBJECT_DETAILS_ROUTE.replace(':id', id)}${searchParams}`)
    },
    [historyPush, setTargetSubject, searchParams]
  )
}

export default TargetSubjectContext

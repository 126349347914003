import React from 'react'
import { Trans } from 'react-i18next'
import { useQuery } from 'react-query'

import { Loading, Text } from '@fullfabric/alma-mater'

import getContextForRole from 'apps/MergeProfiles/api/getContextForRole'
import { BaseCardInfoRow } from './CardInfoRow'

const ApplicJourneyInfoRow = ({ role }) => {
  const { data: context, isLoading } = useQuery(['contextForRole', role], () =>
    getContextForRole(role)
  )

  if (isLoading) return <Loading />

  return (
    <Text type='h5' fontColor='text-base-darkest' marginTop='tiny'>
      {context.parent_name}
      <Text tag='span' marginLeft='small'>
        {context.name}
        {context.campus_name ? ` - ${context.campus_name}` : ''}
      </Text>
    </Text>
  )
}

const AssociatedJourneyInfoRow = ({ applic, profile }) => {
  const rolesForApplic = profile.roles.filter((role) => {
    const isClassRoleForApplic =
      applic.context_id === role.context_id &&
      applic.context_type !== 'Institutions::Institution'
    const isRoleForApplicAssociatedContext =
      applic.associated_context_ids?.includes(role.context_id)

    return isClassRoleForApplic || isRoleForApplicAssociatedContext
  })

  return (
    <BaseCardInfoRow title={<Trans>Associated journeys</Trans>}>
      {rolesForApplic.length === 0 && (
        <Text type='f5' marginTop='tiny'>
          <Trans>No value</Trans>
        </Text>
      )}
      {rolesForApplic.map((role) => {
        return <ApplicJourneyInfoRow key={role.id} role={role} />
      })}
    </BaseCardInfoRow>
  )
}

export default AssociatedJourneyInfoRow

import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '@fullfabric/alma-mater'
import { IfPermit } from '@fullfabric/authorization-officer'

import CreateContractModal from './CreateContractModal'

const CreateContractButton = ({ onContractCreated }) => {
  const { t } = useTranslation()
  const [modalIsOpen, setModalIsOpen] = useState(false)

  return (
    <IfPermit to='billing.billing_change'>
      <Button
        size='small'
        primary
        onClick={() => {
          setModalIsOpen(true)
        }}
      >
        {t('Create new contract')}
      </Button>
      <CreateContractModal
        isOpen={modalIsOpen}
        handleClose={() => setModalIsOpen(false)}
        onSubmitted={onContractCreated}
      />
    </IfPermit>
  )
}

export default CreateContractButton

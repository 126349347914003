import { PROFILE_IMPORT_STEPS } from 'apps/Imports/utils/profile-import/constants'
import { getBaseUrl } from 'apps/Imports/utils/profile-import/get-base-import-url'

const getUrlFromStepId = (context, stepId) => {
  switch (stepId) {
    case PROFILE_IMPORT_STEPS.FILE_UPLOAD:
      return getBaseUrl(context, '/import')
    case PROFILE_IMPORT_STEPS.IMPORT_SETTINGS:
      return getBaseUrl(context, '/import/settings')
    case PROFILE_IMPORT_STEPS.POLICY_CONSENT:
      return getBaseUrl(context, '/import/consent')
    case PROFILE_IMPORT_STEPS.ADD_TO_CONTEXT:
      return getBaseUrl(context, '/import/add-to')
    case PROFILE_IMPORT_STEPS.PROFILE_DETAILS:
      return getBaseUrl(context, '/import/profile-details')
    case PROFILE_IMPORT_STEPS.MAPPING:
      return getBaseUrl(context, '/import/mapping')
    case PROFILE_IMPORT_STEPS.SUMMARY:
      return getBaseUrl(context, '/import/summary')
    case PROFILE_IMPORT_STEPS.REPORT:
      return `/import/${context.id}`
    default:
      return getBaseUrl(context, '/import')
  }
}

export default getUrlFromStepId

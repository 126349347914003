import * as React from 'react'

const getTextContent = (element) => {
  let textContent = ''

  const extractText = (node) => {
    if (typeof node === 'string' || typeof node === 'number') {
      textContent += node
    } else if (Array.isArray(node)) {
      node.forEach((c) => extractText(c))
    } else if (node?.props?.children) {
      const { children } = node.props

      if (Array.isArray(children)) {
        children.forEach((c) => extractText(c))
      } else {
        extractText(children)
      }
    } else if (React.isValidElement(node)) {
      extractText(node.type())
    }
  }

  extractText(element)

  return textContent
}

export default getTextContent

import React from 'react'
import { Route, Switch } from 'react-router-dom'

import DelayedJobs from './DelayedJobs'

export default function Admin() {
  return (
    <Switch>
      <Route exact path='/admin/jobs' component={DelayedJobs} />
    </Switch>
  )
}

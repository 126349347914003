import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Text } from '@fullfabric/alma-mater'

import useIsMobileLayout from 'apps/ContentPages/components/payments-page/use-is-payments-mobile-layout'

import styles from './styles.module.scss'

const Header = () => {
  const { t } = useTranslation()
  const isMobileLayout = useIsMobileLayout()

  React.useEffect(() => {
    document.title = `${t('Payments')}`
  }, [t])

  return (
    <div className={isMobileLayout ? styles.mobileContainer : styles.container}>
      <Text
        type={isMobileLayout ? 'h2' : 'f1'}
        fontColor='text-base-darkest'
        marginBottom={isMobileLayout ? 'small' : 'tiny'}
      >
        <Trans>Payments</Trans>
      </Text>
      <Text type={isMobileLayout ? 'f5' : 'f4'} fontColor='text-base-darker'>
        <Trans>View your fees and make payments</Trans>
      </Text>
    </div>
  )
}

export default Header

import React from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

import getProfilesComparison from 'apps/Reports/api/getProfilesComparison'
import { useClassesData, useMainIntake } from '../ClassOptionsContext'
import { useFilter } from '../FilterContext'
import formatWithdrawnFilterValue from '../utils/formatWithdrawnFilterValue'
import matchDataKeyToIntake from '../utils/matchDataKeyToIntake'
import ComparisonWidget from './ComparisonWidget'

const getWeeksPastText = (value, t) => {
  if (typeof value !== 'number') {
    return null
  }

  switch (value) {
    case 1:
      return t('1 week to deadline')
    default:
      return t('{{weeks}} weeks to deadline', { weeks: value })
  }
}

const IntakeComparisonWidget = () => {
  const { t } = useTranslation()
  const filter = useFilter()
  const intakesData = useClassesData()
  const mainIntakeInfo = useMainIntake()
  const withdrawn = formatWithdrawnFilterValue(filter.withdrawn)

  const {
    data: productComparisonData,
    isLoading,
    error
  } = useQuery(
    ['getProfilesComparison', filter.classes, filter.states, withdrawn],
    () => getProfilesComparison(filter.classes, filter.states, withdrawn),
    { retry: 1 }
  )

  const mainIntakeDataKey =
    productComparisonData?.length > 0 &&
    mainIntakeInfo &&
    Object.keys(productComparisonData[0]).find((dataKey) =>
      matchDataKeyToIntake(dataKey, mainIntakeInfo)
    )

  const lastIndexWithData = productComparisonData?.reduce(
    (last, current, currentIndex) => {
      if (typeof current[mainIntakeDataKey] === 'number') {
        last = currentIndex
      }

      return last
    },
    -1
  )

  const comparisonDataPoint =
    productComparisonData && productComparisonData[lastIndexWithData]
  const comparisonIntakesKeyNames = Object.keys(
    comparisonDataPoint || {}
  ).filter((k) => k !== 'week' && k !== mainIntakeDataKey)

  const comparisonInfo = comparisonIntakesKeyNames.reduce(
    (formattedInfo, intakeKeyName) => {
      const intakeName = intakesData.find((itk) =>
        matchDataKeyToIntake(intakeKeyName, itk)
      )?.name

      return [
        ...formattedInfo,
        {
          label: intakeName,
          value:
            typeof comparisonDataPoint[intakeKeyName] === 'number'
              ? comparisonDataPoint[intakeKeyName]
              : t('Not available')
        }
      ]
    },
    []
  )

  const weeksPast = comparisonDataPoint && Math.abs(comparisonDataPoint.week)

  return (
    <div data-testid='intakeComparisonWidget'>
      <ComparisonWidget
        error={error}
        isLoading={isLoading}
        title={t('Current')}
        description={getWeeksPastText(weeksPast, t)}
        mainInfo={{
          label: mainIntakeInfo?.name,
          value:
            productComparisonData &&
            productComparisonData[lastIndexWithData] &&
            productComparisonData[lastIndexWithData][mainIntakeDataKey]
        }}
        comparisonInfo={comparisonInfo}
      />
    </div>
  )
}

export default IntakeComparisonWidget

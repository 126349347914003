const createAsyncActions = (name) => ({
  start: `${name}_START`,
  success: `${name}_SUCCESS`,
  error: `${name}_ERROR`,
  end: `${name}_END`
})

const actions = {
  createCourse: createAsyncActions('CREATE_COURSE'),
  newCourseDataChanged: 'NEW_COURSE_DATA_CHANGED',
  getSubjects: createAsyncActions('GET_SUBJECTS'),
  getCourses: createAsyncActions('GET_COURSES'),
  getCourse: createAsyncActions('GET_COURSE'),
  getCourseSchema: createAsyncActions('GET_COURSE_SCHEMA'),
  newSubjectClick: 'NEW_SUBJECT_CLICK',
  newCourseClearError: 'NEW_COURSE_CLEAR_ERROR',
  openDeleteCourseModal: 'OPEN_DELETE_COURSE_MODAL',
  openArchiveCourseModal: 'OPEN_ARCHIVE_COURSE_MODAL',
  deleteCourse: createAsyncActions('DELETE_COURSES'),
  archiveCourse: createAsyncActions('ARCHIVE_COURSE'),
  editBasicSectionData: 'EDIT_BASIC_SECTION_DATA',
  cancelEditBasicSectionData: 'CANCEL_EDIT_BASIC_SECTION_DATA',
  editDatesSectionData: 'EDIT_DATES_SECTION_DATA',
  cancelEditDatesSectionData: 'CANCEL_EDIT_DATES_SECTION_DATA',
  editStatusSectionData: 'EDIT_STATUS_SECTION_DATA',
  cancelEditStatusSectionData: 'CANCEL_EDIT_STATUS_SECTION_DATA',
  editAcademicDetailsSectionData: 'EDIT_ACADEMIC_DETAILS_SECTION_DATA',
  cancelEditAcademicDetailsSectionData:
    'CANCEL_EDIT_ACADEMIC_DETAILS_SECTION_DATA',
  editSchemaSectionData: 'EDIT_SCHEMA_SECTION_DATA',
  cancelEditSchemaSectionData: 'CANCEL_EDIT_SCHEMA_SECTION_DATA',
  editCapacitySectionData: 'EDIT_CAPACITY_SECTION_DATA',
  cancelEditCapacitySectionData: 'CANCEL_EDIT_CAPACITY_SECTION_DATA',
  editCourseBasicSectionDataChanged: 'EDIT_COURSE_BASIC_SECTION_DATA_CHANGED',
  editCourseDatesSectionDataChanged: 'EDIT_COURSE_DATES_SECTION_DATA_CHANGED',
  editCourseStatusSectionDataChanged: 'EDIT_COURSE_STATUS_SECTION_DATA_CHANGED',
  editCourseAcademicDetailsSectionDataChanged:
    'EDIT_COURSE_ACADEMIC_DETAILS_SECTION_DATA_CHANGED',
  editCourseCapacitySectionDataChanged:
    'EDIT_COURSE_CAPACITY_SECTION_DATA_CHANGED',
  editCourseSchemaSectionDataChanged: 'EDIT_COURSE_SCHEMA_SECTION_DATA_CHANGED',
  updateCourseBasicSection: createAsyncActions('UPDATE_COURSE_BASIC_SECTION'),
  updateCourseDatesSection: createAsyncActions('UPDATE_COURSE_DATES_SECTION'),
  updateCourseStatusSection: createAsyncActions('UPDATE_COURSE_STATUS_SECTION'),
  updateCourseAcademicDetailsSection: createAsyncActions(
    'UPDATE_COURSE_ACADEMIC_DETAILS_SECTION'
  ),
  updateCourseCapacitySection: createAsyncActions(
    'UPDATE_COURSE_CAPACITY_SECTION'
  ),
  updateCourseSchemaSection: createAsyncActions('UPDATE_COURSE_SCHEMA_SECTION'),
  hideSavedChangesPopup: 'HIDE_SAVED_CHANGES_POPUP',
  newSubjectCreated: 'NEW_SUBJECT_CREATED'
}

export default actions

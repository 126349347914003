import getFormattedDate from '../get-formatted-date'

const DEFAULT_COLOR = 'text-base-darker'

const getLabelColor = (state, date) => {
  if (state === 'closed') {
    return 'text-alert'
  }

  if (state === 'upcoming' || !date) {
    return DEFAULT_COLOR
  }

  const { daysToDeadline } = getFormattedDate(date)

  if (daysToDeadline < 7) {
    return 'text-info'
  } else {
    return DEFAULT_COLOR
  }
}

export default getLabelColor

import React from 'react'
import { Trans } from 'react-i18next'

import { Field, Text } from '@fullfabric/alma-mater'

import { useStudyPlan } from 'apps/StudyPlans/contexts/StudyPlanData'
import {
  useOnChangeSubjectEditionData,
  useSubjectEditionData
} from 'apps/StudyPlans/contexts/SubjectEditionData'
import { studyPlanSubjectForCourseGroup } from './utils'

const PrerequisitesField = () => {
  const data = useSubjectEditionData()
  const studyPlan = useStudyPlan()
  const onChange = useOnChangeSubjectEditionData()

  const prerequisiteOptions = React.useMemo(() => {
    const courseGroups = (studyPlan.default_path?.course_groups || []).sort(
      (a, b) => a.order - b.order
    )

    const multipleElectiveGroups =
      (studyPlan.default_path?.course_groups || []).filter(
        (group) => group.type === 'elective'
      ).length > 1

    const options = courseGroups
      .map((group) => {
        const spSubjectsForGroup = studyPlanSubjectForCourseGroup(
          studyPlan,
          group
        )

        const courseOptionsForGroup = spSubjectsForGroup
          .map((spSubject) => {
            return {
              value: spSubject.id,
              type: 'Affairs::StudyPlan::Course',
              label: (
                <>
                  {spSubject.course.long_name}
                  {multipleElectiveGroups && group.type === 'elective' && ' - '}
                  {multipleElectiveGroups && group.type === 'elective' && (
                    <Text type='h6' tag='span'>
                      {group?.name}
                    </Text>
                  )}
                </>
              )
            }
          })
          .filter((option) => option.value !== data.id)

        return group.type !== 'elective'
          ? courseOptionsForGroup
          : courseOptionsForGroup.length > 0
          ? [
              {
                value: group.id,
                type: 'Affairs::StudyPlan::Course::Group',
                label: (
                  <Text bold>
                    {multipleElectiveGroups ? (
                      <Trans>Elective subjects - {group.name}</Trans>
                    ) : (
                      <Trans>Elective subjects</Trans>
                    )}
                  </Text>
                )
              },
              ...courseOptionsForGroup
            ]
          : []
      })
      .filter((x) => x)
      .flat()

    return options
    //   ...studyPlan.courses.map((spSubject) => {
    //     const groupId = (spSubject.course_types || []).find(
    //       (cType) => cType.path_id === studyPlan.default_path?.id
    //     )?.group_id;
    //     const electiveGroup = electiveGroups.find(
    //       (group) => group.id === groupId
    //     );

    //     return {
    //       value: spSubject.id,
    //       type: "Affairs::StudyPlan::Course",
    //       label: [
    //         spSubject.course?.long_name,
    //         multipleElectiveGroups && electiveGroup
    //           ? electiveGroup?.name
    //           : null,
    //       ]
    //         .filter((x) => x)
    //         .join(" - "),
    //     };
    //   }),
    //   ...electiveGroups.map((group) => ({
    //     value: group.id,
    //     type: "Affairs::StudyPlan::Course::Group",
    //     label: multipleElectiveGroups ? (
    //       <Trans>Elective subjects - {group.name}</Trans>
    //     ) : (
    //       <Trans>Elective subjects</Trans>
    //     ),
    //   })),
    // ].filter((option) => option.value !== data.id);
  }, [studyPlan, data])

  return (
    <Field
      id='prerequisites'
      type='tag'
      value={(data.prerequisites || []).map((prereq) => prereq.prerequisite_id)}
      label={<Trans>Prerequisites</Trans>}
      help={
        <Trans>
          Applicants cannot select the subject until they fulfil its
          prerequisites.
        </Trans>
      }
      inputOptions={{
        isClearable: true,
        placeholder: <Trans>- Select -</Trans>,
        options: prerequisiteOptions
      }}
      onChange={(fieldId, value) =>
        onChange(
          fieldId,
          (value || []).map((prereqId) => {
            const opt = prerequisiteOptions.filter(
              (opt) => opt.value === prereqId
            )[0]

            return {
              prerequisite_id: opt.value,
              prerequisite_type: opt.type
            }
          })
        )
      }
      // error={ERROR_MESSAGES[errors.hours]}
    />
  )
}

export default PrerequisitesField

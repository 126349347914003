/* eslint-disable camelcase */

import React from 'react'

import { useStudyPlan } from './StudyPlanData'

export const SelectedPathContext = React.createContext({})

export const SelectedPathProvider = ({ children }) => {
  const studyPlan = useStudyPlan()
  const [selectedPathId, setSelectedPathId] = React.useState(
    () => studyPlan.default_path.id
  )

  const selectedPath =
    studyPlan.paths.filter((path) => path.id === selectedPathId)[0] ||
    studyPlan.default_path

  return (
    <SelectedPathContext.Provider value={{ selectedPath, setSelectedPathId }}>
      {children}
    </SelectedPathContext.Provider>
  )
}

export const useSelectedPath = () => {
  const pathData = React.useContext(SelectedPathContext)

  return pathData.selectedPath
}

export const useSetSelectedPathId = () =>
  React.useContext(SelectedPathContext).setSelectedPathId

export const useCoreSubjectsForSelectedPath = () => {
  const studyPlan = useStudyPlan()
  const selectedPath = useSelectedPath()

  const courseGroup = selectedPath.course_groups.filter(
    (group) => group.type === 'core'
  )[0]

  const subjects = studyPlan.courses.filter(
    (course) =>
      course.course_types.filter(
        ({ type, path_id, group_id }) =>
          (group_id === courseGroup.id || type === 'Core') &&
          path_id === selectedPath?.id
      ).length > 0
  )

  return subjects
}

export const useExtraCurricularSubjectsForSelectedPath = () => {
  const studyPlan = useStudyPlan()
  const selectedPath = useSelectedPath()

  const courseGroup = selectedPath.course_groups.filter(
    (group) => group.type === 'extra'
  )[0]

  const subjects = studyPlan.courses.filter(
    (course) =>
      course.course_types.filter(
        ({ type, path_id, group_id }) =>
          (group_id === courseGroup.id || type === 'Extra curricular') &&
          path_id === selectedPath?.id
      ).length > 0
  )

  return subjects
}

export const useElectiveGroupsForSelectedPath = () => {
  const selectedPath = useSelectedPath()

  const courseGroups = (selectedPath?.course_groups || []).filter(
    (group) => group.type === 'elective'
  )

  return courseGroups
}

.react-app ._discountsBtn_cvxzj_1 {
  margin-top: var(--space-less);
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.react-app ._discountsContainer_cvxzj_8 {
  width: 100%;
  margin-top: var(--space-tiny);
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}
.react-app ._discountsContainer_cvxzj_8 ._discountsInput_cvxzj_15 {
  width: 200px;
}
.react-app ._discountsContainer_cvxzj_8 ._discountsInput_cvxzj_15 ._discountsApplied_cvxzj_18 {
  margin-top: var(--space-tiny);
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.react-app ._discountsContainer_cvxzj_8 ._discountsInput_cvxzj_15 ._discountsApplied_cvxzj_18 ._iconButton_cvxzj_24 {
  margin-left: var(--space-tiny);
}
.react-app ._closeButton_12gx7_1 {
  margin-top: var(--space-more);
  margin-right: var(--space-small);
  right: 0;
  position: absolute;
  background-color: transparent;
  border: none;
  outline: none;
  border-radius: var(--border-radius-default);
  padding: var(--space-tiny) var(--space-small);
  cursor: pointer;
}
.react-app ._closeButton_12gx7_1:hover {
  background-color: var(--ff-color-grey-100);
}

.react-app ._closeIcon_12gx7_17 {
  line-height: 40px;
}
.react-app ._closeIcon_12gx7_17 path {
  fill: var(--ff-color-grey-500);
}
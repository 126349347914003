import { utils } from '@fullfabric/public-api'

const getApplicationTemplate = async (templateId) => {
  const response = await fetch(
    `/api/applics/templates/${templateId}?include[schemas]=true`
  )
  return await utils.checkResponse(response)
}

export default getApplicationTemplate

import React from 'react'
import { Trans } from 'react-i18next'
import { useQuery } from 'react-query'

import { Text } from '@fullfabric/alma-mater'

import getContextForRole from 'apps/MergeProfiles/api/getContextForRole'
import { formatRoleState } from 'apps/MergeProfiles/utils/formatRoleState'
import { isClassOrCourseRole } from 'apps/MergeProfiles/utils/journeyHelpers'
import DetailSection from './DetailSection'
import InfoRow, { InfoRowLoading } from './InfoRow'

const JourneyInfo = ({ role }) => {
  const { data, isLoading } = useQuery(['contextForRole', role], () =>
    getContextForRole(role)
  )

  if (isLoading) return <InfoRowLoading />

  return (
    <InfoRow
      main={[data?.parent_name, data?.name, data?.campus_name]
        .filter((x) => x)
        .join(' - ')}
      secondary={formatRoleState(role)}
    />
  )
}

const JourneysSection = ({ profile }) => {
  const classOrCourseRoles = React.useMemo(
    () => profile?.roles?.filter(isClassOrCourseRole) || [],
    [profile]
  )

  return (
    <DetailSection aria-label='journeys'>
      <Text type='h6' marginBottom='less'>
        <Trans>Journeys</Trans>
      </Text>
      {classOrCourseRoles.length === 0 && (
        <Text>
          <Trans>No journeys</Trans>
        </Text>
      )}
      {classOrCourseRoles.length > 0 &&
        classOrCourseRoles.map((role) => (
          <JourneyInfo key={role.id} role={role} />
        ))}
    </DetailSection>
  )
}

export default JourneysSection

import React from 'react'

import { OneColumnLayout } from '@fullfabric/alma-mater'
import { useCurrentUser } from '@fullfabric/authorization-officer'

import ProductOverviewReport from 'apps/Reports/components/ProductOverviewReport'
import validateFilter from 'apps/Reports/utils/validateFilter'
import Head from './Head'
import ProductFilter from './ProductFilter'

import styles from './styles.module.scss'

const STORAGE_FILTER_KEY = 'product_overview:filter:'
const INITIAL_FILTER = {
  states: ['prospect::started_application'],
  programme: null,
  classes: []
}

const ProductOverviewPage = () => {
  const user = useCurrentUser()
  const [filter, setFilter] = React.useState(() => {
    const storedFilter = validateFilter(
      STORAGE_FILTER_KEY + user.id,
      ['programme', 'classes', 'states', 'withdrawn'],
      INITIAL_FILTER
    )

    return storedFilter || INITIAL_FILTER
  })
  const [classOptions, setClassOptions] = React.useState([])

  const onFilterChange = React.useCallback(
    (updatedFilter) => {
      const newFilter =
        updatedFilter.programme === filter.programme
          ? updatedFilter
          : { ...updatedFilter, classes: [] }

      setFilter(newFilter)
      localStorage.setItem(
        STORAGE_FILTER_KEY + user.id,
        JSON.stringify(newFilter || INITIAL_FILTER)
      )
    },
    [filter, user.id]
  )

  return (
    <OneColumnLayout pageHead={<Head />} className={styles.pageSpacing}>
      <ProductFilter
        filter={filter}
        onFilterChange={onFilterChange}
        onOptionsLoaded={(filterId, options) => {
          filterId === 'classes' && setClassOptions(options)
        }}
      />
      <ProductOverviewReport filter={filter} classOptions={classOptions} />
    </OneColumnLayout>
  )
}

export default ProductOverviewPage

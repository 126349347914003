import React from 'react'
import { Trans } from 'react-i18next'

import AutoloadDataTable from 'shared/components/AutoloadDataTable'

import ActionsCell from './ActionsCell'
import DateTimeCell from './DateTimeCell'
import HandlerCell from './HandlerCell'

export default function DelayedJobsTable() {
  return (
    <AutoloadDataTable
      fixedLayout
      fetchURL={'/admin/api/jobs'}
      reactQueryProps={{ staleTime: 0 }}
      emptyMessage={<Trans>No jobs</Trans>}
      columns={[
        {
          Header: <Trans>ID</Trans>,
          accessor: 'id',
          disableSortBy: true,
          width: 80
        },
        {
          Header: <Trans>Queue</Trans>,
          accessor: 'queue',
          disableSortBy: true,
          width: 40
        },
        {
          Header: <Trans>Prio</Trans>,
          accessor: 'priority',
          disableSortBy: true,
          width: 30
        },
        {
          Header: <Trans>Handler</Trans>,
          accessor: 'handler',
          disableSortBy: true,
          Cell: HandlerCell
        },
        {
          Header: <Trans>Tries</Trans>,
          accessor: 'attempts',
          disableSortBy: true,
          width: 30
        },
        {
          Header: <Trans>Run at</Trans>,
          accessor: 'run_at',
          width: 100,
          Cell: DateTimeCell
        },
        {
          Header: <Trans>Created</Trans>,
          accessor: 'created_at',
          width: 100,
          Cell: DateTimeCell
        },
        {
          Header: <Trans>Error</Trans>,
          accessor: 'last_error',
          width: 120,
          disableSortBy: true,
          Cell: ({ value }) => value || '-'
        },
        {
          Header: '',
          id: 'actions',
          accessor: 'id',
          width: 40,
          disableSortBy: true,
          Cell: ActionsCell
        }
      ]}
      loadMoreProps={{
        sizeKey: 'limit',
        offsetKey: 'offset'
      }}
      fetchResponseParser={(response) => {
        const pagination = JSON.parse(
          response.headers['x-fullfabric-json'] || '{}'
        )
        return { ...pagination, data: response.data }
      }}
      sortable
      sortParams={{
        sortKey: 'sort[field]',
        directionKey: 'sort[direction]',
        ascValue: 1,
        descValue: -1
      }}
      initialSort={{
        'sort[field]': 'run_at',
        'sort[direction]': -1
      }}
      searchable={false}
      filterable={false}
    />
  )
}

import React from 'react'

import { useCurrentUser } from '@fullfabric/authorization-officer'

import validateFilter from 'apps/Reports/utils/validateFilter'

const STORAGE_FILTER_KEY = 'product_conversion:filter:'
const INITIAL_FILTER = {
  context: null
}
const INITIAL_SELECTED_LIFECYCLES = [
  'prospect::started_application',
  'applicant::submitted'
]

const FilterContext = React.createContext()

export const FilterProvider = ({ children, fixedContextId }) => {
  const user = useCurrentUser()
  const [isLoadingFilter, setIsLoadingFilter] = React.useState(!fixedContextId)
  const [filter, setFilter] = React.useState(() => {
    const storedFilter = validateFilter(
      STORAGE_FILTER_KEY + user.id,
      ['context'],
      INITIAL_FILTER
    )

    return storedFilter || INITIAL_FILTER
  })

  const [selectedLifecycles, setSelectedLifecycles] = React.useState(() => {
    const value = localStorage.getItem(
      `${STORAGE_FILTER_KEY}lifecycles:${user.id}:${fixedContextId || ''}`
    )
    const storedLifecycles = value && JSON.parse(value)

    return storedLifecycles || INITIAL_SELECTED_LIFECYCLES
  })

  const onSelectedLifecyclesChange = React.useCallback(
    (lifecycles) => {
      setSelectedLifecycles(lifecycles)
      localStorage.setItem(
        `${STORAGE_FILTER_KEY}lifecycles:${user.id}:${fixedContextId || ''}`,
        JSON.stringify(
          lifecycles && lifecycles.length > 0
            ? lifecycles
            : INITIAL_SELECTED_LIFECYCLES
        )
      )
    },
    [fixedContextId, user.id]
  )

  const [filterOptions, setFilterOptions] = React.useState({})

  const onFilterChange = React.useCallback(
    (updatedFilter) => {
      const changedContextParent =
        updatedFilter.contextParent !== filter.contextParent

      const newFilter = changedContextParent
        ? { ...updatedFilter, context: null }
        : updatedFilter

      if (changedContextParent) {
        setIsLoadingFilter(true)
      }

      setFilter(newFilter)
      localStorage.setItem(
        STORAGE_FILTER_KEY + user.id,
        JSON.stringify(newFilter || INITIAL_FILTER)
      )
    },
    [filter.contextParent, user.id]
  )

  const onFilterOptionsLoaded = React.useCallback((filterId, options) => {
    if (filterId === 'context') {
      setIsLoadingFilter(false)
    }

    setFilterOptions((currentOptions) => ({
      ...currentOptions,
      [filterId]: options
    }))
  }, [])
  return (
    <FilterContext.Provider
      value={{
        filter,
        isLoadingFilter,
        options: filterOptions,
        onFilterChange,
        onFilterOptionsLoaded,
        selectedLifecycles,
        onSelectedLifecyclesChange
      }}
    >
      {children}
    </FilterContext.Provider>
  )
}

export const useFilter = () => {
  const ctxValue = React.useContext(FilterContext)
  return ctxValue?.filter
}

export const useIsLoadingFilter = () => {
  const ctxValue = React.useContext(FilterContext)
  return ctxValue?.isLoadingFilter
}

export const useFilterOptions = () => {
  const ctxValue = React.useContext(FilterContext)
  return ctxValue?.options
}

export const useFilterCallbacks = () => {
  const ctxValue = React.useContext(FilterContext)
  return {
    onFilterChange: ctxValue?.onFilterChange,
    onFilterOptionsLoaded: ctxValue?.onFilterOptionsLoaded
  }
}

export const useSelectedLifecycles = () => {
  const ctxValue = React.useContext(FilterContext)
  return {
    selectedLifecycles: ctxValue?.selectedLifecycles,
    onSelectedLifecyclesChange: ctxValue?.onSelectedLifecyclesChange
  }
}

export default FilterContext

import React from 'react'

import PropTypes from 'prop-types'

import { ModalTitle } from '@fullfabric/alma-mater'

export default function Header({ onClose }) {
  return <ModalTitle title='Export payment plans' onClose={onClose} />
}

Header.propTypes = {
  onClose: PropTypes.func.isRequired
}

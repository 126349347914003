import React from 'react'
import { Trans } from 'react-i18next'

import { Bar, BarChart, Label, ResponsiveContainer, Tooltip } from 'recharts'

import { Loading, Text } from '@fullfabric/alma-mater'

import useFormatTooltipValue from 'apps/Reports/components/ApplicPerformanceReport/hooks/useFormatTooltipValue'
import usePeriodConfig from 'apps/Reports/components/ApplicPerformanceReport/hooks/usePeriodConfig'
import ChartCard from 'apps/Reports/components/ChartCard'
import {
  commonStyles,
  DEFAULT_SPACE_TO_CONTENT,
  renderCustomCartesianGrid,
  renderCustomXAxis,
  renderCustomYAxis
} from 'apps/Reports/components/CustomChartElements'

const WeeklyApplicsBarChart = ({ title, data, isLoading, dataKey }) => {
  const formatTooltipValue = useFormatTooltipValue()
  const {
    labelText: chartLabelText,
    Tick: CustomXTick,
    dataKey: xAxisKey,
    tooltipLabelFormatter
  } = usePeriodConfig()

  return (
    <ChartCard>
      <Text type='h4' fontColor='text-base-darkest' marginBottom='tiny'>
        {title}
      </Text>
      <Text marginBottom='tiny'>
        <Trans>Non-cumulative</Trans>
      </Text>
      <div className={commonStyles.chartContainer}>
        {isLoading ? (
          <Loading />
        ) : (
          <ResponsiveContainer>
            <BarChart
              data={data || []}
              margin={{ top: 12, right: DEFAULT_SPACE_TO_CONTENT, bottom: 16 }}
            >
              <Tooltip
                formatter={formatTooltipValue}
                labelFormatter={tooltipLabelFormatter}
              />
              {renderCustomCartesianGrid()}
              {renderCustomYAxis()}
              {renderCustomXAxis({
                dataKey: xAxisKey,
                tick: (
                  <CustomXTick
                    dense
                    startingWeek={data[0] && data[0][xAxisKey]}
                  />
                ),
                children: (
                  <Label
                    value={chartLabelText}
                    offset={-8}
                    position='insideBottom'
                  />
                )
              })}
              <Bar dataKey={dataKey} fill='#9C6ADE' />
            </BarChart>
          </ResponsiveContainer>
        )}
      </div>
    </ChartCard>
  )
}

export default WeeklyApplicsBarChart

import React from 'react'
import { Trans } from 'react-i18next'

import { SuccessBigIcon } from '@fullfabric/alma-mater'

import PolicyAcceptanceFeedback from 'apps/Policies/components/PolicyAcceptanceFeedback'

const MarketingPolicyConfirmedPage = () => {
  return (
    <PolicyAcceptanceFeedback
      icon={<SuccessBigIcon color='green-600' width={40} height={40} />}
      title={<Trans>Thank you</Trans>}
      subtitle={<Trans>You have confirmed your marketing preferences.</Trans>}
    />
  )
}

export default MarketingPolicyConfirmedPage

import React from 'react'
import { Trans } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'

import {
  AlertFullIcon,
  Button,
  Modal,
  ModalButtons,
  ModalTitle,
  Text
} from '@fullfabric/alma-mater'

import deleteCourse from 'apps/Applics/api/deleteCourse'

import styles from './styles.module.scss'

const DeleteCourseModal = ({
  open,
  applicId,
  courseId,
  studyPlanSubjectId,
  handleClose
}) => {
  const queryClient = useQueryClient()
  const { mutate: deleteMutation, isLoading: loading } = useMutation(
    deleteCourse,
    {
      onSuccess: (data) => {
        queryClient.setQueryData(['applic', applicId], data)

        handleClose()
      }
    }
  )

  const handleDeleteCourse = () => {
    deleteMutation({ applicId, courseId, studyPlanSubjectId })
  }

  return (
    <Modal
      isOpen={open}
      size='medium'
      header={
        <ModalTitle
          title={<Trans>Delete Course</Trans>}
          iconProps={{
            icon: AlertFullIcon,
            color: 'text-alert'
          }}
        />
      }
      className={styles.deleteModal}
      footer={
        <ModalButtons>
          <Button onClick={handleClose} role='cancel'>
            <Trans>Cancel</Trans>
          </Button>
          <Button
            negative
            role='delete'
            primary
            onClick={handleDeleteCourse}
            loading={loading}
          >
            <Trans>Delete</Trans>
          </Button>
        </ModalButtons>
      }
    >
      <Text type='h4' fontColor='text-base-darkest'>
        <Trans>Are you ABSOLUTELY sure?</Trans>
      </Text>
      <Text type='f5' fontColor='text-base-darkest'>
        <Trans>This action CANNOT be undone.</Trans>
      </Text>
      <Text
        type='h5'
        fontColor='text-base-darkest'
        className={styles.warningSeparation}
      >
        <Trans>This action will:</Trans>
      </Text>
      <Text type='f5' fontColor='text-base-darker'>
        <Trans>Remove the course from the application</Trans>
      </Text>
    </Modal>
  )
}

export default DeleteCourseModal

const getClassesState = async (classIds) =>
  fetch(
    `/api/institutions/classes/options?include_fields_in_class=["state", "id"]&ids=${JSON.stringify(
      classIds
    )}`,
    {
      credentials: 'same-origin'
    }
  ).then((result) => result.json())

export default getClassesState

import { useTranslation } from 'react-i18next'

export const formatTooltipValue = (t, value, name) => {
  const formattedName = name === 'starts' ? t('Started') : t('Submitted')

  return [value, formattedName]
}

const useFormatTooltipValue = () => {
  const { t } = useTranslation()

  return (value, name) => formatTooltipValue(t, value, name)
}

export default useFormatTooltipValue

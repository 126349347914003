import React from 'react'

import ViewerContainer from '../ViewerContainer'

const AudioViewer = ({ viewOption, uploadIndex }) => {
  const [loading, setLoading] = React.useState(true)
  const [error, setError] = React.useState(false)

  return (
    <ViewerContainer
      viewOption={viewOption}
      uploadIndex={uploadIndex}
      loading={loading}
      error={error}
    >
      <audio
        controls
        preload='auto'
        onCanPlayThrough={() => setLoading(false)}
        onError={() => {
          setLoading(false)
          setError(true)
        }}
        src={`/${viewOption?.uploads[uploadIndex]?.path}`}
        // src="https://www.soundhelix.com/examples/mp3/SoundHelix-Song-2.mp3"
        alt={viewOption.label}
      />
    </ViewerContainer>
  )
}

AudioViewer.hideScaling = true

export default AudioViewer

import React from 'react'
import { Trans } from 'react-i18next'

import MappingStep from 'apps/Imports/components/mapping-step'
import PageContainer from 'apps/Imports/components/page-container'
import ImportActions from 'apps/Imports/components/profile-import/import-actions'
import { useCurrentStep } from 'apps/Imports/contexts/steps'
import { PROFILE_IMPORT_STEPS } from 'apps/Imports/utils/profile-import/constants'

export default function MappingPage() {
  useCurrentStep(PROFILE_IMPORT_STEPS.MAPPING)

  return (
    <PageContainer
      title={<Trans>Mapping</Trans>}
      sidebarTitle={<Trans>Import Profiles</Trans>}
      subtitle={
        <Trans>We tried to guess the mapping but need some more help.</Trans>
      }
      contentColumns={9}
    >
      <MappingStep />
      <ImportActions />
    </PageContainer>
  )
}

export const PRODUCT_IMPORT_STEPS = {
  FILE_UPLOAD: 'fileUpload',
  MAPPING: 'mapping',
  SUMMARY: 'summary',
  REPORT: 'report'
}

export const INITIAL_PRODUCT_IMPORT_STATE = {
  file: null,
  separator: null,
  mappings: []
}

import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { useAppData } from 'shared/contexts/AppData'

import MarketingPolicyModal from 'apps/ContentPages/components/marketing-policy-modal'
import PageLayout from 'apps/ContentPages/components/page-layout'
import PrivacyPolicyModal from 'apps/ContentPages/components/privacy-policy-modal'
import SidebarLayout from 'apps/ContentPages/components/sidebar-layout'
import StudentHubPageTextContent from 'apps/ContentPages/components/student-hub-page-text-content'
import StudentHubPageTitle from 'apps/ContentPages/components/student-hub-page-title'
import StudentHubPageWidgets from 'apps/ContentPages/components/student-hub-page-widgets'
import {
  BackgroundImageProvider,
  useChangeBackgroundImage
} from 'apps/ContentPages/contexts/background-image'
import {
  CurrentPageDataProvider,
  usePageData,
  usePageWidgets
} from 'apps/ContentPages/contexts/current-page-data'

export default function StudentHubPageContainer({ preview }) {
  const location = useLocation()
  const { _navigation } = useAppData()

  return (
    <CurrentPageDataProvider
      previewMode={preview}
      currentPath={location.pathname}
      navigationOptions={_navigation}
    >
      <BackgroundImageProvider>
        <StudentHubPage />
      </BackgroundImageProvider>
    </CurrentPageDataProvider>
  )
}

function StudentHubPage() {
  const changeBackgroundImage = useChangeBackgroundImage()
  const { page, isLoading } = usePageData()

  const imageUrl = page && page.image && page.image.url
  const widgets = usePageWidgets()
  const hasWidgets = widgets?.length > 0

  useEffect(() => {
    if (isLoading) return
    changeBackgroundImage(imageUrl)
  }, [isLoading, imageUrl, changeBackgroundImage])

  return (
    <>
      <SidebarLayout>
        <PageLayout
          type='action'
          withNavigationMenu
          title={<StudentHubPageTitle />}
          widgets={hasWidgets && <StudentHubPageWidgets widgets={widgets} />}
        >
          <StudentHubPageTextContent />
        </PageLayout>
      </SidebarLayout>

      <MarketingPolicyModal />
      <PrivacyPolicyModal />
    </>
  )
}

/* eslint-disable import/no-named-as-default */

import React from 'react'
import { useQueryClient } from 'react-query'

import { useCurrentUser, useSettings } from '@fullfabric/authorization-officer'

import { useAppData } from 'shared/contexts/AppData'

import getCard from 'apps/Inbox/api/getCard'
import pusherConfig from 'apps/Inbox/utils/pusherConfig'
import { useCardFilters } from './CardFilters'
import { useAddNewCardItem } from './cardNotifications'

export const PusherProvider = ({ children }) => {
  const { cardStatus, cardType } = useCardFilters()
  const settings = useSettings()
  const queryClient = useQueryClient()
  const appData = useAppData()
  const currentUser = useCurrentUser()
  const addNewCardItem = useAddNewCardItem()
  const channelName = `private-${appData.institution.handle}-inbox-${currentUser.id}`
  const pusher = pusherConfig(settings.hash.integrations.pusher)
  const channel = pusher.subscribe(channelName)

  React.useEffect(() => {
    channel.bind('new-card', async (data) => {
      // only dispatch action when "all cards tab" or card type is same as selected
      if (!cardType || data.type.toLowerCase().includes(cardType)) {
        const { data: card } = await getCard(data.card_id)
        addNewCardItem(card)
      }
    })

    channel.bind('card-update', async (data) => {
      let newCards = []

      const card = await getCard(data.card_id)

      const previousCards =
        queryClient.getQueryData(['cards', cardStatus, cardType]) || []

      if (data.status === 'archived' && cardType !== 'archived') {
        // the card was archived and the current type is not archived so we hide it
        newCards = previousCards?.pages.map((page) => {
          const data = page.data.filter((val) => val.id !== card.id)
          return { ...page, data }
        })
      } else {
        // a visible card got an update so we update it
        newCards = previousCards?.pages.map((page) => {
          const data = page.data.map((val) => (val.id !== card.id ? val : card))
          return { ...page, data }
        })
      }

      queryClient.setQueriesData(['cards', cardStatus, cardType], (data) => ({
        pages: newCards,
        pageParams: data.pageParams
      }))
    })

    return () => channel.unbind_all()
  }, [pusher, channel, cardStatus, cardType, addNewCardItem, queryClient])

  return <>{children}</>
}

import React from 'react'
import { Trans } from 'react-i18next'

import { ArrowLeftIcon, PageHead } from '@fullfabric/alma-mater'

import { HOME_ROUTE } from 'apps/StudyPlans/components/AppRoutes/constants'
import { useSettingsSectionsBeingEdited } from 'apps/StudyPlans/contexts/SettingsSectionsBeingEdited'
import { useClassOf } from 'apps/StudyPlans/contexts/StudyPlanData'

const PageHeader = ({ openConfimationModal }) => {
  const classOf = useClassOf()
  const sectionsBeingEdited = useSettingsSectionsBeingEdited()
  const isBeingEdited = Object.values(sectionsBeingEdited).some(
    (beingEdited) => beingEdited
  )

  return (
    <PageHead
      title={<Trans>Settings</Trans>}
      breadcrumbs={[
        {
          label: <Trans>Back to Study plan</Trans>,
          icon: ArrowLeftIcon,
          to: isBeingEdited ? null : HOME_ROUTE.replace(':classId', classOf.id),
          onClick: () => {
            isBeingEdited && openConfimationModal()
          }
        }
      ]}
    />
  )
}

export default PageHeader

import React from 'react'

const HomeIcon = () => (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path d='M16 6.33353C15.9999 6.12708 15.9041 5.93233 15.7407 5.80619L8.40733 0.139523C8.16736 -0.045694 7.83264 -0.045694 7.59267 0.139523L0.259333 5.80619C0.0959008 5.93233 0.000130218 6.12708 0 6.33353V15.3335C0 15.7017 0.298477 16.0002 0.666667 16.0002H6C6.36819 16.0002 6.66667 15.7017 6.66667 15.3335V12.0002C6.66667 11.2638 7.26362 10.6669 8 10.6669C8.73638 10.6669 9.33333 11.2638 9.33333 12.0002V15.3335C9.33333 15.7017 9.63181 16.0002 10 16.0002H15.3333C15.7015 16.0002 16 15.7017 16 15.3335V6.33353Z' />
  </svg>
)

export default HomeIcon

import React from 'react'

import { useElectiveGroupsForSelectedPath } from 'apps/StudyPlans/contexts/SelectedPath'
import CoreSubjectsSection from './CoreSubjectsSection'
import ElectiveSubjectsSection from './ElectiveSubjectsSection'
import ExtracurricularSubjectsSection from './ExtracurricularSubjectsSection'
import PathsSection from './PathsSection'

const SubjectsPageContents = () => {
  const electiveGroups = useElectiveGroupsForSelectedPath()

  return (
    <>
      <PathsSection />
      <CoreSubjectsSection />
      {electiveGroups.map((electiveGroup) => (
        <ElectiveSubjectsSection
          key={electiveGroup.id}
          electiveGroup={electiveGroup}
          multipleElectiveGroups={electiveGroups.length > 1}
        />
      ))}
      <ExtracurricularSubjectsSection />
    </>
  )
}

export default SubjectsPageContents

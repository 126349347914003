import React, { useState } from 'react'
import { Trans } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'

import {
  Button,
  DropdownInput,
  Modal,
  ModalButtons,
  ModalTitle,
  Text
} from '@fullfabric/alma-mater'

import patchCourse from 'apps/Applics/api/patchCourse'

import styles from './styles.module.scss'

const AddCourseModal = ({ isOpen, courseSelection, applic, handleClose }) => {
  const [selection, setSelection] = useState({})
  const [error, setError] = useState(false)
  const queryClient = useQueryClient()

  const { mutate: patchMutation, isLoading: loading } = useMutation(
    patchCourse,
    {
      onSuccess: (data) => {
        queryClient.setQueryData(['applic', applic?.id], data)
        handleCloseInternal()
      }
    }
  )

  const handleAddCourse = () => {
    if (!selection?.unitId) {
      return setError(true)
    }

    const newApplic = {
      primary_choice_class_study_plan_courses: [
        ...applic.primary_choice_class_study_plan_courses,
        {
          course_id: selection.unitId,
          study_plan_subject_id: selection.studyPlanSubjectId
        }
      ]
    }

    patchMutation({
      applicId: applic.id,
      body: newApplic
    })
  }

  const options = courseSelection?.map((course) => ({
    label: course.name,
    disabled: course.selected,
    value: `${course.unitId}#${course.studyPlanSubjectId}`
  }))

  const handleChangeSelection = (option) => {
    setError(false)
    const [unitId, studyPlanSubjectId] = option.split('#')
    setSelection(
      courseSelection?.find(
        (course) =>
          course.unitId === unitId &&
          course.studyPlanSubjectId === studyPlanSubjectId
      )
    )
  }

  const handleCloseInternal = () => {
    setError(false)
    setSelection({})

    handleClose()
  }

  return (
    <Modal
      isOpen={isOpen}
      size='medium'
      header={<ModalTitle title={<Trans>Add Course</Trans>} />}
      contentClassName={styles.modalBody}
      className={styles.modal}
      footer={
        <ModalButtons>
          <Button onClick={handleCloseInternal}>
            <Trans>Cancel</Trans>
          </Button>
          <Button
            primary
            role='add'
            onClick={handleAddCourse}
            loading={loading}
          >
            <Trans>Add Course</Trans>
          </Button>
        </ModalButtons>
      }
    >
      <DropdownInput
        id='addCourseSelect'
        data-testid='addCourseSelect'
        aria-label='addCourseSelect'
        options={options}
        onChange={handleChangeSelection}
        value={
          selection?.unitId
            ? `${selection?.unitId}#${selection?.studyPlanSubjectId}`
            : null
        }
      />
      {error && (
        <Text small fontColor='text-alert' className={styles.marginError}>
          A course must be selected
        </Text>
      )}
    </Modal>
  )
}

export default AddCourseModal

import React from 'react'
import { Trans } from 'react-i18next'

import { AnnotatedLayout } from '@fullfabric/alma-mater'

import { useSettingsSectionsBeingEdited } from 'apps/StudyPlans/contexts/SettingsSectionsBeingEdited'
import SelectionDisplay from './Display'
import SelectionForm from './Form'

const SubjectsSelectionSection = () => {
  const sectionsBeingEdited = useSettingsSectionsBeingEdited()

  return (
    <AnnotatedLayout.Section
      title={<Trans>Selection</Trans>}
      description={<Trans>Define the rules for selecting subjects.</Trans>}
    >
      {sectionsBeingEdited.paths ? <SelectionForm /> : <SelectionDisplay />}
    </AnnotatedLayout.Section>
  )
}

export default SubjectsSelectionSection

{
  "Delete": "Delete",
  "Cancel": "Cancel",
  "Are you ABSOLUTELY sure?": "Are you ABSOLUTELY sure?",
  "This action CANNOT be undone. This action will:": "This action CANNOT be undone. This action will:",
  "Permanently delete this task from your inbox": "Permanently delete this task from your inbox",
  "Permanently delete this task from the associated profile": "Permanently delete this task from the associated profile",
  "Permanently delete this task from the tasks tab": "Permanently delete this task from the tasks tab",
  "archived this card.": "archived this card.",
  "started this application.": "started this application.",
  "submitted this application.": "submitted this application.",
  "Application": "Application",
  "(no name)": "(no name)",
  "View application": "View application",
  "Archive": "Archive",
  "Restore": "Restore",
  "new items.": "new items.",
  "Click here to update": "Click here to update",
  "You're all done.": "You're all done.",
  "Task completed": "Task completed",
  "You": "You",
  "Read full email +": "Read full email +",
  "sent an email to": "sent an email to",
  "you.": "you.",
  "Email": "Email",
  "(no subject)": "(no subject)",
  "View email": "View email",
  "responded  to this event.": "responded  to this event.",
  "Event": "Event",
  "View submission": "View submission",
  "submitted this form.": "submitted this form.",
  "Form": "Form",
  "All": "All",
  "Emails": "Emails",
  "Tasks": "Tasks",
  "Events": "Events",
  "Applications": "Applications",
  "Forms": "Forms",
  "Archived": "Archived",
  "assigned a task to ": "assigned a task to ",
  "assigned a task to": "assigned a task to",
  "themselves": "themselves",
  "for": "for",
  "Task": "Task",
  "(no title)": "(no title)",
  "Complete task": "Complete task",
  "Mark as incomplete": "Mark as incomplete",
  "Edit task": "Edit task",
  "Inbox": "Inbox",
  "<0></0> ago": "<0></0> ago",
  "Yesterday, ": "Yesterday, "
}

.react-app ._content_97xga_1 * {
  word-wrap: break-word;
}
.react-app ._content_97xga_1 h1,
.react-app ._content_97xga_1 h2 {
  color: var(--ff-color-text-base-darkest);
  margin: 0;
  margin-bottom: var(--space-small);
}
.react-app ._content_97xga_1 h3,
.react-app ._content_97xga_1 h4,
.react-app ._content_97xga_1 h5,
.react-app ._content_97xga_1 h6 {
  color: var(--ff-color-text-base-darkest);
  margin: 0;
  margin-bottom: var(--space-tiny);
}
.react-app ._content_97xga_1 h1 {
  font-size: 32px;
  line-height: 40px;
  color: var(--ff-color-text-base-darkest);
}
.react-app ._content_97xga_1 h2 {
  font-size: 24px;
  line-height: 30px;
}
.react-app ._content_97xga_1 h3 {
  font-size: 20px;
  line-height: 30px;
}
.react-app ._content_97xga_1 h4 {
  font-size: 16px;
  line-height: 24px;
}
.react-app ._content_97xga_1 h5 {
  font-size: 14px;
  line-height: 20px;
}
.react-app ._content_97xga_1 h6 {
  font-size: 12px;
  line-height: 18px;
}
.react-app ._content_97xga_1 p {
  margin-block-end: 1em;
  margin-inline-end: 0px;
}
.react-app ._content_97xga_1 p:last-child {
  margin-bottom: 0;
}
.react-app ._content_97xga_1 ul,
.react-app ._content_97xga_1 ol {
  margin: var(--space-small) 0;
  padding-left: calc(var(--space-more) + var(--space-small));
}
.react-app ._content_97xga_1 a {
  color: var(--ff-color-text-primary);
}
.react-app ._content_97xga_1 .video-container iframe {
  max-width: 100%;
}
.react-app ._content_97xga_1 figure {
  margin: 0;
}
.react-app ._content_97xga_1 figure img {
  width: 100%;
}
.react-app ._content_97xga_1 .text-right {
  text-align: right;
}
.react-app ._content_97xga_1 .text-center {
  text-align: center;
}
.react-app ._content_97xga_1 .text-justify {
  text-align: justify;
}
import React from 'react'
import { Trans } from 'react-i18next'

import { Col, Row } from '@fullfabric/alma-mater'

import BlankSpaceForCookieBanner from 'shared/components/BlankSpaceForCookieBanner'

import useIsMobileLayout from 'apps/Authentication/hooks/useIsMobileLayout'
import InstitutionBackground from '../InstitutionBackground'
import LocaleSelector from '../LocaleSelector'

import classNames from 'classnames'
import styles from './styles.module.scss'

const AppLayout = ({ children }) => {
  const isMobileLayout = useIsMobileLayout()

  return (
    <>
      <a
        className={classNames(styles.skipLink, isMobileLayout && styles.mobile)}
        href='#main'
      >
        <Trans>Skip to main content</Trans>
      </a>
      <Row nogutter className={styles.container}>
        <Col
          xs={12}
          md={6}
          lg={5}
          className={styles.contentCol}
          id='main'
          role='main'
        >
          {children}
          <LocaleSelector className={styles.appLocaleSelector} />
          <BlankSpaceForCookieBanner />
        </Col>
        <Col
          xs={0}
          sm={0}
          md={6}
          lg={7}
          role='complementary'
          aria-label='background image'
          className={styles.imageCol}
        >
          <InstitutionBackground />
        </Col>
      </Row>
    </>
  )
}

export default AppLayout

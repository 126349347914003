import { utils } from '@fullfabric/public-api'

import updateOrganization from '../../utils/update-organization'

const updateContract = async (
  contractId,
  profileId,
  data,
  createAsContact = false
) => {
  if (createAsContact) {
    data.append('create_as_contact', true)
  }

  const response = await fetch(
    `/api/profiles/${profileId}/contracts/${contractId}`,
    {
      method: 'PATCH',
      body: data
    }
  )

  const result = await utils.checkResponse(response)

  return updateOrganization(result)
}

export default updateContract

import * as React from 'react'
import { useTranslation } from 'react-i18next'

import { Text } from '@fullfabric/alma-mater'

import styles from './styles.module.scss'

function TitleAndSubtitle({ isLoading, subtitle, title }) {
  const { t } = useTranslation()

  if (isLoading) return t('Loading')

  return (
    <span className={styles.container}>
      <Text
        tag='span'
        type='f2'
        fontColor='text-base-darker'
        className={styles.programmeName}
      >
        {subtitle}
      </Text>
      <Text
        tag='span'
        type='h2'
        fontColor='text-base-darkest'
        marginLeft='small'
      >
        {title}
      </Text>
    </span>
  )
}

export default TitleAndSubtitle

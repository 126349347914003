import { useMutation, useQueryClient } from 'react-query'

import incompleteTask from 'apps/Inbox/api/incompleteTask'
import updateCard from 'apps/Inbox/api/updateCard'
import { useCardFilters } from '../contexts/CardFilters'

export default function useIncompleteTask() {
  const { cardStatus, cardType } = useCardFilters()
  const queryClient = useQueryClient()

  const { mutate: incompleteTaskMutation } = useMutation(
    async ({ card }) => {
      const { entity: task } = card

      const updatedTask = await incompleteTask(task.id)
      // we're marking the card as active anyways because there might be an inconsistency between current state and DB
      const updatedCard = await updateCard({ ...card, status: 'active' })

      return {
        updates: { updatedTask, updatedCard },
        card,
        cardStatus,
        cardType
      }
    },
    {
      onMutate: async ({ card }) => {
        const previousCards =
          queryClient.getQueryData(['cards', cardStatus, cardType]) || []

        let newCards = null

        if (cardStatus === 'archived') {
          newCards = previousCards?.pages.map((page) => {
            const data = page.data.filter((val) => val.id !== card.id)
            return { ...page, data }
          })
        } else {
          newCards = previousCards?.pages.map((page) => ({
            ...page,
            data: [card, ...page.data]
          }))
        }

        queryClient.setQueriesData(['cards', cardStatus, cardType], (data) => ({
          pages: newCards,
          pageParams: data.pageParams
        }))

        return { previousCards, cardStatus, cardType, card }
      },
      onError: (_err, _data, context) =>
        queryClient.invalidateQueries([
          'cards',
          context.cardStatus,
          context.cardType
        ])
    }
  )

  return incompleteTaskMutation
}

export { useIncompleteTask }

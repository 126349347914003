const flattenFields = (acc, fields) => [...acc, ...fields]

const csvFieldToFieldName = (fieldNameMap) => (mapping) => ({
  header: mapping.header,
  fieldName: fieldNameMap[mapping.field] || mapping.field
})

const nonBlankMappings = (mapping) =>
  mapping.fieldName && mapping.fieldName !== ''

const getCsvMappings = (fieldOptions, currentMapping) => {
  const fieldNameMap = fieldOptions
    .map((fieldGroup) => fieldGroup.options)
    .reduce(flattenFields, [])
    .reduce((acc, { value, label }) => ({ ...acc, [value]: label }), {})

  return currentMapping
    .map(csvFieldToFieldName(fieldNameMap))
    .filter(nonBlankMappings)
}

export default getCsvMappings

{
  "A word by itself is easy to guess": "Una palabra en sí misma es fácil de adivinar",
  "Add \"{{val}}\"": "Añadir \"{{val}}\"",
  "Add another word or two. Uncommon words are better.": "Agregue otra palabra o dos. Las palabras poco comunes son mejores.",
  "All-uppercase is almost as easy to guess as all-lowercase": "Todo en mayúsculas es casi tan fácil de adivinar como minúsculas",
  "Almost there": "Casi finalizado",
  "Already have an account?": "¿Ya tienes una cuenta?",
  "Avoid dates and years that are associated with you": "Evite las fechas y años que están asociados con usted",
  "Avoid recent years": "Evitar los últimos años",
  "Avoid repeated words and characters": "Evite palabras y caracteres repetidos",
  "Avoid sequences": "Evitar secuencias",
  "Avoid years that are associated with you": "Evite los años que están asociados con usted",
  "Back to login": "Volver al ingreso en la página",
  "Cancel": "Cancelar",
  "Capitalization doesn't help very much": "El uso de mayúsculas no ayuda mucho",
  "Common names and surnames are easy to guess": "Los nombres y apellidos comunes son fáciles de adivinar",
  "Continue": "Continuar",
  "Create an account": "Crea una cuenta",
  "Create new account": "Crear una nueva cuenta",
  "Data Protection Regulation": "Reglamento de protección de datos",
  "Dates are often easy to guess": "Las fechas son a menudo fáciles de adivinar",
  "Didn't receive the sms?": "¿No recibió el sms?",
  "E-mail address": "Dirección de correo electrónico",
  "First name": "Nombre",
  "For security reasons account activation links are perishable. You can get a new activation link by filling the sign up form.": "Por razones de seguridad los enlaces de activación son perecederos. Usted puede obtener un nuevo enlace de activación a través del formulario de registro.",
  "Forgot your password?": "¿Olvidaste tu contraseña?",
  "Great, your account has been created!": "Estupendo, tu cuenta ha sido creada!",
  "I accept the privacy policy": "Acepto la política de privacidad",
  "Institutional login": "Inicio de sesión institucional",
  "Last name": "Apellido",
  "Link existing account": "Enlace cuenta existente",
  "LinkedIn": "LinkedIn",
  "Log in": "Iniciar sesión",
  "Log in securely": "Inicia sesión de forma segura",
  "Names and surnames by themselves are easy to guess": "Los nombres y apellidos por sí mismos son fáciles de adivinar",
  "New Password": "Nueva contraseña",
  "No Results": "No hay resultados",
  "No need for symbols, digits, or uppercase letters": "No hay necesidad de símbolos, dígitos o letras mayúsculas",
  "Not registered?": "¿No estás registrado?",
  "Nothing found for": "Nada encontrado para",
  "Or use these alternatives": "O use estas alternativas:",
  "Password": "Contraseña",
  "Password Reset": "Restablecimiento de contraseña",
  "Please accept the privacy policy to continue": "Por favor, acepte la política de privacidad para continuar",
  "Please check your email for instructions on resetting your password": "Por favor, consultar su correo electrónico para obtener instrucciones sobre cómo restablecer su contraseña",
  "Please check your mailbox for instructions on verifying your registration.": "Por favor, consultar su correo electrónico para obtener instrucciones sobre la verificación de su registro.",
  "Please choose a password": "Por favor elija una contraseña",
  "Please confirm your account details below": "Por favor confirmar los detalles de su cuenta a continuación",
  "Please contact your university's IT support department in order to get a new activation link to access Full Fabric.": "Por favor, póngase en contacto con el departamento de soporte de TI de la universidad con el fin de obtener un nuevo enlace de activación acceder a la red completa.",
  "Please type a new password.": "Por favor escriba una nueva contraseña.",
  "Predictable substitutions like \"@\" instead of \"a\" don't help very much": "Las sustituciones predecibles como \"@\" en lugar de \"a\" no ayudan mucho",
  "Predictable substitutions like '@' instead of 'a' don't help very much": "Las sustituciones predecibles como '@' en lugar de 'a' no ayudan mucho",
  "Privacy policy": "Política de privacidad",
  "Recent years are easy to guess": "Los últimos años son fáciles de adivinar",
  "Repeats like \"aaa\" are easy to guess": "Las repeticiones como \"aaa\" son fáciles de adivinar",
  "Repeats like \"abcabcabc\" are only slightly harder to guess than \"abc\"": "Las repeticiones como \"abcabcabc\" son solo un poco más difíciles de adivinar que \"abc\"",
  "Reset password": "Restablecer la contraseña",
  "Reset password and sign in": "Restablecer contraseña e iniciar sesión",
  "Reset your password below.": "Restablezca su contraseña debajo.",
  "Return to log in page": "Volver al ingreso en la página",
  "Reversed words aren't much harder to guess": "Las palabras invertidas no son mucho más difíciles de adivinar",
  "Send again": "Enviar de nuevo",
  "Sequences like abc or 6543 are easy to guess": "Secuencias como abc o 6543 son fáciles de adivinar",
  "Set password and sign in": "Establecer una contraseña y abrir una sesión",
  "Short keyboard patterns are easy to guess": "Los patrones cortos de teclado son fáciles de adivinar",
  "Sign in": "Registrarse",
  "Sign up": "Regístrate",
  "Skip to main content": "Saltar al contenido principal",
  "Straight rows of keys are easy to guess": "Las filas rectas de teclas son fáciles de adivinar",
  "Suggestions": "Sugerencias",
  "Thank you.": "Gracias.",
  "There was a server problem. Please try again.": "Hubo un problema en el servidor. Por favor, inténtelo de nuevo.",
  "This is a top-10 common password": "Esta es una de las 10 contraseñas más comunes",
  "This is a top-100 common password": "Esta es una de las 100 contraseñas más comunes",
  "This is a very common password": "Esta es una contraseña muy común",
  "This is similar to a commonly used password": "Esto es similar a una contraseña de uso común",
  "Two Factor Authentication Token": "Token de autenticación de dos factores",
  "Two step verification": "Verificación de dos pasos",
  "Use a few words, avoid common phrases": "Usa algunas palabras, evita frases comunes",
  "Use a longer keyboard pattern with more turns": "Usa un patrón de teclado más largo con más vueltas",
  "Use one of these alternatives to signup": "Utilice una de estas alternativas para registrarse",
  "Use one of these methods to log in": "Utilice uno de estos métodos para iniciar sesión",
  "We sent a sms to the number <1>{{phoneNumber}}</1> with the verification code": "Enviamos un sms al número <1>{{phoneNumber}}</1> con el código de verificación",
  "Welcome": "Bienvenido/a",
  "Your activation link has expired.": "Su enlace de activación ha caducado.",
  "Your password has expired.": "Su contraseña ha caducado.",
  "Your password is good": "Su contraseña es buena",
  "Your password is reasonably adequate": "Su contraseña es razonablemente adecuada",
  "Your password is too weak": "Su contraseña es muy débil",
  "or e-mail": "o correo electrónico",
  "or link an existing account": "o vincular una cuenta existente",
  "{{minAlpha}} letters": "{{minAlpha}} letra(s)",
  "{{minDigits}} digits": "{{minDigits}} dígito(s)",
  "{{minLength}} characters": "{{minLength}} caractere(s)",
  "{{minSymbols}} symbols": "{{minSymbols}} símbolo(s)",
  "Password must be at least {{minLength}} characters long": "La contraseña debe contener {{minLength}} caracteres como mínimo",
  "Password must have at least {{count}} characters": "La contraseña debe tener al menos {{count}} caracter",
  "Password must have at least {{count}} characters_plural": "La contraseña debe tener al menos {{count}} caracteres",
  "Password must have at least {{count}} digit": "La contraseña debe tener al menos {{count}} dígito",
  "Password must have at least {{count}} digit_plural": "La contraseña debe tener al menos {{count}} dígitos",
  "Password must have at least {{count}} symbol": "La contraseña debe tener al menos {{count}} símbolo",
  "Password must have at least {{count}} symbol_plural": "La contraseña debe tener al menos {{count}} símbolos",
  "Password was already used": "Ya se ha utilizado esta contraseña",
  "Thank you. Please log in below.": "Gracias. Inicie sesión a continuación.",
  "This field is required": "Este campo es obligatorio",
  "Privacy Policy": "Política de privacidad",
  "Link to an existing account": "Vincular a una cuenta existente",
  "There are fields with errors.": "Hay campos con errores.",
  "Please correct the fields with errors.": "Por favor, corrija los campos con errores.",
  "We sent a sms to the number {{ phoneNumber }} with the verification code": "Enviamos un sms al número {{phoneNumber}} con el código de verificación"
}

.react-app ._title_8an86_1 {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-bottom: var(--space-tiny) !important;
}

.react-app ._loadingContainer_8an86_8 {
  padding-top: var(--space-more);
  padding-bottom: var(--space-less);
}

.react-app ._container_8an86_13 {
  margin-bottom: var(--space-more);
}
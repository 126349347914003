import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { CheckboxInput, Text } from '@fullfabric/alma-mater'

import { useMergeData } from 'apps/MergeProfiles/contexts/MergeData'
import { BaseCol, BaseRow } from './Base'

const onSelectAll =
  (profileType, updateMergeData, fieldsWithDifferentValues) => (checked) => {
    const newProfileInfoFrom = checked
      ? fieldsWithDifferentValues.reduce(
          (acc, field) => ({ ...acc, [field.name]: profileType }),
          {}
        )
      : {}

    updateMergeData({ profileInfoFrom: newProfileInfoFrom })
  }

const HeaderRow = () => {
  const { t } = useTranslation()
  const { mergeData, updateMergeData, fieldsWithDifferentValues } =
    useMergeData()

  const onSelectAllDuplicate = React.useCallback(
    (checked) =>
      onSelectAll(
        'duplicate',
        updateMergeData,
        fieldsWithDifferentValues
      )(checked),
    [fieldsWithDifferentValues, updateMergeData]
  )
  const onSelectAllMain = React.useCallback(
    (checked) =>
      onSelectAll('main', updateMergeData, fieldsWithDifferentValues)(checked),
    [fieldsWithDifferentValues, updateMergeData]
  )

  const allSelectedFieldsAreFromDuplicateProfile = React.useMemo(
    () =>
      fieldsWithDifferentValues.every(
        (field) => mergeData.profileInfoFrom[field.name] === 'duplicate'
      ),
    [fieldsWithDifferentValues, mergeData.profileInfoFrom]
  )
  const allSelectedFieldsAreFromMainProfile = React.useMemo(
    () =>
      fieldsWithDifferentValues.every(
        (field) => mergeData.profileInfoFrom[field.name] === 'main'
      ),
    [fieldsWithDifferentValues, mergeData.profileInfoFrom]
  )

  return (
    <BaseRow
      className='marginBottom-default'
      role='rowheader'
      aria-label='profile field info header'
    >
      <BaseCol />
      <BaseCol>
        <Text type='f4' fontColor='yellow-800' marginBottom='small'>
          <Trans>Duplicate profile</Trans>
        </Text>
        <CheckboxInput
          id='selectAllFromDuplicate'
          label={t('Select all fields')}
          checked={allSelectedFieldsAreFromDuplicateProfile}
          onChange={onSelectAllDuplicate}
        />
      </BaseCol>
      <BaseCol>
        <Text type='f4' fontColor='text-info' marginBottom='small'>
          <Trans>Main profile</Trans>
        </Text>
        <CheckboxInput
          id='selectAllFromMain'
          label={t('Select all fields')}
          checked={allSelectedFieldsAreFromMainProfile}
          onChange={onSelectAllMain}
        />
      </BaseCol>
    </BaseRow>
  )
}

export default HeaderRow

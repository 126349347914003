import React from 'react'

import AppLayout from 'apps/Authentication/components/AppLayout'
import AuthContentLayout from 'apps/Authentication/components/AuthContentLayout'
import LinkedInArrivalsForm from 'apps/Authentication/components/LinkedInArrivalsForm'

const LinkedinConfirmationPage = () => (
  <AppLayout>
    <AuthContentLayout>
      <AuthContentLayout.Body>
        <LinkedInArrivalsForm />
      </AuthContentLayout.Body>
    </AuthContentLayout>
  </AppLayout>
)

export default LinkedinConfirmationPage

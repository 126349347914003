import React from 'react'
import { Trans } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'

import { Button } from '@fullfabric/alma-mater'

import { useMergeData } from 'apps/MergeProfiles/contexts/MergeData'
import { isClassOrCourseRole } from 'apps/MergeProfiles/utils/journeyHelpers'

const NextButton = () => {
  const history = useHistory()
  const { id: sourceProfileId } = useParams()
  const {
    mergeData: { sourceProfile, journeysFrom },
    updateMergeError,
    removeMergeError
  } = useMergeData()

  const onJourneysSelected = React.useCallback(() => {
    const duplicateProfileClassOrCourseRoles =
      sourceProfile.roles.filter(isClassOrCourseRole)

    if (
      duplicateProfileClassOrCourseRoles.length !== 0 &&
      Object.keys(journeysFrom || {}).length !==
        duplicateProfileClassOrCourseRoles.length
    ) {
      updateMergeError({ journeysFrom: 'someNotSelected' })
      return
    }

    removeMergeError('journeysFrom')

    history.push(`/profiles/${sourceProfileId}/merge/summary`)
  }, [
    history,
    sourceProfileId,
    journeysFrom,
    updateMergeError,
    removeMergeError,
    sourceProfile.roles
  ])

  return (
    <Button primary onClick={onJourneysSelected}>
      <Trans>Next</Trans>
    </Button>
  )
}

export default NextButton

import { utils } from '@fullfabric/public-api'

const getProductPerformance = async (productId) => {
  const response = await fetch(
    `/api/reports/product_performance/${productId}`,
    {
      credentials: 'same-origin'
    }
  )
  return await utils.checkResponse(response)
}

export default getProductPerformance

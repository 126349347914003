{
  "A word by itself is easy to guess": "A word by itself is easy to guess",
  "Add \"{{val}}\"": "Add \"{{val}}\"",
  "Add another word or two. Uncommon words are better.": "Add another word or two. Uncommon words are better.",
  "All-uppercase is almost as easy to guess as all-lowercase": "All-uppercase is almost as easy to guess as all-lowercase",
  "Almost there": "Almost there",
  "Already have an account?": "Already have an account?",
  "Avoid dates and years that are associated with you": "Avoid dates and years that are associated with you",
  "Avoid recent years": "Avoid recent years",
  "Avoid repeated words and characters": "Avoid repeated words and characters",
  "Avoid sequences": "Avoid sequences",
  "Avoid years that are associated with you": "Avoid years that are associated with you",
  "Back to login": "Back to login",
  "Cancel": "Cancel",
  "Capitalization doesn't help very much": "Capitalization doesn't help very much",
  "Common names and surnames are easy to guess": "Common names and surnames are easy to guess",
  "Continue": "Continue",
  "Create an account": "Create an account",
  "Create new account": "Create new account",
  "Data Protection Regulation": "Data Protection Regulation",
  "Dates are often easy to guess": "Dates are often easy to guess",
  "Didn't receive the sms?": "Didn't receive the sms?",
  "E-mail address": "E-mail address",
  "First name": "First name",
  "For security reasons account activation links are perishable. You can get a new activation link by filling the sign up form.": "For security reasons account activation links are perishable. You can get a new activation link by filling the sign up form.",
  "Forgot your password?": "Forgot your password?",
  "Great, your account has been created!": "Great, your account has been created!",
  "I accept the privacy policy": "I accept the privacy policy",
  "Institutional login": "Institutional login",
  "Last name": "Last name",
  "Link existing account": "Link existing account",
  "LinkedIn": "LinkedIn",
  "Log in": "Log in",
  "Log in securely": "Log in securely",
  "Names and surnames by themselves are easy to guess": "Names and surnames by themselves are easy to guess",
  "New Password": "New Password",
  "No Results": "No Results",
  "No need for symbols, digits, or uppercase letters": "No need for symbols, digits, or uppercase letters",
  "Not registered?": "Not registered?",
  "Nothing found for": "Nothing found for",
  "Or use these alternatives": "Or use these alternatives",
  "Password": "Password",
  "Password Reset": "Password Reset",
  "Please accept the privacy policy to continue": "Please accept the privacy policy to continue",
  "Please check your email for instructions on resetting your password": "Please check your email for instructions on resetting your password",
  "Please check your mailbox for instructions on verifying your registration.": "Please check your mailbox for instructions on verifying your registration.",
  "Please choose a password": "Please choose a password",
  "Please confirm your account details below": "Please confirm your account details below",
  "Please contact your university's IT support department in order to get a new activation link to access Full Fabric.": "Please contact your university's IT support department in order to get a new activation link to access Full Fabric.",
  "Please type a new password.": "Please type a new password.",
  "Predictable substitutions like \"@\" instead of \"a\" don't help very much": "Predictable substitutions like \"@\" instead of \"a\" don't help very much",
  "Predictable substitutions like '@' instead of 'a' don't help very much": "Predictable substitutions like '@' instead of 'a' don't help very much",
  "Privacy policy": "Privacy policy",
  "Recent years are easy to guess": "Recent years are easy to guess",
  "Repeats like \"aaa\" are easy to guess": "Repeats like \"aaa\" are easy to guess",
  "Repeats like \"abcabcabc\" are only slightly harder to guess than \"abc\"": "Repeats like \"abcabcabc\" are only slightly harder to guess than \"abc\"",
  "Reset password": "Reset password",
  "Reset password and sign in": "Reset password and sign in",
  "Reset your password below.": "Reset your password below.",
  "Return to log in page": "Return to log in page",
  "Reversed words aren't much harder to guess": "Reversed words aren't much harder to guess",
  "Send again": "Send again",
  "Sequences like abc or 6543 are easy to guess": "Sequences like abc or 6543 are easy to guess",
  "Set password and sign in": "Set password and sign in",
  "Short keyboard patterns are easy to guess": "Short keyboard patterns are easy to guess",
  "Sign in": "Sign in",
  "Sign up": "Sign up",
  "Skip to main content": "Skip to main content",
  "Straight rows of keys are easy to guess": "Straight rows of keys are easy to guess",
  "Suggestions": "Suggestions",
  "Thank you.": "Thank you.",
  "There was a server problem. Please try again.": "There was a server problem. Please try again.",
  "This is a top-10 common password": "This is a top-10 common password",
  "This is a top-100 common password": "This is a top-100 common password",
  "This is a very common password": "This is a very common password",
  "This is similar to a commonly used password": "This is similar to a commonly used password",
  "Two Factor Authentication Token": "Two Factor Authentication Token",
  "Two step verification": "Two step verification",
  "Use a few words, avoid common phrases": "Use a few words, avoid common phrases",
  "Use a longer keyboard pattern with more turns": "Use a longer keyboard pattern with more turns",
  "Use one of these alternatives to signup": "Use one of these alternatives to signup",
  "Use one of these methods to log in": "Use one of these methods to log in",
  "We sent a sms to the number <1>{{phoneNumber}}</1> with the verification code": "We sent a sms to the number <1>{{phoneNumber}}</1> with the verification code",
  "Welcome": "Welcome",
  "Your activation link has expired.": "Your activation link has expired.",
  "Your password has expired.": "Your password has expired.",
  "Your password is good": "Your password is good",
  "Your password is reasonably adequate": "Your password is reasonably adequate",
  "Your password is too weak": "Your password is too weak",
  "or e-mail": "or e-mail",
  "or link an existing account": "or link an existing account",
  "{{minAlpha}} letters": "{{minAlpha}} letter(s)",
  "{{minDigits}} digits": "{{minDigits}} digit(s)",
  "{{minLength}} characters": "{{minLength}} characters",
  "{{minSymbols}} symbols": "{{minSymbols}} symbol(s)",
  "Password must be at least {{minLength}} characters long": "Password must be at least {{minLength}} characters long",
  "Password must have at least {{count}} characters": "Password must have at least {{count}} character",
  "Password must have at least {{count}} characters_plural": "Password must have at least {{count}} characters",
  "Password must have at least {{count}} digit": "Password must have at least {{count}} digit",
  "Password must have at least {{count}} digit_plural": "Password must have at least {{count}} digits",
  "Password must have at least {{count}} symbol": "Password must have at least {{count}} symbol",
  "Password must have at least {{count}} symbol_plural": "Password must have at least {{count}} symbols",
  "Password was already used": "Password was already used",
  "Thank you. Please log in below.": "Thank you. Please log in below.",
  "This field is required": "This field is required",
  "Privacy Policy": "Privacy Policy",
  "Link to an existing account": "Link to an existing account",
  "There are fields with errors.": "There are fields with errors.",
  "Please correct the fields with errors.": "Please correct the fields with errors.",
  "We sent a sms to the number {{ phoneNumber }} with the verification code": "We sent a sms to the number {{phoneNumber}} with the verification code"
}

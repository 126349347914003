.react-app ._customCell_53m9r_1 {
  font-size: 0.7rem;
  padding: 0;
}

.react-app ._actionOption_53m9r_6 {
  font-size: 0.7rem;
  display: flex;
  align-items: center;
  gap: 0.25rem;
}
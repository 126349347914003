import { utils } from '@fullfabric/public-api'

const removePaymentPlanDiscount = async ({
  profileId,
  paymentPlanId,
  discountId
}) => {
  const response = await fetch(
    `/book_keeper/api/profiles/${profileId}/plan/${paymentPlanId}/discounts/${discountId}`,
    {
      credentials: 'same-origin',
      headers: { 'Content-Type': 'application/json' },
      method: 'DELETE'
    }
  )

  return await utils.checkResponse(response)
}

export default removePaymentPlanDiscount

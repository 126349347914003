import { utils } from '@fullfabric/public-api'

const updateSubject = async (
  studyPlanId,
  subject,
  applyChangesToTranscripts
) => {
  const response = await fetch(
    `/affairs/api/study_plan/${studyPlanId}/courses/${subject.id}`,
    {
      credentials: 'same-origin',
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        course: subject,
        apply_changes_to_transcripts: applyChangesToTranscripts
      })
    }
  )

  return await utils.checkResponse(response)
}

export default updateSubject

.react-app ._container_1s2le_1 {
  padding: var(--space-more) var(--hub-outer-margin);
}
.react-app ._container_1s2le_1._desktop_1s2le_4 {
  padding-top: var(--hub-outer-margin);
  padding-bottom: var(--hub-outer-margin);
}

.react-app ._contentCol_1s2le_9 {
  padding-left: 0 !important;
  padding-right: calc(2 * var(--hub-half-gutter-width)) !important;
}

.react-app ._container_1s2le_1._withWidgets_1s2le_14 ._contentCol_1s2le_9 {
  padding-left: 0 !important;
  padding-right: var(--hub-half-gutter-width) !important;
}
.react-app ._container_1s2le_1._withWidgets_1s2le_14 ._widgetsCol_1s2le_18 {
  position: relative;
  padding-right: 0 !important;
  padding-left: var(--hub-half-gutter-width) !important;
}
.react-app ._container_1s2le_1._withWidgets_1s2le_14 ._widgetsCol_1s2le_18._largeWidgets_1s2le_23 {
  max-width: 400px !important;
}
.react-app ._container_1s2le_1._withWidgets_1s2le_14 ._widgetsCol_1s2le_18 img {
  max-width: 100% !important;
}
.react-app ._container_1s2le_1._withWidgets_1s2le_14 ._fixedWidgetsCol_1s2le_29 ._reference_1s2le_29 {
  position: relative;
  width: 100%;
}
.react-app ._container_1s2le_1._withWidgets_1s2le_14 ._fixedWidgetsCol_1s2le_29 ._reference_1s2le_29 ._widgets_1s2le_18 {
  position: fixed;
}
.react-app ._container_1s2le_1._withWidgets_1s2le_14 ._widgetContainer_1s2le_36 {
  position: relative;
}

.react-app ._container_1s2le_1._mobile_1s2le_40 ._contentCol_1s2le_9 {
  padding-right: 0 !important;
}
.react-app ._container_1s2le_1._mobile_1s2le_40 ._widgetsCol_1s2le_18 {
  margin-top: var(--space-big);
  padding-left: 0 !important;
}
.react-app ._container_1s2le_1._mobile_1s2le_40 ._widgetsCol_1s2le_18._prioritizedOverContent_1s2le_47 {
  margin-top: 0 !important;
}
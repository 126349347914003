import React from 'react'
import { Trans } from 'react-i18next'

import { Button } from '@fullfabric/alma-mater'

import CardContent from 'apps/StudyPlans/components/CardContent'
import CardInfo from 'apps/StudyPlans/components/CardInfo'
import { useSetSettingsSectionsBeingEdited } from 'apps/StudyPlans/contexts/SettingsSectionsBeingEdited'
import { useStudyPlan } from 'apps/StudyPlans/contexts/StudyPlanData'

const SelectionsDisplay = () => {
  const studyPlan = useStudyPlan()
  const setSectionBeingEdited = useSetSettingsSectionsBeingEdited()
  const onEditClick = React.useCallback(() => {
    setSectionBeingEdited((sectionsBeingEdited) => ({
      ...sectionsBeingEdited,
      paths: true
    }))
  }, [setSectionBeingEdited])

  let cardInfo = studyPlan.multiple_paths ? 'Multiple paths' : 'Single path'

  studyPlan.allow_course_overlap
    ? (cardInfo += ' with course overlap')
    : (cardInfo += ' without course overlap')

  return (
    <CardContent
      title={<Trans>Path & course selection settings</Trans>}
      actions={
        <Button size='small' onClick={onEditClick}>
          <Trans>Edit</Trans>
        </Button>
      }
    >
      <CardInfo
        main={<Trans>{cardInfo}</Trans>}
        secondary={
          studyPlan.multiple_paths
            ? (studyPlan.paths || []).map((path) => path.name).join(', ')
            : null
        }
      />
    </CardContent>
  )
}

export default SelectionsDisplay

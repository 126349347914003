import { submissionDataToFormData } from '@fullfabric/schemable-forms'

import updateCourse from 'apps/Courses/api/updateCourse'
import reduxStoreHelper from 'apps/Courses/utils/reduxStoreHelper'
import validateUpdate from 'apps/Courses/utils/validateUpdate'
import getDataToUpdate from './getDataToUpdate'

const updateCourseAction = (updateActionType) => async (dispatch, getState) => {
  const { editCourse: editionState } = getState()

  dispatch({ type: updateActionType.start })

  try {
    const dataToUpdate = getDataToUpdate(updateActionType, editionState)
    validateUpdate(dataToUpdate)

    const formattedData = await reduxStoreHelper.storeDataToSubmissionFormat(
      dataToUpdate
    )
    const result = await updateCourse({
      courseId: dataToUpdate.id,
      data: submissionDataToFormData(formattedData)
    })

    dispatch({ type: updateActionType.success, payload: result })
  } catch (error) {
    dispatch({ type: updateActionType.error, payload: error })
  }
}

export default updateCourseAction

import React from 'react'

import { XAxisTick } from 'apps/Reports/components/CustomChartElements'

const DynamicWeekXAxisTick = ({ payload, ...otherProps }) => {
  if (payload.index === 0) {
    return null
  }

  return <XAxisTick customValue={payload.index} {...otherProps} />
}

export default DynamicWeekXAxisTick

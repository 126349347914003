.react-app ._container_1ebco_1 {
  margin-bottom: var(--space-more);
}

.react-app ._link_1ebco_5 {
  display: inline-flex;
}

.react-app ._icon_1ebco_9 {
  margin-right: var(--space-small);
}
import React from 'react'

import {
  SUPPORTED_AUDIO_EXTENSIONS,
  SUPPORTED_IMG_EXTENSIONS,
  SUPPORTED_VIDEO_EXTENSIONS
} from 'apps/Evaluations/utils/constants'
import { ReactComponent as ImageThumbnail } from '../../icons/image.svg'
import { ReactComponent as PdfThumbnail } from '../../icons/pdf.svg'
import { ReactComponent as SoundThumbnail } from '../../icons/sound.svg'
import { ReactComponent as VideoThumbnail } from '../../icons/video.svg'

import styles from './styles.module.scss'

const UploadThumbnail = ({ upload }) => {
  const fileName = upload?.filename

  if (!fileName) return <ImageThumbnail className={styles.thumbnailImg} />

  const extension = fileName?.split('.').pop()

  if (SUPPORTED_IMG_EXTENSIONS.includes(extension))
    return <ImageThumbnail className={styles.thumbnailImg} />

  if (SUPPORTED_AUDIO_EXTENSIONS.includes(extension))
    return <SoundThumbnail className={styles.thumbnailImg} />
  if (SUPPORTED_VIDEO_EXTENSIONS.includes(extension))
    return <VideoThumbnail className={styles.thumbnailImg} />
  if (extension === 'pdf')
    return <PdfThumbnail className={styles.thumbnailImg} />

  return <ImageThumbnail className={styles.thumbnailImg} />
}

export default UploadThumbnail

import { utils } from '@fullfabric/public-api'

const bulkPublishPaymentPlans = async ({ dryRun, search, filters }) => {
  const params = { dry_run: dryRun, ...search, ...filters }

  const response = await fetch(`/book_keeper/api/profiles/plans/bulk_publish`, {
    credentials: 'same-origin',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(params)
  })

  return await utils.checkResponse(response)
}

export default bulkPublishPaymentPlans

import React from 'react'
import { useQuery } from 'react-query'

import { OneColumnLayout } from '@fullfabric/alma-mater'

import getClassOf from 'apps/Reports/api/getClassOf'
import FunnelReport from 'apps/Reports/components/FunnelReport'
import Head from './Head'

import styles from './styles.module.scss'

const FixedClassConversionPage = ({ classId }) => {
  const { data: classData } = useQuery(['getClassOf', classId], () =>
    getClassOf(classId)
  )

  return (
    <OneColumnLayout
      pageHead={<Head classId={classId} classData={classData} />}
      className={styles.pageSpacing}
    >
      <FunnelReport fixedContextId={classId} />
    </OneColumnLayout>
  )
}

export default FixedClassConversionPage

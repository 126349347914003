import React, { Component } from 'react'
import Dropzone from 'react-dropzone'

import EmptyContent from './empty-content'
import FileAcceptedContent from './file-accepted-content'
import FileInvalidValidContent from './file-invalid-content'
import FileValidContent from './file-valid-content'

import classNames from 'classnames'
import styles from './styles.module.scss'

class FileUploadInput extends Component {
  openFileDialog = (evt) => {
    evt.preventDefault()
    this.dropzoneRef && this.dropzoneRef.open()
  }

  render() {
    const { className, onFileSelected, selectedFile, ...props } = this.props

    return (
      <Dropzone
        disableClick
        onDrop={(file) => onFileSelected(file[0])}
        {...props}
        ref={(node) => {
          this.dropzoneRef = node
        }}
        activeClassName={styles.fileValid}
        rejectClassName={styles.fileInvalid}
        inputProps={{ 'aria-label': 'input your file' }}
        className={classNames(styles.fileUploadInput, className)}
      >
        {({ isDragActive, isDragReject, acceptedFiles }) => {
          if (isDragReject) {
            return <FileInvalidValidContent />
          }

          if (isDragActive) {
            return <FileValidContent />
          }

          const file = selectedFile || acceptedFiles[0]

          return file ? (
            <FileAcceptedContent
              fileName={file.name}
              onOpenFileDialog={this.openFileDialog}
            />
          ) : (
            <EmptyContent onOpenFileDialog={this.openFileDialog} />
          )
        }}
      </Dropzone>
    )
  }
}

export default FileUploadInput

.react-app ._staticPositioner_15p4a_1 {
  position: relative;
}

.react-app ._floatingPositioner_15p4a_5 {
  position: relative;
  height: 0;
  overflow: visible;
  right: calc(20px - 2 * var(--hub-half-gutter-width));
  top: calc(20px - 2 * var(--hub-half-gutter-width));
}

.react-app ._secondaryButton_15p4a_13 {
  background-color: var(--ff-bg-base-light);
  border: var(--border-base-lighter);
  box-shadow: var(--shadow-1);
}

.react-app ._dropdown_15p4a_19 {
  position: absolute;
  top: 34px;
  left: calc(-1 * var(--space-small));
  padding: var(--space-small);
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--ff-bg-base-lightest);
  box-shadow: var(--shadow-3);
  border-radius: 4px;
}

.react-app ._localeButton_15p4a_32 {
  margin-bottom: var(--space-default);
}
.react-app ._localeButton_15p4a_32._last_15p4a_35 {
  margin-bottom: 0;
}
import React from 'react'

const useUpdateSelectedRows = (
  selectableRows,
  onSelectionChanged,
  selectedFlatRows
) => {
  React.useEffect(() => {
    selectableRows &&
      onSelectionChanged &&
      onSelectionChanged(
        selectedFlatRows.map((row) => row.original),
        selectedFlatRows
      )
  }, [selectableRows, onSelectionChanged, selectedFlatRows])
}

export default useUpdateSelectedRows

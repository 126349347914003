import { push } from 'connected-react-router'

import apiDeleteCourse from 'apps/Courses/api/deleteCourse'
import { HOME_ROUTE } from 'apps/Courses/utils/routeConstants'
import actionTypes from '../types'

const deleteCourseAction = () => async (dispatch, getState) => {
  const {
    deleteCourse: { data: course, withinEditPage }
  } = getState()
  dispatch({ type: actionTypes.deleteCourse.start })

  try {
    await apiDeleteCourse(course.id)
    dispatch({
      type: actionTypes.deleteCourse.success,
      payload: { id: course.id }
    })

    if (withinEditPage) {
      dispatch(push(HOME_ROUTE))
    }
  } catch (error) {
    dispatch({ type: actionTypes.deleteCourse.error, payload: error })
  }
}

export default deleteCourseAction

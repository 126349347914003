import React from 'react'

import { useCurrentUser } from '@fullfabric/authorization-officer'

import { AppDataContext } from 'shared/contexts/AppData'

import getMainTextWidget from 'apps/ContentPages/utils/get-main-text-widget'

const getLocale = (user) => {
  return user?.locale || 'en-GB'
}

const useAppData = () => {
  const context = React.useContext(AppDataContext)

  if (typeof context === 'undefined') {
    throw new Error('useAppData must be within an AppDataProvider')
  }

  return context
}

const usePage = () => {
  const appData = useAppData()
  const { page } = appData

  if (!page) {
    return
  }

  return page
}

const usePageContent = () => {
  const user = useCurrentUser()
  const locale = getLocale(user)
  const page = usePage()

  if (!page) {
    return {}
  }

  const localizedContent = page.locals.find(
    (localizedContent) => localizedContent.locale === locale
  )
  const content = page.locals[0] // e.g for forms, there is only one possible "local" object, independent from the selected locale

  return { content, localizedContent }
}

const useMainTextContent = () => {
  const { content } = usePageContent()

  if (!content) {
    return ''
  }

  const mainTextWidget = getMainTextWidget(content.widgets)

  return mainTextWidget && mainTextWidget.content
}

export { useAppData, usePage, usePageContent, useMainTextContent }

import React from 'react'

import { Loading, Row } from '@fullfabric/alma-mater'

import classNames from 'classnames'
import styles from './styles.module.scss'

export default function FullScreenLoading() {
  return (
    <Row
      className={classNames('loading', styles.container)}
      align='center'
      justify='center'
    >
      <Loading type='triple' />
    </Row>
  )
}

import React from 'react'
import { useTranslation } from 'react-i18next'

import { compact, isEmpty } from 'lodash-es'

import { Alert, OneColumnLayout } from '@fullfabric/alma-mater'

import StudyPlanCreationModal from 'apps/StudyPlans/components/StudyPlanCreationModal'
import SubjectGroupsList from 'apps/StudyPlans/components/SubjectGroupsList'
import { SelectedPathProvider } from 'apps/StudyPlans/contexts/SelectedPath'
import { useStudyPlan } from 'apps/StudyPlans/contexts/StudyPlanData'
import PageHeader from './PageHeader'

const SubjectsPage = () => {
  const { t } = useTranslation()
  const studyPlan = useStudyPlan()

  const displayOverlapAlert =
    !studyPlan?.allow_course_overlap &&
    studyPlan?.courses?.length &&
    studyPlan?.courses
      .flatMap((spSubject) => compact(spSubject?.course?.courses))
      .some((course) => !course.starts_on || !course.ends_on)

  return (
    <OneColumnLayout pageHead={<PageHeader />}>
      {isEmpty(studyPlan) ? (
        <StudyPlanCreationModal />
      ) : (
        <SelectedPathProvider>
          {displayOverlapAlert && (
            <Alert
              type='warning'
              title={t('Course dates missing.')}
              description={t(
                'Some courses in the study plan do not have defined start or end dates, so we cannot guarantee that there will be no overlap in the selection.'
              )}
              marginBottom='more'
            />
          )}
          <SubjectGroupsList />
        </SelectedPathProvider>
      )}
    </OneColumnLayout>
  )
}

export default SubjectsPage

import React from 'react'
import { Trans } from 'react-i18next'

import { Button, Text } from '@fullfabric/alma-mater'

import styles from './styles.module.scss'

const EmptyContent = ({ onOpenFileDialog }) => (
  <div className={styles.fileUploadContent}>
    <Text marginBottom='small'>
      <Trans>Drag and drop your file here...</Trans>
    </Text>
    <Button onClick={onOpenFileDialog}>
      <Trans>or Browse</Trans>
    </Button>
  </div>
)

export default EmptyContent

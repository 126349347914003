import React from 'react'

import { Text } from '@fullfabric/alma-mater'

import PremiumLabel from './PremiumLabel'
import Toggle from './Toggle'

import styles from './styles.module.scss'

const ToggleableTab = ({
  name,
  description,
  isPremiumExclusive,
  ...toggleProps
}) => (
  <div className={styles.container}>
    <div className={styles.infoContainer}>
      <div className={styles.titleContainer}>
        <Text type='h4' fontColor='text-base-darkest'>
          {name}
        </Text>
        {isPremiumExclusive && <PremiumLabel />}
      </div>
      <Text type='f6' fontColor='text-base-darker'>
        {description}
      </Text>
    </div>
    <div className={styles.toggleContainer}>
      <Toggle {...toggleProps} />
    </div>
  </div>
)

export default ToggleableTab

.react-app ._section_5yl43_1 {
  margin-left: -8px;
  margin-left: calc(-1 * var(--half-gutter-width));
  margin-right: -8px;
  margin-right: calc(-1 * var(--half-gutter-width));
  margin-top: 24px;
  margin-top: var(--space-more);
  padding: 16px;
  padding: var(--space-default);
  border-radius: 4px;
  border-radius: var(--border-radius-default);
}
.react-app ._section_5yl43_1 ._editLink_5yl43_13 {
  float: right;
}
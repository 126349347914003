import { utils } from '@fullfabric/public-api'

const editSubject = async (id, data) => {
  const response = await fetch(`/affairs/api/courses/${id}`, {
    credentials: 'same-origin',
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      code: data?.code?.trim(),
      name: data?.name?.trim(),
      external_id: data?.external_id?.trim(),
      theme_id: data?.theme_id
    })
  })

  return await utils.checkResponse(response)
}

export default editSubject

import React from 'react'
import { useTranslation } from 'react-i18next'

import { Text } from '@fullfabric/alma-mater'

import styles from './styles.module.scss'

const Placeholder = ({ width }) => {
  const { t } = useTranslation()

  return (
    <>
      <svg
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        className={styles.chartContainer}
      >
        <rect
          y='24'
          x='0'
          width={width}
          height='8'
          rx='2'
          ry='2'
          fill='#EDF0F3'
        />
        <rect
          x={(width * 13) / 508}
          width='32'
          height='18'
          rx='2'
          fill='#EDF0F3'
        />
        <rect
          x={(width * 47) / 508 - 8}
          y='39'
          width='32'
          height='17'
          rx='2'
          fill='#EDF0F3'
        />
        <rect
          x={(width * 83) / 508}
          y='24'
          width='1'
          height='32'
          fill='#1F2026'
          fillOpacity='0.4'
        />
        <rect
          x={(width * 127) / 508 - 8}
          y='39'
          width='32'
          height='17'
          rx='2'
          fill='#EDF0F3'
        />
        <rect
          x={(width * 163) / 508}
          y='24'
          width='1'
          height='32'
          fill='#1F2026'
          fillOpacity='0.4'
        />
        <rect
          x={(width * 471) / 508 - 8}
          y='39'
          width='32'
          height='17'
          rx='2'
          fill='#EDF0F3'
        />
        <rect
          x={width}
          y='24'
          width='1'
          height='32'
          fill='#1F2026'
          fillOpacity='0.4'
        />
      </svg>

      <Text tag='span' type='h6' className={styles.placeholderText}>
        {t("Please specify the product's capacities")}
      </Text>
    </>
  )
}

export default Placeholder

.react-app ._topSpacing_9gb8y_1 {
  border-top: var(--border-base-lighter);
  margin-bottom: var(--space-more);
}

.react-app ._highlight_9gb8y_6 {
  height: var(--space-small);
  width: 100%;
  margin-bottom: var(--space-default);
}

.react-app ._highlight_9gb8y_6._overdue_9gb8y_12 {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background-color: var(--ff-bg-alert);
}

.react-app ._highlight_9gb8y_6._almostDue_9gb8y_18 {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background-color: var(--ff-color-yellow-500);
}

.react-app ._container_9gb8y_24 {
  padding: var(--space-more);
  padding-top: 0;
}

.react-app ._container_9gb8y_24._mobile_9gb8y_29 {
  padding: var(--space-default);
  padding-top: 0;
}
import React from 'react'
import { Trans } from 'react-i18next'

import { Text } from '@fullfabric/alma-mater'

import useFetchedImportData from 'apps/Imports/components/import-report-step/hooks/use-fetched-import-data'
import MappingTable from 'apps/Imports/components/mapping-table'
import getCsvMappings from 'apps/Imports/utils/get-csv-mappings'

const MappingSummary = () => {
  const { document, mappingFieldOptions } = useFetchedImportData()

  return (
    <>
      <Text
        fontColor='text-base-darkest'
        marginTop='more'
        marginBottom='default'
      >
        <Trans>Mapping</Trans>
      </Text>
      <MappingTable
        mappings={getCsvMappings(mappingFieldOptions, document?.mapping || [])}
      />
    </>
  )
}

export default MappingSummary

import React from 'react'
import { Trans } from 'react-i18next'

import { useImportType } from 'apps/Imports/contexts/steps'
import { IMPORT_TYPES } from 'apps/Imports/utils/constants'

const SidebarTitle = () => {
  const importType = useImportType()

  return importType === IMPORT_TYPES.PROFILE ? (
    <Trans>Import Profiles</Trans>
  ) : importType === IMPORT_TYPES.PROGRAMME_AND_CLASS ? (
    <Trans>Import Prog. & Classes</Trans>
  ) : (
    <Trans>Import Subjects & Courses</Trans>
  )
}

export default SidebarTitle

import React from 'react'
import { Route, Switch } from 'react-router'

import EvaluationPage from 'apps/Evaluations/pages/EvaluationPage'
import EvaluationsListPage from 'apps/Evaluations/pages/EvaluationsListPage'
import {
  EVALUATION_FORM_PAGE_ROUTE,
  EVALUATIONS_LIST_PAGE_ROUTE
} from './constants'

const EvaluationsAppRoutes = () => {
  return (
    <Switch>
      <Route
        exact
        path={EVALUATIONS_LIST_PAGE_ROUTE}
        component={EvaluationsListPage}
      />
      <Route
        exact
        path={EVALUATION_FORM_PAGE_ROUTE}
        component={EvaluationPage}
      />
    </Switch>
  )
}

export default EvaluationsAppRoutes

import * as React from 'react'

import BreakdownChart from 'apps/Reports/components/ProductPerformanceBreakdownChart'
import CapacityChart from 'apps/Reports/components/ProductPerformanceCapacityChart'
import { CAPACITY_BREAKDOWN_CHART } from 'apps/Reports/constants/productPerformanceCharts'

const ChartCell = ({ chartType, row, data }) => {
  const product = data[row.index]
  const Chart =
    chartType === CAPACITY_BREAKDOWN_CHART ? BreakdownChart : CapacityChart

  return <Chart product={product} />
}

export default ChartCell

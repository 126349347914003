import { utils } from '@fullfabric/public-api'

const createEvaluation = async (data) => {
  const response = await fetch('/evaluations/api/evaluations', {
    credentials: 'same-origin',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  })

  return await utils.checkResponse(response)
}

export default createEvaluation

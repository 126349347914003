import React from 'react'
import { useTranslation } from 'react-i18next'

import { Filter } from '@fullfabric/alma-mater'

import getLifecyclesAsOptions from 'shared/api/getLifecyclesAsOptions'

import styles from './styles.module.scss'

function FixedClassFilter({
  selectableClasses,
  filter,
  onFilterChange,
  onOptionsLoaded
}) {
  const { t } = useTranslation()

  return (
    <div data-testid='filterBar'>
      <Filter
        onFilterChange={onFilterChange}
        onOptionsLoaded={onOptionsLoaded}
        selectedFilter={filter}
        containerClassName={styles.filter}
        filters={[
          {
            id: 'classes',
            searchField: 'name',
            name: t('Compare with'),
            labelValueSeparator: '',
            type: 'multi',
            valueField: 'id',
            labelField: 'name',
            options: selectableClasses
          },
          {
            id: 'states',
            searchField: 'label',
            defaultLabel: t('All lifecycles'),
            defaultValue: (opts) =>
              opts.find((opt) => opt?.value === 'prospect::started_application')
                ? ['prospect::started_application']
                : [opts[0]?.value],
            name: t('Lifecycles'),
            type: 'multi',
            labelValueSeparator: ' are',
            loadOptions: getLifecyclesAsOptions
          },
          {
            id: 'withdrawn',
            name: t('Withdrawn'),
            defaultValue: 'false',
            type: 'single',
            options: [
              { value: 'true', label: t('has withdrawn') },
              { value: 'false', label: t('has not withdrawn') },
              { value: 'all', label: t('all') }
            ]
          }
        ]}
      />
    </div>
  )
}

export default FixedClassFilter

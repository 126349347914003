import React from 'react'

function OverdueIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='12'
      height='12'
      fill='none'
      viewBox='0 0 12 12'
      aria-label='overdue fee alert'
    >
      <path
        fill='#D22A0C'
        fillRule='evenodd'
        d='M6 0C2.692 0 0 2.692 0 6s2.692 6 6 6 6-2.692 6-6-2.692-6-6-6zM5 4a1 1 0 012 0v1a1 1 0 01-2 0V4zm1.015 5h.019a.983.983 0 00.965-1.02A1.018 1.018 0 005.985 7h-.019a.983.983 0 00-.965 1.02A1.02 1.02 0 006.015 9z'
        clipRule='evenodd'
      ></path>
    </svg>
  )
}

export default OverdueIcon

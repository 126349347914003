const getOffers = async (profileId) =>
  fetch(`/offers/api/profiles/${profileId}/offers`, {
    credentials: 'same-origin'
  }).then((result) => {
    if (!result.ok) {
      throw new Error(result.statusText)
    }
    return result.json()
  })

export default getOffers

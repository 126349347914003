import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { submitForm } from 'apps/ContentPages/api'
import processSubmissionError from 'apps/ContentPages/utils/processSubmissionError'

export default function useHandleSubmit({
  redirectTo,
  formId,
  formRequestId,
  onStartSubmit,
  onSubmitted = () => {},
  onNotSubmitted
}) {
  const { t } = useTranslation()
  const location = useLocation()

  const handleSubmit = useCallback(
    async (submissionData) => {
      try {
        onStartSubmit && onStartSubmit()

        await submitForm(formId, submissionData, {
          requestId: formRequestId
        })

        if (redirectTo) window.open(redirectTo, 'form-success-redirect-tab')

        onSubmitted()

        const isInSubmittedPage = location.pathname.match(/\/submitted\/?$/)
        if (!isInSubmittedPage) {
          const separator = location.pathname.endsWith('/') ? '' : '/' // avoids a pathname like `path.com//submitted`
          window.history.replaceState(
            {},
            '',
            `${location.pathname}${separator}submitted`
          )
        }
      } catch (err) {
        const submissionError = processSubmissionError(err, t)

        onNotSubmitted && onNotSubmitted(submissionError)
      }
    },
    [
      formId,
      formRequestId,
      redirectTo,
      onSubmitted,
      location.pathname,
      onStartSubmit,
      onNotSubmitted,
      t
    ]
  )

  return handleSubmit
}

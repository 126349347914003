import React from 'react'
import { Trans } from 'react-i18next'

import { Button } from '@fullfabric/alma-mater'

import DeleteSubjectModal from 'apps/StudyPlans/components/DeleteSubjectModal'
import { useOriginalSubjectData } from 'apps/StudyPlans/contexts/SubjectEditionData'

import styles from './styles.module.scss'

const DeleteSubjectButton = () => {
  const originalSubjectData = useOriginalSubjectData()
  const [deleteModalOpen, setDeleteModalOpen] = React.useState(false)
  const openDeleteModal = React.useCallback(
    (evt) => {
      evt.stopPropagation()
      setDeleteModalOpen(true)
    },
    [setDeleteModalOpen]
  )

  const closeDeleteModal = React.useCallback(() => {
    setDeleteModalOpen(false)
  }, [setDeleteModalOpen])

  return (
    <>
      <DeleteSubjectModal
        isOpen={deleteModalOpen}
        onCancel={closeDeleteModal}
        onDeleted={closeDeleteModal}
        subject={originalSubjectData}
      />
      <Button
        negative
        className={styles.deleteButton}
        onClick={openDeleteModal}
      >
        <Trans>Delete Subject</Trans>
      </Button>
    </>
  )
}

export default DeleteSubjectButton

import { utils } from '@fullfabric/public-api'

import updateOrganization from 'apps/Authentication/utils/update-organization'

const createContract = async (profileId, data, createAsContact = false) => {
  if (createAsContact) {
    data.append('create_as_contact', true)
  }

  const response = await fetch(`/api/profiles/${profileId}/contracts`, {
    method: 'POST',
    body: data
  })

  const result = await utils.checkResponse(response)
  return updateOrganization(result)
}

export default createContract

import React from 'react'
import { Trans } from 'react-i18next'
import { useHistory, useParams } from 'react-router'

import { Button } from '@fullfabric/alma-mater'

const PreviousButton = () => {
  const history = useHistory()
  const { id: sourceProfileId } = useParams()
  const onPreviousClick = React.useCallback(() => {
    history.push(`/profiles/${sourceProfileId}/merge`)
  }, [history, sourceProfileId])

  return (
    <Button onClick={onPreviousClick}>
      <Trans>Previous</Trans>
    </Button>
  )
}

export default PreviousButton

import React from 'react'

import nextSortCalculator from '../nextSortCalculator'

const DEFAULT_SORT_CONFIG = {
  sortKey: 'sort[field]',
  directionKey: 'sort[direction]',
  ascValue: 1,
  descValue: -1
}

const useTableSort = ({
  sortable,
  sortParamsConfig = {},
  initialSort = {},
  tableProps,
  onSortChanged
}) => {
  const sortConfig = React.useMemo(
    () => ({ ...DEFAULT_SORT_CONFIG, ...sortParamsConfig }),
    [sortParamsConfig]
  )

  const { getNextSortParams, getTableSortState } = React.useMemo(
    () => (sortable ? nextSortCalculator(sortConfig) : {}),
    [sortable, sortConfig]
  )

  const [sortParams, setSortParams] = React.useState(initialSort)

  React.useEffect(() => {
    onSortChanged && onSortChanged(sortParams)
  }, [sortParams, onSortChanged])

  const handleChangeSort = React.useCallback(
    (column, setTableSortState) => {
      const nextSort = getNextSortParams(sortParams, column.id)

      setSortParams(nextSort)
      setTableSortState(getTableSortState(nextSort))
    },
    [sortParams, getNextSortParams, getTableSortState]
  )

  const tablePropsWithSortState =
    sortable && initialSort
      ? {
          ...(tableProps || {}),
          initialState: {
            ...(tableProps?.initialState || {}),
            sortBy: getTableSortState(initialSort)
          }
        }
      : tableProps

  return {
    handleChangeSort,
    sortState: sortParams,
    tablePropsWithSortState
  }
}

export default useTableSort

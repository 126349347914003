import { utils } from '@fullfabric/public-api'

const deleteSubject = async (id) => {
  const response = await fetch(`/affairs/api/courses/${id}`, {
    credentials: 'same-origin',
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ id })
  })

  return await utils.checkResponse(response)
}

export default deleteSubject

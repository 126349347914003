import React, { useContext } from 'react'
import { useQuery } from 'react-query'

import getApplic from 'apps/Applics/api/getApplic'

const ApplicDataContext = React.createContext()

const ApplicDataProvider = ({ applicId, children }) => {
  const { data: applic } = useQuery(['applic', applicId], getApplic, {
    enabled: !!applicId,
    suspense: true
  })

  return (
    <ApplicDataContext.Provider value={applic}>
      {children}
    </ApplicDataContext.Provider>
  )
}

const useApplicData = () => useContext(ApplicDataContext)

export { ApplicDataProvider, useApplicData, ApplicDataContext }

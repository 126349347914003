import React, { useEffect, useState } from 'react'
import { Trans } from 'react-i18next'

import { isEmpty } from 'lodash-es'

import {
  Button,
  Field,
  Modal,
  ModalButtons,
  ModalTitle,
  Text
} from '@fullfabric/alma-mater'
import { useCurrentUser } from '@fullfabric/authorization-officer'

import api from 'apps/ContentPages/api'
import { useTemplate } from '../../template-info-context'
import { useTypeOfWidget } from '../../type-of-widget-context'
import {
  useAdvance,
  useCloseModal,
  useModalsState,
  useStartApplicationParams
} from '../start-application-state-context'
import emailValidation from './email-validation'

import styles from './styles.module.scss'

const ThirdPartyApplicationModal = () => {
  const { isRegistration } = useTypeOfWidget()
  const template = useTemplate()
  const user = useCurrentUser()
  const {
    thirdPartyApplication: { visible }
  } = useModalsState()
  const startApplicationParams = useStartApplicationParams()
  const closeModal = useCloseModal()
  const advance = useAdvance()

  const handleSubmit = async ({ applicationId, profileId }) => {
    await advance({ applicationId, profileId })
  }

  const checkEmail = async () => {
    if (checkEmailLocally()) {
      setSubmitting(true)
      const lookupReponse = await api.lookupUserEmail(form.email)
      if (lookupReponse.status === 'unknown') {
        setFinalSubmission(true)
      } else if (lookupReponse.status === 'inactive') {
        const applicsByProfileResponse = await api.getApplicationsByProfile(
          form.email
        )
        if (!isEmpty(applicsByProfileResponse)) {
          const templateApplics = applicsByProfileResponse.filter(
            (applic) => applic.template_id === template.id
          )

          if (isEmpty(templateApplics)) {
            setFinalSubmission(true)
          } else {
            let allowFinalSubmission = true

            templateApplics.forEach((applic) => {
              if (
                applic.started_by_profile_id !== user?.id ||
                applic.state === 'started'
              ) {
                setFormErrorsState({
                  email: (
                    <Trans>
                      Someone already registered with that email address
                    </Trans>
                  )
                })
                allowFinalSubmission = false
              }
            })
            setFinalSubmission(allowFinalSubmission)
          }
        } else {
          setFinalSubmission(true)
        }
      } else {
        setFormErrorsState({
          email: (
            <Trans>Someone already registered with that email address</Trans>
          )
        })
        setFinalSubmission(false)
      }
      setSubmitting(false)
    } else {
      setFinalSubmission(false)
    }
  }

  const checkEmailLocally = () => {
    if (emailValidation(form.email)) {
      return true
    }
    setFormErrorsState({ email: <Trans>Email address is invalid</Trans> })
    return false
  }

  const checkName = () => {
    let errors = false
    if (!form.firstName.trim().length) {
      setFormErrorsState({ firstName: <Trans>This field is required</Trans> })
      errors = true
    }
    if (!form.lastName.trim().length) {
      setFormErrorsState({ lastName: <Trans>This field is required</Trans> })
      errors = true
    }
    return !errors
  }

  const submitForm = async () => {
    if (!checkName()) {
      checkEmailLocally()
      return
    }

    if (!checkEmailLocally()) {
      return
    }

    setSubmitting(true)

    const applic = await api.startApplicationOnBehalf(
      template.id,
      {
        email: form.email,
        first_name: form.firstName,
        last_name: form.lastName
      },
      startApplicationParams && startApplicationParams.context
    )

    if (applic.errors) {
      const {
        email,
        first_name: firstName,
        last_name: lastName
      } = applic.errors

      let emailError = null
      if (email === 'invalid') {
        emailError = <Trans>Email address is invalid</Trans>
      }
      if (email === 'taken') {
        emailError = <Trans>Email already in use</Trans>
      }

      const firstNameError = firstName ? (
        <Trans>This field is required</Trans>
      ) : null
      const lastNameError = lastName ? (
        <Trans>This field is required</Trans>
      ) : null

      setFormErrorsState({
        email: emailError,
        firstName: firstNameError,
        lastName: lastNameError
      })
      setSubmitting(false)
    } else {
      handleSubmit({
        applicationId: applic.id,
        profileId: applic.profile_id
      })
    }
  }

  const [form, setForm] = useState({
    email: '',
    firstName: '',
    lastName: ''
  })

  const [formErrors, setFormErrors] = useState({
    email: null,
    firstName: null,
    lastName: null
  })

  const [submitting, setSubmitting] = useState(false)

  const [finalSubmission, setFinalSubmission] = useState(false)

  useEffect(() => {
    setForm({
      email: '',
      firstName: '',
      lastName: ''
    })
    setFormErrors({
      email: null,
      firstName: null,
      lastName: null
    })
    setFinalSubmission(false)
    setSubmitting(false)
  }, [visible])

  const setFormState = (update) => {
    setForm({ ...form, ...update })
  }

  const setFormErrorsState = (update) => {
    setFormErrors((prevState) => ({ ...prevState, ...update }))
  }

  const onEmailChange = (id, email) => {
    setFormErrorsState({ email: null })
    setFormState({ email })
  }

  const onFirstNameChange = (id, firstName) => {
    setFormErrorsState({ firstName: null })
    setFormState({ firstName })
  }

  const onLastNameChange = (id, lastName) => {
    setFormErrorsState({ lastName: null })
    setFormState({ lastName })
  }

  return (
    <Modal
      data-testid='third-party-application-modal'
      isOpen={visible}
      className={styles.modalStyle}
      header={
        <ModalTitle
          title={<Trans>Participant details</Trans>}
          onClose={closeModal}
        />
      }
      footer={
        <ModalButtons>
          {!finalSubmission && (
            <Button primary onClick={checkEmail} loading={submitting}>
              <Trans>Continue</Trans>
            </Button>
          )}
          {finalSubmission && (
            <Button primary onClick={submitForm} loading={submitting}>
              {isRegistration ? (
                <Trans>Start registration</Trans>
              ) : (
                <Trans>Start application</Trans>
              )}
            </Button>
          )}
        </ModalButtons>
      }
    >
      <Text
        tag='div'
        type='f5'
        marginBottom='default'
        fontColor='text-base-darkest'
      >
        <Trans render='span'>Please enter the details of the participant</Trans>
      </Text>
      <Field
        className={styles.modalInput}
        id='email'
        label={<Trans>Email address</Trans>}
        required
        error={formErrors.email}
        onChange={onEmailChange}
        type='email'
      />
      {finalSubmission && (
        <>
          <Field
            className={styles.modalInput}
            id='first_name'
            label={<Trans>First name</Trans>}
            required
            error={formErrors.firstName}
            type='text'
            onChange={onFirstNameChange}
          />
          <Field
            className={styles.modalInput}
            id='last_name'
            label={<Trans>Last name</Trans>}
            required
            error={formErrors.lastName}
            type='text'
            onChange={onLastNameChange}
          />
        </>
      )}
    </Modal>
  )
}

export default ThirdPartyApplicationModal

import { utils } from '@fullfabric/public-api'

const getProgrammeClasses = async (programmeId) => {
  if (!programmeId) return []

  const response = await fetch(
    `/api/programmes/${programmeId}/classes?skip_stats=true`,
    {
      credentials: 'same-origin'
    }
  )
  const result = await utils.checkResponse(response)

  return result.sort(
    (cA, cB) =>
      new Date(cB.opens_on).getTime() - new Date(cA.opens_on).getTime()
  ) // bigger numbers = more recent dates
}

export default getProgrammeClasses

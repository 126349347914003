import React, { useState } from 'react'

import { PlainButton } from '@fullfabric/alma-mater'

import { useLocale, useSetLocale } from 'shared/contexts/Locale'

import classNames from 'classnames'
import styles from './styles.module.scss'

const LocaleSwitcherDropdown = ({ floating }) => {
  const { locale: currentLocale, allLocales } = useLocale()
  const setLocale = useSetLocale()
  const onClickLocale = (l) => {
    setLocale(l)

    // eslint-disable-next-line no-restricted-globals
    location.reload()
  }
  const [isDropdownOpen, setDropdownOpen] = useState(false)

  if (!allLocales || allLocales.length <= 1) {
    return null
  }

  return (
    <div
      className={classNames(
        floating ? styles.floatingPositioner : styles.staticPositioner
      )}
      data-testid='locale-switcher-dropdown'
    >
      <PlainButton
        className={classNames(!isDropdownOpen && styles.secondaryButton)}
        primary={isDropdownOpen}
        active={isDropdownOpen}
        onClick={() => setDropdownOpen((isDropdownOpen) => !isDropdownOpen)}
      >
        {currentLocale.split('-')[0]?.toUpperCase()}
      </PlainButton>
      {isDropdownOpen ? (
        <div className={styles.dropdown}>
          {allLocales.map((locale, index) => {
            const isCurrentLocale = locale === currentLocale
            return (
              <PlainButton
                key={locale}
                primary={isCurrentLocale}
                active={isCurrentLocale}
                onClick={() => onClickLocale(locale)}
                className={classNames(
                  styles.localeButton,
                  index === allLocales.length - 1 && styles.last
                )}
              >
                {locale.split('-')[0]?.toUpperCase()}
              </PlainButton>
            )
          })}
        </div>
      ) : null}
    </div>
  )
}

export default LocaleSwitcherDropdown

import React from 'react'

import PropTypes from 'prop-types'

import FormBody from '../../form-body'
import WidgetSection from '../../widget-section'

export default function FormWidget({ widget }) {
  return (
    <WidgetSection single>
      <FormBody inCard formId={widget.form_id} />
    </WidgetSection>
  )
}

FormWidget.propTypes = {
  widget: PropTypes.shape({
    form_id: PropTypes.string.isRequired
  }).isRequired
}

import React from 'react'
import { matchPath, useLocation } from 'react-router-dom'

import { useScreenClass } from '@fullfabric/alma-mater'

import LinkAccordionFolder from '../../link-accordion-folder'
import LinkPopUpFolder from '../../link-pop-up-folder'
import shouldUseMobileNavigation from '../../should-use-mobile-navigation'
import Link from '../secondary-link'
import ArrowIcon from './arrow-icon'

import classNames from 'classnames'
import styles from './styles.module.scss'

export default function LinkGroup({ group }) {
  const location = useLocation()
  const screenClass = useScreenClass()
  const isMobileNavigation = shouldUseMobileNavigation(screenClass)

  const parentLinkIndex = group.findIndex((link) => !link.parent_id)

  if (parentLinkIndex === -1) {
    console.error(`LinkGroup: No parent link found in group:`, group)
    return null
  }

  const parentLink = group[parentLinkIndex]
  const subLinks = [
    ...group.slice(0, parentLinkIndex),
    ...group.slice(parentLinkIndex + 1)
  ]

  const subLinkSelected = subLinks.some((link) => {
    const { path } = link
    const match = matchPath(path, location.pathname)
    return match && match.isExact
  })

  if (isMobileNavigation) {
    return (
      <LinkAccordionFolder links={subLinks}>
        {({ isOpen, toggleOpen }) => (
          <Link
            onClick={toggleOpen}
            label={parentLink.navigation_label}
            selected={subLinkSelected}
            icon={
              <ArrowIcon
                className={classNames([
                  styles.mobileArrow,
                  isOpen && styles.openedArrow
                ])}
              />
            }
          />
        )}
      </LinkAccordionFolder>
    )
  }

  return (
    <LinkPopUpFolder links={subLinks}>
      <Link
        label={parentLink.navigation_label}
        selected={subLinkSelected}
        icon={<ArrowIcon className={styles.arrow} />}
      />
    </LinkPopUpFolder>
  )
}

import React from 'react'

const TemplateInfoContext = React.createContext()

const TemplateInfoProvider = ({
  template,
  linkedApplication,
  linkedApplications,
  classOfTemplate,
  ...props
}) => {
  return (
    <TemplateInfoContext.Provider
      value={{
        template,
        linkedApplication,
        linkedApplications,
        classOfTemplate
      }}
      {...props}
    />
  )
}

const useTemplate = () => {
  const context = React.useContext(TemplateInfoContext)

  if (typeof context === 'undefined') {
    throw new Error('useTemplate must be within a TemplateInfoProvider')
  }

  return context.template
}

const useLinkedApplication = () => {
  const context = React.useContext(TemplateInfoContext)

  if (typeof context === 'undefined') {
    throw new Error(
      'useLinkedApplication must be within a TemplateInfoProvider'
    )
  }

  return context.linkedApplication
}

const useLinkedApplications = () => {
  const context = React.useContext(TemplateInfoContext)

  if (typeof context === 'undefined') {
    throw new Error(
      'useLinkedApplications must be within a TemplateInfoProvider'
    )
  }

  return context.linkedApplications
}

const useClassOfTemplate = () => {
  const context = React.useContext(TemplateInfoContext)

  if (typeof context === 'undefined') {
    throw new Error('useClassOfTemplate must be within a TemplateInfoProvider')
  }

  return context.classOfTemplate
}

export {
  TemplateInfoProvider,
  useTemplate,
  useLinkedApplication,
  useLinkedApplications,
  useClassOfTemplate
}

{
  "Analytical": "Cookies analytiques",
  "Strictly necessary": "Cookies absolument nécessaires",
  "Functional": "Cookies fonctionnels",
  "Advertising": "Cookies publicitaires",
  "Submit": "Envoyer",
  "Thank you": "Merci",
  "You have confirmed your marketing preferences.": "Vous avez confirmé vos préférences marketing.",
  "You did not accept the Privacy Policy.": "Vous n'avez pas accepté la politique de confidentialité.",
  "You must contact us to delete your data.": "Vous devez nous contacter pour supprimer vos données.",
  "Change privacy policy agreement": "Modifier l'accord de la politique de confidentialité",
  "Privacy Policy": "Politique de confidentialité",
  "Next: Update marketing preferences": "Suivant: Mettre à jour les préférences de marketing",
  "I Accept the Privacy Policy": "J'accepte la politique de confidentialité",
  "I DO NOT accept the Privacy Policy": "Je n'accepte pas la politique de confidentialité",
  "If you do not accept the privacy policy, you must contact the data processor to delete your data.": "Si vous n'acceptez pas la politique de confidentialité, vous devez contacter le sous-traitant pour supprimer vos données.",
  "You have updated your marketing preferences.": "Vous avez mis à jour vos préférences marketing.",
  "Log in": "Se connecter",
  "Finish your registration": "Terminez votre inscription",
  "Marketing Policy": "Politique de marketing",
  "I would like to receive information by Email": "Je souhaite recevoir des informations par e-mail",
  "I would like to receive information by Phone": "Je souhaite recevoir des informations par téléphone",
  "I would like to receive information by SMS": "Je souhaite recevoir des informations par SMS",
  "I would like to receive information by Mail": "Je souhaite recevoir des informations par courrier",
  "I do not want to be contacted with this type of information": "Je ne voudrais pas recevoir ce type d'information",
  "Cookie Policy": "Politique relative aux cookies",
  "Back to Portal": "Retour au portail",
  "Manage your preferences": "Gérer vos préférences",
  "Save cookie preferences": "Enregistrer les préférences du cookie",
  "You accepted our privacy policy on our website - {{institutionHost}} - on {{date}}": "Vous avez accepté notre politique de confidentialité sur notre site web - {{institutionHost}} - le {{date}}",
  "You are receiving this email because you accepted our marketing policy on our website - {{institutionHost}} - on  {{date}}": "Vous recevez cet e-mail car vous avez accepté notre politique de marketing sur notre site web - {{institutionHost}} - le {{date}}",
  "You accepted our privacy policy when creating an account on {{date}}": "Vous avez accepté notre politique de confidentialité lors de la création d'un compte le {{date}}",
  "You accepted our privacy policy on {{date}} from our email": "Vous avez accepté notre politique de confidentialité le {{date}} à partir de notre adresse e-mail",
  "You are receiving this email because you accepted our marketing policy on {{date}} from our email": "Vous recevez cet e-mail, car vous avez accepté notre politique de marketing le {{date}} à partir de notre adresse e-mail",
  "You are receiving this email because a person created a profile for you and obtained your consent from {{consentSource}} on {{date}}": "Vous recevez cet e-mail parce qu'une personne a créé un profil en votre nom et a obtenu votre consentement lors de \"{{consentSource}}\" le {{date}}",
  "You accepted our privacy policy when submitting the {{entity}} form on {{date}}": "En soumettant le formulaire de \"{{entity}}\" le {{date}}, vous avez accepté notre politique de confidentialité.",
  "You accepted our marketing policy when submitting the {{entity}} form on {{date}}": "En soumettant le formulaire de \"{{entity}}\" le {{date}}, vous avez accepté notre politique de commercialisation.",
  "You accepted our privacy policy when registering for the {{entity}} event on {{date}}": "Vous avez accepté notre politique de confidentialité lors de votre inscription à l'événement \"{{entity}}\" le {{date}}",
  "You accepted our marketing policy when registering for the {{entity}} event on {{date}}": "Vous avez accepté notre politique de commercialisation lors de votre inscription à l'événement \"{{entity}}\" le {{date}}",
  "Unsubscribe": "Se désabonner",
  "Yes, unsubscribe": "Oui, désinscrire",
  "No, stay subscribed": "Non, restez abonné",
  "Manage marketing preferences": "Gérer les préférences marketing",
  "Manage preferences for <0>{{policyName}}</0>": "Gérer les préférences pour <0>{{policyName}}</0>",
  "Unsubscribe successful": "Désabonnement réussi",
  "You are still subscribed": "Vous êtes toujours abonné",
  "Back to homepage": "retour à la page d'accueil",
  "Are you sure you want to unsubscribe from the <0>{{topicName}}</0> topic?": "Voulez-vous vraiment vous désabonner du sujet <0>{{topicName}}</0> ?",
  "You have been unsubscribed from the <0>{{topicName}}</0> topic.": "Vous avez été désabonné du sujet <0>{{topicName}}</0>.",
  "You have not been unsubscribed from the <0>{{topicName}}</0> topic.": "Vous n'avez pas été désabonné du sujet <0>{{topicName}}</0>."
}

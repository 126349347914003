import React from 'react'
import { Trans } from 'react-i18next'

import PageContainer from 'apps/Imports/components/page-container'
import ConsentStep from 'apps/Imports/components/profile-import/consent-step'
import { useCurrentStep } from 'apps/Imports/contexts/steps'
import { PROFILE_IMPORT_STEPS } from 'apps/Imports/utils/profile-import/constants'

const ConsentPage = () => {
  useCurrentStep(PROFILE_IMPORT_STEPS.POLICY_CONSENT)

  return (
    <PageContainer
      title={<Trans>Consent</Trans>}
      sidebarTitle={<Trans>Import Profiles</Trans>}
      subtitle={
        <Trans>
          Please ensure that the persons contained in your file have granted you
          with valid consent to process their data.
        </Trans>
      }
    >
      <ConsentStep />
    </PageContainer>
  )
}

export default ConsentPage

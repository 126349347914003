import React from 'react'
import { Trans } from 'react-i18next'

import { isEmptyOrZero } from 'apps/StudyPlans/utils/isEmptyOrZero'
import { isEmptyString } from 'apps/StudyPlans/utils/isEmptyString'

export function isValidCategory({ name, credits }) {
  return name && credits >= 0 && Number.isInteger(Number(credits))
}

export function rollbackInvalidUpdates(updatedCategories, oldCategoriesById) {
  return updatedCategories.reduce(
    (validCategories, current) => [
      ...validCategories,
      isValidCategory(current) ? current : oldCategoriesById[current.id]
    ],
    []
  )
}

export function getCategoryPlaceholder() {
  const generatedId = `new${Date.now().valueOf()}`
  return { [generatedId]: { id: generatedId } }
}

export function categoryHasErrors(category) {
  return (
    category.errors &&
    Object.values(category.errors).some((errorMessage) => !!errorMessage)
  )
}

export function getErrorsInCategory(category) {
  const validators = {
    name: getErrorInName,
    credits: getErrorInCredits
  }

  return Object.keys(validators).reduce((errorsByField, fieldName) => {
    const valueInField = category[fieldName]
    const errorInField = validators[fieldName](valueInField)

    return { ...errorsByField, [fieldName]: errorInField }
  }, {})
}

function getErrorInName(name) {
  return isEmptyString(name) ? <Trans>Can't be blank</Trans> : null
}

function getErrorInCredits(credits) {
  return isEmptyOrZero(credits) ? (
    <Trans>Can't be blank</Trans>
  ) : !Number.isInteger(Number(credits)) ? (
    <Trans>Must be a number</Trans>
  ) : null
}

import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, TextInput } from '@fullfabric/alma-mater'

import { useEmailTemplateUpdate } from 'apps/EmailTemplates/hooks/useEmailTemplateUpdate'

import styles from './styles.module.scss'

export default function EditContainer({
  id,
  value,
  editField,
  onCancel,
  onSave
}) {
  const [t] = useTranslation()
  const [editValue, setEditValue] = useState(value)
  const { mutate, isLoading } = useEmailTemplateUpdate(id)

  const onSaveInternal = () => {
    mutate(
      {
        [editField]: editValue
      },
      {
        onSuccess: () => {
          onSave()
          onCancel()
        }
      }
    )
  }

  return (
    <div className={styles.editContainer}>
      <TextInput
        className={styles.input}
        small
        value={editValue}
        onChange={(ev) => setEditValue(ev.target.value)}
      />
      <Button size='small' primary onClick={onSaveInternal} loading={isLoading}>
        {t('Save')}
      </Button>
      <Button size='small' onClick={onCancel}>
        {t('Cancel')}
      </Button>
    </div>
  )
}

import React, { useReducer } from 'react'

import { useCurrentUser, useSettings } from '@fullfabric/authorization-officer'

export const ModalsStateContext = React.createContext()
export const ModalsDispatchContext = React.createContext()
export const ChoicesDataContext = React.createContext()
export const StartApplicationParamsContext = React.createContext()

const isInstitutionWideTemplate = (template) =>
  template.context_type === 'Institutions::Institution'

const isProgrammeClassFieldRequired = (template) =>
  !!template.require_programme_class_field

const isThirdPartyContact = (user) =>
  user && user.roles && user.roles.some((role) => role.state === 'contact')

const choicesDataReducer = (state, action) => {
  switch (action.type) {
    case 'GET_CHOICES_DONE':
      return { data: action.data }
    default:
      return state
  }
}

const paramsReducer = (state, action) => {
  switch (action.type) {
    case 'SAVE':
      if (!action.data) {
        return state
      }

      return { ...state, ...action.data }
    case 'SUBMIT':
      return {} // reset state
    default:
      return state
  }
}

const programmeAndClassModalReducer = (state, action) => {
  switch (action.type) {
    case 'OPEN':
    case 'ADVANCE':
      return {
        ...state,
        visible: action.target === 'PROGRAMME_AND_CLASS_MODAL'
      }
    case 'SUBMIT':
    case 'CLOSE':
      return {
        ...state,
        visible: false
      }
    default:
      return state
  }
}

const thirdPartyApplicationModalReducer = (state, action) => {
  switch (action.type) {
    case 'OPEN':
    case 'ADVANCE':
      return {
        ...state,
        visible: action.target === 'THIRD_PARTY_APPLICATION_MODAL'
      }
    case 'SUBMIT':
    case 'CLOSE':
      return {
        ...state,
        visible: false
      }
    default:
      return state
  }
}

const modalsReducer = (state = {}, action) => ({
  programmeAndClass: programmeAndClassModalReducer(
    state.programmeAndClass,
    action
  ),
  thirdPartyApplication: thirdPartyApplicationModalReducer(
    state.thirdPartyApplication,
    action
  )
})

const combinedReducer = (state = {}, action) => ({
  choices: choicesDataReducer(state.choices, action),
  params: paramsReducer(state.params, action),
  modals: modalsReducer(state.modals, action)
})

export const StartApplicationStateProvider = ({ templateData, children }) => {
  const loggedInUser = useCurrentUser()
  const settings = useSettings()

  const areProxyRegistrationsEnabled =
    settings?.hash?.modules?.applics?.features?.organisation_contact?.enable

  const [{ params, choices, modals }, dispatch] = useReducer(combinedReducer, {
    params: {},
    choices: { isLoading: false },
    modals: {
      programmeAndClass: {
        required:
          isInstitutionWideTemplate(templateData) &&
          isProgrammeClassFieldRequired(templateData),
        visible: false
      },
      thirdPartyApplication: {
        required:
          isThirdPartyContact(loggedInUser) && !areProxyRegistrationsEnabled,
        visible: false
      }
    }
  })

  return (
    <ModalsStateContext.Provider value={modals}>
      <ModalsDispatchContext.Provider value={dispatch}>
        <ChoicesDataContext.Provider value={choices}>
          <StartApplicationParamsContext.Provider value={params}>
            {children}
          </StartApplicationParamsContext.Provider>
        </ChoicesDataContext.Provider>
      </ModalsDispatchContext.Provider>
    </ModalsStateContext.Provider>
  )
}

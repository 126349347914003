import { IMPORT_TYPES } from './constants'

const getRequiredImportFields = (importDocument, context, data) => {
  if (importDocument._ruby_type === IMPORT_TYPES.PROGRAMME_AND_CLASS) {
    return {
      requiredFieldsToImport: ['programme.name', 'ends_on'], // fields required for the profile to be created or updated
      requiredFieldsToDisplay: ['programme.name', 'ends_on'] // fields displayed in the main section of the mapping step (not all of them will be required)
    }
  } else if (importDocument._ruby_type === IMPORT_TYPES.SUBJECT_AND_COURSE) {
    return {
      requiredFieldsToImport: ['subject.name'], // fields required for the profile to be created or updated
      requiredFieldsToDisplay: ['subject.name'] // fields displayed in the main section of the mapping step (not all of them will be required)
    }
  } else {
    return getProfileImportRequiredFields(context, data)
  }
}

const getProfileImportRequiredFields = (context, data) => {
  const isEventImport = context._backbone_type === 'Events.Models.Event'

  const requiredFieldsToImport = data.updateExistingProfiles
    ? ['email']
    : ['first_name', 'last_name', 'email']

  const requiredFieldsToDisplay = ['first_name', 'last_name', 'email']

  if (isEventImport) {
    if (data.addToEventData.responseType === 'defined_in_the_import_file') {
      requiredFieldsToImport.push('response')
      requiredFieldsToDisplay.push('response')
    }

    if (data.addToEventData.attendance === 'defined_in_the_import_file') {
      requiredFieldsToImport.push('attendance')
      requiredFieldsToDisplay.push('attendance')
    }
  }

  return {
    requiredFieldsToImport, // fields required for the profile to be created or updated
    requiredFieldsToDisplay // fields displayed in the main section of the mapping step (not all of them will be required)
  }
}

export default getRequiredImportFields

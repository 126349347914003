export const getBaseUrl = (context, importSufix = '') => {
  switch (context._backbone_type) {
    case 'Institutions.Models.Institution':
      return `/profiles${importSufix}`

    case 'Events.Models.Event':
      return `/events/${context.id}${importSufix}`

    case 'Institutions.Models.Course':
      return `/courses/${context.id}${importSufix}`

    case 'Institutions.Models.ClassOf':
    default:
      return `/classes/${context.id}${importSufix}`
  }
}

import React from 'react'
import { useTranslation } from 'react-i18next'

import moment from 'moment'

import {
  DatesXAxisTick,
  YearWeeksXAxisTick
} from 'apps/Reports/components/CustomChartElements'
import parseMonthFromYearWeek from 'apps/Reports/utils/parseMonthFromYearWeek'
import { getPeriodOptions } from '../../constants'
import { useFilter } from '../../FilterContext'

const DAILY_PERIODS = ['this_week', 'last_week', 'this_month', 'last_month']
const getDataKey = (period) => (DAILY_PERIODS.includes(period) ? 'day' : 'week')

const getTickComponent = (period) => {
  if (period?.includes('week'))
    return (props) => <DatesXAxisTick dateFormat='dddd' {...props} />
  if (period?.includes('month'))
    return (props) => <DatesXAxisTick dateFormat='D' {...props} />
  return YearWeeksXAxisTick
}

export const getLabel = (period, t) => {
  const targetPeriod = period || 'this_year'
  const periodOption = getPeriodOptions(t).find(
    (option) => option.value === targetPeriod
  )

  return periodOption?.label
}

export const getTooltipLabelFormatter = (period, locale) => (value) => {
  if (period?.includes('week') || period?.includes('month'))
    return moment(value).locale(locale).format('ddd, D MMM')

  return parseMonthFromYearWeek(value).locale(locale).format('MMM YYYY')
}

const usePeriodConfig = () => {
  const { t, i18n } = useTranslation()
  const filter = useFilter()

  return {
    dataKey: getDataKey(filter.period),
    Tick: getTickComponent(filter.period),
    labelText: getLabel(filter.period, t),
    tooltipLabelFormatter: getTooltipLabelFormatter(
      filter.period,
      i18n.language
    )
  }
}

export default usePeriodConfig

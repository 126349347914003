import React from 'react'
import { useTranslation } from 'react-i18next'

import { PageHead } from '@fullfabric/alma-mater'

import TablePageLayout from 'shared/components/TablePageLayout'
import { TeamsAndStaffProvider } from 'shared/contexts/TeamsAndStaffContext'

import CreateSegmentButton from 'apps/Segments/components/CreateSegmentButton'
import SegmentsTable from 'apps/Segments/components/SegmentsTable'

export default function SegmentsTablePage() {
  const { t } = useTranslation()

  return (
    <TeamsAndStaffProvider>
      <TablePageLayout>
        <PageHead
          noBorder
          title={t('Segments')}
          action={<CreateSegmentButton />}
        />

        <TablePageLayout.TableSection>
          <SegmentsTable />
        </TablePageLayout.TableSection>
      </TablePageLayout>
    </TeamsAndStaffProvider>
  )
}

const {
  FILL_MAP,
  MAIN_INTAKE_FILL
} = require('apps/Reports/components/CustomChartElements')

export function getBarFill(intakeIndex, mainIntakeIndex) {
  return getChartColor({
    intakeIndex,
    mainIntakeIndex,
    mainIntakeColor: MAIN_INTAKE_FILL,
    otherColors: FILL_MAP
  })
}

function getChartColor({
  intakeIndex,
  mainIntakeIndex,
  mainIntakeColor,
  otherColors
}) {
  const isAfterMainIntake = intakeIndex > mainIntakeIndex

  if (intakeIndex === mainIntakeIndex) {
    return mainIntakeColor
  }

  if (isAfterMainIntake) {
    return otherColors[Math.min(intakeIndex - 1, otherColors.length - 1)]
  }

  return otherColors[Math.min(intakeIndex, otherColors.length - 1)]
}

export default getChartColor

import React, { createContext, useContext } from 'react'
import { useQuery } from 'react-query'

import getApplic from 'apps/Evaluations/api/getApplic'
import getApplicationTemplate from 'apps/Evaluations/api/getApplicationTemplate'

const ApplicationContext = createContext(null)
const ApplicationTemplateContext = createContext(null)

const useApplication = () => {
  return useContext(ApplicationContext)
}

const useApplicationTemplate = () => {
  return useContext(ApplicationTemplateContext)
}

const ApplicationDataProvider = ({ applicationId, children }) => {
  const {
    data: applicData,
    isLoading: isLoadingApplication,
    error: applicError
  } = useQuery(['getApplic', applicationId], () => getApplic(applicationId), {
    suspense: true
  })

  const {
    data: applicTemplateData,
    isLoading: isLoadingApplicTemplate,
    error: applicTemplateError
  } = useQuery(
    ['getApplicationTemplate', applicData?.template_id],
    () => getApplicationTemplate(applicData.template_id),
    {
      enabled: !!applicData?.template_id,
      suspense: true
    }
  )

  return (
    <ApplicationContext.Provider
      value={{
        data: applicData,
        isLoading: isLoadingApplication,
        error: applicError
      }}
    >
      <ApplicationTemplateContext.Provider
        value={{
          data: applicTemplateData,
          isLoading: isLoadingApplicTemplate,
          error: applicTemplateError
        }}
      >
        {children}
      </ApplicationTemplateContext.Provider>
    </ApplicationContext.Provider>
  )
}

export default ApplicationContext
export { useApplication, useApplicationTemplate, ApplicationDataProvider }

import React from 'react'
import { Trans } from 'react-i18next'
import { useMutation } from 'react-query'

import { reject } from 'lodash'

import {
  ArrowLeftIcon,
  DeleteIcon,
  Dropdown,
  IconButton,
  MoreIcon,
  PlayIcon,
  Table
} from '@fullfabric/alma-mater'

import { usePagedQueriesUpdater } from 'shared/hooks/usePagedQueriesUpdater'

import {
  deleteDelayedJob,
  resetDelayedJob,
  runDelayedJob
} from 'apps/Admin/api/delayed-jobs'

import styles from './Cell.module.scss'

export default function ActionsCell({ data, row, value: jobId }) {
  const removeJob = useCachedJobRemover(jobId)
  const replaceJob = useCachedJobReplacer()

  const { mutate: deleteMutation, isLoading: isDeleting } = useMutation(
    deleteDelayedJob,
    { onSuccess: removeJob }
  )
  const { mutate: resetMutation, isLoading: isResetting } = useMutation(
    resetDelayedJob,
    { onSuccess: replaceJob }
  )
  const { mutate: runMutation, isLoading: isRunning } = useMutation(
    runDelayedJob,
    { onSuccess: removeJob }
  )

  return (
    <Table.Data tag='div' title='Actions' className={styles.customCell}>
      <Dropdown>
        <Dropdown.Toggle>
          <IconButton small disabled={isDeleting || isResetting || isRunning}>
            <MoreIcon />
          </IconButton>
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Menu.Option
            className={styles.actionOption}
            size='small'
            title='Force execute this job'
            onClick={() => runMutation(jobId)}
          >
            <PlayIcon />
            <Trans>Run</Trans>
          </Dropdown.Menu.Option>

          <Dropdown.Menu.Option
            className={styles.actionOption}
            size='small'
            title='Reset this job to its initial state'
            onClick={() => resetMutation(jobId)}
          >
            <ArrowLeftIcon />
            <Trans>Reset</Trans>
          </Dropdown.Menu.Option>

          <Dropdown.Menu.Option
            negative
            className={styles.actionOption}
            size='small'
            title='Delete this job'
            onClick={() => deleteMutation(jobId)}
          >
            <DeleteIcon color='text-alert' />
            <Trans>Delete</Trans>
          </Dropdown.Menu.Option>
        </Dropdown.Menu>
      </Dropdown>
    </Table.Data>
  )
}

function useCachedJobRemover(jobId) {
  const updatePages = usePagedQueriesUpdater([
    'tableDataFetch',
    '/admin/api/jobs'
  ])

  return () =>
    updatePages(
      (page) => (page.data = reject(page.data, (job) => job.id === jobId))
    )
}

function useCachedJobReplacer() {
  const updatePages = usePagedQueriesUpdater([
    'tableDataFetch',
    '/admin/api/jobs'
  ])

  return (newJob) =>
    updatePages((page) => {
      page.data = page.data.map((job) => (job.id === newJob.id ? newJob : job))
    })
}

import React from 'react'

const AlertIcon = () => (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M15.5953 7.02361L8.97619 0.404346C8.43691 -0.134782 7.56274 -0.134782 7.02346 0.404346L0.404338 7.0243C-0.134779 7.56359 -0.134779 8.43779 0.404338 8.97708L7.02346 15.5957C7.56274 16.1348 8.43691 16.1348 8.97619 15.5957L15.5953 8.97708C15.8544 8.71807 16 8.36671 16 8.00035C16 7.63398 15.8544 7.28262 15.5953 7.02361ZM7 5C7 4.44772 7.44772 4 8 4C8.55229 4 9 4.44772 9 5V8C9 8.55229 8.55229 9 8 9C7.44772 9 7 8.55229 7 8V5ZM8.01533 12H8.034C8.29813 11.9953 8.54928 11.8846 8.73087 11.6927C8.91246 11.5009 9.0092 11.244 8.99931 10.98C8.97892 10.434 8.53108 10.0014 7.98467 10H7.966C7.70187 10.0047 7.45072 10.1154 7.26913 10.3073C7.08754 10.4991 6.9908 10.756 7.00069 11.02C7.02176 11.5657 7.4692 11.9979 8.01533 12Z'
      fill='#D22A0C'
    />
  </svg>
)

export default AlertIcon

.react-app ._container_1rc0j_1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: var(--space-small) var(--space-default);
  padding-left: var(--space-more);
}
.react-app ._container_1rc0j_1._tabletNav_1rc0j_9 {
  padding-left: var(--space-big);
  padding-right: var(--space-more);
}
.react-app ._container_1rc0j_1._mobileNav_1rc0j_13 {
  padding-top: var(--space-less);
  padding-bottom: var(--space-less);
}

.react-app ._label_1rc0j_18 {
  text-align: left;
}

.react-app ._iconWrapper_1rc0j_22 {
  margin-left: var(--space-small);
}
.react-app ._iconWrapper_1rc0j_22 * {
  fill: var(--ff-color-text-base-darker) !important;
}
.react-app ._iconWrapper_1rc0j_22._mobileNav_1rc0j_13 {
  margin-left: var(--space-less);
}
.react-app ._iconWrapper_1rc0j_22._mobileNav_1rc0j_13 * {
  fill: var(--ff-color-text-base-darkest) !important;
}
.react-app ._teamsTable_xsjjj_1 {
  margin-top: var(--space-default);
}

.react-app ._actionCell_xsjjj_5 {
  text-align: right;
}

.react-app ._editButton_xsjjj_9 {
  float: right;
}
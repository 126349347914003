import fieldInfoStrategyFactory from './fieldInfoStrategyFactory'
import getFieldLabel from './getFieldLabel'
import getSortedSchemaFields from './getSortedSchemaFields'

const schemableColumns = (schemas = [], language = 'en') => {
  return schemas
    .map((columnSchema) => {
      const fields = getSortedSchemaFields(columnSchema.schema)

      return fields.map((field) => {
        const fieldInfoStrategy = fieldInfoStrategyFactory(field)

        return {
          accessor:
            columnSchema.accessor && typeof columnSchema.accessor === 'function'
              ? columnSchema.accessor.bind(null, field)
              : [
                  columnSchema.accessorPrefix,
                  columnSchema.accessorPrefix ? '.' : null,
                  field.name
                ]
                  .filter((x) => x)
                  .join(''),
          Header: getFieldLabel(field, language),
          section: columnSchema.name,
          getTitle: (cell) => {
            return columnSchema.customCellTitle
              ? columnSchema.customCellTitle(cell, field)
              : fieldInfoStrategy.extractTitle(cell, field, columnSchema)
          },
          Cell: (props) => {
            if (columnSchema.customCellRenderer) {
              return columnSchema.customCellRenderer(props, field)
            } else {
              return (
                fieldInfoStrategy.extractValue(props, field, columnSchema) ||
                null
              )
            }
          }
        }
      })
    })
    .reduce((acc, curr) => acc.concat(curr), [])
}

export default schemableColumns

import React from 'react'
import { Trans } from 'react-i18next'

import { Field } from '@fullfabric/alma-mater'

const CampusesField = ({ values, campusesList, onChange }) => {
  return (
    <Field
      id='campus_ids'
      type='checkbox'
      label={<Trans>Campuses</Trans>}
      onChange={onChange}
      value={values}
      inputOptions={(campusesList || []).map((campus) => ({
        value: campus.value,
        label: campus.label
      }))}
    />
  )
}

export default CampusesField

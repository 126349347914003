import React from 'react'

import { useScreenClass } from '@fullfabric/alma-mater'

import ProductCard from '../product-card'
import LoadingCard from './loading-card'

import cx from 'classnames'
import styles from './styles.module.scss'

const ProductsGrid = ({ products, isLoading }) => {
  const screenClass = useScreenClass()
  let gridItems = [...Array(3)].map((_, index) => <LoadingCard key={index} />)

  if (!isLoading) {
    gridItems = products.map((product) => {
      return <ProductCard key={product.context_id} product={product} />
    })
  }

  return (
    <div className={cx(styles.productsGrid, styles[screenClass])}>
      {gridItems}
    </div>
  )
}

export default ProductsGrid

import React from 'react'
import { useTranslation } from 'react-i18next'

import { Text } from '@fullfabric/alma-mater'

import { useMergeData } from 'apps/MergeProfiles/contexts/MergeData'
import { useProfileSchema } from 'apps/MergeProfiles/contexts/ProfileSchema'
import getFieldValue from 'apps/MergeProfiles/utils/getFieldValue'
import getLocaledLabel from 'apps/MergeProfiles/utils/getLocaledLabel'

const getEmailFieldOptionsFn =
  ({ smartEmailFields, smartEmailInfoFrom, currentField, i18n, t }) =>
  ({ profile, type }) => {
    return smartEmailFields
      .map((field) => {
        const fieldName = getLocaledLabel(field.locals, i18n.language)
        const profileFieldValue = getFieldValue(field, profile)

        if (!profileFieldValue) return null

        const valueDescription =
          type === 'main'
            ? t(' - {{fieldName}} from Main profile', { fieldName })
            : t(' - {{fieldName}} from Duplicate profile', { fieldName })

        const optValue = `${type}:${field.name}`
        const valueAvailableForSelection = Object.keys(smartEmailInfoFrom)
          .filter((fieldName) => fieldName !== currentField.name)
          .every((fieldName) => smartEmailInfoFrom[fieldName] !== optValue)

        return {
          value: optValue,
          label: (
            <>
              {profileFieldValue}
              <Text tag='span'>{valueDescription}</Text>
            </>
          ),
          disabled: !valueAvailableForSelection
        }
      })
      .filter((x) => x)
  }

const useSmartEmailOptions = (currentField) => {
  const { smartEmailFields } = useProfileSchema()
  const { i18n, t } = useTranslation()
  const {
    updateMergeData,
    mergeData: { sourceProfile, targetProfile, smartEmailInfoFrom }
  } = useMergeData()

  const smartEmailDropdownOptions = React.useMemo(() => {
    const getEmailFieldOptionsForProfile = getEmailFieldOptionsFn({
      smartEmailFields,
      smartEmailInfoFrom,
      currentField,
      i18n,
      t
    })

    const mainProfileEmailOptions = getEmailFieldOptionsForProfile({
      profile: targetProfile,
      type: 'main'
    })

    const duplicateProfileEmailOptions = getEmailFieldOptionsForProfile({
      profile: sourceProfile,
      type: 'duplicate'
    })

    return [...mainProfileEmailOptions, ...duplicateProfileEmailOptions].concat(
      currentField.name !== 'email'
        ? [{ value: 'empty', label: t('No value') }]
        : []
    )
  }, [
    currentField,
    i18n,
    smartEmailFields,
    t,
    sourceProfile,
    targetProfile,
    smartEmailInfoFrom
  ])

  React.useEffect(() => {
    if (
      smartEmailDropdownOptions.some(
        ({ value }) => value === smartEmailInfoFrom[currentField.name]
      )
    ) {
      return
    }

    updateMergeData({
      smartEmailInfoFrom: {
        ...smartEmailInfoFrom,
        [currentField.name]: 'empty'
      }
    })
  }, [
    updateMergeData,
    smartEmailDropdownOptions,
    currentField.name,
    smartEmailInfoFrom
  ])

  return smartEmailDropdownOptions
}

export default useSmartEmailOptions

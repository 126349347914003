import React from 'react'

export const DeleteCardContext = React.createContext()

export const DeleteCardProvider = ({ children }) => {
  const [cardToDelete, setCardToDelete] = React.useState(null)

  return (
    <DeleteCardContext.Provider
      value={{
        cardToDelete,
        setCardToDelete
      }}
    >
      {children}
    </DeleteCardContext.Provider>
  )
}

export const useCardToDelete = () =>
  React.useContext(DeleteCardContext)?.cardToDelete

export const useSetCardToDelete = () =>
  React.useContext(DeleteCardContext)?.setCardToDelete

import React from 'react'
import { Trans } from 'react-i18next'

import { Col, Field, Row } from '@fullfabric/alma-mater'
import { IfHasSetting } from '@fullfabric/authorization-officer'

import CampusesField from 'apps/Courses/components/Fields/Campuses'
import OwnerField from 'apps/Courses/components/Fields/Owner'
import SubjectField from 'apps/Courses/containers/Fields/Subject'

const ERROR_MESSAGES = {
  not_a_number: <Trans>Must be a number</Trans>,
  required_for_price: <Trans>Currency is required for price</Trans>
}

const CourseCreateFormBasic = ({
  data,
  currencyOptions,
  campusesOptions,
  errors,
  onChange
}) => (
  <div role='form' aria-label='course basics creation form'>
    <SubjectField onChange={onChange} data={data} errors={errors} />

    <Field
      id='name'
      type='text'
      label={<Trans>Name</Trans>}
      help={
        <Trans>
          The course name is automatically populated with the class starts on
          date if the course name is empty.
        </Trans>
      }
      value={data.name}
      onChange={onChange}
    />

    {campusesOptions && campusesOptions.length > 0 && (
      <CampusesField
        onChange={onChange}
        campusesList={campusesOptions}
        values={data.campus_ids}
      />
    )}

    <IfHasSetting settingName='modules.core.submodules.institutions.features.external_id.enable'>
      <Field
        id='external_id'
        type='text'
        label={<Trans>External ID</Trans>}
        value={data.external_id}
        onChange={onChange}
      />
    </IfHasSetting>

    <OwnerField onChange={onChange} value={data.owner_id} />

    <Row>
      <Col xs={6}>
        <Field
          id='price_amount'
          type='text'
          label={<Trans>Price amount</Trans>}
          error={ERROR_MESSAGES[errors.price_amount]}
          value={data.price_amount}
          onChange={onChange}
        />
      </Col>

      <Col xs={6}>
        <Field
          id='price_currency'
          inputOptions={{
            placeholder: <Trans>- Select -</Trans>,
            options: currencyOptions
          }}
          type='dropdown'
          label={<Trans>Price currency</Trans>}
          error={ERROR_MESSAGES[errors.price_currency]}
          value={data.price_currency}
          onChange={onChange}
        />
      </Col>
    </Row>
  </div>
)

export default CourseCreateFormBasic

import React from 'react'
import { useTranslation } from 'react-i18next'

import moment from 'moment'

import { Text } from '@fullfabric/alma-mater'

const AssigneeCell = ({ value: assigneeName, data, row }) => {
  const { i18n } = useTranslation()
  const rowData = data[row.index]

  if (!assigneeName) {
    return null
  }

  const evaluationAssigmentDate = rowData.evaluation.assigned_at
  const assignedAtMoment = moment(evaluationAssigmentDate).locale(i18n.language) // assumes that, if there's an assigned_to_name, there's an assigned_at

  return (
    <>
      {assigneeName}
      <Text tag='div' marginTop='small' type='f6' fontColor='text-base-darker'>
        {assignedAtMoment?.format('ddd DD MMM, YYYY hh:mm a')}
      </Text>
    </>
  )
}

export default AssigneeCell

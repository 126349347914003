export const getDataWithUpdatedEntry = ({ oldQueryData, updatedEntry }) =>
  oldQueryData?.pages?.map((page) => {
    const targetApplicIndex = page.data.findIndex(
      (applic) => applic.id === updatedEntry.id
    )
    const isInThisPage = targetApplicIndex > -1

    if (!isInThisPage) {
      return page
    }

    return {
      ...page,
      data: [
        ...page.data.slice(0, targetApplicIndex),
        updatedEntry,
        ...page.data.slice(targetApplicIndex + 1)
      ]
    }
  }) || []

const updateApplicInQuery = ({
  queryFilter,
  cachedQuery,
  queryClient,
  updatedEntry
}) => {
  if (!cachedQuery) {
    return
  }

  const queryKey = cachedQuery[0]
  const oldQueryData = cachedQuery[1]

  const updatedQueryData = getDataWithUpdatedEntry({
    oldQueryData,
    updatedEntry
  })

  queryClient.setQueryData(queryKey, (data) => {
    return {
      pages: updatedQueryData,
      pageParams: data.pageParams
    }
  })

  queryClient.invalidateQueries(queryFilter)
}

export default updateApplicInQuery

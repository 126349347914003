import { utils } from '@fullfabric/public-api'

import getMergeParamsFromData from 'apps/MergeProfiles/utils/getMergeParamsFromData'

const mergeProfiles = async (mergeData) => {
  const response = await fetch(
    `/api/profiles/${mergeData.targetProfileId}/merge`,
    {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(getMergeParamsFromData(mergeData))
    }
  )

  return await utils.checkResponse(response)
}

export default mergeProfiles

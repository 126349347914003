/* eslint-disable camelcase */

import { connect } from 'react-redux'

import moment from 'moment'

import actionTypes from 'apps/Courses/actions/types'
import updateCourse from 'apps/Courses/actions/updateCourse'
import CourseEditFormSchema from 'apps/Courses/components/CourseEditFormSchema'

const mapStateToProps = ({
  editCourse: {
    schemaSection: { data, schema, inProgress, errors }
  },
  appData: { h: { countries_as_options = [] } = {} } = {}
}) => ({
  data,
  schema,
  countriesAsOptions: countries_as_options,
  inProgress,
  errors
})

const mapDispatchToProps = (dispatch) => ({
  onCancelClick: () =>
    dispatch({ type: actionTypes.cancelEditSchemaSectionData }),
  onChange: (field, value) => {
    const fieldValue =
      value && moment.isMoment(value) && value.isValid()
        ? value.tz(moment.tz.guess()).format()
        : value

    dispatch({
      type: actionTypes.editCourseSchemaSectionDataChanged,
      payload: { [field]: fieldValue }
    })
  },
  onSaveClick: () =>
    dispatch(updateCourse(actionTypes.updateCourseSchemaSection))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CourseEditFormSchema)

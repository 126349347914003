import React from 'react'
import { useHistory } from 'react-router'

import {
  useImportType,
  useStepsCompleted,
  useStepState
} from 'apps/Imports/contexts/steps'
import getUrlFromStepId from 'apps/Imports/utils/product-import/get-url-from-step-id'

const useGoToStep = () => {
  const history = useHistory()
  const { currentStep, nextStep } = useStepState()
  const { addCompletedStep } = useStepsCompleted()
  const importType = useImportType()

  const goToStepPage = React.useCallback(
    (stepId) => {
      const pageUrl = getUrlFromStepId(importType, stepId)
      history.push(pageUrl)
    },
    [history, importType]
  )

  const goToNextStepPage = React.useCallback(() => {
    addCompletedStep(currentStep)
    goToStepPage(nextStep)
  }, [addCompletedStep, currentStep, goToStepPage, nextStep])

  return { goToNextStepPage, goToStepPage }
}

export default useGoToStep

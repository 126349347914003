import React from 'react'
import { Trans } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { Button } from '@fullfabric/alma-mater'

import FeedbackMessageProvider from 'apps/Events/components/FeedbackMessageProvider'
import QRCodeScanner from 'apps/Events/components/QRCodeScanner'
import { QRCodeScanResultProvider } from 'apps/Events/contexts/QRCodeScanResultContext'

import styles from './styles.module.scss'

const ScanEventQRCodePage = () => {
  const history = useHistory()
  const onBackClicked = React.useCallback(() => {
    history.goBack()
  }, [history])

  return (
    <FeedbackMessageProvider>
      <QRCodeScanResultProvider>
        <div>
          <Button className={styles.backButton} onClick={onBackClicked}>
            <Trans>Back to list</Trans>
          </Button>

          <QRCodeScanner />
        </div>
      </QRCodeScanResultProvider>
    </FeedbackMessageProvider>
  )
}

export default ScanEventQRCodePage

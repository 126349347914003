import React from 'react'
import { Trans } from 'react-i18next'

import PropTypes from 'prop-types'

import AuthorizationWrapper from './authorization-wrapper'
import BaseApplicationsWidget from './base'

export default function ApplicationTemplatesWidget({ widget }) {
  return (
    <AuthorizationWrapper id={widget.id} title={<Trans>Applications</Trans>}>
      <BaseApplicationsWidget
        widgetId={widget.id}
        templateIds={widget.template_ids}
        emptyMessage={<Trans>No applications available</Trans>}
      />
    </AuthorizationWrapper>
  )
}

ApplicationTemplatesWidget.propTypes = {
  widget: PropTypes.shape({
    id: PropTypes.string.isRequired,
    template_ids: PropTypes.arrayOf(PropTypes.string).isRequired
  }).isRequired
}

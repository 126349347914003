import 'css-chunk:src/apps/Applics/components/TemplateTabsWizardPage/ToggleableTab/Toggle/styles.module.scss';export default {
  "react-app": "react-app",
  "container": "_container_vgh76_1",
  "disabled": "_disabled_vgh76_6",
  "background": "_background_vgh76_9",
  "active": "_active_vgh76_9",
  "inactive": "_inactive_vgh76_12",
  "alwaysActive": "_alwaysActive_vgh76_32",
  "toggleCirclePositioner": "_toggleCirclePositioner_vgh76_37",
  "toggleCircle": "_toggleCircle_vgh76_37",
  "checkedToggleCircle": "_checkedToggleCircle_vgh76_48"
};
import React from 'react'

import CancelButton from './CancelButton'
import SaveChangesButton from './SaveChangesButton'

const SubjectUpdateActions = ({ fieldsToUpdate, sectionName = 'basic' }) => {
  return (
    <div className='marginTop-default'>
      <SaveChangesButton
        fieldsToUpdate={fieldsToUpdate}
        sectionName={sectionName}
      />
      <CancelButton fieldsToReset={fieldsToUpdate} sectionName={sectionName} />
    </div>
  )
}

export default SubjectUpdateActions

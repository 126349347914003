import 'css-chunk:src/apps/Imports/components/import-report-step/components/report-chart-section/styles.module.scss';export default {
  "react-app": "react-app",
  "container": "_container_1dl36_1",
  "chartContainer": "_chartContainer_1dl36_5",
  "chartInnerLegend": "_chartInnerLegend_1dl36_23",
  "chartInnerText": "_chartInnerText_1dl36_31",
  "legendContainer": "_legendContainer_1dl36_35",
  "warningText": "_warningText_1dl36_48",
  "rectangle": "_rectangle_1dl36_53",
  "blueBackground": "_blueBackground_1dl36_62",
  "darkBlueBackground": "_darkBlueBackground_1dl36_66",
  "redBackground": "_redBackground_1dl36_70",
  "legendRow": "_legendRow_1dl36_74"
};
import { kebabCase } from 'lodash'

export function buildChannelName({ userId, uploadScopeId, uploadId }) {
  const formattedScopeId = kebabCase(uploadScopeId)
  const formattedUploadId = kebabCase(uploadId)

  const suffix = [formattedScopeId, formattedUploadId]
    .filter((str) => !!str)
    .join('-')

  return `${userId}-tus-uploads-${suffix}`
}

.react-app ._positioner_1jjec_1 {
  position: relative;
}

.react-app ._dataCell_1jjec_5 {
  height: 56px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.react-app ._dataCell_1jjec_5 p {
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.react-app ._expandCourseButtonActive_1jjec_17 {
  background: var(--ff-bg-base-darker) !important;
  color: var(--ff-color-text-base-darker) !important;
  border-radius: 4px !important;
}
.react-app ._expandCourseButtonActive_1jjec_17 svg path {
  fill: var(--ff-color-text-base-darker) !important;
}
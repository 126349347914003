import React, { useCallback } from 'react'
import { useQueryClient } from 'react-query'

import { Container, Row } from '@fullfabric/alma-mater'

import { useOpenPaymentsDrawer } from '../contexts/payments-drawer'
import useSettingByName from '../hooks/use-setting-by-name'
import ExpiredCard from './external-payment-area/expired-card'
import InvalidCard from './external-payment-area/invalid-card'
import PaidCard from './external-payment-area/paid-card'
import LandingPageLogo from './landing-page-logo'
import LocaleSwitcherDropdown from './locale-switcher-dropdown'
import PayableDiscounts from './payable-discounts'
import PaymentPlanCard from './payments-page/payment-plan-list-area/payment-plan-card'

import styles from './external-payment-area/styles.module.scss'

const ExternalPaymentArea = ({ externalPayerRequest }) => {
  const queryClient = useQueryClient()

  const { payable, valid = false, expired = false } = externalPayerRequest || {}

  const openPaymentsDrawer = useOpenPaymentsDrawer()
  const externalPayersFeatureEnabled = useSettingByName(
    'modules.financial.features.external_payers.enable'
  )
  const externalPayersDiscountsFeatureEnabled = useSettingByName(
    'modules.financial.features.external_payers_discount_codes.enable'
  )

  const triggerRefetch = useCallback(() => {
    queryClient.invalidateQueries([
      'get-external-payer-request',
      externalPayerRequest?.id
    ])
  }, [queryClient, externalPayerRequest?.id])

  return (
    <Container fluid className={styles.container}>
      <Row nogutter align='start' justify='between' className={styles.header}>
        <LandingPageLogo />
        <LocaleSwitcherDropdown />
      </Row>
      {externalPayersFeatureEnabled && externalPayerRequest ? (
        expired ? (
          <ExpiredCard externalPayerRequest={externalPayerRequest} />
        ) : valid ? (
          <Row
            nogutter
            justify='center'
            align='center'
            className={'marginTop-more'}
          >
            <PaymentPlanCard
              paymentPlan={payable}
              opts={{
                externalPayerRequest,
                onFeeClick: openPaymentsDrawer,
                hideTotalAmountDue: true,
                hideDueDateColumn: true,
                hideSecondaryFeeStatuses: true
              }}
            />
            {externalPayersDiscountsFeatureEnabled && (
              <PayableDiscounts
                payable={payable}
                externalPayerRequest={externalPayerRequest}
                triggerRefetch={triggerRefetch}
              />
            )}
          </Row>
        ) : (
          <PaidCard />
        )
      ) : (
        <InvalidCard />
      )}
    </Container>
  )
}

export default ExternalPaymentArea

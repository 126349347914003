import React from 'react'

import {
  Bar,
  BarChart,
  Customized,
  LabelList,
  Legend,
  ResponsiveContainer
} from 'recharts'

import { Loading } from '@fullfabric/alma-mater'

import {
  commonStyles,
  DEFAULT_SPACE_TO_CONTENT,
  renderCustomCartesianGrid,
  renderCustomXAxis,
  renderCustomYAxis,
  XAxisTick
} from 'apps/Reports/components/CustomChartElements'
import GraphPlaceholder from 'apps/Reports/components/ProductOverviewReport/GraphPlaceholder'
import {
  useFilter,
  useIsLoadingFilter,
  useSelectedLifecycles
} from '../FilterContext'
import CustomBarLabel from './CustomBarLabel'
import LegendContent from './LegendContent'
import useFunnelChartData from './useFunnelChartData'
import Variations from './Variations'

export default function FunnelChart({ fixedContextId }) {
  const filter = useFilter()
  const { selectedLifecycles } = useSelectedLifecycles()
  const isLoadingFilter = useIsLoadingFilter()
  const { barEdges, data, isLoading, error, updateBarEdge } =
    useFunnelChartData(filter, selectedLifecycles, fixedContextId)

  if (isLoading || isLoadingFilter) return <Loading />

  if (error || !data?.stats?.length) {
    return <GraphPlaceholder.BarLarge />
  }

  return (
    <ResponsiveContainer debounce={10}>
      <BarChart
        data={data.stats}
        margin={{
          top: 12,
          right: DEFAULT_SPACE_TO_CONTENT,
          bottom: 16
        }}
        className={commonStyles.customBarChart}
      >
        {renderCustomCartesianGrid()}
        {renderCustomYAxis()}
        {renderCustomXAxis({
          dataKey: 'state',
          tick: <XAxisTick />
        })}

        <Bar
          dataKey='value'
          fill='#7856FF'
          barSize={80}
          isAnimationActive={false}
        >
          <LabelList
            dataKey='value'
            position='insideTop'
            content={<CustomBarLabel updateBarEdge={updateBarEdge} />}
          />
        </Bar>

        <Customized
          barEdges={barEdges}
          component={Variations}
          variation={data.variation}
        />

        <Legend
          wrapperStyle={{
            left: 0,
            top: 0,
            display: 'flex',
            alignItems: 'start'
          }}
          height={44}
          verticalAlign='top'
          align='left'
          content={
            <LegendContent
              hasVariationData={
                data.stats.length > 1 &&
                data.stats.some(({ value }) => value > 0)
              }
            />
          }
        />
      </BarChart>
    </ResponsiveContainer>
  )
}

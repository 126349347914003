import { connect } from 'react-redux'

import { push } from 'connected-react-router'

import archiveCourseAction from 'apps/Courses/actions/archiveCourse'
import getCourseAction from 'apps/Courses/actions/getCourse'
import getCourseSchemaAction from 'apps/Courses/actions/getCourseSchema'
import actionTypes from 'apps/Courses/actions/types'
import EditCoursePage from 'apps/Courses/components/EditCoursePage'
import { COURSE_LIST_ROUTE } from 'apps/Courses/utils/routeConstants'

const mapStateToProps = ({
  editCourse: {
    data,
    schema,
    loadingData,
    loadingSchema,
    newlyCreated,
    basicSection: { isEditing: isBasicSectionEditing },
    datesSection: { isEditing: isDatesSectionEditing },
    statusSection: { isEditing: isStatusSectionEditing },
    academicDetailsSection: { isEditing: isAcademicDetailsSectionEditing },
    capacitySection: { isEditing: isCapacitySectionEditing },
    schemaSection: { isEditing: isSchemaSectionEditing }
  }
}) => {
  return {
    course: data,
    schema,
    newlyCreated,
    loading: loadingData || loadingSchema,
    isBasicSectionEditing,
    isDatesSectionEditing,
    isStatusSectionEditing,
    isAcademicDetailsSectionEditing,
    isCapacitySectionEditing,
    isSchemaSectionEditing
  }
}

const mapDispatchToProps = (dispatch, { urlCourseId }) => {
  return {
    onBackClick: (evt) => {
      evt.preventDefault()
      dispatch(push(COURSE_LIST_ROUTE))
    },
    getCourse: () => dispatch(getCourseAction(urlCourseId)),
    getCourseSchema: () => dispatch(getCourseSchemaAction()),
    onDeleteClick: (course) =>
      dispatch({
        type: actionTypes.openDeleteCourseModal,
        payload: { course, open: true, withinEditPage: true }
      }),
    onArchiveClick: (course) => {
      if (course.archived) {
        dispatch(archiveCourseAction(course))
      } else {
        dispatch({
          type: actionTypes.openArchiveCourseModal,
          payload: { course, open: true }
        })
      }
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditCoursePage)

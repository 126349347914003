import normalizeLocale from '../normalizeLocale'

const getBrowserLocale = () => navigator.language

const getCookieLocale = () => {
  if (!hasCookieLocale()) {
    return null
  }

  return document.cookie
    .split(';')
    .find((item) => item.match(/locale=/))
    .trim()
    .split('=')
    .pop()
}

const hasCookieLocale = () => {
  const cookieFilter = document.cookie
    .split(';')
    .filter((item) => item.trim().startsWith('locale'))

  return document.cookie && cookieFilter.length > 0
}

const getLocale = (normalizedLocale, locales) =>
  locales.find((sl) => normalizeLocale(sl) === normalizedLocale)

const getInitialLocale = (user, locales) => {
  const normalizedLocale = normalizeLocale(
    (user && user.locale) || getCookieLocale() || getBrowserLocale()
  )

  return getLocale(normalizedLocale, locales) || locales[0]
}

export default getInitialLocale

const YEAR_IN_MS = 365 * 24 * 3600 * 1000

export const setCookieLocale = (locale) => {
  const localeParts = locale.split('-')
  const formattedLocale =
    localeParts.length === 1
      ? localeParts[0]
      : [localeParts[0], localeParts[1].toUpperCase()].join('-')

  const today = new Date().getTime()
  const inAYear = new Date(today + YEAR_IN_MS)

  document.cookie = `locale=${formattedLocale}; path=/; expires=${inAYear.toUTCString()}`
}

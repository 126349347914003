import React from 'react'
import { useTranslation } from 'react-i18next'

import { Text } from '@fullfabric/alma-mater'

import styles from './styles.module.scss'

const getLegendLabel = (value, t) => {
  const labelsByValue = {
    starts: t('Started'),
    submissions: t('Submitted')
  }

  return labelsByValue[value]
}

const LegendContent = (props) => {
  const { payload } = props
  const { t } = useTranslation()

  return (
    <ul className={styles.legendContainer}>
      {payload.map((entry, index) => (
        <li key={index} className={styles.itemContainer}>
          <span
            style={{
              backgroundColor: entry.color
            }}
            className={styles.itemIcon}
          />
          <Text>{getLegendLabel(entry.value, t)}</Text>
        </li>
      ))}
    </ul>
  )
}

export default LegendContent

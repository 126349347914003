import React from 'react'
import { Trans } from 'react-i18next'

import { Alert, Field } from '@fullfabric/alma-mater'

import { useSetImportDocumentData } from 'apps/Imports/contexts/import-document'
import { useSelectedData } from 'apps/Imports/contexts/selected-data'
import { useStepsCompleted } from 'apps/Imports/contexts/steps'
import { PROFILE_IMPORT_STEPS } from 'apps/Imports/utils/profile-import/constants'
import FileUploadInput from './components/file-upload-input'
import { handleSelectedFile, WINDOWS_PLATFORMS } from './utils'

import styles from './styles.module.scss'

const FileUploadStep = () => {
  const {
    data: { file, separator },
    onDataChanged
  } = useSelectedData()
  const { setImportDocument } = useSetImportDocumentData()

  const { isStepUnlocked } = useStepsCompleted()
  const summaryUnlocked = isStepUnlocked(PROFILE_IMPORT_STEPS.MAPPING)

  return (
    <>
      {summaryUnlocked ? (
        <Alert
          type='warning'
          className='marginBottom-more'
          description={
            <Trans>
              If you change the file you will need to repeat the mapping step
            </Trans>
          }
        />
      ) : null}

      <FileUploadInput
        className={styles.fileUploadField}
        accept={
          WINDOWS_PLATFORMS.includes(window.navigator.platform)
            ? null
            : 'text/csv'
        }
        multiple={false}
        selectedFile={file}
        onFileSelected={handleSelectedFile(
          file,
          onDataChanged,
          setImportDocument
        )}
      />

      <Field
        id='separator'
        type='radio'
        label={<Trans>Separator in .csv file</Trans>}
        help={
          <Trans>
            You can check this by opening your .csv file in a text editor.
          </Trans>
        }
        value={separator}
        onChange={onDataChanged}
        inputOptions={[
          {
            value: ',',
            label: <Trans>Comma ,</Trans>
          },
          {
            value: ';',
            label: <Trans>Semi-Colon ;</Trans>
          },
          {
            value: ':',
            label: <Trans>Colon :</Trans>
          },
          { value: '\t', label: <Trans>Tab</Trans> }
        ]}
      />
    </>
  )
}

export default FileUploadStep

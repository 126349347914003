import React from 'react'
import { useTranslation } from 'react-i18next'

import { noop } from 'lodash'

import { Badge, EditIcon, Link, PlainButton } from '@fullfabric/alma-mater'

import getTeams from 'shared/api/teams/getTeams'
import AutoloadDataTable from 'shared/components/AutoloadDataTable'

import styles from './TeamsTable.module.scss'

export default function TeamsTable() {
  const { t } = useTranslation()

  return (
    <AutoloadDataTable
      autoPaginate
      fixedLayout
      fetchFn={getTeams}
      reactQueryProps={{ staleTime: 0 }}
      emptyMessage={t('No data')}
      className={styles.teamsTable}
      columns={[
        {
          Header: t('Team name'),
          accessor: 'name',
          Cell: ({ value: name }) => <strong>{name}</strong>
        },
        {
          Header: t('People'),
          accessor: 'profile_count',
          disableSortBy: true
        },
        {
          Header: t('Access Scope'),
          accessor: 'authorization_agent_access_segment_id',
          disableSortBy: true,
          getTitle: noop,
          Cell: ({ value }) =>
            value ? (
              <Badge type='purple'>Yes</Badge>
            ) : (
              <Badge type='grey'>No</Badge>
            )
        },
        {
          Header: '',
          accessor: 'id',
          disableSortBy: true,
          width: 100,
          getTitle: () => 'Edit',
          Cell: ({ value }) => (
            <Link
              withoutStyles
              className={styles.editButton}
              href={`/staff#teams/${value}/edit`}
              target='_parent'
            >
              <PlainButton primary>
                Edit
                <PlainButton.RightIcon>
                  <EditIcon />
                </PlainButton.RightIcon>
              </PlainButton>
            </Link>
          )
        }
      ]}
      loadMoreProps={{
        sizeKey: 'limit',
        offsetKey: 'offset',
        resultDataKey: 'teams'
      }}
      sortable
      sortParams={{
        sortKey: 'sort[field]',
        directionKey: 'sort[direction]',
        ascValue: 1,
        descValue: -1
      }}
      initialSort={{
        'sort[field]': 'name',
        'sort[direction]': 1
      }}
    />
  )
}

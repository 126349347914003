import React from 'react'

function ArrowDownIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='12'
      height='12'
      fill='none'
      viewBox='0 0 12 12'
    >
      <path
        fill='#D22A0C'
        d='M5 9L2 6 1 7l5 5h-.029L11 7l-1-1-3 3V0H5v9z'
      ></path>
    </svg>
  )
}

export default ArrowDownIcon

import React from 'react'
import { Trans } from 'react-i18next'

import { Text } from '@fullfabric/alma-mater'
import { IfHasSetting } from '@fullfabric/authorization-officer'

const SubjectBasicsData = ({ isLoading, data }) => (
  <div role='grid'>
    <div role='row'>
      <Text
        role='columnheader'
        type='h5'
        fontColor='text-base-darker'
        marginBottom='tiny'
      >
        <Trans>Subject Code</Trans>
      </Text>
    </div>
    <div role='row' aria-label='code value row'>
      <Text
        role='gridcell'
        type='f4'
        fontColor='text-base-darkest'
        marginBottom='default'
      >
        {isLoading ? <Trans>Loading</Trans> : data?.code}
      </Text>
    </div>
    <div role='row'>
      <Text
        role='columnheader'
        type='h5'
        fontColor='text-base-darker'
        marginBottom='tiny'
      >
        <Trans>Subject Name</Trans>
      </Text>
    </div>
    <div role='row' aria-label='name value row'>
      <Text
        role='gridcell'
        type='f4'
        fontColor='text-base-darkest'
        marginBottom='default'
      >
        {isLoading ? <Trans>Loading</Trans> : data?.name}
      </Text>
    </div>
    <IfHasSetting settingName='modules.core.submodules.institutions.features.themes.enable'>
      <div role='row'>
        <Text
          role='columnheader'
          type='h5'
          fontColor='text-base-darker'
          marginBottom='tiny'
        >
          <Trans>Theme</Trans>
        </Text>
      </div>
      <div role='row' aria-label='theme value row'>
        <Text
          role='gridcell'
          type='f4'
          fontColor={
            !isLoading && !data?.theme_id ? 'text-base' : 'text-base-darkest'
          }
          marginBottom='default'
        >
          {isLoading ? (
            <Trans>Loading</Trans>
          ) : (
            data?.theme_name || <Trans>Not defined</Trans>
          )}
        </Text>
      </div>
    </IfHasSetting>
    <IfHasSetting settingName='modules.core.submodules.institutions.features.external_id.enable'>
      <div role='row'>
        <Text
          role='columnheader'
          type='h5'
          fontColor='text-base-darker'
          marginBottom='tiny'
        >
          <Trans>External ID</Trans>
        </Text>
      </div>
      <div role='row' aria-label='external id value row'>
        <Text
          role='gridcell'
          type='f4'
          fontColor={
            !isLoading && !data?.external_id ? 'text-base' : 'text-base-darkest'
          }
          marginBottom='default'
        >
          {isLoading ? (
            <Trans>Loading</Trans>
          ) : (
            data?.external_id || <Trans>Not defined</Trans>
          )}
        </Text>
      </div>
    </IfHasSetting>
  </div>
)

export default SubjectBasicsData

import React from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router'

import getProfile from 'apps/MergeProfiles/api/getProfile'
import DuplicateProfileContext from 'apps/MergeProfiles/contexts/DuplicateProfile'
import MainProfileContext from 'apps/MergeProfiles/contexts/MainProfile'
import { useMergeData } from 'apps/MergeProfiles/contexts/MergeData'

const ProfilesProvider = ({ children }) => {
  const { id: mainProfileId } = useParams()
  const { updateMergeData, mergeData } = useMergeData()

  const mainProfileQuery = useQuery(['getProfile', mainProfileId], () =>
    getProfile(mainProfileId)
  )

  const duplicateProfileQuery = useQuery(
    ['getProfile', mergeData.sourceProfileId, 'full:true'],
    () =>
      getProfile(mergeData.sourceProfileId, {
        full: true,
        options: { can_be_merged_into_and_deleted: true }
      })
  )

  React.useEffect(() => {
    updateMergeData({
      targetProfileId: mainProfileId,
      targetProfile: mainProfileQuery?.data
    })
  }, [mainProfileId, mainProfileQuery.data, updateMergeData])

  React.useEffect(() => {
    updateMergeData({
      sourceProfile: duplicateProfileQuery.data
    })
  }, [duplicateProfileQuery.data, updateMergeData])

  return (
    <MainProfileContext.Provider value={mainProfileQuery}>
      <DuplicateProfileContext.Provider value={duplicateProfileQuery}>
        {children}
      </DuplicateProfileContext.Provider>
    </MainProfileContext.Provider>
  )
}

export default ProfilesProvider

import React from 'react'
import { Trans } from 'react-i18next'

import { Alert } from '@fullfabric/alma-mater'

const UploadedFileErrorAlert = () => (
  <Alert
    error
    title={<Trans>Error with importing file.</Trans>}
    description={
      <>
        <br />
        <Trans>
          It wasn't possible to handle your file. Most likely, some error
          occurred while uploading it.
        </Trans>
        <br />
        <Trans>Please go back, upload the file and import it again.</Trans>
        <br />
        <i>
          <Trans>
            If you continue to experience issues please contact support.
          </Trans>
        </i>
      </>
    }
  />
)

export default UploadedFileErrorAlert

/* eslint-disable */

import React from 'react'
import { Trans } from 'react-i18next'

import { Modal, Text } from '@fullfabric/alma-mater'
import {
  useCurrentUser,
  UserRoleUtils
} from '@fullfabric/authorization-officer'

import { useAppData } from 'shared/contexts/AppData'

import startApplication from 'apps/Authentication/api/startApplication'
import ContinueApplicsIcon from 'apps/Authentication/images/icons/continue-application-icon'
import MyselfIcon from 'apps/Authentication/images/icons/myself-icon'
import OnBehalfIcon from 'apps/Authentication/images/icons/on-behalf-icon'
import StartApplicOption from './StartApplicOption'

import styles from '../styles.module.scss'

const SelectModeModal = ({ next, newContactId }) => {
  const {
    logo_url,
    authenticated_profile_id,
    options,
    template_id,
    template_type,
    has_other_applics: hasOtherApplics
  } = useAppData()

  const user = useCurrentUser()
  const isRegistration = template_type === 'registration'

  const submitApplication = () => {
    let params = {}
    if (options.context) {
      params.context = options.context
    }
    params = {
      ...params,
      profile_id: authenticated_profile_id
    }
    return startApplication(template_id, params).then((applic) => {
      window.location.replace(`/applications/${applic.id}`)
    })
  }

  const showParticipantModal = () => {
    next()
  }

  const showOtherApplications =
    hasOtherApplics && (UserRoleUtils.isContact(user) || !!newContactId)

  return (
    <Modal size='medium' isOpen={true} className={styles.modal}>
      <img alt='Logo' src={logo_url} />
      <Text type='h2' fontColor='text-base-darkest' marginBottom='more'>
        <Trans>Please select an option</Trans>
      </Text>
      <div className='options'>
        <StartApplicOption
          IconComponent={MyselfIcon}
          label={
            isRegistration ? (
              <Trans>I am starting a registration for myself</Trans>
            ) : (
              <Trans>I am starting an application for myself</Trans>
            )
          }
          onClick={submitApplication}
        />
        <StartApplicOption
          IconComponent={OnBehalfIcon}
          label={
            isRegistration ? (
              <Trans>
                I am starting a registration on behalf of someone else
              </Trans>
            ) : (
              <Trans>
                I am starting an application on behalf of someone else
              </Trans>
            )
          }
          onClick={showParticipantModal}
        />
        {showOtherApplications && (
          <StartApplicOption
            link={isRegistration ? '/my-registrations' : '/my-applications'}
            IconComponent={ContinueApplicsIcon}
            label={
              isRegistration ? (
                <Trans>I am continuing an existing registration</Trans>
              ) : (
                <Trans>I am continuing an existing application</Trans>
              )
            }
          />
        )}
      </div>
    </Modal>
  )
}

export default SelectModeModal

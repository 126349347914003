import React from 'react'
import { Trans } from 'react-i18next'

import { Label } from '@fullfabric/alma-mater'

const CompleteCheck = ({ coursesLength, minSelected, maxSelected }) => {
  const errors = []

  if (minSelected && minSelected > coursesLength) {
    errors.push(<Trans>Select at least {minSelected} courses</Trans>)
  }

  if (maxSelected && maxSelected < coursesLength) {
    errors.push(<Trans>Select at most {maxSelected} courses</Trans>)
  }

  return !errors.length ? (
    <Label outline color='green-700'>
      <Trans>Complete</Trans>
    </Label>
  ) : (
    <Label outline color='purple-500'>
      <Trans>Incomplete</Trans>
    </Label>
  )
}

export default CompleteCheck

.react-app ._navCard_1vhxh_1 {
  background-color: white;
  width: 100%;
  padding: var(--space-default);
  padding-bottom: 0px;
  border-bottom: var(--border-base-lighter);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.react-app ._navCard_1vhxh_1._disabled_1vhxh_12 ._navList_1vhxh_12 {
  color: var(--ff-color-text-base);
}
.react-app ._navCard_1vhxh_1._disabled_1vhxh_12 ._cardNavLink_1vhxh_15 {
  color: var(--ff-color-text-base);
  cursor: default;
}

.react-app ._navList_1vhxh_12 {
  display: flex;
  align-items: center;
  padding-bottom: var(--space-default);
  gap: var(--space-more);
}

.react-app ._cardNavLink_1vhxh_15 {
  display: inline-block;
  cursor: pointer;
  padding-top: var(--space-tiny);
  padding-bottom: var(--space-tiny);
}
.react-app ._cardNavLink_1vhxh_15._selected_1vhxh_33 {
  color: var(--ff-color-text-primary);
  border-bottom: var(--border-primary);
  border-width: 2px;
  cursor: default;
}
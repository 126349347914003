import React from 'react'
import { Trans } from 'react-i18next'

import PageContainer from 'apps/Imports/components/page-container'
import ImportSummaryStep from 'apps/Imports/components/profile-import/import-summary-step'
import { useCurrentStep } from 'apps/Imports/contexts/steps'
import { PROFILE_IMPORT_STEPS } from 'apps/Imports/utils/profile-import/constants'

import styles from './styles.module.scss'

const ImportSummaryPage = () => {
  useCurrentStep(PROFILE_IMPORT_STEPS.SUMMARY)

  return (
    <PageContainer
      title={<Trans>Summary</Trans>}
      sidebarTitle={<Trans>Import Profiles</Trans>}
      subtitle={
        <Trans>
          Please confirm all the information below before importing.
        </Trans>
      }
      className={styles.container}
      contentColumns={9}
    >
      <ImportSummaryStep />
    </PageContainer>
  )
}

export default ImportSummaryPage

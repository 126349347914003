import React from 'react'
import { Trans } from 'react-i18next'

import { Alert } from '@fullfabric/alma-mater'

const FormDataErrorAlert = () => (
  <Alert
    error
    title={<Trans>Error with importing file.</Trans>}
    description={
      <>
        <Trans>
          There was an error while processing the submitted information.
        </Trans>
        <br />
        <Trans>
          Please go back and review the different fields. Make sure you filled
          all the required ones.
        </Trans>
        <br />
        <i>
          <Trans>
            If you continue to experience issues please contact support.
          </Trans>
        </i>
      </>
    }
  />
)

export default FormDataErrorAlert

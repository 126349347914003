import React from 'react'
import { Route, Switch } from 'react-router'

import ContractPage from 'apps/Billing/pages/ContractPage'
import ContractSettingsPage from 'apps/Billing/pages/ContractSettingsPage'
import ContractsTablePage from 'apps/Billing/pages/ContractsTablePage'

export const HOME_ROUTE = '/billing'
export const CONTRACTS_TABLE_ROUTE = HOME_ROUTE
export const CONTRACT_DETAILS_ROUTE = '/billing/:contractId'
export const CONTRACT_SETTINGS_ROUTE = '/billing/:contractId/settings'

const BillingAppRoutes = () => {
  return (
    <Switch>
      <Route
        exact
        path={CONTRACTS_TABLE_ROUTE}
        component={ContractsTablePage}
      />
      <Route exact path={CONTRACT_DETAILS_ROUTE} component={ContractPage} />
      <Route
        exact
        path={CONTRACT_SETTINGS_ROUTE}
        component={ContractSettingsPage}
      />
    </Switch>
  )
}

export default BillingAppRoutes

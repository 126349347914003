.react-app ._contentContainer_1adj0_1 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.react-app ._feedback_1adj0_7 {
  z-index: 1;
  max-width: 80%;
  margin-top: var(--space-default);
}
.react-app ._feedback_1adj0_7 a {
  display: none;
}

.react-app ._inputsContainer_1adj0_16 {
  display: flex;
  margin-top: var(--space-big);
}

.react-app ._numberTextInput_1adj0_21 {
  width: 144px;
  margin-left: var(--space-tiny);
}

.react-app ._content_1adj0_1 {
  margin: auto 0;
  max-width: calc(100% - 3 * var(--space-big));
}

.react-app ._footer_1adj0_31 {
  width: calc(100% - 3 * var(--space-big));
  margin-top: var(--space-big);
}

.react-app ._actionButton_1adj0_36 {
  margin-right: var(--space-more);
}

/* Portrait */
@media only screen and (min-width: 320px) and (max-width: 568px) and (orientation: portrait) {
  .react-app ._contentContainer_1adj0_1 {
    padding-top: var(--space-default);
    padding-bottom: var(--space-default);
  }
  .react-app ._content_1adj0_1 {
    max-width: 100%;
  }
  .react-app ._feedback_1adj0_7 {
    margin: 0;
    max-width: 100%;
  }
  .react-app ._footer_1adj0_31 {
    width: 100%;
  }
  .react-app ._actionButton_1adj0_36 {
    margin-right: var(--space-default);
  }
  .react-app ._numberTextInput_1adj0_21 {
    width: 100%;
    margin: 0;
  }
  .react-app ._inputLabel_1adj0_63 {
    text-align: center;
  }
  .react-app ._inputsContainer_1adj0_16 {
    margin-top: var(--space-default);
    flex-direction: column;
  }
  .react-app ._inputsContainer_1adj0_16 button {
    border-left: solid 1px var(--ff-color-grey-500) !important;
    width: calc(50% - 2px);
    border-radius: var(--border-radius-default);
    margin-bottom: var(--space-tiny);
    margin-right: var(--space-tiny);
  }
  .react-app ._inputsContainer_1adj0_16 button:first-child {
    width: 100%;
    margin-right: 0;
  }
  .react-app ._inputsContainer_1adj0_16 button:nth-child(odd) {
    margin-right: 0;
  }
}
/* Landscape */
@media only screen and (min-width: 320px) and (max-width: 568px) and (orientation: landscape) {
  .react-app ._inputsContainer_1adj0_16 {
    margin-top: var(--space-default);
  }
  .react-app ._footer_1adj0_31 {
    padding: var(--space-default);
    width: calc(100% - 32px);
    padding-bottom: 0;
  }
  .react-app ._actionButton_1adj0_36 {
    margin-right: var(--space-default);
  }
  .react-app ._inputLabel_1adj0_63 {
    font-weight: 300;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
  }
  .react-app ._content_1adj0_1 {
    max-width: 100%;
  }
}
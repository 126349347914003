import React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { useAppData } from 'shared/contexts/AppData'

import confirmAttendance from 'apps/Events/api/confirmAttendance'
import {
  useCurrentFeedbackMessage,
  useOnDisplayFeedbackMessage
} from 'apps/Events/components/FeedbackMessageProvider'
import { useLatestEventResponse } from 'apps/Events/contexts/QRCodeScanResultContext'
import { extractEventAndResponseIdsFromUrl } from 'apps/Events/utils/extractEventAndResponseIdsFromUrl'

export const useOnScanSuccess = ({
  isScanning,
  setIsScanning,
  setOpenGuestCountModal
}) => {
  const { t } = useTranslation()
  const { institution, event } = useAppData()
  const { id: eventId } = useParams()

  const displayFeedbackMessage = useOnDisplayFeedbackMessage()
  const feedbackMessage = useCurrentFeedbackMessage()

  const { setLatestEventResponse } = useLatestEventResponse()

  const onScanSuccess = React.useCallback(
    async (scannedUrlFromQRCode) => {
      if (!scannedUrlFromQRCode) return
      if (isScanning || feedbackMessage) return

      setIsScanning(true)

      const eventAndResponseIdsData = extractEventAndResponseIdsFromUrl(
        scannedUrlFromQRCode,
        institution.host
      )

      if (
        !eventAndResponseIdsData ||
        eventAndResponseIdsData?.eventId !== eventId
      ) {
        displayFeedbackMessage(t('The code is not valid for this event.'), {
          type: 'error'
        })
        setIsScanning(false)

        return
      }

      try {
        const updatedEventResponse = await confirmAttendance(
          eventAndResponseIdsData.eventId,
          eventAndResponseIdsData.responseId
        )

        setLatestEventResponse(updatedEventResponse)

        displayFeedbackMessage(
          t('Presence confirmed: {{name}}', {
            name: `${updatedEventResponse.first_name} ${updatedEventResponse.last_name} - ${updatedEventResponse.email}`,
            nsSeparator: false
          }),
          {
            type: 'success'
          }
        )
        setIsScanning(false)

        if (event.ask_guest_count) {
          setOpenGuestCountModal(true)
        }
      } catch (err) {}
    },
    [
      displayFeedbackMessage,
      event.ask_guest_count,
      eventId,
      feedbackMessage,
      institution.host,
      isScanning,
      setIsScanning,
      setLatestEventResponse,
      setOpenGuestCountModal,
      t
    ]
  )

  return onScanSuccess
}

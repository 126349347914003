import { utils } from '@fullfabric/public-api'

export default async function updateStaff(profileId, patch) {
  const response = await fetch(`/api/staff/${profileId}`, {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'same-origin',
    body: JSON.stringify(patch)
  })

  return await utils.checkResponse(response)
}

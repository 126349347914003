import React, { useState } from 'react'
import { Trans } from 'react-i18next'

import {
  Button,
  DropdownInput,
  Modal,
  ModalButtons,
  ModalTitle,
  Text
} from '@fullfabric/alma-mater'

import ChangePathWarning from 'apps/Applics/components/StaffSubjectSelection/ChangePathWarning'
import { getPathsWithState } from 'apps/Applics/utils/getSelectedPath'

import styles from './styles.module.scss'

const ChangePathModal = ({ isOpen, applic, studyPlanPaths, handleClose }) => {
  const selectedPaths = getPathsWithState(applic, studyPlanPaths)

  const options = selectedPaths.map((path) => ({
    label: path.name,
    value: path.id,
    disabled: path.selected
  }))

  const [selection, setSelection] = useState(false)
  const [error, setError] = useState(false)
  const [warningOpen, setWarningOpen] = useState(false)

  const handleChangeSelection = (option) => {
    setSelection(option)
    setError(false)
  }

  const handleCloseInternal = () => {
    setSelection(undefined)
    setWarningOpen(false)
    setError(false)
    handleClose()
  }

  const handleChangePath = () => {
    if (!selection) {
      return setError(true)
    }
    setWarningOpen(true)
  }

  const closeAll = () => {
    setWarningOpen(false)
    handleCloseInternal()
  }

  return (
    <>
      <Modal
        isOpen={isOpen}
        size='medium'
        header={<ModalTitle title={<Trans>Change Path</Trans>} />}
        contentClassName={styles.modalBody}
        className={styles.modal}
        footer={
          <ModalButtons>
            <Button onClick={handleCloseInternal}>
              <Trans>Cancel</Trans>
            </Button>
            <Button primary onClick={handleChangePath}>
              <Trans>Change Path</Trans>
            </Button>
          </ModalButtons>
        }
      >
        <DropdownInput
          id='changePathSelect'
          aria-label='change path'
          options={options}
          onChange={handleChangeSelection}
          value={selection || []}
          hasError={error}
        />
        {error && (
          <Text small fontColor='text-alert' className={styles.marginError}>
            A path must be selected
          </Text>
        )}
      </Modal>
      <ChangePathWarning
        applic={applic}
        pathId={selection}
        isOpen={warningOpen}
        handleClose={closeAll}
      />
    </>
  )
}

export default ChangePathModal

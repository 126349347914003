import React from 'react'

import { useCurrentUser } from '@fullfabric/authorization-officer'

import {
  TIMEFRAME_INTERVAL,
  TIMEFRAME_INTERVAL_STORAGE_KEY
} from '../constants'

const useTimeframeIntervalState = () => {
  const user = useCurrentUser()
  const [timeframeInterval, setTimeframeInterval] = React.useState(() => {
    const storedTimeframe = localStorage.getItem(
      TIMEFRAME_INTERVAL_STORAGE_KEY + user.id
    )
    return storedTimeframe || 'weekly'
  })

  const updateTimeframeInterval = React.useCallback(
    (newTimeframe) => {
      localStorage.setItem(
        TIMEFRAME_INTERVAL_STORAGE_KEY + user.id,
        newTimeframe
      )
      setTimeframeInterval(newTimeframe)
    },
    [user.id]
  )

  return {
    timeframeInterval,
    updateTimeframeInterval,
    isWeeklyTimeframeSelected: timeframeInterval === TIMEFRAME_INTERVAL.WEEKLY
  }
}

export default useTimeframeIntervalState

import { utils } from '@fullfabric/public-api'

const unsubmitEvaluation = async (evaluationId, data) => {
  const response = await fetch(
    `/evaluations/api/evaluations/${evaluationId}/unsubmit`,
    {
      credentials: 'same-origin',
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    }
  )

  return await utils.checkResponse(response)
}

export default unsubmitEvaluation

import React, { useContext } from 'react'
import { useQuery } from 'react-query'

import getProfile from 'apps/Applics/api/getProfile'
import { useApplicData } from './ApplicData'

const ProfileDataContext = React.createContext()

const ProfileDataProvider = ({ children }) => {
  const applic = useApplicData()
  const { data: profile } = useQuery(
    ['profile', applic?.profile_id],
    getProfile,
    {
      enabled: Boolean(applic?.profile_id),
      suspense: true
    }
  )

  return (
    <ProfileDataContext.Provider value={profile}>
      {children}
    </ProfileDataContext.Provider>
  )
}

const useProfileData = () => useContext(ProfileDataContext)

export { ProfileDataProvider, useProfileData }

/* eslint-disable camelcase */

import React from 'react'
import { Trans } from 'react-i18next'

import { Button, Text } from '@fullfabric/alma-mater'

import CardContent from 'apps/StudyPlans/components/CardContent'
import CardInfoBulletLocalized from 'apps/StudyPlans/components/CardInfoBulletLocalized'
import { useSetSettingsSectionsBeingEdited } from 'apps/StudyPlans/contexts/SettingsSectionsBeingEdited'
import { useStudyPlan } from 'apps/StudyPlans/contexts/StudyPlanData'

const DegreeClassSectionDisplay = () => {
  const studyPlan = useStudyPlan()
  const setSectionBeingEdited = useSetSettingsSectionsBeingEdited()
  const onEditClick = React.useCallback(() => {
    setSectionBeingEdited((sections) => ({ ...sections, degreeClasses: true }))
  }, [setSectionBeingEdited])

  return (
    <React.Fragment>
      <CardContent
        title={<Trans>Degree classes</Trans>}
        actions={
          <Button size='small' onClick={onEditClick}>
            <Trans>Edit</Trans>
          </Button>
        }
      >
        {studyPlan.degree_classes && studyPlan.degree_classes.length ? (
          studyPlan.degree_classes.map((degree_class) => (
            <CardInfoBulletLocalized
              key={degree_class.id}
              main={degree_class.names}
              secondary={
                <span>
                  {degree_class.minimum} - {degree_class.maximum}
                </span>
              }
            />
          ))
        ) : (
          <Text type='f4' fontColor='text-base' marginTop='tiny'>
            <Trans>No degree class defined</Trans>
          </Text>
        )}
      </CardContent>
    </React.Fragment>
  )
}

export default DegreeClassSectionDisplay

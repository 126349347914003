import React from 'react'
import { Trans } from 'react-i18next'

import { Col, Field, Row } from '@fullfabric/alma-mater'

import { useStudyPlan } from 'apps/StudyPlans/contexts/StudyPlanData'
import {
  useOnChangeSubjectEditionData,
  useSubjectEditionData
} from 'apps/StudyPlans/contexts/SubjectEditionData'
import { subjectTypeOptionsForPath } from 'apps/StudyPlans/utils/subjectTypesValues'

const MultiplePathsCourseTypeField = () => {
  const studyPlan = useStudyPlan()
  const data = useSubjectEditionData()
  const onChange = useOnChangeSubjectEditionData()

  return (
    <>
      {studyPlan.paths.map((path) => {
        const inputOptions = subjectTypeOptionsForPath(path)

        const courseTypeForPath = (data.course_types || []).filter(
          (type) => type.path_id === path.id
        )[0]

        return (
          <Row key={path.id}>
            <Col xs={8}>
              <Field
                id={`path_name_${path.id}`}
                type='text'
                label={<Trans>Path name</Trans>}
                value={path.name}
                onChange={onChange}
                inputOptions={{ disabled: true }}
              />
            </Col>
            <Col xs={4}>
              <Field
                id={`course_type_${path.id}`}
                type='dropdown'
                label={<Trans>Course type</Trans>}
                value={courseTypeForPath && courseTypeForPath.group_id}
                onChange={onChange}
                inputOptions={{
                  isClearable: true,
                  placeholder: <Trans>- Select -</Trans>,
                  options: inputOptions
                }}
              />
            </Col>
          </Row>
        )
      })}
    </>
  )
}

export default MultiplePathsCourseTypeField

import { connect } from 'react-redux'

import moment from 'moment'

import newCourseDataChanged from 'apps/Courses/actions/newCourseDataChanged'
import CourseCreateFormDates from 'apps/Courses/components/CourseCreateFormDates'

const mapStateToProps = ({ newCourse: { data } }) => ({ data })

const mapDispatchToProps = (dispatch) => ({
  onChange: (field, value) => {
    const date = moment(value)
    const formattedValue = value && date.isValid() ? date.utc().format() : ''

    dispatch(newCourseDataChanged({ [field]: formattedValue }))
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CourseCreateFormDates)

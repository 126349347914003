import React from 'react'
import { useTranslation } from 'react-i18next'

import { Text } from '@fullfabric/alma-mater'

import styles from './styles.module.scss'

const MainInfoRow = ({ label, value }) => {
  const { t } = useTranslation()
  return (
    <div className={styles.infoRow} data-testid='mainInfoRow'>
      <Text type='f3' fontColor='text-base-darkest'>
        {label}
      </Text>
      {value ? (
        <Text type='h2' fontColor='text-base-darkest'>
          {value}
        </Text>
      ) : (
        <Text type='f3' fontColor='text-base-darker'>
          {t('Not available')}
        </Text>
      )}
    </div>
  )
}

export default MainInfoRow

.react-app ._baseRow_1kvkl_1 {
  padding-left: var(--space-tiny);
  padding-right: var(--space-tiny);
  display: flex;
  align-items: center;
}

.react-app ._baseCol_1kvkl_8 {
  flex: 0 0 33.3333%;
  padding-left: var(--space-less);
  padding-right: var(--space-less);
  overflow: hidden;
}

.react-app ._loading_1kvkl_15 {
  padding: var(--space-default);
  display: flex;
  justify-content: center;
  align-items: center;
}

.react-app ._sectionContainer_1kvkl_22 {
  margin-bottom: var(--space-big);
  overflow: hidden;
}
.react-app ._sectionContainer_1kvkl_22 ._row_1kvkl_26:last-child {
  border-bottom: none;
}

.react-app ._row_1kvkl_26 {
  border-bottom: var(--border-base-lighter);
}

.react-app ._sectionTitle_1kvkl_34,
.react-app ._field_1kvkl_35 {
  padding-top: var(--space-default);
  padding-bottom: var(--space-default);
}

.react-app ._mainContainer_1kvkl_40 ._errorAlert_1kvkl_40 {
  margin-bottom: var(--space-big);
}
.react-app ._mainContainer_1kvkl_40 ._errorAlert_1kvkl_40:last-child {
  margin-bottom: 0;
}

.react-app ._row_1kvkl_26._field_1kvkl_35 {
  align-items: start;
}

.react-app ._row_1kvkl_26._field_1kvkl_35._multi_1kvkl_51 ._valuesContainer_1kvkl_51 {
  margin-top: var(--space-default);
}
.react-app ._row_1kvkl_26._field_1kvkl_35._multi_1kvkl_51 ._valuesContainer_1kvkl_51 ._valueText_1kvkl_54 {
  margin-bottom: var(--space-small);
  margin-left: var(--space-more);
}
.react-app ._row_1kvkl_26._field_1kvkl_35._multi_1kvkl_51 ._valuesContainer_1kvkl_51 ._valueText_1kvkl_54:last-child {
  margin-bottom: 0;
}
.react-app ._row_1kvkl_26._field_1kvkl_35._multi_1kvkl_51 ._valuesContainer_1kvkl_51 ._subFieldLabelText_1kvkl_61 {
  font-weight: 600;
  margin-left: var(--space-more);
}
.react-app ._row_1kvkl_26._field_1kvkl_35._multi_1kvkl_51 ._valuesContainer_1kvkl_51 ._subFieldValueContainer_1kvkl_65 {
  margin-top: var(--space-default);
}
.react-app ._row_1kvkl_26._field_1kvkl_35._multi_1kvkl_51 ._valuesContainer_1kvkl_51 ._subFieldValueContainer_1kvkl_65:first-child {
  margin-top: 0;
}

.react-app ._smartEmailSection_1kvkl_72 {
  margin-bottom: var(--space-big);
}
.react-app ._smartEmailSection_1kvkl_72 ._smartEmailTitle_1kvkl_75 {
  padding: var(--space-default) var(--space-default) var(--space-small);
}
.react-app ._smartEmailSection_1kvkl_72 ._emailRow_1kvkl_78 {
  padding-bottom: var(--space-default);
}
.react-app ._smartEmailSection_1kvkl_72 ._emailRow_1kvkl_78 ._emailInputCol_1kvkl_81 {
  flex: 0 0 66.6667%;
  overflow: visible;
}
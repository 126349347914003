.react-app ._stepsContainer_yp9o1_1 {
  margin: 0;
  padding: 0;
  list-style: none;
  flex: 1 1 auto;
}

.react-app ._stepItem_yp9o1_8 {
  display: flex;
  align-items: center;
}
.react-app ._stepItem_yp9o1_8 ._stepItemLabel_yp9o1_12 {
  line-height: 26px;
}

.react-app ._stepItemCircle_yp9o1_16 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 1px solid var(--ff-color-grey-500);
}

.react-app ._stepCompleted_yp9o1_26 ._stepItemCircle_yp9o1_16 {
  border: 1px solid var(--ff-color-text-primary);
}

.react-app ._stepActive_yp9o1_30 ._stepItemCircle_yp9o1_16 {
  border: 1px solid var(--border-primary-darker);
  background-color: var(--ff-bg-primary-darker);
}

.react-app ._line_yp9o1_35 {
  width: 2px;
  height: 16px;
  background-color: var(--ff-color-grey-300);
  margin-left: var(--space-less);
  margin-top: var(--space-tiny);
  margin-bottom: var(--space-tiny);
}
.react-app ._line_yp9o1_35._lineCompleted_yp9o1_43 {
  background-color: var(--ff-color-text-primary);
}
import React from 'react'

import PropTypes from 'prop-types'

import { pluralize } from 'shared/utils/pluralize'

const Plural = ({
  className,
  style = {},
  singular,
  plural,
  count = 1,
  showCount = true,
  zero
}) => (
  <span className={className} style={style}>
    {pluralize({ singular, plural, count, showCount, zero })}
  </span>
)

Plural.propTypes = {
  singular: PropTypes.string.isRequired,
  plural: PropTypes.string,
  count: PropTypes.number,
  showCount: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.object,
  zero: PropTypes.string
}

export default Plural

import { utils } from '@fullfabric/public-api'

const getImport = async (documentId) => {
  const response = await fetch(`/api/import/${documentId}`, {
    credentials: 'same-origin'
  })

  return await utils.checkResponse(response)
}

export default getImport

import normalizeLocale from 'shared/utils/normalizeLocale'

const FLYWIRE_LOCALES_SET = new Set([
  'en',
  'es-ES',
  'zh-CN',
  'ko',
  'fr-FR',
  'ja',
  'pt-PT'
])

const getFlywireLocale = (locale) => {
  const normalizedLocale = normalizeLocale(locale)

  if (FLYWIRE_LOCALES_SET.has(locale)) return locale
  if (FLYWIRE_LOCALES_SET.has(normalizedLocale)) return normalizedLocale

  return 'en'
}

export default getFlywireLocale

import qs from 'qs'

import { utils } from '@fullfabric/public-api'

const getProductStatsMonthlyChange = async (contextId, states, withdrawn) => {
  const queryParams = qs.stringify(
    {
      context_id: contextId,
      states: JSON.stringify([states]),
      withdrawn
    },
    { arrayFormat: 'brackets' }
  )

  const response = await fetch(
    `/api/reports/product_overview/current_vs_previous_stats?${queryParams}`,
    { credentials: 'same-origin' }
  )

  return await utils.checkResponse(response)
}

export default getProductStatsMonthlyChange

import { isEmptyArrayOrValue } from 'apps/StudyPlans/utils/isEmptyArrayOrValue'

const REQUIRED_FIELDS = ['credits', 'course_types']
const NUMBER_FIELDS = ['credits', 'hours', 'max_number_absences']

const validateEditSubjectData = (
  dataToUpdate,
  studyPlan,
  sectionName,
  fieldsToUpdate
) => {
  if (!dataToUpdate || Object.keys(dataToUpdate).length === 0) {
    return {}
  }

  const requiredFieldsForSection = REQUIRED_FIELDS.filter((field) =>
    fieldsToUpdate.includes(field)
  )
  const requiredFieldsErrors = requiredFieldsForSection.reduce(
    (acc, fieldName) => {
      return isEmptyArrayOrValue(dataToUpdate[fieldName])
        ? { ...acc, [fieldName]: 'blank' }
        : acc
    },
    {}
  )

  const numberFieldsErrors = NUMBER_FIELDS.reduce((acc, curr) => {
    return isNaN(dataToUpdate[curr]) && !isEmptyArrayOrValue(dataToUpdate[curr])
      ? { ...acc, [curr]: 'notANumber' }
      : acc
  }, {})

  const subjectIsAssignedToGroupInsidePath = (studyPlanSubject, path) =>
    !!(studyPlanSubject.course_types || []).find(
      (courseType) => courseType.path_id === path.id
    )

  const pathErrors =
    sectionName === 'paths' &&
    studyPlan.multiple_paths &&
    studyPlan.paths.every(
      (path) => !subjectIsAssignedToGroupInsidePath(dataToUpdate, path)
    )
      ? { course_types: 'must_choose_at_least_one' }
      : {}

  const errors = {
    ...numberFieldsErrors,
    ...requiredFieldsErrors,
    ...pathErrors
  }

  if (Object.keys(errors).length > 0) {
    return errors
  }

  return null
}

export default validateEditSubjectData

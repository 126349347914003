.react-app ._pageContainer_1kju3_1 {
  position: relative;
}

.react-app ._container_1kju3_5 {
  margin-top: calc(var(--space-big) + var(--space-default));
  display: inline-block;
  width: 100%;
  max-width: 500px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
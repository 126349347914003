import { utils } from '@fullfabric/public-api'

const getClassOf = async (id) => {
  const response = await fetch(`/api/classes/${id}`, {
    credentials: 'same-origin'
  })

  return await utils.checkResponse(response)
}

export default getClassOf

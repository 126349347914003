import React from 'react'

import WizardLayout from './Layout'

const WizardPage = ({ title, subtitle, description, children }) => {
  return (
    <WizardLayout title={title} subtitle={subtitle} description={description}>
      {children}
    </WizardLayout>
  )
}

export default WizardPage

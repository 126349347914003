import hasPreviouslyReplied from '../has-previously-replied'

function shouldShowBanner(hasLocalAcceptance, user, userIsStaff) {
  const userIsLoggedOut = !user
  const isStaff = !userIsLoggedOut && userIsStaff

  if (isStaff) return false

  return !hasLocalAcceptance && (userIsLoggedOut || !hasPreviouslyReplied(user))
}

export default shouldShowBanner

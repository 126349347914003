{
  "Email Builder": "Email Builder",
  "New Builder": "New Builder",
  "Beta Version": "Beta Version",
  "Classic Builder": "Classic Builder",
  "Text only": "Text only",
  "HTML Builder": "HTML Builder",
  "Can't be blank": "Can't be blank",
  "campaign": "Campaign",
  "email": "Email",
  "rule": "Automation",
  "event_message": "Event Message",
  "event_automation": "Event Automation",
  "event_reminder": "Event Reminder",
  "application_applicant_notification": "Application Applicant Notification",
  "workflow_stat": "Live Automation",
  "Type": "Type",
  "Description": "Description",
  "Place": "Place",
  "Places": "Places",
  "From:": "From: {{from}}",
  "To:": "To: {{to}}",
  "Running for": "running for {{description}} - some profiles {{profiles}}",
  "planned": "Planned",
  "draft": "Draft",
  "Send test email": "Send test email",
  "Save and exit": "Save and exit",
  "Save": "Save",
  "Cancel": "Cancel",
  "Subject:": "Subject:",
  "Email Template:": "Email Template:",
  "Test email sent": "Test email sent",
  "Error sending test email, please try again": "Error sending test email, please try again",
  "Saving": "Saving",
  "Changes saved": "Changes saved",
  "Email Template": "Email Template",
  "Copy & Edit": "Copy & Edit",
  "Select a template": "Select a template",
  "Shared template": "Shared template",
  "Use shared template": "Use shared template",
  "Preview": "Preview",
  "All": "All",
  "New email from scratch": "New email from scratch",
  "No email": "No email",
  "Build email": "Build email",
  "Subject replacement": "Subject replacement",
  "Keep current subject": "Keep current subject",
  "Replace subject": "Replace subject",
  "The title already has a defined subject": "The {{title}} already has a defined subject",
  "Do you want to replace it with the subject from the Email template?": "Do you want to replace it with the subject from the Email template?",
  "Current subject": "Current subject",
  "Template subject": "Template subject",
  "Edit email": "Edit email"
}

import React from 'react'

import PropTypes from 'prop-types'

import Footer from './Footer'
import Header from './Header'

import styles from './styles.module.scss'

const AuthContentLayout = ({ children, className }) => (
  <div className={`${styles.container} ${className}`}>
    <Header />
    {children}
  </div>
)

const Body = ({ children }) => <div className={styles.body}>{children}</div>

AuthContentLayout.Body = Body
AuthContentLayout.Footer = Footer

AuthContentLayout.defaultProps = {
  className: ''
}

AuthContentLayout.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
}

export default AuthContentLayout

import React from 'react'
import { Trans } from 'react-i18next'

import { Text } from '@fullfabric/alma-mater'

import styles from './styles.module.scss'

const FormSubmissionSuccessMessage = ({ inCard, successMessage }) => (
  <div className={inCard ? styles.paddedContainer : ''}>
    <Text
      tag='h2'
      type={inCard ? 'h3' : 'h2'}
      fontColor='text-base-darkest'
      marginBottom='tiny'
      marginTop={inCard ? 'less' : 'big'}
    >
      <Trans>Thank you for filling in the form.</Trans>
    </Text>
    {successMessage ? (
      <Text type='h5' fontColor='text-base-darker' className={styles.message}>
        {successMessage}
      </Text>
    ) : null}
  </div>
)

export default FormSubmissionSuccessMessage

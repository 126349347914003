import React from 'react'
import { Trans } from 'react-i18next'
import { useQuery } from 'react-query'

import getStartedApplicsTimeseries from 'apps/Reports/api/getStartedApplicsTimeseries'
import { useFilter } from '../FilterContext'
import WeeklyApplicsBarChart from './WeeklyApplicsBarChart'

const WeeklyStartedApplicationsChart = () => {
  const filter = useFilter()
  const { data, isLoading } = useQuery(
    ['getStartedApplicsTimeseries', filter],
    () => getStartedApplicsTimeseries(filter)
  )

  return (
    <WeeklyApplicsBarChart
      data={data}
      dataKey='starts'
      isLoading={isLoading}
      title={<Trans>Started applications</Trans>}
    />
  )
}

export default WeeklyStartedApplicationsChart

import React, { useEffect } from 'react'

export default function CustomBarLabel({ updateBarEdge, ...props }) {
  const { x, y, width, height, value, index: barIndex } = props

  useEffect(() => {
    updateBarEdge(barIndex, { lx: x, rx: x + width, y })
  }, [barIndex, updateBarEdge, width, x, y])

  const placeInsideBar = height > 25

  return (
    <text
      x={x + width / 2}
      y={placeInsideBar ? y + 14 : y - 14}
      textAnchor='middle'
      dominantBaseline='middle'
      fill={
        placeInsideBar
          ? 'var(--ff-color-text-base-lightest)'
          : 'var(--ff-color-text-info)'
      }
      fontSize={12}
      fontWeight='600'
    >
      {value}
    </text>
  )
}

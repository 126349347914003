import React from 'react'

import { Text, useScreenClass } from '@fullfabric/alma-mater'

import Linkable from '../../linkable'

import classNames from 'classnames'
import styles from './styles.module.scss'

const Link = ({ first, last, label, ...linkableProps }) => {
  const screenClass = useScreenClass()
  return (
    <Linkable {...linkableProps}>
      <Text
        type='f5'
        fontColor='text-base-darkest'
        className={classNames([
          first && styles.first,
          last && styles.last,
          screenClass === 'md' && styles.tabletNav,
          styles.linkText
        ])}
      >
        {label}
      </Text>
    </Linkable>
  )
}

export default Link

import React from 'react'
import { Trans } from 'react-i18next'

import { Text } from '@fullfabric/alma-mater'

import FullScreenError from 'shared/components/FullScreenError'
import FullScreenLoading from 'shared/components/FullScreenLoading'

import cx from 'classnames'
import styles from './styles.module.scss'

const ViewerContainer = ({
  viewOption,
  uploadIndex = 0,
  scale = 1,
  children,
  loading,
  error,
  style = {}
}) => {
  return (
    <div className={styles.container}>
      {loading ? <FullScreenLoading /> : null}
      {error ? (
        <FullScreenError
          className={styles.errorMessage}
          errorMessage={<Trans>Could not load the file.</Trans>}
        />
      ) : null}

      <div
        className={cx(styles.wrapper, (loading || error) && styles.hide)}
        style={{ transform: `translate(-50%, -50%) scale(${scale})`, ...style }}
      >
        {children}
        <Text fontColor='text-base-darkest' marginTop='tiny'>
          {viewOption?.uploads[uploadIndex]?.filename}
        </Text>
      </div>
    </div>
  )
}

export default ViewerContainer

import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Field } from '@fullfabric/alma-mater'

import { useAppData } from 'shared/contexts/AppData'

import getLocalizedPolicyHtml from 'apps/Authentication/utils/getLocalizedPolicyHtml'

const PrivacyPolicyField = ({ ...fieldProps }) => {
  const { t } = useTranslation()
  const { privacy_policy: privacyPolicy } = useAppData()

  if (!privacyPolicy) {
    return null
  }

  return (
    <Field
      id='accept-checkbox'
      type='checkbox'
      label={
        privacyPolicy.name === 'Privacy Policy'
          ? t('Privacy Policy')
          : privacyPolicy.name
      }
      help={
        <div
          dangerouslySetInnerHTML={{
            __html: getLocalizedPolicyHtml(privacyPolicy, window.locale)
          }}
        />
      }
      {...fieldProps}
      inputOptions={[
        {
          label: <Trans>I accept the privacy policy</Trans>,
          value: privacyPolicy.id
        }
      ]}
    />
  )
}

export default PrivacyPolicyField

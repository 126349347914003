import React from 'react'

import { Row } from '@fullfabric/alma-mater'

import { useMergeData } from 'apps/MergeProfiles/contexts/MergeData'
import ApplicSummaryCard from './ApplicSummaryCard'

import styles from './styles.module.scss'

const ApplicSummaryRow = ({
  applicTemplateId,
  sourceProfileApplics,
  targetProfileApplics
}) => {
  const {
    mergeData: { applicsFrom, sourceProfile, targetProfile }
  } = useMergeData()

  const targetProfileApplic = targetProfileApplics.find(
    (role) => role.template_id === applicTemplateId
  )
  const sourceProfileApplic = sourceProfileApplics.find(
    (role) => role.template_id === applicTemplateId
  )

  const shoudUseDuplicateProfileApplic =
    applicsFrom[applicTemplateId] === 'duplicate'

  const [keptApplic, keptFromProfile] = shoudUseDuplicateProfileApplic
    ? [sourceProfileApplic, sourceProfile]
    : [targetProfileApplic, targetProfile]

  const [discardedApplic, discardedFromProfile] = shoudUseDuplicateProfileApplic
    ? [targetProfileApplic, targetProfile]
    : [null, null]

  if (!keptApplic) return null

  return (
    <Row
      role='row'
      aria-label='application summary row'
      nogutter
      className={styles.applicSummaryRow}
    >
      <ApplicSummaryCard
        applic={keptApplic}
        profile={keptFromProfile}
        type={shoudUseDuplicateProfileApplic ? 'added' : null}
        halfWidth={!!discardedApplic}
      />

      {discardedApplic && (
        <ApplicSummaryCard
          applic={discardedApplic}
          profile={discardedFromProfile}
          type='discarded'
          halfWidth
        />
      )}
    </Row>
  )
}

export default ApplicSummaryRow

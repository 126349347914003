import getSideEffectsData from 'apps/Courses/utils/getSideEffectsData'
import actionTypes from '../types'

export default function getDataToUpdate(actionType, state) {
  const { data: coursePersistedData } = state
  const modifiedSectionData = getModifiedSectionData(actionType, state)
  const sideEffectsData = getSideEffectsData({
    existingData: coursePersistedData,
    updatedData: modifiedSectionData
  })

  return {
    id: coursePersistedData?.id,
    ...modifiedSectionData,
    ...sideEffectsData
  }
}

export function getModifiedSectionData(actionType, state) {
  const {
    basicSection,
    datesSection,
    statusSection,
    academicDetailsSection,
    capacitySection,
    schemaSection
  } = state

  switch (actionType) {
    case actionTypes.updateCourseBasicSection: {
      const { subject, ...data } = basicSection.data
      return data
    }
    case actionTypes.updateCourseDatesSection:
      return datesSection.data
    case actionTypes.updateCourseStatusSection:
      return statusSection.data
    case actionTypes.updateCourseAcademicDetailsSection:
      return academicDetailsSection.data
    case actionTypes.updateCourseCapacitySection:
      return capacitySection.data
    case actionTypes.updateCourseSchemaSection:
      return schemaSection.data
    default:
      return {}
  }
}

{
  "Add subject": "Add subject",
  "Add Subject": "Add Subject",
  "Back to Study plan": "Back to Study plan",
  "Not defined": "Not defined",
  "Categories": "Categories",
  "Edit": "Edit",
  "credit": "credit",
  "credits": "credits",
  "No category defined": "No category defined",
  "Can't be blank": "Can't be blank",
  "Must be a number": "Must be a number",
  "Name": "Name",
  "Credits": "Credits",
  "Add another": "Add another",
  "Save changes": "Save changes",
  "Cancel": "Cancel",
  "Study plan subject categories": "Study plan subject categories",
  "Define the study plan subject categories. Each study plan subject can be assigned to one category.": "Define the study plan subject categories. Each study plan subject can be assigned to one category.",
  "All courses": "All courses",
  "Required number of credits": "Required number of credits",
  "Define the required number of credits for completion of the programme.": "Define the required number of credits for completion of the programme.",
  "Degree classes": "Degree classes",
  "No degree class defined": "No degree class defined",
  "Degree class": "Degree class",
  "Min": "Min",
  "Max": "Max",
  "Define the degree classes that are awarded to students based on their transcript grade. The degree class is displayed on student transcripts and is automatically set based on the transcript grade average or the transcript grade percentage.": "Define the degree classes that are awarded to students based on their transcript grade. The degree class is displayed on student transcripts and is automatically set based on the transcript grade average or the transcript grade percentage.",
  "Are you ABSOLUTELY sure?": "Are you ABSOLUTELY sure?",
  "This action CANNOT be undone.": "This action CANNOT be undone.",
  "This action will:": "This action will:",
  "Remove the study plan subject from student transcripts where the student has not registered to the subject": "Remove the study plan subject from student transcripts where the student has not registered to the subject",
  "It is not possible to remove the course due to existing registrations.": "It is not possible to remove the course due to existing registrations.",
  "Delete study plan subject": "Delete study plan subject",
  "Close": "Close",
  "Delete elective group": "Delete elective group",
  "Delete the study plan subject from student transcripts where the student has not registered to the subject": "Delete the study plan subject from student transcripts where the student has not registered to the subject",
  "This action will NOT:": "This action will NOT:",
  "Remove the study plan subject from student transcripts where the student has registered to the subject": "Remove the study plan subject from student transcripts where the student has registered to the subject",
  "It is not possible to remove the elective group since it contains courses with registrations.": "It is not possible to remove the elective group since it contains courses with registrations.",
  "Subject": "Subject",
  "Hours": "Hours",
  "Max number of absences": "Max number of absences",
  "Counts toward final grade?": "Counts toward final grade?",
  "Counts toward final credits awarded?": "Counts toward final credits awarded?",
  "Success.": "Success.",
  "added.": "added.",
  "Delete Subject": "Delete Subject",
  "Apply changes": "Apply changes",
  "Update study plan subject": "Update study plan subject",
  "Would you like to apply these changes to all student transcripts?": "Would you like to apply these changes to all student transcripts?",
  "No, do not update existing student transcripts": "No, do not update existing student transcripts",
  "Yes, update all student transcripts": "Yes, update all student transcripts",
  "Must be greater than 0": "Must be greater than 0",
  "Minimum must be less than maximum": "Minimum must be less than maximum",
  "Maximum must be greater than minimum": "Maximum must be greater than minimum",
  "Elective group settings": "Elective group settings",
  "Define if there are any restrictions for the number of elective subjects that students can select.": "Define if there are any restrictions for the number of elective subjects that students can select.",
  "Define a minimum number of subjects": "Define a minimum number of subjects",
  "Students must select at least the minimum number of subjects.": "Students must select at least the minimum number of subjects.",
  "Define a maximum number of subjects": "Define a maximum number of subjects",
  "Students cannot select more than the maximum number of subjects.": "Students cannot select more than the maximum number of subjects.",
  "Category": "Category",
  "- Select -": "- Select -",
  "Path name": "Path name",
  "Course type": "Course type",
  "Courses": "Courses",
  "Select an existing subject": "Select an existing subject",
  "Create new subject": "Create new subject",
  "Add a subject code": "Add a subject code",
  "Add a subject name": "Add a subject name",
  "Transcript grade display": "Transcript grade display",
  "Grade percentage": "Grade percentage",
  "Grade average": "Grade average",
  "Max transcript grade": "Max transcript grade",
  "decimal cases": "decimal cases",
  "Final grade display": "Final grade display",
  "Maximum transcript grade": "Maximum transcript grade",
  "Maximum transcript grade decimal places": "Maximum transcript grade decimal places",
  "Transcript grade": "Transcript grade",
  "Define how the transcript grade is displayed to staff and students.": "Define how the transcript grade is displayed to staff and students.",
  "Delete multiple paths": "Delete multiple paths",
  "Yes, delete multiple paths": "Yes, delete multiple paths",
  "Delete all paths and their courses apart from the first path. The remaining study plan will only contain the courses in the first path.": "Delete all paths and their courses apart from the first path. The remaining study plan will only contain the courses in the first path.",
  "Path": "Path",
  "Multiple paths": "Multiple paths",
  "Single path": "Single path",
  "Name already exists": "Name already exists",
  "This path cannot be deleted because there are student transcripts for the path.": "This path cannot be deleted because there are student transcripts for the path.",
  "Single path cannot be selected because there are paths that have student transcripts.": "Single path cannot be selected because there are paths that have student transcripts.",
  "Paths": "Paths",
  "Define the type of path and their respective names.": "Define the type of path and their respective names.",
  "Rename elective group": "Rename elective group",
  "Elective group name": "Elective group name",
  "Yes, discard changes": "Yes, discard changes",
  "Discard unsaved changes?": "Discard unsaved changes?",
  "There are unsaved changes.": "There are unsaved changes.",
  "Would you like to discard your changes?": "Would you like to discard your changes?",
  "Settings": "Settings",
  "Basic": "Basic",
  "Define the details of the study plan subject that will appear on student transcripts.": "Define the details of the study plan subject that will appear on student transcripts.",
  "Final grade average & total credits awarded": "Final grade average & total credits awarded",
  "Define whether this subject contributes towards the final grade average and the total credits awarded on students' transcripts.": "Define whether this subject contributes towards the final grade average and the total credits awarded on students' transcripts.",
  "The subject must have a course type for at least one path.": "The subject must have a course type for at least one path.",
  "Error found.": "Error found.",
  "Paths & course types": "Paths & course types",
  "Define which paths the subject will appear in and select the course type of the subject for each of those paths.": "Define which paths the subject will appear in and select the course type of the subject for each of those paths.",
  "Core Subjects": "Core Subjects",
  "None": "None",
  "Elective Subjects - <2></2>": "Elective Subjects - <2></2>",
  "Elective Subjects": "Elective Subjects",
  "Required number of subjects: Minimum = , Maximum = ": "Required number of subjects: Minimum = , Maximum = ",
  "Students can choose which elective subjects to study. <2><0>Required number of subjects: Minimum = , Maximum = </0></2>": "Students can choose which elective subjects to study. <2><0>Required number of subjects: Minimum = , Maximum = </0></2>",
  "Add elective group": "Add elective group",
  "Extracurricular Subjects": "Extracurricular Subjects",
  "Study Plan": "Study Plan",
  "Add study plan subject": "Add study plan subject",
  "Paths:": "Paths:",
  "No subjects added": "No subjects added",
  "Yes if it's a pass, No if grade is a fail": "Yes if it's a pass, No if grade is a fail",
  "Yes": "Yes",
  "No": "No",
  "Core": "Core",
  "Elective": "Elective",
  "Extra curricular": "Extra curricular",
  "Elective - ": "Elective - ",
  "Changes saved": "Changes saved",
  "Elective subjects - {{groupName}}": "Elective subjects - {{groupName}}",
  "Elective subjects": "Elective subjects",
  "Prerequisites": "Prerequisites",
  "Elective subjects - ": "Elective subjects - ",
  "Applicants cannot select the subject until they fulfil its prerequisites.": "Applicants cannot select the subject until they fulfil its prerequisites.",
  "Required number of subjects: Minimum = {{min}}, Maximum = {{max}}": "Required number of subjects: Minimum = {{min}}, Maximum = {{max}}",
  "Students can choose which elective subjects to study.": "Students can choose which elective subjects to study."
}

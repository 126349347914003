import React from 'react'
import { Trans } from 'react-i18next'

import { Loading, Text } from '@fullfabric/alma-mater'

import roundNumber from 'shared/utils/roundNumber'

import { useImportDocument } from 'apps/Imports/contexts/import-document'
import {
  useCurrentStep,
  useImportSteps,
  useStepsCompleted
} from 'apps/Imports/contexts/steps'

import styles from './styles.module.scss'

const ImportLoading = ({ lastStep }) => {
  const { setStepsCompleted, stepsCompleted } = useStepsCompleted()
  const importSteps = useImportSteps()
  const importDocument = useImportDocument()

  useCurrentStep(lastStep)

  React.useEffect(() => {
    if (!importDocument) return

    const reportStepIndex = importSteps.findIndex(
      (step) => step.id === lastStep
    )
    const previousSteps = importSteps.slice(0, reportStepIndex)
    const previousStepsIds = previousSteps.map((step) => step.id)

    if (
      stepsCompleted.length !== previousStepsIds.length ||
      !previousStepsIds.every((stepId) => stepsCompleted.includes(stepId))
    )
      setStepsCompleted(previousStepsIds)
  }, [importSteps, setStepsCompleted, stepsCompleted, importDocument, lastStep])

  const progressPercentage =
    importDocument &&
    importDocument.imported_rows > 0 &&
    importDocument.imported_rows !== importDocument.number_of_rows &&
    importDocument.number_of_rows !== 0
      ? roundNumber(
          (importDocument.imported_rows / importDocument.number_of_rows) * 100,
          1
        )
      : null

  return (
    <>
      <Loading type='triple' className={styles.loading} />

      <Text
        type='f3'
        fontColor='text-base-darkest'
        className={styles.centeredText}
        marginTop='more'
      >
        <Trans>Import is in progress</Trans>
        {progressPercentage ? ` (${progressPercentage}%)` : ''}
      </Text>

      <Text className={styles.centeredText} marginTop='default'>
        <Trans>
          The import report will be displayed here once the import has finished.
        </Trans>
      </Text>

      <Text className={styles.centeredText} marginTop='default'>
        <Trans>
          It should take a few minutes to import a file with several columns and
          a few hundred records.
        </Trans>
      </Text>

      <Text
        className={styles.centeredText}
        marginTop='default'
        marginBottom='huge'
      >
        <Trans>
          Otherwise, you can safely close this page, as you will receive an
          email which has a link to the import report once the import has
          finished.
        </Trans>
      </Text>
    </>
  )
}

export default ImportLoading

import React from 'react'

import SummaryField from '../summary-field'
import SummarySection from '../summary-section'

const SimpleSummarySection = ({ fieldsData, ...props }) =>
  fieldsData ? (
    <SummarySection {...props}>
      {fieldsData.map((data, i) => (
        <SummaryField
          key={`field-${i}`}
          last={i === fieldsData.length - 1}
          {...data}
        />
      ))}
    </SummarySection>
  ) : null

export default SimpleSummarySection

import React from 'react'
import { Trans } from 'react-i18next'

import { Text } from '@fullfabric/alma-mater'
import { useCurrentUser } from '@fullfabric/authorization-officer'

import { relativeTimestamp, roughlySameTime } from 'apps/Inbox/utils/cardUtils'
import InboxCardProfileTooltip from '../InboxCardProfileTooltip'
import InboxCardSubject from '../InboxCardSubject'

import styles from './styles.module.scss'

const InboxEmailCardDescriptor = ({
  status,
  statusUpdatedBy,
  actor,
  toEmails,
  ccEmails,
  bccEmails,
  repliedBy,
  repliedAt,
  updatedAt
}) => {
  const currentUser = useCurrentUser()
  if (status === 'archived' && (repliedBy || statusUpdatedBy)) {
    let tooltipData = {}

    if (
      !statusUpdatedBy ||
      (repliedBy && roughlySameTime(repliedAt, updatedAt))
    ) {
      tooltipData = {
        actor: repliedBy,
        text: 'replied to this email.'
      }
    } else {
      tooltipData = {
        actor: statusUpdatedBy,
        text: 'archived this card.'
      }
    }

    return (
      <div className={styles.cardDescriptor}>
        <InboxCardProfileTooltip
          actor={tooltipData.actor}
        ></InboxCardProfileTooltip>{' '}
        <Text
          type='f5'
          tag='span'
          fontColor='text-base-darkest'
          marginTop='small'
        >
          <Trans>{tooltipData.text}</Trans>
        </Text>
        <Text tag='span' marginLeft='tiny' fontColor='text-base'>
          {relativeTimestamp(updatedAt)}
        </Text>
      </div>
    )
  }

  const toCcAndBccEmails = [
    ...new Set((toEmails || []).concat(ccEmails || []).concat(bccEmails || []))
  ]
  const currentUserInTheEmailChain =
    currentUser.id !== actor.id &&
    toCcAndBccEmails.map((recipient) => recipient.id).includes(currentUser.id)

  return (
    <div className={styles.cardDescriptor}>
      <InboxCardSubject actor={actor}></InboxCardSubject>
      <Text
        type='f5'
        tag='span'
        fontColor='text-base-darkest'
        marginTop='small'
      >
        <Trans>sent an email to</Trans>{' '}
      </Text>
      {currentUserInTheEmailChain ? (
        <Text
          type='f5'
          tag='span'
          fontColor='text-base-darkest'
          marginTop='small'
        >
          <Trans>you.</Trans>
        </Text>
      ) : (
        <>
          {toCcAndBccEmails.map((recipient, index) => (
            <span key={index}>
              <InboxCardProfileTooltip
                actor={recipient}
              ></InboxCardProfileTooltip>
              {recipient === toCcAndBccEmails[toCcAndBccEmails.length - 1]
                ? '.'
                : ', '}
            </span>
          ))}
        </>
      )}
      <Text tag='span' marginLeft='tiny' fontColor='text-base'>
        {relativeTimestamp(updatedAt)}
      </Text>
    </div>
  )
}

export default InboxEmailCardDescriptor

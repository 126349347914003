/* eslint-disable no-case-declarations */

import getRequiredImportFields from 'apps/Imports/utils/get-required-import-fields'
import { PRODUCT_IMPORT_STEPS } from 'apps/Imports/utils/product-import/constants'

export const isStepCompleted = (step, data, opts = {}) => {
  const context = opts.context || {}

  switch (step) {
    case PRODUCT_IMPORT_STEPS.FILE_UPLOAD:
      return data.file && data.separator

    case PRODUCT_IMPORT_STEPS.MAPPING:
      const { requiredFieldsToImport } = getRequiredImportFields(
        opts?.importDocument || {},
        context,
        data
      )
      const mappingsForRequiredFields = data.mappings.filter((mapping) =>
        requiredFieldsToImport.includes(mapping.field)
      )

      return mappingsForRequiredFields.length === requiredFieldsToImport.length

    case PRODUCT_IMPORT_STEPS.SUMMARY:
      return true

    default:
      return false
  }
}

export const areStepsCompleted = (steps = [], data, opts = {}) => {
  return steps.every((step) => isStepCompleted(step, data, opts))
}

import React, { useEffect, useLayoutEffect, useRef } from 'react'
import { Trans } from 'react-i18next'

import { Text, Visible } from '@fullfabric/alma-mater'

import Navigation from 'apps/ContentPages/components/navigation'
import useIsMobileLayout from 'apps/ContentPages/hooks/use-is-mobile-layout'
import CloseIcon from './close-icon'

import classNames from 'classnames'
import styles from './styles.module.scss'

const Menu = ({ opened, handleClose }) => {
  const menuRef = useRef()
  const isMobileLayout = useIsMobileLayout()
  const isDesktopLayout = !isMobileLayout

  useEffect(() => {
    const handleClickOutsideMenu = (evt) => {
      if (menuRef && menuRef.current && !menuRef.current.contains(evt.target)) {
        handleClose(evt)
      }
    }

    document.addEventListener('mouseup', handleClickOutsideMenu)
    return () => {
      document.removeEventListener('mouseup', handleClickOutsideMenu)
    }
  }, [handleClose])

  useLayoutEffect(() => {
    if (opened) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
  }, [opened])

  return (
    <>
      <Visible md>
        <div
          className={classNames([styles.shadow, opened && styles.opened])}
          onClick={handleClose}
          role='presentation'
        />
      </Visible>
      <div
        className={classNames([
          styles.container,
          opened && styles.opened,
          isDesktopLayout && styles.desktop
        ])}
        data-testid='mobile-navigation-menu'
        ref={menuRef}
      >
        <div className={styles.header}>
          <Text type='f3' fontColor='text-base-darkest'>
            <Trans>Main Menu</Trans>
          </Text>

          <button
            onClick={handleClose}
            className={styles.closeButton}
            name={<Trans>Close navigation</Trans>}
          >
            <CloseIcon title={<Trans>Close</Trans>} />
          </button>
        </div>

        <div className={styles.navigationContainer}>
          <Navigation />
        </div>
      </div>
    </>
  )
}

export default Menu

import React from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { useParams } from 'react-router'

import debounce from 'lodash.debounce'

import { AutocompleteInput, DropdownInput } from '@fullfabric/alma-mater'

import searchProfiles from 'apps/MergeProfiles/api/searchProfiles'

const getProfileAsOption = (p) => ({
  value: p.id,
  label: `${p.full_name} (${p.email})`
})

const ProfileSearchInput = ({ id, selectedProfile, onChange }) => {
  const { id: targetProfileId } = useParams()
  const { t } = useTranslation()
  const [searchInputValue, setSearchInputValue] = React.useState()
  const [search, setSearch] = React.useState()

  React.useEffect(() => {
    let isMounted = true
    const setSearchDebounced = debounce((q) => isMounted && setSearch(q), 500)
    setSearchDebounced(searchInputValue)

    return () => {
      isMounted = false
    }
  }, [searchInputValue, setSearch])

  const { data: profileList, isLoading: searchLoading } = useQuery(
    ['searchProfiles', search],
    () => searchProfiles(search)
  )

  const foundProfiles = React.useMemo(
    () =>
      (profileList || [])
        .filter((p) => p.id !== selectedProfile?.id && p.id !== targetProfileId)
        .concat(selectedProfile ? [selectedProfile] : []),
    [profileList, selectedProfile, targetProfileId]
  )

  const onProfileSelected = React.useCallback(
    (profileId) => {
      const profile = foundProfiles.filter((p) => p.id === profileId)[0]
      onChange(profile)
    },
    [foundProfiles, onChange]
  )

  const options = React.useMemo(
    () => (foundProfiles || []).map(getProfileAsOption),
    [foundProfiles]
  )

  return (
    <AutocompleteInput
      id={id}
      aria-label='search duplicate profile'
      value={selectedProfile?.id}
      options={options}
      noOptionsMessage={({ inputValue }) =>
        inputValue && !searchLoading ? t('No results') : null
      }
      placeholder={t('Search here')}
      isLoading={searchLoading}
      inputValue={searchInputValue}
      onInputChange={setSearchInputValue}
      onChange={onProfileSelected}
      components={{ NoOptionsMessage: DropdownInput.NoOptionsMessage }}
    />
  )
}

export default ProfileSearchInput

export const getProductStateOptions = (t) => [
  {
    value: 'init',
    label: t('Class new')
  },
  {
    value: 'open',
    label: t('Applications open')
  },
  {
    value: 'closed',
    label: t('Applications closed')
  },
  {
    value: 'in_progress',
    label: t('Class in progress')
  },
  {
    value: 'done',
    label: t('Class finished')
  }
]

export const getCapacityOptions = (t) => [
  {
    value: 'lt_min',
    label: t('Less than the minimum')
  },
  {
    value: 'gte_min',
    label: t('More than or equal to the minimum')
  },
  {
    value: 'lt_target',
    label: t('Less than the target')
  },
  {
    value: 'gte_target',
    label: t('More than or equal to the target')
  },
  {
    value: 'lt_max',
    label: t('Less than the maximum')
  },
  {
    value: 'gte_max',
    label: t('More than or equal to the maximum')
  }
]

export const getPeriodOptions = (t) => [
  {
    value: 'upcoming_6_months',
    label: t('Upcoming 6 months')
  },
  {
    value: 'upcoming_3_months',
    label: t('Upcoming 3 months')
  },
  {
    value: 'upcoming_month',
    label: t('Upcoming month')
  },
  {
    value: 'this_month',
    label: t('This month')
  },
  {
    value: 'this_week',
    label: t('This week')
  },
  {
    value: 'previous_month',
    label: t('Previous month')
  },
  {
    value: 'previous_3_months',
    label: t('Previous 3 months')
  },
  {
    value: 'previous_6_months',
    label: t('Previous 6 months')
  }
]

import React, { useState } from 'react'
import { Trans } from 'react-i18next'

import PropTypes from 'prop-types'

import { Button, Text } from '@fullfabric/alma-mater'

import classNames from 'classnames'
import styles from './styles.module.scss'

const icon = (
  <svg
    width='24'
    height='18'
    viewBox='0 0 24 18'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M23.4997 4.50384L12.2973 0.0564689C12.1076 -0.018823 11.8964 -0.018823 11.7067 0.0564689L0.504286 4.50384C0.194141 4.62728 -0.00679462 4.93028 0.000175737 5.26401V11.5054C0.000175737 12.1683 0.537552 12.7056 1.20044 12.7056C1.86333 12.7056 2.4007 12.1683 2.4007 11.5054V7.79257C2.40103 7.53318 2.52708 7.29005 2.73885 7.14028C2.95063 6.99051 3.22186 6.95268 3.46654 7.0388L11.7307 9.95624C11.8158 9.98766 11.9058 10.0034 11.9964 10.0027C12.087 10.0031 12.177 9.98739 12.2621 9.95624L23.4645 6.00337C23.78 5.8928 23.9934 5.59781 23.9999 5.26362C24.0063 4.92942 23.8043 4.62645 23.4933 4.50384H23.4997Z'
      fill='#3B4755'
    />
    <path
      d='M18.1319 9.58177L12.2619 11.6622C12.0894 11.7231 11.9014 11.7231 11.7289 11.6622L5.86686 9.58177C5.62192 9.49556 5.35038 9.53357 5.13852 9.68371C4.92667 9.83386 4.80084 10.0775 4.80103 10.3371V14.338C4.80146 14.6772 5.01564 14.9792 5.33554 15.0918L10.1366 16.7881C11.3427 17.2127 12.6577 17.2127 13.8638 16.7881L18.6649 15.0918C18.9848 14.9792 19.1989 14.6772 19.1994 14.338V10.3371C19.1996 10.0775 19.0737 9.83386 18.8619 9.68371C18.65 9.53357 18.3785 9.49556 18.1335 9.58177H18.1319Z'
      fill='#3B4755'
    />
  </svg>
)

const iconHover = (
  <svg
    width='24'
    height='18'
    viewBox='0 0 24 18'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M23.4997 4.50384L12.2973 0.0564689C12.1076 -0.018823 11.8964 -0.018823 11.7067 0.0564689L0.504286 4.50384C0.194141 4.62728 -0.00679462 4.93028 0.000175737 5.26401V11.5054C0.000175737 12.1683 0.537552 12.7056 1.20044 12.7056C1.86333 12.7056 2.4007 12.1683 2.4007 11.5054V7.79257C2.40103 7.53318 2.52708 7.29005 2.73885 7.14028C2.95063 6.99051 3.22186 6.95268 3.46654 7.0388L11.7307 9.95624C11.8158 9.98766 11.9058 10.0034 11.9964 10.0027C12.087 10.0031 12.177 9.98739 12.2621 9.95624L23.4645 6.00337C23.78 5.8928 23.9934 5.59781 23.9999 5.26362C24.0063 4.92942 23.8043 4.62645 23.4933 4.50384H23.4997Z'
      fill='white'
    />
    <path
      d='M18.1319 9.58177L12.2619 11.6622C12.0894 11.7231 11.9014 11.7231 11.7289 11.6622L5.86686 9.58177C5.62192 9.49556 5.35038 9.53357 5.13852 9.68371C4.92667 9.83386 4.80084 10.0775 4.80103 10.3371V14.338C4.80146 14.6772 5.01564 14.9792 5.33554 15.0918L10.1366 16.7881C11.3427 17.2127 12.6577 17.2127 13.8638 16.7881L18.6649 15.0918C18.9848 14.9792 19.1989 14.6772 19.1994 14.338V10.3371C19.1996 10.0775 19.0737 9.83386 18.8619 9.68371C18.65 9.53357 18.3785 9.49556 18.1335 9.58177H18.1319Z'
      fill='white'
    />
  </svg>
)

const SamlButton = ({ className, ...props }) => {
  const [hover, setHover] = useState(false)
  return (
    <Button
      type='outline'
      className={classNames(
        styles.thirdPartyLoginButton,
        styles['saml-button'],
        className
      )}
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      {...props}
    >
      <div className={styles.buttonContent}>
        <div className={styles.iconAndText}>
          {hover ? iconHover : icon}
          <Text type='h5' tag='span'>
            <Trans>Institutional login</Trans>
          </Text>
        </div>
        <svg
          width='12'
          height='12'
          viewBox='0 0 12 12'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M12 5.98163C11.9991 5.63731 11.8508 5.30981 11.5926 5.08174L5.99725 0.162862C5.69683 -0.0827796 5.25559 -0.0465989 4.9993 0.244692C4.74301 0.535982 4.76377 0.977699 5.04626 1.24373L9.36926 5.04325C9.40807 5.07764 9.4216 5.1324 9.40328 5.18088C9.38495 5.22937 9.33856 5.26153 9.28667 5.26172H0.720751C0.322691 5.26172 0 5.58403 0 5.98163C0 6.37922 0.322691 6.70154 0.720751 6.70154H9.28517C9.33703 6.70204 9.38325 6.73431 9.40153 6.78278C9.41981 6.83125 9.40639 6.88596 9.36776 6.92051L5.04475 10.72C4.83885 10.8862 4.74066 11.1516 4.78901 11.4116C4.83736 11.6715 5.02445 11.8841 5.27636 11.9652C5.52826 12.0464 5.80444 11.9831 5.99575 11.8004L11.5921 6.88152C11.8506 6.65361 11.9991 6.32605 12 5.98163Z'
            fill='#C0CCDA'
          />
        </svg>
      </div>
    </Button>
  )
}

SamlButton.propTypes = {
  className: PropTypes.string
}

export default SamlButton

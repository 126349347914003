import React from 'react'
import { Trans } from 'react-i18next'
import { useQuery } from 'react-query'
import { useHistory, useParams } from 'react-router'

import { SuccessPlainBigIcon, Text } from '@fullfabric/alma-mater'

import getImport from 'apps/Imports/api/get-import'
import ImportReportStep from 'apps/Imports/components/import-report-step'
import PageContainer from 'apps/Imports/components/page-container'
import { ProductImportStepsProvider } from 'apps/Imports/components/product-import/steps-provider'
import { ProfileImportStepsProvider } from 'apps/Imports/components/profile-import/steps-provider'
import SidebarTitle from 'apps/Imports/components/sidebar-title'
import { ImportDocumentProvider } from 'apps/Imports/contexts/import-document'
import { IMPORT_TYPES } from 'apps/Imports/utils/constants'
import { PRODUCT_IMPORT_STEPS } from 'apps/Imports/utils/product-import/constants'
import { PROFILE_IMPORT_STEPS } from 'apps/Imports/utils/profile-import/constants'

import styles from './styles.module.scss'

const ImportReportPage = () => {
  const { documentId } = useParams()
  const history = useHistory()
  const { data: importData, isLoading } = useQuery(
    ['getImport', documentId],
    async () => await getImport(documentId)
  )

  React.useEffect(() => {
    if (!isLoading && importData?.document.state !== 'imported') {
      history.push(`/import/${documentId}/loading`)
    }
  }, [isLoading, importData, documentId, history])

  const importType = importData?.document?._ruby_type
  const isProfileImport = importType === IMPORT_TYPES.PROFILE
  const StepsProvider = isProfileImport
    ? ProfileImportStepsProvider
    : ProductImportStepsProvider

  const lastStep = isProfileImport
    ? PROFILE_IMPORT_STEPS.REPORT
    : PRODUCT_IMPORT_STEPS.REPORT

  if (!importData?.document) return null

  return (
    <StepsProvider importType={importType}>
      <ImportDocumentProvider initialDocument={importData?.document}>
        <PageContainer contentColumns={9} sidebarTitle={<SidebarTitle />}>
          <Text
            fontColor='text-base-darkest'
            type='h3'
            marginBottom='more'
            marginLeft='default'
          >
            <Trans>Report:</Trans>
            <Text tag='span' fontColor='green-600' type='h3'>
              &nbsp;Import completed&nbsp;
              <SuccessPlainBigIcon
                color='green-600'
                className={styles.titleIcon}
              />
            </Text>
          </Text>
          {!isLoading && importData ? (
            <ImportReportStep lastStep={lastStep} />
          ) : null}
        </PageContainer>
      </ImportDocumentProvider>
    </StepsProvider>
  )
}

export default ImportReportPage

import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Text } from '@fullfabric/alma-mater'

import { useLocale } from 'shared/contexts/Locale'

import {
  timeFromDueDate,
  timeToDueDate
} from 'apps/ContentPages/services/payments/format-due-date'
import OverdueIcon from './overdue-icon'

import styles from './styles.module.scss'

const DueDateDetail = ({ date, state }) => {
  const { locale } = useLocale()
  const { t } = useTranslation()

  const isOverdue = state === 'overdue'
  const isAlmostDue = state === 'almostDue'

  if (isOverdue) {
    return (
      <div className={styles.overdueDateContainer}>
        <OverdueIcon />
        <Text
          type='h6'
          fontColor='text-alert'
          marginLeft='small'
          className={styles.uppercase}
        >
          {timeFromDueDate(date, locale, t)}
        </Text>
      </div>
    )
  }

  const fontColorLabel = isAlmostDue ? 'text-warning' : 'text-base-darker'
  const fontColorDate = isAlmostDue
    ? 'text-warning-darkest'
    : 'text-base-darkest'

  return (
    <Text type='h6'>
      <Text
        type='h6'
        tag='span'
        fontColor={fontColorLabel}
        className={styles.uppercase}
      >
        <Trans>Next payment:</Trans>
      </Text>
      <Text
        type='h6'
        tag='span'
        fontColor={fontColorDate}
        className={styles.uppercase}
      >
        {' '}
        {timeToDueDate(date, locale, t)}
      </Text>
    </Text>
  )
}

export default DueDateDetail

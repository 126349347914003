import React from 'react'

import PropTypes from 'prop-types'

import { Text } from '@fullfabric/alma-mater'
import { usePermission } from '@fullfabric/authorization-officer'

import { useCardFilters } from 'apps/Inbox/contexts/CardFilters'
import { CARD_TYPE_PERMISSIONS } from './constants'

import cx from 'classnames'
import styles from './styles.module.scss'

export default function CardTypeLink({ label, type }) {
  const hasPermissionForType = usePermission(CARD_TYPE_PERMISSIONS[type])
  const { cardType: selectedCardType, setCardType } = useCardFilters()

  if (!!CARD_TYPE_PERMISSIONS[type] && !hasPermissionForType) return null

  return (
    <Text
      tag='span'
      type='h5'
      fontColor='text-base-darkest'
      className={cx(styles.cardNavLink, {
        [styles.selected]: selectedCardType === type
      })}
      onClick={() => setCardType(type)}
    >
      {label}
    </Text>
  )
}

CardTypeLink.propTypes = {
  label: PropTypes.node.isRequired,
  type: PropTypes.string
}

CardTypeLink.defaultProps = {
  type: null
}

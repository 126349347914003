.react-app ._titleContainer_1ss13_1 {
  display: flex;
  align-items: center;
  margin-top: var(--space-more);
}

.react-app ._icon_1ss13_7 {
  height: 16px;
  width: 16px;
}

.react-app ._title_1ss13_1 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
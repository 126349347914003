import React from 'react'
import { Trans } from 'react-i18next'

import { Button, Text } from '@fullfabric/alma-mater'

import getApplicationChoiceContext from 'apps/ContentPages/utils/get-application-choice-context'
import { useTypeOfWidget } from '../../type-of-widget-context'

const StartedApplication = ({ application, contexts }) => {
  const { isRegistration } = useTypeOfWidget()
  const applicationChoiceContext = getApplicationChoiceContext(application)

  const contextLabel =
    applicationChoiceContext &&
    `${applicationChoiceContext.context_long_name} ${
      applicationChoiceContext.campus_name || ''
    }`

  return (
    <>
      {contextLabel ? (
        <Text type='f5' fontColor='text-base-darkest' marginTop='default'>
          {contextLabel}
        </Text>
      ) : null}
      <Button
        primary
        onClick={() => {
          window.location.href = `/applications/${application.id}`
        }}
        marginTop={contextLabel ? 'tiny' : 'default'}
      >
        {isRegistration ? (
          <Trans>Continue registration</Trans>
        ) : (
          <Trans>Continue application</Trans>
        )}
      </Button>
    </>
  )
}

export default StartedApplication

import React from 'react'
import { Trans } from 'react-i18next'

import { Button } from '@fullfabric/alma-mater'

import {
  useOriginalSubjectData,
  useSetSubjectEditionData,
  useSetSubjectEditionErrors
} from 'apps/StudyPlans/contexts/SubjectEditionData'
import { useSetSubjectSectionsBeingEdited } from 'apps/StudyPlans/contexts/SubjectSectionBeingEdited'
import flattenCourseTypes from 'apps/StudyPlans/utils/flattenCourseType'

import styles from './styles.module.scss'

const CancelButton = ({ fieldsToReset, sectionName }) => {
  const originalSubjectData = useOriginalSubjectData()
  const setSubjectData = useSetSubjectEditionData()
  const setSectionBeingEdited = useSetSubjectSectionsBeingEdited()
  const setSubjectErrors = useSetSubjectEditionErrors()

  const onClickCancel = React.useCallback(() => {
    const originalData = fieldsToReset.reduce((acc, field) => {
      return {
        ...acc,
        [field]: originalSubjectData[field]
      }
    }, {})
    const originalSectionData = {
      ...originalData,
      ...flattenCourseTypes(originalData.course_types || [])
    }

    setSubjectData((subjectData) => ({
      ...subjectData,
      ...originalSectionData
    }))

    setSectionBeingEdited((sections) => ({
      ...sections,
      [sectionName]: false
    }))

    setSubjectErrors({})
  }, [
    fieldsToReset,
    originalSubjectData,
    sectionName,
    setSectionBeingEdited,
    setSubjectData,
    setSubjectErrors
  ])

  return (
    <Button
      size='small'
      onClick={onClickCancel}
      className={styles.cancelButton}
    >
      <Trans>Cancel</Trans>
    </Button>
  )
}

export default CancelButton

import React from 'react'
import { useTranslation } from 'react-i18next'

import { PageHead } from '@fullfabric/alma-mater'

import TablePageLayout from 'shared/components/TablePageLayout'
import { TeamsAndStaffProvider } from 'shared/contexts/TeamsAndStaffContext'

import CreateEmailTemplateButton from 'apps/EmailTemplates/components/CreateEmailTemplateButton'
import EmailTemplatesTable from 'apps/EmailTemplates/components/EmailTemplatesTable'

export default function EmailTemplatesTablePage() {
  const [t] = useTranslation()

  return (
    <TeamsAndStaffProvider>
      <TablePageLayout>
        <PageHead
          noBorder
          title={t('Email templates')}
          action={<CreateEmailTemplateButton />}
        />

        <TablePageLayout.TableSection>
          <EmailTemplatesTable />
        </TablePageLayout.TableSection>
      </TablePageLayout>
    </TeamsAndStaffProvider>
  )
}

import { utils } from '@fullfabric/public-api'

const removeExternalPayerDiscount = async ({
  discountId,
  externalPayerRequestId
}) => {
  const response = await fetch(
    `/book_keeper/api/external_payer_requests/${externalPayerRequestId}/remove_payable_discount`,
    {
      credentials: 'same-origin',
      headers: { 'Content-Type': 'application/json' },
      method: 'DELETE',
      body: JSON.stringify({ discount_id: discountId })
    }
  )

  return await utils.checkResponse(response)
}

export default removeExternalPayerDiscount

import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Button, PlainButton, Row, Text } from '@fullfabric/alma-mater'
import { IfHasSetting } from '@fullfabric/authorization-officer'

import useIsMobileLayout from 'apps/ContentPages/components/payments-page/use-is-payments-mobile-layout'
import {
  useOpenDetailsPaymentsDrawer,
  useOpenPaymentsDrawer
} from 'apps/ContentPages/contexts/payments-drawer'
import amountToPrice from 'apps/ContentPages/services/payments/amount-to-price'
import getStateOfFee from 'apps/ContentPages/services/payments/get-state-of-fee'
import DueDateDetail from './due-date-detail'

import classNames from 'classnames'
import styles from './styles.module.scss'

const FeeDetail = ({ fee, discounts, isHead, paymentPlan }) => {
  const { t } = useTranslation()
  const openPaymentsDrawer = useOpenPaymentsDrawer()
  const openDetailsPaymentsDrawer = useOpenDetailsPaymentsDrawer()
  const isMobileLayout = useIsMobileLayout()
  const feeState = getStateOfFee(fee)
  const isOverdue = feeState === 'overdue'
  const isAlmostDue = feeState === 'almostDue'

  const { payment_portal: paymentPortal } = paymentPlan || {}
  const isPayable = !!paymentPortal

  return (
    <>
      <div
        className={classNames(
          isHead ? styles.highlight : styles.topSpacing,
          isOverdue && styles.overdue,
          isAlmostDue && styles.almostDue
        )}
      />
      <div
        className={classNames(
          styles.container,
          isHead && styles.headerFee,
          isMobileLayout && styles.mobile
        )}
        data-testid='urgent-fee-details'
      >
        <DueDateDetail date={fee.due_date} state={feeState} />
        <Text type='f2' fontColor='text-base-darkest' marginTop='small'>
          {amountToPrice(fee.currency, fee.total_amount)}
        </Text>
        <Text
          type='h4'
          fontColor='text-warning'
          marginBottom={isMobileLayout ? 'small' : 'tiny'}
        >
          {fee.outstanding_amount < fee.total_amount
            ? t('Missing {{amount}}', {
                amount: amountToPrice(fee.currency, fee.outstanding_amount)
              })
            : null}
        </Text>
        <Text
          type={isMobileLayout ? 'f5' : 'f4'}
          fontColor='text-base-darker'
          marginBottom='default'
        >
          {fee.description}
        </Text>
        <Row align='center' nogutter>
          {isPayable && (
            <IfHasSetting settingName='integrations.payments.flywire.enable'>
              <Button
                primary={isOverdue || isAlmostDue}
                marginRight='small'
                onClick={() => openPaymentsDrawer({ fee, paymentPlan })}
              >
                <Trans>Pay now</Trans>
              </Button>
            </IfHasSetting>
          )}
          <PlainButton
            onClick={() =>
              openDetailsPaymentsDrawer({ fee, discounts, paymentPlan })
            }
          >
            <Trans>See details</Trans>
          </PlainButton>
        </Row>
      </div>
    </>
  )
}

export default FeeDetail

import React from 'react'

import { IconButton } from '@fullfabric/alma-mater'

import { ReactComponent as ZoomInIcon } from '../../icons/zoomIn.svg'
import { ReactComponent as ZoomOutIcon } from '../../icons/zoomOut.svg'

import styles from './styles.module.scss'

const ZOOM_INCREMENT = 0.2
const MIN_ZOOM_THRESHOLD = 0.5

const ZoomControl = ({ setScale }) => {
  const onZoomChange = React.useCallback(
    (increment) => {
      setScale((s) => (s + increment > MIN_ZOOM_THRESHOLD ? s + increment : s))
    },
    [setScale]
  )

  return (
    <div className={styles.container}>
      <IconButton
        small
        className={styles.button}
        onClick={() => onZoomChange(ZOOM_INCREMENT)}
        marginRight='tiny'
      >
        <ZoomInIcon />
      </IconButton>
      <IconButton
        small
        className={styles.button}
        onClick={() => onZoomChange(-ZOOM_INCREMENT)}
      >
        <ZoomOutIcon />
      </IconButton>
    </div>
  )
}

export default ZoomControl

import qs from 'qs'

const getUpcomingEvents = async (eventIds) => {
  const params = eventIds?.length
    ? qs.stringify({ events_ids: eventIds }, { arrayFormat: 'brackets' })
    : ''

  return fetch(`/api/events/upcoming?${params}`, {
    credentials: 'same-origin'
  }).then((result) => result.json())
}

export default getUpcomingEvents

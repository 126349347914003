import React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import {
  Button,
  Link,
  ModalButtons,
  ModalTitle,
  SuccessBigIcon,
  Text
} from '@fullfabric/alma-mater'

import FullScreenLoading from 'shared/components/FullScreenLoading'

import {
  SUBMISSION_ACTIONS,
  useDispatchSubmissionUpdate
} from 'apps/Evaluations/context/EvaluationFormData'
import useIsLoaderShown from './useIsLoaderShown'

import styles from './styles.module.scss'

const Header = () => {
  const dispatchSubmissionUpdate = useDispatchSubmissionUpdate()
  const { isLoaderShown } = useIsLoaderShown()

  return isLoaderShown ? null : (
    <ModalTitle
      title=''
      onClose={() =>
        dispatchSubmissionUpdate({ type: SUBMISSION_ACTIONS.CLOSE_MODAL })
      }
    />
  )
}

const Footer = () => {
  const { t } = useTranslation()
  const { profileId, applicationId } = useParams()
  const { isLoaderShown } = useIsLoaderShown()

  return (
    !isLoaderShown && (
      <ModalButtons className={styles.buttonWrapper}>
        <Link
          withoutStyles
          href={`/evaluations/profiles/${profileId}/applications/${applicationId}/evaluations`}
        >
          <Button>{t('Go to evaluation list')}</Button>
        </Link>
      </ModalButtons>
    )
  )
}

const SuccessContent = () => {
  const { t } = useTranslation()
  const { isLoaderShown } = useIsLoaderShown()

  return isLoaderShown ? (
    <div className={styles.loaderWrapper}>
      <FullScreenLoading />
    </div>
  ) : (
    <div className={styles.contentWrapper}>
      <SuccessBigIcon
        width={24}
        height={24}
        color='text-success'
      ></SuccessBigIcon>

      <Text
        className={styles.modalText}
        id='submission-modal-title'
        type='h3'
        fontColor='text-base-darkest'
        marginTop='small'
      >
        {t('Evaluation submitted')}
      </Text>
    </div>
  )
}

SuccessContent.Header = Header
SuccessContent.Footer = Footer

export default SuccessContent

import React, { useState } from 'react'

import { SearchInput } from '@fullfabric/alma-mater'

import styles from './styles.module.scss'

function TableSearch({ onSearch, initialSearch, placeholder }) {
  const [searchValue, setSearchValue] = useState(initialSearch || '')

  return (
    <SearchInput
      className={styles.searchInput}
      value={searchValue}
      onChange={(evt) => {
        onSearch(evt.target.value)
        setSearchValue(evt.target.value)
      }}
      onClearInput={() => {
        onSearch('')
        setSearchValue('')
      }}
      placeholder={placeholder || 'Search'}
    />
  )
}

export default TableSearch

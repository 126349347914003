import React from 'react'

function OverdueIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='16'
      height='16'
      fill='none'
      viewBox='0 0 16 16'
    >
      <path
        fill='#6F0400'
        fillRule='evenodd'
        d='M8 2a1 1 0 00-1 1v6a1 1 0 002 0V3a1 1 0 00-1-1zm.015 12h.019a.983.983 0 00.965-1.02A1.018 1.018 0 007.985 12h-.019a.983.983 0 00-.965 1.02 1.02 1.02 0 001.014.98z'
        clipRule='evenodd'
      ></path>
    </svg>
  )
}

export default OverdueIcon

.react-app ._container_m73f9_1 {
  position: absolute;
  right: var(--space-less);
  top: var(--space-less);
  z-index: 1000;
  display: flex;
}

.react-app ._button_m73f9_9 {
  display: flex;
  justify-content: center;
  align-items: center;
}
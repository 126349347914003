const STORAGE_COLUMNS_KEY = 'payment_plans_table:columns:'

class ColumnStore {
  static getStorageKey(userId) {
    return STORAGE_COLUMNS_KEY + userId
  }

  static getStoredColumnsForUser(userId) {
    const storedCols = localStorage.getItem(this.getStorageKey(userId))

    return storedCols && JSON.parse(storedCols)
  }

  static getStoredVisibleColumnsForUser(userId) {
    return this.getStoredColumnsForUser(userId)?.visible
  }

  static getStoredHiddenColumnsForUser(userId) {
    return this.getStoredColumnsForUser(userId)?.hidden
  }

  static setStoredColumnsForUser(userId, hiddenColumns, visibleColumns) {
    localStorage.setItem(
      this.getStorageKey(userId),
      JSON.stringify({ hidden: hiddenColumns, visible: visibleColumns })
    )
  }
}

export default ColumnStore

import React from 'react'

const BackgroundImage = ({ className }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 876 1080'
      className={className}
    >
      <g clipPath='url(#clip0)' opacity='0.7'>
        <g filter='url(#filter0_f)'>
          <path
            fill='#8662C7'
            d='M400.5 1133.5L809 621l753.5 12-89 311-868 741.5-205-552z'
          ></path>
        </g>
        <g filter='url(#filter1_f)'>
          <path
            fill='#CFBCF2'
            d='M1084.48 932.799L922.129 712.782l86.231-302.487 115.68 69.455 204.98 429.991-244.54 23.058z'
          ></path>
        </g>
        <g filter='url(#filter2_f)'>
          <path
            fill='#2186EB'
            d='M158 351.5L566.5-161l753.5 12-89 311-868 741.5-205-552z'
          ></path>
        </g>
        <g filter='url(#filter3_f)'>
          <path
            fill='#40B5B3'
            d='M86 1079l217-216.5L1373 30l30.5 430-511 345L778 1063l-692 16z'
          ></path>
        </g>
        <g style={{ mixBlendMode: 'multiply' }} filter='url(#filter4_f)'>
          <path
            fill='#243E4F'
            d='M1006.53 64.12l-265.633 38.673a64 64 0 01-51.013-14.862l-53.068-45.758C593.11 4.488 617.681-67.24 675.315-70.212l296.823-15.3a63.995 63.995 0 0149.062 19.18l21.88 22.384c36.58 37.42 15.23 100.53-36.55 108.068z'
          ></path>
        </g>
      </g>
      <defs>
        <filter
          id='filter0_f'
          width='1362'
          height='1264.5'
          x='300.5'
          y='521'
          colorInterpolationFilters='sRGB'
          filterUnits='userSpaceOnUse'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix'></feFlood>
          <feBlend
            in='SourceGraphic'
            in2='BackgroundImageFix'
            result='shape'
          ></feBlend>
          <feGaussianBlur
            result='effect1_foregroundBlur'
            stdDeviation='50'
          ></feGaussianBlur>
        </filter>
        <filter
          id='filter1_f'
          width='606.888'
          height='722.504'
          x='822.129'
          y='310.295'
          colorInterpolationFilters='sRGB'
          filterUnits='userSpaceOnUse'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix'></feFlood>
          <feBlend
            in='SourceGraphic'
            in2='BackgroundImageFix'
            result='shape'
          ></feBlend>
          <feGaussianBlur
            result='effect1_foregroundBlur'
            stdDeviation='50'
          ></feGaussianBlur>
        </filter>
        <filter
          id='filter2_f'
          width='1462'
          height='1364.5'
          x='8'
          y='-311'
          colorInterpolationFilters='sRGB'
          filterUnits='userSpaceOnUse'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix'></feFlood>
          <feBlend
            in='SourceGraphic'
            in2='BackgroundImageFix'
            result='shape'
          ></feBlend>
          <feGaussianBlur
            result='effect1_foregroundBlur'
            stdDeviation='75'
          ></feGaussianBlur>
        </filter>
        <filter
          id='filter3_f'
          width='1717.5'
          height='1449'
          x='-114'
          y='-170'
          colorInterpolationFilters='sRGB'
          filterUnits='userSpaceOnUse'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix'></feFlood>
          <feBlend
            in='SourceGraphic'
            in2='BackgroundImageFix'
            result='shape'
          ></feBlend>
          <feGaussianBlur
            result='effect1_foregroundBlur'
            stdDeviation='100'
          ></feGaussianBlur>
        </filter>
        <filter
          id='filter4_f'
          width='646.878'
          height='389.058'
          x='514.506'
          y='-185.597'
          colorInterpolationFilters='sRGB'
          filterUnits='userSpaceOnUse'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix'></feFlood>
          <feBlend
            in='SourceGraphic'
            in2='BackgroundImageFix'
            result='shape'
          ></feBlend>
          <feGaussianBlur
            result='effect1_foregroundBlur'
            stdDeviation='50'
          ></feGaussianBlur>
        </filter>
        <clipPath id='clip0'>
          <path
            fill='#fff'
            d='M0 0H1080V876H0z'
            transform='matrix(0 1 1 0 0 0)'
          ></path>
        </clipPath>
      </defs>
    </svg>
  )
}

export default BackgroundImage

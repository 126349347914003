import React from 'react'

import { ProgressiveImage as Image } from '@fullfabric/alma-mater'
import { useSettings } from '@fullfabric/authorization-officer'

import classNames from 'classnames'
import styles from './styles.module.scss'

const Logo = ({ className }) => {
  const settings = useSettings()
  const logo = settings?.hash?.modules?.authentication?.logo_url

  return (
    <Image
      className={classNames([styles.logo, className])}
      src={logo}
      alt='logo'
    />
  )
}

export default Logo

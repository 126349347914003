{
  "Email": "Adresse e-mail",
  "Email address is invalid": "Adresse e-mail incorrecte",
  "I accept the privacy policy": "J'accepte la politique de confidentialité",
  "Privacy Policy": "Politique de confidentialité",
  "Please accept the privacy policy to continue": "Veuillez accepter la politique de confidentialité pour continuer",
  "Welcome back": "Nous sommes ravis de vous revoir",
  "Please enter your password for security reasons.": "Pour des raisons de sécurité, veuillez saisir votre mot de passe.",
  "Email address ": "Adresse e-mail ",
  "Password": "Mot de passe",
  "Forgot your password?": "Vous avez oublié votre mot de passe?",
  "An email has been sent to  with an authentication link that will take you to your application.": "An email has been sent to  with an authentication link that will take you to your application.",
  "Once you have accessed the authentication link you can close this page.": "Après avoir accédé au lien d'authentification, vous pourrez fermer cette page.",
  "Didn’t receive the email?": "Vous n'avez pas reçu l'e-mail ?",
  "Resend email with authentication link": "Renvoyer l'e-mail d'authentification",
  "Email sent": "Email envoyé",
  "Welcome! Let's start your new application.": "Bienvenue ! Commençons votre nouvelle candidature.",
  "Welcome! Let's start your new registration.": "Bienvenue ! Commençons votre nouvelle inscription.",
  "Please enter the following details so that you can revisit your application if you exit it.": "Veuillez saisir les informations suivantes afin de pouvoir accéder à votre candidature si vous la quittez.",
  "Please enter the following details so that you can revisit your registration if you exit it.": "Veuillez saisir les informations suivantes afin de pouvoir accéder à votre inscription si vous la quittez. ",
  "First name": "Prénom",
  "Last name": "Nom de famille",
  "Back": "Retour",
  "Continue to application": "Poursuivre la candidature",
  "Continue to registration": "Continuer l'inscription",
  "Data Protection regulation": "Règlement sur la protection des données",
  "Someone already registered with that email address": "Quelqu'un s'est déjà inscrit avec cette adresse électronique",
  "Email address can't be blank": "L'adresse électronique ne peut pas être vide",
  "Continue": "Continuer",
  "Participant details": "Détails du participant",
  "Please enter the details of the participant": "Veuillez saisir les coordonnées du participant.",
  "Registration already exists": "L'enregistrement existe déjà",
  "Application already exists": "L'application existe déjà",
  "There are fields with errors": "Il y a des champs avec des erreurs.",
  "Please correct the fields with errors": "Veuillez corriger les champs comportant des erreurs.",
  "First name can't be blank": "Le prénom ne peut être vide",
  "Last Name": "Nom de famille",
  "Last name can't be blank": "Le nom de famille ne peut pas être vide",
  "Your employment details": "Les détails de votre emploi",
  "To register on behalf of someone else we need more information on your current employment details.": "Pour vous inscrire au nom de quelqu'un d'autre, nous avons besoin de plus d'informations sur les détails de votre emploi actuel.",
  "There was an error in your submission, please try again in a few seconds. If the issue persists, please contact us.": "Il y a eu une erreur dans votre soumission, veuillez réessayer dans quelques secondes. Si le problème persiste, veuillez nous contacter.",
  "The link has expired.": "Le lien a expiré.",
  "Please re-enter your details to receive a new authentication email.": "Veuillez saisir de nouveau vos coordonnées pour recevoir un nouvel e-mail d'authentification.",
  "Please enter your email address so that we can retrieve your information if you have visited before.": "Veuillez saisir votre adresse e-mail afin que nous puissions récupérer vos informations si vous êtes déjà venu(e).",
  "Please select an option": "Veuillez sélectionner une option",
  "I am starting a registration for myself": "Je commence une inscription",
  "I am starting an application for myself": "Je dépose une candidature pour moi-même.",
  "I am starting a registration on behalf of someone else": "Je commence une inscription au nom de quelqu'un d'autre.",
  "I am starting an application on behalf of someone else": "Je dépose une candidature au nom de quelqu'un d'autre.",
  "I am continuing an existing registration": "Je continue une inscription existante.",
  "I am continuing an existing application": "Je continue une candidature existante.",
  "An email has been sent to {{email}} with an authentication link that will take you to your application.": "Un e-mail a été envoyé à {{email}} avec un lien d'authentification qui vous redirigera vers votre candidature."
}

import React from 'react'

function Icon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='16'
      height='16'
      fill='none'
      viewBox='0 0 16 16'
    >
      <path
        fill='#6A6D77'
        fillRule='evenodd'
        d='M0 8a8 8 0 018-8 8.01 8.01 0 018 8A8 8 0 110 8zm2 0a6 6 0 006 6 6.007 6.007 0 006-6A6 6 0 002 8z'
        clipRule='evenodd'
      ></path>
      <path fill='#6A6D77' d='M11 10L9 8V4H7v4l2.5 3 1.5-1z'></path>
    </svg>
  )
}

export default Icon

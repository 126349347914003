import React from 'react'

import { useStudyPlan } from 'apps/StudyPlans/contexts/StudyPlanData'
import MultiplePathsCourseTypeField from './multiple-path-course-type'
import SinglePathsCourseTypeField from './single-path-course-type'

const CourseTypeField = () => {
  const studyPlan = useStudyPlan()

  return studyPlan.multiple_paths ? (
    <MultiplePathsCourseTypeField />
  ) : (
    <SinglePathsCourseTypeField />
  )
}

export default CourseTypeField

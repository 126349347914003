.react-app ._footer_l2zr6_1 {
  padding: var(--space-more);
}
.react-app ._footer_l2zr6_1._mobile_l2zr6_4 {
  padding: var(--space-default);
}

.react-app ._button_l2zr6_8 {
  width: 100%;
  padding: var(--space-less);
}
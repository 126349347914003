import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useQuery } from 'react-query'

import { useCurrentUser } from '@fullfabric/authorization-officer'
import { getCountries } from '@fullfabric/public-api'
import { SchemableForm, useScrollTo } from '@fullfabric/schemable-forms'

import { useAppData } from 'shared/contexts/AppData'
import { useLocale } from 'shared/contexts/Locale'

import { useSetRegisterEventClickHandler } from 'apps/ContentPages/contexts/register-event-click-handler'
import isEventOpen from 'apps/ContentPages/utils/is-event-open'
import refreshEventDigest from 'apps/ContentPages/utils/refresh-event-digest'
import SuccessMessage from './success-message'
import useHandleSubmit from './use-handle-submit'

import styles from './styles.module.scss'

export default function EventForm() {
  const {
    event,
    response,
    privacy_policy: privacyPolicy,
    marketing_policy: marketingPolicy
  } = useAppData()

  const user = useCurrentUser()
  const { locale } = useLocale()
  const { data: countriesAsOptions, isLoading: isLoadingCountries } = useQuery(
    ['get-countries'],
    () => getCountries({ locale })
  )

  const [submissionError, setSubmissionError] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const toggleToSubmitted = useCallback(() => {
    setSubmissionError(false)
    setIsSubmitted(true)
  }, [])

  const handleNotSubmitted = useCallback(
    async (error) => {
      setSubmissionError(error)
      if (error.mayNeedRefetch) await refreshEventDigest(event.id)
    },
    [event.id, setSubmissionError]
  )

  const handleSubmit = useHandleSubmit({
    redirectTo: event?.redirect_path,
    eventId: event?.id,
    onStartSubmit: () => setSubmissionError(false),
    onSubmitted: toggleToSubmitted,
    onNotSubmitted: handleNotSubmitted
  })

  const eventFormRef = useRef()
  const setRegisterEventClickHandler = useSetRegisterEventClickHandler()
  const scrollTo = useScrollTo()
  useEffect(() => {
    setRegisterEventClickHandler(() => () => {
      eventFormRef?.current && scrollTo(eventFormRef.current)
    })
  }, [setRegisterEventClickHandler, scrollTo])

  const schema = event?.schema

  if (isSubmitted) {
    return (
      <>
        <span className={styles.separator} />
        <SuccessMessage />
      </>
    )
  }

  const areRegistrationsOpen = isEventOpen(event)
  if (!areRegistrationsOpen) {
    return null
  }

  return (
    <>
      <span className={styles.separator} />
      <div className={styles.container} ref={eventFormRef}>
        <div className={styles.spacing} />
        <SchemableForm
          schema={schema}
          locale={locale}
          data={response}
          errorDetailScope='events/response/yes'
          error={submissionError}
          countriesAsOptions={isLoadingCountries ? [] : countriesAsOptions}
          privacyPolicy={privacyPolicy}
          marketingPolicy={marketingPolicy}
          user={user}
          handleSubmit={handleSubmit}
        />
      </div>
    </>
  )
}

import { utils } from '@fullfabric/public-api'

export async function deleteDelayedJob(jobId) {
  const response = await fetch(`/admin/api/jobs/${jobId}`, {
    method: 'DELETE',
    credentials: 'same-origin'
  })

  return await utils.checkResponse(response)
}

export async function resetDelayedJob(jobId) {
  const response = await fetch(`/admin/api/jobs/${jobId}/reset`, {
    method: 'POST',
    credentials: 'same-origin'
  })

  return await utils.checkResponse(response)
}

export async function runDelayedJob(jobId) {
  const response = await fetch(`/admin/api/jobs/${jobId}/force`, {
    method: 'POST',
    credentials: 'same-origin'
  })

  return await utils.checkResponse(response)
}

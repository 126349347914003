import React from 'react'

import PropTypes from 'prop-types'

import DataTable from '../DataTable'
import VirtualizedDataTable from '../VirtualizedDataTable'
import useTableFilter from './hooks/useTableFilter'
import useTableQuery from './hooks/useTableQuery'
import useTableSearch from './hooks/useTableSearch'
import useTableSort from './hooks/useTableSort'

/**
 * Data table that loads its data through a URL or a dataFetch function.
 * It uses the DataTable or VirtualizedDataTable component (if virtualized prop is true) internally and its data fetching is done using react-query and axios.
 * It accepts all props that could be used by the underlying component (DataTable or VirtualizedDataTable)
 */
const AutoloadDataTable = ({
  fetchURL,
  fetchFn,
  fetchParams = {},
  fetchOptions,
  fetchedDataParser,
  fetchResponseParser,
  reactQueryProps,
  virtualized,
  searchable,
  searchParamKey = 'search',
  initialSearch = {},
  onSearchChanged,
  searchPlaceholder,
  sortable,
  sortParams: sortParamsConfig,
  initialSort,
  onSortChanged,
  loadMoreProps,
  filterable,
  filters,
  initialFilter,
  filterStorageKey,
  onFilterChanged,
  tableProps = {},
  ...otherProps
}) => {
  const { handleChangeSort, sortState, tablePropsWithSortState } = useTableSort(
    { sortable, sortParamsConfig, initialSort, tableProps, onSortChanged }
  )

  const { handleChangeSearch, searchState } = useTableSearch(
    initialSearch,
    searchParamKey,
    onSearchChanged
  )

  const { handleFilterChange, filterState } = useTableFilter(
    initialFilter,
    filterStorageKey,
    onFilterChanged
  )

  const {
    fetchedData,
    isInitialDataLoading,
    error,
    onLoadMoreClick,
    loadMoreButtonProps
  } = useTableQuery({
    fetchURL,
    fetchFn,
    fetchParams,
    sortState,
    searchState,
    filterState,
    fetchOptions,
    fetchedDataParser,
    fetchResponseParser,
    loadMoreProps,
    reactQueryProps
  })

  const DataTableComponent =
    virtualized && !loadMoreProps ? VirtualizedDataTable : DataTable

  return (
    <DataTableComponent
      {...otherProps}
      data={fetchedData}
      loading={isInitialDataLoading}
      error={error}
      onChangeSort={sortable ? handleChangeSort : undefined}
      onSearch={searchable && handleChangeSearch}
      initialSearch={initialSearch[searchParamKey]}
      searchPlaceholder={searchPlaceholder}
      onLoadMoreClick={onLoadMoreClick}
      loadMoreProps={loadMoreButtonProps}
      filters={filters}
      onFilterChange={filterable ? handleFilterChange : null}
      selectedFilter={filterable ? filterState : null}
      tableProps={tablePropsWithSortState}
    />
  )
}

AutoloadDataTable.propTypes = {
  /**
   * URL containing the data to be fetched and displayed on the data table
   */
  fetchURL: PropTypes.string,
  /**
   * Async function that returns a Promise that resolves the data to be displayed on the data table
   */
  fetchFn: PropTypes.func,
  /**
   * Params object that will be formatted as a query string and appended to the fetchURL string
   */
  fetchParams: PropTypes.object,
  /**
   * Custom options to be passed to the axios get function (headers, etc...)
   */
  fetchOptions: PropTypes.object,
  /**
   * Function that allows parsing the data returned from the fetchURL or fetchFn as an array that can be used by the DataTable component
   */
  fetchedDataParser: PropTypes.func,
  /**
   * Function that allows parsing the response of the request done with fetchURL and retrieve information that can be used by the DataTable component
   */
  fetchResponseParser: PropTypes.func,
  /**
   * Array of column definitions in the format used by the react-table component (https://react-table.tanstack.com/docs/api/useTable#column-options)
   */
  columns: PropTypes.array.isRequired,
  /**
   * Custom class name for the root HTML table element
   */
  className: PropTypes.string,
  /**
   * Custom empty list message
   */
  emptyMessage: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  /**
   * Custom options to be passed on to react-table props (https://react-table.tanstack.com/docs/api/useTable#table-options)
   */
  tableProps: PropTypes.object,
  /**
   * Custom options to be passed on to react-query props (https://react-query.tanstack.com/reference/useQuery)
   */
  reactQueryProps: PropTypes.object,
  /**
   * Whether the sorting should be enabled or not
   */
  sortable: PropTypes.bool,
  /**
   * A configuration object to specify the names of the parameters to use to sort, in the following format:
   *
   * - __sortKey__ (default: `sort[field]`) for the parameter to specify the field to sort by
   *
   * - __directionKey__ (default: `sort[direction]`) for the parameter to specify the direction in with to sort
   *
   * - __descValue (default: `-1`) as the descending value
   *
   * - __ascValue (default: `1`) as the ascending value
   */
  sortParams: PropTypes.shape({
    sortKey: PropTypes.string,
    directionKey: PropTypes.string,
    ascValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    descValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }),
  /**
   * An object representing the parameters for the initial sort of the table. E.g: `{ "sort[field]": "name", "sort[direction]": 1 }`
   */
  initialSort: PropTypes.object,
  /**
   * Whether the search bar and searching functionality should be enabled
   */
  searchable: PropTypes.bool,
  /**
   * The parameter which will be sent in the request and used to search. Default: `search`
   */
  searchParamKey: PropTypes.string,
  /**
   * An object representing the name of the parameter and its value for the initial search over the table. E.g: `{search: "abc"}`
   */
  initialSearch: PropTypes.object,
  /**
   * Placeholder for search input. Default: "Search" (non-localized)
   */
  searchPlaceholder: PropTypes.string,
  /**
   * String specifying where to store the filter in local storage. If not desirable, can be left blank and it will ignore local storage. NOTE: `initialFilter` prop will be prioritized over the one storaged locally.
   */
  filterStorageKey: PropTypes.string,
  /**
   * Object containing the definitions needed for load more functionality to work.
   *
   * If this parameter is not provided, it is assumed that there will be no load more of data (all data will be fetched in a single request)
   *
   * The allowed params are:
   *
   * __pageSize__: number of rows for each page fetched (_default is 20_);
   *
   * __resultDataKey__: the key name in the fetched result object that contains the total of all rows that can be fetched (_default is "data"_);
   *
   * __resultTotalKey__: the key name in the fetched result object that contains the actual data fetched (_default is "total"_);
   *
   * __pageKey__: the key name of the query string that will be sent in each request with the desired page to be fetched (_default is "page"_);
   *
   * __offsetKey__: the key name of the query string that will be sent in each request with the desired offset to be skipped within the collection to be fetched (_default is "offset"_);
   * (ps.: only one of _pageKey_ or _offsetKey_ can be provided)
   *
   * __sizeKey__: the key name of the query string that will be sent in each request with the desired number of data to be fetched (_default is "limit"_);
   */
  loadMoreProps: PropTypes.shape({
    pageSize: PropTypes.number,
    resultDataKey: PropTypes.string,
    resultTotalKey: PropTypes.string,
    pageKey: PropTypes.string,
    sizeKey: PropTypes.string
  })
}

export default AutoloadDataTable

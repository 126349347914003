import React from 'react'

import { usePageMainTextContent } from 'apps/ContentPages/contexts/current-page-data'
import PageMainTextContent from './page-main-text-content'

const StudentHubPageTextContent = () => {
  const { isLoading, content } = usePageMainTextContent()

  return (
    <PageMainTextContent isLoading={isLoading}>{content}</PageMainTextContent>
  )
}

export default StudentHubPageTextContent

import { useEffect, useRef, useState } from 'react'

export default function useCookieBannerHeight(cookieBannerRef) {
  const [contentHeight, setContentHeight] = useState()
  const observer = useRef(null)

  useEffect(() => {
    if (!cookieBannerRef?.current) {
      setContentHeight(0)
      return
    }

    observer.current = new ResizeObserver((entries) =>
      saveHeightOfTarget(entries, setContentHeight)
    )

    observer.current.observe(cookieBannerRef.current)

    return () => {
      observer.current.disconnect()
    }
  }, [cookieBannerRef])

  return contentHeight || 0
}

export function saveHeightOfTarget(entries, setHeight) {
  const target = entries[0]?.target
  setHeight(target?.offsetHeight)
}

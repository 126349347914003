import React from 'react'
import { Trans } from 'react-i18next'

import { Text } from '@fullfabric/alma-mater'

import WidgetSection from 'apps/ContentPages/components/widget-section'

const Empty = () => (
  <WidgetSection last>
    <Text type='f4' fontColor='text-base-darkest'>
      <Trans>No events</Trans>
    </Text>
  </WidgetSection>
)

export default Empty

.react-app ._thirdPartyLoginButton_12zjg_1 {
  width: 100%;
  padding: 7px 16px;
  text-align: left;
  margin-bottom: 8px;
  background: none;
  background-color: var(--ff-bg-base-lightest);
  box-shadow: none;
}
.react-app ._thirdPartyLoginButton_12zjg_1:focus {
  background: none;
  padding: 6px 15px;
}
.react-app ._thirdPartyLoginButton_12zjg_1:hover, .react-app ._thirdPartyLoginButton_12zjg_1:active {
  background: none;
  padding: 7px 16px;
}
.react-app ._thirdPartyLoginButton_12zjg_1 svg {
  height: 24px;
  margin-right: 6px;
}
.react-app ._thirdPartyLoginButton_12zjg_1 svg:first-child {
  float: left;
}
.react-app ._thirdPartyLoginButton_12zjg_1 svg:last-child {
  float: right;
}
.react-app ._thirdPartyLoginButton_12zjg_1 > div > div {
  transition: none !important;
}

.react-app ._buttonContent_12zjg_32 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.react-app ._buttonContent_12zjg_32 ._iconAndText_12zjg_38 {
  display: inline-flex;
  align-items: center;
}

.react-app ._linkedin-button_12zjg_43 ._iconAndText_12zjg_38 span {
  color: #0071a1;
}
.react-app ._linkedin-button_12zjg_43:hover {
  background-color: #0077b5;
  background-image: none;
  border: 1px solid #0077b5;
}
.react-app ._linkedin-button_12zjg_43:hover ._iconAndText_12zjg_38 span {
  color: white;
}

.react-app ._facebook-button_12zjg_55:hover {
  background-color: #3b5998;
  background-image: none;
  border: 1px solid #3b5998;
}

.react-app ._saml-button_12zjg_61:hover {
  background-color: #3b4755;
  border: 1px solid #3b5998;
}
.react-app ._saml-button_12zjg_61:hover ._iconAndText_12zjg_38 span {
  color: white;
}

.react-app ._google-button_12zjg_69:hover {
  background-color: #dd4b39;
  background-image: none;
  border: 1px solid #dd4b39;
}
import React from 'react'
import { useTranslation } from 'react-i18next'

import moment from 'moment'

import parseMonthFromYearWeek from 'apps/Reports/utils/parseMonthFromYearWeek'
import XAxisTick from '../XAxisTick'

const YearWeeksXAxisTick = ({
  payload,
  dense,
  startingWeek,
  ...otherProps
}) => {
  const { i18n } = useTranslation()
  const targetWeek = parseMonthFromYearWeek(payload.value).locale(i18n.language)
  const weekBefore = moment(targetWeek).subtract(1, 'isoWeek')
  const isFirstWeekOfMonth = targetWeek.isAfter(weekBefore, 'month')
  const isFirstWeekOfDataSet = payload.index === 0

  const startingMonth = startingWeek
    ? parseMonthFromYearWeek(startingWeek).month()
    : 0
  const isCollapsibleMonth = targetWeek.month() % 2 !== startingMonth % 2
  const shouldCollapseMonth = dense && isCollapsibleMonth

  const shouldDisplayLabel =
    isFirstWeekOfDataSet || (isFirstWeekOfMonth && !shouldCollapseMonth)

  const isPastYear = targetWeek.year() < moment().year()
  const dateFormat = isPastYear ? 'MMM YYYY' : 'MMM'

  return (
    <XAxisTick
      customValue={shouldDisplayLabel ? targetWeek.format(dateFormat) : ''}
      {...otherProps}
    />
  )
}

export default YearWeeksXAxisTick

import ApplicationTemplatesWidget from './applications'
import MyApplicationsWidget from './my-applications'
import MyRegistrationsWidget from './my-registrations'
import RegistrationTemplatesWidget from './registrations'

export {
  ApplicationTemplatesWidget,
  MyApplicationsWidget,
  RegistrationTemplatesWidget,
  MyRegistrationsWidget
}

export const GUEST_COUNT_INITIAL_STATE = {
  value: 0,
  numberValue: ''
}

export const guestCountReducer = (state, action) => {
  switch (action.type) {
    case 'value_change':
      return {
        ...state,
        value: action.value,
        numberError: false,
        numberValue: ''
      }
    case 'number_change':
      return {
        ...state,
        value: 'other',
        numberValue: action.value,
        numberError: false
      }

    case 'not_a_number_error':
    default:
      return { ...state, numberError: true }
  }
}

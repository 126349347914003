import React from 'react'
import { useTranslation } from 'react-i18next'

import schemableColumns from './schemableColumns'

const useColumns = ({ columns, dataSchemas }) => {
  const { i18n } = useTranslation()

  const memoizedColumns = React.useMemo(() => {
    const columnsFromSchemas = schemableColumns(dataSchemas, i18n.language)

    return [...(columns || []), ...columnsFromSchemas]
  }, [columns, dataSchemas, i18n.language])

  return memoizedColumns
}

export default useColumns

import React from 'react'

import { Text } from '@fullfabric/alma-mater'

import Linkable from '../../linkable'

import styles from './styles.module.scss'

const Link = ({ label, ...linkableProps }) => (
  <Linkable {...linkableProps}>
    <Text type='h5' fontColor='text-base-darkest' className={styles.text}>
      {label}
    </Text>
  </Linkable>
)

export default Link

/* eslint-disable camelcase */

import React from 'react'
import { Trans } from 'react-i18next'

import PropTypes from 'prop-types'

import {
  ActionList,
  Button,
  Col,
  Label,
  Link,
  MoreIcon,
  Row,
  Text
} from '@fullfabric/alma-mater'

import InboxEmailCardDescriptor from 'apps/Inbox/components/InboxEmailCardDescriptor'
import InboxEmailCardState from 'apps/Inbox/components/InboxEmailCardState'
import { useHandleArchive } from 'apps/Inbox/hooks/handleArchiveHook'
import { emailRoute } from 'apps/Inbox/utils/cardUtils'

import styles from './styles.module.scss'

export default function InboxEmailCard({ card }) {
  const handleArchived = useHandleArchive()

  const {
    id: cardId,
    entity: {
      id: emailId,
      subject,
      to_emails: toEmails,
      cc_emails: ccEmails,
      bcc_emails: bccEmails,
      last_seen_by: lastSeenBy,
      last_seen_at: lastSeenAt,
      being_replied_by: beingRepliedBy,
      being_replied_at: beingRepliedAt,
      replied_by: repliedBy,
      replied_at: repliedAt
    },
    status,
    status_updated_by: statusUpdatedBy,
    status_updated_at: statusUpdatedAt,
    actor,
    updated_at
  } = card

  return (
    <div key={cardId} className={styles.emailCard}>
      <Row middle='xs'>
        <Col xs={9} className={styles.subjectContainer}>
          <Label color='purple-500'>
            <Trans>Email</Trans>
          </Label>

          <Text
            tag='span'
            type='h4'
            marginLeft='small'
            fontColor='text-base-darkest'
            className={styles.subject}
          >
            {subject || <Trans>(no subject)</Trans>}
          </Text>

          <InboxEmailCardState
            lastSeenBy={lastSeenBy}
            lastSeenAt={lastSeenAt}
            beingRepliedBy={beingRepliedBy}
            beingRepliedAt={beingRepliedAt}
          />

          <InboxEmailCardDescriptor
            status={status}
            statusUpdatedBy={statusUpdatedBy}
            statusUpdatedAt={statusUpdatedAt}
            actor={actor}
            toEmails={toEmails}
            ccEmails={ccEmails}
            bccEmails={bccEmails}
            repliedBy={repliedBy}
            repliedAt={repliedAt}
            updatedAt={updated_at}
          />
        </Col>

        <Col xs={3}>
          <Row middle='xs' end='xs'>
            <Col xs={12} className={styles.buttons}>
              <Link disabled href={emailRoute(emailId)} target='_top'>
                <Button marginRight='small' size='small'>
                  <Trans>View email</Trans>
                </Button>
              </Link>

              <ActionList
                iconButton={<MoreIcon />}
                iconButtonProps={{ small: true }}
                menuPosition='down'
                wrapperClassName={styles.actionListWrapper}
              >
                <ActionList.Option onClick={() => handleArchived({ card })}>
                  {status === 'active' ? (
                    <Trans>Archive</Trans>
                  ) : (
                    <Trans>Restore</Trans>
                  )}
                </ActionList.Option>
              </ActionList>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  )
}

InboxEmailCard.propTypes = {
  card: PropTypes.shape({
    id: PropTypes.string.isRequired,
    entity: PropTypes.shape({
      id: PropTypes.string.isRequired,
      subject: PropTypes.string,
      to_emails: PropTypes.array,
      cc_emails: PropTypes.array,
      bcc_emails: PropTypes.array,
      last_seen_by: PropTypes.object,
      last_seen_at: PropTypes.string,
      being_replied_by: PropTypes.object,
      being_replied_at: PropTypes.string,
      replied_by: PropTypes.object,
      replied_at: PropTypes.string
    }).isRequired,
    status: PropTypes.string.isRequired,
    status_updated_by: PropTypes.object,
    status_updated_at: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    actor: PropTypes.object,
    updated_at: PropTypes.string
  }).isRequired
}

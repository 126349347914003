import React from 'react'

import {
  Col,
  Container,
  Hidden,
  Row,
  Text,
  useScreenClass
} from '@fullfabric/alma-mater'

import BackgroundImage from './BackgroundImage'
import Header from './Header'
import Preview from './Preview'

import styles from './styles.module.scss'

const WizardLayout = ({ title, subtitle, description, children }) => {
  const screenClass = useScreenClass()
  const isMobileLayout = ['xs', 'sm', 'md'].includes(screenClass)

  return (
    <Container fluid className={styles.outerContainer}>
      <Hidden xs sm md>
        <Row nogutter className={styles.backgroundLayer}>
          <Col lg={5} />
          <Col lg={7}>
            <div className={styles.bgImageContainer}>
              <BackgroundImage className={styles.bgImage} />
            </div>
          </Col>
        </Row>
      </Hidden>
      <Row nogutter className={styles.container}>
        <Col
          xs={12}
          lg={5}
          className={
            isMobileLayout
              ? styles.contentColumnMobileSpacing
              : styles.contentColumnSpacing
          }
        >
          <div className={styles.contentColumn}>
            <div className={styles.headerContainer}>
              <Header />
            </div>
            <div className={styles.contentPositioner}>
              <Text type='h2' fontColor='text-base-darkest'>
                {title}
              </Text>
              <Text
                type='f2'
                fontColor='text-base-darkest'
                marginBottom='small'
              >
                {subtitle}
              </Text>
              <Text type='f5' fontColor='text-base-darker' marginBottom='big'>
                {description}
              </Text>
              {children}
            </div>
          </div>
        </Col>
        <Hidden xs sm md>
          <Col lg={7} className={styles.previewColumn}>
            <Preview />
          </Col>
        </Hidden>
      </Row>
    </Container>
  )
}

export default WizardLayout

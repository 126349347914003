import React from 'react'
import { AutoSizer } from 'react-virtualized'

import PropTypes from 'prop-types'

import TableManipulationSection from '../TableManipulationSection'
import VDataTable from './VDataTable'

/**
 * Data table intended to be used with big data sets.
 * It's powered by react-virtualized Table component.
 * It only renders the rows the are being displayed within the viewport at a given moment.
 */
const VirtualizedDataTable = ({
  onSearch,
  searchPlaceholder,
  initialSearch,
  filters,
  onFilterChange,
  selectedFilter,
  manipulationSectionClassName,
  filterProps = {},
  ...props
}) => {
  const containerRef = React.useRef()

  return (
    <AutoSizer disableHeight>
      {({ width }) => (
        <div
          style={{ width: `${width}px`, overflow: 'auto' }}
          ref={containerRef}
        >
          <TableManipulationSection
            className={manipulationSectionClassName}
            onSearch={onSearch}
            initialSearch={initialSearch}
            searchPlaceholder={searchPlaceholder}
            onFilterChange={onFilterChange}
            selectedFilter={selectedFilter}
            filters={filters}
            filterProps={filterProps}
          />

          <VDataTable width={width} containerRef={containerRef} {...props} />
        </div>
      )}
    </AutoSizer>
  )
}

VirtualizedDataTable.propTypes = {
  /**
   * An array of objects containing the data to be displayed within the DataTable component
   */
  data: PropTypes.array,
  /**
   * Whether the table should paginate automatically once the bottom is reached.
   */
  autoPaginate: PropTypes.bool,
  /**
   * Array of column definitions in the format used by the react-table component (https://react-table.tanstack.com/docs/api/useTable#column-options)
   */
  columns: PropTypes.array.isRequired,
  /**
   * Custom class name for the root HTML table element
   */
  className: PropTypes.string,
  /**
   * Renders the data table loading state
   */
  loading: PropTypes.bool,
  /**
   * Custom empty list message
   */
  emptyMessage: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  /**
   * Custom options to be passed on to react-table props (https://react-table.tanstack.com/docs/api/useTable#table-options)
   */
  tableProps: PropTypes.object,
  /**
   * Callback invoked when a user clicks on a table column. ({ columnData: any, dataKey: string, event: Event }): void
   */
  onColumnClick: PropTypes.func,
  /**
   * Callback invoked when a user clicks on a table header (overriden by onChangeSort if present). ({ columnData: any, dataKey: string, event: Event }): void
   */
  onHeaderClick: PropTypes.func,
  /**
   * Callback invoked when the user clicks on a sortable column's header. `(column: ObjectWithColumnIdOnly, updateTableSortState: function): void`
   */
  onChangeSort: PropTypes.func,
  /**
   * Callback invoked when a user clicks on a table row. ({ event: Event, index: number, rowData: any }): void
   */
  onRowClick: PropTypes.func,
  /**
   * Callback invoked when a user double-clicks on a table row. ({ event: Event, index: number, rowData: any }): void
   */
  onRowDoubleClick: PropTypes.func,
  /**
   * Callback invoked when the mouse leaves a table row. ({ event: Event, index: number, rowData: any }): void
   */
  onRowMouseOut: PropTypes.func,
  /**
   * Callback invoked when a user moves the mouse over a table row. ({ event: Event, index: number, rowData: any }): void
   */
  onRowMouseOver: PropTypes.func,
  /**
   * Callback invoked when a user right-clicks on a table row. ({ event: Event, index: number, rowData: any }): void
   */
  onRowRightClick: PropTypes.func,
  /**
   * Callback invoked with information about the slice of rows that were just rendered: ({ overscanStartIndex: number, overscanStopIndex: number, startIndex: number, stopIndex: number }): void
   */
  onRowsRendered: PropTypes.func,
  /**
   * Number of rows to render above/below the visible bounds of the list. This can help reduce flickering during scrolling on certain browsers/devices. See (https://github.com/bvaughn/react-virtualized/blob/master/docs/overscanUsage.md) for an important note about this property.
   */
  overscanRowCount: PropTypes.number,
  /**
   * Filters to be passed as `filters` prop to alma mater's filter component
   */
  filters: PropTypes.array,
  /**
   * Callback function to be passed as the `onFilterChange` prop to alma mater's filter component
   */
  onFilterChange: PropTypes.func,
  /**
   * Filter object according to alma mater's Filter component specification
   */
  selectedFilter: PropTypes.object,
  /**
   * Other props supported by alma mater's Filter component (e.g: `onOptionsLoaded`)
   */
  filterProps: PropTypes.object,
  /**
   * Class name to be applied to the table manipulation (filter, search) section.
   */
  manipulationSectionClassName: PropTypes.string
}

export default VirtualizedDataTable

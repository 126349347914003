import getSideEffectsData from 'apps/Courses/utils/getSideEffectsData'
import validateCreation from 'apps/Courses/utils/validateCreation'
import actionTypes from '../types'

const newCourseDataChanged = (payload) => (dispatch, getState) => {
  dispatch({ type: actionTypes.newCourseDataChanged, payload })

  const {
    newCourse: { data: newCourseData },
    subjectList: { data: subjects = [] } = {}
  } = getState()

  try {
    validateCreation({ ...newCourseData, ...payload }, subjects)
  } catch (error) {
    dispatch({ type: actionTypes.createCourse.error, payload: error })
  }

  const sideEffectsData = getSideEffectsData({
    existingData: newCourseData,
    updatedData: payload
  })

  if (Object.keys(sideEffectsData)?.length) {
    dispatch({
      type: actionTypes.newCourseDataChanged,
      payload: sideEffectsData
    })
  }
}

export default newCourseDataChanged

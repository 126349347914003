import ProfileImportFormDataError from 'apps/Imports/errors/profile-import-form-data-error'
import UploadedFileError from 'apps/Imports/errors/uploaded-file-error'
import { IMPORT_TYPES } from 'apps/Imports/utils/constants'
import getFileBlob from 'apps/Imports/utils/get-file-blob'

const CONTEXT_TYPE_MAP = {
  'Institutions.Models.ClassOf': 'Institutions::ClassOf',
  'Institutions.Models.Course': 'Institutions::Course',
  'Institutions.Models.Institution': 'Institutions::Institution',
  'Events.Models.Event': 'Events::Event'
}

const getProfileImportFormData = async ({
  data,
  context,
  marketingPolicy,
  dataProcessingPolicy,
  communicationPolicies
}) => {
  const formData = new FormData()

  // IMPORT TYPE
  formData.append('_type', IMPORT_TYPES.PROFILE)

  // UPLOADED FILE
  try {
    const uploadedFile = await getFileBlob(data.file.url)
    formData.append('file_contents', uploadedFile, data.file.name)
  } catch (err) {
    console.error(err)
    throw new UploadedFileError(err)
  }

  try {
    // CONTEXT
    formData.append('context_type', CONTEXT_TYPE_MAP[context._backbone_type])
    formData.append('context_id', context.id)

    // SEPARATOR
    formData.append('column_separator', data.separator)

    // CONTACT IMPORT
    formData.append('import_as_contact', '')

    // UPDATE EXISTING PROFILES
    formData.append(
      'extract_profile_processor_options[update]',
      data.updateExistingProfiles ? 'true' : ''
    )

    // OWNER
    formData.append(
      'processors[ImportService::Processors::Owner][owner]',
      data.addToData.ownerId || ''
    )

    // STATE & SUBSTATE
    formData.append(
      'processors[ImportService::Processors::Role][state_and_substate]',
      data.addToData.stateSubstateId || ''
    )

    // SOURCE CATEGORY
    formData.append(
      'processors[ImportService::Processors::Source][category]',
      data.sourceCategory || ''
    )
    formData.append(
      'processors[ImportService::Processors::Source][category_type]',
      data.sourceCategoryType || ''
    )
    formData.append(
      'processors[ImportService::Processors::Source][overwrite_category]',
      data.updateExistingProfiles ? data.overwriteSourceCategory || '' : 'none'
    )

    // TAGS
    data.tags.forEach((tag) => {
      formData.append('processors[ImportService::Processors::Tags][tag][]', tag)
    })

    // EVENT PROCESSOR FIELDS
    if (context._backbone_type === 'Events.Models.Event') {
      formData.append(
        'processors[ImportService::Processors::EventRegistration][response]',
        data.addToEventData.responseType
      )

      formData.append(
        'processors[ImportService::Processors::EventRegistration][attendance]',
        data.addToEventData.attendance
      )
    }

    const marketingPolicyPublished = marketingPolicy?.published
    const dataProcessingConsentPublished = dataProcessingPolicy?.published
    const consentData = data.consentData

    // POLICY AGREEMENT DETAILS
    if (marketingPolicyPublished || dataProcessingConsentPublished) {
      formData.append(
        'processors[ImportService::Processors::PoliciesAcceptance][policies][consent_type]',
        consentData.consentType
      )
      if (consentData.consentType === 'same') {
        formData.append(
          'processors[ImportService::Processors::PoliciesAcceptance][policies][source_of_consent]',
          consentData.sourceOfConsent
        )
        formData.append(
          'processors[ImportService::Processors::PoliciesAcceptance][policies][date_of_consent]',
          consentData.dateOfConsent
        )
      }
    }

    // MARKETING POLICY
    if (marketingPolicyPublished) {
      const marketingPolicyId = marketingPolicy.id

      if (
        ['accepted', 'doesNotWant'].includes(consentData.marketingAgreement)
      ) {
        formData.append(
          `processors[ImportService::Processors::PoliciesAcceptance][policies][${marketingPolicyId}][accepted]`,
          consentData.consentType === 'same' ? 'true' : ''
        )

        Object.keys(marketingPolicy.channels).forEach((channel) => {
          if (marketingPolicy.channels[channel]) {
            formData.append(
              `processors[ImportService::Processors::PoliciesAcceptance][policies][${marketingPolicyId}][options][${channel}]`,
              consentData.marketingAgreement === 'doesNotWant'
                ? 'false'
                : consentData.marketingOptions[channel].toString()
            )
          }
        })
      } else {
        formData.append(
          `processors[ImportService::Processors::PoliciesAcceptance][policies][${marketingPolicyId}][accepted]`,
          consentData.consentType === 'same' ? 'no' : ''
        )
      }
    }

    communicationPolicies.forEach((communicationPolicyVersion) => {
      if (!communicationPolicyVersion.published) return

      const currentPolicyData = consentData[communicationPolicyVersion.id] || {}
      const currentPolicyId = communicationPolicyVersion.id

      if (
        ['accepted', 'doesNotWant'].includes(
          currentPolicyData.communicationAgreement
        )
      ) {
        formData.append(
          `processors[ImportService::Processors::PoliciesAcceptance][policies][${currentPolicyId}][accepted]`,
          consentData.consentType === 'same' ? 'true' : ''
        )

        Object.keys(communicationPolicyVersion.channels).forEach((channel) => {
          if (communicationPolicyVersion.channels[channel]) {
            formData.append(
              `processors[ImportService::Processors::PoliciesAcceptance][policies][${currentPolicyId}][options][${channel}]`,
              currentPolicyData.communicationAgreement === 'doesNotWant'
                ? 'false'
                : (
                    currentPolicyData.communicationOptions[channel] || false
                  ).toString()
            )
          }
        })
      } else {
        formData.append(
          `processors[ImportService::Processors::PoliciesAcceptance][policies][${currentPolicyId}][accepted]`,
          consentData.consentType === 'same' ? 'no' : ''
        )
      }
    })

    // DATA PROCESSING CONSENT
    if (dataProcessingConsentPublished) {
      formData.append(
        `processors[ImportService::Processors::PoliciesAcceptance][policies][${dataProcessingPolicy.id}][accepted]`,
        consentData.consentType === 'same'
          ? consentData.dataProcessingConsent
            ? 'true'
            : 'no'
          : ''
      )
    }

    data.mappings.forEach((mapping) => {
      formData.append('mapping[][header]', mapping.header)
      formData.append('mapping[][field]', mapping.field || '')
    })
  } catch (err) {
    console.error(err)
    throw new ProfileImportFormDataError(err)
  }

  return formData
}

export default getProfileImportFormData

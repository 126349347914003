import qs from 'qs'

function getStartApplicationLink(product, applicationForm) {
  if (!applicationForm || !product) {
    return
  }

  return `/templates/${applicationForm?.id}/start?${
    applicationForm.context_type === 'Institutions::Institution'
      ? qs.stringify({
          context: {
            type: product?.context_type,
            id: product?.context_id
          }
        })
      : ''
  }`
}

export default getStartApplicationLink

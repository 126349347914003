import 'css-chunk:src/apps/ContentPages/components/navigation/secondary-navigation/link-group/styles.module.scss';export default {
  "react-app": "react-app",
  "container": "_container_2hq07_1",
  "parentLink": "_parentLink_2hq07_6",
  "accordionToggle": "_accordionToggle_2hq07_13",
  "opened": "_opened_2hq07_17",
  "arrow": "_arrow_2hq07_21",
  "mobileArrowContainer": "_mobileArrowContainer_2hq07_29",
  "mobileArrow": "_mobileArrow_2hq07_29",
  "openedArrow": "_openedArrow_2hq07_38",
  "folder": "_folder_2hq07_42"
};
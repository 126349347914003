import React from 'react'

import { useImportErrors } from 'apps/Imports/contexts/import-document'
import AllFieldsSection from './all-fields-section'
import ErrorSection from './error-section'
import RequiredFieldsSection from './required-fields-section'

export default function MappingStep() {
  const error = useImportErrors()

  if (error) return <ErrorSection />

  return (
    <>
      <RequiredFieldsSection />
      <AllFieldsSection />
    </>
  )
}

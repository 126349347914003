import React from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'

import {
  AlertFullIcon,
  Button,
  Modal,
  ModalButtons,
  ModalTitle,
  Text
} from '@fullfabric/alma-mater'

import deleteEmailTemplate from 'shared/api/deleteEmailTemplate'

const TEMPLATE_TABLE_QUERY_KEY_PART = [
  'tableDataFetch',
  '/api/email_templates/shared/tabular'
]

export default function DeleteModal({ isOpen, targetId, handleClose }) {
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  const deleteTemplateMutation = useMutation(
    ({ templateId }) => deleteEmailTemplate(templateId),
    {
      onSuccess: () => {
        const targetQueryData = queryClient.getQueriesData(
          TEMPLATE_TABLE_QUERY_KEY_PART
        )
        const targetQuery =
          targetQueryData && targetQueryData[targetQueryData.length - 1]

        if (targetQuery) {
          const targetQueryKey = targetQuery[0]
          const oldPaginatedData = targetQuery[1]

          const updatedPagesArray =
            oldPaginatedData.pages.map((page) => ({
              ...page,
              data: page.data.filter((tmpl) => tmpl.id !== targetId)
            })) || []

          queryClient.setQueryData(targetQueryKey, (data) => {
            return {
              pages: updatedPagesArray,
              pageParams: data.pageParams
            }
          })

          queryClient.invalidateQueries(targetQueryKey)
        }

        handleClose()
      }
    }
  )

  return (
    <Modal
      role='dialog'
      aria-labelledby='delete-modal-title'
      size='small'
      isOpen={isOpen}
      header={
        <ModalTitle
          title={<span id='delete-modal-title'>{t('Delete template')}</span>}
          onClose={handleClose}
          iconProps={{
            icon: AlertFullIcon,
            color: 'text-alert'
          }}
        />
      }
      footer={
        <ModalButtons>
          <Button
            onClick={(evt) => {
              evt.stopPropagation()
              handleClose()
            }}
            disabled={deleteTemplateMutation.isLoading}
          >
            {t('Cancel')}
          </Button>
          <Button
            negative
            primary
            loading={deleteTemplateMutation.isLoading}
            onClick={(evt) => {
              evt.stopPropagation()
              deleteTemplateMutation.mutate({ templateId: targetId })
            }}
          >
            {t('Delete template')}
          </Button>
        </ModalButtons>
      }
    >
      <Text>{t('Are you sure you want to delete this template?')}</Text>

      <Text>
        {t(
          'If you proceed there will be no way to recover the deleted template.'
        )}
      </Text>

      {deleteTemplateMutation.isError ? (
        <Text fontColor='text-alert' type='f5' marginTop='default'>
          {t(
            'There was an error, please try again in a few seconds. If the issue persists, please contact us.'
          )}
        </Text>
      ) : null}
    </Modal>
  )
}

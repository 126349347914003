import { zipObject } from 'lodash'

import uploadsInReduxStoreHelper from './uploadsInReduxStoreHelper'

export default async function storeDataToSubmissionFormat(storeData) {
  const fieldNames = Object.keys(storeData)
  const fieldValuesInStoreFormat = fieldNames.map(
    // assures same order of keys and values
    (fieldName) => storeData[fieldName]
  )
  const fieldValues = await Promise.all(
    fieldValuesInStoreFormat.map(storeValueToSubmissionFormat)
  )

  return zipObject(fieldNames, fieldValues)
}

export async function storeValueToSubmissionFormat(valueInStore) {
  if (uploadsInReduxStoreHelper.isFileUploadStoreData(valueInStore)) {
    return await uploadsInReduxStoreHelper.storeToFileUploadValue(valueInStore)
  }

  return valueInStore
}

import React from 'react'

import { Visible } from '@fullfabric/alma-mater'

import FeedbackMessageProvider from 'apps/ContentPages/components/feeback-message-provider'
import PageLayout from 'apps/ContentPages/components/page-layout'
import PaymentsPageComponent from 'apps/ContentPages/components/payments-page'
import SidebarLayout from 'apps/ContentPages/components/sidebar-layout'

export default function PaymentsPage() {
  return (
    <FeedbackMessageProvider>
      <SidebarLayout>
        <Visible xs sm md>
          <PageLayout.Header>
            <PageLayout.NavigationInHeader />
          </PageLayout.Header>
        </Visible>
        <PaymentsPageComponent />
      </SidebarLayout>
    </FeedbackMessageProvider>
  )
}

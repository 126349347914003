import React from 'react'
import { Trans } from 'react-i18next'

import { SuccessBigIcon } from '@fullfabric/alma-mater'

import { useAppData } from 'shared/contexts/AppData'

import PolicyAcceptanceFeedback from 'apps/Policies/components/PolicyAcceptanceFeedback'

const MarketingPolicyAcceptedPage = () => {
  const { profile } = useAppData()

  const isProfileActive = !!(profile && profile.activated_at)

  return (
    <PolicyAcceptanceFeedback
      icon={<SuccessBigIcon color='green-600' width={40} height={40} />}
      title={<Trans>Thank you</Trans>}
      subtitle={<Trans>You have updated your marketing preferences.</Trans>}
      nextActionText={
        isProfileActive ? (
          <Trans>Log in</Trans>
        ) : (
          <Trans>Finish your registration</Trans>
        )
      }
      onNextClick={() =>
        window.location.replace(isProfileActive ? '/login' : '/signup')
      }
    />
  )
}

export default MarketingPolicyAcceptedPage

import { utils } from '@fullfabric/public-api'

const getApplic = async (_key, id) => {
  const response = await fetch(`/api/applics/${id}`, {
    credentials: 'same-origin'
  })

  return await utils.checkResponse(response)
}

export default getApplic

import { utils } from '@fullfabric/public-api'

/**
 * Returns an estimate for the size of an export.
 *
 * @param {String} type The type of export (e.g.: 'utm', 'segment', etc.)
 * @param {Object} opts Options for the export (optional).
 * @returns A `{ size: Number }` object if successful
 */
export async function exportSize(type, opts = {}) {
  const response = await fetch('/admin/api/exports/size', {
    credentials: 'same-origin',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      export: { type, opts }
    })
  })

  return await utils.checkResponse(response)
}

/**
 * Creates a new async Export.
 *
 * @param {String} type The type of export (e.g.: 'utm', 'segment', etc.)
 * @param {String} format The format of the export file (e.g.: 'csv', 'xlsx', etc.)
 * @param {Object} opts Options for the export (optional).
 * @returns A `{ data: export }` object if successful
 */
export async function createExport(type, format, opts = {}) {
  const response = await fetch('/admin/api/exports', {
    credentials: 'same-origin',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      export: { format, type, opts }
    })
  })

  return await utils.checkResponse(response)
}

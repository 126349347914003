{
  "A word by itself is easy to guess": "Ett ord i sig är lätt att gissa",
  "Add \"{{val}}\"": "Lägg till \"{{val}}\"",
  "Add another word or two. Uncommon words are better.": "Lägg till ett annat ord eller två. Mindre vanliga ord är bättre.",
  "All-uppercase is almost as easy to guess as all-lowercase": "All upplaga är nästan lika lätt att gissa som helt små bokstäver",
  "Almost there": "Nästan där",
  "Already have an account?": "Har du redan ett konto?",
  "Avoid dates and years that are associated with you": "Undvik datum och år som är förknippade med dig",
  "Avoid recent years": "Undvik de senaste åren",
  "Avoid repeated words and characters": "Undvik upprepade ord och tecken",
  "Avoid sequences": "Undvik sekvenser",
  "Avoid years that are associated with you": "Undvik år som är förknippade med dig",
  "Back to login": "Återgå till inloggningssidan",
  "Cancel": "Annullera",
  "Capitalization doesn't help very much": "Kapitalisering hjälper inte mycket",
  "Common names and surnames are easy to guess": "Vanliga namn och efternamn är lätta att gissa",
  "Continue": "Fortsätta",
  "Create an account": "Skapa ett konto",
  "Create new account": "Skapa nytt konto",
  "Data Protection Regulation": "Data Protection Regulation",
  "Dates are often easy to guess": "Datum är ofta lätt att gissa",
  "Didn't receive the sms?": "Fick inte sms?",
  "E-mail address": "E-postadress",
  "First name": "Förnamn",
  "For security reasons account activation links are perishable. You can get a new activation link by filling the sign up form.": "Kontoaktiveringslänkar är tidsbundna av säkerhetsskäl. Du kan få en ny aktiveringslänk genom att fylla i registreringsformuläret.",
  "Forgot your password?": "Glömt ditt lösenord?",
  "Great, your account has been created!": "Bra, ditt konto har skapats!",
  "I accept the privacy policy": "I accept the privacy policy",
  "Institutional login": "Institutional login",
  "Last name": "Efternamn",
  "Link existing account": "Länka till befintligt konto",
  "LinkedIn": "LinkedIn",
  "Log in": "Logga in",
  "Log in securely": "Logga in säkert",
  "Names and surnames by themselves are easy to guess": "Namnen och efternamnen i sig är lätt att gissa",
  "New Password": "Nytt lösenord",
  "No Results": "Inga resultat",
  "No need for symbols, digits, or uppercase letters": "Inget behov av symboler, siffror eller stora bokstäver",
  "Not registered?": "Inte registrerad?",
  "Nothing found for": "Nothing found for",
  "Or use these alternatives": "Or use these alternatives",
  "Password": "Lösenord",
  "Password Reset": "Återställning av lösenord",
  "Please accept the privacy policy to continue": "Please accept the privacy policy to continue",
  "Please check your email for instructions on resetting your password": "Kontrollera din e-post för instruktioner om att återställa lösenordet",
  "Please check your mailbox for instructions on verifying your registration.": "Kontrollera din e-post för instruktioner om hur du verifierar din registrering.",
  "Please choose a password": "Välj ett lösenord",
  "Please confirm your account details below": "Vänligen bekräfta dina kontouppgifter nedan",
  "Please contact your university's IT support department in order to get a new activation link to access Full Fabric.": "Kontakta universitetets IT-stöd för att få en ny aktiveringslänk för att få tillgång till Full Fabric.",
  "Please type a new password.": "Vänligen skriv ett nytt lösenord.",
  "Predictable substitutions like \"@\" instead of \"a\" don't help very much": "Förutsägbara substitutioner som \"@\" istället för \"a\" hjälper inte mycket",
  "Predictable substitutions like '@' instead of 'a' don't help very much": "Förutsägbara substitutioner som '@' istället för 'a' hjälper inte mycket",
  "Privacy policy": "Privacy policy",
  "Recent years are easy to guess": "De senaste åren är lätt att gissa",
  "Repeats like \"aaa\" are easy to guess": "Upprepningar som \"aaa\" är lätta att gissa",
  "Repeats like \"abcabcabc\" are only slightly harder to guess than \"abc\"": "Upprepningar som \"abcabcabc\" är bara lite svårare att gissa än \"abc\"",
  "Reset password": "Reset password",
  "Reset password and sign in": "Återställ lösenord och logga in",
  "Reset your password below.": "Återställ lösenordet nedan.",
  "Return to log in page": "Återgå till inloggningssidan",
  "Reversed words aren't much harder to guess": "Omvända ord är inte mycket svårare att gissa",
  "Send again": "Skicka igen",
  "Sequences like abc or 6543 are easy to guess": "Sekvenser som abc eller 6543 är lätta att gissa",
  "Set password and sign in": "Ange lösenord och logga in",
  "Short keyboard patterns are easy to guess": "Korta tangentbordsmönster är lätta att gissa",
  "Sign in": "Logga in",
  "Sign up": "Registrera dig",
  "Skip to main content": "Skip to main content",
  "Straight rows of keys are easy to guess": "Raka nycklar är lätt att gissa",
  "Suggestions": "Förslag",
  "Thank you.": "Tack.",
  "There was a server problem. Please try again.": "Det uppstod ett serverproblem. Var god försök igen.",
  "This is a top-10 common password": "Detta är ett av de 10 vanligaste lösenordet",
  "This is a top-100 common password": "Detta är ett av de 100 vanligaste lösenordet",
  "This is a very common password": "Detta är ett mycket vanligt lösenord",
  "This is similar to a commonly used password": "Det här liknar ett vanligt använd lösenord",
  "Two Factor Authentication Token": "Tvåfaktorautentiseringstoken",
  "Two step verification": "Ttvåstegsverifiering",
  "Use a few words, avoid common phrases": "Använd några ord, undvik vanliga fraser",
  "Use a longer keyboard pattern with more turns": "Använd ett längre tangentbordsmönster med fler varv",
  "Use one of these alternatives to signup": "Use one of these alternatives to signup",
  "Use one of these methods to log in": "Use one of these methods to log in",
  "We sent a sms to the number <1>{{phoneNumber}}</1> with the verification code": "Vi skickade ett sms till numret <1>{{phoneNumber}}</1> med verifieringskoden",
  "Welcome": "Välkommen",
  "Your activation link has expired.": "Din aktiveringslänk har gått ut.",
  "Your password has expired.": "Ditt lösenord har gått ut",
  "Your password is good": "Lösenordet är bra",
  "Your password is reasonably adequate": "Lösenordet är nöjaktigt",
  "Your password is too weak": "Lösenordet är för svagt",
  "or e-mail": "eller e-post",
  "or link an existing account": "eller länka ett befintligt konto",
  "{{minAlpha}} letters": "{{minAlpha}} bokstav",
  "{{minDigits}} digits": "{{minDigits}} siffra",
  "{{minLength}} characters": "{{minLength}} tecken",
  "{{minSymbols}} symbols": "{{minSymbols}} symbol(s)",
  "Password must be at least 6 characters long": "Lösenordet måste vara minst 6 bokstäver långt",
  "Password must be at least {{minLength}} characters long": "Lösenordet måste vara minst {{minLength}} bokstäver långt",
  "Password must have at least {{count}} characters": "Lösenordet måste ha minst {{count}} tecken",
  "Password must have at least {{count}} characters_plural": "Lösenordet måste ha minst {{count}} tecken",
  "Password must have at least {{count}} digit": "Lösenordet måste ha minst {{count}} siffra",
  "Password must have at least {{count}} digit_plural": "Lösenordet måste ha minst {{count}} siffra",
  "Password must have at least {{count}} symbol": "Password must have at least {{count}} symbol",
  "Password must have at least {{count}} symbol_plural": "Password must have at least {{count}} symbols",
  "Password was already used": "Password was already used",
  "Thank you. Please log in below.": "Thank you. Please log in below.",
  "This field is required": "This field is required",
  "Privacy Policy": "Privacy Policy",
  "Link to an existing account": "Link to an existing account",
  "There are fields with errors.": "There are fields with errors.",
  "Please correct the fields with errors.": "Please correct the fields with errors.",
  "We sent a sms to the number {{ phoneNumber }} with the verification code": "Vi skickade ett sms till numret {{phoneNumber}} med verifieringskoden"
}

import React from 'react'
import { useController, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

import { Field } from '@fullfabric/alma-mater'
import { useCurrentUser } from '@fullfabric/authorization-officer'

import getStaffOptions from 'shared/api/getStaffOptions'

import getEvaluationTemplates from 'apps/Evaluations/api/getEvaluationTemplates'

const AssigneeField = ({ name, evalTemplateFieldName }) => {
  const { t } = useTranslation()
  const { control, formState, watch } = useFormContext()
  const { errors } = formState

  const user = useCurrentUser()
  const isSupportStaff = user?.teams_names?.some(
    (teamName) => teamName === 'Support'
  )
  const { data: staffProfilesData, isLoading: isLoadingStaffProfilesData } =
    useQuery(
      ['getStaffProfiles', isSupportStaff],
      () => getStaffOptions({ includeSupportStaff: isSupportStaff }),
      { enabled: !!user }
    )

  const { data: evalTemplatesData } = useQuery(
    ['getEvaluationTemplates'],
    getEvaluationTemplates
  )

  const selectedEvaluationTemplateId = watch(evalTemplateFieldName)
  const isEvaluatorRequired =
    selectedEvaluationTemplateId &&
    evalTemplatesData &&
    evalTemplatesData.find(
      (template) => template.id === selectedEvaluationTemplateId
    ).team_evaluation === false

  const {
    field: { ref: evaluatorInputRef, ...evaluatorProps }
  } = useController({
    name,
    control,
    rules: {
      required: {
        value: isEvaluatorRequired,
        message: t("Can't be blank")
      }
    }
  })

  return (
    <Field
      {...evaluatorProps}
      id='new-evaluation-assignee-field'
      required={isEvaluatorRequired}
      inputRef={evaluatorInputRef}
      type='autocomplete'
      label={t('Assign to an evaluator')}
      error={errors[name]?.message}
      inputOptions={{
        inModal: true,
        options: staffProfilesData?.map((template) => ({
          value: template.value,
          label: template.label
        })),
        isLoading: isLoadingStaffProfilesData
      }}
    />
  )
}

export default AssigneeField

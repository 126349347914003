import React from 'react'
import { useQuery } from 'react-query'
import { useHistory, useParams } from 'react-router'

import getImport from 'apps/Imports/api/get-import'
import ImportLoading from 'apps/Imports/components/import-loading'
import PageContainer from 'apps/Imports/components/page-container'
import { ProductImportStepsProvider } from 'apps/Imports/components/product-import/steps-provider'
import { ProfileImportStepsProvider } from 'apps/Imports/components/profile-import/steps-provider'
import SidebarTitle from 'apps/Imports/components/sidebar-title'
import { ImportDocumentProvider } from 'apps/Imports/contexts/import-document'
import { IMPORT_TYPES } from 'apps/Imports/utils/constants'
import { PRODUCT_IMPORT_STEPS } from 'apps/Imports/utils/product-import/constants'
import { PROFILE_IMPORT_STEPS } from 'apps/Imports/utils/profile-import/constants'

import styles from './styles.module.scss'

const REFETCH_INTERVAL = 10000

const ImportLoadingPage = () => {
  const { documentId } = useParams()
  const history = useHistory()
  const { data: importData, isLoading } = useQuery(
    ['getImport', documentId],
    async () => await getImport(documentId),
    {
      refetchInterval: REFETCH_INTERVAL
    }
  )

  React.useEffect(() => {
    if (!isLoading && importData?.document.state === 'imported') {
      history.push(`/import/${documentId}`)
    }
  }, [importData, documentId, history, isLoading])

  if (isLoading) return null

  const importType = importData?.document?._ruby_type
  const isProfileImport = importType === IMPORT_TYPES.PROFILE
  const StepsProvider = isProfileImport
    ? ProfileImportStepsProvider
    : ProductImportStepsProvider
  const lastStep = isProfileImport
    ? PROFILE_IMPORT_STEPS.REPORT
    : PRODUCT_IMPORT_STEPS.REPORT

  return (
    <StepsProvider importType={importType}>
      <ImportDocumentProvider initialDocument={importData?.document}>
        <PageContainer
          contentColumns={6}
          columnOffset={3}
          className={styles.pageContainer}
          sidebarTitle={<SidebarTitle />}
        >
          <div className={styles.container}>
            <ImportLoading lastStep={lastStep} />
          </div>
        </PageContainer>
      </ImportDocumentProvider>
    </StepsProvider>
  )
}

export default ImportLoadingPage

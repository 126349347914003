import React from 'react'
import { Trans } from 'react-i18next'

import { Button } from '@fullfabric/alma-mater'

import DeleteSubjectModal from 'apps/StudyPlans/components/DeleteSubjectModal'
import EditSubjectPropagationModal from 'apps/StudyPlans/components/EditSubjectPropagationModal'
import { useStudyPlan } from 'apps/StudyPlans/contexts/StudyPlanData'
import { useSubjectEditionData } from 'apps/StudyPlans/contexts/SubjectEditionData'
import useOnSaveSubjectClick from './useOnSaveSubjectClick'
import useSaveSubjectMutation from './useSaveSubjectMutation'
import { getDataToUpdate } from './utils'

const SaveChangesButton = ({ sectionName, fieldsToUpdate }) => {
  const studyPlan = useStudyPlan()
  const subjectData = useSubjectEditionData()
  const [openDeleteFailedModal, setOpenDeleteFailedModal] =
    React.useState(false)
  const [openPropagationModal, setOpenPropagationModal] = React.useState(false)

  const { mutate: updateSubjectMutation, isLoading } = useSaveSubjectMutation({
    sectionName,
    setOpenPropagationModal
  })

  const { onClickSave, isReloadingSubject } = useOnSaveSubjectClick({
    sectionName,
    fieldsToUpdate,
    setOpenDeleteFailedModal,
    setOpenPropagationModal,
    updateSubjectMutation
  })

  return (
    <>
      <EditSubjectPropagationModal
        isOpen={openPropagationModal}
        onSubmit={(shouldApplyChangesToTranscripts, dataToUpdate) => {
          updateSubjectMutation({
            studyPlanId: studyPlan.id,
            dataToUpdate,
            shouldApplyChangesToTranscripts
          })
        }}
        targetData={getDataToUpdate(subjectData, fieldsToUpdate)}
        onCancel={() => setOpenPropagationModal(false)}
      />
      <DeleteSubjectModal
        subject={subjectData}
        isOpen={openDeleteFailedModal}
        onCancel={() => setOpenDeleteFailedModal(false)}
        cannotDelete
      />
      <Button
        primary
        size='small'
        loading={isLoading}
        disabled={isReloadingSubject}
        onClick={onClickSave}
      >
        <Trans>Save changes</Trans>
      </Button>
    </>
  )
}

export default SaveChangesButton

import * as React from 'react'

import { Badge } from '@fullfabric/alma-mater'

import styles from '../styles.module.scss'

const ProductTypeCell = ({ value: label, row, data }) => {
  const productType = data[row.index].type
  const isCourse = productType === 'Institutions::Course'
  const badgeType = isCourse ? 'grey' : 'purple'

  return (
    <div className={styles.dataCell}>
      <Badge type={badgeType}>{label}</Badge>
    </div>
  )
}

export default ProductTypeCell

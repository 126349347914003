import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

import { Col, Loading, Row, Text } from '@fullfabric/alma-mater'

import getApplicsBy from 'apps/MergeProfiles/api/getApplicsBy'
import NothingToMergeCard from 'apps/MergeProfiles/components/NothingToMergeCard'
import { useMergeData } from 'apps/MergeProfiles/contexts/MergeData'
import ApplicSelectionRow from './ApplicSelectionRow'

const ApplicationsSelector = () => {
  const { t } = useTranslation()

  const {
    mergeData: { sourceProfile, targetProfile }
  } = useMergeData()

  const {
    data: sourceProfileApplics,
    isLoading: isSourceProfileApplicsLoading
  } = useQuery(['applicsBy', sourceProfile.id], () =>
    getApplicsBy(sourceProfile.id)
  )

  const {
    data: targetProfileApplics,
    isLoading: isTargetProfileApplicsLoading
  } = useQuery(['applicsBy', targetProfile.id], () =>
    getApplicsBy(targetProfile.id)
  )

  if (isSourceProfileApplicsLoading || isTargetProfileApplicsLoading) {
    return <Loading />
  }

  if (
    !isSourceProfileApplicsLoading &&
    (!sourceProfileApplics || sourceProfileApplics.length === 0)
  ) {
    return (
      <NothingToMergeCard
        title={t('No applications to be merged')}
        description={t('The duplicate profile doesn’t have any applications')}
      />
    )
  }

  return (
    <div>
      <Row className='marginBottom-default' gutterWidth={24}>
        <Col xs={6}>
          <Text type='f4' fontColor='yellow-800'>
            <Trans>Duplicate profile</Trans>
          </Text>
        </Col>

        <Col xs={6}>
          <Text type='f4' fontColor='text-info'>
            <Trans>Main profile</Trans>
          </Text>
        </Col>
      </Row>

      <div role='rowgroup'>
        {sourceProfileApplics.map((applic) => {
          const targetProfileApplic = targetProfileApplics?.find(
            (tApplic) => tApplic.template_id === applic.template_id
          )

          return (
            <ApplicSelectionRow
              key={applic.id}
              applic={applic}
              profile={sourceProfile}
              targetProfileApplic={targetProfileApplic}
              targetProfile={targetProfile}
            />
          )
        })}
      </div>
    </div>
  )
}

export default ApplicationsSelector

import React from 'react'
import { Trans } from 'react-i18next'
import { useMutation } from 'react-query'

import {
  Button,
  Field,
  Modal,
  ModalButtons,
  ModalTitle
} from '@fullfabric/alma-mater'

import updateElectiveGroup from 'apps/StudyPlans/api/updateElectiveGroup'
import {
  useElectiveGroupsForSelectedPath,
  useSelectedPath
} from 'apps/StudyPlans/contexts/SelectedPath'
import {
  useSetStudyPlan,
  useStudyPlan
} from 'apps/StudyPlans/contexts/StudyPlanData'
import replaceStudyPlanPath from 'apps/StudyPlans/utils/replaceStudyPlanPath'

const ERROR_DESCRIPTION_MAP = {
  alreadyExists: <Trans>Name already exists</Trans>,
  blank: <Trans>Can't be blank</Trans>
}

const RenameElectiveGroupModal = ({ isOpen, electiveGroup, onCancel }) => {
  const studyPlan = useStudyPlan()
  const setStudyPlan = useSetStudyPlan()
  const selectedPath = useSelectedPath()

  const allElectiveGroups = useElectiveGroupsForSelectedPath()
  const [groupName, setGroupName] = React.useState(() => electiveGroup.name)
  const [error, setError] = React.useState(null)

  React.useEffect(() => {
    if (!groupName?.trim()) {
      setError('blank')
      return
    }
    const nameIsAlreadyTaken = allElectiveGroups
      .filter((group) => group.id !== electiveGroup.id)
      .map((group) => group.name)
      .includes(groupName?.trim())

    setError(nameIsAlreadyTaken ? 'alreadyExists' : null)
  }, [groupName, allElectiveGroups, setError, electiveGroup])

  React.useEffect(() => {
    setGroupName(electiveGroup.name)
  }, [electiveGroup.name])

  const { mutate: renameGroupMutation, isLoading } = useMutation(
    ({ studyPlanId, pathId, data }) => {
      return updateElectiveGroup(studyPlanId, pathId, data)
    },
    {
      onSuccess: (updatedPath) => {
        const updatedStudyPlan = replaceStudyPlanPath(studyPlan, updatedPath)
        setStudyPlan(updatedStudyPlan)

        onCancel && onCancel()
      }
    }
  )

  const onSaveChanges = React.useCallback(() => {
    if (error) return

    if (!groupName || groupName.trim() === '') {
      setError('blank')
    } else {
      setError(null)
      renameGroupMutation({
        studyPlanId: studyPlan.id,
        pathId: selectedPath.id,
        data: {
          group_id: electiveGroup.id,
          name: groupName
        }
      })
    }
  }, [
    error,
    groupName,
    renameGroupMutation,
    setError,
    studyPlan,
    selectedPath,
    electiveGroup
  ])

  return (
    <Modal
      size='small'
      isOpen={isOpen}
      header={
        <ModalTitle
          title={<Trans>Rename elective group</Trans>}
          onClose={onCancel}
        />
      }
      footer={
        <ModalButtons>
          <Button onClick={onCancel} disabled={isLoading}>
            <Trans>Cancel</Trans>
          </Button>
          <Button primary onClick={onSaveChanges} loading={isLoading}>
            <Trans>Save changes</Trans>
          </Button>
        </ModalButtons>
      }
      onClose={onCancel}
    >
      <Field
        id='elective_group_name'
        type='text'
        label={<Trans>Elective group name</Trans>}
        value={groupName}
        error={ERROR_DESCRIPTION_MAP[error]}
        onChange={(_, value) => setGroupName(value)}
        required
      />
    </Modal>
  )
}

export default RenameElectiveGroupModal

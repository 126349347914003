import { utils } from '@fullfabric/public-api'

const getThemes = async () => {
  const response = await fetch(`/api/institutions/themes`, {
    credentials: 'same-origin'
  })

  return await utils.checkResponse(response)
}

export default getThemes

import React from 'react'

import getMainTextWidget from 'apps/ContentPages/utils/get-main-text-widget'
import getPageLocalizedContent from 'apps/ContentPages/utils/get-page-localized-content'
import getPageMainTextContent from 'apps/ContentPages/utils/get-page-main-text-content'
import { useLocale } from '../../../../shared/contexts/Locale'
import { CurrentPageDataContext } from './provider'

const usePageData = () => {
  const context = React.useContext(CurrentPageDataContext)

  if (typeof context === 'undefined') {
    throw new Error('usePageData must be within a CurrentPageDataProvider')
  }

  return context
}

function usePageContent() {
  const { locale } = useLocale()
  const { isLoading, page } = usePageData()

  if (isLoading) {
    return { isLoading: true }
  }

  if (!page) {
    return { isLoading: false }
  }

  return {
    isLoading: false,
    content: page && getPageLocalizedContent(page, locale)
  }
}

const usePageMainTextContent = () => {
  const { locale } = useLocale()
  const { isLoading, page } = usePageData()

  if (isLoading) {
    return { isLoading: true }
  }

  if (!page) {
    return { isLoading: false }
  }

  return { content: page && getPageMainTextContent(page, locale) }
}

function usePageWidgets() {
  const { isLoading, content } = usePageContent()

  if (isLoading || !content) {
    return null
  }

  const mainTextWidget = getMainTextWidget(content.widgets)
  const otherWidgets = mainTextWidget
    ? content.widgets.filter((widget) => widget.id !== mainTextWidget.id)
    : content.widgets

  return otherWidgets
}

export { usePageData, usePageContent, usePageMainTextContent, usePageWidgets }

.react-app ._uploadFile_l3z3e_1 {
  opacity: 0;
  top: 0;
  left: 0px;
  height: 100%;
  width: 100%;
  cursor: pointer;
  position: absolute !important;
}

.react-app ._uploadContainer_l3z3e_11 {
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
}

.react-app ._uploadButton_l3z3e_18 {
  overflow: hidden;
  position: relative;
}

.react-app ._filesList_l3z3e_23 {
  display: flex;
  gap: 10px;
  flex-direction: column;
  width: 100%;
}
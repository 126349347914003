import { utils } from '@fullfabric/public-api'

const unfollowProfile = async (profile) => {
  const response = await fetch(`/api/profiles/${profile.id}/unfollow`, {
    credentials: 'same-origin',
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' }
  })

  return await utils.checkResponse(response)
}

export default unfollowProfile

.react-app ._row_v3y5l_1:hover {
  cursor: pointer;
}
.react-app ._row_v3y5l_1 p {
  margin: 0 !important;
}

.react-app ._status_v3y5l_8 {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.react-app ._status_v3y5l_8._small_v3y5l_13 {
  margin-top: var(--space-small);
  margin-bottom: var(--space-default);
}

.react-app ._rowSmall_v3y5l_18 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: var(--border-base-lighter);
}
.react-app ._rowSmall_v3y5l_18._last_v3y5l_24 {
  border-bottom: none;
}
.react-app ._rowSmall_v3y5l_18 ._contentCol_v3y5l_27 {
  margin-top: var(--space-default) !important;
  padding-bottom: var(--space-default) !important;
  padding-left: var(--space-default) !important;
  padding-right: var(--space-default) !important;
}
.react-app ._rowSmall_v3y5l_18 ._contentCol_v3y5l_27._first_v3y5l_33 {
  margin-top: 0 !important;
}
.react-app ._rowSmall_v3y5l_18 ._arrowCol_v3y5l_36 {
  padding-right: var(--space-default);
}

.react-app ._classOfIdsRow_v3y5l_40 {
  flex-direction: column;
  margin-top: var(--space-default);
}

.react-app ._colNoGutter_v3y5l_45 {
  padding-left: 0;
  padding-right: 0;
}

.react-app ._paddingWithBorder_v3y5l_50 {
  margin-top: var(--space-default) !important;
  padding-bottom: var(--space-default) !important;
  padding-left: var(--space-default) !important;
  padding-right: var(--space-default) !important;
}

.react-app ._subTitle_v3y5l_57 {
  margin-top: var(--space-tiny);
}

.react-app ._smallest_v3y5l_61 {
  width: 1%;
}

.react-app ._label_v3y5l_65 {
  margin-right: var(--space-more);
}

.react-app ._centeredHolder_v3y5l_69 {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.react-app ._emptyMessageMobile_v3y5l_75 {
  text-align: center;
}

.react-app ._loadingMobile_v3y5l_79 {
  margin-top: var(--space-huge);
}

.react-app ._informationIcon_v3y5l_83 {
  margin-top: var(--space-huge);
}
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'

import {
  AlertFullIcon,
  Button,
  Modal,
  ModalButtons,
  ModalTitle,
  Text
} from '@fullfabric/alma-mater'

import deleteSegment from 'apps/Segments/api/deleteSegment'

const SEGMENT_TABLE_QUERY_KEY_PART = ['tableDataFetch', '/api/segments_table']

function DeleteModal({ isOpen, targetId, handleClose }) {
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  const deleteSegmentMutation = useMutation(
    (segmentId) => deleteSegment(segmentId),
    {
      onSuccess: () => {
        const targetQueriesData = queryClient.getQueriesData(
          SEGMENT_TABLE_QUERY_KEY_PART
        )
        const targetQuery =
          targetQueriesData && targetQueriesData[targetQueriesData.length - 1]

        if (targetQuery) {
          const targetQueryKey = targetQuery[0]
          const oldPaginatedData = targetQuery[1]

          const updatedPagesArray =
            oldPaginatedData.pages.map((page) => ({
              ...page,
              data: page.data.filter((tmpl) => tmpl.id !== targetId)
            })) || []

          queryClient.setQueryData(targetQueryKey, (data) => {
            return {
              pages: updatedPagesArray,
              pageParams: data.pageParams
            }
          })

          queryClient.invalidateQueries(targetQueryKey)
        }

        handleClose()
      }
    }
  )

  return (
    <Modal
      role='dialog'
      aria-labelledby='delete-modal-title'
      size='small'
      isOpen={isOpen}
      header={
        <ModalTitle
          title={<span id='delete-modal-title'>{t('Delete segment')}</span>}
          onClose={handleClose}
          iconProps={{
            icon: AlertFullIcon,
            color: 'text-alert'
          }}
        />
      }
      footer={
        <ModalButtons>
          <Button
            onClick={(evt) => {
              evt.stopPropagation()
              handleClose()
            }}
            disabled={deleteSegmentMutation.isLoading}
          >
            {t('Cancel')}
          </Button>
          <Button
            negative
            primary
            loading={deleteSegmentMutation.isLoading}
            onClick={(evt) => {
              evt.stopPropagation()
              deleteSegmentMutation.mutate(targetId)
            }}
          >
            {t('Delete segment')}
          </Button>
        </ModalButtons>
      }
    >
      <Text>{t('Are you sure you want to delete this segment?')}</Text>
      <Text>
        {t(
          'If you proceed there will be no way to recover the deleted segment.'
        )}
      </Text>
      {deleteSegmentMutation.isError ? (
        <Text fontColor='text-alert' type='f5' marginTop='default'>
          {t(
            'There was an error, please refresh the page and try again. If the issue persists, please contact us.'
          )}
        </Text>
      ) : null}
    </Modal>
  )
}

export default DeleteModal

import React from 'react'
import { useFormContext } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'

import { Field } from '@fullfabric/alma-mater'
import { IfHasSetting } from '@fullfabric/authorization-officer'

import ExternalIdField from './ExternalIdField'
import ThemeField from './ThemeField'

const SubjectBasicsForm = () => {
  const { register, formState } = useFormContext()
  const { errors } = formState
  const { t } = useTranslation()

  const { ref: codeInputRef, ...codeInputProps } = register('code', {
    validate: (value) => {
      return value?.trim() ? null : t("Can't be blank")
    }
  })

  const { ref: nameInputRef, ...nameInputProps } = register('name', {
    validate: (value) => (value?.trim() ? null : t("Can't be blank"))
  })

  return (
    <div role='form' aria-label='subject creation form'>
      <Field
        required
        type='text'
        label={'Subject Code'}
        inputRef={codeInputRef}
        error={errors.code?.message}
        {...codeInputProps}
      />
      <Field
        required
        type='text'
        label={<Trans>Subject Name</Trans>}
        inputRef={nameInputRef}
        error={errors.name?.message}
        {...nameInputProps}
      />
      <IfHasSetting settingName='modules.core.submodules.institutions.features.themes.enable'>
        <ThemeField />
      </IfHasSetting>
      <IfHasSetting settingName='modules.core.submodules.institutions.features.external_id.enable'>
        <ExternalIdField />
      </IfHasSetting>
    </div>
  )
}

export default SubjectBasicsForm

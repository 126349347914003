.react-app ._pill_11w95_1 {
  display: inline-flex;
  align-items: center;
  padding: var(--space-tiny) var(--space-less);
  padding-left: var(--space-small);
  border-radius: 14px;
}

.react-app ._pill_11w95_1._mobile_11w95_9 {
  padding-top: 2px;
  padding-bottom: 2px;
}

.react-app ._pill_11w95_1._paid_11w95_14 {
  background-color: var(--ff-color-green-100);
}

.react-app ._pill_11w95_1._overdue_11w95_18 {
  background-color: var(--ff-color-red-000);
}
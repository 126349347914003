import React from 'react'

const MenuIcon = () => (
  <svg
    width='17'
    height='16'
    viewBox='0 0 17 16'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path d='M0.5 2H16.5V4H0.5V2Z' />
    <path d='M0.5 7H16.5V9H0.5V7Z' />
    <path d='M0.5 12H16.5V14H0.5V12Z' />
  </svg>
)

export default MenuIcon

import qs from 'qs'

import { utils } from '@fullfabric/public-api'

const getProfilesComparison = async (
  classes,
  states,
  withdrawn,
  timeframeInterval = 'weekly'
) => {
  const withdrawnParam = typeof withdrawn === 'boolean' ? { withdrawn } : {}
  const queryParams = qs.stringify(
    {
      cumulative: false,
      end: 'class_starts_on',
      xaxis: 'distance_to_class_start',
      classes: JSON.stringify(classes),
      states: JSON.stringify([states]),
      ...withdrawnParam
    },
    { arrayFormat: 'brackets' }
  )

  const response = await fetch(
    `/profiles/data/comparison/${timeframeInterval}?${queryParams}`,
    {
      credentials: 'same-origin'
    }
  )

  return await utils.checkResponse(response)
}

export default getProfilesComparison

import React from 'react'
import { Trans } from 'react-i18next'

export const GUEST_COUNT_OPTIONS = [
  { value: 0, label: <Trans>None</Trans> },
  { value: 1, label: '1' },
  { value: 2, label: '2' },
  { value: 3, label: '3' },
  { value: 4, label: '4' },
  { value: 5, label: '5' },
  { value: 'other', label: <Trans>Other</Trans> }
]

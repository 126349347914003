.react-app ._content_kf8h0_1 {
  margin-bottom: var(--space-small) !important;
}

.react-app ._checkboxMargin_kf8h0_5 {
  margin-bottom: var(--space-small);
}

.react-app ._checkableLabels_kf8h0_9,
.react-app ._checkableLabels_kf8h0_9 label {
  text-align: left;
}
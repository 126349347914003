/* eslint-disable no-case-declarations */

import moment from 'moment'

import { PROFILE_IMPORT_STEPS } from 'apps/Imports/utils/profile-import/constants'
import getRequiredImportFields from '../get-required-import-fields'

export const agreedToCommunicationPolicy = (comPolicy, comConsentData) => {
  if (!comPolicy.published) {
    return true
  }

  if (!comConsentData.communicationAgreement) {
    return false
  }

  if (comConsentData.communicationAgreement !== 'accepted') {
    return true
  }

  const communicationChannels = comPolicy.channels || {}
  const communicationOptions = comConsentData.communicationOptions || {}
  const hasAnyChannelSelected = Object.keys(communicationChannels)
    .filter((channel) => communicationChannels[channel])
    .reduce((acc, channel) => {
      return acc || communicationOptions[channel]
    }, false)

  return !!hasAnyChannelSelected
}

export const agreedToCommunicationsPolicies = (
  communicationPolicies,
  consentData
) => {
  return communicationPolicies.every((comPolicy) => {
    const comConsentData = consentData[comPolicy.id] || {}
    return agreedToCommunicationPolicy(comPolicy, comConsentData)
  })
}

export const isStepCompleted = (step, data, opts = {}) => {
  const context = opts.context || {}

  switch (step) {
    case PROFILE_IMPORT_STEPS.FILE_UPLOAD:
      return data.file && data.separator

    case PROFILE_IMPORT_STEPS.IMPORT_SETTINGS:
      return data.updateExistingProfiles !== null

    case PROFILE_IMPORT_STEPS.POLICY_CONSENT:
      const {
        dataProcessingPolicy = {},
        marketingPolicy = {},
        communicationPolicies = []
      } = opts
      const marketingChannels = marketingPolicy.channels || {}
      const consentData = data.consentData

      const hasAnyChannelSelected = Object.keys(marketingChannels)
        .filter((channel) => marketingChannels[channel])
        .reduce((acc, channel) => {
          return acc || consentData.marketingOptions[channel]
        }, false)

      return (
        consentData.consentType === 'unique' ||
        ((!dataProcessingPolicy.published ||
          consentData.dataProcessingConsent) &&
          (!marketingPolicy.published ||
            (consentData.marketingAgreement &&
              (consentData.marketingAgreement !== 'accepted' ||
                hasAnyChannelSelected))) &&
          agreedToCommunicationsPolicies(communicationPolicies, consentData) &&
          consentData.sourceOfConsent.trim() !== '' &&
          consentData.dateOfConsent !== null &&
          consentData.dateOfConsent !== '' &&
          moment(consentData.dateOfConsent).isValid())
      )

    case PROFILE_IMPORT_STEPS.ADD_TO_CONTEXT:
      const isEventImport = context._ruby_type === 'Events::Event'

      return isEventImport
        ? data.addToEventData.responseType && data.addToEventData.attendance
        : data.addToData.stateSubstateId

    case PROFILE_IMPORT_STEPS.PROFILE_DETAILS:
      return true

    case PROFILE_IMPORT_STEPS.MAPPING:
      const { requiredFieldsToImport } = getRequiredImportFields(
        opts?.importDocument || {},
        context,
        data
      )
      const mappingsForRequiredFields = data.mappings.filter((mapping) =>
        requiredFieldsToImport.includes(mapping.field)
      )

      return mappingsForRequiredFields.length === requiredFieldsToImport.length

    case PROFILE_IMPORT_STEPS.SUMMARY:
      return true

    default:
      return false
  }
}

export const areStepsCompleted = (steps = [], data, opts = {}) => {
  return steps.every((step) => isStepCompleted(step, data, opts))
}

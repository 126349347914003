import React from 'react'
import { Trans } from 'react-i18next'

import {
  Button,
  Link,
  Modal,
  ModalButtons,
  ModalTitle,
  Text
} from '@fullfabric/alma-mater'

import styles from '../styles.module.scss'

export default function ModalDisplay({
  cookieText,
  cookieBtnText,
  acceptAllCookiesAndDismiss
}) {
  return (
    <Modal
      className={styles.cookiesPolicyModal}
      size='medium'
      isOpen={true}
      header={<ModalTitle title={<Trans>Cookie policy</Trans>} />}
      footer={
        <ModalButtons>
          <Button
            primary
            onClick={acceptAllCookiesAndDismiss}
            className={styles.modalCta}
          >
            <p dangerouslySetInnerHTML={cookieBtnText} />
          </Button>
        </ModalButtons>
      }
    >
      <Text
        type='f5'
        fontColor='text-base-darkest'
        dangerouslySetInnerHTML={cookieText}
      />

      <Text type='f5' fontColor='text-base-darkest' marginTop='more'>
        {' '}
        <Link
          secondary
          underline
          href='/policies/cookies'
          className={styles.noEndMargin}
        >
          <Trans>
            Click here to manage your cookie preferences and for further
            information
          </Trans>
        </Link>
        .
      </Text>
    </Modal>
  )
}

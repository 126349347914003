/* eslint-disable promise/always-return */

import React from 'react'
import { Trans } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { Field } from '@fullfabric/alma-mater'

import lookupUser from 'apps/Authentication/api/lookup-user'
import Form from '..'

export const EmailField = ({ data, setData, errors, autoFocus }) => {
  return (
    <Field
      id='email'
      type='email'
      label={<Trans>Email</Trans>}
      value={data.email}
      onChange={(id, value) => setData({ ...data, [id]: value })}
      onBlur={(id, value) => true}
      error={errors.email}
      inputOptions={{ autoFocus }}
    />
  )
}

const Email = ({
  data,
  setData,
  setStep,
  setNeedsPrivacyPolicy,
  setHasPassword
}) => {
  const location = useLocation()
  const [errors, setErrors] = React.useState({})

  React.useEffect(() => setErrors({}), [data.email])

  const onSubmit = ({ onSuccess, onError }) => {
    lookupUser(data.email, location.pathname + location.search)
      .then((response) => {
        switch (response.data.status) {
          case 'active':
            onSuccess()
            setStep('signIn')
            setNeedsPrivacyPolicy(!response.data.privacy_policy_accepted)
            setHasPassword(response.data.has_password)
            break
          case 'invalid':
            onError()
            setErrors({ email: <Trans>Email address is invalid</Trans> })
            break
          default:
            onSuccess()
            setStep('signUp')
            break
        }
      })
      .catch((error) => {
        onError()
        setErrors(error.response.data)
      })
  }

  return (
    <Form onSubmit={onSubmit} showModalActions>
      <EmailField
        data={data}
        setData={setData}
        errors={errors}
        autoFocus={true} // eslint-disable-line jsx-a11y/no-autofocus
      />
    </Form>
  )
}

export default Email

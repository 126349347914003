import React from 'react'
import { Trans } from 'react-i18next'

import moment from 'moment'

import Plural from 'shared/components/Plural'

import {
  EMAIL_ROUTE,
  EVENTS_ROUTE,
  PROFILE_ROUTE
} from 'apps/Inbox/components/AppRoutes/constants'

export const userProfileRoute = (profileId) => `${PROFILE_ROUTE}/${profileId}`

export const emailRoute = (emailId) => `${EMAIL_ROUTE}/${emailId}`

export const taskRoute = (taskTargetId) =>
  `${PROFILE_ROUTE}/${taskTargetId}#tasks`

export const eventRoute = (eventId) => `${EVENTS_ROUTE}/#${eventId}/show`

export const applicRoute = (profileId, applicId) =>
  `${PROFILE_ROUTE}/${profileId}#applications/${applicId}`

export const formRoute = (profileId, formId, submissionId) =>
  `${PROFILE_ROUTE}/${profileId}#forms/${formId}/submissions/${submissionId}/show`

export const relativeTimestamp = (createdAt) => {
  const timestamp = moment().diff(createdAt, 'days')
  if (timestamp < 1) {
    if (moment().diff(createdAt, 'hours') < 1) {
      return (
        <Trans>
          <Plural
            singular={'Minute'}
            plural={'Minutes'}
            count={moment().diff(createdAt, 'minutes')}
          />{' '}
          ago
        </Trans>
      )
    } else {
      return (
        <Trans>
          <Plural
            singular={'Hour'}
            plural={'Hours'}
            count={moment().diff(createdAt, 'hours')}
          />{' '}
          ago
        </Trans>
      )
    }
  } else if (timestamp < 2) {
    return <Trans>Yesterday, {moment(createdAt).format('HH:mm')}</Trans>
  } else if (timestamp > 365) {
    return moment(createdAt).format('D MMM YYYY')
  } else {
    return moment(createdAt).format('D MMM HH:mm')
  }
}

export const roughlySameTime = (time1, time2) => {
  return (
    moment(time1).add(-1, 'minute').isBefore(time2) &&
    moment(time1).add(1, 'minute').isAfter(time2)
  )
}

export const nameInitials = (name) =>
  name &&
  name
    .split(' ')
    .map((subName) => subName[0])
    .join('')

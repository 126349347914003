export const CARD_TYPE_PERMISSIONS = {
  email: ['core.institutions.profile_emails_access'],
  task: ['tasks.tasks_access'],
  form: ['forms.forms_access'],
  event: [
    'events.events_full_access',
    'events.events_access',
    'events.event_access'
  ],
  applic: ['core.home.applications_access', 'applics.applications_access']
}

import React from 'react'
import { Trans } from 'react-i18next'

import { Text } from '@fullfabric/alma-mater'

import { useApplicData } from 'apps/Applics/contexts/StaffSubjectSelection/ApplicData'
import { useStudyPlanData } from 'apps/Applics/contexts/StaffSubjectSelection/StudyPlanData'
import { getSelectedPath } from 'apps/Applics/utils/getSelectedPath'

import styles from './styles.module.scss'

const Path = () => {
  const applic = useApplicData()
  const studyPlan = useStudyPlanData()

  const selectedPath = getSelectedPath(applic, studyPlan?.paths)

  return (
    <Text type='h5' fontColor='text-base-darkest'>
      <Trans>Path:</Trans>
      {!!selectedPath && (
        <span className={styles.badge}>
          <Text type='h5' fontColor='text-info' tag='span'>
            {selectedPath.name}
          </Text>
        </span>
      )}
    </Text>
  )
}

export default Path

.react-app ._container_mefii_1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  width: 100%;
}

.react-app ._centeredHolder_mefii_9 {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.react-app ._navigationContainer_mefii_15 {
  flex: 1;
  width: 100%;
  overflow-y: auto;
}
.react-app ._navigationContainer_mefii_15._mobile_mefii_20 {
  padding-bottom: var(--space-more);
}
import { useCallback, useMemo } from 'react'

import { flatMap, isEmpty, isNil } from 'lodash'
import moment from 'moment'

import { FileUploadInput } from '@fullfabric/alma-mater'

/**
 * @private
 *
 * Marks the field as errored and with a proper error message for each of its
 * failed files.
 */
export function uploadErrorsAsErrorsInForm(tusUploads, uploadsError) {
  const errors = Object.entries(uploadsError)
    .filter(([_uploadId, error]) => !!error)
    .reduce((errorsByField, [uploadId, error]) => {
      const fileName = tusUploads[uploadId].options.metadata.filename
      const fieldName = tusUploads[uploadId].options.metadata.fieldName
      const errorMsg = `${fileName}: ${error.message}`

      if (errorsByField[fieldName]) {
        errorsByField[fieldName] += `; ${errorMsg}`
      } else {
        errorsByField[fieldName] = errorMsg
      }

      return errorsByField
    }, {})

  if (isEmpty(errors)) return null

  return errors
}

/**
 * @private
 *
 * Will mark the file field as "loading" if at least one of its files is being
 * uploaded.
 */
export function uploadStatusAsFieldOptions(tusUploads, uploadsStatus = {}) {
  const options = Object.entries(uploadsStatus).reduce(
    (optionsByField, [uploadId, status]) => {
      const fieldName = tusUploads[uploadId].options.metadata.fieldName
      const isUploading = isNil(status)

      if (optionsByField[fieldName]) {
        const fieldIsAlreadyLoading = optionsByField[fieldName].loading

        optionsByField[fieldName] = {
          loading: fieldIsAlreadyLoading || isUploading
        }
      } else {
        optionsByField[fieldName] = { loading: isUploading }
      }

      return optionsByField
    },
    {}
  )

  if (isEmpty(options)) return null

  return options
}

/**
 * Validates opts argument of useSchemableDocumentForm
 *
 * @param {Object} opts options argument passed to the useSchemableDocumentForm
 * @throws {TypeError} When any of the options is not of a valid typeof
 * @returns {Object} The given opts as is
 */
export function validateOpts(opts) {
  const { documentBaseUrl, saveField, userId } = opts

  if (!documentBaseUrl || typeof documentBaseUrl !== 'string') {
    throw new TypeError(`documentBaseUrl is required and must be a string.`)
  }

  if (!saveField || typeof saveField !== 'function') {
    throw new TypeError(`saveField is required and must be a function.`)
  }

  if (!userId || typeof userId !== 'string') {
    throw new TypeError(`user is required and must be a string.`)
  }

  return opts
}

/**
 * @private
 * @param {String} typeToExtract the ruby type of the schemable field to extract
 * @param {Object} schemableDocument a schemable document as serialized by the backend
 * @returns A flat array with every field name of the given type
 */
function extractFieldNamesOfType(typeToExtract, schemableDocument) {
  const schemaSections = schemableDocument?.schema?.sections

  return flatMap(schemaSections, (section) =>
    section.fields
      .filter((field) => field.type === typeToExtract)
      .map((field) => field.name)
  )
}

/**
 * Transforms value as it comes from schemable forms to a date-only (no hours) string
 * @param {Object<Moment>} dateAndTime Moment object from input
 */
export function getDateOnlyString(dateAndTime) {
  const backendCompatibleFormat = 'YYYY-MM-DD'

  if (!dateAndTime) return ''

  return moment(dateAndTime).format(backendCompatibleFormat)
}

export function useDateFields(schemableDocument) {
  return useMemo(
    () =>
      extractFieldNamesOfType(
        'Schemable::Fields::Types::Date',
        schemableDocument
      ),
    [schemableDocument]
  )
}

export function useFileUploadFields(schemableDocument) {
  return useMemo(
    () =>
      extractFieldNamesOfType(
        'Schemable::Fields::Types::File',
        schemableDocument
      ),
    [schemableDocument]
  )
}

/**
 * Hook that returns a callback. This callback saves the value of a file input
 * field to the server. The uploading handlers must be provided via argument.
 * @param {Object} opts.schemableDocument Schemable document which has the info
 * on the fields being accessed.
 * @param {Function} opts.prepareUpload callback called to generate the TUS
 * upload, as returned by the useTusUpload hook.
 * @param {Function} opts.prepareUpload callback called to actually upload
 * the file. Considers the function as returned by the useTusUpload hook.
 * @returns {Function}
 */
export function useFileUploadFieldSaver({
  schemableDocument,
  prepareUpload,
  startUpload
}) {
  const handleUploadFile = useCallback(
    async ({ fieldName, file, uploadNumber }) => {
      const uploadId = `${fieldName}-${uploadNumber}`

      prepareUpload({
        uploadId,
        file,
        extraMetadata: {
          fieldName,
          schemableId: schemableDocument?.id,
          documentType: schemableDocument?._ruby_type
        }
      })

      return await startUpload(uploadId)
    },
    [schemableDocument, prepareUpload, startUpload]
  )

  return useCallback(
    async ({ fieldName, value }) => {
      saveFileUploadField({ fieldName, value, handleUploadFile })
    },
    [handleUploadFile]
  )
}

export async function saveFileUploadField({
  fieldName,
  value,
  handleUploadFile
}) {
  if (!value) return

  const filesToUpload = FileUploadInput.valueToFilesToUpload(value, {
    lastSelectedOnly: true
  })

  await Promise.all(
    filesToUpload.map((file, uploadNumber) =>
      handleUploadFile({ fieldName, file, uploadNumber })
    )
  )
}

import { useQuery } from 'react-query'

import moment from 'moment'

import getProfilesComparison from 'apps/Reports/api/getProfilesComparison'
import isEmptyDataSeries from 'apps/Reports/components/ProductOverviewReport/utils/isEmptyDataSeries'
import matchDataKeyToIntake from 'apps/Reports/components/ProductOverviewReport/utils/matchDataKeyToIntake'
import sortIntakes from 'apps/Reports/utils/sortIntakes'
import { useClassesData, useMainIntakeIndex } from '../../ClassOptionsContext'
import { useFilter } from '../../FilterContext'
import formatWithdrawnFilterValue from '../../utils/formatWithdrawnFilterValue'
import { TIMEFRAME_INTERVAL } from '../constants'
import addPaddingDataPointToTimeserie from '../helpers/addPaddingDataPointToTimeserie'
import getDateDifference from '../helpers/getDateDifference'

const useIntakeOverviewData = (timeframeInterval) => {
  const filter = useFilter()

  const withdrawn = formatWithdrawnFilterValue(filter.withdrawn)
  const {
    data: productOverviewData,
    isLoading,
    error
  } = useQuery(
    [
      'getProfilesComparison',
      filter.classes,
      filter.states,
      withdrawn,
      timeframeInterval
    ],
    () =>
      getProfilesComparison(
        filter.classes,
        filter.states,
        withdrawn,
        timeframeInterval
      ),
    { retry: 1 }
  )
  const mainIntakeIndex = useMainIntakeIndex()
  const intakesData = useClassesData()
  const isDataReady = productOverviewData && productOverviewData.length > 0

  const graphDataKeys =
    isDataReady &&
    Object.keys(productOverviewData[0])
      .filter((k) => k !== 'week' && k !== 'month')
      .sort((dataKeyA, dataKeyB) => {
        // gathers data keys and finds corresponding intake
        const intakeA = intakesData?.find((itk) =>
          matchDataKeyToIntake(dataKeyA, itk)
        )
        const intakeB = intakesData?.find((itk) =>
          matchDataKeyToIntake(dataKeyB, itk)
        )

        // orders data keys according to intake sort
        return sortIntakes(intakeA, intakeB)
      })

  const mainIntake =
    graphDataKeys &&
    graphDataKeys[mainIntakeIndex] &&
    intakesData.find((itk) =>
      matchDataKeyToIntake(graphDataKeys[mainIntakeIndex], itk)
    )

  const distanceFromCloseToStartDate = getDateDifference(
    mainIntake?.closes_on && moment(mainIntake.closes_on),
    mainIntake?.starts_on && moment(mainIntake.starts_on),
    timeframeInterval
  )

  // needed to make sure xAxis ends in a 0, while maintaining customization in other elements
  const paddedProductOverviewData =
    isDataReady &&
    addPaddingDataPointToTimeserie(
      productOverviewData,
      timeframeInterval === TIMEFRAME_INTERVAL.WEEKLY
    )

  return {
    reportData: paddedProductOverviewData,
    isReportDataEmpty: isEmptyDataSeries(productOverviewData),
    isLoading,
    error,
    distanceFromCloseToStartDate,
    intakesData,
    graphDataKeys
  }
}

export default useIntakeOverviewData

import React from 'react'

const UserIcon = () => (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M0 8C0 3.58172 3.58172 0 8 0C12.4163 0.00477657 15.9952 3.5837 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8ZM1.66667 8C1.66667 11.4978 4.5022 14.3333 8 14.3333C11.4961 14.3293 14.3293 11.4961 14.3333 8C14.3333 4.5022 11.4978 1.66667 8 1.66667C4.5022 1.66667 1.66667 4.5022 1.66667 8ZM8 8.66667C9.47276 8.66667 10.6667 7.47276 10.6667 6C10.6667 4.52724 9.47276 3.33333 8 3.33333C6.52724 3.33333 5.33333 4.52724 5.33333 6C5.33333 7.47276 6.52724 8.66667 8 8.66667ZM4.03174 11.69C4.76294 10.1608 6.30476 9.18503 7.99974 9.17867C9.69472 9.18503 11.2365 10.1608 11.9677 11.69C12.017 11.7933 12.0097 11.9147 11.9486 12.0115C11.8874 12.1082 11.7809 12.1668 11.6664 12.1667H4.33307C4.21861 12.1668 4.11209 12.1082 4.05093 12.0115C3.98977 11.9147 3.98252 11.7933 4.03174 11.69Z'
    />
  </svg>
)

export default UserIcon

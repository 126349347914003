{
  "Email": "Email",
  "Email address is invalid": "La dirección de correo electrónico no es válida",
  "I accept the privacy policy": "Acepto la política de privacidad",
  "Privacy Policy": "Política de privacidad",
  "Please accept the privacy policy to continue": "Por favor, acepte la política de privacidad para continuar",
  "Welcome back": "Bienvenido/a de nuevo",
  "Please enter your password for security reasons.": "Introduzca su contraseña por razones de seguridad.",
  "Email address ": "Dirección de correo electrónico ",
  "Password": "Contraseña",
  "Forgot your password?": "¿Olvidó su contraseña?",
  "An email has been sent to  with an authentication link that will take you to your application.": "An email has been sent to  with an authentication link that will take you to your application.",
  "Once you have accessed the authentication link you can close this page.": "Una vez que haya accedido al enlace de autenticación, puede cerrar esta página.",
  "Didn’t receive the email?": "¿No recibió el correo electrónico?",
  "Resend email with authentication link": "Reenviar correo electrónico de autenticación",
  "Email sent": "Email enviado",
  "Welcome! Let's start your new application.": "¡Bienvenido/a! Comencemos con su nueva solicitud.",
  "Welcome! Let's start your new registration.": "¡Bienvenido/a! Comencemos con su nuevo registro.",
  "Please enter the following details so that you can revisit your application if you exit it.": "Introduzca los siguientes datos para que pueda volver a acceder a su solicitud si se sale de ella.",
  "Please enter the following details so that you can revisit your registration if you exit it.": "Introduzca los siguientes datos para que pueda volver a acceder a su registro si se sale de él. ",
  "First name": "Nombre",
  "Last name": "Apellido",
  "Back": "Volver",
  "Continue to application": "Continuar para la aplicación",
  "Continue to registration": "Continuar con el registro",
  "Data Protection regulation": "Reglamento de protección de datos",
  "Someone already registered with that email address": "Alguien ya se ha registrado con esa dirección de correo electrónico",
  "Email address can't be blank": "La dirección de correo electrónico no puede estar en blanco",
  "Continue": "Continuar",
  "Participant details": "Datos del participante",
  "Please enter the details of the participant": "Introduzca los datos del participante.",
  "Registration already exists": "El registro ya existe",
  "Application already exists": "La aplicación ya existe",
  "There are fields with errors": "Hay campos con errores.",
  "Please correct the fields with errors": "Por favor, corrija los campos con errores.",
  "First name can't be blank": "El nombre no puede estar en blanco",
  "Last Name": "Apellido",
  "Last name can't be blank": "El apellido no puede estar en blanco",
  "Your employment details": "Sus datos laborales",
  "To register on behalf of someone else we need more information on your current employment details.": "Para registrarse en nombre de otra persona, necesitamos más información sobre los detalles de su empleo actual.",
  "There was an error in your submission, please try again in a few seconds. If the issue persists, please contact us.": "Ha habido un error en su envío, por favor inténtelo de nuevo en unos segundos. Si el problema persiste, póngase en contacto con nosotros.",
  "The link has expired.": "El enlace ha caducado.",
  "Please re-enter your details to receive a new authentication email.": "Vuelva a introducir sus datos para recibir un nuevo correo electrónico de autenticación.",
  "Please enter your email address so that we can retrieve your information if you have visited before.": "Introduzca su dirección de correo electrónico para que podamos recuperar su información si nos ha visitado antes.",
  "Please select an option": "Por favor seleccione una opción",
  "I am starting a registration for myself": "Estoy iniciando un registro",
  "I am starting an application for myself": "Estoy iniciando una solicitud para mí.",
  "I am starting a registration on behalf of someone else": "Estoy iniciando un registro en nombre de otra persona.",
  "I am starting an application on behalf of someone else": "Estoy iniciando una solicitud en nombre de otra persona.",
  "I am continuing an existing registration": "Continúo con un registro existente.",
  "I am continuing an existing application": "Continúo con una solicitud existente.",
  "An email has been sent to {{email}} with an authentication link that will take you to your application.": "Se ha enviado un correo electrónico a {{email}} con un enlace de autenticación que le llevará a su solicitud."
}

export const BORDER_RADIUS = 2
export const BAR_HEIGHT = 8
export const BAR_Y = 20
export const MARKER_HEIGHT = 32
export const TEXT_MARGIN = 4
export const BELOW_MIN_BAR_COLOR = 'var(--ff-color-red-300)'
export const BELOW_TARGET_BAR_COLOR = 'var(--ff-color-purple-100)'
export const ABOVE_TARGET_BAR_COLOR = 'var(--ff-color-green-300)'
export const PLACEHOLDER_BAR_COLOR = 'var(--ff-bg-base-light)'
export const MARKER_BAR_COLOR = 'var(--ff-bg-haze)'
export const MARKER_LABEL_COLOR = 'var(--ff-color-text-base-darker)'
export const MARKER_VALUE_COLOR = 'var(--ff-color-text-base-darkest)'
export const BELOW_MIN_CURRENT_VALUE_TEXT_COLOR = 'var(--ff-color-text-alert)'
export const ABOVE_MAX_VALUE_PERCENT_COLOR = 'var(--ff-color-text-alert)'
export const BELOW_TARGET_CURRENT_VALUE_TEXT_COLOR = 'var(--ff-color-text-info)'
export const ABOVE_TARGET_CURRENT_VALUE_TEXT_COLOR =
  'var(--ff-color-text-success)'
export const TEXT_ANCHOR_CURRENT_VALUE_THRESHOLD = 55
export const TEXT_ANCHOR_MARKER_THRESHOLD = 36

export const CAPACITY_TARGETS_CHART = 'CAPACITY_TARGETS'
export const CAPACITY_BREAKDOWN_CHART = 'CAPACITY_BREAKDOWN'

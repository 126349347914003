import createCategory from 'apps/StudyPlans/api/createCategory'
import deleteCategories from 'apps/StudyPlans/api/deleteCategories'
import updateCategories from 'apps/StudyPlans/api/updateCategories'
import { isValidCategory, rollbackInvalidUpdates } from './utils'

const saveCategories = async ({
  studyPlanId,
  updatedCategories,
  previousCategories
}) => {
  const presentInUpdatedCategories = updatedCategories.reduce(
    (hashed, { id }) => ({ ...hashed, [id]: true }),
    {}
  )
  const presentInPreviousCategories = previousCategories.reduce(
    (hashed, category) => ({ ...hashed, [category.id]: category }),
    {}
  )

  const categoriesToCreate = updatedCategories.filter(
    (category) =>
      !presentInPreviousCategories[category.id] && isValidCategory(category)
  )

  const categoriesToUpdate = rollbackInvalidUpdates(
    updatedCategories.filter(({ id }) => presentInPreviousCategories[id]),
    presentInPreviousCategories
  )

  const categoriesToDelete = previousCategories.filter(
    ({ id }) => !presentInUpdatedCategories[id]
  )

  if (categoriesToDelete.length > 0) {
    await deleteCategories(
      studyPlanId,
      categoriesToDelete.map(({ id }) => id)
    )
  }

  let updated = []
  if (categoriesToUpdate.length > 0) {
    updated = await updateCategories(studyPlanId, categoriesToUpdate)
  }

  const created = await Promise.all([
    ...categoriesToCreate.map((toCreate) =>
      createCategory(studyPlanId, toCreate)
    )
  ])

  return [...updated, ...created]
}

export default saveCategories

import { utils } from '@fullfabric/public-api'

const deleteCourse = async ({ applicId, courseId, studyPlanSubjectId }) => {
  const response = await fetch(
    `/api/applics/${applicId}/course/${courseId}/study_plan_subject/${studyPlanSubjectId}`,
    {
      method: 'DELETE',
      credentials: 'same-origin'
    }
  )

  return await utils.checkResponse(response)
}

export default deleteCourse

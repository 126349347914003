import React from 'react'
import { Trans } from 'react-i18next'

import { Col, Row, Text } from '@fullfabric/alma-mater'

import ApplicationCard from 'apps/MergeProfiles/components/ApplicationCard'
import ApplicationNotInMainProfileCard from './ApplicationNotInMainProfileCard'

import styles from './styles.module.scss'

const ApplicSelectionRow = ({
  applic,
  profile,
  targetProfileApplic,
  targetProfile
}) => {
  return (
    <Row
      role='row'
      className={styles.applicSelectionRow}
      gutterWidth={24}
      aria-label={`select application for ${applic.context_name} ${applic.template_name}`}
    >
      {targetProfileApplic && (
        <Text className={styles.orSeparator} tag='div' type='h5'>
          <Trans>OR</Trans>
        </Text>
      )}

      <Col xs={6}>
        <ApplicationCard
          applic={applic}
          profile={profile}
          profileType='duplicate'
        />
      </Col>

      <Col xs={6}>
        {targetProfileApplic ? (
          <ApplicationCard
            applic={targetProfileApplic}
            profile={targetProfile}
            profileType='main'
          />
        ) : (
          <ApplicationNotInMainProfileCard applic={applic} />
        )}
      </Col>
    </Row>
  )
}

export default ApplicSelectionRow

import React from 'react'

import { Col, Row } from '@fullfabric/alma-mater'

import IntakeComparisonWidget from './IntakeComparisonWidget'
import IntakeMonthlyChangeWidget from './IntakeMonthlyChangeWidget'

import styles from './styles.module.scss'

const StatsWidgetsRow = () => {
  return (
    <Row className={styles.container}>
      <Col xs={6}>
        <IntakeComparisonWidget />
      </Col>
      <Col xs={6}>
        <IntakeMonthlyChangeWidget />
      </Col>
    </Row>
  )
}

export default StatsWidgetsRow

{
  "Analytical": "Analytical",
  "Strictly necessary": "Strictly necessary",
  "Functional": "Functional",
  "Advertising": "Advertising",
  "Submit": "Submit",
  "Thank you": "Thank you",
  "You have confirmed your marketing preferences.": "You have confirmed your marketing preferences.",
  "You did not accept the Privacy Policy.": "You did not accept the Privacy Policy.",
  "You must contact us to delete your data.": "You must contact us to delete your data.",
  "Change privacy policy agreement": "Change privacy policy agreement",
  "Privacy Policy": "Privacy Policy",
  "Next: Update marketing preferences": "Next: Update marketing preferences",
  "I Accept the Privacy Policy": "I Accept the Privacy Policy",
  "I DO NOT accept the Privacy Policy": "I DO NOT accept the Privacy Policy",
  "If you do not accept the privacy policy, you must contact the data processor to delete your data.": "If you do not accept the privacy policy, you must contact the data processor to delete your data.",
  "You have updated your marketing preferences.": "You have updated your marketing preferences.",
  "Log in": "Log in",
  "Finish your registration": "Finish your registration",
  "Marketing Policy": "Marketing Policy",
  "I would like to receive information by Email": "I would like to receive information by Email",
  "I would like to receive information by Phone": "I would like to receive information by Phone",
  "I would like to receive information by SMS": "I would like to receive information by SMS",
  "I would like to receive information by Mail": "I would like to receive information by Mail",
  "I do not want to be contacted with this type of information": "I do not want to be contacted with this type of information",
  "Cookie Policy": "Cookie Policy",
  "Back to Portal": "Back to Portal",
  "Manage your preferences": "Manage your preferences",
  "Save cookie preferences": "Save cookie preferences",
  "You accepted our privacy policy on our website - {{institutionHost}} - on {{date}}": "You accepted our privacy policy on our website - {{institutionHost}} - on {{date}}",
  "You are receiving this email because you accepted our marketing policy on our website - {{institutionHost}} - on  {{date}}": "You are receiving this email because you accepted our marketing policy on our website - {{institutionHost}} - on  {{date}}",
  "You accepted our privacy policy when creating an account on {{date}}": "You accepted our privacy policy when creating an account on {{date}}",
  "You accepted our privacy policy on {{date}} from our email": "You accepted our privacy policy on {{date}} from our email",
  "You are receiving this email because you accepted our marketing policy on {{date}} from our email": "You are receiving this email because you accepted our marketing policy on {{date}} from our email",
  "You are receiving this email because a person created a profile for you and obtained your consent from {{consentSource}} on {{date}}": "You are receiving this email because a person created a profile for you and obtained your consent from \"{{consentSource}}\" on {{date}}",
  "You accepted our privacy policy when submitting the {{entity}} form on {{date}}": "You accepted our privacy policy when submitting the {{entity}} form on {{date}}",
  "You accepted our marketing policy when submitting the {{entity}} form on {{date}}": "You accepted our marketing policy when submitting the {{entity}} form on {{date}}",
  "You accepted our privacy policy when registering for the {{entity}} event on {{date}}": "You accepted our privacy policy when registering for the {{entity}} event on {{date}}",
  "You accepted our marketing policy when registering for the {{entity}} event on {{date}}": "You accepted our marketing policy when registering for the {{entity}} event on {{date}}",
  "Unsubscribe": "Unsubscribe",
  "Yes, unsubscribe": "Yes, unsubscribe",
  "No, stay subscribed": "No, stay subscribed",
  "Manage marketing preferences": "Manage marketing preferences",
  "Unsubscribe successful": "Unsubscribe successful",
  "You are still subscribed": "You are still subscribed",
  "Back to homepage": "Back to homepage",
  "Are you sure you want to unsubscribe from the <0>{{topicName}}</0> topic?": "Are you sure you want to unsubscribe from the <0>{{topicName}}</0> topic?",
  "You have been unsubscribed from the <0>{{topicName}}</0> topic.": "You have been unsubscribed from the <0>{{topicName}}</0> topic.",
  "You have not been unsubscribed from the <0>{{topicName}}</0> topic.": "You have not been unsubscribed from the <0>{{topicName}}</0> topic."
}

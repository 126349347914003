import React from 'react'

import useHideOnScroll from 'apps/ContentPages/hooks/use-hide-on-scroll'
import useIsMobileLayout from 'apps/ContentPages/hooks/use-is-mobile-layout'
import Logo from '../../logo'

import classNames from 'classnames'
import styles from './styles.module.scss'

const HEADER_SIZE = 57

const Header = ({ children }) => {
  const isMobileLayout = useIsMobileLayout()
  const isDesktopLayout = !isMobileLayout
  const isVisible = useHideOnScroll(2 * HEADER_SIZE)
  const hasNoAdditionalContent = React.Children.count(children) === 0

  return (
    <header
      className={classNames(styles.wrapper, isDesktopLayout && styles.desktop)}
    >
      <div className={styles.placeholder} />
      <div
        className={classNames(
          styles.container,
          !isVisible && styles.hidden,
          hasNoAdditionalContent && styles.plain
        )}
      >
        <div
          className={classNames(
            styles.logoContainer,
            isDesktopLayout && styles.desktop
          )}
        >
          <a href={'/'} className={styles.logoLink}>
            <Logo />
          </a>
        </div>
        {children}
      </div>
    </header>
  )
}

export default Header

import * as React from 'react'

import { Table, Text } from '@fullfabric/alma-mater'

import styles from '../styles.module.scss'

const ProductCell = ({ value, row, data }) => {
  const rowData = data[row.index]

  const productParentName = value
  const productName = rowData.name
  const linkPrefix =
    rowData.type === 'Institutions::Course' ? '/courses' : '/classes'

  return (
    <div className={styles.dataCell}>
      <Text type='h5' fontColor='text-base-darkest'>
        {productParentName}
      </Text>
      <Table.CellLink
        secondary
        href={`${linkPrefix}/${rowData.id}`}
        target='_parent'
      >
        {productName}
      </Table.CellLink>
    </div>
  )
}

export default ProductCell

import React from 'react'

import { CheckIcon, Text } from '@fullfabric/alma-mater'

import classNames from 'classnames'
import styles from './styles.module.scss'

const StepItem = ({ index, label, completed, active }) => {
  return (
    <li
      className={classNames(
        styles.stepItem,
        completed && styles.stepCompleted,
        active && styles.stepActive
      )}
    >
      <div className={styles.stepItemCircle}>
        {completed ? (
          <CheckIcon color='primary-200' />
        ) : (
          <Text
            fontColor={active ? 'text-base-lightest' : 'text-base-darker'}
            bold
          >
            {index}
          </Text>
        )}
      </div>

      <Text
        fontColor={active ? 'text-base-darkest' : 'text-base-darker'}
        marginLeft='small'
        type='h4'
        className={styles.stepItemLabel}
      >
        {label}
      </Text>
    </li>
  )
}

export default StepItem

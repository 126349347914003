import React, { useRef } from 'react'

import { useScreenClass } from '@fullfabric/alma-mater'
import { ScrollableAreaProvider } from '@fullfabric/schemable-forms'

import BlankSpaceForCookieBanner from 'shared/components/BlankSpaceForCookieBanner'

import useIsMobileLayout from 'apps/ContentPages/hooks/use-is-mobile-layout'
import BackgroundImage from './background-image'

import classNames from 'classnames'
import styles from './styles.module.scss'

const BackgroundLayer = ({ narrow, children }) => {
  const scrollableLayerRef = useRef()
  const screenClass = useScreenClass()
  const isMobileLayout = useIsMobileLayout()
  const isTabletLayout = screenClass === 'md'
  const isDesktopLayout = ['lg', 'xl'].includes(screenClass)

  const navbarSafeArea = isDesktopLayout ? 0 : 72 // maximum navbar size
  const padding = navbarSafeArea + 16

  return (
    <div
      className={classNames([
        styles.positioner,
        isMobileLayout && styles.mobileLayout,
        isTabletLayout && styles.tabletLayout,
        isDesktopLayout && styles.desktopLayout
      ])}
    >
      <div className={styles.background}>
        <div
          className={classNames(
            styles.imageContainer,
            narrow && styles.narrower
          )}
          role='complementary'
        >
          <BackgroundImage />
        </div>
      </div>
      <div className={styles.contentContainer} ref={scrollableLayerRef}>
        <ScrollableAreaProvider
          areaRef={isDesktopLayout ? scrollableLayerRef : null}
          padding={padding}
          mobileLayout={!isDesktopLayout}
        >
          {children}
          <BlankSpaceForCookieBanner />
        </ScrollableAreaProvider>
      </div>
    </div>
  )
}

export default BackgroundLayer

.react-app ._container_1dl36_1 {
  margin-top: var(--space-big);
}

.react-app ._chartContainer_1dl36_5 {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  padding-right: var(--half-gutter-width, 8px);
  padding-left: var(--half-gutter-width, 8px);
  -ms-flex-preferred-size: 33.33333333%;
  flex-basis: 33.33333333%;
  max-width: 33.33333333%;
  display: inline-block;
  position: relative;
  height: fit-content;
}
.react-app ._chartContainer_1dl36_5 svg {
  height: auto;
}

.react-app ._chartInnerLegend_1dl36_23 {
  width: 70%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.react-app ._chartInnerText_1dl36_31 {
  text-align: center;
}

.react-app ._legendContainer_1dl36_35 {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  padding-right: var(--half-gutter-width, 8px);
  padding-left: var(--half-gutter-width, 8px);
  margin-left: 11.11111111%;
  -ms-flex-preferred-size: 55.55555556%;
  flex-basis: 55.55555556%;
  max-width: 55.55555556%;
}

.react-app ._warningText_1dl36_48 {
  margin-left: var(--space-tiny);
  color: #f5a623;
}

.react-app ._rectangle_1dl36_53 {
  display: inline-block;
  vertical-align: middle;
  width: 16px;
  height: 8px;
  border-radius: 2px;
  margin-right: var(--space-small);
}

.react-app ._blueBackground_1dl36_62 {
  background-color: var(--ff-color-primary-300);
}

.react-app ._darkBlueBackground_1dl36_66 {
  background-color: #0c4076;
}

.react-app ._redBackground_1dl36_70 {
  background-color: var(--ff-color-text-alert);
}

.react-app ._legendRow_1dl36_74 {
  margin-bottom: var(--space-default);
}
.react-app ._container_zqt49_1 {
  margin-top: 24px;
  margin-top: var(--space-more);
  margin-bottom: 64px;
  margin-bottom: var(--space-huge);
}

.react-app ._separator_zqt49_8 {
  width: 111px;
  height: 1px;
  background-color: #e0e6ed;
  background-color: var(--ff-color-grey-300);
  margin-bottom: 8px;
  margin-bottom: var(--space-small);
}

.react-app ._list_zqt49_17 {
  margin: 0;
  margin-top: 12px;
  margin-top: var(--space-less);
  padding-left: 16px;
  padding-left: var(--space-default);
}
import React from 'react'
import { Trans } from 'react-i18next'

import { Text } from '@fullfabric/alma-mater'

import styles from './styles.module.scss'

const SummaryField = ({ label, value, last }) =>
  label ? (
    <>
      <Text fontColor='text-base-darkest' marginBottom='tiny'>
        {label}
      </Text>
      <Text
        marginBottom={last ? null : 'default'}
        className={value ? null : styles.emptyValue}
      >
        {value || <Trans>Empty</Trans>}
      </Text>
    </>
  ) : null

export default SummaryField

import React from 'react'
import { Trans } from 'react-i18next'

const SUBJECT_TYPE_VALUES = [
  {
    label: <Trans>Core</Trans>,
    value: 'Core'
  },
  {
    label: <Trans>Elective</Trans>,
    value: 'Elective'
  },
  {
    label: <Trans>Extra curricular</Trans>,
    value: 'Extra curricular'
  }
]

const SUBJECT_TYPE_MAP = {
  Core: <Trans>Core</Trans>,
  Elective: <Trans>Elective</Trans>,
  'Extra curricular': <Trans>Extra curricular</Trans>
}

const subjectTypeOptionsForPath = (path) => {
  const coreCourseGroup = path.course_groups.filter(
    (group) => group.type === 'core'
  )[0]
  const electiveCourseGroups = path.course_groups.filter(
    (group) => group.type === 'elective'
  )
  const extracurricularCourseGroup = path.course_groups.filter(
    (group) => group.type === 'extra'
  )[0]

  return [
    { label: <Trans>Core</Trans>, value: coreCourseGroup.id },
    ...electiveCourseGroups.map((group) => ({
      label:
        electiveCourseGroups.length === 1 ? (
          <Trans>Elective</Trans>
        ) : (
          <Trans>Elective - {group.name}</Trans>
        ),
      value: group.id
    })),
    {
      label: <Trans>Extra curricular</Trans>,
      value: extracurricularCourseGroup?.id
    }
  ]
}

export { SUBJECT_TYPE_MAP, SUBJECT_TYPE_VALUES, subjectTypeOptionsForPath }

export default SUBJECT_TYPE_VALUES

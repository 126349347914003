import React from 'react'
import { Trans } from 'react-i18next'

import { Button } from '@fullfabric/alma-mater'

import CardContent from 'apps/StudyPlans/components/CardContent'
import CardInfo from 'apps/StudyPlans/components/CardInfo'
import { useSetSettingsSectionsBeingEdited } from 'apps/StudyPlans/contexts/SettingsSectionsBeingEdited'
import { useStudyPlan } from 'apps/StudyPlans/contexts/StudyPlanData'

const CreditsSectionDisplay = () => {
  const studyPlan = useStudyPlan()
  const setSectionBeingEdited = useSetSettingsSectionsBeingEdited()
  const onEditClick = React.useCallback(() => {
    setSectionBeingEdited((sectionsBeingEdited) => ({
      ...sectionsBeingEdited,
      credits: true
    }))
  }, [setSectionBeingEdited])

  return (
    <CardContent
      title={<Trans>Required number of credits</Trans>}
      actions={
        <Button size='small' onClick={onEditClick}>
          <Trans>Edit</Trans>
        </Button>
      }
    >
      <CardInfo main={studyPlan.credits} />
    </CardContent>
  )
}

export default CreditsSectionDisplay

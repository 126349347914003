import { utils } from '@fullfabric/public-api'

const deleteElectiveGroup = async (studyPlanId, pathId, groupId) => {
  const response = await fetch(
    `/affairs/api/study_plan/${studyPlanId}/paths/${pathId}/delete_elective_group`,
    {
      credentials: 'same-origin',
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ group_id: groupId })
    }
  )

  return await utils.checkResponse(response)
}

export default deleteElectiveGroup

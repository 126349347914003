.react-app ._chartContainer_ozq4t_1 {
  width: 100%;
  max-height: 56px;
  display: block;
}

.react-app ._placeholderContainer_ozq4t_7 {
  position: relative;
}

.react-app ._placeholderText_ozq4t_11 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: var(--space-small) var(--space-default);
  border-radius: 17px;
  border: var(--border-base-lighter);
  background: var(--ff-bg-base-lightest);
}
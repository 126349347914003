.react-app ._summaryHeader_1qap3_1 {
  padding: var(--space-more);
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.react-app ._summaryFieldSectionsContainer_1qap3_8 {
  margin-top: var(--space-more);
}
.react-app ._summaryFieldSectionsContainer_1qap3_8 ._summaryFieldSection_1qap3_8 {
  border-bottom: var(--border-base-lighter);
  padding-top: var(--space-default);
  padding-bottom: var(--space-default);
}
.react-app ._summaryFieldSectionsContainer_1qap3_8 ._summaryFieldSection_1qap3_8:first-child {
  padding-top: 0;
}
.react-app ._summaryFieldSectionsContainer_1qap3_8 ._summaryFieldSection_1qap3_8:last-child {
  border-bottom: none;
  padding-bottom: var(--space-more);
}

.react-app ._summaryFieldSection_1qap3_8 ._sectionTitle_1qap3_24 {
  padding: 0 var(--space-more);
  margin-bottom: var(--space-default);
}
.react-app ._summaryFieldSection_1qap3_8 ._summaryField_1qap3_8 {
  margin-bottom: var(--space-default);
  padding: 0 var(--space-more);
}
.react-app ._summaryFieldSection_1qap3_8 ._summaryField_1qap3_8._changed_1qap3_32 {
  padding-left: calc(var(--space-more) - 4px);
  border-left: 4px solid var(--ff-color-green-300);
}
.react-app ._summaryFieldSection_1qap3_8 ._summaryField_1qap3_8:last-child {
  margin-bottom: 0;
}
.react-app ._summaryFieldSection_1qap3_8 ._summaryField_1qap3_8._cascading_1qap3_39 {
  margin-bottom: var(--space-big);
  margin-top: var(--space-big);
}
.react-app ._summaryFieldSection_1qap3_8 ._summaryField_1qap3_8 ._keptFieldValuesContainer_1qap3_43,
.react-app ._summaryFieldSection_1qap3_8 ._summaryField_1qap3_8 ._changedFieldValuesContainer_1qap3_44 {
  display: inline-block;
  vertical-align: top;
}
.react-app ._summaryFieldSection_1qap3_8 ._summaryField_1qap3_8 ._keptFieldValuesContainer_1qap3_43 {
  max-width: 100%;
}
.react-app ._summaryFieldSection_1qap3_8 ._summaryField_1qap3_8 ._keptFieldValuesContainer_1qap3_43._hasChanges_1qap3_51 {
  max-width: 60%;
}
.react-app ._summaryFieldSection_1qap3_8 ._summaryField_1qap3_8 ._changedFieldValuesContainer_1qap3_44 {
  max-width: 40%;
  margin-left: var(--space-small);
}
.react-app ._summaryFieldSection_1qap3_8 ._summaryField_1qap3_8 ._summaryFieldSubField_1qap3_58:not(:last-child) {
  margin-bottom: var(--space-default);
}
.react-app ._summaryFieldSection_1qap3_8 ._summaryField_1qap3_8 ._summaryFieldValue_1qap3_61 {
  margin-bottom: var(--space-tiny);
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.react-app ._summaryFieldSection_1qap3_8 ._summaryField_1qap3_8 ._summaryFieldValue_1qap3_61._oldValue_1qap3_68 {
  text-decoration-line: line-through;
}
.react-app ._summaryFieldSection_1qap3_8 ._summaryField_1qap3_8 ._summaryFieldValue_1qap3_61:last-child {
  margin-bottom: 0;
}
import 'css-chunk:src/apps/Events/components/AskGuestCountModal/styles.module.scss';export default {
  "react-app": "react-app",
  "contentContainer": "_contentContainer_1adj0_1",
  "feedback": "_feedback_1adj0_7",
  "inputsContainer": "_inputsContainer_1adj0_16",
  "numberTextInput": "_numberTextInput_1adj0_21",
  "content": "_content_1adj0_1",
  "footer": "_footer_1adj0_31",
  "actionButton": "_actionButton_1adj0_36",
  "inputLabel": "_inputLabel_1adj0_63"
};
import React from 'react'

import GoogleMapReact from 'google-map-react'

import LoadingPlaceholderBlock from 'apps/ContentPages/components/loading-placeholder-block'

import classNames from 'classnames'
import styles from './styles.module.scss'

const EventMap = ({ address, withMargin, apiKey }) => {
  const [isMapVisible, setIsMapVisible] = React.useState(false)
  const defaultProps = {
    center: {
      lat: 10.99835602,
      lng: 77.01502627
    },
    zoom: 14
  }

  return (
    <>
      {!isMapVisible && (
        <LoadingPlaceholderBlock
          className={classNames(
            styles.placeholder,
            withMargin && styles.withMargin
          )}
        />
      )}
      <div
        style={{
          position: 'relative',
          marginBottom: withMargin ? 'var(--space-default)' : 0
        }}
      >
        <div
          style={{
            height: isMapVisible ? '158px' : 0,
            width: '100%',
            borderRadius: '4px',
            position: 'relative'
          }}
        >
          <GoogleMapReact
            bootstrapURLKeys={{ key: apiKey }}
            // defaultCenter={addressPosition}
            defaultCenter={defaultProps.center}
            defaultZoom={defaultProps.zoom}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps, ...rest }) => {
              if (!maps.Geocoder) return

              const geoCoder = new maps.Geocoder()

              geoCoder.geocode({ address }, (result, status) => {
                if (status !== 'OK') return

                const location = result[0]?.geometry?.location
                const formattedPosition = location
                  ? { lat: location.lat(), lng: location.lng() }
                  : null

                map.setCenter(formattedPosition)

                /* eslint-disable-next-line no-unused-vars */
                const marker = new maps.Marker({
                  position: formattedPosition,
                  map
                })

                // const bounds = new maps.LatLngBounds()
                // bounds.extend(formattedPosition)
                // map.fitBounds(bounds)

                setIsMapVisible(true)
              })
            }}
          />
        </div>
      </div>
    </>
  )
}

export default EventMap

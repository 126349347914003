.react-app ._productsGrid_jwgmr_1 {
  margin-top: var(--space-more);
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: var(--space-default);
  row-gap: var(--space-more);
}
.react-app ._productsGrid_jwgmr_1._xs_jwgmr_8, .react-app ._productsGrid_jwgmr_1._sm_jwgmr_8 {
  grid-template-columns: 1fr;
}
.react-app ._productsGrid_jwgmr_1._md_jwgmr_11, .react-app ._productsGrid_jwgmr_1._lg_jwgmr_11 {
  grid-template-columns: 1fr 1fr;
}
/* eslint-disable camelcase */

import { connect } from 'react-redux'

import actionTypes from 'apps/Courses/actions/types'
import CourseEditDisplaySchema from 'apps/Courses/components/CourseEditDisplaySchema'

const mapStateToProps = ({
  editCourse: { data, schema },
  appData: { h: { countries_as_options = [] } = {} } = {}
}) => ({
  data,
  schema,
  countriesAsOptions: countries_as_options
})

const mapDispatchToProps = (dispatch) => ({
  onEditClick: ({ data, schema }) => {
    const schemaFields = schema.sections
      .flatMap((section) => section.fields)
      .flatMap((field) => field.name)

    const schemaData = {}
    schemaFields.forEach((field) =>
      Object.assign(schemaData, { [field]: data[field] })
    )

    dispatch({
      type: actionTypes.editSchemaSectionData,
      payload: { data: schemaData, schema }
    })
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CourseEditDisplaySchema)

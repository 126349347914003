import React from 'react'

import { useAppData } from 'shared/contexts/AppData'

import FormBody from '../form-body'
import WidgetContainer from '../widget-container'

import styles from './styles.module.scss'

export default function FormPageContent({ inCard }) {
  const { form } = useAppData()
  const Container = inCard ? WidgetContainer : React.Fragment

  return (
    <Container>
      <div
        className={inCard ? styles.expandedFormPadding : styles.bodyContainer}
      >
        <FormBody inFormPage inCard={inCard} formId={form.id} />
      </div>
    </Container>
  )
}

.react-app ._legendContainer_17qdj_1 {
  list-style: none;
  margin: 0;
  padding-left: 0;
  display: flex;
}

.react-app ._itemContainer_17qdj_8 {
  display: inline-flex;
  align-items: center;
  margin-right: var(--space-default);
}

.react-app ._itemIcon_17qdj_14,
.react-app ._comparisonItemIcon_17qdj_15 {
  height: 4px;
  width: 12px;
  border-radius: 2px;
  margin-right: var(--space-tiny);
  display: inline-block;
}

.react-app ._circleIcon_17qdj_23 {
  height: 8px;
  width: 8px;
  border-radius: 50%;
  margin-right: var(--space-tiny);
  display: inline-block;
}

.react-app ._comparisonItemIcon_17qdj_15 {
  width: 6px;
  margin-right: 2px;
}
.react-app ._comparisonItemIcon_17qdj_15._last_17qdj_35 {
  margin-right: var(--space-tiny);
}

.react-app ._barChartLegend_17qdj_39 ._itemIcon_17qdj_14 {
  height: 8px;
  width: 8px;
}
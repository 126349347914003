{
  "A word by itself is easy to guess": "Un mot en soi est facile à deviner",
  "Add \"{{val}}\"": "Ajouter \"{{val}}\"",
  "Add another word or two. Uncommon words are better.": "Ajouter un autre mot ou deux. Les mots peu communs sont meilleurs.",
  "All-uppercase is almost as easy to guess as all-lowercase": "Tout en majuscules est presque aussi facile à deviner que tout en minuscules",
  "Almost there": "Presque là",
  "Already have an account?": "Possédez-vous déjà un compte?",
  "Avoid dates and years that are associated with you": "Évitez les dates et les années qui vous sont associées",
  "Avoid recent years": "Évitez les dernières années",
  "Avoid repeated words and characters": "Évitez les mots et les caractères répétés",
  "Avoid sequences": "Évitez les séquences",
  "Avoid years that are associated with you": "Évitez les années qui vous sont associées",
  "Back to login": "Retournez à la page pour vous connecter",
  "Cancel": "Annulez",
  "Capitalization doesn't help very much": "La capitalisation n'aide pas beaucoup",
  "Common names and surnames are easy to guess": "Les noms communs et les noms de famille sont faciles à deviner",
  "Continue": "Continuez",
  "Create an account": "Créez un compte",
  "Create new account": "Créez un nouveau compte",
  "Data Protection Regulation": "Règlement sur la protection des données",
  "Dates are often easy to guess": "Les dates sont souvent faciles à deviner",
  "Didn't receive the sms?": "Vous n'avez pas reçu le sms?",
  "E-mail address": "Adresse e-mail",
  "First name": "Prénom",
  "For security reasons account activation links are perishable. You can get a new activation link by filling the sign up form.": "Pour des raisons de sécurité les liens d'activation sont temporaires. Vous pouvez obtenir un nouveau lien d'activation en remplissant le formulaire d'inscription en haut de la page.",
  "Forgot your password?": "Mot de passe oublié?",
  "Great, your account has been created!": "Génial, votre compte a été créé!",
  "I accept the privacy policy": "J'accepte la politique de confidentialité",
  "Institutional login": "Connexion institutionnelle",
  "Last name": "Nom de famille",
  "Link existing account": "Liez compte existant",
  "LinkedIn": "LinkedIn",
  "Log in": "Se connecter",
  "Log in securely": "Connectez-vous en toute sécurité",
  "Names and surnames by themselves are easy to guess": "Les noms et les noms de famille sont faciles à deviner",
  "New Password": "Nouveau mot de passe",
  "No Results": "Aucun résultat",
  "No need for symbols, digits, or uppercase letters": "Pas besoin de symboles, de chiffres ou de majuscules",
  "Not registered?": "Non enregistré?",
  "Nothing found for": "Rien trouvé pour",
  "Or use these alternatives": "Ou bien utilisez ces alternatives",
  "Password": "Mot de passe",
  "Password Reset": "Réinitialiser le mot de passe",
  "Please accept the privacy policy to continue": "Veuillez accepter la politique de confidentialité pour continuer",
  "Please check your email for instructions on resetting your password": "Veuillez vérifier votre e-mail afin d'obtenir des instructions sur la réinitialisation de votre mot de passe",
  "Please check your mailbox for instructions on verifying your registration.": "Veuillez vérifier votre e-mail pour obtenir des instructions portant sur la vérification de votre inscription.",
  "Please choose a password": "Veuillez choisir un mot de passe",
  "Please confirm your account details below": "Veuillez confirmer vos coordonnées ci-dessous",
  "Please contact your university's IT support department in order to get a new activation link to access Full Fabric.": "Veuillez contacter le service d'assistance informatique de votre université afin d'obtenir un nouveau lien d'activation pour accéder à Full Fabric.",
  "Please type a new password.": "Veuillez saisir un nouveau mot de passe.",
  "Predictable substitutions like \"@\" instead of \"a\" don't help very much": "Substitutions prévisibles comme \"@\" au lieu de \"a\" ne pas aider beaucoup",
  "Predictable substitutions like '@' instead of 'a' don't help very much": "Substitutions prévisibles comme '@' au lieu de 'a' ne pas aider beaucoup",
  "Privacy policy": "Politique de confidentialité",
  "Recent years are easy to guess": "Les années récentes sont faciles à deviner",
  "Repeats like \"aaa\" are easy to guess": "Les répétitions comme \"aaa\" sont faciles à deviner",
  "Repeats like \"abcabcabc\" are only slightly harder to guess than \"abc\"": "Les répétitions comme \"abcabcabc\" ne sont que légèrement plus difficiles à deviner que \"abc\"",
  "Reset password": "Réinitialiser le mot de passe",
  "Reset password and sign in": "Réinitialiser votre mot de passe et se connecter",
  "Reset your password below.": "Réinitialisez votre mot de passe ci-dessous.",
  "Return to log in page": "Retournez à la page pour vous connecter",
  "Reversed words aren't much harder to guess": "Les mots inversés ne sont pas beaucoup plus difficiles à deviner",
  "Send again": "Envoyer à nouveau",
  "Sequences like abc or 6543 are easy to guess": "Des séquences comme abc ou 6543 sont faciles à deviner",
  "Set password and sign in": "Définissez le mot de passe et connectez-vous",
  "Short keyboard patterns are easy to guess": "Les modèles de clavier courts sont faciles à deviner",
  "Sign in": "Connectez-vous",
  "Sign up": "Inscrivez-vous",
  "Skip to main content": "Passer au contenu principal",
  "Straight rows of keys are easy to guess": "Les rangées droites de clés sont faciles à deviner",
  "Suggestions": "Suggestions",
  "Thank you.": "Merci.",
  "There was a server problem. Please try again.": "Un problème de serveur s'est produit. Veuillez réessayer.",
  "This is a top-10 common password": "C'est l'un des 10 mots de passe les plus courants",
  "This is a top-100 common password": "C'est l'un des 100 mots de passe les plus courants",
  "This is a very common password": "Ceci est un mot de passe très commun",
  "This is similar to a commonly used password": "Ceci est similaire à un mot de passe couramment utilisé",
  "Two Factor Authentication Token": "Jeton d'authentification à deux facteurs",
  "Two step verification": "vérification en deux étapes",
  "Use a few words, avoid common phrases": "Utilisez quelques mots, évitez les expressions courantes",
  "Use a longer keyboard pattern with more turns": "Utilisez un motif de clavier plus long avec plus de tours",
  "Use one of these alternatives to signup": "Utilisez l'une de ces alternatives pour vous inscrire",
  "Use one of these methods to log in": "Utilisez l'une de ces méthodes pour vous connecter",
  "We sent a sms to the number <1>{{phoneNumber}}</1> with the verification code": "Nous avons envoyé un SMS au numéro <1>{{phoneNumber}}</1> avec le code de vérification.",
  "Welcome": "Bienvenue",
  "Your activation link has expired.": "Votre lien d'activation a expiré.",
  "Your password has expired.": "Votre mot de passe a expiré.",
  "Your password is good": "Votre mot de passe est bon",
  "Your password is reasonably adequate": "Votre mot de passe est suffisamment adéquat",
  "Your password is too weak": "Votre mot de passe est insuffisant",
  "or e-mail": "ou e-mail",
  "or link an existing account": "ou liez à un compte existant",
  "{{minAlpha}} letters": "{{minAlpha}} lettre(s)",
  "{{minDigits}} digits": "{{minDigits}} chiffre(s)",
  "{{minLength}} characters": "{{minLength}} caractères",
  "{{minSymbols}} symbols": "{{minSymbols}} symbole(s)",
  "Password must be at least {{minLength}} characters long": "Le mot de passe doit contenir au moins {{minLength}} caractères",
  "Password must have at least {{count}} characters": "Le mot de passe doit avoir au moins {{count}} caractère",
  "Password must have at least {{count}} characters_plural": "Le mot de passe doit avoir au moins {{count}} caractères",
  "Password must have at least {{count}} digit": "Le mot de passe doit avoir au moins {{count}} chiffre",
  "Password must have at least {{count}} digit_plural": "Le mot de passe doit avoir au moins {{count}} chiffres",
  "Password must have at least {{count}} symbol": "Le mot de passe doit contenir au moins {{count}} symbole",
  "Password must have at least {{count}} symbol_plural": "Le mot de passe doit contenir au moins {{count}} symboles",
  "Password was already used": "Le mot de passe a déjà été utilisé",
  "Thank you. Please log in below.": "Merci. Veuillez vous connecter ci-dessous.",
  "This field is required": "Ce champ est obligatoire",
  "Privacy Policy": "Politique de confidentialité",
  "Link to an existing account": "Lier un compte existant",
  "There are fields with errors.": "Il y a des champs avec des erreurs.",
  "Please correct the fields with errors.": "Veuillez corriger les champs comportant des erreurs.",
  "We sent a sms to the number {{ phoneNumber }} with the verification code": "Nous avons envoyé un SMS au numéro {{phoneNumber}} avec le code de vérification."
}

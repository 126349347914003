import React from 'react'

import classNames from 'classnames'
import styles from './styles.module.scss'

const WidgetSection = ({ first, last, single, children }) => (
  <div
    className={classNames([
      styles.container,
      single && styles.singleSection,
      first && styles.first,
      last && styles.last
    ])}
  >
    {children}
  </div>
)

export default WidgetSection

.react-app ._positioner_14gg7_1 {
  position: relative;
  z-index: 0;
  width: 100%;
}

.react-app ._background_14gg7_7 {
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: flex-end;
  padding: 0 var(--hub-outer-margin);
}

.react-app ._imageContainer_14gg7_19 {
  --relative-width: 100%;
  --fixed-width: 0;
  width: 100%;
  height: 160px;
  overflow: hidden;
}

.react-app ._mobileLayout_14gg7_27 ._background_14gg7_7 {
  position: relative;
  height: auto;
  padding: 0;
}

.react-app ._tabletLayout_14gg7_33 ._background_14gg7_7 {
  position: fixed;
}
.react-app ._tabletLayout_14gg7_33 ._imageContainer_14gg7_19 {
  --relative-width: 25%;
}

.react-app ._tabletLayout_14gg7_33 ._contentContainer_14gg7_40,
.react-app ._desktopLayout_14gg7_41 ._contentContainer_14gg7_40 {
  height: auto;
  overflow-y: auto;
  min-height: 100vh;
}
.react-app ._tabletLayout_14gg7_33 ._imageContainer_14gg7_19,
.react-app ._desktopLayout_14gg7_41 ._imageContainer_14gg7_19 {
  --fixed-width: var(--hub-outer-margin);
  height: 100vh;
  width: calc(var(--relative-width) + var(--fixed-width));
  max-height: unset;
  margin-right: calc(-1 * var(--fixed-width));
}

.react-app ._desktopLayout_14gg7_41 ._contentContainer_14gg7_40 {
  height: 100vh;
}
.react-app ._desktopLayout_14gg7_41 ._imageContainer_14gg7_19 {
  --relative-width: 33.33%;
}
.react-app ._desktopLayout_14gg7_41 ._imageContainer_14gg7_19._narrower_14gg7_61 {
  --relative-width: 25%;
}
import { FileUploadInput } from '@fullfabric/alma-mater'
import { utils } from '@fullfabric/public-api'

function fileUploadValueToStore(value) {
  const filesToUpload = FileUploadInput.valueToFilesToUpload(value)

  return {
    __isReduxFileDataObject: true,
    filesToUpload: filesToUpload.map((file) => ({
      url: URL.createObjectURL(file),
      name: file.name
    }))
  }
}

async function storeToFileUploadValue(storeValue) {
  const filesToUpload = await Promise.all(
    storeValue.filesToUpload.map(serializedDataToFile)
  )

  return FileUploadInput.filesToValue(filesToUpload)
}

async function serializedDataToFile(serializedData) {
  const response = await fetch(serializedData.url)
  const blob = await utils.checkResponse(response, { responseType: 'blob' })

  return new File([blob], serializedData.name, {
    type: blob.type,
    lastModified: Date.now()
  })
}

function isFileUploadStoreData(value) {
  return value?.__isReduxFileDataObject === true
}

const uploadsInReduxStoreHelper = {
  fileUploadValueToStore,
  storeToFileUploadValue,
  isFileUploadStoreData
}

export default uploadsInReduxStoreHelper

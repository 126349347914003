.react-app ._tooltip_1awmm_1 {
  background-color: white;
  min-width: 240px;
  max-width: 320px;
  box-shadow: var(--shadow-3);
  border: 1px solid var(--border-base-lighter);
  border-radius: var(--border-radius-tiny);
  text-align: left;
  padding: var(--space-more);
}
.react-app ._tooltip_1awmm_1[data-animatefill] {
  background: white;
}
.react-app ._tooltip_1awmm_1 ._actorLogo_1awmm_14 img {
  width: 42px;
  height: 42px;
  margin-left: -2px;
  border-radius: 50%;
  margin-bottom: var(--space-small);
}
.react-app ._tooltip_1awmm_1 ._actorInitials_1awmm_21 {
  display: inline-flex;
  width: 42px;
  height: 42px;
  margin-left: -2px;
  border-radius: 50%;
  margin-bottom: var(--space-small);
  align-items: center;
  -webkit-box-align: center;
  text-align: center;
  justify-content: center;
  background-color: #d898ff;
  color: #eff2f7;
}

.react-app ._followCol_1awmm_36 {
  text-align: right;
}

.react-app ._cardLink_1awmm_40 {
  display: inline-block;
}
.react-app ._cardLink_1awmm_40 a {
  margin: 0;
}
import React, { useState } from 'react'
import { Trans } from 'react-i18next'

import Tippy from '@tippyjs/react'
import { Button, Col, Link, Row, Text } from '@fullfabric/alma-mater'
import { useCurrentUser } from '@fullfabric/authorization-officer'

import {
  useHandleFollow,
  useHandleUnfollow
} from 'apps/Inbox/hooks/handleFollowHooks'
import { nameInitials, userProfileRoute } from 'apps/Inbox/utils/cardUtils'

import styles from './styles.module.scss'

const followedProfile = (currentUser, actor) =>
  actor.followers
    ? actor.followers.map((followerId) => followerId).includes(currentUser.id)
    : false

export default function InboxCardProfileTooltip({ actor }) {
  const currentUser = useCurrentUser()
  const [hover, setHover] = useState(false)
  const isFollowed = followedProfile(currentUser, actor)
  const followProfile = useHandleFollow()
  const unfollowProfile = useHandleUnfollow()

  return (
    <Tippy
      className={styles.tooltip}
      animation='scale'
      duration={0}
      delay={[300, 0]}
      interactive={true}
      content={
        <>
          <Row>
            <Col xs={6}>
              {actor.photo && actor.photo.url ? (
                <span className={styles.actorLogo}>
                  <img src={actor.photo.url} alt='actor profile'></img>
                </span>
              ) : (
                <span className={styles.actorInitials}>
                  <Text type='h4' fontColor='text-base-darkest'>
                    {nameInitials(actor.full_name)}
                  </Text>
                </span>
              )}
            </Col>

            <Col xs={6} className={styles.followCol}>
              <Button
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
                size='small'
                marginLeft='small'
                disabled={actor.id === currentUser.id}
                negative={hover && isFollowed}
                primary={isFollowed}
                onClick={() => {
                  isFollowed ? unfollowProfile(actor) : followProfile(actor)
                }}
              >
                <Trans>
                  {isFollowed ? (hover ? 'Unfollow' : 'Following') : 'Follow'}
                </Trans>
              </Button>
            </Col>
          </Row>

          <Text type='h4' fontColor='text-base-darkest'>
            {actor.full_name}
          </Text>

          <Text type='f5' fontColor='text-base-darker' marginBottom='default'>
            {actor.email}
          </Text>

          {actor.roles?.map((role, index) => (
            <Text
              type='f5'
              fontColor='text-base-darkest'
              marginTop='small'
              key={index}
            >
              {role.state}
              {' - '}
              <Text tag='span' type='f5' fontColor='text-base'>
                {role.context_name}
              </Text>
            </Text>
          ))}
        </>
      }
    >
      <div className={styles.cardLink}>
        <Link href={userProfileRoute(actor.id)} target='_top'>
          {actor.full_name}
        </Link>
      </div>
    </Tippy>
  )
}

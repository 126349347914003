.react-app ._addToButtons_it7vg_1 {
  margin-top: 24px;
  margin-top: var(--space-more);
  margin-right: 8px;
  margin-right: var(--space-tiny);
}

.react-app ._ownerDropdown_it7vg_8 {
  z-index: 10;
}

.react-app ._statesDropdown_it7vg_12 {
  z-index: 11;
}

.react-app ._contextDropdown_it7vg_16 {
  z-index: 12;
}
import { utils } from '@fullfabric/public-api'

const createSubject = async (data) => {
  const response = await fetch('/affairs/api/courses', {
    credentials: 'same-origin',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  })
  return await utils.checkResponse(response)
}

export default createSubject

import { useQuery } from 'react-query'

import api from 'apps/ContentPages/api'

export default function useFormQuery(formId) {
  const { data, status, refetch } = useQuery(['get-form', formId], async () =>
    api.getForm(formId, { returnHeaders: ['x-request-id'] })
  )

  const { data: form, headers } = data || {}

  return {
    form,
    refetch,
    isLoading: status === 'loading',
    requestId: headers?.['x-request-id']
  }
}

import React from 'react'
import { Trans } from 'react-i18next'

import { Button } from '@fullfabric/alma-mater'
import { SchemableForm } from '@fullfabric/schemable-forms'

import styles from './styles.module.scss'

const CourseEditDisplaySchema = ({
  data,
  schema,
  countriesAsOptions,
  onEditClick
}) => {
  return (
    <section aria-label='course schema data read-only'>
      <SchemableForm
        readOnly
        hideSubmitButton
        data={data}
        schema={schema}
        countriesAsOptions={countriesAsOptions}
        handleSubmit={onEditClick}
        separatorClassName={styles.separator}
      />
      <div className='marginTop-default'>
        <Button
          size='small'
          aria-label='edit course schema'
          onClick={() => onEditClick({ data, schema })}
          className={styles.editButton}
        >
          <Trans>Edit</Trans>
        </Button>
      </div>
    </section>
  )
}

export default CourseEditDisplaySchema

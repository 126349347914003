const nextSortCalculator = (config = {}) => {
  const sortFieldKey = config.sortKey || 'sort[field]'
  const sortDirectionKey = config.directionKey || 'sort[direction]'
  const ASC = config.ascValue || config.ascValue === 0 ? config.ascValue : 1
  const DESC =
    config.descValue || config.descValue === 0 ? config.descValue : -1

  const getNextSortParams = (currentSort, targetField) => {
    return {
      [sortFieldKey]: targetField,
      [sortDirectionKey]:
        currentSort[sortFieldKey] === targetField &&
        currentSort[sortDirectionKey] === ASC
          ? DESC
          : ASC
    }
  }

  const getTableSortState = (sortParams) => {
    return [
      {
        id: sortParams[sortFieldKey],
        desc: sortParams[sortDirectionKey] === DESC
      }
    ]
  }

  return {
    getNextSortParams,
    getTableSortState
  }
}

export default nextSortCalculator

import React from 'react'
import { Trans } from 'react-i18next'

import { Table } from '@fullfabric/alma-mater'

import styles from '../styles.module.scss'

const EmptyRow = ({ message }) => {
  return (
    <Table.Row role='row' tag='div' aria-label='empty row'>
      <Table.Data
        role='cell'
        tag='div'
        align='center'
        className={styles.emptyRowCell}
      >
        {message || <Trans>Empty list</Trans>}
      </Table.Data>
    </Table.Row>
  )
}

export default EmptyRow

const updateOrganization = (data) => {
  if (data.organization) {
    data.organization = {
      association_id: data.organization.id,
      association_type: 'Organization',
      name: data.organization.name
    }
  }
  return data
}

export default updateOrganization

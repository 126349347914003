import React, { useState } from 'react'

const SetHandleRegisterInEventContext = React.createContext()
const HandleRegisterInEventContext = React.createContext()

const RegisterEventClickHandlerProvider = ({ children }) => {
  const [handleRegister, setHandleRegister] = useState()

  return (
    <SetHandleRegisterInEventContext.Provider value={setHandleRegister}>
      <HandleRegisterInEventContext.Provider value={handleRegister}>
        {children}
      </HandleRegisterInEventContext.Provider>
    </SetHandleRegisterInEventContext.Provider>
  )
}

const useRegisterEventClickHandler = () => {
  return React.useContext(HandleRegisterInEventContext)
}

const useSetRegisterEventClickHandler = () => {
  return React.useContext(SetHandleRegisterInEventContext)
}

export {
  RegisterEventClickHandlerProvider,
  useRegisterEventClickHandler,
  useSetRegisterEventClickHandler
}

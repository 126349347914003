import React from 'react'

import { Text } from '@fullfabric/alma-mater'

import classNames from 'classnames'
import styles from './styles.module.scss'

const CustomTextContent = ({ htmlContent, className, ...textProps }) => (
  <Text
    tag='div'
    type='f4'
    fontColor='text-base-darkest'
    {...textProps}
    className={classNames([styles.content, className])}
    dangerouslySetInnerHTML={{ __html: htmlContent }}
  />
)

export default CustomTextContent

.react-app ._container_1kimw_1 {
  height: 100%;
  width: 100%;
  position: relative;
  padding: 0 var(--space-less);
  overflow: scroll;
}

.react-app ._wrapper_1kimw_9 {
  width: calc(100% - 2 * var(--space-less));
  position: absolute;
  top: 50%;
  left: 50%;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
}
.react-app ._wrapper_1kimw_9 video,
.react-app ._wrapper_1kimw_9 img,
.react-app ._wrapper_1kimw_9 audio {
  width: 100%;
}
.react-app ._wrapper_1kimw_9._hide_1kimw_22 {
  display: none;
}

.react-app ._errorMessage_1kimw_26 {
  width: calc(100% + 2 * var(--space-less));
  margin-left: calc(-1 * var(--space-less));
}
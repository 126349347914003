import React from 'react'

const ChartDot = (props) => (
  <circle
    {...props}
    r='3'
    fill='#fff'
    stroke='#0967D2'
    strokeWidth='2'
  ></circle>
)

export default ChartDot

import React from 'react'

import PropTypes from 'prop-types'

import AppLayout from 'apps/Authentication/components/AppLayout'
import AuthContentLayout from 'apps/Authentication/components/AuthContentLayout'
import AuthForm from 'apps/Authentication/components/AuthForm'

const LoginPage = ({ location, isInSecondAuthStep }) => (
  <AppLayout>
    <AuthContentLayout>
      <AuthContentLayout.Body>
        <AuthForm
          info={location && location.state && location.state.info}
          isInSecondAuthStep={isInSecondAuthStep}
        />
      </AuthContentLayout.Body>
    </AuthContentLayout>
  </AppLayout>
)

LoginPage.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      info: PropTypes.node
    })
  }),
  isInSecondAuthStep: PropTypes.bool
}

export default LoginPage

import React from 'react'

const MyselfIcon = () => {
  return (
    <svg
      width='32'
      height='32'
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16 20C19.866 20 23 16.866 23 13C23 9.13401 19.866 6 16 6C12.134 6 9 9.13401 9 13C9 16.866 12.134 20 16 20Z'
        stroke='#18191F'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M25.2173 27.8346C22.778 25.38 19.4604 23.9998 15.9999 23.9998C12.5394 23.9998 9.22186 25.38 6.78259 27.8346'
        stroke='#18191F'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16 31C24.2843 31 31 24.2843 31 16C31 7.71573 24.2843 1 16 1C7.71573 1 1 7.71573 1 16C1 24.2843 7.71573 31 16 31Z'
        stroke='#18191F'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default MyselfIcon

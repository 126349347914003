import React from 'react'
import { Trans } from 'react-i18next'

import { startCase } from 'lodash'

import SimpleSummarySection from 'apps/Imports/components/summary-simple-section'
import { useSelectedData } from 'apps/Imports/contexts/selected-data'
import { useImportContext } from 'apps/Imports/hooks/useImportContext'
import { useStaffs } from 'apps/Imports/hooks/useStaffs'
import {
  ATTENDANCE_TRANSLATIONS,
  PROFILE_IMPORT_STEPS,
  RESPONSE_TYPE_TRANSLATIONS
} from 'apps/Imports/utils/profile-import/constants'
import importUrlFromStepId from 'apps/Imports/utils/profile-import/get-url-from-step-id'

const ALLOWED_CONTEXTS = ['Institutions.Models.ClassOf', 'Events.Models.Event']

const AddToContextSummarySection = () => {
  const context = useImportContext()
  const {
    data: {
      addToData: { stateSubstateId, ownerId },
      addToEventData: { responseType, attendance }
    }
  } = useSelectedData()
  const staff = useStaffs()

  if (!ALLOWED_CONTEXTS.includes(context._backbone_type)) return null

  const isEventContext = context._backbone_type === 'Events.Models.Event'
  const isClassContext =
    context._backbone_type === 'Institutions.Models.ClassOf'
  const [state, substate] = stateSubstateId ? stateSubstateId.split('::') : []
  const owner = staff.find((staffProfile) => staffProfile.id === ownerId)

  return (
    <SimpleSummarySection
      linkTo={importUrlFromStepId(context, PROFILE_IMPORT_STEPS.ADD_TO_CONTEXT)}
      stepId={PROFILE_IMPORT_STEPS.ADD_TO_CONTEXT}
      fieldsData={[
        isEventContext && {
          label: <Trans>Event</Trans>,
          value: context.name
        },
        isEventContext && {
          label: <Trans>Registration response</Trans>,
          value: RESPONSE_TYPE_TRANSLATIONS[responseType]
        },

        isEventContext && {
          label: <Trans>Attendance</Trans>,
          value: ATTENDANCE_TRANSLATIONS[attendance]
        },
        isClassContext && {
          label: <Trans>Programme and Class</Trans>,
          value: context?.name_with_programme
        },

        isClassContext && {
          label: <Trans>State and Substate</Trans>,
          value: `${startCase(state)}::${startCase(substate)}`
        },

        isClassContext && {
          label: <Trans>Owner</Trans>,
          value: owner && owner.full_name
        }
      ].filter((x) => x)}
    />
  )
}

export default AddToContextSummarySection

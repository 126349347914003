const getFieldLabel = (field, lang) => {
  const definedLocal = field.locals.find((local) =>
    local.locale?.includes(lang)
  )
  const enLocal = field.locals.find((local) => local.locale === 'en-GB')
  const fallbackLocal = field.locals[0]

  const localized = definedLocal || enLocal || fallbackLocal

  return localized?.label || '-'
}

export default getFieldLabel

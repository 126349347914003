import React from 'react'
import { Trans } from 'react-i18next'

import {
  AlertFullIcon,
  Button,
  Modal,
  ModalButtons,
  ModalTitle,
  Text
} from '@fullfabric/alma-mater'

import styles from './styles.module.scss'

const DeleteCourseModal = ({ inProgress, onDelete, isOpen, onClose }) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size='medium'
      header={
        <ModalTitle
          title={<Trans>Delete course</Trans>}
          iconProps={{ icon: AlertFullIcon, color: 'text-alert' }}
          onClose={onClose}
        />
      }
      footer={
        <ModalButtons>
          <Button className={styles.cancelButton} onClick={onClose}>
            <Trans>Cancel</Trans>
          </Button>
          <Button negative primary onClick={onDelete} loading={inProgress}>
            <Trans>I understand the consequences, delete this course</Trans>
          </Button>
        </ModalButtons>
      }
    >
      <Text type='h4'>
        <Trans>Are you ABSOLUTELY sure?</Trans>
      </Text>
      <Text type='f5'>
        <Trans>This action CANNOT be undone. This action will:</Trans>
      </Text>
      <ul>
        <Text tag='li'>
          <Trans>
            Permanently delete this course and all associated information
          </Trans>
        </Text>
        <Text tag='li'>
          <Trans>Permanently remove all profiles from this course</Trans>
        </Text>
        <Text tag='li'>
          <Trans>
            Permanently remove all enrolments for this course from enrolment
            plans
          </Trans>
        </Text>
        <Text tag='li'>
          <Trans>
            Permanently delete all selections for this course for the programme
            choice and subject choice on applications
          </Trans>
        </Text>
        <Text tag='li'>
          <Trans>Permanently delete all sessions and the syllabus</Trans>
        </Text>
        <Text tag='li'>
          <Trans>Permanently delete all registrations to the course</Trans>
        </Text>
        <Text tag='li'>
          <Trans>
            Permanently delete all grades from the course and from transcripts
            associated with this course
          </Trans>
        </Text>
        <Text tag='li'>
          <Trans>
            Permanently delete all attendance records for this course
          </Trans>
        </Text>
        <Text tag='li'>
          <Trans>
            Permanently delete all assignments created and submitted
          </Trans>
        </Text>
        <Text tag='li'>
          <Trans>Permanently delete all uploaded resources</Trans>
        </Text>
      </ul>
    </Modal>
  )
}

export default DeleteCourseModal

import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

import { useScreenClass } from '@fullfabric/alma-mater'
import {
  useCurrentUser,
  useFeatures,
  usePermission,
  UserRoleUtils,
  useSettings
} from '@fullfabric/authorization-officer'

import api from 'apps/ContentPages/api'
import Link from 'apps/ContentPages/components/navigation/footer/link'
import LinkAccordionFolder from 'apps/ContentPages/components/navigation/link-accordion-folder'
import LinkPopUpFolder from 'apps/ContentPages/components/navigation/link-pop-up-folder'
import shouldUseMobileNavigation from 'apps/ContentPages/components/navigation/should-use-mobile-navigation'
import UserIcon from '../user-icon'

const LoggedInMenu = () => {
  const { t } = useTranslation()
  const screenClass = useScreenClass()
  const isMobileNavigation = shouldUseMobileNavigation(screenClass)
  const settings = useSettings()
  const features = useFeatures()
  const user = useCurrentUser()
  const hasPaymentPlanAccess = usePermission(
    'core.institutions.profile_payment_plans_access'
  )
  const { data: paymentPlans, status } = useQuery(
    ['get-payment-plans', user.id],
    () => api.getPaymentPlans(user.id),
    { enabled: !!(user?.id && isPayingUser(user) && hasPaymentPlanAccess) }
  )

  const isLoadingPaymentPlans = status === 'loading'

  const conditionalLinks = isLoadingPaymentPlans
    ? []
    : getConditionalLinks(user, settings.hash, features, paymentPlans)

  const allLinks = [...conditionalLinks, ...FIXED_LINKS].map((linkConfig) => ({
    external: true,
    ...linkConfig
  }))

  const menuLabel = isLoadingPaymentPlans ? t('Loading') : user?.full_name

  if (isMobileNavigation) {
    return (
      <LinkAccordionFolder links={allLinks}>
        {({ toggleOpen }) => (
          <Link
            large
            onClick={toggleOpen}
            label={menuLabel}
            icon={<UserIcon />}
          />
        )}
      </LinkAccordionFolder>
    )
  }

  return (
    <LinkPopUpFolder placement='right-end' links={allLinks}>
      <div>
        <Link large icon={<UserIcon />} label={menuLabel} />
      </div>
    </LinkPopUpFolder>
  )
}

export const isPayingUser = (user) =>
  user?.roles &&
  (UserRoleUtils.isStudent(user) ||
    UserRoleUtils.isApplicant(user) ||
    user.roles?.some((role) => role.state === 'prospect'))

export const isUserWithPayments = (user, paymentPlans) =>
  isPayingUser(user) && paymentPlans?.length > 0

export const getConditionalLinks = (user, settings, features, paymentPlans) => {
  let links = []

  if (UserRoleUtils.isStudent(user)) {
    links = [
      ...links,
      {
        path: `/profiles/${user.id}`,
        navigation_label: <Trans>Public profile</Trans>
      }
    ]
  }

  if (features['affairs.academic_services_access']) {
    if (UserRoleUtils.isStudent(user)) {
      links = [
        ...links,
        {
          path: `/affairs/transcripts`,
          navigation_label: <Trans>Transcripts</Trans>
        }
      ]
    }

    if (
      UserRoleUtils.isStaff(user) ||
      UserRoleUtils.isLecturer(user) ||
      UserRoleUtils.isStudent(user)
    ) {
      const pathToCalendarPage = settings?.modules?.affairs?.features
        ?.calendar_without_years_and_terms?.enable
        ? '/affairs/calendar'
        : '/affairs/years'

      links = [
        ...links,
        { path: pathToCalendarPage, navigation_label: <Trans>Calendar</Trans> }
      ]
    }
  }

  if (
    settings?.modules?.financial?.version === '5' &&
    isUserWithPayments(user, paymentPlans)
  ) {
    links = [
      ...links,
      {
        path: `/payments`,
        navigation_label: <Trans>Payments</Trans>,
        external: false
      }
    ]
  }

  return links
}

const FIXED_LINKS = [
  { path: '/settings', navigation_label: <Trans>Settings</Trans> },
  { path: '/logout', navigation_label: <Trans>Logout</Trans> }
]

export default LoggedInMenu

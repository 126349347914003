import React from 'react'
import { Trans } from 'react-i18next'

import {
  Button,
  Link,
  Modal,
  ModalTitle,
  Row,
  Text
} from '@fullfabric/alma-mater'

import { HOME_ROUTE } from 'apps/StudyPlans/components/AppRoutes/constants'
import { useSetSettingsSectionsBeingEdited } from 'apps/StudyPlans/contexts/SettingsSectionsBeingEdited'
import { useClassOf } from 'apps/StudyPlans/contexts/StudyPlanData'

const DiscardChangesModal = ({ isOpen, onClose }) => {
  const classOf = useClassOf()
  const setSectionsBeingEdited = useSetSettingsSectionsBeingEdited()
  const handleDiscardChanges = React.useCallback(() => {
    setSectionsBeingEdited({})
  }, [setSectionsBeingEdited])

  return (
    <Modal
      header={
        <ModalTitle
          title={<Trans>Discard unsaved changes?</Trans>}
          onClose={onClose}
        />
      }
      footer={
        <Row end='xs' className='marginBottom-more'>
          <Button onClick={onClose}>
            <Trans>Cancel</Trans>
          </Button>
          <Link
            withoutStyles
            to={HOME_ROUTE.replace(':classId', classOf.id)}
            onClick={handleDiscardChanges}
          >
            <Button primary className='marginLeft-small'>
              <Trans>Yes, discard changes</Trans>
            </Button>
          </Link>
        </Row>
      }
      isOpen={isOpen}
      onClose={onClose}
    >
      <Text fontColor='text-base-darkest'>
        <Trans>There are unsaved changes.</Trans>
      </Text>
      <Text fontColor='text-base-darkest'>
        <Trans>Would you like to discard your changes?</Trans>
      </Text>
    </Modal>
  )
}

export default DiscardChangesModal

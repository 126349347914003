/* eslint-disable camelcase */

import React from 'react'
import { Trans } from 'react-i18next'
import { useHistory, useLocation, useParams } from 'react-router'

import PolicyAcceptanceFeedback from 'apps/Policies/components/PolicyAcceptanceFeedback'
import { ReactComponent as RemoveCircle } from './remove_circle.svg'

const PrivacyPolicyRefusedPage = () => {
  const history = useHistory()
  const { profile_id } = useParams()
  const { search } = useLocation()

  const onNextClick = React.useCallback(() => {
    history.push({
      pathname: `/policies/user/${profile_id}/privacy/edit`,
      search
    })
  }, [history, profile_id, search])

  return (
    <PolicyAcceptanceFeedback
      icon={
        <RemoveCircle
          className='svgColor-red-600 marginBottom-small'
          width='40'
          height='40'
        />
      }
      title={<Trans>You did not accept the Privacy Policy.</Trans>}
      subtitle={<Trans>You must contact us to delete your data.</Trans>}
      nextActionText={<Trans>Change privacy policy agreement</Trans>}
      onNextClick={onNextClick}
    />
  )
}

export default PrivacyPolicyRefusedPage

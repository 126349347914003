import React from 'react'
import { Trans } from 'react-i18next'

import { AnnotatedLayout } from '@fullfabric/alma-mater'

import { useSettingsSectionsBeingEdited } from 'apps/StudyPlans/contexts/SettingsSectionsBeingEdited'
import CategoriesDisplay from './Display'
import CategoriesForm from './Form'

const CategoriesSection = () => {
  const sectionsBeingEdited = useSettingsSectionsBeingEdited()

  return (
    <AnnotatedLayout.Section
      title={<Trans>Study plan subject categories</Trans>}
      description={
        <Trans>
          Define the study plan subject categories. Each study plan subject can
          be assigned to one category.
        </Trans>
      }
    >
      {sectionsBeingEdited.categories ? (
        <CategoriesForm />
      ) : (
        <CategoriesDisplay />
      )}
    </AnnotatedLayout.Section>
  )
}

export default CategoriesSection

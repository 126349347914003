import React from 'react'
import { useFormContext } from 'react-hook-form'
import { Trans } from 'react-i18next'

import { Button } from '@fullfabric/alma-mater'

import { useHandleCreateSubject } from '../SubjectCreationHandlerContext'

const ActionButton = ({ loading }) => {
  const { handleSubmit } = useFormContext()
  const createSubject = useHandleCreateSubject()

  return (
    <Button
      loading={loading}
      type='button'
      primary
      onClick={handleSubmit(createSubject)}
      size='small'
    >
      <Trans>Add subject</Trans>
    </Button>
  )
}

export default ActionButton

.react-app ._container_vgh76_1 {
  position: relative;
  height: 24px;
  width: 44px;
}
.react-app ._container_vgh76_1:not(._disabled_vgh76_6):hover {
  cursor: pointer;
}
.react-app ._container_vgh76_1:not(._disabled_vgh76_6):hover ._background_vgh76_9._active_vgh76_9 {
  background-color: #92d9bb;
}
.react-app ._container_vgh76_1:not(._disabled_vgh76_6):hover ._background_vgh76_9._inactive_vgh76_12 {
  background-color: var(--ff-color-grey-200);
}
.react-app ._container_vgh76_1._disabled_vgh76_6:hover {
  cursor: not-allowed;
}

.react-app ._background_vgh76_9 {
  width: 100%;
  height: 100%;
  border-radius: 12px;
}
.react-app ._background_vgh76_9._active_vgh76_9 {
  background-color: var(--ff-color-green-300);
  box-shadow: var(--shadow-inset);
}
.react-app ._background_vgh76_9._inactive_vgh76_12 {
  background-color: var(--ff-bg-base-darker);
  box-shadow: var(--shadow-inset);
}
.react-app ._background_vgh76_9._alwaysActive_vgh76_32 {
  box-shadow: none;
  background-color: #dbf6ed;
}

.react-app ._toggleCirclePositioner_vgh76_37 {
  position: absolute;
  left: 0;
  top: 0;
}
.react-app ._toggleCirclePositioner_vgh76_37._active_vgh76_9 {
  left: unset;
  right: 0;
}

.react-app ._toggleCircle_vgh76_37,
.react-app ._checkedToggleCircle_vgh76_48 {
  height: 16px;
  width: 16px;
  margin: var(--space-tiny);
  box-shadow: var(--shadow-1);
}

.react-app ._toggleCircle_vgh76_37 {
  border-radius: 50%;
  background-color: white;
}

.react-app ._checkedToggleCircle_vgh76_48._alwaysActive_vgh76_32 {
  box-shadow: none;
}
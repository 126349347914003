import React, { useState } from 'react'
import { Trans } from 'react-i18next'

import { Text, useScreenClass } from '@fullfabric/alma-mater'
import { useSettings } from '@fullfabric/authorization-officer'

import EventDetailRow from '../event-detail-row'
import AddressIcon from './address-icon'
import Map from './map'

import styles from './styles.module.scss'

const EventAddress = ({ event, isLastItem }) => {
  const screenClass = useScreenClass()
  const isInMobileLayout = ['xs', 'sm'].includes(screenClass)

  const settings = useSettings()
  const [isMapVisible, setIsMapVisible] = useState()
  const canShowMap = event?.address
  const shouldShowMap = !isInMobileLayout || isMapVisible
  const googleMapsKey = settings?.hash?.integrations?.google?.maps?.key

  if (!event?.address && !event?.location) {
    return null
  }

  const mainInfo = event.location || event.address
  const secondaryInfo = event.location ? event.address : null

  return (
    <>
      <EventDetailRow
        noMargin={!canShowMap && isLastItem}
        icon={<AddressIcon />}
      >
        <Text
          type='f4'
          fontColor='text-base-darkest'
          data-testid='locationMainInfo'
        >
          {mainInfo}
        </Text>
        {secondaryInfo ? (
          <Text
            type='f5'
            fontColor='text-base-darker'
            data-testid='locationSecondaryInfo'
          >
            {secondaryInfo}
          </Text>
        ) : null}
        {isInMobileLayout && canShowMap ? (
          <Text
            className={styles.toggleMapLink}
            type='h5'
            fontColor='text-primary'
            onClick={() => {
              setIsMapVisible((v) => !v)
            }}
          >
            {isMapVisible ? <Trans>Hide map</Trans> : <Trans>Show map</Trans>}
          </Text>
        ) : null}
      </EventDetailRow>
      {canShowMap && shouldShowMap ? (
        <div data-testid='eventMap'>
          <Map
            address={event.address}
            withMargin={!isLastItem}
            apiKey={googleMapsKey}
          />
        </div>
      ) : null}
    </>
  )
}

export default EventAddress

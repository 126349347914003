.react-app ._titlePlaceholder_1saap_1 {
  height: 16px;
  margin-top: var(--space-more);
}

.react-app ._datePlaceholder_1saap_6 {
  height: 14px;
  margin-top: var(--space-tiny);
}

.react-app ._linkPlaceholder_1saap_11 {
  margin-top: var(--space-tiny);
  height: 14px;
}
import React from 'react'

export const SettingsSectionsBeingEditedContext = React.createContext({})

export const SettingsSectionsBeingEditedProvider = ({ children }) => {
  const [sectionsBeingEdited, setSectionsBeingEdited] = React.useState({
    credits: false,
    paths: false,
    categories: false,
    degreeClasses: false,
    finalGrades: false
  })

  return (
    <SettingsSectionsBeingEditedContext.Provider
      value={{ sectionsBeingEdited, setSectionsBeingEdited }}
    >
      {children}
    </SettingsSectionsBeingEditedContext.Provider>
  )
}

export const useSettingsSectionsBeingEdited = () =>
  React.useContext(SettingsSectionsBeingEditedContext).sectionsBeingEdited

export const useSetSettingsSectionsBeingEdited = () =>
  React.useContext(SettingsSectionsBeingEditedContext).setSectionsBeingEdited

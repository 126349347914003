.react-app ._container_1nbtr_1 {
  max-width: 850px;
}
.react-app ._container_1nbtr_1 ._header_1nbtr_4 {
  display: flex;
  margin-top: var(--space-default);
}
.react-app ._container_1nbtr_1 ._customTabName_1nbtr_8 {
  margin-top: var(--space-less);
  margin-bottom: var(--space-tiny);
}
.react-app ._container_1nbtr_1 ._noticeContainer_1nbtr_12 {
  border: var(--border-base-lighter);
  border-radius: 12px;
  background-color: var(--ff-bg-base-lightest);
  padding: var(--space-big);
  text-align: center;
}
import React from 'react'
import { useTranslation } from 'react-i18next'

import {
  MERGE_STEPS,
  useMergeData
} from 'apps/MergeProfiles/contexts/MergeData'
import Step from '../Step'

const SelectDuplicateProfileStep = () => {
  const { t } = useTranslation()
  const { currentMergeStep, mergeData } = useMergeData()

  return (
    <Step
      name={t('Select duplicate profile')}
      active={currentMergeStep === MERGE_STEPS.selectDuplicateProfile}
      completed={!!(mergeData.targetProfileId && mergeData.sourceProfileId)}
    />
  )
}

export default SelectDuplicateProfileStep

import React, { useContext } from 'react'
import { useQuery } from 'react-query'

import getClassOf from 'apps/Applics/api/getClassOf'
import { useApplicData } from './ApplicData'

const ClassOfDataContext = React.createContext()

const ClassOfDataProvider = ({ children }) => {
  const applic = useApplicData()

  const { data: classOf } = useQuery(
    [
      'classOf',
      applic?.context_type === 'Institutions::ClassOf'
        ? applic.context_id
        : (applic?.primary_choice_contexts || [])[0]?.context_id
    ],
    getClassOf,
    {
      enabled: Boolean(
        applic?.context_type === 'Institutions::ClassOf'
          ? applic.context_id
          : (applic?.primary_choice_contexts || [])[0]?.context_id
      ),
      suspense: true
    }
  )

  return (
    <ClassOfDataContext.Provider value={classOf}>
      {children}
    </ClassOfDataContext.Provider>
  )
}

const useClassOfData = () => useContext(ClassOfDataContext)

export { ClassOfDataProvider, useClassOfData }

import React from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

import {
  ArrowRightIcon,
  IconButton,
  Link,
  Loading,
  Table
} from '@fullfabric/alma-mater'

import { Description } from '../Description'
import { State } from '../State'

export const UsageTable = ({ id }) => {
  const { t } = useTranslation()

  const { data, isLoading } = useQuery({
    queryKey: ['emailTemplates', id, 'usage'],
    queryFn: () =>
      fetch(`/api/email_templates/${id}/usage_tabular`).then((res) =>
        res.json()
      )
  })

  const getUrlForRow = (row) => {
    switch (row.sub_type || row.type) {
      case 'email':
        return `/profiles/${row.to_profile_id || row.from_profile_id}#emails/${
          row.id
        }/edit`
      case 'campaign':
        return `/campaigns#${row.id}/edit/message`
      case 'event_message':
        return `/events#${row.id}/message`
      case 'event_reminder':
        return `/events#${row.id}/reminders`
      case 'application_applicant_notification':
        return `/institutions/settings#applic-templates/${row.id}/automation/notification-template-applicant`
      case 'event_automation':
        return `/events#${row.id}/automation`
      case 'form_automation':
        return `/forms#${row.id}/automation`
      case 'offer_automation':
        return `/institutions/settings#offer-templates/${row.id}/automation`
      case 'class_of':
        return `/institutions/settings#programmes/${row.program_id}/classes/${row.id}/automation`
      case 'lifecycle_workflow_groups':
        return `/institutions/settings#lifecycleWorkflowGroups/${row.id}/workflows`
      case 'application_templates_automation':
        return `/institutions/settings#applic-templates/${row.id}/automation/automation`
      case 'segments_automation':
        return `/segments#${row.id}/automation`
      case 'evaluation_templates_automation':
        return `/institutions/settings#evaluation-templates/${row.id}/automation`
      default:
        return ''
    }
  }

  return (
    <Table.Container>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderItem>{t('Type')}</Table.HeaderItem>
            <Table.HeaderItem>{t('Description')}</Table.HeaderItem>
            <Table.HeaderItem />
            <Table.HeaderItem disabled />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {!isLoading && !data?.length && (
            <Table.Row>
              <Table.Data colSpan='100' align='center'>
                {t('No data')}
              </Table.Data>
            </Table.Row>
          )}
          {isLoading && (
            <Table.Row>
              <Table.Data colSpan='100' align='center'>
                <Loading />
              </Table.Data>
            </Table.Row>
          )}
          {data?.map((entry) => (
            <Table.Row key={`${entry.id}-${entry.description}`}>
              <Table.Data main>{t(entry.type)}</Table.Data>
              <Description entry={entry} />
              <State entry={entry} />
              <Table.Data align='right'>
                <Link withoutStyles href={getUrlForRow(entry)} target='_parent'>
                  <IconButton tag='div' plain>
                    <ArrowRightIcon />
                  </IconButton>
                </Link>
              </Table.Data>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </Table.Container>
  )
}

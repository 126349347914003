import qs from 'qs'

import { utils } from '@fullfabric/public-api'

const getProfile = async (profileId, options = { full: true }) => {
  if (!profileId) return null

  const params = qs.stringify(options)
  const response = await fetch(`/api/profiles/${profileId}?${params}`, {
    credentials: 'same-origin'
  })
  return await utils.checkResponse(response)
}

export default getProfile

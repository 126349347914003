import React from 'react'
import { Trans } from 'react-i18next'
import { useMutation } from 'react-query'

import {
  Button,
  CheckboxInput,
  Field,
  Modal,
  ModalButtons,
  ModalTitle,
  Text
} from '@fullfabric/alma-mater'

import updateElectiveGroup from 'apps/StudyPlans/api/updateElectiveGroup'
import { useSelectedPath } from 'apps/StudyPlans/contexts/SelectedPath'
import {
  useSetStudyPlan,
  useStudyPlan
} from 'apps/StudyPlans/contexts/StudyPlanData'
import replaceStudyPlanPath from 'apps/StudyPlans/utils/replaceStudyPlanPath'
import validateGroupData from './validate-group-data'

import styles from './styles.module.scss'

const ERROR_MESSAGE_MAP = {
  notANumber: <Trans>Must be a number</Trans>,
  blank: <Trans>Can't be blank</Trans>,
  zero: <Trans>Must be greater than 0</Trans>,
  minMustBeLessThanMax: <Trans>Minimum must be less than maximum</Trans>,
  maxMustBeGreaterThanMin: <Trans>Maximum must be greater than minimum</Trans>
}

const ElectiveSubjectsSettingsModal = ({ isOpen, electiveGroup, onCancel }) => {
  const studyPlan = useStudyPlan()
  const setStudyPlan = useSetStudyPlan()
  const selectedPath = useSelectedPath()

  const [data, setData] = React.useState(() => ({
    group_id: electiveGroup.id, // needs to be sent to the api call
    name: electiveGroup.name,
    min_mandatory_courses: electiveGroup.min_mandatory_courses,
    max_mandatory_courses: electiveGroup.max_mandatory_courses
  }))

  const [errors, setErrors] = React.useState({})
  const onDataChanged = React.useCallback((newData) => {
    setData(newData)
    setErrors(validateGroupData(newData))
  }, [])

  const { mutate: updateGroupMutation, isLoading } = useMutation(
    ({ studyPlanId, pathId, groupData }) => {
      return updateElectiveGroup(studyPlanId, pathId, groupData)
    },
    {
      onSuccess: (updatedPath) => {
        const updatedStudyPlan = replaceStudyPlanPath(studyPlan, updatedPath)
        setStudyPlan(updatedStudyPlan)

        onCancel && onCancel()
      }
    }
  )

  const onSaveChanges = React.useCallback(() => {
    updateGroupMutation({
      studyPlanId: studyPlan.id,
      pathId: selectedPath.id,
      groupData: data
    })
  }, [studyPlan.id, selectedPath.id, data, updateGroupMutation])

  return (
    <Modal
      header={
        <ModalTitle
          title={<Trans>Elective group settings</Trans>}
          onClose={onCancel}
        />
      }
      footer={
        <ModalButtons>
          <Button onClick={onCancel}>
            <Trans>Cancel</Trans>
          </Button>
          <Button
            primary
            onClick={onSaveChanges}
            loading={isLoading}
            disabled={Object.keys(errors).length > 0}
          >
            <Trans>Save changes</Trans>
          </Button>
        </ModalButtons>
      }
      isOpen={isOpen}
      onClose={onCancel}
    >
      <Text marginBottom='more' fontColor='text-base-darkest'>
        <Trans>
          Define if there are any restrictions for the number of elective
          subjects that students can select.
        </Trans>
      </Text>
      <CheckboxInput
        id='min_mandatory_courses_active'
        label={<Trans>Define a minimum number of subjects</Trans>}
        checked={data.min_mandatory_courses != null}
        instructions={
          <Trans>
            Students must select at least the minimum number of subjects.
          </Trans>
        }
        onChange={(checked) =>
          onDataChanged({
            ...data,
            min_mandatory_courses: checked ? '' : null
          })
        }
      />
      {data.min_mandatory_courses != null && (
        <Field
          id='min_mandatory_courses'
          type='text'
          value={
            data.min_mandatory_courses && data.min_mandatory_courses.toString()
          }
          inputOptions={{
            'data-testid': 'min-elective-input',
            className: styles.minMaxFieldInput
          }}
          className={styles.minMaxField}
          onChange={(field, value) =>
            onDataChanged({ ...data, [field]: value, field })
          }
          error={ERROR_MESSAGE_MAP[errors.min_mandatory_courses]}
        />
      )}
      <CheckboxInput
        id='max_mandatory_courses_active'
        label={<Trans>Define a maximum number of subjects</Trans>}
        checked={data.max_mandatory_courses != null}
        instructions={
          <Trans>
            Students cannot select more than the maximum number of subjects.
          </Trans>
        }
        onChange={(checked) =>
          onDataChanged({
            ...data,
            max_mandatory_courses: checked ? '' : null
          })
        }
      />
      {data.max_mandatory_courses != null && (
        <Field
          id='max_mandatory_courses'
          type='text'
          value={
            data.max_mandatory_courses && data.max_mandatory_courses.toString()
          }
          inputOptions={{
            'data-testid': 'max-elective-input',
            className: styles.minMaxFieldInput
          }}
          className={styles.minMaxField}
          onChange={(field, value) =>
            onDataChanged({ ...data, [field]: value, field })
          }
          error={ERROR_MESSAGE_MAP[errors.max_mandatory_courses]}
        />
      )}
    </Modal>
  )
}

export default ElectiveSubjectsSettingsModal

.react-app ._editButton_uw0ur_1 {
  overflow: hidden;
}

.react-app ._separator_uw0ur_5 {
  display: none;
}

.react-app ._section_uw0ur_9 {
  padding-bottom: var(--space-less);
  padding-top: var(--space-less);
}
.react-app ._section_uw0ur_9 > p:first-child {
  font-size: var(--font-size-m);
  line-height: 30px;
  font-weight: 400;
  margin-bottom: 0;
}
.react-app ._section_uw0ur_9 > div {
  margin-top: var(--space-less) !important;
}
.react-app ._section_uw0ur_9 > div > p:first-child {
  margin-top: var(--space-less) !important;
}
import React from 'react'

const ArrowIcon = ({ className }) => (
  <svg
    className={className}
    width='12'
    height='12'
    viewBox='0 0 12 12'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path d='M8 6L4 3V9L8 6Z' />
  </svg>
)

export default ArrowIcon

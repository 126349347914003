import React from 'react'
import { Trans } from 'react-i18next'

import { Button, Col, Label, Row, Text } from '@fullfabric/alma-mater'

import STATE_DESCRIPTIONS from 'apps/Courses/utils/courseStateDescriptions'

import styles from './styles.module.scss'

const CourseEditDisplayStatus = ({ data, onEditClick }) => {
  const courseState = STATE_DESCRIPTIONS[data.state || 'init']

  return (
    <>
      <Row>
        <Col xs={12}>
          <Text fontColor='text-base' bold>
            <Trans>Current status</Trans>
          </Text>

          <Label className='marginTop-tiny' color={courseState.color}>
            {courseState.description}
          </Label>

          <Text fontColor='text-base' type='f6' marginTop='tiny'>
            {data.state_auto_mode ? (
              <Trans>Update state automatically</Trans>
            ) : (
              <Trans>Update state manually</Trans>
            )}
          </Text>
        </Col>
      </Row>

      <div className='marginTop-default'>
        <Button
          size='small'
          aria-label='edit course status'
          onClick={() => onEditClick(data)}
          className={styles.editButton}
        >
          <Trans>Edit</Trans>
        </Button>
      </div>
    </>
  )
}

export default CourseEditDisplayStatus

import React, { useCallback, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'

import {
  Button,
  Field,
  Modal,
  ModalButtons,
  ModalTitle,
  Text
} from '@fullfabric/alma-mater'

import createSegment from 'apps/Segments/api/createSegment'

import styles from './styles.module.scss'

export default function CreationModal({ isOpen, handleClose }) {
  const { t } = useTranslation()
  const { register, handleSubmit, formState, reset, setFocus } = useForm()
  const { errors } = formState

  const { ref: nameInputRef, ...nameInputProps } = register('name', {
    required: t("Can't be blank")
  })

  useEffect(() => {
    setFocus('name')
  }, [setFocus])

  const createSegmentMutation = useMutation(
    ({ formValues }) => createSegment(formValues),
    {
      onSuccess: (data) => {
        window.top.location.assign(`/segments#${data.id}/builder`)
      }
    }
  )

  const onSubmit = useCallback(
    (formValues) => createSegmentMutation.mutate({ formValues }),
    [createSegmentMutation]
  )

  const closeModal = () => {
    handleClose()
    reset()
  }

  const isRedirecting =
    createSegmentMutation.isLoading || createSegmentMutation.isSuccess

  return (
    <Modal
      id='segment-creation-modal'
      role='dialog'
      aria-labelledby='creation-modal-title'
      isOpen={isOpen}
      size='small'
      header={
        <ModalTitle
          title={
            <span id='creation-modal-title'>{t('Create a new segment')}</span>
          }
          onClose={closeModal}
        />
      }
      footer={
        <ModalButtons>
          <Button onClick={closeModal} disabled={isRedirecting}>
            {t('Cancel')}
          </Button>

          <Button
            primary
            type='submit'
            form='creation-modal-form'
            onClick={handleSubmit(onSubmit)}
            loading={isRedirecting}
          >
            {t('Create segment')}
          </Button>
        </ModalButtons>
      }
    >
      <form id='creation-modal-form'>
        <Field
          {...nameInputProps}
          className={styles.lastField}
          inputRef={nameInputRef}
          type='text'
          label={t('Name')}
          error={errors.name?.message}
        />
      </form>

      {createSegmentMutation.isError && (
        <Text fontColor='text-alert' type='f5' marginTop='default'>
          {t(
            'There was an error, please refresh the page and try again. If the issue persists, please contact us.'
          )}
        </Text>
      )}
    </Modal>
  )
}

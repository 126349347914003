import React, { useCallback } from 'react'

import qs from 'qs'

import {
  ChoicesDataContext,
  ModalsDispatchContext,
  ModalsStateContext,
  StartApplicationParamsContext
} from './provider'

const useDispatch = () => {
  const dispatch = React.useContext(ModalsDispatchContext)

  if (typeof dispatch === 'undefined') {
    throw new Error(
      'useDispatch must be within a StartApplicationStateProvider'
    )
  }

  return dispatch
}

export const useStartApplicationParams = () => {
  const context = React.useContext(StartApplicationParamsContext)

  if (typeof context === 'undefined') {
    throw new Error(
      'useStartApplicationParams must be within a StartApplicationStateProvider'
    )
  }

  return context
}

export const useModalsState = () => {
  const context = React.useContext(ModalsStateContext)

  if (typeof context === 'undefined') {
    throw new Error(
      'useModalsState must be within a StartApplicationStateProvider'
    )
  }

  return context
}

export const useChoicesData = () => {
  const context = React.useContext(ChoicesDataContext)

  if (typeof context === 'undefined') {
    throw new Error(
      'useChoicesData must be within a StartApplicationStateProvider'
    )
  }

  return context
}

const useNextStep = () => {
  const { programmeAndClass, thirdPartyApplication } = useModalsState()

  const path = [
    programmeAndClass.required && 'PROGRAMME_AND_CLASS_MODAL',
    thirdPartyApplication.required && 'THIRD_PARTY_APPLICATION_MODAL'
  ].filter((step) => !!step)

  const currentStep = programmeAndClass.visible
    ? 'PROGRAMME_AND_CLASS_MODAL'
    : thirdPartyApplication.visible
    ? 'THIRD_PARTY_APPLICATION_MODAL'
    : null

  const currentStepIndex = path.findIndex((step) => step === currentStep) // if currentStep is null, index will be -1 = not started yet

  return path[currentStepIndex + 1] // if index -1 it will return first step
}

const defaultApplicationStart = (templateId) =>
  (window.location.href = `/templates/${templateId}/start`)

export const useStartApplicationProcess = (choices) => {
  const firstStep = useNextStep()
  const isMultiStepProcess = !!firstStep
  const {
    programmeAndClass: { required: hasProgrammeAndClassModal }
  } = useModalsState()
  const dispatch = useDispatch()

  const openStartApplicationModals = useCallback(async () => {
    try {
      if (hasProgrammeAndClassModal) {
        dispatch({ type: 'GET_CHOICES_DONE', data: choices })
      }
      dispatch({ type: 'OPEN', target: firstStep })
    } catch (e) {
      dispatch({ type: 'GET_CHOICES_ERROR', data: e })
    }
  }, [dispatch, firstStep, hasProgrammeAndClassModal, choices])

  if (isMultiStepProcess) {
    return openStartApplicationModals
  } else {
    return defaultApplicationStart
  }
}

export const useCloseModal = () => {
  const dispatch = useDispatch()

  return () => {
    dispatch({ type: 'CLOSE' })
  }
}

export const useAdvance = () => {
  const params = useStartApplicationParams()
  const { thirdPartyApplication } = useModalsState()
  const dispatch = useDispatch()
  const nextStep = useNextStep()

  const isPreCreatedApplication = thirdPartyApplication.required

  return async (updatedData = {}) => {
    dispatch({ type: 'SAVE', data: updatedData })
    const updatedParams = { ...params, ...updatedData }

    if (nextStep) {
      dispatch({ type: 'ADVANCE', target: nextStep })
      return
    }

    let pathname = ``
    let urlParams = ``

    if (isPreCreatedApplication) {
      pathname = `/applications/${updatedParams.applicationId}`
    } else {
      pathname = `/templates/${updatedParams.templateId}/start`
      urlParams = `?${qs.stringify({ context: updatedParams.context })}`
    }

    dispatch({ type: 'SUBMIT' })
    window.location.href = `${pathname}${urlParams}`
  }
}

import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Alert } from '@fullfabric/alma-mater'

import PageLayout from 'apps/MergeProfiles/components/PageLayout'
import ProfileSelector from 'apps/MergeProfiles/components/ProfileSelector'
import ProfileProvider from 'apps/MergeProfiles/components/ProfilesProvider'
import {
  MERGE_STEPS,
  useMergeData
} from 'apps/MergeProfiles/contexts/MergeData'
import useCurrentPage from 'apps/MergeProfiles/hooks/useCurrentPage'
import NextActionButton from './NextActionButton'

const ERROR_MAP = {
  cannotBeMerged: (
    <Trans>
      The duplicate profile contains information which cannot be merged. Please
      manually transfer the information or contact support.
    </Trans>
  )
}

const SelectDuplicateProfilePage = () => {
  useCurrentPage(MERGE_STEPS.selectDuplicateProfile)

  const { t } = useTranslation()

  const {
    mergeData: { sourceProfile }
  } = useMergeData()

  const duplicateProfileError =
    sourceProfile &&
    !sourceProfile.can_be_merged_into_and_deleted &&
    ERROR_MAP.cannotBeMerged

  const actionButton = <NextActionButton />

  return (
    <>
      <PageLayout.Header
        title={t('Select the duplicate profile')}
        description={t(
          'The duplicate profile will be merged into the main profile'
        )}
        actions={actionButton}
      />
      <PageLayout.Content>
        {duplicateProfileError && (
          <Alert
            error
            title={<Trans>Unable to merge.</Trans>}
            description={duplicateProfileError}
            marginBottom='big'
          />
        )}
        <ProfileProvider>
          <ProfileSelector />
        </ProfileProvider>
        {duplicateProfileError && (
          <Alert
            error
            title={<Trans>Unable to merge.</Trans>}
            description={duplicateProfileError}
            marginTop='big'
          />
        )}
      </PageLayout.Content>
      <PageLayout.Footer actions={actionButton} />
    </>
  )
}

export default SelectDuplicateProfilePage

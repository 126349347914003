/* eslint-disable camelcase */

import React from 'react'
import { useTranslation } from 'react-i18next'

import moment from 'moment'

import { useAppData } from 'shared/contexts/AppData'
import normalizeLocale from 'shared/utils/normalizeLocale'

const PolicyAcceptanceDescription = ({ policyType }) => {
  const { t } = useTranslation()
  const {
    locale,
    marketing_policy_acceptance,
    privacy_policy_acceptance,
    institution
  } = useAppData()

  const acceptance =
    policyType === 'marketing'
      ? marketing_policy_acceptance
      : privacy_policy_acceptance

  const institutionHost = (institution && institution.host) || ''

  if (!acceptance || !acceptance.source) return null

  moment.locale(normalizeLocale(locale))

  switch (acceptance.source._type) {
    case 'Policies::Source::Portal':
    case 'Policies::Source::Portal::Settings':
    case 'Policies::Source::Signup':
    case 'Policies::Source::Signin':
      if (acceptance.policy._type === 'Policies::Privacy') {
        return (
          <>
            {t(
              'You accepted our privacy policy on our website - {{institutionHost}} - on {{date}}',
              {
                institutionHost,
                date: moment(acceptance.created_at).format('ll')
              }
            )}
          </>
        )
      } else {
        return (
          <>
            {t(
              'You are receiving this email because you accepted our marketing policy on our website - {{institutionHost}} - on {{date}}',
              {
                institutionHost,
                date: moment(acceptance.created_at).format('ll')
              }
            )}
          </>
        )
      }
    case 'Policies::Source::Campaign':
      if (acceptance.policy._type === 'Policies::Privacy') {
        return (
          <>
            {t('You accepted our privacy policy on {{date}} from our email', {
              date: moment(acceptance.created_at).format('ll')
            })}
          </>
        )
      } else {
        return (
          <>
            {t(
              'You are receiving this email because you accepted our marketing policy on {{date}} from our email',
              {
                date: moment(acceptance.created_at).format('ll')
              }
            )}
          </>
        )
      }
    case 'Policies::Source::Staff::ProfileCreation':
    case 'Policies::Source::Staff::Import':
    case 'Policies::Source::Staff::BulkUnsubscribe':
      return (
        <>
          {t(
            'You are receiving this email because a person created a profile for you and obtained your consent from {{consentSource}} on {{date}}',
            {
              consentSource: acceptance.options.source_of_consent,
              date: moment(acceptance.options.date_of_consent).format('ll')
            }
          )}
        </>
      )
    case 'Policies::Source::Forms::Submission':
      if (acceptance.policy._type === 'Policies::Privacy') {
        return (
          <>
            {t(
              'You accepted our privacy policy when submitting the {{entity}} form on {{date}}',
              {
                entity: acceptance.source.entity_name,
                date: moment(acceptance.created_at).format('ll')
              }
            )}
          </>
        )
      } else {
        return (
          <>
            {t(
              'You accepted our marketing policy when submitting the {{entity}} form on {{date}}',
              {
                entity: acceptance.source.entity_name,
                date: moment(acceptance.created_at).format('ll')
              }
            )}
          </>
        )
      }
    case 'Policies::Source::Events::Response':
      if (acceptance.policy._type === 'Policies::Privacy') {
        return (
          <>
            {t(
              'You accepted our privacy policy when registering for the {{entity}} event on {{date}}',
              {
                entity: acceptance.source.entity_name,
                date: moment(acceptance.created_at).format('ll')
              }
            )}
          </>
        )
      } else {
        return (
          <>
            {t(
              'You accepted our marketing policy when registering for the {{entity}} event on {{date}}',
              {
                entity: acceptance.source.entity_name,
                date: moment(acceptance.created_at).format('ll')
              }
            )}
          </>
        )
      }
    default:
      return null
  }
}

export default PolicyAcceptanceDescription

import React from 'react'
import { useTranslation } from 'react-i18next'

import { Text } from '@fullfabric/alma-mater'

import PremiumIcon from './PremiumIcon'

import styles from './styles.module.scss'

const PremiumLabel = () => {
  const { t } = useTranslation()
  return (
    <a
      className={styles.labelContainer}
      href='https://pricing.fullfabric.com'
      target='_blank'
      rel='noopener noreferrer'
    >
      <PremiumIcon />
      <Text type='h6' fontColor='yellow-900' marginLeft='small'>
        {t('Premium plan required')}
      </Text>
    </a>
  )
}

export default PremiumLabel

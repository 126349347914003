import React from 'react'
import { Trans } from 'react-i18next'
import { useLocation } from 'react-router'

import { Link } from '@fullfabric/alma-mater'

import { useImportDocument } from 'apps/Imports/contexts/import-document'
import { IMPORT_TYPES } from 'apps/Imports/utils/constants'
import getOrGuessImportType from 'apps/Imports/utils/get-or-guess-import-type'
import { ReactComponent as CsvIcon } from './csv-icon.svg'

import styles from './styles.module.scss'

const SUPPORTED_IMPORT_TYPES = [
  IMPORT_TYPES.PROGRAMME_AND_CLASS,
  IMPORT_TYPES.SUBJECT_AND_COURSE
]

const DownloadImportTemplateLink = () => {
  const location = useLocation()
  const importDocument = useImportDocument()
  const importType = getOrGuessImportType(location, importDocument)

  // Only support for classes and courses
  if (!SUPPORTED_IMPORT_TYPES.includes(importType)) return null

  return (
    <div className={styles.container}>
      <Link
        href={`/api/import/import_template?type=${importType}`}
        target='_blank'
        className={styles.link}
      >
        <CsvIcon className={styles.icon} />

        <Trans>Import template</Trans>
      </Link>
    </div>
  )
}

export default DownloadImportTemplateLink

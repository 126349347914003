import { utils } from '@fullfabric/public-api'

const patchCourse = async ({ applicId, body }) => {
  const response = await fetch(`/api/applics/${applicId}`, {
    method: 'PATCH',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  })

  return await utils.checkResponse(response)
}

export default patchCourse

import React from 'react'
import { Trans } from 'react-i18next'

import { Text } from '@fullfabric/alma-mater'

import ChartCard from 'apps/Reports/components/ChartCard'
import { commonStyles } from 'apps/Reports/components/CustomChartElements'
import Chart from './Chart'

const AgeChart = () => {
  return (
    <ChartCard>
      <Text type='h4' fontColor='text-base-darkest' marginBottom='tiny'>
        <Trans>Age</Trans>
      </Text>
      <div className={commonStyles.chartContainer}>
        <Chart />
      </div>
    </ChartCard>
  )
}

export default AgeChart

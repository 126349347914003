import React from 'react'

const AttachmentIcon = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 12 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M6 20C4.46667 20 3.16667 19.4667 2.1 18.4C1.03333 17.3333 0.5 16.0333 0.5 14.5V4C0.5 2.9 0.891667 1.95833 1.675 1.175C2.45833 0.391667 3.4 0 4.5 0C5.6 0 6.54167 0.391667 7.325 1.175C8.10833 1.95833 8.5 2.9 8.5 4V13.5C8.5 14.2 8.25833 14.7917 7.775 15.275C7.29167 15.7583 6.7 16 6 16C5.3 16 4.70833 15.7583 4.225 15.275C3.74167 14.7917 3.5 14.2 3.5 13.5V4H5V13.5C5 13.7833 5.09583 14.0208 5.2875 14.2125C5.47917 14.4042 5.71667 14.5 6 14.5C6.28333 14.5 6.52083 14.4042 6.7125 14.2125C6.90417 14.0208 7 13.7833 7 13.5V4C7 3.3 6.75833 2.70833 6.275 2.225C5.79167 1.74167 5.2 1.5 4.5 1.5C3.8 1.5 3.20833 1.74167 2.725 2.225C2.24167 2.70833 2 3.3 2 4V14.5C2 15.6 2.39167 16.5417 3.175 17.325C3.95833 18.1083 4.9 18.5 6 18.5C7.1 18.5 8.04167 18.1083 8.825 17.325C9.60833 16.5417 10 15.6 10 14.5V4H11.5V14.5C11.5 16.0333 10.9667 17.3333 9.9 18.4C8.83333 19.4667 7.53333 20 6 20Z'
        fill='#BBBBBB'
      />
    </svg>
  )
}

export default function EmptyState() {
  return (
    <div className='row'>
      <div className='col-12 text-center'>
        <AttachmentIcon className='fa fa-paperclip' />
      </div>
      <div className='col-12 text-center mt-2' style={{ color: '#BBBBBB' }}>
        No files attached
      </div>
    </div>
  )
}

import React from 'react'

import { ErrorIcon, SuccessIcon, Text } from '@fullfabric/alma-mater'

import styles from './FinalStateLabel/styles.module.scss'

const FinalStateLabel = ({ accepted }) => (
  <div className={styles.labelContainer}>
    {accepted ? (
      <>
        <SuccessIcon color='text-success' />
        <Text tag='span' type='h6' fontColor='text-success' marginLeft='tiny'>
          Accepted
        </Text>
      </>
    ) : (
      <>
        <ErrorIcon color='text-alert' />
        <Text tag='span' type='h6' fontColor='text-alert' marginLeft='tiny'>
          Declined
        </Text>
      </>
    )}
  </div>
)

export default FinalStateLabel

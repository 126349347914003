import React from 'react'

import { Table } from '@fullfabric/alma-mater'

import PlaceholderTableCell from './Cell'

import styles from './styles.module.scss'

function PlaceholderTableRow({ virtualized, columns, level }) {
  const rowProps = virtualized
    ? {
        role: 'row',
        tag: 'div',
        className: styles.virtualizedRow
      }
    : {}

  return (
    <Table.Row {...rowProps} aria-label='loading row'>
      {columns.map((column, columnIndex) => {
        return (
          <PlaceholderTableCell
            key={columnIndex}
            columnIndex={columnIndex}
            columnData={column}
            virtualized={virtualized}
            level={level}
          />
        )
      })}
    </Table.Row>
  )
}

export default PlaceholderTableRow

import React from 'react'
import { Trans } from 'react-i18next'

import { Col, Row, Text } from '@fullfabric/alma-mater'

import styles from './styles.module.scss'

const CardInfo = ({ title, main, secondary, containerClassName }) => {
  const hasContent = !!main || typeof main === 'number'

  return (
    <Row className={containerClassName}>
      <Col xs={12}>
        {title ? (
          <Text type='h5' fontColor='text-base-darker' className={styles.title}>
            {title}
          </Text>
        ) : null}
        <Text
          type='f4'
          fontColor={hasContent ? 'text-base-darkest' : 'text-base'}
          className={styles.text}
          data-testid='card-info-value'
        >
          {hasContent ? main : <Trans>Not defined</Trans>}
        </Text>
        {secondary && hasContent ? (
          <Text
            type='f5'
            fontColor='text-base'
            className={styles.text}
            data-testid='card-info-secondary-value'
          >
            {secondary}
          </Text>
        ) : null}
      </Col>
    </Row>
  )
}

export default CardInfo

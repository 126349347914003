import React from 'react'

import styles from './styles.module.scss'

export function Card({ children, title }) {
  return (
    <div className={styles.card}>
      <div className={styles.title}>{title}</div>
      <div className={styles.content}>{children}</div>
    </div>
  )
}

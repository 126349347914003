import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { CheckIcon, Text } from '@fullfabric/alma-mater'

import { useLocale } from 'shared/contexts/Locale'

import useIsMobileLayout from 'apps/ContentPages/components/payments-page/use-is-payments-mobile-layout'
import {
  formatDueDate,
  timeFromDueDate,
  timeToDueDate
} from 'apps/ContentPages/services/payments/format-due-date'

import styles from './styles.module.scss'

const DueDateDetail = ({ date, state }) => {
  const { locale } = useLocale()
  const { t } = useTranslation()

  const isInMobileLayout = useIsMobileLayout()
  const isInDesktopLayout = !isInMobileLayout

  const isPaid = state === 'paid'
  const isOverdue = state === 'overdue'
  const isAlmostDue = state === 'almostDue'
  const isUpcoming = state === 'upcoming' || isAlmostDue

  const fontColor = isOverdue
    ? 'text-alert'
    : isPaid
    ? 'text-success'
    : 'text-base-darker'

  const textProps = {
    type: 'h5',
    marginTop: 'tiny',
    fontColor
  }

  if (isPaid) {
    return isInMobileLayout ? (
      <Text {...textProps} className={styles.paidLabel}>
        <Trans>
          Paid <CheckIcon color='text-success' className={styles.checkIcon} />
        </Trans>
      </Text>
    ) : null
  }

  if (isUpcoming) {
    const fontColor = isAlmostDue ? 'text-warning' : textProps.fontColor

    return (
      <Text {...textProps} fontColor={fontColor}>
        {isInMobileLayout
          ? formatDueDate(date, locale)
          : timeToDueDate(date, locale, t)}
      </Text>
    )
  }

  if (isOverdue) {
    const relativeTime = timeFromDueDate(date, locale, t)

    return isInDesktopLayout ? (
      <Text {...textProps}>{relativeTime}</Text>
    ) : (
      <Text {...textProps} type='f5'>
        {formatDueDate(date, locale)}{' '}
        <Text tag='span' type='h5' fontColor='text-alert'>
          {relativeTime}
        </Text>
      </Text>
    )
  }

  return null
}

export default DueDateDetail

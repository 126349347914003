import React from 'react'
import { Trans } from 'react-i18next'

import { Text } from '@fullfabric/alma-mater'

import useIsMobileLayout from 'apps/ContentPages/components/payments-page/use-is-payments-mobile-layout'
import PaymentsPageLayout from '../layout'
import PaymentPlanCard from './payment-plan-card'

import classNames from 'classnames'
import styles from './styles.module.scss'

const PaymentPlanListArea = ({ paymentPlans, isLoading }) => {
  const isMobileLayout = useIsMobileLayout()

  return (
    <PaymentsPageLayout.MainContentArea label={'area with all your fees'}>
      {isMobileLayout && (
        <Text
          type='h6'
          fontColor='text-base-darker'
          marginBottom='small'
          marginLeft='small'
          className={styles.title}
        >
          <Trans>Payment Plans</Trans>
        </Text>
      )}
      {isLoading ? (
        <PaymentsPageLayout.Placeholder />
      ) : paymentPlans?.length ? (
        paymentPlans.map((paymentPlan, index) => (
          <React.Fragment key={paymentPlan.id}>
            <PaymentPlanCard paymentPlan={paymentPlan} />
            {index < paymentPlans.length - 1 && (
              <div
                className={classNames(
                  styles.spaceBetweenTables,
                  isMobileLayout && styles.mobile
                )}
              />
            )}
          </React.Fragment>
        ))
      ) : (
        <Text>
          <Trans>No payment plans found.</Trans>
        </Text>
      )}
    </PaymentsPageLayout.MainContentArea>
  )
}

export default PaymentPlanListArea

import React from 'react'

import LoadingPlaceholderBlock from 'apps/ContentPages/components/loading-placeholder-block'
import useIsMobileLayout from 'apps/ContentPages/hooks/use-is-mobile-layout'

import classNames from 'classnames'
import styles from './styles.module.scss'

const Placeholder = () => {
  const isMobileLayout = useIsMobileLayout()

  return (
    <>
      <LoadingPlaceholderBlock
        data-testid='loading-title'
        className={classNames(styles.title, isMobileLayout && styles.mobile)}
      />
    </>
  )
}

export default Placeholder

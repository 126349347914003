import { TIMEFRAME_INTERVAL } from '../constants'

const getDateDifference = (start, end, timeframeInterval) => {
  if (!start || !end || !start.isBefore(end)) return null

  const day1 = start.clone()
  const day2 = end.clone()
  const datesBetweenMap = {}
  const format =
    timeframeInterval === TIMEFRAME_INTERVAL.WEEKLY ? 'YYYY-MM-DD' : 'YYYY-MM'

  while (day1.isSameOrBefore(day2, 'day')) {
    day1.add(1, 'day')
    datesBetweenMap[day1.format(format)] = true
  }
  const datesBetween = Object.keys(datesBetweenMap)

  const distance =
    timeframeInterval === TIMEFRAME_INTERVAL.WEEKLY
      ? Math.floor(datesBetween.length / 7.0)
      : datesBetween.length - 1

  return distance > 0 ? -distance : 0
}

export default getDateDifference

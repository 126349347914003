import React from 'react'
import { Trans } from 'react-i18next'

import { Text } from '@fullfabric/alma-mater'

import { ReactComponent as RemoveCircleOutlineIcon } from './icons/remove-circle-outline.svg'

import styles from './styles.module.scss'

const FileInvalidValidContent = () => (
  <div className={styles.fileUploadContent}>
    <RemoveCircleOutlineIcon />
    <Text className={styles.invalidText}>
      <Trans>Invalid file format</Trans>
    </Text>
  </div>
)

export default FileInvalidValidContent

import React from 'react'
import { Trans } from 'react-i18next'

import { Button } from '@fullfabric/alma-mater'

function ActionButton({ loading, onClick, size }) {
  return (
    <Button primary size={size} loading={loading} onClick={onClick}>
      <Trans>Add course</Trans>
    </Button>
  )
}

export default ActionButton

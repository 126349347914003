import React, { useMemo } from 'react'

import sortIntakes from 'apps/Reports/utils/sortIntakes'
import { useFilter } from './FilterContext'

const BaseClassContext = React.createContext()
const ClassesContext = React.createContext([])

export const ClassOptionsProvider = ({ allClasses, baseClass, children }) => {
  return (
    <BaseClassContext.Provider value={baseClass}>
      <ClassesContext.Provider value={allClasses}>
        {children}
      </ClassesContext.Provider>
    </BaseClassContext.Provider>
  )
}

export const useClassesData = () => React.useContext(ClassesContext)

export const useSelectedIntakesData = () => {
  const filter = useFilter()
  const intakesData = useClassesData()

  const selectedIntakes = useMemo(() => {
    if (!filter.classes?.length || !intakesData?.length) {
      return []
    }

    return filter.classes
      .map((selectedItkId) =>
        intakesData.find((itk) => itk.id === selectedItkId)
      )
      .filter((itk) => !!itk)
      .sort(sortIntakes)
  }, [filter.classes, intakesData])

  return selectedIntakes
}

export const useMainIntakeIndex = () => {
  const baseClass = React.useContext(BaseClassContext)
  const selectedIntakes = useSelectedIntakesData()

  if (!baseClass) {
    return 0
  }

  const mainIntakeIndex = selectedIntakes.findIndex(
    (aClass) => aClass.id === baseClass.id
  )

  return Math.max(mainIntakeIndex, 0)
}

export const useMainIntake = () => {
  const mainIntakeIndex = useMainIntakeIndex()
  const selectedIntakes = useSelectedIntakesData()

  const mainIntake = selectedIntakes && selectedIntakes[mainIntakeIndex]

  return mainIntake
}

export default ClassesContext

import React from 'react'
import { Trans } from 'react-i18next'
import { useQuery } from 'react-query'

import { Loading, SuccessIcon, Text } from '@fullfabric/alma-mater'

import getOffers from 'apps/MergeProfiles/api/getOffers'
import getPaymentPlans from 'apps/MergeProfiles/api/getPaymentPlans'
import getTranscriptsForProfile from 'apps/MergeProfiles/api/getTranscriptsForProfile'
import { BaseCardInfoRow } from './InfoRow'

const OtherInfoRow = ({ role }) => {
  const { data: offers, isLoading: offersLoading } = useQuery(
    [
      'getOffers',
      { offered_to_id: role.profile_id, context_id: role.context_id }
    ],
    () =>
      getOffers({
        offered_to_id: role.profile_id,
        context_id: role.context_id
      }),
    { retry: false }
  )
  const { data: paymentPlans, isLoading: paymentPlansLoading } = useQuery(
    ['getPaymentPlans', role.profile_id, { context_id: role.context_id }],
    () => getPaymentPlans(role.profile_id, { context_id: role.context_id }),
    { retry: false }
  )
  const { data: transcripts, isLoading: transcriptsLoading } = useQuery(
    ['getTranscriptsForProfile', { profile_id: role.profile_id }],
    () => getTranscriptsForProfile({ profile_id: role.profile_id }),
    { retry: false }
  )

  const isLoading = offersLoading || paymentPlansLoading || transcriptsLoading
  const hasOffers = offers?.length > 0
  const hasPaymentPlans = paymentPlans?.length > 0
  const hasTranscripts =
    transcripts?.filter(
      (transcript) => transcript.context_id === role.context_id
    ).length > 0

  if (!isLoading && !hasOffers && !hasPaymentPlans && !hasTranscripts)
    return null

  return (
    <BaseCardInfoRow title={<Trans>Others</Trans>}>
      {isLoading && <Loading />}

      {hasOffers && (
        <Text fontColor='text-success' marginTop='tiny'>
          <SuccessIcon color='text-success' /> <Trans>Offers</Trans>
        </Text>
      )}

      {hasPaymentPlans && (
        <Text fontColor='text-success' marginTop='tiny'>
          <SuccessIcon color='text-success' /> <Trans>Payment plans</Trans>
        </Text>
      )}

      {hasTranscripts && (
        <Text fontColor='text-success' marginTop='tiny'>
          <SuccessIcon color='text-success' /> <Trans>Transcripts</Trans>
        </Text>
      )}
    </BaseCardInfoRow>
  )
}

export default OtherInfoRow

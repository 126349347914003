import React from 'react'

const useOffsetTop = (elementRef) => {
  const [offsetTop, setOffsetTop] = React.useState()
  React.useEffect(() => {
    if (elementRef && elementRef.current) {
      const containerTop = elementRef.current.getBoundingClientRect().top
      const documentTop = document.body.getBoundingClientRect().top

      setOffsetTop(Math.round(containerTop - documentTop))
    }
  }, [elementRef, setOffsetTop])

  return offsetTop
}

export default useOffsetTop

import React from 'react'
import { Trans } from 'react-i18next'

const STATE_DESCRIPTIONS = {
  init: {
    description: <Trans>Class new</Trans>,
    color: 'primary-600',
    labelType: 'blue'
  },
  open: {
    description: <Trans>Applications Open</Trans>,
    color: 'green-700',
    labelType: 'green'
  },
  closed: {
    description: <Trans>Applications Closed</Trans>,
    color: 'red-600',
    labelType: 'red'
  },
  in_progress: {
    description: <Trans>Class in progress</Trans>,
    color: 'purple-500',
    labelType: 'purple'
  },
  done: {
    description: <Trans>Class finished</Trans>,
    color: 'grey-500',
    labelType: 'grey'
  }
}

export default STATE_DESCRIPTIONS

/* eslint-disable camelcase */

import React from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

import getInstitution from 'apps/Applics/api/getInstitution'
import { useWizardData } from 'apps/Applics/contexts/WizardDataContext'
import {
  TEMPLATE_NAME_STEP,
  TEMPLATE_TABS_STEP,
  useWizardNavigationState
} from 'apps/Applics/contexts/WizardNavigationContext'
import TabBar from './TabBar'

import styles from './styles.module.scss'

const Preview = () => {
  const { t } = useTranslation()
  const { name, enable_experience, references_enabled, payment_enabled } =
    useWizardData()
  const { currentStep } = useWizardNavigationState()
  const { data: institutionData, isLoading: isLoadingInstitution } = useQuery(
    'institution-data',
    getInstitution
  )

  return (
    <div className={styles.container}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        fill='none'
        viewBox='0 0 900 725'
        className={styles.content}
      >
        <rect width='900' height='725' fill='#EDF0F3' rx='8'></rect>
        <path
          fill='#EDF0F3'
          d='M0 8a8 8 0 018-8h884a8 8 0 018 8v16H0V8z'
        ></path>
        <path
          fill='#D8DDE2'
          d='M208 7a3 3 0 013-3h478a3 3 0 013 3v10a3 3 0 01-3 3H211a3 3 0 01-3-3V7zM12 12a4 4 0 118 0 4 4 0 01-8 0zM28 12a4 4 0 118 0 4 4 0 01-8 0zM44 12a4 4 0 118 0 4 4 0 01-8 0z'
        ></path>
        <path fill='#fff' d='M0 24h900v124H0V24z'></path>
        <path fill='#EDF0F3' d='M32 61H137V111H32z'></path>
        <text
          fill={name ? '#18191F' : '#B9BFC7'}
          style={{ whiteSpace: 'pre' }}
          fontFamily='SF Pro Display'
          fontSize='24'
          letterSpacing='0em'
          textAnchor='middle'
        >
          <tspan x='451' y='84.531'>
            {name || t('Application form name')}
            {currentStep === TEMPLATE_NAME_STEP &&
              (name ? (
                <tspan
                  data-testid='name-blinking-cursor'
                  fill='#18191F'
                  className={styles.blinking}
                >
                  |
                </tspan>
              ) : (
                <tspan
                  fill='#18191F'
                  data-testid='name-blinking-cursor'
                  className={styles.blinking}
                  x='451.176'
                  y='84.531'
                >
                  |
                </tspan>
              ))}
          </tspan>
        </text>
        <text
          fill='#9399A2'
          style={{ whiteSpace: 'pre' }}
          fontFamily='SF Pro Text'
          fontSize='14'
          letterSpacing='0em'
          textAnchor='middle'
        >
          <tspan x='451' y='110.977'>
            {isLoadingInstitution ? t('Loading...') : institutionData?.name}
          </tspan>
        </text>
        <g clipPath='url(#clip0)'>
          <path fill='#B9BFC7' d='M173 279H235V290H173z'></path>
          <path fill='#D8DDE2' d='M310 278H377V286H310z'></path>
          <path fill='#F9F9FA' d='M310 302H738V340H310z'></path>
          <path fill='#D8DDE2' d='M310 368H399V376H310z'></path>
          <path fill='#F9F9FA' d='M310 392H738V430H310z'></path>
          <path fill='#D8DDE2' d='M310 458H377V466H310z'></path>
          <path fill='#F9F9FA' d='M310 482H738V520H310z'></path>
          <path fill='#D8DDE2' d='M310 548H344V556H310z'></path>
          <path fill='#F9F9FA' d='M310 572H738V610H310z'></path>
          <path fill='#D8DDE2' d='M310 638H377V646H310z'></path>
          <path fill='#EDF0F3' d='M382 638H416V646H382z'></path>
          <path fill='#F9F9FA' d='M310 662H738V700H310z'></path>
          <path fill='#F9F9FA' d='M277 252H279V355H277z'></path>
          <path fill='#F9F9FA' d='M163 319H235V330H163z'></path>
        </g>
        <svg height='64' width='900' x='0' y='148' viewBox='0 148 900 64'>
          <svg x='0' y='148'>
            <path fill='#F9F9FA' d='M0 0H900V64H0z'></path>
            <path fill='#D8DDE2' d='M0 0H900V1H0z'></path>
            <path fill='#fff' d='M0 1H900V63H0z'></path>
            <path fill='#D8DDE2' d='M0 63H900V64H0z'></path>
            {currentStep < TEMPLATE_TABS_STEP ? (
              <>
                <path fill='#EDF0F3' d='M209 26H270V38H209z'></path>
                <path fill='#EDF0F3' d='M302 26H376V38H302z'></path>
                <path fill='#EDF0F3' d='M420 26H505V38H420z'></path>
                <rect
                  width='131'
                  height='32'
                  x='549'
                  y='16'
                  fill='#DCEEFB'
                  rx='4'
                ></rect>
              </>
            ) : (
              <TabBar
                enable_experience={enable_experience}
                references_enabled={references_enabled}
                payment_enabled={payment_enabled}
              />
            )}
          </svg>
        </svg>
        <defs>
          <clipPath id='clip0'>
            <path
              fill='#fff'
              d='M0 0H575V465H0z'
              transform='translate(163 252)'
            ></path>
          </clipPath>
        </defs>
      </svg>
    </div>
  )
}

export default Preview

import React from 'react'

import { getPathPlaceholder } from '../utils'

const usePathEditionCallbacks = ({ paths, setPaths }) => {
  const onPathAdd = React.useCallback(
    (evt) => {
      evt.preventDefault()
      setPaths({ ...paths, ...getPathPlaceholder() })
    },
    [paths, setPaths]
  )

  const onPathChanged = React.useCallback(
    (updatedPath) => {
      setPaths((paths) => ({ ...paths, [updatedPath.id]: updatedPath }))
    },
    [setPaths]
  )

  const onPathDelete = React.useCallback(
    (pathToDelete, cannotBeDeleted) => {
      if (!cannotBeDeleted) {
        const { [pathToDelete.id]: deletedPath, ...remainingPaths } = paths
        setPaths(
          Object.keys(remainingPaths).length === 0
            ? getPathPlaceholder()
            : remainingPaths
        )
      } else {
        setPaths(
          Object.values(paths)
            .map((path) =>
              path.id === pathToDelete.id
                ? { ...path, error: 'related_to_transcripts' }
                : path
            )
            .reduce(
              (acc, path) => ({
                ...acc,
                [path.id]: path
              }),
              {}
            )
        )
      }
    },
    [paths, setPaths]
  )

  return {
    onPathChanged,
    onPathDelete,
    onPathAdd
  }
}

export default usePathEditionCallbacks

import React from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

import { ArrowLeftIcon, PageHead } from '@fullfabric/alma-mater'

import getClassOf from 'apps/Reports/api/getClassOf'
import TitleAndSubtitle from 'apps/Reports/components/TitleWithSubtitle'

const Head = ({ classId }) => {
  const { t } = useTranslation()
  const { data: targetClass, isLoading } = useQuery(
    ['getClassOf', classId],
    () => getClassOf(classId)
  )

  return (
    <PageHead
      noBorder
      breadcrumbs={[
        {
          label: t('Back to product performance'),
          icon: ArrowLeftIcon,
          to: '/reports/product_performance'
        }
      ]}
      title={
        <TitleAndSubtitle
          isLoading={isLoading}
          subtitle={targetClass?.programme_name}
          title={targetClass?.name}
        />
      }
    />
  )
}

export default Head

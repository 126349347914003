.react-app ._header_ltme4_1 {
  width: 100%;
}
.react-app ._header_ltme4_1 a {
  font-weight: 300;
  color: var(--ff-color-grey-500);
}
.react-app ._header_ltme4_1 svg {
  position: relative;
  top: 2px;
}
.react-app ._header_ltme4_1 svg path {
  fill: black;
}

.react-app ._listContainer_ltme4_16 {
  width: 100%;
}
.react-app ._listContainer_ltme4_16 ._list_ltme4_16 > div:last-child {
  margin-bottom: var(--space-more);
}

.react-app ._feedbackMessage_ltme4_23 {
  position: fixed;
  bottom: 24px;
  left: 50%;
  transform: translateX(-50%);
}

.react-app ._loadingContainer_ltme4_30 {
  margin: var(--space-default);
  text-align: center;
}

.react-app ._emptyInbox_ltme4_35 {
  background: var(--ff-bg-base-lightest);
  text-align: center;
  padding: var(--space-huge) 0;
}
.react-app ._emptyInbox_ltme4_35 svg {
  display: inline-block;
}
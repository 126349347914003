.react-app ._formTitle_tm47v_1 {
  margin-bottom: var(--space-more);
  margin-left: 0;
  margin-right: 0;
}
.react-app ._formTitle_tm47v_1 ._title_tm47v_6,
.react-app ._formTitle_tm47v_1 ._subtitle_tm47v_7 {
  margin: 0;
  padding-left: 0;
  padding-right: 0;
}
.react-app ._formTitle_tm47v_1 ._title_tm47v_6 {
  margin-bottom: var(--space-tiny);
}
import React from 'react'
import { Trans } from 'react-i18next'

import { Text } from '@fullfabric/alma-mater'

import MenuIcon from './menu-icon'

import styles from './styles.module.scss'

const MenuButton = ({ onClick }) => {
  return (
    <div className={styles.container}>
      <button className={styles.clickableWrapper} onClick={onClick}>
        <div className={styles.iconWrapper}>
          <MenuIcon />
        </div>
        <div className={styles.labelWrapper}>
          <Text type='h5' fontColor='text-base-darker'>
            <Trans>Menu</Trans>
          </Text>
        </div>
      </button>
    </div>
  )
}

export default MenuButton

import getMainTextWidget from './get-main-text-widget'
import getPageLocalizedContent from './get-page-localized-content'

const getPageMainTextContent = (page, locale) => {
  const pageContent = getPageLocalizedContent(page, locale)

  if (!pageContent) {
    return null
  }

  const mainTextWidget = getMainTextWidget(pageContent.widgets)

  return mainTextWidget && mainTextWidget.content
}

export default getPageMainTextContent

import React from 'react'

import { useAppData } from 'shared/contexts/AppData'

export const UserPoliciesDataContext = React.createContext({})

export const useUpdatePrivacyPolicyAcceptance = () =>
  React.useContext(UserPoliciesDataContext)?.updatePrivacyPolicyAcceptance

export const useUpdateMarketingPolicyAcceptance = () =>
  React.useContext(UserPoliciesDataContext)?.updateMarketingPolicyAcceptance

export const UserPoliciesDataProvider = ({ children }) => {
  const value = useAppData()
  const [data, setData] = React.useState(value)

  const updatePrivacyPolicyAcceptance = React.useCallback(
    (acceptance) => setData({ ...data, privacy_policy_acceptance: acceptance }),
    [setData, data]
  )

  const updateMarketingPolicyAcceptance = React.useCallback(
    (acceptance) =>
      setData({ ...data, marketing_policy_acceptance: acceptance }),
    [setData, data]
  )

  return (
    <UserPoliciesDataContext.Provider
      value={{
        data,
        updatePrivacyPolicyAcceptance,
        updateMarketingPolicyAcceptance
      }}
    >
      {children}
    </UserPoliciesDataContext.Provider>
  )
}

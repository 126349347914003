import React from 'react'
import { Trans } from 'react-i18next'

import { Button } from '@fullfabric/alma-mater'

import CardContent from 'apps/StudyPlans/components/CardContent'
import CardInfo from 'apps/StudyPlans/components/CardInfo'
import { useSetSettingsSectionsBeingEdited } from 'apps/StudyPlans/contexts/SettingsSectionsBeingEdited'
import { useStudyPlan } from 'apps/StudyPlans/contexts/StudyPlanData'

const FinalGradeSectionDisplay = () => {
  const studyPlan = useStudyPlan()
  const setSectionBeingEdited = useSetSettingsSectionsBeingEdited()

  const onEditClick = React.useCallback(() => {
    setSectionBeingEdited((sections) => ({ ...sections, finalGrades: true }))
  }, [setSectionBeingEdited])

  return (
    <React.Fragment>
      <CardContent
        title={<Trans>Transcript grade display</Trans>}
        actions={
          <Button size='small' onClick={onEditClick}>
            <Trans>Edit</Trans>
          </Button>
        }
      >
        <CardInfo
          main={
            studyPlan.max_final_grade ? (
              <Trans>Grade percentage</Trans>
            ) : (
              <Trans>Grade average</Trans>
            )
          }
          secondary={
            studyPlan.max_final_grade ? (
              <>
                <Trans>Max transcript grade</Trans> {studyPlan.max_final_grade},{' '}
                {studyPlan.max_final_grade_decimals || 0}{' '}
                <Trans>decimal cases</Trans>
              </>
            ) : null
          }
        />
      </CardContent>
    </React.Fragment>
  )
}

export default FinalGradeSectionDisplay

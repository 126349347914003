import React from 'react'
import { Trans } from 'react-i18next'

import PropTypes from 'prop-types'

import AppLayout from 'apps/Authentication/components/AppLayout'
import AuthContentLayout from 'apps/Authentication/components/AuthContentLayout'
import SignupForm from 'apps/Authentication/components/SignupForm'

const SignupPage = ({ title, subtitle }) => (
  <AppLayout>
    <AuthContentLayout className='signup-page'>
      <AuthContentLayout.Body>
        <SignupForm title={title} subtitle={subtitle} />
      </AuthContentLayout.Body>

      <AuthContentLayout.Footer
        question={<Trans>Already have an account?</Trans>}
        linkTo='/login'
        linkText={<Trans>Sign in</Trans>}
      />
    </AuthContentLayout>
  </AppLayout>
)

SignupPage.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
}

export default SignupPage

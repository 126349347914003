import React from 'react'
import { Trans } from 'react-i18next'

import PropTypes from 'prop-types'

import { Button } from '@fullfabric/alma-mater'

import AppLayout from 'apps/Authentication/components/AppLayout'
import AuthContentLayout from 'apps/Authentication/components/AuthContentLayout'
import FormTitle from 'apps/Authentication/components/FormTitle'
import { LOGIN_ROUTE } from 'apps/Authentication/constants/routes'

const ResetEmailSentPage = ({ history }) => (
  <AppLayout>
    <AuthContentLayout>
      <AuthContentLayout.Body>
        <FormTitle
          title={<Trans>Thank you.</Trans>}
          subtitle={
            <Trans>
              Please check your email for instructions on resetting your
              password
            </Trans>
          }
        />
        <Button primary onClick={() => history.push(LOGIN_ROUTE)}>
          <Trans>Return to log in page</Trans>
        </Button>
      </AuthContentLayout.Body>
    </AuthContentLayout>
  </AppLayout>
)

ResetEmailSentPage.propTypes = {
  history: PropTypes.object
}

export default ResetEmailSentPage

import React from 'react'
import { Trans } from 'react-i18next'

import { Text } from '@fullfabric/alma-mater'

import isConditionalOffer from 'apps/ContentPages/services/offers/is-conditional-offer'
import isOfferInFinalState from 'apps/ContentPages/services/offers/is-offer-in-final-state'
import FinalStateLabel from './OfferInfo/FinalStateLabel'

const OfferInfo = ({ data }) => {
  const isConditional = isConditionalOffer(data)
  const isInFinalState = isOfferInFinalState(data)
  const isAccepted = isInFinalState && data.state === 'accepted'
  const offerTypeLabel = isConditional ? (
    <Trans>Conditional offer</Trans>
  ) : (
    <Trans>Unconditional offer</Trans>
  )

  return (
    <>
      <Text type='h4' fontColor='text-base-darkest' marginBottom='tiny'>
        {data.name}
      </Text>
      {isInFinalState ? (
        <FinalStateLabel accepted={isAccepted} />
      ) : (
        <Text type='h6' fontColor='text-info'>
          {offerTypeLabel}
        </Text>
      )}
    </>
  )
}

export default OfferInfo

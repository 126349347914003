import { utils } from '@fullfabric/public-api'

export default async function createStaff(attrs) {
  const response = await fetch('/api/staff/', {
    credentials: 'same-origin',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(attrs)
  })

  return await utils.checkResponse(response)
}

import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ActionList, MoreIcon } from '@fullfabric/alma-mater'

import DeleteModal from './DeleteModal'
import DuplicateModal from './DuplicateModal'

function MoreOptionsCell({ value, row, data }) {
  const { t } = useTranslation()
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [isDuplicateModalOpen, setIsDuplicateModalOpen] = useState(false)

  const onSelectDelete = useCallback((evt) => {
    evt.stopPropagation()
    setIsDeleteModalOpen(true)
  }, [])

  const onSelectDuplicate = useCallback((evt) => {
    evt.stopPropagation()
    setIsDuplicateModalOpen(true)
  }, [])

  const targetId = value
  const rowData = data[row.index]
  const isTemplateInUse = rowData?.is_used
  const templateName = rowData?.name

  return (
    <>
      <ActionList
        iconButtonProps={{ plain: true }}
        iconButton={<MoreIcon />}
        alignEnd
      >
        <ActionList.Option onClick={onSelectDuplicate}>
          <span data-testid='duplicate-template-menu-option'>
            {t('Duplicate template')}
          </span>
        </ActionList.Option>

        <ActionList.Option
          disabled={isTemplateInUse}
          negative={!isTemplateInUse}
          onClick={onSelectDelete}
        >
          <span data-testid='delete-template-menu-option'>
            {isTemplateInUse
              ? t("Can't delete template (in use)")
              : t('Delete template')}
          </span>
        </ActionList.Option>
      </ActionList>

      {isDeleteModalOpen ? (
        <DeleteModal
          isOpen={isDeleteModalOpen}
          targetId={targetId}
          handleClose={() => {
            setIsDeleteModalOpen(false)
          }}
        />
      ) : null}

      {isDuplicateModalOpen ? (
        <DuplicateModal
          isOpen={isDuplicateModalOpen}
          targetId={targetId}
          initialName={templateName}
          handleClose={() => {
            setIsDuplicateModalOpen(false)
          }}
        />
      ) : null}
    </>
  )
}

export default MoreOptionsCell

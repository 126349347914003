import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { castArray, isEmpty } from 'lodash'

import { RadioInput, Text } from '@fullfabric/alma-mater'

import { useMergeData } from 'apps/MergeProfiles/contexts/MergeData'
import {
  buildCascadingFieldLabels,
  mapCascadingValues
} from 'apps/MergeProfiles/utils/cascadingDropDown'
import getFieldValue from 'apps/MergeProfiles/utils/getFieldValue'
import getLocaledLabel from 'apps/MergeProfiles/utils/getLocaledLabel'
import { BaseCol, BaseRow } from './Base'

import classNames from 'classnames'
import styles from '../styles.module.scss'

const noValue = (
  <Text marginLeft='more'>
    <Trans>No value</Trans>
  </Text>
)

const isValueNotEmpty = (value) =>
  Array.isArray(value) ? value.filter((x) => x).length > 0 : !isEmpty(value)

const MultiValue1DFieldRow = ({
  field,
  error,
  onSelectDuplicate,
  onSelectMain
}) => {
  const { i18n, t } = useTranslation()

  const {
    mergeData: { sourceProfile, targetProfile, profileInfoFrom }
  } = useMergeData()

  const duplicateProfileFieldValue = castArray(
    getFieldValue(field, sourceProfile) || []
  )
  const mainProfileFieldValue = castArray(
    getFieldValue(field, targetProfile) || []
  )

  const localizedFieldName = getLocaledLabel(field.locals, i18n.language)

  const cascadingLabels =
    field.type === 'Schemable::Fields::Types::CascadingDropDown' &&
    buildCascadingFieldLabels(field, i18n)

  return (
    <BaseRow
      className={classNames(styles.row, styles.field, styles.multi)}
      role='row'
      aria-label={`${localizedFieldName} profile field`}
    >
      <BaseCol>
        <Text type='h5' fontColor='text-base-darkest'>
          {localizedFieldName}
        </Text>
        {error && (
          <Text type='f6' fontColor='text-alert' marginTop='tiny'>
            <Trans>Field is required</Trans>
          </Text>
        )}
      </BaseCol>

      <BaseCol>
        <RadioInput
          id={`duplicateProfile_${field.name}`}
          aria-label={`duplicate profile ${field.name}`}
          name={field.name}
          value='duplicate'
          checked={profileInfoFrom[field.name] === 'duplicate'}
          label={t('Select this option')}
          onChange={onSelectDuplicate}
        />

        <div className={styles.valuesContainer}>
          {isValueNotEmpty(duplicateProfileFieldValue)
            ? duplicateProfileFieldValue.map((value, index) => (
                <FieldValue
                  key={`${index}:${value}`}
                  cascadingLabels={cascadingLabels}
                  value={value}
                />
              ))
            : noValue}
        </div>
      </BaseCol>

      <BaseCol>
        <RadioInput
          id={`mainProfile_${field.name}`}
          aria-label={`main profile ${field.name}`}
          name={field.name}
          value='main'
          checked={profileInfoFrom[field.name] === 'main'}
          label={t('Select this option')}
          onChange={onSelectMain}
        />

        <div className={styles.valuesContainer}>
          {isValueNotEmpty(mainProfileFieldValue)
            ? mainProfileFieldValue.map((value, index) => (
                <FieldValue
                  key={`${index}:${value}`}
                  cascadingLabels={cascadingLabels}
                  value={value}
                />
              ))
            : noValue}
        </div>
      </BaseCol>
    </BaseRow>
  )
}

export default MultiValue1DFieldRow

function FieldValue({ cascadingLabels, value }) {
  if (cascadingLabels) {
    return mapCascadingValues(
      value,
      cascadingLabels,
      (subFieldLabel, value) => (
        <div className={styles.subFieldValueContainer} key={subFieldLabel}>
          <Text className={styles.subFieldLabelText} marginBottom='tiny'>
            {subFieldLabel}
          </Text>

          <Text className={styles.valueText} fontColor='text-base-darkest'>
            {value}
          </Text>
        </div>
      )
    )
  }

  return (
    <Text className={styles.valueText} fontColor='text-base-darkest'>
      {value || '-'}
    </Text>
  )
}

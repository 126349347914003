import { utils } from '@fullfabric/public-api'

const createElectiveGroup = async (studyPlanId, pathId) => {
  const response = await fetch(
    `/affairs/api/study_plan/${studyPlanId}/paths/${pathId}/add_elective_group`,
    {
      credentials: 'same-origin',
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({})
    }
  )

  return await utils.checkResponse(response)
}

export default createElectiveGroup

import React from 'react'

import { Loading } from '@fullfabric/alma-mater'

import styles from '../styles.module.scss'

const LoadingRow = () => {
  return (
    <div className={styles.loading}>
      <Loading />
    </div>
  )
}

export default LoadingRow

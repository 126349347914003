import React from 'react'
import { Trans } from 'react-i18next'

import { Text } from '@fullfabric/alma-mater'
import { useCurrentUser } from '@fullfabric/authorization-officer'

import { relativeTimestamp } from 'apps/Inbox/utils/cardUtils'
import InboxCardProfileTooltip from '../InboxCardProfileTooltip'
import InboxCardSubject from '../InboxCardSubject'

import styles from './styles.module.scss'

const InboxEventCardDescriptor = ({
  status,
  statusUpdatedBy,
  actor,
  subTypeEntity,
  followOrUnfollowProfile,
  updatedAt
}) => {
  const currentUser = useCurrentUser()

  if (status === 'archived') {
    return (
      <div className={styles.cardDescriptor}>
        <InboxCardProfileTooltip
          currentUser={currentUser}
          actor={statusUpdatedBy}
          followOrUnfollowProfile={followOrUnfollowProfile}
        ></InboxCardProfileTooltip>{' '}
        <Text
          type='f5'
          tag='span'
          fontColor='text-base-darkest'
          marginTop='small'
        >
          <Trans>archived this card.</Trans>
        </Text>
        <Text tag='span' marginLeft='tiny' fontColor='text-base'>
          {relativeTimestamp(updatedAt)}
        </Text>
      </div>
    )
  }

  const { response } = subTypeEntity

  // if (subTypeEntity.type.includes('Events::Response')) {
  //   { response } = subTypeEntity
  // }

  return (
    <div className={styles.cardDescriptor}>
      <InboxCardSubject
        currentUser={currentUser}
        actor={actor}
        followOrUnfollowProfile={followOrUnfollowProfile}
      ></InboxCardSubject>

      <Text
        type='f5'
        tag='span'
        fontColor='text-base-darkest'
        marginTop='small'
      >
        <Trans>responded {response} to this event.</Trans>{' '}
      </Text>

      <Text tag='span' marginLeft='tiny' fontColor='text-base'>
        {relativeTimestamp(updatedAt)}
      </Text>
    </div>
  )
}

export default InboxEventCardDescriptor

import React from 'react'

import { Text, useScreenClass } from '@fullfabric/alma-mater'

import Linkable from '../../linkable'
import shouldUseMobileNavigation from '../../should-use-mobile-navigation'

import classNames from 'classnames'
import styles from './styles.module.scss'

const PrimaryLink = ({ icon, label, ...linkableProps }) => {
  const screenClass = useScreenClass()
  const isMobileNavigation = shouldUseMobileNavigation(screenClass)
  const isTabletNav = screenClass === 'md'

  return (
    <Linkable {...linkableProps}>
      <div
        className={classNames(
          styles.container,
          isTabletNav && styles.tabletNav
        )}
      >
        <span
          className={classNames(
            styles.iconWrapper,
            isMobileNavigation && styles.mobileNav
          )}
        >
          {icon}
        </span>
        <Text
          type={isMobileNavigation ? 'f4' : 'h4'}
          fontColor='text-base-darkest'
          className={styles.labelWrapper}
        >
          {label}
        </Text>
      </div>
    </Linkable>
  )
}

export default PrimaryLink

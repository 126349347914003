import React from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router'

import { Loading } from '@fullfabric/alma-mater'

import FeedbackMessageProvider from 'apps/ContentPages/components/feeback-message-provider'
import api from '../api'
import ExternalPaymentArea from '../components/external-payment-area'
import PaymentsDrawer from '../components/payments-drawer'
import { PaymentsDrawerStateProvider } from '../contexts/payments-drawer'

export default function ExternalPayerPaymentPage() {
  const { externalPayerRequestId } = useParams()

  const { data: externalPayerRequest, isLoading } = useQuery(
    ['get-external-payer-request', externalPayerRequestId],
    () => api.getExternalPayerRequest(externalPayerRequestId)
  )

  if (isLoading) {
    return <Loading />
  }

  return (
    <FeedbackMessageProvider>
      <PaymentsDrawerStateProvider>
        <PaymentsDrawer />
        <ExternalPaymentArea externalPayerRequest={externalPayerRequest} />
      </PaymentsDrawerStateProvider>
    </FeedbackMessageProvider>
  )
}

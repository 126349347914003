import { utils } from '@fullfabric/public-api'

const getCampus = async (campusId) => {
  const response = await fetch('/api/campuses', { credentials: 'same-origin' })
  const campuses = await utils.checkResponse(response)

  return (campuses || []).filter((c) => c.id === campusId)[0]
}

export default getCampus

import { connect } from 'react-redux'

import actionTypes from 'apps/Courses/actions/types'
import updateCourse from 'apps/Courses/actions/updateCourse'
import CourseEditFormAcademicDetails from 'apps/Courses/components/CourseEditFormAcademicDetails'

const mapStateToProps = ({
  editCourse: {
    academicDetailsSection: { data, inProgress, errors }
  }
}) => ({
  data,
  inProgress,
  errors
})

const mapDispatchToProps = (dispatch) => ({
  onCancelClick: () =>
    dispatch({ type: actionTypes.cancelEditAcademicDetailsSectionData }),
  onChange: (field, value) =>
    dispatch({
      type: actionTypes.editCourseAcademicDetailsSectionDataChanged,
      payload: { [field]: value }
    }),
  onSaveClick: () =>
    dispatch(updateCourse(actionTypes.updateCourseAcademicDetailsSection))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CourseEditFormAcademicDetails)

.react-app ._loadMoreRow_w5s0i_1 {
  background: var(--ff-bg-base-lighter);
  cursor: pointer;
}
.react-app ._loadMoreRow_w5s0i_1[role=row]:hover {
  background: var(--ff-bg-base-light) !important;
}
.react-app ._loadMoreRow_w5s0i_1[role=row]._loadingNext_w5s0i_8, .react-app ._loadMoreRow_w5s0i_1[role=row]._loadingNext_w5s0i_8:hover {
  background: var(--ff-bg-base-lightest) !important;
}
.react-app ._loadMoreRow_w5s0i_1._loadingNext_w5s0i_8 {
  border-bottom: none;
}
.react-app ._loadMoreRow_w5s0i_1._loadingNext_w5s0i_8 ._loadMoreCell_w5s0i_14 {
  padding: var(--space-less) var(--space-default);
}
.react-app ._loadMoreRow_w5s0i_1 ._loadMoreCell_w5s0i_14 {
  padding: var(--space-small) var(--space-default);
}
.react-app ._loadMoreRow_w5s0i_1 ._loadMoreCell_w5s0i_14._virtualized_w5s0i_20 {
  width: 100vw;
}
import { useLocation } from 'react-router-dom'

import useObserver from './use-observer'

/**
 * Returns two functions to subscribe and unsubscribe from changes in the pathname.
 * Those methods get a function as an argument, and will register it as callback
 * functions for when the pathname changes, providing the pathname as an argument
 * to the callbacks.
 * To unsubscribe, one should provide the same function reference to the
 * unsubscribe method.
 * @returns {Object} object with subscribe and unsubscribe methods
 */
const usePathOserver = () => {
  const { pathname: currentPath } = useLocation()
  const { subscribe, unsubscribe } = useObserver(currentPath)

  return { subscribe, unsubscribe }
}

export default usePathOserver

import React, { useCallback, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'

import { Button, Modal, ModalButtons, ModalTitle } from '@fullfabric/alma-mater'

import submitContract from 'apps/Billing/api/submitContract'
import ContractForm from 'apps/Billing/components/ContractForm'
import { CONTRACTS_TABLE_QUERY_KEY_PART } from 'apps/Billing/constants/queryParts'

const CreateContractModal = ({ isOpen, handleClose, onSubmitted }) => {
  const { t } = useTranslation()
  const formMethods = useForm()
  const { handleSubmit, setError } = formMethods

  const [hasCaughtError, setHasCaughtError] = useState(false)
  const queryClient = useQueryClient()
  const submitContractMutation = useMutation(
    ({ formValues }) => {
      setHasCaughtError(false)
      return submitContract(formValues)
    },
    {
      onSuccess: () => {
        // TODO: sinchronously insert new record into query with setQueryData
        //    must account for table sort
        queryClient.invalidateQueries(CONTRACTS_TABLE_QUERY_KEY_PART)

        onSubmitted()
        handleClose()
      },
      onError: (error) => {
        if (Object.keys(error?.data['billing/contract'] || {}).length > 0) {
          setHasCaughtError(true)

          Object.keys(error.data['billing/contract']).forEach((key) => {
            setError(key, { message: error.data['billing/contract'][key] })
          })
        }
      }
    }
  )

  const onSubmit = useCallback(
    async (formValues) => {
      submitContractMutation.mutate({ formValues })
    },
    [submitContractMutation]
  )

  return (
    <Modal
      role='dialog'
      aria-modal='true'
      aria-labelledby='create-contract-modal-title'
      size='medium'
      isOpen={isOpen}
      header={
        <ModalTitle
          title={
            <span id='create-contract-modal-title'>{t('New contract')}</span>
          }
          onClose={handleClose}
        />
      }
      footer={
        <ModalButtons>
          <Button
            disabled={submitContractMutation.isLoading}
            onClick={handleClose}
          >
            {t('Cancel')}
          </Button>
          <Button
            primary
            type='submit'
            form='create-contract-modal-form'
            loading={submitContractMutation.isLoading}
            onClick={handleSubmit(onSubmit)}
          >
            {t('Create contract')}
          </Button>
        </ModalButtons>
      }
    >
      <FormProvider {...formMethods}>
        <ContractForm
          withError={submitContractMutation.isError && !hasCaughtError}
        />
      </FormProvider>
    </Modal>
  )
}

export default CreateContractModal

import { utils } from '@fullfabric/public-api'

const getProducts = async () => {
  const response = await fetch('/api/products', {
    credentials: 'same-origin'
  })

  return await utils.checkResponse(response)
}

export default getProducts

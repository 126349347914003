import React from 'react'
import { Trans } from 'react-i18next'

import { BoxShadow, Loading, Row, Text } from '@fullfabric/alma-mater'
import { IfHasModule } from '@fullfabric/authorization-officer'

import ApplicsSection from './ApplicsSection'
import CreatedAtInfo from './CreatedAtInfo'
import DetailSection from './DetailSection'
import JourneysSection from './JourneysSection'
import LastSeenInfo from './LastSeenInfo'
import MarketingAcceptanceInfo from './MarketingAcceptanceInfo'
import SubscriptionInfo from './SubscriptionInfo'

import styles from './styles.module.scss'

const ProfileDetailsCard = ({ profile, isLoading }) => {
  if (isLoading)
    return (
      <BoxShadow rounded type='2' bgColor='white'>
        <Row nogutter justify='center' className={styles.loadingRow}>
          <Loading />
        </Row>
      </BoxShadow>
    )
  if (!profile) return null

  return (
    <BoxShadow
      role='document'
      aria-label={`profile details for ${profile.full_name}`}
      rounded
      type='2'
      bgColor='white'
    >
      <DetailSection aria-label='profile name'>
        <Text fontColor='text-base-darkest' type='f3'>
          {profile.full_name}
        </Text>
        <Text type='f4'>{profile.email}</Text>
      </DetailSection>

      <DetailSection aria-label='summary'>
        <Text type='h6' marginBottom='less'>
          <Trans>Summary</Trans>
        </Text>

        <MarketingAcceptanceInfo profile={profile} />

        <SubscriptionInfo profile={profile} />

        <CreatedAtInfo profile={profile} />

        <LastSeenInfo profile={profile} />
      </DetailSection>

      <JourneysSection profile={profile} />

      <IfHasModule moduleName='applics'>
        <ApplicsSection profile={profile} />
      </IfHasModule>
    </BoxShadow>
  )
}

export default ProfileDetailsCard

{
  "No payment plans found.": "No payment plans found.",
  "Profile fields": "Profile fields",
  "Journey fields": "Journey fields",
  "Product": "Product",
  "All products": "All products",
  "Publish status": "Publish status",
  "Published": "Published",
  "Not published": "Not published",
  "Fee status": "Fee status",
  "No fees due": "No fees due",
  "Fee due or overdue": "Fee due or overdue",
  "All fees paid": "All fees paid",
  "Payment plan": "Payment plan",
  "Name": "Name",
  "Email": "Email",
  "Payment plan name": "Payment plan name",
  "Not due": "Not due",
  "Overdue": "Overdue",
  "Paid": "Paid",
  "Overdue outstanding": "Overdue outstanding",
  "Outstanding": "Outstanding",
  "Subtotal": "Subtotal",
  "Total": "Total",
  "Discount total": "Discount total",
  "No transactions found.": "No transactions found.",
  "State": "State",
  "Pending": "Pending",
  "Succeeded": "Succeeded",
  "Failed": "Failed",
  "Created": "Created",
  "Initiated": "Initiated",
  "Cancelled": "Cancelled",
  "Guaranteed": "Guaranteed",
  "Description": "Description",
  "Date": "Date",
  "Amount": "Amount",
  "Gateway": "Gateway",
  "Reference": "Reference",
  "Payment plans": "Payment plans",
  "Export": "Export",
  "Transactions": "Transactions",
  "Lifecycle": "Lifecycle",
  "Due / Overdue": "Due / Overdue",
  "Transaction": "Transaction",
  "Update payment plan statuses": "Update payment plan statuses",
  "Cancel": "Cancel",
  "Update": "Update",
  "This action will affect the payment plans displayed on the list": "This action will affect the payment plans displayed on the list",
  "Status action": "Status action",
  "Publish payment plans": "Publish payment plans",
  "Unpublish payment plans": "Unpublish payment plans",
  "Can't be blank": "Can't be blank",
  "{{affectedPaymentPlans}} of the {{totalPaymentPlans}} payment plans will be ": "{{affectedPaymentPlans}} of the {{totalPaymentPlans}} payment plans will be ",
  "Update status": "status",
  "Payment plans published": "Payment plans published",
  "Payment plans unpublished": "Payment plans unpublished",
  "The export is ready": "The export is ready",
  "Export failed": "Export failed",
  "Export in progress": "Export in progress",
  "This modal will update with the download link once it's ready.": "This modal will update with the download link once it's ready.",
  "If you don't wish to wait, you may close it and we'll send the link via email.": "If you don't wish to wait, you may close it and we'll send the link via email.",
  "exportCount": "Found {{exportCount}} record to export.",
  "exportCount_plural": "Found {{exportCount}} records to export.",
  "Counting exportable records...": "Counting exportable records...",
  "Please try again or contact support if the problem persists; if so, please mention the ID <1>{{exportId}}</1> in your message.": "Please try again or contact support if the problem persists; if so, please mention the ID <1>{{exportId}}</1> in your message.",
  "Error creating export": "Error creating export",
  "Click here to download it": "Click here to download it",
  "Close": "Close"
}

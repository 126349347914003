import React from 'react'

const MergeDataContext = React.createContext({})

export const useMergeData = () => React.useContext(MergeDataContext)

export const MERGE_STEPS = {
  selectDuplicateProfile: 1,
  info: 2,
  applications: 3,
  journeys: 4,
  summary: 5
}

export default MergeDataContext

import React from 'react'

import PropTypes from 'prop-types'

import { Link, Text } from '@fullfabric/alma-mater'

import classNames from 'classnames'
import styles from './styles.module.scss'

const Footer = ({
  question,
  linkTo,
  linkText,
  disableLink = false,
  className
}) => {
  const linkParams = {}

  if (typeof linkTo === 'string') {
    linkParams.to = linkTo
  } else {
    linkParams.onClick = linkTo
  }
  return (
    <div className={classNames(styles.authFooter, className)}>
      <Text tag='p' marginRight='tiny'>
        {question}
      </Text>
      <Text tag='p'>
        <Link disabled={disableLink} className={styles.link} {...linkParams}>
          {linkText}
        </Link>
      </Text>
    </div>
  )
}

Footer.propTypes = {
  question: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  linkTo: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  linkText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired
}

export default Footer

import React from 'react'
import { useTranslation } from 'react-i18next'

import { COURSE_SIDE_COLOR, formatAsPercentage } from '..'

const CourseCapacityLabel = ({
  forCourseProduct,
  totalCapacity,
  capacity,
  capacityRatio,
  position
}) => {
  const { t } = useTranslation()
  const mainLabelPart =
    totalCapacity > 0
      ? t(`Course:{{capacityPercentage}}%`, {
          capacityPercentage: formatAsPercentage(capacityRatio)
        })
      : t(`Course:0`)
  const secondaryLabelPart = totalCapacity > 0 ? `(${capacity})` : ''

  return (
    <text
      textAnchor={'end'}
      x={position}
      y='14'
      data-testid='course-capacity-value'
    >
      {forCourseProduct ? (
        <>
          <tspan fill={COURSE_SIDE_COLOR} fontWeight='600'>
            {mainLabelPart}
          </tspan>
          {secondaryLabelPart ? (
            <tspan fill={COURSE_SIDE_COLOR} fontWeight='400'>
              {secondaryLabelPart}
            </tspan>
          ) : null}
        </>
      ) : (
        <tspan fill='var(--ff-color-text-base)' fontWeight='600'>
          --
        </tspan>
      )}
    </text>
  )
}

export default CourseCapacityLabel

import React from 'react'
import { Route, Switch } from 'react-router'

import ScanEventQRCodePage from 'apps/Events/pages/ScanEventQRCodePage'

const EventsAppRoutes = () => {
  return (
    <Switch>
      <Route path='/events/:id/scanqr' component={ScanEventQRCodePage} />
    </Switch>
  )
}

export default EventsAppRoutes

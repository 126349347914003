import { useInfiniteQuery } from 'react-query'

import getCards from 'apps/Inbox/api/getCards'
import { useCardFilters } from 'apps/Inbox/contexts/CardFilters'

const CARDS_PER_PAGE = 15

export default function useInfiniteCardsQuery() {
  const { cardType, cardStatus } = useCardFilters()

  const { data, isFetching, isFetchingNextPage, hasNextPage, fetchNextPage } =
    useInfiniteQuery(
      ['cards', cardStatus, cardType],
      async ({ pageParam = 0 }) => {
        const requestParams = {
          offset: pageParam,
          per_page: CARDS_PER_PAGE
        }

        if (cardType) requestParams.card_type = cardType
        if (cardStatus) requestParams.card_status = cardStatus

        return await getCards(requestParams)
      },
      {
        getNextPageParam: (lastPage) => {
          const { total, offset, results } = lastPage?.headers || {}
          if (offset + results < total) return offset + results
        },
        refetchOnWindowFocus: false
      }
    )

  return {
    data,
    isFetching,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage
  }
}

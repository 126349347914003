import { utils } from '@fullfabric/public-api'

export default async function activateStaff(staffId) {
  const response = await fetch(`/api/staff/${staffId}/activate`, {
    method: 'PATCH',
    credentials: 'same-origin'
  })

  return await utils.checkResponse(response)
}

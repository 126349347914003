import qs from 'qs'

import { utils } from '@fullfabric/public-api'

export default async function getProductConversion({ contextId, states }) {
  if (!contextId) return {}

  const queryParams = qs.stringify(
    {
      context_id: contextId,
      states
    },
    { arrayFormat: 'brackets' }
  )

  const response = await fetch(
    `/api/reports/product_conversion?${queryParams}`,
    { credentials: 'same-origin' }
  )
  return await utils.checkResponse(response)
}

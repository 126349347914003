import React from 'react'
import { useTranslation } from 'react-i18next'

import { Button, Dropdown, PointerDownIcon } from '@fullfabric/alma-mater'

import useViewOptions from '../../hooks/useViewOptions'
import { ReactComponent as DownloadIcon } from '../../icons/download.svg'
import MultiUploadSelector from './MultiUploadSelector'

import styles from './styles.module.scss'

const ViewControl = ({
  selectedView,
  setSelectedView,
  selectedUploadIndex,
  setSelectedUploadIndex
}) => {
  const viewOptions = useViewOptions()
  const { t } = useTranslation()

  const selectedOption = viewOptions[selectedView]

  return (
    <div className={styles.container}>
      <MultiUploadSelector
        viewOption={selectedOption}
        selectedUploadIndex={selectedUploadIndex}
        setSelectedUploadIndex={setSelectedUploadIndex}
      />
      <div className={styles.controlsWrapper}>
        <Dropdown>
          <Dropdown.Toggle>
            <Button aria-label={t('Select file uploads')}>
              {selectedOption.label}
              <Dropdown.Toggle.Icon>
                <PointerDownIcon color='text-base-darkest' />
              </Dropdown.Toggle.Icon>
            </Button>
          </Dropdown.Toggle>
          <Dropdown.Menu aria-label={t('File uploads menu')}>
            {Object.keys(viewOptions).map((optionKey) => {
              const option = viewOptions[optionKey]

              return (
                <Dropdown.Menu.Option
                  key={optionKey}
                  aria-label={optionKey}
                  onClick={() => setSelectedView(optionKey)}
                >
                  {option.label}
                </Dropdown.Menu.Option>
              )
            })}
          </Dropdown.Menu>
        </Dropdown>

        {selectedOption?.uploads ? (
          <a
            href={`/${selectedOption?.uploads[selectedUploadIndex]?.path}`}
            download
            className={styles.downloadLink}
          >
            <Button
              className='marginLeft-tiny paddingLeftRight-less'
              aria-label={t('download file link')}
            >
              <DownloadIcon />
            </Button>
          </a>
        ) : null}
      </div>
    </div>
  )
}

export default ViewControl

import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

import {
  ArrowLeftIcon,
  Breadcrumbs,
  Container,
  Loading,
  Row,
  Text
} from '@fullfabric/alma-mater'
import { useCurrentUser } from '@fullfabric/authorization-officer'

import { useLocale } from 'shared/contexts/Locale'

import api from 'apps/ContentPages/api'
import useIsMobileLayout from 'apps/ContentPages/components/payments-page/use-is-payments-mobile-layout'
import getLocalizedText from 'apps/ContentPages/utils/get-localized-text'
import getRequiredToBePaidFees from '../services/payments/get-required-to-be-paid-fees'
import ApplicPaymentCard from './applic-payment-area/applic-payment-card'
import CustomTextContent from './custom-text-content'
import LandingPageLogo from './landing-page-logo'
import LocaleSwitcherDropdown from './locale-switcher-dropdown'

import styles from './applic-payment-area/styles.module.scss'

const ApplicPaymentArea = ({ template, applic }) => {
  const isMobileLayout = useIsMobileLayout()
  const { locale } = useLocale()
  const { t } = useTranslation()
  const user = useCurrentUser()

  const query = applic.custom_payment_plan_id
    ? { id: applic.custom_payment_plan_id }
    : { created_by_id: applic.id }

  const { data: paymentPlan } = useQuery(['get-payment-plan', user.id], () =>
    api.getPaymentPlan(user.id, query)
  )

  const localizedPaymentsTabName = getLocalizedText(
    template?.payments_custom_name_locales,
    locale,
    'text',
    'en-GB'
  )
  const localizedPaymentsTabInstructionsHtml = getLocalizedText(
    template?.payments_instructions_locales,
    locale,
    'html',
    'en-GB'
  )

  const applicType = template.type

  const onRestartApplicClick = async () => {
    try {
      await api.restartApplication({
        applicId: applic.id
      })

      window.location.assign(`/applications/${applic.id}`)
    } catch (error) {
      console.error('error', error)
    }
  }

  const payable = paymentPlan ?? null
  const requiredToBePaidFees = payable && getRequiredToBePaidFees(payable)
  const anyFeePaidOrPartiallyPaid =
    payable && requiredToBePaidFees.some((fee) => fee.paid_amount > 0)

  return (
    <Container fluid className={styles.container}>
      <Row align='start' justify='between' nogutter className={styles.header}>
        <LandingPageLogo />
        <LocaleSwitcherDropdown />
      </Row>
      {!anyFeePaidOrPartiallyPaid && (
        <Row align='start' nogutter>
          <Breadcrumbs
            breadcrumbs={[
              {
                label:
                  applicType === 'application'
                    ? t('Edit application')
                    : t('Edit registration'),
                icon: ArrowLeftIcon,
                onClick: onRestartApplicClick
              }
            ]}
          />
        </Row>
      )}
      <Row align='start' nogutter className={styles.customTabName}>
        <Text type={isMobileLayout ? 'h3' : 'h2'} fontColor='text-base-darkest'>
          {localizedPaymentsTabName || <Trans>Payment</Trans>}
        </Text>
      </Row>
      <Row align='start' nogutter>
        {localizedPaymentsTabInstructionsHtml ? (
          <CustomTextContent
            htmlContent={localizedPaymentsTabInstructionsHtml}
            type={isMobileLayout ? 'f4' : 'f5'}
            fontColor='text-base-darker'
          />
        ) : (
          <Trans>In order to submit you are required to pay a fee.</Trans>
        )}
      </Row>
      <Row
        justify='center'
        align='center'
        nogutter
        className={styles.applicPaymentCard}
      >
        {payable ? (
          <ApplicPaymentCard
            template={template}
            applic={applic}
            payable={payable}
          />
        ) : (
          <Loading />
        )}
      </Row>
    </Container>
  )
}

export default ApplicPaymentArea

import qs from 'qs'

import { utils } from '@fullfabric/public-api'

const getStudyPlans = async ({ targetCourseId }) => {
  const queryParams = qs.stringify(
    {
      with_course: targetCourseId
    },
    { arrayFormat: 'brackets' }
  )
  const queryParamsString = queryParams ? `?${queryParams}` : ''

  const response = await fetch(`/affairs/api/study_plans${queryParamsString}`, {
    credentials: 'same-origin'
  })

  return await utils.checkResponse(response)
}

export default getStudyPlans

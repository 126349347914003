import { utils } from '@fullfabric/public-api'

const getSubjects = async () => {
  const response = await fetch('/affairs/api/courses', {
    credentials: 'same-origin'
  })

  return await utils.checkResponse(response)
}

export default getSubjects

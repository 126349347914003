import normalizeLocale from 'shared/utils/normalizeLocale'

import { getCookieLocale } from './cookie-locale'

const getBrowserLocale = () => navigator.language

const getLocale = (normalizedLocale, locales) =>
  locales.find((sl) => normalizeLocale(sl) === normalizedLocale) // TODO: what to do if we have two variations of the same locale? (e.g: en-GB and en-US)

const getInitialLocale = (user, locales) => {
  const normalizedLocale = normalizeLocale(
    (user && user.locale) || getCookieLocale() || getBrowserLocale()
  )

  return getLocale(normalizedLocale, locales) || locales[0]
}

export default getInitialLocale

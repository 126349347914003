.react-app ._containerWrapper_1e9gp_1 {
  width: 100%;
}

.react-app ._container_1e9gp_1 {
  padding: var(--space-more);
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  background-color: var(--ff-bg-primary-lightest);
}

.react-app ._container_1e9gp_1._verticalFlow_1e9gp_13 {
  flex-direction: column;
  justify-self: flex-start;
}

.react-app ._backLink_1e9gp_18 {
  margin-bottom: var(--space-tiny);
}
.react-app ._backLink_1e9gp_18,
.react-app ._backLink_1e9gp_18 * {
  color: var(--ff-color-text-primary);
}
.react-app ._backLink_1e9gp_18 svg,
.react-app ._backLink_1e9gp_18 svg path {
  fill: var(--ff-color-text-primary);
}
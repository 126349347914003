import React from 'react'

import { YAxis } from 'recharts'

import { DEFAULT_SPACE_TO_CONTENT } from './index'
import YAxisTick from './YAxisTick'

const renderCustomYAxis = ({ ...configuration } = {}) => (
  <YAxis
    axisLine={false}
    tickLine={false}
    tickCount={6}
    allowDecimals={false}
    tick={<YAxisTick />}
    width={DEFAULT_SPACE_TO_CONTENT}
    {...configuration}
  />
)

export default renderCustomYAxis

import React from 'react'
import { Redirect, Route, Switch } from 'react-router'

import TemplateNameWizardPage from 'apps/Applics/components/TemplateNameWizardPage'
import TemplateTabsWizardPage from 'apps/Applics/components/TemplateTabsWizardPage'
import { WizardDataProvider } from 'apps/Applics/contexts/WizardDataContext'
import {
  TEMPLATE_NAME_STEP,
  TEMPLATE_TABS_STEP,
  WizardNavigationProvider
} from 'apps/Applics/contexts/WizardNavigationContext'

const TemplateWizardPage = () => {
  return (
    <WizardDataProvider>
      <WizardNavigationProvider>
        <Switch>
          <Route exact path='/templates/new'>
            <Redirect to='/templates/new/step/0' />
          </Route>
          <Route
            exact
            path={`/templates/new/step/${TEMPLATE_NAME_STEP}`}
            component={TemplateNameWizardPage}
          />
          <Route
            exact
            path={`/templates/new/step/${TEMPLATE_TABS_STEP}`}
            component={TemplateTabsWizardPage}
          />
        </Switch>
      </WizardNavigationProvider>
    </WizardDataProvider>
  )
}

export default TemplateWizardPage

.react-app ._listContainer_ayz5r_1 {
  margin: 0;
}
.react-app ._listContainer_ayz5r_1 dd {
  margin-left: 0;
}

.react-app ._negativeSpace_ayz5r_8 {
  margin-top: calc(-1 * var(--space-small));
}

.react-app ._spacingAfterDefinition_ayz5r_12 {
  margin-bottom: var(--space-small);
}

.react-app ._registerButtonWrapper_ayz5r_16 {
  width: 100%;
  margin-bottom: var(--space-more);
}
.react-app ._registerButtonWrapper_ayz5r_16 ._registerButton_ayz5r_16 {
  width: 100%;
}
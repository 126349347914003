import matchDataKeyToIntake from 'apps/Reports/components/ProductOverviewReport/utils/matchDataKeyToIntake'

export const comparisonValueFormatter = (intakes) => (value, name) => {
  const matchedIntake = intakes.find((intake) =>
    matchDataKeyToIntake(name, intake)
  )

  return [value, matchedIntake?.name || name]
}

const getTooltipProps = (t, intakes, timeframeInterval) => {
  if (!intakes) {
    return {
      labelFormatter: () => t('Loading'),
      formatter: (value) => [value, t('Loading')]
    }
  }

  return {
    labelFormatter: (value) =>
      timeframeInterval === 'weekly' ? t(`Week ${value}`) : t(`Month ${value}`),
    formatter: comparisonValueFormatter(intakes)
  }
}

export default getTooltipProps

import React from 'react'

import {
  useGoBackOnPaymentsDrawer,
  useOpenPaymentsDrawer,
  usePaymentsDrawerData
} from 'apps/ContentPages/contexts/payments-drawer'
import getStateOfFee from 'apps/ContentPages/services/payments/get-state-of-fee'
import AmountDetailsTable from './amount-details-table'
import Header from './header'
import PayFeeButton from './pay-fee-button'
import PendingPaymentAlert from './pending-payment-alert'

import styles from './styles.module.scss'

const Info = () => {
  const { fee, discounts, paymentPlan = {} } = usePaymentsDrawerData()

  const { payment_portal: paymentPortal } = paymentPlan
  const handleClose = useGoBackOnPaymentsDrawer()
  const openPaymentsDrawer = useOpenPaymentsDrawer()

  const feeState = getStateOfFee(fee)

  return (
    <div className={styles.layout}>
      <div className={styles.infoContainer}>
        <Header fee={fee} state={feeState} handleClose={handleClose} />
        {!!fee.has_pending_payment && <PendingPaymentAlert />}
        <AmountDetailsTable fee={fee} discounts={discounts} />
      </div>
      <PayFeeButton
        feeState={feeState}
        onFeeClick={() => openPaymentsDrawer({ fee, paymentPlan })}
        paymentPortal={paymentPortal}
      />
    </div>
  )
}

export default Info

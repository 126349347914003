import { useCallback, useState } from 'react'

export default function useModal(defaultOpened = false) {
  const [isOpen, setIsOpen] = useState(defaultOpened)

  const handleOpen = useCallback(() => setIsOpen(true), [])
  const handleClose = useCallback(() => setIsOpen(false), [])

  return [isOpen, handleOpen, handleClose]
}

import React from 'react'

import PropTypes from 'prop-types'

import Widget from './widget'

export default function StudentHubPageWidgets({ widgets }) {
  return widgets.map((widget) => <Widget key={widget.id} widget={widget} />)
}

StudentHubPageWidgets.propTypes = {
  widgets: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired
    })
  ).isRequired
}

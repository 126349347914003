import React from 'react'

import { Text } from '@fullfabric/alma-mater'
import { useCurrentUser } from '@fullfabric/authorization-officer'
import { SchemableForm } from '@fullfabric/schemable-forms'

import FullScreenLoading from 'shared/components/FullScreenLoading'

import saveEvaluation from 'apps/Evaluations/api/saveEvaluation'
import {
  useApplication,
  useApplicationTemplate
} from 'apps/Evaluations/context/ApplicationData'
import { useCountries } from 'apps/Evaluations/context/CountriesData'
import { useEvaluation } from 'apps/Evaluations/context/EvaluationData'
import {
  SUBMISSION_ACTIONS,
  SUBMISSION_STATE,
  useDispatchSubmissionUpdate,
  useSubmissionState
} from 'apps/Evaluations/context/EvaluationFormData'
import useSchemableDocumentForm from 'apps/Evaluations/hooks/useSchemableDocumentForm'

import classNames from 'classnames'
import styles from './styles.module.scss'

/**
 * Fetches the Evaluation and renders an edit form, or a full screen loader.
 */
export default function EvaluationForm({ widened, formRef }) {
  const currentUser = useCurrentUser()
  const { id: currentUserId } = currentUser

  const { evaluationData, isLoadingEvaluationData, canChangeEvaluation } =
    useEvaluation()
  const { isLoading: isLoadingApplication } = useApplication()
  const { isLoading: isLoadingApplicTemplate } = useApplicationTemplate()

  const { form: formSubmissionState } = useSubmissionState()
  const dispatchSubmissionUpdate = useDispatchSubmissionUpdate()

  const saveField = React.useCallback(
    (key, value) => {
      const evaluationSchemableDocument =
        evaluationData?.schemable_documents?.[0]

      if (evaluationSchemableDocument) {
        saveEvaluation(evaluationData.id, evaluationSchemableDocument.id, {
          [key]: value
        })
      }
    },
    [evaluationData]
  )
  const handleSubmit = React.useCallback(() => {
    dispatchSubmissionUpdate({ type: SUBMISSION_ACTIONS.CONFIRM_SUBMISSION })
  }, [dispatchSubmissionUpdate])

  const { data: countriesAsOptions, isLoading: isLoadingCountries } =
    useCountries()

  const { fieldOptions, error, handleSaveField } = useSchemableDocumentForm(
    evaluationData?.schemable_documents?.[0],
    {
      documentBaseUrl: `/evaluations/api/evaluations/${evaluationData?.id}`,
      userId: currentUserId,
      saveField
    }
  )

  if (
    isLoadingEvaluationData ||
    isLoadingCountries ||
    isLoadingApplication ||
    isLoadingApplicTemplate
  ) {
    return <FullScreenLoading />
  }

  const canEditEvaluation =
    canChangeEvaluation &&
    formSubmissionState === SUBMISSION_STATE.FORM.EDITABLE

  return (
    <div className={classNames(styles.container, widened && styles.wider)}>
      <Text type='h3' fontColor='text-base-darkest' marginBottom='more'>
        {evaluationData.name}
      </Text>

      <SchemableForm
        hideSubmitButton
        user={currentUser}
        countriesAsOptions={countriesAsOptions}
        data={evaluationData?.schemable_documents?.[0]}
        fieldOptions={fieldOptions}
        error={error}
        handleSaveField={handleSaveField}
        handleSubmit={handleSubmit}
        formRef={formRef}
        readOnly={!canEditEvaluation}
        schema={evaluationData?.schemable_documents?.[0]?.schema}
      />
    </div>
  )
}

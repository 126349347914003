import React from 'react'
import { Trans } from 'react-i18next'

import { Col, Field, Row } from '@fullfabric/alma-mater'

import SubjectUpdateActions from 'apps/StudyPlans/components/SubjectUpdateActions'
import {
  useOnChangeSubjectEditionData,
  useSubjectEditionData
} from 'apps/StudyPlans/contexts/SubjectEditionData'
import subjectAccountabilityValues from 'apps/StudyPlans/utils/subjectAccountabilityValues'

const SubjectFinalGradeForm = () => {
  const data = useSubjectEditionData()
  const onChange = useOnChangeSubjectEditionData()

  const isEditing = data.id ? true : null

  return (
    <>
      <Row>
        <Col xs={12} lg={8}>
          <Field
            id='counts_towards_curricular_grade'
            type='radio'
            label={<Trans>Counts toward final grade?</Trans>}
            value={data.counts_towards_curricular_grade}
            onChange={onChange}
            inputOptions={subjectAccountabilityValues}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} lg={8}>
          <Field
            id='counts_towards_total_credits'
            type='radio'
            label={<Trans>Counts toward final credits awarded?</Trans>}
            value={data.counts_towards_total_credits}
            onChange={onChange}
            inputOptions={subjectAccountabilityValues}
          />
        </Col>
      </Row>
      {isEditing && (
        <SubjectUpdateActions
          sectionName='finalGrade'
          fieldsToUpdate={[
            'counts_towards_curricular_grade',
            'counts_towards_total_credits'
          ]}
        />
      )}
    </>
  )
}

export default SubjectFinalGradeForm

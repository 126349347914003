import { utils } from '@fullfabric/public-api'

const updateEventResponse = async (eventId, responseId, data) => {
  const response = await fetch(
    `/api/events/${eventId}/responses/${responseId}/update_response`,
    {
      credentials: 'same-origin',
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ response: data })
    }
  )

  return await utils.checkResponse(response)
}

export default updateEventResponse

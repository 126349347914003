import React from 'react'
import { Trans } from 'react-i18next'

import { Button, Col, Row, Text } from '@fullfabric/alma-mater'

import styles from './styles.module.scss'

const PolicyChoice = ({
  title,
  lastAcceptanceText,
  complementaryText,
  policyHtml,
  children,
  canSubmit,
  onSubmit,
  inProgress
}) => (
  <Row>
    <Col xs={12}>
      <Text type='h1' fontColor='text-base-darkest'>
        {title}
      </Text>
    </Col>

    <Col xs={12}>
      <Text
        dangerouslySetInnerHTML={{ __html: policyHtml }}
        fontColor='text-base-darkest'
        marginTop='small'
        marginBottom='default'
      />
    </Col>

    {lastAcceptanceText ? (
      <Col xs={12}>
        <Text small marginBottom='small'>
          {lastAcceptanceText}
        </Text>
      </Col>
    ) : null}

    {children}

    <Col xs={12} className={styles.buttonRegion}>
      <Button
        primary
        type='submit'
        onClick={onSubmit}
        loading={inProgress}
        disabled={!canSubmit}
      >
        <Trans>Submit</Trans>
      </Button>
    </Col>

    {complementaryText ? (
      <Col xs={12}>
        <Text small marginBottom='huge'>
          {complementaryText}
        </Text>
      </Col>
    ) : null}
  </Row>
)

export default PolicyChoice

.react-app ._container_1naba_1 {
  margin-top: var(--space-big);
  margin-bottom: var(--space-huge);
}

.react-app ._downloadLink_1naba_6 {
  padding: 0;
  border-bottom: 1px solid var(--ff-color-primary-300);
  margin-right: var(--space-small);
}
.react-app ._downloadLink_1naba_6:hover {
  border-bottom: 1px solid var(--ff-color-primary-700);
}

.react-app ._errorTableList_1naba_15 {
  margin-left: calc(-1 * var(--space-default));
  width: calc(100% + 2 * var(--space-default));
  max-width: calc(100% + 2 * var(--space-default));
  table-layout: fixed;
}
.react-app ._errorTableList_1naba_15 ._tableHeaderItem_1naba_21 {
  padding: var(--space-more) var(--space-default) var(--space-small) var(--space-default);
}
.react-app ._errorTableList_1naba_15 ._tableData_1naba_24 {
  padding: var(--space-small) var(--space-default);
  white-space: inherit;
}

.react-app ._profileIcon_1naba_29 {
  width: 16px;
  height: 16px;
  vertical-align: bottom;
  margin-right: var(--space-tiny);
}

.react-app ._warningColor_1naba_36 {
  color: var(--ff-color-text-warning);
}

.react-app ._failureColor_1naba_40 {
  color: var(--ff-color-text-alert);
}
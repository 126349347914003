import React from 'react'
import { Trans } from 'react-i18next'

import { OneColumnLayout, PageHead } from '@fullfabric/alma-mater'

import DeleteTaskModal from 'apps/Inbox/components/DeleteTaskModal'
import InboxCardList from 'apps/Inbox/components/InboxCardList'
import InboxCompletedNotification from 'apps/Inbox/components/InboxCompletedNotification'
import { CardFiltersProvider } from 'apps/Inbox/contexts/CardFilters'
import { CardNotificationProvider } from 'apps/Inbox/contexts/cardNotifications'
import { DeleteCardProvider } from 'apps/Inbox/contexts/deleteCard'
import { PusherProvider } from 'apps/Inbox/contexts/pusher'
import { ScheduledToCompleteProvider } from 'apps/Inbox/contexts/scheduledToComplete'

import styles from './styles.module.scss'

export default function InboxPage() {
  return (
    <OneColumnLayout
      pageHead={
        <PageHead
          className={styles.pageHead}
          title={<Trans>Inbox</Trans>}
          noBorder
        />
      }
      className={styles.layoutContainer}
    >
      <ScheduledToCompleteProvider>
        <DeleteCardProvider>
          <CardFiltersProvider>
            <CardNotificationProvider>
              <PusherProvider>
                <InboxCardList />
                <InboxCompletedNotification />
                <DeleteTaskModal />
              </PusherProvider>
            </CardNotificationProvider>
          </CardFiltersProvider>
        </DeleteCardProvider>
      </ScheduledToCompleteProvider>
    </OneColumnLayout>
  )
}

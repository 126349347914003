import React, { useEffect, useLayoutEffect, useReducer } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Field } from '@fullfabric/alma-mater'

import { useTemplate } from '../../../template-info-context'
import { useChoicesData } from '../../start-application-state-context'

import styles from '../styles.module.scss'

const ChoiceDropdowns = ({ onChange, showErrors }) => {
  const { t } = useTranslation()
  const template = useTemplate()
  const { data: choices } = useChoicesData()

  const [{ chosenParent, chosenContext, chosenCampus }, dispatch] = useReducer(
    choicesReducer,
    {}
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const contextParents = choices ? getContextParents(template, choices, t) : []
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const contexts =
    choices && chosenParent
      ? getAvailableContextsFromParent(template, chosenParent, choices, t)
      : []

  const shouldChooseACampus =
    chosenContext && chosenContext.campuses && chosenContext.campuses.length > 0
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const campuses = shouldChooseACampus ? getContextCampuses(chosenContext) : []

  useLayoutEffect(() => {
    // runs before first render to avoid flickering
    if (choices) {
      selectSingleOptions(template, choices, dispatch, t)
    }
  }, [template, choices, t])

  useEffect(() => {
    if (!chosenParent) {
      selectSingleParent(template, contextParents, dispatch)
    }
  }, [template, contextParents, chosenParent])

  useEffect(() => {
    if (!chosenContext) {
      selectSingleContext(template, contexts, dispatch)
    }
  }, [template, contexts, chosenContext])

  useEffect(() => {
    if (campuses && campuses.length === 1 && !chosenCampus) {
      selectSingleCampus(campuses, chosenContext, dispatch)
    }
  }, [campuses, chosenCampus, chosenContext])

  useEffect(() => {
    if (!chosenContext) {
      onChange(false, {})
    } else {
      const choice = {
        id: chosenContext.id,
        type: chosenContext.type
      }

      let isValid = true
      let campusChoice = {}

      if (shouldChooseACampus) {
        isValid = chosenCampus && chosenCampus.id
        campusChoice = isValid ? { campus_id: chosenCampus.id } : {}
      }

      onChange(isValid, { ...choice, ...campusChoice })
    }
  }, [onChange, chosenContext, shouldChooseACampus, chosenCampus])

  return (
    <>
      <Field
        required
        id='chosenParent'
        type='autocomplete'
        label={
          <span id='programme-and-class-modal-root-dropdown-label'>
            <Trans>Please select an option</Trans>
          </span>
        }
        error={
          showErrors && !chosenParent && <Trans>This field is required</Trans>
        }
        value={chosenParent ? chosenParent.id : ''}
        onChange={(_, parentId) =>
          dispatch({
            type: 'CHANGE_PARENT',
            value: contextParents.find(({ id }) => id === parentId)
          })
        }
        inputOptions={{
          searchable: true,
          clearable: false,
          placeholder: t('Select an option'),
          options: contextParents.map((ctxParent) => ({
            ...ctxParent,
            disabled:
              !template.restrict_choices_to_user_roles &&
              !ctxParent.hasOpenChoices
          }))
        }}
      />
      <Field
        required
        id='chosenContext'
        type='autocomplete'
        className={styles.choiceDropdownChild}
        error={
          showErrors && !chosenContext && <Trans>This field is required</Trans>
        }
        value={chosenContext ? chosenContext.id : ''}
        onChange={(_, contextId) =>
          dispatch({
            type: 'CHANGE_CONTEXT',
            value: contexts.find(({ id }) => id === contextId)
          })
        }
        inputOptions={{
          searchable: true,
          clearable: false,
          placeholder: chosenParent ? t('Select an option') : '',
          options: contexts.map((ctx) => ({
            ...ctx,
            disabled: !template.restrict_choices_to_user_roles && !ctx.isOpen
          })),
          disabled: !chosenParent,
          'aria-labelledby': 'programme-and-class-modal-root-dropdown-label'
        }}
      />
      {shouldChooseACampus && (
        <Field
          required
          id='chosenCampuse'
          type='autocomplete'
          className={styles.choiceDropdownChild}
          error={
            showErrors &&
            shouldChooseACampus &&
            !chosenCampus && <Trans>This field is required</Trans>
          }
          value={chosenCampus ? chosenCampus.id : ''}
          onChange={(_, campusId) =>
            dispatch({
              type: 'CHANGE_CAMPUS',
              value: chosenContext.campuses.find(({ id }) => id === campusId)
            })
          }
          inputOptions={{
            searchable: true,
            clearable: false,
            placeholder: t('Select an option'),
            options: campuses,
            'aria-labelledby': 'programme-and-class-modal-root-dropdown-label'
          }}
        />
      )}
    </>
  )
}

const choicesReducer = (state, action) => {
  switch (action.type) {
    case 'CHANGE_PARENT':
      return {
        ...state,
        chosenParent: action.value,
        chosenContext: null,
        chosenCampus: null
      }
    case 'CHANGE_CONTEXT':
      return {
        ...state,
        chosenContext: action.value,
        chosenCampus: null
      }
    case 'CHANGE_CAMPUS':
      return {
        ...state,
        chosenCampus: action.value
      }
    case 'SUBMIT':
      return {}
    default:
      return state
  }
}

const sortText = (a, b) => {
  const textA = a.toUpperCase()
  const textB = b.toUpperCase()

  if (textA < textB) {
    return -1
  }

  if (textA > textB) {
    return 1
  }

  return 0
}

export const getContextParents = (template, contexts, t) => {
  const parentMap = [...contexts].reduce((map, context) => {
    return {
      ...map,
      [context.context_parent_id]: [
        ...(map[context.context_parent_id] || []),
        context
      ]
    }
  }, {})

  const parents = Object.keys(parentMap).map((parentId) => {
    const context = parentMap[parentId][0]
    const hasOpenChoices = !parentMap[parentId].every(
      (context) => context.state !== 'open'
    )

    return {
      value: context.context_parent_id,
      label: getContextParentLabel(
        template,
        context.context_parent_name,
        hasOpenChoices,
        t
      ),
      id: context.context_parent_id,
      type: context.context_parent_type,
      hasOpenChoices
    }
  })

  return parents.sort((parentA, parentB) =>
    sortText(parentA.label, parentB.label)
  )
}

const getAvailableContextsFromParent = (template, parent, allContexts, t) =>
  allContexts
    .filter((context) => context.context_parent_id === parent.id)
    .sort((a, b) => {
      if (!a.starts_on && !b.starts_on) {
        return sortText(a.context_name, b.context_name)
      }
      if (!a.starts_on) {
        return 1
      }
      if (!b.starts_on) {
        return -1
      }
      return a.starts_on - b.starts_on
    })
    .map((context) => ({
      value: context.context_id,
      label: getContextLabel(template, context.context_name, context.state, t),
      id: context.context_id,
      type: context.context_type,
      campuses: context.campuses,
      isOpen: context.state === 'open'
    }))

const getContextParentLabel = (template, name, hasOpenChoices, t) => {
  if (!template.restrict_choices_to_user_roles && !hasOpenChoices) {
    return t(`{{ name }} (not currently available)`, { name })
  }
  return name
}

export const getContextLabel = (template, name, state, t) => {
  if (template.restrict_choices_to_user_roles) {
    return name
  }

  switch (state) {
    case 'init':
      return t(`{{ name }} (not available yet)`, { name })
    case 'closed':
      return t(`{{ name }} (closed)`, { name })
    default:
      return name
  }
}

const getContextCampuses = (context) =>
  context.campuses &&
  context.campuses
    .map(({ id, name }) => ({ label: name, value: id }))
    .sort((contextA, contextB) => sortText(contextA.label, contextB.label))

const selectSingleOptions = (template, allChoices, dispatch, t) => {
  const parents = getContextParents(template, allChoices, t)
  const singleParent = selectSingleParent(template, parents, dispatch)

  if (singleParent) {
    const availableContexts = getAvailableContextsFromParent(
      template,
      singleParent,
      allChoices,
      t
    )
    const singleContext = selectSingleContext(
      template,
      availableContexts,
      dispatch
    )

    if (
      singleContext &&
      singleContext.campuses &&
      singleContext.campuses.length > 0
    ) {
      const availableCampuses = getContextCampuses(singleContext)
      selectSingleCampus(availableCampuses, singleContext, dispatch)
    }
  }
}

const selectSingleParent = (template, parents, dispatch) => {
  if (
    parents &&
    parents.length === 1 &&
    (template.restrict_choices_to_user_roles || parents[0].hasOpenChoices)
  ) {
    dispatch({ type: 'CHANGE_PARENT', value: parents[0] })
    return parents[0]
  }
}

const selectSingleContext = (template, contexts, dispatch) => {
  if (
    contexts &&
    contexts.length === 1 &&
    (template.restrict_choices_to_user_roles || contexts[0].isOpen)
  ) {
    dispatch({ type: 'CHANGE_CONTEXT', value: contexts[0] })
  }
}

const selectSingleCampus = (campuses, context, dispatch) => {
  if (campuses && campuses.length === 1) {
    dispatch({
      type: 'CHANGE_CAMPUS',
      value: context.campuses.find(({ id }) => id === campuses[0].value)
    })
  }
}

export default ChoiceDropdowns

import React, { Suspense } from 'react'
import { useParams } from 'react-router'

import { OneColumnLayout, Row } from '@fullfabric/alma-mater'

import FullScreenLoading from 'shared/components/FullScreenLoading'

import Path from 'apps/Applics/components/StaffSubjectSelection/Path'
import SelectedCourses from 'apps/Applics/components/StaffSubjectSelection/SelectedCourses'
import { ApplicDataProvider } from 'apps/Applics/contexts/StaffSubjectSelection/ApplicData'
import { ClassOfDataProvider } from 'apps/Applics/contexts/StaffSubjectSelection/ClassOfData'
import { ProfileDataProvider } from 'apps/Applics/contexts/StaffSubjectSelection/ProfileData'
import { StudyPlanDataProvider } from 'apps/Applics/contexts/StaffSubjectSelection/StudyPlanData'
import courseTypes from 'apps/Applics/utils/constants/courseTypes'
import SubjectSelectionPageHead from './components/SubjectSelectionPageHead'

const SubjectSelectionPage = () => {
  const { applicId } = useParams()

  return (
    <Suspense fallback={<FullScreenLoading />}>
      <ApplicDataProvider applicId={applicId}>
        <ClassOfDataProvider>
          <ProfileDataProvider>
            <StudyPlanDataProvider>
              <OneColumnLayout
                className='bgColor-base-lighter'
                pageHead={<SubjectSelectionPageHead />}
              >
                <div className='marginBottom-huge'>
                  <Row nogutter justify='between'>
                    <Path />
                  </Row>
                  <SelectedCourses
                    types={[courseTypes.CORE, courseTypes.ELECTIVE]}
                  />
                </div>
              </OneColumnLayout>
            </StudyPlanDataProvider>
          </ProfileDataProvider>
        </ClassOfDataProvider>
      </ApplicDataProvider>
    </Suspense>
  )
}

export default SubjectSelectionPage

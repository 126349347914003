export const WINDOWS_PLATFORMS = ['Win32', 'Win64', 'Windows', 'WinCE']

export const handleSelectedFile =
  (currentFile, onDataChanged, setImportDocument) => (newFile) => {
    if (currentFile && currentFile.url) {
      window.URL.revokeObjectURL(currentFile.url)
    }

    const storedFile = newFile
      ? {
          name: newFile.name,
          url: newFile.preview || window.URL.createObjectURL(newFile)
        }
      : null

    onDataChanged('file', storedFile)
    setImportDocument(null)
  }

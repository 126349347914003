import React from 'react'

import TablePageLayout from 'shared/components/TablePageLayout'

import SubjectsList from 'apps/Subjects/components/SubjectsList'
import SubjectsListPageHeader from 'apps/Subjects/components/SubjectsListPageHeader'

const SubjectsListPage = () => {
  return (
    <TablePageLayout>
      <SubjectsListPageHeader />

      <TablePageLayout.TableSection>
        <SubjectsList />
      </TablePageLayout.TableSection>
    </TablePageLayout>
  )
}

export default SubjectsListPage

import React from 'react'
import { Trans } from 'react-i18next'
import { useQuery } from 'react-query'

import getSubmittedApplicsTimeseries from 'apps/Reports/api/getSubmittedApplicsTimeseries'
import { useFilter } from '../FilterContext'
import WeeklyApplicsBarChart from './WeeklyApplicsBarChart'

const WeeklySubmittedApplicationsChart = () => {
  const filter = useFilter()
  const { data, isLoading } = useQuery(
    ['getSubmittedApplicsTimeseries', filter],
    () => getSubmittedApplicsTimeseries(filter)
  )

  return (
    <WeeklyApplicsBarChart
      data={data}
      dataKey='submissions'
      isLoading={isLoading}
      title={<Trans>Submitted applications</Trans>}
    />
  )
}

export default WeeklySubmittedApplicationsChart

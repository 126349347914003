import React from 'react'
import { Trans } from 'react-i18next'

import { Row, Text } from '@fullfabric/alma-mater'

import ChartCard from 'apps/Reports/components/ChartCard'
import { commonStyles } from 'apps/Reports/components/CustomChartElements'
import { FilterProvider } from './FilterContext'
import FunnelChart from './FunnelChart'
import LifecyclesSelector from './LifecyclesSelector'
import ReportFilter from './ReportFilter'

import cx from 'classnames'
import styles from './styles.module.scss'

const FunnelReport = ({ fixedContextId }) => {
  return (
    <FilterProvider fixedContextId={fixedContextId}>
      {!fixedContextId && <ReportFilter />}
      <ChartCard>
        <Row nogutter justify='between' align='center'>
          <Text type='h4' fontColor='text-base-darkest' marginBottom='tiny'>
            <Trans>Funnel</Trans>
          </Text>
          <LifecyclesSelector />
        </Row>

        <div
          className={cx([commonStyles.chartContainer, styles.chartContainer])}
        >
          <FunnelChart fixedContextId={fixedContextId} />
        </div>
      </ChartCard>
    </FilterProvider>
  )
}

export default FunnelReport

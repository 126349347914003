import React from 'react'
import { Trans } from 'react-i18next'

import { Alert, Col, Field, Row } from '@fullfabric/alma-mater'

import SubjectUpdateActions from 'apps/StudyPlans/components/SubjectUpdateActions'
import { useStudyPlan } from 'apps/StudyPlans/contexts/StudyPlanData'
import {
  useOnChangeSubjectEditionData,
  useSubjectEditionData,
  useSubjectEditionErrors
} from 'apps/StudyPlans/contexts/SubjectEditionData'
import { subjectTypeOptionsForPath } from 'apps/StudyPlans/utils/subjectTypesValues'

import styles from './styles.module.scss'

const ERROR_MAP = {
  must_choose_at_least_one: (
    <Trans>The subject must have a course type for at least one path.</Trans>
  )
}

const SubjectPathsSectionForm = () => {
  const studyPlan = useStudyPlan()
  const data = useSubjectEditionData()
  const onChange = useOnChangeSubjectEditionData()
  const errors = useSubjectEditionErrors()

  const paths = studyPlan.paths
  const isEditing = data.id ? true : null

  return (
    <>
      {errors.course_types && (
        <Alert
          error
          className={styles.alert}
          title={<Trans>Error found.</Trans>}
          description={ERROR_MAP[errors.course_types]}
        />
      )}
      {paths.map((path) => {
        const inputOptions = subjectTypeOptionsForPath(path)

        return (
          <Row key={path.id}>
            <Col xs={8}>
              <Field
                id={`path_name_${path.id}`}
                type='text'
                label={<Trans>Path name</Trans>}
                value={path.name}
                onChange={() => null}
                inputOptions={{ disabled: true }}
              />
            </Col>
            <Col xs={4}>
              <Field
                id={`course_type_${path.id}`}
                type='dropdown'
                label={<Trans>Course type</Trans>}
                value={data[`course_type_${path.id}`]}
                onChange={onChange}
                inputOptions={{
                  isClearable: true,
                  placeholder: <Trans>- Select -</Trans>,
                  options: inputOptions
                }}
              />
            </Col>
          </Row>
        )
      })}
      {isEditing && (
        <SubjectUpdateActions
          sectionName='paths'
          fieldsToUpdate={['course_types']}
        />
      )}
    </>
  )
}

export default SubjectPathsSectionForm

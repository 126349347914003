import React from 'react'

import { useMergeData } from 'apps/MergeProfiles/contexts/MergeData'
import PageContentRefContext from 'apps/MergeProfiles/contexts/PageContentRef'

const useCurrentPage = (step) => {
  const { setCurrentMergeStep } = useMergeData()
  const pageContentRef = React.useContext(PageContentRefContext)

  React.useEffect(() => {
    setCurrentMergeStep(step)
  }, [step, setCurrentMergeStep])

  React.useEffect(() => {
    pageContentRef.current?.scrollTo && pageContentRef.current?.scrollTo(0, 0)
  }, [pageContentRef])
}

export default useCurrentPage

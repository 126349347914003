import React from 'react'
import { Trans } from 'react-i18next'

import { CheckBigIcon, Text } from '@fullfabric/alma-mater'

import useIsMobileLayout from 'apps/ContentPages/components/payments-page/use-is-payments-mobile-layout'
import OverdueIcon from './overdue-icon'

import classNames from 'classnames'
import styles from './styles.module.scss'

const FeeStatePill = ({ isPaid, isOverdue }) => {
  const isMobileLayout = useIsMobileLayout()

  if (!isPaid && !isOverdue) {
    return null
  }

  return (
    <div
      className={classNames(
        styles.pill,
        isMobileLayout && styles.mobile,
        isOverdue && styles.overdue,
        isPaid && styles.paid
      )}
    >
      {isPaid ? (
        <CheckBigIcon
          color='green-800'
          style={{ marginRight: 'var(--space-tiny)' }}
        />
      ) : (
        <OverdueIcon />
      )}
      <Text type='h5' fontColor={isPaid ? 'green-800' : 'text-alert-darkest'}>
        {isPaid ? <Trans>Paid</Trans> : <Trans>Due / Overdue</Trans>}
      </Text>
    </div>
  )
}

export default FeeStatePill

import React from 'react'

import { useScreenClass } from '@fullfabric/alma-mater'

import useIsMobileLayout from 'apps/ContentPages/hooks/use-is-mobile-layout'
import ActionPageLayout from './action-page-layout'
import FunctionalPageLayout from './functional-page-layout'
import Header from './header'
import NavigationInHeader from './navigation-in-header'

import classNames from 'classnames'
import styles from './styles.module.scss'

const PageLayout = ({ type, ...pageProps }) => {
  const screenClass = useScreenClass()
  const isMobileLayout = useIsMobileLayout()
  const isXlLayout = screenClass === 'xl'
  const isDesktopLayout = !isMobileLayout && !isXlLayout

  let PageLayoutVariation = null

  // types of pages originally described as:
  // Content
  //    Generous text side and large images, made for landing pages
  //    (this page type is still WIP, hence uses the same as functional for now)
  // Functional
  //    More space for content. Made for technical content, where branding
  //    doesn't matter as much
  // Action
  //    Where the page's main purpose is for a single action, like an event page

  switch (type) {
    case 'content':
    case 'functional':
      PageLayoutVariation = FunctionalPageLayout
      break
    case 'action':
    default:
      PageLayoutVariation = ActionPageLayout
      break
  }
  return (
    <div
      className={classNames([
        styles.customGrid,
        isDesktopLayout && styles.desktop,
        isXlLayout && styles.xlDesktop
      ])}
    >
      <PageLayoutVariation {...pageProps} />
    </div>
  )
}

PageLayout.Header = Header
PageLayout.NavigationInHeader = NavigationInHeader

export default PageLayout

import React from 'react'
import { useParams } from 'react-router'

import FixedClassProductPerformancePage from 'apps/Reports/components/FixedClassProductPerformancePage'
import useChangeDocumentTitle from 'apps/Reports/hooks/useChangeDocumentTitle'

const FixedClassProductPerformance = () => {
  useChangeDocumentTitle('Product performance report')
  const { classId } = useParams()

  return <FixedClassProductPerformancePage classId={classId} />
}

export default FixedClassProductPerformance

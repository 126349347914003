import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'

import { PaymentElement } from '@stripe/react-stripe-js'
import { Alert, Button } from '@fullfabric/alma-mater'
import { useCurrentUser } from '@fullfabric/authorization-officer'

import { useDisplayFeedbackMessage } from 'apps/ContentPages/components/feeback-message-provider'
import { useClosePaymentsDrawer } from 'apps/ContentPages/contexts/payments-drawer'
import useStripePaymentSubmission from './use-stripe-payment-submission'

import styles from './styles.module.scss'

const PaymentForm = () => {
  const { t } = useTranslation()
  const [error, setError] = React.useState(null)
  const displayFeedbackMessage = useDisplayFeedbackMessage()
  const closePaymentsDrawer = useClosePaymentsDrawer()
  const queryClient = useQueryClient()
  const user = useCurrentUser()

  const onPaymentSuccess = () => {
    if (!user) {
      // no user will be the case of an external payer payment
      window.location.reload()
    } else {
      displayFeedbackMessage(t('Your payment was processed'))

      queryClient.invalidateQueries(['get-payment-plan', user.id])
      queryClient.invalidateQueries(['get-payment-plans', user.id])

      closePaymentsDrawer()
    }
  }

  const onError = React.useCallback(
    (error) => {
      setError(error)
    },
    [setError]
  )

  const { isPaymentProcessing, handleSubmit, canSubmit } =
    useStripePaymentSubmission({
      onError,
      onPaymentSuccess
    })

  return (
    <>
      {error ? (
        <Alert
          error
          role='alert'
          description={error.message}
          className={styles.errorAlert}
        />
      ) : null}

      <form className={styles.form} onSubmit={handleSubmit}>
        <PaymentElement />
        <Button
          primary
          type='submit'
          className={styles.submitBtn}
          disabled={!canSubmit}
          loading={isPaymentProcessing}
        >
          <Trans>Pay with Stripe</Trans>
        </Button>
      </form>
    </>
  )
}

export default PaymentForm

{
  "Email": "E-mail",
  "Email address is invalid": "E-mail adresse er ugyldig",
  "I accept the privacy policy": "Jeg godkender privatlivspolitikken",
  "Privacy Policy": "Privatlivspolitik",
  "Please accept the privacy policy to continue": "Godkend venligst privatlivspolitikken for at fortsætte",
  "Welcome back": "Velkommen tilbage",
  "Please enter your password for security reasons.": "Indtast din adgangskode af sikkerhedsmæssige årsager.",
  "Email address ": "Email adresse",
  "Password": "Adgangskode",
  "Forgot your password?": "Glemt din adgangskode?",
  "An email has been sent to  with an authentication link that will take you to your application.": "An email has been sent to  with an authentication link that will take you to your application.",
  "Once you have accessed the authentication link you can close this page.": "Når du har fået adgang til godkendelseslinket, kan du lukke denne side.",
  "Didn’t receive the email?": "Modtog du ikke e-mailen?",
  "Resend email with authentication link": "Send godkendelses-e-mail igen",
  "Email sent": "Email sendt",
  "Welcome! Let's start your new application.": "Velkommen! Lad os starte din nye ansøgning.",
  "Welcome! Let's start your new registration.": "Velkommen! Lad os starte din nye registrering.",
  "Please enter the following details so that you can revisit your application if you exit it.": "Indtast følgende oplysninger, så du kan genbesøge din ansøgning igen, hvis du forlader den.",
  "Please enter the following details so that you can revisit your registration if you exit it.": "Indtast venligst følgende oplysninger, så du kan besøge din registrering igen, hvis du forlader den.",
  "First name": "Fornavn",
  "Last name": "Efternavn",
  "Back": "Tilbage",
  "Continue to application": "Fortsæt til ansøgning",
  "Continue to registration": "Fortsæt til registrering",
  "Data Protection regulation": "Databeskyttelsesforordning",
  "Someone already registered with that email address": "Der er allerede registreret en anden med denne e-mailadresse",
  "Email address can't be blank": "E-mail-adresse kan ikke være tom",
  "Continue": "Fortsæt",
  "Participant details": "Oplysninger om deltagerne",
  "Please enter the details of the participant": "Angiv venligst deltagerens oplysninger",
  "Registration already exists": "Der findes allerede en registrering",
  "Application already exists": "Der findes allerede en ansøgning",
  "There are fields with errors": "Der er felter med fejl",
  "Please correct the fields with errors": "Ret venligst felterne med fejl.",
  "First name can't be blank": "Fornavn kan ikke være tomt",
  "Last Name": "Efternavn",
  "Last name can't be blank": "Efternavnet kan ikke være tomt",
  "Your employment details": "Dine ansættelsesdetaljer",
  "To register on behalf of someone else we need more information on your current employment details.": "For at registrere på vegne af en anden har vi brug for mere information om dine aktuelle ansættelsesdetaljer.",
  "There was an error in your submission, please try again in a few seconds. If the issue persists, please contact us.": "Der opstod en fejl i din indsendelse, prøv venligst igen om et par sekunder. Hvis problemet fortsætter, bedes du kontakte os.",
  "The link has expired.": "Linket er udløbet.",
  "Please re-enter your details to receive a new authentication email.": "Indtast venligst dine oplysninger igen for at modtage en ny bekræftelsesmail.",
  "Please enter your email address so that we can retrieve your information if you have visited before.": "Indtast venligst din e-mail-adresse, så vi kan hente dine oplysninger, hvis du har besøgt os før.",
  "Please select an option": "Vælg venligst en mulighed.",
  "I am starting a registration for myself": "Jeg starter en registrering for mig selv.",
  "I am starting an application for myself": "Jeg starter en ansøgning for mig selv.",
  "I am starting a registration on behalf of someone else": "Jeg starter en registrering på vegne af en anden.",
  "I am starting an application on behalf of someone else": "Jeg starter en ansøgning på vegne af en anden.",
  "I am continuing an existing registration": "Jeg fortsætter en eksisterende registrering.",
  "I am continuing an existing application": "Jeg fortsætter en eksisterende ansøgning.",
  "An email has been sent to {{email}} with an authentication link that will take you to your application.": "Der er sendt en e-mail til {{email}} med et godkendelseslink, der fører dig til din ansøgning."
}

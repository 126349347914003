import React from 'react'
import { Trans } from 'react-i18next'

import getLocalizedText from 'shared/utils/getLocalizedText'

import {
  useApplication,
  useApplicationTemplate
} from 'apps/Evaluations/context/ApplicationData'

const useViewOptions = () => {
  const applic = useApplication()?.data
  const applicTemplate = useApplicationTemplate()?.data

  const viewOptions = React.useMemo(() => {
    const applicDocs = applicTemplate?.documents || []

    const fileFieldsMap = applicDocs
      .map((applicTemplDocument) => {
        const applicSchemableDocument = applic.schemable_documents.find(
          (schemableDoc) => schemableDoc.document_id === applicTemplDocument.id
        )
        const schema = applicTemplDocument.schema
        return { applicSchemableDocument, schema }
      }) // applic document values (tab from applic) + schema
      .filter(({ schema }) => !!schema) // filter empty schemas
      .map(({ applicSchemableDocument, schema }) => {
        return schema.sections.map((schemaSection) => ({
          applicSchemableDocument,
          schemaSection
        }))
      }) // map schema sections
      .flat()
      .map(({ applicSchemableDocument, schemaSection }) => {
        return schemaSection.fields.map((field) => ({
          applicSchemableDocument,
          field
        }))
      }) // map schema fields
      .flat()
      .filter(({ field }) => field.type === 'Schemable::Fields::Types::File') // filter file fields
      .filter(
        ({ applicSchemableDocument, field }) =>
          applicSchemableDocument[field.name]?.uploads?.length > 0
      ) // filter applic data that has at least one uploaded file for the field
      .map(({ applicSchemableDocument, field }) => {
        const uploads = applicSchemableDocument[field.name]?.uploads || []

        return { field, uploads }
      }) // map all uploads
      .reduce((map, { field, uploads }) => {
        const optionKey = field.name

        return {
          ...map,

          [optionKey]: {
            fieldName: field.name,
            label: getLocalizedText(field.locals),
            uploads
          }
        }
      }, {}) // merge fields and uploads in an "view option" object with "field name + upload index" as key

    return {
      applic: {
        fieldName: 'applic',
        optionKey: 'applic',
        label: <Trans>Application</Trans>,
        uploads: [
          {
            path: `applications/${applic.id}.pdf?exclude[evaluations]=true`
          }
        ]
      }, // default view
      ...fileFieldsMap
    }
  }, [applic, applicTemplate])

  return viewOptions
}

export default useViewOptions

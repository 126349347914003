.react-app ._linkContainer_ou8xl_1 {
  margin-top: var(--space-small);
}

.react-app ._linkToEvent_ou8xl_5 {
  margin: 0;
}

.react-app ._linkIcon_ou8xl_9 {
  vertical-align: middle;
}
import React, { useEffect } from 'react'

import { useScreenClass } from '@fullfabric/alma-mater'

import { useAppData } from 'shared/contexts/AppData'

import { useChangeBackgroundImage } from 'apps/ContentPages/contexts/background-image'
import isEventOpen from 'apps/ContentPages/utils/is-event-open'
import WidgetContainer from '../widget-container'
import WidgetSection from '../widget-section'
import EventAddress from './event-address'
import EventDate from './event-date'
import EventStateBanner from './event-state-banner'
import RegisterButton from './register-button'

import classNames from 'classnames'
import styles from './styles.module.scss'

const EventDetailsWidget = () => {
  const screenClass = useScreenClass()
  const isMobileLayout = ['xs', 'sm'].includes(screenClass)

  const { event } = useAppData()

  const bgImageUrl = event?.image?.url
  const setBackgroundImage = useChangeBackgroundImage()
  useEffect(() => {
    setBackgroundImage(bgImageUrl)
  }, [setBackgroundImage, bgImageUrl])

  const areRegistrationsOpen = isEventOpen(event)

  return (
    <WidgetContainer>
      <WidgetSection single>
        <div className={classNames(isMobileLayout && styles.container)}>
          <EventStateBanner event={event} />
          <EventDate event={event} />
          <EventAddress event={event} isLastItem={!areRegistrationsOpen} />
          {areRegistrationsOpen ? <RegisterButton /> : null}
        </div>
      </WidgetSection>
    </WidgetContainer>
  )
}

export default EventDetailsWidget

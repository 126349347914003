import { connect } from 'react-redux'

import actionTypes from 'apps/Courses/actions/types'
import CourseEditSuccessPopup from 'apps/Courses/components/CourseEditSuccessPopup'

const mapStateToProps = ({ editCourse: { isShowingPopup } }) => ({
  isUp: isShowingPopup
})

const mapDispatchToProps = (dispatch) => ({
  hidePopup: () => {
    dispatch({ type: actionTypes.hideSavedChangesPopup })
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CourseEditSuccessPopup)

import getNumberCellValue from '../getNumberCellValue'

const getTransactionsCellValue = ({ value } = {}) => {
  const numberAsCurrency = getNumberCellValue(
    { value: value / 100 },
    { style: 'currency', currency: 'GBP', currencyDisplay: 'code' }
  )

  return numberAsCurrency.replace(/^GBP\s/, '')
}

export default getTransactionsCellValue

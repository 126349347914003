import React from 'react'

const Logo = ({ className }) => (
  <svg
    className={className}
    viewBox='0 0 42 42'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <rect width='28' height='10' rx='5' fill='#243E4F' />
    <rect x='32' width='10' height='10' rx='5' fill='#40B5B3' />
    <rect x='14' y='32' width='28' height='10' rx='5' fill='#40B5B3' />
    <rect y='32' width='10' height='10' rx='5' fill='#243E4F' />
    <rect y='16' width='19' height='10' rx='5' fill='#243E4F' />
    <rect x='23' y='16' width='19' height='10' rx='5' fill='#40B5B3' />
  </svg>
)

export default Logo

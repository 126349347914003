/* eslint-disable camelcase */

import { connect } from 'react-redux'

import newCourseDataChanged from 'apps/Courses/actions/newCourseDataChanged'
import CourseCreateFormSchema from 'apps/Courses/components/CourseCreateFormSchema'

export const mapStateToProps = ({
  newCourse: { data, schema },
  appData: { h: { countries_as_options = [] } = {} } = {}
}) => ({
  data,
  schema,
  countriesAsOptions: countries_as_options
})

const mapDispatchToProps = (dispatch) => ({
  onChange: (field, value) => dispatch(newCourseDataChanged({ [field]: value }))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CourseCreateFormSchema)

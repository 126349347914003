.react-app ._logo-rectangle_1ufl8_1 {
  width: 100%;
  display: inline-block;
  padding: 0;
}
.react-app ._logo-rectangle_1ufl8_1:hover {
  border-bottom-color: transparent;
}
.react-app ._logo-rectangle_1ufl8_1 img {
  max-width: 100%;
  max-height: 80px;
  margin-top: var(--space-huge);
  margin-bottom: var(--space-big);
}
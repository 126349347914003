import React from 'react'
import { Trans } from 'react-i18next'

import { Alert } from '@fullfabric/alma-mater'

const failedValidationMessage = {
  title: <Trans>There are fields with errors.</Trans>,
  description: <Trans>Please correct the fields with errors.</Trans>
}

function FormErrorsAlert({ failedValidation, title, description, onClose }) {
  const hasImportantMessage = title || description

  if (hasImportantMessage) {
    return (
      <Alert
        onClose={onClose}
        marginBottom='default'
        title={title}
        description={description}
      />
    )
  }

  if (failedValidation) {
    return (
      <Alert
        onClose={onClose}
        marginBottom='default'
        {...failedValidationMessage}
      />
    )
  }

  return null
}

export default FormErrorsAlert

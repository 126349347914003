import React from 'react'
import { useQuery } from 'react-query'

import { Col, Loading } from '@fullfabric/alma-mater'

import getContextForRole from 'apps/MergeProfiles/api/getContextForRole'
import JourneyCard from 'apps/MergeProfiles/components/JourneyCard'

import styles from './styles.module.scss'

const JourneySummaryCard = ({ role, type }) => {
  const { data: context, isLoading } = useQuery(['contextForRole', role], () =>
    getContextForRole(role)
  )

  if (isLoading) return <Loading />

  return (
    <Col className={styles.journeySummaryCardCol}>
      <JourneyCard role={role} context={context} summary summaryType={type} />
    </Col>
  )
}

export default JourneySummaryCard

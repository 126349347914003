.react-app ._card_1e3aa_1 {
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
}

.react-app ._title_1e3aa_5 {
  font-weight: lighter;
  background-color: var(--gjs-secondary-dark-color);
  letter-spacing: 1px;
  padding: 9px 10px 9px 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
  text-align: left;
  position: relative;
}

.react-app ._content_1e3aa_15 {
  font-size: var(--gjs-font-size);
  padding: 10px 5px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  box-sizing: border-box;
  width: 100%;
  justify-content: center;
}
const getSortDirectionFromColumn = (column) => {
  return column.canSort
    ? column.isSorted
      ? column.isSortedDesc
        ? 'desc'
        : 'asc'
      : 'none'
    : undefined
}

export default getSortDirectionFromColumn

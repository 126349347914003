import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

const useTranslatePolicyErrorMessage = (passwordPolicy) => {
  const { t } = useTranslation()

  return useCallback(
    (originalMessage) => {
      if (originalMessage === 'Password is too weak') {
        return t('Your password is too weak')
      }

      if (originalMessage.endsWith('characters long')) {
        return t('Password must be at least {{minLength}} characters long', {
          minLength: passwordPolicy.minimum_length
        })
      }

      if (originalMessage.startsWith('Password must have at least')) {
        const suffix = originalMessage.split(' ').pop()
        const isPlural = suffix.endsWith('s')
        const property = isPlural ? suffix.slice(0, suffix.length - 1) : suffix

        switch (property) {
          case 'character':
            return t('Password must have at least {{count}} characters', {
              count: passwordPolicy.minimum_alphas_length
            })
          case 'digit':
            return t('Password must have at least {{count}} digit', {
              count: passwordPolicy.minimum_digits_length
            })
          case 'symbol':
            return t('Password must have at least {{count}} symbol', {
              count: passwordPolicy.minimum_symbols_length
            })
          default:
            return originalMessage
        }
      }

      return t(originalMessage) || originalMessage
    },
    [
      t,
      passwordPolicy.minimum_alphas_length,
      passwordPolicy.minimum_digits_length,
      passwordPolicy.minimum_length,
      passwordPolicy.minimum_symbols_length
    ]
  )
}

export default useTranslatePolicyErrorMessage

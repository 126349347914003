{
  "Analytical": "Analysecookies",
  "Strictly necessary": "Erforderliche Cookies",
  "Functional": "Cookies, die für Funktionen benötigt werden",
  "Advertising": "Werbe-Cookies",
  "Submit": "Absenden",
  "Thank you": "Vielen Dank",
  "You have confirmed your marketing preferences.": "Sie haben Ihre Marketing-Präferenzen bestätigt.",
  "You did not accept the Privacy Policy.": "Sie haben die Datenschutzerklärung nicht akzeptiert.",
  "You must contact us to delete your data.": "Sie müssen uns kontaktieren, damit Ihre Daten gelöscht werden.",
  "Change privacy policy agreement": "Einverständnis mit der Datenschutzerklärung ändern",
  "Privacy Policy": "Datenschutzerklärung",
  "Next: Update marketing preferences": "Weiter: Aktualisieren Sie die Marketingeinstellungen",
  "I Accept the Privacy Policy": "Ich akzeptiere die Datenschutzerklärung",
  "I DO NOT accept the Privacy Policy": "Ich akzeptiere die Datenschutzerklärung nicht",
  "If you do not accept the privacy policy, you must contact the data processor to delete your data.": "Wenn Sie die Datenschutzerklärung nicht akzeptieren, müssen Sie sich an den Datenverarbeiter wenden, um Ihre Daten zu löschen.",
  "You have updated your marketing preferences.": "Sie haben Ihre Marketing-Präferenzen aktualisiert.",
  "Log in": "Anmelden",
  "Finish your registration": "Schließen Sie Ihre Registrierung ab",
  "Marketing Policy": "Marketingrichtlinien",
  "I would like to receive information by Email": "Ich möchte Informationen per E-Mail erhalten",
  "I would like to receive information by Phone": "Ich möchte Informationen per Telefon erhalten",
  "I would like to receive information by SMS": "Ich möchte Informationen per SMS erhalten",
  "I would like to receive information by Mail": "Ich möchte Informationen von Post erhalten",
  "I do not want to be contacted with this type of information": "Ich möchte nicht diese Art von Informationen empfangen",
  "Cookie Policy": "Cookie-Richtlinie",
  "Back to Portal": "Zurück zum Portal",
  "Manage your preferences": "Verwalten Sie Ihre Einstellungen",
  "Save cookie preferences": "Cookie-Einstellungen speichern",
  "You accepted our privacy policy on our website - {{institutionHost}} - on {{date}}": "Sie haben unsere Datenschutzerklärung auf unserer Website – {{institutionHost}} – am {{date}} akzeptiert",
  "You are receiving this email because you accepted our marketing policy on our website - {{institutionHost}} - on  {{date}}": "Sie erhalten diese E-Mail, weil Sie unsere Marketingrichtlinien auf unserer Website – {{institutionHost}} – am {{date}} akzeptiert haben",
  "You accepted our privacy policy when creating an account on {{date}}": "Sie haben unsere Datenschutzrichtlinie beim Erstellen eines Kontos in {{date}} akzeptiert",
  "You accepted our privacy policy on {{date}} from our email": "Sie haben unsere Datenschutzrichtlinie über {{date}} von unserer E-Mail akzeptiert",
  "You are receiving this email because you accepted our marketing policy on {{date}} from our email": "Sie erhalten diese E-Mail, weil Sie unsere Marketingrichtlinie auf {{date}} von unserer E-Mail akzeptiert haben",
  "You are receiving this email because a person created a profile for you and obtained your consent from {{consentSource}} on {{date}}": "Sie erhalten diese E-Mail, weil eine Person ein Profil für Sie erstellt hat und durch \"{{consentSource}}\" am {{date}} Ihre Einwilligung erlangt hat",
  "You accepted our privacy policy when submitting the {{entity}} form on {{date}}": "Sie erklärten sich mit unserer Datenschutzrichtlinie einverstanden, als Sie am {{date}} das \"{{entity}}\"-Formular übermittelten",
  "You accepted our marketing policy when submitting the {{entity}} form on {{date}}": "Sie erklärten sich mit unserer Marketingrichtlinie einverstanden, als Sie am {{date}} das \"{{entity}}\"-Formular übermittelten",
  "You accepted our privacy policy when registering for the {{entity}} event on {{date}}": "Sie erklärten sich mit unserer Datenschutzrichtlinie einverstanden, als Sie sich am {{date}} für die \"{{entity}}\"-Veranstaltung registrierten",
  "You accepted our marketing policy when registering for the {{entity}} event on {{date}}": "Sie erklärten sich mit unserer Marketingrichtlinie einverstanden, als Sie sich am {{date}} für die \"{{entity}}\"-Veranstaltung registrierten",
  "Unsubscribe": "Abbestellen",
  "Yes, unsubscribe": "Ja, ich möchte mich abmelden",
  "No, stay subscribed": "Nein, ich möchte angemeldet bleiben",
  "Manage marketing preferences": "Marketingpräferenzen verwalten",
  "Manage preferences for <0>{{policyName}}</0>": "Verwalten Sie die Einstellungen für <0>{{policyName}}</0>",
  "Unsubscribe successful": "Erfolgreich Deabonniert",
  "You are still subscribed": "Sie sind immer noch abonniert",
  "Back to homepage": "Zurück zur Startseite",
  "Are you sure you want to unsubscribe from the <0>{{topicName}}</0> topic?": "Möchten Sie das Thema <0>{{topicName}}</0> wirklich abbestellen?",
  "You have been unsubscribed from the <0>{{topicName}}</0> topic.": "Sie wurden vom Thema <0>{{topicName}}</0> abgemeldet.",
  "You have not been unsubscribed from the <0>{{topicName}}</0> topic.": "Sie wurden nicht vom Thema <0>{{topicName}}</0> abgemeldet."
}

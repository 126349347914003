import 'css-chunk:src/shared/components/PlaceholderTable/styles.module.scss';export default {
  "react-app": "react-app",
  "virtualizedRow": "_virtualizedRow_4d1v5_1",
  "numericAlignment": "_numericAlignment_4d1v5_5",
  "defaultTextPlaceholder": "_defaultTextPlaceholder_4d1v5_10",
  "checkboxPlaceholder": "_checkboxPlaceholder_4d1v5_16",
  "level-0": "_level-0_4d1v5_20",
  "level-1": "_level-1_4d1v5_24",
  "level-2": "_level-2_4d1v5_28",
  "level-3": "_level-3_4d1v5_32",
  "level-4": "_level-4_4d1v5_36",
  "level-5": "_level-5_4d1v5_40",
  "level-6": "_level-6_4d1v5_44",
  "level-7": "_level-7_4d1v5_48",
  "level-8": "_level-8_4d1v5_52",
  "level-9": "_level-9_4d1v5_56"
};
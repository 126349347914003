export const SUPPORTED_IMG_EXTENSIONS = ['png', 'jpg', 'jpeg']
export const SUPPORTED_AUDIO_EXTENSIONS = ['mp3', 'ogg']
export const SUPPORTED_VIDEO_EXTENSIONS = [
  'mpg',
  'mp2',
  'mpeg',
  'mpe',
  'mpv',
  'mp4',
  'webm',
  'ogv',
  'mov'
]

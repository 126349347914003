import React from 'react'
import { Trans } from 'react-i18next'
import { useQuery } from 'react-query'

import {
  Area,
  AreaChart,
  Label,
  Legend,
  ResponsiveContainer,
  Tooltip
} from 'recharts'

import { Loading, Text } from '@fullfabric/alma-mater'

import getApplicsCumulativeTimeseries from 'apps/Reports/api/getApplicsCumulativeTimeseries'
import ChartCard from 'apps/Reports/components/ChartCard'
import {
  commonStyles,
  DEFAULT_SPACE_TO_CONTENT,
  renderCustomCartesianGrid,
  renderCustomXAxis,
  renderCustomYAxis
} from 'apps/Reports/components/CustomChartElements'
import { useFilter } from '../FilterContext'
import useFormatTooltipValue from '../hooks/useFormatTooltipValue'
import usePeriodConfig from '../hooks/usePeriodConfig'
import LegendContent from './LegendContent'

import classNames from 'classnames'
import styles from './styles.module.scss'

const CumulativeWeeklyApplicsChart = () => {
  const formatTooltipValue = useFormatTooltipValue()
  const filter = useFilter()
  const {
    labelText: chartLabelText,
    Tick: CustomXTick,
    dataKey,
    tooltipLabelFormatter
  } = usePeriodConfig()
  const { data, isLoading } = useQuery(
    ['getApplicsCumulativeTimeseries', filter],
    () => getApplicsCumulativeTimeseries(filter)
  )

  return (
    <ChartCard>
      <Text type='h4' fontColor='text-base-darkest' marginBottom='tiny'>
        <Trans>Started and submitted applications over time</Trans>
      </Text>
      <Text marginBottom='tiny'>
        <Trans>Cumulative</Trans>
      </Text>
      <div
        className={classNames([
          commonStyles.chartContainer,
          styles.chartContainer
        ])}
      >
        {isLoading ? (
          <Loading />
        ) : (
          <ResponsiveContainer>
            <AreaChart
              data={data || []}
              margin={{
                top: 12,
                right: DEFAULT_SPACE_TO_CONTENT,
                left: 0,
                bottom: 16
              }}
            >
              <Tooltip
                labelFormatter={tooltipLabelFormatter}
                formatter={formatTooltipValue}
              />
              {renderCustomCartesianGrid()}
              {renderCustomYAxis()}
              {renderCustomXAxis({
                dataKey,
                tick: <CustomXTick />,
                children: (
                  <Label
                    value={chartLabelText}
                    offset={-8}
                    position='insideBottom'
                  />
                )
              })}
              <Area
                type='monotone'
                dataKey='submissions'
                stroke='#9C6ADE'
                strokeWidth='2'
                fill='rgba(156, 106, 222, 0.1)'
              />
              <Area
                type='monotone'
                dataKey='starts'
                stroke='#F49342'
                strokeWidth='2'
                fill='rgba(244, 147, 66, 0.1)'
              />
              <Legend
                wrapperStyle={{
                  left: 0,
                  top: 0,
                  display: 'flex',
                  alignItems: 'start'
                }}
                height={44}
                verticalAlign='top'
                align='left'
                content={<LegendContent />}
              />
            </AreaChart>
          </ResponsiveContainer>
        )}
      </div>
    </ChartCard>
  )
}

export default CumulativeWeeklyApplicsChart

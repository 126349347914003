import React from 'react'
import { Trans } from 'react-i18next'
import { useHistory, useParams } from 'react-router'

import { Button } from '@fullfabric/alma-mater'
import {
  AuthorizationOfficer,
  useSettings
} from '@fullfabric/authorization-officer'

import { useMergeData } from 'apps/MergeProfiles/contexts/MergeData'

const getFieldsWithoutSelection = (profileInfoFrom, fields) => {
  const selectedFieldNames = Object.keys(profileInfoFrom)

  return fields.filter((field) => !selectedFieldNames.includes(field.name))
}

const NextButton = () => {
  const history = useHistory()
  const { id: sourceProfileId } = useParams()
  const {
    mergeData: { profileInfoFrom },
    updateMergeError,
    fieldsWithDifferentValues
  } = useMergeData()
  const appSettings = useSettings()
  const applicsEnabled = AuthorizationOfficer.hasModule('applics', appSettings)

  const onNextClick = React.useCallback(() => {
    const fieldsWithoutSelection = getFieldsWithoutSelection(
      profileInfoFrom,
      fieldsWithDifferentValues
    )
    if (fieldsWithoutSelection.length !== 0) {
      updateMergeError({
        profileInfoFromFieldsWithoutSelection: fieldsWithoutSelection
      })
      return
    }

    updateMergeError({
      profileInfoFromFieldsWithoutSelection: []
    })

    if (applicsEnabled) {
      history.push(`/profiles/${sourceProfileId}/merge/applics`)
    } else {
      history.push(`/profiles/${sourceProfileId}/merge/journeys`)
    }
  }, [
    fieldsWithDifferentValues,
    history,
    profileInfoFrom,
    sourceProfileId,
    updateMergeError,
    applicsEnabled
  ])

  return (
    <Button primary onClick={onNextClick}>
      <Trans>Next</Trans>
    </Button>
  )
}

export default NextButton

import React from 'react'
import { Trans } from 'react-i18next'

export const STATES_MAP = {
  pending: { label: <Trans>Pending</Trans>, color: 'yellow' },
  succeeded: { label: <Trans>Succeeded</Trans>, color: 'green' },
  failed: { label: <Trans>Failed</Trans>, color: 'red' },
  created: { label: <Trans>Created</Trans>, color: 'grey' },
  initiated: { label: <Trans>Initiated</Trans>, color: 'blue' },
  cancelled: { label: <Trans>Cancelled</Trans>, color: 'red' },
  guaranteed: { label: <Trans>Guaranteed</Trans>, color: 'green' }
}

const getLocalizedPolicyHtml = (policy, language) => {
  if (!policy) return ''

  const currentLocale =
    policy.locales.find((locale) => locale.locale === (language || 'en-GB')) ||
    ''
  const defaultLocale = policy.locales.find(
    (locale) => locale.locale === 'en-GB'
  )

  return (
    (currentLocale && currentLocale.html) ||
    (defaultLocale && defaultLocale.html) ||
    ''
  )
}

export default getLocalizedPolicyHtml

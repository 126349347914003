.react-app ._container_ab6jc_1 {
  display: flex;
  align-items: flex-start;
}

.react-app ._sidebar_ab6jc_6 {
  width: 208px;
  height: 100vh;
  background-color: var(--ff-bg-base-light);
  border-right: var(--border-base-lighter);
}

.react-app ._content_ab6jc_13 {
  flex: 1;
}
import React from 'react'
import { Trans } from 'react-i18next'
import { useHistory } from 'react-router'

import { Button } from '@fullfabric/alma-mater'

import { useSetCurrentStep, useStepState } from 'apps/Imports/contexts/steps'
import { useImportContext } from 'apps/Imports/hooks/useImportContext'
import importUrlFromStepId from 'apps/Imports/utils/profile-import/get-url-from-step-id'

import styles from '../styles.module.scss'

const PreviousStepButton = () => {
  const context = useImportContext()
  const history = useHistory()
  const { previousStep } = useStepState()
  const setCurrentStep = useSetCurrentStep()

  const onPreviousStepClick = React.useCallback(() => {
    const pageUrl = importUrlFromStepId(context, previousStep)
    history.push(pageUrl)

    setCurrentStep(previousStep)
  }, [history, previousStep, context, setCurrentStep])

  if (!previousStep) return null

  return (
    <Button className={styles.actionButtons} onClick={onPreviousStepClick}>
      <Trans>Previous</Trans>
    </Button>
  )
}

export default PreviousStepButton

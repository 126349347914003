import React from 'react'
import { useParams } from 'react-router'

import ContractPageComponent from 'apps/Billing/components/ContractPage'

const ContractPage = () => {
  const { contractId } = useParams()

  return <ContractPageComponent contractId={contractId} />
}

export default ContractPage

import React from 'react'
import { useTranslation } from 'react-i18next'

import moment from 'moment'

import InfoRow from './InfoRow'

const CreatedAtInfo = ({ profile }) => {
  const { t, i18n } = useTranslation()
  const createdAt = moment(profile.created_at).locale(i18n.language)

  return (
    <InfoRow
      main={t('Profile created {{fromDate}}', {
        fromDate: createdAt.fromNow()
      })}
      secondary={`${createdAt.format('LL')} (${t('Via {{sourceMethod}}', {
        sourceMethod: profile.source_method
      })}${profile.source_category ? ': ' + profile.source_category : ''})`}
    />
  )
}

export default CreatedAtInfo

import React from 'react'

import PropTypes from 'prop-types'

import { DropdownInput } from '@fullfabric/alma-mater'

import { useAppData } from 'shared/contexts/AppData'

import classNames from 'classnames'
import styles from './styles.module.scss'

const LOCALE_MAP = {
  EN: 'EN',
  ES: 'ES',
  FR: 'FR',
  DE: 'DE',
  NL: 'NL',
  SV: 'SV',
  IT: 'IT',
  DA: 'DA'
}

const toLocaleObj = (locale) => ({
  locale,
  language: LOCALE_MAP[locale.slice(0, 2).toUpperCase()]
})

const LocaleSelector = ({ className, topPosition }) => {
  const appData = useAppData()
  const locales =
    (appData._settings && appData._settings.hash.locales.map(toLocaleObj)) || []

  const currentLanguage = toLocaleObj(window.locale || 'en-GB')

  if (!locales || locales.length <= 1) return null

  const options = locales.map((loc) => ({
    label: loc.language,
    value: loc.locale
  }))

  return (
    <div
      className={classNames(styles.localeSelectorRow, className)}
      role='complementary'
      aria-label='locale selection'
    >
      <DropdownInput
        id='locales'
        name='locales'
        aria-label='locale dropdown'
        className={styles.localeSelector}
        onChange={(locale) => {
          const days = 365
          const date = new Date()
          date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)

          document.cookie = `locale=${locale}; expires= ${date.toGMTString()}; path=/`
          document.location.reload()
        }}
        value={currentLanguage.locale}
        options={options}
      />
    </div>
  )
}

LocaleSelector.defaultProps = {
  topPosition: 0
}

LocaleSelector.propTypes = {
  className: PropTypes.string,
  topPosition: PropTypes.number
}

export default LocaleSelector

.react-app ._layout_w9hkc_1 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
}

.react-app ._infoContainer_w9hkc_9 {
  flex: 1;
}
/* eslint-disable camelcase */
const replaceStudyPlanPath = (studyPlan, updatedPath) => {
  const pathIndex = studyPlan.paths.findIndex(
    (path) => path.id === updatedPath.id
  )

  const default_path =
    updatedPath.id === studyPlan.default_path.id
      ? updatedPath
      : studyPlan.default_path

  return {
    ...studyPlan,
    default_path,
    paths: [
      ...studyPlan.paths.slice(0, pathIndex),
      updatedPath,
      ...studyPlan.paths.slice(pathIndex + 1)
    ]
  }
}

export default replaceStudyPlanPath

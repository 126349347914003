.react-app ._customGrid_1s7ch_1 {
  --hub-outer-margin: 16px;
  --hub-gutter-width: 16px;
  --hub-half-gutter-width: 12px;
}

.react-app ._customGrid_1s7ch_1._desktop_1s7ch_7 {
  --hub-outer-margin: var(--space-big);
  --hub-gutter-width: var(--space-big);
  --hub-half-gutter-width: 20px;
}

.react-app ._customGrid_1s7ch_1._xlDesktop_1s7ch_13 {
  --hub-outer-margin: var(--space-huge);
  --hub-gutter-width: var(--space-huge);
  --hub-half-gutter-width: 40px;
}
import React from 'react'

import { Col, Container, Row } from '@fullfabric/alma-mater'

import { useAppData } from 'shared/contexts/AppData'

import LocaleSelector from './LocaleSelector'

import cx from 'classnames'
import styles from './styles.module.scss'

const PolicyManagementPageLayout = ({ children }) => {
  const { logo_url: logoUrl, background_url: backgroundImageUrl } = useAppData()

  const [scrollTop, setScrollTop] = React.useState(0)

  return (
    <Container
      fluid
      className={cx(styles.appContainer, styles.scrollContainer)}
      onScroll={({ currentTarget: { scrollTop } }) => setScrollTop(scrollTop)}
    >
      <Row className={styles.appContent}>
        <Col xs={0} md={2} className={styles.offsetColumn} />
        <Col xs={12} md={5} className={cx(styles.column, styles.contentColumn)}>
          <div>
            <a href='/' className={styles.logoLink}>
              <img className={styles.logoImage} src={logoUrl} alt='logo' />
            </a>

            {children}
          </div>
        </Col>

        <Col xs={0} md={2} className={cx(styles.column, styles.localeColumn)}>
          <LocaleSelector top={scrollTop} className={styles.localeSelector} />
        </Col>

        <Col xs={0} md={3} className={styles.imageColumn}>
          {backgroundImageUrl ? (
            <div
              role='img'
              aria-label='Background image'
              className={styles.backgroundImage}
              style={{ backgroundImage: `url(${backgroundImageUrl})` }}
            />
          ) : null}
        </Col>
      </Row>
    </Container>
  )
}

export default PolicyManagementPageLayout

import {
  SUPPORTED_AUDIO_EXTENSIONS,
  SUPPORTED_IMG_EXTENSIONS,
  SUPPORTED_VIDEO_EXTENSIONS
} from 'apps/Evaluations/utils/constants'
import ApplicationPdfViewer from '../components/ApplicationPdfViewer'
import AudioViewer from '../components/AudioViewer'
import ImageViewer from '../components/ImageViewer'
import VideoViewer from '../components/VideoViewer'

const getViewerComponentForFile = (fileName) => {
  if (!fileName) return ApplicationPdfViewer

  const extension = fileName?.split('.').pop()

  if (SUPPORTED_IMG_EXTENSIONS.includes(extension)) return ImageViewer
  if (SUPPORTED_AUDIO_EXTENSIONS.includes(extension)) return AudioViewer
  if (SUPPORTED_VIDEO_EXTENSIONS.includes(extension)) return VideoViewer
  if (extension === 'pdf') return ApplicationPdfViewer

  return ApplicationPdfViewer
}

export default getViewerComponentForFile

import React from 'react'

import Tippy from '@tippyjs/react'
import { BoxShadow, Text } from '@fullfabric/alma-mater'

import UploadThumbnail from './UploadThumbnail'

import cx from 'classnames'
import styles from './styles.module.scss'

const MultiUploadSelector = ({
  viewOption,
  selectedUploadIndex,
  setSelectedUploadIndex
}) => {
  if (viewOption?.uploads?.length <= 1) return null

  return (
    <BoxShadow type='2' rounded className={styles.multiUploadsWrapper}>
      {viewOption?.uploads.map((upload, idx) => {
        return (
          <Tippy
            key={upload?.filename}
            content={
              <Text fontColor='text-base-lightest'>{upload.filename}</Text>
            }
          >
            <div
              role='button'
              aria-label={`View file ${upload.filename}`}
              className={cx(
                'shadow-2',
                styles.thumbnailContainer,
                selectedUploadIndex === idx && styles.selected
              )}
              onClick={() => {
                setSelectedUploadIndex(idx)
              }}
            >
              <UploadThumbnail upload={upload} />
            </div>
          </Tippy>
        )
      })}
    </BoxShadow>
  )
}

export default MultiUploadSelector

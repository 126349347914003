import { utils } from '@fullfabric/public-api'

export default async function setRoleState(
  profileId,
  roleId,
  { state, substate }
) {
  const response = await fetch(
    `/api/profiles/${profileId}/roles/${roleId}/set_state`,
    {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'same-origin',
      body: JSON.stringify({ state_and_substate: `${state}::${substate}` })
    }
  )

  return await utils.checkResponse(response)
}

import React, { useState } from 'react'

import BaseModal from './BaseModal'
import DuplicateModal from './DuplicateModal'

export default function StudyPlanCreationModal() {
  const [duplicateModalFormVisible, setDuplicateModalFormVisible] =
    useState(false)

  if (duplicateModalFormVisible) {
    return (
      <DuplicateModal
        handleCancelDuplicateStudyPlanClick={() =>
          setDuplicateModalFormVisible(false)
        }
      />
    )
  } else {
    return (
      <BaseModal
        handleDuplicateStudyPlanClick={() => setDuplicateModalFormVisible(true)}
      />
    )
  }
}

/* eslint-disable camelcase */

import React, { useLayoutEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

const TAB_MARGIN = 32
const BUTTON_PLACEHOLDER_WIDTH = 131
const TAB_CIRCLE_WIDTH = 8
const MARGIN_TO_CIRCLE = 8

const getTabCircleX = (tabX, tabWidth) => tabX + tabWidth + MARGIN_TO_CIRCLE

const getTabWidth = (tabRef) =>
  tabRef?.current?.getBBox ? tabRef.current.getBBox().width : 0

const getTabX = (previousTabs) =>
  previousTabs.reduce((accX, tabRef) => {
    const tabWidth = getTabWidth(tabRef)

    return tabWidth === 0
      ? accX
      : accX + tabWidth + MARGIN_TO_CIRCLE + TAB_CIRCLE_WIDTH + TAB_MARGIN
  }, 0)

const getTabBarOrigin = (buttonPlaceholderX) => {
  return (900 - (buttonPlaceholderX + BUTTON_PLACEHOLDER_WIDTH)) / 2
}

const textCommonProps = {
  y: '38',
  fontSize: '14',
  fill: 'var(--ff-color-text-base-darkest)'
}

const circleCommonProps = {
  width: '8',
  height: '8',
  y: '29',
  fill: 'var(--ff-color-green-300)',
  rx: '4'
}

const TabBar = ({ enable_experience, references_enabled, payment_enabled }) => {
  const { t } = useTranslation()

  const personalTabRef = useRef()
  const applicationTabRef = useRef()
  const experienceTabRef = useRef()
  const referencesTabRef = useRef()
  const paymentTabRef = useRef()

  const getPreviousTabs = (tabNumber) => {
    const orderedTabs = [
      personalTabRef,
      applicationTabRef,
      experienceTabRef,
      referencesTabRef,
      paymentTabRef
    ]

    return orderedTabs.slice(0, tabNumber)
  }

  const [tabBarOrigin, setTabBarOrigin] = useState(0)
  const [experienceTabX, setExperienceTabX] = useState(0)
  const [referencesTabX, setReferencesTabX] = useState(0)
  const [paymentTabX, setPaymentTabX] = useState(0)
  const [buttonPlaceholderX, setButtonPlaceholderX] = useState(0)

  useLayoutEffect(() => {
    setExperienceTabX(getTabX(getPreviousTabs(2)))
    setReferencesTabX(getTabX(getPreviousTabs(3)))
    setPaymentTabX(getTabX(getPreviousTabs(4)))
    setButtonPlaceholderX(getTabX(getPreviousTabs(5)))
  }, [enable_experience, references_enabled, payment_enabled])

  useLayoutEffect(() => {
    setTabBarOrigin(getTabBarOrigin(buttonPlaceholderX))
  }, [buttonPlaceholderX])

  return (
    <svg x={tabBarOrigin} y='0'>
      <text {...textCommonProps} x={getTabX([])} ref={personalTabRef}>
        {t('Personal')}
      </text>
      <rect
        width='8'
        height='8'
        x={getTabCircleX(getTabX([]), getTabWidth(personalTabRef))}
        y='29'
        fill='var(--ff-color-green-300)'
        rx='4'
      />
      <text
        {...textCommonProps}
        x={getTabX(getPreviousTabs(1))}
        ref={applicationTabRef}
      >
        {t('Application')}
      </text>
      <rect
        {...circleCommonProps}
        x={getTabCircleX(
          getTabX(getPreviousTabs(1)),
          getTabWidth(applicationTabRef)
        )}
      />
      {enable_experience && (
        <>
          <text {...textCommonProps} x={experienceTabX} ref={experienceTabRef}>
            {t('Experience')}
          </text>
          <rect
            {...circleCommonProps}
            x={getTabCircleX(experienceTabX, getTabWidth(experienceTabRef))}
          />
        </>
      )}
      {references_enabled && (
        <>
          <text {...textCommonProps} x={referencesTabX} ref={referencesTabRef}>
            {t('References')}
          </text>
          <rect
            {...circleCommonProps}
            x={getTabCircleX(referencesTabX, getTabWidth(referencesTabRef))}
          />
        </>
      )}
      {payment_enabled && (
        <>
          <text {...textCommonProps} x={paymentTabX} ref={paymentTabRef}>
            {t('Payments')}
          </text>
          <rect
            {...circleCommonProps}
            x={getTabCircleX(paymentTabX, getTabWidth(paymentTabRef))}
          />
        </>
      )}
      <rect
        width='131'
        height='32'
        x={buttonPlaceholderX}
        y='16'
        fill='#DCEEFB'
        rx='4'
      ></rect>
    </svg>
  )
}

export default TabBar

.react-app ._name_1ux5e_1 {
  text-overflow: ellipsis;
  white-space: nowrap;
  flex-grow: 1;
  overflow: hidden;
  max-width: 100%;
}

.react-app ._row_1ux5e_9 {
  display: flex;
  width: 100%;
  gap: 8px;
  align-items: center;
}

.react-app ._actions_1ux5e_16 {
  display: flex;
  gap: 8px;
}

.react-app ._rowWithError_1ux5e_21 {
  flex-direction: column;
}
import assert from 'assert'

import { utils } from '@fullfabric/public-api'

const updateCourse = async ({ courseId, data }) => {
  assert.ok(
    data instanceof FormData,
    'formData must be provided and be a FormData instance'
  )

  const response = await fetch(`/api/courses/${courseId}`, {
    credentials: 'same-origin',
    method: 'PATCH',
    body: data
  })

  return await utils.checkResponse(response)
}

export default updateCourse

import React from 'react'
import { Trans } from 'react-i18next'

import { CheckboxInput, RadioInput, Text } from '@fullfabric/alma-mater'

import { useLocale } from 'shared/contexts/Locale'
import getLocalizedText from 'shared/utils/getLocalizedText'

import { useSelectedData } from 'apps/Imports/contexts/selected-data'
import { useDataProcessingPolicy } from 'apps/Imports/hooks/useDataProcessingPolicy'

import styles from './communication-policy-selection/styles.module.scss'

const CommunicationPolicySection = ({ communicationPolicyVersion }) => {
  const {
    data: { consentData },
    onDataChanged
  } = useSelectedData()
  const dataProcessingConsent = useDataProcessingPolicy()
  const currentLocale = useLocale()
  const communicationPolicy = communicationPolicyVersion.policy
  const communicationPolicyVersionId = communicationPolicyVersion.id
  const onConsentDataChanged = React.useCallback(
    (data) => {
      const currentComData = consentData[communicationPolicyVersion.id] || {}
      onDataChanged('consentData', {
        ...consentData,
        [communicationPolicyVersion.id]: { ...currentComData, ...data }
      })
    },
    [onDataChanged, consentData, communicationPolicyVersion.id]
  )

  const currentComData = consentData[communicationPolicyVersion.id] || {}
  const communicationChannels = communicationPolicyVersion?.channels || {}
  const communicationAgreement = currentComData.communicationAgreement
  const communicationOptions = currentComData.communicationOptions || {}
  const disabled =
    dataProcessingConsent?.published && !consentData.dataProcessingConsent

  const disabledTextColor = disabled ? 'text-base-darker' : 'text-base-darkest'
  const channelsDisabled = disabled || communicationAgreement !== 'accepted'
  const channelsDisabledTextColor = channelsDisabled
    ? 'text-base-darker'
    : 'text-base-darkest'

  if (!communicationPolicyVersion.published) return null
  if (consentData.consentType !== 'same') return null
  const policyName = getLocalizedText(
    communicationPolicy.locales,
    currentLocale,
    'html'
  )

  return (
    <>
      <Text
        type='h4'
        marginBottom='small'
        marginTop='more'
        fontColor={disabledTextColor}
      >
        {policyName}
      </Text>
      <Text marginBottom='small' fontColor={disabledTextColor}>
        <Trans>Indicate these person’s consent for being contacted</Trans>
      </Text>

      <RadioInput
        name='communicationOption'
        id={`comm-noNewAgreement-${communicationPolicyVersionId}`}
        key={`comm-noNewAgreementRadio-${communicationPolicyVersionId}`}
        disabled={disabled}
        className={styles.communicationOptionsRadiobuttons}
        checked={communicationAgreement === 'no'}
        onChange={() => onConsentDataChanged({ communicationAgreement: 'no' })}
        label={
          <Text fontColor={disabledTextColor}>
            <Trans>No new agreement made</Trans>
          </Text>
        }
      />

      <RadioInput
        name='communicationOption'
        id={`comm-doesNotWant-${communicationPolicyVersionId}`}
        key={`comm-doesNotWantRadio-${communicationPolicyVersionId}`}
        disabled={disabled}
        className={styles.communicationOptionsRadiobuttons}
        checked={communicationAgreement === 'doesNotWant'}
        onChange={() =>
          onConsentDataChanged({ communicationAgreement: 'doesNotWant' })
        }
        label={
          <Text fontColor={disabledTextColor}>
            <Trans>Does not want to receive communications</Trans>
          </Text>
        }
      />

      <RadioInput
        name='communicationOption'
        id={`comm-wantsCommunication-${communicationPolicyVersionId}`}
        key={`comm-wantsCommunicationRadio-${communicationPolicyVersionId}`}
        disabled={disabled}
        className={styles.communicationOptionsRadiobuttons}
        onChange={() =>
          onConsentDataChanged({ communicationAgreement: 'accepted' })
        }
        checked={communicationAgreement === 'accepted'}
        label={
          <Text fontColor={disabledTextColor}>
            <Trans>Would like to be contacted by:</Trans>
          </Text>
        }
      />
      {Object.keys(communicationChannels).map((channel) => {
        return communicationChannels[channel] ? (
          <CheckboxInput
            id={`communication-${communicationPolicyVersionId}-${channel}`}
            key={`communication-${communicationPolicyVersionId}-${channel}`}
            disabled={channelsDisabled}
            checked={communicationOptions[channel]}
            className={styles.communicationChannelCheckbox}
            onChange={(checked) =>
              onConsentDataChanged({
                communicationOptions: {
                  ...communicationOptions,
                  [channel]: checked
                }
              })
            }
            label={
              <Text fontColor={channelsDisabledTextColor}>
                {channel === 'email' ? <Trans>Email</Trans> : null}
                {channel === 'phone' ? <Trans>Phone</Trans> : null}
                {channel === 'sms' ? <Trans>SMS</Trans> : null}
                {channel === 'mail' ? <Trans>Mail</Trans> : null}
              </Text>
            }
          />
        ) : null
      })}
    </>
  )
}

export default CommunicationPolicySection

import React from 'react'
import { Trans } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'

import { Button } from '@fullfabric/alma-mater'

import api from 'apps/ContentPages/api'
import isConditionalOffer from 'apps/ContentPages/services/offers/is-conditional-offer'
import { redirectToOffer } from '../utils'

export const submitAcceptance = async ({ offerId }) => {
  await api.respondToOffer(offerId, 'accept')
}

export const submitAcceptanceWithCondition = async ({ offerId }) => {
  await api.respondToOffer(offerId, 'accept_conditionally')
}

export const submitDecline = async ({ offerId }) => {
  await api.respondToOffer(offerId, 'decline')
}

const AcceptanceButtons = ({ offer }) => {
  const queryClient = useQueryClient()

  const { mutate: acceptOffer } = useMutation(submitAcceptance, {
    onSuccess: () => queryClient.invalidateQueries('get-offers')
  })

  const { mutate: conditionallyAcceptOffer } = useMutation(
    submitAcceptanceWithCondition,
    { onSuccess: async () => redirectToOffer(offer) }
  )

  const { mutate: declineOffer } = useMutation(submitDecline, {
    onSuccess: () => queryClient.invalidateQueries('get-offers')
  })

  const onClickAccept = isConditionalOffer(offer)
    ? conditionallyAcceptOffer
    : acceptOffer

  return (
    <>
      <Button
        data-testid='accept-offer-button'
        primary
        onClick={() => {
          onClickAccept({ offerId: offer.id })
        }}
      >
        <Trans>Accept</Trans>
      </Button>
      <Button
        data-testid='decline-offer-button'
        marginLeft='small'
        onClick={() => declineOffer({ offerId: offer.id })}
      >
        <Trans>Decline</Trans>
      </Button>
    </>
  )
}

export default AcceptanceButtons

import React from 'react'
import { useTranslation } from 'react-i18next'

import { Text } from '@fullfabric/alma-mater'

import { useMainIntakeIndex } from '../ClassOptionsContext'
import matchDataKeyToIntake from '../utils/matchDataKeyToIntake'

import classNames from 'classnames'
import styles from './styles.module.scss'

const getLegendLabel = (intake, value) => intake?.name || value

const INTAKE_DATA = 0

const LegendContent = ({
  payload,
  intakes = [],
  isBarChartLegend,
  distanceFromCloseToStartDate
}) => {
  const { t } = useTranslation()
  const mainIntakeIndex = useMainIntakeIndex()
  const intakesToDisplayWithEntry = payload
    .map((entry) => [
      intakes.find((intake) => matchDataKeyToIntake(entry.value, intake)),
      entry
    ])
    .sort((a, b) => b[INTAKE_DATA]?.opens_on - a[INTAKE_DATA]?.opens_on)

  return (
    <ul
      className={classNames([
        styles.legendContainer,
        isBarChartLegend && styles.barChartLegend
      ])}
    >
      {intakesToDisplayWithEntry.map(([intake, entry], index) => (
        <li key={index} className={styles.itemContainer}>
          {index !== mainIntakeIndex && !isBarChartLegend ? (
            <>
              <span
                style={{ backgroundColor: entry.color }}
                className={styles.comparisonItemIcon}
              />
              <span
                style={{ backgroundColor: entry.color }}
                className={classNames([styles.comparisonItemIcon, styles.last])}
              />
            </>
          ) : (
            <span
              style={{
                backgroundColor: entry.color
              }}
              className={styles.itemIcon}
            />
          )}
          <Text>{getLegendLabel(intake, entry.value)}</Text>
        </li>
      ))}
      {distanceFromCloseToStartDate &&
      intakesToDisplayWithEntry &&
      intakesToDisplayWithEntry[mainIntakeIndex] ? (
        <li className={styles.itemContainer}>
          <span
            style={{ backgroundColor: '#653CAD' }}
            className={styles.circleIcon}
          />
          <Text fontColor='text-info'>
            {t('Application close date ({{intakeName}})', {
              intakeName:
                intakesToDisplayWithEntry[mainIntakeIndex][INTAKE_DATA].name
            })}
          </Text>
        </li>
      ) : null}
    </ul>
  )
}

export default LegendContent

{
  "Load {{pageSize}} ": "Load {{pageSize}} ",
  "of {{remainingSize}}": "of {{remainingSize}}",
  "Empty list": "Empty list",
  "{{count}} Columns": "{{count}} Column",
  "{{count}} Columns_plural": "{{count}} Columns",
  "General": "General",
  "Cookie policy": "Cookie policy",
  "Click here to manage your cookie preferences and for further information": "Click here to manage your cookie preferences and for further information"
}

export const baseTemplateGrapejs = `
  <mjml>
      <mj-body>
          <mj-section>
          <mj-column>
              <mj-image src="https://assets.unlayer.com/projects/192955/1705058518147-Logo.png">
              </mj-image>
          </mj-column>
          </mj-section>
          <mj-section>
          <mj-column width="100%" padding-top="10px" padding-right="10px" padding-left="10px" padding-bottom="10px">
              <span style="font-size:24px;font-weight:700;text-align:left;">Design your email</span>
          </mj-column>
          <mj-column width="100%">
              <mj-image src="https://assets.unlayer.com/projects/192955/1705058188949-Placeholder@2x.png">
              </mj-image>
              <mj-text>
              <p style="line-height:150%;">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
              </p>
              </mj-text>
          </mj-column>
          </mj-section>
          <mj-section>
          <mj-column>
              <mj-button align="left" background-color="#0a8ccd">
              <span style="line-height:19.2px;">Add button text</span>
              </mj-button>
          </mj-column>
          </mj-section>
      </mj-body>
  </mjml>
`

.react-app ._headerItem_zbsh4_1 {
  padding-bottom: var(--space-small);
  background-color: transparent;
}

.react-app ._alignRight_zbsh4_6 {
  text-align: right;
}

.react-app ._minimizeWidth_zbsh4_10 {
  width: 0;
}
.react-app ._minimizeWidth_zbsh4_10 * {
  white-space: nowrap;
}

.react-app ._widest_zbsh4_17 {
  width: 30%;
}
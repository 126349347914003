import React from 'react'

import { Card, Loading, Row, Text } from '@fullfabric/alma-mater'

import ComparisonInfoRow from './ComparisonInfoRow'
import MainInfoRow from './MainInfoRow'
import Placeholder from './Placeholder'

import styles from './styles.module.scss'

export function isAllInfoEmpty(mainInfo, comparisonInfo) {
  return (
    !mainInfo?.value &&
    (!comparisonInfo || comparisonInfo.every((c) => !c?.value))
  )
}

const ComparisonWidget = ({
  title,
  description,
  isLoading,
  error,
  mainInfo,
  comparisonInfo = []
}) => {
  const isInvalid = !!error || isAllInfoEmpty(mainInfo, comparisonInfo)

  return (
    <Card>
      <div className={styles.titleRow}>
        <Text type='h4' fontColor='text-base-darkest' className={styles.title}>
          {title}
        </Text>
        <Text
          type='f5'
          fontColor='text-base-darker'
          className={styles.description}
          marginLeft='tiny'
        >
          {description}
        </Text>
      </div>
      <>
        {isLoading ? (
          <Row nogutter justify='center' className={styles.loadingContainer}>
            <Loading />
          </Row>
        ) : isInvalid ? (
          <Row nogutter justify='center' className={styles.loadingContainer}>
            <Placeholder />
          </Row>
        ) : (
          <>
            <div data-testid='mainInfo'>
              <MainInfoRow label={mainInfo?.label} value={mainInfo?.value} />
            </div>
            {comparisonInfo.map((comparisonSample, index) => (
              <div data-testid='comparisonInfo' key={index}>
                <ComparisonInfoRow
                  label={comparisonSample?.label}
                  value={comparisonSample?.value}
                  referenceValue={mainInfo?.value}
                />
              </div>
            ))}
          </>
        )}
      </>
    </Card>
  )
}

export default ComparisonWidget

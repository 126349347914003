import React from 'react'
import { useTranslation } from 'react-i18next'

import { Dropdown, IconButton, MoreIcon } from '@fullfabric/alma-mater'

import { useEvaluation } from 'apps/Evaluations/context/EvaluationData'
import ShortcutIcons from './ShortcutIcons'

import styles from './styles.module.scss'

const EvaluationActionListButton = ({
  onClickDeleteEvaluation,
  onClickReassignEvaluation,
  showApplication,
  onShowApplicationClick
}) => {
  const { t } = useTranslation()
  const { hasChangePermission } = useEvaluation()

  if (!hasChangePermission) {
    return null
  }

  return (
    <>
      <Dropdown>
        <Dropdown.Toggle>
          <IconButton small marginRight='small'>
            <MoreIcon />
          </IconButton>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Menu.CheckableOption
            id='show_application'
            checked={showApplication}
            className={styles.showApplicOption}
            onChange={(_, isChecked) => {
              onShowApplicationClick(isChecked)
            }}
          >
            <span className={styles.content}>
              {t('Show application')}

              <span className={styles.shortcut}>
                <ShortcutIcons />
              </span>
            </span>
          </Dropdown.Menu.CheckableOption>

          <Dropdown.Menu.Option
            aria-labelledby='dropdown-option-reassign-evaluation'
            onClick={() => onClickReassignEvaluation()}
          >
            <span id='dropdown-option-reassign-evaluation'>
              {t('Reassign evaluation')}
            </span>
          </Dropdown.Menu.Option>
          <Dropdown.Menu.Option
            aria-labelledby='dropdown-option-delete-evaluation'
            onClick={() => onClickDeleteEvaluation()}
          >
            <span id='dropdown-option-delete-evaluation'>
              {t('Delete evaluation')}
            </span>
          </Dropdown.Menu.Option>
        </Dropdown.Menu>
      </Dropdown>
    </>
  )
}

export default EvaluationActionListButton

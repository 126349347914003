import React from 'react'
import { Trans } from 'react-i18next'

import {
  AddIcon,
  Alert,
  AnnotatedLayout,
  ArrowLeftIcon,
  Button,
  Link,
  PageHead
} from '@fullfabric/alma-mater'
import { useSettings } from '@fullfabric/authorization-officer'

import FullScreenLoading from 'shared/components/FullScreenLoading'

import ArchiveCourseModal from 'apps/Courses/containers/ArchiveCourseModal'
import CourseEditDisplayAcademicDetails from 'apps/Courses/containers/CourseEditDisplayAcademicDetails'
import CourseEditDisplayBasic from 'apps/Courses/containers/CourseEditDisplayBasic'
import CourseEditDisplayCapacity from 'apps/Courses/containers/CourseEditDisplayCapacity'
import CourseEditDisplayDates from 'apps/Courses/containers/CourseEditDisplayDates'
import CourseEditDisplaySchema from 'apps/Courses/containers/CourseEditDisplaySchema'
import CourseEditDisplayStatus from 'apps/Courses/containers/CourseEditDisplayStatus'
import CourseEditFormAcademicDetails from 'apps/Courses/containers/CourseEditFormAcademicDetails'
import CourseEditFormBasic from 'apps/Courses/containers/CourseEditFormBasic'
import CourseEditFormCapacity from 'apps/Courses/containers/CourseEditFormCapacity'
import CourseEditFormDates from 'apps/Courses/containers/CourseEditFormDates'
import CourseEditFormSchema from 'apps/Courses/containers/CourseEditFormSchema'
import CourseEditFormStatus from 'apps/Courses/containers/CourseEditFormStatus'
import CourseEditSuccessPopup from 'apps/Courses/containers/CourseEditSuccessPopup'
import DeleteCourseModal from 'apps/Courses/containers/DeleteCourseModal'
import {
  EDIT_CATALOG_CONTENT_ROUTE,
  NEW_COURSE_ROUTE
} from 'apps/Courses/utils/routeConstants'
import CourseAcademicDetailsSection from '../CourseSectionAcademicDetails'
import CourseBasicSection from '../CourseSectionBasic'
import CourseSectionCapacity from '../CourseSectionCapacity'
import CourseDatesSection from '../CourseSectionDates'
import CourseSchemaSection from '../CourseSectionSchema'
import CourseStatusSection from '../CourseSectionStatus'

import styles from './styles.module.scss'

const schemaHasFields = (schema) =>
  schema &&
  schema.sections.length > 0 &&
  schema.sections.flatMap((section) => section.fields).length > 0

const EditCoursePage = ({
  course,
  schema,
  loading,
  newlyCreated,
  isBasicSectionEditing,
  isDatesSectionEditing,
  isStatusSectionEditing,
  isAcademicDetailsSectionEditing,
  isCapacitySectionEditing,
  isSchemaSectionEditing,
  getCourse,
  getCourseSchema,
  onBackClick,
  onDeleteClick,
  onArchiveClick
}) => {
  React.useEffect(() => {
    getCourse()
    getCourseSchema()
  }, [getCourse, getCourseSchema])

  const settings = useSettings()

  const isProductCatalogEnabled =
    settings?.hash?.modules?.content?.features?.product_catalog?.enable

  const pageHead = (
    <PageHead
      className={styles.pageHead}
      title={<Trans>{{ courseName: course?.full_name }}</Trans>}
      breadcrumbs={[
        {
          label: <Trans>Back</Trans>,
          icon: ArrowLeftIcon,
          onClick: onBackClick
        }
      ]}
      action={
        isProductCatalogEnabled ? (
          <Link
            href={EDIT_CATALOG_CONTENT_ROUTE.replace(':id', course?.id)}
            target='_parent'
          >
            <Button size='small'>
              <Trans>Edit product catalog content</Trans>
            </Button>
          </Link>
        ) : null
      }
    />
  )

  if (loading) return <FullScreenLoading />

  return (
    <>
      <AnnotatedLayout
        pageHead={pageHead}
        className={styles.layoutContainer}
        alert={
          <>
            {newlyCreated && (
              <Alert
                success
                role='alert'
                title={
                  <Trans>
                    Course "{{ courseName: course.full_name }}" created.
                    <Link
                      to={NEW_COURSE_ROUTE}
                      className={styles.createAnotherButton}
                    >
                      Add another
                      <AddIcon />
                    </Link>
                  </Trans>
                }
                message=''
              />
            )}

            {course.archived && (
              <Alert
                type='info'
                role='alert'
                title={<Trans>Course archived.</Trans>}
              />
            )}
          </>
        }
      >
        <CourseBasicSection>
          {isBasicSectionEditing ? (
            <CourseEditFormBasic />
          ) : (
            <CourseEditDisplayBasic />
          )}
        </CourseBasicSection>

        <CourseDatesSection>
          {isDatesSectionEditing ? (
            <CourseEditFormDates />
          ) : (
            <CourseEditDisplayDates />
          )}
        </CourseDatesSection>

        <CourseStatusSection>
          {isStatusSectionEditing ? (
            <CourseEditFormStatus />
          ) : (
            <CourseEditDisplayStatus />
          )}
        </CourseStatusSection>

        <CourseAcademicDetailsSection>
          {isAcademicDetailsSectionEditing ? (
            <CourseEditFormAcademicDetails />
          ) : (
            <CourseEditDisplayAcademicDetails />
          )}
        </CourseAcademicDetailsSection>

        <CourseSectionCapacity>
          {isCapacitySectionEditing ? (
            <CourseEditFormCapacity />
          ) : (
            <CourseEditDisplayCapacity />
          )}
        </CourseSectionCapacity>

        {schema && schemaHasFields(schema) && (
          <CourseSchemaSection>
            {isSchemaSectionEditing ? (
              <CourseEditFormSchema />
            ) : (
              <CourseEditDisplaySchema />
            )}
          </CourseSchemaSection>
        )}

        <div className={styles.footer}>
          <Button
            className='marginRight-small'
            onClick={() => onArchiveClick(course)}
          >
            {course.archived ? (
              <Trans>Unarchive course</Trans>
            ) : (
              <Trans>Archive course</Trans>
            )}
          </Button>

          <Button negative onClick={() => onDeleteClick(course)}>
            <Trans>Delete course</Trans>
          </Button>
        </div>
      </AnnotatedLayout>

      <CourseEditSuccessPopup />

      <DeleteCourseModal />

      <ArchiveCourseModal />
    </>
  )
}

export default EditCoursePage

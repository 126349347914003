.react-app ._editorContainer_1iys3_1 {
  width: 100%;
  height: calc(100dvh - 73px);
  overflow: hidden;
}

.react-app ._loadingContainer_1iys3_7 {
  height: 100%;
}

.react-app ._iframe_1iys3_11 {
  width: 100%;
  height: 100%;
  border: none;
  display: block;
}

.react-app ._hideEditor_1iys3_18 {
  display: none;
}
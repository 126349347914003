import React from 'react'

import { ErrorBigIcon, Text } from '@fullfabric/alma-mater'

import classNames from 'classnames'
import styles from './styles.module.scss'

const FullScreenError = ({ style, className, errorMessage }) => {
  return (
    <div style={style} className={classNames(styles.container, className)}>
      <ErrorBigIcon
        color='text-alert'
        width={32}
        height={32}
        title={errorMessage || 'Fatal error'}
      />
      {errorMessage && (
        <Text fontColor='text-alert' marginTop='less'>
          {errorMessage}
        </Text>
      )}
    </div>
  )
}

export default FullScreenError

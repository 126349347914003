{
  "Skip to main content": "Passa al contenuto principale",
  "In Progress": "In Esecuzione",
  "Submitted": "Inviato",
  "You haven't started or submitted an application yet": "Non hai ancora iniziato o inviato una candidatura",
  "Name": "Nome",
  "Application": "Candidatura",
  "Registration": "Registrazione",
  "This event is now full.": "Questo evento adesso è al completo.",
  "This event has now passed.": "Questo evento è ormai scaduto.",
  "Registrations are closed.": "Le registrazioni adesso sono chiuse.",
  "Register": "Registrati",
  "Thank you for filling in the form.": "Grazie per aver compilato il modulo.",
  "You will receive an email shortly.": "Riceverai un'email a breve.",
  "Email not verified": "Email non verificata",
  "Please check your email.": "Per favore, controlla la tua email.",
  "Email sent": "Email inviata",
  "Email failed!": "Email non inviata!",
  "Resend verification email": "Invia di nuovo email di verifica",
  "Change to English": "Passa all'italiano",
  "Login": "Login",
  "Home": "Home",
  "My Applications": "Le Mie Candidature",
  "My Registrations": "Le Mie Registrazioni",
  "Other Content": "Altri Contenuti",
  "Menu": "Menu",
  "Close navigation": "Chiudi navigazione",
  "Main Menu": "Menu Principale",
  "Close": "Chiudi ",
  "Applications": "Candidature",
  "No applications available": "Nessuna candidatura disponibile",
  "To access registrations please register or login.": "Per accedere alle registrazioni per favore registrati o accedi",
  "To access applications please register or login.": "Per accedere alle candidature per favore registrati o accedi",
  "You haven't started or submitted a registration yet": "Non hai ancora iniziato o inviato una registrazione",
  "Registrations": "Registrazioni",
  "No registrations available": "Nessuna registrazione disponibile",
  "Start registration": "Inizia registrazione ",
  "Start application": "Inizia candidatura",
  "Start another registration": "Inizia un'altra registrazione",
  "Start another application": "Inizia un'altra candidatura",
  "Continue registration": "Continua registrazione",
  "Continue application": "Continua candidatura ",
  "today at {{ time }}": "oggi alle {{ time }}",
  "tomorrow at {{ time }}": "domani alle {{ time }}",
  "on {{ date }} at {{ time }}": "il {{ date }} alle {{ time }}",
  "on {{ date }}": "il {{ date }}",
  "Closes {{ dateAndTimeText }}": "Termina {{ dateAndTimeText }}",
  "Opens {{ dateAndTimeText }}": "Si apre {{ dateAndTimeText }}",
  "Registrations are closed": "Le registrazioni sono chiuse",
  "Applications are closed": "Le candidature sono chiuse",
  "Loading": "Caricamento in corso",
  "Choose a programme or subject": "Scegli un programma o un argomento ",
  "This field is required": "Questo campo è obbligatorio",
  "Choose a class or course": "Scegli una lezione o un corso",
  "Choose a campus": "Scegli un campus",
  "Registration options": "Opzioni di registrazioni",
  "Application options": "Opzioni di candidatura",
  "Cancel": "Cancella",
  "Continue": "Continua",
  "Please select an option": "Per favore seleziona un'opzione",
  "You can change your selection later.": "Puoi modificare la tua selezione in seguito",
  "View registration": "Vedi registrazione",
  "View application": "Vedi candidatura",
  "Accept": "Accetta",
  "Decline": "Rifiuta",
  "View Submission": "Vedi presentazione",
  "Conditional offer": "Offerta condizionata",
  "Unconditional offer": "Offerta incondizionata",
  "No events": "Nessun evento",
  "Learn more": "Leggi di più",
  "Upcoming Events": "Eventi in arrivo",
  "Hide map": "Nascondi mappa",
  "Show map": "Mostra mappa",
  "Please fill out the form": "Per favore compila il modulo",
  "Data Protection Regulation": "Regolamento Protezione Dati",
  "Public profile": "Profilo pubblico",
  "Transcripts": "Trascrizione",
  "Calendar": "Calendario",
  "Settings": "Impostazioni",
  "Logout": "Esci",
  "Someone already registered with that email address": "Qualcuno si è già registrato con questo indirizzo email",
  "Email address is invalid": "L'indirizzo email non è valido",
  "Email already in use": "Email già in uso",
  "Participant details": "Dettagli partecipanti",
  "Please enter the details of the participant": "Per favore inserisci i dettagli dei partecipanti",
  "Email address": "Indirizzo email",
  "First name": "Nome",
  "Last name": "Cognome",
  "Offers": "Offerte",
  "{{ name }} (closed)": "{{ name }} (chiuso)",
  "A verification email was sent to {{email}}.": "Una email di verifica è stata inviata a {{email}}.",
  "Payments": "Pagamenti",
  "View your fees and make payments": "Vedi le tue commissini ed effettuata i pagamenti",
  "Paid <1></1>": "Pagato <1></1>",
  "Due in {{timeToDueDate}}": "Dovuto entro {{timeToDueDate}}",
  "Due today": "Scadenza oggi",
  "{{timeFromDueDate}} overdue": "Scaduto dal {{timeFromDueDate}} ",
  "Due Date": "Data di Scadenza",
  "Status": "Stato",
  "Description": "Descrizione",
  "Amount": "Importo",
  "Payment plan for": "Pagamento programmato per",
  "Alerts": "Avvisi",
  "Next payment:": "Prossimo pagamento",
  "See details": "Vedi dettagli ",
  "Discount": "Sconto",
  "Total": "Totale",
  "Paid": "Pagato",
  "Awaiting confirmation": "In attesa di conferma",
  "Due soon": "Presto scaduto",
  "Due / Overdue": "Dovuto / In ritardo",
  "Back to list": "Torna alla lista",
  "Fee details": "Dettagli commissioni",
  "Payment Plans": "Pianificazione pagamenti",
  "Pay now": "Paga adesso",
  "Pay with Flywire": "Paga con Flywire",
  "Flywire billing details": "Dettagli fatture Flywire",
  "Email": "Email",
  "Phone number": "Numero di telefono",
  "Address line 1": "Indirizzo riga 1",
  "Address line 2": "Indirizzo riga 2",
  "Country": "Nazione",
  "City": "Città",
  "Zip code": "CAP",
  "State": "Stato",
  "Awaiting payment confirmation": "In attesa della conferma di pagamento",
  "We are awaiting confirmation of your payment.": "Stai aspettando la conferma del tuo pagamento.",
  "You will be sent an email when your payment has been received successfully. Please select another method if you had problems making your payment.": "Riceverai un'email quando il tuo pagamento sarà ricevuto con successo. Per favore seleziona un altro metodo se riscontri problemi nell'effettuare il pagamento.",
  "There are fields with errors.": "Ci sono campi con errori.",
  "Please correct the fields with errors.": "Per favore correggi i campi con errori.",
  "Missing {{amount}}": "Mancano {{amount}}",
  "Payment": "Pagamento ",
  "There was an error in your submission, please try again in a few seconds. If the issue persists, please contact us.": "C'è stato un errore nel tuo invio. Per favore, riprova tra qualche secondo. Se il problema persiste, ti preghiamo di contattarci.",
  "{{ name }} (not currently available)": "{{name}} (al momento non disponibile)",
  "{{ name }} (not available yet)": "{{name}} (non ancora disponibile)",
  "Registrations are currently closed.": "Le registrazioni sono al momento chiuse.",
  "Registrations will open on {{ registrationsOpenDate }}.": "Le registrazioni si apriranno il {{registrationsOpenDate}}.",
  "Select an option": "Seleziona un'opzione",
  "({{timezone}} time)": "(ora di {{timezone}})",
  "{{dateFullDay}} at {{dateTime}}": "{{dateFullDay}} il {{dateTime}}",
  "Product catalog": "Catalogo dei prodotti",
  "Search for a programme or course": "Cerca un programma o un corso",
  "Start date": "Data d'inizio",
  "End date": "Data di fine",
  "Duration": "Durata",
  "Location": "Sede",
  "Price": "Prezzo",
  "Application deadline": "Termine per la domanda",
  "Registration deadline": "Termine per la registrazione",
  "Apply": "Fai domanda",
  "Details": "Particolari",
  "All": "Tutti",
  "Now until the end of next month": "A partire da oggi fino alla fine del prossimo mese",
  "In 1-6 months": "Tra 1 - 6 mesi",
  "In 6-12 months": "Tra 6 - 12 mesi",
  "In 1 year": "Tra 1 anno",
  "Theme": "Tema",
  "Pay with Stripe": "Paga con Stripe",
  "Stripe payment details": "Dettagli di pagamento a Stripe",
  "Your payment was processed": "Il tuo pagamento è stato elaborato",
  "Edit application": "Modifica domanda",
  "Edit registration": "Modifica registrazione",
  "Have a discount code?": "Hai un codice sconto?",
  "In order to submit you are required to pay a fee.": "Per inviare è necessario pagare una tassa.",
  "All fees paid": "Tutte le tasse pagate",
  "Next step:": "Prossimo passo:",
  "Sign & Submit": "Firma e invia",
  "Submit": "Invia",
  "I accept the privacy policy": "Accetto l'Informativa sulla privacy",
  "Privacy Policy": "Informativa sulla privacy",
  "All fees paid.": "Tutte le tasse pagate.",
  "Next step.": "Prossimo passo.",
  "Finish your application by clicking the button below.": "Completa la tua applicazione cliccando sul pulsante qui sotto.",
  "Finish your registration by clicking the button below.": "Completa la tua registrazione cliccando sul pulsante qui sotto.",
  "Check your inbox": "Controlla la tua casella di posta",
  "A new link has been sent.": "È stato inviato un nuovo link.",
  "Access expired": "Accesso scaduto",
  "Click the button below to receive a new link in your inbox.": "Clicca sul pulsante qui sotto per ricevere un nuovo link nella tua casella di posta.",
  "Send new link": "Invia nuovo link",
  "Payment link is invalid": "Il link di pagamento non è valido",
  "This payment has been canceled or does not exist.": "Questo pagamento è stato annullato o non esiste.",
  "Fee has been paid": "La tariffa è stata pagata",
  "The requester has been notified.": "Il richiedente è stato informato.",
  "On {{date}}, this fee was sent to an external payer.": "In data {{date}}, questa tassa è stata inviata a un pagatore esterno.",
  "Name is invalid": "Il nome non è valido",
  "Manage payer": "Gestisci il pagatore",
  "Send to payer": "Invia al pagatore",
  "Cancel external payer": "Elimina il pagatore esterno",
  "Resend fee details": "Invia nuovamente i dettagli della tassa",
  "Send fee": "Invia tassa",
  "An email containing all the payment details will be sent to the external payer.": "Una email contenente tutti i dettagli del pagamento verrà inviata al pagatore esterno.",
  "Payer's email address": "Indirizzo email del pagatore",
  "Payer's name": "Nome del pagatore",
  "Actions": "Azioni",
  "External payer access was revoked": "L'accesso del pagatore esterno è stato revocato",
  "Fee was sent to an external payer": "La tassa è stata inviata a un pagatore esterno",
  "Fee paid": "Tassa pagata"
}

.react-app ._container_apuh9_1 {
  max-width: 500px;
}

.react-app ._spacing_apuh9_5 {
  margin-bottom: var(--space-more);
}

.react-app ._separator_apuh9_9 {
  display: block;
  width: 100%;
  border: var(--border-base-lighter);
  margin-top: var(--space-more);
  max-width: 600px;
}
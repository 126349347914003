.react-app ._evaluationContainer_glyg7_1 {
  display: flex;
  flex-flow: column;
  height: 100%;
}

.react-app ._content_glyg7_7 {
  height: 100%;
  flex: 1;
  overflow: hidden;
  display: flex;
}

.react-app ._formContainer_glyg7_14 {
  flex: 1;
  overflow-y: auto;
  box-shadow: var(--shadow-3);
  z-index: 10;
}
import { LOCATION_CHANGE } from 'connected-react-router'
import { combineReducers } from 'redux'

import actionTypes from '../../../actions/types'

const data = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.editStatusSectionData:
      return action.payload
    case actionTypes.cancelEditStatusSectionData:
    case LOCATION_CHANGE:
      return {}
    case actionTypes.editCourseStatusSectionDataChanged:
      return { ...state, ...action.payload }
    default:
      return state
  }
}

const isEditing = (state = false, action) => {
  switch (action.type) {
    case actionTypes.editStatusSectionData:
      return true
    case actionTypes.cancelEditStatusSectionData:
    case actionTypes.updateCourseStatusSection.success:
    case LOCATION_CHANGE:
      return false
    default:
      return state
  }
}

const inProgress = (state = false, action) => {
  switch (action.type) {
    case actionTypes.updateCourseStatusSection.start:
      return true
    case actionTypes.updateCourseStatusSection.success:
    case actionTypes.updateCourseStatusSection.error:
      return false
    default:
      return state
  }
}

export default combineReducers({ data, isEditing, inProgress })

import { initReactI18next } from 'react-i18next'

import i18n from 'i18next'

import 'moment/locale/en-gb'
import 'moment/locale/da'
import 'moment/locale/de'
import 'moment/locale/es'
import 'moment/locale/fr'
import 'moment/locale/it'
import 'moment/locale/nl'

import { loadAlmaMaterI18n } from '@fullfabric/alma-mater'
import { loadSchemableFormsI18n } from '@fullfabric/schemable-forms'

import { loadApplicsI18n } from 'apps/Applics/i18n'
import { loadAuthenticationI18n } from 'apps/Authentication/i18n'
import { loadContentPagesI18n } from 'apps/ContentPages/i18n'
import { loadEmailTemplatesI18n } from 'apps/EmailTemplates/i18n'
import { loadEvaluationsI18n } from 'apps/Evaluations/i18n'
import { loadFinancialI18n } from 'apps/Financial/i18n'
import { loadImportsI18n } from 'apps/Imports/i18n'
import { loadInboxI18n } from 'apps/Inbox/i18n'
import { loadMergeProfilesI18n } from 'apps/MergeProfiles/i18n'
import { loadPoliciesI18n } from 'apps/Policies/i18n'
import { loadReportsI18n } from 'apps/Reports/i18n'
import { loadStudyPlansI18n } from 'apps/StudyPlans/i18n'
import daResource from 'i18n/da/locales.json'
import deResource from 'i18n/de/locales.json'
import enResource from 'i18n/en/locales.json'
import esResource from 'i18n/es/locales.json'
import frResource from 'i18n/fr/locales.json'
import nlResource from 'i18n/nl/locales.json'
import svResource from 'i18n/sv/locales.json'

const resources = {
  en: { translation: enResource },
  de: { translation: deResource },
  fr: { translation: frResource },
  es: { translation: esResource },
  nl: { translation: nlResource },
  sv: { translation: svResource },
  da: { translation: daResource }
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'en',
    fallbackLng: 'en',
    nsSeparator: false,
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  })

// I18n from libraries
loadAlmaMaterI18n('translation', i18n)
loadSchemableFormsI18n(i18n)

// I18n from apps
loadAuthenticationI18n('translation', i18n)
loadContentPagesI18n('translation', i18n)
loadEmailTemplatesI18n('translation', i18n)
loadEvaluationsI18n('translation', i18n)
loadImportsI18n('translation', i18n)
loadInboxI18n('translation', i18n)
loadFinancialI18n('translation', i18n)
loadMergeProfilesI18n('translation', i18n)
loadPoliciesI18n('translation', i18n)
loadReportsI18n('translation', i18n)
loadApplicsI18n('translation', i18n)
loadStudyPlansI18n('translation', i18n)

export default i18n

import React from 'react'
import { useQuery } from 'react-query'

import getProfileSchema from 'apps/MergeProfiles/api/getProfileSchema'
import ProfileSchemaContext from 'apps/MergeProfiles/contexts/ProfileSchema'

const ProfileSchemaProvider = ({ children }) => {
  const query = useQuery('profileSchema', getProfileSchema)

  const smartEmailFields = React.useMemo(() => {
    if (!query?.data?.sections) return []

    const emailFields = query?.data?.sections
      .map((s) => s.fields)
      .reduce((acc, fields) => [...acc, ...fields], [])
      .filter((field) => field.type === 'Schemable::Fields::Types::SmartEmail')

    const primaryEmailField = emailFields.filter(
      (field) => field.name === 'email'
    )[0]
    const otherEmailFields = emailFields
      .filter((field) => field.name !== 'email')
      .sort((a, b) => a.order - b.order)

    return [primaryEmailField, ...otherEmailFields]
  }, [query?.data?.sections])

  return (
    <ProfileSchemaContext.Provider value={{ ...query, smartEmailFields }}>
      {children}
    </ProfileSchemaContext.Provider>
  )
}

export default ProfileSchemaProvider

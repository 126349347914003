import {
  validate,
  validateCredits,
  validateExistingSubject,
  validateHours,
  validateMaximumCapacity,
  validateMaxNumberAbsences,
  validateMinimumCapacity,
  validateNewSubject,
  validatePriceAmount,
  validatePriceCurrency,
  validateTargetCapacity
} from '../validationHelper'

const validateCreation = (data, subjects = []) => {
  const validators = [
    validateExistingSubject,
    validateNewSubject(subjects),
    validatePriceAmount,
    validatePriceCurrency,
    validateMaximumCapacity,
    validateMinimumCapacity,
    validateTargetCapacity,
    validateCredits,
    validateHours,
    validateMaxNumberAbsences
  ]

  validate(data, validators)
}

export default validateCreation

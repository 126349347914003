import courseDefaultName from 'apps/Courses/utils/courseDefaultName'
import getCampus from './getCampus'
import getClassOf from './getClassOf'
import getCourse from './getCourse'

const contextName = (context, isRoleForClass) => {
  if (!context) return null

  if (isRoleForClass) return context.name

  return courseDefaultName(context.default_name)
}

const getContextForRole = async (role) => {
  if (!role) return null

  const isRoleForClass = role.context_type === 'Institutions::ClassOf'
  const getContextFn = isRoleForClass ? getClassOf : getCourse

  const getCampusFn = role.campus_id ? getCampus : () => Promise.resolve(null)

  const [context, campus] = await Promise.all([
    getContextFn(role.context_id),
    getCampusFn(role.campus_id)
  ])

  return (
    context && {
      id: context.id,
      name: contextName(context, isRoleForClass),
      parent_id: isRoleForClass ? context.programme_id : context.subject.id,
      parent_name: isRoleForClass
        ? context.programme_name
        : context.subject.name,
      campus_id: role.campus_id,
      campus_name: campus?.name
    }
  )
}

export default getContextForRole

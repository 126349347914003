import axios from 'axios'

const lookupUser = async (email, redirectTo) => {
  const requestParams = {
    url: '/api/users/lookup',
    method: 'post',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    data: `email=${encodeURIComponent(
      email
    )}&policies_status=true&redirect_path=${redirectTo}`
  }

  return await axios(requestParams)
}

export default lookupUser

import React from 'react'
import { useMutation } from 'react-query'

import { useSettings } from '@fullfabric/authorization-officer'

import createDegreeClass from 'apps/StudyPlans/api/createDegreeClass'
import deleteDegreeClasses from 'apps/StudyPlans/api/deleteDegreeClasses'
import updateDegreeClasses from 'apps/StudyPlans/api/updateDegreeClasses'
import { useOnChangesSaved } from 'apps/StudyPlans/contexts/ChangesSaved'
import { useSetSettingsSectionsBeingEdited } from 'apps/StudyPlans/contexts/SettingsSectionsBeingEdited'
import { useSetStudyPlan } from 'apps/StudyPlans/contexts/StudyPlanData'
import { getDegreeClassPlaceholder, isDirty } from './utils'

const useOnSaveDegreeClasses = ({
  studyPlan,
  degreeClasses,
  setDegreeClasses
}) => {
  const setStudyPlan = useSetStudyPlan()
  const onChangesSaved = useOnChangesSaved()
  const setSectionsBeingEdited = useSetSettingsSectionsBeingEdited()
  const settings = useSettings()

  const { mutate: updateDegreeClassesMutation, isLoading } = useMutation(
    async ({ studyPlanId, newDegreeClasses, currentDegreeClasses }) => {
      const presentInNewDegreeClasses = newDegreeClasses.reduce(
        (hashed, { id }) => ({ ...hashed, [id]: true }),
        {}
      )
      const presentInCurrentDegreeClasses = currentDegreeClasses.reduce(
        (hashed, { id }) => ({ ...hashed, [id]: true }),
        {}
      )

      const degreeClassesToCreate = newDegreeClasses.filter(
        ({ id }) => !presentInCurrentDegreeClasses[id]
      )

      const degreeClassesToUpdate = newDegreeClasses.filter(
        ({ id }) => presentInCurrentDegreeClasses[id]
      )

      const degreeClassesToDelete = currentDegreeClasses.filter(
        ({ id }) => !presentInNewDegreeClasses[id]
      )

      if (degreeClassesToDelete.length > 0) {
        await deleteDegreeClasses(
          studyPlanId,
          degreeClassesToDelete.map(({ id }) => id)
        )
      }

      let updated = []
      if (degreeClassesToUpdate.length > 0) {
        updated = await updateDegreeClasses(studyPlanId, degreeClassesToUpdate)
      }

      const created = await Promise.all([
        ...degreeClassesToCreate.map((toCreate) =>
          createDegreeClass(studyPlanId, toCreate)
        )
      ])

      return [...updated, ...created]
    },
    {
      onSuccess: (degreeClasses) => {
        setStudyPlan((studyPlan) => ({
          ...studyPlan,
          degree_classes: degreeClasses
        }))
        onChangesSaved()
        setSectionsBeingEdited((sections) => ({
          ...sections,
          degreeClasses: false
        }))
      }
    }
  )

  const onSaveChanges = React.useCallback(() => {
    updateDegreeClassesMutation({
      studyPlanId: studyPlan.id,
      newDegreeClasses: Object.values(degreeClasses).filter(isDirty),
      currentDegreeClasses: studyPlan.degree_classes
    })
  }, [updateDegreeClassesMutation, studyPlan, degreeClasses])

  const onCancel = React.useCallback(() => {
    setDegreeClasses(
      (studyPlan.degree_classes || []).length === 0
        ? getDegreeClassPlaceholder(settings.hash.locales)
        : studyPlan.degree_classes.reduce(
            (hashed, curr) => ({ ...hashed, [curr.id]: curr }),
            {}
          )
    )
    setSectionsBeingEdited((sections) => ({
      ...sections,
      degreeClasses: false
    }))
  }, [setSectionsBeingEdited, setDegreeClasses, studyPlan, settings])

  return { onSaveChanges, isLoading, onCancel }
}

export default useOnSaveDegreeClasses

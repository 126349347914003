import { utils } from '@fullfabric/public-api'

export default async function getStaffOptions({
  includeTeams = false,
  includeSupportStaff = false
} = {}) {
  const query = {}
  if (includeTeams) query.include_teams = 'true'
  if (includeSupportStaff) query.include_support_staff = 'true'

  const response = await fetch(
    utils.url('/api/profiles/profiles/options/staff', { query }),
    { credentials: 'same-origin' }
  )

  return await utils.checkResponse(response)
}

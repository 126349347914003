import React from 'react'
import { Trans } from 'react-i18next'

import moment from 'moment'

import { Button, Col, Row, Text } from '@fullfabric/alma-mater'

import styles from './styles.module.scss'

const notDefinedText = (
  <Text type='f4' fontColor='text-base' marginTop='tiny'>
    <Trans>Not defined</Trans>
  </Text>
)

const CourseEditDisplayDates = ({ data, onEditClick }) => {
  return (
    <>
      <Text type='f3' marginBottom='small' fontColor='text-base-darkest'>
        <Trans>Applications</Trans>
      </Text>

      <Row>
        <Col xs={6}>
          <Text fontColor='text-base' bold>
            <Trans>Applications open on</Trans>
          </Text>

          {data.opens_on ? (
            <Text type='f4' marginTop='tiny' fontColor='text-base-darkest'>
              {moment(data.opens_on).format('dddd MMM Do, YYYY [at] HH:mm')}
            </Text>
          ) : (
            notDefinedText
          )}
        </Col>

        <Col xs={6}>
          <Text fontColor='text-base' bold>
            <Trans>Applications close on</Trans>
          </Text>

          {data.closes_on ? (
            <Text type='f4' marginTop='tiny' fontColor='text-base-darkest'>
              {moment(data.closes_on).format('dddd MMM Do, YYYY [at] HH:mm')}
            </Text>
          ) : (
            notDefinedText
          )}
        </Col>
      </Row>

      <Text
        type='f3'
        marginBottom='small'
        marginTop='default'
        fontColor='text-base-darkest'
      >
        <Trans>Class</Trans>
      </Text>

      <Row>
        <Col xs={6}>
          <Text fontColor='text-base' bold>
            <Trans>Class starts on</Trans>
          </Text>

          {data.starts_on ? (
            <Text type='f4' marginTop='tiny' fontColor='text-base-darkest'>
              {moment(data.starts_on).format('dddd MMM Do, YYYY [at] HH:mm')}
            </Text>
          ) : (
            notDefinedText
          )}
        </Col>

        <Col xs={6}>
          <Text fontColor='text-base' bold>
            <Trans>Class ends on</Trans>
          </Text>

          {data.ends_on ? (
            <Text type='f4' marginTop='tiny' fontColor='text-base-darkest'>
              {moment(data.ends_on).format('dddd MMM Do, YYYY [at] HH:mm')}
            </Text>
          ) : (
            notDefinedText
          )}
        </Col>
      </Row>

      <div className='marginTop-default'>
        <Button
          size='small'
          aria-label='edit course dates'
          onClick={() => onEditClick(data)}
          className={styles.editButton}
        >
          <Trans>Edit</Trans>
        </Button>
      </div>
    </>
  )
}

export default CourseEditDisplayDates

import React from 'react'

import { Text } from '@fullfabric/alma-mater'

const ProductChoiceCell = ({ value: productChoicename, data, row }) => {
  const rowData = data[row.index]
  const CHOICE_TYPE_LABEL = {
    primary: 'Primary choice',
    secondary: 'Secondary choice',
    none: ''
  }

  if (!productChoicename) {
    return null
  }
  const evaluationChoice = CHOICE_TYPE_LABEL[rowData.evaluation.choice]
  return (
    <>
      {productChoicename}
      <Text
        data-testid='secondary-info'
        tag='div'
        marginTop='small'
        type='f6'
        fontColor='text-base-darker'
      >
        {evaluationChoice}
      </Text>
    </>
  )
}

export default ProductChoiceCell

import React from 'react'
import { useTranslation } from 'react-i18next'

import { OneColumnLayout } from '@fullfabric/alma-mater'
import { IfHasFeature } from '@fullfabric/authorization-officer'

import getReports from './getReports'
import Head from './Head'
import ReportCard from './ReportCard'

import styles from './styles.module.scss'

const ReportsListPage = () => {
  const { t } = useTranslation()

  return (
    <OneColumnLayout pageHead={<Head />} className={styles.pageSpacing}>
      {getReports(t).map((report) => (
        <IfHasFeature
          key={report.id}
          moduleName='reports'
          featureName={report.featureFlag}
        >
          <ReportCard
            id={report.id}
            legacy={report.legacy}
            title={report.name}
            description={report.description}
            imageUrl={report.imageUrl}
          />
        </IfHasFeature>
      ))}
    </OneColumnLayout>
  )
}

export default ReportsListPage

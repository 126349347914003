.react-app ._qrReader_e2zeq_1 {
  height: 100%;
}
.react-app ._qrReader_e2zeq_1 > section {
  position: initial !important;
  width: inherit !important;
  padding-top: 0 !important;
}
.react-app ._qrReader_e2zeq_1 > section > div {
  display: none;
}

.react-app ._scanTarget_e2zeq_13 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.react-app ._scanInProgress_e2zeq_20 g {
  fill: var(--ff-color-white);
}

/* Portrait */
@media only screen and (min-width: 320px) and (max-width: 568px) and (orientation: portrait) {
  .react-app ._scanTarget_e2zeq_13 {
    width: calc(100% - 2 * var(--space-more));
  }
}
/* Landscape */
@media only screen and (min-width: 320px) and (max-width: 568px) and (orientation: landscape) {
  .react-app ._scanTarget_e2zeq_13 {
    height: calc(100% - 2 * var(--space-more));
  }
}
@media only screen and (min-width: 375px) and (max-width: 667px) and (orientation: landscape) {
  .react-app ._scanTarget_e2zeq_13 {
    height: calc(100% - 2 * var(--space-more));
  }
}
@media only screen and (min-width: 375px) and (max-width: 812px) and (orientation: landscape) {
  .react-app ._scanTarget_e2zeq_13 {
    height: calc(100% - 2 * var(--space-more));
  }
}
import React from 'react'
import { useTranslation } from 'react-i18next'

import { CheckboxInput, Text } from '@fullfabric/alma-mater'

import { useMergeData } from 'apps/MergeProfiles/contexts/MergeData'
import getLocaledLabel from 'apps/MergeProfiles/utils/getLocaledLabel'
import { BaseCol, BaseRow } from './Base'

import classNames from 'classnames'
import styles from '../styles.module.scss'

const onCheckedChange =
  (
    profileType,
    fieldsWithDifferentValuesForSection,
    profileInfoFrom,
    updateMergeData
  ) =>
  (checked) => {
    const newProfileInfoFrom = fieldsWithDifferentValuesForSection.reduce(
      (acc, field) => {
        const { [field.name]: value, ...remainingProfileInfoFrom } = acc
        return checked
          ? { ...remainingProfileInfoFrom, [field.name]: profileType }
          : remainingProfileInfoFrom
      },
      { ...profileInfoFrom }
    )

    updateMergeData({ profileInfoFrom: newProfileInfoFrom })
  }

const SectionTitleRow = ({ section, fieldsWithDifferentValuesForSection }) => {
  const { t, i18n } = useTranslation()
  const {
    mergeData: { profileInfoFrom },
    updateMergeData
  } = useMergeData()

  const onSelectAllDuplicate = React.useCallback(
    (checked) =>
      onCheckedChange(
        'duplicate',
        fieldsWithDifferentValuesForSection,
        profileInfoFrom,
        updateMergeData
      )(checked),
    [fieldsWithDifferentValuesForSection, profileInfoFrom, updateMergeData]
  )
  const onSelectAllMain = React.useCallback(
    (checked) =>
      onCheckedChange(
        'main',
        fieldsWithDifferentValuesForSection,
        profileInfoFrom,
        updateMergeData
      )(checked),
    [fieldsWithDifferentValuesForSection, profileInfoFrom, updateMergeData]
  )

  const allFieldsForSectionAreFromDuplicateProfile =
    fieldsWithDifferentValuesForSection.every(
      (field) => profileInfoFrom[field.name] === 'duplicate'
    )
  const allFieldsForSectionAreFromMainProfile =
    fieldsWithDifferentValuesForSection.every(
      (field) => profileInfoFrom[field.name] === 'main'
    )

  return (
    <BaseRow className={classNames(styles.row, styles.sectionTitle)}>
      <BaseCol>
        <Text type='f3' fontColor='text-base-darkest'>
          {getLocaledLabel(section.locals, i18n.language)}
        </Text>
      </BaseCol>
      <BaseCol>
        <CheckboxInput
          id={`selectAllDuplicateForSection_${section.id}`}
          label={t('Select all')}
          checked={allFieldsForSectionAreFromDuplicateProfile}
          onChange={onSelectAllDuplicate}
        />
      </BaseCol>
      <BaseCol>
        <CheckboxInput
          id={`selectAllMainForSection_${section.id}`}
          label={t('Select all')}
          checked={allFieldsForSectionAreFromMainProfile}
          onChange={onSelectAllMain}
        />
      </BaseCol>
    </BaseRow>
  )
}

export default SectionTitleRow

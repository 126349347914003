import React from 'react'

import ProductPerformancePage from 'apps/Reports/components/ProductPerformancePage'
import useChangeDocumentTitle from 'apps/Reports/hooks/useChangeDocumentTitle'

const ProductPerformance = () => {
  useChangeDocumentTitle('Product performance report')

  return <ProductPerformancePage />
}

export default ProductPerformance

import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, PageHead } from '@fullfabric/alma-mater'

import TablePageLayout from 'shared/components/TablePageLayout'

import { SchemasProvider } from 'apps/Financial/components/SchemasProvider'
import TransactionsTable from 'apps/Financial/components/TransactionsTable'
import useExportTransactionsHandler from './useExportTransactionsHandler'

const TransactionsPage = () => {
  const { t } = useTranslation()
  const [filters, setFilters] = useState({})
  const [search, setSearch] = useState({})
  const exportHandler = useExportTransactionsHandler()

  return (
    <SchemasProvider>
      <TablePageLayout>
        <PageHead
          title={t('Transactions')}
          action={
            <Button primary onClick={() => exportHandler({ search, filters })}>
              {t('Export')}
            </Button>
          }
          noBorder
        />
        <TablePageLayout.TableSection>
          <TransactionsTable setSearch={setSearch} setFilters={setFilters} />
        </TablePageLayout.TableSection>
      </TablePageLayout>
    </SchemasProvider>
  )
}

export default TransactionsPage

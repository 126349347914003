.react-app ._container_1jomc_1 {
  height: 100%;
  display: flex;
}

.react-app ._sidebarRegion_1jomc_6 {
  flex: 0 0 320px;
  padding: var(--space-big);
  background: var(--ff-color-white);
}

.react-app ._contentRegion_1jomc_12 {
  flex-direction: column;
  flex: 1 1 auto;
  overflow: auto;
  display: flex;
  padding: var(--space-big);
  background: var(--ff-bg-base-lighter);
}

.react-app ._header_1jomc_21 {
  display: flex;
  justify-content: space-between;
  padding-bottom: var(--space-more);
  border-bottom: var(--border-base-lighter);
  margin-bottom: var(--space-big);
}

.react-app ._footer_1jomc_29 {
  display: flex;
  justify-content: flex-end;
  padding-top: var(--space-more);
  border-top: var(--border-base-lighter);
  margin-top: var(--space-big);
}

.react-app ._header_1jomc_21 h1 {
  margin: 0;
}

.react-app ._header_1jomc_21,
.react-app ._header_1jomc_21 ._actionsRegion_1jomc_42,
.react-app ._footer_1jomc_29 {
  flex: 0 0 auto;
}

.react-app ._header_1jomc_21 ._actionsRegion_1jomc_42 button,
.react-app ._footer_1jomc_29 button {
  margin-right: var(--space-small);
}
.react-app ._header_1jomc_21 ._actionsRegion_1jomc_42 button:last-child,
.react-app ._footer_1jomc_29 button:last-child {
  margin-right: 0;
}

.react-app ._content_1jomc_12 {
  flex: 1 0 auto;
}
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Col, Row, Text } from '@fullfabric/alma-mater'

import NothingToMergeCard from 'apps/MergeProfiles/components/NothingToMergeCard'
import { useMergeData } from 'apps/MergeProfiles/contexts/MergeData'
import { isClassOrCourseRole } from 'apps/MergeProfiles/utils/journeyHelpers'
import JourneySelectionRow from './JourneySelectionRow'

const JourneysSelector = () => {
  const { t } = useTranslation()
  const {
    mergeData: { sourceProfile, targetProfile }
  } = useMergeData()

  const duplicateProfileClassOrCourseRoles =
    sourceProfile.roles.filter(isClassOrCourseRole)

  if (duplicateProfileClassOrCourseRoles.length === 0)
    return (
      <NothingToMergeCard
        title={t('No journeys to be merged')}
        description={t('The duplicate profile doesn’t have any journeys')}
      />
    )

  return (
    <div>
      <Row className='marginBottom-default' gutterWidth={24}>
        <Col xs={6}>
          <Text type='f4' fontColor='yellow-800'>
            <Trans>Duplicate profile</Trans>
          </Text>
        </Col>

        <Col xs={6}>
          <Text type='f4' fontColor='text-info'>
            <Trans>Main profile</Trans>
          </Text>
        </Col>
      </Row>

      <div role='rowgroup'>
        {duplicateProfileClassOrCourseRoles.map((role) => {
          const targetProfileRole = targetProfile.roles.filter(
            (r) => r.context_id === role.context_id
          )[0]

          return (
            <JourneySelectionRow
              key={role.id}
              role={role}
              targetProfileRole={targetProfileRole}
            />
          )
        })}
      </div>
    </div>
  )
}

export default JourneysSelector

import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ArrowLeftIcon, Button, PageHead } from '@fullfabric/alma-mater'

import ExportConfirmationModal from './ExportConfirmationModal'
import useHandleExportReport from './useHandleExportReport'

const Head = ({ tableParams }) => {
  const { t } = useTranslation()
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false)
  const openConfirmationModal = useCallback(() => {
    setIsConfirmationModalOpen(true)
  }, [])
  const closeConfirmationModal = useCallback(() => {
    setIsConfirmationModalOpen(false)
  }, [])
  const handleExportReport = useHandleExportReport()

  return (
    <>
      <PageHead
        noBorder
        breadcrumbs={[
          {
            label: t('Back to list of reports'),
            icon: ArrowLeftIcon,
            to: '/reports'
          }
        ]}
        title={t('Product performance')}
        action={
          <Button
            primary
            size='small'
            onClick={() => {
              handleExportReport(tableParams)
              openConfirmationModal()
            }}
          >
            Export list
          </Button>
        }
      />
      <ExportConfirmationModal
        isOpen={isConfirmationModalOpen}
        handleClose={closeConfirmationModal}
      />
    </>
  )
}

export default Head

import React, { useState } from 'react'

const ReferrerContext = React.createContext()
const TemplateContextContext = React.createContext()

export const TemplateContextProvider = ({
  children,
  context,
  contextId,
  parentContextId
}) => {
  const [originPath] = useState(
    context === 'Institutions::Institution'
      ? '/institutions/settings#applic-templates'
      : context === 'Institutions::ClassOf'
      ? `/institutions/settings#programmes/${parentContextId}/classes/${contextId}/applic-templates`
      : '/'
  )

  const [templateContext] = useState({
    parentContextId,
    context,
    contextId
  })

  return (
    <ReferrerContext.Provider value={originPath}>
      <TemplateContextContext.Provider value={templateContext}>
        {children}
      </TemplateContextContext.Provider>
    </ReferrerContext.Provider>
  )
}

export const useReferrerPath = () => React.useContext(ReferrerContext)

export const useTemplateContext = () => React.useContext(TemplateContextContext)

import React from 'react'
import { useFormContext } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'

import { Alert, Button, Field } from '@fullfabric/alma-mater'

import { useAppData } from 'shared/contexts/AppData'

import authenticate from 'apps/Authentication/api/authenticate'
import AuthContentLayout from 'apps/Authentication/components/AuthContentLayout'
import FormTitle from 'apps/Authentication/components/FormTitle'

import styles from './styles.module.scss'

function TwoFaForm({ info: flashInfo, twoFaPhoneNumber, onAuthCompleted }) {
  const { t } = useTranslation()
  const appData = useAppData()
  const [info, setInfo] = React.useState(() =>
    appData && appData.flash && appData.flash.trim() !== ''
      ? appData.flash.trim()
      : flashInfo
  )
  const [errorMessage, setErrorMessage] = React.useState(null)
  const [isRedirecting, setIsRedirecting] = React.useState(false)

  const { register, handleSubmit, formState, setFocus, getValues } =
    useFormContext()
  const { errors } = formState
  const { ref: twoFATokenInputRef, ...twoFATokenInputProps } = register(
    'twoFaToken',
    { required: t('This field is required') }
  )

  React.useEffect(() => {
    setFocus('twoFaToken')
  }, [setFocus]) // should only run on initial renders of each state

  const authenticationMutation = useMutation(
    (formValues) => authenticate(formValues),
    {
      onSuccess: (response) => {
        // hack: for reseting sms token, we're sending the auth request without token, simulating a first step auth
        // when no token is sent, response redirects to 2fa
        const askedForResetToken = response.url?.includes('#2fa')
        const completedAuthentication = !askedForResetToken

        if (completedAuthentication) {
          setIsRedirecting(true)
          onAuthCompleted(response)
        }
      },
      onError: (error) => {
        setErrorMessage(error.data?.message)
      }
    }
  )

  const onSubmit = React.useCallback(
    async (formValues) => {
      await authenticationMutation.mutate(formValues)
    },
    [authenticationMutation]
  )

  const onResetSmsToken = async (evt) => {
    evt.preventDefault()

    await authenticationMutation.mutate({ ...getValues(), twoFaToken: null })
  }

  return (
    <form className={styles.form}>
      <FormTitle
        title={<Trans>Two step verification</Trans>}
        subtitle={
          <>
            {t(
              'We sent a sms to the number {{ phoneNumber }} with the verification code',
              { phoneNumber: twoFaPhoneNumber }
            )}
          </>
        }
      />

      {errorMessage ? (
        <Alert type='alert' description={errorMessage} marginBottom='default' />
      ) : null}

      {info ? (
        <Alert
          type='warning'
          onClose={() => setInfo(null)}
          description={info}
          marginBottom='default'
        />
      ) : null}
      <Field
        {...twoFATokenInputProps}
        inputRef={twoFATokenInputRef}
        type='text'
        label={<Trans>Two Factor Authentication Token</Trans>}
        error={errors.twoFaToken?.message}
      />
      <div className={styles.loginActions}>
        <Button
          primary
          type='submit'
          loading={authenticationMutation.isLoading || isRedirecting}
          onClick={handleSubmit(onSubmit)}
        >
          <Trans>Log in securely</Trans>
        </Button>
      </div>
      <AuthContentLayout.Footer
        question={<Trans>Didn't receive the sms?</Trans>}
        linkTo={onResetSmsToken}
        linkText={<Trans>Send again</Trans>}
        disableLink={authenticationMutation.isLoading}
      />
    </form>
  )
}

export default TwoFaForm

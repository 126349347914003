.react-app ._form_qztz5_1 {
  width: 100%;
  padding: var(--space-more);
}

.react-app ._button_qztz5_6 {
  width: 100%;
  padding: var(--space-less);
}

.react-app ._ghostButton_qztz5_11 {
  display: none;
}

.react-app ._errorBannerContainer_qztz5_15 {
  display: inline-block;
  width: 100%;
  text-decoration: none;
  cursor: unset;
  padding: var(--space-default) var(--space-more) 0;
}

.react-app ._errorBannerContainer_qztz5_15._mobile_qztz5_23 {
  padding-left: var(--space-default);
  padding-right: var(--space-default);
}
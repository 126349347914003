import { LOCATION_CHANGE } from 'connected-react-router'
import { combineReducers } from 'redux'

import actionTypes from '../../../actions/types'

const data = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.editDatesSectionData:
      return action.payload
    case actionTypes.cancelEditDatesSectionData:
    case LOCATION_CHANGE:
      return {}
    case actionTypes.editCourseDatesSectionDataChanged:
      return { ...state, ...action.payload }
    default:
      return state
  }
}

const isEditing = (state = false, action) => {
  switch (action.type) {
    case actionTypes.editDatesSectionData:
      return true
    case actionTypes.cancelEditDatesSectionData:
    case actionTypes.updateCourseDatesSection.success:
    case LOCATION_CHANGE:
      return false
    default:
      return state
  }
}

const inProgress = (state = false, action) => {
  switch (action.type) {
    case actionTypes.updateCourseDatesSection.start:
      return true
    case actionTypes.updateCourseDatesSection.success:
    case actionTypes.updateCourseDatesSection.error:
      return false
    default:
      return state
  }
}

export default combineReducers({
  data,
  isEditing,
  inProgress
})

import React from 'react'
import { Trans } from 'react-i18next'
import { animated, useSpring } from 'react-spring'

import { FeedbackMessage } from '@fullfabric/alma-mater'

import styles from './styles.module.scss'

function SavedChangesPopup({ show }) {
  const animationStyles = useSpring({ bottom: show ? '0px' : '-150px' })

  return (
    <animated.div
      aria-hidden={show}
      className={styles.successPopup}
      style={animationStyles}
    >
      <FeedbackMessage
        isOpen
        type='success'
        message={<Trans>Changes saved</Trans>}
      />
    </animated.div>
  )
}

export default SavedChangesPopup

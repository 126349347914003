import { utils } from '@fullfabric/public-api'

export default async function deleteEmailTemplate(id) {
  const response = await fetch(`/api/email_templates/${id}`, {
    credentials: 'same-origin',
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' }
  })

  return await utils.checkResponse(response)
}

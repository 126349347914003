import { utils } from '@fullfabric/public-api'

const updateCard = async (card) => {
  const response = await fetch(`/inbox/api/cards/${card.id}`, {
    credentials: 'same-origin',
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(card)
  })

  return await utils.checkResponse(response)
}

export default updateCard

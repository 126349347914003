import React from 'react'
import { Trans } from 'react-i18next'

import { AnnotatedLayout } from '@fullfabric/alma-mater'

import SubjectBasicDisplay from 'apps/StudyPlans/components/SubjectBasicSection/Display'
import SubjectBasicForm from 'apps/StudyPlans/components/SubjectBasicSection/Form'

const SubjectBasicSection = ({ beingEdited }) => (
  <AnnotatedLayout.Section
    title={<Trans>Basic</Trans>}
    description={
      <Trans>
        Define the details of the study plan subject that will appear on student
        transcripts.
      </Trans>
    }
  >
    {beingEdited ? <SubjectBasicForm /> : <SubjectBasicDisplay />}
  </AnnotatedLayout.Section>
)

export default SubjectBasicSection

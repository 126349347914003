.react-app ._pageLayout_4ki06_1 {
  height: 100vh;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 0 var(--space-more);
  padding-bottom: var(--space-more);
  background-color: var(--ff-bg-base-lightest);
}

.react-app ._tableSection_4ki06_11 {
  flex: 1;
  min-height: 0;
  display: flex;
  flex-direction: column;
}

.react-app ._tableViewport_4ki06_18 {
  overflow: auto;
}
.react-app ._tableViewport_4ki06_18 * {
  overflow: unset;
}
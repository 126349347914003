import React from 'react'
import { Provider } from 'react-redux'
import { Route, Switch, useLocation } from 'react-router'

import { ConnectedRouter } from 'connected-react-router'

import { useCurrentUser, useSettings } from '@fullfabric/authorization-officer'

import { useAppData } from 'shared/contexts/AppData'

import CourseListPage from 'apps/Courses/pages/CourseListPage'
import EditCoursePage from 'apps/Courses/pages/EditCoursePage'
import NewCoursePage from 'apps/Courses/pages/NewCoursePage'
import createReduxStore from 'apps/Courses/utils/createReduxStore'
import * as routes from 'apps/Courses/utils/routeConstants'

const CoursesAppRoutes = () => {
  const currentUser = useCurrentUser()
  const settings = useSettings()
  const appData = useAppData()
  const { pathname } = useLocation()

  const initialState = { currentUser, appData, settings }

  const { history, store } = createReduxStore(initialState, {
    initialEntries: [pathname]
  })

  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <Switch>
          <Route exact path={routes.HOME_ROUTE} component={CourseListPage} />
          <Route
            exact
            path={routes.NEW_COURSE_ROUTE}
            component={NewCoursePage}
          />
          <Route
            exact
            path={routes.EDIT_COURSE_ROUTE}
            component={EditCoursePage}
          />
        </Switch>
      </ConnectedRouter>
    </Provider>
  )
}

export default CoursesAppRoutes

import React, { useEffect, useState } from 'react'
import { Trans } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { Loading, Table, Text, useScreenClass } from '@fullfabric/alma-mater'

import getMyApplications from 'apps/ContentPages/api/get-my-applications'
import ApplicationRow from './application-row'
import ApplicationRowSmall from './application-row-small'
import InformationIcon from './information-icon'

import styles from './styles.module.scss'

const ApplicationsList = () => {
  const screenClass = useScreenClass()
  const isMobileLayout = ['xs', 'sm'].includes(screenClass)
  const location = useLocation()

  const [applics, setApplics] = useState(null)
  const [loading, setLoading] = useState(false)

  const dataType = location.pathname.includes('registrations')
    ? 'registration'
    : 'application'

  useEffect(() => {
    setLoading(true)
    ;(async () => {
      const applicationData = await getMyApplications()
      setApplics(applicationData.filter((applic) => applic.type === dataType))
      setLoading(false)
    })()
  }, [dataType])

  const hasApplics = !loading && applics && !!applics.length
  const noApplics = !loading && applics && !applics.length

  return (
    <>
      {isMobileLayout ? (
        <>
          {loading && (
            <div className={styles.centeredHolder}>
              <Loading className={styles.loadingMobile} type='triple' />
            </div>
          )}

          {noApplics && (
            <div className={styles.centeredHolder}>
              <div className={styles.informationIcon}>
                <InformationIcon />
              </div>
              <Text
                type='f4'
                fontColor='text-base-darkest'
                marginTop='small'
                className={styles.emptyMessageMobile}
              >
                <Trans>
                  You haven't started or submitted an application yet
                </Trans>
              </Text>
            </div>
          )}

          {hasApplics &&
            applics.map((applic, index) => (
              <ApplicationRowSmall
                isFirst={index === 0}
                isLast={index === applics.length - 1}
                key={applic.id}
                applic={applic}
              />
            ))}
        </>
      ) : (
        <>
          {loading ? (
            <div className={styles.centeredHolder}>
              <Loading className={styles.loadingMobile} type='triple' />
            </div>
          ) : (
            <Table>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderItem className={styles.smallest}>
                    <Trans>Name</Trans>
                  </Table.HeaderItem>

                  <Table.HeaderItem>
                    {dataType === 'application' ? (
                      <Trans>Application</Trans>
                    ) : (
                      <Trans>Registration</Trans>
                    )}
                  </Table.HeaderItem>

                  <Table.HeaderItem
                    className={styles.smallest}
                  ></Table.HeaderItem>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {loading && (
                  <Table.Row>
                    <Table.Data colSpan='100' align='center'>
                      <Loading />
                    </Table.Data>
                  </Table.Row>
                )}

                {noApplics && (
                  <Table.Row>
                    <Table.Data colSpan='100' align='center'>
                      <Text type='f5' fontColor='text-base-darker'>
                        <Trans>
                          You haven't started or submitted an application yet
                        </Trans>
                      </Text>
                    </Table.Data>
                  </Table.Row>
                )}

                {hasApplics &&
                  applics.map((applic) => (
                    <ApplicationRow applic={applic} key={applic.id} />
                  ))}
              </Table.Body>
            </Table>
          )}
        </>
      )}
    </>
  )
}

export default ApplicationsList

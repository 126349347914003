import { isEmptyString } from 'apps/StudyPlans/utils/isEmptyString'
import { isCourseTypesEmpty } from './utils'

const validateNewSubjectData = (subjectData, studyPlan) => {
  if (!subjectData.createSubjectBeforeAdding && !subjectData.course_id) {
    return { course_id: 'blank' }
  }

  const errors = {}
  if (subjectData.createSubjectBeforeAdding) {
    if (isEmptyString(subjectData.subject_code)) errors.subject_code = 'blank'
    if (isEmptyString(subjectData.subject_name)) errors.subject_name = 'blank'
  }

  if (isNaN(subjectData.credits)) errors.credits = 'notANumber'
  if (
    !isEmptyString(subjectData.max_number_absences) &&
    isNaN(subjectData.max_number_absences)
  )
    errors.max_number_absences = 'notANumber'
  if (!isEmptyString(subjectData.hours) && isNaN(subjectData.hours))
    errors.hours = 'notANumber'

  if (!studyPlan.multiple_paths && isCourseTypesEmpty(subjectData.course_types))
    errors[`course_type_${studyPlan.default_path.id}`] = 'blank'

  if (studyPlan.multiple_paths && isCourseTypesEmpty(subjectData.course_types))
    errors.course_types = 'must_choose_at_least_one'

  if (isEmptyString(subjectData.credits)) errors.credits = 'blank'

  if (Object.keys(errors).length > 0) {
    return {
      ...errors,
      course_id: subjectData.createSubjectBeforeAdding ? null : errors.course_id
    }
  }

  return null
}

export default validateNewSubjectData

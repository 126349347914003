.react-app ._pageSpacing_jggdk_1 {
  padding-left: var(--space-more) !important;
  padding-right: var(--space-more) !important;
}

.react-app ._loadingContainer_jggdk_6 {
  box-sizing: content-box;
  height: 30px;
  padding: var(--space-tiny) var(--space-less);
  margin-bottom: var(--space-small);
  display: inline-flex;
  align-items: center;
}

.react-app ._filter_jggdk_15 {
  margin-bottom: var(--space-small);
}
import React from 'react'
import { Trans } from 'react-i18next'
import { useQuery } from 'react-query'
import { useHistory } from 'react-router-dom'

import { Button } from '@fullfabric/alma-mater'

import getApplicsBy from 'apps/MergeProfiles/api/getApplicsBy'
import { useMergeData } from 'apps/MergeProfiles/contexts/MergeData'
import { getJourneysFromApplics } from 'apps/MergeProfiles/utils/applicsHelper'

const NextButton = () => {
  const history = useHistory()
  const {
    mergeData: { applicsFrom, journeysFrom, sourceProfile, targetProfile },
    updateMergeData,
    updateMergeError,
    removeMergeError
  } = useMergeData()

  const { data: sourceProfileApplics, isSourceApplicsLoading } = useQuery(
    ['applicsBy', sourceProfile.id],
    () => getApplicsBy(sourceProfile.id)
  )
  const { data: targetProfileApplics, isTargetApplicsLoading } = useQuery(
    ['applicsBy', targetProfile.id],
    () => getApplicsBy(targetProfile.id)
  )

  const onApplicsSelected = React.useCallback(() => {
    const numSourceApplics = sourceProfileApplics?.length || 0
    if (
      numSourceApplics !== 0 &&
      Object.keys(applicsFrom || {}).length !== numSourceApplics
    ) {
      updateMergeError({ applicsFrom: 'someNotSelected' })
      return
    }

    const journeysFromApplics = getJourneysFromApplics(
      sourceProfile,
      sourceProfileApplics,
      targetProfileApplics,
      applicsFrom
    )

    updateMergeData({
      journeysFromApplics,
      journeysFrom: { ...journeysFrom, ...journeysFromApplics }
    })

    removeMergeError('applicsFrom')

    history.push(`/profiles/${targetProfile.id}/merge/journeys`)
  }, [
    history,
    sourceProfile,
    targetProfile,
    sourceProfileApplics,
    applicsFrom,
    updateMergeError,
    removeMergeError,
    targetProfileApplics,
    updateMergeData,
    journeysFrom
  ])

  return (
    <Button
      primary
      onClick={onApplicsSelected}
      disabled={isSourceApplicsLoading || isTargetApplicsLoading}
    >
      <Trans>Next</Trans>
    </Button>
  )
}

export default NextButton

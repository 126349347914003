import React from 'react'
import { Trans } from 'react-i18next'

import { AlertFullIcon, Card, Link, Text } from '@fullfabric/alma-mater'

import {
  useDisplayNewItems,
  useNewCardItems
} from 'apps/Inbox/contexts/cardNotifications'

import styles from './styles.module.scss'

export default function InboxCardListHeader() {
  const newCardItems = useNewCardItems()
  const displayNewItems = useDisplayNewItems()

  return (
    <div className={styles.newItemsCard}>
      {newCardItems.length > 0 && (
        <Card>
          <AlertFullIcon />

          <Text
            tag='span'
            type='f4'
            fontColor='text-base-darkest'
            marginLeft='less'
          >
            {newCardItems.length} <Trans>new items.</Trans>
          </Text>

          <Link onClick={() => displayNewItems(newCardItems)}>
            <Trans>Click here to update</Trans>
          </Link>
        </Card>
      )}
    </div>
  )
}

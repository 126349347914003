import React from 'react'

import EventDetailsWidget from 'apps/ContentPages/components/event-details-widget'
import EventForm from 'apps/ContentPages/components/event-form'
import EventPageTextContent from 'apps/ContentPages/components/event-page-text-content'
import EventTitle from 'apps/ContentPages/components/event-title'
import LandingPageHeader from 'apps/ContentPages/components/landing-page-header'
import LandingPageLogo from 'apps/ContentPages/components/landing-page-logo'
import PageLayout from 'apps/ContentPages/components/page-layout'
import { BackgroundImageProvider } from 'apps/ContentPages/contexts/background-image'
import { RegisterEventClickHandlerProvider } from 'apps/ContentPages/contexts/register-event-click-handler'

export default function EventPage() {
  return (
    <RegisterEventClickHandlerProvider>
      <BackgroundImageProvider>
        <PageLayout
          type='action'
          title={
            <>
              <LandingPageHeader>
                <LandingPageLogo />
              </LandingPageHeader>
              <EventTitle />
            </>
          }
          withPrioritizedWidgets
          withFixedWidgets
          widgets={<EventDetailsWidget />}
        >
          <EventPageTextContent />
          <EventForm />
        </PageLayout>
      </BackgroundImageProvider>
    </RegisterEventClickHandlerProvider>
  )
}

import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { AutocompleteInput, Col, Row, Text } from '@fullfabric/alma-mater'

import ProfileSearchInput from 'apps/MergeProfiles/components/ProfileSearchInput'
import { useDuplicateProfile } from 'apps/MergeProfiles/contexts/DuplicateProfile'
import { useMainProfile } from 'apps/MergeProfiles/contexts/MainProfile'
import { useMergeData } from 'apps/MergeProfiles/contexts/MergeData'
import arrowImage from './arrow.svg'
import ProfileDetailsCard from './ProfileDetailsCard/index'

import styles from './styles.module.scss'

const ERRORS = {
  sourceProfile: {
    empty: (
      <Trans>
        You must select a duplicate profile to be merged into the main profile
      </Trans>
    )
  }
}

const ProfileSelector = () => {
  const { data: mainProfile, isLoading: isLoadingMainProfile } =
    useMainProfile()

  const { data: duplicateProfile, isLoading: isLoadingDuplicateProfile } =
    useDuplicateProfile()

  const { mergeData, updateMergeData, mergeError, removeMergeError } =
    useMergeData()

  const { t } = useTranslation()

  return (
    <div className={styles.container}>
      <Row nogutter>
        <Col>
          <Text type='h5' fontColor='text-base-darkest' marginBottom='tiny'>
            <Trans>Duplicate profile to delete</Trans>
            <Text type='h6' fontColor='text-info' marginLeft='small' tag='span'>
              <Trans>* required</Trans>
            </Text>
          </Text>
        </Col>

        <Col className={styles.separatorColumn}></Col>

        <Col>
          <Text type='h5' fontColor='text-base-darkest' marginBottom='tiny'>
            <Trans>Main profile to keep</Trans>
          </Text>
        </Col>
      </Row>

      <Row nogutter>
        <Col>
          <Text type='f6' marginBottom='small'>
            <Trans>
              Selected information from this profile will be merged into the
              main profile
            </Trans>
          </Text>
        </Col>

        <Col className={styles.separatorColumn}></Col>

        <Col>
          <Text type='f6' marginBottom='small'>
            <Trans>
              Unless specified otherwise, the information in this profile will
              be maintained
            </Trans>
          </Text>
        </Col>
      </Row>

      <Row nogutter className={styles.profileInputsRow}>
        <Col>
          <ProfileSearchInput
            id='sourceProfileId'
            selectedProfile={mergeData.sourceProfileOption}
            onChange={(profile) => {
              updateMergeData({
                sourceProfileId: profile?.id,
                sourceProfileOption: profile
              })
              profile && removeMergeError('sourceProfile')
            }}
          />
          {ERRORS.sourceProfile[mergeError?.sourceProfile] && (
            <Text type='f6' fontColor='text-alert' marginTop='tiny'>
              {ERRORS.sourceProfile[mergeError.sourceProfile]}
            </Text>
          )}
        </Col>

        <Col className={styles.separatorColumn}>
          <img
            src={arrowImage}
            alt='arrow merge duplicated profile to the main one'
          />
        </Col>

        <Col>
          <AutocompleteInput
            id='mainProfile'
            disabled
            aria-label={t('Main profile to keep')}
            value={mainProfile?.id}
            isLoading={isLoadingMainProfile}
            options={[
              {
                value: mainProfile?.id || '',
                label: mainProfile
                  ? `${mainProfile.full_name} (${mainProfile.email})`
                  : ''
              }
            ]}
          />
        </Col>
      </Row>

      <Row nogutter>
        <Col>
          <ProfileDetailsCard
            profile={duplicateProfile}
            isLoading={isLoadingDuplicateProfile}
          />
        </Col>

        <Col className={styles.separatorColumn}></Col>

        <Col>
          <ProfileDetailsCard
            profile={mainProfile}
            isLoading={isLoadingMainProfile}
          />
        </Col>
      </Row>
    </div>
  )
}

export default ProfileSelector

import getCourse from 'apps/Courses/api/getCourse'
import actionTypes from '../types'

const getCourseAction = (courseId) => async (dispatch, getState) => {
  const {
    courseList: { loaded: coursesLoaded, data: courseList },

    editCourse: { newlyCreated }
  } = getState()

  if (newlyCreated) return

  if (coursesLoaded) {
    const course = courseList.filter((course) => course.id === courseId)[0]
    dispatch({ type: actionTypes.getCourse.success, payload: course })
  } else {
    dispatch({ type: actionTypes.getCourse.start })
    try {
      const course = await getCourse(courseId)
      dispatch({ type: actionTypes.getCourse.success, payload: course })
    } catch (error) {
      dispatch({ type: actionTypes.getCourse.error, payload: error })
    }
  }
}

export default getCourseAction

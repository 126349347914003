.react-app ._chartContainer_2n1tu_1 {
  height: 320px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.react-app ._chartContainer_2n1tu_1 .recharts-text.recharts-label {
  fill: var(--ff-color-text-base-darker);
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
}

.react-app ._customBarChart_2n1tu_15 .recharts-rectangle.recharts-tooltip-cursor {
  fill: #f9f9fa;
}
import React from 'react'

import { Col, Row } from '@fullfabric/alma-mater'

import WeeklyStartedApplicationsChart from './WeeklyStartedApplicsChart'
import WeeklySubmittedApplicationsChart from './WeeklySubmittedApplicsChart'

const WeeklyApplicsChartsRow = () => (
  <Row>
    <Col xs={12} md={6}>
      <WeeklyStartedApplicationsChart />
    </Col>
    <Col xs={12} md={6}>
      <WeeklySubmittedApplicationsChart />
    </Col>
  </Row>
)

export default WeeklyApplicsChartsRow

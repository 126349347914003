import React, { useEffect, useReducer } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

import { isEmpty } from 'lodash-es'

import { Field } from '@fullfabric/alma-mater'
import * as api from '@fullfabric/frontend-api'

import sortStrings from 'shared/utils/sortStrings'

import styles from './styles.module.scss'

async function fetchProgrammesData({ onlyClassesWithStudyPlans }) {
  const data = await api.getProgrammesAsOptions({
    onlyClassesWithStudyPlans,
    programmeLabel: 'name'
  })

  const options = data
    .map((programme) => ({
      label: programme.label,
      value: JSON.parse(programme.value).context_id
    }))
    .sort((contextA, contextB) => sortStrings(contextA.label, contextB.label))

  return options
}

async function fetchClassesAndCampuses({
  chosenProgramme,
  chooseCampus,
  onlyClassesWithStudyPlans
}) {
  const data = await api.getClassesAsOptions({
    programmeId: chosenProgramme.value,
    includeCampuses: chooseCampus,
    onlyClassesWithStudyPlans,
    classLabel: 'name'
  })

  const classOptions = data
    .map((option) => ({
      label: option.label,
      value: JSON.parse(option.value)
    }))
    .filter((data) => data.value.context_type === 'Institutions::ClassOf')
    .map((classOf) => ({
      label: classOf.label,
      value: classOf.value.context_id,
      campus_ids: classOf.value.campus_ids
    }))
    .sort((contextA, contextB) => sortStrings(contextA.label, contextB.label))

  let campusOptions = []
  if (chooseCampus) {
    campusOptions = data
      .map((option) => ({
        label: option.label,
        value: JSON.parse(option.value)
      }))
      .filter(
        (data) =>
          !!data.value.campus_id &&
          !data.value.context_id &&
          !data.value.context_type
      )
      .map((campus) => ({
        label: campus.label,
        value: campus.value.campus_id
      }))
      .sort((contextA, contextB) => sortStrings(contextA.label, contextB.label))
  }

  return { programmeClasses: classOptions, classesCampuses: campusOptions }
}

export default function ProgrammeAndClassSelector({
  onChange,
  chooseCampus,
  onlyClassesWithStudyPlans,
  showErrors
}) {
  const { t } = useTranslation()

  const [{ chosenProgramme, chosenClass, chosenCampus }, dispatch] = useReducer(
    choicesReducer,
    {}
  )

  const { data: programmes = [], isLoading: isLoadingProgrammes } = useQuery(
    ['programmes', onlyClassesWithStudyPlans],
    () => fetchProgrammesData({ onlyClassesWithStudyPlans })
  )

  const {
    data: { programmeClasses = [], classesCampuses = [] } = {},
    isLoading: isLoadingClasses
  } = useQuery(
    ['classesAndCampuses', chosenProgramme, chooseCampus],
    () =>
      fetchClassesAndCampuses({
        chosenProgramme,
        chooseCampus,
        onlyClassesWithStudyPlans
      }),
    {
      enabled: !!chosenProgramme
    }
  )

  const chosenClassCampuses =
    chooseCampus &&
    !!chosenClass &&
    getChosenClassCampuses(chosenClass, classesCampuses)

  const shouldChooseACampus = chooseCampus && !isEmpty(chosenClassCampuses)

  useEffect(() => {
    if (!chosenClass) {
      onChange(false, {})
    } else {
      const choice = {
        class_of_id: chosenClass.value
      }

      let isValid = true
      let campusChoice = {}

      if (shouldChooseACampus) {
        isValid = !!chosenCampus && !!chosenCampus.value
        campusChoice = isValid ? { campus_id: chosenCampus.value } : {}
      }

      onChange(isValid, { ...choice, ...campusChoice })
    }
  }, [
    onChange,
    chosenProgramme,
    chosenClass,
    shouldChooseACampus,
    chosenCampus
  ])

  return (
    <div className={styles.programmeAndClassSelector}>
      <Field
        required
        id='chosenProgramme'
        type='autocomplete'
        label={<Trans>Programme</Trans>}
        isLoading={isLoadingProgrammes}
        className={styles.choiceDropdown}
        error={
          showErrors &&
          !chosenProgramme && <Trans>This field is required</Trans>
        }
        value={chosenProgramme ? chosenProgramme.value : ''}
        onChange={(_, programmeId) =>
          dispatch({
            type: 'CHANGE_PROGRAMME',
            data: programmes.find(({ value }) => value === programmeId)
          })
        }
        inputOptions={{
          searchable: true,
          clearable: false,
          placeholder: t('Select an option'),
          options: programmes
        }}
      />
      <Field
        required
        id='chosenClass'
        type='autocomplete'
        label={<Trans>Class</Trans>}
        isLoading={isLoadingClasses}
        className={styles.choiceDropdown}
        error={
          showErrors && !chosenClass && <Trans>This field is required</Trans>
        }
        value={chosenClass ? chosenClass.value : ''}
        onChange={(_, classId) =>
          dispatch({
            type: 'CHANGE_CLASS',
            data: programmeClasses.find(({ value }) => value === classId)
          })
        }
        inputOptions={{
          searchable: true,
          clearable: false,
          placeholder: chosenProgramme ? t('Select an option') : '',
          options: programmeClasses.map((ctx) => ({
            ...ctx
          })),
          disabled: !chosenProgramme
        }}
      />
      {shouldChooseACampus && (
        <Field
          required
          id='chosenCampuse'
          type='autocomplete'
          label={<Trans>Campus</Trans>}
          isLoading={isLoadingClasses}
          className={styles.choiceDropdown}
          error={
            showErrors &&
            shouldChooseACampus &&
            !chosenCampus && <Trans>This field is required</Trans>
          }
          value={chosenCampus ? chosenCampus.value : ''}
          onChange={(_, campusId) =>
            dispatch({
              type: 'CHANGE_CAMPUS',
              data: classesCampuses.find(({ value }) => value === campusId)
            })
          }
          inputOptions={{
            searchable: true,
            clearable: false,
            placeholder: t('Select an option'),
            options: chosenClassCampuses
          }}
        />
      )}
    </div>
  )
}

const choicesReducer = (state, action) => {
  switch (action.type) {
    case 'CHANGE_PROGRAMME':
      return {
        ...state,
        chosenProgramme: action.data,
        chosenClass: null,
        chosenCampus: null
      }
    case 'CHANGE_CLASS':
      return {
        ...state,
        chosenClass: action.data,
        chosenCampus: null
      }
    case 'CHANGE_CAMPUS':
      return {
        ...state,
        chosenCampus: action.data
      }
    case 'SUBMIT':
      return {}
    default:
      return state
  }
}

const getChosenClassCampuses = (chosenClass, classesCampuses) =>
  classesCampuses.filter((campus) =>
    chosenClass.campus_ids.includes(campus.value)
  )

import React from 'react'
import { Trans } from 'react-i18next'

const subjectAccountabilityValues = [
  {
    label: <Trans>Yes if it's a pass, No if grade is a fail</Trans>,
    value: 'depends_on_pass_fail'
  },
  {
    label: <Trans>Yes</Trans>,
    value: 'yes'
  },
  {
    label: <Trans>No</Trans>,
    value: 'no'
  }
]

export default subjectAccountabilityValues

import React from 'react'

function ArrowUpIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='12'
      height='12'
      fill='none'
      viewBox='0 0 12 12'
    >
      <path fill='#1E805F' d='M7 3l3 3 1-1-5-5h.029L1 5l1 1 3-3v9h2V3z'></path>
    </svg>
  )
}

export default ArrowUpIcon

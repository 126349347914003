import React from 'react'
import { useTranslation } from 'react-i18next'

import { Alert } from '@fullfabric/alma-mater'

import { useClassesData } from 'apps/Reports/components/ProductOverviewReport/ClassOptionsContext'
import { useFilter } from 'apps/Reports/components/ProductOverviewReport/FilterContext'

const WarningMessage = () => {
  const { t } = useTranslation()

  const filter = useFilter()
  const selectedClasses = filter?.classes
  const allClasses = useClassesData()

  const hasSelectedClasses = selectedClasses?.length > 0
  const hasClasses = allClasses?.length > 0

  if (!hasSelectedClasses || !hasClasses) {
    return null
  }

  const selectedClassesData = allClasses.filter((classOf) =>
    selectedClasses.includes(classOf.id)
  )

  // const allSelectedIntakesHaveRequiredDates = selectedClassesData.every(
  //   (selectedClass) =>
  //     selectedClass.opens_on &&
  //     selectedClass.closes_on &&
  //     selectedClass.starts_on
  // );

  const someSelectedIntakeHaveMissingDates = selectedClassesData.some(
    (selectedClass) =>
      !selectedClass.opens_on ||
      !selectedClass.closes_on ||
      !selectedClass.starts_on
  )

  if (!someSelectedIntakeHaveMissingDates) return null

  return (
    <Alert
      error
      title={t('Some intakes are missing dates.')}
      marginBottom='default'
      description={t('Please add dates to the intakes.')}
    />
  )
}

export default WarningMessage

import React, { useEffect, useState } from 'react'
import { animated, useSpring } from 'react-spring'

import { FeedbackMessage } from '@fullfabric/alma-mater'

import styles from './styles.module.scss'

function CourseEditSuccessPopup({ isUp, hidePopup }) {
  const [wasUp, setWasUp] = useState(false)
  const animationStyles = useSpring({ bottom: isUp ? '0px' : '-150px' })

  useEffect(() => {
    if (isUp && !wasUp) {
      setTimeout(() => {
        hidePopup()
      }, 3000)
    }

    setWasUp(isUp)
  }, [isUp, wasUp, hidePopup])

  return (
    <animated.div
      aria-hidden={!isUp}
      className={styles.successPopup}
      style={animationStyles}
    >
      <FeedbackMessage isOpen type='success' message='Changes saved' />
    </animated.div>
  )
}

export default CourseEditSuccessPopup

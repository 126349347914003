import { utils } from '@fullfabric/public-api'

export default async function revokeExternalPayerRequest({
  externalPayerRequestId
}) {
  const response = await fetch(
    `/book_keeper/api/external_payer_requests/${externalPayerRequestId}`,
    {
      credentials: 'same-origin',
      headers: { 'Content-Type': 'application/json' },
      method: 'DELETE'
    }
  )

  return await utils.checkResponse(response)
}

import getClassNationalityDistribution from './getClassNationalityDistribution'

const getMultipleClassNationalityDistribution = async ({
  classIds,
  ...params
}) => {
  const response = await Promise.all(
    classIds.map((classId) =>
      getClassNationalityDistribution({ classId, ...params })
    )
  )

  return response
}

export default getMultipleClassNationalityDistribution

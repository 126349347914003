import React from 'react'
import { useFormContext } from 'react-hook-form'
import { Trans } from 'react-i18next'

import { Field } from '@fullfabric/alma-mater'

function ExternalIdField() {
  const { register } = useFormContext()
  const { ref: externalIdInputRef, ...externalIdInputProps } =
    register('external_id')

  return (
    <Field
      type='text'
      label={<Trans>External ID</Trans>}
      inputRef={externalIdInputRef}
      {...externalIdInputProps}
    />
  )
}

export default ExternalIdField

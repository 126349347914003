import React from 'react'
import { useTranslation } from 'react-i18next'

import moment from 'moment'

import { useCurrentUser } from '@fullfabric/authorization-officer'

import AutoloadDataTable from 'shared/components/AutoloadDataTable'

import CreatedByCell from './CreatedByCell'
import MoreOptionsCell from './MoreOptionsCell'
import useCreatedByFilter from './useCreatedByFilter'

const FILTER_STORAGE_KEY = 'shared_segments:filter:'

export default function SegmentsTable() {
  const user = useCurrentUser()
  const createdByFilter = useCreatedByFilter()
  const { t, i18n } = useTranslation()

  return (
    <AutoloadDataTable
      fixedLayout
      fetchURL={'/api/segments_table'}
      reactQueryProps={{ staleTime: 0 }}
      emptyMessage={t('No data')}
      columns={[
        {
          Header: t('Name'),
          accessor: 'name',
          type: 'primary',
          flexibleWidth: true,
          linkTo: ({ row, data }) => ({
            href: `/segments#${data[row.index].id}/builder`,
            target: '_parent'
          })
        },
        {
          Header: t('Created by'),
          accessor: 'created_by_name',
          width: 160,
          Cell: CreatedByCell
        },
        {
          Header: t('Created at'),
          accessor: 'created_at',
          width: 260,
          Cell: ({ value }) => {
            if (!value) return ''

            const createdAtMoment = moment(value).locale(i18n.language)
            return createdAtMoment.format('ddd DD MMM, YYYY hh:mm a')
          }
        },
        {
          Header: '',
          accessor: 'id',
          width: 60,
          disableSortBy: true,
          Cell: MoreOptionsCell
        }
      ]}
      loadMoreProps={{
        sizeKey: 'limit',
        offsetKey: 'offset'
      }}
      fetchResponseParser={(response) => {
        const pagination = JSON.parse(
          response.headers['x-fullfabric-json'] || '{}'
        )
        return { ...pagination, data: response.data }
      }}
      sortable
      sortParams={{
        sortKey: 'sort[field]',
        directionKey: 'sort[direction]',
        ascValue: 1,
        descValue: -1
      }}
      initialSort={{
        'sort[field]': 'created_at',
        'sort[direction]': -1
      }}
      searchable
      filterable
      filterStorageKey={`${FILTER_STORAGE_KEY}${user.id}`}
      filters={[
        createdByFilter,
        {
          id: 'show_workflows',
          name: 'Workflow',
          labelValueSeparator: ':',
          type: 'single',
          defaultValue: 'all',
          options: [
            {
              label: 'All',
              value: 'all'
            },
            {
              label: 'Has a workflow',
              value: 'only_workflows'
            },
            {
              label: 'Does not have a workflow',
              value: 'no_workflows'
            }
          ]
        }
      ]}
    />
  )
}

import React from 'react'

import { Loading, Text } from '@fullfabric/alma-mater'

import styles from './styles.module.scss'

const InfoRow = ({ main, secondary }) => {
  return (
    <div className={styles.infoRow}>
      <Text type='h5' fontColor='text-base-darkest' marginBottom='tiny'>
        {main}
      </Text>
      {secondary && <Text>{secondary}</Text>}
    </div>
  )
}

export const InfoRowLoading = () => (
  <div className={styles.infoRow}>
    <Loading />
  </div>
)

export default InfoRow

import React from 'react'
import { useMutation } from 'react-query'
import { useHistory } from 'react-router'

import updateImport from 'apps/Imports/api/update-import'
import {
  useImportDocument,
  useSetImportDocumentData
} from 'apps/Imports/contexts/import-document'
import { useSelectedData } from 'apps/Imports/contexts/selected-data'
import { useCommunicationPolicies } from 'apps/Imports/hooks/useCommunicationPolicies'
import { useDataProcessingPolicy } from 'apps/Imports/hooks/useDataProcessingPolicy'
import { useImportContext } from 'apps/Imports/hooks/useImportContext'
import { useMarketingPolicy } from 'apps/Imports/hooks/useMarketingPolicy'
import getProfileImportFormData from 'apps/Imports/utils/profile-import/get-profile-import-form-data'

const useUpdateImportMutation = () => {
  const history = useHistory()
  const context = useImportContext()
  const marketingPolicy = useMarketingPolicy()
  const dataProcessingPolicy = useDataProcessingPolicy()
  const communicationPolicies = useCommunicationPolicies()
  const { data } = useSelectedData()
  const importDocument = useImportDocument()
  const {
    setImportDocument,

    setImportErrors
  } = useSetImportDocumentData()

  const goToImportLoadingPage = React.useCallback(() => {
    history.push(`/import/${importDocument.id}/loading`)
  }, [history, importDocument])

  return useMutation(
    async () => {
      const formData = await getProfileImportFormData({
        data,
        context,
        marketingPolicy,
        dataProcessingPolicy,
        communicationPolicies
      })

      return await updateImport(importDocument.id, formData)
    },
    {
      onSuccess: (updatedImportDocument) => {
        setImportErrors(null)
        setImportDocument(updatedImportDocument)

        goToImportLoadingPage()
      },
      onError: (error) => {
        setImportErrors(error)
      }
    }
  )
}

export default useUpdateImportMutation

import React from 'react'
import { Controller, useController, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Col, Field, Row, Text } from '@fullfabric/alma-mater'

import { areDatesCompatible, getInvalidDateError } from './utils'

import styles from './styles.module.scss'

const ContractForm = ({ withError }) => {
  const { t } = useTranslation()
  const { formState, control, getValues } = useFormContext()
  const { errors } = formState

  const {
    field: { ref: periodicityInputRef, ...periodicityInputProps }
  } = useController({
    name: 'billing_periodicity',
    control,
    rules: { required: t("Can't be blank") }
  })

  return (
    <>
      <form id='create-contract-modal-form'>
        <Row nowrap>
          <Col xs={6}>
            <Controller
              control={control}
              name='start_date'
              rules={{
                required: t("Can't be blank"),
                validate: {
                  validDate: (v) => getInvalidDateError(v, t),
                  compatibleDate: (v) =>
                    areDatesCompatible(
                      getValues('start_date'),
                      getValues('end_date')
                    )
                      ? null
                      : t('Start date must be before end date')
                }
              }}
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { error }
              }) => {
                return (
                  <Field
                    inputOptions={{
                      dateFormat: 'DD/MM/YYYY'
                    }}
                    id={name}
                    name={name}
                    value={value}
                    onBlur={(_, val) => onBlur(val)}
                    onChange={(_, val) => onChange(val)}
                    type='date'
                    className={styles.datePicker}
                    label={t('Start date')}
                    error={error?.message}
                  />
                )
              }}
            />
          </Col>

          <Col xs={6}>
            <Controller
              control={control}
              name='end_date'
              rules={{
                required: t("Can't be blank"),
                validate: {
                  validDate: (v) => getInvalidDateError(v, t),
                  compatibleDate: () => {
                    return areDatesCompatible(
                      getValues('start_date'),
                      getValues('end_date')
                    )
                      ? null
                      : t('End date must be after start date')
                  }
                }
              }}
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { error }
              }) => {
                return (
                  <Field
                    id={name}
                    name={name}
                    value={value}
                    onBlur={(_, val) => onBlur(val)}
                    onChange={(_, val) => onChange(val)}
                    inputOptions={{
                      dateFormat: 'DD/MM/YYYY'
                    }}
                    type='date'
                    className={styles.datePicker}
                    label={t('End date')}
                    error={error?.message}
                  />
                )
              }}
            />
          </Col>
        </Row>

        <Field
          {...periodicityInputProps}
          inputRef={periodicityInputRef}
          type='radio'
          label={t('Periodicity')}
          inputOptions={[
            {
              label: t('Monthly'),
              value: 'monthly'
            },
            {
              label: t('Quarterly'),
              value: 'quarterly'
            },
            {
              label: t('Yearly with quarterly reconciliation'),
              value: 'yearly'
            }
          ]}
          error={errors[periodicityInputProps.name]?.message}
        />
      </form>

      {withError ? (
        <Text fontColor='text-alert' type='f5' marginTop='default'>
          {t(
            'There was an error, please try again in a few seconds. If the issue persists, please contact us.'
          )}
        </Text>
      ) : null}
    </>
  )
}

export default ContractForm

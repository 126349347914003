import React from 'react'
import { Trans } from 'react-i18next'

import PrivateWidget from '../private'

const AuthorizationWrapper = ({ id, title, forRegistrations, children }) => (
  <PrivateWidget
    id={id}
    title={title}
    authorizationMessage={
      forRegistrations ? (
        <Trans>To access registrations please register or login.</Trans>
      ) : (
        <Trans>To access applications please register or login.</Trans>
      )
    }
  >
    {children}
  </PrivateWidget>
)

export default AuthorizationWrapper

import React from 'react'

const useChartWidth = () => {
  const [chartWidth, setChartWidth] = React.useState(0)

  const measurerRef = React.useCallback((node) => {
    if (node) setChartWidth(node.offsetWidth - 16)
  }, [])

  return { chartWidth, measurerRef }
}

export default useChartWidth

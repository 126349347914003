import { connect } from 'react-redux'

import actionTypes from 'apps/Courses/actions/types'
import CourseEditDisplayCapacity from 'apps/Courses/components/CourseEditDisplayCapacity'

const mapStateToProps = ({ editCourse: { data } }) => ({ data })

const mapDispatchToProps = (dispatch) => ({
  onEditClick: (course) =>
    dispatch({
      type: actionTypes.editCapacitySectionData,
      payload: {
        minimum_capacity: course.minimum_capacity,
        maximum_capacity: course.maximum_capacity,
        target_capacity: course.target_capacity
      }
    })
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CourseEditDisplayCapacity)

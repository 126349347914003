import React from 'react'
import { Trans } from 'react-i18next'

import { Loading, Text } from '@fullfabric/alma-mater'

import classNames from 'classnames'

export const BaseCardInfoRow = ({ title, children }) => {
  return (
    <div className={classNames('marginTop-default', 'paddingLeft-more')}>
      <Text type='h6'>{title}</Text>

      {children}
    </div>
  )
}

const CardInfoRow = ({ title, value, secondaryValue, isLoading }) => {
  return (
    <BaseCardInfoRow title={title}>
      {isLoading && <Loading />}

      {!isLoading &&
        (value ? (
          <Text type='h5' fontColor='text-base-darkest' marginTop='tiny'>
            {value}
            {secondaryValue && (
              <Text tag='span' type='f5' marginLeft='tiny'>
                {secondaryValue}
              </Text>
            )}
          </Text>
        ) : (
          <Text type='f5' marginTop='tiny'>
            <Trans>No value</Trans>
          </Text>
        ))}
    </BaseCardInfoRow>
  )
}

export default CardInfoRow

import React from 'react'
import { useTranslation } from 'react-i18next'

import { PageHead } from '@fullfabric/alma-mater'

const Head = () => {
  const { t } = useTranslation()
  return <PageHead noBorder title={t('Reports')} />
}

export default Head

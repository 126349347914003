import { useSettings } from '@fullfabric/authorization-officer'

const useSettingByName = (settingName) => {
  const settings = useSettings()
  const settingsHash = settings?.hash || {}

  const value =
    settingName.split('.').reduce((settingNestedHash, settingLevel) => {
      return settingNestedHash && settingNestedHash[settingLevel]
    }, settingsHash) || null

  return value
}

export default useSettingByName

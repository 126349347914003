import React from 'react'
import { useParams } from 'react-router-dom'

import FullscreenV2 from 'shared/components/FullscreenV2'

import EmailEditor from 'apps/EmailTemplates/components/EmailEditor'

export default function EmailEditorPage() {
  const { id } = useParams()

  return (
    <FullscreenV2>
      <EmailEditor id={id} />
    </FullscreenV2>
  )
}

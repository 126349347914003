import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'

import { Field } from '@fullfabric/alma-mater'

import TemplateWizardNavigation from 'apps/Applics/components/TemplateWizardNavigation'
import {
  useSetWizardData,
  useWizardData
} from 'apps/Applics/contexts/WizardDataContext'
import { useWizardNavigationState } from 'apps/Applics/contexts/WizardNavigationContext'

import styles from './styles.module.scss'

const Form = () => {
  const { t } = useTranslation()

  const history = useHistory()
  const { currentStep } = useWizardNavigationState()

  const { name: initialValue } = useWizardData()
  const setWizardData = useSetWizardData()
  const {
    control,
    errors,
    handleSubmit: handleFormValues
  } = useForm({
    mode: 'onBlur'
  })

  const handleSubmit = async (evt) => {
    evt.preventDefault()

    await handleFormValues(async () => {
      history.push(`/templates/new/step/${currentStep + 1}`)
    })(evt)
  }

  return (
    <>
      <form
        data-testid='template-name-form'
        id='template-name-form'
        onSubmit={handleSubmit}
        className={styles.formContainer}
        noValidate
      >
        <Controller
          control={control}
          name='name'
          defaultValue={initialValue || ''}
          rules={{
            required: t('This field is required')
          }}
          render={({ field: { onChange, onBlur, value } }) => (
            <Field
              id='name'
              type='text'
              required
              label={t('Application form name')}
              value={value}
              error={errors?.name?.message}
              onChange={(id, value) => {
                setWizardData((data) => ({ ...data, name: value }))
                onChange(value)
              }}
              onBlur={(id, value) => onBlur(value)}
            />
          )}
        />
      </form>
      <TemplateWizardNavigation targetFormId='template-name-form' />
    </>
  )
}

export default Form

import React from 'react'
import { Trans } from 'react-i18next'

import { Text } from '@fullfabric/alma-mater'

import useIsMobileLayout from 'apps/ContentPages/components/payments-page/use-is-payments-mobile-layout'
import formatPrice from 'apps/ContentPages/utils/format-price'

import classNames from 'classnames'
import styles from './styles.module.scss'

export const getTotalPrice = (plan) => {
  const totalValue = plan.total_amount
  const currency = plan.fees[0]?.currency

  return `${currency} ${formatPrice(totalValue / 100)}`
}

const Header = ({ paymentPlan, opts = {} }) => {
  const isInMobileLayout = useIsMobileLayout()

  return (
    <div
      className={classNames(styles.header, isInMobileLayout && styles.mobile)}
    >
      <Text
        tag='div'
        role='heading'
        aria-level='3'
        type={isInMobileLayout ? 'h4' : 'h3'}
        fontColor='text-base-darkest'
        className={styles.title}
        {...(isInMobileLayout ? { marginBottom: 'tiny' } : {})}
      >
        <Text
          tag='span'
          type={isInMobileLayout ? 'h6' : 'f3'}
          fontColor='text-base-darker'
          {...(isInMobileLayout
            ? { marginBottom: 'tiny', style: { display: 'block' } }
            : { marginRight: 'small' })}
        >
          <Trans>Payment plan for</Trans>{' '}
        </Text>
        {paymentPlan.context_long_name}
      </Text>
      {!opts.hideTotalAmountDue && (
        <Text
          type={isInMobileLayout ? 'f4' : 'f3'}
          fontColor={
            isInMobileLayout ? 'text-base-darker' : 'text-base-darkest'
          }
        >
          {getTotalPrice(paymentPlan)}
        </Text>
      )}
    </div>
  )
}

export default Header

.react-app ._page_16x4c_1 {
  background-color: var(--ff-bg-base-lighter);
}

.react-app ._successPopup_16x4c_5 {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.react-app ._createAnotherButton_16x4c_12 {
  margin-left: var(--space-small);
}
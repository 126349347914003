import React, { useState } from 'react'
import { Trans } from 'react-i18next'

import Tippy from '@tippyjs/react'
import { Text, useScreenClass } from '@fullfabric/alma-mater'

import useIsMobileLayout from 'apps/ContentPages/hooks/use-is-mobile-layout'
import AlertIcon from './alert-icon'
import ArrowIcon from './arrow-icon'
import ResendVerificationEmail from './resend-verification-email'
import { hideEmail } from './utils'

import classNames from 'classnames'
import styles from './styles.module.scss'

const EmailValidationPopUp = ({ user }) => {
  const screenClass = useScreenClass()
  const isMobileLayout = useIsMobileLayout()
  const [open, setOpen] = useState(false)

  return (
    <Tippy
      duration={100}
      interactive
      trigger='click'
      placement='right-end'
      theme={styles.tippy}
      onHide={() => {
        setOpen(false)
      }}
      onShow={() => {
        setOpen(true)
      }}
      content={
        <div className={styles.info}>
          <div className={styles.alertCol}>
            <span className={styles.icon}>
              <AlertIcon />
            </span>

            <Text
              type='h4'
              fontColor='text-alert'
              tag='span'
              className={styles.hover}
            >
              <Trans>Email not verified</Trans>
            </Text>
          </div>

          <div className={styles.textRow}>
            <div className={styles.textCol}>
              <Text type='f5' fontColor='text-base-darkest'>
                <Trans>
                  A verification email was sent to{' '}
                  {{ email: hideEmail(user.email) }}.
                </Trans>
              </Text>

              <Text type='f5' fontColor='text-base-darkest'>
                <Trans>Please check your email.</Trans>
              </Text>
            </div>
          </div>
          <div>
            <div className={styles.alertCol}>
              <ResendVerificationEmail />
            </div>
          </div>
        </div>
      }
      popperOptions={{
        modifiers: [
          { name: 'preventOverflow', enabled: false },
          { name: 'hide', enabled: false }
        ]
      }}
    >
      <button className={styles.menuToggle}>
        <div
          className={classNames([
            styles.link,
            screenClass === 'md' && styles.tablet,
            open && styles.open
          ])}
        >
          <span
            className={classNames([
              styles.icon,
              isMobileLayout && styles.mobile
            ])}
          >
            <AlertIcon />
          </span>
          <Text
            type='h6'
            fontColor={open ? 'text-base-lightest' : 'text-alert'}
            tag='span'
            className={styles.textContainer}
          >
            <Trans>Email not verified</Trans>
            <ArrowIcon />
          </Text>
        </div>
      </button>
    </Tippy>
  )
}

export default EmailValidationPopUp

import { routerMiddleware } from 'connected-react-router'
import { createBrowserHistory, createMemoryHistory } from 'history'
import { applyMiddleware, compose, createStore } from 'redux'
import thunk from 'redux-thunk'

import createRootReducer from '../../reducers'

const createReduxStore = (initialState, memoryHistoryOptions = {}) => {
  const history =
    process.env.NODE_ENV !== 'test'
      ? createBrowserHistory()
      : createMemoryHistory(memoryHistoryOptions)
  const middlewares = [thunk, routerMiddleware(history)]

  if (process.env.NODE_ENV === 'development') {
    const { createLogger } = require('redux-logger')
    const logger = createLogger({
      duration: true,
      collapsed: true,
      diff: true
    })

    middlewares.push(logger)
    middlewares.push(require('redux-immutable-state-invariant').default())
  }

  const store = createStore(
    createRootReducer(history),
    initialState,
    compose(
      applyMiddleware(...middlewares),
      window.__REDUX_DEVTOOLS_EXTENSION__
        ? window.__REDUX_DEVTOOLS_EXTENSION__()
        : (f) => f
    )
  )

  return { history, store }
}

export default createReduxStore

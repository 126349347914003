import React from 'react'

import SuccessIcon from './SuccessIcon'

import classNames from 'classnames'
import styles from './styles.module.scss'

const Toggle = ({ onChange, isActive, alwaysActive }) => {
  const isDisabled = !onChange

  return (
    <div
      data-testid='tab-toggle'
      className={classNames([styles.container, isDisabled && styles.disabled])}
      onClick={onChange}
    >
      <div
        className={classNames([
          styles.background,
          alwaysActive
            ? styles.alwaysActive
            : isActive
            ? styles.active
            : styles.inactive,
          isDisabled && styles.disabled
        ])}
      />
      <div
        className={classNames([
          styles.toggleCirclePositioner,
          (isActive || alwaysActive) && styles.active
        ])}
      >
        <SuccessIcon isActive={isActive} alwaysActive={alwaysActive} />
      </div>
    </div>
  )
}

export default Toggle

import React, { useState } from 'react'
import { Trans } from 'react-i18next'
import { useMutation } from 'react-query'

import { AddIcon, Button, Link } from '@fullfabric/alma-mater'

import { useOnChangesSaved } from 'apps/StudyPlans/contexts/ChangesSaved'
import { useSetSettingsSectionsBeingEdited } from 'apps/StudyPlans/contexts/SettingsSectionsBeingEdited'
import {
  useSetStudyPlan,
  useStudyPlan
} from 'apps/StudyPlans/contexts/StudyPlanData'
import saveCategories from './saveCategories'
import SingleCategoryForm from './SingleCategoryForm'
import { categoryHasErrors, getCategoryPlaceholder } from './utils'

const CategoriesUpdateContext = React.createContext()
export const useUpdatedCategories = () =>
  React.useContext(CategoriesUpdateContext)

const CategoriesForm = () => {
  const studyPlan = useStudyPlan()
  const setStudyPlan = useSetStudyPlan()
  const onChangesSaved = useOnChangesSaved()
  const setSectionsBeingEdited = useSetSettingsSectionsBeingEdited()
  const { mutate: updateCategoriesMutation, isLoading } = useMutation(
    saveCategories,
    {
      onSuccess: (categories) => {
        setStudyPlan({ ...studyPlan, categories })
        onChangesSaved()
        setSectionsBeingEdited((sections) => ({
          ...sections,
          categories: false
        }))
      }
    }
  )

  const [categories, setCategories] = useState(
    (studyPlan.categories || []).length === 0
      ? getCategoryPlaceholder()
      : studyPlan.categories.reduce(
          (hashed, curr) => ({ ...hashed, [curr.id]: curr }),
          {}
        )
  )

  const onSaveChanges = React.useCallback(() => {
    if (Object.values(categories).some(categoryHasErrors)) return

    updateCategoriesMutation({
      studyPlanId: studyPlan.id,
      updatedCategories: Object.values(categories).map(
        ({ edited, ...categoryData }) => categoryData
      ),
      previousCategories: studyPlan.categories
    })
  }, [updateCategoriesMutation, studyPlan, categories])

  const onCancel = React.useCallback(() => {
    setCategories(
      (studyPlan.categories || []).length === 0
        ? getCategoryPlaceholder()
        : studyPlan.categories.reduce(
            (hashed, curr) => ({ ...hashed, [curr.id]: curr }),
            {}
          )
    )
    setSectionsBeingEdited((sections) => ({
      ...sections,
      categories: false
    }))
  }, [setSectionsBeingEdited, setCategories, studyPlan])

  const noneIsEmpty = React.useMemo(
    () =>
      Object.values(categories).every(
        ({ name, credits }) => name || credits >= 0
      ),
    [categories]
  )

  const onAdd = React.useCallback(
    (evt) => {
      evt.preventDefault()

      if (!noneIsEmpty) return

      setCategories((categories) => ({
        ...categories,
        ...getCategoryPlaceholder()
      }))
    },
    [noneIsEmpty]
  )

  return (
    <CategoriesUpdateContext.Provider value={{ categories, setCategories }}>
      {Object.values(categories).map((category) => (
        <SingleCategoryForm key={category.id} category={category} />
      ))}
      <Link disabled={!noneIsEmpty} onClick={onAdd}>
        <Trans>Add another</Trans> <AddIcon />
      </Link>

      <div className='marginTop-more'>
        <Button
          primary
          size='small'
          loading={isLoading}
          disabled={isLoading}
          onClick={onSaveChanges}
        >
          <Trans>Save changes</Trans>
        </Button>
        <Button
          size='small'
          disabled={isLoading}
          onClick={onCancel}
          className='marginLeft-small'
        >
          <Trans>Cancel</Trans>
        </Button>
      </div>
    </CategoriesUpdateContext.Provider>
  )
}

export default CategoriesForm

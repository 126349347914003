.react-app ._legendContainer_1xn3f_1 {
  list-style: none;
  margin: 0;
  padding-left: 0;
  display: flex;
}

.react-app ._itemContainer_1xn3f_8 {
  display: inline-flex;
  align-items: center;
  margin-right: var(--space-default);
}

.react-app ._itemIcon_1xn3f_14,
.react-app ._comparisonItemIcon_1xn3f_15 {
  height: 4px;
  width: 12px;
  border-radius: 2px;
  margin-right: var(--space-tiny);
  display: inline-block;
}

.react-app ._circleIcon_1xn3f_23 {
  height: 8px;
  width: 8px;
  border-radius: 50%;
  margin-right: var(--space-tiny);
  display: inline-block;
}

.react-app ._comparisonItemIcon_1xn3f_15 {
  width: 6px;
  margin-right: 2px;
}
.react-app ._comparisonItemIcon_1xn3f_15._last_1xn3f_35 {
  margin-right: var(--space-tiny);
}

.react-app ._barChartLegend_1xn3f_39 ._itemIcon_1xn3f_14 {
  height: 8px;
  width: 8px;
}
import React from 'react'

import { useImportErrors } from 'apps/Imports/contexts/import-document'
import ProfileImportFormDataError from 'apps/Imports/errors/profile-import-form-data-error'
import UploadedFileError from 'apps/Imports/errors/uploaded-file-error'
import FormDataErrorAlert from './form-data-error-alert'
import ImportCreationErrorAlert from './import-creation-error-alert'
import UploadedFileErrorAlert from './uploaded-file-error-alert'

const ErrorSection = () => {
  const profileImportError = useImportErrors()

  if (profileImportError instanceof UploadedFileError)
    return <UploadedFileErrorAlert />

  if (profileImportError instanceof ProfileImportFormDataError)
    return <FormDataErrorAlert />

  return <ImportCreationErrorAlert />
}

export default ErrorSection

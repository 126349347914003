/* eslint-disable camelcase */

import React from 'react'
import { Trans } from 'react-i18next'

import PropTypes from 'prop-types'

import {
  ActionList,
  Button,
  Col,
  Label,
  Link,
  MoreIcon,
  Row,
  Text
} from '@fullfabric/alma-mater'

import { useHandleArchive } from 'apps/Inbox/hooks/handleArchiveHook'
import { formRoute } from 'apps/Inbox/utils/cardUtils'
import InboxFormCardDescriptor from '../InboxFormCardDescriptor'

import styles from './styles.module.scss'

export default function InboxFormCard({ card }) {
  const handleArchived = useHandleArchive()

  const {
    id: cardId,
    entity: {
      name,
      id: formId,
      sub_type_entity: { submission_id: submissionId }
    },
    status,
    status_updated_by: statusUpdatedBy,
    status_updated_at: statusUpdatedAt,
    actor,
    updated_at
  } = card

  return (
    <div key={cardId} className={styles.formCard}>
      <Row middle='xs'>
        <Col xs={9} className={styles.titleContainer}>
          <Label color='grey-500'>
            <Trans>Form</Trans>
          </Label>

          <Text
            className={styles.title}
            tag='span'
            type='h4'
            marginLeft='small'
            fontColor='text-base-darkest'
          >
            {name || <Trans>(no name)</Trans>}
          </Text>

          <InboxFormCardDescriptor
            status={status}
            statusUpdatedBy={statusUpdatedBy}
            statusUpdatedAt={statusUpdatedAt}
            actor={actor}
            updatedAt={updated_at}
          />
        </Col>

        <Col xs={3}>
          <Row middle='xs' end='xs'>
            <Col xs={12} className={styles.buttons}>
              <Link
                disabled
                href={formRoute(actor.id, formId, submissionId)}
                target='_top'
              >
                <Button marginRight='tiny' size='small'>
                  <Trans>View submission</Trans>
                </Button>
              </Link>

              <ActionList
                iconButton={<MoreIcon />}
                iconButtonProps={{ small: true }}
                menuPosition='down'
                wrapperClassName={styles.actionListWrapper}
              >
                <ActionList.Option onClick={() => handleArchived({ card })}>
                  {status === 'active' ? (
                    <Trans>Archive</Trans>
                  ) : (
                    <Trans>Restore</Trans>
                  )}
                </ActionList.Option>
              </ActionList>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  )
}

InboxFormCard.propTypes = {
  card: PropTypes.shape({
    id: PropTypes.string,
    entity: PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.string,
      sub_type_entity: PropTypes.shape({
        submission_id: PropTypes.string
      })
    }),
    status: PropTypes.string,
    status_updated_by: PropTypes.shape({
      full_name: PropTypes.string
    }),
    status_updated_at: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    actor: PropTypes.shape({
      id: PropTypes.string,
      full_name: PropTypes.string
    }),
    updated_at: PropTypes.string
  }).isRequired
}

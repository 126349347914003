import React from 'react'

import debounce from 'lodash.debounce'

const DEFAULT_SEARCH_DELAY = 500

const useTableSearch = (initialSearch, searchParamKey, onSearchChanged) => {
  const [searchParams, setSearchParams] = React.useState(initialSearch || {})

  React.useEffect(() => {
    onSearchChanged && onSearchChanged(searchParams)
  }, [onSearchChanged, searchParams])

  const handleChangeSearch = debounce((searchValue) => {
    const hasMinimumCharacters = searchValue?.length > 1
    const hasClearedSearch = !searchValue?.length

    if (hasMinimumCharacters) {
      setSearchParams({ [searchParamKey]: searchValue })
    } else if (hasClearedSearch) {
      setSearchParams({})
    }
  }, DEFAULT_SEARCH_DELAY)

  return { handleChangeSearch, searchState: searchParams }
}

export default useTableSearch

import React from 'react'
import { Trans } from 'react-i18next'

import { Row, Text } from '@fullfabric/alma-mater'

import classNames from 'classnames'
import styles from './styles.module.scss'

const CardTitle = ({ big, summaryType, title, subtitle, notice }) => {
  return (
    <div
      className={classNames(
        'paddingLeft-more',
        !big && 'paddingTop-more',
        'paddingRight-default'
      )}
    >
      {summaryType && (
        <Text
          type='h6'
          marginBottom='tiny'
          fontColor={summaryType === 'added' ? 'text-success' : 'text-alert'}
          className={styles.summaryType}
        >
          {summaryType === 'added' ? (
            <Trans>!Added!</Trans>
          ) : (
            <Trans>!Discarded!</Trans>
          )}
        </Text>
      )}

      <Row nogutter justify='between'>
        <Text type={big ? 'f3' : 'h4'} fontColor='text-base-darkest'>
          {title}
        </Text>
        {notice}
      </Row>
      {subtitle && <Text type={big ? 'f4' : 'f5'}>{subtitle}</Text>}
    </div>
  )
}

export default CardTitle

import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

import debounce from 'lodash.debounce'

import { Filter, SearchInput } from '@fullfabric/alma-mater'

import api from 'apps/ContentPages/api'
import PageTitle from 'apps/ContentPages/components/page-title'
import useSettingByName from 'apps/ContentPages/hooks/use-setting-by-name'
import ProductsGrid from './products-grid'

import styles from './styles.module.scss'

const ProductCatalogPageContent = () => {
  const { t } = useTranslation()
  const isCampusesEnabled = useSettingByName(
    'modules.core.submodules.institutions.features.campuses.enable'
  )
  const isThemesEnabled = useSettingByName(
    'modules.core.submodules.institutions.features.themes.enable'
  )

  const [searchValue, setSearchValue] = React.useState('')
  const [searchFormatted, setSearchFormatted] = React.useState('')

  const onSearchChangeDebounced = React.useMemo(() => {
    return debounce((q) => setSearchFormatted(q), 200)
  }, [])

  const onSearch = React.useCallback(
    (evt) => {
      setSearchValue(evt.target.value)
      onSearchChangeDebounced(evt.target.value.toLowerCase().trim())
    },
    [onSearchChangeDebounced]
  )

  const onClearSearch = React.useCallback(() => {
    setSearchValue('')
    setSearchFormatted('')
  }, [setSearchValue, setSearchFormatted])

  const [filter, setFilter] = useState({})

  const { data: products, status } = useQuery(
    ['get-products', filter],
    async () => api.getProductsCatalog(filter)
  )

  const canRenderGrid = status === 'success' || status === 'loading'

  const filteredProducts = searchFormatted
    ? products.filter((product) => {
        return (
          product.context_parent_name.toLowerCase().includes(searchFormatted) ||
          product.context_name.toLowerCase().includes(searchFormatted)
        )
      })
    : products

  const filters = [
    {
      id: 'period',
      name: t('Start date'),
      type: 'multi',
      defaultLabel: t('All'),
      labelValueSeparator: ':',
      options: [
        {
          label: t('Now until the end of next month'),
          value: 'upcoming_month'
        },
        { label: t('In 1-6 months'), value: '1_to_6_months' },
        { label: t('In 6-12 months'), value: '6_to_12_months' },
        { label: t('In 1 year'), value: '1_year_or_later' }
      ]
    },
    isCampusesEnabled
      ? {
          id: 'campuses_ids',
          name: t('Location'),
          type: 'multi',
          defaultLabel: t('All'),
          loadOptions: async () => {
            return api.getCampuses()
          },
          labelValueSeparator: ':',
          valueField: 'id',
          labelField: 'name'
        }
      : null,
    isThemesEnabled
      ? {
          id: 'theme_ids',
          name: t('Theme'),
          type: 'multi',
          defaultLabel: t('All'),
          loadOptions: async () => {
            return api.getThemes()
          },
          labelValueSeparator: ':',
          valueField: 'id',
          labelField: 'name'
        }
      : null
  ].filter((e) => !!e)

  return (
    <>
      <PageTitle thin>
        <Trans>Product catalog</Trans>
      </PageTitle>
      <SearchInput
        id='filterSearch'
        onChange={onSearch}
        onClearInput={onClearSearch}
        value={searchValue}
        placeholder={t('Search for a programme or course')}
        className={styles.searchInput}
      />
      <Filter
        selectedFilter={filter}
        onFilterChange={setFilter}
        filters={filters}
        containerClassName={styles.filterBar}
      />

      {canRenderGrid && (
        <ProductsGrid
          products={filteredProducts}
          isLoading={status === 'loading'}
        />
      )}
    </>
  )
}

export default ProductCatalogPageContent

/* eslint-disable camelcase */

import React from 'react'
import { Trans } from 'react-i18next'

import PropTypes from 'prop-types'

import {
  ActionList,
  Button,
  Col,
  Label,
  Link,
  MoreIcon,
  Row,
  Text
} from '@fullfabric/alma-mater'

import InboxEventCardDescriptor from 'apps/Inbox/components/InboxEventCardDescriptor'
import { useHandleArchive } from 'apps/Inbox/hooks/handleArchiveHook'
import { eventRoute } from 'apps/Inbox/utils/cardUtils'

import styles from './styles.module.scss'

export default function InboxEventCard({ card }) {
  const handleArchived = useHandleArchive()

  const {
    id: cardId,
    entity: { id: eventId, name, sub_type_entity: subTypeEntity },
    status,
    status_updated_by: statusUpdatedBy,
    status_updated_at: statusUpdatedAt,
    actor,
    updated_at
  } = card

  return (
    <div key={cardId} className={styles.eventCard}>
      <Row middle='xs'>
        <Col xs={9} className={styles.titleContainer}>
          <Label color='red-500'>
            <Trans>Event</Trans>
          </Label>
          <Text
            className={styles.title}
            tag='span'
            type='h4'
            marginLeft='small'
            fontColor='text-base-darkest'
          >
            {name || <Trans>(no name)</Trans>}
          </Text>

          <InboxEventCardDescriptor
            status={status}
            statusUpdatedBy={statusUpdatedBy}
            statusUpdatedAt={statusUpdatedAt}
            actor={actor}
            subTypeEntity={subTypeEntity}
            updatedAt={updated_at}
          />
        </Col>

        <Col xs={3}>
          <Row middle='xs' end='xs'>
            <Col xs={12} className={styles.buttons}>
              <Link disabled href={eventRoute(eventId)} target='_top'>
                <Button marginRight='tiny' size='small'>
                  <Trans>View submission</Trans>
                </Button>
              </Link>

              <ActionList
                iconButton={<MoreIcon />}
                iconButtonProps={{ small: true }}
                menuPosition='down'
                wrapperClassName={styles.actionListWrapper}
              >
                <ActionList.Option onClick={() => handleArchived({ card })}>
                  {status === 'active' ? (
                    <Trans>Archive</Trans>
                  ) : (
                    <Trans>Restore</Trans>
                  )}
                </ActionList.Option>
              </ActionList>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  )
}

InboxEventCard.propTypes = {
  card: PropTypes.shape({
    id: PropTypes.string.isRequired,
    entity: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string,
      sub_type_entity: PropTypes.object
    }).isRequired,
    status: PropTypes.string.isRequired,
    status_updated_by: PropTypes.object,
    status_updated_at: PropTypes.string,
    actor: PropTypes.object,
    updated_at: PropTypes.string
  }).isRequired
}

import React from 'react'
import { Trans } from 'react-i18next'

import { Text } from '@fullfabric/alma-mater'
import { useCurrentUser } from '@fullfabric/authorization-officer'

import { relativeTimestamp, roughlySameTime } from 'apps/Inbox/utils/cardUtils'
import InboxCardProfileTooltip from '../InboxCardProfileTooltip'
import InboxCardSubject from '../InboxCardSubject'

import styles from './styles.module.scss'

const InboxTaskCardDescriptor = ({
  status,
  statusUpdatedBy,
  actor, // assigned by
  target,
  assignedTo,
  completedBy,
  completedAt,
  updatedAt
}) => {
  const currentUser = useCurrentUser()
  if (status === 'archived' && (completedBy || statusUpdatedBy)) {
    let tooltipData = {}

    if (
      !statusUpdatedBy ||
      (completedBy && roughlySameTime(completedAt, updatedAt))
    ) {
      tooltipData = {
        actor: completedBy,
        text: 'completed this task.'
      }
    } else {
      tooltipData = {
        actor: statusUpdatedBy,
        text: 'archived this card.'
      }
    }

    return (
      <div className={styles.cardDescriptor}>
        <InboxCardProfileTooltip
          currentUser={currentUser}
          actor={tooltipData.actor}
        ></InboxCardProfileTooltip>{' '}
        <Text
          type='f5'
          tag='span'
          fontColor='text-base-darkest'
          marginTop='small'
        >
          <Trans>{tooltipData.text}</Trans>
        </Text>
        <Text tag='span' marginLeft='tiny' fontColor='text-base'>
          {relativeTimestamp(updatedAt)}
        </Text>
      </div>
    )
  }

  const currentUserIsTheAssignee =
    assignedTo && assignedTo.id === currentUser.id
  const actorIsTheAssignee = actor && assignedTo && actor.id === assignedTo.id
  const actorIsTheCurrentUser = actor && actor.id === currentUser.id

  return (
    <div className={styles.cardDescriptor}>
      <InboxCardSubject
        currentUser={currentUser}
        actor={actor}
      ></InboxCardSubject>
      {currentUserIsTheAssignee ? (
        <Text
          type='f5'
          tag='span'
          fontColor='text-base-darkest'
          marginTop='small'
        >
          <Trans>
            assigned a task to {actorIsTheCurrentUser ? 'yourself' : 'you'}
          </Trans>{' '}
        </Text>
      ) : (
        <>
          <Text
            type='f5'
            tag='span'
            fontColor='text-base-darkest'
            marginTop='small'
          >
            <Trans>assigned a task to</Trans>{' '}
          </Text>
          {actorIsTheAssignee ? (
            <Text
              type='f5'
              tag='span'
              fontColor='text-base-darkest'
              marginTop='small'
            >
              <Trans>themselves</Trans>
            </Text>
          ) : (
            <InboxCardProfileTooltip
              currentUser={currentUser}
              actor={assignedTo}
            ></InboxCardProfileTooltip>
          )}{' '}
        </>
      )}
      <Text
        type='f5'
        tag='span'
        fontColor='text-base-darkest'
        marginTop='small'
      >
        <Trans>for</Trans>
      </Text>{' '}
      <InboxCardProfileTooltip
        currentUser={currentUser}
        actor={target}
        followOrUnfollowProfile={() => {}}
      ></InboxCardProfileTooltip>
      {'. '}
      <Text tag='span' marginLeft='tiny' fontColor='text-base'>
        {relativeTimestamp(updatedAt)}
      </Text>
    </div>
  )
}

export default InboxTaskCardDescriptor

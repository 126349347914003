import React, { useState } from 'react'
import { Trans } from 'react-i18next'

import { AddIcon, Button, Link } from '@fullfabric/alma-mater'
import { useSettings } from '@fullfabric/authorization-officer'

import { useStudyPlan } from 'apps/StudyPlans/contexts/StudyPlanData'
import SingleDegreeClassForm from './SingleDegreeClassForm'
import useOnSaveDegreeClasses from './useOnSaveDegreeClasses'
import { getDegreeClassPlaceholder, isDirty } from './utils'

const DegreeClassSectionForm = () => {
  const studyPlan = useStudyPlan()
  const settings = useSettings()

  const [degreeClasses, setDegreeClasses] = useState(
    (studyPlan.degree_classes || []).length === 0
      ? getDegreeClassPlaceholder(settings.hash.locales)
      : studyPlan.degree_classes.reduce(
          (hashed, curr) => ({ ...hashed, [curr.id]: curr }),
          {}
        )
  )

  const canAddAnother = React.useMemo(
    () => Object.values(degreeClasses).every(isDirty),
    [degreeClasses]
  )

  const onAddAnotherClick = React.useCallback(
    (evt) => {
      evt.preventDefault()
      if (canAddAnother)
        setDegreeClasses({ ...degreeClasses, ...getDegreeClassPlaceholder() })
    },
    [degreeClasses, setDegreeClasses, canAddAnother]
  )

  const { isLoading, onSaveChanges, onCancel } = useOnSaveDegreeClasses({
    studyPlan,
    degreeClasses,
    setDegreeClasses
  })

  return (
    <>
      {Object.values(degreeClasses).map((degreeClass) => (
        <SingleDegreeClassForm
          key={degreeClass.id}
          degreeClass={degreeClass}
          setDegreeClasses={setDegreeClasses}
        />
      ))}

      <Link disabled={!canAddAnother} onClick={onAddAnotherClick}>
        <Trans>Add another</Trans> <AddIcon />
      </Link>

      <div className='marginTop-more'>
        <Button
          primary
          size='small'
          loading={isLoading}
          disabled={isLoading}
          onClick={onSaveChanges}
        >
          <Trans>Save changes</Trans>
        </Button>
        <Button
          size='small'
          disabled={isLoading}
          onClick={onCancel}
          className='marginLeft-small'
        >
          <Trans>Cancel</Trans>
        </Button>
      </div>
    </>
  )
}

export default DegreeClassSectionForm

/* eslint-disable camelcase */

import { connect } from 'react-redux'

import actionTypes from 'apps/Courses/actions/types'
import CourseEditDisplayBasic from 'apps/Courses/components/CourseEditDisplayBasic'

const mapStateToProps = ({
  editCourse: { data },
  appData: { h: { campuses_options = [] } = {} } = {}
}) => ({
  data,
  campusesOptions: campuses_options.map(([label, value]) => ({
    value,
    label
  }))
})

const mapDispatchToProps = (dispatch) => ({
  onEditClick: (course) =>
    dispatch({
      type: actionTypes.editBasicSectionData,
      payload: {
        owner_id: course.owner?.id,
        subject: { ...course.subject },
        name: course.name,
        default_name: course.default_name,
        price_currency: course.price_currency,
        price_amount:
          typeof course.price_amount === 'number'
            ? String(course.price_amount)
            : '',
        campus_ids: course.campus_ids,
        external_id: course.external_id
      }
    })
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CourseEditDisplayBasic)

import React, { useCallback, useEffect, useState } from 'react'
import { Trans } from 'react-i18next'

import { isNumber } from 'lodash'

import {
  Button,
  Field,
  Modal,
  ModalButtons,
  ModalTitle,
  Text
} from '@fullfabric/alma-mater'

import bulkPublishPaymentPlans from 'apps/Financial/api/bulkPublishPaymentPlans'
import bulkUnpublishPaymentPlans from 'apps/Financial/api/bulkUnpublishPaymentPlans'

import styles from './BulkPublishStatusUpdateModal/styles.module.scss'

export default function BulkPublishStatusUpdateModal({
  isOpen,
  onClose,
  search,
  filters,
  onUpdate
}) {
  const [error, setError] = useState(null)
  const [action, setAction] = useState(null)
  const [totalPaymentPlans, setTotalPaymentPlans] = useState(null)
  const [affectedPaymentPlans, setAffectedPaymentPlans] = useState(null)

  const updatePaymentPlansStatuses = useCallback(
    async ({ dryRun = false }) => {
      if (!action) return setError(true)

      try {
        let response
        if (action === 'publish') {
          response = await bulkPublishPaymentPlans({
            dryRun,
            search,
            filters
          })
        } else {
          response = await bulkUnpublishPaymentPlans({
            dryRun,
            search,
            filters
          })
        }

        if (dryRun) {
          setAffectedPaymentPlans(response.affected)
          setTotalPaymentPlans(response.total)
        } else {
          onUpdate(action)
        }
      } catch (e) {
        console.error('Payment plans publish/unpublish error: ', e.message)
      }
    },
    [action, search, filters, onUpdate]
  )

  useEffect(() => {
    action && updatePaymentPlansStatuses({ dryRun: true })
  }, [action, updatePaymentPlansStatuses])

  return (
    <Modal
      role='dialog'
      aria-modal='true'
      aria-labelledby='bulkPublishStatusUpdateModal'
      className={styles.bulkPublishStatusUpdateModal}
      size='medium'
      isOpen={isOpen}
      header={
        <ModalTitle
          title={
            <span id='bulkPublishStatusUpdateModal'>
              <Trans>Update payment plan statuses</Trans>
            </span>
          }
          onClose={onClose}
        />
      }
      footer={
        <ModalButtons>
          <Button onClick={onClose}>
            <Trans>Cancel</Trans>
          </Button>
          <Button
            primary
            onClick={updatePaymentPlansStatuses}
            disabled={affectedPaymentPlans === 0}
          >
            <Trans>Update</Trans>
          </Button>
        </ModalButtons>
      }
    >
      <Text fontColor='text-base-darkest' marginBottom='default'>
        <Trans>
          This action will affect the payment plans displayed on the list
        </Trans>
      </Text>

      <Field
        id='status'
        type='radio'
        label={<Trans>Status action</Trans>}
        required
        className={styles.statusRadio}
        onChange={(_, value) => {
          setError(false)
          setAction(value)
        }}
        value={action}
        inputOptions={[
          {
            value: 'publish',
            label: <Trans>Publish payment plans</Trans>
          },
          {
            value: 'unpublish',
            label: <Trans>Unpublish payment plans</Trans>
          }
        ]}
        error={error && <Trans>Can't be blank</Trans>}
      />

      {isNumber(affectedPaymentPlans) && (
        <Text
          bold
          fontColor='text-info'
          marginTop='default'
          marginBottom='tiny'
        >
          <Trans>
            {{ affectedPaymentPlans }} of the {{ totalPaymentPlans }} payment
            plans will be {action === 'publish' ? 'published' : 'unpublished'}
          </Trans>
        </Text>
      )}
    </Modal>
  )
}

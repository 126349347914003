const getTargetDate = ({ state, template, application }) => {
  switch (state) {
    case 'extended':
      return application.deadline
    case 'open':
      return template.closes_at
    case 'upcoming':
      return template.opens_at
    default:
      return null
  }
}

export default getTargetDate

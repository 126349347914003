.react-app ._journeysTabContainer_fhpwj_1 {
  margin: var(--space-more) 0;
}
.react-app ._journeysTabContainer_fhpwj_1 ._journeySummaryRow_fhpwj_4 {
  padding: var(--space-default) 0;
  border-bottom: var(--border-base-lighter);
}
.react-app ._journeysTabContainer_fhpwj_1 ._journeySummaryRow_fhpwj_4 ._journeySummaryCardCol_fhpwj_8 {
  width: auto !important;
  flex: 0 0 auto !important;
}
.react-app ._journeysTabContainer_fhpwj_1 ._journeySummaryRow_fhpwj_4:first-child {
  padding-top: 0;
}
.react-app ._journeysTabContainer_fhpwj_1 ._journeySummaryRow_fhpwj_4:last-child {
  padding-bottom: 0;
  border-bottom: none;
}
.react-app ._journeysTabContainer_fhpwj_1 ._noJourneys_fhpwj_19 {
  margin-left: var(--space-more);
  margin-bottom: 100px;
  display: flex;
  align-items: center;
}
.react-app ._journeysTabContainer_fhpwj_1 ._noJourneys_fhpwj_19 svg {
  margin-right: var(--space-small);
}
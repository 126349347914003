import React from 'react'
import { Trans } from 'react-i18next'
import { useQuery } from 'react-query'

import { isEmpty } from 'lodash-es'

import { useCurrentUser } from '@fullfabric/authorization-officer'

import api from 'apps/ContentPages/api'
import WidgetContainer from 'apps/ContentPages/components/widget-container'
import WidgetSection from 'apps/ContentPages/components/widget-section'
import WidgetTitle from '../title'
import OffersActions from './OffersWidget/actions'
import OfferInfo from './OffersWidget/OfferInfo'

export default function OffersWidget() {
  const user = useCurrentUser()
  const isLoggedIn = !!user?.token

  const { data: offers, status } = useQuery(
    ['get-offers', user?.id],
    () => api.getOffers(user?.id),
    { enabled: isLoggedIn }
  )

  if (!isLoggedIn || isEmpty(offers) || status !== 'success') {
    return null
  }

  const id = offers.map((o) => o.id).join('-')

  return (
    <WidgetContainer>
      <section
        data-testid='offers-widget'
        aria-labelledby={`widget-title-${id}`}
      >
        <WidgetTitle id={id} title={<Trans>Offers</Trans>} />

        {offers.map((offer, index) => (
          <WidgetSection key={offer.id} last={index === offers.length - 1}>
            <OfferInfo data={offer} />
            <OffersActions data={offer} />
          </WidgetSection>
        ))}
      </section>
    </WidgetContainer>
  )
}

import React from 'react'

import { useScreenClass } from '@fullfabric/alma-mater'

import Navigation from '../navigation'

import styles from './styles.module.scss'

export default function SidebarLayout({ children }) {
  const screenClass = useScreenClass()
  const isMobile = ['xs', 'sm', 'md'].includes(screenClass)

  if (isMobile) {
    return children
  }

  return (
    <div className={styles.container}>
      <div className={styles.sidebar} data-testid='sidebar'>
        <Navigation />
      </div>

      <div className={styles.content}>{children}</div>
    </div>
  )
}

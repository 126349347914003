export const REPORTS_HOME_ROUTE = '/reports'
export const REPORTS_PRODUCT_OVERVIEW_ROUTE = '/reports/product_overview'
export const REPORTS_APPLIC_PERFORMANCE_ROUTE = '/reports/applic_performance'
export const REPORTS_PRODUCT_CONVERSION_ROUTE = '/reports/product_conversion'
export const REPORTS_PRODUCT_PERFORMANCE_ROUTE = '/reports/product_performance'
export const REPORTS_PRODUCT_PERFORMANCE_DETAIL_ROUTE =
  '/reports/product_performance/:classId'

export const REPORTS_CLASS_OVERVIEW_ROUTE = '/classes/:classId/overview'
export const REPORTS_CLASS_CONVERSION_ROUTE = '/classes/:classId/conversion'
export const REPORTS_HOMEPAGE_ROUTE = '/home/applic_performance'

export const BASE_REPORTS_APP_ROUTES = [
  REPORTS_HOME_ROUTE,
  REPORTS_HOMEPAGE_ROUTE,
  REPORTS_CLASS_OVERVIEW_ROUTE,
  REPORTS_CLASS_CONVERSION_ROUTE
]

import { useEffect, useState } from 'react'

import {
  SUBMISSION_STATE,
  useSubmissionState
} from 'apps/Evaluations/context/EvaluationFormData'

const LOADER_TIME_SECONDS = 1.5 * 1000

function useIsLoaderShown() {
  const [isTimeoutFinished, setIsTimeoutFinished] = useState(false)
  const [isLoaderShown, setIsLoaderShown] = useState(true)
  const submissionState = useSubmissionState()

  useEffect(() => {
    const fakeLoadingTimeout = setTimeout(() => {
      setIsTimeoutFinished(true)
    }, LOADER_TIME_SECONDS)

    return () => {
      clearTimeout(fakeLoadingTimeout)
    }
  }, [])

  useEffect(() => {
    // TODO: remove NODE_ENV check after fixing tests
    if (
      (isTimeoutFinished || process.env.NODE_ENV === 'test') &&
      submissionState.modal === SUBMISSION_STATE.MODAL.SUCCESS
    ) {
      setIsLoaderShown(false)
    }
  }, [isTimeoutFinished, submissionState.modal])
  return { isLoaderShown }
}
export default useIsLoaderShown

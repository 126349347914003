import React from 'react'
import { Trans } from 'react-i18next'

import PropTypes from 'prop-types'

import { Alert } from '@fullfabric/alma-mater'

export default function ExportStatusCallout({
  xport,
  exportCreateError,
  exportCount,
  exportCountReady
}) {
  if (exportCreateError) {
    return (
      <Alert
        type='alert'
        title={<Trans>Error creating export</Trans>}
        description={exportCreateError.statusText}
      />
    )
  }

  if (xport?.state === 'finished') {
    return (
      <Alert
        type='success'
        title={<Trans>The export is ready</Trans>}
        description={
          <>
            <a href={exportUrl(xport)}>
              <Trans>Click here to download it</Trans>
            </a>
            {'.'}
          </>
        }
      />
    )
  }

  if (xport?.state === 'failed') {
    const exportId = xport.id

    return (
      <Alert
        type='alert'
        title={<Trans>Export failed</Trans>}
        description={
          <Trans>
            Please try again or contact support if the problem persists; if so,
            please mention the ID <code>{{ exportId }}</code> in your message.
          </Trans>
        }
      />
    )
  }

  if (xport) {
    return (
      <Alert
        type='info'
        title={<Trans>Export in progress</Trans>}
        description={
          <>
            <Trans>
              This modal will update with the download link once it's ready.
            </Trans>

            <br />

            <Trans>
              If you don't wish to wait, you may close it and we'll send the
              link via email.
            </Trans>
          </>
        }
      />
    )
  }

  if (exportCountReady) {
    return (
      <Alert
        type='info'
        description={
          <Trans i18nKey='exportCount' count={exportCount}>
            Found {{ exportCount }} records to export.
          </Trans>
        }
      />
    )
  }
  return (
    <Alert
      type='info'
      description={<Trans>Counting exportable records...</Trans>}
    />
  )
}

ExportStatusCallout.propTypes = {
  xport: PropTypes.shape({
    format: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired
  }),
  exportCount: PropTypes.number,
  exportCountReady: PropTypes.bool.isRequired
}

function exportUrl(xport) {
  return `/admin/exports/${xport.id}/download.${xport.format}`
}

.react-app ._infoContainer_z88mp_1 {
  width: 100%;
  border: var(--border-base-lighter);
  box-shadow: var(--shadow-2);
  border-radius: 12px;
  margin-top: var(--space-more);
}
.react-app ._infoContainer_z88mp_1 ._header_z88mp_8 {
  width: 100%;
  padding: var(--space-more);
}
.react-app ._infoContainer_z88mp_1 ._header_z88mp_8._mobile_z88mp_12 {
  padding: var(--space-default);
}

.react-app ._submitBtn_z88mp_16 {
  margin-top: var(--space-default);
  width: 100%;
}

.react-app ._container_z88mp_21 {
  width: 100%;
  margin-top: var(--space-more);
  text-align: center;
}

.react-app ._containerRow_z88mp_27 {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--space-tiny);
  margin-top: var(--space-default);
}
import React, { useCallback, useEffect, useState } from 'react'
import { useQuery } from 'react-query'

import PropTypes from 'prop-types'

import { useCurrentUser } from '@fullfabric/authorization-officer'
import { getCountries } from '@fullfabric/public-api'
import {
  FormAvailabilityAlert,
  isFormOpenForSubmissions,
  SchemableForm
} from '@fullfabric/schemable-forms'

import { useAppData } from 'shared/contexts/AppData'
import { useLocale } from 'shared/contexts/Locale'

import { useChangeBackgroundImage } from 'apps/ContentPages/contexts/background-image'
import FormSubmissionSuccessMessage from '../form-submission-success-message'
import useFormQuery from './use-form-query'
import useHandleSubmit from './use-handle-submit'

import styles from './styles.module.scss'

export default function FormBody({ inCard, inFormPage, formId }) {
  const setBackgroundImage = useChangeBackgroundImage()

  const { privacy_policy: privacyPolicy, marketing_policy: marketingPolicy } =
    useAppData()

  const user = useCurrentUser()
  const { locale } = useLocale()
  const { data: countriesAsOptions, isLoading: isLoadingCountries } = useQuery(
    ['get-countries'],
    () => getCountries({ locale })
  )

  const {
    form,
    requestId,
    refetch: refetchForm,
    isLoading: isLoadingForm
  } = useFormQuery(formId)

  const schema = form?.schema
  const preFilledData = form?.submissions && form.submissions[0]
  const formImageUrl = form?.image?.url

  const [submissionError, setSubmissionError] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)

  const toggleToSubmitted = useCallback(() => {
    setSubmissionError(false)
    setIsSubmitted(true)
  }, [])

  const handleNotSubmitted = useCallback(
    (error) => {
      setSubmissionError(error)

      if (error.mayNeedRefetch) refetchForm()
    },
    [setSubmissionError, refetchForm]
  )

  useEffect(() => {
    if (isLoadingForm || !inFormPage) return
    setBackgroundImage(formImageUrl)
  }, [inFormPage, isLoadingForm, setBackgroundImage, formImageUrl])

  const isOpen = isFormOpenForSubmissions(
    form?.start_submissions,
    form?.end_submissions
  )

  const handleSubmit = useHandleSubmit({
    redirectTo: form?.redirect_path,
    formId,
    formRequestId: requestId,
    onStartSubmit: () => setSubmissionError(false),
    onSubmitted: toggleToSubmitted,
    onNotSubmitted: handleNotSubmitted
  })

  if (!isLoadingForm && !isOpen) {
    return (
      <FormAvailabilityAlert
        openDate={form?.start_submissions}
        closeDate={form?.end_submissions}
        userTimezone={user?.timezoneName}
        userLocale={locale}
      />
    )
  }

  if (isSubmitted) {
    return (
      <FormSubmissionSuccessMessage
        inCard={inCard}
        successMessage={form?.success_message}
      />
    )
  }

  return (
    <>
      {!isLoadingForm && form?.end_submissions && (
        // condition is a hack to avoid rendering div when there is no alert.
        // if we support classname in FormAvailabilityAlert in the future, we could avoid this div altogether
        // and let the component do all the visibility work
        <div
          className={
            inCard ? styles.alertContainerInCard : styles.alertContainer
          }
        >
          <FormAvailabilityAlert
            openDate={form?.start_submissions}
            closeDate={form?.end_submissions}
            userTimezone={user?.timezoneName}
            userLocale={locale}
          />
        </div>
      )}

      <SchemableForm
        isLoading={isLoadingForm}
        schema={schema}
        locale={locale}
        data={preFilledData}
        error={submissionError}
        errorDetailScope='forms/submission'
        countriesAsOptions={isLoadingCountries ? [] : countriesAsOptions}
        privacyPolicy={privacyPolicy}
        marketingPolicy={marketingPolicy}
        user={user}
        handleSubmit={handleSubmit}
        sectionClassName={inCard ? styles.section : ''}
        separatorClassName={inCard ? styles.separator : ''}
      />
    </>
  )
}

FormBody.propTypes = {
  inCard: PropTypes.bool,
  inFormPage: PropTypes.bool,
  formId: PropTypes.string.isRequired
}

FormBody.defaultProps = {
  inCard: false,
  inFormPage: false
}

import React from 'react'
import { useTranslation } from 'react-i18next'

import {
  Label,
  Legend,
  Line,
  LineChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip
} from 'recharts'

import { Loading } from '@fullfabric/alma-mater'

import {
  CustomTooltip,
  renderCustomCartesianGrid,
  renderCustomXAxis,
  renderCustomYAxis,
  XAxisTick
} from 'apps/Reports/components/CustomChartElements'
import LeftAlignedXAxisLabel from 'apps/Reports/components/CustomChartElements/LeftAlignedXAxisLabel'
import { useMainIntakeIndex } from 'apps/Reports/components/ProductOverviewReport/ClassOptionsContext'
import getChartColor from 'apps/Reports/components/ProductOverviewReport/utils/getChartColor'
import GraphPlaceholder from '../../../GraphPlaceholder'
import LegendContent from '../../../LegendContent'
import { TIMEFRAME_INTERVAL } from '../../constants'
import getTooltipProps, {
  comparisonValueFormatter
} from '../../helpers/getTooltipProps'
import hasNoMoreValuesAhead from '../../helpers/hasNoMoreValuesAhead'
import useIntakeOverviewData from '../../hooks/useIntakeOverviewData'
import ChartDot from '../ChartDot'

const MAIN_INTAKE_LINE_COLOR = '#0967D2'
const LINE_COLORS = ['#B9BFC7', '#474A53']

function Chart({ timeframeInterval }) {
  const { t } = useTranslation()
  const mainIntakeIndex = useMainIntakeIndex()
  const {
    reportData,
    isReportDataEmpty,
    isLoading,
    error,
    distanceFromCloseToStartDate,
    intakesData,
    graphDataKeys
  } = useIntakeOverviewData(timeframeInterval)

  if (isLoading) return <Loading />

  if (error || isReportDataEmpty) return <GraphPlaceholder.Line />

  const isWeeklyTimeframeSelected =
    timeframeInterval === TIMEFRAME_INTERVAL.WEEKLY

  const distanceFromCloseToStartDataPointExists = reportData?.some((data) =>
    isWeeklyTimeframeSelected
      ? data.week === distanceFromCloseToStartDate
      : data.month === distanceFromCloseToStartDate
  )

  return (
    <ResponsiveContainer>
      <LineChart
        data={reportData || []}
        margin={{
          top: 12,
          left: 0,
          right: 0,
          bottom: 26
        }}
      >
        <Tooltip
          {...getTooltipProps(t, intakesData, timeframeInterval)}
          content={
            <CustomTooltip
              mainDataName={graphDataKeys && graphDataKeys[mainIntakeIndex]}
              customValueFormatter={comparisonValueFormatter(intakesData)}
              labelFormatter={(l) =>
                isWeeklyTimeframeSelected
                  ? t('Week {{weekNumber}}', { weekNumber: l })
                  : t('Month {{monthNumber}}', { monthNumber: l })
              }
            />
          }
        />
        {renderCustomCartesianGrid()}
        {renderCustomYAxis({
          orientation: 'right'
        })}
        {renderCustomXAxis({
          dataKey: isWeeklyTimeframeSelected ? 'week' : 'month',
          tick: <XAxisTick intervaled />,
          children: (
            <Label
              content={<LeftAlignedXAxisLabel />}
              value={
                isWeeklyTimeframeSelected
                  ? t('Weeks until class starts')
                  : t('Months until class starts')
              }
              offset={8}
              position='bottom'
            />
          )
        })}
        {distanceFromCloseToStartDate && (
          <ReferenceLine
            x={distanceFromCloseToStartDate}
            stroke='#653CAD'
            strokeWidth='2'
            label={({ viewBox: { x, y } }) => (
              <circle
                r='3'
                stroke='#653CAD'
                stroke-width='2'
                fill='#653CAD'
                cx={x}
                cy={y}
              />
            )}
          />
        )}
        {graphDataKeys &&
          graphDataKeys.map((dataKey, keyIndex) => (
            <Line
              key={dataKey}
              type='monotone'
              dataKey={dataKey}
              stroke={getChartColor({
                intakeIndex: keyIndex,
                mainIntakeIndex,
                mainIntakeColor: MAIN_INTAKE_LINE_COLOR,
                otherColors: LINE_COLORS
              })}
              strokeWidth='2'
              strokeDasharray={keyIndex !== mainIntakeIndex ? '5 5' : null}
              dot={(props) => {
                const isMainIntakeLine = keyIndex === mainIntakeIndex

                if (!isMainIntakeLine) {
                  return false
                }

                const isLastPoint =
                  typeof props.value === 'number' &&
                  hasNoMoreValuesAhead(
                    props.index,
                    reportData,
                    (dataPoint) => dataPoint[graphDataKeys[mainIntakeIndex]]
                  )

                return isLastPoint && <ChartDot {...props} />
              }}
            />
          ))}
        <Legend
          wrapperStyle={{
            left: 0,
            top: 0,
            display: 'flex',
            alignItems: 'start'
          }}
          height={44}
          verticalAlign='top'
          align='left'
          content={
            <LegendContent
              intakes={intakesData}
              distanceFromCloseToStartDate={
                distanceFromCloseToStartDataPointExists
                  ? distanceFromCloseToStartDate
                  : null
              }
            />
          }
        />
      </LineChart>
    </ResponsiveContainer>
  )
}

export default Chart

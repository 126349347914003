import React from 'react'

import { Text } from '@fullfabric/alma-mater'

import styles from '../styles.module.scss'

const LabelValuePair = ({ label, value }) => {
  if (!value && value !== 0) {
    return null
  }

  return (
    <>
      <Text tag='dt' type='f5' fontColor='text-base-darker'>
        {label}
      </Text>
      <Text
        tag='dd'
        type='f4'
        fontColor='text-base-darkest'
        className={styles.spacingAfterDefinition}
      >
        {value}
      </Text>
    </>
  )
}

export default LabelValuePair

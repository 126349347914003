import { utils } from '@fullfabric/public-api'

const getStudyPlanSubject = async (studyPlanId, studyPlanSubjectId) => {
  const response = await fetch(
    `/affairs/api/study_plan/${studyPlanId}/courses/${studyPlanSubjectId}`,
    {
      credentials: 'same-origin',
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
    }
  )

  return await utils.checkResponse(response)
}

export default getStudyPlanSubject

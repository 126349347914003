import { isEmpty, isNil, isPlainObject } from 'lodash'

import { FileUploadInput } from '@fullfabric/alma-mater'

import uploadsInStoreHelper from './uploadsInReduxStoreHelper'

export default function formValueToStoreFormat(data) {
  const isEmptyObject = isPlainObject(data) && isEmpty(data)
  const hasAValue = !isNil(data) && !isEmptyObject
  const isValueFromFileUploadInput = FileUploadInput.isValidValue(data)

  if (hasAValue && isValueFromFileUploadInput) {
    return uploadsInStoreHelper.fileUploadValueToStore(data)
  }

  return data
}

import { combineReducers } from 'redux'

import actionTypes from 'apps/Courses/actions/types'

const data = (state = [], action) => {
  switch (action.type) {
    case actionTypes.getCourses.success:
      return action.payload
    case actionTypes.deleteCourse.success:
      return state.filter((course) => course.id !== action.payload.id)
    default:
      return state
  }
}
const loaded = (state = false, action) => {
  switch (action.type) {
    case actionTypes.getCourses.success:
      return true
    default:
      return state
  }
}
const loading = (state = false, action) => {
  switch (action.type) {
    case actionTypes.getCourses.start:
      return true
    case actionTypes.getCourses.success:
    case actionTypes.getCourses.error:
      return false
    default:
      return state
  }
}
const error = (state = null, action) => {
  switch (action.type) {
    case actionTypes.getCourses.error:
      return action.payload
    default:
      return state
  }
}

export default combineReducers({ data, loaded, loading, error })

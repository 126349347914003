.react-app ._dataCell_1oq3g_1 {
  height: 56px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.react-app ._dataCell_1oq3g_1 p {
  overflow-x: hidden;
  text-overflow: ellipsis;
}
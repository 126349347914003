import React from 'react'

import ApplicPerformanceReport from 'apps/Reports/components/ApplicPerformanceReport'
import Header from './Header'

import styles from './styles.module.scss'

const STORAGE_FILTER_KEY = 'homepage_report:filter:'

const ApplicPerformanceHomepage = () => (
  <div className={styles.container}>
    <Header />
    <ApplicPerformanceReport filterStorageKey={STORAGE_FILTER_KEY} />
  </div>
)

export default ApplicPerformanceHomepage

const getEmailFieldValues = (emailInfoFrom, sourceProfile, targetProfile) => {
  return Object.keys(emailInfoFrom)
    .filter((fieldName) => {
      const selection = emailInfoFrom[fieldName]
      const [fromProfileType, selectedFieldName] = selection?.split(':') || []
      return !(fromProfileType === 'main' && selectedFieldName === fieldName)
    })
    .reduce((acc, fieldName) => {
      const selection = emailInfoFrom[fieldName]
      const [fromProfileType, selectedFieldName] = selection?.split(':') || []
      const profile = fromProfileType === 'main' ? targetProfile : sourceProfile
      const value = selection === 'empty' ? null : profile[selectedFieldName]

      return { ...acc, [fieldName]: value }
    }, {})
}

export default getEmailFieldValues

import React from 'react'
import { useTranslation } from 'react-i18next'

import { Text } from '@fullfabric/alma-mater'

import styles from './styles.module.scss'

function BarGraphPlaceholder() {
  const { t } = useTranslation()
  return (
    <div className={styles.placeholderContainer}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='972'
        height='276'
        fill='none'
        viewBox='0 0 972 276'
        preserveAspectRatio='none'
      >
        <path fill='#EDF0F3' d='M24 259H972V260H24z'></path>
        <path fill='#EDF0F3' d='M24 43H972V44H24z'></path>
        <path fill='#EDF0F3' d='M24 151H972V152H24z'></path>
        <path fill='#EDF0F3' d='M68 260H69V264H68z'></path>
        <path fill='#EDF0F3' d='M163 260H164V264H163z'></path>
        <path fill='#EDF0F3' d='M258 260H259V264H258z'></path>
        <path fill='#EDF0F3' d='M353 260H354V264H353z'></path>
        <path fill='#EDF0F3' d='M448 260H449V264H448z'></path>
        <path fill='#EDF0F3' d='M543 260H544V264H543z'></path>
        <path fill='#EDF0F3' d='M638 260H639V264H638z'></path>
        <path fill='#EDF0F3' d='M733 260H734V264H733z'></path>
        <path fill='#EDF0F3' d='M828 260H829V264H828z'></path>
        <path fill='#EDF0F3' d='M923 260H924V264H923z'></path>
        <rect width='15' height='8' x='61' y='268' fill='#EDF0F3' rx='2'></rect>
        <rect
          width='15'
          height='8'
          x='156'
          y='268'
          fill='#EDF0F3'
          rx='2'
        ></rect>
        <rect
          width='15'
          height='8'
          x='251'
          y='268'
          fill='#EDF0F3'
          rx='2'
        ></rect>
        <rect
          width='15'
          height='8'
          x='346'
          y='268'
          fill='#EDF0F3'
          rx='2'
        ></rect>
        <rect
          width='15'
          height='8'
          x='441'
          y='268'
          fill='#EDF0F3'
          rx='2'
        ></rect>
        <rect
          width='15'
          height='8'
          x='536'
          y='268'
          fill='#EDF0F3'
          rx='2'
        ></rect>
        <rect
          width='15'
          height='8'
          x='631'
          y='268'
          fill='#EDF0F3'
          rx='2'
        ></rect>
        <rect
          width='15'
          height='8'
          x='726'
          y='268'
          fill='#EDF0F3'
          rx='2'
        ></rect>
        <rect
          width='15'
          height='8'
          x='821'
          y='268'
          fill='#EDF0F3'
          rx='2'
        ></rect>
        <rect
          width='15'
          height='8'
          x='916'
          y='268'
          fill='#EDF0F3'
          rx='2'
        ></rect>
        <rect width='14' height='8' x='1' y='40' fill='#EDF0F3' rx='2'></rect>
        <rect width='14' height='8' x='1' y='149' fill='#EDF0F3' rx='2'></rect>
        <rect width='14' height='8' x='1' y='245' fill='#EDF0F3' rx='2'></rect>
        <path
          fill='#EDF0F3'
          d='M51 194a2 2 0 012-2h12a2 2 0 012 2v64H51v-64zM71 142a2 2 0 012-2h12a2 2 0 012 2v116H71V142zM146 111a2 2 0 012-2h12a2 2 0 012 2v147h-16V111zM166 198a2 2 0 012-2h12a2 2 0 012 2v60h-16v-60zM241 149a2 2 0 012-2h12a2 2 0 012 2v109h-16V149zM261 96a2 2 0 012-2h12a2 2 0 012 2v162h-16V96zM336 127a2 2 0 012-2h12a2 2 0 012 2v131h-16V127zM356 172a2 2 0 012-2h12a2 2 0 012 2v86h-16v-86zM431 74a2 2 0 012-2h12a2 2 0 012 2v184h-16V74zM451 158a2 2 0 012-2h12a2 2 0 012 2v100h-16V158zM526 160a2 2 0 012-2h12a2 2 0 012 2v98h-16v-98zM546 104a2 2 0 012-2h12a2 2 0 012 2v154h-16V104zM621 162a2 2 0 012-2h12a2 2 0 012 2v96h-16v-96zM641 70a2 2 0 012-2h12a2 2 0 012 2v188h-16V70zM716 143a2 2 0 012-2h12a2 2 0 012 2v115h-16V143zM736 166a2 2 0 012-2h12a2 2 0 012 2v92h-16v-92zM811 133a2 2 0 012-2h12a2 2 0 012 2v125h-16V133zM831 69a2 2 0 012-2h12a2 2 0 012 2v189h-16V69zM906 171a2 2 0 012-2h12a2 2 0 012 2v87h-16v-87zM926 123a2 2 0 012-2h12a2 2 0 012 2v135h-16V123z'
        ></path>
        <rect width='9' height='11' fill='#EDF0F3' rx='2'></rect>
        <rect width='35' height='11' x='13' fill='#EDF0F3' rx='2'></rect>
        <rect width='32' height='11' x='52' fill='#EDF0F3' rx='2'></rect>
        <rect width='63' height='11' x='100' fill='#EDF0F3' rx='2'></rect>
        <rect width='9' height='11' x='167' fill='#EDF0F3' rx='2'></rect>
        <rect width='35' height='11' x='180' fill='#EDF0F3' rx='2'></rect>
        <rect width='35' height='11' x='231' fill='#EDF0F3' rx='2'></rect>
        <rect width='9' height='11' x='270' fill='#EDF0F3' rx='2'></rect>
        <rect width='32' height='11' x='283' fill='#EDF0F3' rx='2'></rect>
      </svg>
      <Text tag='span' type='h6' className={styles.placeholderText}>
        {t('No data available')}
      </Text>
    </div>
  )
}

export default BarGraphPlaceholder

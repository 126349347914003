import React from 'react'
import { useTranslation } from 'react-i18next'

import { BoxShadow } from '@fullfabric/alma-mater'

import { useMergeData } from 'apps/MergeProfiles/contexts/MergeData'
import getLocaledLabel from 'apps/MergeProfiles/utils/getLocaledLabel'
import FieldBaseRow from './Rows/FieldBase'
import SectionTitleRow from './Rows/SectionTitle'

import styles from './styles.module.scss'

const FieldSection = ({ section }) => {
  const { i18n } = useTranslation()
  const { fieldsWithDifferentValues } = useMergeData()

  const fieldsWithDifferentValuesIds = React.useMemo(
    () => fieldsWithDifferentValues.map((field) => field.id),
    [fieldsWithDifferentValues]
  )

  const fieldsWithDifferentValuesForSection = React.useMemo(
    () =>
      section.fields
        .filter((field) => fieldsWithDifferentValuesIds.includes(field.id))
        .sort((fieldA, fieldB) => fieldA.order - fieldB.order),
    [fieldsWithDifferentValuesIds, section.fields]
  )

  if (fieldsWithDifferentValuesForSection.length === 0) return null

  return (
    <BoxShadow
      type='2'
      bgColor='base-lightest'
      rounded
      className={styles.sectionContainer}
      role='rowgroup'
      aria-label={`${getLocaledLabel(
        section.locals,
        i18n.language
      )} fields section`}
    >
      <SectionTitleRow
        section={section}
        fieldsWithDifferentValuesForSection={
          fieldsWithDifferentValuesForSection
        }
      />

      {fieldsWithDifferentValuesForSection.map((field) => (
        <FieldBaseRow key={field.id} section={section} field={field} />
      ))}
    </BoxShadow>
  )
}

export default FieldSection

import React from 'react'

import { BoxShadow } from '@fullfabric/alma-mater'

import ContentPlaceholder from './ContentPlaceholder'
import Header from './Header'
import SubjectsTable from './SubjectsTable'

import styles from './styles.module.scss'

const SubjectsSection = ({ subjects, ...headerProps }) => (
  <BoxShadow className={styles.container} type='2'>
    <Header {...headerProps} />
    {subjects && subjects.length > 0 ? (
      <SubjectsTable subjects={subjects} />
    ) : (
      <ContentPlaceholder />
    )}
  </BoxShadow>
)

export default SubjectsSection

import qs from 'qs'

import { utils } from '@fullfabric/public-api'

const getProductsCatalog = async (filter = {}) => {
  const params = qs.stringify(
    {
      limit: 0,
      ...filter
    },
    { arrayFormat: 'brackets' }
  )

  const response = await fetch(`/api/tabular_data/product_catalog?${params}`, {
    credentials: 'same-origin'
  })

  return await utils.checkResponse(response)
}

export default getProductsCatalog

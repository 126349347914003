import React from 'react'

import classNames from 'classnames'
import styles from './styles.module.scss'

function SuccessIcon({ isActive, alwaysActive }) {
  if (isActive || alwaysActive) {
    return (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='16'
        height='16'
        fill='none'
        viewBox='0 0 16 16'
        className={classNames([
          styles.checkedToggleCircle,
          alwaysActive && styles.alwaysActive
        ])}
      >
        {alwaysActive && (
          <path
            fill='#F9F9FA'
            fillRule='evenodd'
            d='M8 0C3.589 0 0 3.589 0 8s3.589 8 8 8 8-3.589 8-8-3.589-8-8-8z'
            clipRule='evenodd'
          ></path>
        )}
        <path
          fill={alwaysActive ? '#92D9BB' : '#fff'}
          fillRule='evenodd'
          d='M8 0C3.589 0 0 3.589 0 8s3.589 8 8 8 8-3.589 8-8-3.589-8-8-8zm2.293 5.293L7 8.586 5.707 7.293a.999.999 0 10-1.414 1.414l2 2a.997.997 0 001.414 0l4-4a.999.999 0 10-1.414-1.414z'
          clipRule='evenodd'
        ></path>
      </svg>
    )
  }

  return <div className={styles.toggleCircle}></div>
}

export default SuccessIcon

.react-app ._modal_1jrqo_1 * {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Helvetica Neue", Arial, sans-serif;
  box-sizing: border-box !important;
}
.react-app ._modal_1jrqo_1 .material-icons {
  font-family: "Material Icons" !important;
}
.react-app ._modal_1jrqo_1 img {
  display: block;
  max-height: 45px;
  margin-bottom: var(--space-more);
}
.react-app ._modal_1jrqo_1 ._alert_1jrqo_13 {
  width: auto;
  margin-top: var(--space-small);
  margin-bottom: var(--space-small);
}

.react-app ._iconAndLabel_1jrqo_19 {
  display: flex;
  align-items: center;
}

.react-app ._container_1jrqo_24 {
  text-decoration: none;
  padding: 15px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.react-app ._container_1jrqo_24:not(:last-child) {
  border-bottom: 1px solid #d8dde2;
}

.react-app ._label_1jrqo_35 {
  margin-left: 10px;
  margin-bottom: 0 !important;
}

.react-app ._backButton_1jrqo_40 {
  float: right;
  margin-top: 24px;
  margin-right: 10px;
  padding: 8px 16px;
}

.react-app ._form_1jrqo_47 button[type=submit] {
  float: right;
}
.react-app ._form_1jrqo_47 div[role=alert] {
  width: auto;
}
import qs from 'qs'

const getRegistrationTemplates = async (ids) => {
  const params = qs.stringify(
    {
      template_type: 'registration',
      opened_only: true,
      ids
    },
    { arrayFormat: 'brackets' }
  )

  return fetch(`/api/portal/applics/templates?${params}`, {
    credentials: 'same-origin'
  }).then((result) => result.json())
}

export default getRegistrationTemplates

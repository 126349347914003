import React from 'react'
import { Trans } from 'react-i18next'

import PageContainer from 'apps/Imports/components/page-container'
import ImportSummaryStep from 'apps/Imports/components/product-import/import-summary-step'
import SidebarTitle from 'apps/Imports/components/sidebar-title'
import { useCurrentStep } from 'apps/Imports/contexts/steps'
import { PRODUCT_IMPORT_STEPS } from 'apps/Imports/utils/product-import/constants'

const ImportSummaryPage = () => {
  useCurrentStep(PRODUCT_IMPORT_STEPS.SUMMARY)

  return (
    <PageContainer
      title={<Trans>Summary</Trans>}
      sidebarTitle={<SidebarTitle />}
      subtitle={
        <Trans>
          Please confirm all the information below before importing.
        </Trans>
      }
      // className={styles.container}
      contentColumns={9}
    >
      <ImportSummaryStep />
    </PageContainer>
  )
}

export default ImportSummaryPage

import React from 'react'
import { Trans } from 'react-i18next'

import moment from 'moment'

import {
  BoxShadow,
  Link,
  PointerDownIcon,
  PointerUpIcon,
  Text
} from '@fullfabric/alma-mater'

import useFetchedImportData from 'apps/Imports/components/import-report-step/hooks/use-fetched-import-data'
import ReportSummaryField from '../report-summary-field'
// import FilenameField from "../../containers/report-summary-fields/filename";
import ExpandedSummary from './expanded-summary'

import styles from './styles.module.scss'

const ReportSummarySection = () => {
  const [expanded, setExpanded] = React.useState(false)
  const importData = useFetchedImportData()

  const importDocument = importData.document
  const creator = importDocument.created_by

  const toogleExpand = React.useCallback(
    (evt) => {
      evt.preventDefault()
      setExpanded((expanded) => !expanded)
    },
    [setExpanded]
  )

  return (
    <BoxShadow type='3' className={styles.summaryContainer}>
      <Text type='h4' fontColor='text-base-darkest' marginBottom='default'>
        <Trans>Summary</Trans>
      </Text>

      <ReportSummaryField
        label={<Trans>Imported by</Trans>}
        value={
          creator && importDocument ? (
            <Trans>
              {creator.first_name} {creator.last_name} on{' '}
              {moment(importDocument.updated_at).format('LL')} at{' '}
              {moment(importDocument.updated_at).format('LT')}
            </Trans>
          ) : null
        }
        marginBottom='small'
      />

      <ReportSummaryField
        label={<Trans>File name</Trans>}
        value={importDocument.filename}
      />

      {expanded ? <ExpandedSummary /> : null}

      <Link
        icon={expanded ? 'expand_less' : 'expand_more'}
        className={styles.expandCollapseButton}
        onClick={toogleExpand}
      >
        {expanded ? (
          <>
            <Trans>Collapse</Trans>
            <PointerUpIcon />
          </>
        ) : (
          <>
            <Trans>Expand</Trans>
            <PointerDownIcon />
          </>
        )}
      </Link>
    </BoxShadow>
  )
}

export default ReportSummarySection

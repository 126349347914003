import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import moment from 'moment'

import { Col, Field, Row, Text } from '@fullfabric/alma-mater'

const CourseCreateFormDates = ({ data, onChange }) => {
  const { t } = useTranslation()

  return (
    <div role='form' aria-label='course dates creation form'>
      <Text type='f3' marginBottom='small' fontColor='text-base-darkest'>
        <Trans>Applications</Trans>
      </Text>

      <Row>
        <Col xs={6}>
          <Field
            id='opens_on'
            type='date'
            label={<Trans>Applications open on</Trans>}
            value={data.opens_on && moment(data.opens_on)}
            onChange={onChange}
            inputOptions={{
              dateFormat: 'DD/MM/YYYY',
              timeFormat: '[at] HH:mm',
              readableDateAndTimeFormat: 'dd/mm/yyyy at hh:mm',
              inputProps: {
                placeholder: t('Select the day')
              }
            }}
          />
        </Col>

        <Col xs={6}>
          <Field
            id='closes_on'
            type='date'
            label={<Trans>Applications close on</Trans>}
            value={data.closes_on && moment(data.closes_on)}
            onChange={onChange}
            inputOptions={{
              dateFormat: 'DD/MM/YYYY',
              timeFormat: '[at] HH:mm',
              readableDateAndTimeFormat: 'dd/mm/yyyy at hh:mm',
              inputProps: {
                placeholder: t('Select the day')
              }
            }}
          />
        </Col>
      </Row>

      <Text type='f3' marginBottom='small' fontColor='text-base-darkest'>
        <Trans>Class</Trans>
      </Text>

      <Row>
        <Col xs={6}>
          <Field
            id='starts_on'
            type='date'
            label={<Trans>Class starts on</Trans>}
            value={data.starts_on && moment(data.starts_on)}
            onChange={onChange}
            inputOptions={{
              dateFormat: 'DD/MM/YYYY',
              timeFormat: '[at] HH:mm',
              readableDateAndTimeFormat: 'dd/mm/yyyy at hh:mm',
              inputProps: {
                placeholder: t('Select the day')
              }
            }}
          />
        </Col>

        <Col xs={6}>
          <Field
            id='ends_on'
            type='date'
            label={<Trans>Class ends on</Trans>}
            value={data.ends_on && moment(data.ends_on)}
            onChange={onChange}
            inputOptions={{
              dateFormat: 'DD/MM/YYYY',
              timeFormat: '[at] HH:mm',
              readableDateAndTimeFormat: 'dd/mm/yyyy at hh:mm',
              inputProps: {
                placeholder: t('Select the day')
              }
            }}
          />
        </Col>
      </Row>
    </div>
  )
}

export default CourseCreateFormDates

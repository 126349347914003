import { useCallback, useEffect } from 'react'

export const usePreventLeaveIfSaving = (saving) => {
  const handleBeforeUnload = useCallback(
    (e) => {
      if (saving) {
        e.preventDefault()
        e.stopPropagation()
        e.returnValue = false
        return false
      }
    },
    [saving]
  )

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [handleBeforeUnload])
}

import React from 'react'
import { Trans } from 'react-i18next'

import PropTypes from 'prop-types'

import { useAppData } from 'shared/contexts/AppData'

import AuthorizationWrapper from './authorization-wrapper'
import BaseApplicationsWidget from './base'

export default function MyApplicationsWidget({ widget }) {
  const { applics } = useAppData()
  const templateIds = (applics || []).map((applic) => applic.template_id)

  return (
    <AuthorizationWrapper id={widget.id} title={<Trans>My Applications</Trans>}>
      <BaseApplicationsWidget
        widgetId={widget.id}
        templateIds={templateIds}
        emptyMessage={
          <Trans>You haven't started or submitted an application yet</Trans>
        }
      />
    </AuthorizationWrapper>
  )
}

MyApplicationsWidget.propTypes = {
  widget: PropTypes.shape({
    id: PropTypes.string.isRequired
  }).isRequired
}

import React from 'react'
import { Trans } from 'react-i18next'
import { useMutation } from 'react-query'
import { useHistory, useParams } from 'react-router'

import {
  AlertFullIcon,
  Button,
  Modal,
  ModalButtons,
  ModalTitle,
  Text
} from '@fullfabric/alma-mater'

import deleteSubject from 'apps/StudyPlans/api/deleteSubject'
import { HOME_ROUTE } from 'apps/StudyPlans/components/AppRoutes/constants'
import {
  useSetStudyPlan,
  useStudyPlan
} from 'apps/StudyPlans/contexts/StudyPlanData'

const DeleteSubjectModal = ({
  isOpen,
  subject,
  onCancel,
  onDeleted,
  cannotDelete
}) => {
  const studyPlan = useStudyPlan()
  const setStudyPlan = useSetStudyPlan()
  const history = useHistory()
  const { classId } = useParams()

  const { mutate: deleteMutation, isLoading: deleteInProgress } = useMutation(
    ({ studyPlanId, subjectId }) => {
      deleteSubject(studyPlanId, subjectId)
    },
    {
      onSuccess: () => {
        history.push(HOME_ROUTE.replace(':classId', classId))

        const deletedSubjectIndex = studyPlan.courses.findIndex(
          (s) => s.id === subject.id
        )
        setStudyPlan({
          ...studyPlan,
          courses: [
            ...studyPlan.courses.slice(0, deletedSubjectIndex),
            ...studyPlan.courses.slice(deletedSubjectIndex + 1)
          ]
        })

        onDeleted && onDeleted()
      }
    }
  )

  const onDeleteClick = React.useCallback(() => {
    deleteMutation({ studyPlanId: studyPlan.id, subjectId: subject.id })
  }, [deleteMutation, studyPlan.id, subject.id])

  const canDeleteSubject =
    !cannotDelete && !subject.is_registered_in_any_transcript

  return (
    <Modal
      size={canDeleteSubject ? 'medium' : 'small'}
      isOpen={isOpen}
      header={
        <ModalTitle
          title={
            <>
              <AlertFullIcon color='text-alert' className='marginRight-small' />
              <Trans>Delete study plan subject</Trans>
            </>
          }
          onClose={onCancel}
        />
      }
      footer={
        <ModalButtons>
          <Button onClick={onCancel} disabled={deleteInProgress}>
            {canDeleteSubject ? <Trans>Close</Trans> : <Trans>Cancel</Trans>}
          </Button>
          {canDeleteSubject && (
            <Button
              onClick={onDeleteClick}
              loading={deleteInProgress}
              className='marginLeft-small'
              negative
              primary
            >
              <Trans>Delete study plan subject</Trans>
            </Button>
          )}
        </ModalButtons>
      }
    >
      {canDeleteSubject ? (
        <>
          <Text tag='p' type='h4' fontColor='text-base-darkest'>
            <Trans>Are you ABSOLUTELY sure?</Trans>
          </Text>
          <Text
            tag='p'
            type='f5'
            marginBottom='default'
            fontColor='text-base-darkest'
          >
            <Trans>This action CANNOT be undone.</Trans>
          </Text>
          <Text tag='p' type='h5' fontColor='text-base-darkest'>
            <Trans>This action will:</Trans>
          </Text>
          <Text tag='p' type='f5' marginBottom='small'>
            <Trans>
              Remove the study plan subject from student transcripts where the
              student has not registered to the subject
            </Trans>
          </Text>
        </>
      ) : (
        <Text tag='p' type='h5' fontColor='text-base-darkest'>
          <Trans>
            It is not possible to remove the course due to existing
            registrations.
          </Trans>
        </Text>
      )}
    </Modal>
  )
}

export default DeleteSubjectModal

const addPaddingDataPointToTimeserie = (data, isWeeklyTimeframe = true) => {
  if (!data.length || data.length % 2 === 1) {
    return data
  }

  const firstDataPoint = data.find((d) => !!d)
  const emptyDataPoint = Object.keys(firstDataPoint).reduce(
    (result, key) => ({ [key]: null, ...result }),
    isWeeklyTimeframe
      ? { week: firstDataPoint.week - 1 }
      : { month: firstDataPoint.month - 1 }
  ) // builds object with same structure but empty values

  return [emptyDataPoint, ...data]
}

export default addPaddingDataPointToTimeserie

{
  "A word by itself is easy to guess": "Een woord op zichzelf is gemakkelijk te raden",
  "Add \"{{val}}\"": "\"{{val}}\" toevoegen",
  "Add another word or two. Uncommon words are better.": "Voeg nog een woord of twee toe. Ongebruikelijke woorden zijn beter.",
  "All-uppercase is almost as easy to guess as all-lowercase": "Alle-hoofdletters is bijna net zo gemakkelijk te raden als in kleine letters",
  "Almost there": "We zijn er bijna",
  "Already have an account?": "Heb je al een profiel?",
  "Avoid dates and years that are associated with you": "Vermijd datums en jaren die met u zijn geassocieerd",
  "Avoid recent years": "Vermijd de afgelopen jaren",
  "Avoid repeated words and characters": "Vermijd herhaalde woorden en karakters",
  "Avoid sequences": "Vermijd reeksen",
  "Avoid years that are associated with you": "Vermijd jaren die met jou zijn geassocieerd",
  "Back to login": "Terug naar de inlogpagina",
  "Cancel": "Annuleer",
  "Capitalization doesn't help very much": "Kapitalisatie helpt niet veel",
  "Common names and surnames are easy to guess": "Gemeenschappelijke namen en achternamen zijn gemakkelijk te raden",
  "Continue": "Doorgaan",
  "Create an account": "Profiel aanmaken",
  "Create new account": "Creëer een nieuw profiel",
  "Data Protection Regulation": "Verordening gegevensbescherming",
  "Dates are often easy to guess": "Datums zijn vaak gemakkelijk te raden",
  "Didn't receive the sms?": "Geen sms ontvangen?",
  "E-mail address": "E-mailadres",
  "First name": "Voornaam",
  "For security reasons account activation links are perishable. You can get a new activation link by filling the sign up form.": "Om veiligheidsredenen zijn profielactivatielinks vergankelijk. Door het inschrijfformulier in te vullen zal je een nieuwe activatielink ontvangen.",
  "Forgot your password?": "Je wachtwoord vergeten?",
  "Great, your account has been created!": "Perfect, je account is aangemaakt!",
  "I accept the privacy policy": "Ik accepteer het privacybeleid",
  "Institutional login": "Institutionele login",
  "Last name": "Achternaam",
  "Link existing account": "Bestaand profiel koppelen",
  "LinkedIn": "LinkedIn",
  "Log in": "Inloggen",
  "Log in securely": "Log veilig in",
  "Names and surnames by themselves are easy to guess": "Namen en achternamen op zichzelf zijn gemakkelijk te raden",
  "New Password": "Nieuw paswoord",
  "No Results": "Geen resultaten",
  "No need for symbols, digits, or uppercase letters": "Geen symbolen, ifids of hoofdletters nodig",
  "Not registered?": "Niet geregistreerd?",
  "Nothing found for": "Niets gevonden voor",
  "Or use these alternatives": "Of gebruik deze alternatieven:",
  "Password": "Wachtwoord",
  "Password Reset": "Wachtwoord reset",
  "Please accept the privacy policy to continue": "Accepteer alstublieft het privacybeleid om door te gaan",
  "Please check your email for instructions on resetting your password": "Controleer je e-mail om je wachtwoord opnieuw in te stellen",
  "Please check your mailbox for instructions on verifying your registration.": "Controleer je e-mail voor meer informatie over het verifiëren van jouw registratie.",
  "Please choose a password": "Kies een wachtwoord",
  "Please confirm your account details below": "Bevestig je profielgegevens hieronder",
  "Please contact your university's IT support department in order to get a new activation link to access Full Fabric.": "Neem contact op met de IT-afdeling om een nieuwe activatielink te krijgen voor Full Fabric.",
  "Please type a new password.": "Voer een nieuw wachtwoord in.",
  "Predictable substitutions like \"@\" instead of \"a\" don't help very much": "Voorspelbare substituties zoals '@' in plaats van 'a', helpen niet erg",
  "Predictable substitutions like '@' instead of 'a' don't help very much": "Voorspelbare substituties zoals '@' in plaats van 'a', helpen niet erg",
  "Privacy policy": "Privacybeleid",
  "Recent years are easy to guess": "De afgelopen jaren zijn gemakkelijk te raden",
  "Repeats like \"aaa\" are easy to guess": "Herhalingen zoals \"aaa\" zijn gemakkelijk te raden",
  "Repeats like \"abcabcabc\" are only slightly harder to guess than \"abc\"": "Herhalingen zoals \"abcabcabc\" zijn slechts iets moeilijker te raden dan \"abc\"",
  "Reset password": "Wachtwoord resetten",
  "Reset password and sign in": "Reset wachtwoord en log in",
  "Reset your password below.": "Reset hieronder je wachtwoord.",
  "Return to log in page": "Terug naar de inlogpagina",
  "Reversed words aren't much harder to guess": "Omgekeerde woorden zijn niet veel moeilijker te raden",
  "Send again": "Opnieuw versturen",
  "Sequences like abc or 6543 are easy to guess": "Sequences zoals abc of 6543 zijn gemakkelijk te raden",
  "Set password and sign in": "Stel wachtwoord in en meld je aan",
  "Short keyboard patterns are easy to guess": "Korte toetsenbordpatronen zijn gemakkelijk te raden",
  "Sign in": "Aanmelden",
  "Sign up": "Aanmelden",
  "Skip to main content": "Ga naar de hoofdinhoud",
  "Straight rows of keys are easy to guess": "Rechte rijen toetsen zijn gemakkelijk te raden",
  "Suggestions": "Suggesties",
  "Thank you.": "Dank je.",
  "There was a server problem. Please try again.": "Er was een probleem met de server. Probeer het opnieuw.",
  "This is a top-10 common password": "Dit is een van de 10 meest voorkomende wachtwoorden",
  "This is a top-100 common password": "Dit is een van de 100 meest voorkomende wachtwoorden",
  "This is a very common password": "Dit is een heel gebruikelijk wachtwoord",
  "This is similar to a commonly used password": "Dit is vergelijkbaar met een veelgebruikt wachtwoord",
  "Two Factor Authentication Token": "Token met twee-factorenauthenticatie",
  "Two step verification": "Tweestapsverificatie",
  "Use a few words, avoid common phrases": "Gebruik een paar woorden, vermijd veelgebruikte zinsdelen",
  "Use a longer keyboard pattern with more turns": "Gebruik een langer toetsenbordpatroon met meer beurten",
  "Use one of these alternatives to signup": "Gebruik een van deze alternatieven om u aan te melden",
  "Use one of these methods to log in": "Gebruik een van deze methoden om in te loggen",
  "We sent a sms to the number <1>{{phoneNumber}}</1> with the verification code": "We stuurden een sms naar het nummer <1>{{phoneNumber}}</1> met de verificatiecodetweestapsverificatie",
  "Welcome": "Welkom",
  "Your activation link has expired.": "Je activatielink is verlopen.",
  "Your password has expired.": "Je wachtwoord is verlopen.",
  "Your password is good": "Je wachtwoord is goed",
  "Your password is reasonably adequate": "Je wachtwoord is sterk genoeg",
  "Your password is too weak": "Je wachtwoord is te zwak",
  "or e-mail": "of e-mail",
  "or link an existing account": "of koppel een bestaand profiel",
  "{{minAlpha}} letters": "{{minAlpha}} letter(en)",
  "{{minDigits}} digits": "{{minDigits}} cijfer(s)",
  "{{minLength}} characters": "{{minLength}} tekens",
  "{{minSymbols}} symbols": "{{minSymbols}} symbool",
  "Password must be at least {{minLength}} characters long": "Het wachtwoord moet op zijn minst {{minLength}} tekens lang zijn",
  "Password must have at least {{count}} characters": "Wachtwoord moet minimaal {{count}} karakter hebben",
  "Password must have at least {{count}} characters_plural": "Wachtwoord moet ten minste {{count}} tekens lang zijn",
  "Password must have at least {{count}} digit": "Wachtwoord moet minimaal {{count}} cijfer hebben",
  "Password must have at least {{count}} digit_plural": "Wachtwoord moet ten minste {{count}} cijfers lang zijn",
  "Password must have at least {{count}} symbol": "Wachtwoord moet ten minste {{count}} symbool bevatten",
  "Password must have at least {{count}} symbol_plural": "Wachtwoord moet ten minste {{count}} symbolen bevatten",
  "Password was already used": "Het wachtwoord werd al gebruikt",
  "Thank you. Please log in below.": "Bedankt. Gelieve hieronder in te loggen.",
  "This field is required": "Dit veld is vereist",
  "Privacy Policy": "Privacybeleid",
  "Link to an existing account": "Een bestaand profiel koppelen",
  "There are fields with errors.": "Er zijn velden met fouten.",
  "Please correct the fields with errors.": "Gelieve de velden met fouten te corrigeren.",
  "We sent a sms to the number {{ phoneNumber }} with the verification code": "We stuurden een sms naar het nummer {{phoneNumber}} met de verificatiecodetweestapsverificatie"
}

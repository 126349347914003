/* eslint-disable camelcase */

import { connect } from 'react-redux'

import { push } from 'connected-react-router'

import actionTypes from 'apps/Courses/actions/types'
import CourseList from 'apps/Courses/components/CourseList'
import { EDIT_COURSE_ROUTE } from 'apps/Courses/utils/routeConstants'

const mapStateToProps = ({
  appData: { h: { campuses_options = [] } = {} } = {}
}) => ({
  campusesOptions: campuses_options.map(([label, value]) => ({
    value,
    label
  }))
})

const mapDispatchToProps = (dispatch) => ({
  onOpenDeleteModal: (course) =>
    dispatch({
      type: actionTypes.openDeleteCourseModal,
      payload: { course, open: true }
    }),
  onCourseClick: (courseId) =>
    dispatch(push(EDIT_COURSE_ROUTE.replace(':id', courseId)))
})

export default connect(mapStateToProps, mapDispatchToProps)(CourseList)

import React, { useCallback, useState } from 'react'

import { useSettings } from '@fullfabric/authorization-officer'

const BackgroundImageContext = React.createContext()
const ChangeBackgroundImageContext = React.createContext()

const BackgroundImageProvider = ({ value, children }) => {
  const settings = useSettings()
  const defaultImageUrl =
    settings?.hash?.modules?.authentication?.background_url

  const [bgImage, setBgImage] = useState(value)

  const changeBackgroundImageUrl = useCallback(
    (url) => {
      url ||= defaultImageUrl

      if (!url) return

      setBgImage(url)
    },
    [defaultImageUrl, setBgImage]
  )

  return (
    <BackgroundImageContext.Provider value={bgImage}>
      <ChangeBackgroundImageContext.Provider value={changeBackgroundImageUrl}>
        {children}
      </ChangeBackgroundImageContext.Provider>
    </BackgroundImageContext.Provider>
  )
}

const useBackgroundImage = () => {
  const imageUrl = React.useContext(BackgroundImageContext)
  return imageUrl
}

const useChangeBackgroundImage = () => {
  const changeBackgroundImage = React.useContext(ChangeBackgroundImageContext)

  return changeBackgroundImage
}

export { BackgroundImageProvider, useBackgroundImage, useChangeBackgroundImage }

import React, { useState } from 'react'

import LinkAccountForm from './LinkAccountForm'
import LinkedinSignupForm from './LinkedinSignupForm'

function LinkedInArrivalsForm() {
  const [activeForm, setActiveForm] = useState('signup')

  if (activeForm === 'signup') {
    return (
      <LinkedinSignupForm
        onClickLinkAccount={(evt) => {
          evt.preventDefault()
          setActiveForm('link')
        }}
      />
    )
  }

  if (activeForm === 'link') {
    return (
      <LinkAccountForm
        onClickCreateAccount={(evt) => {
          evt.preventDefault()
          setActiveForm('signup')
        }}
      />
    )
  }
}

export default LinkedInArrivalsForm

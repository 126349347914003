import { useCallback, useRef, useState } from 'react'

import * as tus from 'tus-js-client'

import { buildChannelName } from './helpers'

const FIFTY_MB = 50 * 1024 * 1024

/**
 * Helper hook for handling multiple resumable file uploads using Tus.
 *
 * Expects a single endpoint as an argument that should have a mounted Tus
 * handler. This then returns a single object with multiple functions for
 * starting uploads, and other objects with upload status.
 *
 * ### Usage
 *
 * Call the returned `prepareUpload` function, followed by `startUpload`. If
 * the upload can be resumed, this will be handled automatically.
 *
 * Both functions expect an upload ID as the first argument. This has no impact
 * on the upload itself, but it should uniquely identify each different instance
 * of upload being handled (for example, one per file input).
 *
 * ### Returns an object with the following keys:
 *
 * * `prepareUpload`: A function that takes an upload ID, a file and a metadata
 *   object, of which the `channelName` key is required.
 * * `startUpload`: A function that takes an upload ID and starts the upload.
 * * `error`: A map of upload IDs to their error messages (string).
 * * `progress`: A map of upload IDs to their current upload progress (0 to 1).
 * * `status`: A map of upload IDs to their status (null, "succeeded" or
 *   "aborted").
 * * `tusUploads`: A map of upload IDs to their Tus upload objects, if any.
 *
 * @param {String} opts.endpoint Upload endpoint, file-specific.
 * @param {String} opts.userId Id of current user.
 * @param {String} opts.uploadScopeId Id of the context this upload is in. E.g:
 * schemable document id.
 * @returns See description for contents.
 */
export function useTusUploads({ endpoint, userId, uploadScopeId }) {
  const [error, setError] = useState({})
  const [status, setStatus] = useState({})
  const [progress, setProgress] = useState({})
  const tusUploadsRef = useRef({})

  const setUploadError = useCallback(
    (uploadId, error) => setError((prev) => ({ ...prev, [uploadId]: error })),
    [setError]
  )

  const setUploadStatus = useCallback(
    (uploadId, status) =>
      setStatus((prev) => ({ ...prev, [uploadId]: status })),
    [setStatus]
  )

  const setUploadProgress = useCallback(
    (uploadId, uploadProgress) =>
      setProgress((prev) => ({ ...prev, [uploadId]: uploadProgress })),
    [setProgress]
  )

  const resetUpload = useCallback(
    (uploadId) => {
      setUploadError(uploadId, null)
      setUploadProgress(uploadId, 0)
      setUploadStatus(uploadId, null)
    },
    [setUploadError, setUploadStatus, setUploadProgress]
  )

  const prepareUpload = useCallback(
    ({ uploadId, file, extraMetadata }) => {
      const metadata = {
        filename: file.name,
        filetype: file.type,
        channelName: buildChannelName({ userId, uploadScopeId, uploadId }),
        ...(extraMetadata || {})
      }

      const tusUpload = new tus.Upload(file, {
        endpoint,
        chunkSize: FIFTY_MB,
        metadata,
        // Retry delays will enable tus-js-client to automatically retry on errors
        // retryDelays: [0, 3000, 5000, 10000, 20000],
        onError: (error) => {
          setUploadStatus(uploadId, 'aborted')
          setUploadError(uploadId, buildErrorObject(error))

          tusUpload.abort()
        },
        onShouldRetry: (error, _retryAttempt, _options) => {
          const status = error?.originalResponse?.getStatus()
          return ![500, 406, 404, 403].includes(status)
        },
        onProgress: (bytesUploaded, bytesTotal) => {
          setUploadProgress(uploadId, bytesUploaded / bytesTotal)
        },
        onSuccess: (/* no args */) => {
          setUploadStatus(uploadId, 'succeeded')
        }
      })

      tusUploadsRef.current[uploadId] = tusUpload

      resetUpload(uploadId)
    },
    [
      endpoint,
      setUploadError,
      setUploadStatus,
      setUploadProgress,
      resetUpload,
      uploadScopeId,
      userId
    ]
  )

  const startUpload = async (uploadId) => {
    const tusUpload = tusUploadsRef.current[uploadId]

    if (!tusUpload) {
      throw new Error('No tus upload prepared')
    }

    // Check if there are any previous uploads to continue.
    const previousUploads = await tusUpload.findPreviousUploads()

    if (
      previousUploads.length &&
      previousUploads[0].metadata.fieldName ===
        tusUpload.options.metadata.fieldName
    ) {
      // We can only reuse previous uploads if the fieldName matches, otherwise
      // the wrong field will end up with the file (the metadata is stored
      // along with the aborted upload).

      tusUpload.resumeFromPreviousUpload(previousUploads[0])
    }

    tusUpload.start()
  }

  return {
    progress,
    status,
    error,
    prepareUpload,
    startUpload,
    tusUploads: tusUploadsRef.current
  }
}

/**
 * Same as `useTusUploads`, but simplified for a single upload only.
 *
 * The `prepareUpload` and `startUpload` functions don't need an `uploadId`
 * parameter, and a single `tusUpload` object is returned instead of the
 * `tusUploads` map.
 *
 * @param {String} endpoint Upload endpoint, file-specific.
 * @returns {Object} See description for contents.
 */
export function useTusUpload(endpoint) {
  const uploadId = 'USE-TUS-UPLOAD'

  const { error, prepareUpload, progress, startUpload, status, tusUploads } =
    useTusUploads(endpoint)

  const prepareSingleUpload = useCallback(
    (...args) => prepareUpload(uploadId, ...args),
    [prepareUpload]
  )

  const startSingleUpload = useCallback(
    (...args) => startUpload(uploadId, ...args),
    [startUpload]
  )

  return {
    error: error[uploadId],
    prepareUpload: prepareSingleUpload,
    progress: progress[uploadId],
    startUpload: startSingleUpload,
    status: status[uploadId],
    tusUpload: tusUploads[uploadId]
  }
}

/**
 * @private
 */
function buildErrorObject(tusError) {
  let message
  const status = tusError?.originalResponse?.getStatus()

  if (status === 500) {
    message = 'unknown error uploading file'
  } else {
    message = tusError?.originalResponse?.getBody()?.toString()
  }

  return {
    status,
    message,
    original: tusError
  }
}

import * as React from 'react'

import requestProductPerformanceExport from 'apps/Reports/api/requestProductPerformanceExport'
import requestProductPerformanceExportWithDetailedCapacity from 'apps/Reports/api/requestProductPerformanceExportWithDetailedCapacity'
import useHasCourseJourneysSetting from 'apps/Reports/hooks/useHasCourseJourneysSetting'

const useHandleExportReport = () => {
  const hasCourseJourneys = useHasCourseJourneysSetting()

  const enqueueReportExport = React.useCallback(
    async (params) => {
      if (hasCourseJourneys) {
        return await requestProductPerformanceExportWithDetailedCapacity(params)
      }

      return await requestProductPerformanceExport(params)
    },
    [hasCourseJourneys]
  )

  return enqueueReportExport
}

export default useHandleExportReport

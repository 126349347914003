import i18n from 'i18next'

import daResource from 'apps/EmailTemplates/i18n/da/locales.json'
import deResource from 'apps/EmailTemplates/i18n/de/locales.json'
import enResource from 'apps/EmailTemplates/i18n/en/locales.json'
import esResource from 'apps/EmailTemplates/i18n/es/locales.json'
import frResource from 'apps/EmailTemplates/i18n/fr/locales.json'
import itResource from 'apps/EmailTemplates/i18n/it/locales.json'
import nlResource from 'apps/EmailTemplates/i18n/nl/locales.json'
import svResource from 'apps/EmailTemplates/i18n/sv/locales.json'

export const loadEmailTemplatesI18n = (
  namespace = 'translation',
  i18nInstance = i18n
) => {
  i18nInstance.addResourceBundle('en', namespace, enResource, true, false)
  i18nInstance.addResourceBundle('de', namespace, deResource, true, false)
  i18nInstance.addResourceBundle('es', namespace, esResource, true, false)
  i18nInstance.addResourceBundle('fr', namespace, frResource, true, false)
  i18nInstance.addResourceBundle('nl', namespace, nlResource, true, false)
  i18nInstance.addResourceBundle('sv', namespace, svResource, true, false)
  i18nInstance.addResourceBundle('da', namespace, daResource, true, false)
  i18nInstance.addResourceBundle('it', namespace, itResource, true, false)
}

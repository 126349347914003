import React from 'react'

import SubjectBasicsForm from 'apps/Subjects/components/SubjectBasicsForm'
import SubjectBasicsSection from 'apps/Subjects/components/SubjectBasicsSection'

const BasicsCreationForm = () => {
  return (
    <SubjectBasicsSection>
      <SubjectBasicsForm />
    </SubjectBasicsSection>
  )
}

export default BasicsCreationForm

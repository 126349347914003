import React from 'react'

import { useScreenClass } from '@fullfabric/alma-mater'

import { useAppData } from 'shared/contexts/AppData'
import { useLocale } from 'shared/contexts/Locale'

import getLocalizedText from 'apps/ContentPages/utils/get-localized-text'
import PageMainTextContent from '../page-main-text-content'

import styles from './styles.module.scss'

const EventPageTextContent = () => {
  const { event } = useAppData()
  const screenClass = useScreenClass()
  const isMobileLayout = ['xs', 'sm', 'md'].includes(screenClass)
  const { locale } = useLocale()

  return (
    <div className={!isMobileLayout ? styles.container : null}>
      <PageMainTextContent>
        {getLocalizedText(
          event?.more_info_text_locales,
          locale,
          'html',
          'en-GB'
        )}
      </PageMainTextContent>
    </div>
  )
}

export default EventPageTextContent

import React from 'react'
import { Trans } from 'react-i18next'

import { SuccessIcon, Text } from '@fullfabric/alma-mater'

import styles from './styles.module.scss'

export default function PaidCard() {
  return (
    <div className={styles.noticeContainer}>
      <SuccessIcon color='text-success' width={24} height={24} />
      <Text
        type='h3'
        fontColor='text-base-darkest'
        className={'marginTop-default'}
      >
        <Trans>Fee has been paid</Trans>
      </Text>
      <Text type='f4' fontColor='text-base-darkest'>
        <Trans>The requester has been notified.</Trans>
      </Text>
    </div>
  )
}

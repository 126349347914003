import 'css-chunk:src/shared/components/cookie-prompt/styles.module.scss';export default {
  "react-app": "react-app",
  "cookieBannerContainer": "_cookieBannerContainer_128y4_1",
  "buttonCol": "_buttonCol_128y4_12",
  "textCol": "_textCol_128y4_18",
  "small": "_small_128y4_22",
  "flexCols": "_flexCols_128y4_25",
  "linkText": "_linkText_128y4_37",
  "noEndMargin": "_noEndMargin_128y4_43",
  "cookieText": "_cookieText_128y4_46",
  "cookiesPolicyModal": "_cookiesPolicyModal_128y4_50"
};
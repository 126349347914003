import React from 'react'

import { Table } from '@fullfabric/alma-mater'

import styles from './Cell.module.scss'

export default function HandlerCell({ data, row, value }) {
  const args = data[row.index].args

  return (
    <Table.Data
      tag='div'
      title={value}
      complementaryInfo={`(${args.join(', ')})`}
      className={styles.customCell}
    >
      {value}
    </Table.Data>
  )
}

.react-app ._fieldArrowIcon_1rjt7_1 {
  position: absolute;
  top: 12px;
  right: 8px;
}

.react-app ._requiredFieldColData_1rjt7_7 {
  position: relative;
}

.react-app ._allFieldsSection_1rjt7_11 {
  margin-top: var(--space-big);
}

.react-app ._allFieldsList_1rjt7_15 {
  margin-top: var(--space-small);
  table-layout: fixed;
}
.react-app ._allFieldsList_1rjt7_15 ._allFieldsHeaderItem_1rjt7_19,
.react-app ._allFieldsList_1rjt7_15 ._allFieldsColData_1rjt7_20 {
  padding-left: 0;
  vertical-align: middle;
}
.react-app ._allFieldsList_1rjt7_15 ._samplesColumnHeader_1rjt7_24 {
  width: 40%;
}
.react-app ._allFieldsList_1rjt7_15 ._samplesColumnText_1rjt7_27 {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.react-app ._allFieldsCsvFieldCol_1rjt7_33 {
  position: relative;
}
.react-app ._allFieldsCsvFieldCol_1rjt7_33 p {
  display: inline-block;
  width: calc(100% - var(--space-more));
}
.react-app ._allFieldsCsvFieldCol_1rjt7_33 ._arrow_1rjt7_40 {
  margin-left: var(--space-small);
  vertical-align: middle;
}
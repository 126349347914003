.react-app ._container_ou6hg_1 {
  margin-bottom: var(--space-default);
}

.react-app ._title_ou6hg_5 {
  margin-bottom: var(--space-small);
}

.react-app ._description_ou6hg_9 p {
  margin-bottom: var(--space-default);
}
const getLocaledLabel = (locals, language) => {
  const englishName = locals.filter(
    ({ locale }) => locale.split('-')[0] === 'en'
  )[0]?.label
  const selectedLocalName = locals.filter(
    ({ locale }) => locale.split('-')[0] === language
  )[0]?.label
  return selectedLocalName || englishName
}

export default getLocaledLabel

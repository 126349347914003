import { utils } from '@fullfabric/public-api'

const deleteSegment = async (id) => {
  const response = await fetch(`/api/segments/${id}`, {
    credentials: 'same-origin',
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' }
  })

  return await utils.checkResponse(response)
}

export default deleteSegment

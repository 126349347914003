.react-app ._linkText_1nsxd_1 {
  padding: var(--space-less) var(--space-default);
  border-left: var(--border-base-lighter);
}
.react-app ._linkText_1nsxd_1._first_1nsxd_5 {
  padding-top: var(--space-tiny);
}
.react-app ._linkText_1nsxd_1._last_1nsxd_8 {
  padding-bottom: var(--space-tiny);
  margin-bottom: var(--space-small);
}
.react-app ._linkText_1nsxd_1._tabletNav_1nsxd_12 {
  padding-right: var(--space-more);
}
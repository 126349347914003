.react-app ._pageHead_mftgt_1 {
  width: 100%;
}

.react-app ._footer_mftgt_5 {
  display: flex;
  justify-content: flex-end;
  margin: 0;
  padding-bottom: var(--space-more);
}

.react-app ._layoutContainer_mftgt_12 {
  overflow: auto;
}
.react-app ._layoutContainer_mftgt_12 ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.react-app ._layoutContainer_mftgt_12 ul li {
  padding: 0;
  margin: 0;
}
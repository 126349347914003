.react-app ._emailCard_18ito_1 {
  background: var(--ff-bg-base-lightest);
  padding: var(--space-default);
  border-bottom: var(--border-base-lighter);
}
.react-app ._emailCard_18ito_1 ._subjectContainer_18ito_6 {
  height: 54px;
}
.react-app ._emailCard_18ito_1 ._subjectContainer_18ito_6 ._subject_18ito_6 {
  position: relative;
  top: 4px;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  max-width: 70%;
  display: inline-block;
  line-height: 18px;
}
.react-app ._emailCard_18ito_1 ._buttons_18ito_19 > a {
  margin: 0;
}
@supports (-ms-ime-align: auto) {
  .react-app ._emailCard_18ito_1 ._buttons_18ito_19 > a {
    top: 9px !important;
  }
}
.react-app ._emailCard_18ito_1 ._buttons_18ito_19 ._actionListWrapper_18ito_27 > button {
  position: relative;
  top: 1px;
}
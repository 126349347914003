import areaChartSample from './images/areaChart.png'
import funnelChartSample from './images/funnelChart.png'
// import verticalBarChartSample from "./images/verticalBarChart.png";
import horizontalBarChartSample from './images/horizontalBarChart.png'
import lineChartSample from './images/lineChart.png'

const getReports = (t) => [
  {
    id: 'applic_performance',
    name: t('Application performance'),
    description: t(
      'Monitor the success of the start of your admissions funnel'
    ),
    imageUrl: areaChartSample,
    featureFlag: 'application_performance_report'
  },
  {
    id: 'product_performance',
    name: t('Product performance'),
    description: t('Monitor your products’ conversions against your targets'),
    imageUrl: horizontalBarChartSample,
    featureFlag: 'product_performance_report'
  },
  {
    id: 'product_overview',
    name: t('Product overview'),
    description: t(
      'Monitor the admissions progression for a product and the demographic distribution of its candidates.'
    ),
    imageUrl: lineChartSample,
    featureFlag: 'product_overview_report'
  },
  {
    id: 'product_conversion',
    name: t('Product conversion'),
    description: t(
      'Monitor your conversion rates between different lifecycle states in your funnel for a particular product intake.'
    ),
    imageUrl: funnelChartSample,
    featureFlag: 'funnel_report'
  },
  {
    id: 'source-category-analysis',
    legacy: true,
    name: t('Source category analysis report'),
    description: t(
      'Compare the success of your different marketing activities'
    ),
    imageUrl: horizontalBarChartSample,
    featureFlag: 'source_category_analysis_report'
  },
  {
    id: 'source-method-analysis',
    legacy: true,
    name: t('Source method analysis report'),
    description: t('Identify how your leads are entering your database'),
    imageUrl: horizontalBarChartSample,
    featureFlag: 'source_method_analysis_report'
  }
]

export default getReports

import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

import { Alert, Button, Field } from '@fullfabric/alma-mater'
import { getCountries } from '@fullfabric/public-api'
import { useScrollTo } from '@fullfabric/schemable-forms'

import { useLocale } from 'shared/contexts/Locale'

import useIsMobileLayout from 'apps/ContentPages/components/payments-page/use-is-payments-mobile-layout'
import { usePaymentsDrawerData } from 'apps/ContentPages/contexts/payments-drawer'
import getFlywireLocale from 'apps/ContentPages/utils/get-flywire-locale'
import ContainerPanel from '../container-panel'

import classNames from 'classnames'
import styles from './styles.module.scss'

const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

const getPayableType = (fee) => {
  return (
    {
      'BookKeeper::PaymentPlan::Fee': 'Payment plan fee'
    }[fee?._ruby_type] || fee?._ruby_type
  )
}

const getDescription = (paymentPlan, fee) => {
  if (paymentPlan && fee) {
    return `${paymentPlan.name} - ${fee.description}`
  }
  return `${fee.description}`
}

const FlywirePayment = ({ paymentPortal }) => {
  const { t } = useTranslation()
  const isMobileLayout = useIsMobileLayout()

  const { locale: currentLocale } = useLocale()

  const { fee, paymentPlan } = usePaymentsDrawerData()
  const { data: countries, isLoading: isLoadingCountries } = useQuery(
    ['get-countries', { withCode: true }],
    () => getCountries({ withCode: true, locale: currentLocale })
  )
  const formattedCountries = countries?.map((countryData) => ({
    label: countryData.label,
    value: countryData.code
  }))

  const { control, handleSubmit, formState } = useForm({ mode: 'onBlur' })
  const { errors, isSubmitted, submitCount } = formState
  const errorBannerRef = React.useRef()
  const [lastAlertFocusCount, setLastAlertFocusCount] = React.useState(0)
  const scrollTo = useScrollTo()
  const failedValidation = isSubmitted && Object.keys(errors).length > 0

  React.useEffect(() => {
    if (submitCount > lastAlertFocusCount && failedValidation) {
      setLastAlertFocusCount(submitCount)
      errorBannerRef.current.focus({ preventScroll: true })

      scrollTo(errorBannerRef.current)
    }
  }, [submitCount, failedValidation, lastAlertFocusCount, scrollTo])

  const onSubmit = async (dataInForm) => {
    const { profile } = paymentPlan

    const flywireConfig = {
      env: process.env.NODE_ENV !== 'production' ? 'demo' : 'production',
      recipient: paymentPortal.recipient,
      provider: 'fullfabric',
      locale: getFlywireLocale(currentLocale),
      // amount in cents
      amount: fee.outstanding_amount,
      ...dataInForm,
      payable_type: getPayableType(fee),
      payable_id: paymentPlan.id || fee.id,
      description: getDescription(paymentPlan, fee),
      product: paymentPlan.context_long_name || '',
      student_id: profile.id,
      student_first_name: profile.first_name,
      student_last_name: profile.last_name,
      student_email: profile.email,
      callback_id: fee.id,
      callback_url:
        window.location.origin + '/payments/flywire/api/payment/plan_fee',
      return_url: window.location.href,
      displayPayerInformation: true
    }

    window.flywire.Checkout.render(
      flywireConfig,
      '#make-flywire-payment-button'
    )
    document.getElementById('make-flywire-payment-button').click()
  }

  const requiredRule = {
    required: {
      value: true,
      message: t('This field is required')
    }
  }

  const emailRule = {
    pattern: {
      value: EMAIL_REGEX,
      message: t('Email address is invalid')
    }
  }

  return (
    <ContainerPanel title={<Trans>Flywire billing details</Trans>}>
      {failedValidation && (
        <a
          href='#failed-payment-submission'
          className={classNames(
            styles.errorBannerContainer,
            isMobileLayout && styles.mobile
          )}
          ref={errorBannerRef}
        >
          <Alert
            error
            role='alert'
            title={t('There are fields with errors.')}
            description={t('Please correct the fields with errors.')}
          />
        </a>
      )}
      <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
        <Controller
          name='sender_first_name'
          control={control}
          defaultValue=''
          rules={requiredRule}
          render={({ field: { onChange, value } }) => (
            <Field
              type='text'
              required
              id='sender_first_name'
              label={t('First name')}
              error={errors.sender_first_name?.message}
              value={value}
              onChange={(id, val) => onChange(val)}
            />
          )}
        />
        <Controller
          name='sender_last_name'
          control={control}
          defaultValue=''
          rules={requiredRule}
          render={({ field: { onChange, value } }) => (
            <Field
              type='text'
              required
              id='sender_last_name'
              label={t('Last name')}
              error={errors.sender_last_name?.message}
              value={value}
              onChange={(id, val) => onChange(val)}
            />
          )}
        />
        <Controller
          name='sender_email'
          control={control}
          defaultValue=''
          rules={{ ...requiredRule, ...emailRule }}
          render={({ field: { onChange, value } }) => (
            <Field
              type='email'
              required
              id='sender_email'
              label={t('Email')}
              error={errors.sender_email?.message}
              value={value}
              onChange={(id, val) => onChange(val)}
            />
          )}
        />
        <Controller
          name='sender_phone'
          control={control}
          defaultValue=''
          rules={requiredRule}
          render={({ field: { onChange, value } }) => (
            <Field
              type='text'
              required
              id='sender_phone'
              label={t('Phone number')}
              error={errors.sender_phone?.message}
              value={value}
              onChange={(id, val) => onChange(val)}
            />
          )}
        />
        <Controller
          name='sender_address1'
          control={control}
          defaultValue=''
          rules={requiredRule}
          render={({ field: { onChange, value } }) => (
            <Field
              type='text'
              required
              id='sender_address1'
              label={t('Address line 1')}
              error={errors.sender_address1?.message}
              value={value}
              onChange={(id, val) => onChange(val)}
            />
          )}
        />
        <Controller
          name='sender_address2'
          control={control}
          defaultValue=''
          render={({ field: { onChange, value } }) => (
            <Field
              type='text'
              id='sender_address2'
              label={t('Address line 2')}
              error={errors.sender_address2?.message}
              value={value}
              onChange={(id, val) => onChange(val)}
            />
          )}
        />
        <Controller
          name='sender_country'
          control={control}
          defaultValue=''
          rules={requiredRule}
          render={({ field: { onChange, value } }) => (
            <Field
              type='autocomplete'
              required
              isLoading={isLoadingCountries}
              id='sender_country'
              label={t('Country')}
              error={errors.sender_country?.message}
              value={value}
              onChange={(id, val) => onChange(val)}
              inputOptions={{
                isClearable: false,
                options: formattedCountries
              }}
            />
          )}
        />
        <Controller
          name='sender_city'
          control={control}
          defaultValue=''
          rules={requiredRule}
          render={({ field: { onChange, value } }) => (
            <Field
              type='text'
              required
              id='sender_city'
              label={t('City')}
              error={errors.sender_city?.message}
              value={value}
              onChange={(id, val) => onChange(val)}
            />
          )}
        />
        <Controller
          name='sender_state'
          control={control}
          defaultValue=''
          render={({ field: { onChange, value } }) => (
            <Field
              type='text'
              id='sender_state'
              label={t('State')}
              error={errors.sender_state?.message}
              value={value}
              onChange={(id, val) => onChange(val)}
            />
          )}
        />
        <Controller
          name='sender_zip'
          control={control}
          rules={requiredRule}
          defaultValue=''
          render={({ field: { onChange, value } }) => (
            <Field
              type='text'
              required
              id='sender_zip'
              label={t('Zip code')}
              error={errors.sender_zip?.message}
              value={value}
              onChange={(id, val) => onChange(val)}
            />
          )}
        />
        <Button primary type='submit' className={styles.button}>
          <Trans>Pay with Flywire</Trans>
        </Button>
        <Button
          type='button'
          id='make-flywire-payment-button'
          className={styles.ghostButton}
        >
          Flywire modal activation button
        </Button>
      </form>
    </ContainerPanel>
  )
}

export default FlywirePayment

import React from 'react'

import MergeDataContext, {
  MERGE_STEPS
} from 'apps/MergeProfiles/contexts/MergeData'
import { useProfileSchema } from 'apps/MergeProfiles/contexts/ProfileSchema'
import getFieldsWithDifferentValues from 'apps/MergeProfiles/utils/getFieldsWithDifferentValues'

const INITIAL_STATE = {
  targetProfileId: null,
  targetProfile: null,
  sourceProfileId: null,
  sourceProfileOption: null,
  sourceProfile: null,
  confirmDuplicateProfileDeletion: false,
  profileInfoFrom: {},
  smartEmailInfoFrom: {},
  journeysFrom: {},
  applicsFrom: {},
  journeysFromApplics: {}
}

const MergeDataProvider = ({ children }) => {
  const [mergeData, setMergeData] = React.useState(INITIAL_STATE)
  const [mergeError, setMergeError] = React.useState({})
  const [currentMergeStep, setCurrentMergeStep] = React.useState(
    MERGE_STEPS.selectDuplicateProfile
  )

  const updateMergeData = React.useCallback(
    (data) => setMergeData((current) => ({ ...current, ...data })),
    [setMergeData]
  )

  const updateMergeError = React.useCallback(
    (error) => setMergeError((current) => ({ ...current, ...error })),
    [setMergeError]
  )

  const removeMergeError = React.useCallback(
    (field) => {
      const { [field]: errorToRemove, ...remainingErrors } = mergeError
      setMergeError(remainingErrors)
    },
    [mergeError, setMergeError]
  )

  const updateProfileInfoFrom = React.useCallback(
    (value) => {
      updateMergeData({
        profileInfoFrom: { ...mergeData.profileInfoFrom, ...value }
      })
    },
    [mergeData.profileInfoFrom, updateMergeData]
  )

  const {
    data: profileSchema,
    isLoading: schemaIsLoading,
    smartEmailFields
  } = useProfileSchema()

  React.useEffect(() => {
    updateMergeData({
      smartEmailInfoFrom: smartEmailFields.reduce((acc, emailField) => {
        return { ...acc, [emailField.name]: `main:${emailField.name}` }
      }, {})
    })
  }, [updateMergeData, smartEmailFields])

  const fieldsWithDifferentValues = React.useMemo(
    () =>
      !schemaIsLoading
        ? getFieldsWithDifferentValues(
            profileSchema,
            mergeData.sourceProfile,
            mergeData.targetProfile
          )
        : [],
    [
      mergeData.sourceProfile,
      mergeData.targetProfile,
      profileSchema,
      schemaIsLoading
    ]
  )

  return (
    <MergeDataContext.Provider
      value={{
        mergeData,
        setMergeData,
        updateMergeData,
        mergeError,
        setMergeError,
        updateMergeError,
        removeMergeError,
        currentMergeStep,
        setCurrentMergeStep,
        updateProfileInfoFrom,
        fieldsWithDifferentValues
      }}
    >
      {children}
    </MergeDataContext.Provider>
  )
}

export default MergeDataProvider

import React from 'react'
import { Trans } from 'react-i18next'

import { Alert } from '@fullfabric/alma-mater'

import { useAppData } from 'shared/contexts/AppData'

import FormTitle from 'apps/Authentication/components/FormTitle'
import SocialSection from 'apps/Authentication/components/SocialSection'

function AltAuthOptions() {
  const appData = useAppData()

  const [info, setInfo] = React.useState(() =>
    appData && appData.flash && appData.flash.trim() !== ''
      ? appData.flash.trim()
      : null
  )

  return (
    <>
      <FormTitle title={<Trans>Welcome</Trans>} />
      {info && (
        <Alert
          type='warning'
          onClose={() => setInfo(null)}
          description={info}
          marginBottom='default'
        />
      )}
      <SocialSection
        separatorText={<Trans>Use one of these methods to log in</Trans>}
      />
    </>
  )
}

export default AltAuthOptions

import React from 'react'
import { useQuery } from 'react-query'

import api from 'apps/ContentPages/api'
import { useLocale } from '../../../../shared/contexts/Locale'
import { usePage as usePageEmbeddedInAppData } from '../app-data'

const CurrentPageDataContext = React.createContext()

// a unreachable page is one which is not accessible through the navigation
const isUnreachablePage = (pagePath, navigationOptions) =>
  navigationOptions.every((navOption) => navOption.path !== pagePath)

const CurrentPageDataProvider = ({
  previewMode,
  currentPath,
  navigationOptions,
  children
}) => {
  const flatNavigationOptions = (navigationOptions || []).reduce(
    (flattened, group) => [...flattened, ...group],
    []
  )

  if (previewMode || isUnreachablePage(currentPath, flatNavigationOptions)) {
    // local page means we use data coming from app data
    return <LocalPageProvider>{children}</LocalPageProvider>
  }

  const { id: currentPageId } =
    flatNavigationOptions.find((navOption) => navOption.path === currentPath) ||
    {}

  return (
    <FetchedPageProvider pageId={currentPageId}>{children}</FetchedPageProvider>
  )
}

const FetchedPageProvider = ({ pageId, children }) => {
  const { locale } = useLocale()
  const { data: page, status } = useQuery(['getPage', pageId, locale], () =>
    api.getPage(pageId)
  )
  const isLoading = status === 'loading'

  return (
    <CurrentPageDataContext.Provider value={{ page, isLoading }}>
      {children}
    </CurrentPageDataContext.Provider>
  )
}

const LocalPageProvider = ({ children }) => {
  // page data comes from app data
  const page = usePageEmbeddedInAppData()

  return (
    <CurrentPageDataContext.Provider value={{ page, isLoading: false }}>
      {children}
    </CurrentPageDataContext.Provider>
  )
}

export { CurrentPageDataProvider, CurrentPageDataContext }

import 'css-chunk:src/apps/ContentPages/components/payments-page/layout/styles.module.scss';export default {
  "react-app": "react-app",
  "viewport": "_viewport_1pt47_1",
  "container": "_container_1pt47_6",
  "containerMobile": "_containerMobile_1pt47_12",
  "spaceBetweenTables": "_spaceBetweenTables_1pt47_17",
  "grid": "_grid_1pt47_25",
  "feeListContainer": "_feeListContainer_1pt47_31",
  "warningWidgetContainer": "_warningWidgetContainer_1pt47_36",
  "placeholder": "_placeholder_1pt47_43",
  "mobileGrid": "_mobileGrid_1pt47_47"
};
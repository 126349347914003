import React from 'react'
import { useTranslation } from 'react-i18next'

import moment from 'moment'

import { useLocale } from 'shared/contexts/Locale'

import PageLayout from 'apps/ContentPages/components/page-layout'
import ProductCatalogPageContent from 'apps/ContentPages/components/product-catalog-page-content'
import SidebarLayout from 'apps/ContentPages/components/sidebar-layout'

import styles from './styles.module.scss'

const ProductCatalogPage = () => {
  const { locale } = useLocale()
  const { t } = useTranslation()

  React.useEffect(() => {
    moment.locale(locale)
    document.title = t('Product catalog')
  }, [locale, t])

  return (
    <SidebarLayout>
      <PageLayout
        type='content'
        hideBackgroundImage
        contentClassName={styles.contentWrapper}
      >
        <ProductCatalogPageContent />
      </PageLayout>
    </SidebarLayout>
  )
}

export default ProductCatalogPage

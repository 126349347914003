import React from 'react'

import { XAxis } from 'recharts'

const renderCustomXAxis = ({ tickLine = {}, ...configuration } = {}) => (
  <XAxis
    axisLine={{ stroke: 'var(--ff-color-grey-200)' }}
    padding={{ left: 16, right: 16 }}
    tickSize={8}
    interval={0}
    tickLine={{
      ...tickLine,
      stroke: 'none',
      strokeWidth: 1
    }}
    {...configuration}
  />
)

export default renderCustomXAxis

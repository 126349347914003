import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { SuccessIcon, Text } from '@fullfabric/alma-mater'

import capitalizeString from 'apps/MergeProfiles/utils/capitalizeString'
import { ReactComponent as WarningIcon } from './warning.svg'

import classNames from 'classnames'
import styles from './styles.module.scss'

const getAcceptedMarketingChannels = (marketingAcceptance, t) => {
  const translationMap = {
    email: t('email'),
    sms: t('sms'),
    phone: t('phone'),
    mail: t('mail')
  }

  const acceptedAsString = Object.keys(marketingAcceptance.options)
    .filter(
      (channel) => marketingAcceptance.options[channel].toString() === 'true'
    )
    .map((channel) => translationMap[channel])
    .filter((c) => c)
    .join(', ')

  return acceptedAsString
    ? t('Marketing consent - {{consent}}', {
        consent: capitalizeString(acceptedAsString)
      })
    : t('Marketing consent - No contact allowed')
}

const MarketingAcceptanceInfo = ({ profile }) => {
  const { t } = useTranslation()

  return (
    <div className={classNames(styles.infoRow, styles.withIcon)}>
      {profile.marketing_acceptance ? (
        <>
          <SuccessIcon color='text-success' className={styles.icon} />{' '}
          <Text fontColor='text-success'>
            {getAcceptedMarketingChannels(profile.marketing_acceptance, t)}
          </Text>
        </>
      ) : (
        <>
          <WarningIcon className={styles.icon} />{' '}
          <Text fontColor='yellow-800'>
            <Trans>Marketing consent - No response</Trans>
          </Text>
        </>
      )}
    </div>
  )
}

export default MarketingAcceptanceInfo

import React from 'react'
import { Trans } from 'react-i18next'

import { AnnotatedLayout } from '@fullfabric/alma-mater'

import { useSettingsSectionsBeingEdited } from 'apps/StudyPlans/contexts/SettingsSectionsBeingEdited'
import DegreeClassDisplay from './Display'
import DegreeClassForm from './Form'

const DegreeClassSection = () => {
  const sectionsBeingEdited = useSettingsSectionsBeingEdited()

  return (
    <AnnotatedLayout.Section
      title={<Trans>Degree class</Trans>}
      description={
        <Trans>
          Define the degree classes that are awarded to students based on their
          transcript grade. The degree class is displayed on student transcripts
          and is automatically set based on the transcript grade average or the
          transcript grade percentage.
        </Trans>
      }
    >
      {sectionsBeingEdited.degreeClasses ? (
        <DegreeClassForm />
      ) : (
        <DegreeClassDisplay />
      )}
    </AnnotatedLayout.Section>
  )
}

export default DegreeClassSection

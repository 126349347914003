import React from 'react'
import { Trans } from 'react-i18next'

import { Col, DeleteIcon, Field, IconButton, Row } from '@fullfabric/alma-mater'

import styles from './styles.module.scss'

const ERROR_MAP = {
  blank: <Trans>Can't be blank</Trans>,
  exists: <Trans>Name already exists</Trans>,
  related_to_transcripts: (
    <Trans>
      This path cannot be deleted because there are student transcripts for the
      path.
    </Trans>
  )
}

const SinglePathForm = ({ path, onChange, onDelete, hasMoreThanOnePath }) => {
  const onNameChange = React.useCallback(
    (_, value) => onChange({ ...path, name: value }),
    [path, onChange]
  )

  const onDeleteClick = React.useCallback(() => {
    const pathToDelete = !path.related_to_transcripts
      ? path
      : { ...path, error: 'related_to_transcripts' }

    onDelete(pathToDelete, path.related_to_transcripts)
  }, [path, onDelete])

  return (
    <Row key={path.id}>
      <Col xs={11}>
        <Field
          id={`name_${path.id}`}
          type='text'
          value={path.name}
          label={<Trans>Path name</Trans>}
          error={ERROR_MAP[path.error]}
          onChange={onNameChange}
        />
      </Col>
      <Col xs={1} className={styles.deleteIcon}>
        <IconButton
          disabled={hasMoreThanOnePath}
          plain
          data-testid='delete-path'
          onClick={onDeleteClick}
        >
          <DeleteIcon />
        </IconButton>
      </Col>
    </Row>
  )
}

export default SinglePathForm

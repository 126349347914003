import React from 'react'
import { Trans } from 'react-i18next'

import {
  ArrowRightIcon,
  AutocompleteInput,
  BoxShadow,
  Table,
  Text
} from '@fullfabric/alma-mater'

import { useSelectedData } from 'apps/Imports/contexts/selected-data'
import useMappingFieldOptions from '../hooks/use-mapping-field-options'
import useMappingSamples from '../hooks/use-mapping-samples'
import useOnMappingChange from '../hooks/use-on-mapping-change'
import useRequiredMappingFields from '../hooks/use-required-mapping-fields'

import styles from './styles.module.scss'

const RequiredFieldsSection = () => {
  const { data } = useSelectedData()

  const { requiredFieldsToImport, requiredFieldsToDisplay } =
    useRequiredMappingFields()
  const { csvHeaderOptions, fieldNamesMap } = useMappingFieldOptions()
  const onChange = useOnMappingChange()
  const samples = useMappingSamples()

  return (
    <BoxShadow type='3'>
      <header className={styles.requiredFieldsHeader}>
        <Text type='h4' fontColor='text-base-darkest'>
          <Trans>Fields required for import actions</Trans>
        </Text>
        <Text>
          <Trans>
            These fields are required for processing different actions involved
            with the import.
          </Trans>
        </Text>
      </header>

      <Table.Container className={styles.requiredFieldsListContainer}>
        <Table className={styles.requiredFieldsList}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderItem disabled>
                <Trans>Column on .csv file</Trans>
              </Table.HeaderItem>

              <Table.HeaderItem disabled>
                <Trans>Full fabric field</Trans>
              </Table.HeaderItem>

              <Table.HeaderItem disabled className={styles.samplesColumnHeader}>
                <Trans>Sample from .csv file</Trans>
              </Table.HeaderItem>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {requiredFieldsToDisplay.map((field, index) => {
              const mapping = data.mappings.find((m) => m.field === field)

              return (
                <Table.Row key={`required-field-${index}`}>
                  <Table.Data className={styles.requiredFieldColData}>
                    <AutocompleteInput
                      id={field}
                      className={styles.requiredFieldDropdown}
                      onChange={(value) => onChange(field, value)}
                      value={mapping?.header}
                      options={csvHeaderOptions}
                    />
                    <ArrowRightIcon
                      width={20}
                      height={20}
                      color='grey-300'
                      className={styles.arrow}
                    />
                  </Table.Data>

                  <Table.Data className={styles.textCol}>
                    <Text fontColor='text-base-darkest' type='f4'>
                      {fieldNamesMap[field]}
                      {requiredFieldsToImport.includes(field) ? (
                        <Text
                          tag='span'
                          marginLeft='tiny'
                          fontColor='text-alert'
                        >
                          *
                        </Text>
                      ) : null}
                    </Text>
                  </Table.Data>

                  <Table.Data className={styles.textCol}>
                    <Text className={styles.samplesColumnText}>
                      {samples[mapping?.header]}
                    </Text>
                  </Table.Data>
                </Table.Row>
              )
            })}
          </Table.Body>
        </Table>
      </Table.Container>
    </BoxShadow>
  )
}

export default RequiredFieldsSection

import React from 'react'
import { Trans } from 'react-i18next'

import { Field } from '@fullfabric/alma-mater'

import ImportActions from 'apps/Imports/components/profile-import/import-actions'
import { useSelectedData } from 'apps/Imports/contexts/selected-data'

const ImportSettingsStep = () => {
  const {
    data: { updateExistingProfiles },
    onDataChanged
  } = useSelectedData()

  const onChange = React.useCallback(
    (field, value) => {
      onDataChanged(field, value === 'true')
    },
    [onDataChanged]
  )

  return (
    <>
      <Field
        id='updateExistingProfiles'
        type='radio'
        label={<Trans>Do you want to update existing profiles?</Trans>}
        help={
          <Trans>
            The file being imported could contain information of existing
            profiles in the system
          </Trans>
        }
        value={
          updateExistingProfiles != null
            ? updateExistingProfiles.toString()
            : null
        }
        onChange={onChange}
        inputOptions={[
          {
            value: 'false',
            label: (
              <Trans>
                No, only add new profiles, do not update existing profiles
              </Trans>
            )
          },
          {
            value: 'true',
            label: (
              <Trans>Yes, add new profiles and update existing profiles</Trans>
            )
          }
        ]}
      />

      <ImportActions />
    </>
  )
}

export default ImportSettingsStep

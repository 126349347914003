import React from 'react'
import { Trans } from 'react-i18next'

import PageContainer from 'apps/Imports/components/page-container'
import ProfileDetailsStep from 'apps/Imports/components/profile-import/profile-details-step'
import { useCurrentStep } from 'apps/Imports/contexts/steps'
import { PROFILE_IMPORT_STEPS } from 'apps/Imports/utils/profile-import/constants'

const ProfileDetailsPage = () => {
  useCurrentStep(PROFILE_IMPORT_STEPS.PROFILE_DETAILS)

  return (
    <PageContainer
      title={<Trans>Profile Details</Trans>}
      sidebarTitle={<Trans>Import Profiles</Trans>}
    >
      <ProfileDetailsStep />
    </PageContainer>
  )
}

export default ProfileDetailsPage

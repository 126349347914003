import { connect } from 'react-redux'

import deleteCourseAction from 'apps/Courses/actions/deleteCourse'
import actionTypes from 'apps/Courses/actions/types'
import DeleteCourseModal from 'apps/Courses/components/DeleteCourseModal'

const mapStateToProps = ({ deleteCourse: { modalOpen, inProgress } }) => ({
  isOpen: modalOpen,
  inProgress
})

const mapDispatchToProps = (dispatch) => ({
  onClose: () =>
    dispatch({
      type: actionTypes.openDeleteCourseModal,
      payload: { course: {}, open: false }
    }),
  onDelete: () => dispatch(deleteCourseAction())
})

export default connect(mapStateToProps, mapDispatchToProps)(DeleteCourseModal)

import React from 'react'
import { useTranslation } from 'react-i18next'

import {
  MERGE_STEPS,
  useMergeData
} from 'apps/MergeProfiles/contexts/MergeData'
import Step from '../Step'

const ApplicationsStep = () => {
  const { t } = useTranslation()
  const { currentMergeStep } = useMergeData()

  return (
    <Step
      name={t('Applications')}
      active={currentMergeStep === MERGE_STEPS.applications}
      completed={currentMergeStep > MERGE_STEPS.applications}
    />
  )
}

export default ApplicationsStep

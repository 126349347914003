import React from 'react'

import { useLocale } from 'shared/contexts/Locale'

import EmailValidation from './email-validation'
import LocaleButton from './locale-button'
import LoginLink from './login-link'

import styles from './styles.module.scss'

export default function Footer() {
  const { alternativeLocale } = useLocale()
  const hasLocale = !!alternativeLocale

  return (
    <div className={styles.container}>
      <LoginLink last={!hasLocale} />
      {hasLocale && <LocaleButton />}
      <EmailValidation />
    </div>
  )
}

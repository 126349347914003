.react-app ._link_1v66a_1 {
  background: none;
  color: inherit;
  border: none !important;
  margin: 0 !important;
  margin-bottom: 0 !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  padding: 0;
  font: inherit;
  outline: inherit;
  text-decoration: none;
  color: var(--ff-color-text-base-darkest) !important;
  display: block;
  width: 100%;
  cursor: pointer;
}
.react-app ._link_1v66a_1 svg path,
.react-app ._link_1v66a_1 svg rect {
  fill: var(--ff-color-text-base-darkest) !important;
}
.react-app ._link_1v66a_1:hover, .react-app ._link_1v66a_1:focus {
  background-color: var(--ff-bg-base-lighter);
  border-bottom: none !important;
}

.react-app ._selectedLink_1v66a_27, .react-app ._selectedLink_1v66a_27 * {
  color: var(--ff-color-text-primary) !important;
}
.react-app ._selectedLink_1v66a_27 svg path,
.react-app ._selectedLink_1v66a_27 svg rect {
  fill: var(--ff-color-text-primary) !important;
}
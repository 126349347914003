import moment from 'moment'

export default function isEventOpen(event) {
  return (
    areEventRegistrationsAlreadyOpen(event) &&
    !areEventRegistrationsClosed(event) &&
    eventHasSpace(event)
  )
}

export const areEventRegistrationsClosed = (event) => {
  if (!event.end_registrations) {
    return false
  }

  const endRegistrationsMoment = moment(event.end_registrations)
  const nowMoment = moment()

  return nowMoment.isSameOrAfter(endRegistrationsMoment)
}

export const areEventRegistrationsAlreadyOpen = (event) => {
  const nowMoment = moment()
  const startRegistrationsMoment = moment(event.start_registrations)

  return nowMoment.isSameOrAfter(startRegistrationsMoment)
}

export const hasEventStarted = (event) => {
  const startMoment = moment(event.start)
  const nowMoment = moment()

  return nowMoment.isSameOrAfter(startMoment)
}

export const eventHasSpace = (event) => {
  return event.has_space
}

import React from 'react'
import { Route } from 'react-router'

import { ProductImportStepsProvider } from 'apps/Imports/components/product-import/steps-provider'
import { ImportDocumentProvider } from 'apps/Imports/contexts/import-document'
import { SelectedDataProvider } from 'apps/Imports/contexts/selected-data'
import FileUploadPage from 'apps/Imports/pages/ProductImport/FileUploadPage'
import ImportSummaryPage from 'apps/Imports/pages/ProductImport/ImportSummaryPage'
import MappingPage from 'apps/Imports/pages/ProductImport/MappingPage'
import { IMPORT_TYPES } from 'apps/Imports/utils/constants'
import { INITIAL_PRODUCT_IMPORT_STATE } from 'apps/Imports/utils/product-import/constants'

const SubjectsAndCourseImportRoutes = () => {
  return (
    <ProductImportStepsProvider importType={IMPORT_TYPES.SUBJECT_AND_COURSE}>
      <SelectedDataProvider initialState={INITIAL_PRODUCT_IMPORT_STATE}>
        <ImportDocumentProvider>
          <Route
            exact
            path='/institutions/settings/courses/import/summary'
            component={ImportSummaryPage}
          />

          <Route
            exact
            path='/institutions/settings/courses/import/mapping'
            component={MappingPage}
          />

          <Route
            exact
            path='/institutions/settings/courses/import'
            component={FileUploadPage}
          />
        </ImportDocumentProvider>
      </SelectedDataProvider>
    </ProductImportStepsProvider>
  )
}

export default SubjectsAndCourseImportRoutes

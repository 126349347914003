.react-app ._manageLink_1uw81_1 {
  display: inline-flex;
  line-height: 22px;
  margin-top: 16px;
}

.react-app ._buttonContainer_1uw81_7 {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: var(--space-small);
}
import React from 'react'
import { Trans } from 'react-i18next'

import PropTypes from 'prop-types'

import AuthorizationWrapper from './authorization-wrapper'
import BaseApplicationsWidget from './base'

export default function RegistrationTemplatesWidget({ widget }) {
  return (
    <AuthorizationWrapper
      forRegistrations
      title={<Trans>Registrations</Trans>}
      id={widget.id}
    >
      <BaseApplicationsWidget
        forRegistrations
        widgetId={widget.id}
        templateIds={widget.template_ids}
        emptyMessage={<Trans>No registrations available</Trans>}
      />
    </AuthorizationWrapper>
  )
}

RegistrationTemplatesWidget.propTypes = {
  widget: PropTypes.shape({
    id: PropTypes.string.isRequired,
    template_ids: PropTypes.arrayOf(PropTypes.string).isRequired
  }).isRequired
}

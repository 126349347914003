import { debounce } from 'lodash'

export const onDesignSaveDebounced = debounce(async (editor, callback) => {
  const designAndHtml = await new Promise((resolve) =>
    editor.exportHtml((data) =>
      resolve({
        design: data.design,
        html: data.html
      })
    )
  )
  const textObj = await new Promise((resolve) =>
    editor.exportPlainText((data) =>
      resolve({
        text: data.text
      })
    )
  )
  callback(Object.assign(designAndHtml, textObj))
}, 1500)

import { utils } from '@fullfabric/public-api'

const deleteDegreeClasses = async (studyPlanId, degreeClassIds) => {
  const response = await fetch(
    `/affairs/api/study_plan/${studyPlanId}/degree_classes`,
    {
      credentials: 'same-origin',
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ ids: degreeClassIds })
    }
  )

  return await utils.checkResponse(response)
}

export default deleteDegreeClasses

import React, { useRef } from 'react'
import { Route, Switch } from 'react-router'

import PropTypes from 'prop-types'

import {
  useCurrentUser,
  UserRoleUtils
} from '@fullfabric/authorization-officer'

import CookiePrompt from 'shared/components/cookie-prompt'
import { useAppData } from 'shared/contexts/AppData'
import { CookiePromptRefProvider } from 'shared/contexts/CookiePromptRef'
import { useLocale } from 'shared/contexts/Locale'

import { COOKIE_POLICY_ROUTES } from 'apps/Policies/components/AppRoutes'

export default function CookiePromptWrapper({ children, onCookiesAccepted }) {
  const user = useCurrentUser()
  const appData = useAppData()
  const { locale } = useLocale()

  const cookiePromptRef = useRef()

  return (
    <CookiePromptRefProvider value={cookiePromptRef}>
      {children}

      <Switch>
        <Route path={COOKIE_POLICY_ROUTES} />
        <Route>
          <CookiePrompt
            ref={cookiePromptRef}
            cookiePolicy={appData?.cookies_policy}
            locale={locale}
            user={user}
            userIsStaff={user && UserRoleUtils.isStaff(user)}
            onAccepted={onCookiesAccepted}
          />
        </Route>
      </Switch>
    </CookiePromptRefProvider>
  )
}

CookiePromptWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  onCookiesAccepted: PropTypes.func
}

import React from 'react'
import { Route } from 'react-router'

import { ProfileImportStepsProvider } from 'apps/Imports/components/profile-import/steps-provider'
import { ImportDocumentProvider } from 'apps/Imports/contexts/import-document'
import { SelectedDataProvider } from 'apps/Imports/contexts/selected-data'
import AddToContextPage from 'apps/Imports/pages/ProfileImport/add-to-context-page'
import ConsentPage from 'apps/Imports/pages/ProfileImport/consent-page'
import FileUploadPage from 'apps/Imports/pages/ProfileImport/file-upload-page'
import ImportSettingsPage from 'apps/Imports/pages/ProfileImport/import-settings-page'
import ImportSummaryPage from 'apps/Imports/pages/ProfileImport/import-summary-page'
import MappingPage from 'apps/Imports/pages/ProfileImport/mapping-page'
import ProfileDetailsPage from 'apps/Imports/pages/ProfileImport/profile-details-page'

const EventImportRoutes = () => {
  return (
    <ProfileImportStepsProvider>
      <SelectedDataProvider>
        <ImportDocumentProvider>
          <Route
            exact
            path='/events/:eventId/import/summary'
            component={ImportSummaryPage}
          />
          <Route
            exact
            path='/events/:eventId/import/mapping'
            component={MappingPage}
          />
          <Route
            exact
            path='/events/:eventId/import/profile-details'
            component={ProfileDetailsPage}
          />
          <Route
            exact
            path='/events/:eventId/import/add-to'
            component={AddToContextPage}
          />
          <Route
            exact
            path='/events/:eventId/import/consent'
            component={ConsentPage}
          />
          <Route
            exact
            path='/events/:eventId/import/settings'
            component={ImportSettingsPage}
          />
          <Route
            exact
            path='/events/:eventId/import'
            component={FileUploadPage}
          />
        </ImportDocumentProvider>
      </SelectedDataProvider>
    </ProfileImportStepsProvider>
  )
}

export default EventImportRoutes

.react-app ._title_129ln_1 {
  height: 24px;
  margin-bottom: var(--space-tiny);
}

.react-app ._subtitle_129ln_6 {
  height: 20px;
  margin-bottom: var(--space-small);
}

.react-app ._date_129ln_11 {
  height: 18px;
  margin-bottom: var(--space-default);
}

.react-app ._button_129ln_16 {
  height: 38px;
}
const formatStudyPlanCourseData = (data) =>
  Object.keys(data).reduce((acc, key) => {
    const pathId = key.replace('course_type_', '')
    const courseTypesForOtherPaths = (acc.course_types || []).filter(
      (courseType) => courseType.path_id !== pathId
    )

    return key.startsWith('course_type_') && data[key]
      ? {
          ...acc,
          course_types: [
            ...courseTypesForOtherPaths,
            { group_id: data[key], path_id: pathId }
          ]
        }
      : { ...acc, [key]: data[key] }
  }, {})

export default formatStudyPlanCourseData

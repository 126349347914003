import React from 'react'
import { useController, useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'

import PropTypes from 'prop-types'

import { Button, Field, useScreenClass, Visible } from '@fullfabric/alma-mater'

import { useAppData } from 'shared/contexts/AppData'

import createAccount from 'apps/Authentication/api/createAccount'
import FormErrorsAlert from 'apps/Authentication/components/FormErrorsAlert'
import FormTitle from 'apps/Authentication/components/FormTitle'
import PrivacyPolicyField from 'apps/Authentication/components/PrivacyPolicyField'
import SocialSection from 'apps/Authentication/components/SocialSection'
import locationHelpers from 'apps/Authentication/utils/locationHelpers'

import classNames from 'classnames'
import styles from './styles.module.scss'

const getError = (field, localErrors, serverErrors) =>
  (serverErrors && serverErrors[field]) || localErrors[field]?.message

const SignupForm = ({ title, subtitle }) => {
  const { t } = useTranslation()
  const {
    _settings: settings,
    privacy_policy: privacyPolicy,
    password_policy: passwordPolicy
  } = useAppData()

  const { register, handleSubmit, formState, control } = useForm()
  const { errors: localErrors, isValid, isSubmitted } = formState
  const { ref: firstNameInputRef, ...firstNameInputProps } = register(
    'first_name',
    { required: t('This field is required') }
  )
  const { ref: lastNameInputRef, ...lastNameInputProps } = register(
    'last_name',
    { required: t('This field is required') }
  )
  const { ref: emailInputRef, ...emailInputProps } = register('email', {
    required: t('This field is required')
  })
  const { ref: passwordInputRef, ...passwordInputProps } = register(
    'password',
    { required: t('This field is required') }
  )
  const {
    field: { ref: privacyPolicyRef, ...privacyPolicyProps }
  } = useController({
    name: 'privacyPolicy',
    control,
    rules: {
      required: privacyPolicy && t('This field is required')
    }
  })

  const [inProgress, setInProgress] = React.useState(false)
  const failedValidation = inProgress ? false : isSubmitted && !isValid
  const [errorAlertMessage, setErrorAlertMessage] = React.useState(null)
  const [serverErrors, setServerErrors] = React.useState(null)

  const screenClass = useScreenClass()
  const isInMobileLayout = ['xs', 'sm'].includes(screenClass)
  const isInNarrowLayout = ['xs', 'sm', 'md'].includes(screenClass)

  const onSubmit = async (formValues) => {
    setErrorAlertMessage({})
    setInProgress(true)

    const { privacyPolicy: privacyPolicyValue, ...signupData } = formValues
    const hasAcceptedPrivacyPolicy =
      privacyPolicy &&
      privacyPolicyValue &&
      privacyPolicyValue[0] === privacyPolicy.id

    try {
      const resp = await createAccount(
        signupData,
        privacyPolicy,
        hasAcceptedPrivacyPolicy
      )

      locationHelpers.goTo(resp.redirect_url || '/')
    } catch (error) {
      setInProgress(false)
      const policyError =
        error.data?.policies &&
        error.data.policies[privacyPolicy?.id] &&
        error.data.policies[privacyPolicy?.id][0]

      setErrorAlertMessage({
        title: error.data?.account?.title,
        description: error.data?.account?.base
      })
      setServerErrors({
        ...(error.data ? error.data['profiles/profile'] : {}),
        policyError
      })
    }
  }

  const isIdentityEnabled = settings?.hash?.auth?.identity?.enable

  if (isIdentityEnabled) {
    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormTitle
          title={title || <Trans>Sign up</Trans>}
          subtitle={subtitle}
        />

        <FormErrorsAlert
          failedValidation={failedValidation}
          {...errorAlertMessage}
        />

        <div // easier to do manually than use row+col with custom gutters
          className={classNames(
            styles.nameFieldsRow,
            isInNarrowLayout && styles.mobile
          )}
        >
          <div className={styles.field}>
            <Field
              type='text'
              label={<Trans>First name</Trans>}
              inputOptions={{ autoFocus: true }}
              {...firstNameInputProps}
              inputRef={firstNameInputRef}
              error={getError('first_name', localErrors, serverErrors)}
            />
          </div>

          <Visible lg xl xxl>
            <div className={styles.spacingBetweenFields} />
          </Visible>

          <div className={styles.field}>
            <Field
              type='text'
              label={<Trans>Last name</Trans>}
              {...lastNameInputProps}
              inputRef={lastNameInputRef}
              error={getError('last_name', localErrors, serverErrors)}
            />
          </div>
        </div>

        <Field
          type='email'
          label={<Trans>E-mail address</Trans>}
          {...emailInputProps}
          inputRef={emailInputRef}
          error={getError('email', localErrors, serverErrors)}
        />

        <Field
          type='passwordStrengthChecked'
          id='password'
          inputOptions={{
            tooltipPreferredPosition: isInMobileLayout ? 'above' : 'right',
            passwordPolicy: {
              minDigits: passwordPolicy.minimum_digits_length,
              minAlpha: passwordPolicy.minimum_alphas_length,
              minLength: passwordPolicy.minimum_length,
              minSymbols: passwordPolicy.minimum_symbols_length
            }
          }}
          label={<Trans>Password</Trans>}
          hideErrorLabel
          {...passwordInputProps}
          inputRef={passwordInputRef}
          error={getError('password', localErrors, serverErrors)}
        />

        <PrivacyPolicyField
          {...privacyPolicyProps}
          inputRef={privacyPolicyRef}
          error={
            serverErrors?.policyError || localErrors.privacyPolicy?.message
          }
        />

        <Button primary type='submit' loading={inProgress}>
          <Trans>Sign up</Trans>
        </Button>

        <SocialSection
          separatorText={<Trans>Or use these alternatives</Trans>}
        />
      </form>
    )
  } else {
    return (
      <>
        <FormTitle
          title={title || <Trans>Sign up</Trans>}
          subtitle={subtitle}
        />

        <SocialSection
          separatorText={<Trans>Use one of these alternatives to signup</Trans>}
        />
      </>
    )
  }
}

SignupForm.propTypes = {
  title: PropTypes.node,
  subtitle: PropTypes.node
}

export default SignupForm

import { useEffect } from 'react'

export function generateNewTitle(oldTitle, newTitle) {
  const SEPARATOR = ' :: '
  const [prefix, ...suffix] = oldTitle.split(SEPARATOR)

  if (!suffix.length) {
    return newTitle
  }

  if (newTitle !== prefix) {
    return [newTitle, SEPARATOR, suffix.join(SEPARATOR)].join('')
  } else {
    return oldTitle
  }
}

function useChangeDocumentTitle(title) {
  useEffect(() => {
    const newTitle = generateNewTitle(document.title, title)

    if (newTitle !== document.title) {
      document.title = newTitle
    }

    if (window.top && window.top.document.title !== newTitle) {
      window.top.document.title = newTitle
    }
  }, [title])
}

export default useChangeDocumentTitle

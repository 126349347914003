import React from 'react'
import { useTranslation } from 'react-i18next'

import {
  Button,
  CheckboxInput,
  Modal,
  ModalButtons,
  ModalTitle,
  Text
} from '@fullfabric/alma-mater'
import { useCurrentUser } from '@fullfabric/authorization-officer'

import keyboardInteractionImage from './images/keyboardInteraction.png'
import mouseInteractionImage from './images/mouseInteraction.png'
import { setUserDecision, shouldShowModalToUser } from './utils'

import styles from './styles.module.scss'

const UsefulInfoModal = () => {
  const user = useCurrentUser()
  const [showModal, setShowModal] = React.useState(
    shouldShowModalToUser(user?.id)
  )
  const [dontWantToSeeAgain, setDontWantToSeeAgain] = React.useState(false)

  const { t } = useTranslation()

  return (
    <Modal
      role='dialog'
      aria-labelledby='evaluation-useful-info-modal'
      isOpen={showModal}
      size='medium'
      header={
        <ModalTitle
          title={
            <span id='evaluation-useful-info-modal'>
              {t('New evaluation design')}
            </span>
          }
          onClose={() => setShowModal(false)}
        />
      }
      footer={
        <ModalButtons>
          <Button
            primary
            onClick={() => {
              setShowModal(false)
              setUserDecision(user?.id, dontWantToSeeAgain)
            }}
          >
            {t('Start evaluation')}
          </Button>
        </ModalButtons>
      }
    >
      <>
        <Text type='f5' marginBottom='default'>
          {t(
            'The new evaluation design allows you to see the evaluation form and the application side-by-side.'
          )}
        </Text>
        <Text type='h5' fontColor='text-base-darkest' marginBottom='default'>
          {t('Instructions to hide/show the application:')}
        </Text>
        <div className={styles.imageRow}>
          <div className={styles.imageCard}>
            <div
              className={styles.imageContainer}
              style={{ backgroundImage: `url(${mouseInteractionImage})` }}
            />
            <div className={styles.textContainer}>
              <Text type='h5' fontColor='text-base-darkest'>
                {t('Using the mouse')}
              </Text>
              <Text type='f5'>{t('Menu on the top right corner.')}</Text>
            </div>
          </div>
          <div className={styles.imageCard}>
            <div
              className={styles.imageContainer}
              style={{ backgroundImage: `url(${keyboardInteractionImage})` }}
            />
            <div className={styles.textContainer}>
              <Text type='h5' fontColor='text-base-darkest'>
                {t('Using the keyboard')}
              </Text>
              <Text type='f5'>{t('Shortcut ⇧ Shift + A')}</Text>
            </div>
          </div>
        </div>
        <CheckboxInput
          id={'dontWantToSeeAgain'}
          className={styles.checkbox}
          label={t("Don't show this message again")}
          checked={dontWantToSeeAgain}
          onChange={() => {
            setDontWantToSeeAgain((val) => !val)
          }}
        />
      </>
    </Modal>
  )
}

export default UsefulInfoModal

import React from 'react'
import { Trans } from 'react-i18next'
import { useMutation } from 'react-query'
import { useHistory, useLocation } from 'react-router'

import qs from 'qs'

import { Col, RadioInput, Text } from '@fullfabric/alma-mater'
import * as api from '@fullfabric/frontend-api'

import { useAppData } from 'shared/contexts/AppData'
import getLocalizedText from 'shared/utils/getLocalizedText'

import PolicyAcceptanceDescription from 'apps/Policies/components/PolicyAcceptanceDescription'
import PolicyChoice from 'apps/Policies/components/PolicyChoice'
import { useSelectedLocale } from 'apps/Policies/contexts/SelectedLocale'
import { useUpdatePrivacyPolicyAcceptance } from 'apps/Policies/contexts/UserPoliciesData'

import styles from './styles.module.scss'

const EditPrivacyPolicyPage = () => {
  const { profile, privacy_policy: privacyPolicy } = useAppData()
  const selectedLocale = useSelectedLocale()

  const { search } = useLocation()
  const history = useHistory()

  const { communication_policy_id: communicationPolicyId } = qs.parse(
    (search || '').substr(1)
  )

  const [agreement, setAgreement] = React.useState(null)
  const updatePrivacyPolicyAcceptance = useUpdatePrivacyPolicyAcceptance()

  const { mutate: onSubmit, isLoading } = useMutation(
    async () => {
      const params = qs.parse((search || '').substr(1))

      const data = {
        acceptor_type: 'Profiles::Profile',
        acceptor_id: profile.id,
        source_type: 'Policies::Source::Campaign',
        policies: {
          [privacyPolicy.id]: {
            accepted: 'true',
            options: {
              agreement,
              ...params
            }
          }
        }
      }

      return await api.createPolicyAcceptance(data)
    },
    {
      onSuccess: (result) => {
        const privacyPolicyAcceptance =
          agreement === 'accepted' ? result[0] : null

        updatePrivacyPolicyAcceptance(privacyPolicyAcceptance)

        const communicationPolicySlug = communicationPolicyId
          ? `${communicationPolicyId}/edit`
          : 'edit'

        const marketingEditUrl = `/policies/user/${profile.id}/marketing/${communicationPolicySlug}`

        const redirectTo =
          agreement === 'refused'
            ? `/policies/user/${profile.id}/privacy/refused`
            : marketingEditUrl

        history.push(redirectTo)
      }
    }
  )

  const policyHtml = getLocalizedText(
    (privacyPolicy && privacyPolicy.locales) || [],
    selectedLocale,
    'html'
  )

  return (
    <PolicyChoice
      title={<Trans>Privacy Policy</Trans>}
      lastAcceptanceText={<PolicyAcceptanceDescription policyType='privacy' />}
      complementaryText={<Trans>Next: Update marketing preferences</Trans>}
      policyHtml={policyHtml}
      canSubmit={!!agreement}
      onSubmit={onSubmit}
      inProgress={isLoading}
    >
      <Col xs={12}>
        <RadioInput
          id='acceptBtn'
          checked={agreement === 'accepted'}
          name='acceptPrivacyPolicy'
          hasError={false}
          label={<Trans>I Accept the Privacy Policy</Trans>}
          onChange={() => setAgreement('accepted')}
        />
      </Col>

      <Col xs={12} className={styles.refusalButton}>
        <RadioInput
          id='refuseBtn'
          checked={agreement === 'refused'}
          name='acceptPrivacyPolicy'
          hasError={false}
          label={<Trans>I DO NOT accept the Privacy Policy</Trans>}
          onChange={() => setAgreement('refused')}
        />

        {agreement === 'refused' ? (
          <Text small marginLeft='more'>
            <Trans>
              If you do not accept the privacy policy, you must contact the data
              processor to delete your data.
            </Trans>
          </Text>
        ) : null}
      </Col>
    </PolicyChoice>
  )
}

export default EditPrivacyPolicyPage

import React from 'react'

import { Text } from '@fullfabric/alma-mater'

import styles from './styles.module.scss'

const Header = ({ title, description, actions }) => {
  return (
    <header className={styles.header}>
      <div>
        <Text tag='h1' type='h3' fontColor='text-base-darkest'>
          {title}
        </Text>
        <Text>{description}</Text>
      </div>

      <div className={styles.actionsRegion}>{actions}</div>
    </header>
  )
}

export default Header

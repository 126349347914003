import React from 'react'
import { useQueryClient } from 'react-query'

import { useCardFilters } from './CardFilters'

export const CardNotificationContext = React.createContext()

export function CardNotificationProvider({ children }) {
  const [newCardItems, setNewCardItems] = React.useState([])

  return (
    <CardNotificationContext.Provider
      value={{
        newCardItems,
        setNewCardItems
      }}
    >
      {children}
    </CardNotificationContext.Provider>
  )
}

export function useNewCardItems() {
  return React.useContext(CardNotificationContext)?.newCardItems
}

export function useSetNewCardItems() {
  return React.useContext(CardNotificationContext)?.setNewCardItems
}

export function useAddNewCardItem() {
  const { setNewCardItems, newCardItems } = React.useContext(
    CardNotificationContext
  )

  return (card) => setNewCardItems([...newCardItems, card])
}

export function useDisplayNewItems() {
  const newCardItems = useNewCardItems()
  const setNewCardItems = useSetNewCardItems()
  const queryClient = useQueryClient()
  const { cardStatus, cardType } = useCardFilters()
  const displayItems = () => {
    const previousCards =
      queryClient.getQueryData(['cards', cardStatus, cardType]) || []

    const newCards = previousCards?.pages.map((page) => {
      return { ...page, data: [...newCardItems, ...page.data] }
    })

    queryClient.setQueriesData(['cards', cardStatus, cardType], (data) => ({
      pages: newCards,
      pageParams: data.pageParams
    }))

    setNewCardItems([])
  }
  return displayItems
}

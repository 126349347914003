import React, { useCallback, useState } from 'react'
import { Trans } from 'react-i18next'

import { Button, ModalButtons, PlainButton } from '@fullfabric/alma-mater'

import { useAppData } from 'shared/contexts/AppData'

import styles from './styles.module.scss'

const AuthenticationLiteModalForm = ({
  onSubmit,
  step,
  setStep,
  showModalActions,
  authenticatedProfileId,
  children: body
}) => {
  const { template_type: templateType } = useAppData()

  const [loading, setLoading] = useState(false)
  const [feedback, setFeedback] = useState(null)

  const backAction = (e) => {
    e.preventDefault()
    setStep('email')
  }

  const decoratedOnSubmit = useCallback(
    (e) => {
      e.preventDefault()

      setLoading(true)
      onSubmit({
        onSuccess: () => {
          setLoading(false)
          setFeedback('success')
        },
        onError: () => {
          setLoading(false)
          setFeedback(null)
        }
      })
    },
    [onSubmit]
  )

  return (
    <form>
      {body}

      {showModalActions && (
        <ModalButtons className={styles.modalButtonsContainer}>
          {!authenticatedProfileId && step && (
            <PlainButton type='button' onClick={backAction}>
              <Trans>Back</Trans>
            </PlainButton>
          )}

          <Button
            type='submit'
            loading={loading}
            feedback={feedback}
            onClick={decoratedOnSubmit}
            primary
          >
            {templateType === 'application' ? (
              <Trans>Continue to application</Trans>
            ) : (
              <Trans>Continue to registration</Trans>
            )}
          </Button>
        </ModalButtons>
      )}
    </form>
  )
}

export default AuthenticationLiteModalForm

import React, { useEffect } from 'react'

import FullScreenLoading from '../FullScreenLoading'

import styles from './styles.module.scss'

export default function FullscreenV2({ children, isLoading }) {
  useEffect(() => {
    const iframe = window.parent.document.querySelector(
      '#canvas-region > iframe'
    )
    iframe && iframe.classList.add('fullscreen')
    document.body.classList.add('fullscreen')

    return () => {
      iframe && iframe.classList.remove('fullscreen')
      document.body.classList.remove('fullscreen')
    }
  }, [])

  return (
    <div className={styles.fullscreen}>
      {isLoading ? <FullScreenLoading /> : children}
    </div>
  )
}

import React from 'react'

import { useAppData } from 'shared/contexts/AppData'

import PageTitle from './page-title'

const FormTitle = () => {
  const { form } = useAppData()
  const formTitle = form && form.name

  return <PageTitle>{formTitle}</PageTitle>
}

export default FormTitle

import React, { useCallback, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Alert, AnnotatedLayout } from '@fullfabric/alma-mater'

import createSubject from 'apps/Subjects/api/createSubject'
import { useGoToSubjectPage } from 'apps/Subjects/contexts/TargetSubjectContext'
import isExistingCodeError from 'apps/Subjects/utils/isExistingCodeError'
import ActionButton from './ActionButton'
import SubjectBasicsForm from './BasicsCreationForm'
import Footer from './Footer'
import Header from './Header'
import { SubjectCreationHandlerProvider } from './SubjectCreationHandlerContext'

import styles from './styles.module.scss'

const CreateSubjectPage = () => {
  const { t } = useTranslation()
  const [isCreatingSubject, setIsCreatingSubject] = useState(false)
  const [submissionError, setSubmissionError] = useState('')
  const formMethods = useForm()
  const goToSubjectPage = useGoToSubjectPage({ afterCreatingSubject: true })

  const onSubmit = useCallback(
    async (formValues) => {
      try {
        setIsCreatingSubject(true)
        const createdSubject = await createSubject(formValues)

        goToSubjectPage({ ...createdSubject })
      } catch (e) {
        setIsCreatingSubject(false)
        if (isExistingCodeError(e)) {
          setSubmissionError(t('The subject code already exists.'))
        } else {
          setSubmissionError(
            t(
              'There was an error, please try again in a few seconds. If the issue persists, please contact us.'
            )
          )
        }
      }
    },
    [t, goToSubjectPage]
  )

  return (
    <SubjectCreationHandlerProvider handler={onSubmit}>
      <FormProvider {...formMethods}>
        <AnnotatedLayout
          alert={
            submissionError ? (
              <Alert error role='alert' title={submissionError} />
            ) : null
          }
          pageHead={<Header loading={isCreatingSubject} />}
          className={styles.pageLayout}
        >
          <div className={styles.mainSection}>
            <SubjectBasicsForm />
          </div>
          <Footer>
            <ActionButton loading={isCreatingSubject} />
          </Footer>
        </AnnotatedLayout>
      </FormProvider>
    </SubjectCreationHandlerProvider>
  )
}

export default CreateSubjectPage

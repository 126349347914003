import React from 'react'

import Content from './Content'
import ContentRegion from './ContentRegion'
import Footer from './Footer'
import Header from './Header'
import SidebarRegion from './SidebarRegion'

import styles from './styles.module.scss'

const PageLayout = ({ children }) => {
  return <div className={styles.container}>{children}</div>
}

PageLayout.SidebarRegion = SidebarRegion
PageLayout.ContentRegion = ContentRegion
PageLayout.Header = Header
PageLayout.Footer = Footer
PageLayout.Content = Content

export default PageLayout

import React from 'react'
import { useTranslation } from 'react-i18next'

import { Badge } from '@fullfabric/alma-mater'

const typesByStatus = {
  deleted: 'red',
  'non-billable': 'yellow',
  billable: 'green'
}

const getTitle = (status, t) => {
  const titlesByStatus = {
    deleted: t('Deleted'),
    'non-billable': t('Non-billable'),
    billable: t('Billable')
  }

  return titlesByStatus[status]
}

const StatusBadge = ({ status }) => {
  const { t } = useTranslation()
  return <Badge type={typesByStatus[status]}>{getTitle(status, t)}</Badge>
}

export default StatusBadge

import React from 'react'
import { Trans } from 'react-i18next'

import { Col, DeleteIcon, Field, IconButton, Row } from '@fullfabric/alma-mater'
import { useSettings } from '@fullfabric/authorization-officer'

import { getDegreeClassPlaceholder } from './utils'

import styles from './styles.module.scss'

const SingleDegreeClassForm = ({ degreeClass, setDegreeClasses }) => {
  const settings = useSettings()

  const onChange = React.useCallback(
    (fieldId, value) => {
      const fieldName = fieldId.split('_')[0]

      const formattedValue =
        fieldName === 'names'
          ? Object.keys(value).reduce(
              (all, locale) => ({
                ...all,
                [locale]: value[locale]?.target?.value || value[locale]
              }),
              {}
            )
          : parseInt(value, 10)

      setDegreeClasses((degreeClasses) => ({
        ...degreeClasses,
        [degreeClass.id]: { ...degreeClass, [fieldName]: formattedValue }
      }))
    },
    [degreeClass, setDegreeClasses]
  )

  const onDeleteClick = React.useCallback(() => {
    setDegreeClasses((degreeClasses) => {
      const { [degreeClass.id]: deletedDegreeClass, ...remaining } =
        degreeClasses

      return Object.keys(remaining).length === 0
        ? getDegreeClassPlaceholder()
        : remaining
    })
  }, [degreeClass.id, setDegreeClasses])

  return (
    <Row key={degreeClass.id}>
      <Col xs={7}>
        <Field
          id={`names_${degreeClass.id}`}
          type='localizedText'
          locales={settings.hash.locales}
          value={degreeClass.names}
          label={<Trans>Degree class</Trans>}
          inputOptions={{
            'en-GB': {
              'data-testid': 'degreeClassEnGB'
            }
          }}
          onChange={onChange}
        />
      </Col>
      <Col xs={2}>
        <Field
          id={`minimum_${degreeClass.id}`}
          type='text'
          value={degreeClass.minimum >= 0 ? `${degreeClass.minimum}` : ''}
          label={<Trans>Min</Trans>}
          onChange={onChange}
        />
      </Col>
      <Col xs={2}>
        <Field
          id={`maximum_${degreeClass.id}`}
          type='text'
          value={degreeClass.maximum >= 0 ? `${degreeClass.maximum}` : ''}
          label={<Trans>Max</Trans>}
          onChange={onChange}
        />
      </Col>
      <Col xs={1} className={styles.deleteIcon}>
        <IconButton plain onClick={onDeleteClick}>
          <DeleteIcon />
        </IconButton>
      </Col>
    </Row>
  )
}

export default SingleDegreeClassForm

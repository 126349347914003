.react-app ._virtualizedRow_4d1v5_1 {
  display: flex;
}

.react-app ._numericAlignment_4d1v5_5 {
  display: flex;
  justify-content: flex-end;
}

.react-app ._defaultTextPlaceholder_4d1v5_10 {
  height: 14px;
  margin: 1px;
  max-width: 250px;
}

.react-app ._checkboxPlaceholder_4d1v5_16 {
  max-width: 16px;
}

.react-app ._level-0_4d1v5_20 {
  opacity: 1;
}

.react-app ._level-1_4d1v5_24 {
  opacity: 0.9;
}

.react-app ._level-2_4d1v5_28 {
  opacity: 0.8;
}

.react-app ._level-3_4d1v5_32 {
  opacity: 0.7;
}

.react-app ._level-4_4d1v5_36 {
  opacity: 0.6;
}

.react-app ._level-5_4d1v5_40 {
  opacity: 0.5;
}

.react-app ._level-6_4d1v5_44 {
  opacity: 0.4;
}

.react-app ._level-7_4d1v5_48 {
  opacity: 0.3;
}

.react-app ._level-8_4d1v5_52 {
  opacity: 0.2;
}

.react-app ._level-9_4d1v5_56 {
  opacity: 0.1;
}
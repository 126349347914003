/* eslint-disable */

import React from 'react'
import { Trans } from 'react-i18next'

import { Alert, Modal, Text } from '@fullfabric/alma-mater'

import { useAppData } from 'shared/contexts/AppData'

import Email from 'apps/Authentication/components/AuthLiteForm/body/email'
import SignIn from 'apps/Authentication/components/AuthLiteForm/body/signIn'
import SignUp from 'apps/Authentication/components/AuthLiteForm/body/signUp'
import {
  useSetStepInAuthentication,
  useStepInAuthentication
} from '../contexts/StepInAuthentication'

import styles from '../styles.module.scss'

const LoginModal = ({
  data,
  setData,
  needsPrivacyPolicy,
  setNeedsPrivacyPolicy,
  hasPassword,
  setHasPassword,
  expiredMagicToken
}) => {
  const {
    template_name,
    logo_url,
    privacy_policy: privacyPolicy
  } = useAppData()
  const step = useStepInAuthentication()
  const setStep = useSetStepInAuthentication()

  return (
    <Modal size='medium' isOpen={true} className={styles.modal}>
      <img alt='Logo' src={logo_url} />
      {step === 'email' && expiredMagicToken && (
        <Alert
          error
          title={<Trans>The link has expired.</Trans>}
          description={
            <Trans>
              Please re-enter your details to receive a new authentication
              email.
            </Trans>
          }
        />
      )}
      <Text
        type={step === 'email' ? 'h2' : 'h6'}
        fontColor={step === 'email' ? 'text-base-darkest' : 'text-base-darker'}
        marginTop={expiredMagicToken ? 'more' : null}
        marginBottom={step === 'email' ? 'more' : 'tiny'}
      >
        {template_name}
      </Text>
      {step === 'email' && (
        <>
          <Text marginBottom='default' fontColor='text-base-darkest'>
            <Trans>
              Please enter your email address so that we can retrieve your
              information if you have visited before.
            </Trans>
          </Text>
          <Email
            data={data}
            setData={setData}
            setStep={setStep}
            setNeedsPrivacyPolicy={setNeedsPrivacyPolicy}
            setHasPassword={setHasPassword}
          />
        </>
      )}
      {step === 'signIn' && (
        <SignIn
          data={data}
          setStep={setStep}
          setData={setData}
          privacyPolicy={privacyPolicy}
          needsPrivacyPolicy={needsPrivacyPolicy}
          hasPassword={hasPassword}
        />
      )}
      {step === 'signUp' && (
        <SignUp
          data={data}
          setStep={setStep}
          setData={setData}
          privacyPolicy={privacyPolicy}
        />
      )}
    </Modal>
  )
}

export default LoginModal

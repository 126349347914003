import React from 'react'
import { Trans } from 'react-i18next'

import {
  AddIcon,
  Button,
  CheckboxInput,
  Field,
  Link,
  Text
} from '@fullfabric/alma-mater'

import { useStudyPlan } from 'apps/StudyPlans/contexts/StudyPlanData'
import DeleteMultiplePathsConfirmationModal from '../DeletePathsConfirmationModal'
import usePathEditionCallbacks from './hooks/usePathEditionCallbacks'
import usePathSaveChanges from './hooks/usePathSaveChanges'
import SinglePathForm from './SinglePathForm'
import { canSelectSinglePath, getPathPlaceholder } from './utils'

const SelectionsForm = () => {
  const studyPlan = useStudyPlan()

  const [multiplePaths, setMultiplePaths] = React.useState(
    studyPlan.multiple_paths
  )
  const [allowCourseOverlap, setAllowCourseOverlap] = React.useState(
    studyPlan.allow_course_overlap
  )
  const [paths, setPaths] = React.useState(
    (studyPlan.paths || []).length === 0
      ? getPathPlaceholder()
      : studyPlan.paths.reduce(
          (hashed, curr) => ({ ...hashed, [curr.id]: curr }),
          {}
        )
  )
  const [confirmModalOpen, setConfirmModalOpen] = React.useState(false)

  const { onPathChanged, onPathDelete, onPathAdd } = usePathEditionCallbacks({
    paths,
    setPaths
  })

  const { isLoading, onSaveChanges, onSaveChangesModalConfirm, onCancel } =
    usePathSaveChanges({
      multiplePaths,
      paths,
      studyPlan,
      setPaths,
      allowCourseOverlap,
      setConfirmModalOpen
    })

  const canSelectSingle = canSelectSinglePath(multiplePaths, studyPlan)

  return (
    <>
      <Text type='h5' marginBottom='small' fontColor='text-base-darkest'>
        <Trans>Course selection</Trans>
      </Text>
      <CheckboxInput
        id='allowCourseOverlap'
        checked={!allowCourseOverlap}
        label={
          <Trans>
            Don't allow the selection of courses with overlapping dates
          </Trans>
        }
        instructions={
          <Trans>
            Make sure to set dates for the courses you add to the study plan
          </Trans>
        }
        onChange={(checked) => setAllowCourseOverlap(!checked)}
      />
      <Field
        id='pathType'
        type='radio'
        value={multiplePaths ? 'multiple' : 'single'}
        inputOptions={[
          {
            label: <Trans>Single path</Trans>,
            value: 'single',
            disabled: !canSelectSingle,
            instructions: !canSelectSingle ? (
              <Trans>
                Single path cannot be selected because there are paths that have
                student transcripts.
              </Trans>
            ) : null
          },
          { label: <Trans>Multiple paths</Trans>, value: 'multiple' }
        ]}
        label={<Trans>Path</Trans>}
        onChange={(_, value) => {
          setMultiplePaths(value === 'multiple')
        }}
      />
      {multiplePaths && (
        <>
          <>
            {Object.values(paths).map((path) => (
              <SinglePathForm
                key={path.id}
                path={path}
                onChange={onPathChanged}
                onDelete={onPathDelete}
                hasMoreThanOnePath={Object.keys(paths).length === 1}
              />
            ))}
          </>
          <Link
            disabled={Object.values(paths).some((path) => !path.name)}
            onClick={onPathAdd}
          >
            <Trans>Add another</Trans> <AddIcon />
          </Link>
        </>
      )}
      <div className={multiplePaths ? 'marginTop-more' : 'marginTop-small'}>
        <Button
          primary
          size='small'
          onClick={onSaveChanges}
          loading={isLoading}
          disabled={isLoading}
        >
          <Trans>Save changes</Trans>
        </Button>
        <Button
          size='small'
          onClick={onCancel}
          className='marginLeft-small'
          disabled={isLoading}
        >
          <Trans>Cancel</Trans>
        </Button>
      </div>
      <DeleteMultiplePathsConfirmationModal
        isOpen={confirmModalOpen}
        onCancel={() => setConfirmModalOpen(false)}
        onConfirm={onSaveChangesModalConfirm}
      />
    </>
  )
}

export default SelectionsForm

const startApplicationOnBehalf = (applicId, profileParams, context) => {
  const contextParams = context ? { context } : {}
  return fetch(`/api/templates/${applicId}/start_on_behalf`, {
    method: 'POST',
    body: JSON.stringify({ profile_params: profileParams, ...contextParams }),
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json'
    }
  }).then((result) => result.json())
}

export default startApplicationOnBehalf

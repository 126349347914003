import React from 'react'
import { Trans } from 'react-i18next'

import { Text } from '@fullfabric/alma-mater'

import classNames from 'classnames'
import styles from './styles.module.scss'

const HasAssociatedContextInApplicsNotice = ({ discarded }) => {
  const text = discarded ? (
    <Trans>
      This journey was automatically discarded since the associated application
      was discarded.
    </Trans>
  ) : (
    <Trans>
      This journey was automatically added since the associated application was
      added.
    </Trans>
  )

  return (
    <div
      className={classNames(
        styles.associatedContextNoticeContainer,
        discarded ? styles.discarded : styles.added
      )}
    >
      <Text fontColor={discarded ? 'yellow-700' : 'text-success'}>{text}</Text>
    </div>
  )
}

export default HasAssociatedContextInApplicsNotice

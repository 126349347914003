import * as React from 'react'
import { useTranslation } from 'react-i18next'

import { Button, Dropdown, EyeIcon } from '@fullfabric/alma-mater'

import {
  CAPACITY_BREAKDOWN_CHART,
  CAPACITY_TARGETS_CHART
} from 'apps/Reports/constants/productPerformanceCharts'
import useHasCourseJourneysSetting from 'apps/Reports/hooks/useHasCourseJourneysSetting'

import styles from './styles.module.scss'

const ChartTypeButton = ({ onChangeChartType }) => {
  const hasCourseJourneysSettings = useHasCourseJourneysSetting()
  const { t } = useTranslation()
  const capacityTargetsChartLabel = t('Target Capacity')
  const capacityBreakdownChartLabel = t('Breakdown')
  const [chartLabel, setChartLabel] = React.useState(capacityTargetsChartLabel)

  if (!hasCourseJourneysSettings) {
    return null
  }

  return (
    <div className={styles.buttonPositioner}>
      <Dropdown>
        <Dropdown.Toggle>
          <Button size='small'>
            <EyeIcon className={styles.eyeIcon} color='text-base-darker' />
            {chartLabel}
          </Button>
        </Dropdown.Toggle>
        <Dropdown.Menu aria-label='chart type dropdown menu'>
          <Dropdown.Menu.Option
            aria-labelledby='capacity-targets-dropdown-option'
            onClick={() => {
              onChangeChartType(CAPACITY_TARGETS_CHART)
              setChartLabel(capacityTargetsChartLabel)
            }}
          >
            <span id='capacity-targets-dropdown-option'>
              {capacityTargetsChartLabel}
            </span>
          </Dropdown.Menu.Option>
          <Dropdown.Menu.Option
            aria-labelledby='capacity-breakdown-dropdown-option'
            onClick={() => {
              onChangeChartType(CAPACITY_BREAKDOWN_CHART)
              setChartLabel(capacityBreakdownChartLabel)
            }}
          >
            <span id='capacity-breakdown-dropdown-option'>
              {capacityBreakdownChartLabel}
            </span>
          </Dropdown.Menu.Option>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  )
}

export default ChartTypeButton

.react-app ._loaderWrapper_17hrl_1 {
  height: 245px;
}

.react-app ._contentWrapper_17hrl_5 {
  display: flex;
  align-items: center;
  flex-flow: column;
  justify-content: space-between;
  margin-top: var(--space-default);
}

.react-app ._modalText_17hrl_13 {
  margin: 0;
}

.react-app ._buttonWrapper_17hrl_17 {
  justify-content: center !important;
  margin-top: 59px;
}

.react-app ._modalContent_17hrl_22 {
  margin-bottom: 0 !important;
  margin-top: 0 !important;
}
import { connect } from 'react-redux'

import actionTypes from 'apps/Courses/actions/types'
import CourseEditDisplayAcademicDetails from 'apps/Courses/components/CourseEditDisplayAcademicDetails'

const mapStateToProps = ({ editCourse: { data } }) => ({ data })

const mapDispatchToProps = (dispatch) => ({
  onEditClick: (course) =>
    dispatch({
      type: actionTypes.editAcademicDetailsSectionData,
      payload: {
        credits: course.credits,
        hours: course.hours,
        max_number_absences: course.max_number_absences
      }
    })
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CourseEditDisplayAcademicDetails)

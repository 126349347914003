function getPeriodicityLabel(value, t) {
  const periodicityLabels = {
    yearly: t('Yearly'),
    monthly: t('Monthly'),
    quarterly: t('Quarterly')
  }

  return periodicityLabels[value] || value
}

export default getPeriodicityLabel

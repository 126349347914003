import React from 'react'

import { Table } from '@fullfabric/alma-mater'

import getSortDirectionFromColumn from '../../../../utils/getSortDirectionFromColumn'
import normalizeColumnTitle from '../../../../utils/normalizeColumnTitle'

const isThereAnyHeader = (headerGroups) =>
  headerGroups.some((hg) => hg.headers.some((c) => c.Header))

const TableHeader = ({
  headerGroups,
  onChangeSort,
  setSortBy,
  isDisplayingFullTableMessage
}) => {
  if (!isThereAnyHeader(headerGroups)) {
    return null
  }

  return (
    <Table.Header>
      {headerGroups.map((headerGroup) => {
        const { key: headerGroupKey, ...headerGroupProps } =
          headerGroup.getHeaderGroupProps()

        return (
          <Table.Row key={headerGroupKey} {...headerGroupProps}>
            {headerGroup.headers.map((column) => {
              const sortProps =
                column.getSortByToggleProps &&
                column.getSortByToggleProps((props) => {
                  return {
                    ...props,
                    title: normalizeColumnTitle(column),
                    onClick: column.canSort
                      ? () => onChangeSort(column, setSortBy)
                      : undefined
                  }
                })

              const {
                key: colKey,
                flexibleWidth,
                ...rawColumnProps
              } = column.getHeaderProps(sortProps)

              const columnProps =
                isDisplayingFullTableMessage && flexibleWidth
                  ? { ...rawColumnProps, style: { width: '100%' } }
                  : rawColumnProps

              return (
                <Table.HeaderItem
                  key={colKey}
                  {...columnProps}
                  sort={getSortDirectionFromColumn(column)}
                >
                  {column.render('Header')}
                </Table.HeaderItem>
              )
            })}
          </Table.Row>
        )
      })}
    </Table.Header>
  )
}

export default TableHeader

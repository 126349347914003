import 'css-chunk:src/apps/ContentPages/components/payments-drawer/fee-info/amount-details-table/styles.module.scss';export default {
  "react-app": "react-app",
  "table": "_table_14iax_1",
  "headerCell": "_headerCell_14iax_13",
  "leftColumn": "_leftColumn_14iax_18",
  "rightColumn": "_rightColumn_14iax_23",
  "dataCell": "_dataCell_14iax_28",
  "totalRow": "_totalRow_14iax_33",
  "totalCell": "_totalCell_14iax_37",
  "mobile": "_mobile_14iax_42"
};
import getClassAttributeDistribution from './getClassAttributeDistribution'

const getClassNationalityDistribution = async ({ ...params }) => {
  return await getClassAttributeDistribution({
    top: 10,
    ...params
  })
}

export default getClassNationalityDistribution

import React from 'react'

import arrayAsGroupedOptions from 'apps/Imports/utils/array-as-grouped-options'

export const ImportDocumentContext = React.createContext()

export const ImportDocumentProvider = ({
  children,
  initialDocument = null,
  initialMappableFields = null
}) => {
  const [importDocument, setImportDocument] = React.useState(initialDocument)
  const [importMappableFields, setImportMappableFields] = React.useState(
    initialMappableFields
  )
  const [importErrors, setImportErrors] = React.useState()

  return (
    <ImportDocumentContext.Provider
      value={{
        importDocument,
        importMappableFields,
        importErrors,
        setImportDocument,
        setImportMappableFields,
        setImportErrors
      }}
    >
      {children}
    </ImportDocumentContext.Provider>
  )
}

export const useImportDocument = () =>
  React.useContext(ImportDocumentContext)?.importDocument

export const useImportFieldOptions = () => {
  const mappableFields = React.useContext(
    ImportDocumentContext
  )?.importMappableFields

  const fieldOptions = React.useMemo(
    () => arrayAsGroupedOptions(mappableFields),
    [mappableFields]
  )

  return fieldOptions
}

export const useImportErrors = () =>
  React.useContext(ImportDocumentContext)?.importErrors

export const useSetImportDocumentData = () => {
  const { setImportDocument, setImportMappableFields, setImportErrors } =
    React.useContext(ImportDocumentContext)

  return {
    setImportDocument,
    setImportMappableFields,
    setImportErrors
  }
}

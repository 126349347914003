import React, { forwardRef } from 'react'

import PropTypes from 'prop-types'

import getLocalizedText from '../../utils/getLocalizedText'
import FooterDisplay from './footer-display'
import ModalDisplay from './modal-display'
import useCookiePrompt from './use-cookie-prompt'

const CookiePrompt = forwardRef(
  ({ locale, cookiePolicy, user, userIsStaff, onAccepted }, ref) => {
    const { isPromptShowing, onAcceptCookies: acceptAllCookiesAndDismiss } =
      useCookiePrompt(cookiePolicy, user, userIsStaff, onAccepted)

    const cookieText = cookiePolicy && {
      __html: getLocalizedText(cookiePolicy.footer_locales, locale, 'html')
    }

    const cookieBtnText = cookiePolicy && {
      __html: getLocalizedText(cookiePolicy.footer_locales_btn, locale, 'html')
    }

    if (!cookiePolicy || !isPromptShowing) return null

    return cookiePolicy.display_type === 'modal' ? (
      <ModalDisplay
        cookieText={cookieText}
        cookieBtnText={cookieBtnText}
        acceptAllCookiesAndDismiss={acceptAllCookiesAndDismiss}
      />
    ) : (
      <FooterDisplay
        cookieText={cookieText}
        cookieBtnText={cookieBtnText}
        acceptAllCookiesAndDismiss={acceptAllCookiesAndDismiss}
        ref={ref}
      />
    )
  }
)

export default CookiePrompt

CookiePrompt.propTypes = {
  locale: PropTypes.string.isRequired,
  cookiePolicy: PropTypes.shape({
    display_type: PropTypes.string.isRequired,
    footer_locales: PropTypes.arrayOf(
      PropTypes.shape({
        locale: PropTypes.string.isRequired,
        html: PropTypes.string.isRequired
      })
    ).isRequired,
    footer_locales_btn: PropTypes.arrayOf(
      PropTypes.shape({
        locale: PropTypes.string.isRequired,
        html: PropTypes.string.isRequired
      })
    ).isRequired
  }),
  user: PropTypes.shape({
    cookies_acceptance: PropTypes.object
  }),
  userIsStaff: PropTypes.bool,
  onAccepted: PropTypes.func
}

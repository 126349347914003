{
  "A word by itself is easy to guess": "Ein Wort an sich ist leicht zu erraten",
  "Add \"{{val}}\"": "Mehrwert \"{{val}}\"",
  "Add another word or two. Uncommon words are better.": "Fügen Sie ein oder zwei weitere Wörter hinzu. Ungewöhnliche Wörter sind besser.",
  "All-uppercase is almost as easy to guess as all-lowercase": "Großbuchstaben sind fast so einfach zu erraten wie Kleinbuchstaben",
  "Almost there": "Fast geschafft",
  "Already have an account?": "Verfügen Sie bereits über ein Konto?",
  "Avoid dates and years that are associated with you": "Vermeiden Sie Daten und Jahre, die mit Ihnen verbunden sind",
  "Avoid recent years": "Vermeide die letzten Jahre",
  "Avoid repeated words and characters": "Vermeiden Sie wiederholte Wörter und Zeichen",
  "Avoid sequences": "Vermeiden Sie Sequenzen",
  "Avoid years that are associated with you": "Vermeiden Sie Jahre, die mit Ihnen verbunden sind",
  "Back to login": "Zurück zur Anmeldeseite",
  "Cancel": "Abbrechen",
  "Capitalization doesn't help very much": "Die Großschreibung hilft nicht sehr",
  "Common names and surnames are easy to guess": "Allgemeine Namen und Nachnamen sind leicht zu erraten",
  "Continue": "Fortfahren",
  "Create an account": "Ein Konto erstellen",
  "Create new account": "Neuen Account erstellen",
  "Data Protection Regulation": "Datenschutzverordnung",
  "Dates are often easy to guess": "Termine sind oft leicht zu erraten",
  "Didn't receive the sms?": "Haben Sie die SMS nicht erhalten?",
  "E-mail address": "E-Mail-Adresse",
  "First name": "Vorname",
  "For security reasons account activation links are perishable. You can get a new activation link by filling the sign up form.": "Aus Sicherheitsgründen sind die Aktivierungslinks für Ihr Konto vergänglich. Sie können einen neuen Aktivierungslink erhalten, indem Sie das Anmeldeformular erneut ausfüllen.",
  "Forgot your password?": "Haben Sie Ihr Passwort vergessen?",
  "Great, your account has been created!": "Toll, Ihr Konto wurde erstellt!",
  "I accept the privacy policy": "Ich akzeptiere die Datenschutzerklärung",
  "Institutional login": "Institutions-Login",
  "Last name": "Nachname",
  "Link existing account": "Vorhandenes Konto verknüpfen",
  "LinkedIn": "LinkedIn",
  "Log in": "Anmelden",
  "Log in securely": "Sicher einloggen",
  "Names and surnames by themselves are easy to guess": "Namen und Familiennamen sind leicht zu erraten",
  "New Password": "Neues Kennwort",
  "No Results": "Keine Ergebnisse",
  "No need for symbols, digits, or uppercase letters": "Keine Notwendigkeit für Symbole, Ziffern oder Großbuchstaben",
  "Not registered?": "Noch nicht registriert?",
  "Nothing found for": "Nichts gefunden für",
  "Or use these alternatives": "Oder verwenden Sie diese Alternativen:",
  "Password": "Passwort",
  "Password Reset": "Passwort zurücksetzen",
  "Please accept the privacy policy to continue": "Bitte akzeptieren Sie die Datenschutzrichtlinie, um fortzufahren",
  "Please check your email for instructions on resetting your password": "Bitte überprüfen Sie Ihre E-Mail mit den Anweisungen zum Zurücksetzen Ihres Passworts",
  "Please check your mailbox for instructions on verifying your registration.": "Bitte loggen Sie sich in Ihr E-Mail-Postfach ein. Dort finden Sie eine E-Mail mit einem Link, über den Sie Ihre Registrierung bestätigen können.",
  "Please choose a password": "Bitte wählen Sie ein Passwort",
  "Please confirm your account details below": "Bitte bestätigen Sie unten Ihre Account-Daten",
  "Please contact your university's IT support department in order to get a new activation link to access Full Fabric.": "Bitte kontaktieren Sie den IT-Support-Abteilung Ihrer Universität, um einen neuen Aktivierungslink für den Zugriff auf Full-Fabric zu erhalten.",
  "Please type a new password.": "Bitte geben Sie ein neues Passwort ein.",
  "Predictable substitutions like \"@\" instead of \"a\" don't help very much": "Vorhersehbare Substitutionen wie \"@\" anstelle von \"a\" helfen nicht sehr",
  "Predictable substitutions like '@' instead of 'a' don't help very much": "Vorhersehbare Substitutionen wie '@' anstelle von 'a' helfen nicht sehr",
  "Privacy policy": "Datenschutzerklärung",
  "Recent years are easy to guess": "Die letzten Jahre sind leicht zu erraten",
  "Repeats like \"aaa\" are easy to guess": "Wiederholungen wie \"aaa\" sind leicht zu erraten",
  "Repeats like \"abcabcabc\" are only slightly harder to guess than \"abc\"": "Wiederholungen wie \"abcabcabc\" sind nur etwas schwerer zu erraten als \"abc\"",
  "Reset password": "Passwort zurücksetzen",
  "Reset password and sign in": "Passwort zurücksetzen und anmelden",
  "Reset your password below.": "Sie können unten Ihr Passwort zurücksetzen.",
  "Return to log in page": "Zurück zur Anmeldeseite",
  "Reversed words aren't much harder to guess": "Umgekehrte Wörter sind nicht viel schwerer zu erraten",
  "Send again": "Nochmal senden",
  "Sequences like abc or 6543 are easy to guess": "Sequenzen wie abc oder 6543 sind leicht zu erraten",
  "Set password and sign in": "Passwort erstellen und einloggen",
  "Short keyboard patterns are easy to guess": "Kurze Tastaturmuster sind leicht zu erraten",
  "Sign in": "Anmelden",
  "Sign up": "Anmelden",
  "Skip to main content": "Zum Hauptinhalt springen",
  "Straight rows of keys are easy to guess": "Gerade Reihen von Tasten sind leicht zu erraten",
  "Suggestions": "Vorschläge",
  "Thank you.": "Vielen Dank.",
  "There was a server problem. Please try again.": "Es gab ein Server-Problem. Bitte versuchen Sie es erneut.",
  "This is a top-10 common password": "Dies ist eines der 10 häufigsten Passwörter",
  "This is a top-100 common password": "Dies ist eines der 100 häufigsten Passwörter",
  "This is a very common password": "Dies ist ein sehr häufiges Passwort",
  "This is similar to a commonly used password": "Dies ähnelt einem häufig verwendeten Passwort",
  "Two Factor Authentication Token": "Zwei-Faktor-Authentifizierungs-Token",
  "Two step verification": "Bestätigung in zwei Schritten",
  "Use a few words, avoid common phrases": "Verwenden Sie ein paar Wörter, vermeiden Sie häufige Phrasen",
  "Use a longer keyboard pattern with more turns": "Verwenden Sie ein längeres Tastaturmuster mit mehr Drehungen",
  "Use one of these alternatives to signup": "Verwenden Sie eine dieser Alternativen zur Registrierung",
  "Use one of these methods to log in": "Verwenden Sie eine dieser Methoden, um sich anzumelden",
  "We sent a sms to the number <1>{{phoneNumber}}</1> with the verification code": "Wir haben eine SMS mit dem Bestätigungscode an die Nummer <1>{{phoneNumber}}</1> gesendet",
  "Welcome": "Herzlich willkommen",
  "Your activation link has expired.": "Ihr Aktivierungslink ist abgelaufen.",
  "Your password has expired.": "Ihr Passwort ist abgelaufen.",
  "Your password is good": "Ihr Passwort ist sehr sicher",
  "Your password is reasonably adequate": "Ihr Passwort ist relativ sicher",
  "Your password is too weak": "Ihr Passwort ist zu schwach",
  "or e-mail": "oder E-Mail",
  "or link an existing account": "oder verknüpfen ein bereits bestehendes Konto",
  "{{minAlpha}} letters": "{{minAlpha}} Buchstabe",
  "{{minDigits}} digits": "{{minDigits}} Zahl(en)",
  "{{minLength}} characters": "{{minLength}} Zeichen",
  "{{minSymbols}} symbols": "{{minSymbols}} symbol(s)",
  "Password must be at least {{minLength}} characters long": "Das Passwort muss mindestens {{minLength}} Zeichen lang sein",
  "Password must have at least {{count}} characters": "Das Passwort muss mindestens {{count}} Zeichen enthalten",
  "Password must have at least {{count}} characters_plural": "Das Passwort muss mindestens {{count}} Zeichen enthalten",
  "Password must have at least {{count}} digit": "Das Passwort muss mindestens {{count}} Ziffer enthalten",
  "Password must have at least {{count}} digit_plural": "Das Passwort muss mindestens {{count}} Ziffern enthalten",
  "Password must have at least {{count}} symbol": "Das Passwort muss mindestens {{count}} Sonderzeichen enthalten",
  "Password must have at least {{count}} symbol_plural": "Das Passwort muss mindestens {{count}} Sonderzeichen enthalten",
  "Password was already used": "Passwort wurde bereits verwendet",
  "Thank you. Please log in below.": "Dankeschön. Bitte loggen Sie sich unten ein.",
  "This field is required": "Dieses Feld ist erforderlich",
  "Privacy Policy": "Datenschutzerklärung",
  "Link to an existing account": "Verknüpfen Sie ein bestehendes Konto",
  "There are fields with errors.": "Es gibt fehlerhafte Felder.",
  "Please correct the fields with errors.": "Bitte korrigieren Sie die fehlerhaften Felder.",
  "We sent a sms to the number {{ phoneNumber }} with the verification code": "Wir haben eine SMS mit dem Bestätigungscode an die Nummer {{phoneNumber}} gesendet"
}

import React, { useCallback, useState } from 'react'
import { Trans } from 'react-i18next'
import { useMutation } from 'react-query'

import {
  Button,
  Field,
  Modal,
  ModalButtons,
  ModalTitle,
  Text
} from '@fullfabric/alma-mater'

import ProgrammeAndClassSelector from 'shared/components/ProgrammeAndClassSelector'

import duplicateStudyPlanFromClass from 'apps/StudyPlans/api/duplicateStudyPlanFromClass'
import {
  useClassOf,
  useSetStudyPlan
} from 'apps/StudyPlans/contexts/StudyPlanData'

import styles from './styles.module.scss'

export default function DuplicateModal({
  handleCancelDuplicateStudyPlanClick
}) {
  const classOf = useClassOf()
  const setStudyPlan = useSetStudyPlan()
  const [showErrors, setShowErrors] = useState(false)
  const [classChoice, setClassChoice] = useState(null)
  const [includeCourses, setIncludeCourses] = useState('true')

  const { mutate: duplicateStudyPlanMutation } = useMutation(
    () =>
      duplicateStudyPlanFromClass({
        fromClassOfId: classChoice,
        toClassOfId: classOf.id,
        includeCourses
      }),
    {
      onSuccess: (createdStudyPlan) => setStudyPlan(createdStudyPlan)
    }
  )

  const onChoiceChange = useCallback(
    (isValidChoice, choice) => {
      setShowErrors((isShowingErrors) => isShowingErrors && !isValidChoice)
      setClassChoice(isValidChoice && choice?.class_of_id)
    },
    [setShowErrors]
  )

  const handleDuplicateStudyPlan = () => duplicateStudyPlanMutation()

  return (
    <Modal
      isOpen
      size='medium'
      contentClassName={styles.modalBody}
      header={<ModalTitle title={<Trans>Create study plan</Trans>} />}
      footer={
        <ModalButtons>
          <Button onClick={handleCancelDuplicateStudyPlanClick}>
            <Trans>Cancel</Trans>
          </Button>
          <Button
            primary
            onClick={handleDuplicateStudyPlan}
            disabled={!classChoice}
          >
            <Trans>Confirm and duplicate</Trans>
          </Button>
        </ModalButtons>
      }
    >
      <>
        <Text>
          <Trans>
            Please select the class with the study plan to be duplicated. All
            information of the study plan will be duplicated including the study
            plan’s settings and the study plan subjects.
          </Trans>
        </Text>

        <ProgrammeAndClassSelector
          onChange={onChoiceChange}
          onlyClassesWithStudyPlans={true}
          chooseCampus={false}
          showErrors={showErrors}
        />

        <Field
          id='include-courses-radio'
          type='radio'
          label={
            <Trans>Copy course selection for each study plan subject?</Trans>
          }
          required
          onChange={(field, value) => setIncludeCourses(value)}
          value={includeCourses}
          inputOptions={[
            {
              label: <Trans>Yes, select the same courses</Trans>,
              value: 'true'
            },
            {
              label: <Trans>No, do not select any course</Trans>,
              value: 'false'
            }
          ]}
        />
      </>
    </Modal>
  )
}

import React, { useState } from 'react'

import PropTypes from 'prop-types'

import { Button } from '@fullfabric/alma-mater'

import classNames from 'classnames'
import styles from './styles.module.scss'

const icon = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
  >
    <g fill='none' fillRule='evenodd'>
      <path
        fill='#395185'
        d='M22.631 23.95c.729 0 1.32-.59 1.32-1.319V1.37c0-.73-.591-1.32-1.32-1.32H1.37C.639.05.049.64.049 1.37V22.63c0 .729.59 1.32 1.32 1.32H22.63'
      />
      <path
        fill='#FFF'
        d='M17 15h2.5l.5-4h-3V9c0-1.044-.17-1.971 1.328-1.971H20V4c-.33-.044-1.226-.34-2.545-.34C14.701 3.66 13 5.412 13 8.5V11h-3v4h3v8.95h4V15z'
      />
    </g>
  </svg>
)

const iconHover = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
  >
    <g fill='none' fillRule='evenodd'>
      <path
        fill='#FFF'
        d='M22.675 24c.732 0 1.325-.593 1.325-1.325V1.325C24 .593 23.407 0 22.675 0H1.325C.593 0 0 .593 0 1.325v21.35C0 23.407.593 24 1.325 24h21.35'
      />
      <path
        fill='#3B5998'
        d='M17 15.178h2.5l.5-3.943h-3V9.264c0-1.03-.17-1.943 1.328-1.943H20V4.336C19.67 4.292 18.774 4 17.455 4 14.701 4 13 5.728 13 8.771v2.464h-3v3.943h3V24h4v-8.822z'
      />
    </g>
  </svg>
)

const FacebookButton = ({ className, ...props }) => {
  const [hover, setHover] = useState(false)
  return (
    <Button
      type='outline'
      className={classNames(
        styles.thirdPartyLoginButton,
        styles['facebook-button'],
        className
      )}
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      {...props}
    >
      {hover ? iconHover : icon}
    </Button>
  )
}

FacebookButton.propTypes = {
  className: PropTypes.string
}

export default FacebookButton

import React from 'react'
import { Trans } from 'react-i18next'

import { Button } from '@fullfabric/alma-mater'

import { redirectToOffer } from './utils'

const ViewOfferButton = ({ offer }) => (
  <Button size='small' onClick={() => redirectToOffer(offer)}>
    <Trans>View Submission</Trans>
  </Button>
)

export default ViewOfferButton

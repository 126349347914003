import React, { useEffect } from 'react'
import { Trans } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'

import {
  AlertFullIcon,
  Button,
  Modal,
  ModalButtons,
  ModalTitle,
  Text
} from '@fullfabric/alma-mater'

import deleteTask from 'apps/Inbox/api/deleteTask'
import { useCardFilters } from 'apps/Inbox/contexts/CardFilters'
import {
  useCardToDelete,
  useSetCardToDelete
} from 'apps/Inbox/contexts/deleteCard'

import styles from './styles.module.scss'

export default function DeleteTaskModal() {
  const queryClient = useQueryClient()
  const { cardStatus, cardType } = useCardFilters()
  const cardToDelete = useCardToDelete()
  const setCardToDelete = useSetCardToDelete()
  const isOpen = !!cardToDelete
  const onClose = () => setCardToDelete(null)

  const {
    mutate: onDelete,
    isLoading: inProgress,
    reset
  } = useMutation(
    async ({ card }) => {
      const { entity: task } = card

      await deleteTask(task.id)

      return { updates: {}, card, cardType }
    },
    {
      onMutate: async ({ card }) => {
        const previousCards =
          queryClient.getQueryData(['cards', cardStatus, cardType]) || []

        const newCards = previousCards?.pages.map((page) => {
          const data = page.data.filter((val) => val.id !== card.id)
          return { ...page, data }
        })
        queryClient.setQueriesData(['cards', cardStatus, cardType], (data) => ({
          pages: newCards,
          pageParams: data.pageParams
        }))

        return { previousCards, cardStatus, cardType, card }
      },
      onError: (_err, _data, context) => {
        const { cardType } = context
        queryClient.invalidateQueries(['cards', cardStatus, cardType])
      },
      onSettled: () => {
        setCardToDelete(null)
      }
    }
  )

  useEffect(() => reset(), [cardToDelete, reset])

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size='medium'
      header={
        <ModalTitle
          title={<Trans>Delete</Trans>}
          iconProps={{ icon: AlertFullIcon, color: 'text-alert' }}
          onClose={onClose}
        />
      }
      footer={
        <ModalButtons>
          <Button className={styles.cancelButton} onClick={onClose}>
            <Trans>Cancel</Trans>
          </Button>

          <Button
            negative
            primary
            onClick={() => onDelete({ card: cardToDelete })}
            loading={inProgress}
          >
            <Trans>Delete</Trans>
          </Button>
        </ModalButtons>
      }
    >
      <Text type='h4'>
        <Trans>Are you ABSOLUTELY sure?</Trans>
      </Text>

      <Text type='f5'>
        <Trans>This action CANNOT be undone. This action will:</Trans>
      </Text>

      <ul>
        <Text tag='li'>
          <Trans>Permanently delete this task from your inbox</Trans>
        </Text>

        <Text tag='li'>
          <Trans>
            Permanently delete this task from the associated profile
          </Trans>
        </Text>

        <Text tag='li'>
          <Trans>Permanently delete this task from the tasks tab</Trans>
        </Text>
      </ul>
    </Modal>
  )
}

.react-app ._textContainer_1pcnc_1 {
  display: flex;
  gap: var(--space-tiny);
  align-items: center;
  width: fit-content;
  box-sizing: border-box;
}
.react-app ._textContainer_1pcnc_1 button {
  display: none;
}
.react-app ._textContainer_1pcnc_1:hover button {
  display: block;
}

.react-app ._pageHead_1pcnc_15 {
  box-sizing: border-box;
}

.react-app ._embedded_1pcnc_19 {
  min-height: 55px;
}
import React, { useState } from 'react'

export const WizardDataContext = React.createContext()
export const SetWizardDataContext = React.createContext()

export const wizardInitialData = {
  enable_experience: true,
  references_enabled: true
}

export const WizardDataProvider = ({ children }) => {
  const [wizardData, setWizardData] = useState(wizardInitialData)

  return (
    <WizardDataContext.Provider value={wizardData}>
      <SetWizardDataContext.Provider value={setWizardData}>
        {children}
      </SetWizardDataContext.Provider>
    </WizardDataContext.Provider>
  )
}

export const useWizardData = () => React.useContext(WizardDataContext)

export const useSetWizardData = () => {
  return React.useContext(SetWizardDataContext)
}

export default WizardDataContext

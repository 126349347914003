import React from 'react'
import { Trans } from 'react-i18next'

import {
  AlertFullIcon,
  Button,
  Modal,
  ModalButtons,
  ModalTitle,
  Text
} from '@fullfabric/alma-mater'

import styles from './styles.module.scss'

const ArchiveCourseModal = ({ inProgress, onArchive, isOpen, onClose }) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size='medium'
      header={
        <ModalTitle
          title={<Trans>Archive course</Trans>}
          iconProps={{ icon: AlertFullIcon, color: 'text-alert' }}
          onClose={onClose}
        />
      }
      footer={
        <ModalButtons>
          <Button className={styles.cancelButton} onClick={onClose}>
            <Trans>Cancel</Trans>
          </Button>
          <Button negative primary onClick={onArchive} loading={inProgress}>
            <Trans>I understand the consequences, archive this course</Trans>
          </Button>
        </ModalButtons>
      }
    >
      <Text type='h4'>
        <Trans>Are you sure?</Trans>
      </Text>
      <Text type='f5'>
        <Trans>This action will:</Trans>
      </Text>
      <ul>
        <Text tag='li'>
          <Trans>
            Archive the course and hide it from application templates, lifecycle
            workflows and the product performance report
          </Trans>
        </Text>
      </ul>
    </Modal>
  )
}

export default ArchiveCourseModal

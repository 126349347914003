import React from 'react'

import { Text } from '@fullfabric/alma-mater'

import smileImage from './smile.svg'

import styles from './styles.module.scss'

const NothingToMergeCard = ({ title, description }) => {
  return (
    <div className={styles.nothingToMergeCardContainer}>
      <img src={smileImage} alt='smile' />
      <Text type='h4' fontColor='text-base-darkest' marginTop='default'>
        {title}
      </Text>
      <Text marginTop='tiny'>{description}</Text>
    </div>
  )
}

export default NothingToMergeCard

import React from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { Table, Text } from '@fullfabric/alma-mater'

const EvaluationNameCell = ({ data, row }) => {
  const { pathname } = useLocation()
  const { t } = useTranslation()

  const rowData = data[row.index]
  const evaluationId = rowData.evaluation._id
  const evaluationName = rowData.evaluation.name
  const isTeamEvaluation = rowData.evaluation.team_evaluation

  return (
    <>
      <Table.CellLink main href={`${pathname}/${evaluationId}`} target='_top'>
        {evaluationName}
      </Table.CellLink>

      <Text tag='div' marginTop='small' type='f6'>
        {isTeamEvaluation ? t('Team evaluation') : t('Individual evaluation')}
      </Text>
    </>
  )
}

export default EvaluationNameCell

import 'css-chunk:src/apps/ContentPages/components/navigation/footer/email-validation/styles.module.scss';export default {
  "react-app": "react-app",
  "info": "_info_9pzdu_1",
  "link": "_link_9pzdu_15",
  "open": "_open_9pzdu_26",
  "tablet": "_tablet_9pzdu_43",
  "resendStateContainer": "_resendStateContainer_9pzdu_50",
  "icon": "_icon_9pzdu_56",
  "mobile": "_mobile_9pzdu_60",
  "textContainer": "_textContainer_9pzdu_64",
  "menuToggle": "_menuToggle_9pzdu_71",
  "accordionContent": "_accordionContent_9pzdu_84",
  "resendButton": "_resendButton_9pzdu_88",
  "alertCol": "_alertCol_9pzdu_92",
  "textRow": "_textRow_9pzdu_105",
  "arrowSmall": "_arrowSmall_9pzdu_113",
  "tippy": "_tippy_9pzdu_120"
};
import React from 'react'

const ColumnsIcon = ({ className }) => {
  return (
    <svg
      width='12'
      height='12'
      viewBox='0 0 12 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.99985 0C11.1044 0 11.9999 0.895443 11.9999 2.00002L11.9998 10C11.9998 11.1046 11.1043 12 9.99976 12H1.99976C0.895181 12 -0.000252069 11.1046 -0.000244141 9.99999L-0.000186721 1.99999C-0.000178794 0.895422 0.895247 0 1.99981 0H9.99985ZM6.99999 9.8V2.2C6.99999 2.08954 6.92537 2 6.83332 2H5.16665C5.0746 2 4.99999 2.08954 4.99999 2.2V9.8C4.99999 9.91046 5.0746 10 5.16665 10H6.83332C6.92537 10 6.99999 9.91046 6.99999 9.8ZM2.19999 2H3.79999C3.91044 2 3.99999 2.08954 3.99999 2.2V9.8C3.99999 9.91046 3.91044 10 3.79999 10H2.19999C2.08953 10 1.99999 9.91046 1.99999 9.8V2.2C1.99999 2.08954 2.08953 2 2.19999 2ZM8.19999 10H9.79999C9.91044 10 9.99999 9.91046 9.99999 9.8V2.2C9.99999 2.08954 9.91044 2 9.79999 2H8.19999C8.08953 2 7.99999 2.08954 7.99999 2.2V9.8C7.99999 9.91046 8.08953 10 8.19999 10Z'
        fill='#6A6D77'
      />
    </svg>
  )
}

export default ColumnsIcon

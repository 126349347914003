import React from 'react'

import FormPageContent from 'apps/ContentPages/components/form-page-content'
import FormTitle from 'apps/ContentPages/components/form-page-title'
import LandingPageHeader from 'apps/ContentPages/components/landing-page-header'
import LandingPageLogo from 'apps/ContentPages/components/landing-page-logo'
import PageLayout from 'apps/ContentPages/components/page-layout'

export default function FormWithoutContent() {
  return (
    <PageLayout type='action'>
      <LandingPageHeader>
        <LandingPageLogo />
      </LandingPageHeader>

      <FormTitle />

      <FormPageContent />
    </PageLayout>
  )
}

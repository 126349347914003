.react-app ._modal_hur1u_1 p {
  margin: 0;
}

.react-app ._modalBody_hur1u_5 {
  margin-bottom: var(--space-small) !important;
  overflow-y: visible !important;
}

.react-app ._choiceDropdownChild_hur1u_10 {
  margin-top: calc(-1 * var(--space-small));
}
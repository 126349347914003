{
  "Analytical": "Analytische cookies",
  "Strictly necessary": "Strikt noodzakelijke cookies",
  "Functional": "Functionele cookies",
  "Advertising": "Advertentiecookies",
  "Submit": "Verzenden",
  "Thank you": "Dank u wel",
  "You have confirmed your marketing preferences.": "U heeft uw marketingvoorkeuren bevestigd.",
  "You did not accept the Privacy Policy.": "U heeft het privacybeleid niet geaccepteerd.",
  "You must contact us to delete your data.": "U moet contact met ons opnemen om uw data te laten verwijderen.",
  "Change privacy policy agreement": "Verander de privacybeleidovereenkomst",
  "Privacy Policy": "Privacybeleid",
  "Next: Update marketing preferences": "Volgende: marketingvoorkeuren bijwerken",
  "I Accept the Privacy Policy": "Ik accepteer het privacybeleid",
  "I DO NOT accept the Privacy Policy": "Ik accepteer het privacybeleid niet",
  "If you do not accept the privacy policy, you must contact the data processor to delete your data.": "Als u het privacybeleid niet accepteert, moet u contact opnemen met de gegevensverwerker om uw gegevens te verwijderen.",
  "You have updated your marketing preferences.": "U heeft uw marketingvoorkeuren bijgewerkt.",
  "Log in": "Inloggen",
  "Finish your registration": "Voltooi uw registratie",
  "Marketing Policy": "Communicatiebeleid",
  "I would like to receive information by Email": "Ik wil graag informatie ontvangen per e-mail",
  "I would like to receive information by Phone": "Ik wil graag informatie ontvangen via de telefoon",
  "I would like to receive information by SMS": "Ik wil graag informatie ontvangen via SMS",
  "I would like to receive information by Mail": "Ik wil graag informatie ontvangen per post",
  "I do not want to be contacted with this type of information": "Ik wens liever geen informatie te ontvangen",
  "Cookie Policy": "Cookiebeleid",
  "Back to Portal": "Terug naar het portaal",
  "Manage your preferences": "Beheer uw voorkeuren",
  "Save cookie preferences": "Sla cookie-voorkeuren op",
  "You accepted our privacy policy on our website - {{institutionHost}} - on {{date}}": "U heeft ons privacybeleid voor onze website geaccepteerd - {{institutionHost}} - op {{date}}",
  "You are receiving this email because you accepted our marketing policy on our website - {{institutionHost}} - on  {{date}}": "U heeft deze e-mail ontvangen omdat u ons communicatiebeleid op onze website heeft geaccepteerd - {{institutionHost}} - op {{date}}",
  "You accepted our privacy policy when creating an account on {{date}}": "U hebt ons privacybeleid aanvaard toen u een account aanmaakte op {{date}}",
  "You accepted our privacy policy on {{date}} from our email": "U hebt ons privacybeleid aanvaard op {{date}} vanuit onze e-mail",
  "You are receiving this email because you accepted our marketing policy on {{date}} from our email": "U ontvangt deze e-mail omdat u ons communicatiebeleid op {{date}} hebt geaccepteerd vanuit onze e-mail",
  "You are receiving this email because a person created a profile for you and obtained your consent from {{consentSource}} on {{date}}": "U ontvangt deze e-mail omdat iemand een profiel voor u heeft aangemaakt en uw toestemming heeft verkregen via \"{{consentSource}}\" op {{date}}",
  "You accepted our privacy policy when submitting the {{entity}} form on {{date}}": "Door het inzenden van het formulier \"{{entity}}\" op {{date}} hebt u ons privacybeleid geaccepteerd",
  "You accepted our marketing policy when submitting the {{entity}} form on {{date}}": "Door het inzenden van het formulier \"{{entity}}\" op {{date}} hebt u ons marketingbeleid geaccepteerd",
  "You accepted our privacy policy when registering for the {{entity}} event on {{date}}": "Door u te registreren voor het evenement \"{{entity}}\" op {{date}} hebt u ons privacybeleid geaccepteerd",
  "You accepted our marketing policy when registering for the {{entity}} event on {{date}}": "Door u te registreren voor het evenement \"{{entity}}\" op {{date}} hebt u ons marketingbeleid geaccepteerd",
  "Unsubscribe": "Uitschrijven",
  "Yes, unsubscribe": "Ja, schrijf me uit",
  "No, stay subscribed": "Nee, blijf geabonneerd",
  "Manage marketing preferences": "Beheer marketingvoorkeuren",
  "Manage preferences for <0>{{policyName}}</0>": "Beheer voorkeuren voor <0>{{policyName}}</0>",
  "Unsubscribe successful": "Afmelden succesvol",
  "You are still subscribed": "Je bent nog steeds geabonneerd",
  "Back to homepage": "Terug naar de startpagina",
  "Are you sure you want to unsubscribe from the <0>{{topicName}}</0> topic?": "Weet je zeker dat je je wilt afmelden voor het onderwerp <0>{{topicName}}</0>?",
  "You have been unsubscribed from the <0>{{topicName}}</0> topic.": "Je bent afgemeld voor het onderwerp <0>{{topicName}}</0>.",
  "You have not been unsubscribed from the <0>{{topicName}}</0> topic.": "Je bent niet afgemeld voor het onderwerp <0>{{topicName}}</0>."
}

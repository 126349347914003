import React from 'react'
import { useTranslation } from 'react-i18next'

import { BoxShadow, Tabs } from '@fullfabric/alma-mater'
import {
  AuthorizationOfficer,
  useSettings
} from '@fullfabric/authorization-officer'

import ApplicationsTab from './ApplicationsTab'
import JourneysTab from './JourneysTab'
import ProfileInfoTab from './ProfileInfoTab'
import SummaryHeader from './SummaryHeader'

import styles from './styles.module.scss'

const MergeInfoSummary = () => {
  const { t } = useTranslation()
  const settings = useSettings()
  const applicsEnabled = AuthorizationOfficer.hasModule('applics', settings)

  const tabs = applicsEnabled ? (
    <Tabs>
      <Tabs.Panel id='infoTab' label={t('Profile info')}>
        <ProfileInfoTab />
      </Tabs.Panel>

      <Tabs.Panel id='applicsTab' label={t('Applications')}>
        <ApplicationsTab />
      </Tabs.Panel>

      <Tabs.Panel id='journeysTab' label={t('Journeys')}>
        <JourneysTab />
      </Tabs.Panel>
    </Tabs>
  ) : (
    <Tabs>
      <Tabs.Panel id='infoTab' label={t('Profile info')}>
        <ProfileInfoTab />
      </Tabs.Panel>

      <Tabs.Panel id='journeysTab' label={t('Journeys')}>
        <JourneysTab />
      </Tabs.Panel>
    </Tabs>
  )

  return (
    <BoxShadow
      rounded
      type='2'
      bgColor='base-lightest'
      className={styles.container}
    >
      <SummaryHeader />

      {tabs}
    </BoxShadow>
  )
}

export default MergeInfoSummary

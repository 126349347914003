import React, { useContext } from 'react'
import { useQuery } from 'react-query'

import getCoursesForStudyPlan from 'apps/Applics//api/getCoursesForStudyPlan'
import getStudyPlan from 'apps/Applics//api/getStudyPlan'
import { useClassOfData } from './ClassOfData'

const StudyPlanDataContext = React.createContext()
const CoursesDataContext = React.createContext()

const StudyPlanDataProvider = ({ children }) => {
  const classOf = useClassOfData()
  const { data: studyPlan } = useQuery(
    ['studyPlan', classOf?.study_plan_id],
    getStudyPlan,
    {
      enabled: Boolean(classOf?.study_plan_id),
      suspense: true
    }
  )
  const { data: courses } = useQuery(
    ['coursesForStudyPlan', classOf?.study_plan_id],
    getCoursesForStudyPlan,
    {
      enabled: Boolean(classOf?.study_plan_id),
      suspense: true
    }
  )

  return (
    <StudyPlanDataContext.Provider value={studyPlan}>
      <CoursesDataContext.Provider value={courses}>
        {children}
      </CoursesDataContext.Provider>
    </StudyPlanDataContext.Provider>
  )
}

const useStudyPlanData = () => useContext(StudyPlanDataContext)

const useCoursesData = () => useContext(CoursesDataContext)

export {
  StudyPlanDataProvider,
  useStudyPlanData,
  useCoursesData,
  StudyPlanDataContext,
  CoursesDataContext
}

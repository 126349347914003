import React from 'react'
import { Trans } from 'react-i18next'

import { ArrowLeftIcon, PageHead } from '@fullfabric/alma-mater'

import { HOME_ROUTE } from 'apps/Subjects/components/AppRoutes'

const Header = ({ title }) => (
  <PageHead
    title={title || <Trans>Loading</Trans>}
    breadcrumbs={[
      {
        label: <Trans>Back</Trans>,
        icon: ArrowLeftIcon,
        to: HOME_ROUTE
      }
    ]}
  />
)

export default Header

import React from 'react'
import { Trans } from 'react-i18next'
import { useQuery } from 'react-query'

import { compact } from 'lodash-es'
import PropTypes from 'prop-types'

import api from 'apps/ContentPages/api'
import WidgetSection from 'apps/ContentPages/components/widget-section'
import WidgetTitle from '../../title'
import Empty from './empty'
import Event from './event'
import Placeholder from './placeholder'

export default function UpcomingEvents({ widget }) {
  const selectedEvents = compact(widget.events_ids || [])

  const { data: upcomingEvents, status } = useQuery(
    ['upcoming-events', selectedEvents],
    () => api.getUpcomingEvents(selectedEvents)
  )
  const sortedEvents = upcomingEvents
    ? [...upcomingEvents].sort((a, b) => a.start - b.start)
    : []

  const id = sortedEvents?.map((o) => o.id).join('-') || 'upcoming-events'

  return (
    <section aria-labelledby={`widget-title-${id}`}>
      <WidgetTitle id={id} title={<Trans>Upcoming Events</Trans>} />

      {status !== 'success' ? (
        <WidgetSection single>
          <Placeholder />
        </WidgetSection>
      ) : selectedEvents?.length > 0 && sortedEvents.length > 0 ? (
        sortedEvents.map((event, index) => (
          <WidgetSection key={event.id} last={index === widget.length - 1}>
            <Event data={event} />
          </WidgetSection>
        ))
      ) : (
        <Empty />
      )}
    </section>
  )
}

UpcomingEvents.propTypes = {
  widget: PropTypes.shape({
    events_ids: PropTypes.arrayOf(PropTypes.string).isRequired
  }).isRequired
}

import React from 'react'
import { Route, Switch, useLocation } from 'react-router'

import qs from 'qs'

import { TemplateContextProvider } from 'apps/Applics/contexts/TemplateContextContext'
import TemplateWizardPage from 'apps/Applics/pages/TemplateWizardPage'

const ApplicTemplatesAppRoutes = () => {
  const { search } = useLocation()
  const searchParams = qs.parse(search, { ignoreQueryPrefix: true })

  return (
    <TemplateContextProvider
      context={searchParams.context_type}
      contextId={searchParams.context_id}
      parentContextId={searchParams.context_parent_id}
    >
      <Switch>
        <Route path='/templates/new' component={TemplateWizardPage} />
      </Switch>
    </TemplateContextProvider>
  )
}

export default ApplicTemplatesAppRoutes

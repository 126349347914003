import React, { forwardRef, useCallback, useState } from 'react'

import FullScreenLoading from 'shared/components/FullScreenLoading'

import classNames from 'classnames'
import styles from './styles.module.scss'

export default forwardRef(function ClassicEditor({ id }, ref) {
  const [loadingEditor, setLoadingEditor] = useState(true)

  const onLoad = useCallback(() => {
    // Hide the loading spinner after a short delay to prevent flickering
    setTimeout(() => setLoadingEditor(false), 500)
  }, [])

  return (
    <div className={styles.editorContainer}>
      {loadingEditor && (
        <div className={styles.loadingContainer}>
          <FullScreenLoading />
        </div>
      )}
      <iframe
        src={`/email_templates/${id}/classic_editor?iframe=true#${id}/classic_editor`}
        title='Email Template'
        onLoad={onLoad}
        className={classNames(styles.iframe, {
          [styles.hideEditor]: loadingEditor
        })}
        ref={ref}
      />
    </div>
  )
})

import React from 'react'

import { usePaymentsDrawerData } from 'apps/ContentPages/contexts/payments-drawer'
import FlywirePayment from './flywire-payment'
import StripePayment from './stripe-payment'

const FeePayment = () => {
  const { paymentPlan = {} } = usePaymentsDrawerData()
  const { payment_portal: paymentPortal } = paymentPlan

  const { type } = paymentPortal

  if (/stripe/i.test(type)) {
    return <StripePayment />
  }

  return <FlywirePayment paymentPortal={paymentPortal} />
}

export default FeePayment

import React from 'react'

import ApplicPerformancePage from 'apps/Reports/components/ApplicPerformancePage'
import useChangeDocumentTitle from 'apps/Reports/hooks/useChangeDocumentTitle'

const ApplicationPerformance = () => {
  useChangeDocumentTitle('Application performance report')

  return <ApplicPerformancePage />
}

export default ApplicationPerformance

import React from 'react'
import { useTranslation } from 'react-i18next'

import { ArrowLeftIcon, PageHead } from '@fullfabric/alma-mater'

import TitleAndSubtitle from 'apps/Reports/components/TitleWithSubtitle'

const Head = ({ classId, classData }) => {
  const { t } = useTranslation()

  return (
    <PageHead
      noBorder
      breadcrumbs={[
        {
          label: t('Back to class'),
          icon: ArrowLeftIcon,
          href: `/classes/${classId}`,
          target: '_parent'
        }
      ]}
      title={
        <TitleAndSubtitle
          isLoading={!classData}
          subtitle={`${classData?.programme_name} ${classData?.name}`}
          title={t('Product conversion')}
        />
      }
    />
  )
}

export default Head

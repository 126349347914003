import { isNil } from 'lodash'
import qs from 'qs'

import { utils } from '@fullfabric/public-api'

export default async function getTeams({
  limit,
  offset,
  'sort[field]': sortField,
  'sort[direction]': sortDirection
} = {}) {
  const query = {}

  if (!isNil(sortField)) {
    query.sort = { field: sortField }
    if (!isNil(sortDirection)) query.sort.direction = sortDirection
  }

  if (!isNil(limit)) query.limit = limit
  if (!isNil(offset)) query.offset = offset

  const queryString = qs.stringify(query, { arrayFormat: 'brackets' })
  const response = await fetch(`/api/teams?${queryString}`, {
    credentials: 'same-origin'
  })

  const teams = await utils.checkResponse(response)
  const headers = response.headers.get('x-fullfabric-json')

  return { teams, ...JSON.parse(headers) }
}

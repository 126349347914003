import React from 'react'

import { useScreenClass } from '@fullfabric/alma-mater'

import LoadingPlaceholderBlock from 'apps/ContentPages/components/loading-placeholder-block'
import CustomTextContent from '../custom-text-content'

import styles from './styles.module.scss'

export default function PageMainTextContent({
  isLoading,
  children: contentAsHtml
}) {
  const screenClass = useScreenClass()
  const isXl = screenClass === 'xl'

  return (
    <div className={styles.container}>
      {isLoading ? (
        <>
          <LoadingPlaceholderBlock
            data-testid='text-content-placeholder'
            className={styles.textPlaceholder}
          />
          <LoadingPlaceholderBlock className={styles.textPlaceholder} />
          <LoadingPlaceholderBlock className={styles.textPlaceholder} />
        </>
      ) : (
        <CustomTextContent
          htmlContent={contentAsHtml}
          type={isXl ? 'f3' : 'f4'}
          fontColor='text-base-darkest'
          data-testid='info-content'
        />
      )}
    </div>
  )
}

import React from 'react'

import LoadingPlaceholderBlock from 'apps/ContentPages/components/loading-placeholder-block'

import styles from './styles.module.scss'

const Placeholder = () => (
  <>
    <LoadingPlaceholderBlock className={styles.titlePlaceholder} />
    <LoadingPlaceholderBlock className={styles.datePlaceholder} />
    <LoadingPlaceholderBlock className={styles.linkPlaceholder} />
  </>
)

export default Placeholder

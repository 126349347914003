import React from 'react'

import BackToSummaryButton from './components/back-to-summary-button'
import NextStepButton from './components/next-step-button'
import PreviousStepButton from './components/previous-step-button'

const ImportActions = () => {
  return (
    <>
      <PreviousStepButton />
      <NextStepButton />
      <BackToSummaryButton />
    </>
  )
}

export default ImportActions

import React from 'react'

import { useProfileSchema } from 'apps/MergeProfiles/contexts/ProfileSchema'
import SummaryFieldSection from './SummaryFieldSection'

import styles from '../styles.module.scss'

const ProfileInfoTab = () => {
  const { data: schema } = useProfileSchema()
  const sortedSections = schema.sections.sort((a, b) => a.order - b.order)

  return (
    <div className={styles.summaryFieldSectionsContainer}>
      {sortedSections.map((section) => (
        <SummaryFieldSection key={section.id} section={section} />
      ))}
    </div>
  )
}

export default ProfileInfoTab

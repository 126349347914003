import React from 'react'

import { useAppData } from 'shared/contexts/AppData'

import PageTitle from '../page-title'

const EventTitle = () => {
  const { event } = useAppData()
  const eventTitle = event?.name

  return <PageTitle>{eventTitle}</PageTitle>
}

export default EventTitle

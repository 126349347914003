/* eslint-disable camelcase */

import React from 'react'
import { Trans } from 'react-i18next'
import { useMutation } from 'react-query'
import { useHistory, useLocation } from 'react-router'

import qs from 'qs'

import { Button, Col, Link, Row, Text } from '@fullfabric/alma-mater'
import * as api from '@fullfabric/frontend-api'

import { useAppData } from 'shared/contexts/AppData'
import getLocalizedText from 'shared/utils/getLocalizedText'

import { useSelectedLocale } from 'apps/Policies/contexts/SelectedLocale'
import { useUpdateMarketingPolicyAcceptance } from 'apps/Policies/contexts/UserPoliciesData'
import { DEFAULT_MARKETING_OPTIONS } from 'apps/Policies/utils/constants'
import getRelevantMarketingOptions from 'apps/Policies/utils/getRelevantMarketingOptions'
import { ReactComponent as ChevronRightIcon } from './chevron_right.svg'

import styles from './styles.module.scss'

const EditMarketingPolicyTopicsPage = () => {
  const {
    topic,
    profile,
    communication_policies_acceptances: commPoliciesAcceptances,
    communication_policies: commPolicyVersions
  } = useAppData()

  const communicationPolicyVersion = commPolicyVersions.find(
    (pol) => pol.policy.id === topic.policy_id
  )

  const communicationPolicy = communicationPolicyVersion.policy

  const communicationPolicyAcceptance = commPoliciesAcceptances.find(
    (acc) => acc.policy.id === communicationPolicyVersion.id
  )
  const locale = useSelectedLocale()

  const { search } = useLocation()
  const history = useHistory()
  const updateMarketingPolicyAcceptance = useUpdateMarketingPolicyAcceptance()

  const { mutate: onUnsubscribeClick, isLoading: isUnsubscribing } =
    useMutation(
      async () => {
        const agreementOptions = getRelevantMarketingOptions(
          communicationPolicyAcceptance && communicationPolicyAcceptance.options
        )

        const params = qs.parse(search.substr(1))

        const { none, ...options } = agreementOptions

        const data = {
          acceptor_type: 'Profiles::Profile',
          acceptor_id: profile.id,
          source_type: 'Policies::Source::Campaign',
          policies: {
            [communicationPolicyVersion.id]: {
              accepted: 'true',
              topics: {
                ...(communicationPolicyAcceptance?.topics || {}),
                [topic.id]: false
              },
              options: {
                ...DEFAULT_MARKETING_OPTIONS,
                ...options,
                ...params
              }
            }
          }
        }

        return await api.createPolicyAcceptance(data)
      },
      {
        onSuccess: (result) => {
          updateMarketingPolicyAcceptance(result[0])

          history.push({
            pathname: `/policies/user/${profile.id}/marketing/topic/${topic.id}/unsubscribed`,
            search
          })
        }
      }
    )

  const onKeepClick = React.useCallback(() => {
    history.push(
      `/policies/user/${profile.id}/marketing/topic/${topic.id}/keep`
    )
  }, [history, profile.id, topic.id])

  const onManageClick = React.useCallback(() => {
    history.push(
      `/policies/user/${profile.id}/marketing/${communicationPolicy.id}/edit`
    )
  }, [history, profile.id, communicationPolicy.id])

  const topicName = getLocalizedText(topic.name, locale, 'text')
  const inProgress = isUnsubscribing

  const policyName = communicationPolicy.main
    ? communicationPolicyVersion.name
    : getLocalizedText(communicationPolicy.locales, locale, 'html')

  return (
    <Row>
      <Col xs={12}>
        <Text type='h1' fontColor='text-base-darkest' data-testid='title'>
          <Trans>Unsubscribe</Trans>
        </Text>
      </Col>
      <Col xs={12}>
        <Text
          type='f4'
          fontColor='text-base-darkest'
          marginTop='small'
          marginBottom='default'
          data-testid='subtitle'
        >
          <Trans
            defaults='Are you sure you want to unsubscribe from the <0>{{topicName}}</0> topic?'
            values={{ topicName }}
            components={[
              <Text type='h4' tag='span'>
                {topicName}
              </Text>
            ]}
          />
        </Text>
      </Col>
      <Col xs={12}>
        <div className={styles.buttonContainer}>
          <Button negative onClick={onUnsubscribeClick} loading={inProgress}>
            <Trans>Yes, unsubscribe</Trans>
          </Button>
          <Button onClick={onKeepClick}>
            <Trans>No, stay subscribed</Trans>
          </Button>
        </div>
      </Col>
      <Col xs={12}>
        <Link
          secondary
          data-testid='manage'
          onClick={onManageClick}
          className={styles.manageLink}
        >
          {communicationPolicy.main ? (
            <Trans>Manage marketing preferences</Trans>
          ) : (
            <Trans
              defaults='Manage preferences for <0>{{policyName}}</0>'
              values={{ policyName }}
            />
          )}
          <ChevronRightIcon
            className='svgColor-grey-500'
            height={20}
            width={20}
          />
        </Link>
      </Col>
    </Row>
  )
}

export default EditMarketingPolicyTopicsPage

/* eslint-disable camelcase */

import React from 'react'
import { Trans } from 'react-i18next'
import { useMutation } from 'react-query'
import { useLocation, useParams } from 'react-router'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

import qs from 'qs'

import { CheckboxInput, Col, RadioInput } from '@fullfabric/alma-mater'
import * as api from '@fullfabric/frontend-api'

import { useAppData } from 'shared/contexts/AppData'
import getLocalizedText from 'shared/utils/getLocalizedText'

import PolicyAcceptanceDescription from 'apps/Policies/components/PolicyAcceptanceDescription'
import PolicyChoice from 'apps/Policies/components/PolicyChoice'
import { useSelectedLocale } from 'apps/Policies/contexts/SelectedLocale'
import { useUpdateMarketingPolicyAcceptance } from 'apps/Policies/contexts/UserPoliciesData'
import {
  DEFAULT_CHANNELS_ACTIVE,
  DEFAULT_MARKETING_OPTIONS,
  DEFAULT_MARKETING_OPTIONS_USER_SELECTION
} from 'apps/Policies/utils/constants'
import getRelevantMarketingOptions from 'apps/Policies/utils/getRelevantMarketingOptions'

import styles from './styles.module.scss'

const EditMarketingPolicyPage = ({ noAgreementOnStart = false }) => {
  const { search } = useLocation()
  const history = useHistory()
  const {
    marketing_policy: marketingPolicy,
    profile,
    communication_policies_acceptances: commPoliciesAcceptances,
    communication_policies: commPolicies
  } = useAppData()

  const { policy_id: policyId } = useParams()

  const communicationPolicyVersion = policyId
    ? commPolicies.find((pol) => pol.policy.id === policyId)
    : marketingPolicy

  const communicationPolicy = communicationPolicyVersion.policy

  const commPolicyAcceptance =
    communicationPolicy &&
    commPoliciesAcceptances.find(
      (acc) => acc.policy.policy.id === communicationPolicy.id
    )

  const updateMarketingPolicyAcceptance = useUpdateMarketingPolicyAcceptance()
  const currentLocale = useSelectedLocale()

  const [agreement, setAgreement] = React.useState(() =>
    getRelevantMarketingOptions(
      commPolicyAcceptance && commPolicyAcceptance.options
    )
  )

  const { mutate: onSubmit, isLoading } = useMutation(
    async () => {
      const params = qs.parse((search || '').substring(1))

      const { none, ...options } = agreement
      const data = {
        acceptor_type: 'Profiles::Profile',
        acceptor_id: profile.id,
        source_type: 'Policies::Source::Campaign',
        policies: {
          [communicationPolicyVersion.id]: {
            accepted: 'true',
            options: { ...DEFAULT_MARKETING_OPTIONS, ...options, ...params }
          }
        }
      }

      return await api.createPolicyAcceptance(data)
    },
    {
      onSuccess: (result) => {
        updateMarketingPolicyAcceptance(result[0])

        history.push({
          pathname: `/policies/user/${profile.id}/marketing/accepted`,
          search
        })
      }
    }
  )

  const onOptionChange = React.useCallback(
    (channel, checked) => {
      const value = checked === 'on' ? true : checked
      const updatedAgreement = { ...agreement, [channel]: value.toString() }
      const updatedAgreementIncludingNone = {
        ...updatedAgreement,
        none: Object.values(getRelevantMarketingOptions(updatedAgreement))
          .every((channelAgreement) => channelAgreement === 'false')
          .toString()
      }

      setAgreement(updatedAgreementIncludingNone)
    },
    [setAgreement, agreement]
  )

  React.useEffect(() => {
    if (noAgreementOnStart) {
      setAgreement(DEFAULT_MARKETING_OPTIONS_USER_SELECTION)
    }
  }, [noAgreementOnStart, setAgreement])

  const policyHtml = getLocalizedText(
    (communicationPolicyVersion && communicationPolicyVersion.locales) || [],
    currentLocale,
    'html'
  )

  const policyName = communicationPolicy.main
    ? communicationPolicy.name
    : getLocalizedText(communicationPolicy.locales, currentLocale, 'html')

  const channels =
    (communicationPolicyVersion && communicationPolicyVersion.channels) || {}
  const allowedMarketingChannels = { ...DEFAULT_CHANNELS_ACTIVE, ...channels }

  const numberOfChannels = Object.keys(allowedMarketingChannels).reduce(
    (acc, name) => acc + Number(allowedMarketingChannels[name] === 'true'),
    0
  )

  const canSubmit = Object.keys(agreement || {}).reduce(
    (acc, channel) => acc || agreement[channel] === 'true',
    false
  )

  const Checkable = numberOfChannels === 1 ? RadioInput : CheckboxInput

  return (
    <PolicyChoice
      title={
        communicationPolicy.main ? <Trans>Marketing Policy</Trans> : policyName
      }
      lastAcceptanceText={
        <PolicyAcceptanceDescription policyType='marketing' />
      }
      policyHtml={policyHtml}
      canSubmit={canSubmit}
      onSubmit={onSubmit}
      inProgress={isLoading}
    >
      {allowedMarketingChannels.email ? (
        <Col xs={12}>
          <Checkable
            id='email'
            label={<Trans>I would like to receive information by Email</Trans>}
            checked={agreement && agreement.email === 'true'}
            className={styles.marketingOptionCheckbox}
            onChange={(checked) => onOptionChange('email', checked)}
          />
        </Col>
      ) : null}

      {allowedMarketingChannels.phone ? (
        <Col xs={12} className={styles.marketingOptionCol}>
          <Checkable
            id='phone'
            label={<Trans>I would like to receive information by Phone</Trans>}
            checked={agreement && agreement.phone === 'true'}
            className={styles.marketingOptionCheckbox}
            onChange={(checked) => onOptionChange('phone', checked)}
          />
        </Col>
      ) : null}

      {allowedMarketingChannels.sms ? (
        <Col xs={12} className={styles.marketingOptionCol}>
          <Checkable
            id='sms'
            label={<Trans>I would like to receive information by SMS</Trans>}
            checked={agreement && agreement.sms === 'true'}
            className={styles.marketingOptionCheckbox}
            onChange={(checked) => onOptionChange('sms', checked)}
          />
        </Col>
      ) : null}

      {allowedMarketingChannels.mail ? (
        <Col xs={12} className={styles.marketingOptionCol}>
          <Checkable
            id='mail'
            label={<Trans>I would like to receive information by Mail</Trans>}
            checked={agreement && agreement.mail === 'true'}
            className={styles.marketingOptionCheckbox}
            onChange={(checked) => onOptionChange('mail', checked)}
          />
        </Col>
      ) : null}

      <Col xs={12} className={styles.noCommsCheckbox}>
        <RadioInput
          id='noComms'
          checked={agreement && agreement.none === 'true'}
          name='acceptPrivacyPolicy'
          className={styles.marketingOptionCheckbox}
          label={
            <Trans>
              I do not want to be contacted with this type of information
            </Trans>
          }
          onChange={() =>
            setAgreement(DEFAULT_MARKETING_OPTIONS_USER_SELECTION)
          }
        />
      </Col>
    </PolicyChoice>
  )
}

export default EditMarketingPolicyPage

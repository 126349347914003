import React from 'react'

import { useLinkedApplications, useTemplate } from '../../template-info-context'
import StartApplication from './start-application'
import StartedApplication from './started-application'

const ApplicationActions = ({ contexts }) => {
  const template = useTemplate()
  const applications = useLinkedApplications() || []

  const submittedApplications = applications.filter(
    (application) => application.state === 'submitted'
  )
  const startedApplication = applications.find(
    (application) =>
      application.state === 'started' || application.state === 'pending_payment'
  ) // only should be possible to have one started / pending_payment application

  return startedApplication ? (
    <StartedApplication
      template={template}
      application={startedApplication}
      contexts={contexts}
    />
  ) : (
    <StartApplication
      hasSubmittedApplications={submittedApplications.length > 0}
      template={template}
      contexts={contexts}
    />
  )
}

export default ApplicationActions

import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { PieChart } from 'react-minimal-pie-chart'

import { Container as Grid, Row, Text } from '@fullfabric/alma-mater'

import useFetchedImportData from 'apps/Imports/components/import-report-step/hooks/use-fetched-import-data'
import { IMPORT_TYPES } from 'apps/Imports/utils/constants'

import classNames from 'classnames'
import styles from './styles.module.scss'

const ReportChartSection = () => {
  const { t } = useTranslation()
  const { document } = useFetchedImportData()

  const isProfileImport = document._ruby_type === IMPORT_TYPES.PROFILE

  const total = document.number_of_rows || 0

  const created =
    (isProfileImport
      ? document.number_of_profiles_created
      : document.number_of_products_created) || 0
  const createdWithWarnings =
    (isProfileImport
      ? document.number_of_profiles_created_with_warnings
      : document.number_of_products_created_with_warnings) || 0
  const updated =
    (isProfileImport
      ? document.number_of_profiles_updated
      : document.number_of_products_updated) || 0
  const updatedWithWarnings =
    (isProfileImport
      ? document.number_of_profiles_updated_with_warnings
      : document.number_of_products_updated_with_warnings) || 0

  const errors =
    (isProfileImport
      ? document.number_of_errors
      : document.number_of_errors_product_import) || 0

  const pieChartData = []

  if (!total) return null

  if (errors) pieChartData.push({ value: errors, color: '#ed4747' })
  if (updated) pieChartData.push({ value: updated, color: '#0c4076' })
  if (created) pieChartData.push({ value: created, color: '#017ace' })

  return (
    <Grid className={styles.container}>
      <Row>
        <PieChart
          className={styles.chartContainer}
          startAngle={270}
          lineWidth={8}
          paddingAngle={1.6}
          lengthAngle={-360}
          animate
          data={pieChartData}
        >
          <div className={styles.chartInnerLegend}>
            <Text
              className={styles.chartInnerText}
              type='f2'
              fontColor='text-base-darkest'
            >
              {total}
            </Text>
            <Text className={styles.chartInnerText}>
              <Trans>RECORDS FOUND</Trans>
            </Text>
          </div>
        </PieChart>

        <div className={styles.legendContainer}>
          {created ? (
            <div className={styles.legendRow}>
              <span
                className={classNames(styles.rectangle, styles.blueBackground)}
              />
              <Text tag='span' fontColor='text-base-darkest'>
                {isProfileImport
                  ? t('{{count}} New profile created', {
                      count: created
                    })
                  : t('{{count}} New product created', {
                      count: created
                    })}
              </Text>
              {createdWithWarnings ? (
                <Text tag='span' className={styles.warningText}>
                  {t('{{count}} records with error', {
                    count: createdWithWarnings
                  })}
                </Text>
              ) : null}
            </div>
          ) : null}

          {updated ? (
            <div className={styles.legendRow}>
              <span
                className={classNames(
                  styles.rectangle,
                  styles.darkBlueBackground
                )}
              />
              <Text tag='span' fontColor='text-base-darkest'>
                {isProfileImport
                  ? t('{{count}} Existing profiles updated', {
                      count: updated
                    })
                  : t('{{count}} Existing products updated', {
                      count: updated
                    })}
              </Text>
              {updatedWithWarnings ? (
                <Text tag='span' className={styles.warningText}>
                  {t('{{count}} records with error', {
                    count: updatedWithWarnings
                  })}
                </Text>
              ) : null}
            </div>
          ) : null}

          {errors ? (
            <div className={styles.legendRow}>
              <span
                className={classNames(styles.rectangle, styles.redBackground)}
              />
              <Text tag='span' fontColor='text-alert'>
                {t('{{count}} records failed to import', { count: errors })}
              </Text>
            </div>
          ) : null}
        </div>
      </Row>
    </Grid>
  )
}

export default ReportChartSection

import React, { useState } from 'react'
import { Trans } from 'react-i18next'

import {
  Button,
  Modal,
  ModalButtons,
  ModalTitle,
  Text
} from '@fullfabric/alma-mater'

import { useLocale } from 'shared/contexts/Locale'
import getLocalizedText from 'shared/utils/getLocalizedText'

export default function ConfirmationModal({
  isOpen,
  onClose,
  template,
  onSubmit
}) {
  const { locale } = useLocale()
  const [isSubmitting, setIsSubmitting] = useState(false)

  const localizedModalTitle = getLocalizedText(
    template?.confirmation_title_locales,
    locale,
    'text',
    'en-GB'
  )

  const localizedModalMessage = {
    __html: getLocalizedText(
      template?.confirmation_message_locales,
      locale,
      'html',
      'en-GB'
    )
  }

  const localizedModalButton = getLocalizedText(
    template?.confirmation_button_locales,
    locale,
    'text',
    'en-GB'
  )

  return (
    <Modal
      role='dialog'
      aria-modal='true'
      aria-labelledby='confirmationModal'
      size='medium'
      isOpen={isOpen}
      header={
        <ModalTitle
          title={<span id='confirmationModal'>{localizedModalTitle}</span>}
          onClose={onClose}
        />
      }
      footer={
        <ModalButtons>
          <Button onClick={onClose}>
            <Trans>Cancel</Trans>
          </Button>
          <Button
            primary
            onClick={() => {
              setIsSubmitting(true)
              onSubmit()
            }}
            loading={isSubmitting}
          >
            {localizedModalButton}
          </Button>
        </ModalButtons>
      }
    >
      <Text
        fontColor='text-base-dark'
        dangerouslySetInnerHTML={localizedModalMessage}
        marginBottom='small'
      />
    </Modal>
  )
}

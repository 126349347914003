import { push } from 'connected-react-router'

import { submissionDataToFormData } from '@fullfabric/schemable-forms'

import createCourse from 'apps/Courses/api/createCourse'
import reduxStoreHelper from 'apps/Courses/utils/reduxStoreHelper'
import { EDIT_COURSE_ROUTE } from 'apps/Courses/utils/routeConstants'
import validateCreation from 'apps/Courses/utils/validateCreation'
import actionTypes from '../types'

export default function createCourseAction() {
  return async (dispatch, getState) => {
    const {
      newCourse: { data: storeData }
    } = getState()

    dispatch({ type: actionTypes.createCourse.start })

    try {
      validateCreation(storeData)

      const formattedStoreData =
        await reduxStoreHelper.storeDataToSubmissionFormat(storeData)

      const course = await createCourse(
        submissionDataToFormData(formattedStoreData)
      )
      dispatch({ type: actionTypes.createCourse.success, payload: course })
      if (storeData.create_new_subject) {
        dispatch({
          type: actionTypes.newSubjectCreated,
          payload: course.subject
        })
      }
      dispatch(push(EDIT_COURSE_ROUTE.replace(':id', course.id)))
    } catch (err) {
      dispatch({ type: actionTypes.createCourse.error, payload: err })
    }
  }
}

import React from 'react'
import { Trans } from 'react-i18next'
import { useParams } from 'react-router'

import { ArrowLeftIcon, PageHead } from '@fullfabric/alma-mater'

import { HOME_ROUTE } from 'apps/StudyPlans/components/AppRoutes/constants'
import AddSubjectButton from './AddSubjectButton'

const PageHeader = () => {
  const { classId } = useParams()

  return (
    <PageHead
      title={<Trans>Add Subject</Trans>}
      breadcrumbs={[
        {
          label: <Trans>Back to Study plan</Trans>,
          icon: ArrowLeftIcon,
          to: HOME_ROUTE.replace(':classId', classId)
        }
      ]}
      action={<AddSubjectButton />}
    />
  )
}

export default PageHeader

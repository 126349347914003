import getClassAttributeDistribution from './getClassAttributeDistribution'

const getClassAgeDistribution = async ({ dimension, ...params }) => {
  return await getClassAttributeDistribution({
    dimension: `age/${dimension}`,
    ...params
  })
}

export default getClassAgeDistribution

.react-app ._modal_1immh_1 p {
  margin: 0;
}

.react-app ._modalBody_1immh_5 {
  overflow: visible;
}

.react-app ._marginError_1immh_9 {
  margin-top: var(--space-tiny) !important;
}
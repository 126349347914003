import React from 'react'

import PropTypes from 'prop-types'

import { PlainButton } from '@fullfabric/alma-mater'

import { useCardFilters } from 'apps/Inbox/contexts/CardFilters'

export default function CardStatusLink({ label, status }) {
  const { cardStatus: selectedCardStatus, setCardStatus } = useCardFilters()

  return (
    <PlainButton
      active={selectedCardStatus === status}
      onClick={() =>
        setCardStatus(status === selectedCardStatus ? null : status)
      }
    >
      {label}
    </PlainButton>
  )
}

CardStatusLink.propTypes = {
  label: PropTypes.node.isRequired,
  status: PropTypes.string
}

CardStatusLink.defaultProps = {
  status: null
}

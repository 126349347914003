import 'css-chunk:src/shared/components/VirtualizedDataTable/VDataTable/styles.module.scss';export default {
  "react-app": "react-app",
  "headerRow": "_headerRow_1bsx7_1",
  "numericCell": "_numericCell_1bsx7_10",
  "cell": "_cell_1bsx7_14",
  "row": "_row_1bsx7_32",
  "clickable": "_clickable_1bsx7_38",
  "colHeaderItem": "_colHeaderItem_1bsx7_45",
  "loadingRowCell": "_loadingRowCell_1bsx7_51",
  "emptyRowCell": "_emptyRowCell_1bsx7_52",
  "errorRowCell": "_errorRowCell_1bsx7_53"
};
import { useState } from 'react'
import { useMutation } from 'react-query'

import { createExport } from 'shared/api/exports'
import usePusher from 'shared/hooks/usePusher'

export default function useExportCreate() {
  const [pusherExport, setPusherExport] = useState()

  const channel = usePusher({
    channelName: 'exports',
    binds: ['export:finished', 'export:failed'],
    onMessage: (_, data) => setPusherExport(data),
    opts: { tenant: true }
  })

  const bindToChannel = (xport) => {
    channel.bind(`export:${xport.id}:finished`, setPusherExport)
    channel.bind(`export:${xport.id}:failed`, setPusherExport)
  }

  const mutation = useMutation(
    ({ format, type, opts = {} }) => createExport(type, format, opts),
    { onSuccess: ({ export: xport }) => bindToChannel(xport) }
  )

  return {
    creating: mutation.isLoading,
    error: mutation.isError ? mutation.error : null,
    export: pusherExport || mutation.data?.export,
    createExport: mutation.mutate
  }
}

import { utils } from '@fullfabric/public-api'

const signApplication = async ({ applicId }) => {
  const response = await fetch(`/api/applications/${applicId}/start-signing`, {
    credentials: 'same-origin',
    headers: { 'Content-Type': 'application/json' },
    method: 'POST'
  })

  return await utils.checkResponse(response)
}

export default signApplication

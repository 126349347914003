const submitPolicyAcceptance = async ({
  policyId,
  agreement,
  userId,
  policyType
}) => {
  const data = {
    acceptor_type: 'Profiles::Profile',
    acceptor_id: userId,
    source_type: 'Policies::Source::Portal',
    policy_type: policyType,
    policies: {
      [policyId]: {
        accepted: true,
        options: agreement.options
      }
    }
  }

  return fetch('/policies/api/acceptances', {
    credentials: 'same-origin',
    headers: { 'Content-Type': 'application/json' },
    method: 'POST',
    body: JSON.stringify(data)
  }).then((result) => result.json())
}

export default submitPolicyAcceptance

import { useCallback, useMemo } from 'react'

import { useCurrentUser } from '@fullfabric/authorization-officer'

import { useStaffByTeams, useTeams } from 'shared/contexts/TeamsAndStaffContext'

export const sortOptionsAlphabetically = (a, b) => {
  const nameA = a.label?.toLowerCase()
  const nameB = b.label?.toLowerCase()

  if (nameA < nameB) {
    return -1
  }

  if (nameA > nameB) {
    return 1
  }

  return 0
}

const useCreatedByFilter = () => {
  const user = useCurrentUser()
  const userName = `${user?.first_name} ${user?.last_name}`
  const userId = user?.id

  const teams = useTeams()
  const teamsAsOptions = useMemo(
    () =>
      teams
        .map((t) => ({ label: t.name, value: t.id }))
        .sort(sortOptionsAlphabetically),
    [teams]
  )

  const staffByTeams = useStaffByTeams()
  const staffSectionedByTeams = useMemo(
    () =>
      teamsAsOptions.reduce((sections, teamOption) => {
        const staffInTheTeam = staffByTeams[teamOption.value]

        if (!staffInTheTeam?.length) {
          return sections
        }

        return [
          ...sections,
          {
            name: teamOption.label,
            options: staffInTheTeam
          }
        ]
      }, []),
    [teamsAsOptions, staffByTeams]
  )

  const quickFilters = useMemo(
    () => ({
      name: 'Quick filters',
      options: [
        {
          label: `Me (${userName})`,
          value: userId
        },
        { label: 'My teams', value: 'teams' },
        { label: 'Anyone', value: 'all' }
      ]
    }),
    [userName, userId]
  )

  const loadOptions = useCallback(async () => {
    return [
      quickFilters,
      {
        name: 'By team',
        options: teamsAsOptions
      },
      ...staffSectionedByTeams
    ]
  }, [quickFilters, staffSectionedByTeams, teamsAsOptions])

  return {
    id: 'created_by',
    name: 'Created by',
    type: 'single',
    defaultValue: 'teams',
    labelValueSeparator: '',
    searchField: 'label',
    loadOptions
  }
}

export default useCreatedByFilter

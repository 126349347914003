import React from 'react'

import FeedbackPopupMessage from '../FeedbackPopupMessage'

const SuccessPopupMessage = ({ isDisplayed, onAfterTimeout, message }) => {
  return (
    <FeedbackPopupMessage
      isDisplayed={isDisplayed}
      onAfterTimeout={onAfterTimeout}
      message={message}
      type='success'
    />
  )
}

export default SuccessPopupMessage

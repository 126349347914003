import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import moment from 'moment'

import { Badge, Text } from '@fullfabric/alma-mater'

import { useCurrencyFormatter } from 'shared/utils/formatCurrency'

import { STATES_MAP } from './constants'

const useTransactionColumns = () => {
  const { t } = useTranslation()
  const formatCurrency = useCurrencyFormatter()
  const sectionName = t('Transaction')

  return [
    {
      Header: <Trans>Name</Trans>,
      section: sectionName,
      accessor: 'profile.full_name',
      width: 250,
      type: 'primary',
      linkTo: ({ row, data }) => ({
        href: `/profiles/${data[row.index].profile_id}#financials/transactions`,
        target: '_parent'
      })
    },
    {
      Header: <Trans>Payer</Trans>,
      section: sectionName,
      accessor: 'payer',
      width: 250,
      Cell: ({ value, data, row }) => {
        if (data[row.index].gateway === 'Manual') return '-'
        if (!value) return 'Applicant'
        const payerName = value.name
        const payerEmail = value.email

        return (
          <>
            {payerName}
            <Text tag='div' marginTop='tiny' type='f6'>
              {payerEmail}
            </Text>
          </>
        )
      }
    },
    {
      Header: <Trans>Description</Trans>,
      section: sectionName,
      accessor: 'description',
      width: 300
    },
    {
      Header: <Trans>Date</Trans>,
      section: sectionName,
      accessor: 'transaction_date',
      width: 240,
      Cell: ({ value }) => {
        if (!value) return null
        const date = moment(value)

        return (
          <>
            {date.isValid() && date.format('dddd MMM D, Y [at] HH:mm')}
            <Text tag='div' marginTop='tiny' type='f6'>
              {date.isValid() && date.fromNow()}
            </Text>
          </>
        )
      }
    },
    {
      Header: <Trans>Amount</Trans>,
      section: sectionName,
      accessor: 'amount',
      width: 150,
      type: 'number',
      Cell: ({ value, row, data }) =>
        formatCurrency(value, data[row.index].currency)
    },
    {
      Header: <Trans>State</Trans>,
      section: sectionName,
      accessor: 'state',
      width: 150,
      Cell: ({ value }) => {
        const badgeForState = STATES_MAP[value]
        return (
          badgeForState && (
            <Badge type={badgeForState.color}>{badgeForState.label}</Badge>
          )
        )
      }
    },
    {
      Header: <Trans>Gateway</Trans>,
      section: sectionName,
      accessor: 'gateway',
      width: 150
    },
    {
      Header: <Trans>Reference</Trans>,
      section: sectionName,
      accessor: 'reference',
      width: 250
    }
  ]
}

export default useTransactionColumns

.react-app ._info_9pzdu_1 {
  padding: var(--space-default);
  background-color: var(--ff-bg-base-lightest);
  box-shadow: var(--shadow-3);
  border-radius: 4px;
  margin-left: calc(-1 * var(--space-small));
  margin-top: calc(-1 * var(--space-small));
  outline: none;
  border: none;
}
.react-app ._info_9pzdu_1 p {
  margin: 0;
}

.react-app ._link_9pzdu_15 {
  padding: var(--space-less) var(--space-default) var(--space-less) var(--space-more);
  display: flex;
  align-items: center;
  width: 100%;
  background-color: var(--ff-bg-alert-lightest);
}
.react-app ._link_9pzdu_15 svg path,
.react-app ._link_9pzdu_15 svg rect {
  fill: var(--ff-color-text-alert) !important;
}
.react-app ._link_9pzdu_15._open_9pzdu_26 {
  background-color: var(--ff-bg-alert);
}
.react-app ._link_9pzdu_15._open_9pzdu_26 svg path,
.react-app ._link_9pzdu_15._open_9pzdu_26 svg rect {
  fill: var(--ff-color-text-base-lightest) !important;
}
.react-app ._link_9pzdu_15:hover {
  background-color: var(--ff-bg-alert-light);
}
.react-app ._link_9pzdu_15:hover span {
  color: var(--ff-color-text-base-lightest) !important;
}
.react-app ._link_9pzdu_15:hover svg path,
.react-app ._link_9pzdu_15:hover svg rect {
  fill: var(--ff-color-text-base-lightest) !important;
}
.react-app ._link_9pzdu_15._tablet_9pzdu_43 {
  padding-left: var(--space-big);
  padding-right: var(--space-more);
  padding-top: var(--space-less);
  padding-bottom: var(--space-less);
}

.react-app ._resendStateContainer_9pzdu_50 {
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
}

.react-app ._icon_9pzdu_56 {
  height: 16px;
  margin-right: var(--space-small);
}
.react-app ._icon_9pzdu_56._mobile_9pzdu_60 {
  margin-right: var(--space-less);
}

.react-app ._textContainer_9pzdu_64 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.react-app ._menuToggle_9pzdu_71 {
  width: 100%;
  padding: 0;
  border: 0;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.react-app ._menuToggle_9pzdu_71:focus {
  outline: none;
  border: none;
}

.react-app ._accordionContent_9pzdu_84 {
  padding: var(--space-default);
}

.react-app ._resendButton_9pzdu_88 {
  margin: 0 !important;
}

.react-app ._alertCol_9pzdu_92 {
  display: flex;
  align-items: center;
  width: 100%;
}
.react-app ._alertCol_9pzdu_92 ._icon_9pzdu_56 {
  display: flex;
  margin-right: var(--space-small);
}
.react-app ._alertCol_9pzdu_92 a {
  display: contents;
}

.react-app ._textRow_9pzdu_105 {
  margin-top: var(--space-small);
  margin-bottom: var(--space-small);
}
.react-app ._textRow_9pzdu_105:first-child {
  margin-top: 0;
}

.react-app ._arrowSmall_9pzdu_113 {
  transform: rotate(90deg);
}
.react-app ._arrowSmall_9pzdu_113._open_9pzdu_26 {
  transform: rotate(-90deg);
}

.react-app ._tippy_9pzdu_120 {
  outline: 0;
}
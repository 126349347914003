import React from 'react'

import { SelectedLocaleProvider } from 'apps/Policies/contexts/SelectedLocale'
import { UserPoliciesDataProvider } from 'apps/Policies/contexts/UserPoliciesData'
import PolicyManagementPageLayout from '../PolicyManagementPageLayout'

const AppLayout = ({ children }) => {
  return (
    <UserPoliciesDataProvider>
      <SelectedLocaleProvider>
        <PolicyManagementPageLayout>{children}</PolicyManagementPageLayout>
      </SelectedLocaleProvider>
    </UserPoliciesDataProvider>
  )
}

export default AppLayout

import React from 'react'
import { useTranslation } from 'react-i18next'

import moment from 'moment'

import { usePermission } from '@fullfabric/authorization-officer'

import AutoloadDataTable from 'shared/components/AutoloadDataTable'

import MoreOptionsCell from './MoreOptionsCell'
import StatusCell from './StatusCell'

const ApplicationsUnderContractTable = ({ contractId }) => {
  const { t } = useTranslation()
  const hasPermission = usePermission('billing.billing_change')

  const defaultColumns = [
    {
      Header: t('Name'),
      accessor: 'profile_name',
      type: 'text'
    },
    {
      Header: t('Application'),
      accessor: 'template_name',
      type: 'text'
    },
    {
      Header: t('Submitted on'),
      accessor: 'submitted_on',
      type: 'text',
      Cell: ({ value }) => moment(value).format('DD MMM YYYY h:mm A')
    },
    {
      Header: t('Status'),
      Cell: StatusCell,
      disableSortBy: true
    }
  ]

  const columnsUnderPermission = hasPermission
    ? [
        {
          Header: '',
          id: 'more-options-col',
          Cell: (props) => (
            <MoreOptionsCell contractId={contractId} {...props} />
          ),
          disableSortBy: true,
          width: 60
        }
      ]
    : []

  return (
    <AutoloadDataTable
      fetchURL={`/billing/api/tabular_data/billing_applics/${contractId}`}
      loadMoreProps={{
        sizeKey: 'limit',
        offsetKey: 'offset'
      }}
      fetchResponseParser={(response) => {
        const pagination = response.headers['x-fullfabric-json']
          ? JSON.parse(response.headers['x-fullfabric-json'])
          : {}

        return { ...pagination, data: response.data }
      }}
      sortable
      sortParams={{
        sortKey: 'sort[field]',
        directionKey: 'sort[direction]',
        ascValue: 1,
        descValue: -1
      }}
      initialSort={{
        'sort[field]': 'submitted_on',
        'sort[direction]': -1
      }}
      emptyMessage={t('No data')}
      columns={[...defaultColumns, ...columnsUnderPermission]}
    />
  )
}

export default ApplicationsUnderContractTable

import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'

import {
  Button,
  Modal,
  ModalButtons,
  ModalTitle,
  TextInput
} from '@fullfabric/alma-mater'
import { useCurrentUser } from '@fullfabric/authorization-officer'
import { utils } from '@fullfabric/public-api'

import FeedbackPopupMessage from 'shared/components/FeedbackPopupMessage'

export default function SendTestEmailModal({ id, isOpen, onClose, context }) {
  const [t] = useTranslation()
  const { email } = useCurrentUser()
  const [emailValue, setEmailValue] = useState(email)
  const [feedbackMessage, setFeedbackMessage] = useState(null)

  const { mutate, isLoading } = useMutation({
    mutationFn: async () => {
      const response = await fetch(`/api/email_templates/${id}/send_test`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email: emailValue, context }),
        credentials: 'same-origin'
      })
      return utils.checkResponse(response)
    },
    onSuccess: () => {
      setFeedbackMessage({ type: 'success', message: t('Test email sent') })
      onClose()
    },
    onError: () => {
      setFeedbackMessage({
        type: 'error',
        message: t('Error sending test email, please try again')
      })
    }
  })

  return (
    <>
      <FeedbackPopupMessage
        isDisplayed={feedbackMessage !== null}
        onAfterTimeout={() => setFeedbackMessage(null)}
        message={feedbackMessage?.message}
        type={feedbackMessage?.type}
      />
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size='small'
        header={<ModalTitle title={t('Send test email')} />}
        footer={
          <ModalButtons>
            <Button primary size='small' loading={isLoading} onClick={mutate}>
              {t('Send test email')}
            </Button>
            <Button size='small' onClick={onClose}>
              {t('Cancel')}
            </Button>
          </ModalButtons>
        }
      >
        <TextInput
          value={emailValue}
          onChange={(ev) => setEmailValue(ev.target.value)}
        />
      </Modal>
    </>
  )
}

import React from 'react'
import { Trans } from 'react-i18next'

import { Button, Field } from '@fullfabric/alma-mater'

import styles from './styles.module.scss'

const ERROR_MESSAGES = {
  not_a_number: <Trans>Must be a number</Trans>
}

const CourseEditFormCapacity = ({
  data,
  inProgress,
  errors,
  onChange,
  onSaveClick,
  onCancelClick
}) => (
  <section aria-label='course capacity data editable'>
    <form aria-label='course capacity data form'>
      <Field
        id='minimum_capacity'
        type='text'
        label={<Trans>Minimum capacity</Trans>}
        error={ERROR_MESSAGES[errors.minimum_capacity]}
        help={
          <Trans>
            This is the minimum number of candidates that are required for
            running the course
          </Trans>
        }
        value={data.minimum_capacity || ''}
        onChange={onChange}
      />
      <Field
        id='maximum_capacity'
        type='text'
        label={<Trans>Maximum capacity</Trans>}
        error={ERROR_MESSAGES[errors.maximum_capacity]}
        help={
          <Trans>
            This is the maximum number of candidates that can attend the course
          </Trans>
        }
        value={data.maximum_capacity || ''}
        onChange={onChange}
      />
      <Field
        id='target_capacity'
        type='text'
        label={<Trans>Target capacity</Trans>}
        error={ERROR_MESSAGES[errors.target_capacity]}
        help={
          <Trans>
            This is the course's recruitment target defined as the ideal number
            of candidates that will attend the course
          </Trans>
        }
        value={data.target_capacity || ''}
        onChange={onChange}
      />
    </form>
    <div className='marginTop-default'>
      <Button primary size='small' loading={inProgress} onClick={onSaveClick}>
        <Trans>Save changes</Trans>
      </Button>
      <Button
        size='small'
        onClick={onCancelClick}
        className={styles.cancelButton}
      >
        <Trans>Cancel</Trans>
      </Button>
    </div>
  </section>
)

export default CourseEditFormCapacity

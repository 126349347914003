/* eslint-disable camelcase */

import { startCase } from 'lodash'

export const staffToOptions = ({ id, full_name }) => ({
  value: id,
  label: full_name
})

const stateIsIncludedIn =
  (states) =>
  ({ name }) =>
    states.includes(name)
const stateAndSubstateToOption = (state) => (substate) => ({
  value: `${state.name}::${substate.name}`,
  label: `${startCase(state.name)}::${startCase(substate.name)}`
})
const statesToOptions = (options, state) => [
  ...options,
  ...state.substates.map(stateAndSubstateToOption(state))
]

export const getStateOptions = (lifecycle, user) => {
  const allAllowedStates = ['prospect', 'applicant', 'student', 'alumnus']
  const fluidStates = user.is_admin ? allAllowedStates : lifecycle.fluid_states
  const availableStates = lifecycle.states
    .filter(stateIsIncludedIn(fluidStates))
    .filter(stateIsIncludedIn(allAllowedStates))

  const options = availableStates.reduce(statesToOptions, [])
  return options.length > 0
    ? options
    : [{ value: 'prospect::new', label: 'Prospect::New' }]
}

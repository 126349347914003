import React from 'react'

import { BoxShadow, Text } from '@fullfabric/alma-mater'

import styles from './styles.module.scss'

const CustomTooltip = ({
  mainDataName,
  customValueFormatter = (value, label) => [value, label],
  labelFormatter = (l) => l,
  label,
  payload
}) => (
  <BoxShadow type='3' rounded bgColor='white' className={styles.card}>
    <Text type='h6' fontColor='text-base-darker'>
      {labelFormatter(label)}
    </Text>
    {payload?.map(({ value, name }) => {
      const [formattedValue, formattedName] = customValueFormatter(value, name)

      return (
        <div className={styles.item} key={name}>
          <Text
            type='f4'
            fontColor={
              name === mainDataName ? 'text-primary' : 'text-base-darkest'
            }
            marginRight='tiny'
          >
            {`${formattedName}:`}
          </Text>
          <Text
            type='h4'
            fontColor={
              name === mainDataName ? 'text-primary' : 'text-base-darkest'
            }
          >
            {formattedValue}
          </Text>
        </div>
      )
    })}
  </BoxShadow>
)

export default CustomTooltip

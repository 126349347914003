import React from 'react'
import { Trans } from 'react-i18next'

import { mapValues } from 'lodash'

import {
  ArrowLeftIcon,
  Button,
  Col,
  PageHead,
  Row,
  Text
} from '@fullfabric/alma-mater'
import { useCurrentUser } from '@fullfabric/authorization-officer'
import { accept as acceptPolicy } from '@fullfabric/policies'

import { useAppData } from 'shared/contexts/AppData'
import getLocalizedText from 'shared/utils/getLocalizedText'

import CookieTypeCheckbox from 'apps/Policies/components/CookieTypeCheckbox'
import { useSelectedLocale } from 'apps/Policies/contexts/SelectedLocale'

import styles from './styles.module.scss'

const CookiePolicyPage = () => {
  const currentUser = useCurrentUser()
  const {
    cookies_policy: policy,
    cookies_policy_acceptance: policyAcceptance
  } = useAppData()

  const selectedLocale = useSelectedLocale()

  const [cookiesAcceptance, setCookiesAcceptance] = React.useState(
    mapValues(
      (policyAcceptance && policyAcceptance.options) || {},
      (val) => val === 'true'
    )
  )

  const handleCookieChanged = React.useCallback(
    (cookieType, value) => {
      setCookiesAcceptance((cookiesAcceptance) => ({
        ...cookiesAcceptance,
        [cookieType]: value
      }))
    },
    [setCookiesAcceptance]
  )

  const handleSubmitted = React.useCallback(async () => {
    await acceptPolicy(policy.id, {
      policyOptions: cookiesAcceptance,
      profileId: currentUser?.id
    })

    window.location.replace('/')
  }, [cookiesAcceptance, currentUser, policy])

  const policyHtml = getLocalizedText(
    (policy && policy.locales) || [],
    selectedLocale,
    'html'
  )

  const cookieTypes = Object.keys(policy.cookie_types).filter(
    (cookie) => policy.cookie_types[cookie]
  )

  return (
    <Row className={styles.cookiePolicy}>
      <Col xs={10}>
        <PageHead
          className={styles.pageHead}
          title={<Trans>Cookie Policy</Trans>}
          breadcrumbs={[
            {
              label: <Trans>Back to Portal</Trans>,
              icon: ArrowLeftIcon,
              href: '/'
            }
          ]}
        />

        <Text marginTop='tiny' marginBottom='big' fontColor='grey-400'>
          <Trans>Manage your preferences</Trans>
        </Text>

        <Text
          className={styles.cookiePolicyContent}
          marginBottom='big'
          dangerouslySetInnerHTML={{ __html: policyHtml }}
        ></Text>

        {cookieTypes.map((cookieType) => (
          <CookieTypeCheckbox
            checked={cookiesAcceptance[cookieType]}
            cookieType={cookieType}
            key={cookieType}
            onChange={handleCookieChanged}
          />
        ))}
      </Col>

      <Col xs={10}>
        <Button
          primary
          marginTop='big'
          marginBottom='huge'
          onClick={handleSubmitted}
        >
          <Trans>Save cookie preferences</Trans>
        </Button>
      </Col>
    </Row>
  )
}

export default CookiePolicyPage

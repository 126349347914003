import React from 'react'

import { Col, Row, Text } from '@fullfabric/alma-mater'

import styles from './styles.module.scss'

const CardContent = ({ title, children: content, actions }) => (
  <Row>
    <Col xs={12}>
      {title ? (
        <Text type='h5' marginBottom='tiny' className={styles.title}>
          {title}
        </Text>
      ) : null}
      {content}
      {actions ? (
        <div className={styles.actionsContainer}>{actions}</div>
      ) : null}
    </Col>
  </Row>
)

export default CardContent

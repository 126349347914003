import { utils } from '@fullfabric/public-api'

const duplicateStudyPlanFromClass = async ({
  fromClassOfId,
  toClassOfId,
  includeCourses
}) => {
  const response = await fetch(
    '/affairs/api/study_plans/duplicate_from_class',
    {
      credentials: 'same-origin',
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        from_class_of_id: fromClassOfId,
        to_class_of_id: toClassOfId,
        include_courses: includeCourses
      })
    }
  )

  return await utils.checkResponse(response)
}

export default duplicateStudyPlanFromClass

import React from 'react'

const ProductCatalogIcon = () => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M14 4C15.1046 4 16 4.89543 16 6V7C16 7.55228 15.5523 8 15 8C14.4477 8 14 7.55228 14 7V6H2V7C2 7.55228 1.55228 8 1 8C0.447715 8 0 7.55228 0 7V6C0 4.89543 0.895431 4 2 4H14Z'
        fill='#0170BD'
      />
      <path
        d='M14 0C15.1046 0 16 0.895431 16 2C16 2.55228 15.5523 3 15 3C14.4477 3 14 2.55228 14 2H2C2 2.55228 1.55228 3 1 3C0.447715 3 0 2.55228 0 2C0 0.895431 0.895431 0 2 0H14Z'
        fill='#0170BD'
      />
      <rect x='5' y='8' width='6' height='2' rx='1' fill='#0170BD' />
      <path
        d='M4 11C4 10.4477 3.55228 10 3 10H2C0.895431 10 0 10.8954 0 12V15C0 15.5523 0.447715 16 1 16H15C15.5523 16 16 15.5523 16 15V12C16 10.8954 15.1046 10 14 10H13C12.4477 10 12 10.4477 12 11C12 11.5523 11.5523 12 11 12H5C4.44772 12 4 11.5523 4 11Z'
        fill='#0170BD'
      />
    </svg>
  )
}

export default ProductCatalogIcon

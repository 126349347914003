import React from 'react'

import { INITIAL_PROFILE_IMPORT_STATE } from 'apps/Imports/utils/profile-import/constants'

export const SelectedDataContext = React.createContext({})

export const SelectedDataProvider = ({
  children,
  initialState = INITIAL_PROFILE_IMPORT_STATE
}) => {
  const [data, setData] = React.useState(initialState)
  const onDataChanged = React.useCallback((fieldName, value) => {
    setData((data) => ({ ...data, [fieldName]: value }))
  }, [])

  return (
    <SelectedDataContext.Provider value={{ data, setData, onDataChanged }}>
      {children}
    </SelectedDataContext.Provider>
  )
}

export const useSelectedData = () => React.useContext(SelectedDataContext)

import React from 'react'
import { Trans } from 'react-i18next'

import { Label, Text } from '@fullfabric/alma-mater'

import { useMergeData } from 'apps/MergeProfiles/contexts/MergeData'

import styles from './styles.module.scss'

const Header = () => {
  const {
    mergeData: {
      targetProfile,
      sourceProfile,
      profileInfoFrom,
      smartEmailInfoFrom
    }
  } = useMergeData()

  const firstName =
    profileInfoFrom.first_name === 'duplicate'
      ? sourceProfile?.first_name
      : targetProfile?.first_name
  const lastName =
    profileInfoFrom.last_name === 'duplicate'
      ? sourceProfile?.last_name
      : targetProfile?.last_name
  const fullName = [firstName, lastName].filter((x) => x).join(' ')

  const [emailFrom, emailField] = smartEmailInfoFrom.email?.split(':') || []
  const email =
    emailFrom === 'duplicate'
      ? sourceProfile && sourceProfile[emailField]
      : targetProfile && targetProfile[emailField || 'email']

  return (
    <div
      className={styles.summaryHeader}
      role='heading'
      aria-level='2'
      aria-label='profile summary'
    >
      <div>
        <Text type='f2' fontColor='text-base-darkest' marginBottom='tiny'>
          {fullName}
        </Text>

        <Text type='f4'>{email}</Text>
      </div>

      <Label color='green-700' large>
        <Trans>Final profile</Trans>
      </Label>
    </div>
  )
}

export default Header

import { utils } from '@fullfabric/public-api'

const removeApplicDiscount = async ({ discountId }) => {
  const response = await fetch(`/book_keeper/api/discounts/${discountId}`, {
    credentials: 'same-origin',
    headers: { 'Content-Type': 'application/json' },
    method: 'DELETE'
  })

  return await utils.checkResponse(response)
}

export default removeApplicDiscount

import React from 'react'

import {
  BAR_HEIGHT,
  BAR_Y,
  BORDER_RADIUS,
  PLACEHOLDER_BAR_COLOR
} from 'apps/Reports/constants/productPerformanceCharts'
import { CLASS_SIDE_COLOR, COURSE_SIDE_COLOR } from '..'

const ChartContent = ({
  leftSideSize,
  rightSideSize,
  classCapacity,
  courseCapacity,
  totalCapacity,
  totalSize
}) => {
  if (totalCapacity === 0) {
    return (
      <rect
        data-testid='empty-capacity-rect'
        x='0'
        y={BAR_Y}
        rx={BORDER_RADIUS}
        ry={BORDER_RADIUS}
        width={totalSize}
        height={BAR_HEIGHT}
        fill={PLACEHOLDER_BAR_COLOR}
      />
    )
  }

  return (
    <>
      {classCapacity > 0 ? (
        <rect
          data-testid='class-capacity-rect'
          x='0'
          y={BAR_Y}
          rx={BORDER_RADIUS}
          ry={BORDER_RADIUS}
          width={leftSideSize}
          height={BAR_HEIGHT}
          fill={CLASS_SIDE_COLOR}
        />
      ) : null}
      {courseCapacity > 0 ? (
        <rect
          data-testid='course-capacity-rect'
          x={leftSideSize}
          y={BAR_Y}
          rx={BORDER_RADIUS}
          ry={BORDER_RADIUS}
          width={rightSideSize}
          height={BAR_HEIGHT}
          fill={COURSE_SIDE_COLOR}
        />
      ) : null}
      {courseCapacity > 0 && classCapacity > 0 ? (
        <rect
          data-testid='margin-between-rects'
          x={leftSideSize - 1}
          y={BAR_Y}
          width={2}
          height={BAR_HEIGHT}
          fill={'white'}
        />
      ) : null}
    </>
  )
}

export default ChartContent

import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import moment from 'moment'

import { Alert } from '@fullfabric/alma-mater'

import { useLocale } from 'shared/contexts/Locale'

import isEventOpen, {
  areEventRegistrationsAlreadyOpen,
  areEventRegistrationsClosed,
  eventHasSpace
} from 'apps/ContentPages/utils/is-event-open'

import styles from './styles.module.scss'

const getEventState = (event, locale, t) => {
  const description = <Trans>Registrations are closed.</Trans>

  if (!eventHasSpace(event))
    return {
      title: <Trans>This event is now full.</Trans>,
      description
    }
  if (!areEventRegistrationsAlreadyOpen(event)) {
    const registrationsOpenDate = moment(event.start_registrations)
      .tz(event.timezoneName)
      .locale(locale)
      .format(`LLL`)
    const registrationsAreNotOpenYetDescription = t(
      'Registrations will open on {{ registrationsOpenDate }}.',
      { registrationsOpenDate }
    )
    return {
      title: <Trans>Registrations are currently closed.</Trans>,
      description: registrationsAreNotOpenYetDescription
    }
  }

  if (areEventRegistrationsClosed(event))
    return {
      title: <Trans>Registrations are closed.</Trans>
    }
}

const EventStateBanner = ({ event }) => {
  const { t } = useTranslation()
  const { locale } = useLocale()
  const isOpen = isEventOpen(event)

  if (isOpen) {
    return null
  }

  const eventState = getEventState(event, locale, t)

  return (
    <Alert
      type={!areEventRegistrationsAlreadyOpen(event) ? 'warning' : 'alert'}
      title={eventState.title}
      description={eventState.description}
      className={styles.banner}
    />
  )
}

export default EventStateBanner

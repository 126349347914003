import React from 'react'

import useIsMobileLayout from 'apps/ContentPages/components/payments-page/use-is-payments-mobile-layout'
import FeeDetail from './fee-detail'

import classNames from 'classnames'
import styles from './styles.module.scss'

const AlertingFeesWidget = ({ urgentFees, discounts }) => {
  const isMobileLayout = useIsMobileLayout()
  const isSingleFee = urgentFees?.length === 1

  if (isMobileLayout) {
    return (
      <div className={styles.cardRow}>
        {urgentFees.map(({ fee, paymentPlan }) => (
          <div
            key={fee.id}
            className={classNames(
              styles.cardContainer,
              isSingleFee && styles.singleCard
            )}
          >
            <div className={styles.card}>
              <FeeDetail
                isHead
                fee={fee}
                discounts={discounts}
                paymentPlan={paymentPlan}
              />
            </div>
          </div>
        ))}
      </div>
    )
  }

  return (
    <div className={styles.card}>
      {urgentFees.map(({ fee, paymentPlan }, index) => (
        <FeeDetail
          isHead={index === 0}
          fee={fee}
          discounts={discounts}
          paymentPlan={paymentPlan}
          key={fee.id}
        />
      ))}
    </div>
  )
}

export default AlertingFeesWidget

import React from 'react'
import { useParams } from 'react-router'

import FixedClassOverviewPage from 'apps/Reports/components/FixedClassOverviewPage'
import useChangeDocumentTitle from 'apps/Reports/hooks/useChangeDocumentTitle'

function FixedClassOverview() {
  useChangeDocumentTitle('Product overview report')

  const { classId } = useParams()
  return <FixedClassOverviewPage baseClassId={classId} />
}

export default FixedClassOverview

import React from 'react'

const OnBehalfIcon = () => {
  return (
    <svg
      width='32'
      height='32'
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10 16C13.0376 16 15.5 13.5376 15.5 10.5C15.5 7.46243 13.0376 5 10 5C6.96243 5 4.5 7.46243 4.5 10.5C4.5 13.5376 6.96243 16 10 16Z'
        stroke='black'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M1 27C1 22.0294 5.02944 18 10 18C14.9706 18 19 22.0294 19 27'
        stroke='black'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M23.6361 18C26.1214 18 28.1361 15.9853 28.1361 13.5C28.1361 11.0147 26.1214 9 23.6361 9C21.1508 9 19.1361 11.0147 19.1361 13.5C19.1361 15.9853 21.1508 18 23.6361 18Z'
        stroke='black'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M21.084 20.0906C23.3439 19.2577 25.8681 19.5819 27.8442 20.9588C29.8203 22.3357 30.9987 24.5915 31 27'
        stroke='black'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default OnBehalfIcon

.react-app ._mappingTable_1w8dk_1 {
  border-collapse: collapse;
  border: 1px solid #c0ccda;
  border: 1px solid var(--ff-color-grey-500);
}
.react-app ._mappingTable_1w8dk_1 th,
.react-app ._mappingTable_1w8dk_1 td {
  padding: 0;
  padding: 8px 16px;
  padding: var(--space-small) var(--space-default);
}
.react-app ._mappingTable_1w8dk_1 th {
  border-bottom: 1px solid #c0ccda;
  border-bottom: 1px solid var(--ff-color-grey-500);
}
.react-app ._mappingTable_1w8dk_1 th:first-child {
  border-right: 1px solid #e0e6ed;
  border-right: 1px solid var(--ff-color-grey-300);
}
.react-app ._mappingTable_1w8dk_1 tbody tr td {
  border-bottom: 1px solid #e0e6ed;
  border-bottom: 1px solid var(--ff-color-grey-300);
}
.react-app ._mappingTable_1w8dk_1 tbody tr td:first-child {
  border-right: 1px solid #e0e6ed;
  border-right: 1px solid var(--ff-color-grey-300);
}
.react-app ._mappingTable_1w8dk_1 tbody tr:last-child td {
  border-bottom: 1px solid #c0ccda;
  border-bottom: 1px solid var(--ff-color-grey-500);
}
import 'css-chunk:src/apps/MergeProfiles/components/MergeInfoSummary/styles.module.scss';export default {
  "react-app": "react-app",
  "summaryHeader": "_summaryHeader_1qap3_1",
  "summaryFieldSectionsContainer": "_summaryFieldSectionsContainer_1qap3_8",
  "summaryFieldSection": "_summaryFieldSection_1qap3_8",
  "sectionTitle": "_sectionTitle_1qap3_24",
  "summaryField": "_summaryField_1qap3_8",
  "changed": "_changed_1qap3_32",
  "cascading": "_cascading_1qap3_39",
  "keptFieldValuesContainer": "_keptFieldValuesContainer_1qap3_43",
  "changedFieldValuesContainer": "_changedFieldValuesContainer_1qap3_44",
  "hasChanges": "_hasChanges_1qap3_51",
  "summaryFieldSubField": "_summaryFieldSubField_1qap3_58",
  "summaryFieldValue": "_summaryFieldValue_1qap3_61",
  "oldValue": "_oldValue_1qap3_68"
};
import { utils } from '@fullfabric/public-api'

const editBillingActivity = async (id, isBillable) => {
  const response = await fetch(`/billing/api/billing_activities/${id}`, {
    credentials: 'same-origin',
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ billable: isBillable })
  })

  return await utils.checkResponse(response)
}

export default editBillingActivity

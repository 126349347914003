import React from 'react'
import { Trans } from 'react-i18next'
import { useQuery } from 'react-query'

import { Field, Text } from '@fullfabric/alma-mater'

import api from 'apps/Imports/api'
import ImportActions from 'apps/Imports/components/profile-import/import-actions'
import { useSelectedData } from 'apps/Imports/contexts/selected-data'

import styles from './styles.module.scss'

const ProfileDetailsStep = () => {
  const { data, onDataChanged } = useSelectedData()
  const { data: sourceCategories, isLoading: isLoadingSourceCategories } =
    useQuery('sourceCategories', api.getSourceCategories)
  const isImportingNewAndExistingProfiles = !!data.updateExistingProfiles

  const { data: tags, isLoading: isLoadingTags } = useQuery('tags', api.getTags)

  const createNewTag = React.useCallback(
    (newTagName) => {
      onDataChanged('tags', [...data.tags, newTagName])
    },
    [onDataChanged, data]
  )

  const tagOptions = (data.tags || [])
    .filter((selectedTag) => !tags.some((tag) => tag.name === selectedTag))
    .map((selectedTagNotWithinLoadedTags) => ({
      value: selectedTagNotWithinLoadedTags,
      label: selectedTagNotWithinLoadedTags
    }))
    .concat(
      (tags || []).map((loadedTag) => ({
        value: loadedTag.name,
        label: loadedTag.name
      }))
    )

  return (
    <>
      <Field
        id='tags'
        type='tag'
        label={<Trans>Tag</Trans>}
        help={
          <Trans>
            The tags selected will be added to all profiles in the import.
            Existing tags on profiles will remain and will not be deleted.
          </Trans>
        }
        onChange={onDataChanged}
        value={data.tags}
        inputOptions={{
          values: data.tags,
          options: tagOptions,
          isLoading: isLoadingTags,
          onCreateOption: createNewTag,
          className: styles.tags
        }}
      />

      <Text type='h4' fontColor='text-base-darkest'>
        <Trans>Source category</Trans>
      </Text>

      {isImportingNewAndExistingProfiles && (
        <Field
          id='overwriteSourceCategory'
          type='radio'
          label={
            <Trans>
              Would you like to update the source category of existing profiles?
            </Trans>
          }
          value={data.overwriteSourceCategory}
          onChange={onDataChanged}
          inputOptions={[
            {
              value: 'none',
              label: (
                <Trans>
                  Do NOT update the source category of existing profiles
                </Trans>
              )
            },
            {
              value: 'all',
              label: (
                <Trans>
                  Update the source category of all existing profiles
                </Trans>
              )
            }
          ]}
          className={styles.sourceCategoryType}
        />
      )}

      <Field
        id='sourceCategoryType'
        type='radio'
        label={<Trans>How would you like to import the source category?</Trans>}
        value={data.sourceCategoryType}
        onChange={onDataChanged}
        inputOptions={[
          {
            value: 'same',
            label: <Trans>Same source category for the whole import file</Trans>
          },
          {
            value: 'unique',
            label: (
              <Trans>
                Unique source category for each row in the import file
              </Trans>
            )
          }
        ]}
        className={styles.sourceCategoryType}
      />

      {data.sourceCategoryType === 'unique' ? (
        <Text type='f4' className={styles.uniqueSourceCategoryCaption}>
          <Trans>
            Please map the columns in your import file in the Mapping step
          </Trans>
        </Text>
      ) : (
        <Field
          id='sourceCategory'
          type='autocomplete'
          label={<Trans>Source category for all profiles</Trans>}
          value={data.sourceCategory}
          onChange={onDataChanged}
          inputOptions={{
            options: sourceCategories,
            isLoading: isLoadingSourceCategories,
            className: styles.sourceCategoryAutocomplete
          }}
        />
      )}

      <ImportActions />
    </>
  )
}

export default ProfileDetailsStep

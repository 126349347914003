import React from 'react'
import { Trans } from 'react-i18next'

import {
  ArrowLeftIcon,
  OneColumnLayout,
  PageHead
} from '@fullfabric/alma-mater'
import { IfHasFeature } from '@fullfabric/authorization-officer'

import FunnelReport from 'apps/Reports/components/FunnelReport'
import useChangeDocumentTitle from 'apps/Reports/hooks/useChangeDocumentTitle'

import styles from './styles.module.scss'

const pageHead = (
  <PageHead
    noBorder
    breadcrumbs={[
      {
        label: <Trans>Back to list of reports</Trans>,
        icon: ArrowLeftIcon,
        to: '/reports'
      }
    ]}
    title={<Trans>Product conversion</Trans>}
  />
)

const ProductConversion = () => {
  useChangeDocumentTitle('Product conversion report')

  return (
    <IfHasFeature moduleName='reports' featureName='funnel_report'>
      <OneColumnLayout pageHead={pageHead} className={styles.pageSpacing}>
        <FunnelReport />
      </OneColumnLayout>
    </IfHasFeature>
  )
}

export default ProductConversion

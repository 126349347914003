import React, { Suspense } from 'react'
import { Trans } from 'react-i18next'
import { Route, Switch } from 'react-router-dom'

import FullScreenLoading from 'shared/components/FullScreenLoading'

import useIsMobileLayout from 'apps/ContentPages/hooks/use-is-mobile-layout'
import {
  APPLIC_PAYMENTS_PAGE_ROUTE,
  EVENT_PAGE_ROUTE,
  EXTERNAL_PAYER_PAYMENT_PAGE_ROUTE,
  FORM_PAGE_ROUTE,
  MY_APPLICATIONS_PAGE_ROUTE,
  MY_REGISTRATIONS_PAGE_ROUTE,
  PAYMENTS_PAGE_ROUTE,
  PRODUCT_CATALOG_DETAILS_PAGE_ROUTE,
  PRODUCT_CATALOG_PAGE_ROUTE,
  STUDENT_HUB_PAGE_ROUTE,
  STUDENT_HUB_PREVIEW_PAGE_ROUTE
} from './constants'

import classNames from 'classnames'
import styles from './styles.module.scss'

const EventPage = React.lazy(() => import('../../pages/event'))
const FormPage = React.lazy(() => import('../../pages/form'))
const MyApplicationsPage = React.lazy(() =>
  import('../../pages/my-applications')
)
const PaymentsPage = React.lazy(() => import('../../pages/payments'))
const ApplicPaymentPage = React.lazy(() =>
  import('../../pages/applic-payment-page')
)
const ExternalPayerPaymentPage = React.lazy(() =>
  import('../../pages/external-payer-payment-page')
)
const ProductCatalogPage = React.lazy(() =>
  import('../../pages/product-catalog')
)
const ProductCatalogDetailsPage = React.lazy(() =>
  import('../../pages/product-catalog-details')
)
const StudentHubPage = React.lazy(() => import('../../pages/student-hub'))

const ContentPagesAppRoutes = () => {
  const isMobileLayout = useIsMobileLayout()

  return (
    <>
      <a
        className={classNames([
          styles.skipLink,
          isMobileLayout && styles.mobile
        ])}
        href='#main-content'
        tabIndex='0'
      >
        <Trans>Skip to main content</Trans>
      </a>
      <Suspense fallback={<FullScreenLoading />}>
        <Switch>
          <Route path={EVENT_PAGE_ROUTE} component={EventPage} />
          <Route path={FORM_PAGE_ROUTE} component={FormPage} />
          <Route
            path={MY_APPLICATIONS_PAGE_ROUTE}
            component={MyApplicationsPage}
          />
          <Route
            path={MY_REGISTRATIONS_PAGE_ROUTE}
            component={MyApplicationsPage}
          />
          <Route
            path={APPLIC_PAYMENTS_PAGE_ROUTE}
            component={ApplicPaymentPage}
          />
          <Route
            path={EXTERNAL_PAYER_PAYMENT_PAGE_ROUTE}
            component={ExternalPayerPaymentPage}
          />
          <Route path={PAYMENTS_PAGE_ROUTE} component={PaymentsPage} />
          <Route
            path={PRODUCT_CATALOG_PAGE_ROUTE}
            exact
            component={ProductCatalogPage}
          />
          <Route
            path={PRODUCT_CATALOG_DETAILS_PAGE_ROUTE}
            exact
            component={ProductCatalogDetailsPage}
          />
          <Route
            path={STUDENT_HUB_PREVIEW_PAGE_ROUTE}
            render={(props) => <StudentHubPage preview {...props} />}
          />
          <Route path={STUDENT_HUB_PAGE_ROUTE} component={StudentHubPage} />
        </Switch>
      </Suspense>
    </>
  )
}

export default ContentPagesAppRoutes

import React from 'react'

import ProductOverviewPage from 'apps/Reports/components/ProductOverviewPage'
import useChangeDocumentTitle from 'apps/Reports/hooks/useChangeDocumentTitle'

const ProductOverview = () => {
  useChangeDocumentTitle('Product overview report')

  return <ProductOverviewPage />
}

export default ProductOverview

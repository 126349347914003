import 'css-chunk:src/apps/ContentPages/components/page-layout/content-grid/styles.module.scss';export default {
  "react-app": "react-app",
  "container": "_container_1s2le_1",
  "desktop": "_desktop_1s2le_4",
  "contentCol": "_contentCol_1s2le_9",
  "withWidgets": "_withWidgets_1s2le_14",
  "widgetsCol": "_widgetsCol_1s2le_18",
  "largeWidgets": "_largeWidgets_1s2le_23",
  "fixedWidgetsCol": "_fixedWidgetsCol_1s2le_29",
  "reference": "_reference_1s2le_29",
  "widgets": "_widgets_1s2le_18",
  "widgetContainer": "_widgetContainer_1s2le_36",
  "mobile": "_mobile_1s2le_40",
  "prioritizedOverContent": "_prioritizedOverContent_1s2le_47"
};